const initialGMWalletState = {
    showSpinner: false,
    viewWallet: false,
    viewAddNewPayment: false,
    openFoundPayModal: false,
    showLookUpPayBtn: false,
    doesUserHaveGMWallet: false,
    hideConsentModal: false,
    addPaymentAttempt: false,
    addPaymentSuccess: false,
    showErrorMessage: false,
    editPaymentSuccess: false,
    removedCardSuccess: false,
    addFoundCardToWalletSuccess: false,
    payMethodsResponse: {},
    paymentResult: {},
    lastCardAdded: {
        cardName: "",
        lastFour: ""
    },
    removedCardData: {
        cardName: "",
        lastFour: ""
    },
    nameOnCard: "",
    showFindExistingBtn: false,
    showWalletModal: false,
    showEditErrorMessage: false,
    showEditSpinnerBtn: false,
    params: {
        currency: "USD",
        orderNo: "",
        totalAmount: "0",
        userId: ""
    },
    storedPaymentMethodId: "",
    isAuthUser: false,
    isCheckout: false,
    viewAddOneTimePayment: false,
    isOneTimePayment: false,
    showAddToWalletCheckboxError: false,
    numOfCards: null,
    hasGMCard: false
};

const GMWalletReducer = (state, action) => {
    switch (action.type) {
        case "SET_PARAMS":
            return {
                ...state,
                params: {
                    currency: "USD",
                    orderNo: action.params.orderNo,
                    totalAmount: action.params.totalAmount,
                    userId: action.params.userId
                }
            };

        case "SHOW_SPINNER":
            return {
                ...state,
                showSpinner: action.boolean
            };

        case "VIEW_ADD_NEW_PAY":
            return {
                ...state,
                viewAddNewPayment: action.viewAddNewPayment,
                showWalletModal: false,
                viewWallet: action.viewWallet
            };

        case "VIEW_ADD_ONE_TIME_PAYMENT":
            return {
                ...state,
                viewAddOneTimePayment: action.boolean,
                showWalletModal: false
            };

        case "SET_IS_ONE_TIME_PAYMENT_FLOW":
            return {
                ...state,
                isOneTimePayment: action.boolean,
                showWalletModal: false
            };

        case "SHOW_ADD_TO_WALLET_CHECKBOX_REQUIRED_ERROR":
            return {
                ...state,
                showAddToWalletCheckboxError: true
            };

        case "OPEN_FOUND_PAY_MODAL":
            return {
                ...state,
                openFoundPayModal: action.boolean,
                showWalletModal: false
            };

        case "ADD_FOUND_CARD_TO_WALLET_SUCCESS":
            return {
                ...state,
                addFoundCardToWalletSuccess: action.boolean
            };

        case "NEW_PAYMENT_ADDED":
            return {
                ...state,
                addPaymentSuccess: action.response,
                addPaymentAttempt: true,
                payMethodsResponse: action.response ? {} : state.payMethodsResponse
            };

        case "SET_PAYMENT_RESULT":
            return {
                ...state,
                paymentResult: action.result
            };

        case "SHOW_EDIT_SPINNER":
            return {
                ...state,
                showEditSpinnerBtn: action.boolean
            };

        case "EDIT_PAYMENT":
            return {
                ...state,
                editPaymentSuccess: action.success,
                showEditErrorMessage: !action.success,
                showEditSpinnerBtn: false
            };

        case "HIDE_EDIT_ERROR_MESSAGE":
            return {
                ...state,
                showEditErrorMessage: false
            };

        case "REMOVE_PAYMENT_METHOD":
            return {
                ...state,
                removedCardData: {
                    cardName: action.cardName,
                    lastFour: action.lastFour
                },
                removedCardSuccess: action.success
            };

        case "NO_MESSAGES":
            return {
                ...state,
                addPaymentAttempt: false,
                addPaymentSuccess: false,
                lastCardAdded: {
                    cardName: "",
                    lastFour: ""
                },
                removedCardData: {
                    cardName: "",
                    lastFour: ""
                },
                showErrorMessage: false,
                editPaymentSuccess: false,
                removedCardSuccess: false,
                addFoundCardToWalletSuccess: false,
                showLookUpPayBtn: false,
                showAddToWalletCheckboxError: false
            };

        case "ERROR_RETRIEVING_PAYMENTS":
            return {
                ...state,
                showErrorMessage: true,
                hideConsentModal: true
            };

        case "SET_PAY_METHOD_RESPONSE":
            return {
                ...state,
                payMethodsResponse: action.response,
                showErrorMessage: false
            };

        case "VIEW_WALLET":
            return {
                ...state,
                viewWallet: true
            };

        case "SHOW_LOOK_UP_BTN":
            return {
                ...state,
                showLookUpPayBtn: true
            };

        case "USER_HAS_GM_WALLET":
            return {
                ...state,
                doesUserHaveGMWallet: true
            };

        case "SET_LAST_CARD_ADDED":
            return {
                ...state,
                lastCardAdded: {
                    cardName: action.lastCard.name,
                    lastFour: action.lastCard.lastFour
                }
            };

        case "SHOW_FIND_EXISITING_BTN":
            return {
                ...state,
                showFindExistingBtn: action.boolean
            };

        case "SHOW_WALLET_MODAL":
            return {
                ...state,
                showWalletModal: action.boolean
            };
        //This is to set what the user will use for payment
        case "SET_PAYMENT_ID":
            return {
                ...state,
                storedPaymentMethodId: action.storedPaymentMethodId
            };

        case "SET_WALLET_CONDITIONS":
            return {
                ...state,
                isAuthUser: action.isAuthUser,
                isCheckout: action.isCheckout
            };

        case "SET_NUM_OF_CARDS":
            return {
                ...state,
                numOfCards: action.numOfCards
            };

        case "SET_HAS_GM_CARD":
            return {
                ...state,
                hasGMCard: action.hasGMCard
            };

        default:
            return state;
    }
};

export { initialGMWalletState, GMWalletReducer };
