import React, { useState, useEffect } from "react";
import store from '../../../core/Redux/Store';
import AppSettings from '../../../core/AppSettings';

/* Shared component that takes in the disclaimer content name of interest and returns the
 associated superscripted number label based on footer numbering

Current non-Monthly numbered Content Names from Management Center:
Brand Checkout Coupon Disclaimer
Brand Rewards Disclaimer

*/
const DEBUG = AppSettings.isLocalHost;

export const useFooterDisclaimerLabel = (disclaimerName) => {

    const [label, setLabel] = useState("");

    const [footerData, setFooterData] = useState();

    useEffect(() => {
        setFooterData(store.getState().FooterReducer);

        // need store subscribe listener to keep up with state changes of footerData
        const unsubscribe = store.subscribe(() => {
            setFooterData(store.getState().FooterReducer);
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        if (footerData && footerData.sequence) {
            const disclaimerFound = footerData.sequence.find(disclaimer => disclaimer.name.includes(disclaimerName));
            if (disclaimerFound) {
                setLabel(disclaimerFound.label);
            }
        }
    }, [footerData]);

    return label;
}

// wrapper function for hook to be used in Class components
// pass in disclaimerName as argument to hook and store label to pass to children render prop
// ex. <FooterDisclaimerLabel disclaimerName={disclaimer} children={label => <sup>{label}</sup>}/>
export function FooterDisclaimerLabel({ disclaimerName, children }) {
    let label = useFooterDisclaimerLabel(disclaimerName);
    return children(label);
}

/**
 * A function generator that will return a function which can wrap HTML strings and replace all disclaimers within it.
 * Returned function takes one required param, the html text to parse, plus one optional param, the element type
 * to replace (default = "sup"). Returns a string of HTML.
 * @returns {function} a function to replace all disclaimers in a string containing arbitrary HTML.
 */
export const useReplaceAllNamedDisclaimers = () => {
    // list of disclaimers is in footerData.sequence at all times. set up subscription for this.
    const [footerData, setFooterData] = useState({});
    useEffect(() => {
        setFooterData(store.getState().FooterReducer);

        // need store subscribe listener to keep up with state changes of footerData
        const unsubscribe = store.subscribe(() => {
            setFooterData(store.getState().FooterReducer);
        });
        return unsubscribe;
    }, []);

    // context for function below. should provide all that's needed to look up disclaimers.
    const closedContext = Object.freeze({
        footerDisclaimers: "sequence" in footerData && footerData.sequence,
        hasDisclaimer: function (disclaimerName) {
            return this.footerDisclaimers.some((disclaimer) => disclaimer.name.includes(disclaimerName));
        },
        getDisclaimerId: function (disclaimerName) {
            return this.footerDisclaimers.find((disclaimer) => disclaimer && disclaimer.name.includes(disclaimerName)).label;
        },
        replacementsMade: []
    });

    return function (markdownText, elementType = "sup") {
        if (typeof markdownText !== "string") throw Error("invalid parameter to disclaimer function");

        // don't attempt to process if footer disclaimer list is not populated.
        if (!(this.footerDisclaimers instanceof Array) || this.footerDisclaimers.length === 0) return markdownText;

        // find <elementType ... / ... > and replace innerText with disclaimer ID
        // capture the name of the disclaimer if we are in debug mode
        const startString = DEBUG ? `<${elementType} data-disc="(?<disclaimerName>[^"]*)` : `<${elementType}`;
        const closeSlash = "/";
        const closeArrow = ">";

        // find all indexes of startTag
        const startRegex = new RegExp(startString, "g");
        const openTags = markdownText.matchAll(startRegex);
        let baseDiff = 0; // length difference after latest replacement

        // for each, perform replacement (could be self-closing)
        for (const openTag of openTags) {
            DEBUG && console.log("regex match: ", openTag);

            const tagMeta = {
                start: openTag.index + baseDiff,
                end: null,
                disclaimerName: null,
                disclaimerIndex: null,
                thisDiff: 0,
                baseDiff
            };

            const nextCloseSlash = markdownText.indexOf(closeSlash, tagMeta.start);
            const nextCloseArrow = markdownText.indexOf(closeArrow, tagMeta.start);

            if (nextCloseSlash < nextCloseArrow) {
                tagMeta.end = nextCloseArrow;
            } else {
                tagMeta.end = markdownText.indexOf(closeArrow, nextCloseSlash);
            }

            DEBUG && console.log("substring is: ", markdownText.slice(tagMeta.start, tagMeta.end + 1));

            // get the disclaimer if it exists, otherwise fall back to ""
            const readDisclaimerIfExists = (name) => (this.hasDisclaimer(name) ? this.getDisclaimerId(name) : "");

            try {
                const replacement = getDisclaimerText(
                    markdownText.slice(tagMeta.start, tagMeta.end + 1),
                    readDisclaimerIfExists
                );

                tagMeta.disclaimerName = replacement.disclaimerName;
                tagMeta.disclaimerIndex = replacement.disclaimerIndex;
                tagMeta.thisDiff = replacement.thisDiff;
                baseDiff += replacement.thisDiff;

                markdownText =
                    markdownText.slice(0, tagMeta.start) +
                    replacement.newMarkdown +
                    markdownText.slice(tagMeta.end + 1);
            } catch (e) {
                DEBUG && console.log("Error occured in disclaimer linking:\n", e);
            } finally {
                this.replacementsMade.push(tagMeta);
                DEBUG && console.log("replacement context: ", this);
            }
        }

        return markdownText;
    }.bind(closedContext);
};

let parser = undefined;

/**
 * Replace the content of an element with a data-disc attribute
 * with the label (numeric index) of its named disclaimer.
 * @param {string} markdownText - the string containing only the element to work with.
 * @param {string | function} disclaimerIndexGetter - the replacer function or the label to use.
 * @returns {object} the markdown text with the label inserted and the length difference between before and after replacement.
 */
const getDisclaimerText = (markdownText, disclaimerIndexGetter) => {
    if (typeof markdownText !== "string") throw Error("invalid parameter to replaceNamedDisclaimer");
    if (!(parser instanceof DOMParser)) parser = new DOMParser();

    const dom = parser.parseFromString(markdownText, "text/html");
    if (!dom || dom.body.childElementCount !== 1 || dom.body.children[0].childElementCount !== 0)
        throw Error("invalid html for single element passed to replaceNamedDisclaimer");

    const targetElement = dom.body.children[0];
    const disclaimerName = "disc" in targetElement.dataset && targetElement.dataset.disc;
    if (!disclaimerName && typeof disclaimerIndexGetter === "function")
        throw Error("disclaimer name not found on element: " + targetElement.outerHTML);

    // get this disclaimer from Redux:
    // if disclaimer not found, content will be empty.
    const previousLength = markdownText.length;
    targetElement.innerText =
        typeof disclaimerIndexGetter === "function" ? disclaimerIndexGetter(disclaimerName) : disclaimerIndexGetter;

    return {
        newMarkdown: targetElement.outerHTML,
        thisDiff: targetElement.outerHTML.length - previousLength,
        disclaimerName: disclaimerName,
        disclaimerIndex: targetElement.innerText
    };
};
