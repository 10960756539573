import React from "react";
import AppSettings from "../../../../core/AppSettings"
import Modal from "../../../../shared/Modal/Modal"
import store from "../../../../core/Redux/Store";
import SharedButton from "../../../../shared/Button/SharedButton";
import { useTranslation } from "react-i18next";
import MiniCartWrapper from "./MiniCartWrapper";
import accHistory from "../../History";

/**
 * @param {*} props
 *  data: miniCartResponse state
 *  modalOpen: boolean - if true modal will display
 *  count: number of items in cart
 * @return {div} Mini Cart Modal that displays all the items in a user's shopping cart,
 * looks at modalOpen boolean, if true - modal is open, if false - modal is closed
 */

function MiniCartModal(props) {

    const data = props.data;
    const modalOpen = props.modalOpen;
    const miniCartCount = props.count;
    const { t } = useTranslation();

    //Redirect functions for buttons within modal and empty modal
    const handleGoToAcc = () => {
        setModalClose();
        if (AppSettings.isT3) {
            accHistory.push(AppSettings.homeUrl);
        } else {
            accHistory.push("/");
        }
    };
    function setModalClose() {
        store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: false });
    }


    const emptyModalContent = (
        <div id="minicart-modal" className="mini-cart-empty-modal acc-minicart-modal">
            <div className="mini-cart-empty-title gb-headline3">{t("LABEL_MINI_CART_EMPTY_TITLE")}</div>
            <SharedButton
                id="empty-cart-acc-button"
                className="gb-primary-button mini-cart-empty-button"
                analyticsTag="shopping cart"
                buttonText= {t("LABEL_SHOP_ACCESSORIES_BUTTON")}
                onClick={handleGoToAcc}
            ></SharedButton>
        </div>
    );

    if (miniCartCount === 0) {
        return (
            <div className="acc-minicart-modal">
                {modalOpen && window.scrollTo(0, 0)}
                <Modal
                    classNames = {{
                        modal: "acc-minicart-modal",
                        overlay: "acc-minicart-modal-backdrop"}}
                    open={modalOpen}
                    showCloseIcon={false}
                    onClose={() => setModalClose()}
                    focusTrapped={false}
                >
                    {emptyModalContent}
                </Modal>
            </div>
        );
    }
    else {
        return (
            <div className="acc-minicart-modal">
                {modalOpen && window.scrollTo(0, 0)}
                <Modal
                    classNames = {{
                        modal: "acc-minicart-modal",
                        overlay: "acc-minicart-modal-backdrop"}}
                    open={modalOpen}
                    showCloseIcon={false}
                    onClose={() => setModalClose()}
                    focusTrapped={false}
                >
                    <MiniCartWrapper data={data} />
                </Modal>
            </div>
        );
    }
}
export default MiniCartModal;
