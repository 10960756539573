import React, { Component } from "react";
import store from '../../core/Redux/Store';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import VehicleSelect from './VehicleSelect';
import { loadYearsAsync } from './VehicleSelectRedux/VehicleSelectActionCreator';
import { Translation } from 'react-i18next';

class VehicleSelectContainer extends Component {

  constructor(props) {
    super(props);
    // Load years from service call, starting with 10 years ago
    const startingYear = new Date().getFullYear() - 10;
    const loadYearsAsyncAction = loadYearsAsync(startingYear);
    store.dispatch(loadYearsAsyncAction);
  }

  render() {
    return (
      <Translation>
        {(t) => (
            <div className="ac-vehicle-select row gridfullwidth">
              <div className="q-gridbuilder gridspacing-large gridfullwidth">
                <div className="small-12 medium-12 xlarge-12  grid-column-alignment-left">
                  <VehicleSelect headerLabel={this.props.headerLabel} headerLabelDynamicValue={this.props.headerLabelDynamicValue}/>
                </div>
              </div>
            </div>
          )}
      </Translation>
    );
  }
}


const mapStateToProps = state => {
  return {
    vehicleSelectData: state.VehicleSelectReducer,
    reduxFormData: state.form,
    searchLinkParams: state.SearchLinkReducer
  }
}

export default (withRouter(connect(mapStateToProps)(VehicleSelectContainer)));