import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import AppSettings from "../../client/core/AppSettings";
import injectVaultCSS from "./VaultCssInjector";
import OverridesTable from "./OverridesTable";
import OverridesConfirmTable from "./OverridesConfirmTable";
import VaultModalFooter from "./VaultModalFooter";
import { BackButton, resetAllValues, saveAndApplyAll } from "./VaultUIActions";
import vaultConstants from "../../config/vault_constants";
import Modal from "../../client/shared/Modal/Modal";
export const ENABLE_VAULT_CONTROLLER_SOURCE =
    "true" === String(vaultConstants.UI_VAULT_CONTROLLER_SOURCE) || AppSettings.isLocalHost;

export const VAULT_CONTROLLER_DOM_ID = "applicationVaultOverrideUI";
export const getVaultContainer = () => document.getElementById(VAULT_CONTROLLER_DOM_ID);

/**
 * The parent component for the Vault Controller UI
 * @returns {React.ReactFragment}
 */
const VaultUI = () => {
    const [showVaultUI, setShowVaultUI] = useState(false);
    const [step, setStep] = useState("main");
    const [isBackEnabled, setIsBackEnabled] = useState(true);
    const edits = useRef({});
    const resets = useRef({});

    const toggleUI = () => setShowVaultUI((show) => !show);
    const getChangeSet = () => [edits.current, resets.current];

    // inject a stylesheet to make the modal look nice on intialization
    useEffect(() => {
        const [result, err] = injectVaultCSS();
        if (!result) console.warn(`VC: ${err}`);
    }, []);

    // extend VaultController with the toggleUI method to launch the UI
    useEffect(() => {
        window.VaultController.toggleUI = () => {
            const vaultButtonQuery = `#${VAULT_CONTROLLER_DOM_ID} > button.showhide`;
            document.querySelector(vaultButtonQuery)?.click?.();
        };
    }, []);

    // reset UI states when modal is hidden
    useEffect(() => {
        if (!showVaultUI) {
            setStep("main");
            setIsBackEnabled(true);
            edits.current = {};
            resets.current = {};
        }
    }, [showVaultUI]);

    // scroll to top of modal on page changes
    useEffect(() => {
        const scrollModalBodyQuery = `#${VAULT_CONTROLLER_DOM_ID} .modal-body`;
        const body = document.querySelector(scrollModalBodyQuery);
        if (body) body.scrollTop = 0;
    }, [showVaultUI, step]);

    /**
     * Returns the proper component for this route of the modal's navigation.
     * @returns {React.ReactElement}
     */
    const GetBodyContentForPhase = () => {
        switch (step) {
            case "main":
                return <OverridesTable editsRef={edits.current} resetsRef={resets.current} />;
            case "resetConfirm":
                return (
                    <aside>
                        <BackButton target="main" onBack={setStep} disabled={!isBackEnabled} />
                        <h2>Reset All</h2>
                        <p className="content-area">
                            This will reset all values to defaults and erase any locally-stored overrides. Would you
                            like to continue?
                        </p>
                        <div className="content-area">
                            <button className="vc-button" variant="secondary" onClick={toggleUI}>
                                Cancel
                            </button>
                            <button className="vc-button" variant="info" onClick={resetAllValues}>
                                Continue
                            </button>
                        </div>
                    </aside>
                );
            case "applyConfirm":
                return (
                    <aside>
                        <BackButton target="main" onBack={setStep} disabled={!isBackEnabled} />
                        <h2>Save and Apply All</h2>
                        <p className="content-area">
                            These overrides will be applied to the current vault configuration after reloading the page:
                        </p>
                        <OverridesConfirmTable diff={getChangeSet()} onDelete={() => setIsBackEnabled(false)} />
                        <p className="content-area toggles"></p>
                        <div className="content-area">
                            <button className="vc-button" variant="secondary" onClick={toggleUI}>
                                Cancel
                            </button>
                            <button
                                className="vc-button"
                                variant="info"
                                onClick={() => saveAndApplyAll(...getChangeSet())}
                            >
                                Continue
                            </button>
                        </div>
                    </aside>
                );
        }
    };

    /**
     * React Fragment with:
     * 1) the invisible button to toggle the UI
     * 2) the modal in which the vault controller renders
     */
    return (
        <>
            <button className="showhide" type="button" onClick={toggleUI}>
                Show Vault Controller
            </button>
            <Modal
                className="vaultcontroller-modal"
                style="max-width: 84vw;"
                open={showVaultUI}
                onClose={() => setShowVaultUI(false)}
                center
            >
                <thead className="q-headline2">Vault Controller UI</thead>
                <div>
                    <GetBodyContentForPhase />
                </div>
                <div>
                    {step === "main" && (
                        <VaultModalFooter editsRef={edits.current} resetsRef={resets.current} onSelection={setStep} />
                    )}
                </div>
            </Modal>
        </>
    );
};

/**
 * Renders the Vault UI in the Vault Container through a portal from
 * the mounted location in the React tree.
 *
 * Keeps the Vault UI logic as separate as possible from application logic.
 */
export const VaultPortalUI = () => {
    const { current: mountPoint } = useRef(getVaultContainer());
    if (mountPoint) {
        return ReactDOM.createPortal(<VaultUI />, mountPoint);
    } else {
        return null;
    }
};

export default VaultPortalUI;
