import AppSettings from '../../../core/AppSettings';
import { Translation } from 'react-i18next';
import React, { Component } from 'react';


/*
* The list of suggestions for the Error Page
* getHomeUrl is to decide the return home link for the "homepage" text
* */


class ErrorList extends Component {
  getHomeUrl(){
    if(AppSettings.isT3){
      return AppSettings.dealerName + '?bac=' + AppSettings.bac;
    }else {
      return '/';
    }
  }
  render() {
    return (
      <Translation>
        {
          (t) => (
            <div>
              <h3 className="q-headline2 ">
                {t("LABEL_ERROR_PAGE_LIST_HEADER")}
              </h3>
              <ul >
                <li className="q-text q-body1">
                  {t("LABEL_ERROR_PAGE_LIST_POINT_1")}
                </li>
                <li className="q-text q-body1">
                  {t("LABEL_ERROR_PAGE_LIST_POINT_2")}
                </li>
                <li className="q-body1" >
                  <span className="q-text q-body1">{t("LABEL_ERROR_PAGE_LIST_POINT_3")} </span>
                  <a className="ac-link" href={this.getHomeUrl()}>
                    {t("LABEL_ERROR_PAGE_LIST_HOME")}
                  </a>
                </li>
              </ul>
            </div>
          )
        }
      </Translation>
    );
  }
}

export default (ErrorList)