import i18next from 'i18next';
import React, { Component, useState } from 'react';
import * as validate from "../../../../shared/Form/FormValidate/FormValidate";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import AppSettings from "../../../../core/AppSettings";
import store from '../../../../core/Redux/Store';
import vaultConstants from "../../../../../config/vault_constants";
import { scrollerToTop } from "../../../../shared/Utils/Utils";
import {neverCloseModal, shouldPreventStepOneSkip} from "../../CheckoutRedux/CheckoutActionCreator";
import ReviewFormEdit from './ReviewFormEdit';


class ReviewForm extends Component {
  constructor(props) {
    super(props);
    this.state ={
      handleEditContactInfo: false,
      handleEditCustomerInfo: false,
      handleEditBillingInfo: false 
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    scrollerToTop('root');
  }

  render() {
    // grab data from checkout wizard
    const {handleContactFormSubmit, stateList, initialValues} = this.props;
    const { handleEditClick } = this.props;
    const zeroDollarAmount = this.props.zeroDollarAmount;
    const { customerInfo, shipping, dealer } = this.props.data.parsedResponse;
    const { billingInformation, paymentInformation, payMethodId } = this.props.paymentResponse;
    const { stateNameToCode } = store.getState().CheckoutReducer.shippingContactForm;
    const contactInfoDisplayFeatureFlag = AppSettings.isLocalHost
        ? true
        : vaultConstants.FF_2170003_ORDER_REVIEW_CONTACT;
    const isValidResponse = (value) => {
      return (value !== '' && value !== 'null' && value !== undefined && value !== null && value !== 'undefined')
    };
    
    //Cases buttonCustomer and buttonBilling aren't being used right now, but will be used for future stories
    const updateState = (value) =>{
      switch(value){
          case "buttonCustomer": return (this.state.handleEditCustomerInfo ? this.state.handleEditCustomerInfo = false : this.state.handleEditCustomerInfo = true)
          case "buttonContact": return (this.state.handleEditContactInfo ? this.state.handleEditContactInfo = false : this.state.handleEditContactInfo = true)
          case "buttonBilling": return (this.state.handleEditBillingInfo ? this.state.handleEditBillingInfo = false : this.state.handleEditBillingInfo = true)
      }
  }
    
    let cardType;
    let last4;
    let expiryMonth;
    let expiryYear;
    let companyName;

    if (!zeroDollarAmount) {
      //New Adyen Wallet paymentInformation object parsing
      cardType = AppSettings.isT3 && payMethodId === "payInStore" ? '' : paymentInformation.payment.brand;
      last4 = AppSettings.isT3 && payMethodId === "payInStore" ? '' : paymentInformation.payment.lastFour;
      expiryMonth = AppSettings.isT3 && payMethodId === "payInStore" ? '' : paymentInformation.payment.expiryMonth;
      expiryYear = AppSettings.isT3 && payMethodId === "payInStore" ? '' : paymentInformation.payment.expiryYear;
      companyName = isValidResponse(customerInfo.officeAddress) ? customerInfo.officeAddress : '';
    }


    let renderBillingInformation = true;
    let renderPaymentInformation = true;

    if (payMethodId === "payInStore" || zeroDollarAmount) {
      renderBillingInformation = false;
    }
    if (zeroDollarAmount) {
      renderPaymentInformation = false;
    }
    if(contactInfoDisplayFeatureFlag){
        return (
          <Translation>
            {t => (
              <div className="ac-review-form">
                <div className="gmit-gf-groupbox-content q-body2 q-text mb-3">
                  <h5 className="ac-padding-top-sm">
                    {t("LABEL_CHECKOUT_REVIEW_ORDER")}
                  </h5>
    
                  <div className="ac-padding-top-md small-12 medium-12 large-12 xlarge-8">
                    {/* Display Shipping Address on T1 But not on T3*/}
                    {!AppSettings.isT3 && (
                      <div>
                        <div className="section-header">
                          <h5>{t("LABEL_CONFIRMATION_CUSTOMER_ADDRESS")}</h5>
                          <p
                            className="ac-quick-link stat-text-link"
                            data-dtm="checkout step4:customer address"
                            onClick={() => {
                              preventStepOneSkip();
                              handleEditClick(1);
                            }}
                          >
                            {t("LABEL_CHECKOUT_EDIT_INFO")}
                          </p>
                        </div>
                        <p>
                          {customerInfo.firstName} {customerInfo.lastName}
    
                          {
                            isValidResponse(customerInfo.officeAddress) && <br />
                          }
    
                          {companyName}
                          <br />
                          {shipping.address1} {shipping.address2}
                          <br />
                          {shipping.city} {shipping.state} {shipping.zipcode}
                        </p>
                        <hr />
                      </div>
                      
                    )}
                    <div>
                        <div className="section-header">
                          <h5>{t("LABEL_CONFIRMATION_CONTACT_INFORMATION")}</h5>
                          <p
                            className="ac-quick-link stat-text-link"
                            data-dtm="checkout step4:contact information"
                            onClick={() => {
                              updateState("buttonContact");
                              this.forceUpdate();
                            }}
                          >
                            {this.state.handleEditContactInfo ? t("LABEL_COMMON_CANCEL"): t("LABEL_CHECKOUT_EDIT_INFO")}
                          </p>
                        </div>
                        
                        {this.state.handleEditContactInfo && <ReviewFormEdit
                            editForm={2}
                            onSubmit={handleContactFormSubmit}
                            stateList={stateList}
                            initialValues={initialValues}
                            />}
                        <p>
                          {!this.state.handleEditContactInfo && customerInfo.email}
                          <br />
                          {!this.state.handleEditContactInfo && customerInfo.phone}
                          <br />
                          
                        </p>
                        <hr />
                      </div>
                    {/* Always display Dealer Pick Up */}
                    <div>
                      <div className="section-header">
                        <h5>{t("LABEL_CONFIRMATION_DEALER_PICKUP")}</h5>
                        <p
                          className="ac-quick-link stat-text-link"
                          data-dtm="checkout step4:dealer"
                          onClick={() => {
                            handleEditClick(2);
                          }}
                        >
                        </p>
                      </div>
                      <p>
                        {dealer.dealerName}
                        <br />
                        {dealer.address1}
                        <br />
                        {dealer.city}, {dealer.state} {dealer.zipcode}
                        <br />
                        {dealer.country}
                        <br />
                        {t("LABEL_COMMON_PHONE")}: {dealer.phone1}
                        <br />
                      </p>
                      <hr />
                    </div>
                    {/* Display Billing Address on T1 && T3 Credit Card*/}
                    {
                      renderBillingInformation && (
                        <div>
                          <div className="section-header">
                            <h5>{t("LABEL_CONFIRMATION_BILLING_ADDRESS")}</h5>
                            <p
                              className="ac-quick-link stat-text-link"
                              data-dtm="checkout step4:billing address"
                              onClick={() => {
                                handleEditClick(3);
                              }}
                            >
                              {t("LABEL_CHECKOUT_EDIT_INFO")}
                            </p>
                          </div>
                          <p>
                            {billingInformation.firstName}{" "}
                            {billingInformation.lastName}
                            <br />
                            {billingInformation.address1} {billingInformation.address2}
                            <br />
                            {billingInformation.city}, {stateNameToCode[billingInformation.state]}{" "}
                            {billingInformation.zipCode}
                            {/* Currently not taking in phone and email for billing info */}
                            <br />
                            {billingInformation.phone}
                            <br />
                            {billingInformation.email}
                          </p>
                          <hr />
                        </div>
                      )}
    
                    {/* Always display Payment Method unless its zero checkout amount*/}
                    {
                      renderPaymentInformation && <div>
                        <div className="section-header">
                          <h5>{t("LABEL_CONFIRMATION_PAYMENT_METHOD")}</h5>
                          {
                            payMethodId !== 'payInStore' && <p
                              className="ac-quick-link stat-text-link"
                              data-dtm="checkout step4:payment method"
                              onClick={() => {
                                handleEditClick(3);
                              }}
                            >
    
                              {t("LABEL_CHECKOUT_EDIT_INFO")}
                            </p>
                          }
                        </div>
                        {
                          AppSettings.isT3 && payMethodId === "payInStore" ? (
                            <p>
                              {t("LABEL_RECEIPT_PAY_IN_STORE")}
                            </p>
                          ) : (
                            <p>
                              {i18next.t("LABEL_CHECKOUT_CARD_ENDING_IN", { cardType, last4 })}
                              <br />
                              {expiryMonth && expiryMonth.length === 1
                                ? "0" + expiryMonth
                                : expiryMonth}{" "}
                                / {expiryYear}
                            </p>
                          )
                        }
                        <hr />
                      </div>
                    }
                  </div>
    
                </div>
              </div>
            )}
          </Translation>
        );
    }else{
      return (
        <Translation>
          {t => (
            <form className="ac-review-form">
              <div className="gmit-gf-groupbox-content q-body2 q-text mb-3">
                <h5 className="ac-padding-top-sm">
                  {t("LABEL_CHECKOUT_REVIEW_ORDER")}
                </h5>
  
                <div className="ac-padding-top-md small-12 medium-12 large-12 xlarge-8">
                  {/* Display Shipping Address on T1 But not on T3*/}
                  {!AppSettings.isT3 && (
                    <div>
                      <div className="section-header">
                        <h5>{t("LABEL_CONFIRMATION_CUSTOMER_ADDRESS")}</h5>
                        <p
                          className="ac-quick-link stat-text-link"
                          data-dtm="checkout step4:customer address"
                          onClick={() => {
                            preventStepOneSkip();
                            handleEditClick(1);
                          }}
                        >
                          {t("LABEL_CHECKOUT_EDIT_INFO")}
                        </p>
                      </div>
                      <p>
                        {customerInfo.firstName} {customerInfo.lastName}
  
                        {
                          isValidResponse(customerInfo.officeAddress) && <br />
                        }
  
                        {companyName}
                        <br />
                        {shipping.address1} {shipping.address2}
                        <br />
                        {shipping.city} {shipping.state} {shipping.zipcode}
                        <br />
                        {customerInfo.phone}
                        <br />
                        {customerInfo.email}
                      </p>
                      <hr />
                    </div>
                    
                  )}
                  {/* Always display Dealer Pick Up */}
                  <div>
                    <div className="section-header">
                      <h5>{t("LABEL_CONFIRMATION_DEALER_PICKUP")}</h5>
                      <p
                        className="ac-quick-link stat-text-link"
                        data-dtm="checkout step4:dealer"
                        onClick={() => {
                          handleEditClick(2);
                        }}
                      >
                      </p>
                    </div>
                    <p>
                      {dealer.dealerName}
                      <br />
                      {dealer.address1}
                      <br />
                      {dealer.city}, {dealer.state} {dealer.zipcode}
                      <br />
                      {dealer.country}
                      <br />
                      {t("LABEL_COMMON_PHONE")}: {dealer.phone1}
                      <br />
                    </p>
                    <hr />
                  </div>
                  {/* Display Billing Address on T1 && T3 Credit Card*/}
                  {
                    renderBillingInformation && (
                      <div>
                        <div className="section-header">
                          <h5>{t("LABEL_CONFIRMATION_BILLING_ADDRESS")}</h5>
                          <p
                            className="ac-quick-link stat-text-link"
                            data-dtm="checkout step4:billing address"
                            onClick={() => {
                              handleEditClick(3);
                            }}
                          >
                            {t("LABEL_CHECKOUT_EDIT_INFO")}
                          </p>
                        </div>
                        <p>
                          {billingInformation.firstName}{" "}
                          {billingInformation.lastName}
                          <br />
                          {billingInformation.address1} {billingInformation.address2}
                          <br />
                          {billingInformation.city}, {stateNameToCode[billingInformation.state]}{" "}
                          {billingInformation.zipCode}
                          {/* Currently not taking in phone and email for billing info */}
                          <br />
                          {billingInformation.phone}
                          <br />
                          {billingInformation.email}
                        </p>
                        <hr />
                      </div>
                    )}
  
                  {/* Always display Payment Method unless its zero checkout amount*/}
                  {
                    renderPaymentInformation && <div>
                      <div className="section-header">
                        <h5>{t("LABEL_CONFIRMATION_PAYMENT_METHOD")}</h5>
                        {
                          payMethodId !== 'payInStore' && <p
                            className="ac-quick-link stat-text-link"
                            data-dtm="checkout step4:payment method"
                            onClick={() => {
                              handleEditClick(3);
                            }}
                          >
  
                            {t("LABEL_CHECKOUT_EDIT_INFO")}
                          </p>
                        }
                      </div>
                      {
                        AppSettings.isT3 && payMethodId === "payInStore" ? (
                          <p>
                            {t("LABEL_RECEIPT_PAY_IN_STORE")}
                          </p>
                        ) : (
                          <p>
                            {i18next.t("LABEL_CHECKOUT_CARD_ENDING_IN", { cardType, last4 })}
                            <br />
                            {expiryMonth && expiryMonth.length === 1
                              ? "0" + expiryMonth
                              : expiryMonth}{" "}
                              / {expiryYear}
                          </p>
                        )
                      }
                      <hr />
                    </div>
                  }
                </div>
  
              </div>
            </form>
          )}
        </Translation>
      );
    }
  };
};
    

function preventStepOneSkip() {
  store.dispatch(shouldPreventStepOneSkip());
}

function mapDispatchToProps(dispatch) {
  return {
    //keep modal open forever
    neverCloseOrderProcessingModal: () => {
      dispatch(neverCloseModal());
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ReviewForm);
