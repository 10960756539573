/*** COMPONENT NAME : SELECTED DEALER (DEFAULT DEALER INFORMATION) ***/

/*** IMPORT STATEMENT ***/
import React from 'react';
import {Translation} from 'react-i18next';



const SelectedDealer = (props)=>{
   const dealerInfo = props.dealerInfo;

   if(dealerInfo !== undefined){
        return (
            <Translation>
                {
                    (t)=>(
                        <div className="ac-selectedDealer">
                            <div>
                                <h4>
                                    <b>
                                        {t("LABEL_CHECKOUT_SEL_DEALER")}
                                    </b>
                                </h4>
                            </div>
                            <div>
                                <b>{dealerInfo.dealerName}
                                </b>
                            </div>
                            <div>{dealerInfo.address1}</div>
                            <div>{dealerInfo.address2}</div>
                            <div className="ac-cityStateZip">
                                <span>{dealerInfo.city},</span>
                                <span>{dealerInfo.state}</span>
                                <span>{dealerInfo.zipcode}</span>
                            </div>
                            <div>
                                <span>{dealerInfo.country}</span>
                            </div>
                            <div>
                                <span>{t("LABEL_COMMON_PHONE")}: {dealerInfo.phone1}</span>
                            </div>

                        </div>
                    )
                }
            </Translation>
        )
    }
    else
        return null
};


export default SelectedDealer