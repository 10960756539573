import i18next from "i18next";
import React from "react";
import AppSettings from "../../core/AppSettings";

function MiniProductCard(props) {
    const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";

    return (
        <div className="ac-mini-product-card">
            <div className="ac-mini-product-card-img-wrapper">
                <img
                    src={props.imagePath}
                    alt={props.productName}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = imgNotFound;
                    }}
                />
            </div>
            <div className="ac-mini-product-card-info-wrapper">
                <div>{props.title}</div>
                <div>
                    {i18next.t("LABEL_COMMON_PART_NO") + ": "}
                    {props.partNumber}
                    {props.oldPartNumber && (
                        <span className="ac-old-partnumber-span">
                            {i18next.t("LABEL_REPLACE_OLD_PARTNUMBER", { oldPartNumber: props.oldPartNumber })}
                        </span>
                    )}
                </div>
                <div>{`${props.priceLabel} ${props.price}${props.priceAsterisk}`}</div>
            </div>
        </div>
    );
}

export default MiniProductCard;
