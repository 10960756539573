import * as type from './UserProfileActionType';

let initialState = {
    profilesettings: {
        showEditPhone: false,
        editAddressInitialValues : {
          firstname : "",
          lastname : "",
          companyName : "",
          address1 : "",
          address2 : "",
          address3 : "",
          city : "",
          state : "",
          zipcode : ""
        },
        showEditPersonalInfo: false,
        showEditAddress: false,
        showAddPhone: false,
        showAddAddress: false
    }
};

const UserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
    
    case type.ORDER_HISTORY_DATA_LOADED:
      return Object.assign({}, state, { orderHistoryData: action.payload });

    case type.LOAD_ORDER_HISTORY_DATA_ASYNC_FAIL:
      return Object.assign({}, state, {
        error: action.error
      });

    case type.ORDER_DATA_LOADED:

      return Object.assign({}, state, { orderDetailsData: action.payload });

    case type.LOAD_ORDER_DATA_ASYNC_FAIL:
      return Object.assign({}, state, {
        error: action.error
      });

    case type.PAYMENT_INFO_DATA_LOADED:
      return Object.assign({}, state, { paymentInfoData: action.payload });

    case type.LOAD_PAYMENT_INFO_DATA_ASYNC_FAIL:
      return Object.assign({}, state, {
        error: action.error
      });

    case type.TOGGLE_PROFILE_EDIT_PHONE:
      return Object.assign({}, state, {
        profilesettings: {
          showEditPhone: !state.profilesettings.showEditPhone,
          showEditPersonalInfo: state.profilesettings.showEditPersonalInfo,         
          showEditAddress: state.profilesettings.showEditAddress,
          editAddressInitialValues: state.profilesettings.editAddressInitialValues,
          showAddPhone: state.profilesettings.showAddPhone,
          showAddAddress: state.profilesettings.showAddAddress
        }
      });
    case type.TOGGLE_PROFILE_EDIT_ADDRESS:
      return Object.assign({}, state, {
        profilesettings: {
          showEditPhone: state.profilesettings.showEditPhone,
          showEditPersonalInfo: state.profilesettings.showEditPersonalInfo,
          showEditAddress: !state.profilesettings.showEditAddress,
          editAddressInitialValues: {
            firstname :  action.payload.person.first_name,
            lastname : action.payload.person.last_name,
            companyName : "",
            address1 : action.payload.address.address_line1,
            address2 : action.payload.address.address_line2,
            address3 : action.payload.address.address_line3,
            city : action.payload.address.city,
            state : action.payload.address.state_or_province,
            zipcode : action.payload.address.postal_code,
          },        
          showAddPhone: state.profilesettings.showAddPhone,
          showAddAddress: state.profilesettings.showAddAddress
        }
      });
    case type.TOGGLE_PROFILE_ADD_PHONE:
      return Object.assign({}, state, {
        profilesettings: {
          showEditPhone: state.profilesettings.showEditPhone,
          showEditPersonalInfo: state.profilesettings.showEditPersonalInfo,
          showEditAddress: state.profilesettings.showEditAddress,
          editAddressInitialValues: state.profilesettings.editAddressInitialValues,
          showAddPhone: !state.profilesettings.showAddPhone,
          showAddAddress: state.profilesettings.showAddAddress
        }
      });
    case type.TOGGLE_PROFILE_ADD_ADDRESS:
      return Object.assign({}, state, {
        profilesettings: {
          showEditPhone: state.profilesettings.showEditPhone,
          showEditPersonalInfo: state.profilesettings.showEditPersonalInfo,
          showEditAddress: state.profilesettings.showEditAddress,
          editAddressInitialValues: {
            firstname :  "",
            lastname : "",
            companyName : "",
            address1 : "",
            address2 : "",
            address3 : "",
            city : "",
            state : "",
            zipcode : "",
          },
          showAddPhone: state.profilesettings.showAddPhone,
          showAddAddress: !state.profilesettings.showAddAddress
        }
      });
    case type.RESET_PROFILE_TOGGLE:
      return Object.assign({}, state, {
        profilesettings: {
          showEditPhone: false,
          showEditPersonalInfo: false,
          showEditAddress: false,
          editAddressInitialValues: state.profilesettings.editAddressInitialValues,
          showAddPhone: false,
          showAddAddress: false
        }
      });
    case type.PERSONAL_INFO_DATA_LOADED:
      return Object.assign({}, state, { personalInfoData: action.payload });

    case type.LOAD_PERSONAL_INFO_DATA_ASYNC_FAIL:
      return Object.assign({}, state, {
        error: action.error
      });
    default:
      return state;
  }
}

export default UserProfileReducer;