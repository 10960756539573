import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {Translation, Trans} from 'react-i18next';

import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import * as formValidate from '../../../shared/Form/FormValidate/FormValidate';
import FormInstructions from '../../../shared/Form/FormInstructions';
import Button from '../../../shared/Button/Button';
import { renderInputField } from '../../../shared/Form/FormFields/FormFields';
import {handleUserLogin} from '../../../shared/Utils/Utils';
function RegisterEmailForm(props) {

    const { handleSubmit, submitting, onEmailChange, isLoading, errorMessage } = props;
    return (
        <Translation>
            {(t) => (
                <form className="ac-email-form" onSubmit={handleSubmit}>
                    {errorMessage && <ErrorMessage icon={true} message={errorMessage} />}
                    <FormInstructions
                        instructions={[
                            { text: "LABEL_ACCOUNT_REGISTRATION_CREATE_ACCOUNT_SUBHEADER" }
                        ]} />                    

                    <Field
                        name="email"
                        component={renderInputField}
                        type="email"
                        onChange={onEmailChange}
                        label={t("LABEL_COMMON_EMAIL")}
                        required={true}
                        validate={[formValidate.required, formValidate.email]}
                        minLength={6} 
                        capitalized={true}
                        classes={'stat-input-field'}
                        data-dtm={'enter email'}/>

                    <Button
                        className="stat-button-link"
                        localeKey={t("LABEL_COMMON_NEXT")}
                        type="submit"
                        disabled={submitting}
                        isLoading={isLoading}
                        data-dtm={'enter email'}/>

                    <div className="q-text small-margin">
                        <span>{t("LABEL_ACCOUNT_REGISTRATION_ALREADY_HAVE_ACCOUNT")} </span>
                        <Trans  i18nKey="LABEL_ACCOUNT_REGISTRATION_ALREADY_HAVE_ACCT">
                            {/* Not hardcoded text - this is done for interpoliation */}
                            Sign in
                            <a className="ac-accessible-link-underline stat-input-text" onClick={() => handleUserLogin()} data-dtm={'enter-email'}>LOG IN</a>
                        </Trans>
                    </div>
                </form>
            )}
        </Translation>
    )
}

export default (reduxForm({
    form: 'RegisterEmailForm', // form name
    destroyOnUnmount: true, // preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // unregister fields on unmount
})(RegisterEmailForm))
