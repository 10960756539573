import axios from 'axios';
import AppSettings from '../../../core/AppSettings';


let wheelPackageBaseUrl = `/wcs/resources/store/${AppSettings.storeId}/productSearch/{seoUrl}?brand={make}`;

export const getAllWheelPackageData = (payload)=>{
    const seoUrl = payload.seoUrl;
    const make = payload.make;

    let wheelPackageUrl = wheelPackageBaseUrl.replace("{seoUrl}",seoUrl);
        wheelPackageUrl = wheelPackageUrl.replace("{make}",make);
     return axios.get(wheelPackageUrl).then(response=>response.data)
                                      .catch((error)=>{console.log("error",error);throw error});
};

