import React, {useReducer, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import GMWallet from "../../../shared/GMWallet/GMWallet";
import {GMWalletReducer, initialGMWalletState} from "../../../shared/GMWallet/GMWalletReducer";
import AppSettings from "../../../core/AppSettings";
import ErrorMessage from "../../../shared/ErrorMessage/ErrorMessage";
import {useTranslation} from "react-i18next";

//WALLET PAGE - NOT USED IN T3 - WILL NOT UPDATE WITH ADYEN CHANGES
const PaymentMethodAdyen = (props) => {
    const { registrationStatusFetched, registrationStatus, isSessionExpired, userData } = props.authData;
    const { t } = useTranslation();
    const cryptoObj = window.crypto || window.msCrypto;
    const randomNum = cryptoObj.getRandomValues(new Uint32Array(1));
    const orderID = new Date().valueOf() + 1 + randomNum[0];
    const [state, dispatch] = useReducer(GMWalletReducer, initialGMWalletState);
    const [walletErrorStatus, setWalletErrorStatus] = useState({
        error: false,
        errorMsg: ""
    });

   let params={totalAmount: "0", currency: AppSettings.currency, userId: userData.logonId, orderNo: orderID};

    useEffect(() => {
        //setting the wallet state before it renders the GMWallet
        dispatch({
            type: "SET_WALLET_CONDITIONS",
            isAuthUser: props.authData.registrationStatus !== "G",
            isCheckout: false
        });
    }, [props.authData]);
    useEffect(() => {
        // Get registration status and make sure session hasn't expired
        // If user is authenticated, get wallet data and initiate wallet widget
        if(registrationStatusFetched) {
            if (registrationStatus !== "G" && !isSessionExpired && userData.country === "US") {
                setWalletErrorStatus({error: false, errorMsg: ""});
            }
            // If user is a guest and their session isn't expired (they weren't logged in previously), send to home page
            else if (registrationStatus === "G" && !isSessionExpired) {
                window.location.href = "/";
            }
            // If user's session expires, display session timeout error
            else if (isSessionExpired) {
                setWalletErrorStatus({
                    error: true,
                    errorMsg: t("LABEL_AUTHENTICATION_COOKIE_EXPIRED_ERROR_MESSAGE")
                });
            } else {
                // Display error for users not registered in US
                if (userData.country && userData.country !== "US") {
                    setWalletErrorStatus({
                        error: true,
                        errorMsg: t("LABEL_WALLET_AVAILABLE_ONLY_IN_US_ERROR")
                    });
                }
                // Display generic error message
                else {
                    setWalletErrorStatus({
                        error: true,
                        errorMsg: t("LABEL_ERROR_BOUNDARY_GENERIC_ERROR")
                    });
                }
            }
        }
    }, [registrationStatusFetched, isSessionExpired]);

    return (
        <div id="paymentMethodsPageAdyen" className="ac-large-margintop large-margin">
            {walletErrorStatus.error && <ErrorMessage icon={true} message={walletErrorStatus.errorMsg} />}

            { !walletErrorStatus.error && registrationStatusFetched && !isSessionExpired && registrationStatus !== "G" &&
            <GMWallet state={state} dispatch={dispatch} isAuthUser params={params}/>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.Authentication,
    };
};

export default connect(mapStateToProps)(PaymentMethodAdyen);
