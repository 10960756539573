import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCopy, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Checks if a value if a function
 * @param {any} f value to check type of
 * @returns true if the value if a function
 */
const isFunc = (f) => typeof f === "function";

/**
 * Renders a single Cell object
 *
 * @param {{attributes?: object, children?: React.ReactChild}} props the parameters to use for this cell
 * @returns {React.ReactElement}
 */
export const Cell = ({ attributes = {}, children }) => <tc {...attributes}>{children}</tc>;

/**
 * Transforms a list of React props into a list of HTML attributes
 *
 * @typedef CellAttributeProps
 * @type {Object}
 * @property {string=} className a className to apply
 * @property {boolean=} isTouched boolean to represent if a input field has been modified
 *
 * @param {CellAttributeProps} props a set of properties to parse
 * @returns {Object<string, string>} a set of spreadable HTML attributes for use
 */
const getAttributesFromProps = (props) => ({
    className: `${props.className || ""} ${props.isTouched ? "touched" : ""}`
});

/**
 * Renders a mutable text cell
 * @param {{text: string, onEdit: Function} & CellAttributeProps} props arguments for this cell
 * @returns {React.ReactElement}
 */
export const InputCell = (props) => {
    const attr = getAttributesFromProps(props);

    const handleEntry = (e) => {
        props.onEdit(e.target.value);
    };

    return (
        <th className="vc-table-input-cell" attributes={attr}>
            <input onChange={handleEntry} value={props.text} spellCheck={false} />
        </th>
    );
};

/**
 * Renders a static text cell
 * @param {{text: string, wrapText: boolean} & CellAttributeProps} props arguments for this cell
 * @returns {React.ReactElement}
 */
export const TextCell = (props) => {
    const attr = getAttributesFromProps(props);
    attr.className = attr.className ? attr.className + " vc-text-cell" : "vc-text-cell";

    return (
        <th className="vc-table-key-cell" attributes={attr}>
            <span className={props.wrapText ? "wrap-text" : ""}>{props.text}</span>
        </th>
    );
};

/**
 * Renders the passed children with a top tooltip that holds the given text.
 * Requires a unique ID to be passed as a prop.
 *
 * @param {{id: string, text: string, children: React.ReactChild}} props arguments to build the tooltip
 * @returns {React.ReactElement}
 */
const WithTopTooltip = (props) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip className="vaultUITable" id={props.id}>
                    {props.text}
                </Tooltip>
            }
        >
            {props.children}
        </OverlayTrigger>
    );
};

/**
 * Renders a cell with action buttons for all functions passed in
 *
 * @typedef ActionsCellProps
 * @type {object}
 * @property {string} vaultKey the key that this action references
 * @property {Function=} onCopy the action to define a copy button
 * @property {Function=} onClear the action to define a clear button
 * @property {Function=} onDelete the action to define a delete button
 *
 * @param {ActionsCellProps & CellAttributeProps} props
 * @returns {React.ReactElement}
 */
export const ActionsCell = (props) => {
    const { vaultKey: key } = props;
    const attr = getAttributesFromProps(props);

    return (
        <tr className="vc-table-actions-cell" attributes={attr}>
            {isFunc(props.onCopy) && (
                <th
                    id={`buttonTooltip-COPY-${key}`}
                    class="d-inline-block"
                    data-toggle="tooltip"
                    title="Copy to clipboard in .profile format"
                >
                    <button className="vc-button-copy" onClick={props.onCopy}>
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </th>
            )}
            {isFunc(props.onClear) && (
                <th
                    id={`buttonTooltip-RESET-${key}`}
                    class="d-inline-block"
                    data-toggle="tooltip"
                    title="Reset to default value"
                >
                    <button className="vc-button-single-reset" onClick={props.onClear}>
                        <FontAwesomeIcon icon={faUndo} />
                    </button>
                </th>
            )}
            {isFunc(props.onDelete) && (
                <th
                    id={`buttonTooltip-DELETE-${key}`}
                    class="d-inline-block"
                    text="Remove this override from the set of changes"
                >
                    <button className="vc-delete-btn vc-button" onClick={props.onDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </th>
            )}
        </tr>
    );
};
