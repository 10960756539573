/** IMPORT STATEMENT **/
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { reset, setSubmitFailed, setSubmitSucceeded } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import AppSettings from '../../../core/AppSettings';
import store from "../../../core/Redux/Store";
import { getDealerData } from '../../../modules/App/Header/HeaderRedux/HeaderService';
import { LOAD_MINI_CART_DATA_ASYNC } from "../../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartActionType";
import { deliveryPageSwitchOrgFlag,zeroCheckoutAmount } from '../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator';
import {
  showApplyCouponBtnSpinner,
  showSummaryRailSpinner
} from '../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator';
import {
  cartQuantityShowSpinner,
  cartRemoveShowSpinner,
  loadOrderInfoAsync,
  loadOrderInfoDataAsync,
  loadOrderInfoDataAsyncFail,
  loadOrderItemChangeQuantityResponseFail,
  loadOrderItemDeleteResponseFail,
  loadShippingRatesAsync,
  loadShippingRatesAsyncFail,
  loadSwitchOrgAsyncFailed,
  miniCartRemoveShowSpinner
} from './OrderInfoCreator';
import {
  callSwitchOrg,
  getCartResponse,
  getOrderItemChangeQuantityResponse,
  getOrderItemDeleteResponse,
  getShippingRates
} from './OrderInfoService';
import {checkPartialAuthenticationError} from "../../Utils/Utils";
import {
  removeItemPotentialRewardsPoints,
  updateItemPotentialRewardsPoints
} from "../../Rewards/RewardsRedux/RewardsActionCreator";
import { cartCalculate } from '../../../modules/Cart/Redux/CartService';

export const getOrderId = state => state.OrderInfoReducer;
export const isRewardsActive = state => state.LoyaltyReducer.isRewardsActive;


/** WORKER TO CALL GET CART RESPONSE AND DISPATCH A FUNCTION */
export function* getOrderInfo() {
  const isUserSignedIn = store.getState().Authentication.registrationStatus != 'G';
  try {
    let parsedResponse = {};
    if (AppSettings.pageName == "Cart") {
      yield cartCalculate();
    }
    const res = yield call(getCartResponse);
    if (res.order) {
      parsedResponse = parseCartResponse(res);

      const totalOfOrder = parseFloat(parsedResponse.orderInfo.total);

      const isZeroCheckout = totalOfOrder === 0;


      yield put (zeroCheckoutAmount(isZeroCheckout));

      yield put(loadOrderInfoDataAsync(res, parsedResponse));
      yield put({ type: LOAD_MINI_CART_DATA_ASYNC });
      yield put(showSummaryRailSpinner(false));
      yield put(showApplyCouponBtnSpinner(false));

      /* Calling the Switch Org on Delivery Page for T1 Order only */
      const isDeliveryPage = store.getState().CheckoutReducer.currentStep;
      const switchOrgFlag = store.getState().CheckoutReducer.switchOrgFlag;
      if (!AppSettings.isT3 && isDeliveryPage === 2 && switchOrgFlag) {
        yield switchOrgOnT1Delivery(parsedResponse.dealer.activeOrgId)
        yield put(deliveryPageSwitchOrgFlag());
      }
    } else if (res && Object.keys(res).length === 0) {
      // getCartData will have an empty response now when cart is empty, this is to handle that change
      const parsedResponse = {
        orderId: null,
        cost: 0,
        orderInfo: null,
        customerInfo: null,
        vehicles: [],
        shipping: null,
        dealer: null
      }
      yield put(loadOrderInfoDataAsync(res, parsedResponse));
      yield put({ type: LOAD_MINI_CART_DATA_ASYNC });
    }

  }
  catch (error) {
    yield put(loadOrderInfoDataAsyncFail(error));
    const authentication401Message = 'Error: Request failed with status code 401';
    if (error === authentication401Message && isUserSignedIn && (AppSettings.pageName === 'Checkout' || AppSettings.pageName === 'Cart')) {
      yield checkPartialAuthenticationError();
   }
  }
}



/** FUNCTION CALLS WHEN QTY CHANGE **/
export function* orderItemChangeQuantity(action) {
  yield put(cartQuantityShowSpinner(true, action.payload.lineItemId));
  let addFromCartQueryParam = true;
  let payload;
  try {
    //laborOrderItemId returns undefined if delivery method option IS NOT dealer install
    if (action.payload.laborOrderItemId) {
    payload = { x_action: "change_quantity",
      orderId:action.payload.orderId,
      orderItem: [{ "orderItemId": action.payload.lineItemId, "quantity": action.payload.qty.toString() },{ "orderItemId": action.payload.laborOrderItemId, "quantity": action.payload.qty.toString() }] };
    } else {
    payload = { x_action: "change_quantity",
      orderId:action.payload.orderId,
      orderItem: [{ "orderItemId": action.payload.lineItemId, "quantity": action.payload.qty.toString() }] };
    }
    /** CHANGE PAYLOAD FOR T3 ORDER */
    if (AppSettings.isT3) {
      addFromCartQueryParam = false;
      payload = { x_action: "change_quantity",
        orderId:action.payload.orderId,
        "x_bac": AppSettings.bac, orderItem: [{ "orderItemId": action.payload.lineItemId, "quantity": action.payload.qty.toString() }] };
    }

    const res = yield call(getOrderItemChangeQuantityResponse, payload, addFromCartQueryParam);
    if (!res || !res.data || res.status !== 200) {
      yield put(reset('cartQuantityForm-' + action.payload.lineItemId));
      yield put(setSubmitFailed('cartQuantityForm-' + action.payload.lineItemId));
      yield put(loadOrderItemChangeQuantityResponseFail(''));
    } else {
      yield put(updateItemPotentialRewardsPoints(action.payload.lineItemId, action.payload.qty));
      yield put(setSubmitSucceeded('cartQuantityForm-' + action.payload.lineItemId));
      yield put(loadOrderInfoAsync());
    }
  }
  catch (error) {
    yield put(reset('cartQuantityForm-' + action.payload.lineItemId));
    yield put(setSubmitFailed('cartQuantityForm-' + action.payload.lineItemId));
    yield put(loadOrderItemChangeQuantityResponseFail(error));
  }
  yield put(cartQuantityShowSpinner(false, action.payload.lineItemId));

}

/** FUNCTION CALLS WHEN ITEM REMOVE FROM CART **/
export function* orderItemRemove(action) {
  // If we are getting information from shopping cart call we can just use the orderItemIds array
  let orderItemIds = [];
  let lastIndex;
  if (action.payload.orderItemIds[0]) {
    orderItemIds = action?.payload?.orderItemIds
    lastIndex = orderItemIds.length - 1; //need to pull the index of the last item to check for spinner
    yield put(cartRemoveShowSpinner(true, orderItemIds[lastIndex].orderItemId));
  } else { // If getting the data from mini cart we have to split up the original orderItemIds object and insert it into a new array
    orderItemIds.push(action?.payload?.orderItemIds);
    if (orderItemIds[0].laborItemOrderId) {
      const laborItem = {"orderItemId":orderItemIds[0].laborItemOrderId, "orderItemType":"Installed"}
      orderItemIds.push(laborItem)
    }
    lastIndex = orderItemIds.length - 1; //need to pull the index of the last item to check for spinner
    yield put(miniCartRemoveShowSpinner(true, orderItemIds[lastIndex]?.orderItemId));

  }
  let addFromCartQueryParam = true;
  // If the item needs Installation - Need to make sure that the order that it is removed is Labor item -> Part Item
  if (!AppSettings.isT3 && orderItemIds.length > 1) {
    if (orderItemIds[1].orderItemType == "Installed") { //Switching order of the array if the first item is the item itself and not install
      let tempItem = orderItemIds[1];
      orderItemIds[1] = orderItemIds[0];
      orderItemIds[0] = tempItem;
    }
  };

  try {
    for(let item of orderItemIds){
        let payload = {
            orderItemId: item.orderItemId
        };
        /** CHANGE PAYLOAD FOR T3 ORDER */
        if (AppSettings.isT3) {
            addFromCartQueryParam = false;
            payload = {
                orderItemId: item.orderItemId,
                x_bac: AppSettings.bac
            };
        }
        yield call(getOrderItemDeleteResponse, payload, addFromCartQueryParam);
        yield put(removeItemPotentialRewardsPoints(item.orderItemId));
    }
    yield put(loadOrderInfoAsync());
  }
  catch (error) {
    yield put(loadOrderItemDeleteResponseFail(error));
  }
  finally {
    yield put(miniCartRemoveShowSpinner(false, orderItemIds[lastIndex]?.orderItemId));
    yield put(cartRemoveShowSpinner(false, orderItemIds[lastIndex].orderItemId));
  }
}

/** SWITCH ORG WORKER FOR T3 ORDERS **/
export function* getDataInfo() {
  try {
    yield put(loadOrderInfoAsync());
  }
  catch(error){
    yield loadOrderInfoDataAsyncFail(error);
  }
}

/** WORKER TO GET SHIPPING RATES AND DISPATCH ACTION **/
export function* getShippingRateData() {
  try {
    let shipRatesFormat = {};
    const orderInfo = yield select(getOrderId);
    const res = yield call(getShippingRates, orderInfo.parsedResponse.orderId);
    if (res) {
      shipRatesFormat = formatShippingRates(res);
      yield put(loadShippingRatesAsync(shipRatesFormat, res.FedexRateError));
    }
    else {
      yield put(loadShippingRatesAsyncFail('shipping rates error'));
    }


  }
  catch (error) {
    yield put(loadShippingRatesAsyncFail(error));
  }
}

/** FUNCTION TO PARSE CART RESPONSE */
export function parseCartResponse(response) {

  let parsedCartResponse = {
    cost: '',
    customerInfo: '',
    vehicles: ''
  };
  if (response.order) {
    let order = response.order;
    parsedCartResponse = {
      orderId: order.orderInfo.orderId,
      cost: parseCost(order),
      orderInfo: order.orderInfo,
      customerInfo: order.customerInfo,
      vehicles: parseVehicles(order),
      shipping: Object.assign(order.shipping, { shipAddressId: order.shipAddressId }),
      dealer: order.dealer
    }
  }

  return parsedCartResponse;
}

/*** FUNCTION TO FORMAT SHIPPING RATES **/
export function formatShippingRates(response) {

  let orderItemsResult = [];

  if (response.orderItems) {
    let orderItems = response.orderItems;
    let currency = response.currency;
    orderItems.forEach((orderItem) => {
      let itemId = orderItem.orderItemId;
      let shipChargesByShipMode = parseShipCharges(orderItem.shipChargesByShipMode, currency);

      let orderItemPush = { orderItemId: itemId, shipChargesByShipMode: shipChargesByShipMode };
      orderItemsResult.push(orderItemPush);
    });
  }

  return orderItemsResult;
}

/** FUNCTION TO PARSE COST **/
function parseCost(order) {

  const orderInCart = order || {};
  const orderInfo = orderInCart.orderInfo || {};
  let currency = 'USD';
  if (orderInfo.currency) {
    currency = orderInfo.currency;
  }
  const lang = 'en-EN';

 let formattedAmounts = {
    totalSubtotal: formatCurrency(orderInfo.subtotal, lang, currency),
    recordSetTotal: orderInfo.recordSetTotal,
    installation: formatCurrency(orderInfo.installation, lang, currency),
    total: formatCurrency(orderInfo.total, lang, currency),
    shipping: formatCurrency(orderInfo.fedEx, lang, currency),
    totalShippingTax: formatCurrency(orderInfo.totalShippingTax, lang, currency),
    totalAdjustment: formatCurrency(orderInfo.totalAdjustment, lang, currency),
    totalSalesTax: formatCurrency(orderInfo.totalSalesTax, lang, currency),
    totalTax: formatCurrency(orderInfo.totalTax, lang, currency),
    adiTotalCost: formatCurrency(orderInfo.adiTotalCost, lang, currency)
  };

  if (orderInfo.adjustment.length > 0) {
    let couponData = formatCouponData(orderInfo.adjustment, orderInfo.subtotal, lang, currency);
    formattedAmounts.coupons = couponData;
  }
  if (orderInfo.notAdjustedPromotions && orderInfo.notAdjustedPromotions.length > 0) {
    let notAdjustedPromotions = formatNotAdjustedCoupons(orderInfo.notAdjustedPromotions);
    formattedAmounts.notAdjustedPromotions = notAdjustedPromotions;
  }
  return formattedAmounts;

}

/** FUNCTION TO PARSE SHIPPING CHARGE **/
function parseShipCharges(shipCharges, curr) {
  let currency = curr;
  let lang = "en-EN";
  let shipChargesResult = [];

  shipCharges.forEach((charge) => {
    let chargeResult = "";

    if (charge.rate === "0.00") {
      chargeResult = { description: charge.description, carrierCode: charge.carrierCode, shipModeId: charge.id };
    } else {
      let rate = new Intl.NumberFormat(lang, { style: 'currency', currency: currency }).format(Number(charge.rate));
      chargeResult = { description: charge.description + " " + rate, carrierCode: charge.carrierCode, shipModeId: charge.id };
    }

    shipChargesResult.push(chargeResult);
  });

  shipChargesResult = shipChargesResult.sort(function (a, b) {
    if (a.carrierCode === 'SELECT') {
      return -1;
    }
    if (b.carrierCode === 'SELECT') {
      return 1;
    }

    return a.shipModeId.localeCompare(b.shipModeId);
  })

  return shipChargesResult;
}

/** FUNCTION TO FORMAT CURRENCY **/
function formatCurrency(amount, lang, currency) {
  if (!amount || amount === null || amount === "0") {
    amount = '';
  } else {
    amount = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency
    }).format(Number(amount));
  }
  return amount;
}


function formatCouponData(couponData, totalSubtotal, lang, currency) {
  let coupons = {
    manufacturer_list: [],
    dealer_list: [],
    shipping_list: [],
    familyFirst_list: []
  }
  if (couponData.length > 0) {
    couponData.forEach(function (fee) {
      if (fee.usage === 'Discount' && fee.promotionType === 'GM') {
        coupons.manufacturer_list.push({ "manufactureCoupon": fee.amount, "manufacturePromotionCode": fee.promotionCode, "manufacturePromotionId": fee.promotionId });
      }
      else if (fee.usage === 'Discount' && fee.promotionType === 'DEALER') {
        coupons.dealer_list.push({ "dealerCoupon": fee.amount, "dealerPromotionCode": fee.promotionCode, "dealerPromotionId": fee.promotionId });
      }
      else if (fee.promotionType === 'SHP') {
        coupons.shipping_list.push({ "shippingCoupon": formatCurrency(fee.amount, lang, currency), "shippingPromotionCode": fee.promotionCode, "shippingPromotionId": fee.promotionId });
      }
      else if (fee.promotionType === 'FF') {
        coupons.familyFirst_list.push({ "familyFirstCoupon": fee.amount, "familyFirstPromotionCode": fee.promotionCode, "familyFirstPromotionId": fee.promotionId });
      }
    });
  }
  if (coupons.manufacturer_list.length > 0 || coupons.familyFirst_list.length > 0 || coupons.dealer_list.length > 0) {
    let subtotal = Number(parseFloat(totalSubtotal).toFixed(2));
    if (coupons.manufacturer_list.length > 0) {
      coupons.manufacturer_list.forEach(function (coup) {
        subtotal = subtotal + coup.manufactureCoupon;
      });
      coupons.afterManufacturer = formatCurrency(subtotal, lang, currency);
      coupons.manufacturer_list.forEach(function (coup) {
        coup.manufactureCoupon = new Intl.NumberFormat(lang, {
          style: 'currency',
          currency: currency
        }).format(Number(coup.manufactureCoupon));
      })
    }
    else if (coupons.familyFirst_list.length > 0) {
      coupons.familyFirst_list.forEach(function (coup) {
        subtotal = subtotal + coup.familyFirstCoupon;
      });
      coupons.afterFamilyFirst = formatCurrency(subtotal, lang, currency);
      coupons.familyFirst_list.forEach(function (coup) {
        coup.familyFirstCoupon = new Intl.NumberFormat(lang, {
          style: 'currency',
          currency: currency
        }).format(Number(coup.familyFirstCoupon));
      })
    }
    if (coupons.dealer_list.length > 0) {
      coupons.dealer_list.forEach(function (coup) {
        subtotal = subtotal + coup.dealerCoupon;
      });
      coupons.afterDealer = formatCurrency(subtotal, lang, currency);
      coupons.dealer_list.forEach(function (coup) {
        coup.dealerCoupon = new Intl.NumberFormat(lang, {
          style: 'currency',
          currency: currency
        }).format(Number(coup.dealerCoupon));
      })
    }
  }
  return coupons;
}

/** FUNCTION TO PARSE VEHICLE **/
function parseVehicles(order) {
  let vehicles = {
    orderItems: [],
    vehiclesInfo: {}
  };
  if (order.vehicles) {

    vehicles = order.vehicles.map(function (x) {

      return {
        orderItems: x.orderItems,
        vehiclesInfo: Object.assign(x.vehiclesInfo, { vin: x.vinNumber })
      }

    });

  }
  return vehicles;

}


/** Switch org call on Delivery Page, this is needed to make installation work properly **/
function* switchOrgOnT1Delivery(orgId) {
  const payload = {
    "URL": "/",
    "activeOrgId": orgId
  };
  yield call(callSwitchOrg, payload)


}

function formatNotAdjustedCoupons(notAdjustedPromotions) {
  let notAdjustedCouponsObject = {
    notAdjustedCoupons: [],
    fullCouponErrorMessage: ''
  };
  let notAdjustedCouponCodes = [];
  let standardMessage = '';
  let couponCodesList = '';
  let addComma = false;

  notAdjustedPromotions.length > 0 && notAdjustedPromotions.map (function (x) {
    //to push potentially multiple non Adjusted Promotions
    x.couponCode && notAdjustedCouponCodes.push(x.couponCode);

    if(x.Message){
      standardMessage = x.Message;
    }
    // if only one coupon, then we dont want to add a comma
    if(x.couponCode && !addComma){
      couponCodesList += x.couponCode;
      addComma = true;
    }
    //if multiple coupons, we want to add a comma in the message
    else if(x.couponCode && addComma){
      couponCodesList += ', ' + x.couponCode;
    }

    notAdjustedCouponsObject = {
      notAdjustedCoupons: notAdjustedCouponCodes,
      fullCouponErrorMessage: standardMessage + couponCodesList
    }
  });

  return notAdjustedCouponsObject;
}
