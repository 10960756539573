import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import AppSettings from '../../../core/AppSettings';

class EmptyCart extends Component {

    constructor(props) {
        super(props);
    }

   

    render() {
        const isT3 = AppSettings.isT3;

        let homeURL = "/";

        if(isT3) {
            homeURL = (AppSettings.dealerName + '?bac=' + AppSettings.bac);
        }
        return (
            <Translation>{(t) => (
                <div className="ac-cart row q-gridbuilder small-margin ac-xlarge-margintop ac-nopadding-x">
                    <div className="ac-empty-cart small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns ac-nopadding-x">
                        <div className="ac-empty-cart-text">
                            <span className="q-text q-body1">{t("LABEL_CHECKOUT_ZERO_ITEMS")}</span>
                            <div className="ac-empty-cart-continue small-12 medium-12 large-3 xlarge-3 grid-column-alignment-center columns">
                                <a className="q-button q-primary-button gm-primary-btn" href={homeURL}>{t("LABEL_CHECKOUT_CONTINUE_SHOPPING")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}</Translation>
        );
    }
}

export default EmptyCart;
