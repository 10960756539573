import React from "react";
import { Card } from "react-bootstrap";
import Proptypes from "prop-types";
import { useTranslation } from "react-i18next";
import { buildSearchResultsURL } from "../HelperFunctions";
import AppSettings from "../../../../core/AppSettings";
import { Link } from "react-router-dom";

/**
 * Used to display the card for each subcategory on the Category Landing Page.
 * Displays an image, the subcategory name, and the count of items within that subcategory
 * @param props
 * count: String - The number of items within the subcategory
 * label: String - The name of the subcategory
 * parentUniqueID: String - The uniqueID number of the subcategory parent. Will likely be removed in future
 * uniqueID: String - The uniqueID of the subcategory being displayed
 * imageUrl: String - The url for the image of subcategory on DAM
 * fitmentInfo: Object - Fitment information from URL, used to build the URL to redirect to the PSR for a subcategory
 * subcategoryImages: Object. Contains map from subcategory uniqueIds to their respective image urls
 * @return {JSX.Element}
 * @constructor
 */
const SubcategoryCard = (props) => {
    const { t } = useTranslation();
    const { count, label, uniqueID, fitmentInfo, subcategoryImages, mainCategory } = props;
    const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";
    const imgSource = (subcategoryImages[uniqueID] ? subcategoryImages[uniqueID] + "/640X640" : null) || imgNotFound;
    const itemOrItems = count === "1" ? t("LABEL_COMMON_ITEM") : t("LABEL_COMMON_ITEMS");
    const searchResultsUrl = fitmentInfo ? buildSearchResultsURL(fitmentInfo, uniqueID) : "";

    return (
        <Card
            className="category-card stat-image-link"
            data-dtm={"results:" + mainCategory.replace("&", "and").replace("/", "-").trim().toLowerCase()}
        >
            <Link
                className="card-image-link-container"
                to={searchResultsUrl}
                id={`category-card-image-link-${label.toLowerCase()}`}
            >
                <img
                    className="card-image"
                    alt={label.toLowerCase().replace("&", "and")}
                    src={imgSource}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = imgNotFound;
                    }}
                />
            </Link>
            <Card.Body>
                <Link to={searchResultsUrl} id={`category-card-body-link-${label.toLowerCase()}`}>
                    <Card.Title className="category-card-title card-link q-vehicle-info-text q-body1">
                        {label}
                    </Card.Title>
                    <Card.Text className="category-card-text card-link">
                        {t("LABEL_COMMON_VIEW") + " " + count + " " + itemOrItems}
                    </Card.Text>
                </Link>
            </Card.Body>
        </Card>
    );
};

SubcategoryCard.propTypes = {
    count: Proptypes.string.isRequired,
    label: Proptypes.string.isRequired,
    parentUniqueID: Proptypes.string,
    uniqueID: Proptypes.string.isRequired,
    imageUrl: Proptypes.string,
    fitmentInfo: Proptypes.object,
    subcategoryImages: Proptypes.object,
    mainCategory: Proptypes.string.isRequired
};

export default SubcategoryCard;
