import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import store from '../../../core/Redux/Store';
import Spinner from '../../../shared/Spinner/Spinner';
import { getWalletData } from '../../../shared/Wallet/WalletRedux/WalletActionCreator';
import WalletWidget from '../../../shared/Wallet/WalletWidget';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';


const PaymentMethod = (props) => {

  const { registrationStatusFetched, registrationStatus, isSessionExpired, userData } = props.authData;
  const { error, walletLoadError } = props.walletData;

  // Stores wallet initialization state:
  // Used to store status of auth and GPMS calls (loading/successful/error out)
  // to determine when to initalize the wallet widget
  const [walletInitStatus, setWalletInitStatus] = useState({
    isLoading: false,
    showWallet: false,
    error: false,
    errorMsg: ''
  });

  useEffect(() => {
    // Get registration status and make sure session hasn't expired
    // If user is authenticated, get wallet data and initiate wallet widget
    if (registrationStatusFetched && registrationStatus != 'G' && !isSessionExpired && userData.country === 'US') {
      store.dispatch(getWalletData());
      // If wallet data is returned successfully display the widget
      if (!error) {
        setWalletInitStatus({ isLoading: false, showWallet: true, error: false });
      }
      // If wallet data returns errors, display error message
      else {
        setWalletInitStatus({ isLoading: false, showWallet: false, error: true, errorMsg: walletLoadError ? i18next.t('LABEL_WALLET_NOT_AVAILABLE_ERROR') : i18next.t('LABEL_ERROR_BOUNDARY_GENERIC_ERROR') });
      }
    }
    // While registration status service call is fetching, show spinner
    else if (!registrationStatusFetched) {
      setWalletInitStatus({ isLoading: true, showWallet: false, error: false });
    }
    // If user is a guest and their session isn't expired (they weren't logged in previously), send to home page
    else if (registrationStatus == 'G' && !isSessionExpired) {
      window.location.href = '/';
    }
    // If user's session expires, display session timeout error
    else if (isSessionExpired) {
      setWalletInitStatus({ isLoading: false, showWallet: false, error: true, errorMsg: i18next.t('LABEL_AUTHENTICATION_COOKIE_EXPIRED_ERROR_MESSAGE') });
    }
    else {
      // Display error for users not registered in US
      if (userData.country !== 'US') {
        setWalletInitStatus({ isLoading: false, showWallet: false, error: true, errorMsg: i18next.t('LABEL_WALLET_AVAILABLE_ONLY_IN_US_ERROR') });
      }
      // Display generic error message
      else {
        setWalletInitStatus({ isLoading: false, showWallet: false, error: true, errorMsg: i18next.t('LABEL_ERROR_BOUNDARY_GENERIC_ERROR') });
      }
    }
  }, [registrationStatusFetched, isSessionExpired, walletLoadError]);


  return (
    <div id="paymentMethodsPage" className="ac-large-margintop large-margin">
      {/* Spinner */}
      {walletInitStatus.isLoading && <Spinner className="q-loader" />}

      {/* Error Message */}
      {walletInitStatus.error && <ErrorMessage icon={true} message={walletInitStatus.errorMsg} />}

      {/* Wallet Widget */}
      {walletInitStatus.showWallet && <WalletWidget />}
    </div>
  );

}

const mapStateToProps = state => {
  return {
    authData: state.Authentication,
    walletData: state.WalletReducer
  }
}

export default connect(mapStateToProps)(PaymentMethod);