import * as type from './SimilarItemsActionType';

const initialState = {
    similarItems: []
}

const SimilarItemsReducer = (state = initialState, action) => {
  
  switch (action.type) {
      case type.SET_SIMILAR_ITEMS_DATA:
        return Object.assign({}, state, { similarItems: action.data }, action.payload);

      case type.SET_SIMILAR_ITEMS_DATA_FAIL:
        let error = action.error;
        return Object.assign({}, state, { similarItems: [] });
  
      default:
        return state;
    }
  }
  
  export default SimilarItemsReducer;