import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AppSettings from "../../core/AppSettings";
import { LinkWrapper } from '../Utils/Utils';
import ViewOnVehicle from "../ViewOnVehicle/ViewOnVehicle";
import WheelPackage from "../WheelPackage/WheelPackage";

class ProductCard extends Component {

    render() {
        let showWheelPackage = false;
        if (this.props.catalogEntryTypeCode === 'PackageBean') {
            showWheelPackage = true;
        }

        const aTag = this.props.aTag;

        const newVoVData = {
            productName: this.props.productName || '',
            productDetailsLink: this.props.productLink || '',
            installPrice: this.props.installPrice,
            productPrice: this.props.productPrice,
            partsRPO: this.props.partsRPO,
            colorCode: this.props.colorCode
        };

        return (
            <div className="small-6 medium-6 large-6 ac-desktop xlarge-3 grid-column-alignment-left columns ac-product-card-wrapper" data-dtm={`${this.props.productName}`} >
                <div className={AppSettings.isT3 ? 'ac-card-wrapper ac-dealer-product-card' : 'ac-card-wrapper'} data-dtm={`${this.props.productName}`}>
                    {/* Product Image */}
                    <LinkWrapper route={this.props.productLink} classNames="q-responsive-image q-responsive-image-container" aTag={aTag}>
                        <img src={this.props.productImg + this.props.productImgSize} alt={this.props.productName} onError={(e) => { e.target.onerror = null; e.target.src = this.props.imgNotFound }} className="stat-image-link" data-dtm={`${this.props.productName}`} />
                    </LinkWrapper>
                    {/* Product Name */}
                    <div className="q-vehicle-info-text stat-image-link" data-dtm={`${this.props.productName}`}>
                        <LinkWrapper route={this.props.productLink} aTag={aTag} data-dtm={`${this.props.productName}`}>
                            <div className="q-margin-base ac-card-top-text" data-dtm={`${this.props.productName}`}>
                                <div className="q-text q-body1" data-dtm={`${this.props.productName}`}>
                                    <p data-dtm={`${this.props.productName}`}>
                                        {this.props.productName} 
                                    </p>
                                </div>
                            </div>
                        </LinkWrapper>
                        {/** Wheel Package ***/}
                        <div className="ac-wheelPack" >
                            {
                                showWheelPackage && <WheelPackage seoUrl={this.props.seoUrl} make={this.props.make} cssName="ac-fixedOverlay" />
                            }
                        </div>
                        <div className="q-text q-body2">
                            <span className="q-text q-body2">
                                <span className="show-disclosure q-mod q-mod-rte-disclosure q-rte-disclosure">
                                    <span className="q-bold2">{this.props.modalLink}</span>
                                </span>
                            </span>
                        </div>

                        {/* Product Price */}
                        <div className="columns ac-nopadding-x small-12 medium-6 ac-prices-wrapper" data-dtm={`${this.props.productName}`}>
                            <div className={'q-vehicle-info-text-price ac-product-price-wrapper'}>
                                <div className="q-mod q-mod-regional-text-price q-vehicle-info-text-price">
                                    <h6 className="q-text q-body2">
                                        <span className="q-bold2">{this.props.productPriceLabel}&nbsp;{this.props.productPrice}</span>
                                        <sup>
                                            <span className="q-mod q-mod-rte-disclosure q-bold2 q-rte-disclosure">
                                                <span className="q-disclosure-print-hide">{this.props.productPriceAsterisk}</span>
                                            </span>
                                        </sup>
                                    </h6>
                                </div>
                            </div>
                            {/* Text below price (i.e. dealer price disclosure) */}
                            <div className="q-text q-body2 ac-price-disclosure-wrapper">
                                <span className="q-text q-body2">
                                    <span className="q-mod q-mod-rte-disclosure q-rte-disclosure">
                                        <span className={this.props.installPrice ? 'ac-price-disclosure' : 'ac-price-disclosure-disclaimer'}>{this.props.productInstallPriceLabel}{this.props.installPrice ? this.props.installPrice : this.props.productPriceDisclosure}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* View on Vehicle Modal */}
                        <div className="ac-viewOnVehicle columns ac-nopadding-x small-12 medium-6">
                            {this.props.vovIcon && <ViewOnVehicle data={newVoVData} />}
                        </div>
                    </div>
                    <div>
                        {/* Note: using <a/> tag instead of <Link/> 
                            because we need a page refresh when this
                            is used on the product details page for similar items
                            https://stackoverflow.com/questions/43087007/react-link-vs-a-tag-and-arrow-function
                        */}
                        <LinkWrapper route={this.props.productLink} aTag = {aTag}>
                            <button
                                className="gm-primary-btn q-mod q-mod-button q-button q-primary-button q-button-small q-button-padding-none medium-margin ac-small-margintop stat-button-link"
                                data-dtm={`${this.props.productName}`}
                                type="button">
                                <span className="move-text">
                                    {this.props.buttonLabel}<br />
                                </span>
                            </button>
                        </LinkWrapper>
                    </div>
                </div>
            </div>
        )
    }

}

ProductCard.propTypes = {
    productLink: propTypes.string,
    categoryName: propTypes.string,
    subcategoryName: propTypes.string,
    seoUrl: propTypes.string,
    productImg: propTypes.string,
    imgNotFound: propTypes.string,
    productImgSize: propTypes.string,
    productName: propTypes.string,
    modalLink: propTypes.string,
    productPriceLabel: propTypes.string,
    productPrice: propTypes.string,
    productPriceAsterisk: propTypes.string,
    productPriceDisclosure: propTypes.string,
    buttonLabel: propTypes.string
}

export default ProductCard;