import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect,useState} from "react";
import { Field, reduxForm } from "redux-form";
import { normalizePhone, normalizePostalCode } from "../../../../shared/Form/FormNormalize/FormNormalize";
import * as options from "../../../../shared/Form/FormOptions/FormOptions";
import * as validate from "../../../../shared/Form/FormValidate/FormValidate";
import AppSettings from "../../../../core/AppSettings";
import CheckoutPageButtons from "../../../../shared/CheckoutPageButtons/CheckoutPageButtons";
import { withTranslation } from "react-i18next";
import i18n from "../../../../core/i18n/i18n";
import vaultConstants from "../../../../../config/vault_constants";

const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? "gmit-gf-text-field error" : "gmit-gf-text-field"}>
            {required == true && <label className="gmit-gf-label-text required">{label}</label>}
            {required != true && <label className="gmit-gf-label-text">{label}</label>}
            <input
                {...input}
                placeholder={label}
                type={type}
                className="stat-input-field"
                data-dtm="checkout step4:contact information"
                data-dtm2="exclude"
                {...otherProps}
            />
            {touched &&
                ((error && (
                    <label className="error ac-error-label">
                        <FontAwesomeIcon icon="exclamation-circle" />
                        {error}
                    </label>
                )) ||
                    (warning && <span>{warning}</span>))}
        </div>
    </div>
);

const renderSelect = ({ input, label, required, meta: { touched, error, warning }, children }) => (
    <div className="gmit-gf-dropdown">
        {required == true && <label className="gmit-gf-label-text required">{label}</label>}
        {required != true && <label className="gmit-gf-label-text">{label}</label>}
        <div className={touched && error ? "error" : ""}>
            <select
                {...input}
                className="q-dropdown q-form-dropdown stat-dropdown"
                id={label.replace(/ /g, "-") + "-dropdown"}
                data-dtm="checkout step1"
                data-dtm2="exclude"
            >
                {children}
            </select>
            {touched &&
                ((error && (
                    <label className="error ac-error-label">
                        <FontAwesomeIcon icon="exclamation-circle" />
                        {error}
                    </label>
                )) ||
                    (warning && <span>{warning}</span>))}
        </div>
    </div>
);

const renderShippingAddressForm = (stateList, addressLineTwoShow, setAddressLineShow, hideAndExpandFeatureFlag) => (
    <div>
        <div className="row">
            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                <Field
                    name="address1"
                    type="text"
                    component={renderField}
                    label={i18n.t("LABEL_CHECKOUT_ADDRESS_LINE1")}
                    required={true}
                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                    maxLength={64}
                    minLength={2}
                />
            </div>
        </div>
        <div className="row">
                {hideAndExpandFeatureFlag && !addressLineTwoShow ? 
                    <span className="grid-column-alignment-left columns hideandexpand" onClick={setAddressLineShow}>{i18n.t("LABEL_COMMON_HIDE_ADDRESS_LINE_TWO")}</span> :
                        <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                            <Field
                                name="address2"
                                type="text"
                                component={renderField}
                                label={i18n.t("LABEL_CHECKOUT_ADDRESS_LINE2")}
                                validate={[validate.minLength2, validate.maxLength64]}
                                maxLength={64}
                                minLength={2}
                            />
                        </div>
                }
        </div>
        <div className="row">
            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                <Field
                    name="city"
                    type="text"
                    component={renderField}
                    label={i18n.t("LABEL_COMMON_CITY")}
                    required={true}
                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                    maxLength={64}
                    minLength={2}
                />
            </div>
        </div>
        <div className="row">
            <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                <Field
                    name="state"
                    component={renderSelect}
                    label={i18n.t("LABEL_COMMON_STATE")}
                    required={true}
                    validate={[validate.required]}
                >
                    <option hidden value="">
                        State
                    </option>
                    {stateList.map((stateOption) => (
                        <option value={stateOption} key={stateOption}>
                            {stateOption}
                        </option>
                    ))}
                </Field>
            </div>
        </div>
        <div className="row">
            <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                <Field
                    name="postalCode"
                    component={renderField}
                    type="text"
                    label={i18n.t("LABEL_COMMON_ZIP")}
                    required={true}
                    validate={[validate.required, validate.postalCode]}
                    normalize={normalizePostalCode}
                />
            </div>
        </div>
    </div>
);

const ShippingContactForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, stateList, initialValues, submitFailed, triggerSubmit } = props;
    const [companyNameShow, setCompanyNameShow] = useState(false);
    const [addressLineTwoShow, setAddressLineShow] = useState(false);
    //scroll to location on submit error
    useEffect(() => {
        const errorFields = document.getElementsByClassName("error ac-error-label");
        if(initialValues.companyName){
            setCompanyNameShow(true);
        }
        if (initialValues.address2){
            setAddressLineShow(true);
        }
        if (submitFailed && errorFields.length) {
            //just grab the first one if there's multiple
            const location = errorFields[0].getBoundingClientRect().top;

            /*calculate position to scroll to based on a) error element location b) current scroll location and
             extra 400 padding to put the element in the middle of the screen*/
            window.scrollTo(0, location + window.pageYOffset - 400);
        }
    }, [triggerSubmit]);
    
    

    const hideAndExpandFeatureFlag = AppSettings.isLocalHost
            ? true
            : String(vaultConstants.FF_2258116_HIDE_SHOW_COMPANY) ==="true";
    
      return (
        <form className="ac-shipping-contact-form" onSubmit={handleSubmit}>
            <div className="gmit-gf-groupbox-content">
                <div className="row">
                    <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                        <Field
                            name="firstName"
                            type="text"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_FIRST_NAME")}
                            required={true}
                            validate={[validate.required, validate.minLength2, validate.maxLength64]}
                            className="stat-input-field"
                            maxLength={64}
                            minLength={2}
                        />
                    </div>
                    <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                        <Field
                            name="lastName"
                            type="text"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_LAST_NAME")}
                            required={true}
                            validate={[validate.required, validate.minLength2, validate.maxLength64]}
                            maxLength={64}
                            minLength={2}
                        />
                    </div>
                </div>
                <div className="row">
                    {hideAndExpandFeatureFlag && !companyNameShow && !AppSettings.isT3 ? 
                        <span className="grid-column-alignment-left columns hideandexpand" onClick={setCompanyNameShow}>{i18n.t("LABEL_COMMON_ADD_COMPANY_NAME")}</span> :
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="companyName"
                                    type="text"
                                    component={renderField}
                                    label={i18n.t("LABEL_COMMON_COMPANY_NAME")}
                                    validate={[validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                />
                            </div>
                    }
                    
                </div>

                {!AppSettings.isT3 && renderShippingAddressForm(stateList, addressLineTwoShow, setAddressLineShow, hideAndExpandFeatureFlag )}
                
                <div className="ac-header-contact-info">
                        <h2 className="q-headline2">{i18n.t("LABEL_CONFIRMATION_CONTACT_INFORMATION")}</h2>
                    </div>
                    
                <div className="row">
                    <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                        <Field
                            name="email"
                            type="email"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_EMAIL")}
                            required={true}
                            validate={[validate.required, validate.minLength2, validate.email]}
                            minLength={2}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                        <Field
                            name="phone"
                            component={renderField}
                            type="text"
                            label={i18n.t("LABEL_COMMON_PHONE")}
                            required={true}
                            validate={[validate.required, validate.phoneNumber]}
                            normalize={normalizePhone}
                        />
                        <label className="prt-tcpa-disclosure" htmlFor="checkoutPhone">
                                {i18n.t("LABEL_TCPA_DISCLOSURE")}
                        </label>
                    </div>
                </div>
            </div>
        </form>
    );

    
 };

export default withTranslation()(
    reduxForm({
        form: "ShippingContactForm", // a unique identifier for this form
        destroyOnUnmount: false, // <------ preserve form data
        enableReinitialize: true,
        forceUnregisterOnUnmount: true // <------ unregister fields on unmount
    })(ShippingContactForm)
);
export {renderField, renderSelect}
