import React, { useState } from "react";
import PartsShopCard from "./PartsShopCard";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { urlBuilder } from "./PartsShopCardUtils";

/*
SHARED PARTS SHOP CARDS COMPONENT

Props:
    eSpotData: object
    Object containing all of the data to populate this component.

Usage:
    1) Import the component

    2) Pass the eSpotData object as a prop. The eSpot should contain the following items. 
        NOTE: this is bound to change in next iterations since eSpot data is being worked on
        - header1
        - header2
        - cardTitle
        - array of cards with all the info:
            - imageUrl
            - categoryId
            - title
*/
function PartsShopCards(props) {
    // set the title of the cards component if it exists using espot data
    const data = (props && props.eSpotData) || {};
    const header1 =
        data.MarketingSpotData[0].marketingSpotDataTitle[0].marketingContentDescription[0].marketingText || "";
    const header2 =
        data.MarketingSpotData[0].marketingSpotDataTitle[1].marketingContentDescription[0].marketingText || "";
    const cards = data.MarketingSpotData[0].baseMarketingSpotActivityData;
    const { t } = useTranslation();
    const [cardTitle, setCardTitle] = useState("");
    let isElectric =
        props.vehicleCombinations.filter((element) => element.FUEL_TYPE.includes("EV") || element.FUEL_TYPE.includes("ELECTRIC")).length !== 0 ? true : false;

    const CreateShopCardList = () => {
        return cards.map((item, index) => {
            if (item.contentFormatName === "File") {
                let image = item?.attachmentAsset[0]?.attachmentAssetPath || "";
                let label = item?.baseMarketingSpotActivityName || "";
                let categoryId = item?.contentUrl ? queryString.parseUrl(item.contentUrl).query.categoryId : "";
                return (
                    // populate parts shop cards
                    <div id="ac-parts-card" key={index}>
                        <PartsShopCard image={image} label={label} categoryId={categoryId} />
                    </div>
                );
            } else if (item.contentFormatName === "Text") {
                setCardTitle(item.contentName || "");
            }
        });
    };

    return (
        <div>
            {!isElectric && (
                <div id="header1" className="q-descriptive q-heavy">
                    {" "}
                    {t("LABEL_EV_CHARGER_NO_SUPPORT")}
                </div>
            )}
            <div id="header1" className="q-descriptive q-heavy">
                {header1}
            </div>
            <div id="header2" className="q-body ac-PartsShopCards-header">
                {header2}
            </div>
            <div className="small-12 xlarge-3">
                <a id="ac-parts-card-search-catalog-btn" href={urlBuilder()} target="_blank">
                    <button
                        className="ac-add-to-cart-btn q-button q-primary-button gm-primary-btn stat-button-link ac-PartsShopCards-header"
                        data-dtm=""
                    >
                        {t("LABEL_PARTS_SHOP_CARD_PARTS_CATALOG")}
                    </button>
                </a>
            </div>
            {isElectric && (
                <>
                    <div className="q-headline2">{cardTitle}</div>
                    <CreateShopCardList />
                </>
            )}
        </div>
    );
}

PartsShopCards.propTypes = {
    eSpotData: PropTypes.object.isRequired
};

export default PartsShopCards;
