import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import VehicleConfigForm from "./VehicleConfigForm";

// Returns fitment check content. The content rendering is separated into
// two files: fitmentCheck.js and fitmentCheckForm.js. fitmentCheck.js contains
// logic to handle fitment match/no match (add item to cart/redirect user) and
// renders the modal container for the fitment check form (which contains the fitment dropdowns).
// State data for the fitmentCheckForm is loaded here and then passed to the component as its props.
const VehicleConfig = (props) => {
    if (props.dropdownData) {
        let bodyFitmentSelection = [];
        let wheelFitmentSelection = [];
        let trimFitmentSelection = [];
        let driveFitmentSelection = [];
        let engineFitmentSelection = [];

        if (props.dropdownData.fitmentSelections) {
            bodyFitmentSelection = props.dropdownData.fitmentSelections.bodyFitmentSelection;
            wheelFitmentSelection = props.dropdownData.fitmentSelections.wheelFitmentSelection;
            trimFitmentSelection = props.dropdownData.fitmentSelections.trimFitmentSelection;
            driveFitmentSelection = props.dropdownData.fitmentSelections.driveFitmentSelection;
            engineFitmentSelection = props.dropdownData.fitmentSelections.engineFitmentSelection;
        }

        const isFetchingVehicleCombinations = props.dropdownData.isFetchingVehicleCombinations;
        const isFetchingFitmentCheck = props.dropdownData.isFetchingFitmentCheck;

        return (
            <VehicleConfigForm
                isFetchingVehicleCombinations={isFetchingVehicleCombinations}
                isFetchingFitmentCheck={isFetchingFitmentCheck}
                wheelDropDownLabel={props.dropdownData.wheelDropDownLabel}
                vehicleCombinations={props.dropdownData.vehicleCombinations}
                vehicleParams={props.dropdownData.vehicleParams}
                fitmentVehicleInfo={props.dropdownData.fitmentVehicleInfo}
                bodyFitmentSelection={bodyFitmentSelection}
                wheelFitmentSelection={wheelFitmentSelection}
                trimFitmentSelection={trimFitmentSelection}
                driveFitmentSelection={driveFitmentSelection}
                engineFitmentSelection={engineFitmentSelection}
                InlineFitmentYMM={props.InlineFitmentYMM}
                showError={props.showError}
                handleSearchAccessories={props.handleSearchAccessories}
                handleVehicleBreadcrumb={props.handleVehicleBreadcrumb}
                setStylingDropdown={props.setStylingDropdown}
                stylingDropdown={props.stylingDropdown}
                vehicleSelectDropdownClass={props.vehicleSelectDropdownClass}
            />
        );
    }
};

const mapStateToProps = (state) => {
    // All data for the fitment check dropdowns is stored in the Redux store
    // state and accessed through the FitmentCheckReducer. mapStateToProps maps
    // the latest state data to props that are connected to FitmentCheck.
    // FitmentCheck then uses props.dropdownData to render data in the component.
    // It gets ymm info from Session reducer
    return {
        dropdownData: state.VehicleConfigReducer,
        showError: state.VehicleConfigReducer.showError || false
    };
};

VehicleConfig.propTypes = {
    dropdownData: PropTypes.shape({
        wheelDropDownLabel: PropTypes.string,
        vehicleCombinations: PropTypes.array
    })
};

export default connect(mapStateToProps)(VehicleConfig);
