import React, {useEffect, useState} from 'react';
import {Translation} from 'react-i18next';
import AppSettings from '../../../core/AppSettings';
import { Toggle } from '../../Hooks/Hooks';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import { connect } from 'react-redux';
import {getEnrollConfirmationModalEspot} from "./EnrollmentModalService";
import {getBaseMarketingSpotActivityData} from "../../Validator/Validator";

const EnrolledConfirmationModal = (props) => {
    const [enrollConfirmationMessage, setEnrollConfirmationMessage] = useState();
    useEffect(() => {
        getEnrollConfirmationMessage()
    }, [])

    //get Espot data for cart potential points message
    const getEnrollConfirmationMessage = async () => {
        try {
            const response = await getEnrollConfirmationModalEspot();
            const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(response.data, 0);
            if (baseMarketingSpotActivityData[0].marketingContentDescription[0]) {
                setEnrollConfirmationMessage(baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
            }
        } catch
            (e) {
            console.warn("Unable to retrieve content from espot -->",e);
        }
    }

    // Handle modal state to know when to show/hide it
    const [modalVisibility, setModalVisibility] = useState(true);

    const brand = AppSettings.currentSite.label;
    const myRewardsLink = process.env.REACT_APP_MYREWARDS_LINK;

    function handleCloseModal() {
        // Hide modal
        Toggle(modalVisibility, setModalVisibility);
        // Remove item from local storage (set in AuthenticationWorker)
        localStorage.removeItem('showEnrolledConfirmationModal');
    }

    return (
        <Modal
            open={modalVisibility}
            onClose={() => handleCloseModal()}
            center
            dataClass="stat-button-close"
            dataDtm="enrollment state 2-modal"
        >
            <Translation>{(t) => (
                <div>
                    {props.authData.userData.firstName ?
                        <div className="q-headline2">
                            {t('REWARDS_ENROLL_MODAL_STATE_2_CONFIRMATION_HEADER', {firstName: props.authData.userData.firstName})}
                        </div>
                        :
                        // display this if there happens to be no first name
                        <div className="q-headline2">
                            {t('REWARDS_ENROLL_MODAL_STATE_2_CONFIRMATION_HEADER_NO_NAME')}
                        </div>
                    }
                    <hr className="q-separator-horizontal"/>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: enrollConfirmationMessage}}/>
                        <p>
                            <p>
                                <a className="ac-accessible-link-underline stat-text-link" href={myRewardsLink} target="_blank" rel="noopener" data-dtm="rewards enrolled-modal">
                                    {t("REWARDS_ENROLL_MODAL_STATE_2_LEARN_MORE", {brand})}
                                </a>
                            </p>

                        </p>
                    </div>
                    <br/>
                    <div>
                        <Button
                            className="stat-button-link"
                            localeKey={t("REWARDS_ENROLL_MODAL_STATE_2_BUTTON")}
                            onClick={() => handleCloseModal()}
                            dataDtm={"enroll modal:congratulations"}
                        />
                    </div>
                </div>
            )}
            </Translation>
        </Modal>
    );

}
const mapStateToProps = state => {
    return {
        authData: state.Authentication,
        rewardsData: state.RewardsReducer
    }
}
export default connect(mapStateToProps)(EnrolledConfirmationModal);
