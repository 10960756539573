import React, { useEffect, useState } from "react";
import SharedAccordion from "../../shared/Accordion/SharedAccordion";
import { Container } from "react-bootstrap";
import { getAllCategories, getParentCategoryId } from "./AllCategoriesService";
import { isValidValue } from "../../shared/Validator/Validator";
import { setCategory } from "../../shared/SearchLink/SearchLinkRedux/SearchLinkActionCreator";
import { selectedCategoryTitle } from "../Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { SET_CLP_REDIRECT, TOGGLE_MODAL } from "../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionType";
import vaultConstants, { FF_2085403_DISPLAY_CATEGORIES } from "../../../config/vault_constants";
import VehicleSelectModal from "../../shared/VehicleSelect/VehicleSelectModal/VehicleSelectModal";
import ErrorMessage from "../../shared/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import AppSettings from "../../core/AppSettings";
import accHistory from "../../modules/App/History";
import { jsonToUrlParams } from "../../shared/Utils/Utils";

//Initializes useStates  & adds feature flags in case we want to disable/enable features quickly on the environments/production
const AllCategories = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const NUM_OF_COL = 4;
    const categoriesSlicedObj = [];
    const [allCategoriesData, setAllCategoriesData] = useState([]);
    const displayCategoriesFeatureFlag =
        window.location.href.indexOf("localhost") > -1 || String(FF_2085403_DISPLAY_CATEGORIES) === "true";
    const selectedCategory = useSelector((state) => state.HomeCategoriesReducer.selectedCategoryTitle).toLowerCase();
    const [categoryError, setCategoryError] = useState(false);
    const isT3 = AppSettings.isT3;
    const vehicleInfoData = useSelector((state) => state.Session);
    const urlParameters = AppSettings.urlParameters();
    const clpFitmentFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2237161_NEW_FITMENT_CLP_ACC) === "true";
    //set vehicle fitment info
    const vehicleFitment = {
        year: vehicleInfoData.year || "",
        make: vehicleInfoData.make || "",
        model: vehicleInfoData.model || "",
        body: vehicleInfoData.vehicleConfig.body || "",
        bodyId: vehicleInfoData.vehicleConfig.bodyId || "",
        trim: vehicleInfoData.vehicleConfig.trim || "",
        trimId: vehicleInfoData.vehicleConfig.Id || "",
        drive: vehicleInfoData.vehicleConfig.drive || "",
        driveId: vehicleInfoData.vehicleConfig.driveId || "",
        engine: vehicleInfoData.vehicleConfig.engine || "",
        engineId: vehicleInfoData.vehicleConfig.engineId || "",
        wheel: vehicleInfoData.vehicleConfig.wheel || "",
        wheelId: vehicleInfoData.vehicleConfig.wheelId || "",
        bodyNumDoors: vehicleInfoData.vehicleConfig.bodyNumDoors || "",
        vin: vehicleInfoData.vin ? vehicleInfoData.vin : ""
    };

    useEffect(() => {
        getCarouselData();
    }, []);

    useEffect(() => {
        Object.entries(vehicleFitment).map(([key, value]) => {
            urlParameters[key] = value;
        });
    }, [vehicleFitment]);

    const isMobile = window.innerWidth < AppSettings.MEDIUM_BREAKPOINT;

    //Gets all categories using parent ID
    const getCarouselData = async () => {
        try {
            const categoryParentId = await getParentCategoryId();
            if (isValidValue(categoryParentId)) {
                const allCategories = await getAllCategories(categoryParentId);
                if (isValidValue(allCategories)) {
                    for (let x in allCategories) {
                        //Rearrange in Alphabetical order
                        allCategories.sort(function (a, b) {
                            let nameA = a.name.toUpperCase();
                            let nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            // names must be equal
                            return 0;
                        });
                    }
                    setAllCategoriesData(allCategories);
                    setCategoryError(false);
                } else {
                    setCategoryError(true);
                }
            } else {
                setCategoryError(true);
            }
        } catch (e) {
            setCategoryError(true);
        }
    };
    //Sends user to CLP
    const handleClick = (catName, catId) => {
        dispatch(setCategory(catName, catId));
        dispatch(selectedCategoryTitle(catName));
        if (!clpFitmentFlag || isT3) {
            dispatch({ type: TOGGLE_MODAL });
        }
        dispatch({ type: SET_CLP_REDIRECT, category: catName });
        if (clpFitmentFlag && !isT3) {
            urlParameters["categoryId"] = catId;
            //redirecting to CLP without fitment modal
            accHistory.push(`/categories/${catName}` + jsonToUrlParams(urlParameters));
        }
    };
    //Displays all categories in columns
    const categoryBody = (
        <Container className={"ac-all-categories-body gb-body1"} fluid>
            <div key="row1" className="ac-all-catagory-body-row">
                <div className="category-homepage-column">
                    {allCategoriesData.map((category, key) => (
                        <div
                            id={"categoryItem" + key}
                            key={category.uniqueID}
                            className="stat-text-link prt-all-categories-links"
                            data-dtm="shop by category"
                        >
                            <p onClick={() => handleClick(category.name, category.uniqueID)}>
                                <a>{category.name}</a>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );

    const accordionsArray = [{ position: "0", titleName: "All Categories", bodyContent: categoryBody }];
    // Displays error message when allCategories isn't available or display allCategories
    const allCategoriesHandler = () => {
        if (categoryError) {
            return <ErrorMessage icon={true} message={t("LABEL_ALL_CATEGORIES_ERROR")}></ErrorMessage>;
        } else if (displayCategoriesFeatureFlag) {
            return (
                <>
                    <div id="ac-all-categories">
                        <SharedAccordion
                            defaultActiveKey="0"
                            objBody={true}
                            accordions={accordionsArray}
                            leftOfTitle={isMobile ? false : true}
                            iconPlusMinus={true}
                            fontAwesomeSize={"lg"}
                        />
                    </div>

                    <VehicleSelectModal headerLabel={"LABEL_COMMON_VEHICLE_SELECT_CATEGORIES_HEADER"} />
                </>
            );
        } else {
            return <></>;
        }
    };
    //Returns null if no data is available
    if (allCategoriesData.length > 0) {
        return allCategoriesHandler();
    } else {
        return null;
    }
};

export default AllCategories;
