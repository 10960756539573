import * as type from './UserProfileActionType';

/**************************************************************/
// Order History
/**************************************************************/
export const retrieveOrderHistoryDataAsync = ()=>{
    return {
        type:type.LOAD_ORDER_HISTORY_DATA_ASYNC
    }
}

export const loadOrderHistoryDataAsync = (response) => {
    const action = {
        type: type.ORDER_HISTORY_DATA_LOADED,
        payload : response
    }
    return action;
}

export const loadOrderHistoryDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_ORDER_HISTORY_DATA_ASYNC_FAIL,
        error: error
    }
    return action;
}

/**************************************************************/
// Order Data
/**************************************************************/

export const retrieveOrderDataAsync = ()=>{
    return {
        type:type.LOAD_ORDER_DATA_ASYNC
    }
};

export const loadOrderDataAsync = (response) => {
    const action = {
        type: type.ORDER_DATA_LOADED,
        payload : response
    }
    return action;
}

export const loadOrderDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_ORDER_DATA_ASYNC_FAIL,
        error: error
    }
    return action;
}

/**************************************************************/
// Payment Info
/**************************************************************/

export const retrievePaymentInfoDataAsync = ()=>{
    return {
        type:type.LOAD_PAYMENT_INFO_DATA_ASYNC
    }
};

export const loadPaymentInfoDataAsync = (response) => {
    const action = {
        type: type.PAYMENT_INFO_DATA_LOADED,
        payload : response
    }
    return action;
}

export const loadPaymentInfoDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_PAYMENT_INFO_DATA_ASYNC_FAIL,
        error: error
    }
    return action;
}

/**************************************************************/
// Profile & Settings
/**************************************************************/
export const togglePhoneEdit = () => {
    const action = {
        type: type.TOGGLE_PROFILE_EDIT_PHONE
    }
    return action;
}

export const toggleAddressEdit = (addressInfo) => {
    const action = {
        type: type.TOGGLE_PROFILE_EDIT_ADDRESS,
        payload : addressInfo
    }
    return action;
}

export const togglePersonalInfoEdit = () => {
    const action = {
        type: type.TOGGLE_PROFILE_EDIT_PERSONAL_INFO
    }
    return action;
}

export const togglePhoneAdd = () => {
    const action = {
        type: type.TOGGLE_PROFILE_ADD_PHONE
    }
    return action;
}

export const toggleAddressAdd = () => {
    const action = {
        type: type.TOGGLE_PROFILE_ADD_ADDRESS
    }
    return action;
}

export const resetProfileToggle = () => {
    const action = {
        type: type.RESET_PROFILE_TOGGLE
    }
    return action;
}

export const retrievePersonalInfoDataAsync = ()=>{
    return {
        type:type.LOAD_PERSONAL_INFO_DATA_ASYNC
    }
}

export const loadPersonalInfoDataAsync = (response) => {
    const action = {
        type: type.PERSONAL_INFO_DATA_LOADED,
        payload : response
    }
    return action;
}

export const loadPersonalInfoDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_PERSONAL_INFO_DATA_ASYNC_FAIL,
        error: error
    }
    return action;
}