import * as type from './FooterActionType';

/**************************************************************/
// Actions to load data into the Footer state
/**************************************************************/
export const loadFooterDataAsync = (pageName) => {
    const action = {
        type: type.LOAD_FOOTER_DATA_ASYNC,
        pageName: pageName
    }
    return action;
}

export const setLinks = (links) => {
    const action = {
        type: type.SET_LINKS,
        links: links
    }
    return action;
}

export const setSlogan = (slogan) => {
    const action = {
        type: type.SET_SLOGAN,
        slogan: slogan
    }
    return action;
}

export const setText = (text) => {
    const action = {
        type: type.SET_TEXT,
        text: text
    }
    return action;
}

export const setSequence = (sequence) => {
    const action = {
        type: type.SET_SEQUENCE,
        sequence: sequence
    }
    return action;
}

export const setLoading = (loading) => {
    const action = {
        type: type.SET_LOADING,
        loading: loading
    }
    return action;
}


/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadFooterDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_FOOTER_DATA_ASYNC_FAIL,
        error
    }
    return action;
}

export const setLinksFail = (error) => {
    const action = {
        type: type.SET_LINKS_FAIL,
        error
    }
    return action;
}

export const setSloganFail = (error) => {
    const action = {
        type: type.SET_SLOGAN_FAIL,
        error
    }
    return action;
}

export const setTextFail = (error) => {
    const action = {
        type: type.SET_TEXT_FAIL,
        error
    }
    return action;
}

export const setSequenceFail = (error) => {
    const action = {
        type: type.SET_SEQUENCE_FAIL,
        error
    }
}