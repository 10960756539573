import React, { useEffect } from 'react';
import AppSettings from "../../core/AppSettings";
import store from "../../core/Redux/Store";

// Page Title (Hooks version of titleSetUp in Utils.js)

/* EXAMPLE USE:
    function functionName() {
        // PAGE TITLE
        pageTitle("NAME");
    }
*/
export const pageTitle = (name) => {
    useEffect(() => {
        if (AppSettings.isT3) {
            let dealerName = '';
            setTimeout(() => {
                dealerName = store.getState().HeaderReducer.name;
                if(dealerName !==undefined){
                    document.title = `${name} | ${dealerName} ${AppSettings.currentSite.label}  Accessories`;
                }
                else{
                    document.title = `${name} | ${AppSettings.currentSite.label}  Accessories`;
                }
            }, 2000);
        } else {
            document.title = `${name} | ${AppSettings.currentSite.label}  Accessories`;
        }
    })
}


// TOGGLE ALL THE THINGS (BETWEEN TRUE AND FALSE)
// Toggle between true and false (To be used with State Hooks)
// Just pass in the current state value and a function that lets you update it
// https://reactjs.org/docs/hooks-overview.html)
//
// Example Use:
//      const [showText, setShowText] = useState(false);
//      <button onClick={() => Toggle(showText, setShowText)}>Toggle Text</button>
export function Toggle(currentState, updateState) {
    updateState(!currentState)
}