import React from 'react';
import propTypes from 'prop-types';
import { Translation } from 'react-i18next';

function FormHeader(props) {

    return (
        <Translation>
            {(t) => (
                <h3 className="small-margin">
                    {t(props.headerLabel)}
                </h3>
            )}</Translation>
    )
}

FormHeader.propTypes = {
    headerLabel: propTypes.string.isRequired,
}

export default FormHeader;