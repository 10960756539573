/** CONSTANTS ***/
export const UPDATE_ZIP_CODE = "UPDATE_ZIP_CODE";
export const SELECT_DEALER = "SELECT_DEALER";
export const CANCEL_BUTTON = "CANCEL_BUTTON";
export const CONTINUE_BUTTON = "CONTINUE_BUTTON";
export const LOAD_DEALER_DATA_ASYNC = "LOAD_DEALER_DATA_ASYNC";
export const LOAD_DEALER_DATA_ASYNC_FAILED = "LOAD_DEALER_DATA_ASYNC_FAILED";
export const LOAD_DEALER_DATA = "LOAD_DEALER_DATA";
export const NO_DEALER_FOUND_ERROR = "NO_DEALER_FOUND_ERROR";
export const CHANGE_ZIP_CODE = "CHANGE_ZIP_CODE";
export const DISPATCH_ERROR_MESSAGE = "DISPATCH_ERROR_MESSAGE";
export const VALID_ZIP_CODE = "VALID_ZIP_CODE";
export const FAILED_UPDATE_DEALER = "FAILED_UPDATE_DEALER";