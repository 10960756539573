/*** PAYMENT JS FILE ***/

/*** IMPORT STATEMENT ***/
import React from 'react';
import store from '../../../../../core/Redux/Store';
import {
    nextStep,
    setLoading,
    updateCreditCardSuccessInformation,
    enableTechnicalFlag,
    resetAllErrors,
    loadSpinnerOnPaymentPage,
    enablePaymentUserFlag
} from '../../../CheckoutRedux/CheckoutActionCreator';
import {
    handlePaymentFieldError
} from "./PaymentFieldErrorHandler";
import i18n from '../../../../../core/i18n/i18n';
import { setAnalyticsclassAndAttribute } from "../../../../../shared/Analytics/PaymentFormDTM"
import vaultConstants from '../../../../../../config/vault_constants';

/** Passing only the last part of the url, Rest is handle by node server ***/
let paymentScript = `${process.env.REACT_APP_PAYMENT_URL}/v3/lib/GMPaymentUI.min.js`;

let creditCardResponse = '';
const SYSTEM_ERROR = 'system_error';






/*** ERROR CODE THAT IS RETURNED BY MASTER CARD IN CASE OF TRANSACTION FAILED ***/
const paymentUserError = ['UNSPECIFIED_FAILURE',
    'DECLINED',
    'EXPIRED_CARD',
    'INSUFFICIENT_FUNDS',
    'ACQUIRER_SYSTEM_ERROR',
    'DECLINED_DO_NOT_CONTACT',
    'BLOCKED',
    'REFERRED',
    'INVALID_CSC',
    'DECLINED_AVS',
    'DECLINED_CSC',
    'DECLINED_AVS_CSC',
    'DECLINED_PAYMENT_PLAN',
    'PARTIALLY_APPROVED',
    'UNKNOWN'
];

/*** APPEND PAYMENT SCRIPT TAG AND URL ON DOM WHEN PAYMENT PAGE LOADS ***/
export const loadGMPaymentScripts = () => {
    /*** APPEND PAYMENT SCRIPT TO THE DOM **/
    const script = document.createElement("script");
    script.src = paymentScript;
    script.async = true;
    document.head.appendChild(script);




    /*** LOAD GMPAYMENT UI AFTER SCRIPT TAG IS ADDED ***/
    script.onload = () => {
        /** THIS ENVIRONMENT NAME IS HANDLE BY VAULT ***/
        let environmentName = String(vaultConstants.SERVER_ENVIRONMENT_NAME_HOST);
        loadGMPaymentWidget(environmentName);

        /** ADD DATA DTM FOR STATS **/
        setAnalyticsclassAndAttribute();

        /*** SOMETIMES IT TAKES SOME TIME TO LOAD THE PAYMENT WIDGET, WE ARE ADDING SPINNER EXTRA 2 MIN TO LOAD SO PAYMENT SECTION
         * DOESN'T LOOKS EMPTY FOR FEW SECONDS
         */
        setTimeout(() => {
            store.dispatch(loadSpinnerOnPaymentPage());
        }, 2000);

    }
};







/*** GM PAYMENT WIDGET TO USE PAYMENT LIBRARY ***/
export const loadGMPaymentWidget = (environmentName) => {
    /*** Debug Flag :
     * PRE-PROD : this flag is always true on preprod env, would call master card test server, don't create an actual transaction.
     * PRODUCTION : flag is false, it calls the actual master card service to create an transaction.
     */

    /** Setting up up default debug flag to true **/
    let isDebug = true;

    /** Environment name value will be replaced by vault, checking to see if its prod or not **/
    if (environmentName === 'accsprodw' || environmentName === 'accsprodm') {
        isDebug = false;
    }

    let dealerBac = store.getState().OrderInfoReducer.parsedResponse.dealer.dealerBac;

    let widget = new GMPaymentUI({
        containerId: "accessories-payment-widget",
        mastercardOptions: {
            //use MasterCard test servers, FALSE FOR PRODUCTION!
            useTestServers: isDebug,

            //bac or MasterCard merchantId are required!!!!
            bac: dealerBac,
            merchantId: `GM-${dealerBac}`,

            //Display images for accepted cards, false by default
            showCardImages: true,

            //error messages will clear when input changes
            clearErrorOnChange: true,

            //error messages will clear when input gains focus
            clearErrorOnFocus: false,
            //closeIcon: <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path></svg>,
            //error messages will clear when input loses focus
            clearErrorOnBlur: true,

            //text to display on form fields
            //note: creditCardOption is text to display on radio button if 
            //more than 1 payment method (not currently being used, only one payment methond right now)
            translations: {
                nameOnCard: {
                    label: i18n.t("LABEL_GMPAYMENT_NAME_LABEL"),
                    placeholder: i18n.t("LABEL_GMPAYMENT_NAME_PLACEHOLDER"),
                    requiredError: i18n.t("LABEL_GMPAYMENT_ERROR_REQUIRED"),
                    invalidError: i18n.t("LABEL_GMPAYMENT_NAME_ERROR_INVALID")
                },
                creditCard: {
                    label: i18n.t("LABEL_GMPAYMENT_CC_LABEL"),
                    placeholder: i18n.t("LABEL_GMPAYMENT_CC_PLACEHOLDER"),
                    requiredError: i18n.t("LABEL_GMPAYMENT_ERROR_REQUIRED"),
                    invalidError: i18n.t("LABEL_GMPAYMENT_CC_ERROR_INVALID")
                },
                expiryMonth: {
                    label: i18n.t("LABEL_GMPAYMENT_EXPIRYMONTH_LABEL"),
                    placeholder: i18n.t("LABEL_GMPAYMENT_EXPIRYMONTH_PLACEHOLDER"),
                    requiredError: i18n.t("LABEL_GMPAYMENT_ERROR_REQUIRED"),
                    invalidError: i18n.t("LABEL_GMPAYMENT_EXPIRYMONTH_ERROR_INVALID")
                },
                expiryYear: {
                    label: i18n.t("LABEL_GMPAYMENT_EXPIRYYEAR_LABEL"),
                    placeholder: i18n.t("LABEL_GMPAYMENT_EXPIRYYEAR_PLACEHOLDER"),
                    requiredError: i18n.t("LABEL_GMPAYMENT_ERROR_REQUIRED"),
                    invalidError: i18n.t("LABEL_GMPAYMENT_EXPIRYYEAR_ERROR_INVALID")
                },
                securityCode: {
                    label: i18n.t("LABEL_GMPAYMENT_SECCODE_LABEL"),
                    placeholder: i18n.t("LABEL_GMPAYMENT_SECCODE_PLACEHOLDER"),
                    requiredError: i18n.t("LABEL_GMPAYMENT_ERROR_REQUIRED"),
                    invalidError: i18n.t("LABEL_GMPAYMENT_SECCODE_ERROR_INVALID"),
                    toolTip: {
                        header: i18n.t("LABEL_GMPAYMENT_CVV_TOOLTIP_HEADER"),
                        instructions1: i18n.t("LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS"),
                        instructions2: i18n.t("LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS2"),
                        instructions3: i18n.t("LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS3"),
                        instructions4: i18n.t("LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS4"),
                    }
                }
            },
        },
        /*
        Callback after a SUCCESSFUL submit to MasterCard payment gateway
        (e.g. no validation errors and credit card is valid)
        returns data object with information on submitted fields

            klarna success data example:
            {
                "paymentType":"pay_later",
                "response":{
                    "authorization_token":"7fa606e6-c971-62c8-ba25-9dce0004357c",
                    "show_form":true,
                    "approved":true,
                    "finalize_required":false
                }
            }

            credit success data example:
            {
                "paymentType":"credit",
                "response":{
                    "sessionId":"SESSION000XXXXXXXXXXXXXXXXXX",
                    "payment":{
                    "brand":"VISA",
                    "expiry":{
                        "month":"5",
                        "year":"21"
                    },
                    "number":"401200xxxxxx0026",
                    "nameOnCard":"Sample User"
                    },
                    "version":"44",
                    "customer":{
                    "ipAddress":"127.0.0.1"
                    }
                }
            }

        */
        onSuccessCallback: (data) => {
            console.log("success", data);
            creditCardResponse = data;
            handlePaymentSuccess();
            return creditCardResponse;
        },
        /*
        Callback when an error occurs
        Returns error object with short error message
        
        credit error example
            {"paymentType":"credit","error":{"cardNumber":"invalid"}}

        klarna error example
            {"paymentType":"pay_later","error":{"show_form":false,"approved":false,"finalize_required":false}}
        */
        onErrorCallback: (error) => {
            console.log("error", error);
            creditCardResponse = error;
            handlePaymentError(creditCardResponse.error);
            return creditCardResponse;
        }
    });

    window.submit = function () {
        //Reset All Errors
        store.dispatch(resetAllErrors());
        widget.submit();
    }
};


const handlePaymentSuccess = () => {
    store.dispatch(updateCreditCardSuccessInformation(creditCardResponse));
    store.dispatch(nextStep());
    store.dispatch(setLoading(false));
};

const handlePaymentError = (error) => {
    store.dispatch(setLoading(false));
    console.log("Error found while trying to submit an order, Error code =>", error);
    if (error === SYSTEM_ERROR) {
        store.dispatch(enableTechnicalFlag());
        /*** ReInitialize the payment widget ***/
        let environmentName = String(vaultConstants.SERVER_ENVIRONMENT_NAME_HOST);
        loadGMPaymentWidget(environmentName);
    }
    else if (error.cardNumber || error.nameOnCard || error.expiryMonth || error.expiryYear || error.securityCode) {
        handlePaymentFieldError();
    }
    else {
        handleOtherPaymentError(error);
    }

};

const handleOtherPaymentError = (error) => {
    store.dispatch(setLoading(false));
    let errorFound = false;

    for (let i = 0; i <= paymentUserError.length; i++) {
        if (error.toLowerCase() === paymentUserError[i].toLowerCase()) {
            store.dispatch(enablePaymentUserFlag());
            errorFound = true;
            break;
        }
    }
    if (!errorFound) {
        store.dispatch(enableTechnicalFlag());
        /** ReInitialize the payment widget ***/
        let environmentName = String(vaultConstants.SERVER_ENVIRONMENT_NAME_HOST);
        loadGMPaymentWidget(environmentName);
    }
};