import { getFeaturedCategoriesData } from "./FeaturedCategoriesService";
import { setFeaturedCategoriesData, setFeaturedCategoriesDataFail } from "./FeaturedCategoriesActionCreator";
import { call, put } from "redux-saga/effects";

/**************************************************************/
// FEATURED CATEGORIES CALL
// Espot response has a lot of unnecessary info for UI. The JSON
// is parsed here and only the relevant data is sent to the
// home categories component (see FeaturedCategoriesActionCreator & FeaturedCategoriesReducer)
/**************************************************************/
export function* callForFeaturedCategories() {
    let assets = {};
    let title = "";

    try {
        //get all data from response
        const response = yield call(getFeaturedCategoriesData);
        const espotData = response.MarketingSpotData[0].baseMarketingSpotActivityData;

        //take only relevant data from response
        assets = parseAssets(espotData);
        title = parseTitle(response.MarketingSpotData[0].marketingSpotDataTitle[0]);

        //clean data set in SET_FEATURED_CATEGORIES_DATA
        //and is added to state
        yield put(setFeaturedCategoriesData(assets, title));
    } catch (error) {
        // Dispatches SET_FEATURED_CATEGORIES_DATA_FAIL to handle the error.
        yield put(setFeaturedCategoriesDataFail(error));
    }
}

/**************************************************************/
// PARSE JSON FOR FEATURED CATEGORIES
/**************************************************************/
function parseAssets(response) {
    // Mapping data to extract only the relevant information from the espot
    return response.map((item) => {
        const { contentUrl } = item;
        let title = "";
        let desc = "";
        let image = "";
        let categoryId = "";
        let categoryName = "";
        let clpName = "";

        if (item.MarketingContentDescription && item.MarketingContentDescription[0].xmktDesc_customField1) {
            title = item.MarketingContentDescription[0].xmktDesc_customField1;
        }
        if (item.marketingContentDescription && item.marketingContentDescription[0].marketingText) {
            desc = item.marketingContentDescription[0].marketingText;
        }
        if (item.attachmentAsset && item.attachmentAsset[0].attachmentAssetPath) {
            image = item.attachmentAsset[0].attachmentAssetPath;
        }
        if (contentUrl) {
            const nameKey = "identifier=";
            const idKey = "categoryId=";
            let categoryIdArray = contentUrl.match(new RegExp(idKey + "\\d+", "g"));

            let categoryNameArray = contentUrl.match(new RegExp(nameKey + "[^&]+", "g"));

            if (categoryIdArray) {
                categoryId = categoryIdArray[0].replace(idKey, "");
            }
            if (categoryNameArray) {
                categoryName = categoryNameArray[0].replace(nameKey, "");
            }

            clpName = parseCLPCategory(contentUrl);
            
        }

        // return clean data
        return {
            id: categoryId,
            category: categoryName,
            title: title,
            desc: desc,
            image: image,
            clpName: clpName
        };
    });
}

function parseTitle(response) {
    let { marketingContentDescription } = response;
    let title = "";
    if (marketingContentDescription) {
        title = marketingContentDescription[0].marketingText;
    }

    return title;
}


function parseCLPCategory(contentUrl) {
    const identifierName = contentUrl.substring(contentUrl.indexOf("OC") + 3, contentUrl.indexOf("&"));
    const formattedName = identifierName.replace("%26", "");
    const nameArray = formattedName.toLowerCase().split(/\W+/);
    return nameArray.join("-");
  }
