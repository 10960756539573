import React, { useState, useEffect } from 'react';
import { Translation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { pageTitle } from "../../../shared/Hooks/Hooks";
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import { sendEmail } from '../shared/IAMRequests';
import RequestPasswordResetForm from './RequestPasswordResetForm';
import { submitButtonFocus, scrubFormField } from '../../../shared/Utils/Utils';

function RequestPasswordReset(props) {

    const [email, setEmail] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false); // disable/enable form fields/btns


    // The user will be redirected to this page if they try to go directly to /forgotUsernamePassword/emailConfirmation. 
    // They will also see an error which can be triggered with this variable
    const [errorMessage, setErrorMessage] = useState(props.location.state && props.location.state.noEmailError ? "LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_ERROR_EMAIL_NOT_ENTERED" : null);

    pageTitle("Forgot Password");


    

    useEffect(() => {
        // Check if user is logged in. disable field and button if user is logged in
        if (props.authData.registrationStatus == "R") {
            setErrorMessage("LABEL_PASSWORD_RESET_USER_ALREADY_LOGGED_IN_ERROR");
            setIsFormDisabled(true)
        }
        else {
            setIsFormDisabled(false) // reset disabled field
        }

    }, [props.authData.registrationStatus])

    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

    function handleEmailRequest(email) {
        setErrorMessage(null); // Clear error message
        setShowSpinner(true);

        
        // When submitting through the use of pressing enter, if loading bar is wanted, then this focuses on the button so that the loading bar can be seen.
        submitButtonFocus();

        const scrubbedEmail = scrubFormField(email);

        sendEmail(scrubbedEmail).then(result => {
            // Check if email is valid username
            if (result.data.status === "success") {
                setRedirect(true);
            } else {
                handleErrorMessage(result.data.messageKey, result.data.messageDescription);
            }
        }).catch((error) => {
            handleErrorMessage(error, "LABEL_PASSWORD_RESET_EMAIL_ERROR");
        });
    }

    function handleErrorMessage(error, errorMessage) {

        // Hide spinner
        setShowSpinner(false);

        // Remove focus from button
        if (document.activeElement != document.body) {
            document.activeElement.blur();
        }

        // Display error message
        setErrorMessage(errorMessage);
    }

    if (redirect) {
        return <Redirect to={{ pathname: '/forgotUsernamePassword/emailConfirmation', state: { email: email } }} />
    }

    return (
        <CenterGrid headerLabel="LABEL_REQUEST_PASSWORD_RESET_PAGE_TITLE">
            <Translation>
                {(t) => (
                    <React.Fragment>
                        <div className="margin-medium">
                            <RequestPasswordResetForm
                                onEmailChange={e => setEmail(e.target.value)}
                                onSubmit={() => handleEmailRequest(email)}
                                isLoading={showSpinner}
                                errorMessage={errorMessage}
                                isFormDisabled={isFormDisabled} />
                            <div className="text-center"><a className="ac-accessible-link-underline" href="javascript:history.back()">{t("LABEL_COMMON_CANCEL")}</a></div>
                        </div>
                        <div className="text-center">
                            <Trans i18nKey="LABEL_PASSWORD_RESET_PHONECALL">
                                {/* Not hardcoded text - this is done for interpoliation */}
                                IF YOU NEED HELP
                                <a href={"tel:" + phoneNumber}>{{ phoneNumber }}</a>
                            </Trans>
                        </div>
                    </React.Fragment>
                )}
            </Translation>
        </CenterGrid>
    );
}

/** MAP STAGE TO PROPS **/
const mapStateToProps = state => {
    return {
        authData: state.Authentication
    };
};
export default connect(mapStateToProps)(RequestPasswordReset);