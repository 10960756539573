/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_VEHICLE_COMBOS_ASYNC = "LOAD_VEHICLE_COMBOS_ASYNC";

/**************************************************************/
// Actions to set data (handle in FitmentCheckReducer switch statement) 
/**************************************************************/
export const SET_VEHICLE_CONFIG_COMBINATIONS = 'SET_VEHICLE_CONFIG_COMBINATIONS';
export const SET_VEHICLE_FITMENT = "SET_VEHICLE_FITMENT";
export const RESET_VEHICLE_CONFIG_STATE = "RESET_VEHICLE_CONFIG_STATE";

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const SELECT_BODY = 'SELECT_BODY';
export const SELECT_WHEEL = 'SELECT_WHEEL';
export const SELECT_TRIM = 'SELECT_TRIM';
export const SELECT_DRIVE = 'SELECT_DRIVE';
export const SELECT_ENGINE = 'SELECT_ENGINE';
export const DISABLE_BTN = 'DISABLE_BTN';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_VEHICLE_COMBOS_ASYNC_FAIL = "LOAD_VEHICLE_COMBOS_ASYNC_FAIL";
export const SET_VEHICLE_CONFIG_COMBINATIONS_FAIL = 'SET_VEHICLE_CONFIG_COMBINATIONS_FAIL';