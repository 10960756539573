import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translation } from 'react-i18next';

import * as formValidate from '../../../shared/Form/FormValidate/FormValidate';
import Button from '../../../shared/Button/Button';
import { PasswordInputField } from '../../../shared/Form/FormFields/FormFields';
import FormInstructions from '../../../shared/Form/FormInstructions';

/**************************************************************/
//   HANDLE SPECIAL VALIDATION CASES SPECIFIC TO THIS FORM 
// (cases seperate from shared/Form/FormValidate/FormValidate) 
/**************************************************************/
const warn = values => {
    const warnings = {}
    if (values.password) {
        if (process.env.REACT_APP_AUTH_OC_PWD_ONE_NUMBER && /.*[0-9]+.*$/i.test(values.password)) {
            warnings.Req1 = "contains at least 1 number"
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH && values.password.length >= 8) {
            warnings.Req2 = "contains correct length of characters"
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_UPPER_CASE_LETTER && /[A-Z]/.test(values.password)) {
            warnings.Req3 = "contains upper case letter";
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_LOWER_CASE_LETTER && /[a-z]/.test(values.password)) {
            warnings.Req4 = "contains lower case letter";
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR && /.*[!@#$_*]/i.test(values.password)) {
            warnings.Req5 = "contains special characters";
        }
    }
    return warnings
}

function UpdatePasswordForm(props) {

    // TOGGLE PASSWORD VISIBILITY
    const [passwordType, togglePasswordType] = useState('LABEL_COMMON_SHOW_PASSWORD');

    const { handleSubmit, submitting, username, password, onPasswordChange } = props;

    // Password requirements from vault
    const minLength = process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH; 
    const maxLength = process.env.REACT_APP_AUTH_OC_PWD_MAX_LENGTH;
    const oneNumber = process.env.REACT_APP_AUTH_OC_PWD_ONE_NUMBER;
    const upperCase = process.env.REACT_APP_AUTH_OC_PWD_UPPER_CASE_LETTER;
    const lowerCase = process.env.REACT_APP_AUTH_OC_PWD_LOWER_CASE_LETTER;
    const specialChar = process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR;

    return (
        <Translation>
            {(t) => (

                <form className="ac-password-form" onSubmit={handleSubmit}>
                    {/* Instructions to use on updating their password */}
                    <FormInstructions
                        instructions={[
                            { text: 'LABEL_PASSWORD_RESET_FORM_INSTRUCTIONS' },
                            { text: 'LABEL_ACCOUNT_REGISTRATION_EMAIL', name: username, bold: true }, //displays username associated with account
                        ]} />

                    {/* New password input field with async validation */}
                    <Field
                        name="password"
                        component={PasswordInputField}
                        onChange={onPasswordChange}
                        passwordType={passwordType}
                        togglePasswordType={togglePasswordType}
                        fieldLabel={t("LABEL_PASSWORD_RESET_PASSWORD_INPUT_FIELD_LABEL")}
                        requirementLabels={[oneNumber ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT") : null,
                                            minLength ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT2", { number: process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH }) : null,
                                            upperCase ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_UPPER_CASE") : null,
                                            lowerCase ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_LOWER_CASE") : null,
                                            specialChar ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_SPECIAL_CHAR") : null
                                        ]}
                        required={true}
                        synchronous={true}
                        validate={[formValidate.required, formValidate.oneNumberPwd, formValidate.minLengthPwd, formValidate.maxLengthPwd, formValidate.specialCharsPwd]}
                        minLength={minLength ? minLength : 8}
                        maxLength={maxLength ? maxLength : 256}
                        password={password} />

                    <Button
                        localeKey="LABEL_PASSWORD_RESET_BTN_LABEL"
                        isLoading={props.isLoading}
                        type="submit"
                        disabled={submitting} />
                </form>
            )}</Translation>
    )
}

export default (reduxForm({
    form: 'UpdatePasswordForm', // a unique identifier for this form
    destroyOnUnmount: true, // preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true, // unregister fields on unmount
    warn, // warning function given to redux-form (https://redux-form.com/6.4.3/examples/syncvalidation/)
})(UpdatePasswordForm))
