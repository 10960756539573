import axios from 'axios';
import AppSettings from '../../../core/AppSettings';
import queryString from 'query-string';


/** REST CALL TO GET ORDER DATA **/
export const getOrderData = () => {
  const getOrderDataUrl = `/wcs/resources/store/${AppSettings.storeId}/GMOrder/getOrderData/${AppSettings.orderId}?accessOMSOrder=false`;
  if (!AppSettings.orderId) {
    const orderId = queryString.parse(window.location.search).orderId;
    const getOrderUrl = `/wcs/resources/store/${AppSettings.storeId}/GMOrder/getOrderData/${orderId}?accessOMSOrder=false`;
    return axios.get(getOrderUrl)
      .then((response) => {return response.data; })  
  }
  return axios.get(getOrderDataUrl)
      .then((response) => {return response.data; })  
};

/** REST CALL TO GET PAYMENT INFORMATION **/
export const getBillingInfo = () => {
  const getPaymentUrl = `/wcs/resources/store/${AppSettings.storeId}/cart/payment/info/${AppSettings.orderId}`;
  if (!AppSettings.orderId) {
    const orderId = queryString.parse(window.location.search).orderId;
    const getOrderUrl = `/wcs/resources/store/${AppSettings.storeId}/cart/payment/info/${orderId}`;
    return axios.get(getOrderUrl)
      .then((response) => {return response.data; })  
  }
  return axios.get(getPaymentUrl)
      .then((response) => {return response.data; })
};

/** LOYALTY - CHECK IF A USER EXISTS */
export const getIsUserRegistered = (email) => {
  const UserExistsURL = "/wcs/resources/store/" + AppSettings.storeId + "/GMPerson/user/checkExists/" + email + "?responseFormat=json";
  return axios.get(UserExistsURL).then(response => response.data).catch(error => console.log("error", error));
}

export const enrollUserInRewards = () => {
  const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMPerson/user/enrollRewards/';
  const body = {
    enrolled_in_rewards: true
  };

  return axios.post(url, body).catch(error => console.log("error", error));
}

export const updateUserForOmsOrder = (orderId) => {
  const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMOrder/updateuserforomsorder/' + orderId;


  // No body expected
  return axios.post(url).then((response) => { return response; }).catch((error) => { return error.response });
}