/**COMPONENT NAME : CART BUTTONS (BUTTON FOR SHOPPING CART PAGE) **/
import React, { useState } from 'react';
import {Translation} from 'react-i18next';
import PropTypes from 'prop-types';
import AppSettings from "../../../core/AppSettings";
import { connect } from 'react-redux';
import Modal from '../../Modal';
import Button from '../../Button/Button';
import accHistory from '../../../modules/App/History';

const CartButtons = (props)=>{

    /** VARIABLES **/
    const searchResultUrl = props.url;
    const orderId = props.orderNumber;
    const dealerSelected = props.orderInCart.dealer;
    const orderCarrier = props.orderInCart.vehicles;

    //If one of the items in cart has a select delivery option, return True, else false. 
    const selectDeliveryExists = () => {
        for (const vehicle of orderCarrier){
            for (const item of vehicle.orderItems) {
                if (item.items[0].carrier === "SELECT") {
                    return true;
                }
            }
        }   

        return false;
    }
    const [showModal, setShowModal] = useState(false);
    const isOrderDetailsComplete = (dealerSelected !== "" && !selectDeliveryExists() ? true : false);
        /** FUNCTION TO TAKE USER BACK TO SEARCH RESULT**/
        const continueShopping = ()=>{
       if(searchResultUrl){
           window.location.href = searchResultUrl;
       }
    };
    /** FUNCTION TO TAKE USER TO CHECKOUT PAGE **/
    const secureCheckout = ()=>{
        if(orderId && !AppSettings.isT3){
            if(props.authentication.registrationStatusFetched && props.authentication.registrationStatus == 'R'){
                window.location.href = '/checkout' + "?orderId="+orderId;
            }
            else{
                window.location.href = '/checkoutmethod' + "?orderId="+orderId; 
            }
        }
        else if(orderId && AppSettings.isT3){
            window.location.href = AppSettings.dealerName + '/checkout' + "?orderId="+orderId + '&bac=' + AppSettings.bac;
        }
    };
    /** FUNCTION TO PROMPT USER TO MESSAGE POPUP **/
    function handleClose() {
        setShowModal(false);
    }
    function handleOpen() {
        setShowModal(true);
    }
    function handleGoToCart() {
        accHistory.push("/cart");
        setShowModal(false);
    }

    return (
        <Translation>
            {
                (t)=>(<div className="ac-cart-button-wrapper">
                        <div className="ac-cta-button">
                            <button className="q-button-small q-button q-primary-button stat-button-link" data-dtm="shopping cart" 
                                onClick={AppSettings.isT3 ? secureCheckout : 
                                (isOrderDetailsComplete ? secureCheckout : handleOpen)}>
                                {t("LABEL_CHECKOUT_SECURE_CHECKOUT")} </button>
                            <Modal 
                                open={showModal}
                                modalId="ac-null-modal-handler"
                                showCloseIcon={true}
                                onClose={handleClose}
                            > 
                                <div id="prt-dealer-locator-warning-container" className="prt-dealer-locator-warning">
                                    <span className="prt-dealer-locator-warning-title">
                                        {t("DEALER_LOCATOR_REMOVE_ITEMS_FROM_CART_WARNING_TITLE")}
                                    </span>
                                    <hr className="prt-dealer-locator-warning-separator" />
                                    <span className="prt-dealer-locator-warning-description">
                                        {t("DEALER_LOCATOR_REMOVE_ITEMS_FROM_CART_WARNING_DESCRIPTION1")}
                                    </span>
                                    <span className="prt-dealer-locator-warning-description">
                                        {t("DEALER_LOCATOR_REMOVE_ITEMS_FROM_CART_WARNING_DESCRIPTION2")}
                                    </span>
                                </div>
                                <Button
                                    className="prt-dealer-locator-cart-button"
                                    onClick={handleGoToCart}
                                    localeKey={t("LABEL_COMMON_GO_TO_CART")}
                                />
                            </Modal>
                        </div>
                    <div className="ac-cta-button">
                        <button className="q-button q-button-small q-secondary-button stat-button-link" data-dtm="shopping cart" onClick={continueShopping}>
                            {t("LABEL_CHECKOUT_CONTINUE_SHOPPING")}</button>
                    </div>

                    </div>

                )
            }
        </Translation>
    )
};

/**  PROP TYPES VALIDATION **/
CartButtons.propTypes = {
    orderInCart: PropTypes.object.isRequired,
    orderNumber:PropTypes.string.isRequired,
    url:PropTypes.string.isRequired
};

/** MAP STAGE TO PROPS **/
const mapStateToProps = state => {
    return {
      authentication: state.Authentication
    };
  };
  export default (connect(mapStateToProps)(CartButtons))