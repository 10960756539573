import React, { useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from 'react-i18next';
import store from '../../../../../core/Redux/Store';

const GuestUserWidget = (props) => {

    const { isActive, isDropdownActive, width } = props

    useEffect(() => {
        if (isDropdownActive) {
         store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: false });
        }
     }, [isDropdownActive]);

    const guestElement = (
        <Translation>
            {(t) => (
                <a className="ac-header-user-vert-align stat-button-link" data-dtm="global nav">
                    <span>
                        <FontAwesomeIcon className='ac-header-icon-phone-padding' icon="user-circle" size='2x' />
                        {<span className="ac-header-user-vert-align ac-header-label-spacing">{t('LABEL_ACCOUNT_MY_ACCOUNT')}</span>}
                        {/* Will not center the caret if not wrapped in span */}
                        <span className="ac-header-user-vert-align ac-header-caret-spacing">
                            <FontAwesomeIcon icon={getRegisteredUserIcon(isDropdownActive)} />
                        </span>
                    </span>
                </a>
            )}
        </Translation>);

    return (
        isActive ? guestElement : <div></div>
    )
}

const getRegisteredUserIcon = (isDropdownActive) => {
    return isDropdownActive ? "caret-up" : "caret-down";
}

export default GuestUserWidget;