import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Translation } from 'react-i18next';

import AppSettings from '../../../core/AppSettings';
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import { pageTitle } from "../../../shared/Hooks/Hooks";
import Footnote from '../../../shared/Footnote/Footnote';
import {handleUserLogin, submitButtonFocus, getBrandUrl, brand} from '../../../shared/Utils/Utils'
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import vaultConstants from '../../../../config/vault_constants';
import UpdatePasswordForm from './UpdatePasswordForm';
import {updatePasswordToken} from '../shared/IAMRequests'

function UpdatePassword() {

    // Page title
    pageTitle("Reset Password");

    //  Errors
    const [errorMsg, setErrorMsg] = useState("");

    // Only display form if token is verified
    const [displayPwdForm, setPwd] = useState(false);

    // Store password entered in form
    const [password, setPassword] = useState("");

    // Store username/email
    const [username, setUserName] = useState("");

    // Show the loading spinner
    const [showSpinner, setShowSpinner] = useState(false);

    // Get token from url and store it
    let parsed = queryString.parse(window.location.search);
    const [token, setToken] = useState(parsed.token);

    //capitalized brand name
    const ownerCenterLink = getBrandUrl();
    const ff_2224243_caps_v3_migration = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2224243_CAPS_V3_MIGRATION_TARGETABLE) === "true";

    // 1.) VERIFY TOKEN, CHECK IF IT'S EXPIRED (ON PAGE LOAD)
    useEffect(() => {
        if (!ff_2224243_caps_v3_migration) {
            const url = `/wcs/resources/store/${AppSettings.storeId}/GMPerson/resetpassword/verifyToken`;
            const config = {
                params: {
                    "verifyToken": token,
                    "responseFormat": "json"
                },
                headers: {
                    "Content-Type": "application/json"
                }
            }
            axios.get(url, config).then(result => {
                if (result.status == 200) {
                    if (result.data.status == 'failure') {
                        window.location.href = '/forgotUsernamePassword/resetExpired';
                    }
                    else {
                        let email = result.data.userName ? result.data.userName : '';
                        let emailKey = result.data.emailKey ? result.data.emailKey : token
                        setUserName(email.toLowerCase());
                        setToken(emailKey);
                        setPwd(true);
                    }
                }
                else {
                   window.location.href = '/forgotUsernamePassword/resetExpired';
                }
            }).catch(() => {
                window.location.href = '/forgotUsernamePassword/resetExpired';
            });
        } else {
            const payload = {
                verificationCode: token
            }
            updatePasswordToken(payload).then((result) => {
                if (result.status == 200) {
                    let email = result.data.username ? result.data.username : '';
                    let emailKey = result.data.token ? result.data.token : token
                    setUserName(email.toLowerCase());
                    setToken(emailKey);
                    setPwd(true);
                }
                else {
                   window.location.href = '/forgotUsernamePassword/resetExpired';
                }
            }).catch(() => {
                window.location.href = '/forgotUsernamePassword/resetExpired';
            });
        }
    }, []);


    // 2.) VALIDATE PASSWORD ENTERED BY USER IN FORM
    function handlePasswordUpdate(username, password) {
        // send user-entered password in body to validate it
        let body = {
            password: password
        };
        setShowSpinner(true);

        // When submitting through the use of pressing enter, if loading bar is wanted, then this focuses on the button so that the loading bar can be seen.
        submitButtonFocus();

        // validate password
        axios.post(`/wcs/resources/store/${AppSettings.storeId}/GMPerson/resetpassword/validation?responseFormat=json`, body)
            .then(result => {
                // if password is valid, make reset password call
                if (result.status == 200) {
                    handleResetPassword(username, password, token);
                }
                else {
                    setErrorMsg("LABEL_PASSWORD_RESET_INVALID_PASSWORD");
                    setShowSpinner(false);
                }
            }).catch((error) => {
                setErrorMsg("LABEL_SERVER_ERROR_MSG");
                setShowSpinner(false);
            });

        return ''
    }

    // 3.) RESET USER'S PASSWORD AND SEND TO AZURE LOGIN
    function handleResetPassword(username, password, token) {
        // confirm password reset, update user's info
        let body = {
            brand: brand(),
            newPassword: password,
            username: username,
            verificationCode: token
        };
        axios.post(`/wcs/resources/store/${AppSettings.storeId}/GMPerson/resetpassword/confirm?responseFormat=json`, body)
            .then(result => {
                // if password is updated successfully, send user to azure login page and back to brand home page
                if (result.status == 200) {
                    handleUserLogin('/', username); // 1st arg: return to after logging in / 2nd arg: prepopulate email field 
                }
                else {
                    setErrorMsg("LABEL_SERVER_ERROR_MSG");
                }
                setShowSpinner(false);
            }).catch((error) => {
                setShowSpinner(false);
                setErrorMsg("LABEL_SERVER_ERROR_MSG");
            });
    }

    if (displayPwdForm) {
        return (
            <Translation>{(t) => (
                <CenterGrid headerLabel="LABEL_PASSWORD_RESET_PAGE_TITLE">
                    {/* Display any errors */}
                    {errorMsg && <ErrorMessage icon={true} message={t(errorMsg)} />}

                    {/* Form for updating/saving new password */}
                    <UpdatePasswordForm
                        username={username}
                        onPasswordChange={e => setPassword(e.target.value)}
                        onSubmit={() => handlePasswordUpdate(username, password)}
                        isLoading={showSpinner}
                        password={password} />

                    {/* Terms and Conditions by brand*/}
                    <Footnote
                        localeKey="LABEL_PASSWORD_RESET_FOOTNOTE"
                        href={[ownerCenterLink]} />
                </CenterGrid>
            )}</Translation>
        )
    }
    else return null

}

export default UpdatePassword;
