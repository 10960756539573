import React, { Component } from 'react';

export class DescriptionTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const createMarkup = (text) => {
            return {
                __html: text
            }
        };
        return(
            <div className="ac-tabContent"><h2 className="q-headline2">{this.props.descriptionTitle}</h2>
                {
                    /** DISPLAY PRODUCT DESCRIPTION MESSAGE IF THERE IS NO PROP 65 AVAILABLE ***/
                    <h5 className="q-text">{this.props.descriptionData.Description}</h5>
                }
                {
                    /*** ADDING PROP65 FLAG IF THERE IS ANY ***/
                    (this.props.descriptionData.prop65Message && this.props.descriptionData.prop65Message !== "") &&
                    <div className="ac-prop65">

                        <span className="ac-propImage"><img src={this.props.prop65ImageUrl} alt={this.props.prop65ImageUrl} />
                            <h5  dangerouslySetInnerHTML={createMarkup(this.props.descriptionData.prop65Message)} />
                            </span>
                    </div>

                }

            </div>
        );
    }
}

export default DescriptionTab
