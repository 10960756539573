
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export class DesktopTabs extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>
                <div className="ac-product-tabs">
                    <Tabs>
                        <TabList className="react-tabs__tab-list stat-tab-link" data-dtm="subheader breadcrumb">
                            {
                                /*************** DESCRIPTION TITLE *******************************************/
                                this.props.isDescription &&<Tab style={this.props.customStyle}>{this.props.descriptionTitle}</Tab>
                            }

                            {
                                /*************** WHEEL PACKAGE TITLE *******************************************/
                                this.props.isWheelPackage && <Tab style={this.props.customStyle}>{this.props.wheelPackageTitle}</Tab>
                            }

                            {
                                /*************** WARRANTY TITLE *******************************************/
                                this.props.isWarranty && <Tab style={this.props.customStyle}>{this.props.warrantyTitle}</Tab>
                            }

                            {
                                /*************** SPECIFICATION TITLE *******************************************/
                                this.props.isSpecs && <Tab style={this.props.customStyle}>{this.props.specificationTitle}</Tab>
                            }

                            {
                                /*************** DOCUMENTATION TITLE *******************************************/
                                this.props.isDocumentation && <Tab style={this.props.customStyle}>{this.props.documentationTitle}</Tab>
                            }

                            {
                                /*************** FAQ TITLE *******************************************/
                                this.props.isFaq && <Tab style={this.props.customStyle}>{this.props.faqTitle}</Tab>
                            }

                            {
                                /*************** TROUBLE SHOOTING TITLE *******************************************/
                                this.props.isTroubleshooting && <Tab style={this.props.customStyle}>{this.props.troubleshootingTitle}</Tab>
                            }

                            {
                                /*************** MAINTENANCE TITLE *******************************************/
                                this.props.isMaintenance && <Tab style={this.props.customStyle}>{this.props.maintenanceTitle}</Tab>
                            }

                        </TabList>

                        {
                            /*************** DESCRIPTION CONTENT *******************************************/
                            this.props.isDescription && <TabPanel><div>{this.props.descriptionContent}</div></TabPanel>
                        }

                        {
                            /*************** WHEEL PACKAGE TITLE *******************************************/
                            this.props.isWheelPackage && <TabPanel><div>{this.props.wheelPackageContent}</div></TabPanel>
                        }

                        {
                            /*************** WARRANTY CONTENT *******************************************/
                            this.props.isWarranty && <TabPanel><div>{this.props.warrantyContent}</div></TabPanel>
                        }

                        {
                            /*************** SPECIFICATION CONTENT *******************************************/
                            this.props.isSpecs && <TabPanel><div>{this.props.specificationContent}</div></TabPanel>
                        }

                        {
                            /*************** DOCUMENTATION CONTENT *******************************************/
                            this.props.isDocumentation && <TabPanel><div>{this.props.documentationContent}</div></TabPanel>
                        }

                        {
                            /*************** FAQ CONTENT *******************************************/
                            this.props.isFaq && <TabPanel><div>{this.props.faqContent}</div></TabPanel>
                        }

                        {
                            /*************** TROUBLESHOOTING CONTENT *******************************************/
                            this.props.isTroubleshooting && <TabPanel><div>{this.props.troubleshootContent}</div></TabPanel>
                        }

                        {
                            /*************** MAINTENANCE CONTENT *******************************************/
                            this.props.isMaintenance && <TabPanel><div>{this.props.maintenanceContent}</div></TabPanel>

                        }

                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DesktopTabs



