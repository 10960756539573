import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    disableBtn,
    resetVehicleConfig,
    selectBody,
    selectDrive,
    selectEngine,
    selectTrim,
    selectWheel
} from "../../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import queryString from "query-string";
import propTypes from "prop-types";
import InlineSharedYMMDropdown from "./InlineSharedYMMDropdown";
import AppSettings from "../../../core/AppSettings";

const SharedYMMSearchExtend = (props) => {
    const dispatch = useDispatch();

    /** * Using Selector to get all the data/state from reducer ***/
    const bodyFitmentSelection =
        useSelector((state) => state.VehicleConfigReducer.fitmentSelections.bodyFitmentSelection) || [];
    const wheelFitmentSelection =
        useSelector((state) => state.VehicleConfigReducer.fitmentSelections.wheelFitmentSelection) || [];
    const trimFitmentSelection =
        useSelector((state) => state.VehicleConfigReducer.fitmentSelections.trimFitmentSelection) || [];
    const driveFitmentSelection =
        useSelector((state) => state.VehicleConfigReducer.fitmentSelections.driveFitmentSelection) || [];
    const engineFitmentSelection =
        useSelector((state) => state.VehicleConfigReducer.fitmentSelections.engineFitmentSelection) || [];
    const bodyStyleId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.bodyStyleId);
    const wheelBaseId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.wheelBaseId);
    const trimId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.trimId);
    const driveTypeId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.driveTypeId);
    const engineId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.engineId);
    const selectYear = useSelector((state) => state.VehicleSelectReducer.selectedYear) || "";
    const selectMake = useSelector((state) => state.VehicleSelectReducer.selectedMake) || "";
    const selectMakeId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.makelId) || "";
    const selectModel = useSelector((state) => state.VehicleSelectReducer.selectedModel) || "";
    const selectModelId = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.modelId);

    const vehicleInfo = useSelector((state) => state.Session.vehicleConfig);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
    };

    useEffect(() => {
        setIsMobile(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
        window.addEventListener("resize", handleResize);
    }, []);

    /** * Reset the data if YMM info Change ***/
    useEffect(() => {
        //Prevents reset for inline fitment on ymm change as extend dropdowns will only show once ymm is locked in and can't be changed.
        if (!props.inlineFitmentYMM) {
            if (!isMobile) {
                if (selectYear || selectMake || selectModel) {
                    dispatch(resetVehicleConfig());
                }
            } else {
                const queryYear = queryString.parse(location.search).year || "";
                const queryMake = queryString.parse(location.search).make || "";
                const queryModel = queryString.parse(location.search).model || "";
                if (selectYear !== queryYear || selectMake !== queryMake || selectModel !== queryModel) {
                    dispatch(resetVehicleConfig());
                }
            }
        }
    }, [selectYear, selectMake, selectModel]);

    // pre-populate additional YMM dropdowns if the original vehicle info is still selected and reset CTA is not pressed.
    useEffect(() => {
        if (props.inlineFitmentYMM && !props.hasReset) {
            dispatch(selectBody(vehicleInfo));
            dispatch(selectTrim(vehicleInfo));
            dispatch(selectDrive(vehicleInfo));
            dispatch(selectWheel(vehicleInfo));
            dispatch(selectEngine(vehicleInfo));
        }
    }, []);

    /** * Dispatch Default Item ***/
    const dispatchDefaultItem = () => {
        if (!bodyStyleId && bodyFitmentSelection.length === 1 && props.neededFitment?.includes("bodyStyle")) {
            dispatch(selectBody(bodyFitmentSelection[0]));
        }
        if (!wheelBaseId && wheelFitmentSelection.length === 1 && props.neededFitment?.includes("wheelBase")) {
            dispatch(selectWheel(wheelFitmentSelection[0]));
        }
        if (!trimId && trimFitmentSelection.length === 1 && props.neededFitment?.includes("trim")) {
            dispatch(selectTrim(trimFitmentSelection[0]));
        }
        if (!driveTypeId && driveFitmentSelection.length === 1 && props.neededFitment?.includes("driveType")) {
            dispatch(selectDrive(driveFitmentSelection[0]));
        }
        if (!engineId && engineFitmentSelection.length === 1 && props.neededFitment?.includes("engineBase")) {
            dispatch(selectEngine(engineFitmentSelection[0]));
        }
    };

    useEffect(() => {
        dispatchDefaultItem();
    }, [
        bodyFitmentSelection,
        wheelFitmentSelection,
        trimFitmentSelection,
        driveFitmentSelection,
        engineFitmentSelection
    ]);

    return (
        <InlineSharedYMMDropdown
            autoComplete={props.autoComplete}
            bodyFitmentSelection={bodyFitmentSelection}
            wheelFitmentSelection={wheelFitmentSelection}
            trimFitmentSelection={trimFitmentSelection}
            driveFitmentSelection={driveFitmentSelection}
            engineFitmentSelection={engineFitmentSelection}
            make={selectMake}
            model={selectModel}
            year={selectYear}
            modelId={selectModelId}
            makeId={selectMakeId}
            handleCloseModal={props.handleCloseModal}
            handleAddToCartSRError={props.handleAddToCartSRError}
            handleAddToCartPDError={props.handleAddToCartPDError}
            inlineFitmentYMM={props.inlineFitmentYMM}
            neededFitment={props.neededFitment}
            steps={props.steps}
            handleVehicleBreadcrumb={props.handleVehicleBreadcrumb}
            showFitHandler={props.showFitHandler}
            hasReset={props.hasReset}
        />
    );
};

SharedYMMSearchExtend.propTypes = {
    handleCloseModal: propTypes.func,
    handleAddToCartSRError: propTypes.func,
    handleAddToCartPDError: propTypes.func
};

export default SharedYMMSearchExtend;
