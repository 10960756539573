import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import PasswordForm from './PasswordForm/PasswordForm';


class Password extends Component {
    render() {
        return (
            <Translation>
                {
                    (t) => (
                        <div className="ac-password-section small-12 grid-column-alignment-left columns">
                            <h3 className="q-headline3">Password</h3>
                            <div className="ac-password-email">
                                <h5>{t("LABEL_COMMON_EMAIL")}: john-doe@email.com</h5>
                            </div>

                            <PasswordForm/>

                            <div className="ac-password-info">
                                <div className="ac-password-requirements">
                                    <p className="q-descriptive2">{t("LABEL_PROFILE_PASSWORD_INCLUDE")}</p>

                                    <div className="q-body2 small-12 grid-column-alignment-left columns ac-nopadding-x">
                                        <div className="small-4 grid-column-alignment-left columns ac-nopadding-x">
                                            {t("LABEL_PROFILE_PASSWORD_INSTRUCTION_1")}
                                        </div>
                                        <div className="small-4 grid-column-alignment-left columns ac-nopadding-x">
                                            {t("LABEL_PROFILE_PASSWORD_INSTRUCTION_2")}
                                        </div>
                                    </div>
                                </div>
                                <p className="q-body2">{t("LABEL_PROFILE_PASSWORD_NOTE")}</p>
                            </div>
                            <div className="ac-change-password small-12 medium-12 large-3 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                                <button className="q-button q-primary-button gm-primary-btn">{t("LABEL_PROFILE_CHANGE_PASSWORD")}</button>
                            </div>
                        </div>
                    )
                }

            </Translation>
        )
    }
}

export default Password;