import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import store from "../../core/Redux/Store";
import AppSettings from "../../core/AppSettings";
import { useLocation } from "react-router-dom";
import DealerSelectModal from './Components/DealerSelectModal';
import DealerizationModalButton from "./Components/Buttons/DealerizationModalButton";
import { updateSelectedDealer } from "./Data/ApiService";

import {
    cleanResponseArr,
    resetErrors,
    useDefaultZip,
    validateBac,
    validateMake,
    isValidUSZip, storeSearchZip
} from "./Components/HelperFunctions";
import {
    getDealersByZipcode,
    getDealersByCity,
    getDealersByName,
    getDealerInfoByBac,
    setDealerCookie,
    deleteDealerCookie,
    validateDealerCookie,
    readDealerCookie
} from "./Data/ApiService";
import ErrorBoundary from "../../shared/ErrorBoundary/ErrorBoundary";
import vaultConstants from "../../../config/vault_constants";

/**
 * ADDING IDs Data-dtm value subject to change. awaiting business and/or qa specs
 * Remove temp/TODO values
 * WAIT FOR GET DEALERS BY BAC CALL
 * REMOVE DEALER DATA FROM SESSION STORAGE
 *
 * @param {*} props
 */

const sitesArray = Object.values(AppSettings.sitesStoreMap);

export default function DealerLocator() {
    const { t } = useTranslation();
    const dealerizationState = useSelector((state) => state.DealerizationReducer);
    const cartState = useSelector((state) => state.MiniCartReducer && state.MiniCartReducer.data && state.MiniCartReducer.data.dealerizationInfo);
    const isLoggedIn = useSelector((state) => state.Authentication.registrationStatus);
    const [updateCookie, setUpdateCookie] = useState(null);
    const dispatch = useDispatch();
    const pathName = useLocation().pathname;
    const [dealerList, setDealerList] = useState(false); //Used to show error for no dealers whenever user hits search multiple times for a zip with no dealers
    /** Flags */
    const MAP_FLAG = dealerizationState.showMap;
    const CART_ITEMS = store.getState().MiniCartReducer.count !== 0;
    const CHECKOUT = pathName.includes("checkout");
    useEffect(() => {}, [pathName.includes("checkout")]);
    /** COOKIE DATA */
    const LOCATION_COOKIE = Cookies.get("GMWP_location");
    const VALIDATE = "Validate";
    const VALIDATE_LOCATION = "Validate Location";
    const restApiContractsFeatureFlag = AppSettings.isLocalHost
    ? true
    : String(vaultConstants.FF_2243221_REST_API_CONTRACTS) === "true";

    /** CONDITIONAL STYLING */
    const MODAL_STYLE = MAP_FLAG ? "prt-dealer-locator-modal-pd-view" : "prt-dealer-locator-modal";

    // ========= Grab Data =========================================================
    /**
     * formatting div labels for the shared radio button component.
     */
    const createSrcRadioLabels = () => {
        const sourceRadio =
            dealerizationState.listOfAllSources?.length !== 0 &&
            dealerizationState.listOfAllSources?.map((item, index) => {
                const brandLabel = sitesArray.find((site) => site.key === String(item.vendorMake).toLowerCase())?.label || "";
                const rewardsLabel = t("LABEL_DEALER_LOCATOR_PARTICIPATES_IN_REWARDS", { brand: brandLabel });
                const radioLabel = (
                    <React.Fragment key={index}>
                        <div className="source-radio-labels">
                            <h5>
                            {item.dealerName}{" "}
                                {dealerizationState.searchType === "Zip" &&
                                    t("LABEL_DEALER_LOCATOR_X_MILES", { num: item.distance })}
                            </h5>
                            <div>{item.addr1}</div>
                            <div>{t("LABEL_COMMON_ADDRESS_FORMAT", { 2: item.city, 3: item.state, 4: item.zip })}</div>
                            {item.rewardsProgram && <div className="prt-source-feature-text">{rewardsLabel}</div>}
                        </div>
                    </React.Fragment>
                );
                return { label: radioLabel, id: item.id };
            });
        dispatch({ type: "UPDATE_LIST_OF_SELLERS", listOfSellers: sourceRadio }); // default
    };

    /**
     * createCondensedDealerArr()
     * chooses which getDealerInfo call to make whether input is a zipcode or coordinates
     * creates a condensed list of dealer data with just the needed information to create
     * the radio buttons.
     *
     * @param {*} locationArg string
     * either in the format of 'XXXXX' for zipcode or  'X.XXXXXX, Y.YYYYYY' for coordinates
     * UPDATE: Coordinates are no longer used here since they're converted to a zip
     */
    const createCondensedDealerArr = (locationArg) => {
        const param = {
            location: locationArg,
            partNumber: dealerizationState.partNumber
        };
        dispatch({ type: "TOGGLE_SPINNER", showSpinner: true });

        if (dealerizationState.searchType === "Zip" && param.location) {
            getDealersByZipcode(param).then((response) => {
                if (response?.data?.dealers?.length) {
                    dispatch({
                        type: "UPDATE_LIST_OF_ALL_SOURCES",
                        listOfAllSources: cleanResponseArr(response.data.dealers)
                    });
                    dispatch({ type: "TOGGLE_ZIP_ERROR", showZipcodeError: false });
                } else {
                    const errorType = response.status === 204
                        ? t("ERROR_DEALER_LOCATOR_NO_DEALERS", { src: dealerizationState.sellerSource })
                        : t("ERROR_DEALER_LOCATOR_INVALID_ZIP");
                    dispatch({ type: "SET_ZIP_ERROR_MSG", zipErrorMsg: errorType });
                    dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                    dispatch({ type: "TOGGLE_ZIP_ERROR", showZipcodeError: true });
                }
            }).catch((error) => { //errors 400 and above
                const errorType =  t("ERROR_DEALER_LOCATOR_NO_RESPONSE");
                dispatch({ type: "SET_ZIP_ERROR_MSG", zipErrorMsg: errorType });
                dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                dispatch({ type: "TOGGLE_ZIP_ERROR", showZipcodeError: true });
            }).finally(() => {
                dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
            }); 
        } else if (dealerizationState.searchType === "City") {
            getDealersByCity(param).then((response) => {
                if (response?.data?.dealers?.length) {
                    dispatch({
                        type: "UPDATE_LIST_OF_ALL_SOURCES",
                        listOfAllSources: cleanResponseArr(response.data.dealers)
                    });
                    dispatch({ type: "TOGGLE_CITY_ERROR", showCityError: false });
                } else {
                    const errorType = response.status === 204
                        ? t("ERROR_DEALER_LOCATOR_NO_DEALERS_CITY", { src: dealerizationState.sellerSource })
                        : t("ERROR_DEALER_LOCATOR_INVALID_CITY");
                    dispatch({ type: "SET_CITY_ERROR_MSG", cityErrorMsg: errorType });
                    dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                    dispatch({ type: "TOGGLE_CITY_ERROR", showCityError: true });
                }
            }).catch((error) => { //errors 400 and above
                const errorType =  t("ERROR_DEALER_LOCATOR_NO_RESPONSE");
                dispatch({ type: "SET_CITY_ERROR_MSG", cityErrorMsg: errorType });
                dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                dispatch({ type: "TOGGLE_CITY_ERROR", showCityError: true });
            }).finally(() => {
                dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
            }); 
        } else if (dealerizationState.searchType === "Seller") {
            getDealersByName(param).then((response) => {
                if (response?.data?.dealers?.length) {
                    dispatch({
                        type: "UPDATE_LIST_OF_ALL_SOURCES",
                        listOfAllSources: cleanResponseArr(response.data.dealers)
                    });
                    dispatch({ type: "TOGGLE_NAME_ERROR", showNameError: false });
                } else { //covers errors below 400
                    const errorType = response.status === 204
                        ? t("ERROR_DEALER_LOCATOR_NO_DEALERS_NAME", { src: dealerizationState.sellerSource })
                        : t("ERROR_DEALER_LOCATOR_INVALID_NAME");
                    dispatch({ type: "SET_NAME_ERROR_MSG", nameErrorMsg: errorType });
                    dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                    dispatch({ type: "TOGGLE_NAME_ERROR", showNameError: true });
                }
            }).catch((error) => { //errors 400 and above
                const errorType =  t("ERROR_DEALER_LOCATOR_NO_RESPONSE");
                dispatch({ type: "SET_NAME_ERROR_MSG", nameErrorMsg: errorType });
                dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                dispatch({ type: "TOGGLE_NAME_ERROR", showNameError: true });
            }).finally(() => {
                dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
            });
        } else {
            if (String(vaultConstants.API_TARGETABLE).includes("dev")) {
                console.log("unable to fetch dealer list.");
            }
            dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
        }
    };
    
    // Check when user logs in and reset dealer cookie using minicartreducer data
    useEffect(() => {
        if (cartState && cartState.zipcode && cartState.bac && cartState.make) {
            const resetDealerCookie = {
                make: [cartState.make],
                bac: [cartState.bac],
                location: cartState.zipcode.substring(0, 5)
            };

            if (
                validateBac(resetDealerCookie.bac) &&
                isValidUSZip(resetDealerCookie.location) &&
                validateMake(resetDealerCookie.make)
            ) {
                setDealerCookie(
                    JSON.stringify([cartState.bac]),
                    JSON.stringify([cartState.make]),
                    resetDealerCookie.location
                ).then(() => {
                    setUpdateCookie((prev) => prev === null ? true : !prev);
                });
            }
        } else {
            // Need this if loading onto page with dealer cookie and no cart to set dealer data in redux
            setUpdateCookie((prev) => prev === null ? true : !prev);
        }
    }, [JSON.stringify(cartState)]);

    // Validate cookie only when coming back to the site, if validation fails, just remove cookie. Cookie and reducerstate won't be in sync, but this should be
    // fine as we only need to care about the cookie when revisiting site/
    useEffect(() => {
        validateDealerCookie().then(async (res) => {
            if (res && res.status === 200 && (res.data && res.data.msg === VALIDATE || res.data && res.data.msg === VALIDATE_LOCATION)) {
                // Before going into all of the logic below, check for BAC in the URL. if it exists, auto-dealerize user
                // Within this flow we are trying to override the dealer or location that is currently saved.
                if (AppSettings.bac) {
                    deleteDealerCookie().then(() => {
                        dealerizeUser(AppSettings.bac);
                    });
                } else {
                    const potentialCookie = JSON.parse(res.data.cookie);
                    let validBac,
                        validZip = false;
                    if (potentialCookie.bac) {
                        //User is dealerized
                        try {
                            validBac = await getDealerInfoByBac(potentialCookie).then((response) => {
                                if (response) {
                                    return true;
                                } else {
                                    throw new Error("Try Contract call");
                                }
                            });
                        } catch (error) {
                            validBac = await getDealerInfoByBac(potentialCookie).then(
                                (response) => (response ? true : false),
                                (error_1) => false
                            );
                        }

                        validZip = await getDealersByZipcode(potentialCookie).then(
                            (response) => {
                                return response.data && response.data.dealers && response.data.dealers.length ? true : false;
                            },
                            (error) => {
                                return false;
                            }
                        );

                        if (!validBac || !validZip) {
                            //failed zip/bac check, remove cookie
                            deleteDealerCookie();
                        }
                    } else {
                        //User has saved location
                        validZip = await getDealersByZipcode(potentialCookie).then(
                            (response) => {
                                return response.data && response.data.dealers && response.data.dealers.length ? true : false;
                            },
                            (error) => {
                                return false;
                            }
                        );

                        if (!validZip) {
                            //failed Zip check, remove cookie
                            deleteDealerCookie();
                        }
                    }
                }
            } else {
                //response comes back as empty or as remove, meaning cookie should be removed or is empty, so try to reset cookie using minicart data
                if (cartState && cartState.data && cartState.data.dealerizationInfo) {
                    const resetDealerInfo = cartState.data.dealerizationInfo;
                    const resetDealerCookie = {
                        make: [resetDealerInfo.make],
                        bac: [resetDealerInfo.bac],
                        location: resetDealerInfo.zipcode.substring(0, 5)
                    };
                    if (
                        validateBac(resetDealerCookie.bac) &&
                        isValidUSZip(resetDealerCookie.location) &&
                        validateMake(resetDealerCookie.make)
                    ) {
                        setDealerCookie(JSON.stringify([cartState.bac]), JSON.stringify([cartState.make]), location);
                    } else {
                        deleteDealerCookie();
                        useDefaultZip(dispatch, LOCATION_COOKIE);
                    }
                } else {
                    // Dealerize user if BAC exists in the url.
                    if (AppSettings.bac && cartState == undefined) {
                        dealerizeUser(AppSettings.bac);
                    } else {
                        deleteDealerCookie();
                        useDefaultZip(dispatch, LOCATION_COOKIE);
                    }

                }
            }
        });
    }, []);

    useEffect(async () => {
        if (updateCookie != null) {
            const source = "dealer";
            dispatch({type: "UPDATE_SELLER_SOURCE", sellerSource: source});
            let potentialDealerCookie = await readDealerCookie().then((res) => {
                if (res && res.status === 200 && res.data && res.data.cookie) {
                    return JSON.parse(res.data.cookie);
                } else {
                    return "";
                }
            });

            if (potentialDealerCookie) {
                dispatch({type: "SOURCE_RESULTS", sourceResults: "Zip"});
                dispatch({type: "UPDATE_CURRENT_ZIP", currentZip: potentialDealerCookie.location});
                dispatch({type: "UPDATE_USER_INPUT_ZIP", userInputZip: potentialDealerCookie.location});
                if (potentialDealerCookie.bac) {
                    // if the user has been previously dealerized...
                    //If the user hits save and close these calls are unnecessary (functionality is handled in handleSaveAndClose function in SaveAndCloseButton.js)

                    getDealerInfoByBac(potentialDealerCookie).then((res) => {
                        if (res) {
                            dispatch({type: "UPDATE_CURRENT_SRC", currentSourceName: res.dealerName});
                            dispatch({
                                type: "UPDATE_SELECTED_SOURCE",
                                selectedDealerAllData: res,
                                selectedSource: potentialDealerCookie.bac[0]
                            });
                            let isContractPresent = "";
                            if (res) {
                                isContractPresent = res.status === 200 ? "true" : "false";
                            } else {
                                isContractPresent = "error";
                            }
                            dispatch({type: "TOGGLE_CONTRACT", contractStatus: isContractPresent});
                        } else {
                            // no dealer comes back, use GMWP_location zip data.
                            deleteDealerCookie();
                            useDefaultZip(dispatch, LOCATION_COOKIE);
                        }
                    });
                } else {
                    //bac cookie not having bac means user isn't dealerized - case where user enters zip/location, but doesn't dealerize
                    createCondensedDealerArr(potentialDealerCookie.location);
                    if (potentialDealerCookie.location.length == 5) {
                        //is zip
                        dispatch({type: "UPDATE_CURRENT_SRC", currentSourceName: potentialDealerCookie.location});
                    }
                }
            } else {
                // user has never been dealerized...
                useDefaultZip(dispatch, LOCATION_COOKIE);
            }
        }
    }, [updateCookie, JSON.stringify(cartState)]);

    /* re-render radio buttons when the list is update via coordinates or zip code. */
    useEffect(() => {
        createSrcRadioLabels();
    }, [dealerizationState.listOfAllSources]);

    /* after the user clicks 'search', make a backend call to update list of dealers. */

    useEffect(() => {
        if (dealerizationState.searchType === "Seller") {
            createCondensedDealerArr(dealerizationState.currentSeller);
        } else if (dealerizationState.searchType === "Zip") {
            createCondensedDealerArr(dealerizationState.currentZip);
        } else if (dealerizationState.searchType === "City") {
            createCondensedDealerArr(dealerizationState.currentCity);
        }
    }, [dealerizationState.currentSeller, dealerizationState.currentZip, dealerizationState.currentCity, dealerizationState.partNumber, dealerList]);
    
        // when user leaves modal, prev values will be used, but if partnumber was recently added, need to update listofallsources for pricing
    useEffect(() => {
        if (dealerizationState.currentZip && dealerizationState.showMap && dealerizationState.searchType == "Zip") {
            createCondensedDealerArr(dealerizationState.currentZip);
        } else if (dealerizationState.showMap && dealerizationState.searchType == "Seller") {
            createCondensedDealerArr(dealerizationState.userInputSeller);
        } else {
            createCondensedDealerArr(dealerizationState.currentCity);
        }

        if (dealerizationState.showMap) {
            //updates initial map data when user is dealerized since selectedDealerAllData does not use listOfAllSources format (no pos field)
            if (
                !dealerizationState.selectedDealerAllData.pos &&
                Object.keys(dealerizationState.selectedDealerAllData).length !== 0
            ) {
                dealerizationState.listOfAllSources.map((dealer, index) => {
                    if (dealer.bac === dealerizationState.selectedDealerAllData.bac) {
                        const mapArg = {
                            coordinates: { lat: parseFloat(dealer.pos.lat), lng: parseFloat(dealer.pos.long) },
                            googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-adamopelag",
                            zoom: 20
                        };
                        dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
                    }
                });
            }
        }
    }, [dealerizationState.showMap]);

    function dealerizeUser(bac) {
        // This logic is the same as when the user Saves in the dealerization modal.
        updateSelectedDealer(bac).then((response) => {
            if (response.status === 200) {
                if (
                    (restApiContractsFeatureFlag && response.data.viewTaskName === "GMContractSetupView") ||
                    (!restApiContractsFeatureFlag &&
                        response.data ===
                            "<!-- GM B2C Dealer Contract setup test  page --><!-- setup the cookies with contract to get dealer price -->")
                ) {
                    dispatch({ type: "TOGGLE_CONTRACT", contractStatus: true });
                }

                let bacCookie = {
                    "bac": [bac],
                    "make": [AppSettings.thisMake.label]
                }
                getDealerInfoByBac(bacCookie).then(res => {                                
                    if (res) {
                        dispatch({
                            type: "UPDATE_SELECTED_SOURCE",
                            selectedDealerAllData: res,
                            selectedSource: "0_" + res.bac
                        });
                        dispatch({ type: "UPDATE_CURRENT_SRC", currentSourceName: res.dealerName });
                        setDealerCookie(
                            JSON.stringify([res.bac]),
                            JSON.stringify([AppSettings.thisMake.label.toLowerCase()]),
                            res?.zipcode ? res.zipcode.slice(0, 5) : ""
                        );

                        if (res?.zipcode) {
                            const param = { location: res.zipcode.slice(0, 5) };
                            getDealersByZipcode(param).then((res) => {
                                if (res?.data?.dealers?.length) {
                                    dispatch({
                                        type: "UPDATE_LIST_OF_ALL_SOURCES",
                                        listOfAllSources: cleanResponseArr(res.data.dealers)
                                    });
                                    dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: param.location});
                                }
                                else {
                                    dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                                }
                            }, () => {
                                dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                                useDefaultZip(dispatch, LOCATION_COOKIE)
                            })
                                
                        }
                        // Zipcode needs to be set this way for City and Dealer searches. Otherwise add to cart would not work due to an error.
                        storeSearchZip(res?.zipcode ? res.zipcode : "");
                    }
                }, () => {
                    deleteDealerCookie();
                    useDefaultZip(dispatch, LOCATION_COOKIE);
                });
            } else { //Response is not 200 - do we need this if we have second callback handler?
                /** ERRORS */
                deleteDealerCookie();
                useDefaultZip(dispatch, LOCATION_COOKIE);
                props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
            }
        }, () => {
            deleteDealerCookie();
            useDefaultZip(dispatch, LOCATION_COOKIE);
            props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
        })
    }

    // ==================== Final UI Render ==================================
    return CHECKOUT || AppSettings.isT3 ? (
        <></>
    ) : (
        <ErrorBoundary message="Error occurred on dealer locator component">
            <div className="prt-dealer-locator-container" data-dtm={"dealer locator"}>
            <DealerizationModalButton state={dealerizationState} dispatch={dispatch}/>
            <DealerSelectModal 
                MAP_FLAG={MAP_FLAG} 
                MODAL_STYLE={MODAL_STYLE} 
                CART_ITEMS={CART_ITEMS} 
                dealerizationState={dealerizationState}
                setDealerList={setDealerList}
            />
            </div>
        </ErrorBoundary>
    );
}
