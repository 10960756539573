import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

/**
 * SharedProgressBar
 *
 * Props:
 * stepCount: Number of total steps
 * completedSteps: Number of steps completed / Current step
 *
 *
 * import SharedProgressBar from "/shared/ProgressBar/SharedProgressBar.js"
 *
 * input number of steps total and number of steps completed and component will
 * display progress bar showing previous completed steps, current step in progress, and upcoming incomplete steps.
 */

const SharedProgressBar = (props) => {
    const stepCount = props.stepCount ? props.stepCount : 1;
    const completedSteps = props.completedSteps ? props.completedSteps : 0;
    const { t } = useTranslation();

    const buildProgressBar = () => {
        var amountCompleted = 0;
        var progressBar = [];

        while (amountCompleted < stepCount) {
            if (amountCompleted < completedSteps) {
                progressBar.push(<SharedProgressBubble status={"complete"} />);
                if (amountCompleted != stepCount - 1) {
                    progressBar.push(<hr id="shared-progress-line-complete" />);
                }
            } else if (amountCompleted == completedSteps) {
                progressBar.push(<SharedProgressBubble status={"current"} />);
                if (amountCompleted != stepCount - 1) {
                    progressBar.push(<hr id="shared-progress-line-incomplete" />);
                }
            } else if (amountCompleted != stepCount && amountCompleted >= completedSteps) {
                progressBar.push(<SharedProgressBubble status={"incomplete"} />);
                if (amountCompleted != stepCount - 1) {
                    progressBar.push(<hr id="shared-progress-line-incomplete" />);
                }
            }
            amountCompleted++;
        }
        return progressBar;
    };

    return <div className="shared-progress-bar-container">{buildProgressBar()}</div>;
};

const SharedProgressBubble = (props) => {
    if (props?.status === "complete") {
        return (
            <div className="shared-progress-bar-bubble">
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
        );
    } else if (props?.status === "current") {
        return (
            <div className="shared-progress-bar-bubble-current-wrapper">
                <div id="shared-progress-bar-bubble-current">
                    <div id="inner-circle" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="shared-progress-bar-bubble-incomplete-wrapper">
                <div className="shared-progress-bar-bubble-incomplete" />
            </div>
        );
    }
};

SharedProgressBar.propTypes = {
    stepCount: PropTypes.number.isRequired,
    completedSteps: PropTypes.number.isRequired
};

export default SharedProgressBar;