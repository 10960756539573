import { getVehicleCombinationsData, getFitmentCheckData } from "./FitmentCheckService";
import { setFitmentCheck, setFitmentCheckFail, setVehicleCombinations, setVehicleCombinationsFail, returnToSearch, showFitmentModal } from './FitmentCheckActionCreator';
import { addBodyFitmentSelection, addDriveFitmentSelection, addEngineFitmentSelection, addTrimFitmentSelection, addWheelFitmentSelection } from './FitmentCheckUtils'
import { call, put, select } from "redux-saga/effects";

/**************************************************************/
// GET VALUES FROM REDUCERS FOR API CALLS
/**************************************************************/
export const getProductID = state => state.ProductDetailsReducer.partNumber;
export const getFitmentCombinations = state => state.FitmentCheckReducer.vehicleCombinations;
export const getVehicleParams = state => state.FitmentCheckReducer.vehicleParams;
export const getYear = state => state.Session.year;
export const getMake = state => state.Session.make;
export const getModel = state => state.Session.model;
export const getBodyParam = state => state.FitmentCheckReducer.vehicleParams.bodyParam;
export const getWheelParam = state => state.FitmentCheckReducer.vehicleParams.wheelParam;
export const getTrimParam = state => state.FitmentCheckReducer.vehicleParams.trimParam;
export const getDriveParam = state => state.FitmentCheckReducer.vehicleParams.driveParam;
export const getEngineParam = state => state.FitmentCheckReducer.vehicleParams.engineParam;
export const getVehicleConfig = state => state.Session.vehicleConfig;

/**************************************************************/
// VEHICLE COMBINATIONS CALL
// This data is loaded when the PD page loads
/**************************************************************/
export function* callForVehicleCombinations() {
    //get params for api call (see FitmentCheckService.js)
    const year = yield select(getYear);
    const make = yield select(getMake);
    const model = yield select(getModel);

    let combinations = {}
    let parsedCombinations = {}

    try {
        const vehicleCombinationsResponse = yield call(getVehicleCombinationsData, year, make, model);
        //get all combinations from call
        combinations = vehicleCombinationsResponse.records;
        //parse combinations for each fitment check (body, wheel, trim, drive, engine)
        parsedCombinations = parseVehicleCombinations(combinations);

        //return all combinations, and parsed combinations
        yield put(setVehicleCombinations(combinations, parsedCombinations));
    }

    catch (error) {
        // Dispatches SET_VEHICLE_COMBINATIONS_FAIL to handle the error.
        yield put(setVehicleCombinationsFail(error));
    }
}

/**************************************************************/
// FITMENT CHECK CALL
// This data is loaded when the user selects add to cart
// and again when fitment options have been selected and the user
// selects 'continue' on the fitment modal
/**************************************************************/
export function* callForFitmentCheck() {
    //get params for api call (see FitmentCheckService.js)
    const productID = yield select(getProductID);
    const year = yield select(getYear);
    const make = yield select(getMake);
    const model = yield select(getModel);
    const vehicleConfig = yield select(getVehicleConfig);
    const bodyParam = yield select(getBodyParam);
    const wheelParam = yield select(getWheelParam);
    const trimParam = yield select(getTrimParam);
    const driveParam = yield select(getDriveParam);
    const engineParam = yield select(getEngineParam);

    // check for fitment params (flow where user did not select configurations before the pd page)
    // or vehicle config params (user previously selected configurations before pd page)
    const bodyId = vehicleConfig.bodyId ? vehicleConfig.bodyId : bodyParam ? bodyParam : '';
    const wheelId = vehicleConfig.wheelId ? vehicleConfig.wheelId : wheelParam ? wheelParam : '';
    const trimId = vehicleConfig.trimId ? vehicleConfig.trimId : trimParam ? trimParam : '';
    const driveId = vehicleConfig.driveId ? vehicleConfig.driveId : driveParam ? driveParam : '';
    const engineId = vehicleConfig.engineId ? vehicleConfig.engineId : engineParam ? engineParam : '';

    //values from reducer for data filtering purposes
    const combinations = yield select(getFitmentCombinations);
    const params = yield select(getVehicleParams);
    const parsedCombinations = parseVehicleCombinations(combinations);

    let selectionValues = {}

    try {
        const fitmentCheckResponse = yield call(getFitmentCheckData,
            productID,
            year,
            make,
            model,
            bodyId,
            wheelId,
            trimId,
            driveId,
            engineId);

        //fitmentStatus determines whether the item fits the vehicle (yes),
        // doesn't fit (no), or needs further info to determine fit (more info needed)
        const fitmentStatus = fitmentCheckResponse.fitment.fitmentStatus;

        //If more info is needed, fitmentDetails contains which vehicle field options are needed (i.e. body, wheel, etc)
        const fitmentDetails = fitmentCheckResponse.fitment.fitmentDetails;

        //check if accessory does meets fitmentStatus 'yes' or 'no'
        if (fitmentStatus == 'yes') {
            // yield put(setFitmentCheck(fitmentDetails, fitmentStatus));
        }
        if (fitmentStatus == 'no') {
            yield put(setFitmentCheck(fitmentDetails, fitmentStatus));
            yield put(returnToSearch(true));
        }

        //otherwise check if fitmentDetails returns just one field and selection option
        else {
            yield put(showFitmentModal(true)); // display fitment modal
            selectionValues = checkSingleFitOneSelection(parsedCombinations, fitmentDetails, params)
            yield put(setFitmentCheck(fitmentDetails, fitmentStatus, selectionValues));
        }

    }
    catch (error) {
        yield put(setFitmentCheckFail(error));
    }
}


/**************************************************************/
// PARSE JSON FROM VEHICLE COMBINATIONS CALL
// Flat json is parsed into bodyFitmentSelection, wheelFitmentSelection,
// trimFitmentSelection, driveFitmentSelection, and engineFitmentSelection.
// These values are used to determine dropdown selection options in
// the fitment modal which is then used for further filtering
/**************************************************************/
export function parseVehicleCombinations(combinations) {

    let bodyFitmentSelection = [];
    let wheelFitmentSelection = [];
    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    for (let indexRecord = 0; indexRecord < combinations.length; indexRecord++) {
        /********** CREATE BODY SELECTION ARRAY *****************/
        addBodyFitmentSelection(combinations[indexRecord], bodyFitmentSelection);

        /********** CREATE WHEEL SELECTION ARRAY *****************/
        addWheelFitmentSelection(combinations[indexRecord], wheelFitmentSelection);

        /********** CREATE TRIM SELECTION ARRAY *****************/
        addTrimFitmentSelection(combinations[indexRecord], trimFitmentSelection);

        /********** CREATE DRIVE SELECTION ARRAY *****************/
        addDriveFitmentSelection(combinations[indexRecord], driveFitmentSelection);

        /********** CREATE ENGINE SELECTION ARRAY *****************/
        addEngineFitmentSelection(combinations[indexRecord], engineFitmentSelection);
    }

    let fitmentSelections = {
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    };

    return fitmentSelections;

};



/**************************************************************/
// Special case scenario where fitment check returns just one
// fitment option (body, wheel, etc) with only one selection option.
// If this runs true, then the fitment check modal is not displayed 
// and the accessory is added to the cart.
// See oneFitOneSelectionFlag in FitmentCheckForm.js
/**************************************************************/
export function checkSingleFitOneSelection(parsedCombinations, fitmentDetails, params) {
    let oneFitOneSelectionFlag = false;
    let bodyNumDoorsParam = params.bodyNumDoorsParam;
    let bodyParam = params.bodyParam;
    let wheelParam = params.wheelParam;
    let trimParam = params.trimParam;
    let driveParam = params.driveParam;
    let engineParam = params.engineParam;

    if (fitmentDetails) {

        if (fitmentDetails.length == 1) {
            switch (fitmentDetails[0]) {
                case "bodyStyle":
                    if (parsedCombinations.bodyFitmentSelection.length == 1) {
                        bodyParam = parsedCombinations.bodyFitmentSelection[0].BODY_TYPE_ID;
                        oneFitOneSelectionFlag = true;
                    }
                    break;
                case "wheelBase":
                    if (parsedCombinations.wheelFitmentSelection.length == 1) {
                        wheelParam = parsedCombinations.wheelFitmentSelection[0].WHEEL_BASE_ID;
                        oneFitOneSelectionFlag = true;
                    }
                    break;
                case "trim":
                    if (parsedCombinations.trimFitmentSelection.length == 1) {
                        trimParam = parsedCombinations.trimFitmentSelection[0].SUBMODEL_ID;
                        oneFitOneSelectionFlag = true;
                    }
                    break;
                case "driveType":
                    if (parsedCombinations.driveFitmentSelection.length == 1) {
                        driveParam = parsedCombinations.driveFitmentSelection[0].DRIVE_TYPE_ID;
                        oneFitOneSelectionFlag = true;
                    }
                    break;
                case "engineBase":
                    if (parsedCombinations.engineFitmentSelection.length == 1) {
                        engineParam = parsedCombinations.engineFitmentSelection[0].ENGINE_BASE_ID;
                        oneFitOneSelectionFlag = true;
                    }
                    break;
                default:
            }

            let fitmentFlags = { oneFitOneSelectionFlag: oneFitOneSelectionFlag }

            let vehicleParams = {
                bodyNumDoorsParam: bodyNumDoorsParam,
                bodyParam: bodyParam,
                wheelParam: wheelParam,
                trimParam: trimParam,
                driveParam: driveParam,
                engineParam: engineParam
            };

            return { fitmentFlags, vehicleParams }
        }
    } else {
        let fitmentFlags = { oneFitOneSelectionFlag: false }

        let vehicleParams = {
            bodyNumDoorsParam: params.bodyNumDoorsParam,
            bodyParam: params.bodyParam,
            wheelParam: params.wheelParam,
            trimParam: params.trimParam,
            driveParam: params.driveParam,
            engineParam: params.engineParam
        };


        return { fitmentFlags, vehicleParams }
    }
}