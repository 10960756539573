import React, {useEffect, useState} from 'react';
import { Translation, Trans } from 'react-i18next';
import FieldWarningMessage from '../../../FieldWarningMessage/FieldWarningMessage';
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../../shared/Spinner/Spinner';
import AppSettings from '../../../../core/AppSettings';

function SummaryRailRedeemTextbox(props) {
    const [showSpinner, setShowSpinner] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [redeemValue, setRedeemValue] = useState("");
    const [removeText, setRemoveText] = useState("");
    const totalPointPoints = props.totalPointPoints;
    const totalPointDollars = props.totalPointDollars;
    const [errMessage, setErrMessage] = useState('');
    const [removePoints, setRemovePoints] = useState(false);
    const [fieldDirty, setFieldDirty] = useState(false);
    const orderSubtotal = props.subtotal;
    const brand = AppSettings.currentSite.label;
    const warningClass = "ac-summary-rail-warning-line-" + brand.toLowerCase();
    const removeClass = "ac-summary-rail-remove-line-" + brand.toLowerCase();
    //check for errors when value in textbox changes
    useEffect(() => {
        redeemErrors();
    }, [redeemValue]);

    useEffect(() => {
        if (props.pointAllowancesError !== '' && props.pointAllowancesError !== null) {
            setDisplayError(true);
            setErrMessage(<Trans i18nKey={props.pointAllowancesError}></Trans>);
        } else {
            setDisplayError(false);
            setErrMessage('');
            if (redeemValue !== '') {
                let strippedValue = removeCurrencySymbol();
                setRedeemValue(props.currencySymbol + parseFloat(parseFloat(strippedValue).toFixed(2)));
            }
        }
    }, [props.pointAllowancesError]);

    useEffect(() => {
        if (Array.isArray(props.appliedDollars) && props.appliedDollars.length) {
            if (props.appliedDollars[0] != undefined) {
                const i = props.appliedDollars[0].indexOf('_');
                const formatAppliedValue = parseFloat(props.appliedDollars[0].substring(0, i)).toFixed(2);
                setRemoveText(props.currencySymbol + formatAppliedValue + " (" + props.appliedPoints + " points) applied");
                setRemovePoints(true);
            }
        } else {
            setRedeemValue('');
            setFieldDirty(false);
            setRemoveText("");
            setRemovePoints(false);
        }
        setShowSpinner(false);
    }, [props.appliedDollars]);

    const handleApply = () => {
        setShowSpinner(true);
        let strippedValue = removeCurrencySymbol();
        if (isNaN(strippedValue) || strippedValue === 0 || strippedValue === null){
            setErrMessage(<Trans i18nKey="LABEL_REWARDS_NO_POINTS_INPUT">{{brand}}</Trans>);
            setDisplayError(true);
        } else {
            if (strippedValue === '' && Array.isArray(props.appliedDollars) && props.appliedDollars.length) {
                if (orderLevelError(totalPointDollars, true)) {
                    props.handleApplyRewardsPoints(props.appliedDollars[0], totalPointDollars);
                }
            } else if (strippedValue === '') {
                if (orderLevelError(totalPointDollars, true)) {
                    props.handleApplyRewardsPoints('', totalPointDollars);
                }
            } else if (Array.isArray(props.appliedDollars) && props.appliedDollars.length) {
                props.handleApplyRewardsPoints(props.appliedDollars[0], strippedValue.toString());
            } else {
                props.handleApplyRewardsPoints('', strippedValue.toString());
            }
        }
        setShowSpinner(false);
    };

    const handleRemove = (e) => {
        props.handleApplyRewardsPoints(props.appliedDollars[0], '');
        setRedeemValue('');
        setFieldDirty(false);
    };

    const handleChange = (e) => {
        e.target.value === '' ? setFieldDirty(false) : setFieldDirty(true);
        setRedeemValue(e.target.value);   
    };

   const handleKeyDown = (e) => {
        if(e.key === "Enter"){
            e.preventDefault();
            setRedeemValue(e.target.value);
            if(!displayError){
                handleApply();
            }
            else return;   
        }
        else return;
    }

    const removeCurrencySymbol = () => {
        if (redeemValue === '') {
            return '';
        } else {
            return parseFloat(parseFloat(redeemValue.split("").filter(c => c !== "$").join("")).toFixed(2));
        }
    };

    const handleFormat = () => {
        let strippedValue = removeCurrencySymbol();
        redeemValue !== '' ? setRedeemValue(props.currencySymbol + strippedValue) : '';
    }

    const orderLevelError = (value, handleApply) => {
        if (fieldDirty || handleApply) {
            if (value > parseFloat(totalPointDollars)) {
                setDisplayError(true);
                setErrMessage(<Trans i18nKey="LABEL_REWARDS_TOTAL_POINTS_ERROR"> {{ totalPointDollars }} {{ totalPointPoints }}</Trans>);
                return false;
            } else if (value > parseFloat(orderSubtotal)) {
                setDisplayError(true);
                setErrMessage(<Trans i18nKey="LABEL_REWARDS_SUBTOTAL_ERROR">{{orderSubtotal}}</Trans>)
                return false;
            } else {
                setDisplayError(false);
                setErrMessage('');
                return true;
            }
        }
    }
    
    const redeemErrors = () => {
        setDisplayError(false);
        setErrMessage("");
        let strippedValue = removeCurrencySymbol();
        if (isNaN(strippedValue) && strippedValue !== "") {    
            setDisplayError(true);
            setErrMessage(<Trans i18nKey="LABEL_REWARDS_INPUT_INVALID"></Trans>);
        } else if (strippedValue === "" && fieldDirty){
            orderLevelError(totalPointDollars);
        } else {
            orderLevelError(strippedValue);
        }
    };

    if (showSpinner) {
        return (
            <Spinner/>
        )
    } else {
        return (
            <Translation>
                {(t) => (
                    <div className="row">
                            <form className="ac-coupon-code-form"> 
                                <div className="redeem-container">
                                    <div className="ac-coupon-field small-7 medium-8 columns q-form">
                                        <input className = "gmit-gf-input-text stat-input-field q-input margin-small stat-input-field" 
                                            type="text" 
                                            value={redeemValue} 
                                            onChange={handleChange} 
                                            onBlur={handleFormat}
                                            onKeyDown =  {handleKeyDown}
                                            data-dtm="checkout step2:rewards" 
                                            placeholder={props.currencySymbol + parseFloat(props.totalPointDollars).toFixed(2) + " (" + props.totalPointPoints.toString() + ")"}
                                        />
                                    </div>
                                    <div className="ac-coupon-button small-5 medium-4 grid-column-alignment-right columns">
                                        <button
                                            type="button"
                                            className={'ac-coupon-apply-button q-button q-primary-button gm-primary-btn stat-button-link'}
                                            data-dtm="checkout step2:rewards"
                                            disabled={displayError}
                                            onClick={handleApply}>
                                            {t("LABEL_COMMON_APPLY")}
                                        </button>
                                    </div>
                                </div>
                                <div className="input-error">{displayError && <FieldWarningMessage message={errMessage} showError={true} className={warningClass}/>}</div>
                                {removePoints && (
                                    <div className='output-container'>
                                        <div className={removeClass}>
                                            <FontAwesomeIcon style={{ fontSize: "20px", color: "#252f32"}} icon="check-circle" />
                                            <span className ="ac-summary-rail-remove-value">{removeText}</span> 
                                            <span className="ac-summary-rail-remove-pointer" onClick={handleRemove}> {t("LABEL_CHECKOUT_REMOVE")}</span>
                                        </div>
                                    </div>
                                )}
                            </form>
                    </div>
                )}
            </Translation>
            
        )
    }
};

export default (reduxForm({
    form: 'SummaryRailRedeemTextbox', // form name
    destroyOnUnmount: true, // don't preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // unregister fields on unmount
})(SummaryRailRedeemTextbox))
