import React from 'react';
import { connect } from 'react-redux';
import AppSettings from "../../../core/AppSettings";
import i18n from "../../../core/i18n/i18n";
import i18next from 'i18next';
import { Translation } from 'react-i18next';
import vaultConstants from "../../../../config/vault_constants";
import OrderConfirmationLoyalty from './OrderConfirmationLoyalty';

function ConfirmationContainer(props) {

  let orderInfo = "", customerInfo = "", dealer = "", shipping = "", placedDate = "";
  if (props.order) {
    orderInfo = props.order.orderInfo;
    customerInfo = props.order.customerInfo;
    dealer = props.order.dealer;
    shipping = props.order.shipping;
    const date = new Date(orderInfo.placedDate);
    var dateOption = { year: 'numeric', month: 'long', day: 'numeric' };
    const language = i18n.language;
    placedDate = new Intl.DateTimeFormat(language, dateOption).format(date);
  }
  const contactInfoDisplayFeatureFlag = AppSettings.isLocalHost
        ? true
        : vaultConstants.FF_2170003_ORDER_CONFIRM_CONTACT;
        
  const isT3 = AppSettings.isT3;

  const totalOfOrder = parseFloat(orderInfo.total);

  const isZeroCheckout = totalOfOrder === 0;


   const isValidResponse = (value) =>{
    return (value !== '' && value !== 'null' && value !== undefined && value !== null && value !== 'undefined')
  };

  const companyName = isValidResponse(customerInfo.officeAddress) ? customerInfo.officeAddress : '';
  // to account for absence of shipping address
  // in certain cases
  let purchaseInfoColumnSize = 'xlarge-offset-6';
  if (isT3) {
    purchaseInfoColumnSize = 'xlarge-6';
  }

 
  if (props.payment) {
    const billingInfo = props.payment;
    const cardType = billingInfo.cc_brand;
    const last4 = billingInfo.account;
    if(contactInfoDisplayFeatureFlag){
      return (
        <Translation>
        {(t) => (
          <div className="ac-confirm-container ac-large-margintop" id='order-confirmation-container'>
            <div className="ac-confirm-thanks">
              <div className="ac-confirm-header-wrapper">
                <h4 className="q-headline3 ac-large-marginbottom ac-thank-you-header">{t("LABEL_CONFIRMATION_THANK_YOU")}</h4>
                <h4 className="ac-confirm-num q-headline3 ac-small-marginbottom">{t("LABEL_CONFIRMATION_NUMBER")} {orderInfo.orderId}</h4>
              </div>
              <OrderConfirmationLoyalty
                authData={props.authData}
                orderData={props.orderData}
                rewardsData={props.rewardsData}
            />
            <hr className="ac-confirm-border-top" />
              <p className="ac-order-placed ac-small-marginbottom">{t("LABEL_CONFIRMATION_ORDER_PLACED")} {placedDate}</p>
              <p className="ac-email-receipt ac-small-marginbottom">{t("LABEL_RECEIPT_EMAILED_TO")} {customerInfo.email} </p>
              <p className="ac-confirm-messag ac-small-marginbottom">
                {t("LABEL_CONFIRMATION_MESSAGE_1")}{orderInfo.orderId}
                {t("LABEL_CONFIRMATION_MESSAGE_2")} {dealer.dealerName}
                {t("LABEL_CONFIRMATION_MESSAGE_3")}
              </p>
              <p className=" ac-shipping-confirmation-meassage ac-small-marginbottom">{t("LABEL_CONFIRMATION_MESSAGE_4")}</p>
              <p className="ac-contact-address-msg">{t("LABEL_CONFIRMATION_DEALER_MESSAGE")}</p>
              <div className="ac-confirm-purchase-info">
                {dealer.dealerName}
                <br />{dealer.address1}
                <br />{dealer.city}, {dealer.state} {dealer.zipcode}
                <br />{dealer.country}
                <br />{dealer.phone1 && <span>{t("LABEL_COMMON_PHONE")}: {dealer.phone1}</span>}
              </div>
            </div>

            <hr />
            <div className="q-adv-col small-12 ac-small-marginbottom">
              <h4 className="ac-purshase-info-header q-headline3">{t("LABEL_CONFIRMATION_PURCHASE_INFORMATION")}</h4>
            </div>
            <div className="q-adv-grid ac-confirm-info">
                {!isT3 &&
                  <div className="q-adv-col small-12 xlarge-6 ac-confirm-shipping">
                    <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_CONTACT_INFORMATION")}</h5>
                    <div className="ac-confirm-contact-information">
                      {customerInfo.email}
                      <br />{shipping.phone1}
                    </div>
                  </div>
                }
                {!isT3 &&
                  <div className="q-adv-col small-12 xlarge-6 ac-confirm-shipping">
                    <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_CUSTOMER_ADDRESS")}</h5>
                    <div className="ac-confirm-purchase-info">
                      {customerInfo.firstName} {customerInfo.lastName}
                      {isValidResponse(customerInfo.officeAddress) && <br />}
                      {companyName}
                      <br />{shipping.address1} {shipping.address2}
                      <br />{shipping.city}, {shipping.state} {shipping.zipcode}
                      <br />{shipping.phone1 && <span>{t("LABEL_COMMON_PHONE")}: {shipping.phone1}</span>}
                      <br />{customerInfo.email}
                    </div> 
                  </div>}

              {
                !isZeroCheckout && <div className="q-adv-col small-12 xlarge-6 ac-confirm-billing">
                  {billingInfo.payment_method != 'PayInStore' && <div>
                    <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_BILLING_ADDRESS")}</h5>
                    <div className="ac-confirm-purchase-info">
                      {billingInfo.billto_firstname} {billingInfo.billto_lastname}
                      <br />{billingInfo.billto_address1} {billingInfo.billto_address2}
                      <br />{billingInfo.billto_city}, {billingInfo.billto_state} {billingInfo.billto_zipcode}
                      <br />{customerInfo.phone && <span>{t("LABEL_COMMON_PHONE")}: {customerInfo.phone}</span>}
                    </div>
                  </div>}
                </div>
              }

              {
                !isZeroCheckout && <div className={"q-adv-col small-12 xlarge-6 ac-confirm-payment"}>
                  <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_PAYMENT_METHOD")}</h5>
                  {billingInfo.payment_method != 'PayInStore' &&
                  <div className="ac-confirm-purchase-info">
                    {i18next.t("LABEL_CHECKOUT_CARD_ENDING_IN", { cardType, last4 })}
                  </div>
                  }
                  {billingInfo.payment_method == 'PayInStore' &&
                  <div>
                    <p>{t("LABEL_RECEIPT_PAY_IN_STORE")}</p>
                  </div>}
                </div>
              }
              {!isZeroCheckout && <hr className="ac-confirm-border-bottom" />}
              </div>
            </div>

        )}
      </Translation>
      )
    }
    else{
      return (
        <Translation>
          {(t) => (
            <div className="ac-confirm-container ac-large-margintop" id='order-confirmation-container'>
              <div className="ac-confirm-thanks">
                <div className="ac-confirm-header-wrapper">
                  <h4 className="q-headline3 ac-large-marginbottom ac-thank-you-header">{t("LABEL_CONFIRMATION_THANK_YOU")}</h4>
                  <h4 className="ac-confirm-num q-headline3 ac-small-marginbottom">{t("LABEL_CONFIRMATION_NUMBER")} {orderInfo.orderId}</h4>
                </div>
                <OrderConfirmationLoyalty
                  authData={props.authData}
                  orderData={props.orderData}
                  rewardsData={props.rewardsData}
              />
              <hr className="ac-confirm-border-top" />
                <p className="ac-order-placed ac-small-marginbottom">{t("LABEL_CONFIRMATION_ORDER_PLACED")} {placedDate}</p>
                <p className="ac-email-receipt ac-small-marginbottom">{t("LABEL_RECEIPT_EMAILED_TO")} {customerInfo.email} </p>
                <p className="ac-confirm-messag ac-small-marginbottom">
                  {t("LABEL_CONFIRMATION_MESSAGE_1")}{orderInfo.orderId}
                  {t("LABEL_CONFIRMATION_MESSAGE_2")} {dealer.dealerName}
                  {t("LABEL_CONFIRMATION_MESSAGE_3")}
                </p>
                <p className=" ac-shipping-confirmation-meassage ac-small-marginbottom">{t("LABEL_CONFIRMATION_MESSAGE_4")}</p>
                <p className="ac-contact-address-msg">{t("LABEL_CONFIRMATION_DEALER_MESSAGE")}</p>
                <div className="ac-confirm-purchase-info">
                  {dealer.dealerName}
                  <br />{dealer.address1}
                  <br />{dealer.city}, {dealer.state} {dealer.zipcode}
                  <br />{dealer.country}
                  <br />{dealer.phone1 && <span>{t("LABEL_COMMON_PHONE")}: {dealer.phone1}</span>}
                </div>
              </div>
  
              <hr />
              <div className="q-adv-col small-12 ac-small-marginbottom">
                <h4 className="ac-purshase-info-header q-headline3">{t("LABEL_CONFIRMATION_PURCHASE_INFORMATION")}</h4>
              </div>
              <div className="q-adv-grid ac-confirm-info">
                  {!isT3 &&
                    <div className="q-adv-col small-12 xlarge-6 ac-confirm-shipping">
                      <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_CONTACT_INFORMATION")}</h5>
                      <div className="ac-confirm-contact-information">
                        {customerInfo.email}
                        <br />{shipping.phone1}
                      </div>
                    </div>
                  }
                  {!isT3 &&
                    <div className="q-adv-col small-12 xlarge-6 ac-confirm-shipping">
                      <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_CUSTOMER_ADDRESS")}</h5>
                      <div className="ac-confirm-purchase-info">
                        {customerInfo.firstName} {customerInfo.lastName}
                        {isValidResponse(customerInfo.officeAddress) && <br />}
                        {companyName}
                        <br />{shipping.address1} {shipping.address2}
                        <br />{shipping.city}, {shipping.state} {shipping.zipcode}
                        <br />{shipping.phone1 && <span>{t("LABEL_COMMON_PHONE")}: {shipping.phone1}</span>}
                        <br />{customerInfo.email}
                      </div> 
                    </div>}
  
                {
                  !isZeroCheckout && <div className="q-adv-col small-12 xlarge-6 ac-confirm-billing">
                    {billingInfo.payment_method != 'PayInStore' && <div>
                      <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_BILLING_ADDRESS")}</h5>
                      <div className="ac-confirm-purchase-info">
                        {billingInfo.billto_firstname} {billingInfo.billto_lastname}
                        <br />{billingInfo.billto_address1} {billingInfo.billto_address2}
                        <br />{billingInfo.billto_city}, {billingInfo.billto_state} {billingInfo.billto_zipcode}
                        <br />{customerInfo.phone && <span>{t("LABEL_COMMON_PHONE")}: {customerInfo.phone}</span>}
                      </div>
                    </div>}
                  </div>
                }
  
                {
                  !isZeroCheckout && <div className={'ac-payment-wrapper small-12 ' + purchaseInfoColumnSize}>
                    <h5 className="ac-payment-header ac-small-marginbottom">{t("LABEL_CONFIRMATION_PAYMENT_METHOD")}</h5>
                    {billingInfo.payment_method != 'PayInStore' &&
                    <div className="ac-confirm-purchase-info">
                      {i18next.t("LABEL_CHECKOUT_CARD_ENDING_IN", { cardType, last4 })}
                    </div>
                    }
                    {billingInfo.payment_method == 'PayInStore' &&
                    <div>
                      <p>{t("LABEL_RECEIPT_PAY_IN_STORE")}</p>
                    </div>}
                  </div>
                }
                {!isZeroCheckout && <hr className="ac-confirm-border-bottom" />}
                </div>
              </div>
  
          )}
        </Translation>
      )
    }
      
  }

  else {
    return null
  }
}

const mapStateToProps = state => {
  return {
    authData: state.Authentication,
    orderData: state.OrderConfirmReducer,
    rewardsData: state.RewardsReducer
  }
}


export default connect(mapStateToProps)(ConfirmationContainer);
