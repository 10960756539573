import { getAllWheelPackageData } from "./WheelPackageService";
import * as type from './WheelPackageActionType';
import { call, put } from "redux-saga/effects";

export function* getWheelPackageData(action){

    try {
        const response = yield call (getAllWheelPackageData,action.payload);
        yield put ({
            type: type.WHEEL_PACKAGE_ASYNC_SUCCESS,
            payload:response.catalogEntry.components
        })
    }
    catch (error){
        yield put ({
            type: type.WHEEL_PACKAGE_ASYNC_FAILED,
            payload:error
        })
    }
}