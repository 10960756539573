import { constructAzureLoginURL } from '../../../shared/Authentication/AuthenticationWorker';
import AppSettings from '../../../core/AppSettings';

export const handleUserLogin = () => {
    const applicationState = {'redirectPath' : window.location.href };
    const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));
    const userFlow = AppSettings.azureLoginUserFlow;
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    const currentSite = AppSettings.currentSite.key;

    window.location.href = constructAzureLoginURL(userFlow,clientId,applicationStateString,'/authenticate', currentSite);
}
