import React from "react";
import { withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerComponent from "./MarkerComponent";
import PropTypes from "prop-types";

/**
 * @param {*} props
 * @return {*} Google Map component rendered with markers based off of dealer data
 */
const DealerMap = withGoogleMap((props) => (
        <div>
            <GoogleMap zoom={props.zoom} center={props.centerCoord}>
                {props.isMarkerShown &&
                    props.dealerData.map((item, index) => {
                        return (
                            <MarkerComponent
                                dispatch={props.dispatch}
                                state={props.state}
                                data={item}
                                count={index + 1}
                                key={index}
                            />
                        );
                    })}
            </GoogleMap>
        </div>
    ))

/*** PROP TYPES VALIDATION ***/
DealerMap.propTypes = {
    isMarkerShown: PropTypes.bool.isRequired,
    dealerData: PropTypes.array,
    containerElement: PropTypes.object.isRequired, // styling div
    loadingElement: PropTypes.object.isRequired, // styling div
    googleMapURL: PropTypes.string.isRequired, // Google Maps API Key/Url
    mapElement: PropTypes.object.isRequired, // styling div
    state: PropTypes.any,
    dispatch: PropTypes.func,
    centerCoord: PropTypes.object
};

export default DealerMap;
