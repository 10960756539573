import i18next from 'i18next';
import React from 'react';
import { connect } from "react-redux";
import Toggle from '../../Toggle/Toggle';
import { changeImageToggle, showSpinner } from '../ViewOnVehicleRedux/VoVActionCreator';

const ViewOnVehicleToggle = (props) => {
    return (
        <div>
            <Toggle dataDtm={"vov:"+props.dataDtm} checked={props.isImageWithParts} onClick={props.changeImage} />
            <span className="q-text">{i18next.t("LABEL_VIEW_ON_VEHICLE_IMAGE_WITH_WITHOUT_PARTS")}</span>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeImage: () => {
            dispatch(showSpinner());
            dispatch(changeImageToggle());
        }
    }
}
export default connect(null, mapDispatchToProps)(ViewOnVehicleToggle)