import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import RadioButtons from "./AddressValidationComponents/RadioButtons";
import EditAddress from "./AddressValidationComponents/EditAddress";
import * as type from '../../CheckoutRedux/CheckoutActionType'
import Modal from '../../../../shared/Modal';
import store from '../../../../core/Redux/Store';
import { nextStep, submitModal } from "../../CheckoutRedux/CheckoutActionCreator";


class AddressValidation extends Component{
  constructor(props) {
    super(props);
  }

  /**
   *  There's a few different expected flows depending on the address validation. With those, the appropriately
   *  translated text must be returned. I opted for if/else returns of the entire <div> because of the lack of
   *  ability to use variables within the Translation tag we use for translations
   * @param validity          The FedEx response: true if valid; false if invalid
   * @param fedExAvailability The availability of FedEx's address validation service: true if available; false if not
   * @param stateZipMismatch  The user's address input vs the FedEx validation: true if mismatch; false if match
   * @param POBox Whether the address entered is recognized by FedEx to be a POBox or not (True means POBox and False means not a POBox)
   * @returns The appropriate description text per flow, depending on the above
   */
  populateModalDescription(validity, fedExAvailability, stateZipMismatch, POBox, suggestedAddress) {
    if (!validity && stateZipMismatch && !suggestedAddress?.data?.address) {
      /*If fedex address validation is false & state and zip do not match,
       * force user to edit address before proceeding. */
      return (
        <div>
          <Translation>
            {(t) => (
              <h5>
                {t('LABEL_ADDRESS_VALIDATION_STATE_ZIP_MISMATCH')}
              </h5>
            )}
          </Translation>
        </div>
      );
    } else if (!validity && !fedExAvailability) {
      /*If fedex returns bad response, fedex is down or address is a POBox, disable button and
       * force user to edit address before proceeding. */
      return (
        <div>
          <Translation>
            {(t) => (
              <h5>
                {t('LABEL_ADDRESS_VALIDATION_FEDEX_DOWN')}
              </h5>
            )}
          </Translation>
        </div>
      );
    } else if (!validity && !stateZipMismatch && !suggestedAddress?.data?.address) {
      /* If fedex address validation is false & state and zip match & fedex does not find a suggested address, allow user to
       * proceed with entered address. There are cases where new streets are not yet
       * in fedex system, etc. The important thing is that state and zip match for calculation purposes. */
      return (
        <div>
          <Translation>
            {(t) => (
              <h5>
                {t('LABEL_ADDRESS_VALIDATION_BAD_STREET_ADDRESS')}
              </h5>
            )}
          </Translation>
        </div>
      );
    } else if (!stateZipMismatch && suggestedAddress?.data?.address) {
      /* If fedex cannot validate address but has a suggested address and original address' state and zip match,
       * user can choose to either use original address or use suggested address. */
      return (
        <div>
          <Translation>
            {(t) => (
                <h5>
                  {t('LABEL_USE_SUGGESTED_DESCRIPTION_TEXT')}
                </h5>
            )}
          </Translation>
        </div>
    );
    } else if (suggestedAddress?.data?.address) {
      /* If fedex returns a suggested address, enable button for user to select
       * suggest address. */
      return (
          <div>
            <Translation>
              {(t) => (
                  <h5>
                    {t('LABEL_USE_SUGGESTED_DESCRIPTION_TEXT')}
                  </h5>
              )}
            </Translation>
          </div>
      );
    } else if (POBox) {
      return (
          <div>
            <Translation>
              {(t) => (
                  <h5>
                    {t('LABEL_POBOX_DESCRIPTION_TEXT')}
                  </h5>
              )}
            </Translation>
          </div>
      );
    } else {
      return (
        <div>
          <Translation>
            {(t) => (
              <h5>
                {t('LABEL_USE_ENTERED_DESCRIPTION_TEXT')}
              </h5>
            )}
          </Translation>
        </div>
      )
    }
  }

  render() {
    if (this.props.data) {
      const data = this.props.data;
      const validity = data.validity;
      const fedExAvailability = data.fedExAvailability;
      const stateZipMismatch = data.stateZipMismatch;
      const POBox = data.POBox;
      const suggestedAddress = data.validatedResponse;

      let modalDescription = this.populateModalDescription(validity, fedExAvailability, stateZipMismatch, POBox, suggestedAddress);

      return (
        <div>
          <Modal
            open={this.props.data.addressModalOpen}
            onClose={this.props.handleCloseModal}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            center
          >
            <div>
              <span>
                <Translation>
                  {(t) => (
                    <h4>
                      {t('LABEL_ADDRESS_VALIDATION_TITLE')}
                    </h4>
                  )}
                </Translation>
              </span>
              <hr className="q-separator-horizontal"/>
              {modalDescription}
              <div>
                <RadioButtons
                  originalAddressForm={this.props.data.originalAddressForm}
                  validatedResponse={this.props.data.validatedResponse}
                  handleAddressModalSubmit={this.props.handleAddressModalSubmit}
                  validity={this.props.data.validity}
                  fedExAvailability={this.props.data.fedExAvailability}
                  stateZipMismatch={this.props.data.stateZipMismatch}
                  POBox={this.props.data.POBox}
                  isLoading={this.props.data.isLoading}
                />
              </div>
              <div>
                <EditAddress
                  handleCloseModal={this.props.handleCloseModal}
                />
              </div>
            </div>
          </Modal>
        </div>
      )
    } else {
      return <a onClick={this.props.handleShowModal}></a>
    }
  }
}

/********** MAPPING STATE TO PROPS *****************/
function mapStateToProps(state) {
  // state comes from store
  // return value goes to props
  return {
    data: state.CheckoutReducer,
  }
}


/********** ACTION DISPATCH *****************/
function mapDispatchToProps(dispatch) {
  return {
    handleShowModal: () => {
      dispatch({ type: type.SHOW_ADDRESS_MODAL })
    },
    handleCloseModal: () => {
      dispatch({ type: type.CLOSE_ADDRESS_MODAL })
    },
    handleNext: (e) => {
      store.dispatch(nextStep());
    },
    handleAddressModalSubmit: (form, selectedAddress) => {
      store.dispatch(submitModal(form, selectedAddress,  true));
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddressValidation)