import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { capitalizeString } from "./InputTagUtils";
import { useTranslation } from "react-i18next";
import { getKeyByValue } from "../SearchUtils";
import queryString from "query-string";


/** The sharedInputTags is a component that renders tags for any array that is passed to it. The tags can be styled differently if needed in the .scss
 *  within different parents if they have their own id.
 *
 * @param props
 * tags, Array Required. This is the array that takes in all of the labels for the tags that need to be rendered.
 *
 * clickHandler, Function Optional. Handle your click... or don't.
 *
 * hasCloseIcon, Boolean Optional. If the user needs the close icon, declare this prop!
 *
 * clearAllOption, Boolean Optional. If the users wants to have a remove all (tags) when there are more than two, this should be set to true.
 *
 * shouldCapitalize, Boolean Optional. If you'd like the first letter capitalized of your tags, then make this true.
 */
function SharedInputTags(props) {
    const { t } = useTranslation();
    const { tags, clickHandler, hasCloseIcon, clearAllOption, shouldCapitalize } = props;
    // To make it as shareable as possible, can I use the URL facet param as an object, and pass it down to the tags?
    // then the object or array itself is what we use to update it. Otherwise the URL becomes pretty specific and hardly shareable.

    // the PSR file can have a new useEffect controlling the array/object that passes it down to this?
    // Then we map through it to create the tags
    const buildTags = () => {
        return tags.map((tag) => {
            let type = getKeyByValue(queryString.parse(location.search), tag);
            // TEMPORARY fix to hide engine filter and tags while Khalid finishes backend logic for engine facet
            if(type !== "engine"){
            return (
                <span
                    key={tag}
                    className="tag-container gb-body2 stat-button-link"
                    onClick={clickHandler ? () => clickHandler(tag) : null}
                    data-dtm="filter control:remove"
                >
                    <p className="tag-label">{shouldCapitalize ? capitalizeString(tag) : tag}</p>

                    {hasCloseIcon && (
                        <span className="tag-icon">
                            <FontAwesomeIcon icon={faTimes} />
                        </span>
                    )}
                </span>
            );
            }
        });
    };

    if (tags.length) {
        return (
            <div id="tag-section-container">
                {buildTags()}
                {tags.length > 1 && clearAllOption && (
                    <a id="tag-remove-all" className="gb-body1 stat-text-link" data-dtm="filter control" onClick={clickHandler ? () => clickHandler("") : null}>
                        {t("LABEL_COMMON_REMOVE_FILTERS")}
                    </a>
                )}
            </div>
        );
    } else {
        return null;
    }
}

SharedInputTags.defaultProps = {
    tags: [],
    hasCloseIcon: true,
    clearAllOption: true,
    shouldCapitalize: false
};

export default SharedInputTags;
