import { set } from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Translation, Trans, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import AppSettings from '../../../core/AppSettings';
import store from '../../../core/Redux/Store';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import { Toggle } from '../../../shared/Hooks/Hooks';
import { getRewardsPotentialPointsInfo, setRewardsPotentialPoints, getPdPotentialPoints } from '../../../shared/Rewards/RewardsRedux/RewardsActionCreator';
import { handleUserLogin } from '../../../shared/Utils/Utils';
import { useFooterDisclaimerLabel } from '../../App/Footer/FooterDisclaimerLabel';
import { enrollUserInRewards, getIsUserRegistered, updateUserForOmsOrder } from '../OrderConfirmRedux/OrderConfirmService';
import OrderConfirmationGuest from './OrderConfirmationLoyalty/OrderConfirmationGuest';
import OrderConfirmationNonregistered from './OrderConfirmationLoyalty/OrderConfirmationNonregistered';
import {formatNumber} from "../../../shared/Internationalization/FormattedNumbers";

const OrderConfirmationLoyalty = (props) => {
    let {authData, orderData, rewardsData} = props;

    const [tierName, setTierName] = useState('');
    const [rewardsPoints, setRewardsPoints] = useState('');
    const [isDealerInRewardsProgram, setIsDealerInRewardsProgram] = useState(false);
    const [isEnrollRewardsChecked, setIsEnrollRewardsChecked] = useState(false);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectToRegister, setRedirectToRegister] = useState(false);
    const [redirectToAccDetails, setRedirectToAccDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [enrollmentError, setEnrollmentError] = useState('');
    const [displayUser3Message, setDisplayUser3Message] = useState(localStorage.getItem('newUserThreeLogic') ? false : true);
    const [userData, setUserData] = useState({});

    // get correct superscript label for the loyalty points disclaimer
    const disclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
    const label = useFooterDisclaimerLabel(disclaimer);
    const brand = AppSettings.currentSite.label;

    const rewardsTerms = process.env.REACT_APP_REWARDS_BASE_URL + brand + process.env.REACT_APP_REWARDS_FILENAME;
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    useEffect(() => {
        if (authData.registrationStatusFetched && authData.registrationStatus == 'R') {
            setIsAuthenticated(true);
            if (authData.enrolledAttributeValueFetched) {
                if (authData.enrolledAttributeValue != "1") {
                    // Registered user but NOT enrolled in Loyalty
                    setIsEnrolled(false)
                } else {
                    setIsEnrolled(true);
                }
            }
        } else {
            // Guest
            setIsAuthenticated(false);
        }
    }, [authData])

    useEffect(() => {
        if (orderData && orderData?.order?.errors) {
            setErrorMessage("LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            return;
        }

        // Check if the dealer is participating in rewards
        if (Object.entries(orderData?.order)?.length !== 0 && orderData?.order && orderData?.order?.order?.dealer?.rewardsProgram) {
            setIsDealerInRewardsProgram(true);
        } else {
            // Dont bother doing any calls or further checks if the dealer is not in rewards program.
            setIsDealerInRewardsProgram(false);
            return;
        }
        setRewardsPoints('');
        if (!rewardsData.potentialPoints && authData.registrationStatusFetched && !authData.addWrapper && orderData && Object.entries(orderData?.order)?.length !== 0) {
          const orderTotal = orderData?.order?.order?.orderInfo?.rewardsAdjustedTotal ? orderData?.order?.order?.orderInfo?.rewardsAdjustedTotal : "";
            if (isAuthenticated) {
                // User #3
                if (isEnrolled ) {
                    if(rewardsData.currentTierName){
                        setTierName(rewardsData.currentTierName);
                    }
                    if (orderTotal > 0 && rewardsData.isRewardsActive) {
                        //calling rewards 2.0 potential points API
                        store.dispatch(getRewardsPotentialPointsInfo(orderData.order.order.orderInfo.rewardsAdjustedTotal));
                    }

                    // OMS call ONLY if the user is authenticated and enrolled!
                    if (localStorage.getItem('newUserThreeLogic') &&
                    (authData.userData && authData.userData?.email1?.toLowerCase() == orderData?.parsedResponse?.customerInfo?.email?.toLowerCase())) {
                        makeOmsCall(orderData?.order?.order?.orderInfo?.orderId);
                    }
                } else {
                    // User #2
                    if (orderTotal > 0 && authData.registrationStatusFetched) {
                        store.dispatch(getPdPotentialPoints(null, orderData?.order?.order?.orderInfo?.rewardsAdjustedTotal));
                    }
                    // Fill in the customer data in case of them enrolling, but haven't filled out the form.
                    if (orderData?.order?.order?.customerInfo && orderData?.paymentInfo) {
                        setUserData({"firstName": orderData.paymentInfo.billto_firstname,
                                    "lastName": orderData.paymentInfo.billto_lastname,
                                    "phone": orderData.order.order.customerInfo.phone,
                                    "address1": orderData.paymentInfo.billto_address1,
                                    "address2": orderData.paymentInfo.billto_address2,
                                    "city": orderData.paymentInfo.billto_city,
                                    "state": orderData.paymentInfo.billto_state,
                                    "postalCode": orderData.paymentInfo.billto_zipcode,
                                    "orderId": orderData.order.order.orderInfo.orderId,
                                    "prefillCheckbox": true});
                    }
                }
            } else {
                // User #1 - measly guest :>
                  if (orderTotal > 0 && authData.enrolledAttributeValue != 1) {
                      store.dispatch(getPdPotentialPoints(null, orderData?.order?.order?.orderInfo?.rewardsAdjustedTotal));
                  }
            }
        }
        if(rewardsData.potentialPoints){
          setRewardsPoints(rewardsData.potentialPoints.includes(",") ?  rewardsData.potentialPoints : formatNumber(rewardsData.potentialPoints));
        }
    }, [authData, isAuthenticated, orderData.order, rewardsData.isEnrolledInRewards, rewardsData.potentialPoints]);

    // first checks to see if account already exists
    function checkIsUserRegistered (email) {
        getIsUserRegistered(email).then(result => {
            // Checks if email already exists in system
            if (result.exists === "true") {
                handleUserLogin('/orderconfirmation?orderId=' + orderData?.order?.order?.orderInfo?.orderId,
                                orderData?.order?.order?.customerInfo?.email);
            }
            else if (result.exists === "false") {
                // if does not exist, then sends user to createPassword page
                setRedirectToRegister(true);
            }
            else {
                // if response is successful but some reason doesnt have data, then display error message
                setErrorMessage("LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            }
        }).catch((error) => {
            // if response does not return then display error message
            setErrorMessage("LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
        });
    }

    const handleSubmit = () => {
        setIsLoading(true);
        if (isAuthenticated && !isEnrolled) {
            // Authenticated but not enrolled user
            isUserDataComplete();
        } else if (!isAuthenticated){
            // Guest
            checkIsUserRegistered(orderData?.order?.order?.customerInfo?.email);
        }
    }

    function makeOmsCall(_orderId) {
        // This call is to be made when a user is authenticated and enrolled. Back-end handles checking if emails match.
        // This call will be made inside the right conditions, so no need to check here.
        updateUserForOmsOrder(_orderId).then(result => {
            if (result.status != 200) {
                setErrorMessage("LABEL_LOYALTY_POINTS_ERROR");
            } else {
                // We don't want to display the loyalty message until OMS call is successful.
                setDisplayUser3Message(true);
                localStorage.removeItem('newUserThreeLogic');
            }
        });
    }

    function isUserDataComplete () {
        if (authData && authData.userData?.firstName) {
            // If the first name exists, then the user has already completed their additional details, and just open the modal
            setIsLoading(true);
            setEnrollmentError('');
            enrollUserInRewards(authData.userData?.logonId).then(result => {
                // If the user was successfully enrolled, then show success message and reload page. Otherwise show error.
                if (result.data?.gmrewards_success) {
                    localStorage.setItem('showEnrolledConfirmationModal', true);
                    localStorage.setItem('newUserThreeLogic', true);
                    location = location;
                }
                else {
                    setEnrollmentError("LABEL_LOYALTY_ENROLLMENT_ERROR_TRY_AGAIN");
                }

                setIsLoading(false);
            }).catch(() => {
                setEnrollmentError("LABEL_LOYALTY_ENROLLMENT_ERROR_TRY_AGAIN");
                setIsLoading(false);
            });
        } else {
            // If the first name does not exist, then re-direct the user to the additional details page.
            setRedirectToAccDetails(true);
        }
    }

    function handleEnrollInRewardsCheckboxClick() {
        Toggle(isEnrollRewardsChecked, setIsEnrollRewardsChecked)
    }

    // RENDERING BELOW
    if (redirectToRegister) {
        // Registering flow from the OCP page
        if (orderData) {
            return <Redirect to={{ pathname: '/register/', state: { email: orderData?.order?.order?.customerInfo?.email, orderId: orderData?.order?.order?.orderInfo?.orderId} }} />
        } else {
            setErrorMessage("LABEL_ERROR_BOUNDARY_GENERIC_ERROR")
        }
    } else if (redirectToAccDetails) {
        return <Redirect to={{ pathname: '/register/registrationDetails/', state: { userData: userData} }} />
    } else if (isDealerInRewardsProgram) { // Only show these if the dealer is participating in rewards.
        // Take care of error handling. Only show error handling if dealer is in rewards program though!

        if ((rewardsData && rewardsData.potentialPointsInfoError) || errorMessage) {
            return (
                <Translation>
                    {(t) => (
                        <ErrorMessage icon={true} message={errorMessage ? errorMessage : t("LABEL_REWARDS_SERVICE_ERROR", { brand })} />
                    )}
                </Translation>
            )
        } else if (rewardsPoints) {
            // Render either of the following components depending on user type (1 - GUEST, 2 - AUTH, NOT ENROLLED, 3 - AUTH & ENROLLED)
            if (!isAuthenticated) {
                // Guest
                return (
                    <OrderConfirmationGuest
                        rewardsPoints={rewardsPoints}
                        brand={brand}
                        label={label}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                    />
                )
            } else if (isAuthenticated && !isEnrolled ) {
                // Authenticated but not Registered... and email from order matches signed in user's email
                return (
                    <>
                        <OrderConfirmationNonregistered
                            rewardsPoints={rewardsPoints}
                            brand={brand}
                            label={label}
                            handleSubmit={handleSubmit}
                            handleEnrollInRewardsCheckboxClick={handleEnrollInRewardsCheckboxClick}
                            isEnrollRewardsChecked={isEnrollRewardsChecked}
                            privacyLink={privacyLink}
                            rewardsTerms={rewardsTerms}
                            isLoading={isLoading}
                            enrollError={enrollmentError}
                        />
                    </>
                );
            }

            else if (displayUser3Message && rewardsData.isRewardsActive) {
                // Authenticated and Registered, and order email must match signed in user's. Display message after OMS call.
                // Display confirmation message only if member is 'Active'
                return (
                    <b>
                        <div id="rewards-points-confirm-message">
                            <Trans i18nKey="LABEL_REWARDS_CONFIRMATION_MESSAGE">
                                {{ rewardsPoints }} {{ brand }} <sup>{{ label }}</sup> {{ tierName }}
                            </Trans>
                        </div>
                    </b>
                )
            }
            else {
                return null;
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default withTranslation()(reduxForm({
    form: 'OrderConfirmationLoyaltyForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(OrderConfirmationLoyalty));
