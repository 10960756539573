import React, { useState, useEffect } from 'react';
import store from '../../../core/Redux/Store';
import { retrieveOrderDataAsync, retrievePaymentInfoDataAsync } from '../UserProfileRedux/UserProfileCreator';
import { connect } from 'react-redux';
import HistoryVehicle from '../OrderHistory/HistoryVehicle/HistoryVehicle';
import HistoryOrderSummary from './HistoryOrderSummary/HistoryOrderSummary';
import i18next from 'i18next';

function HistoryOrderDetails(props) {

    const [orderInfo, setOrderInfo] = useState({});
    const [dealerInfo, setDealerInfo] = useState({});
    const [vehiclesInfo, setVehiclesInfo] = useState([{}]);
    const [shippingInfo, setShippingInfo] = useState({});
    const [customerInfo, setCustomerInfo] = useState({});
    const [paymentInfo, setPaymentInfo] = useState({});


    useEffect(() => {
        //retrieve order details data
        store.dispatch(retrieveOrderDataAsync());
        store.dispatch(retrievePaymentInfoDataAsync());
    }, []);

    useEffect(() => {
        if (props.orderDetailsData != undefined) {
            if (props.orderDetailsData.orderInfo) {
                setOrderInfo(props.orderDetailsData.orderInfo);
            }
            if (props.orderDetailsData.dealer) {
                setDealerInfo(props.orderDetailsData.dealer);
            }
            if (props.orderDetailsData.vehicles) {
                setVehiclesInfo(props.orderDetailsData.vehicles);
            }
            if (props.orderDetailsData.shipping) {
                setShippingInfo(props.orderDetailsData.shipping);
            }
            if (props.orderDetailsData.customerInfo) {
                setCustomerInfo(props.orderDetailsData.customerInfo);
            }
        }
    }, [props.orderDetailsData]);

    useEffect(() => {
        if (props.paymentInfoData != undefined) {
            setPaymentInfo(props.paymentInfoData);
        }
    }, [props.paymentInfoData]);


    return (
        <div className="row q-gridbuilder small-margin gridfullwidth ac-small-sidepadding">
            <h1 className="q-headline1">{i18next.t('LABEL_EMAIL_ORDER_DETAILS')}</h1>
            <div className="ac-history-order-details-info-section small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="ac-history-order-details-header small-12 grid-column-alignment-left columns ac-nopadding-x">

                    <div className="small-6 medium-4 grid-column-alignment-left columns ac-nopadding-x">
                        <div className="ac-order-desc-label q-body2">
                            {i18next.t('LABEL_ORDER_HISTORY_ORDER_DATE')}
                        </div>
                        <div className="ac-order-desc-value q-body1">
                            {orderInfo.formattedCreateDate}
                        </div>
                    </div>
                    <div className="small-6 medium-4 grid-column-alignment-center columns ac-nopadding-x">
                        <div className="ac-history-order-details-text-align-left">
                            <div className="ac-order-desc-label q-body2">
                                {i18next.t('LABEL_EMAIL_ORDER_NUMBER')}
                            </div>
                            <div className="ac-order-desc-value q-body1">
                                {orderInfo.orderId}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">
                    <h2 className="q-headline2">{i18next.t('LABEL_RECEIPT_DEALER_INFO')}</h2>
                    <div className="ac-history-order-details-dealer-column small-6 medium-4 grid-column-alignment-left columns ac-nopadding-x">
                        <div className="ac-order-desc-label q-body2">
                            {i18next.t('LABEL_CONFIRMATION_DEALER_PICKUP')}
                        </div>
                        {dealerInfo.dealerName}
                    </div>
                    <div className="ac-history-order-details-dealer-column small-6 medium-4 grid-column-alignment-center columns ac-nopadding-x">
                        <div className="ac-history-order-details-text-align-left">
                            <div className="ac-order-desc-label q-body2 ">
                                {i18next.t('LABEL_COMMON_ADDRESS')}
                            </div>
                            <div>{dealerInfo.address1}</div>
                            <div>{dealerInfo.address2}</div>
                            <div>{dealerInfo.address3}</div>
                            <div>{dealerInfo.city}, {dealerInfo.state} {dealerInfo.zipcode}</div>
                        </div>
                    </div>
                    <div className="ac-history-order-details-dealer-column small-12 medium-4 grid-column-alignment-right columns ac-nopadding-x">
                        <div className="ac-history-order-details-text-align-left">
                            <div className="ac-order-desc-label q-body2">
                                {i18next.t('LABEL_COMMON_PHONE')}
                            </div>
                            <div>{dealerInfo.phone1}</div>
                        </div>
                    </div>
                </div>
                <div className="small-12 grid-column-alignment-left columns ac-nopadding-x q-body2">
                    <a href="#">{i18next.t('LABEL_ORDER_HISTORY_RETURNS')}</a>
                </div>
            </div>
            <div className="ac-history-order-details-info-section small-12 grid-column-alignment-left columns ac-nopadding-x">
                <h2 className="q-headline2">{i18next.t('LABEL_COMMON_ITEMS')} ( {orderInfo.recordSetTotal} ) </h2>
                {vehiclesInfo.map(function (vehicle, i) {
                    return (
                        <HistoryVehicle
                            key={i}
                            vehicle={vehicle}
                            orderInfo={orderInfo}
                        />
                    )
                })
                }
            </div>
            <div className="ac-history-order-details-info-section small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="ac-history-order-details-price-summary small-12 medium-6 grid-column-alignment-right columns ac-nopadding-x">
                    <HistoryOrderSummary
                        orderInfo={orderInfo}
                        shippingInfo={shippingInfo}
                    />
                    <div className="ac-history-order-details-rewards small-12 grid-column-alignment-left columns ac-nopadding-x q-body1">
                        {i18next.t('LABEL_ORDER_HISTORY_EARNED_REWARDS')}
                    </div>
                </div>
            </div>
            <div className="ac-history-order-details-info-section small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="ac-history-order-details-price-summary small-12 grid-column-alignment-left columns ac-nopadding-x">
                    <h2 className="q-headline2">{i18next.t('LABEL_CONFIRMATION_PURCHASE_INFORMATION')}</h2>
                    <div className="small-4 medium-4 large-4 xlarge-4 grid-column-alignment-left columns ac-nopadding-x">
                        <div className="ac-order-desc-label q-body2">
                            {i18next.t('LABEL_CONFIRMATION_SHIPPING_ADDRESS')}
                        </div>
                        <div className="q-body1">
                            <div>{customerInfo.firstName} {customerInfo.lastName}</div>
                            <div>{shippingInfo.address1}</div>
                            <div>{shippingInfo.address2}</div>
                            <div>{shippingInfo.address3}</div>
                            <div>{shippingInfo.city}, {shippingInfo.state} {shippingInfo.zipcode}</div>
                            <div>{shippingInfo.phone1}</div>
                            <div>{customerInfo.email}</div>
                        </div>
                    </div>
                    <div className="small-4 medium-4 large-4 xlarge-4 grid-column-alignment-center columns ac-nopadding-x">
                        <div className="ac-history-order-details-text-align-left">
                            <div className="ac-order-desc-label q-body2">
                                {i18next.t('LABEL_ORDER_HISTORY_SHIPPING_METHOD')}
                            </div>
                            <div className="q-body1">
                                What goes here?
                                        </div>
                        </div>
                    </div>
                    <div className="small-4 medium-4 large-4 xlarge-4 grid-column-alignment-center columns ac-nopadding-x">
                        <div className="ac-history-order-details-text-align-left">
                            <div className="ac-order-desc-label q-body2">
                                {i18next.t('LABEL_CONFIRMATION_PAYMENT_METHOD')}
                            </div>
                            <div className="q-body1">
                                {i18next.t('LABEL_ORDER_HISTORY_PAYMENT_ENDING_IN')} {paymentInfo.cc_4digits} {paymentInfo.payment_method}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
/**************************************************************/
//          Map State to Props
/**************************************************************/
function mapStateToProps(state) {
    // State comes from store
    // Return value goes to props
    return {
        orderDetailsData: state.UserProfileReducer.orderDetailsData,
        paymentInfoData: state.UserProfileReducer.paymentInfoData
    }
}

export default connect(mapStateToProps)(HistoryOrderDetails)