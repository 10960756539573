/** COMPONENT NAME : SUBTOTALCOMPONENT (TOTAL PRICE IN CART PAGE) **/
import React from 'react';
import {Translation} from 'react-i18next';
import PropTypes from 'prop-types';
import {formattedCurrency} from "../../Internationalization/FormattedNumbers";

/** BEGINNING OF THE COMPONENT **/
const SubtotalComponent = (props)=>{

    /*** VARIABLES ***/
    const subTotal = props.subTotal;
    const formattedSubtotal = formattedCurrency('USD',subTotal);


    return (<Translation>
            {
                (t)=>(<div className="ac-SubtotalWrapper">
                        <hr/>
                        <span>
                            {t("LABEL_COMMON_SUBTOTAL")}: {formattedSubtotal}
                        </span>
                    </div>
                )
            }
        </Translation>
    )
};

    /** PROP TYPES VALIDATION **/
SubtotalComponent.proptype = {
    subTotal:PropTypes.string.isRequired
};

 export default SubtotalComponent