/** COMPONENT NAME : MINICARTLINEITEM(PARENT OF SUB COMPONENT ON LINE ITEM) **/
import React, { useState, useEffect } from 'react';
import ImageAndDescription from "../MiniCartLineItem/MiniCartImageAndDescription/MiniCartImageAndDescription";
import MsrpComponent from "../../../../../shared/CartComponents/MSRP/MsrpComponent"
import QtyCounter from "../../../../../shared/CartComponents/QtyCounter/QtyCounter";
import MediaQuery from 'react-responsive';
import { useDispatch, useSelector } from "react-redux";
import SharedSpinner from "../../../../../shared/Spinner/Spinner";

const ProductLineItem = (props) => {

    /** VARIABLES **/
    const mainItem = props.mainItem;
    const item = props.data;
    let installItem; // if the delivery method is INSTALL then we will get a second "item" that has install charges
    if (mainItem?.items?.length > 1) { //Install will always generate two objects, this lets us know the item is install
        mainItem.items.map((item, subIndex) => {
            if (item.orderItemType === "Installed") {
                installItem = mainItem.items[subIndex];
            }
        });
    }

    let deliveryInfo;
    installItem ? deliveryInfo = {
        deliveryMethod: installItem.shipModeDescription,
        deliveryPrice: installItem.unitPrice,
        deliveryId: installItem.shipModeId,
        carrier: "INSTALL"
    } :
    deliveryInfo = {
        deliveryMethod: item.shipModeDescription,
        deliveryPrice: item.shippingCharge,
        deliveryId: item.shipModeId,
        carrier: item.carrier 
    };
    const vehicleInfo = props.vehicleInformation;
    const vinNum = vehicleInfo.vin;
    const currentComponent = props.component;
    const qty = parseInt(item.quantity);
    const items = props.items;
    const [showItemSpinner, setShowItemSpinner] = useState(false); //TODO: Need to look into this func
    const orderInfo = useSelector((state) => state.OrderInfoReducer);
    const showMiniCartSpinner = orderInfo?.showMiniCartRemoveSpinner;
    const dispatch = useDispatch();
    /** CREATING OBJECT OF DATA THAT WILL BE REQUIRED ON CARTIMAGEANDDESCRIPTION COMPONENT **/
    // availableDeliveryMethods not required (on order confirmation page)
    let availableDeliveryMethods = {}
    if (item.availableDeliveryMethods) {
        availableDeliveryMethods = item.availableDeliveryMethods[0];
    }
    const imageDescProps = {
        seoUrl: item.seoUrl || '',
        partNumber: item.partNumber,
        partName: item.partName,
        availableDeliveryMethods: availableDeliveryMethods,
        imageName: item.imageName,
        productType: item.productType || '',
        productId: item.productId || ''
    };

    useEffect(() => {
        setShowItemSpinner(false);
    }, [orderInfo]);

    return <div className="ac-shopcartLineItemWrapper">
        <div className={"col-xs-12 small-12 medium-12 large-12 xlarge-12 columns acc-mini-cart-row-wrapper"}>
            <ImageAndDescription data={imageDescProps} vehicleInfo={vehicleInfo} vin={vinNum} component={currentComponent} price={item.UnitPrice} orderItemId={item.orderItemId} value={qty}/>
            {showMiniCartSpinner || showItemSpinner &&
            <div className="prt-product-line-item-spinner">
                <SharedSpinner size="sm" />
            </div>
            }
        </div>
        <div id="ac-mini-cart-qty-mrsp-col" className="col-xs-12 small-12 medium-12 large-12 xlarge-12 columns ac-MobileQty acc-mini-cart-row">
            <MediaQuery query="(min-width:26.25em)">
                <div className="col-xs-6 small-6 medium-3 large-6 xlarge-6 columns">
                    <QtyCounter setShowItemSpinner={setShowItemSpinner} dispatch={dispatch} orderId={props.orderId} orderInfo={props.orderInfo} orderItemId={item.orderItemId} laborOrderItemId={item.laborItemOrderId} value={item.quantity} partName={item.partName} items={items} component={currentComponent}/>
                </div>
                <div className="col-xs-6 small-6 medium-3 large-6 xlarge-6 columns">
                    <MsrpComponent price={item.UnitPrice} />
                </div>
            </MediaQuery>
            <MediaQuery query="(max-width:26.24em)">
                <div className="col-xs-6 small-6 medium-3 large-2 xlarge-2 columns">
                    <QtyCounter setShowItemSpinner={setShowItemSpinner} dispatch={dispatch} orderId={props.orderId} orderInfo={props.orderInfo} orderItemId={item.orderItemId} laborOrderItemId={item.laborItemOrderId} value={item.quantity} partName={item.partName} items={items} component={currentComponent}/>
                </div>
                <div className="col-xs-6 small-6 medium-3 large-2 xlarge-2 columns">
                    <MsrpComponent price={item.UnitPrice} />
                </div>
            </MediaQuery>
        </div>
    </div>
};

export default ProductLineItem