import React, {useEffect, useState} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import queryString from "query-string";
import AppSettings, { getPageName } from "../../core/AppSettings";
import store from "../../core/Redux/Store";
import accHistory from "../../modules/App/History";
import { formattedCurrency } from "../../shared/Internationalization/FormattedNumbers";
import { nodeAuthCheck } from "../Authentication/AuthenticationService";
import { constructAzureLoginURL } from "../Authentication/AuthenticationWorker";
import { LiveChatPreProd } from "../LiveChat/LiveChatPreProd";
import { LiveChatProd } from "../LiveChat/LiveChatProd";
import { isValidValue } from "../Validator/Validator";
import { loadFooterDataAsync } from "../../modules/App/Footer/FooterRedux/FooterActionCreator";
import { Link } from "react-router-dom";
import vaultConstants from "../../../config/vault_constants";
import i18n from "../../core/i18n/i18n";
import {get_FF_1745770_USE_AZURE_WALLET_WIDGET} from "./FeatureFlags";
import {walletLoadError} from "../Wallet/WalletRedux/WalletActionCreator";

// email campaignes, so bac will be passed to T1 sites as well.
let dealerName = AppSettings.dealerName || "";
if (dealerName) {
    dealerName = AppSettings.dealerName.replace("/", "");
}
const restApiContractsFeatureFlag = AppSettings.isLocalHost
    ? true
    : String(vaultConstants.FF_2243221_REST_API_CONTRACTS) === "true";
const dealerUrl =
    restApiContractsFeatureFlag && !AppSettings.isT3
        ? `/wcs/resources/store/${AppSettings.storeId}/GMDealerSetting/setup_contract?catalogId=${AppSettings.catalogId}&langId=${AppSettings.langId}&bac=${AppSettings.bac}&responseFormat=json`
        : `/webapp/wcs/stores/servlet/GMContractSetup?catalogId=${AppSettings.catalogId}&storeId=${AppSettings.storeId}&langId=-1&domain=${dealerName}&bac=${AppSettings.bac}`;

/*** Forms that have a Button component can be focused on if this function is called in the handleSubmit function. This is specifically
 *  used for the forms that have an input field, and pressing enter can be used. Furthermore, the main use of this function is to focus the
 *  button so that if there is a loading bar enabled for the button, when pressing enter the focus of the button allows users to see the loading bar
 */
export const submitButtonFocus = () => {
    const submitButton = document.getElementsByClassName("q-mod q-mod-button q-button q-button-small");
    submitButton[0].focus();
};

/***
 *    !!! Checkout new Hooks version (pageTitle in Hooks.js) !!!
 *               Title at the top of the page
 ***/
export const titleSetUp = (name) => {
    if (AppSettings.isT3) {
        let dealerName = "";
        setTimeout(() => {
            dealerName = store.getState().HeaderReducer.name;
            if (dealerName !== undefined) {
                document.title = `${name} | ${dealerName} ${AppSettings.currentSite.label}  Accessories`;
            } else {
                document.title = `${name} | ${AppSettings.currentSite.label}  Accessories`;
            }
        }, 2000);
    } else {
        document.title = `${name} | ${AppSettings.currentSite.label}  Accessories`;
    }
};

/** Retrieve dealerization bacs and if the user is dealerized in an object **/
export function getDealerizedData(dealerReducerData) {
    // Passing in an array of BACs for some reason causes infinite calls being made. so turn them into a string.
    // First check if a cookie has been set. If so, user dealerized.
    let data = {
        isDealerized: dealerReducerData.selectedDealerAllData ? true : false,
        bac: dealerReducerData.selectedDealerAllData?.bac || "",
        isValidated: dealerReducerData.selectedSource !== ""
    };
    // Dealerized only if bac value exists - cookie can only have at most one bac value
    if (!data.isDealerized) {
        if (dealerReducerData?.listOfAllSources?.length) {
            data.bac = commaSeparatedBacFromObj(dealerReducerData.listOfAllSources);
        } else {
            data.bac = "";
        }
    }
    return data;
}

export function commaSeparatedBacFromObj(arr) {
    let str = "";
    if (arr.length) {
        for (let index in arr) {
            if (index == arr.length - 1) {
                str += arr[index].bac;
            } else {
                str += arr[index].bac + ",";
            }
        }
    }

    return str;
}

export const getSearchBaseUrl = () => {
    if (AppSettings.isT3) {
        return "/" + dealerName + "/search";
    } else {
        return "/search";
    }
};

// Retrieves vehicle params from session reducer and builds a URL
// Can pass a string value before the params (pageName) and add custom
// params if more are needed (param)
export const getSessionParams = (pageName, param) => {
    const session = store.getState().Session;
    const urlParams = AppSettings.urlParameters();

    // Get params
    let year = session.year || urlParams.year || "";
    let make = session.make || urlParams.make || "";
    let model = session.model || urlParams.model || "";
    let vin = session.vin || urlParams.vin || "";
    let modelId = session.vehicleConfig.modelId || urlParams.modelId || "";
    let body = session.vehicleConfig.body || urlParams.body || "";
    let bodyId = session.vehicleConfig.bodyId || urlParams.bodyId || "";
    let wheel = session.vehicleConfig.wheel || urlParams.wheel || "";
    let wheelId = session.vehicleConfig.wheelId || urlParams.wheelId || "";
    let trim = session.vehicleConfig.trim || urlParams.trim || "";
    let trimId = session.vehicleConfig.trimId || urlParams.trimId || "";
    let drive = session.vehicleConfig.drive || urlParams.drive || "";
    let driveId = session.vehicleConfig.driveId || urlParams.driveId || "";
    let engine = session.vehicleConfig.engine || urlParams.engine || "";
    let engineId = session.vehicleConfig.engineId || urlParams.engineId || "";
    let bodyNumDoorsId = session.vehicleConfig.bodyNumDoorsId || urlParams.bodyNumDoors || "";

    // Start url
    let url = `${pageName ? pageName : ""}`;

    // Check for ymm or vin
    if (year && make && model && !vin) {
        url += `?year=${year}&make=${make}&model=${model}` + getBacIfT3();
    } else if (vin) {
        url += `?vin=${vin}` + getBacIfT3();
    } else {
        // SWF
        let params = {};
        year ? (params.year = year) : null;
        make ? (params.make = make) : null;
        model ? (params.model = model) : null;

        url += jsonToUrlParams(params);
    }

    if (!vin) {
        // Vehicle config params
        modelId ? (url += `&modelId=${modelId}`) : "";
        body ? (url += `&body=${body}`) : "";
        bodyId ? (url += `&bodyId=${bodyId}`) : "";
        wheel ? (url += `&wheel=${wheel}`) : "";
        wheelId ? (url += `&wheelId=${wheelId}`) : "";
        trim ? (url += `&trim=${trim}`) : "";
        trimId ? (url += `&trimId=${trimId}`) : "";
        drive ? (url += `&drive=${drive}`) : "";
        driveId ? (url += `&driveId=${driveId}`) : "";
        engine ? (url += `&engine=${engine}`) : "";
        engineId ? (url += `&engineId=${engineId}`) : "";
        bodyNumDoorsId ? (url += `&bodyNumDoors=${bodyNumDoorsId}&bodyNumDoorsId=${bodyNumDoorsId}`) : "";
    }
    // Extra param (i.e. categoryId)
    param ? (url += `${param}`) : "";

    return url.replace(/ /g, "%20");
};

getSessionParams.PropTypes = {
    pageName: PropTypes.string
};

/***  bac for T1 email campagins **/
export const setBacIfT1 = (bac) => {
    if (bac === -9999) {
        return localStorage.removeItem("bacNumber");
    }
    if (bac && !AppSettings.isT3) {
        localStorage.setItem("bacNumber", JSON.stringify(bac));
    }
};

export const getBacIfT3 = () => {
    if (AppSettings.isT3) {
        return "&bac=" + AppSettings.bac;
    } else {
        return "";
    }
};

/** Current brand **/
export const brand = () => {
    if (!AppSettings.isT3) {
        return AppSettings.currentSite.label.toLowerCase();
    } else {
        return null;
    }
};

/*** This service needs to run on t3 site to get the dealer price (install price)**/
/*export const loadDealerPricing =()=>{
    return axios.get(dealerUrl).then((success)=>{success.data}).catch((error)=>{console.log("unable to load GMContractSetup",error); throw error});
};*/
export const loadDealerPricing = () => {
    if (restApiContractsFeatureFlag && !AppSettings.isT3) {
        return axios.post(dealerUrl).then((response) => {
            response;
        });
    } else {
        return axios.get(dealerUrl).then((response) => {
            response;
        });
    }
};

/** Live Chat/LivePerson Monitor **/
export const loadLiveChatScript = (environmentName) => {
    //const liveChatEnv = process.env.REACT_APP_LIVECHAT_SCRIPT;
    if (environmentName === "accsprodw" || environmentName === "accsprodm") {
        try {
            LiveChatProd();
        } catch (e) {
            console.log("Live Chat Feature Error: ", e);
        }
    } else {
        LiveChatPreProd();
    }
    /*
    const liveChatEnv = process.env.REACT_APP_LIVECHAT_SCRIPT;
    if(liveChatEnv ==='livechat_prod'){
         LiveChatProd();
     }
     else {
         LiveChatPreProd();
     }*/
};

/** Sends User info to LiveEngage agent **/
export const sendLiveChatUserInfo = (userData) => {
    window.lpTag.events.bind("LP_OFFERS", "OFFER_CLICK", function (eventData, eventInfo) {
        if (eventData.state === 1 && userData.accountStatus === "Enabled") {
            window.lpTag.sdes.push({
                type: "personal",
                personal: {
                    firstname: userData.firstName || "",
                    lastname: userData.lastName || ""
                }
            });
        }
    });
};

/** Sets the authenticated/unauthenticated Live Chat button **/
export const setLiveChatButtonAttributes = (authenticatedUser) => {
    if (!authenticatedUser) {
        if (window.lpTag) {
            //sets the liveChat button to use the unauthenticated button
            window.lpTag.section =
                AppSettings.liveChatButtonAttributes.chatEngagementAttributes.unauthenticated.en[
                    AppSettings.currentSite.label.toLowerCase()
                ];
        }
    } else if (authenticatedUser) {
        if (window.lpTag) {
            //sets the liveChat button to use the authenticated button
            window.lpTag.section =
                AppSettings.liveChatButtonAttributes.chatEngagementAttributes.authenticated.en[
                    AppSettings.currentSite.label.toLowerCase()
                ];
        }
    }
};

/** Scroller to the top function **/
export const scrollerToTop = (id) => {
    if (id !== null || id !== undefined) {
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: "smooth" });
    }
};

/** Store ID **/
export const storeId = () => {
    return AppSettings.storeId;
};

/***  bac for T1 email campagins **/
export const setPromoCodeLocalStorage = () => {
    const code = queryString.parse(location.search).promoCode;
    if (code !== undefined) {
        localStorage.setItem("promoCode", code);
        console.log("Local Session: ==> " + localStorage.getItem("promoCode"));
    } else {
        console.log("Unable to add Promo Code to Local Storage", code);
    }
};

/** RemovePromoCode in Order Conformation Page **/
export const removePromoCode = () => {
    return localStorage.removeItem("promoCode");
};

export const getBrandUrl = () => {
    switch (AppSettings.currentSite.label.toLowerCase()) {
        case "chevrolet": {
            return (
                process.env.REACT_APP_DAM_SOURCE +
                "/marketing/accessories/chevrolet/na/usa/en_us/documents/Chevrolet_Accessories_Important_Information.pdf"
            );
        }
        case "gmc": {
            return (
                process.env.REACT_APP_DAM_SOURCE +
                "/marketing/accessories/gmc/na/usa/en_us/documents/GMC_Accessories_Important_Information.pdf"
            );
        }
        case "buick": {
            return (
                process.env.REACT_APP_DAM_SOURCE +
                "/marketing/accessories/buick/na/usa/en_us/documents/Buick_Accessories_Important_Information.pdf"
            );
        }
        case "cadillac": {
            return (
                process.env.REACT_APP_DAM_SOURCE +
                "/marketing/accessories/cadillac/na/usa/en_us/documents/Cadillac_Accessories_Important_Information.pdf"
            );
        }
        default:
            return (
                process.env.REACT_APP_DAM_SOURCE +
                "/marketing/accessories/chevrolet/na/usa/en_us/documents/Chevrolet_Accessories_Important_Information.pdf"
            );
    }
};

export const getGenericVehicle = () => {
    return String(process.env.REACT_APP_DAM_SOURCE + process.env.REACT_APP_GENERIC_CURRENT_VEHICLE_ICON); 
}

/** Login to Azure **/
export const handleUserLogin = (pageRedirect, prepopulatedEmail) => {
    // The pageRedirect props allows you to redirect back to a specific page after logging in and returning to B2C.
    const applicationState = {
        redirectPath: pageRedirect
            ? window.location.protocol + "//" + window.location.host + pageRedirect
            : window.location.href
    };
    const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));
    const userFlow = AppSettings.azureLoginUserFlow;
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    const currentSite = AppSettings.currentSite.key;

    if (prepopulatedEmail) {
        window.location.href = constructAzureLoginURL(
            userFlow,
            clientId,
            applicationStateString,
            "/authenticate",
            currentSite,
            prepopulatedEmail
        );
    } else {
        window.location.href = constructAzureLoginURL(
            userFlow,
            clientId,
            applicationStateString,
            "/authenticate",
            currentSite
        );
    }
};

/** Converts a JSON object to a string, formatted as url parameters */
export const jsonToUrlParams = (jsonObject) => {
    let urlParams = "";
    for (var attr in jsonObject) {
        if (jsonObject[attr]) {
            const key = encodeURIComponent(String(attr));
            const value = encodeURIComponent(String(jsonObject[attr]));
            urlParams += urlParams.length === 0 ? "?" : "&";
            urlParams += key + "=" + value;
        }
    }
    return urlParams;
};

/** Function to replace certain keys for apis Eg. trim -> trimId */
export const constructMappingUrl = (jsonObject) => {
    if (jsonObject.trimId) {
        jsonObject.trim = jsonObject.trimId;
        delete jsonObject.trimId;
    }
    if (jsonObject.body) {
        delete jsonObject.body;
    }
    if (jsonObject.bodyId) {
        jsonObject.bodyType = jsonObject.bodyId;
        delete jsonObject.bodyId;
    }
    if (jsonObject.wheel) {
        delete jsonObject.wheel;
    }
    if (jsonObject.wheelId) {
        jsonObject.wheelBase = jsonObject.wheelId;
        delete jsonObject.wheelId;
    }
    if (jsonObject.driveId) {
        jsonObject.driveType = jsonObject.driveId;
        delete jsonObject.driveId;
    }
    if (jsonObject.drive) {
        delete jsonObject.drive;
    }
    if (jsonObject.engineId) {
        jsonObject.engineBase = jsonObject.engineId;
        delete jsonObject.engineId;
    }
    if (jsonObject.engine) {
        delete jsonObject.engine;
    }

    return jsonObject;
};

/** Builds the url context for a product's Product Details page */
export const constructProductUrl = (seoUrl, additionalParams) => {
    // Set up json object with url parameters
    const session = store.getState().Session;
    let urlParams = session.vehicleConfig;
    delete urlParams.searchTerm;
    //If searching with vin, session fitment cookie will also have ymm fitment data. So we create a new object with just vin to not add unnecessary ymm fitment to url.
    if (session.vin) {
        urlParams = {"vin":session.vin};
    } else {
        urlParams.year = session.year;
        urlParams.make = session.make;
        urlParams.model = session.model;
    }

    // Set up category/subcategory names before removing from additionalParams (they are not url params)
    let categoryName = "";
    if (additionalParams && additionalParams.categoryName) {
        categoryName = getUrlCategory(additionalParams.categoryName);
        delete additionalParams.categoryName;
    }

    let subcategoryName = "";
    if (additionalParams && additionalParams.subcategoryName) {
        subcategoryName = getUrlCategory(additionalParams.subcategoryName);
        delete additionalParams.subcategoryName;
    }

    // Append additional params
    if (additionalParams) {
        Object.assign(urlParams, additionalParams);
    }

    // Construct the url
    let url = "";

    if (AppSettings.isT3) {
        url += AppSettings.dealerName;
    }

    url += AppSettings.routePaths["product"] + "/";

    if (categoryName) {
        url += categoryName + "/";
    }

    if (subcategoryName) {
        url += subcategoryName + "/";
    }
    url += seoUrl + jsonToUrlParams(urlParams);

    return url;
};

/**
 * Converts category to url friendly category separating words with dashes.
 * @param {*} clpCategory category name/title obtained from api calls
 * @returns url friendly category title
 */
export const getUrlCategory = (clpCategory) => {
    const baseUrlCategory = clpCategory.toLowerCase().split(/\W+/);
    let urlCategory = baseUrlCategory.join("-");
    return urlCategory;
};

// Takes an array of prices and returns the price for the given type
export const formatProductPrice = (prices, type) => {
    const priceEntry = prices.find((price) => price.usage.toLowerCase() === type);
    return priceEntry && priceEntry.currency && priceEntry.value
        ? formattedCurrency(priceEntry.currency, priceEntry.value)
        : null;
};

export const isValidResponse = (response) => {
    return response !== null && response !== undefined && typeof object;
};

// Calculate modulo
export const mod = (n, m) => {
    return ((n % m) + m) % m;
};

//Listening to browserHistory
accHistory.listen(() => {
    // Updating the pageName when routing change
    AppSettings.pageName = getPageName();
    /**The espot footer calls were passing in the wrong pageName to the espot service call with the value of 'Authenticate'
       This dispatch's the loadFooterDataAsync to remake the footer espot service calls for every route change.
       This is required as every page has distinct footer text assigned to them in Management Center **/
    if (AppSettings.pageName !== "Authenticate") {
        store.dispatch(loadFooterDataAsync(AppSettings.pageName));
    }
});

/*** Create a shared function to check the partial authentication error and redirect the page ***/
export const checkPartialAuthenticationError = async () => {
    let response = "";
    const orderId = queryString.parse(location.search).orderId;

    try {
        response = await nodeAuthCheck();

        if (isValidValue(response) && response.data) {
            const message = response.data.message;
            if (isValidValue(message) && message === "success") {
                localStorage.setItem("authExpiredMsg", "LABEL_AUTHENTICATION_COOKIE_EXPIRED_ERROR_MESSAGE");
                // send to roadblock
                //accHistory.push(`/checkoutmethod?orderId=${orderId}`);
                location.href = `/checkoutmethod?orderId=${orderId}`;
            } else {
                localStorage.removeItem("authExpiredMsg");
            }
        }
    } catch (error) {
        console.log("Error occur while trying to call /node/authCheck api , error => ", error);
    }
};

export const isIEBrowser = () => {
    const ua = window.navigator.userAgent;
    return ua.indexOf("Trident/") > 0;
};

export const LinkWrapper = (props) => {
    const aTag = props.aTag;
    const productT3 = AppSettings.isT3 ? props.route + "&bac=" + AppSettings.bac : props.route;

    if (aTag) {
        return (
            <a href={productT3} className={props.classNames}>
                {props.children}
            </a>
        );
    } else {
        return (
            <Link to={productT3} className={props.classNames}>
                {props.children}
            </Link>
        );
    }
};

export const isEmpty = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

/**
 * Replaces < and > in a string to prevent html injections
 * @param {*} value Field information to scrub
 * @returns scrubbed value
 */
export const scrubFormField = (value) => {
    return value.replace(/[<>()\[\]\!\?\%\*\&\^\#\~\`\+\=\:]/g, "");
};

/**
 * Creates a date string based on the options
 *
 * Options:
 *      second: 'numeric'
 *      minute: 'numeric'
 *      hour: 'numeric'
 *      hour12: boolean
 *      timeZone: 'Australia/Sydney'
 *      timeZoneName: 'short', 'long'
 *      day: 'numeric'
 *      weekday: 'long', 'short'
 *      month: 'long', 'short'
 *      year: 'numeric'
 *
 * @param {string} datestring
 * @param {object} options
 *
 * @returns string
 */
export const formatDateString = (datestring, options) => {
    let date = new Date(datestring);

    const language = i18n.language;
    const newDate = new Intl.DateTimeFormat(language, options).format(date);

    return newDate;
};

// Formats a list of suggestions "a,b,c" to "a, b, and c".
export function mispellingsFormat(list) {
    let suggestions = "";

    if (list.length > 1) {
        list.forEach((val, key, arr) => {
            if (key == arr.length - 1) {
                suggestions += "and " + val;
            } else {
                suggestions += val + ", ";
            }
        });
    } else {
        // List is just one suggestion. Return only that.
        suggestions = list[0];
    }

    return suggestions;
}

/**
 * Custom hook to return if window width is below bootstrap mobile breakpoint
 * @returns {boolean}
 */
export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const MOBILE_BREAKPOINT = AppSettings.MEDIUM_BREAKPOINT;

    useEffect(()=> {
        const controller = new AbortController();
        // This is less than or equal to because medium breakpoint is 991px rather than 992px
        setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);

        const useIsMobileHandleResize = () => {
            setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
        };

        window.addEventListener("resize", useIsMobileHandleResize,{signal: controller.signal});

        // Remove listener on cleanup
        return (() => {
            controller.abort();
        })
    }, [])

    return isMobile;
};

/**
 * Append the cookie data to existing url parameters. The cookie data is retrieved from the session reducer.
 * @param {object} params: Existing url parameters.
 * @param {object} sessionData: This is the reducer that gets set by the cookie.
 * @param {boolean} returnAsUrl: User can decide to return the params as an object or as url parameters.
 * 
 * @returns {object} containing the url parameters.
 */
export const appendCookieFitmentToUrl = (params, sessionData, returnAsUrl=false) => {
    sessionData.year ? (params.year = sessionData.year) : null;
    sessionData.make ? (params.make = sessionData.make) : null;
    sessionData.model ? (params.model = sessionData.model) : null;
    sessionData.modelId ? (params.modelId = sessionData.modelId) : null;
    sessionData.body ? (params.body = sessionData.body) : null;
    sessionData.bodyId ? (params.bodyId = sessionData.bodyId) : null;
    sessionData.wheel ? (params.wheel = sessionData.wheel) : null;
    sessionData.wheelId ? (params.wheelId = sessionData.wheelId) : null;
    sessionData.trim ? (params.trim = sessionData.trim) : null;
    sessionData.trimId ? (params.trimId = sessionData.trimId) : null;
    sessionData.drive ? (params.drive = sessionData.drive) : null;
    sessionData.driveId ? (params.driveId = sessionData.driveId) : null;
    sessionData.engineId ? (params.engineId = sessionData.engineId) : null;
    sessionData.categoryId ? (params.categoryId = sessionData.categoryId) : null;
    sessionData.bodyNumDoorsId ? (params.bodyNumDoors = sessionData.bodyNumDoorsId) : null;
    sessionData.searchTerm ? (params.searchTerm = sessionData.searchTerm) : null;

    return returnAsUrl ? jsonToUrlParams(params) : params;
}

//
/**
 * Adobe analytics team will only accept <<brand code>> in the URL query string of one of the following: buick || ca || ch || gmc || gmgp
 *  @param analyticsTag - specific Adobe tag to set
 * @param queryStringValue - specific query string value to append after the standard '?eVar25=<channel>_<brand>_'
 * @returns {string}
 */
export function appendAnalyticsTagAsURLParam(analyticsTag, queryStringValue) {
    let brand = AppSettings.brand.toLowerCase();
    if (brand === "chevrolet") {
        brand = "ch";
    } else if (brand === "cadillac") {
        brand = "ca";
    } else if (brand === "gm") {
        brand = "gmgp";
    }
    return `?${analyticsTag}=acc_${brand}_${queryStringValue}`;
}

//Promise to load the GM Wallet Widget
export const loadGMWalletUI = new Promise((resolve, reject) => {
    // Append Wallet Script
    const useWalletWidget = get_FF_1745770_USE_AZURE_WALLET_WIDGET();
    //Wrap in feature flag so that it doesnt load the script as Promises are ran automatically whether they are called or not
    if (useWalletWidget) {
        const script = document.createElement("script");
        let epgScript = process.env.REACT_APP_GM_WALLET_WIDGET_SCRIPT_URL;
        //use the following for local testing, will remove when development is done
        let epgScript1 =
            "https://ui-dev.musea2.azure.ext.gm.com/webapps/EPG_163080_WALLETUI-3.0.3-SNAPSHOT-DEV_Profile/public/lib/v3/GMWalletUI.js";
        let epgScript2 = "https://wallet-ui-dev2.apps.pcfepg3mi.gm.com/lib/v3/GMWalletUI.min.js";
        let epgScript3 = "http://localhost:63342/EPG_163080_WALLETUI/public/lib/v3/GMWalletUI.js"; //local

        script.src = epgScript;
        script.async = true;
        document.head.appendChild(script);
        script.onload = () => {
            resolve();
        };
        // If script isn't loaded correctly (wallet script unavailable), display error message
        // on payment methods page and display guets payment widget in checkout
        script.onerror = () => {
            store.dispatch(walletLoadError());
            reject("Error loading payment widget");
        };
    }
});
