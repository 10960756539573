import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { slickSliderSettings, VideoExitBtnImg, VideoPlayBtnImg, generateAltText } from "./CarouselHelper";
import { useReplaceAllNamedDisclaimers } from "../../modules/App/Footer/FooterDisclaimerLabel";

function CarouselSlider(props) {
    // GET CAROUSEL DATA
    const [carouselAssets, setCarouselAssets] = useState({});
    useEffect(() => {
        setCarouselAssets(props.carouselAssets);
    }, [props.carouselAssets]);

    const replaceDisclaimers = useReplaceAllNamedDisclaimers();

    const startVideo = (videoId) => {
        const startVideos = carouselAssets.map((assets) => {
            //set videoIsPlaying to true for selected video only
            if (assets.videoId === videoId) {
                return { ...assets, videoIsPlaying: true };
            }
            return assets;
        });
        setCarouselAssets(startVideos);
    };

    const stopVideo = (videoId) => {
        const stopVideos = carouselAssets.map((assets) => {
            //set videoIsPlaying to true for selected video only
            if (assets.videoId === videoId) {
                return { ...assets, videoIsPlaying: false };
            }
            return assets;
        });
        setCarouselAssets(stopVideos);
    };

    const redirectUrl = (newUrl) => {
        //Appends fitment info onto banner link if present in URL
        //This is necessary to avoid appending the parent categoryId
        const oldUrl = window.location.href;
        const catIdIndex = oldUrl.indexOf("&categoryId=");
        let fullUrl = "";
        if (catIdIndex != -1) {
            fullUrl =
                newUrl +
                "&" +
                oldUrl.substring(oldUrl.indexOf("?") + 1, catIdIndex) +
                oldUrl.substring(catIdIndex + 17, oldUrl.length);
        } else {
            fullUrl = newUrl;
        }
        return fullUrl;
    };

    /**************************************************************/
    //     CREATE SLICK CAROUSEL
    /**************************************************************/
    //  This function sets up slick and populates it with images, marketing text
    //  and video placeholder images from the data recieved from the espot.
    //  This data is parsed in HomeCarouselWorker.js
    const createSlides = () => {
        if (carouselAssets && carouselAssets.length) {
            // Dangerously Set innerHTML: https://react-cn.github.io/react/tips/dangerously-set-inner-html.html
            function createMarkup(text) {
                return {
                    __html: text
                };
            }

            // BRIGHTCOVE VIDEO JS
            const script = document.createElement("script");
            script.src = "https://players.brightcove.net/5358100852001/ClrGDcamQr_default/index.min.js";
            script.async = true;
            document.body.appendChild(script);

            return (
                <div className="q-gallery-full-scroller">
                    <div className="ac-no-background q-multimedia-scroller q-mod q-mod-multimedia-scroller">
                        <Slider
                            {...slickSliderSettings(props.dataDtm)}
                            className="q-slider q-with-caption js-has-caption q-slider-hover-arrow"
                        >
                            {carouselAssets.map((asset, i) => (
                                <div
                                    className="q-responsive-image q-responsive-image-container"
                                    key={i}
                                    alt={asset.assetName.toString()}
                                >
                                    {/* IMAGE SLIDES */}
                                    {asset.imagePath && (
                                        <a
                                            className={!asset.contentUrl && "inactiveLink"}
                                            href={redirectUrl(asset.contentUrl)}
                                            onClick={!asset.contentUrl ? (e) => e.preventDefault() : null}
                                        >
                                            <div className="q-content-overlay">
                                                {/* Markup html displayed over carousel image */}
                                                <div className="q-layer q-top-xs q-top-s q-top-m q-top-l q-top-xl">
                                                    <div className="q-layer-width  q-twenty-five-percent-width-xsmall q-twenty-five-percent-width-small q-twenty-five-percent-width-medium q-twenty-five-percent-width-large q-twenty-five-percent-width-xlarge">
                                                        <div className="row q-gridbuilder grid-bg-color-one q-indent-grid-content">
                                                            <div
                                                                className="inner-carousel-text-content"
                                                                dangerouslySetInnerHTML={createMarkup(
                                                                    replaceDisclaimers(asset.marketingText)
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <picture>
                                                    {/* swap image src based on screen size */}
                                                    <source media="(min-width: 1025px)" srcSet={asset.imagePath} />
                                                    <source media="(min-width: 641px)" srcSet={asset.imagePathMd} />
                                                    <img
                                                        // applies to all smaller viewports (max-width: 640px)
                                                        src={asset.imagePathSm}
                                                        alt={generateAltText(asset.marketingText)}
                                                    />
                                                </picture>
                                            </div>
                                        </a>
                                    )}
                                    {/* VIDEO SLIDES */}
                                    {asset.videoPlaceholderImgPath && (
                                        <div className="q-content-overlay">
                                            <div className="ac-video-slide-wrapper">
                                                {/* Video play button - show video on click */}
                                                <span
                                                    onClick={() => startVideo(asset.videoId)}
                                                    className="ac-video-play-btn"
                                                >
                                                    <img src={VideoPlayBtnImg} alt="play" />
                                                </span>
                                                <picture>
                                                    {/* swap image src based on screen size */}
                                                    <source
                                                        media="(min-width: 1500px)"
                                                        srcSet={asset.videoPlaceholderImgPath}
                                                    />
                                                    <source
                                                        media="(min-width: 1200px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 960px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 600px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 0px)"
                                                        srcSet={asset.videoPlaceholderImgPathSm}
                                                    />
                                                    <img
                                                        src={asset.videoPlaceholderImgPath}
                                                        alt="image Not Found"
                                                        className="q-rectangle-image-responsive q-image q-posterframe"
                                                    />
                                                </picture>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            );
        }
    };
    /**************************************************************/
    //     CREATE VIDEO PLAYER
    /**************************************************************/
    //  This function contains the video player for the carousel.
    //  It's detached because Slick Slider duplicates its slides
    //  (when infinite is set to true) and was adding two of each video
    const createVideo = () => {
        if (carouselAssets && carouselAssets.length) {
            return (
                <div className="video-wrapper">
                    {carouselAssets.map((asset, i) => (
                        <div key={i}>
                            {/* Brightcove video display when this.props.data.videoIsPlaying is set to true */}
                            {asset.videoIsPlaying && (
                                <div className="ac-carousel-video-wrapper">
                                    <video
                                        id={`bcPlayerID-` + asset.videoId}
                                        data-video-id={asset.videoId}
                                        data-account="5358100852001"
                                        data-player="ClrGDcamQr"
                                        data-embed="default"
                                        data-application-id
                                        className="video-js gm-player"
                                        controls={true}
                                        autoPlay={true}
                                    />
                                    {/* video close button - stop video on click */}
                                    <span onClick={() => stopVideo(asset.videoId)} className="ac-video-stop-btn">
                                        <img src={VideoExitBtnImg} alt="X" />
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }
    };

    return (
        <div className="home-carousel-wrapper">
            {/* video player */}
            {createVideo()}
            {/* slick carousel */}
            {createSlides()}
        </div>
    );
}

export default CarouselSlider;
