import queryString from 'query-string';
import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { reset, SubmissionError } from 'redux-form';
import AppSettings from '../../core/AppSettings';
import store from '../../core/Redux/Store';
import CheckoutPageButtons from '../../shared/CheckoutPageButtons/CheckoutPageButtons';
import CheckoutProgressBar from '../../shared/CheckoutProgressBar/CheckoutProgressBar';
import ErrorMessage from '../../shared/ErrorMessage/ErrorMessage';
import Spinner from '../../shared/Spinner/Spinner';
import SummaryRailContainer from '../../shared/SummaryRail/SummaryRailContainer';
import { showApplyCouponBtnSpinner } from "../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator";
import {loadGMWalletUI, scrollerToTop, titleSetUp} from "../../shared/Utils/Utils";
import {
    couponCodePostAsync,
    couponCodeRemovePostAsync,
    deliveryMethodChangeAsync,
    deliveryPageTaxFalse,
    editForm, enableMissingPaymentFlag,
    loadBillingAsShipping,
    loadInitialContactForm,
    nextStep,
    onDeliveryFormPrevious,
    prevStep,
    resetAllErrors,
    sendToOrderConfirmation,
    setDeliveryErrorFalse,
    setLoading,
    submitContactFormAsync,
    submitDeliveryFormAsync,
    submittingOrder,
    updateBillingInfo
} from "../Checkout/CheckoutRedux/CheckoutActionCreator";
import AddressValidation from './Modals/AddressValidation/AddressValidation';
import CheckoutWizard from './CheckoutWizard/CheckoutWizard';
import { isValidDeliveryMethodForm } from './Pages/DeliveryAndDiscounts/Delivery/DeliveryFormValidation/DeliveryFormValidate';
import PlaceOrderBtn from './Pages/Review/PlaceOrderBtn';
import RoscaDisclosure from './Pages/Review/RoscaDisclosure';
import ShippingErrorModal from './Modals/AddressValidation/ShippingErrorModal';
import {get_FF_1745770_USE_AZURE_WALLET_WIDGET} from "../../shared/Utils/FeatureFlags";

class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            analyticsFired: false,
            missingOrderId: false
        };

        /*** Title Set Up ***/
        titleSetUp(this.props.t("LABEL_CHECKOUT"));

        // Loads data for first step of form.
        const loadInitialContactFormAction = loadInitialContactForm();
        store.dispatch(loadInitialContactFormAction);
    }
    componentWillMount() {
        const parsedParams = queryString.parseUrl(location.search);
        if (parsedParams.query['orderId'] == undefined) {
            this.setState(() => {
                return { missingOrderId: true };
            });
        }
        const useWalletWidget = get_FF_1745770_USE_AZURE_WALLET_WIDGET();

        //Loading the GMWallet script sooner than later to avoid having to wait for it to load on Payment Page
            useWalletWidget &&
            loadGMWalletUI.catch((error) => {
                console.error(error);
            });
    }
    componentDidUpdate(prevProps) {
        // Makes analtyics direct call on every product details page visit.
        if (typeof _satellite != 'undefined' && !this.state.analyticsFired) {
            _satellite.track("checkout");
            this.setState({
                analyticsFired: true
            });
        }
        /*On initial mount, when the orderId is missing in the URL,
        attempt to manually update the URL to include '?orderId={orderId}' with the orderId retrieved from the getCartResponse call*/
        if (this.state.missingOrderId && this.props.cartResponse.parsedResponse && this.props.cartResponse.parsedResponse.orderId) {
            window.history.pushState({ orderId: this.props.cartResponse.parsedResponse.orderId }, "Title", `/checkout?orderId=${this.props.cartResponse.parsedResponse.orderId}`);
            AppSettings.orderId = queryString.parse(location.search).orderId;
            this.setState(() => {
                return { missingOrderId: false };
            });
        }

        // SEND UESR TO ORDER CONFIRMATION PAGE AFTER SUCCESSFUL SUBMIT ORDER
        if (this.props.checkoutData.sendToOrderConfirmation === true && this.props.checkoutData.sendToOrderConfirmation !== prevProps.checkoutData.sendToOrderConfirmation) {
            const resetSendToOrderConfirmationAction = sendToOrderConfirmation(false);
            store.dispatch(resetSendToOrderConfirmationAction);
            const placeOrderResponse = this.props.checkoutData.submitOrderInfo;

            if (placeOrderResponse.submitOrderStatus === "SUBMIT_SUCCESS") {
                const orderId = placeOrderResponse.submitOrderId;
                if (AppSettings.isT3) {
                    window.location.href = `${AppSettings.dealerName}/orderconfirmation?orderId=${orderId}&bac=${AppSettings.bac}`;
                }
                else {
                    window.location.href = `/orderconfirmation?orderId=${orderId}`;
                }
            }
        }

        // SEND USER TO HOME PAGE IF ON THE CHECKOUT PAGE
        // AND NO DATA/CART IS EMPTY
        if (this.props.checkoutData.sendToHome) {
            this.props.history.push(AppSettings.homeUrl);
        }
    }

    renderCheckoutHeader(t) {
        return (
            <div className="ac-checkout-wizard-header-steps small-12 medium-12 large-12 xlarge-12  grid-column-alignment-left columns">
                <div className="small-12 medium-12 large-3 xlarge-3  grid-column-alignment-left columns">
                    <h1 className="q-headline2">{t("LABEL_CHECKOUT")}</h1>
                </div>
                <div className="small-12 medium-12 large-9 xlarge-9  grid-column-alignment-left columns">
                    <CheckoutProgressBar step={this.props.checkoutData.currentStep} />
                </div>

                <hr />
            </div>
        )
    }

    renderCheckoutWizard() {
        return (
            <div className="small-12 medium-12 large-12 xlarge-6  grid-column-alignment-left columns gmit-gf-groupbox">
                <CheckoutWizard
                    onSubmit={this.props.handleNext}
                    onPrevious={this.props.handlePrevious}
                    step={this.props.checkoutData.currentStep}
                    label={this.props.checkoutData.currentLabel}
                    cartResponse={this.props.cartResponse}
                    shippingContactFormData={this.props.checkoutData.shippingContactForm}
                    handleContactFormSubmit={this.props.handleContactFormSubmit}
                    onDeliveryFormPrevious={this.props.onDeliveryFormPrevious}
                    handleDeliveryMethodChange={this.props.handleDeliveryMethodChange}
                    handleCouponCodeSubmit={this.props.handleCouponCodeSubmit}
                    handleCouponCodeRemove={this.props.handleCouponCodeRemove}
                    deliveryForm={this.props.deliveryForm}
                    handlePaymentFormSubmit={this.props.handlePaymentFormSubmit}
                    handleBillingInformationCheckBox={this.props.loadBillingInformation}
                    enableBillingAsShipping={this.props.checkoutData.paymentPage.isBillingIsSameAsShipping}
                    paymentResponse={this.props.checkoutData.paymentPage}
                    handleEditClick={this.props.handleEditClick}
                    handlePaymentPrevious={this.props.handlePaymentPrevious}
                    showPaymentSpinner={this.showSpinner()}
                    handleDeliveryFormSubmit={this.props.handleDeliveryFormSubmit}
                    deliveryError={this.props.checkoutData.deliveryError}
                    deliveryErrorTax={this.props.checkoutData.deliveryErrorTax}
                    deliveryMethodShowSpinner={this.props.checkoutData.deliveryMethodShowSpinner}
                    deliveryMissingParams={this.props.checkoutData.deliveryMissingParams}
                    isRewardsInputDisabled={this.props.rewardsData.isRewardsInputDisabled}
                    zeroDollarAmount={this.props.checkoutData.zeroCheckoutAmount}
                />
            </div>
        )
    }


    renderSummary(pointsAutoRemoved, voucherAutoRemoved, voucherAmountWarning) {
        const cartResponse = this.props.cartResponse;
        const orderInCart = cartResponse.response.order;
        const parsedOrderInCart = cartResponse.parsedResponse;
        const showSpinner = cartResponse.isLoading;
        let renderCart = false;

        if (orderInCart) {
            renderCart = !showSpinner && orderInCart.vehicles.length > 0;
        }

        return (
            <div className="small-12 medium-12 large-12 xlarge-6 columns ac-summary-wrapper">
                {
                    /** RENDER CART WHEN DATA IS LOADED CORRECTLY**/
                    renderCart &&
                    <SummaryRailContainer
                        data={parsedOrderInCart}
                        handleCouponCodeRemove={this.props.handleCouponCodeRemove}
                        deliveryErrorTax={this.props.checkoutData.deliveryErrorTax}
                        pointsAutoRemoved={pointsAutoRemoved}
                        voucherAutoRemoved={voucherAutoRemoved}
                        voucherAmountWarning={voucherAmountWarning}
                    />
                }
            </div>
        )
    }

    showSpinner() {
        return (
            this.props.checkoutData.isLoading === true
            || this.props.cartResponse.isLoading === true
            || (this.props.checkoutData.currentStep === 3 && this.props.checkoutData.paymentPage.showSpinner === true)
        )
    }

    renderButtons() {
        let onPrev;
        let onNext;
        let secondaryButton;
        let formName;
        let statsProps = {};

        switch (this.props.checkoutData.currentStep) {
            case 1:
                secondaryButton = false;
                formName = 'ShippingContactForm';
                statsProps = {
                    className: "stat-button-link",
                    dataDtm: "checkout step1",
                    dataDtm2: "exclude"
                }
                break;
            case 2:
                onPrev = this.props.onDeliveryFormPrevious;
                secondaryButton = true;
                formName = 'DeliveryForm';
                statsProps = {
                    className: "stat-button-link",
                    dataDtm: "checkout step2",
                }
                break;
            case 3:
                if (this.props.checkoutData.paymentPage.payMethodId !== 'payInStore' && !this.props.checkoutData.zeroCheckoutAmount) {
                    onPrev = this.props.handlePaymentPrevious;
                    formName = 'BillingForm';
                } else {
                    onPrev = this.props.handlePrevious;
                    onNext = this.props.handleNext;
                }

                secondaryButton = true;
                statsProps = {
                    className: "stat-button-link",
                    dataDtm: "checkout step3",
                    dataDtm2: ""
                }
                break;
        }
        return (
            <div className="small-12 medium-12 large-12 xlarge-6  columns">
                <CheckoutPageButtons
                    onPrev={onPrev}
                    onNext={onNext}
                    primaryButton={true}
                    secondaryButton={secondaryButton}
                    formName={formName}
                    statsProps={statsProps}
                />
            </div>
        );
    }

    render() {
        const showSpinner = this.showSpinner();
        const showError = !this.props.checkoutData.hideCheckoutAlert;

        let pointsAutoRemoved = false;
        let voucherAutoRemoved = false;
        let voucherAmountWarning = false;
        // Check the orderExtendAtrrtibutes here so they only need to be iterated over once
        if (this.props.rewardsData.isRewardsActive) { 
            if (this.props.cartResponse.parsedResponse &&
                this.props.cartResponse.parsedResponse.orderInfo.orderExtendAttribute) {
                for (let attr of this.props.cartResponse.parsedResponse.orderInfo.orderExtendAttribute) {
                    if (attr.attributeName == "rewardsWarning") {
                        const key = JSON.parse(attr.attributeValue).key;
                        switch (key) {
                            case "FAILED_APPLYING_REWARDS_POINTS":
                                pointsAutoRemoved = true;
                                break;
                            case "FAILED_APPLYING_REWARDS_VOUCHER":
                                voucherAutoRemoved = true;
                                break;
                            case "WARNING_VOUCHER_AMT_MODIFIED":
                                voucherAmountWarning = true;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }


        return (
            <Translation>
                {(t) => (
                    <div className="ac-checkout ac-small-sidepadding gmit-gf-request-type">
                        <div className="row q-gridbuilder small-margin ac-checkout gridfullwidth">
                            {this.renderCheckoutHeader(t)}
                            <div className="columns">
                                {
                                    (!showSpinner && voucherAutoRemoved && this.props.checkoutData.currentStep > 2) &&
                                    <ErrorMessage icon={true} message="LABEL_CHECKOUT_AUTO_REMOVED_VOUCHER" />
                                }
                                {
                                    (!showSpinner && pointsAutoRemoved && this.props.checkoutData.currentStep > 2) &&
                                    <ErrorMessage icon={true} message="LABEL_CHECKOUT_AUTO_REMOVED_POINTS" />
                                }
                                {
                                    showError && <ErrorMessage icon={true} message={this.props.checkoutData.currentCheckoutError} htmlMessage={this.props.checkoutData.currentCheckoutError.includes('TAX_REMITTABLE')}/>
                                }
                            </div>
                            {showSpinner &&
                                <div className="small-12 medium-12 large-12 xlarge-12  grid-column-alignment-left columns ac-nopadding-left">
                                    <Spinner scrollToTop={true} />
                                </div>
                            }

                            {(!showSpinner || this.props.checkoutData.currentStep === 3) && this.renderCheckoutWizard()}
                            {!showSpinner &&
                                this.renderSummary(
                                    (pointsAutoRemoved && this.props.checkoutData.currentStep === 2),
                                    (voucherAutoRemoved && this.props.checkoutData.currentStep === 2),
                                    voucherAmountWarning
                                )
                            }
                            {!showSpinner && this.props.checkoutData.currentStep !== 4 && this.renderButtons()}
                            {this.props.checkoutData.currentStep === 4 &&
                                <RoscaDisclosure
                                    roscaCheckboxState={this.props.cartResponse}
                                /> &&
                                <PlaceOrderBtn
                                    cartResponse={this.props.cartResponse}
                                    handleSubmitOrder={this.props.handleSubmitOrder}
                                    showOrderProcessingModal={this.props.checkoutData.showOrderProcessingModal}
                                    hideCheckoutAlert={this.props.checkoutData.hideCheckoutAlert}
                                    isRoscaChecked={this.props.checkoutData.isRoscaChecked}
                                    roscaDisclosureMsg={this.props.checkoutData.roscaDisclosureMsg} />}
                        </div>
                        <div>
                            <AddressValidation />
                            {this.props.checkoutData.currentStep ===2 && <ShippingErrorModal />}
                        </div>
                    </div>
                )}
            </Translation>
        );

    }

}

const mapStateToProps = state => {
    return {
        checkoutData: state.CheckoutReducer,
        cartResponse: state.OrderInfoReducer,
        rewardsData: state.RewardsReducer
    }
}

/**
 * mapDispatchToProps() maps checkout event handlers to Checkout props. These
 * event handlers dispatch appropriate actions to update the checkout state in
 * response to user input and interactions. The event handlers may be passed to
 * the CheckoutWizard and along to other components as necessary.
 */
const mapDispatchToProps = dispatch => {
    return {
        /**
         * Defines general event handlers for checkout.
         */
        handleNext: (e) => {
            store.dispatch(nextStep());
        },
        handlePrevious: (e) => {
            store.dispatch(prevStep());
        },

        /**
         * Defines event handlers for checkout step 1 (shipping/contact form).
         */
        handleContactFormSubmit: (contactFormEvent) => {
            store.dispatch(submitContactFormAsync(contactFormEvent, false));
            // store.dispatch({ type: ORDER_INFO_SHIPPING_RATES });
        },

        /**
         * Defines event handlers for checkout step 2 (delivery form).
         */
        onDeliveryFormPrevious: (e) => {
            const onDeliveryFormPreviousAction = onDeliveryFormPrevious();
            store.dispatch(onDeliveryFormPreviousAction);
            store.dispatch(reset('DeliveryForm'));
        },

        handleDeliveryMethodChange: (orderItemId, shipModeId, quantity, addressId, carrierCode, orderId, productId) => {
            store.dispatch(deliveryMethodChangeAsync(orderItemId, shipModeId, quantity, addressId, carrierCode, orderId, productId));
            store.dispatch(deliveryPageTaxFalse());
        },
        handleCouponCodeSubmit: (couponCode) => {
            store.dispatch(showApplyCouponBtnSpinner(true));
            store.dispatch(couponCodePostAsync(couponCode));
            store.dispatch(deliveryPageTaxFalse());
        },
        handleCouponCodeRemove: (couponCode) => {
            store.dispatch(couponCodeRemovePostAsync(couponCode));
            store.dispatch(deliveryPageTaxFalse());
        },

        /* T3 supports selecting delivery methods in checkout while T1 does not
         * Logic below only verifies delivery form if user is on a T3 site. If on
         * T1, user will bypass validation */
        handleDeliveryFormSubmit: (values, dispatch, props) => {
            const isValid = isValidDeliveryMethodForm(values, props);
            if (AppSettings.isT3 && isValid.result === true) {
                // TODO: find out if these first two dispatches are necessary.
                store.dispatch(setDeliveryErrorFalse());
                store.dispatch(deliveryPageTaxFalse());
                store.dispatch(submitDeliveryFormAsync());
            } else if (!AppSettings.isT3) {
                store.dispatch(setDeliveryErrorFalse());
                store.dispatch(deliveryPageTaxFalse());
                store.dispatch(submitDeliveryFormAsync());
            } else {
                window.scrollTo({ top: 0, behavior: "smooth" });
                scrollerToTop('root');
                throw new SubmissionError(isValid.errors)
            }
        },

        /**
         * Defines event handlers for checkout step 3 (payment).
         */
        /**
         * When user click on "Billing same as shipping" checkbox on checkout step 3 (payment page),
         * all billing information will be filled with shipping information that was provided on checkout step 1.
         * Data Source : GetCartData.Shipping from Order Info Reducer
         */
        loadBillingInformation: () => {
            const currentState = store.getState().OrderInfoReducer.parsedResponse;
            dispatch(loadBillingAsShipping(currentState));
        },
        /**** When user click the next button on payment page,
         * it will get the current state of the form and pass it to payment widget..
         * where its making a call to update credit card information and eventually takes user to step 4 (review page)***/
        handlePaymentFormSubmit: () => {
            dispatch(setLoading(true));
            let formValues = store.getState().form.BillingForm.values;
            dispatch(updateBillingInfo(formValues));
            //Make sure payment information has been stored in the checkout Reducer before moving onto next step
            let paymentInfo = store.getState().CheckoutReducer.paymentPage.paymentInformation.payment;
            if(paymentInfo) {
                store.dispatch(nextStep());
            }else{
                //Make sure Auth User has selected a payment method
                store.dispatch(enableMissingPaymentFlag());
                window.scroll(0,0);
            }
            store.dispatch(setLoading(false));

        },
        handlePaymentPrevious: () => {
            dispatch(resetAllErrors());
            let formValues = store.getState().form.BillingForm.values;
            dispatch(updateBillingInfo(formValues));
            dispatch(prevStep());

        },
        /**
         * Defines event handlers for checkout step 4 (review page).
         */

        handleEditClick: (step) => {
            store.dispatch(editForm(step))
        },
        handleSubmitOrder: () => {
            store.dispatch(submittingOrder())
        },

    }
}
export default withTranslation()(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Checkout)
));
