import i18next from "i18next";
import queryString from "query-string";
import React, { Component } from "react";
import { Translation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppSettings from "../../core/AppSettings";
import vaultConstants from "../../../config/vault_constants";
import store from "../../core/Redux/Store";
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../shared/ErrorMessage/ErrorMessage";

import { updateVehicleInfoByUrl } from "../../shared/Session/VehicleInfo/VehicleInfoActionCreator";
import Spinner from "../../shared/Spinner/Spinner";
import { getDealerizedData, setBacIfT1, titleSetUp } from "../../shared/Utils/Utils";
import VehicleHeader from "../../shared/VehicleHeader/VehicleHeader";
import NaturalLanguageSearch from "../Search/NaturalLanguageSearch/NaturalLanguageSearch";
import {
    closeModal,
    returnToSearch,
    setVehicleFitment
} from "./FitmentCheck/FitmentCheckRedux/FitmentCheckActionCreator";
import ProductDetailsTab from "./Product-Details-Tab/ProductDetailsTabs";
import ProductDetailsDescription from "./ProductDetailsDescription/ProductDetailsDescription";
import ProductDetailsGallery from "./ProductDetailsGallery/ProductDetailsGallery";
import {
    goToCart,
    loadProductDetailsAsync,
    resetPage,
    setProductIdentifier
} from "./ProductDetailsRedux/ProductDetailsActionCreator";
import ProductDetailsSimilarItems from "./ProductDetailsSimilarItems/ProductDetailsSimilarItems";
import ProductDetailsActionArea from './ProductDetailsActionArea';

const featureCookieFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2246212_FITMENT_COOKIE;
class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            analyticsFired: false,
            showError: false,
            rerenderBreadcrumb: false
        };

        /*** T1 LocalStorage for campaignes  ***/
        setBacIfT1(AppSettings.bac);

        this.handlePageLoad();

        // Loads vehicle information into the current state.
        if (!featureCookieFlag){
            const params = this.props.location.search;
            const updateVehicleInfoAction = updateVehicleInfoByUrl(params);
            store.dispatch(updateVehicleInfoAction);
        }

        // Set Vehicle fitment details per query params
        store.dispatch(setVehicleFitment(queryString.parse(location.search)));
        this.handlePDLegacyUrl(this.props.location.pathname, this.props.location.search);
        
    }

	handlePageLoad = () => {
        // Sets the product identifier that is needed to load product details.
        const productIdentifier = this.getProductIdentifier();
        const setProductIdentifierAction = setProductIdentifier(productIdentifier);
        store.dispatch(setProductIdentifierAction);

        // Loads product details into the current state.
        // Also makes vehicleInfo call within the handleLoadData in vehicleInfoWorker.js
        const loadAction = loadProductDetailsAsync();
        store.dispatch(loadAction);
    }

    // TEMPORARY: DELETE handlePDLegacyUrl
    // WHEN BRANDS AND CDK UPDATE THEIR LINKS
    //
    // Convert legacy urls into params
    handlePDLegacyUrl = (path, params) => {
        const parsedParams = queryString.parseUrl(params);
        let { query } = parsedParams;

        // T1 ONLY
        // takes '/year/make/model/productIdentifier'
        // and converts into params.
        if (!AppSettings.isT3) {
            const searchPathName = path.split("/").slice(2);

            if (!searchPathName || searchPathName == "") {
                return false;
            }
            let additionalQueryParamsString = "";
            //   Check if first value is 4 digits (year)
            //   and if there's at least 3 seperate values in path
            //   (ymm pattern check)
            if (/^\d{4}$/.test(searchPathName[0]) && searchPathName[2]) {
                const year = "year=" + searchPathName[0];
                const make = "make=" + searchPathName[1];
                const model = "model=" + searchPathName[2];
                const productIdentifier = searchPathName[3];

                // need to add all query params like categoryId and eVar values and add them to the new url
                for (var key in query) {
                    additionalQueryParamsString += `&${key}=${query[key]}`;
                }
                // resolve url into params
                this.props.history.push(
                    `/product/${productIdentifier}?${year}&${make}&${model}${additionalQueryParamsString}`
                );

                window.location.reload();
            }
        }
    };
    // TEMPORARY: DELETE handleSearchLegacyUrl
    // WHEN BRANDS AND CDK UPDATE THEIR LINKS

    componentWillUnmount() {
        const resetPageAction = resetPage();
        store.dispatch(resetPageAction);
        sessionStorage.removeItem("supersessionOldPartNumber");
    }

    componentDidUpdate(prevProps) {
        // Makes analtyics direct call on every product details page visit.
        if (typeof _satellite != "undefined" && !this.state.analyticsFired) {
            this.setState({
                analyticsFired: true
            });
            _satellite.track("prod-view");
        }
        if (this.props.item.goToCart === true && this.props.item.goToCart !== prevProps.item.goToCart) {
            const resetGoToCartAction = goToCart(false);
            store.dispatch(resetGoToCartAction);
            if (!AppSettings.isT3) {
                //This allows for a refresh to be done as it goes to the cart page
                //The refresh is needed in order to pull the account information again for rewards users
                window.location.href = "/cart";
            } else {
                this.props.history.push(AppSettings.dealerName + "/cart?bac=" + AppSettings.bac);
            }
        }

        //Fitment Check redirect to search when accessory doesn't fit current vehicle
        if (
            this.props.fitmentData.returnToSearch === true &&
            this.props.fitmentData.returnToSearch !== prevProps.fitmentData.returnToSearch
        ) {
            const resetReturnToSearchAction = returnToSearch(false);
            store.dispatch(resetReturnToSearchAction);

            const year = this.props.ymmParams.year;
            const make = this.props.ymmParams.make;
            const model = this.props.ymmParams.model;
            const fitmentId = this.props.fitmentId;

            const vehicleConfig = this.props.ymmParams.vehicleConfig; // vehicle config params
            const fitmentParams = this.props.fitmentData.vehicleParams; // fitment check params
            const fitmentParamsFromData = this.props.fitmentData.fitmentVehicleInfo; // fitment check params

            // check if there are vehicle config or fitment check params for filtered search
            const bodyNumDoors = fitmentParams.bodyNumDoorsParam;
            const bodyStyle = vehicleConfig.bodyId
                ? vehicleConfig.bodyId
                : fitmentParams.bodyParam
                ? fitmentParams.bodyParam
                : "";
            const wheelBase = vehicleConfig.wheelId
                ? vehicleConfig.wheelId
                : fitmentParams.wheelParam
                ? fitmentParams.wheelParam
                : "";
            const trim = vehicleConfig.trimId
                ? vehicleConfig.trimId
                : fitmentParams.trimParam
                ? fitmentParams.trimParam
                : "";
            const driveType = vehicleConfig.driveId
                ? vehicleConfig.driveId
                : fitmentParams.driveParam
                ? fitmentParams.driveParam
                : "";
            const engineBase = vehicleConfig.engineId
                ? vehicleConfig.engineId
                : fitmentParams.engineParam
                ? fitmentParams.engineParam
                : "";
            const bodyStyleName = vehicleConfig.body
                ? vehicleConfig.body
                : fitmentParamsFromData.bodyStyle
                ? fitmentParamsFromData.bodyStyle
                : "";
            const wheelBaseName = vehicleConfig.wheel
                ? vehicleConfig.wheel
                : fitmentParamsFromData.wheelBase
                ? fitmentParamsFromData.wheelBase
                : "";
            const trimName = vehicleConfig.trim
                ? vehicleConfig.trim
                : fitmentParamsFromData.trim
                ? fitmentParamsFromData.trim
                : "";
            const driveTypeName = vehicleConfig.drive
                ? vehicleConfig.drive
                : fitmentParamsFromData.driveType
                ? fitmentParamsFromData.driveType
                : "";

            let path = `/search?year=${year}` + `&make=${make}` + `&model=${model}`;

            if (fitmentId) {
                path += `&fitmentID=${fitmentId}`;
            }
            if (bodyNumDoors) {
                path += `&bodyNumDoors=${bodyNumDoors}`;
            }
            if (bodyStyle) {
                path += `&bodyStyle=${bodyStyle}`;
            }
            if (wheelBase) {
                path += `&wheelBase=${wheelBase}`;
            }
            if (trim) {
                path += `&trim=${trim}`;
            }
            if (driveType) {
                path += `&driveType=${driveType}`;
            }
            if (engineBase) {
                path += `&engineBase=${engineBase}`;
            }
            if (bodyStyleName) {
                path += `&bodyStyleName=${bodyStyleName}`;
            }
            if (driveTypeName) {
                path += `&driveTypeName=${driveTypeName}`;
            }
            if (trimName) {
                path += `&trimName=${trimName}`;
            }
            if (wheelBaseName) {
                path += `&wheelBaseName=${wheelBaseName}`;
            }

            if (AppSettings.isT3) {
                path = `${AppSettings.dealerName}${path}&bac=${AppSettings.bac}`;
            }

            this.props.history.push(path);
            store.dispatch(closeModal());
        }

        // Bug 673112 - Pathname changing does not reload the page through the use of <Link> element from NLS product cards.
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // Reload the PD page if the pathname changes.
            const resetPageAction = resetPage();
            store.dispatch(resetPageAction);

            this.handlePageLoad();
        }
    }

    getProductIdentifier() {
        // Gets the product identifier from the current URL path.
        // The product identifier is a string consisting of product name and part number.
        // The current URL path is accessed used withRouter from react-router.
        if (this.props.location) {
            const currentPath = this.props.location.pathname;

            if (currentPath) {
                const splitPath = currentPath.split("/");

                if (splitPath.length > 0 && !AppSettings.isT3) {
                    const productIdentifier = splitPath[splitPath.length - 1];
                    return productIdentifier;
                } else if (splitPath.length > 0 && AppSettings.isT3) {
                    const productIdentifier = splitPath[splitPath.length - 1];
                    const splitPathBacParam = productIdentifier.split("?");
                    const productIdentifierNoBac = splitPathBacParam[0];
                    return productIdentifierNoBac;
                }
            }
        }

        // Returns empty string if no product identifier found. This empty value will be propagated
        // into the ProductDetailsWorker, where it will be handled by setting default values.
        return "";
    }

    /**
     * Flips dummy state variable to re-render breadcrumb after inline fitment reset. Otherwise breadcrumb keeps
     * using old fitment
     */
    flipRerenderBreadcrumb() {
        this.setState((state) => ({
            rerenderBreadcrumb: !state.rerenderBreadcrumb
        })) ;
    }

    renderSpinner() {
        return (
            <div className="row small-12 medium-12 large-12 xlarge-12 ac-nopadding-x ac-xlarge-margintop">
                <Spinner />
            </div>
        );
    }

    renderBreadcrumb() {
        let breadcrumb = "";
        let categoryId = "";
        let category = "";
        let subcategory = "";
        let subcategoryId = "";
        let allCategoriesId = "";
        let allCategories = "";
        if (this.props.item.breadcrumb) {
            breadcrumb = this.props.item.breadcrumb;
            if (breadcrumb.category) {
                categoryId = breadcrumb.categoryId;
                category = breadcrumb.category;
            }
            if (breadcrumb.subcategory) {
                subcategoryId = breadcrumb.subcategoryId;
                subcategory = breadcrumb.subcategory;
            }
            if (breadcrumb.allCategories) {
                allCategoriesId = breadcrumb.allCategoriesId;
                allCategories = breadcrumb.allCategories;
            }
        }

        return (
            <div className="row q-gridbuilder gridfullwidth small-margin">
                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns small-margin">
                    <Translation>
                        {(t) => (
                            <Breadcrumb
                                pageName={t("LABEL_BREADCRUMB_PRODUCT_INFO")}
                                searchTerm={queryString.parse(this.props.location.search).searchTerm}
                                selectedCategory={category}
                                selectedCategoryId={categoryId}
                                selectedSubcategory={subcategory}
                                selectedSubcategoryId={subcategoryId}
                                searchParams={this.props.ymmParams}
                                vehicleConfig={this.props.ymmParams.vehicleConfig}
                                allCategoriesId={allCategoriesId}
                                allCategoriesLabel={allCategories}
                                rerenderBreadcrumb={this.state.rerenderBreadcrumb}
                            />
                        )}
                    </Translation>
                </div>

                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                    <NaturalLanguageSearch redirectFlag />
                </div>

                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                    <Translation>
                        {(t) => (
                            <VehicleHeader
                                year={this.props.ymmParams.year}
                                make={this.props.ymmParams.make}
                                model={this.props.ymmParams.model}
                                bodyAndWheel={this.props.ymmParams.bodyAndWheel}
                                bodyStyle={this.props.ymmParams.bodyStyle}
                                wheelBase={this.props.ymmParams.wheelBase}
                                trim={this.props.ymmParams.trim}
                                engineBase={this.props.ymmParams.engine}
                                vehicleConfig={this.props.ymmParams.vehicleConfig}
                                showVehicleInfo={true}
                                SWOFFlag={true}
                            />
                        )}
                    </Translation>
                </div>
            </div>
        );
    }

    renderProductDetails() {
        const { isDealerized } = getDealerizedData(this.props.dealerizationData);
        return (
            <main>
                <div className="ac-pd q-gridbuilder gridfullwidth ac-small-sidepadding">
                    {this.renderBreadcrumb()}
                    <div className="row q-gridbuilder gridfullwidth small-margin">
                        <div className="small-12 xlarge-6 columns" id="ac-product-details-gallery">
                            <ProductDetailsGallery item={this.props.item} />
                        </div>
                        <div className="small-12 xlarge-6 columns" id="ac-product-details-description">
                            <ProductDetailsDescription
                                item={this.props.item}
                                vin={this.props.ymmParams.vin}
                                rewardsData={this.props.rewardsData}
                                dealerData={this.props.dealerizationData}
                                isDealerized={isDealerized}
                                showError={this.state.showError}
                                flipRerenderBreadcrumb={() => {this.flipRerenderBreadcrumb()}}
                            />
                        </div>
                        <ProductDetailsActionArea isDealerized={isDealerized} changeErrorStatus={(value)=>{
                            this.setState({
                            showError: value
                        });}} 
                        />
                    </div>
                    <div className="row gridfullwidth ac-small-margintop small-margin">
                        <div className="small-12 columns ac-product-details-similar-items ac-large-margintop">
                            <ProductDetailsTab item={this.props.item} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="small-12 columns ac-small-marginbottom ac-product-details-similar-items">
                            <ProductDetailsSimilarItems />
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    renderError() {
        return (
            <main>
                <div className="ac-pd q-gridbuilder gridfullwidth ac-small-sidepadding">
                    {this.renderBreadcrumb()}
                    <div className="row q-gridbuilder gridfullwidth small-margin">
                        <div className="small-12 medium-12 large-12 xlarge-12  grid-column-alignment-left columns">
                            <Translation>
                                {(t) => <ErrorMessage message={t(this.props.item.message)} icon={true} />}
                            </Translation>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    render() {
        const isLoading = this.props.item.isLoading;
        const showError = this.props.item.showError;

        if (isLoading) {
            return this.renderSpinner();
        } else if (showError) {
            return this.renderError();
        } else {
            return this.renderProductDetails();
        }
    }
}

const mapStateToProps = (state) => {
    /** Setting up Page Title **/
    if (state.ProductDetailsReducer.name !== "") {
        titleSetUp(state.ProductDetailsReducer.name);
    } else {
        titleSetUp(i18next.t("LABEL_PRODUCT_DETAILS_TITLE"));
    }
    return {
        item: state.ProductDetailsReducer,
        fitmentData: state.FitmentCheckReducer,
        vehicleSelectData: state.VehicleSelectReducer,
        fitmentId: state.ProductDetailsReducer.partTerminologyId,
        ymmParams: state.Session,
        rewardsData: state.RewardsReducer,
        categoryNavData: state.CategoryNavReducer,
        dealerizationData: state.DealerizationReducer
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps)(ProductDetails)));
