/** COMPONENT NAME : CARTLINEITEMTITLE (TITLE ON SHOPPING CART PAGE) **/

/** IMPORT STATEMENT **/
import React from 'react';
import AppSetting from '../../../core/AppSettings';
import PropTypes from 'prop-types';
import SubtotalComponent from "../Subtotal/SubtotalComponent";
import MediaQuery from 'react-responsive';
import {Translation} from 'react-i18next';

/** COMPONENT **/
const LineItemTitle = (props)=>{

    /**VARIABLES **/
    const isT3 = AppSetting.isT3;
    const count = props.itemCount;

    return(<Translation>
            {
                (t)=>(
                    <div className="ac-cartlineitemtitleWrapper">
                 <div className="small-8  medium-6 large-7 xlarge-8 columns ac-nopadding-x">
                     { count > 1 && <span>{t("LABEL_COMMON_ITEMS")} ({count})</span> }
                     { count === 1 && <span>{t("LABEL_COMMON_ITEMS")} ({count})</span> }
                 </div>
                        {/** HIDING UNIT PRICE AND QTY ON SMALL DEVICES **/}
                 <MediaQuery query="(min-width: 26.25em)">
                 <div className="small-2 medium-4 large-3 xlarge-2 columns ac-nopadding-x ac-unitPrice">
                     { isT3 && <span>{t("LABEL_COMMON_UNIT_PRICE")}</span>}
                     { !isT3 && <span>{t("LABEL_COMMON_UNIT_PRICE")} ({t("LABEL_COMMON_MSRP")})</span>}
                 </div>
                 <div className="small-2  medium-2 large-2 xlarge-2 columns ac-nopadding-x ac-qty">
                     <span>{t("LABEL_COMMON_QUANTITY")}</span>
                 </div>
                 </MediaQuery>

         </div>
                )
            }
        </Translation>
    )
 };
/** PROP TYPE VALIDATION **/
SubtotalComponent.proptype = {
    itemCount:PropTypes.string.isRequired
};

export default LineItemTitle