import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ErrorMessage from "../../shared/ErrorMessage/ErrorMessage";
import ErrorBoundary from "../../shared/ErrorBoundary/ErrorBoundary";
import SharedMarketingContentArea from "../../shared/MarketingContentArea/SharedMarketingContentArea";
import NaturalLanguageSearch from "../Search/NaturalLanguageSearch/NaturalLanguageSearch";
import { useTranslation } from "react-i18next";
import { getCategoryData, getSubcategoryImageUrls } from "./Components/HelperFunctions";
import CategoriesViewSection from "./Components/CategoriesViewSection/CategoriesViewSection";
import Button from "../../shared/Button/Button";
import SharedToast from "../../shared/Toasts/SharedToast";
import { useLocation } from "react-router-dom";
import AppSettings from "../../core/AppSettings";
import { getUrlCategory, jsonToUrlParams } from "../../shared/Utils/Utils";
import { isValidValue } from "../../shared/Validator/Validator";
import accHistory from "../App/History";
import CategorySelector from "./CategoriesLeftNav/CategorySelector";
import AllCategories from "../AllCategories/AllCategories";
import vaultConstants from "../../../config/vault_constants";
import CurrentVehicle from "../Home/CurrentVehicle/CurrentVehicle";
import VehicleHeader from "../../shared/VehicleHeader/VehicleHeader";
import VehicleSelectContainer from "../../shared/VehicleSelect/VehicleSelectContainer";
import { connect, useSelector } from "react-redux";
import { setCategory } from "../../shared/SearchLink/SearchLinkRedux/SearchLinkActionCreator";
import { selectedCategoryTitle } from "../Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionCreator";
import store from "../../core/Redux/Store";
import { SET_CLP_REDIRECT } from "../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionType";
import { pageTitle } from "../../shared/Hooks/Hooks";

/**
 * Base component for the Category Landing Page. This page looks similar to the Product Search Results Page, but instead
 * of showing products for each category we show tiles for subcategories on a category basis which link to respective
 * PSR pages.
 * No props required, only requirement for this page to work is for the proper fitment and category query parameters
 * in the url
 * IMPORTANT: The only valid categories are PARENT categories, i.e. categories that have subcategories within them. This
 * does NOT include the All Products category
 * @return {JSX.Element}
 * @constructor
 */
const CategoryLandingPage = (props) => {
    let isInitialLoad = true;

    const { t } = useTranslation();
    const errorMessage = "";
    // Card limit is 5 for small screens before clicking on show all button
    const mobileCardLimit = 6;
    const location = useLocation();
    const clpEnhancementsFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2207674_CLP_ENHANCEMENTS) === "true";
    const clpFitmentFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2237161_NEW_FITMENT_CLP_ACC) === "true";
    const vehicleInfoData = useSelector((state) => state.Session);
    const urlParameters = AppSettings.urlParameters();

    const [fitmentInfo, setFitmentInfo] = useState(null);
    const [fitmentVin, setFitmentVin] = useState(null);
    // currentCategory is the category that will determine which subcategories to display on the right
    const [currentCategory, setCurrentCategory] = useState("");
    const [displayCategory, setDisplayCategory] = useState("");
    const [expandedCategory, setExpandedCategory] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const [pageError, setPageError] = useState();
    // Contains entire response from search call. Leaving here in case anyone needs something outside categoryInfo below
    const [searchData, setSearchData] = useState(null);
    // An array of sorted objects for each parent category, each with their own arrays of subcategories
    const [categoryInfo, setCategoryInfo] = useState([]);
    const [currentCategoryName, setCurrentCategoryName] = useState("Category Landing Page");
    const [selectedCategoryInfo, setSelectedCategoryInfo] = useState(null);
    const [closeAllCategories, setCloseAllCategories] = useState(false);
    const [subcategoryImages, setSubcategoryImages] = useState({});
    const [showAll, setShowAll] = useState(false);
    const [showCurrentVehicle, setShowCurrentVehicle] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
    };

    pageTitle(currentCategoryName);

    useEffect(() => {
        window.scrollTo(0, 0);

        setIsMobile(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
        window.addEventListener("resize", handleResize);

        if (typeof _satellite != "undefined") {
            _satellite.track("page-view");
        }
    }, []);

    useEffect(() => {
        const makeCode = AppSettings.sitesStoreMap[AppSettings.storeId].makeCode;
        const tempFitmentInfo = {
            year: urlParameters.year || "",
            make: urlParameters.make || "",
            makeId: makeCode || "",
            model: urlParameters.model || "",
            modelId: urlParameters.modelId || "",
            body: urlParameters.body || "",
            bodyId: urlParameters.bodyId || "",
            trim: urlParameters.trim || "",
            trimId: urlParameters.trimId || "",
            drive: urlParameters.drive || "",
            driveId: urlParameters.driveId || "",
            engine: urlParameters.engine || "",
            engineId: urlParameters.engineId || "",
            wheel: urlParameters.wheel || "",
            wheelId: urlParameters.wheelId || "",
            bodyNumDoorsId: urlParameters.bodyNumDoors || "",
            vin: urlParameters.vin || ""
        };

        if (JSON.stringify(tempFitmentInfo) !== JSON.stringify(fitmentInfo)) {
            setFitmentInfo(tempFitmentInfo);
        }
        if (currentCategory !== urlParameters.categoryId) {
            setCurrentCategory(String(urlParameters.categoryId));
        }
    }, [location]);

    useEffect(() => {
        // Fetch facet data on page load and when fitment changes
        if (fitmentInfo && currentCategory) {
            getCategoryData(
                fitmentInfo,
                setIsLoading,
                setNoResults,
                setPageError,
                setSearchData,
                setCategoryInfo,
                setSelectedCategoryInfo,
                setSubcategoryImages,
                setFitmentVin,
                t,
                currentCategory
            );
        }
        setDisplayCategory(currentCategory);
    }, [fitmentInfo]);

    useEffect(() => {
        // Fetch subcategory images when category changes
        if (currentCategory) {
            if (isInitialLoad) {
                isInitialLoad = false;
                setExpandedCategory(currentCategory);
            }
            if (!isLoading) {
                getSubcategoryImageUrls(
                    currentCategory,
                    false,
                    setSubcategoryImages,
                    setIsLoading,
                    setNoResults,
                    setPageError
                );
            }
        }
        if (categoryInfo.length) {
            categoryInfo.forEach((mainCategory) => {
                if (mainCategory.uniqueID === currentCategory) {
                    setSelectedCategoryInfo(mainCategory);
                }
            });
        }
    }, [currentCategory]);

    useEffect(() => {
        // Reset show all button when switching categories
        setShowAll(false);

        if (selectedCategoryInfo !== null) {
            setCurrentCategoryName(selectedCategoryInfo.mainCategory);
            if (Object.keys(selectedCategoryInfo).length === 0) {
                setNoResults(true);
            } else {
                setNoResults(false);
            }
        }
    }, [selectedCategoryInfo]);

    // This will handle the category Accordion selections to only expand category
    const handleCategoryClick = (categoryId, categoryName) => {
        if (isValidValue(categoryId)) {
            if (categoryName === "All Products") {
                setCloseAllCategories(true);
            } else {
                const categoryToCompare = clpEnhancementsFlag ? expandedCategory : currentCategory;
                if (categoryId === categoryToCompare) {
                    // This means the user clicked on the same category (the active category) to close it.
                    setCloseAllCategories((prev) => !prev);
                } else {
                    // An inactive/closed category was clicked
                    setCloseAllCategories(false);
                    setExpandedCategory(categoryId);
                    setCurrentCategory(categoryId);
                    setDisplayCategory(categoryId);
                }
            }

            const urlParameters = AppSettings.urlParameters();
            urlParameters["categoryId"] = categoryId;

            if (categoryName) {
                const urlCategory = getUrlCategory(categoryName);
                accHistory.push(`/categories/${urlCategory}` + jsonToUrlParams(urlParameters));
            }
        }
    };

    const handleSubMainClick = (categoryName, categoryId) => {
        setDisplayCategory(categoryId);

        const urlParameters = AppSettings.urlParameters();
        urlParameters["categoryId"] = categoryId;
        accHistory.push(`/categories/${getUrlCategory(categoryName)}` + jsonToUrlParams(urlParameters));
    };

    //subcategory clicks redirect to psr, so we're using a separate handler here
    const handleSubCategoryClick = (categoryId) => {
        const urlParameters = AppSettings.urlParameters();
        urlParameters["categoryId"] = categoryId;
        accHistory.push("/search" + jsonToUrlParams(urlParameters));
    };

    const handleCatLabel = () => {
        const curr = window.location.href;
        const categorySubstring = curr.substring(curr.indexOf("categories/") + 11, curr.indexOf("?"));
        return categorySubstring;
    };

    const handleShowAllClick = () => {
        setShowAll(true);
    };

    //will set showCurrentVehicle to true if fitment exists
    useEffect(() => {
        if (vehicleInfoData.model && vehicleInfoData.vehicleConfig.engineId) {
            setShowCurrentVehicle(true);
        }
    }, [
        vehicleInfoData.year,
        vehicleInfoData.model,
        vehicleInfoData.vehicleConfig.body,
        vehicleInfoData.vehicleConfig.trim,
        vehicleInfoData.vehicleConfig.engine,
        vehicleInfoData.vehicleConfig.wheel
    ]);

    //fitment component -> only shown when there is no previous fitment in home page
    const ymmFitmentSection = (
        <section
            id="ac-clp-ymm"
            className="ac-clp-fitment"
            onClick={() => {
                props.handleVehicleChangeClp(selectedCategoryInfo?.mainCategory, currentCategory);
            }}
        >
            <VehicleSelectContainer
                headerLabel={vehicleInfoData.model ? "" : "LABEL_COMMON_VEHICLE_SELECT_NON_HOME_HEADER"}
            />
        </section>
    );

    //current vehicle component -> only shown when there is previous fitment
    const currentVehicle = (
        <section className="ac-clp-fitment">
            <CurrentVehicle currentVehicle={vehicleInfoData} setShowCurrentVehicle={setShowCurrentVehicle} />
        </section>
    );

    const categoriesViewSection = (
        <CategoriesViewSection
            isLoading={isLoading}
            selectedCategoryInfo={selectedCategoryInfo}
            subcategoryImages={subcategoryImages}
            fitmentInfo={fitmentInfo}
            isMobile={isMobile}
            mobileCardLimit={mobileCardLimit}
            showAll={showAll}
        />
    );

    const noResultsFoundSection = (
        <div>
            <h4 className="no-results-found q-headline3 qbody-2">{t("LABEL_CATEGORY_NO_RESULTS_PICK_OTHER")}</h4>
        </div>
    );

    let leftNav;
    if (fitmentInfo === null) {
    } else {
        leftNav = (
            <CategorySelector
                handleClickEvent={handleCategoryClick}
                handleSubClickEvent={handleSubCategoryClick}
                handleSubMainClickEvent={handleSubMainClick}
                categoryData={categoryInfo}
                currentCategory={expandedCategory}
                closeAllCategories={closeAllCategories}
                isMobile={isMobile}
                displayCategory={displayCategory}
            />
        );
    }

    return (
        <div>
            <>
                <ErrorBoundary>
                    <div id="categoryLandingPage" className="ac-small-sidepadding">
                        <Row id="clp-row">
                            {pageError && (
                                <div className="acc-toast">
                                    <SharedToast
                                        toast_header={t("LABEL_CATEGORY_ERROR_TOAST_HEADER")}
                                        toast_body={pageError}
                                        toast_type={"error"}
                                    />
                                </div>
                            )}
                            <div className="acc-search-cart">
                                <NaturalLanguageSearch clpFlag={true} redirectFlag={true} />
                            </div>
                            <div>
                                <div id="clp-marketing-carousel-container" className="clp-marketing-carousel-container">
                                    <SharedMarketingContentArea
                                        componentName="Category_Carousel"
                                        categoryLabel={handleCatLabel()}
                                    />
                                </div>

                                {!clpFitmentFlag ? (
                                    AppSettings.urlParameters().vin ? (
                                        <VehicleHeader
                                            year={fitmentVin?.year}
                                            make={fitmentVin?.make}
                                            model={fitmentVin?.model}
                                            bodyAndWheel={fitmentVin?.bodyAndWheel}
                                            bodyStyle={fitmentVin?.bodyStyle}
                                            driveType={fitmentVin?.driveType}
                                            wheelBase={fitmentVin?.wheelBase}
                                            trim={fitmentVin?.trim}
                                            showVehicleInfo={true}
                                            catName={selectedCategoryInfo?.mainCategory}
                                            catId={currentCategory}
                                            SWOFFlag={false}
                                        />
                                    ) : (
                                        <VehicleHeader
                                            year={fitmentInfo?.year}
                                            make={fitmentInfo?.make}
                                            model={fitmentInfo?.model}
                                            bodyAndWheel={fitmentInfo?.body}
                                            bodyStyle={fitmentInfo?.bodyId}
                                            driveType={fitmentInfo?.drive}
                                            wheelBase={fitmentInfo?.wheelId}
                                            trim={fitmentInfo?.trim}
                                            engineBase={fitmentInfo?.engine}
                                            vehicleConfig={fitmentInfo}
                                            showVehicleInfo={true}
                                            catName={selectedCategoryInfo?.mainCategory}
                                            catId={currentCategory}
                                            SWOFFlag={false}
                                        />
                                    )
                                ) : (
                                    <div />
                                )}

                                <div
                                    className="clp-content-container ac-small-top-margin"
                                    data-testid={"isMobile-" + isMobile + "-screenWidth-" + window.innerWidth}
                                >
                                    <Col
                                        id="clp-accordion-col"
                                        className={
                                            isMobile ? "medium-12 ac-clp-full" : "small-12 medium-12 large-3 xlarge-3"
                                        }
                                        data-testid={
                                            isMobile
                                                ? "clp-accordion-col-mobile-view-active"
                                                : "clp-accordion-col-mobile-view-inactive"
                                        }
                                    >
                                        {!isMobile && categoryInfo.length > 0 && (
                                            <h4 className="shop-by-category q-headline3 gb-body1">
                                                {t("LABEL_SHOP_BY_CATEGORY")}
                                            </h4>
                                        )}
                                        {leftNav}
                                    </Col>
                                    {
                                        // If no error message, then render the list and the load more button. Otherwise render the error message.
                                        errorMessage ? (
                                            <Col id="clp-errormessage" className="small-12 medium-12 large-9 xlarge-9">
                                                <ErrorMessage icon={true} message={"placeholder error message"} />
                                            </Col>
                                        ) : isMobile ? (
                                            <Col sm={12} id="clp-mobile-content-column" className="small-12 medium-12">
                                                {clpFitmentFlag &&
                                                    (vehicleInfoData &&
                                                    vehicleInfoData.vehicleConfig.engineId &&
                                                    showCurrentVehicle
                                                        ? currentVehicle
                                                        : ymmFitmentSection)}
                                                {noResults ? noResultsFoundSection : categoriesViewSection}
                                                <div id="product-card-columns">
                                                    {!showAll &&
                                                        !isLoading &&
                                                        selectedCategoryInfo &&
                                                        selectedCategoryInfo.subCategories &&
                                                        selectedCategoryInfo.subCategories.length > mobileCardLimit && (
                                                            <Button
                                                                localeKey="LABEL_COMMON_SHOW_ALL"
                                                                className="show-all-categories-button"
                                                                id="show-all"
                                                                dataDtm="show all categories"
                                                                onClick={handleShowAllClick}
                                                            />
                                                        )}
                                                </div>
                                                <div
                                                    id="clp-marketing-mobile-content"
                                                    className="clp-marketing-mobile-content"
                                                >
                                                    <SharedMarketingContentArea
                                                        componentName="Category_Content"
                                                        categoryLabel={handleCatLabel()}
                                                    />
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col
                                                id="clp-nonmobile-content-column"
                                                className="small-12 medium-12 large-9 xlarge-9"
                                            >
                                                {clpFitmentFlag &&
                                                    (vehicleInfoData &&
                                                    vehicleInfoData.vehicleConfig.engineId &&
                                                    showCurrentVehicle
                                                        ? currentVehicle
                                                        : ymmFitmentSection)}
                                                {noResults ? noResultsFoundSection : categoriesViewSection}
                                                <div
                                                    id="clp-marketing-desktop-content"
                                                    className="clp-marketing-desktop-content"
                                                >
                                                    <SharedMarketingContentArea
                                                        componentName="Category_Content"
                                                        categoryLabel={handleCatLabel()}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    }
                                </div>
                            </div>
                        </Row>
                    </div>
                </ErrorBoundary>
            </>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleVehicleChangeClp: (catName, catId) => {
            store.dispatch(setCategory(catName, catId));
            store.dispatch(selectedCategoryTitle(catName));
            store.dispatch({ type: SET_CLP_REDIRECT, category: catName });
        }
    };
};

export default connect(mapDispatchToProps)(CategoryLandingPage);
