import React, { useState } from 'react';
import { Translation, Trans } from 'react-i18next';
import { handleUserLogin } from '../shared/LogIn';
import { Redirect } from 'react-router-dom';
import { pageTitle } from '../../../shared/Hooks/Hooks';
import Spinner from '../../../shared/Spinner/Spinner';
import AppSettings from '../../../core/AppSettings';
import Footnote from '../../../shared/Footnote/Footnote';
import CreatePasswordForm from './CreatePasswordForm';
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import axios from 'axios';
import { submitButtonFocus, getBrandUrl, scrubFormField } from '../../../shared/Utils/Utils';
import vaultConstants from "../../../../config/vault_constants";

function CreatePassword(props) {
    pageTitle('Create Password');
    const ff_2224243_caps_v3_migration = props.location.state.ff_2224243_caps_v3_migration;
    const [errorMessage, setErrorMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    // Terms and conditions, and privacy links
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;
    // The user will be redirected to this page if they try to go directly to /register. 
    // They will also see an error which can be triggered with the noEmailError variable.
    let email = props.location.state ? props.location.state.email : "";
    if (!ff_2224243_caps_v3_migration) {
        if (email == "" || email == null) {
            return <Redirect to={{ pathname: '/register', state: { noEmailError: true } }} />
        }
    } else {
        //V3 Migration
        if (props.location.state == "" || props.location.state == null) {
            return <Redirect to={{ pathname: "/register", state: { noEmailError: true } }} />;
        }
    }
    // Registering flow from the OCP page -- Loyalty 3.8 release
    let orderId = localStorage.getItem('OrderIdRegistration');
    
    //V1 Implemenetation
    function handleEmailRequest(email) {
        // Clear error message
        setErrorMessage(null);
        setShowSpinner(true);

        // When submitting through the use of pressing enter, if loading bar is wanted, then this focuses on the button so that the loading bar can be seen.
        submitButtonFocus();

        // Get the call ready.
        let body = {
            username: scrubFormField(email),
            password: scrubFormField(password),
            emailAddress: scrubFormField(email)
        };

        // Registering flow from the OCP page -- Loyalty 3.8 release
        if (orderId) {
            body = {
                username: scrubFormField(email),
                password: scrubFormField(password),
                emailAddress: scrubFormField(email),
                orderId: orderId
            };
        } 

        let headers = {
            'Content-Type': 'application/json',
            'locale': 'en_US'
        };
        //TODO: Does this need to change to a verification API?
        axios.post(`/wcs/resources/store/${AppSettings.storeId}/GMPerson/user/register?responseFormat=json`, body, { headers })
            .then(result => {
                // if user is registered successfully, send verification email. Else display errors, or if user already exists, then redirect.
                if (result.status == 200) {
                    // Makes analtyics direct call when user starts the registration process
                    if (typeof _satellite != 'undefined') {
                        _satellite.track('registration-start');
                    }
                    setVerificationCode(result.data.verificationCode);
                    setRedirect(true);
                }
                else if ((result.status == "failure") && (result.messageKey == "iam.registration.duplicate_user")) {
                    window.location.href = '/register/userAlreadyExists';
                } else {
                    handleErrorMessage("Status Failed.", "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                }
            }).catch((error) => {
                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            });
    }

    //V3 Implemenetation
    const handlePasswordVerify = (password) => {
        // send user-entered password in body to validate it
        let body = {
            password: password
        };
        setShowSpinner(true);

        // When submitting through the use of pressing enter, if loading bar is wanted, then this focuses on the button so that the loading bar can be seen.
        submitButtonFocus();

        // validate password
        axios
            .post(
                "/wcs/resources/store/" +
                    AppSettings.storeId +
                    "/GMPerson/resetpassword/validation?responseFormat=json",
                body
            )
            .then((result) => {
                // if password is valid, make reset password call
                if (result.status == 200) {
                    setRedirect("accountDetails");
                } else {
                    handleErrorMessage("Status Failed.", t("LABEL_ERROR_BOUNDARY_GENERIC_ERROR"));
                }
            })
            .catch((error) => {
                handleErrorMessage(error, t("LABEL_ERROR_BOUNDARY_GENERIC_ERROR"));
            });
        setShowSpinner(false);
        return "";
    };

    function handleErrorMessage(error, errorMessage) {
        console.log(error);

        // Hide spinner
        setShowSpinner(false);

        // Remove focus from button
        if (document.activeElement != document.body) {
            document.activeElement.blur();
        }

        // Display error message
        setErrorMessage(errorMessage);
    }

    // V1 Implementation: When the password is successful, we will redirect to confirmation page.
    if (redirect && !ff_2224243_caps_v3_migration) {
        if (ff_2224243_caps_v3_migration) {
            return <Redirect to={{ pathname: '/register/registrationDetails/', state: { password: password, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration} }} />
        } else {
            return <Redirect to={{
                pathname: '/register/accountConfirmation',
                state: {
                    email: email,
                    checkExpiration: false,
                    orderId: orderId ? orderId : "",
                    ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration
                }
            }} />
        }
    } else if (redirect === "accountDetails" && ff_2224243_caps_v3_migration) {
        //V3 Implementation
        return (
            <Redirect
                to={{
                    pathname: "/register/registrationDetails",
                    state: {
                        email: email,
                        password: password,
                        token: props.location.state.token,
                        orderId: orderId ? orderId : "",
                        ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration
                    }
                }}
            />
        );
    }

    return (
        <CenterGrid headerLabel="LABEL_ACCOUNT_REGISTRATION_CREATE_PASSWORD_HEADER">
            <Translation>
                {(t) => (
                    <div className="margin-medium">
                        {/* Display any errors */}
                        {errorMessage && <ErrorMessage icon={true} message={t(errorMessage)} />}
                        <CreatePasswordForm
                            email={email}
                            onPasswordChange={e => setPassword(e.target.value)}
                            onSubmit={() =>
                                ff_2224243_caps_v3_migration ? handlePasswordVerify(password) : handleEmailRequest(email)
                            }
                            password={password}
                            isLoading={showSpinner}
                            errorMessage={errorMessage}
                        />
                        <div className="center-align q-text small-margin">
                            <span>{t("LABEL_ACCOUNT_REGISTRATION_ALREADY_HAVE_ACCOUNT")} </span>
                            <Trans i18nKey="LABEL_ACCOUNT_REGISTRATION_ALREADY_HAVE_ACCT">
                                {/* Not hardcoded text - this is done for interpoliation */}
                                Sign in
                                <a className="ac-accessible-link-underline" onClick={() => handleUserLogin()}>LOG IN</a>
                            </Trans>
                        </div>

                        {/* Terms and Conditions by brand*/}
                        <br />
                        <Footnote
                            localeKey="LABEL_ACCOUNT_REGISTRATION_TERMS_CONDITIONS_FOOTNOTE"
                            href={[ownerCenterLink, privacyLink]}
                            dataDtmLink={["create password", "create password"]}
                        />
                    </div>
                )}
            </Translation>
        </CenterGrid>
    );
}

export default CreatePassword;