import * as type from './SearchDataServiceActionTypes';

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const loadMore = (searchParams, pageNum) => ({
    type: type.LOAD_MORE,
    payload: {
        year: searchParams.year,
        make: searchParams.make,
        model: searchParams.model,
        categoryId: searchParams.categoryId,
        orderBy: searchParams.orderBy,
        searchTerm: searchParams.searchTerm,
        pageNum: pageNum
    }
})

export const loadSearchTermFlow = (responseData) => {
    const action = {
        type: type.REDIRECT_TO_PARTS_TILES,
        associationData: responseData
    }
    return action;
}

export const loadSpinner = (isLoading) => {
    const action = {
        type: type.NLS_LOAD_SPINNER,
        searchResultsListIsLoading: isLoading,
    }
    return action;
}

export const setShopWithoutFitment = (active) => {
    const action = {
        type: type.SET_SHOP_WITHOUT_FITMENT,
        isShopWithoutFitmentActive: active,
    }
    return action;
}

export const setCurrentVehicleActive = (active) => {
    const action = {
        type: type.SET_CURRENT_VEHICLE_ACTIVE,
        currentVehicleActive: active
    }
    return action;
}