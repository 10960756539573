import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../../shared/Button/SharedButton";
import Button from "../../../shared/Button/Button";
import InlineFitmentYMM from "./InlineFitmentYMM";
import InlineFitmentSteps from "./InlineFitmentSteps";
import {
    resetVehicleSelectState,
    selectYear,
    selectMake,
    selectModel
} from "../../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
import {
    loadVehicleCombosAsync,
    resetVehicleConfig
} from "../../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import { checkInputtedVIN, handlePSRRedirectVin } from "./HelperFunctions";
import Spinner from "../../../shared/Spinner/Spinner";
import VinInstructions from "../../../shared/VehicleSelect/VehicleSelectVinInstructions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import InlineFitmentResults from "./InlineFitmentResults";
import { resetSessionVehicleInfo } from "../../../shared/Session/VehicleInfo/VehicleInfoActionCreator";
import { deleteFitmentCookie } from "../../../shared/VehicleSelect/CookieService/FitmentData";
import AppSettings from "../../../core/AppSettings";
import accHistory from "../../App/History";
import { jsonToUrlParams } from "../../../shared/Utils/Utils";
import vaultConstants from "../../../../config/vault_constants";

/**This component will function similarly to the VehicleSelector Component on the home page, which handles
 * the YMM/Vin Searches. For YMM(and possibly VIN) using the current YMM/Vin components may not work well as the
 * new requirements are different than what was already on YMM/VIN. Creating new components would help with
 *  organization of code/logic.
 *
 * All Inlinefitment related components should be created in the InlineFitment folder.
 * @param {*} props
 * @returns Ymm and
 * VIN Search Section on PDP in PDInfo Container
 *  this component should return in the following format:
 *  Labeling for InlineFitment Section
    YMM Component/Section
    OR
    VIN Component/Section
 *
 */

export default function InlineFitment(props) {
    const fitmentResetImprovementsFlag = AppSettings.isLocalHost ? true :
        String(vaultConstants.FF_473706_FITMENT_RESET_IMPROVEMENTS) === "true";
    const dispatch = useDispatch();
    const vin = "VIN";
    const ymm = "YMM";
    const [fitType, setFitType] = useState("");
    const { t } = useTranslation();
    const [continueFitment, setContinueFitment] = useState(false);
    const [showVinInstructions, setShowVinInstructions] = useState(false);
    const [isFitmentError, setIsFitmentError] = useState(false);
    const [vinCheckMessage, setVINCheckMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [vinResults, setVinResults] = useState(undefined);
    const [vinBreadcrumb, setVinBreadcrumb] = useState([]);
    const [currVin, setCurrVin] = useState(undefined);
    // currentVehicleInfo set when inlineFitment flow is complete. Used to check if we need to update cookie
    const [currentVehicleInfo, setCurrentVehicleInfo] = useState({});
    const selectedModel = useSelector((state) => state.VehicleSelectReducer.selectedModel);
    const [hasReset, setHasReset] = useState(false);
    const failElem = !!document.getElementById("inline-fitment-fail");

    // Track vehicle info from session reducer
    const vehicleInfo = useSelector((state) => state.Session.vehicleConfig);

    // Needed to check VIN
    const sessionReducerInfo = useSelector((state) => state.Session);

    useEffect(() => {
        dispatch(resetVehicleSelectState());
        dispatch(resetVehicleConfig());

        if (sessionReducerInfo.year && !hasReset) {
            dispatch(selectYear(sessionReducerInfo.year));
            if (sessionReducerInfo.make) {
                dispatch(selectMake(sessionReducerInfo.make, sessionReducerInfo.makeCode));
                if (sessionReducerInfo.model) {
                    dispatch(selectModel(sessionReducerInfo.model, sessionReducerInfo.modelCode));
                    dispatch(loadVehicleCombosAsync());
                }
            }
        }
    }, []);

    useEffect(() => {
        if (vehicleInfo.year && !hasReset) {
            setFitType(ymm);
            if (selectedModel) {
                setContinueFitment(true);
            }
        }
    }, [selectedModel]);

    useEffect(() => {
        if (sessionReducerInfo.vin && !hasReset) {
            setFitType(vin);
            checkVPM();
        }
    }, [vehicleInfo.vin]);

    function removeFitmentFromUrl () {
        const urlParameters = AppSettings.urlParameters();
        const fitmentParams = ["vin", "year", "make", "makeId", "model", "modelId", "body", "bodyId", "trim", "trimId",
            "drive", "driveId", "engine", "engineId", "wheel", "wheelId", "bodyNumDoors", "bodyNumDoorsId"];
        for (let param of fitmentParams) {
            delete urlParameters[param];
        }
        accHistory.replace(location.pathname + jsonToUrlParams(urlParameters));
    }

    async function InlineConfigReset ()  {
        fitmentResetImprovementsFlag && await deleteFitmentCookie();
        dispatch(resetSessionVehicleInfo());
        dispatch(resetVehicleSelectState());
        dispatch(resetVehicleConfig());
        fitmentResetImprovementsFlag && removeFitmentFromUrl();
        fitmentResetImprovementsFlag && props.flipRerenderBreadcrumb();
        setHasReset(true);
        setFitType("");
        setContinueFitment(false);
        setCurrVin(undefined);
        setVinBreadcrumb([]);
        setVinResults(undefined);
        setIsFitmentError(false);
        setShowVinInstructions(false);
        setVINCheckMessage({});
        setCurrentVehicleInfo({});
    }
    const renderTooltip = (props) => (
        <Tooltip id="tooltip-icon" className="gb-body2" {...props}>
            <strong>{t("LABEL_TOOLTIP_HEADER")}</strong>
            <br />
            {t("LABEL_TOOLTIP_BODY")}
        </Tooltip>
    );

    function getVinInstructionsIcon() {
        return showVinInstructions ? "caret-up" : "caret-down";
    }

    function vpmErrorMessage() {
        if (vinCheckMessage.hasOwnProperty("0130")) {
            return (
                <label className="error ac-error-label">
                    <FontAwesomeIcon icon="exclamation-circle" />
                    {t(vinCheckMessage["0130"][0], { differentBrand: vinCheckMessage["0130"][2] }) + " "}{" "}
                    <a id="vpm-error-link" href={"https://accessories." + vinCheckMessage["0130"][2] + ".com"}>
                        {vinCheckMessage["0130"][2] + " Accessories"}
                    </a>{" "}
                    {t(vinCheckMessage["0130"][1], { brand: vinCheckMessage["0130"][3] })}
                </label>
            );
        } else {
            return (
                <label className="error ac-error-label">
                    <FontAwesomeIcon icon="exclamation-circle" />
                    {t(Object.values(vinCheckMessage)[0])}
                </label>
            );
        }
    }

    const checkVPM = async () => {
        let selectedVin;
        if (sessionReducerInfo.vin && !hasReset) {
            selectedVin = sessionReducerInfo.vin;
        } else {
            selectedVin = document.getElementById("vin-input1").value;
        }
        checkInputtedVIN(
            selectedVin,
            props.partNumber,
            setVINCheckMessage,
            setIsLoading,
            setIsFitmentError,
            setVinResults,
            setVinBreadcrumb,
            setCurrVin,
            setCurrentVehicleInfo
        );
    };

    const VINFitment = (
        <section id="inline-fitment-vin">
            <div className="vin-header1 gb-body2">{t("LABEL_DETAIL_VIN_SEARCH")}</div>
            <h6 className="gb-body2">{t("LABEL_COMMON_VIN_CAPS")}</h6>

            {isLoading ? (
                <Spinner className="q-loader" />
            ) : (
                <div className="vin-section">
                    <input
                        id="vin-input1"
                        className="vin-input1 stat-input-field"
                        data-dtm={"vehicle fit:vin"}
                        type="text"
                        placeholder={t("LABEL_EXAMPLE_VIN")}
                    />
                    <SharedButton
                        id="inline-check-vehicle-fit"
                        className="gb-secondary-button button-check"
                        buttonText={t("LABEL_CHECK_VEHICLE_FIT")}
                        onClick={checkVPM}
                        variant="secondary"
                        analyticsTag={"vehicle fit:vin"}
                    ></SharedButton>
                </div>
            )}
            {isFitmentError && !isLoading && <div className="ac-small-marginbottom">{vpmErrorMessage()}</div>}
            <a
                className="reset-vehicle-fit gb-body2 stat-text-link"
                data-dtm={"vehicle fit:vin"}
                id="inline-reset-vehicle-fit"
                onClick={() => {
                    setFitType("");
                    setIsFitmentError(false);
                    setShowVinInstructions(false);
                    setVINCheckMessage({});
                }}
            >
                {t("LABEL_RESET_VEHICLE_INLINE_FIT")}
            </a>
            <a
                className="reset-vehicle-fit gb-body2 stat-expand-icon"
                id="inline-help-vehicle-fit"
                data-dtm={"vehicle fit:vin"}
                onClick={() => setShowVinInstructions((x) => !x)}
            >
                {t("LABEL_INLINE_HOW_TO_FIND_VIN")}
                <FontAwesomeIcon icon={getVinInstructionsIcon()} />
            </a>
            {showVinInstructions && (
                <div id="inline-fit-vin-instructions">
                    <VinInstructions showVinInstructions={showVinInstructions} />
                </div>
            )}
        </section>
    );

    const initialInlineFitment = (
        <>
            <h3 className="gb-body2">{t("LABEL_INLINE_FITMENT_HEADER")}</h3>
            <div className="inline-content-area">
                {fitType != vin &&
                    (fitType == ymm ? (
                        <div>
                            <InlineFitmentYMM onReset={InlineConfigReset} />
                            <SharedButton
                                id="inline-check-vehicle-fit-btn"
                                className="gb-primary-button"
                                analyticsTag={"vehicle fit"}
                                buttonText={t("LABEL_CHECK_VEHICLE_FIT")}
                                onClick={() => setContinueFitment(true)}
                            />
                            <a
                                className="reset-vehicle-fit stat-text-link"
                                data-dtm={"vehicle fit"}
                                id="inline-reset-vehicle-fit"
                                onClick={InlineConfigReset}
                            >
                                {t("LABEL_RESET_VEHICLE_INLINE_FIT")}
                            </a>
                        </div>
                    ) : (
                        <Button
                            localeKey={t("LABEL_CHECK_START_YMM")}
                            onClick={() => {
                                setFitType(ymm);
                            }}
                            id="ymm-action-button"
                            className={"gb-secondary-button fit-button-check ac-inline-buick-btn"}
                            dataDtm={"vehicle fit"}
                            isSecondary={true}
                        />
                    ))}
                <div>
                    {fitType != ymm &&
                        (fitType == vin ? (
                            VINFitment
                        ) : (
                            <Button
                                localeKey={t("LABEL_CHECK_START_VIN")}
                                onClick={() => setFitType(vin)}
                                id="vin-action-button"
                                className={"gb-secondary-button vin-button-check ac-inline-buick-btn"}
                                dataDtm={"vehicle fit"}
                                isSecondary={true}
                            />
                        ))}
                </div>
            </div>
        </>
    );

    const currentView = () => {
        if (vinResults === true || (vinResults === false && currVin)) {
            return (
                <InlineFitmentResults
                    vehicleBreadcrumb={vinBreadcrumb}
                    vehicleInfo={currentVehicleInfo}
                    fits={vinResults}
                    handlePSRRedirect={() => handlePSRRedirectVin(currVin)}
                    onReset={InlineConfigReset}
                    fitmentError={props.showError}
                />
            );
        }

        return continueFitment ? (
            <InlineFitmentSteps
                hasReset={hasReset}
                onReset={InlineConfigReset}
                currentVehicleInfo={currentVehicleInfo}
                setCurrentVehicleInfo={setCurrentVehicleInfo}
                fitmentError={props.showError}
            />
        ) : (
            initialInlineFitment
        );
    };

    return (
        <>
            <section id="inline-fitment-section">
                <hr className="q-separator-horizontal" />
                <div id="check-vehicle-fit">
                    {props.showError && !failElem && (
                        <span className="errorbox">{t("LABEL_ADD_TO_CART_FORCED_FITMENT_ERROR")}</span>
                    )}
                    <h4 id="check-fit-anchor" className="check-vehicle-fit gb-body1">
                        {t("LABEL_CHECK_VEHICLE_FIT")}
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            trigger={["click", "hover"]}
                            overlay={renderTooltip}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} className="info-icon"></FontAwesomeIcon>
                        </OverlayTrigger>
                    </h4>
                </div>
                {currentView()}
            </section>
            <hr />
        </>
    );
}
