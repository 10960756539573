import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { Translation } from "react-i18next";

function PricingItem(props) {
    return (
        <Translation>
            {t => (
                <li className="ac-coupon-line-item">
                    {props.isRemovable ?
                        <span
                            className="ac-coupon-btn ac-light-link stat-text-link"
                            title={t("LABEL_CHECKOUT_REMOVE")}
                            onClick={() => props.handleRemove(props.id, '', "REMOVE")}
                            data-dtm={props.isPoints ? "checkout step2:rewards" : "checkout step2:coupon codes"}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                            <span dangerouslySetInnerHTML={{ __html:props.label}}></span>
                        </span>
                        :
                        <span className="ac-coupon-btn-static">
                            <span dangerouslySetInnerHTML={{ __html:props.label}}></span>
                        </span>
                    }
                    <span>
                        {props.amount}
                    </span>
                </li>
            )}
        </Translation>
    );
}

export default PricingItem;
