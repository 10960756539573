import axios from 'axios';
import AppSettings from '../../../core/AppSettings';
import store from '../../../core/Redux/Store';
import { jsonToUrlParams } from '../../../shared/Utils/Utils';
import { handleFullSearchRoute } from '../../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVWorker';
import { checkFitmentWithUrlParams } from '../SearchUtils';
import { UPDATE_CATEGORY_DATA, UPDATE_SEARCH_DATA } from "./SearchDataServiceActionTypes";

let catalogData = null;

export const setCatalogData = (catalog) => {
    catalogData = catalog;
};

/******************************************/
//              YMM CALLS
/******************************************/
export const getSearchResultsByYMM = (year, make, makeId, model, modelId, categoryId, pageNumber, orderBy, bodyId, wheelId, trimId, driveId, engineId, bodyNumDoorsId, searchTerm) => {
    //Don't want to pass in 'undefined' for optional parameters
    let order = orderBy ? orderBy : '';
    let catId = categoryId ? categoryId : '';
    let pageNum = pageNumber ? pageNumber : '1';


    //Old Url
    //let url = `/wcs/resources/store/${AppSettings.storeId}/Search/ByYMM/${year}/${make}/${model}?&langId=-1&catalogId=10052&currency=USD&responseFormat=JSON&searchType=100&pageNumber=${pageNum}&pageSize=24&categoryId=${catId}&orderBy=${order}`;
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/product/?year=${year}&make=${make}&model=${model}?&langId=-1&catalogId=${AppSettings.catalogId}&currency=USD&responseFormat=JSON&searchType=100&pageNumber=${pageNum}&pageSize=24&categoryId=${catId}&orderBy=${order}`;


    // add vehicle config params if given
    makeId ? url += ('&makeId=' + makeId) : '';
    modelId ? url += ('&modelId=' + modelId) : '';
    bodyId ? url += ('&bodyType=' + bodyId) : '';
    wheelId ? url += ('&wheelBase=' + wheelId) : '';
    trimId ? url += ('&trim=' + trimId) : '';
    driveId ? url += ('&driveType=' + driveId) : '';
    engineId ? url += ('&engineBase=' + engineId) : '';
    bodyNumDoorsId ? url += ('&bodyNumDoors=' + bodyNumDoorsId) : '';
    searchTerm ? url += ('&searchTerm=' + searchTerm) : '';

    return axios.get(url).then(response => {
        //update state
        let updateAction = {
            type: UPDATE_SEARCH_DATA,
            payload: {
                response: response.data,
                searchResultsVisible: catalogData,
                params: {
                    year: response.data.vehicleInfo[0].year,
                    make: response.data.vehicleInfo[0].make,
                    model: response.data.vehicleInfo[0].model,
                    vin: '',
                    bodyStyle: '',
                    wheelBase: '',
                    trim: '',
                    driveType: '',
                    categoryId: categoryId,
                    orderBy: orderBy,
                    searchTerm: searchTerm,
                    searchMode: "YMM"
                }
            }
        }

        store.dispatch(updateAction)
        // add catalog items to existing data to display more items (on load more click)
        if (pageNumber == 1) {
            catalogData = response.data;
        } else if (response.data.catalogEntryView !== undefined && catalogData.catalogEntryView.length < catalogData.recordSetTotalMatches) {
            const newCatalogEntryView = catalogData.catalogEntryView.concat(response.data.catalogEntryView);
            catalogData.catalogEntryView = newCatalogEntryView;
        }
        // return all catalog items
        if (catalogData) {
            return catalogData;
        }
        else {
            return response.data;
        }

    });
}

export const getCategoryResultsByYMM = (year, make, model, modelId, bodyId, wheelId, trimId, driveId, engineId, bodyNumDoorsId) => {

    //Old url
    //let url = `/wcs/resources/store/${AppSettings.storeId}/Search/ByYMM/${year}/${make}/${model}?noCache=true&langId=-1&catalogId=10052&currency=USD&responseFormat=JSON&searchType=100&pageNumber=&pageSize=24&categoryId=&orderBy=`;
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/product/?year=${year}&make=${make}&model=${model}&noCache=true&langId=-1&catalogId=${AppSettings.catalogId}&currency=USD&responseFormat=JSON&searchType=100&pageNumber=&pageSize=24&categoryId=&orderBy=`;

    // add vehicle config params if given
    modelId ? url += ('&modelId=' + modelId) : '';
    bodyId ? url += ('&bodyType=' + bodyId) : '';
    wheelId ? url += ('&wheelBase=' + wheelId) : '';
    trimId ? url += ('&trim=' + trimId) : '';
    driveId ? url += ('&driveType=' + driveId) : '';
    engineId ? url += ('&engineBase=' + engineId) : '';
    bodyNumDoorsId ? url += ('&bodyNumDoors=' + bodyNumDoorsId) : '';

    return axios.get(url).then(response => {
        //update state
        let updateAction = {
            type: UPDATE_CATEGORY_DATA,
            payload: {
                response: response.data,
                params: {
                    year: response.data.vehicleInfo[0].year || '',
                    make: response.data.vehicleInfo[0].make || '',
                    model: response.data.vehicleInfo[0].model || '',
                    vin: '',
                    modelId: '',
                    bodyStyle: '',
                    wheelBase: '',
                    trim: '',
                    driveType: '',
                    searchMode: "YMM"
                }
            }
        }

        store.dispatch(updateAction);

        //Setting up for VOV if catalogEntryView and Full Search response return empty (Mostly on T3 sites).
        const statusOfEmptyCategoryEntryView = store.getState().VoVReducer.isEmptyCategoryEntryView;
        if (statusOfEmptyCategoryEntryView) {
            handleFullSearchRoute();
        }
        return response.data;

    });
}


/******************************************/
//              VIN CALLS
/******************************************/
// v7: https://accessories.chevrolet.com/wcs/resources/store/11201/Search/ByVIN/1G1YY26W385125472?&langId=-1&catalogId=10052&currency=USD&responseFormat=JSON&searchType=100&pageNumber=1&pageSize=20&categoryId= 
export const getSearchResultsByVIN = (vehicleVin, categoryId, pageNumber, orderBy, searchTerm) => {
    //Don't want to pass in 'undefined' for optional parameters
    let order = orderBy ? orderBy : '';
    let catId = categoryId ? categoryId : '';
    let pageNum = pageNumber ? pageNumber : '1';
    let url = "/wcs/resources/store/" + AppSettings.storeId + "/Search/ByVIN/" + vehicleVin + "?&langId=-1&catalogId=" + AppSettings.catalogId + "&currency=USD&responseFormat=JSON&searchType=100&pageNumber=" + pageNum + "&pageSize=24&categoryId=" + catId + "&orderBy=" + order;
    searchTerm ? url += ('&searchTerm=' + searchTerm) : '';

    return axios.get(url).then(response => {
        const vehicleInfo = response.data.vehicleInfo[0];

        // Precaution for a nonexistent catalogEntryView
        if (!response?.data?.catalogEntryView) {
            response.data.catalogEntryView = [];
        }

        //update state
        let updateAction = {
            type: UPDATE_SEARCH_DATA,
            payload: {
                response: response.data,
                searchResultsVisible: catalogData,
                params: {
                    year: vehicleInfo.year,
                    make: vehicleInfo.make,
                    model: vehicleInfo.model,
                    vin: vehicleInfo.vin,
                    bodyStyle: vehicleInfo.bodyStyle,
                    wheelBase: vehicleInfo.wheelBase,
                    trim: vehicleInfo.trim,
                    driveType: vehicleInfo.driveType,
                    categoryId: categoryId,
                    orderBy: orderBy,
                    searchTerm: searchTerm,
                    searchMode: "VIN"
                }
            }
        }
        if(vehicleVin === AppSettings.urlParameters().vin){
            store.dispatch(updateAction)
        }

        // add catalog items to existing data to display more items (on load more click)
        if (pageNumber == 1) {
            //need to clear the array    
            catalogData = response.data;
        } else if (response.data.catalogEntryView !== undefined  && catalogData.catalogEntryView.length < catalogData.recordSetTotalMatches) {
            const newCatalogEntryView = catalogData.catalogEntryView.concat(response.data.catalogEntryView);
            catalogData.catalogEntryView = newCatalogEntryView;
        }
        // return all catalog items
        if (catalogData) {
            return catalogData;
        }
        else {
            return response.data;
        }

    });
}

export const getCategoryResultsByVIN = (vehicleVin) => {
    let url = "/wcs/resources/store/" + AppSettings.storeId + "/Search/ByVIN/" + vehicleVin + "?&langId=-1&catalogId=" + AppSettings.catalogId + "&currency=USD&responseFormat=JSON&searchType=100&pageNumber=1&pageSize=24&categoryId=";

    return axios.get(url).then(response => {
        const categoryVehicleInfo = response.data.vehicleInfo[0];

        // Precaution for a nonexistent catalogEntryView
        if (!response?.data?.catalogEntryView) {
            response.data.catalogEntryView = [];
        }

        //update state
        let updateAction = {
            type: UPDATE_CATEGORY_DATA,
            payload: {
                response: response.data,
                params: {
                    year: categoryVehicleInfo.year || '',
                    make: categoryVehicleInfo.make || '',
                    model: categoryVehicleInfo.model || '',
                    vin: categoryVehicleInfo.vin || '',
                    bodyStyle: categoryVehicleInfo.bodyStyle || '',
                    wheelBase: categoryVehicleInfo.wheelBase || '',
                    trim: categoryVehicleInfo.trim || '',
                    driveType: categoryVehicleInfo.driveType || '',
                    searchMode: "VIN"
                }
            }
        }

        store.dispatch(updateAction);

        return response.data;

    });
}


/******************************************/
//              FITMENT CALLS
/******************************************/
export const getSearchResultsByFitment = (fitmentID, year, make, model, bodyNumDoors, bodyStyle, wheelBase, trim, driveType, engineBase, categoryId, pageNumber, orderBy) => {
    let order = orderBy ? orderBy : '';
    let catId = categoryId ? categoryId : '';
    let pageNum = pageNumber ? pageNumber : '1';
    let url = `/wcs/resources/store/${AppSettings.storeId}/productSearch/similarItems/${fitmentID}/${year}/${make}/${model}`
        + `?&langId=-1&catalogId=${AppSettings.catalogId}&currency=USD&responseFormat=JSON&searchType=100&pageNumber=${pageNum}`
        + `&pageSize=24&categoryId=${catId}&orderBy=${order}&bodyNumDoors=${bodyNumDoors}&bodyType=${bodyStyle}&wheelBase=${wheelBase}`
        + `&trim=${trim}&driveType=${driveType}&engineBase=${engineBase}`;

    return axios.get(url).then(response => {
        //update state
        let updateAction = {
            type: UPDATE_SEARCH_DATA,
            payload: {
                response: response.data,
                searchResultsVisible: catalogData,
                params: {
                    year: response.data.vehicleInfo[0].year,
                    make: response.data.vehicleInfo[0].make,
                    model: response.data.vehicleInfo[0].model,
                    vin: '',
                    vcdb: response.data.vehicleInfo[0].vcdb,
                    categoryId: categoryId || '',
                    orderBy: orderBy || '',
                    searchMode: "YMM"
                }
            }
        }

        store.dispatch(updateAction)


        // add catalog items to existing data to display more items (on load more click)
        if (pageNumber == 1) {
            //need to clear the array
            catalogData = response.data;
        } else if (response.data.catalogEntryView !== undefined && catalogData.catalogEntryView.length < catalogData.recordSetTotalMatches) {
            const newCatalogEntryView = catalogData.catalogEntryView.concat(response.data.catalogEntryView);
            catalogData.catalogEntryView = newCatalogEntryView;
        }
        // return all catalog items
        if (catalogData) {
            return catalogData;
        }
        else {
            return response.data;
        }

    });
}


/******************************************/
//         NATURAL LANGUAGE SEARCH
/******************************************/
export const getNLSSuggestions = (params) => {
    const urlParams = { ...params };
    delete urlParams.year;
    delete urlParams.make;
    delete urlParams.model;
    delete urlParams.vin;

    let url = '';
    if (params.vin) {
        url = `/wcs/resources/store/${AppSettings.storeId}/sitecontent/suggestions/ByVIN/${params.vin}${jsonToUrlParams(urlParams)}&suggestType=Product&suggestType=Categories&suggestType=Keyword`;
    } else if (params.year && params.make && params.model) {
        url = `/wcs/resources/store/${AppSettings.storeId}/sitecontent/suggestions/ByYMM/${params.year}/${params.make}/${params.model}${jsonToUrlParams(urlParams)}&suggestType=Categories&suggestType=Product&suggestType=Keyword`;
    } else {
        url = `/wcs/resources/store/${AppSettings.storeId}/sitecontent/suggestions/search?suggestType=Product&suggestType=Categories&suggestType=Keyword&searchTerm=${params.searchTerm}`;
    }

    return axios.get(url).then((response) => { return response }).catch((error) => { return error });
}

export const getSearchTermAssociation = () => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/search_term_association?q=byAssociationType&associationType=4`;
    return axios.get(url).then((response) => { return response }).catch((error) => { return error });
}

export const associationTermsEspot = (eSpot) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/espot/${eSpot}`;
    return axios.get(url).then((response) => { return response }).catch((error) => { return error });
}

/******************************************/
//         SHOP WITHOUT FITMENT
/******************************************/
export const searchCallForShopWithoutFitment = (params, _orderBy = "", _pageNumber = "", urlParams) => {
    // If there are no facets, then we need orderBy to begin with the ?, instead of continuing the facet parm with &
    let hasFacets = params ? "&" : "?";
    let orderBy = _orderBy ? "orderBy=" + _orderBy : "orderBy=Top";
    let pageNumber = _pageNumber ? "&pageNumber=" + _pageNumber : "&pageNumber=1";

    // Back end call needs the facets to be separated as different facet parameters, rather than one facet parameter separated by commas
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/product${params}${hasFacets}${orderBy}${pageNumber}&pageSize=24`;
    return axios.get(url).then((response) => {
        let updateAction = {
            type: UPDATE_SEARCH_DATA,
            payload: {
                response: response.data,
                params: {
                    year: '',
                    make: '',
                    model: '',
                    vin: '',
                    modelId: '',
                    bodyStyle: '',
                    wheelBase: '',
                    trim: '',
                    driveType: '',
                    searchMode: "YMM"
                }
            }
        }
        //Helps to prevent race condition which could cause categorynav to populate incorrect categories. 
        if(urlParams && checkFitmentWithUrlParams(urlParams)){
            store.dispatch(updateAction);
        }

        // add catalog items to existing data to display more items (on load more click)
        pageNumber = _pageNumber ? _pageNumber : 1
        if (pageNumber == 1) {
            catalogData = response.data;
        } else if (response.data.catalogEntryView !== undefined && catalogData.catalogEntryView.length < catalogData.recordSetTotalMatches) {
            const newCatalogEntryView = catalogData.catalogEntryView.concat(response.data.catalogEntryView);
            catalogData.catalogEntryView = newCatalogEntryView;
        }
        // return all catalog items
        if (catalogData) {
            return catalogData;
        }
        else {
            return response.data;
        }
    })
    .catch((error) => { return error });
}