import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import AppSettings from "../../../../../core/AppSettings";
import store from "../../../../../core/Redux/Store";
import ErrorMessage from "../../../../../shared/ErrorMessage/ErrorMessage";
import { scrollerToTop } from "../../../../../shared/Utils/Utils";
import { getWalletData } from "../../../../../shared/Wallet/WalletRedux/WalletActionCreator";
import WalletWidget from "../../../../../shared/Wallet/WalletWidget";
import {
    checkPaymentMethodAsync,
    checkPaymentMethodCreator,
    loadBillingAsShipping as loadFromShipping,
    loadSpinnerOnPaymentPage
} from "../../../CheckoutRedux/CheckoutActionCreator";
import BillingForm from "./BillingForm/BillingForm";
import PayInStore from "./PayInStore/PayInStore";
import PaymentForm from "./PaymentForm";
import { gmCardPromo } from "../../../CheckoutRedux/CheckoutService";
import {useReplaceAllNamedDisclaimers} from "../../../../App/Footer/FooterDisclaimerLabel";


/*** CHECKOUT STEP 3 PAYMENT PAGE ***/
const PaymentPage = (props) => {
    const { showSpinner, showTechnicalError, payMethodId, payMethodError, authData, walletData, billingInformation, rewardsData } =
        props;
    let dealerBac = store.getState().MiniCartReducer.data.dealerizationInfo.bac;
    const [checkboxChecked, toggleCheckbox] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();
    const [espotTextFormatted, setEspotTextFormatted] = useState("");

    const createMarkup = (text) => {
        return {
            __html: text
        };
    }

    useEffect(() => {
        /** ADDED CHECK HERE TO ENSURE IT DISPATCH ONLY ONE TIME ***/
        const payMethodTriggered = props.triggeredPayMethod;
        const isT3 = AppSettings.isT3;

        if (!isT3) {
            store.dispatch(checkPaymentMethodCreator("creditCard"));
        }
        if (!payMethodTriggered && isT3) {
            store.dispatch(checkPaymentMethodAsync());
        }

        window.scrollTo(0, 0);
        scrollerToTop("root");
        const isUSUser = props.authData.userData && props.authData.userData.country === "US" ? true : false;
        if (props.authData.registrationStatusFetched && props.authData.registrationStatus !== "G" && isUSUser) {
            store.dispatch(getWalletData());
        }if(rewardsData.isRewardsActive && rewardsData.applyForGMCard === "N") {
            //Check to see if user can apply for a GM Rewards card & if user is active
            gmCardPromo().then((result) => {
                //Inject html once DOM renders
                setTimeout(() => {
                    if (result && result.data && result.data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText) {
                        setEspotTextFormatted(result.data.MarketingSpotData[0].baseMarketingSpotActivityData[0]
                            .marketingContentDescription[0].marketingText)
                    }
                }, 3000);
            });
        }
    }, []);


    useEffect(() => {
        if (props.authData.registrationStatusFetched && props.authData.registrationStatus !== "G") {
            store.dispatch(getWalletData());
        }
    }, [props.authData]);

    useEffect(() => {
        setInitialValues(billingInformation);
    }, [props.billingInformation]);

    const handleBillingSameAsShippingCheckbox = (orderData) => {
        if (!checkboxChecked) {
            toggleCheckbox(true);
            store.dispatch(loadFromShipping(orderData));
        } else {
            toggleCheckbox(false);
            store.dispatch(loadFromShipping());
        }
    };

    if (payMethodError) {
        return (
            <Translation>
                {(t) => (
                    <div>
                        <ErrorMessage message={t("LABEL_SERVER_ERROR_MSG")} icon={true} />
                    </div>
                )}
            </Translation>
        );
    } else if (payMethodId === "payInStore") {
        return <PayInStore nextButton={props.payInStoreNext} prevButton={props.payInStorePrev} />;
    } else if (payMethodId === "creditCard") {
        const registeredUser = authData.registrationStatusFetched && authData.registrationStatus != "G";
        const isUSUser = authData.userData && authData.userData.country === "US" ? true : false;
        // If wallet fails to load, display guest payment as default
        const paymentInput =
            registeredUser && walletData && walletData.walletLoadError === false && isUSUser ? (
                <WalletWidget
                    enableCheckout={true}
                    onLoad={() => store.dispatch(loadSpinnerOnPaymentPage())}
                    merchantId={`GM-${dealerBac}`}
                />
            ) : (
                <PaymentForm technicalError={showTechnicalError} />
            );

        return (
            <>
                {/* SHOW SESSION EXPIRED ERROR ON PAYMENT PAGE  */}
                {authData.isSessionExpired && (
                    <Translation>
                        {(t) => (
                            <div>
                                <ErrorMessage
                                    message={t("LABEL_AUTHENTICATION_COOKIE_EXPIRED_ERROR_MESSAGE")}
                                    icon={true}
                                />
                            </div>
                        )}
                    </Translation>
                )}
                {espotTextFormatted && (<div dangerouslySetInnerHTML={createMarkup(replaceDisclaimers(espotTextFormatted))} id="GM-Checkout-Promo"/>)}
                {paymentInput}
                {!showSpinner && (
                    <BillingForm
                        stateData={props.StatesData}
                        onSubmit={props.onSubmit}
                        handleCheckBox={props.handleBillingInformationCheckBox}
                        cartResponse={props.cartResponse}
                        enableBillingAsShipping={props.enableBillingAsShipping}
                        handlePaymentPrevious={props.handlePaymentPrevious}
                        initialValues={initialValues}
                        checkboxChecked={checkboxChecked}
                        handleBillingSameAsShippingCheckbox={handleBillingSameAsShippingCheckbox}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state) => {
    return {
        billingInformation: state.CheckoutReducer.paymentPage.billingInformation,
        StatesData: state.CheckoutReducer.shippingContactForm,
        showSpinner: state.CheckoutReducer.paymentPage.showSpinner,
        showTechnicalError: state.CheckoutReducer.paymentPage.showTechnicalError,
        payMethodId: state.CheckoutReducer.paymentPage.payMethodId,
        triggeredPayMethod: state.CheckoutReducer.paymentPage.triggeredPayMethod,
        payMethodError: state.CheckoutReducer.paymentPage.payMethodError,
        authData: state.Authentication,
        walletData: state.WalletReducer,
        rewardsData: state.RewardsReducer
    };
};

export default connect(mapStateToProps)(PaymentPage);
