import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { reduxForm } from "redux-form";
import AppSettings from "../../../../../core/AppSettings";
import ImageAndDescription from "../../../../../shared/CartComponents/ImageAndDescription/ImageAndDescription.js";
import CartOrderLineItemHeader from "../../../../../shared/CartComponents/ItemHeader/ItemHeader";
import DealerLocator from "../../../../../shared/DealerLocator/DealerLocator";
import Spinner from "../../../../../shared/Spinner/Spinner";
import asyncValidate from "./DeliveryFormValidation/DeliveryFormAsyncValidate";
import ErrorMessage from "../../../../../shared/ErrorMessage/ErrorMessage";
import { scrollerToTop } from "../../../../../shared/Utils/Utils";
import { formattedCurrency } from "../../../../../shared/Internationalization/FormattedNumbers";
import { useDispatch } from "react-redux";
import { showShippingModal, toggleShippingModal } from "../../../CheckoutRedux/CheckoutActionCreator";


let asyncChangeFields = [];
let deliveryMethodInitialValues = {};

const DeliveryForm = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        scrollerToTop("root");
        dispatch(showShippingModal(false)); //reset shipping error modal
        dispatch(toggleShippingModal(false)); //reset flag to show shipping error modal
    }, []);

    const {
        handleSubmit,
        handleDeliveryFormSubmit,
        submitting,
        previousPage,
        error,
        deliveryErrorTax,
        deliveryMethodShowSpinner,
        deliveryMissingParams,
        isRewardsInputDisabled
    } = props;
    const orderInCart = props.data.response.order;
    const orderId = orderInCart.orderInfo.orderId;
    const { shippingRates, fedexRateError, shippingRatesError } = props.data;
    let showDealer = false;
    let vehicleData = "";

    function getDeliveryMethodInitialValue(items) {
        let defaultValue = {};
        if (items != undefined) {
            const item = items[0];
            let installItem;
            if (items.length > 1) { //Indicates there is an install item - install labor price and the actual item are split into separate objects
                items.forEach((item, index) => {
                    if (item.orderItemType === "Installed") {
                        installItem = items[index]
                    }
                })
            }

            let itemShipModeId = installItem ? installItem.shipModeId : item.shipModeId;
            const itemShipModeDescription = installItem ? installItem.shipModeDescription : item.shipModeDescription;
            let itemShipModePrice = installItem? installItem.unitPrice : item.shippingCharge;
            itemShipModePrice === 0 || !installItem && item.shipModeDescription == 'Pickup at Dealer - No Additional cost' || !installItem && item.shipModeDescription == 'Dealer Installation - Contact Dealer for Cost'
                ? (itemShipModePrice = "")
                : (itemShipModePrice);

            itemShipModePrice > 0
                ? (itemShipModePrice = ' - ' + formattedCurrency(orderInCart.orderInfo.currency, itemShipModePrice))
                : (itemShipModePrice);

            const itemCarrier = installItem ? "INSTALL" : item?.carrier;

            defaultValue = {
                carrierCode: itemCarrier,
                shipModeId: itemShipModeId,
                itemShipModeDescription: itemShipModeDescription,
                itemShipModePrice: itemShipModePrice,
                orderItemId: item?.orderItemId,
                productId: item?.productId,
                quantity: item?.quantity
            };
        }
        return defaultValue;
    }

    const getImageDescProps = (item) => {
        const imageDescProps = {
            seoUrl: item.seoUrl,
            partNumber: item.partNumber,
            partName: item.partName,
            imageName: item.imageName,
            productType: item.productType,
            productId: item.productId
        };

        if (item.availableDeliveryMethods && item.availableDeliveryMethods.length) {
            imageDescProps.availableDeliveryMethods = item.availableDeliveryMethods[0];
        }
        return imageDescProps;
    };
    const dealerLocatorInfo = {
        zipCode: orderInCart.shipping.zipcode || "",
        state: orderInCart.shipping.state || "",
        country: orderInCart.shipping.countryCode || "",
        dealer: orderInCart.dealer || ""
    };

    /*** show Dealer link on T1 site only when shipping information is available ***/
    if (orderInCart.shipping !== undefined && !AppSettings.isT3) {
        showDealer = true;
    }

    if (orderInCart) {
        vehicleData = orderInCart.vehicles.map((items, index) => {
            return {
                vehicleIndex: index + 1,
                vehicleInformation: items.vehiclesInfo,
                items: items.orderItems,
                vin: items.vinNumber
            };
        });
    }

    if (shippingRatesError) {
        return (
            <div>
                <ErrorMessage icon={true} message={"LABEL_FED_EX_SHIPPING_RATES_ERROR"} />
            </div>
        );
    } else
        return (
            <form
                className="ac-delivery-form"
                onSubmit={handleSubmit((values) => {
                    handleDeliveryFormSubmit(values, this.props);
                })}
            >
                {
                    /** DISPLAY ERROR MSG IF SUBMIT FAILS AND THERE ARE OPTIONS NOT SELECTED OR INVALID DELIVERY OPTIONS SELECTED **/
                    ((props.submitFailed && props.invalid) || (props.submitSucceeded && props.invalid)) && (
                        <div className="ac-missing-delivery-method">
                            <ErrorMessage icon={true} message={"LABEL_CHECKOUT_SELECT_VALID_DELIVERY_OPTIONS"} />
                        </div>
                    )
                }
                <div className="gmit-gf-groupbox-content">
                    <div className="row">
                        <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                            <div className="ac-shoppingCartWrapper">
                                {
                                    /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY VEHICLE INFORMATION  **/
                                    vehicleData.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div>
                                                    <CartOrderLineItemHeader
                                                        data={item.vehicleInformation}
                                                        count={item.vehicleIndex}
                                                        vinNumber={item.vin}
                                                        currentComponent="delivery"
                                                    />
                                                </div>
                                                <div className="row">
                                                    {
                                                        /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY EACH LINE ITEM **/
                                                        item.items &&
                                                            item.items.map((subItem, index) => {
                                                                let currentShippingRate =
                                                                    getDeliveryMethodInitialValue(
                                                                        subItem.items
                                                                    );
                                                                
                                                                if (currentShippingRate && currentShippingRate.itemShipModeDescription) {
                                                                    if (currentShippingRate.itemShipModeDescription === t("KEY_CHECKOUT_DEFAULT_SELECT_OPTION")) {
                                                                        dispatch(toggleShippingModal(true));
                                                                    }
                                                                }
                                                                const orderItemId = subItem.items[0].orderItemId;

                                                                //need to disable fields when a network call is occuring to prevent continuous calls
                                                                const disableFields =
                                                                    deliveryMethodShowSpinner.size > 0 ||
                                                                    isRewardsInputDisabled;

                                                                const deliveryMethodFieldName = `deliveryMethod-${orderItemId}`;
                                                                if (
                                                                    !asyncChangeFields.includes(deliveryMethodFieldName)
                                                                ) {
                                                                    asyncChangeFields.push(
                                                                        `deliveryMethod-${orderItemId}`
                                                                    );
                                                                }

                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="ac-delivery-form-line-item ac-shoppingLineItemIndex"
                                                                    >
                                                                        {
                                                                            /* ADDING HORIZONTAL LINE IF THERE ARE IS MORE THAN ONE ITEM */
                                                                            index > 0 && <hr />
                                                                        }
                                                                        <div className="row ac-delivery-line-item-row">
                                                                            {deliveryMethodShowSpinner.has(
                                                                                subItem.items[0].orderItemId
                                                                            ) && (
                                                                                <div className="ac-delivery-form-overlay">
                                                                                    <div className="ac-delivery-form-spinner">
                                                                                        <Spinner />
                                                                                    </div>
                                                                                </div>
                                                                            )}

                                                                            <div className="col-xs-12 small-12 medium-12 large-12 xlarge-6 columns ">
                                                                                <ImageAndDescription
                                                                                    data={getImageDescProps(
                                                                                        subItem.items[0]
                                                                                    )}
                                                                                    vehicleInfo={
                                                                                        item.vehicleInformation
                                                                                    }
                                                                                    value={subItem.items[0].quantity}
                                                                                    price={subItem.items[0].unitPrice}
                                                                                    vin={item.vin}
                                                                                    component="delivery"
                                                                                    orderInfo={
                                                                                        orderInCart.orderInfo
                                                                                    }
                                                                                    subItems={subItem.items}
                                                                                    productId={subItem.items[0].productId}
                                                                                />
                                                                            </div>
                                                                                <div className="col-xs-12 small-12 medium-12 large-12 xlarge-5 columns ac-delivery-method-form-wrapper">
                                                                                    <div className="ac-checkout-step-2-delivery-method">
                                                                                        {t(
                                                                                            "LABEL_CHECKOUT_DELIVERY_METHOD"
                                                                                            )}
                                                                                    </div>
                                                                                        <div className="prt-checkout-delivery-method gb-label">
                                                                                            {
                                                                                                currentShippingRate.itemShipModeDescription === "Dealer Installation - Contact Dealer for Cost" ? currentShippingRate.itemShipModeDescription.split("-")[0] : currentShippingRate.itemShipModeDescription
                                                                                            }
                                                                                            {
                                                                                                currentShippingRate.itemShipModePrice
                                                                                            }
                                                                                        </div>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                );
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="small-12 medium-12 large-12 xlarge-12">
                    {
                        /** SHOW DEALER INFO AND DEALER LOCATOR FOR T1 ORDER **/
                        showDealer && (
                            <DealerLocator
                                zipCode={dealerLocatorInfo.zipCode}
                                state={dealerLocatorInfo.state}
                                country={dealerLocatorInfo.country}
                            />
                        )
                    }
                </div>
                {deliveryErrorTax && (
                    <div className="ac-delivery-tax-error">{t("LABEL_CHECKOUT_DELIVERY_SALES_TAX_ERROR")}</div>
                )}
            </form>
        );
};
DeliveryForm.proptype = {
    data: PropTypes.object.isRequired
};

export default reduxForm({
    form: "DeliveryForm", // a unique identifier for this form
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncValidate,
    initialValues: deliveryMethodInitialValues,
    asyncChangeFields: asyncChangeFields,
    shouldAsyncValidate: (params) => {
        // shouldAsyncValidate() configures the form to only call
        // asyncValidate() for field-level validation. This is because all
        // form-level validation may be performed synchronously.
        const { syncValidationPasses, trigger } = params;
        if (!syncValidationPasses) {
            return false;
        }

        // A trigger value of 'change' or 'blur' indicates field-level
        // validation. The third possible value, 'submit', is excluded.
        switch (trigger) {
            case "blur":
            case "change":
                return true;
            default:
                return false;
        }
    }
})(DeliveryForm);
