/** COMPONENT NAME : CARTIMAGEANDDESCRIPTION (Image and Description for Shopping Cart Page) **/

/** IMPORT STATEMENT **/
import React from 'react';
import { Translation } from 'react-i18next';
import Delivery from '../DeliveryMethod/DeliveryMethod';
import PropTypes from 'prop-types';
import WheelPackage from '../../WheelPackage/WheelPackage';
import MediaQuery from 'react-responsive';
import MsrpComponent from "../MSRP/MsrpComponent";
import QtyCounter from "../QtyCounter/QtyCounter";
import AppSettings from "../../../core/AppSettings"
import ItemSellerWrapper from '../ItemSeller/ItemSellerWrapper';
import CartDelivery from "../DeliveryMethod/CartDelivery/CartDelivery"
import SharedSpinner from "../../../shared/Spinner/Spinner";



/*** COMPONENT **/
const ImageAndDescription = (props) => {

    /** VARIABLES **/
    const data = props.data;
    const deliveryMethod = data.availableDeliveryMethods ? data.availableDeliveryMethods : "";
    const partNumber = data.partNumber;
    const partName = data.partName;
    const imageSource = data.imageName;
    const seoUrl = data.seoUrl;
    const prodType = data.productType;


    const vin = props.vin;

    const vehicle = props.vehicleInfo;
    const year = vehicle.year;
    const make = vehicle.make;
    const model = vehicle.model;

    // For use in building the URL
    const bodyStyle = vehicle.bodyStyle; // Text name
    const wheelBase = vehicle.wheelBase; // Text name
    const trim = vehicle.trim; // Text name
    const driveType = vehicle.driveType; // Text name
    const engineBase = vehicle.engineBase; // Text name

    const modelId = vehicle.modelId; // ID number
    const bodyStyleId = vehicle.bodyStyleId; // ID number
    const wheelBaseId = vehicle.wheelBaseId; // ID number
    const trimId = vehicle.trimId; // ID number
    const driveTypeId = vehicle.driveTypeId; // ID number
    const engineBaseId = vehicle.engineBaseId; // ID number
    const bodyNumDoorsId = vehicle.bodyNumDoors;

    let productDetailsUrl = '';
    let deliveryClass = "ac-delivery";

    const currentComponent = props.component;

    const qty = props.value;
    const orderItemId = props.orderItemId;

    const price = props.price

    if (prodType === 'PackageBean') {
        deliveryClass = "ac-delivery ac-delivery-margin"
    }



    /** BUILDING SEO URL FOR PRODUCT DETAILS PAGE **/
    if (AppSettings.routePaths) {
        productDetailsUrl = `${AppSettings.routePaths['product']}/${seoUrl}?`;
        if (vin) {
            productDetailsUrl += `vin=${vin}`
        } 
        else {
            productDetailsUrl += `year=${year}&make=${make}&model=${model}`

            // check for vehicle config params
            modelId ? productDetailsUrl += `&modelId=${modelId}` : '';
            bodyStyle ? productDetailsUrl += `&body=${bodyStyle}` : '';
            bodyStyleId ? productDetailsUrl += `&bodyId=${bodyStyleId}` : '';
            wheelBase ? productDetailsUrl += `&wheel=${wheelBase}` : '';
            wheelBaseId ? productDetailsUrl += `&wheelId=${wheelBaseId}` : '';
            trim ? productDetailsUrl += `&trim=${trim}` : '';
            trimId ? productDetailsUrl += `&trimId=${trimId}` : '';
            driveType ? productDetailsUrl += `&drive=${driveType}` : '';
            driveTypeId ? productDetailsUrl += `&driveId=${driveTypeId}` : '';
            engineBase ? productDetailsUrl += `&engine=${engineBase}` : '';
            engineBaseId ? productDetailsUrl += `&engineId=${engineBaseId}` : '';
            bodyNumDoorsId ? productDetailsUrl += `&bodyNumDoors=${bodyNumDoorsId}&bodyNumDoorsId=${bodyNumDoorsId}` :
                '';

        }
        if (AppSettings.isT3) {
            //Prepend dealer name and append bac for T3 sites
            productDetailsUrl = `${AppSettings.dealerName}${productDetailsUrl}&bac=${AppSettings.bac}`;
        }
    }

    const takeToProdDetails = () => {
        window.location.href = productDetailsUrl;
    };

    return (
        <Translation>
            {
                (t) => (<div className="ac-imageDescWrapper">
                    <div className={"small-5 medium-6 large-4 xlarge-" + ((currentComponent === "summaryRail" || currentComponent === "delivery") ? "3" : "2") + " columns ac-nonPadding-x ac-productImage"}>
                        <a href={productDetailsUrl} alt={productDetailsUrl}>
                            <img className="ac-productImage stat-image-link" data-dtm="shopping cart" src={imageSource} alt="No Image Available" />
                        </a>
                    </div>
                    <div className={"ac-partDeliveryDesc small-7 medium-6 large-8 xlarge-" + ((currentComponent === "summaryRail" || currentComponent === "delivery") ? "9" : "10") + " columns ac-nonPadding-x"}>
                        <div className="ac-prodDescription">
                            <span className="q-descriptive2 stat-text-link" data-dtm="shopping cart" onClick={takeToProdDetails}>{partName}</span>
                        </div>
                        <div className="ac-partNumber">
                            {t("LABEL_COMMON_PART_NO")} {partNumber}
                        </div>
                        {currentComponent === "summaryRail" && <div className="summary-rails-style">
                            <MsrpComponent price={price} component={currentComponent} />
                            <QtyCounter orderItemId={orderItemId} value={qty} component={currentComponent} />
                        </div>
                        }
                        {currentComponent === "delivery" && <div className="delivery-style">
                            <MsrpComponent price={price} component={currentComponent} />
                            <QtyCounter orderItemId={orderItemId} value={qty} component={currentComponent} />
                        </div>
                        }
                        {
                            /** Wheel Package for Package bean item **/

                            prodType === 'PackageBean' && <div>
                                <WheelPackage make={make} seoUrl={seoUrl} cssName="ac-fixedOverLayCartCheckout" />
                            </div>
                        }
                        { /** Seller section **/ }
                        <ItemSellerWrapper />
                        {/** DISPLAY DELIVERY METHOD UNDER THE PRODUCT DESCRIPTION ON LARGE DEVICES **/}
                        {currentComponent !== "delivery" && (<MediaQuery query="(min-width: 26.24em)">
                            <div className={deliveryClass}>
                                { /** IMPORT DELIVERY METHOD COMPONENT AND INJECT IT HERE **/}
                                {currentComponent === "summaryRail" && <Delivery availableDeliveryMethods={deliveryMethod} />}
                                {currentComponent === "cartWrapper" && props.showItemSpinner &&
                                    <SharedSpinner size="sm" />
                                }
                                {currentComponent === "cartWrapper" && !props.showItemSpinner && !AppSettings.isT3 &&
                                    <CartDelivery
                                        currency={AppSettings.currency}
                                        //styles={props.styles}
                                        orderInfo={props.orderInfo}
                                        orderItemId={props.orderItemId}
                                        quantity={qty}
                                        dealerName={props.dealer}
                                        deliveryInfo={props.deliveryInfo}
                                        dispatch={props.dispatch}
                                        setShowItemSpinner={props.setShowItemSpinner}
                                        partNumber={partNumber} 
                                        productId={props.data.productId}
                                        installItem={props.installItem}
                                    />
                                }
                                {currentComponent === "cartWrapper" && AppSettings.isT3 && <Delivery availableDeliveryMethods={deliveryMethod} />}
                            </div>
                        </MediaQuery>
                        )}
                    </div>

                </div>
                )
            }
        </Translation>
    )
};

/**  PROP TYPES VALIDATION **/
ImageAndDescription.propTypes = {
    data: PropTypes.shape({

        seoUrl: PropTypes.string.isRequired,
        partNumber: PropTypes.string.isRequired,
        partName: PropTypes.string.isRequired,
        imageSource: PropTypes.string,
        productId: PropTypes.string.isRequired,
        productType: PropTypes.string.isRequired
    }),
    vehicleInfo: PropTypes.shape({        
        wheelBase:PropTypes.string,
        driveType:PropTypes.string,
        bodyStyle:PropTypes.string,
        trim:PropTypes.string,
        engineBase:PropTypes.string,
        wheelBaseId: PropTypes.string,
        driveTypeId: PropTypes.string,
        bodyStyleId: PropTypes.string,
        trimId: PropTypes.string,
        engineBaseId: PropTypes.string,
        makeId: PropTypes.string,
        modelId: PropTypes.string,
        ymm: PropTypes.string.isRequired,
        year: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number
        ]),
        make: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired
    }),
    vin: PropTypes.string
};

export default ImageAndDescription
