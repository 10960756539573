import AppSettings from '../../core/AppSettings';
import { Translation } from 'react-i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorList from './ErrorList/ErrorList'
import ErrorPageReducer from "./ErrorPageRedux/ErrorPageReducer";
import { loadErrorPageDataAsync } from "./ErrorPageRedux/ErrorPageActionCreator"
import store from "../../core/Redux/Store";


/*
* Error page to link for general errors
* Can be expanded to handle other error cases in the future
* createImage populates the brand specific images, unless it's a t3 dealer
* pulls image location from eSpot using de reducer*/
class ErrorPage extends Component {

  componentWillMount() {
    const action = loadErrorPageDataAsync();
    store.dispatch(action);
  }

  componentDidMount() {
    /** Setting up Page Title **/
    document.title = "Error | " + AppSettings.currentSite.label + " " + "Accessories";
  }

  createImage() {
    const errorData = this.props.data.errorPageAssets;
    const ist3 = AppSettings.isT3;

    if (errorData && !ist3) {
      const imageSource = errorData;
      return (
        <div className="q-responsive-image q-responsive-image-container stat-image-link ac-large-marginbottom">
          <img alt="Page not found" src={imageSource} className="q-rectangle-image-responsive q-image">
          </img>
        </div>
      )
    } else {
      return (
        <div>
        </div>
      )
    }
  }

  render() {
    return (
      <Translation>
        {
          (t) => (
            <div className="q-gridbuilder collapse gridspacing-xlarge grid-bg-color-one    gridfullwidth">
              <div className="">
                <h1 className="ac-error-header q-display1" >
                  {t("LABEL_ERROR_PAGE_APOLOGY")}
                </h1>
              </div>
              {this.createImage()}
              <div className="small-12 medium-12 xlarge-12  grid-column-alignment-left  columns">
                <div >
                  <h2 className="ac-error-header2 q-display2 ac-large-marginbottom">
                    {t("LABEL_ERROR_PAGE_UNEXPECTED")}
                  </h2>
                </div>
                <div className="ac-large-marginbottom">
                  <ErrorList />
                </div>
              </div>
            </div>
          )
        }
      </Translation>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.ErrorPageReducer
  }
}
export default connect(mapStateToProps)(ErrorPage);
