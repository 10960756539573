import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
SHARED RadioButton COMPONENT

Props:
This components accept of array of below items
 checked:boolean (*Required)
 disabled:boolean (*Required)
 hidden:boolean (Optional, defaults to false)
 handleClick:function (Required only if disabled is false)
 handleOnChange:function (Required only if disabled is false)
 label: string, default to "" (Optional);
 id:string,default to "" (Optional),
 value:string,default to "" (Optional)
 objectLabel: boolean, defaults to false, determines if you can have a div for radio button label(optional)
 hrBetweenLabels: boolean, defaults to false, determines if there will be line separators between labels (Optional)


Usage:
    1) Import the Checkbox component from shared/RadioButton/RadioButton
Example: import SharedToggle from '../../shared/RadioButton/SharedRadioButton';


2) Add the Checkbox component and pass in the items as a prop, for example:
const sampleOnClick = (e)=> {
    console.log('e',e);
}

 const radioButton = [
 {handleClick:sampleOnClick,checked:false,disabled:false,label:"Checkbox 1",handleOnChange:sampleOnClick,id:"Cb1"},
 {handleClick:sampleOnClick,checked:true,disabled:true,label:"Checkbox 2",handleOnChange:sampleOnClick,id:'cb2'},
 {handleClick:sampleOnClick,checked:true,disabled:false,label:"Checkbox 3",handleOnChange:sampleOnClick,id:'cb3'}
];
<SharedRadioButton radioButtonProps={radioButton}/>

*/
const SharedRadioButton = (props) => {
    const { t } = useTranslation();
    const radioButtonObj = props.radioButtonProps;
    return (
        <div className="radio-button-container">
            {radioButtonObj.length > 0 && radioButtonObj.length > 0
                ? radioButtonObj.map((item, index) => {
                      const label = props.objectLabel ? item.label : t(item.label) || "";
                      const handleClick = item.disabled
                          ? () => {
                                return false;
                            }
                          : item.handleClick;
                      const disabled = item.disabled;
                      const hidden = item.hidden || false;
                      const checked = item.checked;
                      const id = item.id || "";
                      const value = item.value || "";
                      const handleOnChange = item.disabled
                          ? () => {
                                return false;
                            }
                          : item.handleOnChange;
                      const customClass = classNames({
                          checkmark: true,
                          checked: item.checked,
                          disabled: item.disabled,
                          hidden: item.hidden
                      });

                      let radioBtnClass =
                          "q-radio-button " + (item.description ? "radio-button-with-description-margin " : "");

                      return (
                          <React.Fragment key={index}>
                              {props.hrBetweenLabels && index > 0 && <hr />}
                              <label
                                  className={item.disabled ? "q-radio text-muted " : "q-radio"}
                                  key={index}
                                  hidden={hidden}
                              >
                                  {/* If this label is present, then likely using delivery options styling. Otherwise, keep regular */}
                                  {typeof(item.secondaryLabel) === "string" ? (
                                      <span className="prt-radio-delivery-label q-descriptive2">
                                          {label}
                                          {item.secondaryLabel && (
                                              <label className="prt-radio-button-secondary-label prt-radio-button-secondary-label-margin-top">
                                                  {item.secondaryLabel}
                                              </label>
                                          )}
                                      </span>
                                  ) : (
                                      label
                                  )}
                                  <input
                                      className={(props.inputClass || "") + " stat-radio"}
                                      type="checkbox"
                                      id={id}
                                      value={value}
                                      checked={checked}
                                      onClick={handleClick}
                                      disabled={disabled}
                                      onChange={handleOnChange}
                                      data-dtm={props.analyticsTag}
                                  />
                                  <span className={radioBtnClass + customClass} />
                                  {item.description && (
                                      <div className="prt-radio-button-description">{item.description}</div>
                                  )}
                              </label>
                          </React.Fragment>
                      );
                  })
                : null}
        </div>
    );
};

export default SharedRadioButton;

/** * Prop Types Checking ***/
SharedRadioButton.propTypes = {
    objectLabel: PropTypes.bool,
    analyticsTag: PropTypes.string,
    radioButtonProps: PropTypes.arrayOf(
        PropTypes.shape({
            checked: PropTypes.bool.isRequired,
            disabled: PropTypes.bool.isRequired,
            id: PropTypes.string,
            value: PropTypes.string,
            label: PropTypes.any,
            analyticsTag: PropTypes.string,
            handleOnChange: (props, propName, componentName) => {
                if (!props.disabled && !props.handleOnChange) {
                    return new Error(
                        "Invalid prop `" + propName + "` supplied to" + " `" + componentName + "`. Validation failed."
                    );
                }
            },
            handleClick: (props, propName, componentName) => {
                if (!props.disabled && !props.handleClick) {
                    return new Error(
                        "Invalid prop `" + propName + "` supplied to" + " `" + componentName + "`. Validation failed."
                    );
                }
            },
            secondaryLabel: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired,
    hrBetweenLabels: PropTypes.bool
};

/** * Default Props ***/
SharedRadioButton.defaultProps = {
    objectLabel: false,
    radioButtonProps: [
        {
            checked: true,
            id: "defaultId",
            value: "defaultValue",
            disabled: false,
            label: "Default Checkbox",
            analyticsTag: "stat-button-link stat-radio",
            handleOnChange: () => {
                return false;
            },
            handleClick: () => {
                return false;
            },
            secondaryLabel: "",
            description: ""
        }
    ],
    hrBetweenLabels: false
};
