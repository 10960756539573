import Cookies from "js-cookie";
import queryString from "query-string";
import { call, put, select } from "redux-saga/effects";
import AppSettings from "../../../../core/AppSettings";
import store from "../../../../core/Redux/Store";
import { constructMappingUrl, jsonToUrlParams, loadDealerPricing } from "../../../../shared/Utils/Utils";
import { callCGIToGetDataForVovIcon } from "../../../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVWorker";
import { MIN_SEARCH_LENGTH } from "../../NaturalLanguageSearch/NaturalLanguageSearch";
import {
    associationTermsEspot,
    getSearchResultsByFitment,
    getSearchResultsByVIN,
    getSearchResultsByYMM,
    getSearchTermAssociation,
    searchCallForShopWithoutFitment
} from "../../SearchService/SearchDataService";
import { loadSearchTermFlow } from "../../SearchService/SearchDataServiceActionCreator";
import {
    LIST_DATA_INVALID_SEARCH,
    LIST_DATA_IN_PAGELOAD,
    LIST_DATA_IN_PAGELOAD_FAILED,
    LOCK_SEARCH,
    SET_CURRENT_VEHICLE_ACTIVE
} from "../../SearchService/SearchDataServiceActionTypes";
import { loadSearchServiceDataAsync } from "./SearchResultsListActionCreator";
import vaultConstants from "../../../../../config/vault_constants";
import { checkFitmentWithUrlParams } from "../../SearchUtils";
import { loadVehicleCombinationsAsync } from "../../../ProductDetails/FitmentCheck/FitmentCheckRedux/FitmentCheckActionCreator";

// get page number from state
export const getPageNumber = (state) => state.SearchServiceReducer.pageNumber;
export const getSearchCallLock = (state) => state.SearchServiceReducer.searchCallLock;
export const getShopWithoutFitment = (state) => state.SearchServiceReducer.isShopWithoutFitmentActive;
export const getSearchServiceReducer = (state) => state.SearchServiceReducer;

// VEHICLE INFO HERE
export const getYear = (state) => state.Session.year;
export const getMake = (state) => state.Session.make;
export const getMakeCode = (state) => state.Session.makeCode;
export const getModel = (state) => state.Session.model;
export const getVin = (state) => state.Session.vin;
export const getBodyStyle = (state) => state.Session.bodyStyle;
export const getWheelBase = (state) => state.Session.wheelBase;
export const getTrim = (state) => state.Session.trim;
export const getDriveType = (state) => state.Session.driveType;
export const getVcdb = (state) => state.Session.vcdb;
export const getVehicleConfig = (state) => state.Session.vehicleConfig;
export const stateSession = (state) => state.Session;
export const getCategorySession = (state) => state.CategoryNavReducer;

// get breadcrumb all products clicked state
export const getAllProductsBreadcrumbFlag = (state) => state.BreadcrumbReducer.isAllProductsBreadcrumbClicked;

export function* searchResultsListData(action) {
    if (AppSettings.isT3 && !Cookies.get("WC_SESSION_ESTABLISHED")) {
        yield call(loadDealerPricing);
    }

    // Search call is being duplicated in the search results page due to the design.
    // This prevents that from happening. It happens when categories are clicked, load more, or breadcrumbs.
    const isLocked = yield select(getSearchCallLock);

    //SWOF: If fitment changes on PDP and a user navigates back to search, Search should unlock because url parameters will change
    let differentParams = false;
    const serviceData = yield select(getSearchServiceReducer);
    if (!checkFitmentWithUrlParams(serviceData.currentUrlParams)) {
        differentParams = true;
    }

    if (differentParams || !isLocked) {
        yield put({
            type: LOCK_SEARCH,
            searchCallLock: true,
            currentUrlParams: AppSettings.urlParameters()
        });
        const searchParams = action.payload;
        // get ymm from url params
        let parsed = queryString.parse(location.search);
        let year = "";
        let vehicleMake = "";
        let categoryId = "";
        let model = "";
        // Session storage logic added since we wnat the order by selection to persist when leaving and coming back to the PSR.
        let orderBy = sessionStorage.getItem("SEARCH_RESULTS_FILTERS")
            ? sessionStorage.getItem("SEARCH_RESULTS_FILTERS")
            : "";
        let fitment = "";
        let searchTerm = "";

        if (searchParams) {
            year = searchParams.year;
            vehicleMake = searchParams.make;
            model = searchParams.model;
            categoryId = searchParams.categoryId || "";
            // Session storage also needed, so that we continue persisting after clicking a category. This is because orderBy is not included in the action.payload obj when a category is clicked.
            orderBy = sessionStorage.getItem("SEARCH_RESULTS_FILTERS")
                ? sessionStorage.getItem("SEARCH_RESULTS_FILTERS")
                : searchParams.orderBy || "";
            searchTerm =
                searchParams.searchTerm && searchParams.searchTerm.length >= MIN_SEARCH_LENGTH
                    ? searchParams.searchTerm
                    : "";
        } else {
            year = parsed.year;
            model = parsed.model;
            vehicleMake = parsed.make;
            categoryId = parsed.categoryId;
            fitment = parsed.fitmentID;
            searchTerm = parsed.searchTerm && parsed.searchTerm.length >= MIN_SEARCH_LENGTH ? parsed.searchTerm : "";
        }

        switch (vehicleMake) {
            case "45":
                vehicleMake = "Buick";
                break;
            case "46":
                vehicleMake = "Cadillac";
                break;
            case "47":
                vehicleMake = "Chevrolet";
                break;
            case "48":
                vehicleMake = "GMC";
                break;
        }

        try {
            const pageNumber = yield select(getPageNumber);
            const session = yield select(stateSession);
            const categorySession = yield select(getCategorySession);
            // flag to see if user is coming from SERP using the All Parts breadcrumb
            const AllProductsBreadcrumbFlag = yield select(getAllProductsBreadcrumbFlag);

            // VIN SEARCH
            let vehicleVin = yield select(getVin);
            // Cookies don't work when running local, so this is to ease work in local. For safety measure in async cases,
            // if vehicleVin does not exist, check the payload and url.
            if (!vehicleVin || AppSettings.isLocalHost) {
                vehicleVin = AppSettings.urlParameters().vin || "";
            }

            yield put({
                type: SET_CURRENT_VEHICLE_ACTIVE,
                currentVehicleActive: true
            });
            // if Shop WithoutFitment -- make search for shop without fitment call
            // if vin -- make a call for RPO/VoV parts
            // if ymm only -- do what we do now, no VoV
            // if ymm plus extra fitment info -- make a call for RPO/VoV parts
            if (!vehicleVin) {
                let urlParams = AppSettings.urlParameters();
                let params = {};
                // model and year are temporary checks that default to url params because current fitment cookie requires YMM for validation.
                urlParams.year
                    ? (params.year = urlParams.year)
                    : session.vehicleConfig.year
                    ? (params.year = session.vehicleConfig.year)
                    : null;
                urlParams.model
                    ? (params.model = urlParams.model)
                    : session.vehicleConfig.model
                    ? (params.model = session.vehicleConfig.model)
                    : null;
                urlParams.modelId
                    ? (params.modelId = urlParams.modelId)
                    : session.vehicleConfig.modelId
                    ? (params.modelId = session.vehicleConfig.modelId)
                    : null;
                urlParams.make
                    ? (params.make = urlParams.make)
                    : session.vehicleConfig.make
                    ? (params.make = session.vehicleConfig.make)
                    : null;
                urlParams.body
                    ? (params.body = urlParams.body)
                    : session.vehicleConfig.body
                    ? (params.body = session.vehicleConfig.body)
                    : null;
                urlParams.bodyId
                    ? (params.bodyId = urlParams.bodyId)
                    : session.vehicleConfig.bodyId
                    ? (params.bodyId = session.vehicleConfig.bodyId)
                    : null;
                urlParams.wheel
                    ? (params.wheel = urlParams.wheel)
                    : session.vehicleConfig.wheel
                    ? (params.wheel = session.vehicleConfig.wheel)
                    : null;
                urlParams.wheelId
                    ? (params.wheelId = urlParams.wheelId)
                    : session.vehicleConfig.wheelId
                    ? (params.wheelId = session.vehicleConfig.wheelId)
                    : null;
                urlParams.trim
                    ? (params.trim = urlParams.trim)
                    : session.vehicleConfig.trim
                    ? (params.trim = session.vehicleConfig.trim)
                    : null;
                urlParams.trimId
                    ? (params.trimId = urlParams.trimId)
                    : session.vehicleConfig.trimId
                    ? (params.trimId = session.vehicleConfig.trimId)
                    : null;
                urlParams.drive
                    ? (params.drive = urlParams.drive)
                    : session.vehicleConfig.drive
                    ? (params.drive = session.vehicleConfig.drive)
                    : null;
                urlParams.driveId
                    ? (params.driveId = urlParams.driveId)
                    : session.vehicleConfig.driveId
                    ? (params.driveId = session.vehicleConfig.driveId)
                    : null;
                urlParams.engine
                    ? (params.engine = urlParams.engine)
                    : session.vehicleConfig.engine
                    ? (params.engine = session.vehicleConfig.engine)
                    : null;
                urlParams.engineId
                    ? (params.engineId = urlParams.engineId)
                    : session.vehicleConfig.engineId
                    ? (params.engineId = session.vehicleConfig.engineId)
                    : null;
                // categorySession is empty on initial load so it it gives an error. only once we click load more again it gets filled. this advoid the initial error
                if (!Object.keys(categorySession).length === 0) {
                    urlParams.categoryId
                        ? (params.categoryId = urlParams.categoryId)
                        : categorySession.selectedCategory?.uniqueId
                        ? (params.categoryId = categorySession.selectedCategory.uniqueId)
                        : null;
                }
                urlParams.bodyNumDoorsId
                    ? (params.bodyNumDoors = urlParams.bodyNumDoorsId)
                    : urlParams.bodyNumDoors
                    ? (params.bodyNumDoors = urlParams.bodyNumDoors)
                    : session.vehicleConfig?.bodyNumDoorsId
                    ? (params.bodyNumDoors = session.vehicleConfig.bodyNumDoorsId)
                    : null;

                //combine params and cookieParams
                const combinedParams = {
                    searchTerm: urlParams.searchTerm,
                    categoryId: urlParams.categoryId,
                    ...params
                };

                // Due to async flow, we need to grab the categoryId from the action rather than from the urlParams function
                if (combinedParams.categoryId && action?.payload?.categoryId) {
                    combinedParams.categoryId = action.payload.categoryId;
                }

                // Pressing All Parts breadcrumb in NLS flow also has an async edge case we need to handle. Get rid of the parameter.
                if ((combinedParams.searchTerm && action?.payload?.categoryId) || AllProductsBreadcrumbFlag) {
                    delete combinedParams.searchTerm;
                }

                const mappingParams = constructMappingUrl(combinedParams);
                const swfResponse = yield call(
                    searchCallForShopWithoutFitment,
                    jsonToUrlParams(mappingParams),
                    orderBy,
                    pageNumber,
                    params
                );

                // This check should prevent race conditions from occuring when hitting the back button with SWOF.
                if (checkFitmentWithUrlParams(params)) {
                    yield put({
                        type: LIST_DATA_IN_PAGELOAD,
                        payload: swfResponse
                    });
                } else {
                    yield put({
                        type: LOCK_SEARCH,
                        searchCallLock: false,
                        currentUrlParams: AppSettings.urlParameters()
                    });
                }

                // Still need to make Vov call? Will get errors without vehicleInfo, but SWF does not necessarily have that.
                // -- TODO: Come back to this.
                // yield (callCGIToGetDataForVovIcon())
            } else if (vehicleVin) {
                const vinResponse = yield call(
                    getSearchResultsByVIN,
                    vehicleVin,
                    categoryId,
                    pageNumber,
                    orderBy,
                    searchTerm
                );

                const currUrlParams = AppSettings.urlParameters();
                //prevents race conditions by checking vin at time of search call with the latest vin in the url parameters.
                if (vehicleVin === currUrlParams["vin"]) {
                    yield put({
                        type: LIST_DATA_IN_PAGELOAD,
                        payload: vinResponse
                    });
                    yield callCGIToGetDataForVovIcon();
                } else {
                    yield put({
                        type: LOCK_SEARCH,
                        searchCallLock: false,
                        currentUrlParams: AppSettings.urlParameters()
                    });
                }
            } else if (fitment) {
                const vcdb = yield select(getVcdb);
                const response = yield call(
                    getSearchResultsByFitment,
                    fitment,
                    year,
                    vehicleMake,
                    model,
                    vcdb.bodyNumDoors,
                    vcdb.bodyTypeId,
                    vcdb.wheelBaseId,
                    vcdb.trimId,
                    vcdb.driveTypeId,
                    vcdb.engineBaseId,
                    categoryId,
                    pageNumber,
                    orderBy
                );
                yield put({
                    type: LIST_DATA_IN_PAGELOAD,
                    payload: response
                });

                yield callCGIToGetDataForVovIcon();
            }
            // YMM SEARCH
            else if (year && vehicleMake && model) {
                const vehicleConfig = yield select(getVehicleConfig);
                const makeCode = yield select(getMakeCode);

                let makeId = makeCode ? makeCode : "";
                let modelId = vehicleConfig.modelId ? vehicleConfig.modelId : "";
                let bodyId = vehicleConfig.bodyId ? vehicleConfig.bodyId : "";
                let wheelId = vehicleConfig.wheelId ? vehicleConfig.wheelId : "";
                let trimId = vehicleConfig.trimId ? vehicleConfig.trimId : "";
                let driveId = vehicleConfig.driveId ? vehicleConfig.driveId : "";
                let engineId = vehicleConfig.engineId ? vehicleConfig.engineId : "";
                let bodyNumDoorsId = vehicleConfig.bodyNumDoors ? vehicleConfig.bodyNumDoors : "";

                const ymmResponse = yield call(
                    getSearchResultsByYMM,
                    year,
                    vehicleMake,
                    makeId,
                    model,
                    modelId,
                    categoryId,
                    pageNumber,
                    orderBy,
                    bodyId,
                    wheelId,
                    trimId,
                    driveId,
                    engineId,
                    bodyNumDoorsId,
                    searchTerm
                );
                yield put({
                    type: LIST_DATA_IN_PAGELOAD,
                    payload: ymmResponse
                });

                yield callCGIToGetDataForVovIcon();
            } else {
                yield put({
                    type: LIST_DATA_INVALID_SEARCH,
                    showInvalidSearchError: true
                });
            }
        } catch (error) {
            yield put({
                type: LIST_DATA_IN_PAGELOAD_FAILED,
                showError: true,
                error: error
            });
        }
    }
}

export function* handleNlsFlow() {
    //Set Feature Flag
    const featureFlagSearchTermAssociation = AppSettings.isLocalHost
        ? true
        : vaultConstants.FF_2098470_NLS_SEARCH_TERM_ASSOCIATION;
    if (featureFlagSearchTermAssociation) {
        try {
            const searchTermAssociationResponse = yield call(getSearchTermAssociation);
            const searchTerm = queryString.parse(location.search).searchTerm;
            let isMatching = false;
            let associationTerms = "";
            const resultList = searchTermAssociationResponse.data.resultList;

            for (var index in resultList) {
                if (searchTerm.toLowerCase() == resultList[index].searchTerms.toLowerCase()) {
                    // load the vehicle combination data, used in Parts shop cards component to check if current vehicle is EV
                    yield put(loadVehicleCombinationsAsync());
                    isMatching = true;
                    associationTerms = resultList[index].associationTerms;
                    break;
                }
            }

            // If no match, then go back to the regular flow. Otherwise go through the new flow.
            if (isMatching) {
                // If one of the terms matched, then we need to make the eSpot call.
                associationTermsEspot(associationTerms)
                    .then((response) => {
                        if (response.data && response.status === 200) {
                            // Once we have the spot data, now render the page.
                            const loadSearchTerm = loadSearchTermFlow(response.data);
                            store.dispatch(loadSearchTerm);
                        } else {
                            store.dispatch({
                                type: LIST_DATA_IN_PAGELOAD_FAILED,
                                showError: true
                            });
                        }
                    })
                    .catch((error) => {
                        store.dispatch({
                            type: LIST_DATA_IN_PAGELOAD_FAILED,
                            showError: true,
                            error: error
                        });
                    });
            } else {
                // Continue with classic flow -- load search service data to state
                const loadSearchServiceData = loadSearchServiceDataAsync();
                store.dispatch(loadSearchServiceData);
            }
        } catch (error) {
            yield put({
                type: LIST_DATA_IN_PAGELOAD_FAILED,
                showError: true,
                error: error
            });
        }
    } else {
        //load search service data to state
        const loadSearchServiceData = loadSearchServiceDataAsync();
        store.dispatch(loadSearchServiceData);
    }
}
