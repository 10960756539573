import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getBaseMarketingSpotActivityData } from "../Validator/Validator";
import { getMarketingContentEspot } from "./SharedMarketingContentAreaService";
import Carousel from "../Carousel/Carousel";

/*

SharedMarketingContentArea

Implement this in any place for marketable content from espot

Use steps:

1. import SharedMarketingContentArea from "/shared/MarketingContentArea/SharedMarketingContentArea"

2. Insert <SharedMarketingContentArea /> with props (** = required):
    componentName**: Name of the page where the marketing content area is being rendered
    categoryLabel: Name of desired content for the marketing content area 
                   (useful for multiple marketing components per page)
    responseCallback: Function from parent to return espot response

*/

export default function SharedMarketingContentArea(props) {
    const [carouselResponse, setCarouselResponse] = useState();
    const [isActivated, setIsActivated] = useState(true);

    useEffect(() => {
        getMarketingContent();
    }, [props.categoryLabel]);

    // Get data from Espot and set Values for each content
    const getMarketingContent = async () => {
        let response;
        try {
            response = await getMarketingContentEspot(props);
            if (response) {
                setCarouselResponse(response.data);
                setIsActivated(true);
            } else {
                setIsActivated(false);
            }
            if (props.responseCallback) {
                props.responseCallback(response);
            }
        } catch (e) {
            console.warn("Unable to retrieve content from espot -->", e);
        }
    };

    return isActivated ? (
        <div id="marketing-content-carousel" className="marketing-carousel-container">
            <Carousel pageName={window.location.pathname} customResponse={carouselResponse} />
        </div>
    ) : (
        <></>
    );
}

SharedMarketingContentArea.propTypes = {
    componentName: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string,
    responseCallback: PropTypes.func
};

SharedMarketingContentArea.defaultProps = {
    categoryLabel: ""
};
