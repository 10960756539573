import axios from "axios";
import AppSettings from "../../core/AppSettings";

export const getMarketingContentEspot = (URLdata) => {
    const marketingContentEspot =
        "/wcs/resources/store/" +
        AppSettings.storeId +
        "/espot/" +
        URLdata.componentName +
        (URLdata.categoryLabel !== "" ? "?categoryName=" : "") +
        URLdata.categoryLabel +
        "&noCache=true";

    return axios
        .get(marketingContentEspot)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};
