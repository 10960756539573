import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import DealerLocatorHeader from "../DealerLocatorHeader";
import { dealerRadioBtn, selectASrcSection } from "./ModalBodyComponents";
import MapWrapper from "../MapContent/MapWrapper";
import SaveAndCloseButton from "../Buttons/SaveAndCloseButton";
import CancelButton from "../Buttons/CancelButton";
import CartRefillWarning from "../Warning/CartRefillWarning";
import AppSettings from "../../../../core/AppSettings";
import { switchSearch } from "../HelperFunctions";

/**
 * @param {*} props
 * handleClose // handle close function for dealerization widget
 * dispatch // reducer updater
 * state // reducer state // dealerization reducer state(s)
 * cartFlag // bool // renders whether warning shows or not
 *
 * @return {div} <MapBody/> body of dealerization widget for the product description view.
 */
export default function MapBody(props) {
    //Chevy requires special styling found in PDDealerizerStyle.scss
    const IS_CHEVY = AppSettings.currentSite.key === "chevrolet";
    const chevyStyle = IS_CHEVY ? " chevy-adjust" : "";

    const CART_FLAG = props.cartFlag;

    if (CART_FLAG) {
        return (
            <div className="prt-dealerization-modal-body">
                <CartRefillWarning handleClose={props.handleClose} />
            </div>
        );
    } else {
        return (
            <div className={"prt-dealerization-modal-body prt-map-and-price-view" + chevyStyle}>
                <Row>
                    <Col>
                        <Row className={"row-no-margin"}>
                            <DealerLocatorHeader mapView={true} dispatch={props.dispatch} state={props.state} />
                        </Row>
                    </Col>
                </Row>
                <Row className={"row-zipCode-map"}>
                    <Col lg={6} md={12} sm={12} className={"col-zipcode-sellers"}>
                        <Row className={"row-no-margin"}>{dealerRadioBtn(props.handleSearchType, props.state)}</Row>
                        <Row className={"row-no-margin"}>{switchSearch(props)}</Row>
                        <Row className={"row-no-margin"}>
                            {selectASrcSection(props.state, props.dispatch, true)}
                        </Row>
                        {!props.state.listOfAllSources.length && (
                            <Row>
                                <div className="map-list-placeholder" />
                            </Row>
                        )}
                    </Col>
                    <Col lg={6} md={12} sm={12} className={"col-pdp-map"}>
                        <MapWrapper dispatch={props.dispatch} state={props.state} />
                    </Col>
                </Row>
                <Row className={"row-top-margin-mobile"}>
                    <Col className={"save-btn-modal"}>
                        <SaveAndCloseButton
                            mapView={true}
                            cartFlag={CART_FLAG}
                            dispatch={props.dispatch}
                            state={props.state}
                        />
                    </Col>
                    <Col className={"cancel-btn-modal"}>
                        <CancelButton handleClose={props.handleClose} dispatch={props.dispatch} state={props.state} />
                    </Col>
                </Row>
            </div>
        );
    }
}

/*** PROP TYPES VALIDATION ***/
MapBody.propTypes = {
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired,
    cartFlag: PropTypes.bool.isRequired
};
