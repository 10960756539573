/****** Old Configuratino ******/
/*
// import { default as i18n } from "i18next";
const i18n = require('i18next');
//import { reactI18nextModule } from "react-i18next";
import { initReactI18next } from "react-i18next";
import AppSettings from '../AppSettings';
import backend from "i18next-xhr-backend";

// the translation files

//This code is only used if we're importing directly and not from the backend 

//import commonEN from '../../assets/locales/en_US/t1GMResourcesBundle.json';

//const resources = {
 // 'en-US': {
//    common: commonEN
//  }
//};
//
//change namespace based on store
const isT3 = AppSettings.isT3;

let ns = 't1GMResourcesBundle';
if(isT3 === true){
  ns = 't3GMResourcesBundle';
}

//config options documentation https://www.i18next.com/overview/configuration-options
i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(backend)
.init({
//list of namespaces to load, will load all if it's being used on the page
  //the default namespace is the  ns used when non are specified
  ns: ns,
  defaultNS: ns,
  fallbackNS: ns,

//backend config settings
  backend :{
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
    },


    //loads only the language files necessary for the page to function, can be changed to specific files, 'all', or only certain languages
    load: "currentOnly",
    //asks react to wait until the translation files are loaded before rendering the DOM content
    react:{
      useSuspense: false,
      wait: true
    },
    //specifies the language on page load, there is an additional package that can be used to auto detect the users language
    lng: "en-US",
    //doesn't allow cross domain JSON requests
    crossDomain: false,
    //fallback language incase the currently selected language doesn't have a key available
    fallbackLng: "en-US",
    //not escaping values since react handles that for us
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;*/

/*** New Configuration ****/
import i18n from "i18next";
import AppSettings from "../AppSettings";
import {  initReactI18next } from "react-i18next";
import t1GMResourcesBundle from '../../../client/assets/locales/en-US/t1GMResourcesBundle.json';
import t3GMResourcesBundle from '../../../client/assets/locales/en-US/t3GMResourcesBundle.json';

/**
 * This is the translation file. Use this in combination with the assets/locales/en-US/GMResourcesBundle.json
 *    file to translate text.
 * Follow these steps while paying special attention to whether you'll be implementing it within a function or a class:
 * Step 0) Import the translation respective to whether you're using it as a class or a function
 * Step 1) Create the translate text within the locales folders' files. Create it in all languages available.
 * Step 2) Reference the label within the component you'd like to translate text in
 *    There are a few ways you can go about this, shown in the examples further below.
 * Step 2A) (FUNCTION ONLY) You need to set the function as a const to be able to use the translation.

 CLASS INSTRUCTIONS:
 // Step 0) THIS IS YOUR COMPONENT IMPORTING
 import i18n from '../../i18n/i18n'

 // Step 1) DO THIS FROM WITHIN THE RESPECTIVE LOCALES FILES
 assets/locales/en-US/GMResourcesBundle.json
 "LABEL_HTML": "html stuff whatever",
 "LABEL_PLAINSTRING": "plain string stuff you get it",
 "LABEL_THIS_WORKS_TOO": "This works ok just trust me"
 // notice the two above end with a comma, but the final one doesn't. it's a json file.

 // Step 2) THIS IS NOW YOUR COMPONENT
 render(){
   // <> this syntax can replace any HTML syntax. I'm using it here to just show this can be placed with divs and etc </>
   const htmlComponentWithText = <>{i18n.t('LABEL_HTML')}</>;
   const plainStringText = {i18n.t('LABEL_PLAINSTRING')};
  return <div>
            <SomeRelevantComponent
                htmlComponentWithText={htmlComponentWithText}
                plainStringText={plainStringText}
                translateTextAsProp={i18n.t('LABEL_THIS_WORKS_TOO')}
            />
        </div>
    }

 FUNCTION INSTRUCTIONS:
 // Step 0) THIS IS YOUR COMPONENT IMPORTING
 import { useTranslation } from "react-i18next";

 // Step 1) DO THIS FROM WITHIN THE RESPECTIVE LOCALES FILES
 assets/locales/en-US/GMResourcesBundle.json
 "LABEL_SOME_OTHER_TEXT": "some other stuff whatever",
 "LABEL_MORE_TEXT_WITHIN_HTML": "more text but within html you get it",
 "LABEL_TEXT_THAT_WAS_ALREADY_PASSED_BUT_AS_A_PROP": "you can pass the plain string
 'LABEL_TEXT_THAT_WAS_ALREADY_PASSED_BUT_AS_A_PROP' from the parent
 and then translate it within the child component if you really want to.
 kind of confusing, i'd probably avoid it."
 // notice the two above end with a comma, but the final one doesn't. it's a json file.

 export default function whateverFunctionComponent (props) {
   // Step 2A)
   const { t } = useTranslation();
   const someOtherText = {t('LABEL_SOME_OTHER_TEXT)};
   const moreTextWithinHTML = <div>{t('LABEL_MORE_TEXT_WITHIN_HTML')}</div>;

   return (
     // Step 2)
     {t(props.textlabelpassedasaprop)}
     {someOtherText}
     {moreTextWithinHTML}
   )
 }
 */

//change namespace based on store
const isT3 = AppSettings.isT3;

let GMResourcesBundle = t1GMResourcesBundle;
if(isT3 === true){
  GMResourcesBundle = t3GMResourcesBundle;
}
const defaultLang = process.env.JEST_WORKER_ID !== undefined ? "cimode" : "en";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: GMResourcesBundle
        }
      },
      lng: defaultLang,
      fallbackLng: "en",

      interpolation: {
        escapeValue: false
      }
    });

export const _setLanguage = async (langId) => i18n.changeLanguage(langId);
export const _resetLanguage = async () => i18n.changeLanguage(defaultLang);
    

export default i18n;


