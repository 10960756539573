import React from 'react';
import i18next from 'i18next';


function SideNavigatorLink(props) {
    const renderMobile = { props };
    if (!renderMobile) {
        return (
            <div className={props.isActive ? "ac-side-nav-link-active" : "ac-side-nav-link"}>
                <a className="q-mod-button q-button q-link" href={props.url}>{i18next.t(props.displayName)}</a>
            </div>
        )
    }
    else {
        return (
            <div className={props.isActive ? "ac-side-nav-link-active" : "ac-side-nav-link"}>
                <a className="q-mod q-mod-button q-button q-list-item-icon-primary hoverable-icon q-button-small q-button-padding q-list-item none-margin" href={props.url}>{i18next.t(props.displayName)}</a>
            </div>
        )
    }
}

export default SideNavigatorLink