import React, { useState, useReducer } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import propTypes from "prop-types";
import EditPaymentForm from "../EditPaymentForm/EditPaymentForm";
import {useTranslation} from "react-i18next";
import RemoveConfirmationModal from "../WalletModals/RemoveConfirmationModal";
import store from "../../../core/Redux/Store";
import {updateCreditCardSuccessInformation} from "../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import { useSelector} from "react-redux";
import {getPaymentTypeImage} from "../GMWalletHelper";

StoredCardInfo.propTypes = {
    showESTOREcard: propTypes.bool,
    storedCardData: propTypes.object,
    state: propTypes.object,
    dispatch: propTypes.func
};
/**
This component displays the stored card information within a react-bootstrap Card. Displays the card details such as
 brand name, last four digits, holder name , expiry date
 It also presents the Edit and Removal functions for each card.
 */
export default function StoredCardInfo(props) {
     const { t } = useTranslation();
    const {
        storedCardData,
        showESTOREcard
    } = props;

    const selectedPaymentMethod = useSelector((state) => state.CheckoutReducer.paymentPage.paymentInformation);

    const [showEditForm, setShowEditForm] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    //handle the state of the removal modal
    const hideRemovalModal = () => {
        setShowRemoveModal(false);
    };

    //For Authenticated users in Checkout when they click the payment method they wish to use
    //This sets the payment method information into the Checkout Reducer
    const selectPaymentMethod = (method) => {
        store.dispatch(updateCreditCardSuccessInformation({ payment: method, userId: props.state.params.userId, isOneTimePayment: props.state.isOneTimePayment }));
    };

    return (
            //This section renders for:
            //  Authenticated users viewing there Wallet in the /profile/payment page
            //  Guest users in checkout flow
            //  Authenticated users in Checkout who select One Time payment option
        ((props.state.isAuthUser && !props.state.isCheckout && showESTOREcard) ||
            (!props.state.isAuthUser && props.state.isCheckout) ||
            (props.state.isAuthUser && props.state.isCheckout && props.state.isOneTimePayment)) && (
            <>
                <Card className="card-height card-bootstrap">
                    <Row>
                        {storedCardData.brand && (
                            <Col xs={1} id="imageCol">
                                <Card.Img
                                    style={{ padding: "2.5em 0 2.5em 0" }}
                                    src={getPaymentTypeImage(storedCardData.brand)}
                                    alt="Card image"
                                />
                            </Col>
                        )}
                        <Col xs={6} id="cardInformationCol">
                            <Card.Body className="">
                                <div style={{ fontWeight: "500" }}>
                                    {storedCardData.name + " ****" + storedCardData.lastFour}
                                </div>
                                <div> {storedCardData.holderName}</div>
                                {storedCardData.expiryMonth && (
                                    <span>
                                        {t("LABEL_GMWALLET_EXPIRES")} {storedCardData.expiryMonth}/{storedCardData.expiryYear}
                                    </span>
                                )}
                            </Card.Body>
                        </Col>
                        {storedCardData.holderName && (
                            <Col className="col-editCard" id="editCardCol">
                                <Card.Body className="edit-remove-align">
                                    <div>
                                        <span className="wallet-edit-payment link-text" onClick={() => {
                                                setShowEditForm(!showEditForm);
                                                props.dispatch({ type: "HIDE_EDIT_ERROR_MESSAGE" });
                                            }}>
                                        {showEditForm ? t("LABEL_COMMON_CANCEL") : t("LABEL_GMWALLET_EDIT")}
                                        </span>
                                        {!showEditForm && <span className="separator">{showEditForm ? "" : "|"} </span>}
                                        <span className="wallet-remove-payment link-text" onClick={() => setShowRemoveModal(true)}>
                                            {showEditForm ? "" : t("LABEL_GMWALLET_REMOVE") }
                                        </span>
                                    </div>
                                </Card.Body>
                            </Col>
                        )}
                    </Row>
                    {showEditForm && (
                        <EditPaymentForm
                            hideEditForm={() => setShowEditForm(false)}
                            storedPaymentMethodId={storedCardData.id}
                            holderName={storedCardData.holderName}
                            expiryDate={storedCardData.expiryMonth + "/" + storedCardData.expiryYear}
                            state={props.state}
                            dispatch={props.dispatch}
                        />
                    )}
                </Card>
                {showRemoveModal && (
                    <RemoveConfirmationModal
                        removalData={storedCardData}
                        hideRemovalModal={hideRemovalModal}
                        state={props.state}
                        dispatch={props.dispatch}
                    />
                )}
            </>
        )) ||
        //This section renders only for Authenticated users while in Checkout
        (props.state.isCheckout && props.state.isAuthUser && showESTOREcard && (
            <>
                <Card className="card-height card-bootstrap">
                    <Row>
                        <Col xs={2}>
                            {/* <SharedRadioButton radioButtonProps={radioButton} chooseCard={chooseCard}/> */}
                            <input className="radio-button" type="radio" id="html" name="fav_language" value="HTML" checked={storedCardData.id === selectedPaymentMethod?.payment?.id} onChange={() => selectPaymentMethod(storedCardData)}/>
                        </Col>
                        {storedCardData.brand && (
                            <Col xs={1} id="imageCol">
                                <Card.Img
                                    style={{ padding: "2.5em 0 2.5em 0" }}
                                    src={getPaymentTypeImage(storedCardData.brand)}
                                    alt="Card image"
                                />
                            </Col>
                        )}
                        <Col xs={6} id="cardInformationCol">
                            <Card.Body className="">
                                <div style={{ fontWeight: "500" }}>
                                    {storedCardData.name + " ****" + storedCardData.lastFour}
                                </div>
                                <div> {storedCardData.holderName}</div>
                                {storedCardData.expiryMonth && (
                                    <span>
                                        {t("LABEL_GMWALLET_EXPIRES")} {storedCardData.expiryMonth}/{storedCardData.expiryYear}
                                    </span>
                                )}
                            </Card.Body>
                        </Col>
                        {storedCardData.holderName && (
                            <Col className="col-editCard" id="editCardCol">
                                <Card.Body className="edit-remove-align">
                                    <div>
                                        <span className="wallet-edit-payment link-text" onClick={() => {
                                            setShowEditForm(!showEditForm);
                                            props.dispatch({ type: "HIDE_EDIT_ERROR_MESSAGE" });
                                        }}>
                                        {showEditForm ? t("LABEL_COMMON_CANCEL") : t("LABEL_GMWALLET_EDIT")}
                                        </span>
                                        {!showEditForm && <span className="separator">{showEditForm ? "" : "|"} </span>}
                                        <span className="wallet-remove-payment link-text" onClick={() => setShowRemoveModal(true)}>
                                            {showEditForm ? "" : t("LABEL_GMWALLET_REMOVE") }
                                        </span>
                                    </div>
                                </Card.Body>
                            </Col>
                        )}
                    </Row>
                    {showEditForm && (
                        <div>
                            <EditPaymentForm
                                hideEditForm={() => setShowEditForm(false)}
                                storedPaymentMethodId={storedCardData.id}
                                holderName={storedCardData.holderName}
                                expiryDate={storedCardData.expiryMonth + "/" + storedCardData.expiryYear}
                                state={props.state}
                                dispatch={props.dispatch}
                            />
                        </div>
                    )}
                </Card>
                {showRemoveModal && (
                    <RemoveConfirmationModal
                        removalData={storedCardData}
                        hideRemovalModal={hideRemovalModal}
                        state={props.state}
                        dispatch={props.dispatch}
                    />
                )}
            </>
         ) || null
    );
}
