import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    LABEL_YMM_SELECT_BODY,
    LABEL_YMM_SELECT_DRIVE,
    LABEL_YMM_SELECT_ENGINE,
    LABEL_YMM_SELECT_TRIM,
    LABEL_YMM_SELECT_WHEEL,
    YMM_BODY,
    YMM_DRIVE,
    YMM_ENGINE,
    YMM_TRIM,
    YMM_WHEEL,
    YMM_CHOOSE_BODY,
    YMM_CHOOSE_DRIVE,
    YMM_CHOOSE_ENGINE,
    YMM_CHOOSE_TRIM
} from "../InlineFitment/InlineSharedConstants";
import InlineSharedDropdown from "./InlineSharedDropdown";
import {
    selectBody,
    selectDrive,
    selectEngine,
    selectTrim,
    selectWheel
} from "../../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import AppSettings from "../../../core/AppSettings";
import { browserHistory } from "../../../modules/App/App";
import propTypes from "prop-types";
import SharedButton from "../../../shared/Button/SharedButton";
import vaultConstants from "../../../../config/vault_constants";
import { loadFitmentCheckAsync } from "../FitmentCheck/FitmentCheckRedux/FitmentCheckActionCreator";

const InlineSharedYMMDropdown = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const EMPTY_STRING = "";

    /** * Taking a value from Redux-Reducer and using them as constants ***/
    const selectedBody = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.bodyStyle);
    const selectedWheel = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.wheelBaseId);
    const selectedTrim = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.trim);
    const selectedDrive = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.driveType);
    const selectedEngine = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo.engine);
    const selectedCategory = useSelector((state) => state.SearchLinkReducer.categoryId);
    const vehicleInfo = useSelector((state) => state.VehicleSelectReducer);
    const selectedBodyNumDoors = useSelector((state) => state.VehicleConfigReducer.vehicleParams.bodyNumDoorsParam);

    const inlineFitmentFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2197227_INLINE_FITMENT_PDP_ACCESSORIES) === "true";

    const configureShowFitCheck = () => {
        if (
            ((!props?.neededFitment?.includes("bodyStyle") && selectedBody === EMPTY_STRING) ||
                (props?.neededFitment?.includes("bodyStyle") && selectedBody !== EMPTY_STRING)) &&
            (!props?.neededFitment?.includes("wheelBase") ||
                (props?.neededFitment?.includes("wheelBase") && selectedWheel !== EMPTY_STRING)) &&
            ((!props?.neededFitment?.includes("trim") && selectedTrim === EMPTY_STRING) ||
                (props?.neededFitment?.includes("trim") && selectedTrim !== EMPTY_STRING)) &&
            (!props?.neededFitment?.includes("driveType") ||
                (props?.neededFitment?.includes("driveType") && selectedDrive !== EMPTY_STRING)) &&
            ((!props?.neededFitment?.includes("engine") && selectedEngine === EMPTY_STRING) ||
                (props?.neededFitment?.includes("engine") && selectedEngine !== EMPTY_STRING))
        ) {
            if (!window.location.pathname.includes("/MarketingMaterials")) {
                if (props.autoComplete) {
                    //For inlinefitment, don't redirect to PSR, instead call fit handler to show final fit/doesn't fit page.
                    if (props.showFitHandler) {
                        props.showFitHandler();
                    }
                }
            }
        }
    };

    const formatTitle = (args) => t(args);

    /** * OverWrite 'Select Wheel' Dropdown Name ***/
    const formatWheelTitle = () => {
        if (props.wheelFitmentSelection.length) {
            const wheelDropDownName = props.wheelFitmentSelection[0].WHEEL_DROPDOWN_LABEL;

            if (props.inlineFitmentYMM) {
                return t("LABEL_COMMON_BOX_OR_WHEEL_TYPE", { wheelDropDownName });
            }
            return " \u2014 " + t("LABEL_COMMON_CHOOSE_BOX_OR_WHEEL_TYPE", { wheelDropDownName }) + " \u2014 ";
        }
        return formatTitle("LABEL_YMM_SELECT_WHEEL");
    };

    /** * Function that accepts an event and return the data attribute value as JSON ***/
    const getParsedData = (e) => JSON.parse(e.target.getAttribute("data-object"));

    useEffect(() => {
        if (props?.neededFitment) {
            configureShowFitCheck();
        }
    }, [props?.neededFitment]);

    // useEffects build the breadcrumbs off of pre-population data based on the present vehicle attributes
    useEffect(() => {
        if (selectedBody && !props.hasReset) {
            props.handleVehicleBreadcrumb(selectedBody);
        }
    }, [selectedBody]);

    useEffect(() => {
        if (selectedTrim && !props.hasReset) {
            props.handleVehicleBreadcrumb(selectedTrim);
        }
    }, [selectedTrim]);

    useEffect(() => {
        if (selectedDrive && !props.hasReset) {
            props.handleVehicleBreadcrumb(selectedDrive);
        }
    }, [selectedDrive]);

    useEffect(() => {
        if (selectedEngine && !props.hasReset) {
            props.handleVehicleBreadcrumb(selectedEngine);
        }
    }, [selectedEngine]);

    /** *Return true if it has length, otherwise false ***/
    const hasLength = (args) => args.length > 1;
    /** * Dispatch Redux action ***/
    const handleBodyChange = (e) => {
        dispatch(selectBody(getParsedData(e)));
        if (props.inlineFitmentYMM && props.handleVehicleBreadcrumb) {
            if (selectedBody) {
                props.handleVehicleBreadcrumb(getParsedData(e).BODY_TYPE_NAME, true);
            } else {
                props.handleVehicleBreadcrumb(getParsedData(e).BODY_TYPE_NAME);
            }
        }
    };
    const handleTrimChange = (e) => {
        dispatch(selectTrim(getParsedData(e)));
        if (props.inlineFitmentYMM && props.handleVehicleBreadcrumb) {
            props.handleVehicleBreadcrumb(getParsedData(e).SUBMODEL_NAME);
        }
    };
    const handleWheelChange = (e) => {
        dispatch(selectWheel(getParsedData(e)));
        if (props.inlineFitmentYMM && props.handleVehicleBreadcrumb) {
            props.handleVehicleBreadcrumb(getParsedData(e).WHEEL_BASE_OVERRIDE);
        }
    };
    const handleDriveChange = (e) => {
        dispatch(selectDrive(getParsedData(e)));
        if (props.inlineFitmentYMM && props.handleVehicleBreadcrumb) {
            if (selectedDrive) {
                props.handleVehicleBreadcrumb(getParsedData(e).DRIVE_TYPE_NAME, true);
            } else {
                props.handleVehicleBreadcrumb(getParsedData(e).DRIVE_TYPE_NAME);
            }
        }
    };
    const handleEngineChange = (e) => {
        dispatch(selectEngine(getParsedData(e)));
        if (props.inlineFitmentYMM && props.handleVehicleBreadcrumb) {
            props.handleVehicleBreadcrumb(getParsedData(e).ENGINE_LABEL);
        }
    };
    /** * Logic to show or hide dropdown ***/
    const showBodyDropDown = () => {
        //CHECKS if its inline fitment if so check if we need body to show fit
        if (props.inlineFitmentYMM) {
            return hasLength(props.bodyFitmentSelection) && props?.neededFitment?.includes("bodyStyle");
        }

        return hasLength(props.bodyFitmentSelection);
    };
    const showWheelDropDown = () => {
        if (inlineFitmentFlag) {
            //CHECKS if its inline fitment if so check if we need wheel to show fit
            if (props.inlineFitmentYMM) {
                return (
                    hasLength(props.wheelFitmentSelection) &&
                    (selectedBody !== EMPTY_STRING || !props?.neededFitment?.includes("bodyStyle")) &&
                    props?.neededFitment?.includes("wheelBase")
                );
            }
            return hasLength(props.wheelFitmentSelection) && selectedBody !== EMPTY_STRING && isFullVehicleDetails;
        } else {
            return hasLength(props.wheelFitmentSelection) && selectedBody !== EMPTY_STRING;
        }
    };

    const showTrimDropDown = () => {
        if (inlineFitmentFlag) {
            //CHECKS if its inline Trim if so check if we need body to show fit
            if (props.inlineFitmentYMM) {
                return (
                    hasLength(props.trimFitmentSelection) &&
                    (selectedBody !== EMPTY_STRING || !props?.neededFitment?.includes("bodyStyle")) &&
                    (selectedWheel !== EMPTY_STRING || !props?.neededFitment?.includes("wheelBase")) &&
                    props?.neededFitment?.includes("trim")
                );
            }
            return hasLength(props.trimFitmentSelection) && selectedBody !== EMPTY_STRING && isFullVehicleDetails;
        } else {
            return (
                hasLength(props.trimFitmentSelection) && selectedBody !== EMPTY_STRING && selectedWheel !== EMPTY_STRING
            );
        }
    };

    const showDriveDropDown = () => {
        if (inlineFitmentFlag) {
            //CHECKS if its inline fitment if so check if we need drive to show fit
            if (props.inlineFitmentYMM) {
                return (
                    hasLength(props.driveFitmentSelection) &&
                    (selectedBody !== EMPTY_STRING || !props?.neededFitment?.includes("bodyStyle")) &&
                    (selectedWheel !== EMPTY_STRING || !props?.neededFitment?.includes("wheelBase")) &&
                    (selectedTrim !== EMPTY_STRING || !props?.neededFitment?.includes("trim")) &&
                    props?.neededFitment?.includes("driveType")
                );
            }
            return hasLength(props.driveFitmentSelection) && selectedBody !== EMPTY_STRING && isFullVehicleDetails;
        } else {
            return (
                hasLength(props.driveFitmentSelection) &&
                selectedBody !== EMPTY_STRING &&
                selectedWheel !== EMPTY_STRING &&
                selectedTrim !== EMPTY_STRING
            );
        }
    };
    const showEngineDropDown = () => {
        if (inlineFitmentFlag) {
            //CHECKS if its inline fitment if so check if we need engine to show fit
            if (props.inlineFitmentYMM) {
                return (
                    hasLength(props.engineFitmentSelection) &&
                    (selectedBody !== EMPTY_STRING || !props?.neededFitment?.includes("bodyStyle")) &&
                    (selectedWheel !== EMPTY_STRING || !props?.neededFitment?.includes("wheelBase")) &&
                    (selectedTrim !== EMPTY_STRING || !props?.neededFitment?.includes("trim")) &&
                    (selectedDrive !== EMPTY_STRING || !props?.neededFitment?.includes("driveType")) &&
                    props?.neededFitment?.includes("engine")
                );
            }
            return hasLength(props.engineFitmentSelection) && selectedBody !== EMPTY_STRING && isFullVehicleDetails;
        } else {
            return (
                hasLength(props.engineFitmentSelection) &&
                selectedBody !== EMPTY_STRING &&
                selectedWheel !== EMPTY_STRING &&
                selectedTrim !== EMPTY_STRING &&
                selectedDrive !== EMPTY_STRING
            );
        }
    };

    /** * YMM Data that is required to render the Dropdown ***/
    const YMMData = [
        {
            id: YMM_BODY,
            title: selectedBody || (props.inlineFitmentYMM ? t(YMM_CHOOSE_BODY) : formatTitle(LABEL_YMM_SELECT_BODY)),
            options: props.bodyFitmentSelection,
            onClick: handleBodyChange,
            show: showBodyDropDown()
        },
        {
            id: YMM_WHEEL,
            title: selectedWheel || formatWheelTitle(LABEL_YMM_SELECT_WHEEL),
            options: props.wheelFitmentSelection,
            onClick: handleWheelChange,
            show: showWheelDropDown()
        },
        {
            id: YMM_TRIM,
            title: selectedTrim || (props.inlineFitmentYMM ? t(YMM_CHOOSE_TRIM) : formatTitle(LABEL_YMM_SELECT_TRIM)),
            options: props.trimFitmentSelection,
            onClick: handleTrimChange,
            show: showTrimDropDown()
        },
        {
            id: YMM_DRIVE,
            title:
                selectedDrive || (props.inlineFitmentYMM ? t(YMM_CHOOSE_DRIVE) : formatTitle(LABEL_YMM_SELECT_DRIVE)),
            options: props.driveFitmentSelection,
            onClick: handleDriveChange,
            show: showDriveDropDown()
        },
        {
            id: YMM_ENGINE,
            title:
                selectedEngine ||
                (props.inlineFitmentYMM ? t(YMM_CHOOSE_ENGINE) : formatTitle(LABEL_YMM_SELECT_ENGINE)),
            options: props.engineFitmentSelection,
            onClick: handleEngineChange,
            show: showEngineDropDown()
        }
    ];
    return (
        <>
            <InlineSharedDropdown
                ymmData={YMMData}
                inlineFitmentYMM={props.inlineFitmentYMM}
                catEntryID={props.catEntryID}
                steps={props.steps}
                neededFitment={props?.neededFitment}
            />
            {!props.autoComplete && (
                <SharedButton
                    id="acYmmSearchSubmit"
                    onClick={() => browserHistory.push(searchURL)}
                    disabled={searchURL === EMPTY_STRING}
                    buttonText="LABEL_VIN_SEARCH"
                />
            )}
        </>
    );
};

InlineSharedYMMDropdown.propTypes = {
    resetVehicleConfig: propTypes.func,
    resetVehicleSelectState: propTypes.func,
    make: propTypes.any,
    model: propTypes.any,
    year: propTypes.any,
    modelId: propTypes.any,
    makeId: propTypes.any,
    bodyFitmentSelection: propTypes.any,
    driveFitmentSelection: propTypes.any,
    wheelFitmentSelection: propTypes.any,
    engineFitmentSelection: propTypes.any,
    handleCloseModal: propTypes.func,
    handleAddToCartPDError: propTypes.func,
    handleAddToCartSRError: propTypes.func,
    trimFitmentSelection: propTypes.any,
    steps: propTypes.number,
    autoComplete: propTypes.bool
};

export default InlineSharedYMMDropdown;
