import React from 'react';
import {Translation, Trans, useTranslation} from 'react-i18next';
import Button from '../../../../shared/Button/Button';
import {formatNumber} from "../../../../shared/Internationalization/FormattedNumbers";
import {useFooterDisclaimerLabel} from "../../../App/Footer/FooterDisclaimerLabel";

const OrderConfirmationGuest = (props) => {
    let {rewardsPoints, brand, label, handleSubmit, isLoading} = props;
    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;
    const earnDisclaimer =process.env.REACT_APP_DISCLAIMER_EARN_NAME;
    const earnLabel = useFooterDisclaimerLabel(earnDisclaimer);

    const createMarkup = (text) => {
        return {
            __html: text
        };
    };

    if (rewardsPoints) {
        return (
            <Translation>
                {
                    (t) => (
                        <>
                            <b className="ac-confirm-loyalty">
                                <div className="ac-confirm-loyalty-bottom-margin" id="order-confirmation-rewards-guest" dangerouslySetInnerHTML={createMarkup(t("LABEL_CONFIRMATION_POTENTIAL_LOYALTY_USERS", {brand, label, rewardsPoints}))}/>
                            </b>
                            <div className="ac-confirm-loyalty-bottom-margin" id="order-confirmation-rewards-create-account" dangerouslySetInnerHTML={createMarkup(t("LABEL_CONFIRMATION_POTENTIAL_LOYALTY_GUEST1", {brand, label, earnLabel}))}/>
                            <Button
                                localeKey={t("LABEL_CONFIRMATION_POTENTIAL_LOYALTY_GUEST3")}
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                dataDtm={"order confirmation"}
                                dataDtm2={"additional info"}
                                id={"sign-in-or-register-button"}
                            />
                        </>
                    )
                }

            </ Translation>
        )
    } else {
        return null
    }
}

export default OrderConfirmationGuest;
