import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import AppSettings from '../../../../core/AppSettings';
import { LIST_DATA_ASYNC, RESET_PAGE_NUMBER } from '../../SearchService/SearchDataServiceActionTypes';

const SortBySelect = (props) => {
    const sortDefaultValue = sessionStorage.getItem("SEARCH_RESULTS_FILTERS") ? sessionStorage.getItem("SEARCH_RESULTS_FILTERS") : "Top";
    return (
        <Translation>
            {(t) => (
                <div className="ac-sort-wrapper gmit-gf-request-type">
                    <div className="ac-select-wrapper gmit-gf-dropdown">
                        <MediaQuery query="(min-width: 60em)">
                            <h5 className="ac-select-title">{t("LABEL_COMMON_SORT_BY")}:</h5>
                        </MediaQuery>
                        <div className="ac-vehicle-select-fields">
                            <form id="sortBy">
                                <select className="q-form-dropdown stat-dropdown" id="sort-by-dropdown" value={sortDefaultValue} onChange={(e) => props.fireEvent(e,props.searchData)} data-dtm="all products:sort">
                                    <option value="Top">{t("LABEL_SEARCH_POPULAR")}</option>
                                    <option value="2">{t("LABEL_SEARCH_NAME")}: {t("LABEL_SEARCH_A_Z")}</option>
                                    <option value="5">{t("LABEL_SEARCH_NAME")}: {t("LABEL_SEARCH_Z_A")}</option>
                                    {/* Bug 1906944 - value varies between T1 and T3 */}
                                    <option value={AppSettings.isT3 ? "3" : "6"}>{t("LABEL_SEARCH_PRICE")}: {t("LABEL_SEARCH_LOW_TO_HIGH")}</option>
                                    <option value={AppSettings.isT3 ? "4" : "7"}>{t("LABEL_SEARCH_PRICE")}: {t("LABEL_SEARCH_HIGH_TO_LOW")}</option>
                                </select>
                            </form>
                        </div>
                    </div>
                </div>)
            }
        </Translation>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        fireEvent: (e, searchData) => {
            const el = e.target.value;
            sessionStorage.setItem("SEARCH_RESULTS_FILTERS", el);
            dispatch({
                type: RESET_PAGE_NUMBER
            })
            dispatch({
                type: LIST_DATA_ASYNC,
                payload: {
                    year: searchData.searchParams.year,
                    make: searchData.searchParams.make,
                    model: searchData.searchParams.model,
                    categoryId: searchData.searchParams.categoryId,
                    orderBy: el,
                    searchTerm: searchData.searchParams.searchTerm
                }
            })
        }
    }
};

const mapStateToProps = state => {
    return {
        searchData: state.SearchServiceReducer
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SortBySelect);

