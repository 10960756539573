/** COMPONENT NAME : CARTIMAGEANDDESCRIPTION (Image and Description for Shopping Cart Page) **/

/** IMPORT STATEMENT **/
import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppSettings from '../../../../../../core/AppSettings';

/*** COMPONENT **/
const ImageAndDescription = (props) => {

    /** VARIABLES **/
    const data = props.data;
    const partName = data.partName;
    const imageSource = data.imageName;
    const seoUrl = data.seoUrl;
    const prodType = data.productType;


    const vin = props.vin;

    const vehicle = props.vehicleInfo;
    const year = vehicle.year;
    const make = vehicle.make;
    const model = vehicle.model;

    // For use in building the URL
    const bodyStyle = vehicle.bodyStyle; // Text name
    const wheelBase = vehicle.wheelBase; // Text name
    const trim = vehicle.trim; // Text name
    const driveType = vehicle.driveType; // Text name
    const engineBase = vehicle.engineBase; // Text name

    const modelId = vehicle.modelId; // ID number
    const bodyStyleId = vehicle.bodyStyleId; // ID number
    const wheelBaseId = vehicle.wheelBaseId; // ID number
    const trimId = vehicle.trimId; // ID number
    const driveTypeId = vehicle.driveTypeId; // ID number
    const engineBaseId = vehicle.engineBaseId; // ID number
    const bodyNumDoorsId = vehicle.bodyNumDoors;

    let productDetailsUrl = '';

    /** BUILDING SEO URL FOR PRODUCT DETAILS PAGE **/
    if (AppSettings.routePaths) {
        productDetailsUrl = `${AppSettings.routePaths['product']}/${seoUrl}?`;
        if (vin) {
            productDetailsUrl += `vin=${vin}`
        } 
        else {
            productDetailsUrl += `year=${year}&make=${make}&model=${model}`

            // check for vehicle config params
            modelId ? productDetailsUrl += `&modelId=${modelId}` : '';
            bodyStyle ? productDetailsUrl += `&body=${bodyStyle}` : '';
            bodyStyleId ? productDetailsUrl += `&bodyId=${bodyStyleId}` : '';
            wheelBase ? productDetailsUrl += `&wheel=${wheelBase}` : '';
            wheelBaseId ? productDetailsUrl += `&wheelId=${wheelBaseId}` : '';
            trim ? productDetailsUrl += `&trim=${trim}` : '';
            trimId ? productDetailsUrl += `&trimId=${trimId}` : '';
            driveType ? productDetailsUrl += `&drive=${driveType}` : '';
            driveTypeId ? productDetailsUrl += `&driveId=${driveTypeId}` : '';
            engineBase ? productDetailsUrl += `&engine=${engineBase}` : '';
            engineBaseId ? productDetailsUrl += `&engineId=${engineBaseId}` : '';
            bodyNumDoorsId ? productDetailsUrl += `&bodyNumDoors=${bodyNumDoorsId}&bodyNumDoorsId=${bodyNumDoorsId}`
                : '';

        }
        if (AppSettings.isT3) {
            //Prepend dealer name and append bac for T3 sites
            productDetailsUrl = `${AppSettings.dealerName}${productDetailsUrl}&bac=${AppSettings.bac}`;
        }
    }

    const takeToProdDetails = () => {
        window.location.href = productDetailsUrl;
    };

    return (
        <Translation>
            {
                (t) => (<div className="ac-imageDescWrapper">
                    <div className={"small-5 medium-6 large-5 xlarge-5 columns ac-nonPadding-x ac-productImage acc-mini-cart-row"}>
                        <a href={productDetailsUrl} alt={productDetailsUrl}>
                            <img className="ac-productImage stat-image-link" data-dtm="shopping cart" src={imageSource} alt="No Image Available" />
                        </a>
                    </div>
                    <div className={"ac-partDeliveryDesc small-7 medium-6 large-7 xlarge-7 columns ac-nonPadding-x acc-mini-cart-row"}>
                        <div className="ac-prodDescription">
                            <span className="q-descriptive2 stat-text-link" data-dtm="shopping cart" onClick={takeToProdDetails}>{partName}</span>
                        </div>
                    </div>

                </div>
                )
            }
        </Translation>
    )
};

/**  PROP TYPES VALIDATION **/
ImageAndDescription.propTypes = {
    data: PropTypes.shape({

        seoUrl: PropTypes.string.isRequired,
        partNumber: PropTypes.string.isRequired,
        partName: PropTypes.string.isRequired,
        imageSource: PropTypes.string,
        productId: PropTypes.string.isRequired,
        productType: PropTypes.string.isRequired
    }),
    vehicleInfo: PropTypes.shape({        
        wheelBase:PropTypes.string,
        driveType:PropTypes.string,
        bodyStyle:PropTypes.string,
        trim:PropTypes.string,
        engineBase:PropTypes.string,
        wheelBaseId: PropTypes.string,
        driveTypeId: PropTypes.string,
        bodyStyleId: PropTypes.string,
        trimId: PropTypes.string,
        engineBaseId: PropTypes.string,
        makeId: PropTypes.string,
        modelId: PropTypes.string,
        ymm: PropTypes.string.isRequired,
        year: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number
        ]),
        make: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired
    }),
    vin: PropTypes.string
};

export default ImageAndDescription
