import React from 'react';
import ReactResponsiveModal from 'react-responsive-modal';
import { Translation } from 'react-i18next';
import propTypes from 'prop-types';

import './Modal.scss';

/**
 * Adds a close icon to the modal and optional quantum style header. Takes all the same props as react-responsive-modal
 * (passes through all props to react-responsive-modal).
 */
const Modal = (props) => {
  const { marginTop, children, classNames, showCloseIcon, headerLabel, dataClass, dataDtm, dataDtm2, closeIconSize, ...otherProps } = props;
  const modalStyles = {
      overlay: {
          marginTop: marginTop,
      }
  }
  // Makes the close icon size changeable
  let actualCloseIconSize = 30;
  if (closeIconSize !== undefined) {
    actualCloseIconSize = closeIconSize;
  }
  if (showCloseIcon !== false) {
    return (
        <ReactResponsiveModal
            styles={modalStyles}
            classNames={{ 'closeButton': 'ac-modal-close-btn', ...classNames }}
            closeIconSize={actualCloseIconSize}
            closeIconSvgPath={<svg viewBox="0 0 32 32" className={dataClass} id="closeModalBtn" data-dtm={dataDtm} data-dtm2={dataDtm2}>
                <title>
                    <Translation>
                        {(t) => t('LABEL_CLOSE')}
                    </Translation>
                </title>
                <path fill="#060505" clipRule="evenodd" d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16   c8.836,0,16-7.163,16-16C32,7.163,24.836,0,16,0z M16,30C8.268,30,2,23.732,2,16C2,8.268,8.268,2,16,2s14,6.268,14,14   C30,23.732,23.732,30,16,30z" fillRule="evenodd" />
                <path fill="#060505" clipRule="evenodd" d="M22.729,21.271l-5.268-5.269l5.238-5.195   c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.39-1.034-0.39-1.428,0l-5.231,5.188l-5.309-5.31c-0.394-0.396-1.034-0.396-1.428,0   c-0.394,0.395-0.394,1.037,0,1.432l5.301,5.302l-5.331,5.287c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.429,0   l5.324-5.28l5.276,5.276c0.394,0.396,1.034,0.396,1.428,0C23.123,22.308,23.123,21.667,22.729,21.271z" fillRule="evenodd" />
            </svg>}

            {...otherProps}
        >
            <div className="ac-modal-body">
                {/* OPTIONAL MODAL HEADER DISPLAYED INLINE WITH CLOSE ICON (FROM MRM STYLE GUIDE: CONTENT MODAL) */}
                {headerLabel &&
                <h5 className="q-headline2 q-bold2 ac-modal-header">
                    <Translation>
                        {(t) => t(headerLabel)}
                    </Translation>
                </h5>
                }
                {children}
            </div>
        </ReactResponsiveModal>
    );
  }

  else {
    return (
        <ReactResponsiveModal
            classNames={{ 'closeButton': 'ac-modal-close-btn',
                ...classNames }}
            showCloseIcon={false}
            {...otherProps}
            styles={modalStyles}
        >
            <div className="ac-modal-body">
                {children}
            </div>
        </ReactResponsiveModal>
    );
  }

};



Modal.propTypes = {
  headerLabel: propTypes.string,
  showCloseIcon: propTypes.bool
}


export default Modal;
