/** COMPONENT NAME : MINICARTWRAPPER (WRAPPING EVERYTHING TOGETHER) ***/

/** IMPORT STATEMENT **/
import React, { useEffect, useState } from 'react';
import AppSettings from "../../../../core/AppSettings";
import CartOrderLineItemHeader from '../../../../shared/CartComponents/ItemHeader/ItemHeader'
import SharedButton from "../../../../shared/Button/SharedButton";
import MiniCartLineItem from "./MiniCartLineItem/MiniCartLineItem";
import SubtotalComponent from '../../../../shared/CartComponents/Subtotal/SubtotalComponent';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import ErrorMessage from '../../../../shared/ErrorMessage/ErrorMessage';
import accHistory from "../../History";
import store from "../../../../core/Redux/Store";

/**
 * @param {*} props
 *  data: miniCartResponse state
 * @return {div} Mini Cart Wrapper that displays the items in a user's cart with similar format to Cart Wrapper
 */

/** COMPONENT **/
const MiniCartWrapper = (props) => {
    const handleGoToCart = () => {
        store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: false });
        if (AppSettings.isT3) {
            const buildUrl = AppSettings.dealerName + "/cart?bac=" + AppSettings.bac;
            accHistory.push(buildUrl);
        } else {
            accHistory.push("/cart");
        }
    };

    /** VARIABLES **/
    const orderInCart = props.data;
    const orderInfo = orderInCart.data;
    const totalSubTotal = orderInfo.subtotal;
    const recordSetTotal = orderInfo.recordSetTotal;
    const { t } = useTranslation();
    let [itemsQuantity, setItemsQuantity] = useState(0);
    const cartResponse = useSelector((state) => state.CartReducer);
    let cartError = cartResponse.error;
    let cartErrorMessage = cartResponse.errorMessage;

    /** PREPARE CART DATA **/
    let vehicleData = '';
    if (orderInfo) {
        vehicleData = orderInfo.vehicles.map((items, index) => {
            return {
                vehicleIndex: index + 1,
                vehicleInformation: items,
                items: items.orderItems,
                vin: items.vinNumber
            }
        })
    }

    //Total items for all vehicles (each vehicle has its own count)
    useEffect(() => {
        vehicleData.map((item, index) => {
            setItemsQuantity((itemsQuantity += item.vehicleInformation.orderItems.length));
        });
    }, []);

return (
        <div id="minicart-modal acc-minicart-modal">
            {cartError && <ErrorMessage icon={true} message={cartErrorMessage} />}
            <div className="mini-cart-title">
                {t("LABEL_MINI_CART_TITLE")}
            </div>
            <div className="ac-shoppingCartWrapper-modal">
            <div className={itemsQuantity > 1 ? "ac-shoppingCartWrapper-scroll" : "ac-shoppingCartWrapper"}>
                {
                /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY VEHICLE INFORMATION  **/
                vehicleData.map((item, index) => {
                    return <div className="row" key={index}>
                        <div >
                            <CartOrderLineItemHeader data={item.vehicleInformation} count={item.vehicleIndex} vinNumber={item.vin} miniCart={true}/>
                        </div>
                        <div className="row">
                            {
                                /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY EACH LINE ITEM **/
                                item.items && item.items.map((subItem, index) => {
                                    return <div key={subItem.productId} className="ac-shoppingLineItemIndex">
                                        {
                                            /** ADDING HORIZONTAL LINE IF THERE ARE IS MORE THAN ONE ITEM **/
                                            index > 0 && <hr />
                                        }
                                        <MiniCartLineItem orderId={orderInfo.orderId} orderInfo={orderInfo} data={subItem} vin={item.vin} vehicleInformation={item.vehicleInformation} mainItem={subItem} items={subItem} component="miniCartWrapper" />

                                            </div>
                                        })
                                    }
                                </div>

                            </div>
                        })
                    }
                </div>
                <div className="row">
                    <div className="small-12 medium-12 large-12 xlarge-12 ac-nopadding-x">
                        {/** SHOPPING CART ITEM SUBTOTAL **/}
                        <SubtotalComponent subTotal={totalSubTotal} />
                    </div>
                </div>

                <div className="row">
                    <div className="small-12 medium-12 large-12 xlarge-12 ac-nopadding-x">
                        {/** SHOPPING CART  BUTTONS **/}
                        <SharedButton
                        id="view-cart-button"
                        className="minicart-view-cart gb-primary-button"
                        analyticsTag="shopping cart"
                        buttonText="VIEW CART"
                        onClick={handleGoToCart}
                        ></SharedButton>
                    </div>
                </div>
            </div>
        </div>
    )
};

/** PROP TYPES VALIDATION **/
MiniCartWrapper.proptype = {
    data: PropTypes.object.isRequired
};
export default MiniCartWrapper
