import React from 'react';
import i18next from 'i18next';
import AppSettings from '../../../core/AppSettings';

const headerClassName = () => {
    switch (AppSettings.currentSite.label) {
        case 'Chevrolet':
            return 'q-headline3';
        case 'Buick':
            return 'q-headline3';
        case 'Cadillac':
            return 'q-headline1';
        default:
            return 'q-headline3';
    }
}

const ViewOnVehicleLabel = (props) => {
    return (
        <h1 className={headerClassName()} >
            {i18next.t("LABEL_VIEW_ON_VEHICLE_CURRENTLY_VIEWING")}: {props.productName}
        </h1>
    );
}

export default ViewOnVehicleLabel;