import React from 'react';
import { Translation, Trans } from "react-i18next";

const CheckBox = (props) => {

  return (
    <Translation>{(t) => (
      <div className="ac-checkedBox">
        {!props.input &&
          <label className="ac-font-label ac-footnote q-link small-margin small-12 q-text q-body2">
            <input
              className={props.className ? props.className : ""}
              id={props.id}
              type="checkbox"
              checked={props.checked ? props.checked : false}
              onClick={props.onClick}
              onChange={props.onChange}
              data-dtm={props.dataDtmCheckBox} />
            <Trans i18nKey={props.localKey.toString()} values={{ brand: props.brand }}>
              By clicking the button, you agree to the
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[0] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[0] : null}>first thing</a>, and
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[1] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[1] : null}>second thing</a>, and
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[2] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[2] : null}>last thing</a>.
                    </Trans>
          </label>}

        {/* Redux-Form validation use */}
        {props.input &&
          <label className="ac-font-label ac-footnote q-link small-margin small-12 q-text q-body2 ac-not-bold-label-checkbox" >
            <input
              className={props.className ? props.className : ""}
              id={props.id}
              type="checkbox"
              data-dtm={props.dataDtmCheckBox}
              {...props.input} />
            <Trans i18nKey={props.localKey.toString()} values={{ brand: props.brand }}>
              By clicking the button, you agree to the
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[0] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[0] : null}>first thing</a>, and
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[1] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[1] : null}>second thing</a>, and
                      &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[2] : props.href} target={props.linkTarget} data-dtm={props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink[2] : null}>last thing</a>.
                    </Trans>
          </label>}
      </div>
    )}</Translation>
  )

}

export default CheckBox
