import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';

const FieldErrorMessage = (props) => {
    const showError = props.showError;

    if (showError === false) {
        return (null);
    }
    else {
        const errorMessage = props.message;

        return (
            <label className="error ac-error-label">
                <FontAwesomeIcon icon={faExclamationCircle}/>{errorMessage}
            </label>
        )
    }
}

export default FieldErrorMessage