import React, { useState } from "react";
import { AccordionItem, AccordionItemTitle, AccordionItemBody } from "react-accessible-accordion";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";

/**
 * Implementation of react-accessible-accordion on Category Landing Page.
 * Takes a category object and uses maincategory and subcategory information to render Tiles.
 * Tiles have a title(main category information) and a body that is expanded on click(subcategory information).
 * 
 * PROPS:
 * Category Object(required) : {
  "category": {
    "position": "3",
    "uniqueID": "12013",
    "mainCategory": "Electronics",
    "count": "17",
    "subCategories": [
      {
        "uniqueID": "12319",
        "label": "Audio",
        "count": "5",
        "parentCategoryID": "12013"
      },
      {
        "uniqueID": "160002",
        "label": "Battery Charger",
        "count": "4",
        "parentCategoryID": "12013"
      },
      {
        "uniqueID": "74602",
        "label": "Battery Jump Starter",
        "count": "3",
        "parentCategoryID": "12013"
      },
      {
        "uniqueID": "74565",
        "label": "Cameras",
        "count": "1",
        "parentCategoryID": "12013"
      },
      {
        "uniqueID": "97003",
        "label": "Personal Device Electronic Cable",
        "count": "4",
        "parentCategoryID": "12013"
      }
    ]
  }
 * 
 * closeAllCategories(required): Tells component whether to collapse all accordion tabs.
 * currentCategory: current Category that is expanded/active. Uses a categories unique id found in category object.
 * handleClick/handleSubClick/handleSubMainClick: Functions that handle behavior of clicking on subcategories and main categories. 
 *
 * @returns Accordion tabs for react-accessible-accordion
 */
export default function ClpCategoryItem(props) {
    const { category, closeAllCategories, currentCategory } = props;
    const isOpen = currentCategory === category.uniqueID;
    const { t } = useTranslation();
    const MAX_LENGTH_MOBILE = 4;
    const MAX_LENGTH_DESKTOP = 7;
    const maxSubcategoryLength = props.isMobile ? MAX_LENGTH_MOBILE : MAX_LENGTH_DESKTOP;
    const clpEnhancementsFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2207674_CLP_ENHANCEMENTS) === "true";

    const [showMore, setShowMore] = useState(false);

    function subMainHandler(mainCategory, id) {
        props.handleSubMainClickEvent(mainCategory, id);

        if (props.isModalOpen) {
            if (props.closeModalFunc) {
                props.closeModalFunc();
            }
        }
    }

    const subCategoryItems = () => {
        let subMainCategory = () => {
            return (
                <li
                    id={"clp-subcategories-all-subcat-" + category.mainCategory.replace(" & ", "").replace(" ", "")}
                    onClick={() => subMainHandler(category.mainCategory, category.uniqueID)}
                    className={
                        props.displayCategory === category.uniqueID
                            ? "q-text q-body2 ac-clp-chosen-category stat-text-link"
                            : "q-text q-body2 stat-text-link"
                    }
                    data-dtm={"shop by category"}
                >
                    {t("LABEL_COMMON_ALL") + " " + category.mainCategory}
                </li>
            );
        };

        // Group 1 contains the first 7 (Desktop) or 4 (Mobile) Sub-Categories
        const subCategoriesUnexpanded = category.subCategories.slice(0, maxSubcategoryLength).map((subCat, i) => {
            return (
                <li
                    id={"clp-subcategories-" + category.mainCategory.replace(" & ", "-")}
                    data-testid={"clp-subcategories-" + category.mainCategory.replace(" & ", "-")} // For Reasoning on why data-testid please look at CategorySelector.test.js
                    onClick={() => props.handleSubClickEvent(subCat.uniqueID)}
                    className={"q-text q-body2 stat-text-link"}
                    key={i}
                    data-dtm={"shop by category:" + category.mainCategory.replace("&", "and").replace("/", "-").trim()}
                >
                    {subCat.label} ({subCat.count})
                </li>
            );
        });

        const seeMoreToggle = (
            <li
                id={"clp-accordion-see-more-toggle-" + category.mainCategory}
                onClick={() => setShowMore(true)}
                className={"q-text q-body2 ac-accordion-length-toggle stat-text-link"}
                data-testid={"clp-see-more-toggle-" + category.mainCategory} // For Reasoning on why data-testid please look at CategorySelector.test.js
                data-dtm={"shop by category:" + category.mainCategory.replace("&", "and").replace("/", "-").trim()}
            >
                {t("LABEL_COMMON_SEE_MORE")}
            </li>
        );

        // Group 2 contains index 7 (desktop) or 4 (mobile) until the end of the subcategory list
        const subCategoriesExpanded = category.subCategories
            .slice(maxSubcategoryLength, category.subCategories.length)
            .map((subCat, i) => {
                return (
                    <li
                        id={"clp-subcategories-" + category.mainCategory.replace(" & ", "-")}
                        data-testid={"clp-subcategories-" + category.mainCategory.replace(" & ", "-")} // For Reasoning on why data-testid please look at CategorySelector.test.js
                        onClick={() => props.handleSubClickEvent(subCat.uniqueID)}
                        className={"q-text q-body2 stat-text-link"}
                        key={i}
                        data-dtm={
                            "shop by category:" + category.mainCategory.replace("&", "and").replace("/", "-").trim()
                        }
                    >
                        {subCat.label} ({subCat.count})
                    </li>
                );
            });

        const seeLessToggle = (
            <li
                id={"clp-accordion-see-less-toggle-" + category.mainCategory}
                onClick={() => setShowMore(false)}
                className={"q-text q-body2 ac-accordion-length-toggle stat-text-link"}
                data-testid={"clp-see-less-toggle-" + category.mainCategory} // For Reasoning on why data-testid please look at CategorySelector.test.js
                data-dtm={"shop by category:" + category.mainCategory.replace("&", "and").replace("/", "-").trim()}
            >
                {t("LABEL_COMMON_SEE_LESS")}
            </li>
        );

        return (
            <ul id="ac-clp-left-nav" className="ac-subcat-items ac-clp-accordion-body">
                {clpEnhancementsFlag && subMainCategory()}
                {subCategoriesUnexpanded}
                {clpEnhancementsFlag &&
                    category.subCategories.length > maxSubcategoryLength &&
                    !showMore &&
                    seeMoreToggle}
                {(!clpEnhancementsFlag || showMore) && subCategoriesExpanded}
                {clpEnhancementsFlag && showMore && seeLessToggle}
            </ul>
        );
    };

    if (category.mainCategory === "All Products") {
        return (
            <AccordionItem
                id="clp-item-button"
                expanded={!closeAllCategories && isOpen}
                uuid={category.position}
                className={
                    closeAllCategories
                        ? "q-expander stat-text-link"
                        : isOpen
                        ? "q-expander q-button-active stat-text-link"
                        : "q-expander stat-text-link"
                }
                data-dtm={"shop by category"}
            >
                <div
                    onClick={
                        category.mainCategory === "All Products"
                            ? () => props.handleSubClickEvent(category.uniqueID)
                            : () => {
                                  props.handleClickEvent(category.uniqueID, category.mainCategory);
                                  setShowMore(false);
                              }
                    }
                >
                    <AccordionItemTitle
                        id="clp-item-title"
                        className={
                            "allProductsButton ac-accordion-title ac-parent-expander q-headline q-expander-button"
                        }
                        data-dtm={"shop by category"}
                    >
                        <h5>
                            <span className="ac-category-link">
                                {category.mainCategory} ({category.count})
                            </span>
                        </h5>
                    </AccordionItemTitle>
                </div>
            </AccordionItem>
        );
    } else {
        return (
            <AccordionItem
                id="clp-item-button"
                expanded={!closeAllCategories && isOpen}
                uuid={category.position}
                className={closeAllCategories ? "q-expander" : isOpen ? "q-expander q-button-active" : "q-expander"}
            >
                <div
                    onClick={
                        category.mainCategory === "All Products"
                            ? () => props.handleSubClickEvent(category.uniqueID)
                            : () => {
                                  props.handleClickEvent(category.uniqueID, category.mainCategory);
                                  setShowMore(false);
                              }
                    }
                >
                    <AccordionItemTitle
                        id="clp-item-title"
                        className={
                            category.uniqueID === props.displayCategory
                                ? "ac-accordion-title q-headline q-expander-button stat-expand-icon active"
                                : "ac-accordion-title q-headline q-expander-button stat-expand-icon"
                        }
                        data-dtm={"shop by category"}
                    >
                        <h5>
                            <span className="ac-category-link">
                                {category.mainCategory} ({category.count})
                            </span>
                        </h5>
                    </AccordionItemTitle>
                </div>
                <AccordionItemBody id="clp-item-body">{subCategoryItems()}</AccordionItemBody>
            </AccordionItem>
        );
    }
}

ClpCategoryItem.propTypes = {
    category: propTypes.object.isRequired,
    closeAllCategories: propTypes.bool.isRequired,
    currentCategory: propTypes.any,
    handleClickEvent: propTypes.func.isRequired,
    handleSubMainClickEvent: propTypes.func.isRequired,
    handleSubClickEvent: propTypes.func.isRequired,
    isMobile: propTypes.bool,
    displayCategory: propTypes.any
};
