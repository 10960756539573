/*******
 * COMPONENT NAME : VIEW ON VEHICLE (VOV)
 * PAGES IN USE : SEARCH RESULT & PRODUCE DETAILS
 * Dependency: Two External Service - BYO (BUILD YOUR OWN) FROM Quantum Team for vehicle color configuration (1) and CGI
 * to get all images (2)
 *
*/

/*** IMPORT STATEMENT **/
import React, { Component } from "react";
import { connect } from "react-redux";
import i18next from 'i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import store from '../../core/Redux/Store';
import Modal from '../../shared/Modal/Modal';
import Spinner from "../Spinner/Spinner";
import VoVSlider from "./ViewOnVehicleComponents/VoVSlider/VoVSlider";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ViewOnVehicleLabel from './ViewOnVehicleLabel/ViewOnVehicleLabel';
import ViewOnVehiclePrice from './ViewOnVehiclePrice/ViewOnVehiclePrice';
import ViewOnVehicleToggle from './ViewOnVehicleToggle/ViewOnVehicleToggle';
import ViewOnVehicleButton from './ViewOnVehicleButton/ViewOnVehicleButton';
import { resetDataOnClose, vovDataAsync, vovDataInitialization } from "./ViewOnVehicleRedux/VoVActionCreator";

class ViewOnVehicle extends Component {

    constructor(props) {
        super(props);
        /** Setting up initial state of the modal **/
        this.state = {
            openCarousel: false
        };
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }
    /** Modal will close when user click the close icon **/
    closeModal() {
        this.setState({
            openCarousel: false
        })
        store.dispatch(resetDataOnClose());
    };

    /** Modal will open when user View On Vehicle Link or icon on the page **/
    openModal() {
        /** NON-ASYNC action dispatch to set props data to the reducer so VoVDataInitialization can use it */

        /** If there are more than one RPO, take the first matching RPO with cgi ***/
        const partsRPOObj = this.props.data.partsRPO;
        if(partsRPOObj.length > 1 ){
            const cgiPartsRPO = this.props.VoVReducerData.cgiPartsRPOList;
            for(let i=0;i< partsRPOObj.length;i++){
                if(cgiPartsRPO.includes(partsRPOObj[i])){
                    this.props.data.partsRPO = partsRPOObj[i];
                    break;
                }
            }
            store.dispatch(vovDataInitialization(this.props));
        }
        else {
            store.dispatch(vovDataInitialization(this.props));
        }

        /** Make a call to cgi, and set the response data on reducer */
        store.dispatch(vovDataAsync());
        /*** Open the modal ***/
        this.setState({
            openCarousel: true
        })
    }

    render() {
        const openCarousel = this.state.openCarousel;
        const voVReducerData = this.props.VoVReducerData;
        const productDetailsLink = this.props.data.productDetailsLink;
        const productName = this.props.data.productName;
        const installPrice = this.props.data.installPrice;
        const productPrice = this.props.data.productPrice;
        const addToCartData = this.props.data.addToCartData;
        const showError = voVReducerData.showError;
        const showSpinner = voVReducerData.showSpinner;

        return (
            <span className="ac-vov-link-wrapper ac-slider-parent">
                <span className="ac-vov-link q-text q-body1 ac-nomargin-bottom stat-text-link" onClick={this.openModal} data-dtm={productName}>
                    <FontAwesomeIcon icon={faEye} /> {i18next.t("LABEL_VIEW_ON_VEHICLE")}
                </span>
                {openCarousel &&
                    <Modal
                        open={openCarousel}
                        onClose={this.closeModal}
                        onOverlayClick={this.closeModal}
                        center
                        classNames={{ modal: 'ac-customVoVModal' }}>

                        {/* Display spinner while content is loading or until an error displays */}
                        {showSpinner && !showError && <Spinner />}

                        {/* Display modal content only if data is loaded correctly */}
                        {!showSpinner && !showError &&
                            <>
                                <VoVSlider dataDtm={productName} data={this.props.data} vovReducerData={voVReducerData} />

                                <div className="row ac-small-margintop">
                                    <div className="columns small-12 medium-12 large-8 xlarge-8">
                                        <div className="row">
                                            <ViewOnVehicleLabel productName={productName} />
                                        </div>
                                        <div className="row">
                                            {/* If there is an error while retrieving base image 
                                                (image without parts), hide the toggle */}
                                            {voVReducerData.showAccToggle &&
                                                <ViewOnVehicleToggle
                                                    dataDtm={productName}
                                                    isImageWithParts={voVReducerData.isImageWithParts} />}
                                        </div>
                                    </div>
                                    <div className="columns small-12 medium-12 large-4 xlarge-4">
                                        <div className="row">
                                            <ViewOnVehiclePrice
                                                installPrice={installPrice}
                                                productPrice={productPrice} />
                                        </div>
                                        <div className="row">
                                            <ViewOnVehicleButton
                                                productDetailsLink={productDetailsLink}
                                                addToCartData={addToCartData} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {/* Error Handling */}
                        {showError && <ErrorMessage icon={true} message={'LABEL_SERVER_ERROR_MSG'} />}
                    </Modal>
                }
            </span>
        );
    }

}
const mapStateToProps = (state) => {
    return {
        VoVReducerData: state.VoVReducer
    }
}
export default connect(mapStateToProps, null)(ViewOnVehicle)

