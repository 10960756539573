import * as type from "./BreadcrumbActionType";

export const setSearchParams = (params) => {
    const action = {
        type: type.SET_SEARCH_PARAMS,
        params: params
    }
    return action;
}

export const setAllCategories = (name, id) => {
    const action = {
        type: type.SET_ALL_CATEGORIES,
        name: name,
        id: id
    }
    return action;
}

export const setCategory = (name, id) => {
    const action = {
        type: type.SET_CATEGORY,
        name: name,
        id: id
    }
    return action;
}

export const setSubCategory = (name, id) => {
    const action = {
        type: type.SET_SUBCATEGORY,
        name: name,
        id: id
    }
    return action;
}

export const setPageName = (name) => {
    const action = {
        type: type.SET_PAGE_NAME,
        name: name
    }
    return action;
}

export const setAllProductsBreadcrumbFlag = (flag) => {
    const action = {
        type: type.PASS_ALL_PRODUCTS_BREADCRUMB_FLAG,
        isAllProductsBreadcrumbClicked: flag
    }
    return action;
}