import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as validate from '../../../../../shared/Form/FormValidate/FormValidate';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../core/i18n/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? 'gmit-gf-text-field error' : 'gmit-gf-text-field'}>
            {required == true && <label className="gmit-gf-label-text required">{label}</label>}
            {required != true && <label className="gmit-gf-label-text">{label}</label>}
            <input {...input} placeholder={label} type={type} className="stat-input-field" data-dtm="checkout step1"
                   data-dtm-2="exclude" {...otherProps} />
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />
                {error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)

const renderSelect = ({ input, label, required, meta: { touched, error, warning }, children }) => (
    <div className={touched && error ? 'gmit-gf-dropdown error' : 'gmit-gf-dropdown'}>
        {required == true && <label className="gmit-gf-label-text required">{label}</label>}
        {required != true && <label className="gmit-gf-label-text">{label}</label>}
        <div>
            <select {...input} className="q-dropdown q-form-dropdown stat-dropdown" id={label.replace(/ /g, '-') + "-dropdown"} data-dtm="checkout step1" data-dtm-2="exclude">
                {children}
            </select>
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />{error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)

const EditPersonalInfoForm = props => {
    const { handleSubmit, pristine, reset, submitting, stateList, initialValues } = props;

    return(
        <Translation>
            {
                (t)=>(
                    <div className="ac-phone-edit-form">
                        <div className="gmit-gf-request-type">
                            <div className="row">
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="firstname"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_FIRST_NAME")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        className="stat-input-field"
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="newpassword"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_LAST_NAME")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-6 grid-column-alignment-left columns">
                                    <Field
                                        name="phone"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_PHONE")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-6 grid-column-alignment-left columns">
                                    <Field
                                        name="phonetype"
                                        component={renderSelect}
                                        label="Phone Type"
                                        required={true}
                                        validate={[validate.required]}
                                    >
                                        <option hidden value="">Mobile</option>
                                        <option value="select" key="selectkey">Select</option>
                                        <option value="mobile" key="mobilekey">Mobile</option>
                                        <option value="home" key="homekey">Home</option>
                                        <option value="work" key="workkey">Work</option>
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="ac-phone-disclaimer">
                            <p className="q-body2">
                                {t("LABEL_PROFILE_PHONE_DISCLAIMER")}
                            </p>
                        </div>
                        <div className="ac-profile-add-phone small-12 grid-column-alignment-left columns ac-nopadding-x">
                            <a className="q-body2 ac-add-phone">{t("LABEL_PROFILE_ADD_NEW_PHONE")}</a>
                        </div>
                        <div className="ac-update-profile small-12 medium-12 large-3 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                            <button className="q-button q-primary-button gm-primary-btn" onClick={props.resetProfileToggle}>{t("LABEL_PROFILE_UPDATE_PROFILE")}</button>
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

export default withTranslation()(reduxForm({
    form: 'EditPersonalInfoForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(EditPersonalInfoForm))