import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import AppSettings from '../../../../core/AppSettings';
import store from '../../../../core/Redux/Store';
import MiniCartModal from './MiniCartModal';
import { useLocation } from "react-router-dom";

/**
 * @param {*} props
 *  state: authData for authentication status
 * @return {div} Mini Cart displays one of two icons - if there is items in the cart it returns icon with 
 * that quantity, otherwise icon has no number
 */

function MiniCart(props) {
    const CHECKOUT = useLocation().pathname.includes("checkout");
    const miniCartResponse = useSelector((state) => state.MiniCartReducer);
    const modalOpen = miniCartResponse.modalOpen;
    const miniCartCount = miniCartResponse.count;
    function setModalOpen() {
        let cartURL;
        if (AppSettings.isT3) {
            cartURL = AppSettings.dealerName + "/cart?bac=" + AppSettings.bac;
        } else {
            cartURL = "/cart";
        }
        CHECKOUT ? 
        window.location.href = cartURL :
        store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: !modalOpen });
    }

    useEffect(() => {
        const action = {
            type: 'LOAD_MINI_CART_DATA_ASYNC'
        }
        store.dispatch(action);
    }, [props.authData.userData]);

    if (miniCartCount > 0) {
        return (
            <div className="ac-header-minicart-container stat-icon-link" data-dtm="global nav">
                <div className="ac-header-cart stat-image-link">
                    <FontAwesomeIcon onClick={setModalOpen} icon="shopping-cart" />
                    <div className="ac-minicart-circle">
                        <div className="ac-minicart-font">{miniCartCount}</div>
                    </div>
                </div>
                <MiniCartModal data={miniCartResponse} modalOpen={modalOpen} count={miniCartCount} />
            </div>
        )
    } else {
        return (
            <div className="ac-header-minicart-container stat-icon-link" data-dtm="global nav">
                <div className="ac-header-cart shopping-icon stat-image-link">
                    <FontAwesomeIcon onClick={setModalOpen} icon="shopping-cart" className={AppSettings.isT3 ? "gm-ow-black-icon" : ""} />
                    <div className="ac-minicart-circle" style={{ visibility: 'hidden' }}></div>
                </div>
                <MiniCartModal data={miniCartResponse} modalOpen={modalOpen} count={miniCartCount} />
            </div>
        )
    }
}

/**************************************************************/
//          Map State to Props
/**************************************************************/
function mapStateToProps(state) {
    return {
        authData: state.Authentication
    }
}

export default connect(mapStateToProps)(MiniCart)
