import React from "react";
import { Marker } from "react-google-maps";
import PropTypes from "prop-types";

/**
 * @param {*} props
 * state // dealerizationState reducer states
 * dispatch // dealerizationState reducer state updater
 *
 * @param {*} data
 * dealer data for one dealer.
 */

const handleMarkerClick = (props, data) => {
    const mapArg = {
        coordinates: { lat: parseFloat(data.pos.lat), lng: parseFloat(data.pos.long) },
        googleMapURL: props.state.googleMapURL,
        zoom: 20
    };

    document.getElementById(data.id + " source-radio-option").scrollIntoView();

    props.dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
    props.dispatch({
        type: "UPDATE_SELECTED_SOURCE",
        selectedSource: data.id,
        selectedDealerAllData: props.state.selectedDealerAllData
    });
};

const MarkerComponent = (props) => {
    const customTitle =
        props.data.name +
        "\n" +
        props.data.addr1 +
        "\n" +
        props.data.city +
        ", " +
        props.data.state +
        "-" +
        props.data.zip;
    return (
        <Marker
            position={{ lat: parseFloat(props.data.pos.lat), lng: parseFloat(props.data.pos.long) }}
            title={customTitle}
            onClick={() => handleMarkerClick(props, props.data)}
            icon={`https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${props.count}|FF0000|000000`}
        />
    );
};

// ===== PropType Checking =============================================================
MarkerComponent.propTypes = {
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default MarkerComponent;
