/**COMPONENT NAME : CART BUTTONS (BUTTON FOR SHOPPING CART PAGE) **/
import React, { useState, useEffect } from "react";
import { Translation } from 'react-i18next';
import queryString from 'query-string';
import store from '../../core/Redux/Store';
import ErrorMessage from '../../shared/ErrorMessage/ErrorMessage';
import HeaderBetweenLines from '../../shared/Headers/HeaderBetweenLines/HeaderBetweenLines';
import { handleUserLogin } from "../../shared/Utils/Utils";
import accHistory from "../App/History";


const Roadblock = () => {

    const orderId = queryString.parse(location.search).orderId;

    // Check if authentication is expired
    let isAuthExpired = localStorage.getItem('authExpiredMsg') ? true : false;

    // Get user registration status
    const [isRegistered, setRegistrationStatus] = useState(false);
    // Get cart status (user should have at least one item in the cart to proceed)
    const [hasCartItems, setCartStatus] = useState(true);

    useEffect(() => {
        // listener used to get latest reducer data
        const subscribe = store.subscribe(() => {
            // check that registration and cart data have been fetched before getting data status
            setRegistrationStatus((store.getState().Authentication.registrationStatusFetched && store.getState().Authentication.registrationStatus == "R") ? true : false);
            setCartStatus((store.getState().MiniCartReducer.loaded && store.getState().MiniCartReducer.count < 1) ? false : true);
        });

        return subscribe;
    }, []);

    const azureLoginURL = () => {
        localStorage.removeItem('authExpiredMsg'); // session expired error messaging

        handleUserLogin('/checkout');
    }

    const secureGuestCheckout = () => {
        localStorage.removeItem('authExpiredMsg'); // session expired error messaging

        if (orderId) {
            accHistory.push('/checkout' + "?orderId=" + orderId)
        }
    };
    // If user navigates to this page and is already signed in and has items in their cart, 
    // redirect to checkout
    if (isRegistered && hasCartItems && orderId && !isAuthExpired) {
        window.location.href = `/checkout?orderId=${orderId}`;
    }
    // If there's no orderId (because user navigated to this page manually perhaps or it's removed from the URL), 
    // then send back to the cart page where they can retrieve one
    if (hasCartItems && !orderId) {
        window.location.href = '/cart';
    }
    // If user has no items in their cart, redirect to home page
    else if (!hasCartItems) {
        window.location.href = '/';
    }

    return (
        <Translation>{(t) => (
            <div className="ac-xlarge-margintop ac-small-marginbottom row columns q-gridbuilder gridfullwidth ac-large-sidepadding">
                {/* Auth Expired Message Banner */}
                {isAuthExpired &&
                    <div className="small-12 columns ac-small-marginbottom">
                        <ErrorMessage icon={true} message={localStorage.getItem('authExpiredMsg')} />
                    </div>
                }

                {/* Guest Checkout */}
                <div className="small-12 xlarge-5 q-grid-row-new-mobile columns ac-roadblock-option">
                    <h2 className="q-headline2 ac-small-marginbottom">{t("LABEL_CHECKOUT_GUEST_CHECKOUT")}</h2>
                    <div className="ac-roadblock-cta-button" id="ac-guest-checkout-button">
                        <button
                            className="q-button-small q-button q-primary-button stat-button-link"
                            onClick={secureGuestCheckout}
                            data-dtm="checkout"
                            data-dtm2="guest checkout">
                            {t("LABEL_CHECKOUT_CONTINUE_GUEST")} </button>
                    </div>
                </div>

                {/* OR */}
                <div className="small-12 xlarge-2 columns" id="ac-roadblock-or-divider">
                    <HeaderBetweenLines
                        localeKey={"LABEL_COMMON_VEHICLE_SELECT_OR"}
                        vertical={true}
                        horizontal={true} />
                </div>

                {/* Sign In */}
                <div className="small-12 xlarge-5 q-grid-row-new-mobile columns ac-roadblock-option">
                    <h2 className="q-headline2 ac-small-marginbottom">{t("LABEL_CHECKOUT_RETURNING_CUSTOMER")}</h2>
                    <div className="ac-roadblock-cta-button" id="ac-returning-customer-checkout-button">
                        <button
                            className="q-button-small q-button q-primary-button stat-button-link"
                            onClick={azureLoginURL}
                            data-dtm="checkout"
                            data-dtm2="returning customer">
                            {t("LABEL_COMMON_SIGN_IN")}</button>
                    </div>
                </div>
            </div>
        )}</Translation>
    )


};

export default Roadblock;
