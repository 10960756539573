import React, { useEffect, useState } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { reset } from 'redux-form';

import store from '../../core/Redux/Store';
import { useSelector } from "react-redux";

import AppSettings from '../../core/AppSettings';
import VehicleSelectContainer from '../../shared/VehicleSelect/VehicleSelectContainer';
import HomeCarouselContainer from './HomeCarousel/HomeCarouselContainer';
import HomeIntro from './HomeIntro/HomeIntro';
import HomeContentBottom from './HomeContentBottom/HomeContentBottom';
import AllCategories from '../AllCategories/AllCategories';
import { loadDealerPricing, titleSetUp, setBacIfT1 } from "../../shared/Utils/Utils";
import { resetVehicleSelectState } from '../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator';
import { resetVehicleConfig } from '../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator';
import FeaturedCategories from '../../shared/FeaturedCategories/FeaturedCategories';
import CurrentVehicle from './CurrentVehicle/CurrentVehicle';
import vaultConstants from '../../../config/vault_constants';

const Home = (props) => {
  let isHomePage = true;
  const [showCurrentVehicle, setShowCurrentVehicle] = useState(false);
  const vehicleInfoData = useSelector((state) => state.Session);
  const currentVehicleFlag = AppSettings.isLocalHost
    ? true
    : String(vaultConstants.FF_2237155_HOME_PAGE_CURRENT_VEHICLE) === "true";

  /** Setting up Page Title **/
  titleSetUp(props.t("LABEL_BREADCRUMB_HOME"));
  /*** T1 LocalStorage for campaignes  ***/
  setBacIfT1(AppSettings.bac)
  /*** Loading up Dealer Price for t3 ***/
  if (AppSettings.isT3) {
    loadDealerPricing();
  }

  useEffect(() => {
      //clears modal if they have it open and click back to home
      if (!vehicleInfoData.model){
        store.dispatch(resetVehicleSelectState());
        store.dispatch(resetVehicleConfig());
        store.dispatch(reset('VehicleSelectForm'));
      }
      if(vehicleInfoData.model && vehicleInfoData.vehicleConfig.engineId && !AppSettings.isT3 && currentVehicleFlag) {
        setShowCurrentVehicle(true);
      }
    }, [])

  useEffect(() => {
      return () => {
      // set vehicle select to initial state.
      // used to ensure no values remain in vehicle select when user
      // leaves home page
      const resetVehicleSelectStateData = resetVehicleSelectState();
      store.dispatch(resetVehicleSelectStateData);
      store.dispatch(resetVehicleConfig());
      }
    }, [])

    return (
      <div className="ac-home">
        <HomeCarouselContainer />

        <section className="ac-alt-bg">
          {vehicleInfoData.model && showCurrentVehicle
            ? <CurrentVehicle currentVehicle = {vehicleInfoData} setShowCurrentVehicle = {setShowCurrentVehicle} isHomePage = {isHomePage}/> 
              : <>
                  <HomeIntro/> 
                  <VehicleSelectContainer headerLabel={"LABEL_COMMON_VEHICLE_SELECT_HEADER"}/> 
                </> 
          }
        </section>
        <section className="ac-nomargin-bottom">
          <FeaturedCategories />
        </section>
        <section>
          <AllCategories />
        </section>
        <section>
          <HomeContentBottom />
        </section>
      </div>
    );
}

export default withTranslation()(withRouter(Home));
