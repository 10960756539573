const initialDealerLocatorStates = {
    modalOpen: false,
    sellerSource: "", // dealer/seller verbiage
    currentSourceName: "",
    selectedSource: "",
    searchType: "Zip", //Default Selection to Zip - User can choose to search by Zip, City, or Seller
    sourceResults: "",
    currentZip: "",
    currentSeller: "",
    currentCity: "",
    userInputZip: "",
    userInputSeller: "",
    userInputCity: "",
    prevSources: [],
    prevZip: "",
    prevSelectedSrc: "",
    listOfSellers: [], // radio button labels
    listOfAllSources: [], // formatted array for radio buttons
    selectedDealerAllData: "", // state for PD/PSR pages to refer to for selected dealer's info/flags
    partNumber: "",
    contractStatus: false,

    /* states for error handling/additional UI */
    showLocationError: false,
    showZipcodeError: false,
    showNameError: false,
    showCityError: false,
    zipErrorMsg: "",
    nameErrorMsg: "",
    cityErrorMsg: "",
    locationErrorMsg: "",
    showSaveError: false,
    showSpinner: false,
    preventClose: false,
    // FOR PD VIEW
    showMap: false, // == true if PD, == false if not PD
    dealerMapData: {
        coordinates: { lat: 37.0902, lng: -95.7129 },
        googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-adamopelag",
        zoom: 4
    }
};

const DealerizationReducer = (state = initialDealerLocatorStates, action) => {
    switch (action.type) {
        case "TOGGLE_CONTRACT": {
            return {
                ...state,
                contractStatus: action.contractStatus
            };
        }
        case "TOGGLE_PD_VIEW": {
            return {
                ...state,
                modalOpen: action.modalOpen,
                showMap: true,
                partNumber: action.partNumber
            };
        }
        case "UPDATE_MAP_DATA": {
            return {
                ...state,
                dealerMapData: action.dealerMapData
            };
        }
        case "SET_ZIP_ERROR_MSG":
            return {
                ...state,
                zipErrorMsg: action.zipErrorMsg
            };
        case "SET_CITY_ERROR_MSG":
            return {
                ...state,
                cityErrorMsg: action.cityErrorMsg
            };
        case "SET_NAME_ERROR_MSG":
            return {
                ...state,
                nameErrorMsg: action.nameErrorMsg
            };
        case "SET_LOCATION_ERROR_MSG":
            return {
                ...state,
                locationErrorMsg: action.locationErrorMsg
            };
        case "UPDATE_USER_INPUT_ZIP":
            return {
                ...state,
                userInputZip: action.userInputZip
            };
        case "UPDATE_USER_INPUT_SELLER":
            return {
                ...state,
                userInputSeller: action.userInputSeller
            };
        case "UPDATE_USER_INPUT_CITY":
            return {
                ...state,
                userInputCity: action.userInputCity
            };
        case "TOGGLE_SAVE_ERROR":
            return {
                ...state,
                showSaveError: action.showSaveError
            };
        case "UPDATE_CURRENT_SRC":
            return {
                ...state,
                currentSourceName: action.currentSourceName
            };
        case "UPDATE_SELLER_SOURCE":
            return {
                ...state,
                sellerSource: action.sellerSource
            };
        case "UPDATE_CURRENT_ZIP":
            return {
                ...state,
                currentZip: action.currentZip
            };
        case "UPDATE_CURRENT_SELLER":
            return {
                ...state,
                currentSeller: action.currentSeller
            };
        case "UPDATE_CURRENT_CITY":
            return {
                ...state,
                currentCity: action.currentCity
            };
        case "TOGGLE_SPINNER":
            return {
                ...state,
                showSpinner: action.showSpinner
            };
        case "TOGGLE_CLOSE":
            return {
                ...state,
                preventClose: action.preventClose
            };
        case "UPDATE_LIST_OF_ALL_SOURCES":
            return {
                ...state,
                listOfAllSources: action.listOfAllSources
            };
        case "UPDATE_SELECTED_SOURCE":
            return {
                ...state,
                selectedSource: action.selectedSource,
                selectedDealerAllData: action.selectedDealerAllData
            };
        case "UPDATE_LIST_OF_SELLERS":
            return {
                ...state,
                listOfSellers: action.listOfSellers
            };
        case "TOGGLE_ZIP_ERROR":
            return {
                ...state,
                showZipcodeError: action.showZipcodeError
            };
        case "TOGGLE_CITY_ERROR":
            return {
                ...state,
                showCityError: action.showCityError
            };
        case "TOGGLE_NAME_ERROR":
            return {
                ...state,
                showNameError: action.showNameError
            };
        case "TOGGLE_DEALER_MODAL":
            return {
                ...state,
                modalOpen: action.modalOpen,
                showMap: false
            };
        case "TOGGLE_LOCATION_ERROR":
            return {
                ...state,
                showLocationError: action.showLocationError
            };
        case "SET_PREV_ZIP":
            return {
                ...state,
                prevZip: action.prevZip
            };
        case "SET_PREV_SRC":
            return {
                ...state,
                prevSelectedSrc: action.prevSelectedSrc
            };
        case "SET_PREV_SOURCES":
            return {
                ...state,
                prevSources: action.prevSources
            };
        case "RESET_DEALER_DATA":
            return initialDealerLocatorStates;
        case "RESET_DEALER_ERRORS":
            return {
                ...state,
                showLocationError: false,
                showZipcodeError: false,
                showNameError: false,
                showCityError: false
            };
        case "RESET_DEFAULT_ZIP":
            return {
                ...initialDealerLocatorStates,
                sellerSource: state.sellerSource,
                partNumber: state.partNumber
            };
        case "UPDATE_SEARCH_TYPE":
            return {
                ...state,
                searchType: action.searchType
            };
        case "SOURCE_RESULTS":
            return {
                ...state,
                sourceResults: action.sourceResults
            };
        default:
            return state;
    }
};

export { initialDealerLocatorStates, DealerizationReducer };
