import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SharedRadioButton from "../../../../shared/RadioButton/SharedRadioButton";
import Spinner from "../../../../shared/Spinner/Spinner";
import { Row, Col } from "react-bootstrap";
import { formattedCurrency } from "../../../../shared/Internationalization/FormattedNumbers";
import AppSettings from "../../../../core/AppSettings";
/**
 * <ListOfSellersSection/>
 * List of dealers/sellers will be rendered as radio button(s) with their address
 * and whatever special features they have (e.g, Participates in My <Brand> Rewards, etc.)
 *
 * @param {*} props
 *    dispatch: reducer state updater function
 *    state: state of the component from reducer
 *
 * @returns {Object} a scrollable <div> with a list of sellers near the user.
 *  regular: has radio buttons. no price.
 *  map: has pins matching the one(s) on the Google map and a border when clicked/active. has price.
 */

export default function ListOfSellersSection(props) {
    const { t } = useTranslation();
    const SPINNER_STATUS = props.state.showSpinner;
    const SELLER_ADDR_LABELS = props.state.listOfSellers;
    const UNFORMATTED_SOURCES = props.state.listOfAllSources;
    const MAP_FLAG = props.mapFlag;
    const marker = (count) => `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${count}|FF0000|000000`;
    const selectASrcStyle = SPINNER_STATUS
        ? "prt-dealerization-select-src-section spinner-view"
        : "prt-dealerization-select-src-section stat-radio";

    /**
     * sets current dealer for radio buttons
     * @param {String} id gets the id of the selected source.
     */
    const handleOnClick = (id) => {
        props.dispatch({
            type: "UPDATE_SELECTED_SOURCE",
            selectedSource: id,
            selectedDealerAllData: props.state.selectedDealerAllData
        });
    };

    /**
     * sets current selected dealer for map view
     * @param {Object} dealer dealer information
     */
    const handlePinClick = (dealer) => {
        props.dispatch({
            type: "UPDATE_SELECTED_SOURCE",
            selectedSource: dealer.id,
            selectedDealerAllData: props.state.selectedDealerAllData
        });
        const mapArg = {
            coordinates: { lat: parseFloat(dealer.pos.lat), lng: parseFloat(dealer.pos.long) },
            googleMapURL: props.state.googleMapURL,
            zoom: 20
        };

        props.dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
    };

    //selectedSource normal format is "key_bac", but we don't want to compare using key when reading from cookie because dealer list might update, so instead only compare bacs.
    const compareSources = (id) => {
        return props.state.selectedSource.includes("_")
            ? props.state.selectedSource.split("_")[1] === id.split("_")[1]
            : props.state.selectedSource === id.split("_")[1];
    };
    /**
     * Format dealer list to be rendered for the map view. Include price.
     */
    const createMapList = UNFORMATTED_SOURCES.length
        ? UNFORMATTED_SOURCES.map((item, index) => {
              const count = index + 1;
              const brand = item.vendorMake.charAt(0).toUpperCase() + item.vendorMake.slice(1);
              const rewardsLabel = t("LABEL_DEALER_LOCATOR_PARTICIPATES_IN_REWARDS", { brand: brand });

              return (
                  <React.Fragment key={index}>
                      <div
                          id={item.id + " source-radio-option"}
                          className={"source-radio-labels prt-map-version " + (compareSources(item.id) && "selected") + " stat-text-link"}
                          data-dtm="modal:dealer results"
                          onClick={() => handlePinClick(item)}
                      >
                          <Row className={"dealer-info-select-src-section"}>
                              <Col md={3} sm={2} className={"pin-right-align"}>
                                  <img src={marker(count)} alt={`dealer location pin icon ${count}`} />
                              </Col>
                              <Col md={6} sm={7} className={"src-info-dealer"}>
                                  <div>
                                      <b> {item.dealerName} </b>
                                      <p>{item.addr1}</p>
                                      <p>
                                          {t("LABEL_COMMON_ADDRESS_FORMAT", {
                                              2: item.city,
                                              3: item.state,
                                              4: item.zip
                                          })}
                                      </p>
                                      {item.rewardsProgram && <p className="prt-source-feature-text">{rewardsLabel}</p>}
                                  </div>
                              </Col>
                              <Col md={3} sm={2} className="prt-miles-and-price">
                                  <p>
                                      <b>
                                          {item.partInfo &&
                                              item.partInfo.price &&
                                              formattedCurrency(AppSettings.currency, parseFloat(item.partInfo.price))}
                                      </b>
                                  </p>
                                  <p>{t("LABEL_DEALER_LOCATOR_X_MI", { num: item.distance })}</p>
                              </Col>
                          </Row>
                      </div>
                  </React.Fragment>
              );
          })
        : null;

    /**
     * Format array that can be used for the shared radio button component
     * based off of the div labels created by createSrcRadioLabels()
     */
    const createSrcRadioBtns =
          
        SELLER_ADDR_LABELS.length &&
        SELLER_ADDR_LABELS.map((source) => {
            return {
                handleClick: () => handleOnClick(source.id),
                checked: compareSources(source.id),
                disabled: false,
                label: source.label,
                handleOnChange: () => {
                    return false;
                },
                id: source.id
            };
        });

    // final component UI render
    return (
        <div className={selectASrcStyle}>
            {!SPINNER_STATUS && !SELLER_ADDR_LABELS.length && (
                <div className="prt-empty-list-messaging"> 
                    {t("LABEL_DEALER_LOCATOR_EMPTY_SRC_LIST", { source: props.state.sellerSource })}
                </div>
            )}
            {SPINNER_STATUS ? (
                <Spinner />
            ) : MAP_FLAG ? (
                createMapList
            ) : (
                createSrcRadioBtns && <SharedRadioButton objectLabel={true} radioButtonProps={createSrcRadioBtns} />
            )}
        </div>
    );
}

/* proptype checking */
ListOfSellersSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
