/*** DEALER LOCATOR SERVICE ***/

/*** IMPORT STATEMENT **/
import axios from 'axios';
import AppSettings from '../../../core/AppSettings'
import {postAuthenticated} from "../../Authenticator/Authenticator";
import {call} from 'redux-saga/effects';



/** REST CALL TO GET THE DEALER LIST DATA ***/
export const getDealerListData = (params)=>{
    const zipCode = params.payload.zipCode;
    const state = params.payload.state;
    const country = params.payload.country;
    const dealerLocateUrl = `/wcs/resources/${AppSettings.storeId}/dealerLocate/byPostalCode/${zipCode}/${state}/${country}`;
    return axios.get(dealerLocateUrl).then(
            (response)=>response.data,
            (error)=>{
                console.log("Error while trying to retrieve dealer Data =>",error);
                throw error;
            }
        )

};

/*** REST CALL TO UPDATE SELECTED DEALER **/
export function * updateSelectedDealer(bac) {
    const url = `/wcs/resources/store/${AppSettings.storeId}/GMCart/associateDealer/${bac}`;
    const payload = {};
    try {
        const data = yield call(postAuthenticated, url, payload);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to update delivery method", error);
        throw error;
    }
}


