import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import AppSettings from "../../core/AppSettings";
import { slickSliderSettings as baseSliderSettings } from "../../shared/Carousel/CarouselHelper";
import CategoryCard from "../../shared/CategoryCard/CategoryCard";
import { loadFeaturedCategoriesDataAsync } from "./FeaturedCategoriesRedux/FeaturedCategoriesActionCreator";
import { resetVehicleSelectState } from "../VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
import { resetVehicleConfig } from "../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import { SET_CLP_REDIRECT, TOGGLE_MODAL } from "../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionType";
import { setCategory } from "../../shared/SearchLink/SearchLinkRedux/SearchLinkActionCreator";
import { selectedCategoryTitle } from "../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionCreator";
import accHistory from "../../modules/App/History";
import { jsonToUrlParams } from "../Utils/Utils";
import vaultConstants from "../../../config/vault_constants";
import App from "../../modules/App/App";

/**
 * Featured categories component turns featured categories cards into a carousel and when clicked brings up vehicle modal
 * which then redirects user to corresponding category landing page
 * @returns Carousel object
 */

const FeaturedCategories = (props) => {
    const [analyticsFired, setAnalyticsFired] = useState(false);
    const [isMediumDevices, setIsMediumDevices] = useState(false);
    const [isSmallDevices, setIsSmallDevices] = useState(false);

    const FeaturedCategoriesData = useSelector((state) => state.FeaturedCategoriesReducer) || {};
    const dispatch = useDispatch();
    const isContentLoaded =
        !FeaturedCategoriesData.isFetching &&
        Array.isArray(FeaturedCategoriesData.categoriesAssets) &&
        FeaturedCategoriesData.categoriesAssets.length > 0;
    const brandName = AppSettings.currentSite.label;
    const isT3 = AppSettings.isT3;
    const vehicleInfoData = useSelector((state) => state.Session);
    const urlParameters = AppSettings.urlParameters();
    const clpFitmentFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2237161_NEW_FITMENT_CLP_ACC) === "true";
    //setting vehicle fitment info
    const vehicleFitment = {
        year: vehicleInfoData.year || "",
        make: vehicleInfoData.make || "",
        model: vehicleInfoData.model || "",
        modelId: vehicleInfoData.vehicleConfig.modelId || "",
        body: vehicleInfoData.vehicleConfig.body || "",
        bodyId: vehicleInfoData.vehicleConfig.bodyId || "",
        trim: vehicleInfoData.vehicleConfig.trim || "",
        trimId: vehicleInfoData.vehicleConfig.trimId || "",
        drive: vehicleInfoData.vehicleConfig.drive || "",
        driveId: vehicleInfoData.vehicleConfig.driveId || "",
        engine: vehicleInfoData.vehicleConfig.engine || "",
        engineId: vehicleInfoData.vehicleConfig.engineId || "",
        wheel: vehicleInfoData.vehicleConfig.wheel || "",
        wheelId: vehicleInfoData.vehicleConfig.wheelId || "",
        bodyNumDoors: vehicleInfoData.vehicleConfig.bodyNumDoorsId || "",
        vin: vehicleInfoData.vin ? vehicleInfoData.vin : ""
    };

    useEffect(() => {
        Object.entries(vehicleFitment).map(([key, value]) => {
            urlParameters[key] = value;
        });
    }, [vehicleFitment]);

    const handleCategoryChange = useCallback(
        (name, clpName, id) => {
            //resets data before setting the category
            dispatch(resetVehicleSelectState());
            dispatch(resetVehicleConfig());
            dispatch(selectedCategoryTitle(name));
            dispatch(setCategory(name, id));
            if (!clpFitmentFlag || isT3) {
                dispatch({ type: TOGGLE_MODAL });
            }
            dispatch({ type: SET_CLP_REDIRECT, category: clpName });
            if (clpFitmentFlag && !isT3) {
                urlParameters["categoryId"] = id;
                //redirecting to CLP without fitment modal
                accHistory.push(`/categories/${clpName}` + jsonToUrlParams(urlParameters));
            }
            document.getElementById("vehicleSelectorBackButton")
                ? document.getElementById("vehicleSelectorBackButton").click()
                : null;
        },
        [dispatch]
    );

    const handleResize = () => {
        setIsMediumDevices(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
        setIsSmallDevices(window.innerWidth < AppSettings.XSMALL_BREAKPOINT);
    };

    const numSlides = (() => {
        if (isSmallDevices) return 1;
        if (isMediumDevices) return 2;
        return 4;
    })();

    useEffect(() => {
        dispatch(loadFeaturedCategoriesDataAsync());

        setIsMediumDevices(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT);
        setIsSmallDevices(window.innerWidth < AppSettings.XSMALL_BREAKPOINT);
        window.addEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // Makes analtyics direct call on every home page visit.
        if (typeof _satellite != "undefined" && !analyticsFired) {
            setAnalyticsFired(true);
            _satellite.track("page-view");
        }
    });

    let cards = null;
    const { categoriesAssets, categoriesTitle } = FeaturedCategoriesData;
    if (categoriesAssets?.length) {
        cards = categoriesAssets.map((card, index) => {
            return (
                <div className="ac-carousel-track" key={index}>
                    <CategoryCard
                        brandName={brandName}
                        handleChange={handleCategoryChange}
                        categoryName={card.category}
                        categoryId={card.id}
                        image={card.image}
                        clpName={card.clpName}
                        title={card.title}
                        desc={card.desc}
                        disableBootstrap={true}
                        responsiveClassName=""
                    />
                </div>
            );
        });
    }

    return (
        <>
            {isContentLoaded && (
                <section id="marketing-page-featured-categories">
                    <div className="q-margin-base q-headline featured-headline gb-headline1">
                        <div className="small-margin">
                            <div>
                                <h2 className="q-headline1 ac-categoryTitle gb-headline1">{categoriesTitle}</h2>
                            </div>
                        </div>
                    </div>
                    <Slider
                        {...baseSliderSettings()}
                        autoplay={false}
                        speed={500}
                        slidesToShow={numSlides}
                        slidesToScroll={numSlides}
                        className="q-multimedia-scroller featured-scroller"
                        data-dtm="shop by category"
                    >
                        {cards}
                    </Slider>
                </section>
            )}
        </>
    );
};

export default FeaturedCategories;
