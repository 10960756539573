// FormValidate contains field-level validation functions for redux-form fields.

// The parameters to the validation function are:
//   value - The current value of the field
//   allValues - The values of the entire form
//   props - Any props passed to the form
//   name - The name of the field

// If the value is valid, the validation function should return undefined.

// If the value is invalid, the validation function should return an error. 
// This is usually a string, but it does not have to be.

/**************************************************************/
// Validation functions
/**************************************************************/

// Validates required field value is present.

export const required = value => (value || typeof value === 'number' ? undefined : 'Required')

// Validates field value maximum character length.
const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined
export const maxLength16 = maxLength(16)
export const maxLength17 = maxLength(17)
export const maxLength32 = maxLength(32)
export const maxLength64 = maxLength(64)

// Validates field value minimum character length.
export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength2 = minLength(2)
export const minLength8 = minLength(8)

// Validates field value is alphanumeric.
export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined

// Validates field value is a number.
const number = value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined

// Validates field value is an email address.
export const email = value =>
    value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value.toLowerCase())        ? 'Invalid email address'
        : undefined

// Validates field value is a phone number.
export const phoneNumber = value =>
    value && !/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/i.test(value)
        ? 'Invalid phone number'
        : undefined

// Validates field value is a postal code.
export const postalCode = value =>
    value && !/^[0-9]{5}(?:-[0-9]{4})?$/i.test(value)
        ? 'Invalid postal code'
        : undefined

// Validates shipModeId to not be Select Delivery shipModeId.
export const shipModeId = value =>
    value && (value === "13701") ? 'This field is required. Please make a selection.' : undefined

// Validates shop cart quantity input field value, allowing only a numbers with a max length of 5
export const qtyInputLength = value =>
    value && value.length > 5 && /^[0-9]\d*$/i.test(value) ? '(0-99999)' : undefined

// Validates shop cart quantity input field value, allowing only numbers > 0
export const qtyInputMinValue = min => value =>
    value && value < min ? '(0-99999)' : undefined
export const qtyInputMinValue0 = qtyInputMinValue(0)

// Validates field value contains at least 1 number
export const oneNumber = value =>
    value && !/.*[0-9]+.*$/i.test(value)
        ? 'Must include at least 1 number'
        : undefined



/**************************************************************/
// PasswordValidators
/**************************************************************/

// PASSWORD: Validates field value maximum character length.
export const maxLengthPwd = (value) => {
    if (process.env.REACT_APP_AUTH_OC_PWD_MAX_LENGTH) {
        let max = process.env.REACT_APP_AUTH_OC_PWD_MAX_LENGTH
        return value && value.length > max ? `Must be ${max} characters or less` : undefined
    }
    else return undefined
}

// PASSWORD: Validates field value minimum character length.
export const minLengthPwd = (value) => {

    if (process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH) {
        let min = process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH
        return value && value.length < min ? `Must be ${min} characters or more` : undefined
    }
    else return undefined
}

// PASSWORD: Validates filed value contains at least 1 number
export const oneNumberPwd = (value) => {
    if (process.env.REACT_APP_AUTH_OC_PWD_ONE_NUMBER) {
        return (
            value && !/.*[0-9]+.*$/i.test(value)
                ? 'Must include at least 1 number'
                : undefined
        )
    }
    else {
        return undefined
    }
}

// PASSWORD: Validates field value contains 1 uppercase letter
export const upperCasePwd = (value) => {
    if (process.env.REACT_APP_AUTH_OC_PWD_UPPER_CASE) {
        return value && !/.*[A-Z]+.*$/i.test(value) ? "Must include at least 1 uppercase letter" : undefined;
    } else {
        return undefined;
    }
};
// PASSWORD: Validates field value contains 1 lowercase letter
export const lowerCasePwd = (value) => {
    if (process.env.REACT_APP_AUTH_OC_PWD_LOWER_CASE) {
        return value && !/.*[a-z]+.*$/i.test(value) ? "Must include at least 1 lower letter" : undefined;
    } else {
        return undefined;
    }
};

// PASSWORD: Validates field value to only allow special characters and no spaces (i.e. !@#$_-]*)
export const specialCharsPwd = (value) => {

    if (process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR) {
        // Get special characters for allowed input value
        const specialChars = process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR;
        // Parse special characters and add '\' between them for regex expression
        const parsedSpecialChars = specialChars.split('').join('\\');
        // create regex that only allows certain characters
        const pattern = new RegExp("^[a-zA-Z\\d\\" + (parsedSpecialChars) + "]+$");

        return (
            value && !pattern.test(value) ?
                `Password can only use ${specialChars}`
                : undefined
        )
    }
    // if no vault value for special pwd characters, 
    // no need to validate for special characters
    // so returning undefined
    else {
        return undefined
    }

}


