import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../Modal/Modal"
import SharedRadioButton from "../../../RadioButton/SharedRadioButton";
import SharedButton from "../../../Button/SharedButton";
import { useDispatch } from "react-redux";
import { getOrderItemChangeDeliveryMethodResponse, getCartItemDeleteResponse } from "../../../../modules/Cart/Redux/CartService";
import { fetchCartData } from "../../../../modules/Cart/Redux/CartHandler";
import store from '../../../../core/Redux/Store'

/**
 * Used to display the selected delivery method and the modal to change delivery method per item
 * @param props
 * orderItemId: String, Required. The orderItemId of the item to be used in payload when changing delivery method
 * quantity: Number, Required. The quantity of an item. Used for payload when changing delivery method
 * dispatch: Function, Required. Function for dispatching action to cart reducer
 * setShowItemSpinner: Function, Required. Function used to display item spinner when quantity is being changed
 * @returns {JSX.Element}
 * @constructor
 */
 const DeliveryModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deliveryData = props.shippingRates.deliveryOptions;
    const [selectedShipMode, setSelectedShipMode] = useState("");
    const [carrierCode, setCarrierCode] = useState("");
    let pickupItems = [];
    let installItems = [];
    let shippingItems = [];
    if (deliveryData.length) {
        pickupItems.push(deliveryData.filter(item => item.carrierCode === "PICKUP"));
        installItems.push(deliveryData.filter(item => item.carrierCode === "INSTALL"));
        shippingItems.push(deliveryData.filter(item => item.carrierCode !== "INSTALL" && item.carrierCode !== "PICKUP" && item.carrierCode !== "SELECT"));
    }
    
    const handleOnClick = (shippingModeId, carrierCode) => {
        setSelectedShipMode(shippingModeId);
        setCarrierCode(carrierCode);
    };

    const handleSaveAndClose = () => {
        /*Need to delete the install data first and then update if previously selected delviery method was install.
        * Else, make update call. */
        if (props.installItem) {
            let authData = store.getState().Authentication;
            const payload = {
                orderItemId: props.installItem.orderItemId
            };
            getCartItemDeleteResponse(payload, true, authData)
            .then((response) => {
                if (response) {
                    getOrderItemChangeDeliveryMethodResponse(selectedShipMode, props.orderItemId, props.quantity, carrierCode, props.orderId, "delivery", props.productId)
                    .then((response) => {
                        if (response.status == 200) {
                            fetchCartData(dispatch).then((response) => {
                                dispatch({type: "ORDER_INFO_ORDER_IN_CART_ASYNC"});
                            });
                        } else {
                            dispatch({type: "CART_DELIVERY_CHANGE_FAIL"});
                        }
                    })
                }
            });   
        } else {
            getOrderItemChangeDeliveryMethodResponse(selectedShipMode, props.orderItemId, props.quantity, carrierCode, props.orderId, "delivery", props.productId)
            .then((response) => {
                if (response.status == 200) {
                    fetchCartData(dispatch).then((response) => {
                        dispatch({type: "ORDER_INFO_ORDER_IN_CART_ASYNC"});
                    });
                } else {
                    dispatch({type: "CART_DELIVERY_CHANGE_FAIL"});
                }
            })
        }
        
        props.handleClose();
        props.setShowItemSpinner(true);
    };

    const compareSources = (id) => {
        return id === selectedShipMode ? true : false;
    };

    const createDeliveryOptions = (item) => {
        return [{
            handleClick: () => handleOnClick(item.shippingModeId, item.carrierCode),
            checked: compareSources(item.shippingModeId),
            disabled: false,
            label: item.desc,
            handleOnChange: () => {
                return false;
            },
            id: item.shippingModeId
        }];
    };

    const formatPrice = (deliveryMethod, price) => {
        if (deliveryMethod === "Pickup at Dealer - No Additional cost") {
            return "Free";
        } else {
         return t("LABEL_COMMON_DOLLAR_SIGN") + "" + Number(price).toFixed(2);
        }
    }

    const formatDeliveryOptions = (arr) => {
        let formattedDeliveryOptions;
        if (arr.length) {
            formattedDeliveryOptions = (
                arr.map((item) => (
                    item.map((obj, index) => (
                        <article id="deliveryOptionsSelectorCartModal" >
                            <div className='delivery-type'>
                                <SharedRadioButton objectLabel={false} radioButtonProps={createDeliveryOptions(obj)} />
                            </div>
                            <div className='delivery-price'>
                                {formatPrice(obj.desc , obj.shippingRate)}
                            </div>
                        </article>
                    ))
                ))
            )
        }
        return formattedDeliveryOptions;
    }

    //TODO: STYLE AND RENAME CLASSNAMES
    const deliveryModalContent = (
        <div className={"delivery-modal-content-wrapper"}>
            <div className={"prt-delivery-modal-title q-headline2"}>{t("LABEL_COMMON_DELIVER_OPTIONS")}</div>
            <div className={"q-body1"}>
                {t("LABEL_CART_SELECT_DELIVERY_PREFERENCE") + props.zipcode?.slice(0, 5) + ":"}
            </div>
            {formatDeliveryOptions(pickupItems)}
            <hr className="delivery-headline" />
            <div className={"q-headline4"}>
                {t("LABEL_ESTIMATED_SHIPPING")}
            </div>
            {formatDeliveryOptions(shippingItems)}
            <hr className="delivery-headline" />
            {formatDeliveryOptions(installItems)}
            <div>
                <SharedButton
                    className="prt-cart-button prt-delivery-modal-save-button"
                    analyticsTag="stat-button-link"
                    onClick={handleSaveAndClose}
                    disabled={selectedShipMode.length ? false : true}
                    buttonText={t("LABEL_COMMON_SAVE_AND_CLOSE")}
                    analyticsTag="delivery method"
                />
            </div>
        </div>
    )

    return (
        <Modal
        modalId={"prt-cart-item-delivery-methods-modal"}
        onClose={props.handleClose}
        open={props.showDeliveryModal}
        simpleModal={true}
    >
    {deliveryModalContent}
    </Modal>
    );
};

DeliveryModal.propTypes = {
};

export default DeliveryModal;