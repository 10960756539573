import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formattedCurrency } from "../../../Internationalization/FormattedNumbers";
import AppSettings from "../../../../core/AppSettings";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { getDeliveryOptionsByCityState } from "../../../Utils/sharedServices";
import DeliveryModal from "../DeliveryModal/DeliveryModal"
import { getShippingRatesResponse } from "../../../../modules/Cart/Redux/CartService";
import {CartReducer} from "../../../../modules/Cart/Redux/CartReducer";

/**
 * Used to display the selected delivery method and the modal to change delivery method per item
 * @param props
 * currency: String, Required. The currency that the delivery prices will be formatted to
 * styles: Object, Optional. Contains classnames to style text
 * orderInfo: Object, Required. Contains order information from getCartData
 * orderItemId: String, Required. The orderItemId of the item to be used in payload when changing delivery method
 * quantity: Number, Required. The quantity of an item. Used for payload when changing delivery method
 * dealerName: String, Required. The name of the seller to display
 * deliveryInfo: Object, Required. Contains information on the selected delivery option from getCartData
 * dispatch: Function, Required. Function for dispatching action to cart reducer
 * setShowItemSpinner: Function, Required. Function used to display item spinner when quantity is being changed
 * @returns {JSX.Element}
 * @constructor
 */
const CartDelivery = (props) => {
    //GET DEALER DATA
    const dealerData = useSelector(state => state.DealerizationReducer);
    const cartData = useSelector(state => state.CartReducer);
    const bac = cartData?.parsedResponse?.dealer?.dealerBac || "";
    const { t } = useTranslation();
    const { city, state, country, zip, zipcode: zip2 } = dealerData.selectedDealerAllData;
    const zipcode = dealerData.currentZip || zip || zip2; // same logic exists on parts.

    const cartStateClassification = useSelector(
        (state) => state.MiniCartReducer?.data?.dealerizationInfo?.classification
    );
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState("");
    const [fetchError, setFetchError] = useState(false);
    const [deliveryOptionFailed, setDeliveryOptionFailed] = useState(false);
    
    const [deliveryPrice, setDeliveryPrice] = useState(props.deliveryInfo.deliveryPrice === 0
        ? t("LABEL_COMMON_FREE")
        : formattedCurrency(props.currency, props.deliveryInfo.deliveryPrice));
    const [shippingRates, setShippingRates] = useState([]);
    const { initMethod } = {
        initMethod: props.deliveryInfo.deliveryId
    };
    const BRAND = AppSettings.thisMake.label;
    const { orderItemId, orderInfo, quantity, productId, dealerName, dispatch } = props;
    // Getting information on selected delivery method retrieved from getCartData
    const deliveryMethodClass = BRAND === "gmc" ? "q-body2" : "";
    const deliveryMethod = props.deliveryInfo.deliveryMethod === "Dealer Installation - Contact Dealer for Cost" ? props.deliveryInfo.deliveryMethod.split("-")[0] : props.deliveryInfo.deliveryMethod
    const orderId = orderInfo.parsedResponse.orderId;

    const radioButtons = [];
    // Go through shipping data to make radio buttons for each option.
    shippingRates?.length > 0
        ? shippingRates.forEach((shippingOption) => {
              const rate =
                  shippingOption.shippingRate === "0.0"
                      ? t("LABEL_COMMON_FREE")
                      : new Intl.NumberFormat("en-EN", { style: "currency", currency: AppSettings.currency }).format(
                            Number(shippingOption.shippingRate)
                        );
              const carrier = shippingOption.carrierCode.toLowerCase();
              const shipModeId = shippingOption.shippingModeId;
              const name = shippingOption.name;
              const description = shippingOption.desc;
              const shippingLabel = (
                  <div className={"prt-shipping-label q-body1"}>
                      {name} <span className={"prt-shipping-label-rate"}>{rate}</span>
                      <div className={"prt-shipping-label-subscript q-body2"}>{description}</div>
                  </div>
              );

              radioButtons.push({
                  handleClick: () => handleOptionClick(shipModeId, carrier),
                  checked: selectedMethod === shipModeId,
                  disabled: false,
                  label: shippingLabel,
                  handleOnChange: () => {},
                  id: shipModeId
              });
          })
        : "";

    const handleClickChange = (e) => {
        e.preventDefault();
        setDeliveryOptionFailed(false);
        setShippingRates("")

        // Fetch shipping rates/options when we click the change button
        getShippingRatesResponse(orderId, orderItemId, bac).then((response) => {
            if (response?.deliveryOptions) {
                setShippingRates(response);
            } else {
                setDeliveryOptionFailed(true);
            }
        });
    };

    const handleClose = () => {
        setShowDeliveryModal(false);
    };

    useEffect(() => {
        if (fetchError) {
            setFetchError(false);
            handleClose();
        }
    }, [fetchError]);

    useEffect(() => {
        if (shippingRates.deliveryOptions) {
            setShowDeliveryModal(true);
        }
    }, [shippingRates]);

    useEffect(() => {
        setSelectedMethod(initMethod);
    }, [initMethod]);

    useEffect(() => {
      if (props.deliveryInfo.deliveryMethod === "Pickup at Dealer - No Additional cost"){
        setDeliveryPrice("Free");
      } else {
        setDeliveryPrice(formattedCurrency(props.currency, props.deliveryInfo.deliveryPrice));
      }
    }, [orderInfo]);

    const changeDeliveryLink = (
        <a
            id={"prt-cart-delivery-change-link"}
            className={"delivery-change q-headline3 gb-descriptive1 stat-text-link"}
            onClick={handleClickChange}
            href={"#"}
            data-dtm="shopping cart:delivery method"
        >
            {t("LABEL_COMMON_CHANGE")}
        </a>
    );
    //TODO: STYLE AND REDO CLASSNAMES
    return (
        <div id="delivery-methods" className="prt-line-item-delivery-container">
            <div className="prt-delivery-header-and-change">
                <p className={"delivery-label q-headline3 gb-descriptive1"}>{t("LABEL_COMMON_DELIVER_METHOD")}</p>
                {changeDeliveryLink}
            </div>
            <div className="prt-delivery-details">
                <div className={"prt-delivery-method" + deliveryMethodClass}>
                    <span className={"delivery-method-top-line gb-body3"}>{deliveryMethod}</span>
                </div>
                <div className={"delivery-method-price gb-body3"}>{deliveryPrice}</div>
            </div>
            {
                showDeliveryModal && <DeliveryModal installItem={props.installItem} zipcode={zipcode} handleClose={handleClose} showDeliveryModal={showDeliveryModal} shippingRates={shippingRates} orderItemId={orderItemId} quantity={quantity} orderId={orderId} setShowItemSpinner={props.setShowItemSpinner} productId={productId}/>
            }
            {deliveryOptionFailed && <div className="prt-shipping-unavailable-warning q-body3">{t("LABEL_DELIVERY_OPTIONS_ERROR")}</div>}
            {deliveryMethod===t("KEY_CHECKOUT_DEFAULT_SELECT_OPTION") && <div className="prt-shipping-unavailable-warning q-body3">{t("LABEL_CART_SHIPPING_ERROR")}</div>}
        </div>
    );
};

CartDelivery.propTypes = {
    currency: PropTypes.string.isRequired,
    styles: PropTypes.object,
    orderInfo: PropTypes.object.isRequired,
    orderItemId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    deliveryInfo: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setShowItemSpinner: PropTypes.func.isRequired
};

export default CartDelivery;