/****** SHOPPING CART LINE ITEM HEADER ******/

/****IMPORT STATEMENT *********/
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import {Translation} from 'react-i18next';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import * as utils from '../../Utils/Utils';
import AppSettings from '../../../core/AppSettings';




/**** BEGINNING OF THE SHOPPING CART LINE ITEM HEADER ***/
const CartOrderLineItemHeader = (props)=>{


    /** VARIABLES **/
    const vehicleInfo = props.data;
    const vehicleCount = props.count;
    const vinNumber = vehicleInfo.vin;
    const yearMakeModel = vehicleInfo.ymm;
    const bodyStyle = vehicleInfo.bodyStyle; // Text name
    const wheelBase = vehicleInfo.wheelBase; // Text name
    const vehicleTrim = vehicleInfo.trim; // Text name
    const driveType = vehicleInfo.driveType; // Text name
    const engineBase = vehicleInfo.engineBase; // Text name
    const year = vehicleInfo.year;
    const make = vehicleInfo.make;
    const model = vehicleInfo.model;

    // For use in building the URLs
    const modelId = vehicleInfo.modelId; // ID number
    const bodyStyleId = vehicleInfo.bodyStyleId; // ID number
    const wheelBaseId = vehicleInfo.wheelBaseId; // ID number
    const trimId = vehicleInfo.trimId; // ID number
    const driveTypeId = vehicleInfo.driveTypeId; // ID number
    const engineBaseId = vehicleInfo.engineBaseId; // ID number
    const bodyNumDoorsId = vehicleInfo.bodyNumDoors;

    let continueShoppingUrl = AppSettings.homeUrl;
    let isVin = false;

    /*** CHECKING FOR VIN NUMBER TO BUILD CONTINUE SHOPING URL **/
    if (vinNumber !== '' && vinNumber !== undefined && vinNumber !== null){
        continueShoppingUrl = utils.getSearchBaseUrl() + '/?vin=' + vinNumber + utils.getBacIfT3();
        isVin = true;
    }
    // This URL is built if any of the fitment/vehicle config parameters are missing
    // This is with the expectation that if one variable is present, they all are
    else {
        continueShoppingUrl =
            utils.getSearchBaseUrl() + '/?year=' + year + '&make=' + make + '&model=' + model + utils.getBacIfT3();

            // fitment/vehicle config parameters
            modelId ? continueShoppingUrl += `&modelId=${modelId}` : '';
            bodyStyle ? continueShoppingUrl += `&body=${bodyStyle}` : '';
            bodyStyleId ? continueShoppingUrl += `&bodyId=${bodyStyleId}` : '';
            wheelBase ? continueShoppingUrl += `&wheel=${wheelBase}` : '';
            wheelBaseId ? continueShoppingUrl += `&wheelId=${wheelBaseId}` : '';
            vehicleTrim ? continueShoppingUrl += `&trim=${vehicleTrim}` : '';
            trimId ? continueShoppingUrl += `&trimId=${trimId}` : '';
            driveType ? continueShoppingUrl += `&drive=${driveType}` : '';
            driveTypeId ? continueShoppingUrl += `&driveId=${driveTypeId}` : '';
            engineBase ? continueShoppingUrl += `&engine=${engineBase}` : '';
            engineBaseId ? continueShoppingUrl += `&engineId=${engineBaseId}` : '';
            // Need bodyNumDoorsId too because it is passed into add to cart call. If we don't, we may encounter issue
            // of products in cart being under different duplicate vehicle headers
            bodyNumDoorsId ? continueShoppingUrl += `&bodyNumDoors=${bodyNumDoorsId}&bodyNumDoorsId=${bodyNumDoorsId}`
                : '';
    }

    return (<Translation>
        {(t)=>(<div className="ac-cartLineItemHeaderWrapper small-12 medium-12 large-12 xlarge-12">
                <div className="ac-accessory-item-header q-headline-text q-header">
                    {
                        /*** RENDER VIN NUMBER ***/
                        isVin && <span>{vinNumber}&nbsp;</span>
                    }

                    {
                        /*** RENDER YEAR,MAKE & MODEL ***/
                        yearMakeModel && <span>{yearMakeModel}</span>
                    }

                    {
                        /*** RENDER BODY STYLE ***/
                        bodyStyle && <span>/{bodyStyle}</span>
                    }

                    {
                        /*** RENDER WHEEL BASE***/
                        wheelBase && <span>/{wheelBase}</span>
                    }

                    {
                        /*** RENDER TRIM ***/
                        vehicleTrim && <span>/{vehicleTrim}</span>
                    }

                    {
                        /*** RENDER DRIVE TYPE ***/
                        driveType && <span>/{driveType}</span>
                    }

                    {
                        /*** RENDER ENGINE TYPE ***/
                        engineBase && <span>/{engineBase}</span>
                    }
                {!props.miniCart && 
                <span>
                    { /*** LINK FOR CONTINUE SHOPPING ***/ }
                   <a
                     className="q-shopping-links stat-text-link"
                     data-dtm={typeof yearMakeModel == 'string' ? yearMakeModel.toLowerCase() : undefined}
                     href={continueShoppingUrl}>
                         {t("LABEL_CHECKOUT_CONT_SHOPPING_FOR_THIS_VEHICLE")}
                         <span><FontAwesomeIcon className="ac-right-arrow" icon={faCaretRight}/></span>
                   </a>
                </span>}

                </div>
            </div>


            )

        }</Translation>)
};

/********** VALIDATING PROPS TYPE *****************/
CartOrderLineItemHeader.propTypes = {
    data:PropTypes.shape({
        ymm:PropTypes.string.isRequired,
        wheelBase:PropTypes.string,
        driveType:PropTypes.string,
        bodyStyle:PropTypes.string,
        trim:PropTypes.string,
        engineBase:PropTypes.string,
        bodyStyleId:PropTypes.string,
        wheelBaseId:PropTypes.string,
        trimId:PropTypes.string,
        driveTypeId:PropTypes.string,
        engineBaseId:PropTypes.string,
        makeId:PropTypes.string,
        modelId:PropTypes.string,
        year:PropTypes.oneOfType([
             PropTypes.string,
             PropTypes.number
        ]),
        make:PropTypes.string,
        model:PropTypes.string
    }),
    count:PropTypes.number,
    vinNumber:PropTypes.string
};

export default CartOrderLineItemHeader
