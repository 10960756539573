import { call, put } from 'redux-saga/effects';
import { setWalletData, setGpmsLocale, setWalletDataError, walletLoadError } from './WalletActionCreator';
import { getGpmsWalletData } from './WalletService';

export function* handleGetWalletData() {
    try {
        const response = yield call(getGpmsWalletData);

        if (response.data.response_data && response.data.response_data.shared.wallet.status === 'SUCCESS') {
            yield put(setGpmsLocale(response.data.response_data.data.language_preference));
        }
        if (response.data.response_data && response.data.response_data.shared.wallet) {
            // Currently only supporting US accounts and ACC only supports english so checking country 
            // status by locale for now
            // TODO: GPMS or auth should return country in clear format to UI
            if (response.data.response_data.data.language_preference === "en-US") {
                yield put(setWalletData(response.data.response_data.shared.wallet));
            }
            else {
                yield put(walletLoadError());
            }
        }
        else {
            yield put(setWalletDataError());
            yield put(walletLoadError());
        }

    } catch (error) {
        yield put(setWalletDataError(error));
        yield put(walletLoadError());
    }
}