import * as type from "./BreadcrumbActionType";

var initialState = {
    searchParams: '',
    allCategoriesId: '',
    allCategiresLabel: '',
    selectedCategoryId: '',
    selectedCategory: '',
    selectedSubcategoryId: '',
    selectedSubcategory: '',
    currentPageName: '',
    isAllProductsBreadcrumbClicked: false
}

const BreadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_SEARCH_PARAMS:
            return Object.assign({}, state, { searchParams: action.params });
        case type.SET_ALL_CATEGORIES:
            return Object.assign({}, state, { allCategoriesLabel: action.name, allCategoriesId: action.id });
        case type.SET_CATEGORY:
            return Object.assign({}, state, { selectedCategoryLabel: action.name, selectedCategoryId: action.id, });
        case type.SET_SUBCATEGORY:
            return Object.assign({}, state, { selectedSubcategoryLabel: action.name, selectedSubcategoryId: action.id });
        case type.SET_PAGE_NAME:
            return Object.assign({}, state, { currentPageName: action.name });
        case type.PASS_ALL_PRODUCTS_BREADCRUMB_FLAG:
            return Object.assign({}, state, {isAllProductsBreadcrumbClicked: action.isAllProductsBreadcrumbClicked});

        default: return state;
    }
}

export default BreadcrumbReducer;