import React, { Component } from "react";
// react-accessible-accordion: Accessible Accordion component
import { AccordionItem, AccordionItemTitle, AccordionItemBody } from "react-accessible-accordion";
import SubCategoryItem from "./SubCategoryItem";
import { resetCategoryData } from "../CategoryNavRedux/CategoryNavActionCreator";
import store from "../../../../../core/Redux/Store";
import { setAllCategories } from "../../../../../shared/Breadcrumb/BreadcrumbRedux/BreadcrumbActionCreator";

/**************************************************************/
//          CategoryItemComponent
/**************************************************************/

class CategoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.isOpen
        };
        this.handleClick = this.handleClick.bind(this);
    }

    /* handleClick logic to handle the biz req for active/inactive states
     * If it's a subcategory, both category and selected category needs to be set to active or "isOpen"
     * if it's the category, then just the selected category needs to be active
     * with the other categories auto-closing
     * -J-Sully*/
    handleClick(category, searchParams, subCat) {
        if (!subCat) {
            this.setState((prevState) => ({ active: !prevState.active }));

            // If the category is clicked while it is active, then act as if "All Products" was clicked.
            // Otherwise... act normal... just act normal...
            if (this.state.active) {
                this.props.handleURL(this.props.allCategories);
                this.props.selectCategory(this.props.allCategories, searchParams);
            } else {
                this.props.handleURL(category);
                this.props.selectCategory(category, searchParams);
            }
        }
    }

    //Handles case on mobile where user selects the Category link, modal needs to close on link selection
    handleCategoryLinkClick() {
        this.props.closeAccordion([]);
    }

    componentDidUpdate(prevProps) {
        //reset active state with prop changes
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({ active: this.props.isOpen });
        }
    }

    /**
     * responsible for creating the subcategories that act as links
     *  */
    createSubcategories(parentCategory, categories) {
        if (categories != undefined) {
            let renderedSubcategory = categories.map(
                function (subcat, i) {
                    return (
                        <SubCategoryItem
                            key={i}
                            fitmentErrorActive={this.props.fitmentErrorActive}
                            closeAccordion={this.props.closeAccordion}
                            selectCategory={this.props.selectCategory}
                            currentCategory={this.props.currentCategory}
                            category={subcat}
                            searchParams={this.props.searchParams}
                            onClick={() => this.props.handleClick(subcat)}
                            handleURL={this.props.handleURL}
                        />
                    );
                }.bind(this)
            );

            return (
                <ul id="ac-subcat-items" className="ac-subcat-items">
                    {renderedSubcategory}
                </ul>
            );
        } else {
            return (
                <ul id="ac-subcat-items" className="ac-subcat-items">
                    <li>...</li>
                </ul>
            );
        }
    }
    /**
     * renders the catnav bar
     * handleClick on <div> handles the category click
     * handleClick on <AccordionItemBody> handles the subcategory click*/
    render() {
        let { category, isOpen } = this.props;
        let subCategoryItems = this.createSubcategories(category, category.subCategories);
        let categoryLabel = category.label;
        return (
            <AccordionItem expanded={isOpen} className={"q-expander " + (this.state.active ? "q-button-active" : "")}>
                <div onClick={() => this.handleClick(category, this.props.searchParams, false)}>
                    <AccordionItemTitle
                        className="ac-accordion-title q-headline q-expander-button stat-text-link"
                        data-dtm={"shop by category"}
                    >
                        <h5>
                            <span
                                className="ac-category-link stat-text-link"
                                onClick={() => this.handleCategoryLinkClick()}
                                data-dtm={"shop by category: " + categoryLabel}
                            >
                                {categoryLabel} ({category.count})
                            </span>
                        </h5>
                    </AccordionItemTitle>
                </div>
                <AccordionItemBody onClick={() => this.handleClick(category, this.props.searchParams, true)}>
                    {subCategoryItems}
                </AccordionItemBody>
            </AccordionItem>
        );
    }
}

export default CategoryItem;
