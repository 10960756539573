import React from 'react';
import { Translation } from 'react-i18next';
import propTypes from 'prop-types';
import {formatNumber} from '../../../../../client/shared/Internationalization/FormattedNumbers';

HeaderLoyalty.propTypes = {
    rewardsPoints: propTypes.string, // loyalty rewards points displayed
};

function HeaderLoyalty(props) {

    const { rewardsPoints, width } = props

    if (rewardsPoints && rewardsPoints != 0) {
        const points = rewardsPoints.includes(",") ?  rewardsPoints : formatNumber(rewardsPoints) ; //points const stores formatted version of rewardsPoints with commas
        return (
            <Translation>{(t) => (
                <div className="ac-header-loyalty-pts">
                    {/* Conditional loyalty header will pass 'Rewards' heading title for larger screens and omit 'Rewards' heading title for mobile */}
                    {/* Possibly update later to use media screen small medium definitions instead of width variable */}
                    <span>{width >= 570 ? 
                        t('LABEL_LOYALTY_HEADER_REWARDS_POINTS', { rewardsPoints: points }) : 
                        t('LABEL_LOYALTY_HEADER_REWARDS_POINTS_SMALL', { rewardsPoints: points })}
                    </span>
                </div>
            )}</Translation>
        );
    }
    else {
        return null;
    }
}

export default HeaderLoyalty;
