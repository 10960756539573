/** * Constants for YMM and YMM Extend ***/
export const YMM_SELECT_YEAR_TITLE = "LABEL_YMM_SELECT_YEAR";
export const YMM_SELECT_MAKE_TITLE = "LABEL_YMM_SELECT_MAKE";
export const YMM_SELECT_MODEL_TITLE = "LABEL_YMM_SELECT_MODEL";
export const LABEL_YMM_SELECT_BODY = "LABEL_YMM_SELECT_BODY";
export const LABEL_YMM_SELECT_WHEEL = "LABEL_YMM_SELECT_MODEL";
export const LABEL_YMM_SELECT_TRIM = "LABEL_YMM_SELECT_TRIM";
export const LABEL_YMM_SELECT_DRIVE = "LABEL_YMM_SELECT_DRIVE";
export const LABEL_YMM_SELECT_ENGINE = "LABEL_YMM_SELECT_ENGINE";
export const YMM_YEAR = "YMM_YEAR";
export const YMM_MAKE = "YMM_MAKE";
export const YMM_MODEL = "YMM_MODEL";
export const YMM_BODY = "YMM_BODY";
export const YMM_WHEEL = "YMM_WHEEL";
export const YMM_TRIM = "YMM_TRIM";
export const YMM_DRIVE = "YMM_DRIVE";
export const YMM_ENGINE = "YMM_ENGINE";
export const YMM_CHOOSE_YEAR = "LABEL_CHOOSE_YEAR";
export const YMM_CHOOSE_MODEL = "LABEL_CHOOSE_MODEL";
export const YMM_CHOOSE_MAKE = "LABEL_CHOOSE_MAKE";
export const YMM_CHOOSE_BODY = "LABEL_CHOOSE_BODY";
export const YMM_CHOOSE_TRIM = "LABEL_CHOOSE_TRIM";
export const YMM_CHOOSE_DRIVE = "LABEL_CHOOSE_DRIVE";
export const YMM_CHOOSE_ENGINE = "LABEL_CHOOSE_ENGINE";