import axios from "axios";
import AppSettings from "../../core/AppSettings";

export const getParentCategoryId = () => {
    const getParentCategoryIdURL = "/wcs/resources/store/" + AppSettings.storeId + "/categoryview/@top";
    return axios.get(getParentCategoryIdURL).then((response) => {
        if (response.status !== 200 || response.errors) {
            return null;
        } else {
            return response.data.CatalogGroupView[0].uniqueID;
        }
    });
};
export const getAllCategories = (byParentCategoryId) => {
    const getAllCategoriesURL =
        "/wcs/resources/store/" + AppSettings.storeId + "/categoryview/byParentCategory/" + byParentCategoryId;
    return axios.get(getAllCategoriesURL).then((response) => {
        if (response.status !== 200 || response.errors) {
            return null;
        } else {
            return response.data.CatalogGroupView;
        }
    });
};
