import React from "react";
import PropTypes from "prop-types";

const CategoryCard = (props) => {
    return (
        <div
            className={
                props.disableBootstrap
                    ? "column ac-large-marginbottom ac-category-card stat-image-link" + props.responsiveClassName
                    : "small-12 medium-6 xlarge-4 grid-column-alignment-left columns ac-large-marginbottom ac-category-card stat-image-link" +
                      props.responsiveClassName
            }
            onClick={() => props.handleChange(props.title, props.clpName, props.categoryId)}
            data-dtm={`top-selling ` + props.brandName + ` parts`}
        >
            <div className="q-multimedia-teaser q-normal-hover">
                <picture>
                    <img
                        alt={props.title}
                        className="q-image stat-image-link"
                        src={props.image}
                        data-dtm={props.title}
                    />
                </picture>
                <div className="q-teaser-appearance-a-text">
                    <div className="q-teaser-text-title q-headline q-teaser-headline-text-a gb-headline2">
                        <div className="q-teaser-longer-text gb-headline2">{props.title}</div>
                        <div className="q-teaser-shorter-text gb-headline2">{props.title}</div>
                    </div>
                    <hr className="q-teaser-separator-light" />
                    <div className="q-teaser-text-description q-text q-teaser-copy-text-a gb-body2 gb-color-grey-mid-dark">
                        <div className="q-teaser-longer-text gb-body2 gb-color-grey-mid-dark">{props.desc}</div>
                        <div className="q-teaser-shorter-text gb-body2 gb-color-grey-mid-dark">{props.desc}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CategoryCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    responsiveClassName: PropTypes.string.isRequired
};

export default CategoryCard;
