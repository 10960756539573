/****
 * COMPONENT NAME : DEALER-LOCATOR (CHANGE DEALER),
 * ORIGINAL-CODE-COMPLETED-ON : 02-18,
 * BY:NPANTHI
 *
 * ****/

/*** IMPORT STATEMENT *****/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { closeChangeDealerLocatorModal, loadDealerLocatorDataAsync } from "./DealerLocatorRedux/DealerLocatorActionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import DealerLocatorWrapper from "./DealerLocatorComponents/DealerLocatorWrapper/DealerLocatorWrapper";
import SelectedDealer from './DealerLocatorComponents/SelectedDealer/SelectedDealer';
import AppSettings from '../../core/AppSettings'
import PropTypes from 'prop-types';
import FieldWarningMessage from '../FieldWarningMessage/FieldWarningMessage';

class DealerLocator extends Component {
    render() {
        let dealerInfo = '';
        let dealerSite = AppSettings.isT3 || false;
        let brand = '';
        if (!AppSettings.isT3 && AppSettings.currentSite.label) {
            brand = AppSettings.currentSite.label;
        }

        if (this.props.dealerInfo !== undefined) {
            dealerInfo = this.props.dealerInfo.dealer;
        }
        return (
            <Translation>
                {
                    (t) => (<div className="ac-changeDealerContainer">
                        {(this.props.authData.enrolledAttributeValue == 1 && !dealerInfo.rewardsProgram) &&
                            <FieldWarningMessage message={t("LABEL_LOYALTY_CHECKOUT_NONPARTICIPATION", { brand })}/>
                        }
                        <div className="small-7 medium-10 large-6 xlarge-6 columns ac-nopadding-x">
                            <SelectedDealer dealerInfo={dealerInfo} />
                        </div>
                        {
                            /*** WHEN USER CLICK ON CHANGE DEALER, A MODEL WILL POP UP WHERE USER CAN CHANGE THE DEALER ***/
                            <Modal open={this.props.showDealerLocatorModal}
                                onClose={this.props.closeModal}
                                onOverlayClick={this.props.closeModal}
                                classNames={{ modal: 'ac-customModal' }}
                                dataClass="stat-button-close"
                                dataDtm="checkout step2:locate your dealer"
                            >

                                {/*** DEALER LOCATOR CONTENT INSIDE THE POP UP MODAL ***/}
                                <DealerLocatorWrapper dealerLocatorData={this.props.dealerData} />
                            </Modal>
                        }
                    </div>

                    )
                }
            </Translation>
        )
    }
}

/*** TAKING CURRENT STATE FROM STORE AND PASSING IT TO VIEW (COMPONENT)) ***/
const mapStateToProps = (state) => {
    return {
        showDealerLocatorModal: state.DealerLocatorReducer.showDealerLocator,
        dealerData: state.DealerLocatorReducer,
        dealerInfo: state.OrderInfoReducer.parsedResponse,
        authData: state.Authentication
    };
};

/*** MAP-DISPATCH-TO-PROPS
 * initChangeDealer : WHEN USER CLICK ON CHANGE DEALER LINK, ACTION WILL DISPATCH TO MAKE A CALL TO GET LIST OF DEALERS AND ALSO OPEN THE MODAL
 * closeModal : WHEN USER CLICK ON CLOSE BUTTON, ACTION WILL DISPATCH TO CLOSE THE MODAL
 *
 * ***/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initChangeDealer: () => {
            dispatch(loadDealerLocatorDataAsync(ownProps));
        },
        closeModal: () => {
            dispatch(closeChangeDealerLocatorModal())
        }
    }
};


/*** DEALER LOCATOR PROP TYPES ***/
DealerLocator.propTypes = {
    zipCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerLocator)