import * as type from './CategoryNavActionTypes';
import queryString from 'query-string';

var initialState = {

}

const CategoryNavReducer = (state = initialState, action) => {
  switch (action.type) {

    case type.CATEGORY_SELECTED:

    return Object.assign({},state,action.payload);

    case type.LOAD_CATEGORY_NAV_DATA:

    return Object.assign({},state,action.payload);

    case type.RESET_CATEGORY_NAV_DATA:
    state = {};
    return Object.assign({},state,{});

    default:
      return state;
  }
};

export default CategoryNavReducer;