export const setAnalyticsclassAndAttribute = () => {
    const name = document.getElementById("gmpayui-name");
    const cardNumber = document.getElementById("gmpayui-card-number");
    const expirationMonth = document.getElementById("gmpayui-expiry-month");
    const expirationYear = document.getElementById("gmpayui-expiry-year");
    const CVV = document.getElementById("gmpayui-security-code");

    name.classList.add("stat-input-field");
    name.setAttribute("data-dtm", "checkout step3");
    name.setAttribute("data-dtm2", "exclude");

    cardNumber.classList.add("stat-input-field");
    cardNumber.setAttribute("data-dtm", "checkout step3");
    cardNumber.setAttribute("data-dtm2", "exclude");

    expirationMonth.classList.add("stat-drop-down");
    expirationMonth.setAttribute("data-dtm", "checkout step3:dropdown:month");
    expirationMonth.setAttribute("data-dtm2", "exclude");

    expirationYear.classList.add("stat-drop-down");
    expirationYear.setAttribute("data-dtm", "checkout step3:dropdown:year");
    expirationYear.setAttribute("data-dtm2", "exclude");

    CVV.classList.add("stat-input-field");
    CVV.setAttribute("data-dtm", "checkout step3");
    CVV.setAttribute("data-dtm2", "exclude");
}