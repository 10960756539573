import {
    cartCalculate,
    getCartItemDeleteResponse,
    getCartResponse,
    getOrderItemChangeDeliveryMethodResponse,
    getOrderItemChangeQuantityResponse,
    getShippingRatesResponse,
    callSwitchOrg
} from "./CartService";
import {
    CART_DELIVERY_CHANGE_FAIL,
    CART_GET_SHIPPING_RATES_ERROR,
    CART_PAGE_FAILED_API_CALL,
    CART_PAGE_INIT,
    CART_PAGE_ITEM_REMOVE_FAIL,
    CART_PAGE_SPINNER_OFF,
    CART_QTY_CHANGE_FAIL,
    CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY,
    ORDER_INFO_ORDER_IN_CART
} from "./CartActionTypes";
import store from "../../../core/Redux/Store";
import { LOAD_MINI_CART_DATA_ASYNC } from "../../App/Header/MiniCart/MiniCartRedux/MiniCartActionType";
import { call, put } from "redux-saga/effects";
import { deliveryPageSwitchOrgFlag, zeroCheckoutAmount } from "../../Checkout/CheckoutRedux/CheckoutActionCreator";
import { loadOrderInfoDataAsync, loadOrderInfoDataAsyncFail } from "./CartActionCreator";
import {
    showApplyCouponBtnSpinner,
    showSummaryRailSpinner
} from "../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator";
import AppSettings from "../../../core/AppSettings";
import { checkPartialAuthenticationError, isEmpty } from "../../../shared/Utils/Utils";
import { removeLineItem } from "../../../shared/OrderInfo/OrderInfoRedux/OrderInfoCreator";

/** * Calling get cart data and dispatching the response to reducer ***/
export const fetchCartData = async (dispatch) => {
    try {
        await cartCalculate();
        const response = await getCartResponse();
        let parsedResponse = parseCartResponse(response);
        // Response is valid in two situations:
        //  response object is empty: cart is empty
        //  response object is not empty and it has an "order" key value: we have something in cart
        if (response && (isEmpty(response) || (!isEmpty(response) && response.order))) {
            const isFedexWarning = parseFedexWarning(response);
            if (isFedexWarning) {
                dispatch({ type: CART_GET_SHIPPING_RATES_ERROR });
            }
            dispatch({
                type: CART_PAGE_INIT,
                response: response
            });
            dispatch({
                type: ORDER_INFO_ORDER_IN_CART,
                payload: {
                    response: response,
                    parsedResponse: parsedResponse
                }
            });
        } else {
            dispatch({ type: CART_PAGE_FAILED_API_CALL });
        }
    } catch (error) {
        dispatch({ type: CART_PAGE_FAILED_API_CALL });
    } finally {
        dispatch({ type: CART_PAGE_SPINNER_OFF });
    }
};

/*** Fetch data for delivery options ***/
export const fetchShippingRates = async (
    dispatch,
    setShippingRates,
    setIsLoadingRates,
    setFetchError,
    orderId,
    orderItemId
) => {
    try {
        setFetchError(false);
        setIsLoadingRates(true);
        const response = await getShippingRatesResponse(orderId, orderItemId);
        if (response) {
            setShippingRates(response.deliveryOptions);
        } else {
            setFetchError(true);
            dispatch({ type: CART_GET_SHIPPING_RATES_ERROR });
        }
    } catch (error) {
        setFetchError(true);
        dispatch({ type: CART_GET_SHIPPING_RATES_ERROR });
    } finally {
        setIsLoadingRates(false);
    }
};

/*** Call update_order_item to update quantity of item ***/
export const cartChangeQuantity = async (dispatch, orderId, orderItemId, newQuantity, authData, setShowItemSpinner) => {
    const payload = {
        x_action: "change_quantity",
        orderId: orderId,
        orderItem: [{ orderItemId: orderItemId, quantity: newQuantity.toString() }]
    };

    try {
        setShowItemSpinner(true);
        const response = await getOrderItemChangeQuantityResponse(payload, true, authData);

        if (!response || !response.data || response.status !== 200 || response?.data?.errors?.length) {
            // Show alert message that not enough inventory is available instead of error message
            if (response?.data?.errors?.[0].errorMessage.indexOf("Inventory is Unavailable") > -1) {
                dispatch({ type: CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY });
            } else {
                dispatch({ type: CART_QTY_CHANGE_FAIL });
            }
        } else {
            await fetchCartData(dispatch);
            store.dispatch({ type: LOAD_MINI_CART_DATA_ASYNC });
        }
    } catch (error) {
        dispatch({ type: CART_QTY_CHANGE_FAIL });
    } finally {
        setShowItemSpinner(false);
    }
};

const handleChangeDeliveryFail = (dispatch, orderItemId, resetSelection) => {
    dispatch({ type: CART_DELIVERY_CHANGE_FAIL, orderItemId: orderItemId });
    resetSelection();
};

export const cartChangeDeliveryMethod = async (
    dispatch,
    orderItemId,
    shipModeId,
    quantity,
    carrierCode,
    orderId,
    reset,
    setShowItemSpinner,
    productId
) => {
    try {
        setShowItemSpinner(true);
        const response = await getOrderItemChangeDeliveryMethodResponse(
            shipModeId,
            orderItemId,
            quantity,
            carrierCode,
            orderId,
            reset,
            "handler",
            productId
        );

        if (response.status === 200) {
            if (response.data) {
                if (response.data.errors) {
                    handleChangeDeliveryFail(dispatch, orderItemId, reset);
                } else {
                    await fetchCartData(dispatch);
                    store.dispatch({ type: LOAD_MINI_CART_DATA_ASYNC });
                }
            }
        } else {
            handleChangeDeliveryFail(dispatch, orderItemId, reset);
        }
    } catch (error) {
        handleChangeDeliveryFail(dispatch, orderItemId, reset);
    } finally {
        setShowItemSpinner(false);
    }
};

export const cartItemRemove = async (dispatch, orderItemId, authData, setShowItemSpinner, items) => {
    const payload = {
        orderItemId: orderItemId
    };
    try {
        setShowItemSpinner(true);
        const response = await getCartItemDeleteResponse(payload, true, authData);
        if (!response || !response.data || response.status !== 200 || response.data?.errors?.length) {
            dispatch({ type: CART_PAGE_ITEM_REMOVE_FAIL });
        } else {
            await fetchCartData(dispatch);
            store.dispatch(removeLineItem(items));
            store.dispatch({ type: LOAD_MINI_CART_DATA_ASYNC });
        }
    } catch (error) {
        dispatch({ type: CART_PAGE_ITEM_REMOVE_FAIL });
    } finally {
        setShowItemSpinner(false);
    }
};

// Build continue shopping link from vehicleInfo
export const ContinueShoppingLink = (lastVehicle) => {
    const checkVin = isVin(lastVehicle);
    const isFitmentAvailable = checkFitmentStuff(lastVehicle);
    const urlWithFitmentInformation = addFitmentStuff(lastVehicle);

    if (checkVin) {
        return `/search?vin=${lastVehicle.vin}`;
    } else {
        if (isFitmentAvailable) {
            return `/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&makeId=${lastVehicle.vehicleInformation.makeId}&model=${lastVehicle.vehicleInformation.model}&${urlWithFitmentInformation}`;
        } else {
            return `/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&makeId=${lastVehicle.vehicleInformation.makeId}&model=${lastVehicle.vehicleInformation.model}`;
        }
    }
};

/** Parse cart changes warnings from getCartData**/
export const parseCartWarnings = (order) => {
    const warnings = { priceChangeWarnings: [], shipChangeWarnings: [], itemsChanged: 0 };

    if (order && order?.vehicles?.length) {
        order.vehicles.forEach((vehicle) => {
            vehicle?.orderItems?.length
                ? vehicle.orderItems.forEach((orderItems) => {
                      // orderItems.items[0] is an actual item with its data
                      const orderItemData = orderItems.items[0];
                      const { partName, orderItemExtendAttribute } = orderItemData;

                      if (orderItemExtendAttribute?.[0]?.attributeName === "priceChangeWarning") {
                          warnings.priceChangeWarnings.push({
                              partName: partName,
                              warning: orderItemExtendAttribute[0].attributeValue
                          });
                          warnings.itemsChanged += 1;
                      }
                  })
                : null;
        });
    }

    return warnings;
};

const parseFedexWarning = (response) => {
    let isWarning = false;
    const orderAttributes = response.order?.orderInfo?.orderExtendAttribute;
    if (orderAttributes && orderAttributes.length) {
        orderAttributes.map((attributes) => {
            if (attributes?.attributeName === "fedexServiceUnavailable" && attributes?.attributeValue === "true") {
                isWarning = true;
            }
        });
    }
    return isWarning;
};

/** Testing for vin search ***/
const isVin = (lastVehicle) => {
    return lastVehicle.vin !== "" && lastVehicle.vin !== undefined && lastVehicle.vin !== null;
};

/*** Test if any of fitment data is available ***/
const checkFitmentStuff = (lastVehicle) => {
    return (
        lastVehicle.vehicleInformation.bodyStyleId ||
        lastVehicle.vehicleInformation.wheelBaseId ||
        lastVehicle.vehicleInformation.trimId ||
        lastVehicle.vehicleInformation.driveTypeId ||
        lastVehicle.vehicleInformation.engineBaseId
    );
};

// check fitment Trim
const addFitmentStuff = (lastVehicle) => {
    let continueShoppingUrl = "";
    if (lastVehicle.vehicleInformation.modelId) {
        continueShoppingUrl += "&modelId=" + lastVehicle.vehicleInformation.modelId;
    }

    if (lastVehicle.vehicleInformation.bodyStyle) {
        continueShoppingUrl += "&body=" + lastVehicle.vehicleInformation.bodyStyle;
    }

    if (lastVehicle.vehicleInformation.bodyStyleId) {
        continueShoppingUrl += "&bodyId=" + lastVehicle.vehicleInformation.bodyStyleId;
    }

    if (lastVehicle.vehicleInformation.wheelBase) {
        continueShoppingUrl += "&wheel=" + lastVehicle.vehicleInformation.wheelBase;
    }

    if (lastVehicle.vehicleInformation.wheelBaseId) {
        continueShoppingUrl += "&wheelId=" + lastVehicle.vehicleInformation.wheelBaseId;
    }

    if (lastVehicle.vehicleInformation.trim) {
        continueShoppingUrl += "&trim=" + lastVehicle.vehicleInformation.trim;
    }

    if (lastVehicle.vehicleInformation.trimId) {
        continueShoppingUrl += "&trimId=" + lastVehicle.vehicleInformation.trimId;
    }

    if (lastVehicle.vehicleInformation.driveType) {
        continueShoppingUrl += "&drive=" + lastVehicle.vehicleInformation.driveType;
    }

    if (lastVehicle.vehicleInformation.driveTypeId) {
        continueShoppingUrl += "&driveId=" + lastVehicle.vehicleInformation.driveTypeId;
    }

    if (lastVehicle.vehicleInformation.engineBase) {
        continueShoppingUrl += "&engine=" + lastVehicle.vehicleInformation.engineBase;
    }

    if (lastVehicle.vehicleInformation.engineBaseId) {
        continueShoppingUrl += "&engineId=" + lastVehicle.vehicleInformation.engineBaseId;
    }

    if (lastVehicle.vehicleInformation.bodyNumDoors) {
        continueShoppingUrl += "&bodyNumDoors=" + lastVehicle.vehicleInformation.bodyNumDoors;
    }
    return continueShoppingUrl;
};

export const pdPageUrl = (data, vin, vehicle) => {
    const seoUrl = data.seoUrl;

    const year = vehicle.year;
    const make = vehicle.make;
    const makeId = vehicle.makeId;
    const model = vehicle.model;

    // For use in building the URL
    const bodyStyle = vehicle.bodyStyle; // Text name
    const wheelBase = vehicle.wheelBase; // Text name
    const trim = vehicle.trim; // Text name
    const driveType = vehicle.driveType; // Text name
    const engineBase = vehicle.engineBase; // Text name

    const modelId = vehicle.modelId; // ID number
    const bodyStyleId = vehicle.bodyStyleId; // ID number
    const wheelBaseId = vehicle.wheelBaseId; // ID number
    const trimId = vehicle.trimId; // ID number
    const driveTypeId = vehicle.driveTypeId; // ID number
    const engineBaseId = vehicle.engineBaseId; // ID number
    const bodyNumDoorsId = vehicle.bodyNumDoors;

    let productDetailsUrl = "";

    /** BUILDING SEO URL FOR PRODUCT DETAILS PAGE **/
    productDetailsUrl = `/product/${seoUrl}?`;
    if (vin) {
        productDetailsUrl += `vin=${vin}`;
    } else {
        productDetailsUrl += `year=${year}&make=${make}&makeId=${makeId}&model=${model}`;

        // check for vehicle config params
        modelId ? (productDetailsUrl += `&modelId=${modelId}`) : "";
        bodyStyle ? (productDetailsUrl += `&body=${bodyStyle}`) : "";
        bodyStyleId ? (productDetailsUrl += `&bodyId=${bodyStyleId}`) : "";
        wheelBase ? (productDetailsUrl += `&wheel=${wheelBase}`) : "";
        wheelBaseId ? (productDetailsUrl += `&wheelId=${wheelBaseId}`) : "";
        trim ? (productDetailsUrl += `&trim=${trim}`) : "";
        trimId ? (productDetailsUrl += `&trimId=${trimId}`) : "";
        driveType ? (productDetailsUrl += `&drive=${driveType}`) : "";
        driveTypeId ? (productDetailsUrl += `&driveId=${driveTypeId}`) : "";
        engineBase ? (productDetailsUrl += `&engine=${engineBase}`) : "";
        engineBaseId ? (productDetailsUrl += `&engineId=${engineBaseId}`) : "";
        bodyNumDoorsId ? (productDetailsUrl += `&bodyNumDoors=${bodyNumDoorsId}`) : "";
    }

    return productDetailsUrl;
};

/** WORKER TO CALL GET CART RESPONSE AND DISPATCH A FUNCTION */
export function* getOrderInfo() {
    const isUserSignedIn = store.getState().Authentication.registrationStatus != "G";
    try {
        let parsedResponse = {};
        const res = yield call(getCartResponse);

        if (res.order) {
            parsedResponse = parseCartResponse(res);

            const totalOfOrder = parseFloat(parsedResponse.orderInfo.total);

            const isZeroCheckout = totalOfOrder === 0;

            yield put(zeroCheckoutAmount(isZeroCheckout));

            yield put(loadOrderInfoDataAsync(res, parsedResponse));
            yield put({ type: LOAD_MINI_CART_DATA_ASYNC });
            yield put(showSummaryRailSpinner(false));
            yield put(showApplyCouponBtnSpinner(false));
        }
    } catch (error) {
        yield put(loadOrderInfoDataAsyncFail(error));
        const authentication401Message = "Error: Request failed with status code 401";
        if (
            error === authentication401Message &&
            isUserSignedIn &&
            (AppSettings.pageName === "Checkout" || AppSettings.pageName === "Cart")
        ) {
            yield checkPartialAuthenticationError();
        }
    }
}

/** FUNCTION TO PARSE CART RESPONSE */
export function parseCartResponse(response) {
    let parsedCartResponse = {
        cost: "",
        customerInfo: "",
        vehicles: ""
    };
    if (response.order) {
        let order = response.order;
        parsedCartResponse = {
            orderId: order.orderInfo.orderId,
            cost: parseCost(order),
            orderInfo: order.orderInfo,
            customerInfo: order.customerInfo,
            vehicles: parseVehicles(order),
            shipping: Object.assign(order.shipping, { shipAddressId: order.shipAddressId }),
            dealer: order.dealer
        };
    }

    return parsedCartResponse;
}

/** FUNCTION TO PARSE COST **/
function parseCost(order) {
    const orderInCart = order || {};
    const orderInfo = orderInCart.orderInfo || {};
    let currency = "USD";
    if (orderInfo.currency) {
        currency = orderInfo.currency;
    }
    const lang = "en-EN";

    let formattedAmounts = {
        totalSubtotal: formatCurrency(orderInfo.subtotal, lang, currency),
        recordSetTotal: orderInfo.recordSetTotal,
        installation: formatCurrency(orderInfo.installation, lang, currency),
        total: formatCurrency(orderInfo.total, lang, currency),
        shipping: formatCurrency(orderInfo.fedEx, lang, currency),
        totalShippingTax: formatCurrency(orderInfo.totalShippingTax, lang, currency),
        totalAdjustment: formatCurrency(orderInfo.totalAdjustment, lang, currency),
        totalSalesTax: formatCurrency(orderInfo.totalSalesTax, lang, currency),
        totalTax: formatCurrency(orderInfo.totalTax, lang, currency),
        adiTotalCost: formatCurrency(orderInfo.adiTotalCost, lang, currency)
    };

    if (orderInfo.adjustment.length > 0) {
        let couponData = formatCouponData(orderInfo.adjustment, orderInfo.subtotal, lang, currency);
        formattedAmounts.coupons = couponData;
    }
    if (orderInfo.notAdjustedPromotions && orderInfo.notAdjustedPromotions.length > 0) {
        let notAdjustedPromotions = formatNotAdjustedCoupons(orderInfo.notAdjustedPromotions);
        formattedAmounts.notAdjustedPromotions = notAdjustedPromotions;
    }
    return formattedAmounts;
}

/** FUNCTION TO FORMAT CURRENCY **/
function formatCurrency(amount, lang, currency) {
    if (!amount || amount === null || amount === "0") {
        amount = "";
    } else {
        amount = new Intl.NumberFormat(lang, {
            style: "currency",
            currency: currency
        }).format(Number(amount));
    }
    return amount;
}

function formatCouponData(couponData, totalSubtotal, lang, currency) {
    let coupons = {
        manufacturer_list: [],
        dealer_list: [],
        shipping_list: [],
        familyFirst_list: []
    };
    if (couponData.length > 0) {
        couponData.forEach(function (fee) {
            if (fee.usage === "Discount" && fee.promotionType === "GM") {
                coupons.manufacturer_list.push({
                    manufactureCoupon: fee.amount,
                    manufacturePromotionCode: fee.promotionCode,
                    manufacturePromotionId: fee.promotionId
                });
            } else if (fee.usage === "Discount" && fee.promotionType === "DEALER") {
                coupons.dealer_list.push({
                    dealerCoupon: fee.amount,
                    dealerPromotionCode: fee.promotionCode,
                    dealerPromotionId: fee.promotionId
                });
            } else if (fee.promotionType === "SHP") {
                coupons.shipping_list.push({
                    shippingCoupon: formatCurrency(fee.amount, lang, currency),
                    shippingPromotionCode: fee.promotionCode,
                    shippingPromotionId: fee.promotionId
                });
            } else if (fee.promotionType === "FF") {
                coupons.familyFirst_list.push({
                    familyFirstCoupon: fee.amount,
                    familyFirstPromotionCode: fee.promotionCode,
                    familyFirstPromotionId: fee.promotionId
                });
            }
        });
    }
    if (coupons.manufacturer_list.length > 0 || coupons.familyFirst_list.length > 0 || coupons.dealer_list.length > 0) {
        let subtotal = Number(parseFloat(totalSubtotal).toFixed(2));
        if (coupons.manufacturer_list.length > 0) {
            coupons.manufacturer_list.forEach(function (coup) {
                subtotal = subtotal + coup.manufactureCoupon;
            });
            coupons.afterManufacturer = formatCurrency(subtotal, lang, currency);
            coupons.manufacturer_list.forEach(function (coup) {
                coup.manufactureCoupon = new Intl.NumberFormat(lang, {
                    style: "currency",
                    currency: currency
                }).format(Number(coup.manufactureCoupon));
            });
        } else if (coupons.familyFirst_list.length > 0) {
            coupons.familyFirst_list.forEach(function (coup) {
                subtotal = subtotal + coup.familyFirstCoupon;
            });
            coupons.afterFamilyFirst = formatCurrency(subtotal, lang, currency);
            coupons.familyFirst_list.forEach(function (coup) {
                coup.familyFirstCoupon = new Intl.NumberFormat(lang, {
                    style: "currency",
                    currency: currency
                }).format(Number(coup.familyFirstCoupon));
            });
        }
        if (coupons.dealer_list.length > 0) {
            coupons.dealer_list.forEach(function (coup) {
                subtotal = subtotal + coup.dealerCoupon;
            });
            coupons.afterDealer = formatCurrency(subtotal, lang, currency);
            coupons.dealer_list.forEach(function (coup) {
                coup.dealerCoupon = new Intl.NumberFormat(lang, {
                    style: "currency",
                    currency: currency
                }).format(Number(coup.dealerCoupon));
            });
        }
    }
    return coupons;
}

/** FUNCTION TO PARSE VEHICLE **/
function parseVehicles(order) {
    let vehicles = {
        orderItems: [],
        vehiclesInfo: {}
    };
    if (order.vehicles) {
        vehicles = order.vehicles.map(function (x) {
            return {
                orderItems: x.orderItems,
                vehiclesInfo: Object.assign(x.vehiclesInfo, { vin: x.vinNumber })
            };
        });
    }
    return vehicles;
}

/** Switch org call on Delivery Page, this is needed to make installation work properly **/
// function* switchOrgOnT1Delivery(orgId) {
//     const payload = {
//         URL: "/",
//         activeOrgId: orgId
//     };
//     yield call(callSwitchOrg, payload);
// }

function formatNotAdjustedCoupons(notAdjustedPromotions) {
    let notAdjustedCouponsObject = {
        notAdjustedCoupons: [],
        fullCouponErrorMessage: ""
    };
    let notAdjustedCouponCodes = [];
    let standardMessage = "";
    let couponCodesList = "";
    let addComma = false;

    notAdjustedPromotions.length > 0 &&
        notAdjustedPromotions.map(function (x) {
            //to push potentially multiple non Adjusted Promotions
            x.couponCode && notAdjustedCouponCodes.push(x.couponCode);

            if (x.Message) {
                standardMessage = x.Message;
            }
            // if only one coupon, then we dont want to add a comma
            if (x.couponCode && !addComma) {
                couponCodesList += x.couponCode;
                addComma = true;
            }
            //if multiple coupons, we want to add a comma in the message
            else if (x.couponCode && addComma) {
                couponCodesList += ", " + x.couponCode;
            }

            notAdjustedCouponsObject = {
                notAdjustedCoupons: notAdjustedCouponCodes,
                fullCouponErrorMessage: standardMessage + couponCodesList
            };
        });

    return notAdjustedCouponsObject;
}
