/**************** IMPORT STATEMENT ******************************************/
import React from 'react';
import { Translation } from 'react-i18next';
import '../../../../node_modules/react-tabs/style/react-tabs.scss';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import AppSettings from '../../../core/AppSettings';
import WheelPackageTable from "../../../shared/WheelPackage/WheelPackageTable/WheelPackageTable";
import WarrantyTab from "./ProductDetailsTabComponents/WarrantyTab";
import FAQTab from "./ProductDetailsTabComponents/FAQTab";
import MaintenanceTab from "./ProductDetailsTabComponents/MaintenanceTab";
import DocumentationTab from "./ProductDetailsTabComponents/DocumentationTab";
import ProductSpecsTab from "./ProductDetailsTabComponents/ProductSpecsTab";
import DescriptionTab from "./ProductDetailsTabComponents/DescriptionTab";
import TroubleshootTab from "./ProductDetailsTabComponents/TroubleshootTab";
import DesktopTabs from "./ProductDetailsTabComponents/DesktopTabs";
import MobileTabs from "./ProductDetailsTabComponents/MobileTabs";

const ProductDetailsTabs = (props) => {
    const componentData = props.item;
    const prop65ImageUrl = AppSettings.damSource + "products/images/Warning_sign.jpg";
    if (componentData === undefined) {
        return null
    }
    const reducerData = props.ProductDetailsTabReducer;


    /**************** INITIALIZING PRODUCT SPECIFIC CONTENT VARIABLES ******************************************/
    let lengthOfTab = 0;
    let specificationTitle, specificationContent,
        descriptionTitle, descriptionContent,
        documentationTitle, documentationContent,
        troubleshootingTitle,
        troubleshootContent,
        warrantyTitle, warrantyContent,
        faqTitle, faqContent,
        maintenanceTitle, maintenanceContent = '',
        wheelPackageTitle, wheelPackageContent = '';

    /***************** FLAG FOR EACH OF THE ITEMS *****************************************/

    let isWarranty,
        isFaq,
        isDocumentation,
        isSpecs,
        isMaintenance,
        isTroubleshooting,
        isDescription,
        isWheelPackage = false;


    /*************** TESTING EACH PROPERTIES AND ACTIVATE THE FLAG **********/

    if (componentData) {
        /**************** WHEEL PACKAGE *************************************/
        if (componentData.hasOwnProperty('wheelPackageComponent') && componentData.wheelPackageComponent && (componentData.wheelPackageComponent.length)) {
            isWheelPackage = true;
            lengthOfTab++;
            wheelPackageTitle = <Translation>{(t) => (<span>{t("LABEL_WHEEL_PACKAGES")}</span>)}</Translation>;
            wheelPackageContent = <WheelPackageTable data={componentData.wheelPackageComponent} />
        }

        /*************** WARRANTY *******************************************/

        if (componentData.hasOwnProperty('warranty') && (componentData.warranty.length)) {
            isWarranty = true;
            lengthOfTab++;
            warrantyTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_WARRANTY")}</span>)}</Translation>;
            warrantyContent = <WarrantyTab warrantyTitle={warrantyTitle} warrantyContent={componentData.warranty}></WarrantyTab>
        }

        /*************** DOCUMENTATION *******************************************/

        if (componentData.hasOwnProperty('documentation') && (componentData.documentation['documents'] && componentData.documentation['documents'].length)) {

            let documentationData = componentData.documentation['documents'];

            isDocumentation = true;
            lengthOfTab++;
            documentationTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_INSTRUCTION_SHEET")}</span>)}</Translation>;
            documentationContent = <DocumentationTab documentationTitle={documentationTitle} documentationContent={documentationData}></DocumentationTab>
        }

        /*************** FREQUENTLY ASKED QUESTIONS *******************************************/
        if (componentData.hasOwnProperty('faq') && (componentData.faq.length)) {
            isFaq = true;
            lengthOfTab++;
            faqTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_FAQ")}</span>)}</Translation>;
            faqContent = <FAQTab faqTitle={"LABEL_PRODUCT_DETAILS_FREQUENTLY_ASKED_QUESTIONS"} faqData={componentData.faq}></FAQTab>
        }

        /*************** MAINTENANCE *******************************************/
        if (componentData.hasOwnProperty('maintenance') && (componentData.maintenance.length)) {
            isMaintenance = true;
            lengthOfTab++;
            maintenanceTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_MAINTENANCE")}</span>)}</Translation>;
            maintenanceContent = <MaintenanceTab maintenanceTitle={"LABEL_PRODUCT_DETAILS_GOOD_MAINTENANCE"} maintenanceData={componentData.maintenance}></MaintenanceTab>
        }

        /*************** SPECIFICATION *******************************************/
        if ((componentData.hasOwnProperty('specs') && (componentData.specs.length)) || ((componentData.hasOwnProperty('packagingSpecification') && (componentData.packagingSpecification.length))) ) {
            isSpecs = true;
            lengthOfTab++;
            specificationTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_SPECS")}</span>)}</Translation>;
            specificationContent = <ProductSpecsTab productSpecificationTitle={"LABEL_PRODUCT_DETAILS_SPECIFICATIONS"}
                                                    productSpecificationData={componentData.specs}
                                                    packagingInformationTitle="LABEL_PRODUCT_DETAILS_PACKAGE_SPECIFICATIONS"
                                                    packagingInformationData={componentData.packagingSpecification}>

                                 </ProductSpecsTab>
            }

        /*************** TROUBLE SHOOTING *******************************************/
        if (componentData.hasOwnProperty('troubleshooting') && (componentData.troubleshooting.length)) {

            isTroubleshooting = true;
            lengthOfTab++;
            troubleshootingTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_TROUBLESHOOTING")}</span>)}</Translation>;
            troubleshootContent = <TroubleshootTab troubleshootTitle={"LABEL_PRODUCT_DETAILS_SIGNS_OF_WEAR"} troubleshootData={componentData.troubleshooting[0]}></TroubleshootTab>
        }

        /*************** DESCRIPTION *******************************************/
        if (componentData.hasOwnProperty('description')) {

            isDescription = true;
            lengthOfTab++;
            descriptionTitle = <Translation>{(t) => (<span>{t("LABEL_PRODUCT_DETAILS_TITLE_DESCRIPTION")}</span>)}</Translation>
            descriptionContent = <DescriptionTab prop65ImageUrl={prop65ImageUrl} descriptionTitle={descriptionTitle} descriptionData={componentData.description}></DescriptionTab>

        }
    }

    /*************** SETTING UP WIDTH OF THE EACH TAB *******************************************/
    const cWidth = 100 / lengthOfTab;
    const customStyle = {
        'width': cWidth + '%'
    };



    /*************** FOR LARGER DEVICES *******************************************/

    const desktopExperience = <DesktopTabs
        customStyle={customStyle}
        isDescription={isDescription}
        descriptionTitle={descriptionTitle}
        descriptionContent={descriptionContent}

        isWheelPackage={isWheelPackage}
        wheelPackageTitle={wheelPackageTitle}
        wheelPackageContent={wheelPackageContent}

        isWarranty={isWarranty}
        warrantyTitle={warrantyTitle}
        warrantyContent={warrantyContent}

        isSpecs={isSpecs}
        specificationTitle={specificationTitle}
        specificationContent={specificationContent}

        isDocumentation={isDocumentation}
        documentationTitle={documentationTitle}
        documentationContent={documentationContent}

        isFaq={isFaq}
        faqTitle={faqTitle}
        faqContent={faqContent}

        isTroubleshooting={isTroubleshooting}
        troubleshootingTitle={troubleshootingTitle}
        troubleshootContent={troubleshootContent}

        isMaintenance={isMaintenance}
        maintenanceTitle={maintenanceTitle}
        maintenanceContent={maintenanceContent}

    ></DesktopTabs>

    /*************** END OF LARGE DEVICES *******************************************/


    /*************** FOR SMALL DEVICES *******************************************/

    const mobileExperience = <MobileTabs
        reducerData={reducerData}
        openPanel={props.openPanel}
        productName={componentData.name}

        isDescription={isDescription}
        descriptionTitle={descriptionTitle}
        descriptionContent={descriptionContent}

        isWheelPackage={isWheelPackage}
        wheelPackageTitle={wheelPackageTitle}
        wheelPackageContent={wheelPackageContent}

        isWarranty={isWarranty}
        warrantyTitle={warrantyTitle}
        warrantyContent={warrantyContent}

        isSpecs={isSpecs}
        specificationTitle={specificationTitle}
        specificationContent={specificationContent}

        isDocumentation={isDocumentation}
        documentationTitle={documentationTitle}
        documentationContent={documentationContent}

        isFaq={isFaq}
        faqTitle={faqTitle}
        faqContent={faqContent}

        isTroubleshooting={isTroubleshooting}
        troubleshootingTitle={troubleshootingTitle}
        troubleshootContent={troubleshootContent}

        isMaintenance={isMaintenance}
        maintenanceTitle={maintenanceTitle}
        maintenanceContent={maintenanceContent}

    ></MobileTabs>

    /*************** END OF MOBILE EXPERIENCE  *******************************************/

    return (
        <Translation>
            {(t) => (
                <div className="ac-productDetailsTabs">
                    <MediaQuery query="(max-width: 59.99em)">
                        {mobileExperience}
                    </MediaQuery>
                    <MediaQuery query="(min-width: 60em)">
                        {desktopExperience}
                    </MediaQuery>
                </div>

            )}
        </Translation>
    );
}

/*************** MAPPING STATE TO PROPS *******************************************/
const mapStateToProps = (state) => {
    return state;
};

/*************** DISPATCHER *******************************************/

const mapDispatchToProps = (dispatch) => {
    return {
        openPanel: (e) => {
            const actionType = e.currentTarget.id;
            dispatch({
                type: actionType
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsTabs)