import * as type from "./VehicleSelectActionType";

// Action creators are functions that create and return actions. The purpose is
// to make actions more portable and easier to test.

/**************************************************************/
// Actions to load data into the VehicleSelectReducer state
/**************************************************************/
export const loadYearsAsync = (startingYear) => {
    const action = {
        type: type.LOAD_YEARS_ASYNC,
        startingYear: startingYear
    };
    return action;
};

export const loadVINDataAsync = (searchType, UrlVin) => {
    const action = {
        type: type.LOAD_VIN_DATA_ASYNC,
        searchType: searchType,
        UrlVin: UrlVin
    };
    return action;
};

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const setClpRedirect = (category) => {
    const action = {
        type: type.SET_CLP_REDIRECT,
        category: category
    };
    return action;
};

export const setYears = (years) => {
    const action = {
        type: type.SET_YEARS,
        years: years
    };
    return action;
};

export const loadMakes = (yearsToMakes, makes) => {
    const action = {
        type: type.LOAD_MAKES,
        yearsToMakes: yearsToMakes,
        rawMakes: makes
    };
    return action;
};

export const loadModels = (yearsToModels, models) => {
    const action = {
        type: type.LOAD_MODELS,
        yearsToModels: yearsToModels,
        rawModels: models
    };
    return action;
};

export const setT3DefaultMake = (t3DefaultMake) => {
    const action = {
        type: type.SET_T3_DEFAULT_MAKE,
        t3DefaultMake: t3DefaultMake
    };
    return action;
};

// Pass response from vin call (whether it's vehicleInfo or invalidVIN) and the vin
export const setVIN = (response, vin, msg) => {
    const action = {
        type: type.SET_VIN,
        response: response,
        vin: vin,
        msg: msg
    };
    return action;
};

export const resetVIN = () => {
    return { type: type.RESET_VIN };
};

export const resetVehicleSelectState = () => {
    const action = {
        type: type.RESET_VEHICLE_SELECT_STATE
    };
    return action;
};

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const selectYear = (year) => {
    const action = {
        type: type.SELECT_YEAR,
        year: year
    };
    return action;
};

export const selectMake = (make) => {
    const action = {
        type: type.SELECT_MAKE,
        make: make
    };
    return action;
};

export const selectModel = (model) => {
    const action = {
        type: type.SELECT_MODEL,
        model: model
    };
    return action;
};

export const toggleVinInstructions = () => {
    const action = {
        type: type.TOGGLE_VIN_INSTRUCTIONS
    };
    return action;
};

export const setModelToModelID = (modelToModelID) => {
    const action = {
        type: type.SET_MODEL_TO_MODEL_ID,
        modelToModelID: modelToModelID
    };
    return action;
};

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadMakesFail = (error) => {
    const action = {
        type: type.LOAD_MAKES_FAIL,
        error
    };
    return action;
};

export const loadModelsFail = (error) => {
    const action = {
        type: type.LOAD_MODELS_FAIL,
        error
    };
    return action;
};

export const loadVINDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_VIN_DATA_ASYNC_FAIL,
        error
    };
    return action;
};

export const setVINFail = (error) => {
    const action = {
        type: type.SET_VIN_FAIL,
        error
    };
    return action;
};
