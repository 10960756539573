import * as type from './ErrorPageActionType';
/*
* typical reducer things
* left stuff p vague in case we need to leverage the eSpot call for more
* than just the DAM image path*/

let initialState = {
  isFetching: false,
  errorPageAssets: []
};

const ErrorPageReducer = (state = initialState, action) => {
  switch (action.type) {

    /**************************************************************/
    // Loading data and updating the state
    /**************************************************************/
    case type.LOAD_ERROR_PAGE_DATA_ASYNC:
      return { ...state, isFetching: true };

    case type.SET_ERROR_PAGE_DATA:
      return {
        ...state,
        isFetching: false,
        errorPageAssets: action.errorPageAssets
      };

    /**************************************************************/
    // Handling data errors
    /**************************************************************/
    case type.LOAD_ERROR_PAGE_DATA_ASYNC_FAIL:
      return { ...state, isFetching: false, error: action, showError: true };

    case type.SET_ERROR_PAGE_DATA_FAIL:
      return { ...state, isFetching: false, error: action, showError: true };

    default:
      return state
  }
};

export default ErrorPageReducer;