import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JsonData from './AccountOverview.json';
import i18next from 'i18next';




export const  accountOverview = ()=>{
  const data = JsonData;
  return <div className="ac-account_overview_wrapper">
      {
           data.length && data.map((item,index)=>{
                const url = i18next.t(item.url);
                const iconName = i18next.t(item.icon);
                const rewards = i18next.t("LABEL_ACCOUNT_OVERVIEW_MY_CHEVROLET_REWARDS");
                return <div className="small-12 medium-12 large-6 xlarge-6 xxlarge-4 ac-account_overview_item_wrapper columns" key={index}>
                  <div className="ac-account_overview_section">
                      <div className="ac-accountOverviewIcon">
                        <i> <FontAwesomeIcon icon={iconName}/></i>
                      </div>
                       <div className="ac-accountOverviewText">
                          <h2 className="q-headline2">{i18next.t(item.name)}
                            {
                              i18next.t(item.name) === rewards && <span>
                                <i> <FontAwesomeIcon icon="external-link-alt"/></i>
                              </span>
                            }
                          </h2>
                          <div className="q-text q-body2">{i18next.t(item.description)}</div>
                      </div>
                  </div>
              </div>
          })
      }

    </div>
}

export default accountOverview