import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { resetErrors } from "../HelperFunctions";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currentSellerStringParser } from "../../../ProductDetails/ProductDetailsDescription/DealerPicker";

/**
 * Button in the eyebrow nav that tells the user what
 * dealer they are shopping with.
 *
 * @param {*} props
 * state: dealerizationState reducer
 * dispatch: update function for dealerizationState reducer
 *
 * @return {Button} 'You're Shopping [dealer name or zip code]'
 */

export default function DealerizationModalButton(props) {
    const { t } = useTranslation();
    const selectedDealerAllData = props.state.selectedDealerAllData || "";
    const currentSourceName = props.state.currentSourceName || "";

    const handleOpen = () => {
        props.dispatch({ type: "TOGGLE_CLOSE", preventClose: false });
        props.dispatch({ type: "UPDATE_USER_INPUT_ZIP", userInputZip: props.state.currentZip });
        props.dispatch({ type: "SET_PREV_ZIP", prevZip: props.state.currentZip });
        props.dispatch({ type: "SET_PREV_SRC", prevSelectedSrc: props.state.selectedSource });
        props.dispatch({ type: "SET_PREV_SOURCES", prevSources: props.state.listOfAllSources });
        resetErrors(props.dispatch);
        props.dispatch({ type: "TOGGLE_DEALER_MODAL", modalOpen: true, showMap: false });
    };

    /* Render 'You're Shopping X' Button */
    return (
        <a
            id={"dealerization-open-modal-btn"}
            className="stat-text-link"
            data-dtm="global nav:eyebrow"
            onClick={() => handleOpen()}
        >
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <span className="prt-youre-shopping ">{t("LABEL_DEALER_LOCATOR_YOURE_SHOPPING")}&nbsp;</span>
            <span className="prt-current-dealer-label">
                <div>
                    {selectedDealerAllData ? 
                        (currentSellerStringParser(selectedDealerAllData.city)) + ", " + selectedDealerAllData.state + " " +
                        (selectedDealerAllData.zip || selectedDealerAllData.zipcode?.substr(0,5)) : currentSourceName ? currentSourceName : t("LABEL_EMPTY_CURRENT_DEALER")}
                </div>
            </span>
        </a>
    );
}

DealerizationModalButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
