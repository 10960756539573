/** COMPONENT NAME: MARKER COMPONENT (MARKER INSIDE THE GOOGLE MAP) **/

/*** IMPORT STATEMENT ***/
import { Marker } from "react-google-maps"
import React from 'react';
import {connect} from 'react-redux';
import {handleSelectDealer,handleScroller} from '../../DealerLocatorRedux/DealerLocatorActionCreators';
import PropTypes from 'prop-types';

const MarkerComponent = (props)=>{
    const customTitle = props.data.name + '\n' + props.data.address1 + '\n'+ props.data.city +', '+ props.data.state +'-'+ props.data.postalCode;
    return <Marker  defaultZoom={19} position={props.data.coordinates} icon={props.data.marker} title={customTitle} onClick={props.handleMarkerClick} />
    };


/*** MAP-DISPATCH-TO-PROPS
 * handleScroller : MOVE THE SCROLLER UP AND DOWN TO ALIGN THE ITEM VERTICALLY ON CENTER.
 * selectDealer :   SELECT THE DEALER, CHANGE THE MARKET TO SELECTED DEALER
 *
 * **/
const mapDispatchToProps = (dispatch,ownProps)=>{
    return {
        handleMarkerClick : ()=>{
            handleScroller(ownProps.data.bac);

           dispatch(handleSelectDealer(ownProps.data));
        }
    }

};

/*** PROP TYPES VALIDATION ***/
MarkerComponent.propTypes = {
    data:PropTypes.object.isRequired,
    count:PropTypes.number.isRequired,
    key:PropTypes.number
};


export default connect (null,mapDispatchToProps)(MarkerComponent)