import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import AppSettings from '../../../core/AppSettings';
import Button from '../../Button/Button';
import Modal from '../Modal';
import './LeavingSiteModal.scss';

/**
LEAVING SITE MODAL COMPONENT

Props:
    REQUIRED
        open: state
            State that tracks when modal is opened or closed.
            i.e. const [modalVisibility, setModalVisibility] = useState(false);

        onClose: function
            Function to close the modal when user clicks 'Go Back' or the 'X' button
            i.e. const closeSiteModal = () => {
                    setModalVisibility(false);
                 };

Usage:
    1) Import the LeavingSiteModal component from /shared/Modal/LeavingSiteModal/LeavingSiteModal to parent file
        Example: import LeavingSiteModal from '../Modal/LeavingSiteModal/LeavingSiteModal';
    
    2) Declare useState for showing modal:
        Example: const [modalVisibility, setModalVisibility] = useState(false);

    3) Add the LeavingSiteModal component and pass in the items as a prop, for example:
        <LeavingSiteModal
                open={modalVisibility}
                onClose={()=>closeSiteModal()}/>
 */

const LeavingSiteModal = (props) => {
    let brand = AppSettings.currentSite.label;
    const [content, setContent] = useState('');
    const [continueText, setContinueText] = useState('');

    useEffect(() => {
        if (content === '') {
            axios.get("/wcs/resources/store/" + AppSettings.storeId + "/espot/Rewards_Card_Apply?noCache=true").then(res => {
                const marketingSpotData = res.data.MarketingSpotData[0];
                setContent(marketingSpotData.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText.replaceAll('<Brand>', brand));
                setContinueText("https://" + marketingSpotData.baseMarketingSpotActivityData[1].marketingContentDescription[0].marketingText);
            })
                .catch(error => {
                    console.log("error: " + error)
                })
        };
    }, []);



    const onContinue = () => {
        props.onClose();
        window.open(continueText, "_blank")
    };

    // ------------------------- Final Return ------------------------------------------------------------------------
    return (
        <Modal
            open={props.open}
            onClose={() => props.onClose()}
            center
            className="ac-leaving-site-modal"
            dataClass="stat-button-close"
            dataDtm="rewards state 2-modal">
            <Translation>
                {(t) => (
                    <div className="leaving-site-modal" id={"gm-rewards-card-offer"}>
                        <div className="q-headline2">
                            <div className="context-message" dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                        <div className="btn-wrapper">
                            <div className="back-btn">
                                <Button
                                    className="stat-button-link"
                                    localeKey="Go Back"
                                    type="submit"
                                    onClick={() => props.onClose()}
                                    dataDtm="third party modal"
                                    dataDtm2="goBack"
                                    isSecondary={true} />
                            </div>
                            <div className="continue-btn">
                                <Button
                                    className="stat-button-link"
                                    localeKey="Continue"
                                    type="submit"
                                    onClick={() => onContinue()}
                                    dataDtm="third party modal"
                                    dataDtm2="continue" />
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        </Modal>
    )
};

export default LeavingSiteModal;
