import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/Button/Button";

/**
 * Cancel button to be rendered for the map view of the dealerization widget.
 * Closes modal and resets data.
 *
 * @param {*} props
 * dispatch: dispatch to update reducer state(s)
 * state: reducer state for dealerization widget
 * handleClose: handleClose function for modal
 *
 * @return {Button} <CancelButton/> component
 *
 */
export default function CancelButton(props) {
    const { t } = useTranslation();

    return (
        <Button
            id={"dealerization-cancel-btn"}
            className="gb-secondary-button"
            isSecondary={true}
            localeKey={t("LABEL_COMMON_CANCEL")}
            onClick={() => props.handleClose()}
            dataDtm="modal:dealer results"
        />
    );
}
/*** PROP TYPES VALIDATION ***/
CancelButton.propTypes = {
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object
};
