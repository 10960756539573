import * as type from "./FeaturedCategoriesActionType";

let initialState = {
    isFetching: false,
    categoriesAssets: [],
    categoriesTitle: ""
};

const FeaturedCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.LOAD_FEATURED_CATEGORIES_DATA_ASYNC:
            return { ...state, isFetching: true };

        case type.SET_FEATURED_CATEGORIES_DATA:
            return {
                ...state,
                isFetching: false,
                categoriesAssets: action.categoriesAssets,
                categoriesTitle: action.categoriesTitle
            };

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_FEATURED_CATEGORIES_DATA_ASYNC_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        case type.SET_FEATURED_CATEGORIES_DATA_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        default:
            return state;
    }
};

export default FeaturedCategoriesReducer;
