export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';
export const LIST_DATA_IN_PAGELOAD = 'LIST_DATA_IN_PAGELOAD';
export const LOAD_MORE = "LOAD_MORE";
export const RESET_PAGE_NUMBER = "RESET_PAGE_NUMBER";
export const LIST_DATA_ASYNC = "LIST_DATA_ASYNC";
export const LIST_DATA_INVALID_SEARCH = "LIST_DATA_INVALID_SEARCH";
export const LIST_DATA_IN_PAGELOAD_FAILED = "LIST_DATA_IN_PAGELOAD_FAILED";
export const UPDATE_SEARCH_RESULT_WITH_VOV_DATA = "UPDATE_SEARCH_RESULT_WITH_VOV_DATA";
export const UPDATE_FULL_SEARCH_WITH_VOV_DATA = "UPDATE_FULL_SEARCH_WITH_VOV_DATA";
export const REDIRECT_TO_PARTS_TILES = "REDIRECT_TO_PARTS_TILES"; 
export const NLS_LOAD_SPINNER = "NLS_LOAD_SPINNER";
export const LOCK_SEARCH = "LOCK_SEARCH";
export const SET_SHOP_WITHOUT_FITMENT = "SET_SHOP_WITHOUT_FITMENT";
export const SET_CURRENT_VEHICLE_ACTIVE = "SET_CURRENT_VEHICLE_ACTIVE";