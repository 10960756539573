import React, { Component } from "react";
import { connect } from 'react-redux';
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import store from '../../../../../../core/Redux/Store';
import AppSettings from "../../../../../../core/AppSettings";
import { FooterDisclaimerLabel } from '../../../../../App/Footer/FooterDisclaimerLabel';
import CouponsForm from "./CouponsForm";
import CouponsModal from "../../../../Modals/Coupons/CouponsModal";
import { couponCodePostAsync } from "../../../../CheckoutRedux/CheckoutActionCreator";
import ErrorMessage from "../../../../../../shared/ErrorMessage/ErrorMessage";
import { showSummaryRailSpinner, resetPromotionIdState } from "../../../../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator";

class Coupons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isDealer: false,
      dealerOffers: [],
      manuOffers: [],
      selectedOffers: []
    };

    this.showDealerOffers = this.showDealerOffers.bind(this);
    this.showManuOffers = this.showManuOffers.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.generateOffers = this.generateOffers.bind(this);
    this.applyPromotionCode = this.applyPromotionCode.bind(this);
    this.resetPromotionId = this.resetPromotionId.bind(this);
  }

  componentDidMount() {
    this.generateOffers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkoutData.couponDisplayData != this.props.checkoutData.couponDisplayData) {
      this.generateOffers();
    }
  }

  showDealerOffers() {
    this.setState({
      isDealer: true,
      selectedOffers: this.state.dealerOffers
    });
    this.toggleModal();
    this.resetPromotionId();
  }

  showManuOffers() {
    this.setState({
      isDealer: false,
      selectedOffers: this.state.manuOffers
    });
    this.toggleModal();
    this.resetPromotionId();
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  generateOffers() {
    const couponData = this.props.checkoutData.couponDisplayData;
    let manuData = [], dealerData = [];
    couponData.forEach(function (index) {
      if (index.GMOffers) {
        manuData = index.GMOffers;
      }
      if (index.DealerOffers) {
        dealerData = index.DealerOffers;
      }
    })
    this.setState({
      manuOffers: manuData,
      dealerOffers: dealerData
    });
  }

  applyPromotionCode(couponCode) {
    store.dispatch(showSummaryRailSpinner(true));
    store.dispatch(couponCodePostAsync(couponCode));
    this.toggleModal();
  }

  resetPromotionId() {
    if (this.props.checkoutData.promoIdMsg) {
      const clearError = resetPromotionIdState();
      store.dispatch(clearError);
    }
  }

  render() {
    let { showModal, manuOffers, dealerOffers } = this.state;
    // get disclaimer name for coupons
    const disclaimer = process.env.REACT_APP_DISCLAIMER_CHECKOUT_COUPON;

    return (
      <Translation>
        {
          (t) => (
            <div className={this.props.summaryRailsState.showSpinner ? 'ac-summary-rail-coupon-code ac-summary-rail-untouchable' : 'ac-summary-rail-coupon-code'}>
              <div className="ac-coupon-code">
                {/* Coupon Header */}
                <h4 className="q-headline">
                  <b>{t("LABEL_COUPON_CODE")}</b>
                  {/* get correct superscript label for the checkout coupon disclaimer in FooterDisclaimerLabel function component */}
                  <FooterDisclaimerLabel disclaimerName={disclaimer} children={label => <sup>{label}</sup>} />
                </h4>

                {/* Coupon Offer Links */}
                <div className="ac-coupon-links-wrapper">

                  {/* T3/Dealer: Display coupon modal link if any coupon offers */}
                  {dealerOffers.length > 0 &&
                    <div className="ac-coupon-offer-link-wrapper small-12 medium-12 large-12 columns">
                      <a className="ac-coupon-offer-link stat-text-link" data-dtm="checkout step2" onClick={this.showDealerOffers}>
                        <span>{t("LABEL_COUPON_VIEW_DEALER")}</span>
                        <span className="q-link-label2"><FontAwesomeIcon icon={faAngleRight} /></span>
                      </a>
                    </div>}

                  {/* T1 Only: Display coupon modal link if any coupon offers */}
                  {!AppSettings.isT3 && manuOffers.length > 0 &&
                    <div className="ac-coupon-offer-link-wrapper small-12 medium-12 large-12 columns">
                      <a className="ac-coupon-offer-link stat-text-link" data-dtm="checkout step2" onClick={this.showManuOffers}>
                        <span>{t("LABEL_COUPON_VIEW_MANUFACTURER")}</span>
                        <span className="q-link-label2"><FontAwesomeIcon icon={faAngleRight} /></span>
                      </a>
                    </div>}

                  {/* Display error message above form (e.g. duplicate coupon codes) */}
                  {this.props.checkoutData.promoIdMsg &&
                    <div className="columns ac-nopadding-x">
                      <ErrorMessage icon={true} message={this.props.checkoutData.promoIdMsg ? this.props.checkoutData.promoIdMsg : ''} />
                    </div>}
                  {/* Display error if user selected a coupon from coupon banner and is not being applied to order */}
                  {this.props.cartResponse.parsedResponse.cost.notAdjustedPromotions &&
                  this.props.cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage &&
                  <div className="columns ac-nopadding-x">
                    <ErrorMessage icon={true} message={this.props.cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage ? this.props.cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage : ''} />
                  </div>}
                </div>

                {/* Coupon Inline Input/Button Form */}
                <div className="row">
                  <CouponsForm
                    onSubmit={this.props.handleCouponCodeSubmit}
                    couponMsg={this.props.checkoutData.couponMsg ? this.props.checkoutData.couponMsg : ''}
                    showLoadingBtn={this.props.summaryRailsState.showLoadingBtn}
                  />
                </div>

                {/* Coupon Modal with Offers */}
                <CouponsModal
                  showModal={showModal}
                  toggleModal={this.toggleModal}
                  offersLabel={this.state.isDealer}
                  offers={this.state.selectedOffers}
                  apply={this.applyPromotionCode}
                />
              </div>
            </div>
          )}
      </Translation>
    )
  }
}

const mapStateToProps = state => {
  return {
    checkoutData: state.CheckoutReducer,
    cartResponse: state.OrderInfoReducer,
    summaryRailsState: state.RailsReducer
  }
}

export default connect(mapStateToProps)(Coupons);
