import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/Button/Button";
import { setDealerCookie, updateSelectedDealer } from "../../Data/ApiService";
import { storeSearchZip } from "../HelperFunctions";
import AppSettings from "../../../../core/AppSettings";
import vaultConstants from "../../../../../config/vault_constants";

/**
 * button is disabled until the user selects a dealer.
 * after being clicked, it makes an axios POST call to
 * "dealerize" the user.
 *
 * @param {*} props
 * state: dealerizationState reducer
 * dispatch: dealerizationState reducer updater
 *
 * @return {Button}
 * 'Save and Close' button for regular view of dealerization modal
 * 'Save' button for map view of dealerization modal
 */
export default function SaveAndCloseButton(props) {
    const { t } = useTranslation();
    /**
     * matches selected dealer's id with the all the dealers.
     * if there is a match, the new selected dealer name renders
     * for the 'You're Shopping...' button
     * @return {Object} dealer information
     */
    const matchSelectedToDealer = () => {
        let match;
        props.state.listOfAllSources.forEach((dealer) => {
            if (dealer.id === props.state.selectedSource || dealer.id.split("_")[1] === props.state.selectedSource) {
                match = dealer;
            }
        });
        return match;
    };

    const restApiContractsFeatureFlag = AppSettings.isLocalHost
    ? true
    : String(vaultConstants.FF_2243221_REST_API_CONTRACTS) === "true";


    const handleSaveAndClose = () => {
        props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: true });
        props.dispatch({ type: "TOGGLE_CLOSE", preventClose: true });
        props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
        let matchedDealer;
        let payload;
        if (props.state.selectedSource) {
            matchedDealer = matchSelectedToDealer();
            if (matchedDealer) {
                payload = { bac: [matchedDealer.bac], make: [matchedDealer.vendorMake] };
            }
        }
        // save and user did not select a dealer
        if (matchedDealer === undefined) {
            setDealerCookie("", "", props.state.currentZip);
            props.dispatch({ type: "TOGGLE_DEALER_MODAL", modalOpen: false });
            props.dispatch({ type: "UPDATE_SELECTED_SOURCE", selectedSource: "", selectedDealerAllData: "" });
            props.dispatch({ type: "UPDATE_CURRENT_SRC", currentSourceName: props.state.currentZip });
            props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
            props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
        } else if (matchedDealer !== undefined) {
            // save and close and user has a dealer selected.
            updateSelectedDealer(payload.bac[0]).then((response) => {
                if (response.status === 200) {
                    props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
                    props.dispatch({ type: "TOGGLE_DEALER_MODAL", modalOpen: false });
                    if (
                        (restApiContractsFeatureFlag && response.data.viewTaskName === "GMContractSetupView") ||
                        (!restApiContractsFeatureFlag &&
                            response.data ===
                                "<!-- GM B2C Dealer Contract setup test  page --><!-- setup the cookies with contract to get dealer price -->")
                    ) {
                        props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: true });
                    }
                    if (matchedDealer) {
                        props.dispatch({
                            type: "UPDATE_SELECTED_SOURCE",
                            selectedDealerAllData: matchedDealer,
                            selectedSource: props.state.selectedSource
                        });
                        props.dispatch({ type: "UPDATE_CURRENT_SRC", currentSourceName: matchedDealer.dealerName });
                    }
                    //Reset user input zip if seller or city were chosen - required for deliveryOptions call on PDP.
                    if (props.state.searchType === "Seller" || props.state.searchType === "City") {
                        props.dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: "" });
                        setDealerCookie(
                            JSON.stringify([matchedDealer.bac]),
                            JSON.stringify([matchedDealer.vendorMake]),
                            matchedDealer.zip ? matchedDealer.zip :
                            props.state.selectedDealerAllData ? props.state.selectedDealerAllData.zip : ""
                        );
                     
                    }
                    else {
                        setDealerCookie(
                            JSON.stringify([matchedDealer.bac]),
                            JSON.stringify([matchedDealer.vendorMake]),
                            props.state.currentZip ? props.state.currentZip : 
                            props.state.selectedDealerAllData ? props.state.selectedDealerAllData.zip : 
                            matchedDealer.zip ? matchedDealer.zip :
                            props.state.selectedDealerAllData ? props.state.selectedDealerAllData.zip : ""
                        );
                    }
                    // Bugfix2167495 - Zipcode needs to be set this way for City and Dealer searches. Otherwise add to cart would not work due to an error.
                    storeSearchZip( props.state.currentZip ? props.state.currentZip : 
                                    matchedDealer.zip ? matchedDealer.zip :
                                    props.state.selectedDealerAllData ? props.state.selectedDealerAllData.zip : "");
                } else {
                    /** ERRORS */
                    props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
                    props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
                    props.dispatch({ type: "TOGGLE_SAVE_ERROR", showSaveError: true });
                    //if modal is closed this value will be handled properly on reopen, but in this scenario(errors) modal isn't closed but save and close is resolved
                    props.dispatch({ type: "TOGGLE_CLOSE", preventClose: false });
                }
            });
        }
    };

    // ========= Render of Save and Close Button =============================================================
    return (
        <>
            <span>
                <Button
                    id="dealerization-save-close-btn"
                    className="gb-primary-button stat-button-link"
                    isSecondary={false}
                    localeKey={props.mapView ? t("LABEL_COMMON_SAVE") : t("LABEL_COMMON_SAVE_AND_CLOSE")}
                    onClick={() => handleSaveAndClose()}
                    disabled={props.cartFlag || props.state.showSpinner || !props.state.listOfAllSources.length}
                    dataDtm= "modal:dealer results"
                />
            </span>

            {props.state.showSaveError && (
                <div className="prt-dealerization-error-msg">
                    {t("LABLEL_DEALER_LOCATOR_SAVE_ERROR", { source: props.state.sellerSource })}
                </div>
            )}
        </>
    );
}

// ========= PropType Checking =============================================================================
SaveAndCloseButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired,
    cartFlag: PropTypes.bool.isRequired
};
