import i18next from "i18next";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { Redirect } from "react-router-dom";
import store from "../../../core/Redux/Store";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { appendCookieFitmentToUrl, constructProductUrl, jsonToUrlParams, mod } from "../../../shared/Utils/Utils";
import { getNLSSuggestions } from "../SearchService/SearchDataService";
import NLSSuggestions from "./NLSSuggestions";
import propTypes from "prop-types";
import axios from "axios";
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";

NaturalLanguageSearch.propTypes = {
    redirectFlag: propTypes.bool //Determines whether we're coming from Category Landing Page if so path needs to be changed to use /search
};

export const MIN_SEARCH_LENGTH = process.env.REACT_APP_NLS_MIN_SEARCH_LENGTH;

function NaturalLanguageSearch(props) {
    const parsedURL = queryString.parse(window.location.search);

    const [searchTerm, setSearchTerm] = useState(parsedURL.searchTerm ? parsedURL.searchTerm : "");
    const [suggestionRequests, setSuggestionRequests] = useState([]);
    const [searchSuggestions, setSearchSuggestions] = useState();
    const [searchErrorMessage, setSearchErrorMessage] = useState("");
    const [reloadUrl, setReloadUrl] = useState("");
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [activeSuggestionEvent, setActiveSuggestionEvent] = useState();
    const [superSessionFlowSeoUrl, setSuperSessionFlowSeoUrl] = useState("");
    const [supercededNumber, setSupercededNumber] = useState("");

    const OLD_PART_NUMBER_LENGTH = 8;

    useEffect(() => {
        if (reloadUrl !== "") {
            setReloadUrl("");
        }
    }, [reloadUrl]);

    function handleOnMouseMove(e) {
        const id = e.currentTarget.id;
        if (id && id.indexOf("ac-search-suggestion-item-") >= 0) {
            const newActiveSuggestion = id.substring(id.lastIndexOf("-") + 1);
            handleSetActiveSuggestion(newActiveSuggestion, false, "onMouseMove");
        } else {
            handleSetActiveSuggestion(0, false, "onMouseMove");
        }
    }

    function handleCategoryClick() {
        setSearchTerm("");
    }

    function dropdownContent() {
        if (
            searchTerm.trim().length >= MIN_SEARCH_LENGTH &&
            searchSuggestions &&
            searchSuggestions.suggestionView &&
            ((searchSuggestions.suggestionView.Product && searchSuggestions.suggestionView.Product.length > 0) ||
                (searchSuggestions.suggestionView.Category && searchSuggestions.suggestionView.Category.length > 0) ||
                (searchSuggestions.suggestionView.Keyword && searchSuggestions.suggestionView.Keyword.length > 0))
        ) {
            return (
                <>
                    <MediaQuery query="(max-width: 59.99em)">
                        <NLSSuggestions
                            searchTerm={searchTerm.trim()}
                            setSearchTerm={setSearchTerm}
                            searchSuggestions={limitSuggestions(searchSuggestions, true)}
                            oldPartNumber={searchSuggestions.oldPartNumber}
                            onMouseMove={handleOnMouseMove}
                            handleCategoryClick={handleCategoryClick}
                        />
                    </MediaQuery>
                    <MediaQuery query="(min-width: 60em)">
                        <NLSSuggestions
                            searchTerm={searchTerm.trim()}
                            setSearchTerm={setSearchTerm}
                            searchSuggestions={limitSuggestions(searchSuggestions, false)}
                            oldPartNumber={searchSuggestions.oldPartNumber}
                            onMouseMove={handleOnMouseMove}
                            handleCategoryClick={handleCategoryClick}
                        />
                    </MediaQuery>
                </>
            );
        } else {
            return null;
        }
    }

    function isValidSearchTerm(searchTerm) {
        return searchTerm && (searchTerm.length == 0 || searchTerm.length >= MIN_SEARCH_LENGTH);
    }

    function filterCategories(searchSuggestions) {
        const suggestionCategories = searchSuggestions.suggestionView.Category;
        const navCategories =
            props.categoryNavData &&
            props.categoryNavData.parsedCategories &&
            props.categoryNavData.parsedCategories[0].categories;
        if (suggestionCategories && suggestionCategories.length > 0 && navCategories && navCategories.length > 0) {
            // Create an object with entries of our suggestedCategories for constant look up time
            const suggestionCategoryIds = {};

            for (const category of suggestionCategories) {
                suggestionCategoryIds[`${category.value}`] = category;
            }

            // Add categories that exist in both lists to this array
            const allMatchingCategories = [];

            // Iterate over nav parent categories
            for (const parentCategory of navCategories) {
                // Check between the parentCategories and suggestedCategoryIds if there is a match
                const parentId = parentCategory.uniqueId;
                if (suggestionCategoryIds.hasOwnProperty(parentId)) {
                    allMatchingCategories.push(suggestionCategoryIds[parentId]);

                    // Sort and return all found matches
                    if (allMatchingCategories.length === Object.keys(suggestionCategoryIds).length) {
                        return {
                            suggestionView: {
                                ...searchSuggestions.suggestionView,
                                Category: allMatchingCategories.sort(
                                    (a, b) => suggestionCategories.indexOf(a) - suggestionCategories.indexOf(b)
                                )
                            }
                        };
                    }
                }

                // Iterate over the parent sub categories
                for (const subCategory of parentCategory.subCategories) {
                    // Check between subCategories and suggestedCategoryIds if there is a match
                    const subId = subCategory.uniqueId;
                    if (suggestionCategoryIds.hasOwnProperty(subId)) {
                        allMatchingCategories.push(suggestionCategoryIds[subId]);

                        // Sort and return all found matches
                        if (allMatchingCategories.length === Object.keys(suggestionCategoryIds).length) {
                            return {
                                suggestionView: {
                                    ...searchSuggestions.suggestionView,
                                    Category: allMatchingCategories.sort(
                                        (a, b) => suggestionCategories.indexOf(a) - suggestionCategories.indexOf(b)
                                    )
                                }
                            };
                        }
                    }
                }
            }
            // Return what matches were found
            return {
                suggestionView: {
                    ...searchSuggestions.suggestionView,
                    Category: allMatchingCategories.sort(
                        (a, b) => suggestionCategories.indexOf(a) - suggestionCategories.indexOf(b)
                    )
                }
            };
        } else {
            // No categories were suggested, so just return the suggestion data untouched
            return searchSuggestions;
        }
    }

    function limitSuggestions(searchSuggestions, mobile) {
        if (searchSuggestions && searchSuggestions.suggestionView) {
            if (mobile) {
                return getMobileSuggestions(searchSuggestions);
            }

            const limit = process.env.REACT_APP_NLS_AUTOSUGGEST_SECTION_LIMIT;
            if (searchSuggestions.suggestionView.Product && searchSuggestions.suggestionView.Product.length > limit) {
                searchSuggestions.suggestionView.Product = searchSuggestions.suggestionView.Product.slice(0, limit);
            }
            if (searchSuggestions.suggestionView.Category && searchSuggestions.suggestionView.Category.length > limit) {
                searchSuggestions.suggestionView.Category = searchSuggestions.suggestionView.Category.slice(0, limit);
            }
            if (searchSuggestions.suggestionView.Keyword && searchSuggestions.suggestionView.Keyword.length > limit) {
                searchSuggestions.suggestionView.Keyword = searchSuggestions.suggestionView.Keyword.slice(0, limit);
            }

            return searchSuggestions;
        }
    }

    // Show one result from each suggestion category until the limit is reached
    function getMobileSuggestions(searchSuggestions) {
        const limit = process.env.REACT_APP_NLS_AUTOSUGGEST_MOBILE_LIMIT;
        const mobileSuggestions = {
            Product: [],
            Category: [],
            Keyword: []
        };
        let count = 0;
        let i = 0;
        while (count < limit && i < limit) {
            if (
                count < limit &&
                searchSuggestions.suggestionView.Product &&
                searchSuggestions.suggestionView.Product[i]
            ) {
                mobileSuggestions.Product.push(searchSuggestions.suggestionView.Product[i]);
                count++;
            }
            if (
                count < limit &&
                searchSuggestions.suggestionView.Category &&
                searchSuggestions.suggestionView.Category[i]
            ) {
                mobileSuggestions.Category.push(searchSuggestions.suggestionView.Category[i]);
                count++;
            }
            if (
                count < limit &&
                searchSuggestions.suggestionView.Keyword &&
                searchSuggestions.suggestionView.Keyword[i]
            ) {
                mobileSuggestions.Keyword.push(searchSuggestions.suggestionView.Keyword[i]);
                count++;
            }
            i++;
        }

        return { suggestionView: mobileSuggestions };
    }

    function handleOnChange(e) {
        setSearchTerm(e.currentTarget.value);
        setSearchErrorMessage("");
        if (e.currentTarget.value.trim().length >= MIN_SEARCH_LENGTH) {
            // Set up params
            const params = {
                bodyNumDoors: parsedURL.bodyNumDoors,
                bodyType: parsedURL.bodyId,
                catalogId: "10052",
                currency: "USD",
                driveType: parsedURL.driveId,
                engineBase: parsedURL.engineId,
                langId: "-1",
                limit: Math.max(
                    process.env.REACT_APP_NLS_AUTOSUGGEST_SECTION_LIMIT,
                    process.env.REACT_APP_NLS_AUTOSUGGEST_MOBILE_LIMIT
                ),
                make: parsedURL.make,
                model: parsedURL.model,
                searchTerm: e.currentTarget.value.trim(),
                trim: parsedURL.trimId,
                vin: parsedURL.vin,
                wheelBase: parsedURL.wheelId,
                year: parsedURL.year
            };

            // Make service call
            const date = new Date();
            const superSessionflag = AppSettings.isLocalHost ? true : vaultConstants.FF_2134531_SUPERSESSION_SERVICE;
            const key = date.valueOf();
            suggestionRequests.push(key);
            // Set this to blank to prevent supersession flow if a superceded part number was searched and found,
            // but deleted  the number, then searched for something else and pressed enter.
            setSuperSessionFlowSeoUrl("");
            getNLSSuggestions(params)
                .then((response) => {
                    if (suggestionRequests[suggestionRequests.length - 1] === key) {
                        if (response.data && response.status === 200) {
                            // Condition for when SuperSession will be called
                            if (
                                superSessionflag &&
                                !response.data.suggestionView.Product &&
                                params.searchTerm.length == OLD_PART_NUMBER_LENGTH
                            ) {
                                params.searchTerm = callSuperSession(params);
                            }
                            setSearchSuggestions(filterCategories(response.data));
                        } else {
                            console.log("Unable to retrieve search suggestions");
                        }
                    }
                })
                .catch((error) => {
                    if (String(vaultConstants.API_TARGETABLE).includes("dev")) {
                        console.log("Unable to retrieve search suggestions:", error);
                    }
                });
        }
    }

    /**
     * Takes an old part number. Calls SuperSession to get new part number. Updates search results
     * searchTerm is the only param used here, but all params are passed to reuse getNLSSuggestions call
     * @param  bodyNumDoors {string}
     * @param bodyType {string}
     * @param  catalogId {string}
     * @param currency {string}
     * @param  engineBase {string}
     * @param langId {string}
     * @param  limit {int}
     * @param make {string}
     * @param  model {string}
     * @param searchTerm {string}
     * @param trim {string}
     * @param vin {string}
     * @param wheelBase {string}
     * @param year {string}
     * @returns newPartnumber or oldPartNumber
     */

    const callSuperSession = async (params) => {
        const oldPartNumber = params.searchTerm;
        const newPartNumber = await getNewPartNumberFromOldPartNumber(oldPartNumber);
        if (newPartNumber) {
            params.searchTerm = newPartNumber;
            await getNLSSuggestions(params).then((response) => {
                if (response.data && response.status === 200) {
                    let tempSearch = filterCategories(response.data);
                    tempSearch.oldPartNumber = oldPartNumber;
                    setSearchSuggestions(tempSearch);
                    // Needed for pressing enter with an item that was superceded since it technically does not exist, they were not redirected to PDP.
                    if (response.data?.suggestionView?.Product?.length) { 
                        setSuperSessionFlowSeoUrl(response.data?.suggestionView?.Product[0]?.seoUrl);
                    }
                    setSupercededNumber(oldPartNumber);
                }
            });
            return newPartNumber;
        } else {
            return oldPartNumber;
        }
    };
    const getNewPartNumberFromOldPartNumber = async (oldPartNumber) => {
        const url = `/rest/custom/supersession/byheadparts/${oldPartNumber}?country=us&supersessionType=GM`;
        const response = await axios.get(url);
        if (response.data.errorCode || response.status != 200) {
            return null;
        }
        return response.data.headParts[0].newPartNumber;
    };

    function handleOnSubmit(e) {
        e.preventDefault();

        if (!isValidSearchTerm(searchTerm.trim())) {
            setSearchErrorMessage(i18next.t("LABEL_SEARCH_NLS_ERROR_MESSAGE"));
        } else {
            // Reset things
            store.dispatch({
                type: "RESET_PAGE_NUMBER"
            });
            setSearchErrorMessage("");
            document.activeElement.blur();

            // Update url & reload
            if (superSessionFlowSeoUrl) {
                const productUrl = constructProductUrl(
                    superSessionFlowSeoUrl,
                    { bodyNumDoors: parsedURL.bodyNumDoors },
                    parsedURL
                );

                // Needed to show the replaces text in PDP when the product was superceded.
                sessionStorage.setItem("supersessionOldPartNumber", supercededNumber);
                // If in PDP, Redirect would not reload the page, so this had to be done.
                if (AppSettings.pageName == "Product") {
                    window.location.href = productUrl;
                    return;
                }
                setReloadUrl(productUrl);
                return;
            }

            let params = { ...parsedURL };
            // Needed for homepage search so that fitment data is appended when cookie data exists. 
            if (AppSettings.pageName == "HomePage") {
                params = appendCookieFitmentToUrl(params, props.sessionData);
            }

            params.searchTerm = searchTerm.trim();

            if (params.categoryId) {
                delete params.categoryId;
            }

            props.redirectFlag == true ||
            location.pathname == "/product/search" ||
            location.pathname == "/categories/search"
                ? setReloadUrl(AppSettings.dealerName + "/search" + jsonToUrlParams(params))
                : setReloadUrl("search" + jsonToUrlParams(params));
        }
        setSearchTerm("");
    }

    function handleOnFocus() {
        setSearchSuggestions(null);
        setSuggestionRequests([]);
        handleSetActiveSuggestion(0, false, "onFocus");
    }

    function handleOnClick() {
        handleSetActiveSuggestion(0, false, "onClick");
    }

    function handleSetActiveSuggestion(newActiveSuggestion, scrollIntoView, event) {
        // Save old active suggestion
        const oldActiveSuggestion = activeSuggestion;
        if (newActiveSuggestion != oldActiveSuggestion) {
            const element =
                newActiveSuggestion == 0
                    ? document.getElementById("ac-nls-form")
                    : document.getElementById(`ac-search-suggestion-item-${newActiveSuggestion}`);

            // Add focus styling
            if (newActiveSuggestion != 0) {
                // The classList property is not supported in IE9 and earlier
                // element.classList.add('ac-search-suggestion-focus');
                let className = element.className;
                if (className.indexOf("ac-search-suggestion-focus") < 0) {
                    className += (className.length > 0 ? " " : "") + "ac-search-suggestion-focus";
                    element.className = className;
                }
            }

            // Remove focus styling from previous active suggestion
            if (oldActiveSuggestion != 0) {
                // The classList property is not supported in IE9 and earlier
                // document.getElementById(`ac-search-suggestion-item-${oldActiveSuggestion}`).classList.remove('ac-search-suggestion-focus');
                const oldElement = document.getElementById(`ac-search-suggestion-item-${oldActiveSuggestion}`);
                if (oldElement) {
                    let className = oldElement.className;
                    const activeClassName = "ac-search-suggestion-focus";
                    const i = className.indexOf(activeClassName);
                    if (i >= 0) {
                        className = className.substring(0, i) + className.substring(i + activeClassName.length);
                        oldElement.className = className;
                    }
                }
            }

            // Scroll to the active suggestion
            if (scrollIntoView) {
                // returns true if browser supports smooth scrolling/scroll behavior options -- mostly need this for ie browser
                // which doesn't support full scrollBy options
                const supportsSmoothScrolling = "scrollBehavior" in document.documentElement.style;
                const bufferSpace = 50;
                const navbarHeight = document.getElementById("navbar").offsetHeight;
                if (element.getBoundingClientRect().bottom > window.innerHeight - bufferSpace) {
                    if (supportsSmoothScrolling) {
                        window.scrollBy({
                            top: element.getBoundingClientRect().bottom - (window.innerHeight - bufferSpace),
                            left: 0,
                            behavior: "smooth"
                        });
                    } else {
                        window.scrollBy(0, element.getBoundingClientRect().bottom - (window.innerHeight - bufferSpace));
                    }
                }

                if (element.getBoundingClientRect().top < navbarHeight + bufferSpace) {
                    if (supportsSmoothScrolling) {
                        window.scrollBy({
                            top: element.getBoundingClientRect().top - (navbarHeight + bufferSpace),
                            left: 0,
                            behavior: "smooth"
                        });
                    } else {
                        window.scrollBy(0, element.getBoundingClientRect().top - (navbarHeight + bufferSpace));
                    }
                }
            }

            // Set state
            setActiveSuggestion(newActiveSuggestion);
            setActiveSuggestionEvent(event);
        }
    }

    function handleArrowKey(direction) {
        const modification = direction === "up" ? -1 : 1;
        const numSuggestions = document.getElementsByClassName("ac-search-suggestion-item").length;
        const newActiveSuggestion = mod(parseInt(activeSuggestion) + modification, numSuggestions + 1);
        handleSetActiveSuggestion(newActiveSuggestion, true, "onKeyDown");
    }

    function handleOnKeyDown(e) {
        if (searchSuggestions) {
            switch (e.key) {
                case "ArrowDown":
                    e.preventDefault();
                    handleArrowKey("down");
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    handleArrowKey("up");
                    break;
                case "Tab":
                    e.preventDefault();
                    const direction = e.shiftKey ? "up" : "down";
                    handleArrowKey(direction);
                    break;
                case "Shift":
                    break;
                case "Enter":
                    e.preventDefault();
                    if (activeSuggestion == 0 || activeSuggestionEvent != "onKeyDown") {
                        handleOnSubmit(e);
                    } else {
                        // Get url behind active element & reload
                        // const linkElement = document.getElementById(`ac-search-suggestion-item-${activeSuggestion}`);
                        // const url = linkElement.pathname + linkElement.search;
                        // setReloadUrl(url);
                        // setSearchTerm(linkElement.innerText);
                        document.getElementById(`ac-search-suggestion-item-${activeSuggestion}`).click();
                    }
                default:
                    handleSetActiveSuggestion(0, true, "onKeyDown");
            }
        }
    }

    if (reloadUrl) {
        return <Redirect push to={reloadUrl} />;
    } else {
        return (
            <div id="ac-nls-form" className="small-margin">
                <SearchBar
                    value={searchTerm}
                    placeholder={i18next.t("LABEL_SEARCH_NLS_INPUT_PLACEHOLDER")}
                    dropdownContent={dropdownContent()}
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                    onFocus={handleOnFocus}
                    onClick={handleOnClick}
                    onKeyDown={handleOnKeyDown}
                    errorMessage={searchErrorMessage}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categoryNavData: state.CategoryNavReducer,
        sessionData: state.Session
    };
};

export default connect(mapStateToProps)(NaturalLanguageSearch);
