import React, { useState, useEffect } from "react";
import { reduxForm } from "redux-form";
import AppSettings from "../../../core/AppSettings";
import Select from "../../../shared/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    selectMake,
    selectModel,
    selectYear
} from "../../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
import {
    loadVehicleCombosAsync,
    resetVehicleConfig
} from "../../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resetVehicleSelectState } from "../../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
/**
 * Taken and updated from vehicleselectform.js to separate YMM from VIN.
 *
 * @param {*} props
 * @returns Year, Make, and Model dropdowns
 */
function InlineFitmentYMM(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const vehicleInfoData = useSelector((state) => state.VehicleSelectReducer);
    const vehicleReducerInfo = useSelector((state) => state.Session);
    const [Action, setAction] = useState(false);
    const {
        years,
        selectedYear,
        selectedMake,
        codeToMake,
        yearsToMakes,
        selectedModel,
        yearsToModels,
        showLoadModelsError,
        showLoadMakesError
    } = vehicleInfoData;

    const models = yearsToModels[selectedYear];
    const makes = yearsToMakes[selectedYear];

    const isMakeSelectDisabled = () => selectedYear == null || selectedYear == "" || selectedYear == "Year";

    const isModelSelectDisabled = () =>
        isMakeSelectDisabled() || selectedMake == null || selectedMake == "" || selectedMake == "Make";

    //Temporary use case for inline fitment since we would need the ability to shop w/o fitment.
    useEffect(() => {
        dispatch(resetVehicleConfig());
        dispatch(resetVehicleSelectState());

        // If selected values are preset, handle change to pre-populate each
        if (selectedYear) {
            handleYearChange(selectedYear);
        }
        if (selectedMake) {
            handleMakeChange(selectedMake, vehicleReducerInfo.makeCode);
        }
        if (selectedModel) {
            handleModelChange(selectedModel, vehicleReducerInfo.vehicleConfig.modelId);
        }
    }, []);

    // If reached from click, e has a target value. If reached from pre-pop, e will be the relevant data.
    const handleYearChange = (e) => {
        let year;
        if (e.target) {
            year = e.target.value;
        } else {
            year = e;
        }
        dispatch(selectYear(year));
    };

    const handleMakeChange = (e) => {
        let make;
        if (e.target) {
            make = e.target.value;
        } else {
            make = e;
        }
        dispatch(selectMake(make));
    };

    const handleModelChange = (e) => {
        let model;
        if (e.target) {
            model = e.target.value;
        } else {
            model = e;
        }
        dispatch(selectModel(model));
        dispatch(loadVehicleCombosAsync());
    };

    const ModelSelect = () => {
        return (
            <>
                <h5 className="inline-ymm-headers">{t("LABEL_COMMON_CHOOSE_MODEL")}</h5>
                <Select
                    disabled={isModelSelectDisabled() || showLoadModelsError || !models}
                    defaultValue={t("LABEL_COMMON_CHOOSE_MODEL")}
                    value={selectedModel}
                    options={models}
                    onClick={handleModelChange}
                    dataDtm={"vehicle fit:model"}
                />
                {showLoadModelsError && (
                    <label className="error ac-error-label">
                        <FontAwesomeIcon icon="exclamation-circle" />
                        {t("LABEL_COMMON_CHOOSE_MODEL_LOAD_ERROR")}
                    </label>
                )}
            </>
        );
    };

    const vehicleSelectDropdownClass = (classes) => {
        let dropdownClasses = "small-12 medium-6 grid-column-alignment-left q-grid-row-new-mobile columns ";

        // add extra grid class for T3/dealer
        AppSettings.isT3 ? (dropdownClasses += "xlarge-4 ") : "";

        // check for extra classes
        classes ? (dropdownClasses += classes) : "";

        return dropdownClasses;
    };

    const MakeSelect = () => {
        const value = codeToMake[selectedMake] || selectedMake;
        return (
            <div>
                <h5 className="inline-ymm-headers">{t("LABEL_COMMON_CHOOSE_MAKE")}</h5>
                <Select
                    disabled={isMakeSelectDisabled() || showLoadMakesError || !makes}
                    defaultValue={t("LABEL_COMMON_CHOOSE_MAKE")}
                    value={value}
                    options={makes}
                    showError={showLoadMakesError}
                    onClick={handleMakeChange}
                    dataDtm={"vehicle fit:make"}
                />
                {showLoadMakesError && (
                    <label className="error ac-error-label">
                        <FontAwesomeIcon icon="exclamation-circle" />
                        {t("LABEL_COMMON_CHOOSE_MAKE_LOAD_ERROR")}
                    </label>
                )}
            </div>
        );
    };

    return (
        <section id="inline-fitment-section">
            <div className="inline-main-area">
                <div>
                    <h5 className="inline-ymm-headers">{t("LABEL_COMMON_CHOOSE_YEAR")}</h5>
                    <Select
                        defaultValue={t("LABEL_COMMON_CHOOSE_YEAR")}
                        value={selectedYear}
                        options={years}
                        onClick={handleYearChange}
                        dataDtm={"vehicle fit:model year"}
                    />
                </div>

                <div>{AppSettings.isT3 && <MakeSelect />}</div>
                <div>
                    <ModelSelect />
                </div>
            </div>
        </section>
    );
}

export default reduxForm({
    form: "InlineFitmentYMM",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(InlineFitmentYMM);
