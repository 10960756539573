/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_PRODUCT_DETAILS_ASYNC = 'LOAD_PRODUCT_DETAILS_ASYNC';

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const SET_PRODUCT_IDENTIFIER = 'SET_PRODUCT_IDENTIFIER';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const SET_PRODUCT_DETAILS_ITEM_PRICE = 'SET_PRODUCT_DETAILS_ITEM_PRICE';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_PRODUCT_DETAILS_ASYNC_FAIL = 'LOAD_PRODUCT_DETAILS_ASYNC_FAIL';
export const SET_PRODUCT_DETAILS_FAIL = 'SET_PRODUCT_DETAILS_FAIL';

/**************************************************************/
// User actions (handle in Reducer switch statement) 
/**************************************************************/
export const ADD_TO_CART_ASYNC = 'ADD_TO_CART_ASYNC';
export const ADD_TO_CART = 'ADD_TO_CART';
export const SHOW_ADD_TO_CART_ERROR = 'SHOW_ADD_TO_CART_ERROR';
export const PRODUCT_DETAILS_GO_TO_CART = 'PRODUCT_DETAILS_GO_TO_CART';
export const RESET_LOADING_BUTTON = 'RESET_LOADING_BUTTON';
export const SET_QUANTITY = 'SET_QUANTITY';

/**************************************************************/
// Action to handle reseting page to empty values on unmount
/**************************************************************/
export const PRODUCT_DETAILS_RESET_PAGE = 'PRODUCT_DETAILS_RESET_PAGE';

/**************************************************************/
// Action to handle setting the flag to show the VoV Icon
/**************************************************************/
export const UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA = "UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA";

/**************************************************************/
// Actions to support delivery options consistency
/**************************************************************/
export const SET_TOAST_MESSAGE_ERROR = 'SET_TOAST_MESSAGE_ERROR';
export const SET_SUPPRESS_PDP = 'SET_SUPPRESS_PDP';
export const SET_NATIONAL_ORDER_WRITING_CONTROL = 'SET_NATIONAL_ORDER_WRITING_CONTROL';
export const SET_DELIVERY_OPTIONS = 'SET_DELIVERY_OPTIONS';
export const SET_DELIVERY_OPTIONS_LOADING_STATE = 'SET_DELIVERY_OPTIONS_LOADING_STATE';

/**************************************************************/
// Action to handle redux flow with delivery option
/**************************************************************/
export const SET_SHIP_MODE = 'SET_SHIP_MODE';
