/* Helper functions */

export function addBodyFitmentSelection(record, bodyFitmentSelection) {
  let bodyFound = false;
  // parse json for Body selections
  const bodyObject = {
    BODY_NUM_DOORS: record.BODY_NUM_DOORS,
    BODY_NUM_DOORS_ID: record.BODY_NUM_DOORS_ID,
    BODY_TYPE_NAME: record.BODY_TYPE_NAME,
    BODY_TYPE_ID: record.BODY_TYPE_ID,
    BODY_LABEL: record.BODY_NUM_DOORS + ' Door - ' + record.BODY_TYPE_NAME,
  };

  // set bodyFound to true if bodyFitmentSelection > 0
  if (bodyFitmentSelection.length > 0) {
    for (let indexBody = 0; indexBody <
    bodyFitmentSelection.length; indexBody++) {
      if (record.BODY_NUM_DOORS_ID ==
          bodyFitmentSelection[indexBody].BODY_NUM_DOORS_ID &&
          record.BODY_TYPE_ID == bodyFitmentSelection[indexBody].BODY_TYPE_ID) {
        bodyFound = true;
        break;
      }
    }
  }
  if (bodyFound == false) {
    bodyFitmentSelection.push(bodyObject);
  }
}

export function addEngineFitmentSelection(record, engineFitmentSelection) {
  let engineFound = false;
  let label = record.ENGINE_SIZE +
      'L ' +
      record.BLOCK_TYPE +
      record.CYLINDER_COUNT +
      ' ' +
      record.FUEL_TYPE;
  let engineObject = {
    FUEL_TYPE: record.FUEL_TYPE,
    CYLINDER_COUNT: record.CYLINDER_COUNT,
    BLOCK_TYPE: record.BLOCK_TYPE,
    ENGINE_SIZE: record.ENGINE_SIZE,
    ENGINE_BASE_ID: record.ENGINE_BASE_ID,
    ENGINE_LABEL: label,
  };

  if (engineFitmentSelection.length > 0) {
    for (let indexEngine = 0; indexEngine <
    engineFitmentSelection.length; indexEngine++) {
      if (record.ENGINE_BASE_ID ==
          engineFitmentSelection[indexEngine].ENGINE_BASE_ID) {
        engineFound = true;
        break;
      }
    }
  }
  if (engineFound == false) {
    engineFitmentSelection.push(engineObject);
  }
  return engineFitmentSelection;
}

export function addDriveFitmentSelection(record, driveFitmentSelection) {
  let driveFound = false;
  let driveObject = {
    DRIVE_TYPE_NAME: record.DRIVE_TYPE_NAME,
    DRIVE_TYPE_ID: record.DRIVE_TYPE_ID,
  };
  if (driveFitmentSelection.length > 0) {
    for (let indexDrive = 0; indexDrive <
    driveFitmentSelection.length; indexDrive++) {
      if (record.DRIVE_TYPE_ID ==
          driveFitmentSelection[indexDrive].DRIVE_TYPE_ID) {
        driveFound = true;
        break;
      }
      ;
    }
  }
  if (driveFound == false) {
    driveFitmentSelection.push(driveObject);
  }
}

export function addTrimFitmentSelection(record, trimFitmentSelection) {
  let trimFound = false;
  let trimObject = {
    SUBMODEL_NAME: record.SUBMODEL_NAME,
    SUBMODEL_ID: record.SUBMODEL_ID,
  };
  if (trimFitmentSelection.length > 0) {
    for (let indexTrim = 0; indexTrim <
    trimFitmentSelection.length; indexTrim++) {
      if (record.SUBMODEL_ID == trimFitmentSelection[indexTrim].SUBMODEL_ID) {
        trimFound = true;
        break;
      }
      ;
    }
  }
  if (trimFound == false) {
    trimFitmentSelection.push(trimObject);
  }
}

export function addWheelFitmentSelection(record, wheelFitmentSelection) {
  let wheelFound = false;

  let wheelObject = {
    WHEEL_LABEL: '',
    WHEEL_DROPDOWN_LABEL: '',
    WHEEL_BASE: record.WHEEL_BASE,
    WHEEL_BASE_ID: record.WHEEL_BASE_ID,
    WHEEL_BASE_OVERRIDE: record.WHEEL_BASE_OVERRIDE,
    DROP_DOWN_NAME: record.DROP_DOWN_NAME,
  };

  if (wheelObject.WHEEL_BASE_OVERRIDE) {
    wheelObject.WHEEL_LABEL = wheelObject.WHEEL_BASE_OVERRIDE;
  } else {
    wheelObject.WHEEL_LABEL = wheelObject.WHEEL_BASE;
  }

  if (wheelObject.DROP_DOWN_NAME) {
    wheelObject.WHEEL_DROPDOWN_LABEL = wheelObject.DROP_DOWN_NAME;
  } else {
    wheelObject.WHEEL_DROPDOWN_LABEL = 'Wheel Base';
  }

  if (wheelFitmentSelection.length > 0) {
    for (let indexWheel = 0; indexWheel <
    wheelFitmentSelection.length; indexWheel++) {
      if (record.WHEEL_BASE_ID ==
          wheelFitmentSelection[indexWheel].WHEEL_BASE_ID) {
        wheelFound = true;
        break;
      }
    }
  }
  if (wheelFound == false) {
    wheelFitmentSelection.push(wheelObject);
  }
}