import React from 'react'
import i18next from 'i18next';
import AddToCart from '../../../modules/ProductDetails/AddToCart/AddtoCart';
import { withRouter } from "react-router-dom";
import AppSettings from '../../../core/AppSettings';

const ViewDetailsButton = (props) => {
    return (
        <a href={props.productDetailsLink} className="q-button q-primary-button gm-primary-btn">
            {i18next.t("LABEL_SEARCH_VIEW_DETAILS")}
        </a>
    );
}

const AddToCartButton = (props) => {
    const { addToCartData } = props;
    return (
        <AddToCart
            vin={addToCartData.vin}
            showAddToCartError={addToCartData.showAddToCartError}
            showLoadingBtn={addToCartData.showLoadingBtn}
            name={addToCartData.name}
            dataDtm={"vov:"+addToCartData.name}
        />
    );
}

const ViewOnVehicleButton = (props) => {
    const splitPath = props.location.pathname.split('/');
    for (let dir of splitPath) {
        let path = `/${dir}`;
        switch (path) {
            case AppSettings.routePaths.search:
                return <ViewDetailsButton productDetailsLink={props.productDetailsLink} />
            case AppSettings.routePaths.product:
                return <AddToCartButton addToCartData={props.addToCartData} />
        }
    }
    return <ViewDetailsButton productDetailsLink={props.productDetailsLink} />
};

export default withRouter(ViewOnVehicleButton);