/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_HOME_CATEGORIES_DATA_ASYNC = 'LOAD_HOME_CATEGORIES_DATA_ASYNC';

/**************************************************************/
// Actions to set data (handle in HomeCarouselReducer switch statement) 
/**************************************************************/
export const SET_HOME_CATEGORIES_DATA = 'SET_HOME_CATEGORIES_DATA';

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const SELECTED_CATEGORY_TITLE = 'SELECTED_CATEGORY_TITLE';
export const RESET_HOME_CATEGORY = 'RESET_HOME_CATEGORY';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_HOME_CATEGORIES_DATA_ASYNC_FAIL = "LOAD_HOME_CATEGORIES_DATA_ASYNC_FAIL";
export const SET_HOME_CATEGORIES_DATA_FAIL = 'SET_HOME_CATEGORIES_DATA_FAIL';