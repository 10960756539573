import * as type from './FooterActionType';

var initialState = {
  slogan: '',
  links: '',
  text: '',
  loading: true,
  sequence: []
}

const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LINKS:
      return Object.assign({}, state, { links: action.links });

    case type.SET_SLOGAN:
      return Object.assign({}, state, { slogan: action.slogan });

    case type.SET_TEXT:
      return Object.assign({}, state, { text: action.text });

    case type.SET_SEQUENCE:
      return Object.assign({}, state, { sequence: action.sequence });

    case type.SET_LOADING:
      return Object.assign({}, state, { loading: action.loading });

    case type.SET_LINKS_FAIL:
      return Object.assign({}, state, { links: '' });

    case type.SET_SLOGAN_FAIL:
      return Object.assign({}, state, { slogan: '' });

    case type.SET_TEXT_FAIL:
      return Object.assign({}, state, { text: '' });

    case type.SET_SEQUENCE_FAIL:
      return Object.assign({}, state, { sequence: '' });

    default:
      return state;
  }
}

export default FooterReducer;