import * as type from "./VehicleInfoActionType";
import queryString from "query-string";

var initialState = {
    year: "",
    make: "",
    makeCode: "",
    model: "",
    modelCode: "",
    vin: "",
    bodyStyle: "",
    wheelBase: "",
    trim: "",
    driveType: "",
    bodyNumDoors: "",
    engine: "",
    engineBase: "",
    bodyStyleName: "",
    driveTypeName: "",
    trimName: "",
    wheelBaseName: "",
    bodyAndWheel: "",
    vcdb: {
        makeId: "",
        modelId: "",
        subModelId: "",
        engineConfigId: "",
        bodyStyleConfigId: "",
        baseVehicleId: "",
        vehicleId: "",
        wheelBaseId: "",
        driveTypeId: "",
        bodyStyle: "",
        driveType: "",
        trim: "",
        bodyStyleName: "",
        driveTypeName: "",
        trimName: "",
        wheelBaseName: ""
    },
    vehicleConfig: {}
};

// Get year, make, and model information from the current state.
// export const getYear = state => state.VehicleSelectReducer.selectedYear;
// export const getMake = state => state.VehicleSelectReducer.selectedMake;
// export const getModel = state => state.VehicleSelectReducer.selectedModel;
// export const getVin = state => state.VehicleSelectReducer.vin;
// export const getBodyStyle = state => state.VehicleSelectReducer.bodyStyle;
// export const getWheelBase = state => state.VehicleSelectReducer.wheelBase;
// export const getTrim = state => state.VehicleSelectReducer.trim;
// export const getDriveType = state => state.VehicleSelectReducer.driveType;

const makeToCode = {
    Buick: "45",
    Cadillac: "46",
    Chevrolet: "47",
    GMC: "48"
};

const VehicleInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_YEAR_MAKE_MODEL:
            return Object.assign({}, state, {});

        case type.SET_VIN_ASYNC:
            return Object.assign({}, state, {});

        case type.SET_VIN_SEARCH:
            const makeForCode = action.make;
            const makeToTheCode = makeToCode[makeForCode];

            return Object.assign({}, state, {
                year: action.year,
                make: action.make,
                makeCode: makeToTheCode,
                model: action.model,
                vin: action.vin,
                bodyStyle: action.bodyStyle,
                wheelBase: action.wheelBase,
                trim: action.trim,
                driveType: action.driveType,
                vcdb: action.vcdb,
                marketing: action.marketing,
                options: action.options,
                bodyAndWheel: action.bodyAndWheel,
                bodyNumDoors: action.bodyNumDoors,
                engine: action.engine
            });

        case type.SET_TEMPLATE_DATA_FAIL:
            var error = action.error;
            // console.log('SET_TEMPLATE_DATA_FAIL: ' + error);
            return Object.assign({}, state, { text: "" });

        case type.SET_VIN_ASYNC_FAIL:
            return { ...state, error: action, showError: true };

        case type.UPDATE_VEHICLE_INFO_BY_DATA:
            return Object.assign({}, state, {
                year: action.vehicleInfo.year,
                make: action.vehicleInfo.make,
                makeCode: action.vehicleInfo.makeId,
                model: action.vehicleInfo.model,
                vehicleConfig: {
                    model: action.vehicleInfo.model,
                    modelId: action.vehicleInfo.modelId,
                    bodyId: action.vehicleInfo.bodyId,
                    body: action.vehicleInfo.body,
                    wheelId: action.vehicleInfo.wheelId,
                    wheel: action.vehicleInfo.wheel,
                    trimId: action.vehicleInfo.trimId,
                    trim: action.vehicleInfo.trim,
                    driveId: action.vehicleInfo.driveId,
                    drive: action.vehicleInfo.drive,
                    engineId: action.vehicleInfo.engineId,
                    engine: action.vehicleInfo.engine,
                    bodyNumDoorsId: action.vehicleInfo.bodyNumDoorsId ? action.vehicleInfo.bodyNumDoorsId : "",
                    bodyNumDoors: action.vehicleInfo.bodyNumDoors ? action.vehicleInfo.bodyNumDoors : "",
                    year: action.vehicleInfo.year,
                    make: action.vehicleInfo.make
                },
                vin: action.vehicleInfo.vin,
                vcdb: {
                    bodyNumDoors: action.vehicleInfo.bodyNumDoors ? action.vehicleInfo.bodyNumDoors : "",
                    bodyTypeId: action.vehicleInfo.bodyTypeId ? action.vehicleInfo.bodyTypeId : "",
                    driveTypeId: action.vehicleInfo.driveType ? action.vehicleInfo.driveType : "",
                    engineBaseId: action.vehicleInfo.engineBase ? action.vehicleInfo.engineBase : "",
                    trimId: action.vehicleInfo.trim,
                    wheelBaseId: action.vehicleInfo.wheelBase ? action.vehicleInfo.wheelBase : "",
                    bodyStyleConfigId: "",
                    bodyStyleName: action.vehicleInfo.bodyStyleName ? action.vehicleInfo.bodyStyleName : "",
                    driveTypeName: action.vehicleInfo.driveTypeName ? action.vehicleInfo.driveTypeName : "",
                    trimName: action.vehicleInfo.trimName ? action.vehicleInfo.trimName : "",
                    wheelBaseName: action.vehicleInfo.wheelBaseName ? action.vehicleInfo.wheelBaseName : ""
                },
                bodyStyle: action.vehicleInfo.bodyStyle ? action.vehicleInfo.bodyStyle : "",
                wheelBase: action.vehicleInfo.wheelBase ? action.vehicleInfo.wheelBase : "",
                trim: action.vehicleInfo.trim ? action.vehicleInfo.trim : "",
                driveType: action.vehicleInfo.driveType ? action.vehicleInfo.driveType : "",
                bodyStyleName: action.vehicleInfo.bodyStyleName ? action.vehicleInfo.bodyStyleName : "",
                driveTypeName: action.vehicleInfo.driveTypeName ? action.vehicleInfo.driveTypeName : "",
                trimName: action.vehicleInfo.trimName ? action.vehicleInfo.trimName : "",
                wheelBaseName: action.vehicleInfo.wheelBaseName ? action.vehicleInfo.wheelBaseName : ""
            });

        case type.UPDATE_VEHICLE_INFO_BY_URL:
            const params = queryString.parse(action.params);
            let year = params.year ? params.year : "";
            let make = params.make ? params.make : "";
            let makeCode = makeToCode[make] ? makeToCode[make] : "";
            let model = params.model ? params.model : "";
            let modelId = params.modelId ? params.modelId : "";
            let bodyId = params.bodyId ? params.bodyId : "";
            let body = params.body ? params.body : "";
            let wheelId = params.wheelId ? params.wheelId : "";
            let wheel = params.wheel ? params.wheel : "";
            let trimId = params.trimId ? params.trimId : "";
            let trim = params.trim ? params.trim : "";
            let driveId = params.driveId ? params.driveId : "";
            let drive = params.drive ? params.drive : "";
            let engine = params.engine ? params.engine : "";
            let engineId = params.engineId ? params.engineId : "";
            let vin = params.vin ? params.vin : "";
            let bodyNumDoors = params.bodyNumDoors ? params.bodyNumDoors : "";
            let bodyNumDoorsId = params.bodyNumDoorsId ? params.bodyNumDoorsId : "";
            let bodyStyle = params.bodyStyle ? params.bodyStyle : "";
            let wheelBase = params.wheelBase ? params.wheelBase : "";
            // let trim = params.trim ? params.trim : '';
            let driveType = params.driveType ? params.driveType : "";
            let engineBase = params.engineBase ? params.engineBase : "";
            let bodyStyleName = params.bodyStyleName ? params.bodyStyleName : "";
            let driveTypeName = params.driveTypeName ? params.driveTypeName : "";
            let trimName = params.trimName ? params.trimName : "";
            let wheelBaseName = params.wheelBaseName ? params.wheelBaseName : "";

            return Object.assign({}, state, {
                year: year,
                make: make,
                makeCode: makeCode,
                model: model,
                vehicleConfig: {
                    model: model,
                    modelId: modelId,
                    bodyId: bodyId,
                    body: body,
                    wheelId: wheelId,
                    wheel: wheel,
                    trimId: trimId,
                    trim: trim,
                    driveId: driveId,
                    drive: drive,
                    engineId: engineId,
                    engine: engine,
                    bodyNumDoorsId: bodyNumDoorsId,
                    bodyNumDoors: bodyNumDoors
                },
                vin: vin,
                vcdb: {
                    bodyNumDoors: bodyNumDoors,
                    bodyTypeId: bodyStyle,
                    driveTypeId: driveType,
                    engineBaseId: engineBase,
                    trimId: trim,
                    wheelBaseId: wheelBase,
                    bodyStyleConfigId: "",
                    bodyStyleName: bodyStyleName,
                    driveTypeName: driveTypeName,
                    trimName: trimName,
                    wheelBaseName: wheelBaseName
                },
                bodyStyle: action.bodyStyle,
                wheelBase: action.wheelBase,
                trim: action.trim,
                driveType: action.driveType,
                bodyStyleName: bodyStyleName,
                driveTypeName: driveTypeName,
                trimName: trimName,
                wheelBaseName: wheelBaseName
            });

        case type.UPDATE_VEHICLE_INFO:
            const _bodyNumDoors = action.params.numOfDoors ? action.params.numOfDoors : "";
            return Object.assign({}, state, {
                bodyNumDoors: _bodyNumDoors,
                vehicleInfo: action.params
            });

        case type.RESET_VEHICLE_INFO:
                return Object.assign({}, initialState);
        default:
            return state;
    }
};

export default VehicleInfoReducer;
