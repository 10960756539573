/**
 * @SAGA : Redux Saga middleware for side effects. Help for Redux async call.
 * @Enhancers: Connect your browser Redux Tool to your store. You must install Redux-DevTool as browser add in to make this to work
 * @Logger : Logs on browser console with Previous state, action and next state.
 */

/*** Importing all dependencies ***/
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './RootSaga';
import VehicleSelectReducer from '../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectReducer';
import HomeCarouselReducer from '../../modules/Home/HomeCarousel/HomeCarouselRedux/HomeCarouselReducer';
import HomeCategoriesReducer from '../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesReducer';
import HeaderReducer from '../../modules/App/Header/HeaderRedux/HeaderReducer';
import FooterReducer from '../../modules/App/Footer/FooterRedux/FooterReducer';
import SearchServiceReducer from '../../modules/Search/SearchService/SearchDataServiceReducer';
import SearchLinkReducer from '../../shared/SearchLink/SearchLinkRedux/SearchLinkReducer';
import CategoryNavReducer from '../../modules/Search/SearchNav/CategoryNav/CategoryNavRedux/CategoryNavReducer';
import ProductDetailsReducer from '../../modules/ProductDetails/ProductDetailsRedux/ProductDetailsReducer';
import CheckoutReducer from '../../modules/Checkout/CheckoutRedux/CheckoutReducer';
import SimilarItemsReducer from "../../modules/ProductDetails/ProductDetailsSimilarItems/ProductDetailsSimilarItemsRedux/SimilarItemsReducer";
import ProductDetailsTabReducer from "../../modules/ProductDetails/Product-Details-Tab/ProductDetailsTabRedux/ProductDetailsTabReducer";
import WheelPackageReducer from '../../shared/WheelPackage/WheelPackageRedux/WheelPackageReducer';
import FitmentCheckReducer from '../../modules/ProductDetails/FitmentCheck/FitmentCheckRedux/FitmentCheckReducer';
import VehicleConfigReducer from '../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigReducer';
import MiniCartReducer from '../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartReducer';
import BreadcrumbReducer from '../../shared/Breadcrumb/BreadcrumbRedux/BreadcrumbReducer';
import RailsReducer from "../../shared/SummaryRail/SummaryRailRedux/SummaryRailReducer";
import SessionReducer from '../../shared/Session/VehicleInfo/VehicleInfoReducer';
import DealerLocatorReducer from '../../shared/DealerLocator/DealerLocatorRedux/DealerLocationReducer';
import OrderConfirmReducer from '../../modules/OrderConfirmation/OrderConfirmRedux/OrderConfirmReducer';
import ErrorPageReducer from '../../shared/ErrorPage/ErrorPageRedux/ErrorPageReducer';
import VoVReducer from '../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVReducer';
import AuthenticationReducer from '../../shared/Authentication/AuthenticationReducer';
import RewardsReducer from '../../shared/Rewards/RewardsRedux/RewardsReducer';
import AccountCreationReducer from '../../modules/AccountCreation/AccountCreationRedux/AccountCreationReducer';
import OrderInfoReducer from '../../shared/OrderInfo/OrderInfoRedux/OrderInfoReducer';
import UserProfileReducer from '../../modules/UserProfile/UserProfileRedux/UserProfileReducer';
import WalletReducer from '../../shared/Wallet/WalletRedux/WalletReducer';
import { DealerizationReducer } from "../../modules/DealerLocator/Data/Reducer";
import FeaturedCategoriesReducer from '../../shared/FeaturedCategories/FeaturedCategoriesRedux/FeaturedCategoriesReducer';
import AppSettings from "../AppSettings";
import { CartReducer } from '../../modules/Cart/Redux/CartReducer';


/*** create the Saga Middleware ***/
const sagaMiddleware = createSagaMiddleware();


let middleware = [sagaMiddleware];

/** The combineReducers helper function turns an object whose values are different
 reducing functions into a single reducing function you can pass to createStore **/
const rootReducer = combineReducers({
  //reducer goes here
  HomeCarouselReducer,
  HomeCategoriesReducer,
  VehicleSelectReducer,
  SearchServiceReducer,
  HeaderReducer,
  FooterReducer,
  SearchLinkReducer,
  CategoryNavReducer,
  ProductDetailsReducer,
  SimilarItemsReducer,
  ProductDetailsTabReducer,
  WheelPackageReducer,
  form: formReducer,
  MiniCartReducer,
  DealerLocatorReducer,
  OrderInfoReducer,
  CheckoutReducer,
  OrderConfirmReducer,
  FitmentCheckReducer,
  VehicleConfigReducer,
  BreadcrumbReducer,
  RailsReducer,
  Session: SessionReducer,
  FeaturedCategoriesReducer,
  ErrorPageReducer,
  VoVReducer,
  Authentication: AuthenticationReducer,
  RewardsReducer,
  UserProfileReducer,
  WalletReducer,
  AccountCreationReducer,
  DealerizationReducer,
  CartReducer
});

/*** Enable Redux-Logger and @Enhancers only on dev mode **/
let store = '';
const env = process.env.NODE_ENV;


if (env === 'development' && AppSettings.enableReduxLogger) {
  let reduxLogger = require('redux-logger');
  // const enhancers = compose(window.devToolsExtension ? window.devToolsExtension() : f => f
  // );
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const loggerMiddleware = reduxLogger.createLogger();

  middleware.push(loggerMiddleware);


  store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
}
else {
  store = createStore(rootReducer, compose(applyMiddleware(...middleware)));
}




/** run the saga **/
sagaMiddleware.run(rootSaga);



export default store
