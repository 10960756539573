import { call, put } from 'redux-saga/effects';
import { setLinks, setLinksFail, setLoading, setSlogan, setSloganFail, setText, setTextFail, setSequence, setSequenceFail } from '../FooterRedux/FooterActionCreator';
import { getLinks, getSlogan, getText } from './FooterService';
import { getBaseMarketingSpotActivityData } from '../../../../shared/Validator/Validator';
import AppSettings from '../../../../core/AppSettings';

// handles the loading of the espot data in the footer. When on the search page check to see if you are changing pages
// if user is clicking through left nav (not changing pages) do not reload the footer data.
// if the user is changing pages, reload footer data and set prevPageName.
export function* handleLoadFooterData(action) {
  if (AppSettings.pageName == "Search") {
    if (AppSettings.prevPageName != AppSettings.pageName) {
      yield handleFooterCalls(action.pageName);
      AppSettings.prevPageName = AppSettings.pageName;
    }
  } else {
      yield handleFooterCalls(action.pageName);
    if (AppSettings.prevPageName != AppSettings.pageName) {
      AppSettings.prevPageName = AppSettings.pageName;
    }
  }
}

function* handleFooterCalls(pageName) {
  yield callForLinks(pageName);
  yield callForSlogan(pageName);
  yield callForText(pageName);
  yield put(setLoading(false));
}

function* callForLinks(pageName) {
  try {
    const res = yield call(getLinks, pageName);
    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if (baseMarketingSpotActivityData) {
      const linksDiv = baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText;
      yield put(setLinks(linksDiv));
    } else {
      yield put(setLinksFail(new Error('invalid marketing data')));
    }
  }
  catch (error) {
    yield put(setLinksFail(error));
  }
}

function* callForSlogan(pageName) {
  try {
    const res = yield call(getSlogan, pageName);
    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if (baseMarketingSpotActivityData) {
      const imgPath = baseMarketingSpotActivityData[0].attachmentAsset[0].attachmentAssetPath;
      const damImgUrlDomain = "/parts/images"; // the DAM path
      const imgURL = imgPath.replace(/https?:\/\/\[[a-z]\w*\]/, damImgUrlDomain);
      yield put(setSlogan(imgURL));
    } else {
      yield put(setSloganFail(new Error('invalid marketing data')));
    }
  }
  catch (error) {
    yield put(setSloganFail(error));
  }
}

function* callForText(pageName) {
  try {
    let textDiv = '';
    let count = 1;
    let sequence = [];
    const res = yield call(getText, pageName);
    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if (baseMarketingSpotActivityData) {
      baseMarketingSpotActivityData.forEach(function (espot) {
        if (espot.marketingContentDescription[0]) {
          let espotText = espot.marketingContentDescription[0].marketingText;
          // function goes through and re-numbers all footer disclaimers with the '##' tag 
          // with the appropriate footer number label based on the espot defined content sequence
          // and loops through each individual espot text content to find and replace all '##'
          while (espotText.includes("##")) {
            espotText = espotText.replace("##", "<sup>" + count + "</sup>");
            sequence.push({ name: espot.contentName, label: count });
            count++;
          }
          // appends all espot text contents together after numbering from above
          textDiv += espotText;
        }
      });
      yield put(setText(textDiv));
      yield put(setSequence(sequence));
    } else {
      yield put(setTextFail(new Error('invalid marketing data')));
    }
  }
  catch (error) {
    // since sequence does not need its own 'getSequence' call it will set same error as setTextFail
    yield put(setTextFail(error));
    yield put(setSequenceFail(error));
  }
}