import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, {useState} from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import store from "../../core/Redux/Store";
import { useFooterDisclaimerLabel } from '../../modules/App/Footer/FooterDisclaimerLabel';
import FieldWarningMessage from '../../shared/FieldWarningMessage/FieldWarningMessage';
import { formatNumber, formattedCurrency } from '../../shared/Internationalization/FormattedNumbers';
import CartOrderLineItemHeader from "../CartComponents/ItemHeader/ItemHeader";
import ProductLineItem from "../CartComponents/ProductLineItem/ProductLineItem";
import { updateRewardsPoints } from '../Rewards/RewardsRedux/RewardsActionCreator';
import Spinner from '../Spinner/Spinner';
import PricingItem from "./PricingItem/PricingItem";
import RewardsWrapper from "./RewardsWrapper/RewardsWrapper";
import { toggleAccordion } from "./SummaryRailRedux/SummaryRailActionCreator";
import AppSettings from "../../core/AppSettings";
import vaultConstants from "../../../config/vault_constants";


const SummaryRail = props => {
  /** VARIABLES **/
  const orderInCart = props.data;
  const deliveryErrorTax = props.deliveryErrorTax || '';
  const [appliedPoints, setAppliedPoints] = useState("");
  const brand = AppSettings.currentSite.label;
  const REMOVE_REWARD_CHECKOUT = AppSettings.isLocalHost ? false : String(vaultConstants.FF_2106197_REMOVE_REWARD_CHECKOUT) === "true";

  /** PREPARE CART DATA **/
  let vehicleData = "";
  if (orderInCart) {
    vehicleData = orderInCart.vehicles.map((items, index) => {
      if (!items.vehiclesInfo.model) {
        items.vehiclesInfo.model = "";
      }
      return {
        vehicleIndex: index + 1,
        vehicleInformation: items.vehiclesInfo,
        items: items.orderItems,
        vin: items.vinNumber
      };
    });
  }

  let cost = {};
  if (!isEmpty(props.data) && !isEmpty(props.data.cost)) {
    cost = props.data.cost;
  }

  const checkoutData = props.checkoutData;
  const checkoutStep = checkoutData.currentStep;
  const orderConfirmationStep = window.location.pathname.includes('/orderconfirmation');
  const showFullOrderSummary = (checkoutStep !== 1) || orderConfirmationStep || props.RIGHT_ORDER_SUMMARY_FF;
  const pricingItemsRemovable = (checkoutStep === 2) || props.RIGHT_ORDER_SUMMARY_FF;
  const pointsSuperscript = useFooterDisclaimerLabel(process.env.REACT_APP_DISCLAIMER_LOYALTY);

  // Sort adjustments
  let surcharges = [];
  let vouchers = [];
  let pointsVouchers = [];
  if (!isEmpty(props.data) && !isEmpty(props.data.orderInfo.adjustment)) {
    props.data.orderInfo.adjustment.forEach((adjustment) => {
      if (adjustment.usage === 'Surcharge') {
        surcharges.push(adjustment);
      } else if (adjustment.code === process.env.REACT_APP_LOYALTY_VOUCHER_ADJ_CODE) {
        vouchers.push(adjustment);
      } else if (adjustment.code === process.env.REACT_APP_LOYALTY_POINTS_ADJ_CODE) {
        pointsVouchers.push(adjustment);
      }
    })
  }

  const descriptionTranslation = (description) => {
    switch(description) {
      case 'CO - STATE RETAIL DELIVERY FEE':
        return "LABEL_STATE_DELIVERY_FEE"
      default:
        return description
    }
  }

  return (
    <Translation>
      {t => (
        <div className="q-text q-body2 ac-sr-container">
          <div className="ac-sr-upper-container">
            {(props.summaryRailsState.showSpinner || props.summaryRailsState.showLoadingBtn) && <div className="ac-summary-rail-overlay"><div className="ac-summary-rail-spinner"><Spinner /></div></div>}
            <ul>
              {/* Order Summary title */}
              <li>
                <h4>
                  <b>{t("LABEL_RECEIPT_ORDER_SUMMARY")}</b>
                </h4>
              </li>
              {showFullOrderSummary && <>
                {props.voucherAmountWarning &&
                  <li>
                    <FieldWarningMessage message={t("LABEL_CHECKOUT_VOUCHER_AMT_WARNING")} />
                  </li>
                }
              </>}
              {/* Subtotal with (# items) */}
              <li>
                <span>
                  <b>{t("LABEL_COMMON_SUBTOTAL")}</b> ({cost.recordSetTotal}{" "}
                  {cost.recordSetTotal == 1
                    ? t("LABEL_COMMON_ITEM")
                    : t("LABEL_COMMON_ITEMS")}
                  ):
                </span>
                <span>
                  <b>{cost.totalSubtotal}</b>
                </span>
              </li>
              {showFullOrderSummary && <>
                {cost.totalTax && <li className="ac-line-break" />}
                {/* Manufacturer Offers*/}
                {
                  cost.coupons && cost.coupons.manufacturer_list.length > 0 && cost.coupons.manufacturer_list.map((coups) => {
                    var label = t("LABEL_COUPON_MANUFACTURER_OFFER") + " - " + coups.manufacturePromotionCode;
                    return <PricingItem
                      label={label}
                      amount={coups.manufactureCoupon}
                      id={coups.manufacturePromotionCode}
                      isRemovable={pricingItemsRemovable}
                      key={coups.manufacturePromotionId}
                      handleRemove={props.handleCouponCodeRemove}
                    />
                  })
                }
                {cost.coupons && cost.coupons.afterManufacturer &&
                  <>
                    <li className="ac-line-break" />
                    <li>
                      <span>{t("LABEL_COUPON_PRICE_AFTER_MANUFACTURER")}:</span>
                      <span><b>{cost.coupons.afterManufacturer}</b></span>
                    </li>
                  </>
                }
                {/* Family First Offers */}
                {
                  cost.coupons && cost.coupons.familyFirst_list.length > 0 && cost.coupons.familyFirst_list.map((coups) => {
                    var label = t("LABEL_COUPON_FAMILY_FIRST_OFFER") + " - " + coups.familyFirstPromotionCode;
                    return <PricingItem
                      label={label}
                      amount={coups.familyFirstCoupon}
                      id={coups.familyFirstPromotionId}
                      isRemovable={pricingItemsRemovable}
                      handleRemove={props.handleCouponCodeRemove}
                      key={coups.familyFirstPromotionId}
                    />
                  })
                }
                {cost.coupons && cost.coupons.afterFamilyFirst &&
                  <>
                    <li className="ac-line-break" />
                    <li>
                      <span>{t("LABEL_COUPON_PRICE_AFTER_MANUFACTURER")}:</span>
                      <span><b>{cost.coupons.afterFamilyFirst}</b></span>
                    </li>
                  </>
                }
                {/* Dealer Offers */}
                {
                  cost.coupons && cost.coupons.dealer_list.length > 0 && cost.coupons.dealer_list.map((coups) => {
                    var label = t("LABEL_COUPON_DEALER_OFFER") + " - " + coups.dealerPromotionCode;
                    return <PricingItem
                      label={label}
                      amount={coups.dealerCoupon}
                      id={coups.dealerPromotionCode}
                      isRemovable={pricingItemsRemovable}
                      handleRemove={props.handleCouponCodeRemove}
                      key={coups.dealerPromotionId}
                    />
                  })
                }
                {cost.coupons && cost.coupons.afterDealer &&
                  <>
                    <li className="ac-line-break" />
                    <li>
                      <span>{t("LABEL_COUPON_PRICE_AFTER_DEALER")}:</span>
                      <span><b>{cost.coupons.afterDealer}</b></span>
                    </li>
                  </>
                }
                {/* Vouchers */}
                {
                  vouchers.length > 0 && vouchers.map((voucher, index) => {
                    return <PricingItem
                      label={voucher.description}
                      amount={formattedCurrency(voucher.currency, voucher.amount)}
                      id={voucher.couponCode}
                      isRemovable={pricingItemsRemovable}
                      handleRemove={props.handleRemoveVoucher}
                      key={index}
                    />
                  })
                }
                {/* Points */}
                {
                  pointsVouchers.length > 0 && pointsVouchers.map((pointsVoucher, index) => {
                    // Need to format points in the description and add in superscript
                    // 1234 Rewards Points voucher => 1,234 My GM Rewards points*
                    let pointsDescription = pointsVoucher.description.split(" ");
                    setAppliedPoints(formatNumber(pointsDescription[0]));
                    const label = appliedPoints + t("LABEL__REWARDS_PRICE_STACK", { brand }) + "<sup>" + pointsSuperscript + "</sup>"; 

                    return <PricingItem
                      label={label}
                      amount={formattedCurrency(pointsVoucher.currency, pointsVoucher.amount)}
                      id={pointsVoucher.couponCode}
                      isRemovable={pricingItemsRemovable}
                      handleRemove={props.handleApplyRewardsPoints}
                      isPoints={true} // data-dtm attribute is different for rewards points line item
                      key={index}
                    />
                  })
                }
                {/* Shipping */}
                {cost.shipping && (<li>
                  <span>{t("LABEL_COMMON_SHIPPING")}:</span>
                  <span>{cost.shipping}</span>
                </li>)}
                {/* Shipping Coupons */}
                {
                  cost.coupons && cost.coupons.shipping_list.length > 0 && cost.coupons.shipping_list.map((coups, index) => {
                    var label = t("LABEL_COUPON_MANUFACTURER_OFFER") + " - " + coups.shippingPromotionCode;
                    return <PricingItem
                      label={label}
                      amount={coups.shippingCoupon}
                      id={coups.shippingPromotionCode}
                      isRemovable={pricingItemsRemovable}
                      handleRemove={props.handleCouponCodeRemove}
                      key={coups.shippingPromotionId ? coups.shippingPromotionId : index}
                    />
                  })
                }
                {/* Installation */}
                {cost.installation && (<li>
                  <span>{t("LABEL_RECEIPT_INSTALLATION")}:</span>
                  <span>{cost.installation}</span>
                </li>)}
                {/* Tax */}
                {cost.totalTax && !deliveryErrorTax && (<li>
                  <span>{t("LABEL_RECEIPT_SALES_TAX")}:</span>
                  <span>{cost.totalTax}</span>
                </li>)}
                {/* Surcharges */}
                {
                  surcharges.length > 0 && surcharges.map((surcharge, index) => {
                    const surChargeAmount = surcharge.amount;
                    if(surChargeAmount > 0){
                      return <li key={index}>
                        <span>{t(descriptionTranslation(surcharge.description))}:</span>
                        <span>{formatCurrency(surcharge.amount, 'en-EN', 'USD')}</span>
                      </li>
                    }
                   })
                }
                {/* Tax Error */}
                {deliveryErrorTax && (<li>
                  <span>{t("LABEL_RECEIPT_SALES_TAX")}:</span>
                  <span className="ac-sr-tax-error">{t("LABEL_RECEIPT_TAX_ERROR")}</span>
                </li>
                )}
                <li className="ac-line-break" />
                {/* Total */}
                <li>
                  <h4 className="ac-sr-total">
                    <b>
                      {t("LABEL_RECEIPT_TOTAL")}: {!cost.total ? formatCurrency(0, 'en-EN', 'USD') : cost.total}
                    </b>
                  </h4>
                </li>
              </>}
            </ul> {/* End of Price Stack */}
          </div>

          {/* REWARDS COMPONENT */}
          {checkoutData.isLoading == false && checkoutStep == 2 && props.authData.enrolledAttributeValue == 1
          && props.rewardsData.isRewardsActive && props.rewardsData.canRedeem === 'Y' && 
            !REMOVE_REWARD_CHECKOUT &&
           <RewardsWrapper
            adjustedTotal={props.data.orderInfo.adjustedTotal}
            appliedAdjustments={props.data.orderInfo.adjustment}
            appliedPoints={appliedPoints}
            handleApplyRewardsPoints={props.handleApplyRewardsPoints}
            isDealerParticipating={props.data.dealer.rewardsProgram}
          />
          }
          { !props.RIGHT_ORDER_SUMMARY_FF &&
          <ul className="ac-sr-ul-wrapper">
            <li className="full-width light-bg ac-item-in-order-header stat-expand-icon" data-dtm="order summary" onClick={props.toggleAccordion}>
              <span>
                <b>
                  {cost.recordSetTotal == 1
                    ? t("LABEL_COMMON_ITEM")
                    : t("LABEL_COMMON_ITEMS")}{" "}
                  {t("LABEL_COMMON_IN_ORDER")} ({cost.recordSetTotal})
                </b>
              </span>
              <span>
                <span>
                  {props.summaryRailsState.accordion && (
                    <FontAwesomeIcon icon="angle-up" />
                  )}
                </span>
                <span>
                  {!props.summaryRailsState.accordion && (
                    <FontAwesomeIcon icon="angle-down" />
                  )}
                </span>
              </span>
            </li>
            {/** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY VEHICLE INFORMATION  **/
              props.summaryRailsState.accordion &&
              vehicleData.map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div>
                      <CartOrderLineItemHeader
                        data={item.vehicleInformation}
                        count={item.vehicleIndex}
                        vinNumber={item.vin}
                      />
                    </div>
                    <div className="row">
                      {item.items && item.items.map((subItem, index) => {
                        return (
                          <div key={index} className="ac-shoppingLineItemIndex">
                            {index > 0 && <hr />}
                            <ProductLineItem
                              mainItem = {subItem}
                              data={subItem.items[0]}
                              vin={item.vin}
                              vehicleInformation={item.vehicleInformation}
                              component="summaryRail"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </ul>
          }
        </div>
      )}
    </Translation>
  );
};

const isEmpty = obj => {
  // Checks if an object is empty
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

function formatCurrency(amount, lang, currency) {
  if (amount === undefined || amount === null || amount < 0) {
    amount = '';
  } else {
    amount = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency
    }).format(Number(amount));
  }
  return amount;
}

SummaryRail.proptype = {
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    summaryRailsState: state.RailsReducer,
    checkoutData: state.CheckoutReducer,
    authData: state.Authentication,
    rewardsData: state.RewardsReducer
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleAccordion: () => {
      store.dispatch(toggleAccordion());
    },
    handleApplyRewardsPoints: (rewardsPointsToRemove, rewardsPointsToApply) => {
      store.dispatch(updateRewardsPoints(props.data.orderInfo.orderId, rewardsPointsToRemove, rewardsPointsToApply));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryRail);
