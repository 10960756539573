import React from 'react';
import propTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import { Field } from 'redux-form';
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { brand } from '../../Utils/Utils';
import Footnote from '../../Footnote/Footnote';
import Button from '../../Button/Button';
import Container from "react-bootstrap/Container";
import Row from"react-bootstrap/Row";

/**************************************************************/
//                  STANDARD INPUT FIELD
// prop 'classes' used for input width that is < full width (e.g. small-6)
/**************************************************************/
export const renderInputField = ({ input, classes, label, disabled, type, placeholder, showAsterisk, showOptional, dataDtm, dataDtm2, meta: { touched, error }, capitalized, maxLength }) => (
    <Translation>{(t) => (
        <div className={("gmit-gf-request-type ac-field-wrapper " + (classes ? classes : ""))}>
            <div className={touched && error ? 'ac-field-error' : ''}>
                <div className="q-mod q-mod-gmit-gf-text-field gmit-gf-text-field">
                    <label className="q-label2 q-caps gmit-gf-label-text">
                        {/* display label and optional asterisk or '(Optional)' text */}
                        {capitalized ? (t(label).toUpperCase()) : t(label)}{showAsterisk && !showOptional ? '*' : ''} {showOptional && !showAsterisk ? t("LABEL_COMMON_OPTIONAL") : ''}
                    </label>
                </div>
                {/* input field */}
                <div className="gmit-gf-text-field">
                    <input className="gmit-gf-input-text stat-input-field"
                        {...input}
                        type={type}
                        disabled={disabled}
                        placeholder={placeholder}
                        data-dtm={dataDtm ? dataDtm : null}
                        data-dtm2={dataDtm2 ? dataDtm2 : null}
                        maxLength={maxLength} />
                </div>
                {/* error displayed beneath field */}
                {touched && error && <div className="error ac-error-wrapper"><label className="gmit-gf-error-label">{error}</label></div>}
            </div>
        </div>
    )}</Translation>
)

renderInputField.propTypes = {
    label: propTypes.string, // label for input field
    showAsterisk: propTypes.bool, // displays an asterisk after label - not all required fields ask for this
    showOptional: propTypes.bool, // displays '(Optional)' after label - not all optional fields ask for this
    dataDtm: propTypes.string,
    dataDtm2: propTypes.string
}

/**************************************************************/
//                INPUT WITH INLINE BUTTON FIELD
/**************************************************************/

export const renderInlineBtnInputField = ({ input, disabled, type, placeholder, dataDtm, dataDtm2, dataDtm3, btnLabel, btnOnClick, btnDisabled, showLoadingBtn, meta: { touched, error } }) => (
    <div className="ac-inline-btn-field gmit-gf-request-type">
        <div className="gmit-gf-text-field ac-field-wrapper small-7 medium-8 columns ac-nopadding-x">
            <div className={touched && error ? 'ac-field-error' : ''}>
                {/* input field */}
                <input className="gmit-gf-input-text stat-input-field"
                    {...input}
                    type={type}
                    disabled={(disabled)}
                    placeholder={placeholder}
                    data-dtm={dataDtm}
                    data-dtm2={dataDtm2} />
                {/* error displayed beneath field */}
                {touched && error && <div className="error ac-error-wrapper"><label className="gmit-gf-error-label">{error}</label></div>}
            </div>
        </div>
        <div className={"small-5 medium-4 grid-column-alignment-right ac-nopadding-x " + `ac-inline-btn-${brand().toLowerCase()}`}>
            <Button
                type="submit"
                disabled={btnDisabled}
                localeKey={btnLabel}
                onClick={btnOnClick}
                isLoading={showLoadingBtn}
                dataDtm={dataDtm3} />
        </div>
    </div>
)

renderInlineBtnInputField.propTypes = {
    label: propTypes.string, // inline field label
    btnLabel: propTypes.string.isRequired, // inline button label NOTE: limit length or button may get ugly at certain screen sizes
    showLoadingBtn: propTypes.bool, // show button spinner
    dataDtm: propTypes.string, // dtm for input field
    dataDtm2: propTypes.string, // dtm for input field
    dataDtm3: propTypes.string, // dtm for button
}


/**************************************************************/
//                  DROPDOWN SELECT
// prop 'classes' used for input width that is < full width (e.g. small-6)
/**************************************************************/
export const renderSelectDropdown = ({ input, classes, label, value, disabled, showAsterisk, defaultValue, showOptional, dataDtm, dataDtm2, meta: { touched, error }, children }) => (
    <Translation>{(t) => (
        <div className={("gmit-gf-request-type ac-field-wrapper " + (classes ? classes : ""))}>
            <div className={touched && error ? 'ac-field-error' : ''}>
                <div className="q-mod q-mod-gmit-gf-text-field gmit-gf-text-field">
                    <label className="q-label2 q-caps gmit-gf-label-text">
                        {/* display label and optional asterisk or '(Optional)' text */}
                        {t(label)}{showAsterisk && !showOptional ? '*' : ''} {showOptional && !showAsterisk ? t("LABEL_COMMON_OPTIONAL") : ''}
                    </label>
                </div>
                {/* dropdown select */}
                <select className="q-form-dropdown stat-dropdown ac-form-dropdown gmit-gf-dropdown ac-form-drpdwn"
                    id={label.replace(/ /g, '-') + "-dropdown"}
                    {...input}
                    value={value || defaultValue}
                    disabled={disabled}
                    data-dtm={dataDtm}
                    data-dtm2={dataDtm2}>
                    {children}
                </select>
                {/* error displayed beneath dropdown */}
                {touched && error && <div className="error ac-error-wrapper"><label className="gmit-gf-error-label">{error}</label></div>}
            </div>
        </div>
    )}</Translation>
)
renderSelectDropdown.propTypes = {
    label: propTypes.string, // label for dropdown field
    value: propTypes.string,
    showAsterisk: propTypes.bool, // displays an asterisk after label - not all required fields ask for this
    showOptional: propTypes.bool, // displays '(Optional)' after label - not all optional fields ask for this
    dataDtm: propTypes.string,
    dataDtm2: propTypes.string
}


/**************************************************************/
// CHECKBOX FIELD (WITH FOOTNOTE) ➔ [✓] agreement text here
/**************************************************************/
export const renderCheckboxField = ({ input, localeKey, href, dataDtm, dataDtm2, dataDtmCheckBox, dataDtmCheckBoxLink, meta: { touched, error } }) => (
    <>
        <Footnote
            localeKey={localeKey}
            href={href}
            showCheckbox={true}
            input={input}
            dataDtm={dataDtm}
            dataDtm2={dataDtm2}
            dataDtmCheckBox={dataDtmCheckBox}
            dataDtmCheckBoxLink={dataDtmCheckBoxLink}
        />
        {/* error displayed beneath checkbox field */}
        {touched && error && <div className="error ac-error-wrapper"><label className="gmit-gf-error-label">{error}</label></div>}
    </>
)

renderCheckboxField.propTypes = {
    localeKey: propTypes.string.isRequired,
    href: propTypes.array
}

/**************************************************************/
//                  PASSWORD INPUT FIELD 
/**************************************************************/
// NOTES:
// - This displays an input field with a toggle button that shows/hides input text
// - Currently need to handle toggle state outside of this shared component where form is setup (a hook/state issue)
// - A standard error label (red text) is displayed underneath the input field
// - Optional password requirements text can be displayed beneath field (requirementLabels)
// - Option password strength meter displaying beneath pwd field if you pass the input from the password field (password={password})
// - †††Supports dynamic password requirements that are synchronous with password input.
//      Need to pass the 'warn' function to redux-form and set up your own password requirements.
//      Synchronous support is commented out in below example and will need to be uncommented if copy/pasted
// - Note: Using the shared component 'Button' in example and will need to be imported if used
/* 

How to Implement: 

import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { PasswordInputField } from '../shared/Form/FormFields/FormFields';
import { Button } from '../shared/Button/Button';

// †††SYNCHRONOUS validation (OPTIONAL LINES BELOW)
// HANDLE SPECIAL VALIDATION CASES SPECIFIC TO THIS FORM
// const warn = values => {
//     const warnings = {}
//     if (values.password) {
//         // Set up password requirements (Note: keep naming convention Req1, Req2, etc. or synchronous password stuff won't work. Basic examples used below)
//         if (values.password.length > 8) {
//             warnings.Req1 = "good length"
//         }
//         if (!(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i.test(values.password))) {
//             warnings.Req2 = "good content"
//         }
//         if (values.password.length < 10) {
//             warnings.Req3 = "ok"
//         }
//     }
//     return warnings
// }

const [USEYOUROWNFORMNAME] = (props) => {
    
    // TOGGLE PASSWORD VISIBILITY
    const [passwordType, togglePasswordType] = useState('LABEL_COMMON_SHOW_PASSWORD');

    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit}>
            <Field
                component={PasswordInputField}
                name="password"
                passwordType={passwordType}
                togglePasswordType={togglePasswordType}
                showRequirements={true}
                requirementLabels={['LABEL_PASSWORD_REQUIREMENT1', 
                                    'LABEL_PASSWORD_REQUIREMENT2']}
                // synchronous={true} // †††SYNCHRONOUS (OPTIONAL LINE)
                required={true} />  
            <Button
                localeKey="LABEL_COMMON_LOGIN"
                type="submit" />
        </form>
    )
}

export default (reduxForm({
    form: '[USEYOUROWNFORMNAME]'
    // warn, // †††SYNCHRONOUS (OPTIONAL LINE) <--- warning function given to redux-form (https://redux-form.com/6.4.3/examples/syncvalidation/) 
})([USEYOUROWNFORMNAME]))

*/

export const PasswordInputField = ({ input, fieldLabel, password, passwordType, togglePasswordType, requirementLabels, synchronous, required, maxLength, dataDtm, dataDtm2, btnDataDtm, btnDataDtm2, meta: { touched, error } }) => (
    <Translation>{(t) => (
        <div className={"gmit-gf-request-type ac-field-wrapper"}>
            {/* Input field label */}
            <div className="q-mod q-mod-gmit-gf-text-field gmit-gf-text-field">
                <label className="q-label2 q-caps gmit-gf-label-text">{t(fieldLabel)}</label>
            </div>
            <div className={"ac-pasword-input-wrapper gmit-gf-text-field " + (touched && error ? 'ac-field-error' : '')}>
                {/* Input field - if hiding password, not passing a type since quatum css adds weird padding even if 
                type is left blank*/}
                {passwordType == "LABEL_COMMON_SHOW_PASSWORD" &&
                    <input className={"gmit-gf-input-text stat-input-field " + `ac-password-input-${brand().toLowerCase()}`}
                        {...input}
                        type="password"
                        maxLength={maxLength}
                        data-dtm={dataDtm}
                        data-dtm2={dataDtm2} />
                }
                {passwordType != "LABEL_COMMON_SHOW_PASSWORD" &&
                    <input className={"gmit-gf-input-text stat-input-field " + `ac-password-input-${brand().toLowerCase()}`}
                        {...input}
                        maxLength={maxLength}
                        data-dtm={dataDtm}
                        data-dtm2={dataDtm2} />
                }
                {password && <PasswordStrengthMeter password={password} />}

                {/* Toggle password visibility */}
                <span className={`ac-toggle-password ac-toggle-password-visibility-${brand().toLowerCase()} stat-text-link`}
                    onClick={() => togglePasswordType(passwordType ===
                        'LABEL_COMMON_SHOW_PASSWORD' ?
                        'LABEL_COMMON_HIDE_PASSWORD' :
                        'LABEL_COMMON_SHOW_PASSWORD')}
                    data-dtm={btnDataDtm ? btnDataDtm : null}
                    data-dtm2={btnDataDtm2 ? btnDataDtm2 : null}>
                    {t(passwordType)}
                </span>
            </div>
            {/* Input field errors */}
            {touched && error && <div className={password ? "error" : "error ac-error-wrapper"}><label className="gmit-gf-error-label">{error}</label></div>}
            {/* Input field requirements listen below field */}
            {requirementLabels && <PasswordReqs requirementLabels={requirementLabels} synchronous={synchronous} />}
        </div>
    )}</Translation>
)

PasswordInputField.propTypes = {
    fieldLabel: propTypes.string, // label for input field
    passwordType: propTypes.string, // state of input type ('LABEL_COMMON_SHOW_PASSWORD' or 'LABEL_COMMON_HIDE_PASSWORD')
    togglePasswordType: propTypes.func, // toggles input type (text or password)
    requirementLabels: propTypes.array, // requirement text displayed below input field
    synchronous: propTypes.bool, // set to true for synchronous password requirements
    dataDtm: propTypes.string,
    dataDtm2: propTypes.string,
    password: propTypes.string // passing the user's input displays the pwd strength meter
}

// grid for checkmark + requirement text
function ReqGrid(props) {
    return (
        <Translation>
            {(t) => (
                <div className={"ac-password-requirements columns small-12 medium-6 " + (props.warning ? 'ac-req-passed' : '')}>
                    <FontAwesomeIcon style={{ fontSize: "20px" }} icon="check-circle" />
                    <span>{t(props.label)}</span>
                </div>
            )}</Translation>
    )
}

// synchronous requirement fields
const Req1 = ({ label, meta: { warning } }) => (
    <ReqGrid label={label} warning={warning} />
)
const Req2 = ({ label, meta: { warning } }) => (
    <ReqGrid label={label} warning={warning} />
)
const Req3 = ({ label, meta: { warning } }) => (
    <ReqGrid label={label} warning={warning} />
)
const Req4 = ({ label, meta: { warning } }) => (
    <ReqGrid label={label} warning={warning} />
)
const Req5 = ({ label, meta: { warning } }) => (
    <ReqGrid label={label} warning={warning} />
)

const PasswordReqs = (props) => {

    // static password requirements
    let requirements = props.requirementLabels.map((label, key) => {

        if (label) {
            return (
                <ReqGrid key={key} label={label} />
            )
        }

    })

    // synchronous password requirements
    if (props.synchronous) {
        requirements = props.requirementLabels.map((item, key) => {

            const component = [Req1, Req2, Req3, Req4, Req5];

            const name = ['Req1', 'Req2', 'Req3', 'Req4', 'Req5'];

            if (item) {
                return (

                    <div key={key}>
                        <Field
                            component={component[key]}
                            name={name[key]}
                            type="text"
                            label={item} />
                    </div>
                )
            }
        })
    }


    // since requirementLabels can return null/undefined for all reqs (due to these being defined in the vault)
    // i'm looping through the reqs here just in case tbefore displaying anything
    let showReqs = false;

    for (var i = 0; i < requirements.length; i++) {
        // check if all requirements are null/undefined
        if (!requirements[i]) {
            showReqs = false;
        }
        // if any are not null/undefined, show reqs
        else {
            showReqs = true
        }
    }

    if (showReqs) {
        return (
            <Translation>
                {(t) => (
                    <>  
                        <h4 className="q-text q-body2">{t("LABEL_ACCOUNT_REGISTRATION_ENTER_PASSWORD_REQUIREMENT")}</h4>
                        <Container>
                            <Row>{requirements}</Row>
                        </Container>
                        <p className="ac-registration-password-footnote q-text q-body2">
                            {t("LABEL_ACCOUNT_REGISTRATION_ENTER_PASSWORD_FOOTNOTE")}
                        </p>
                    </>
                 )
                }</Translation>
        )
    }
    else {
        return null
    }
}

// Optional password strength meter
const PasswordStrengthMeter = (props) => {

    const { password } = props;

    // test password score using zxcvbn library: https://github.com/dropbox/zxcvbn
    const testedInput = zxcvbn(password);

    function createPasswordLabel(input) {
        switch (input.score) {
            case 0: case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    }

    return (
        <Translation>
            {(t) => (
                <React.Fragment>
                    {password &&
                        <React.Fragment>
                            {/* Progress bar */}
                            <progress
                                className={`ac-pwd-strength-meter ac-pwd-strength-${createPasswordLabel(testedInput).toLowerCase()}`}
                                style={{ width: "100%" }}
                                value={testedInput.score}
                                max="4" />
                            {/* Progress bar status label */}
                            <span className={'ac-bold-text'} >{t("LABEL_PASSWORD_RESET_STRENGTH")} </span>
                            <span className={`ac-bold-text ac-pwd-strength-word-${createPasswordLabel(testedInput).toLowerCase()}`} >{createPasswordLabel(testedInput)}</span>
                        </React.Fragment>
                    }
                </React.Fragment>
            )}</Translation>
    )
}
