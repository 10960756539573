import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputGroup } from "react-bootstrap";
import { resetErrors } from "../HelperFunctions";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "react-places-autocomplete";
import MediaQuery from "react-responsive";

/**
 * City search function takes user input to get autocomplete and then updates currentCity.
 * When the value of currentCity changes, a GET call is made in DealerLocator.js
 * @param {CitySearchProps} props
 * @typedef CitySearchProps
 * @type {object}
 * @property {object} state - dealerizationState reducer updated
 * @property {function} dispatch - dealerizationState reducer
 * @return {CitySearch} React Component with search bar for zipcode
 */

export default function CitySearch(props) {
    const { t } = useTranslation();
    const MAX_WIDTH = props.state.showMap ? "979" : "639";
    const MIN_WIDTH = props.state.showMap ? "980" : "640";
    const [address, setAddress] = useState("");

    /**
     * @param {string} - takes string value selected from autocomplete dropdown
     * updates city in reducer so parent can make a backend call
     * to update the list of sources.
     */
    const handleCitySearch = (cityValue) => {
        if (!cityValue) {
            props.dispatch({ type: "TOGGLE_CITY_ERROR", showCityError: true });
            props.dispatch({ type: "SET_CITY_ERROR_MSG", cityErrorMsg: t("ERROR_DEALER_LOCATOR_INVALID_CITY") });
        } else {
            props.dispatch({ type: "UPDATE_CURRENT_CITY", currentCity: cityValue });
            props.dispatch({ type: "SOURCE_RESULTS", sourceResults: "City" });
            const mapArg = {
                coordinates: { lat: 37.0902, lng: -95.7129 },
                googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-adamopelag",
                zoom: 4
            };
            props.dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
            (props.state.showLocationError || props.state.showCityError) && resetErrors(props.dispatch);
            props.setDealerList((prevDealerList) => !prevDealerList);
        }
    };

    useEffect(() => {
        setAddress(props.state.currentCity);
    }, [props.state]);

    /**
     * @param {object} - takes placeString value, which is autocomplete address
     * placeID which was used for key values in autocomplete
     * placeInfo, has info for terms to generate correct city/state format
     */
    const handleSelect = (placeString, placeId, placeInfo) => {
        const cityTerm = placeInfo.terms[0].value;
        const stateTerm = placeInfo.terms[1].value;
        const finalCityTerm = cityTerm + "/" + stateTerm;
        setAddress(placeString);
        handleCitySearch(finalCityTerm);
    };

    // Allow only for cities in US for autocomplete
    const searchOptions = {
        types: ["(cities)"],
        componentRestrictions: { country: "us" }
    };

    /* UI render of the city search bar. */
    return (
        <div className="dl-search-container">
            {/** DESKTOP VIEW*/}
            <MediaQuery query={`(min-width: ${MIN_WIDTH}px)`}>
                <InputGroup>
                    <Autocomplete
                        type={"text"}
                        name={"dealerLocatorCity"}
                        onChange={setAddress}
                        value={address}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className="card-autocomplete">
                                <input
                                    {...getInputProps({ placeholder: t("LABEL_COMMON_CITY_NAME") })}
                                    id={"dealerization-search-bar"}
                                    className="stat-search-input"
                                    data-dtm="modal:dealer search"
                                    name={"dealerLocatorCity"}
                                />
                                <div className="dropdown-autocomplete">
                                    {suggestions.map((suggestion) => {
                                        const style = {
                                            backgroundColor: suggestion.active ? "#F8F8F8" : "#fff"
                                        };

                                        return (
                                            <li
                                                className="input-suggestion"
                                                {...getSuggestionItemProps(suggestion, { style })}
                                                key={suggestion.placeId}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                    className="map-marker-icon"
                                                ></FontAwesomeIcon>
                                                {suggestion.description}
                                            </li>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Autocomplete>
                </InputGroup>
            </MediaQuery>

            {/** MOBILE VIEW */}
            <MediaQuery query={`(max-width: ${MAX_WIDTH}px)`}>
                <InputGroup>
                    <Autocomplete
                        type={"text"}
                        name={"dealerLocatorCity"}
                        id={"dealerization-search-bar"}
                        onChange={setAddress}
                        value={address}
                        onSelect={handleSelect}
                        searchOptions={searchOptions}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="card-autocomplete">
                                <input
                                    {...getInputProps({
                                        placeholder: t("LABEL_COMMON_CITY_NAME")
                                    })}
                                    id={"dealerization-search-bar"}
                                />

                                <div className="dropdown-autocomplete">
                                    {suggestions.map((suggestion) => {
                                        const style = {
                                            backgroundColor: suggestion.active ? "#F8F8F8" : "#fff"
                                        };

                                        return (
                                            <li
                                                className="input-suggestion"
                                                {...getSuggestionItemProps(suggestion, { style })}
                                                key={suggestion.placeId}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                    className="map-marker-icon"
                                                ></FontAwesomeIcon>
                                                {suggestion.description}
                                            </li>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </Autocomplete>
                </InputGroup>
            </MediaQuery>
            {props.state.showCityError && <div className="prt-dealerization-error-msg">{props.state.cityErrorMsg}</div>}
        </div>
    );
}

// PropType Checking
CitySearch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
