import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import AppSettings from '../../../core/AppSettings';

class HomeIntro extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isT3 = AppSettings.isT3;

    return (
      <Translation>
        { (t) => (
          <div className="ac-home-intro row q-gridbuilder gridfullwidth columns gm-ow-highlight-text">
            <div className="small-12 medium-12 large-12 xlarge-9  grid-column-alignment-left  q-grid-row-new-mobile columns">
              {/*<div className="q-margin-base q-headline">
                <div className="none-margin">
                  <div>
                    <h2 className="q-display2">{t("LABEL_COMMON_INTRO_HEADER")}</h2>
                  </div>
                </div>
              </div>*/}
              <div className="q-margin-base">
                <div className="medium-margin">
                  <div className={"q-text q-body1 " + (isT3 ? "ac-text-inherit" : "")}>
                    <p>{t("LABEL_COMMON_INTRO_DESCRIPTION")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>)
        }
      </Translation>
    );
  }
}

export default HomeIntro;