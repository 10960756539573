import {
  call,
  put
} from 'redux-saga/effects';
import AppSettings from '../../../../core/AppSettings';
import {
  setLogo,
  setLogoFail,
  setLoading,
  setDealerHeader,
  setDealerHeaderFail,
  setFlyoutText,
  setFlyoutTextFail
} from '../HeaderRedux/HeaderActionCreator';
import {
  getLogo,
  getDealerData,
  getFlyoutText
} from './HeaderService';
import { getBaseMarketingSpotActivityData } from '../../../../shared/Validator/Validator';

export function* handleLoadHeaderData() {
  if (AppSettings.isT3) {
    yield callDealerData();
  } else {
    yield callForLogo();
    yield callForFlyoutText();
  }
  yield put(setLoading(false));
}

function* callForLogo() {
  try {
    const res = yield call(getLogo);
    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if(baseMarketingSpotActivityData) {
      const imgPath = baseMarketingSpotActivityData[0].attachmentAsset[0].attachmentAssetPath;
      const damImgUrlDomain = "/parts/images"; // the DAM path
      const imgURL = imgPath.replace(/https?:\/\/\[[a-z]\w*\]/, damImgUrlDomain);
      yield put(setLogo(imgURL));
    } else {
      yield put(setLogoFail(new Error('invalid marketing data')));
    }

  } catch (error) {
    yield put(setLogoFail(error));
  }
}

function* callDealerData() {
  try {
    const res = yield call(getDealerData);

    yield setDealerLogo(res.data);
    yield setDealerMasthead(res.data);

  } catch (error) {
    yield put(setDealerHeaderFail(error));
    yield put(setLogoFail(error));
  }
}

function* callForFlyoutText() {
  try {
    yield put(setFlyoutText(''));
    let textDiv = '';
    const res = yield call(getFlyoutText);
    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if (baseMarketingSpotActivityData) {
      baseMarketingSpotActivityData.forEach(function (espot) {
        if (espot.marketingContentDescription[0]) {
          textDiv += espot.marketingContentDescription[0].marketingText;
        }
      });
      yield put(setFlyoutText(textDiv));
    } else {
      yield put(setFlyoutTextFail(new Error('invalid marketing data')));
    }
  }
  catch (error) {
    yield put(setFlyoutTextFail(error));
  }
}

function* setDealerLogo(data) {
  try {
    const imgPath = data.attributes.LOGO_URL.value;
    yield put(setLogo(imgPath));

    if (data) {
      setDealerLogo(data);

    }
  } catch(error) {
    yield put(setLogoFail(error));
  }

}

function* setDealerMasthead(data) {
  try {
    const dealerName = data.name;
    const resDealerAddress = data.DealerAddress;
    const orgId = data.activeOrgId;

    let dealerAddress = '';

    if(resDealerAddress.address1) {
      dealerAddress += resDealerAddress.address1 + ', ';
    }
    if(resDealerAddress.city) {
      dealerAddress += resDealerAddress.city + ', ';
    }
    if(resDealerAddress.stateOrProvinceName) {
      dealerAddress += resDealerAddress.stateOrProvinceName + ' ';
    }
    if(resDealerAddress.postalCode) {
      dealerAddress += resDealerAddress.postalCode + ' ';
    }
    if(resDealerAddress.country) {
      dealerAddress += resDealerAddress.country;
    }

    let dealerPhone = '';
    if (data.attributes.SALES_PHONE) {
      dealerPhone = 'Sales: ' + data.attributes.SALES_PHONE.value;
    }
    if (data.attributes.SERVICE_PHONE) {
      dealerPhone += ' | Service: ' + data.attributes.SERVICE_PHONE.value;
    }
    yield put(setDealerHeader(dealerAddress, dealerPhone, dealerName, orgId));
  } catch (error) {
    yield put(setDealerHeaderFail(error));
  }
}