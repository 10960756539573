import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { constructAzureLoginURL } from '../../../../../shared/Authentication/AuthenticationWorker';
import AppSettings from "../../../../../core/AppSettings";
import {
    useReplaceAllNamedDisclaimers
} from '../../../Footer/FooterDisclaimerLabel';

const GuestFlyoutWidget = (props) => {
    const { clientId, userFlow, applicationStateString, createAcctEmail, currentSite, eSpotFlyoutText } = props;
    const [isFlyoutActive, setFlyoutActive] = useState(false);
    const [flyoutText, setFlyoutText] = useState('');
    let prevScrollpos = window.pageYOffset;
    const brand = AppSettings.currentSite.label
    // Used to keep the clickable area of the flyout at the correct height -- it can change if the window is resized and text within wraps around.
    const [flyoutHeight, setFlyoutHeight] = useState(0);

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();
    const createMarkup = (text) => {
        return {
            __html: text
        };
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleClick);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('resize', handleResize);
        };
    }, [isFlyoutActive, flyoutHeight]
    );

    useEffect(() => {
        setFlyoutText(eSpotFlyoutText.replace("[brand]", brand));
    }), [eSpotFlyoutText]

    // Resize the clickable area of the flyout as needed -- this occurs if the window is resized enogh for the text to wrap.
    const handleResize = () => {
        if (isFlyoutActive) {
            setFlyoutHeightFromWrapperHeight();
        }
    }

    // If scrolling down, then close the flyout.
    const handleScroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos < currentScrollPos) {
            setFlyoutActive(false);
        }
        prevScrollpos = currentScrollPos;
    }

    const handleClick = (e) => {
        const widgetFlyoutElement = document.getElementById("ac-user-widget");
        const flyoutWrapperElement = document.getElementsByClassName("ac-header-flyout-clickable-area ");
        let targetElement = e.target;

        // Potential explanation of this do/while loop: The target element is the innermost nested element (if nested at all) of the position clicked.
        // At the end of each iteration the next targetElement is the parent of the inner element in the prior iteration. The loop ends when there are no more parent elements.
        // In this situation we look through all of the elements at the position clicked and check if the we clicked the element we were looking for to toggle flyout.
        do {
            // Do not close the flyout if the flyout itself is clicked.
            if (targetElement == flyoutWrapperElement[0]) {
                return;
            }

            if (targetElement == widgetFlyoutElement) {
                setFlyoutActive(!isFlyoutActive);
                if (!isFlyoutActive) {
                    // Set clickable area height to prevent flyout from closing when clicking within.
                    setFlyoutHeightFromWrapperHeight();
                }
                return;
            }

            targetElement = targetElement.parentNode;
        } while (targetElement);

        setFlyoutActive(false);
    }

    // Get the size of the wrapper for the flyout, so that the clickable area can have it's height set.
    const setFlyoutHeightFromWrapperHeight = () => {
        const heightobj = document.getElementsByClassName("ac-header-dropdown-content-header-wrapper");
        setFlyoutHeight(heightobj[0] ? heightobj[0].offsetHeight : '');
    }



    const flyoutElement = (
        <Translation>
            {(t) => (
                <div id="ac-signIn-flyout" className="row">
                    <div className={isFlyoutActive ? "medium-5 large-4 xlarge-3 ac-header-user-dropdown-mobile ac-header-user-dropdown q-expander" : "ac-header-user-dropdown-hidden q-expander"}>
                        <div className={eSpotFlyoutText ? "ac-header-dropdown-content-header-wrapper" : "ac-header-dropdown-content-header-wrapper-hidden"}>
                            <div className="ac-header-flyout-clickable-area" style={{ height: flyoutHeight + "px" }} />
                            <h5 className="ac-header-dropdown-content-header q-body1">{t('LABEL_ACCOUNT_MY_ACCOUNT')}</h5>
                            <div dangerouslySetInnerHTML={createMarkup(
                                replaceDisclaimers(flyoutText)
                            )} />
                        </div>
                        <div onClick={() => handleRegisterUser()} id="ac-register-account" className="ac-sign-in-flyout q-text ac-header-user-link stat-button-link" data-dtm="global nav:my account">
                            {t('LABEL_COMMON_REGISTER_ACCOUNT')}
                        </div>
                        <div onClick={() => handleUserLogin(userFlow, clientId, applicationStateString, currentSite, createAcctEmail)} id="ac-sign-in-flyout" className="ac-sign-in-flyout q-text ac-header-user-link stat-button-link" data-dtm="global nav:my account">
                            {t('LABEL_COMMON_SIGN_IN')}
                        </div>
                    </div>
                </div>
            )}
        </Translation>);

    return (
        isFlyoutActive ? flyoutElement : <div/>
    )
}

const handleUserLogin = (userFlow, clientId, applicationStateString, currentSite, email) => {
    window.location.href = constructAzureLoginURL(userFlow, clientId, applicationStateString, '/authenticate', currentSite, email);
}

const handleRegisterUser = () => {
    window.location.href = '/register';
}

export default GuestFlyoutWidget;
