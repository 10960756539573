import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import store from "../../../core/Redux/Store";
import ErrorMessage from "../../../shared/ErrorMessage/ErrorMessage";
import DeliveryForm from "../Pages/DeliveryAndDiscounts/Delivery/DeliveryForm";
import DeliveryFormT3 from "../Pages/DeliveryAndDiscounts/Delivery/DeliveryFormT3";
import Coupons from "../Pages/DeliveryAndDiscounts/Discounts/Coupons/Coupons";
import PaymentPage from "../Pages/PaymentAndBilling/PaymentPage/PaymentPage";
import AdyenPaymentPage from "../Pages/PaymentAndBilling/PaymentPage/AdyenPaymentPage";
import ReviewForm from "../Pages/Review/ReviewForm";
import ShippingContactForm from "../Pages/ShippingAndContact/ShippingAndContactForm";
import ZeroDollarCheckout from "../Pages/PaymentAndBilling/ZeroDollarCheckout/ZeroDollarCheckout";
import { preventStepOneSkipStatus, shouldPreventStepOneSkip } from "../CheckoutRedux/CheckoutActionCreator";
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";

// TODO: need to fix infinite spinner on step 3, setting to false to unblock environments for now
const REMOVE_COUPON_CHECKOUT = AppSettings.isLocalHost ? false : String(vaultConstants.FF_2106248_REMOVE_COUPON_CHECKOUT) === 'true';
const dynamicCheckoutFeatureFlag = AppSettings.isLocalHost || vaultConstants.FF_2094634_DYNAMIC_CHECKOUT;

const CheckoutWizard = (props) => {
    const { t } = useTranslation();

    const {
        onSubmit,
        onPrevious,
        label,
        handleContactFormSubmit,
        handleDeliveryMethodChange,
        handleCouponCodeSubmit,
        handleCouponCodeRemove,
        handlePaymentFormSubmit,
        handleBillingInformationCheckBox,
        shippingContactFormData,
        handleDeliveryFormSubmit,
        cartResponse,
        enableBillingAsShipping,
        handlePaymentPrevious,
        paymentResponse,
        handleEditClick,
        handleSubmitOrder,
        showOrderProcessingModal,
        hideCheckoutAlert,
        onDeliveryFormPrevious,
        deliveryForm,
        deliveryError,
        deliveryErrorTax,
        deliveryMethodShowSpinner,
        deliveryMissingParams,
        isRewardsInputDisabled,
        showPaymentSpinner,
        zeroDollarAmount
    } = props;

    const step = props.step;

    useEffect(() => {
        if(dynamicCheckoutFeatureFlag) {
            const userContactForm = shippingContactFormData.initialValues;
            if (props.step === 1 && contactFormIsNotEmpty(userContactForm) && !preventStepOneSkipStatus()) {
                handleContactFormSubmit(userContactForm, false);
            } else {
                store.dispatch(shouldPreventStepOneSkip());
            }
        }
    }, [step]);

    const contactFormIsNotEmpty = (userContactForm) => {
        if (
            userContactForm?.firstName != "" &&
            userContactForm?.lastName != "" &&
            userContactForm?.email != "" &&
            userContactForm?.address1 != "" &&
            userContactForm?.city != "" &&
            userContactForm?.state != "" &&
            userContactForm?.postalCode != "" &&
            (userContactForm?.phone)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getCheckoutWizardClassName = () => {
        if (showPaymentSpinner === true) {
            return "hide";
        } else {
            return "";
        }
    };

    const showPaymentError = paymentResponse.showPaymentUserError;
    const showTechnicalError = paymentResponse.showTechnicalError;
    const showMissingPaymentError = paymentResponse.showMissingPaymentError;

    let showRequiredInformation = step === 1 || step === 2 || step === 3;

    if (step === 3 && zeroDollarAmount) {
        showRequiredInformation = false;
    }

    return (
        <div id="ac-checkout-wizard" className={getCheckoutWizardClassName()}>
            {
                /*** SHOW TECHNICAL ERROR ON PAYMENT PAGE ***/
                showTechnicalError && <ErrorMessage message={t("LABEL_CHECKOUT_MASTERCARD_TECH_ERROR")} icon={true} />
            }
            {
                /*** SHOW PAYMENT ERROR ON PAYMENT PAGE ***/
                showPaymentError && <ErrorMessage message={t("LABEL_CHECKOUT_MASTERCARD_PAYMENT_ERROR")} icon={true} />
            }
            {showMissingPaymentError && (
                <ErrorMessage message={t("LABEL_CHECKOUT_MASTERCARD_MISSING_PAYMENT_ERROR")} icon={true} />
            )}
            <div className="ac-checkout-wizard-header">
                <h2 className="q-headline2 ac-checkout-wizard-header-title">{t(label)}</h2>
                <hr className="q-separator separator-primary" />
                {showRequiredInformation && (
                    <h4 className="q-headline4 ac-checkout-wizard-required">{t("LABEL_COMMON_REQ_INFO")}</h4>
                )}
            </div>
            <div>
                {step === 1 && (
                    <ShippingContactForm
                        onSubmit={handleContactFormSubmit}
                        stateList={shippingContactFormData.stateList}
                        initialValues={shippingContactFormData.initialValues}
                    />
                )}
                {step === 2 &&
                    (!AppSettings.isT3 ? (
                        <>
                            <DeliveryForm
                                previousPage={onDeliveryFormPrevious}
                                handleDeliveryFormSubmit={handleDeliveryFormSubmit}
                                onSubmit={handleDeliveryFormSubmit}
                                handleDeliveryMethodChange={handleDeliveryMethodChange}
                                handleCouponCodeSubmit={handleCouponCodeSubmit}
                                handlecouponCodeRemove={handleCouponCodeRemove}
                                data={cartResponse}
                                deliveryForm={deliveryForm}
                                deliveryError={deliveryError}
                                deliveryErrorTax={deliveryErrorTax}
                                deliveryMethodShowSpinner={deliveryMethodShowSpinner}
                                deliveryMissingParams={deliveryMissingParams}
                                isRewardsInputDisabled={isRewardsInputDisabled}
                            />
                            {!REMOVE_COUPON_CHECKOUT &&
                             <Coupons handleCouponCodeSubmit={handleCouponCodeSubmit} />}
                        </>
                    ) : (
                        <>
                            <DeliveryFormT3
                                previousPage={onDeliveryFormPrevious}
                                handleDeliveryFormSubmit={handleDeliveryFormSubmit}
                                onSubmit={handleDeliveryFormSubmit}
                                handleDeliveryMethodChange={handleDeliveryMethodChange}
                                handleCouponCodeSubmit={handleCouponCodeSubmit}
                                handlecouponCodeRemove={handleCouponCodeRemove}
                                data={cartResponse}
                                deliveryForm={deliveryForm}
                                deliveryError={deliveryError}
                                deliveryErrorTax={deliveryErrorTax}
                                deliveryMethodShowSpinner={deliveryMethodShowSpinner}
                                deliveryMissingParams={deliveryMissingParams}
                                isRewardsInputDisabled={isRewardsInputDisabled}
                            />
                            <Coupons handleCouponCodeSubmit={handleCouponCodeSubmit} />
                        </>
                    ))}
                {step === 3 &&
                    (zeroDollarAmount ? (
                        <ZeroDollarCheckout />
                    ) :  <AdyenPaymentPage
                            previousPage={onPrevious}
                            onSubmit={handlePaymentFormSubmit}
                            handleBillingInformationCheckBox={handleBillingInformationCheckBox}
                            cartResponse={cartResponse}
                            enableBillingAsShipping={enableBillingAsShipping}
                            handlePaymentPrevious={handlePaymentPrevious}
                            payInStoreNext={onSubmit}
                            payInStorePrev={onPrevious}
                        />
                    )}

                {step === 4 && (
                    <ReviewForm
                        previousPage={onPrevious}
                        data={cartResponse}
                        paymentResponse={paymentResponse}
                        handleEditClick={handleEditClick}
                        zeroDollarAmount={zeroDollarAmount}
                        initialValues={shippingContactFormData.initialValues}
                        handleContactFormSubmit={handleContactFormSubmit}
                        stateList={shippingContactFormData.stateList}

                    />
                )}
            </div>
        </div>
    );
};

CheckoutWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default CheckoutWizard;
