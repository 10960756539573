import React, { Component } from 'react';
import {Translation} from "react-i18next";
import store from '../../../../../core/Redux/Store';
import {nextStep, turnOffSpinnerForZeroCheckout} from "../../../CheckoutRedux/CheckoutActionCreator";



class ZeroDollarCheckout extends Component {

    componentWillMount() {
        store.dispatch(turnOffSpinnerForZeroCheckout());
        store.dispatch(nextStep());
    }
    render (){
        return (
            <Translation>
                {(t) => (<div>
                    {t('LABEL_ZERO_CHECKOUT_AMOUNT_PAYMENT_MESSAGE')}
                </div>)}
            </Translation>
        )
    }


};

export default ZeroDollarCheckout
