import React, { useEffect, useState } from 'react'
import { Translation } from 'react-i18next';
import Button from '../../Button/Button';
import './GMRewardsCardOffer.scss'
import LeavingSiteModal from "../../Modal/LeavingSiteModal/LeavingSiteModal";
import { getBaseMarketingSpotActivityData } from "../../Validator/Validator";
import { getCardOfferEspot } from "./GMRewardsCardOfferService";
import { useReplaceAllNamedDisclaimers } from '../../../modules/App/Footer/FooterDisclaimerLabel';

const GMRewardsCardOffer = () => {
    const [showModal, setShowModal] = useState(false);
    const [offerMessage, setOfferMessage] = useState("");
    const [offerImage, setOfferImage] = useState();
    const [learnMore, setLearnMore] = useState();
    const [espotMade, setEspotMade] = useState(false);

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();

    useEffect(() => {
        if (espotMade) {
            let formattedText = replaceDisclaimers(offerMessage);
            setOfferMessage(formattedText);
        }
    }, [espotMade, replaceDisclaimers]);

    useEffect(() => {
        getRewardsCardOffer();
    }, []);

    //Get data from Espot and set Values for each content
    const getRewardsCardOffer = async () => {
        try {
            const response = await getCardOfferEspot();
            const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(response.data, 0);
            if (baseMarketingSpotActivityData[0].marketingContentDescription[0]) {
                setOfferMessage(baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText);
                setOfferImage(baseMarketingSpotActivityData[0].attachmentAsset[0].attachmentAssetPath);
                setLearnMore(baseMarketingSpotActivityData[1].marketingContentDescription[0].marketingText);
                setEspotMade(true);
            }
        } catch (e) {
            console.warn("Unable to retrieve content from espot -->", e);
        }
    }

    const handleLearnMore = () => {
        window.open(learnMore);
    };

    return (
        <Translation>
            {(t) => (
                <div>
                    <LeavingSiteModal open={showModal} onClose={() => setShowModal(false)} center gmRewardsCardOffer={true} />
                    <hr />
                    {offerMessage && <div id="rewards-promo-card-offer" className="rewards-container">
                        <div className="rewards-divider">
                            <div id="rewards-image-container" className="rewards-img-container">
                                <img className="rewards-img"
                                    src={offerImage}
                                    alt="gm card image" />
                            </div>
                            <div className="rewards-facts">
                                <div className='offer-message' dangerouslySetInnerHTML={{ __html: offerMessage }} />
                                <div className="rewards-button-container">
                                    <div className="apply-btn">
                                        <Button id="rewards-promo-apply-btn" localeKey="Apply Today"
                                            onClick={() => setShowModal(true)}
                                            className="stat-button-link" dataDtm="gm rewards card" />
                                    </div>
                                    <div className="learn-more-btn">
                                        <Button id="rewards-promo-learn-more-btn" localeKey="Learn More"
                                            onClick={() => handleLearnMore()} isSecondary={true}
                                            className="stat-button-link" dataDtm="gm rewards card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

            )}
        </Translation>
    )
}

export default GMRewardsCardOffer
