/**********IMPORT STATEMENT *****************/
import React from 'react';
import {Translation} from 'react-i18next';
import propTypes from "prop-types";

const WheelPackageTable = (props)=>{
    const wheelPackData = props.data  || '';
    const MOUNTING_AND_BALANCING = 'mounting and balancing';

    /********** COMPONENT WILL RETURN NULL IF THERE IS NO DATA *****************/
    if(!wheelPackData.length){
        return null
    }
    else
    /********** COMPONENT WILL RENDER ONLY IF THERE IS EXPECTED DATA *****************/
        return(
        <Translation>
            { (t) => (
                <div className="ac-wheelPackageTableWrapper">
                    <div className="q-headline2">{t("LABEL_WHEEL_PACKAGES")}</div>
                    <hr/>
                    <div className='ac-addScroll'>
                            <table>
                        <thead>
                        <tr>
                            <td>{t("LABEL_WHEEL_PACKAGES_PART_NUMBER")}</td>
                            <td>{t("LABEL_WHEEL_PACKAGES_PART_DESCRIPTION")}</td>
                            <td>{t("LABEL_WHEEL_PACKAGES_QUANTITY")}</td>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            wheelPackData.map((item,index)=>{
                                const productDescription = item.name.toLowerCase();

                                if(productDescription !== MOUNTING_AND_BALANCING){
                                    return <tr className={item.partNumber+'mountingBalancing'} key={index}>
                                        <td className="q-text">{item.partNumber}</td>
                                        <td className="q-text">{item.name}</td>
                                        <td className="q-text">{item.quantity}</td>
                                    </tr>
                                 }
                                })
                        }
                        </tbody>
                            </table>
                        </div>
                </div>
            )}
        </Translation>
    )
}


/********** VALIDATING PROPS TYPE *****************/
WheelPackageTable.propTypes = {
    data: propTypes.arrayOf(
          propTypes.shape({
            partNumber:propTypes.string.isRequired,
            name:propTypes.string.isRequired,
            quantity:propTypes.string.isRequired
        })
    )
};

export default WheelPackageTable

/********** END OF WHEEL PACKAGE TABLE *****************/