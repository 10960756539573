import { getHomeCarouselData } from "./HomeCarouselService";
import { setHomeCarouselData, setHomeCarouselDataFail } from "./HomeCarouselActionCreator";
import { call, put } from "redux-saga/effects";

/**************************************************************/
// HOME CAROUSEL CALL
// Espot response has a lot of unnecessary info for UI. The JSON
// is parsed here and only the relevant data is sent to the
// home carousel component (see HomeCarouselActionCreator & HomeCarouselReducer)
/**************************************************************/
export function* callForHomeCarousel() {
    let carouselAssets = {};

    try {
        //get all data from response
        const homeCarouselResponse = yield call(getHomeCarouselData);
        const espotData = homeCarouselResponse.MarketingSpotData[0].baseMarketingSpotActivityData;

        //take only relevant data from response
        carouselAssets = parseAssets(espotData);

        //clean data set in SET_HOME_CAROUSEL_DATA
        //and is added to state
        yield put(setHomeCarouselData(carouselAssets));
    } catch (error) {
        // Dispatches SET_HOME_CAROUSEL_DATA_FAIL to handle the error.
        yield put(setHomeCarouselDataFail(error));
    }
}

/**************************************************************/
// PARSE JSON FOR HOME CAROUSEL
// Assets extracted from espot:
//      Asset Name (used only as alt text and not for functionality),
//      Marketing Text,
//      Carousel Image (+small & medium sizes),
//      Video Placeholder Image (+small & medium sizes),
//      Video Id
/**************************************************************/
function parseAssets(homeCarouselResponse) {
    let assets = {
        assetName: "",
        imagePath: "",
        imagePathSm: "",
        imagePathMd: "",
        videoPlaceholderImgPath: "",
        videoPlaceholderImgPathmd: "",
        videoPlaceholderImgPathSm: "",
        videoId: "",
        marketingText: "",
        contentUrl: ""
    };

    // Mapping data to extract only the relevant information from the espot
    assets = homeCarouselResponse.map((item) => {
        //check for marketing text/markup (overlay text on carousel images)
        let marketingText = "";

        if (item.marketingContentDescription && item.marketingContentDescription[0].marketingText) {
            marketingText = item.marketingContentDescription[0].marketingText;
        }

        let contentUrl = "";
        if (item.contentUrl) {
            contentUrl = item.contentUrl;
        }

        // note: video and image paths are in the same location in espot
        //       and aren't differentiated, so the logic below sorts through
        //       what is an image slide and what is a video slide
        let assetType =
            item.attachmentAsset && item.attachmentAsset[0] && item.attachmentAsset[0].xasse_type
                ? item.attachmentAsset[0].xasse_type
                : "";

        let imagePath = "";
        let imagePathMd = "";
        let imagePathSm = "";
        let videoPlaceholderImgPath = "";
        let videoPlaceholderImgPathMd = "";
        let videoPlaceholderImgPathSm = "";
        let videoId = "";
        let videoIsPlaying = false;

        // parse asset url paths to extract the video id,
        // video placeholder image paths, and image paths

        if (assetType === "image") {
            imagePath = item.attachmentAsset[0].attachmentAssetPath;
            imagePathMd = imagePath.replace(/-Large./, "-Medium.");
            imagePathSm = imagePath.replace(/-Large./, "-Small.");
        }
        if (assetType === "video") {
            videoPlaceholderImgPath = item.attachmentAsset[0].xasse_image1;
            videoPlaceholderImgPathMd = videoPlaceholderImgPath.replace(/-Large./, "-Medium.");
            videoPlaceholderImgPathSm = videoPlaceholderImgPath.replace(/-Large./, "-Small.");
            videoId = item.attachmentAsset[0].attachmentAssetPath;
        }

        // return clean data
        return {
            assetName: item.baseMarketingSpotActivityName,
            marketingText: marketingText,
            contentUrl: contentUrl,
            imagePath: imagePath,
            imagePathSm: imagePathSm,
            imagePathMd: imagePathMd,
            videoPlaceholderImgPath: videoPlaceholderImgPath,
            videoPlaceholderImgPathMd: videoPlaceholderImgPathMd,
            videoPlaceholderImgPathSm: videoPlaceholderImgPathSm,
            videoId: videoId,
            videoIsPlaying: videoIsPlaying
        };
    });

    return assets;
}
