
import React, { Component } from 'react';

import PackagingInformation from "./PackagingInformation";
import ProductSpecifications from "./ProductSpecifications";
import { Trans } from "react-i18next";

export class ProductSpecsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showProductSpecification: false,
            showPackagingInformation: false,
            hasProductSpecsError: false,
            hasPackagingInfoError: false
        }
    }

    componentDidMount() {
        this.getProductSpecs();
    }

    getProductSpecs() {
        const packagingInformationData = this.props.packagingInformationData;
        const productSpecificationData = this.props.productSpecificationData;

        try {
            if (productSpecificationData &&
                productSpecificationData.length &&
                (productSpecificationData !== undefined)) {
                this.setState({ showProductSpecification: true });
            }
        } catch (error) {
            console.log("Error retrieving Product Specs Data: ", error);
            this.setState({ hasProductSpecsError: true });
        }

        try {
            const packagingPackOF = packagingInformationData[0].packOf
            if (packagingInformationData &&
                packagingInformationData.length &&
                (packagingInformationData !== undefined) &&
                !(packagingPackOF > 1)) {
                this.setState({ showPackagingInformation: true });
            }
        } catch (error) {
            console.log("Error retrieving Product Packaging Info: ", error);
            this.setState({ hasPackagingInfoError: true });
        }
    }

    render() {
        // Set the css sizing for description
        let productSpecsClass = "small-12 medium-12 large-12 xlarge-12";
        if (this.state.showProductSpecification && this.state.showPackagingInformation) {
            productSpecsClass = 'large-6 xlarge-6 ac-packaging-information';
        }

        return <div className="ac-product-specs">
            {
            // Error will only show on UI if BOTH Product Specs and Packaging Information are unavailable
                this.state.hasProductSpecsError && this.state.hasPackagingInfoError &&
                <div className={productSpecsClass}><div className="ac-tabContent">
                    <h2 className="q-headline2"><Trans i18nKey="LABEL_PRODUCT_SPECS_NOT_AVAILABLE"></Trans></h2></div>
                </div>
            }
            {
                /*** RENDER PRODUCT SPECIFICATION IF THERE IS ANY DATA AVAILABLE ***/
                this.state.showProductSpecification && <div className={productSpecsClass}>
                    <ProductSpecifications productSpecsData={this.props.productSpecificationData}
                        productSpecsTitle={this.props.productSpecificationTitle} />
                </div>
            }
            {
                /*** RENDER PACKAGING INFORMATION IF THERE IS ANY DATA AVAILABLE ***/
                this.state.showPackagingInformation && <div className={productSpecsClass}>
                    <PackagingInformation packInfoData={this.props.packagingInformationData}
                        packInfoTitle={this.props.packagingInformationTitle} />
                </div>
            }
        </div>
    }
}

export default ProductSpecsTab
