import * as type from './WalletActionType';

const initialState = {
    gpmsWalletData: null,
    gpmsLocale: null,
    gpmsCountryCode: "US",
    error: false,
    walletLoadError: false
}

const WalletReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_WALLET_DATA:
            return {
                ...state,
                gpmsWalletData: action.walletData
            };

        case type.SET_GPMS_LOCALE:
            return {
                ...state,
                gpmsLocale: action.locale,
                gpmsCountryCode: action.locale.split('-')[1]
            };

        case type.SET_WALLET_DATA_ERROR:
            return {
                ...state,
                error: true
            }

        case type.WALLET_LOAD_ERROR:
            return {
                ...state,
                error: true,
                walletLoadError: true
            }

        default: return state;
    }
}

export default WalletReducer