import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import { connect } from "react-redux";
import VehicleSection from "./VehicleSection";

const MyVehicles = props => {
    return (
        <Translation>
            {
                (t)=>(
                    <div className = "ac-profile-vehicles small-12 grid-column-alignment-left columns ac-nopadding-x">
                        <h1 className="q-headline1">{t("LABEL_MY_VEHICLES")}</h1>
                        <VehicleSection/>
                    </div>

                )
            }
        </Translation>
    )
}

const mapStateToProps = state => {
    return {
        profileSettingsState: state.UserProfileReducer.profilesettings
    };
};

export default connect(mapStateToProps)(MyVehicles);