import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button/Button";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {appendAnalyticsTagAsURLParam} from "../../Utils/Utils";

//This component is a modal that welcomes the user to the new GMWallet
//It will ask the user to add new Payments or select their exisiting payments if applicable
const WalletConsentModal = (props) => {
    const { t } = useTranslation();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS + appendAnalyticsTagAsURLParam("eVar25", "new-wallet-modal");;

    useEffect(() => {
        props.dispatch({ type: "SHOW_WALLET_MODAL", boolean: true });
    }, []);

    useEffect( () => {
        //checks if /getPaymentMethods returns with data and makes sure the user has storedPaymentMethods
        if (JSON.stringify(props.state.payMethodsResponse) !== "{}" && 
            props.state.payMethodsResponse?.storedPaymentMethods) {
            //if user has payment methods that they need to move to Global GM wallet, then show 'Find Existing' button
            props.dispatch({ type: "SHOW_FIND_EXISITING_BTN", boolean: true });
        }
    }, []);
    //modal styling that is sent to the <Modal component
    const modalStyling= {
        modal: 'wallet-modal-width'
    };

    return (
        <>
        {!props.state.showFindExistingBtn && (
                <div id={"wallet-modal"} className="gmWalletConsentModal">
                    <Modal
                        classNames={modalStyling}
                        onClose={() => props.dispatch({ type: "SHOW_WALLET_MODAL", boolean: false })}
                        open={props.state.showWalletModal}
                    >
                        <div>
                            <div className="q-headline1 welcome-header ac-modal-header">
                           {t("LABEL_GMWALLET_WELCOME_HEADER")}
                            </div>
                            <div className="ac-modal-body">
                                <div>
                                    <p>{t("LABEL_GMWALLET_WELCOME_MANAGE_PAYMENT_INTRODUCTION")}</p>
                                    <br></br>
                                    <a className="privacy-statement stat-text-link" id="privacy-statement" data-dtm="modal:new wallet" href={privacyLink} target="_blank">
                                        {t("LABEL_GMWALLET_WELCOME_PRIVACY_STATEMENT")}
                                        <br></br>
                                    </a>
                                    <br></br>
                                </div>
                            </div>
                            <div className="gmWallet-btn-container">
                                <div className="addNew-btn-nopayment" id="addNew-btn-nopayment">
                                    <Button
                                        id="wallet-consent-modal-add-new-btn"
                                        className="stat-button-link"
                                        dataDtm="modal:new wallet"
                                        localeKey={t("LABEL_GMWALLET_ADD_NEW_BTN")}
                                        onClick={() => {
                                            props.dispatch({ type: "VIEW_ADD_NEW_PAY", boolean: true });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        {props.state.showFindExistingBtn && (
            <div className="gmWalletConsentModal">
                <Modal
                    classNames={modalStyling}
                    onClose={() => props.dispatch({ type: "SHOW_WALLET_MODAL", boolean: false })}
                    open={props.state.showWalletModal}
                >
                    <div>
                        <div className="q-headline1 welcome-header ac-modal-header">
                            {t("LABEL_GMWALLET_WELCOME_HEADER")}
                        </div>
                        <div className="ac-modal-body">
                            <div>
                                <p>{t("LABEL_GMWALLET_WELCOME_MANAGE_PAYMENT_INTRODUCTION")}</p>
                                <br></br>
                            </div>
                            <div className="found-payment-section">
                                <br></br>
                                <div className="q-headline1 welcome-header">{t("LABEL_GMWALLET_WELCOME_PAYMENT_QUESTION")}</div>
                                <p>{t("LABEL_GMWALLET_WELCOME_FIND_PAYMENT")}</p>
                                <br></br>
                                <a className="privacy-statement" id="privacy-statement" href={privacyLink} target="_blank">
                                    {t("LABEL_GMWALLET_WELCOME_PRIVACY_STATEMENT")}
                                    <br></br>
                                </a>
                            </div>
                        </div>
                        <div className="gmWallet-btn-container">
                            <div className="btn-width" id="gmWallet-addNew-btn">
                                <Button
                                    id="wallet-consent-modal-add-new-btn"
                                    className="stat-button-link"
                                    dataDtm="modal:new wallet"
                                    localeKey={t("LABEL_GMWALLET_ADD_NEW_BTN")}
                                    onClick={() => {
                                        props.dispatch({ type: "VIEW_ADD_NEW_PAY", boolean: true });
                                    }}
                                />
                            </div>
                            <div className="btn-width" id="find-existing-btn">
                                <Button
                                    id="wallet-consent-modal-find-existing-btn"
                                    className="stat-button-link"
                                    dataDtm="modal:new wallet"
                                    localeKey={t("LABEL_GMWALLET_FIND_EXISTING_BTN")}
                                    onClick={() => {
                                        props.dispatch({ type: "OPEN_FOUND_PAY_MODAL", boolean: true });
                                        props.dispatch({ type: "SHOW_WALLET_MODAL", boolean: false });
                                    }}
                                    isSecondary={true}
                                />
                            </div>
                        </div>
                    </div>
            </Modal>
        </div>
        )}
    </>
    );
};

export default WalletConsentModal;
