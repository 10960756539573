/*** COMPONENT NAME:BILLING FORM ***/

/*** IMPORT STATEMENT ***/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { normalizePostalCode } from '../../../../../../shared/Form/FormNormalize/FormNormalize';
import * as validate from '../../../../../../shared/Form/FormValidate/FormValidate';
import { connect } from "react-redux";
import { loadBillingAsShipping as loadFromShipping } from '../../../../CheckoutRedux/CheckoutActionCreator'
import { Translation } from "react-i18next";
import AppSettings from '../../../../../../core/AppSettings';

/*** RENDER FIELD ***/
const renderField = ({ input, label, type, required, disabled, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? 'gmit-gf-text-field error' : 'gmit-gf-text-field'}>
            {required === true && <label className="gmit-gf-label-text required">{label}</label>}
            {required !== true && <label className="gmit-gf-label-text">{label}</label>}
            <input {...input} placeholder={label} type={type} disabled={disabled} className="stat-input-field"
                data-dtm="checkout step3" data-dtm2="exclude" {...otherProps} />
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />
                {error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
);

/*** RENDER SELECT ***/
const renderSelect = ({ input, label, required, disabled, meta: { touched, error, warning }, children }) => (
    <div className={touched && error ? 'gmit-gf-dropdown error' : 'gmit-gf-dropdown'}>
        {required === true && <label className="gmit-gf-label-text required">{label}</label>}
        {required !== true && <label className="gmit-gf-label-text">{label}</label>}
        <div>
            <select {...input} disabled={disabled} className="q-dropdown q-form-dropdown stat-dropdown" id={label.replace(/ /g, '-') + "-dropdown"} data-dtm="checkout step3:dropdown state" data-dtm2="exclude">
                {children}
            </select>
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />{error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
);

let BillingForm = props => {

    const { handleSubmit,submitFailed, triggerSubmit,
        handleBillingSameAsShippingCheckbox } = props;
    const states = props.stateData.stateList;
    const isFormDisabled = props.enableBillingAsShipping;
    const myData = props.cartResponse.parsedResponse;
    const isDealer = AppSettings.isT3;

    //scroll to location on submit error
    useEffect(() => {
        const errorFields = document.getElementsByClassName('error ac-error-label');
        if (submitFailed && errorFields.length) {
            //just grab the first one if there's multiple
            const location = errorFields[0].getBoundingClientRect().top;

            /*calculate position to scroll to based on a) error element location b) current scroll location and 
                extra 400 padding to put the element in the middle of the screen*/
            window.scrollTo(0, location + window.pageYOffset - 400);
        }
    }, [triggerSubmit]);

    return (
        <Translation>
            {
                (t) => (
                    <form className="ac-payment-form" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 columns">
                                <span className="q-headline2 ac-checkout-wizard-header-title">{t("LABEL_RECEIPT_BILLING_INFO")}</span>
                                {
                                    /*** HIDE ON DEALER SITE SINCE WE DON'T HAVE SHIPPING INFORMATION ON STEP 1 ***/
                                    !isDealer && <div className="gmit-gf-field-group-container">
                                        <label className=" grid-column-alignment-left gmit-gf-label-text stat-checkbox" data-dtm="checkout step3:checkbox:same as shopping">
                                            <input type="checkbox"
                                                   onChange={
                                                       () => handleBillingSameAsShippingCheckbox(myData)
                                                   }
                                                   checked={isFormDisabled} />
                                            <span> {t("LABEL_CHECKOUT_SAME_AS_SHIPPING")}</span>
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="gmit-gf-groupbox-content">
                            <div className="row">
                                <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                                    <Field
                                        name="firstName"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_COMMON_FIRST_NAME")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                                    <Field
                                        name="lastName"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_COMMON_LAST_NAME")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                    <Field
                                        name="companyName"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_COMMON_COMPANY_NAME")}
                                        disabled={isFormDisabled}
                                        validate={[validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                    <Field
                                        name="address1"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_CHECKOUT_ADDRESS_LINE1")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                    <Field
                                        name="address2"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_CHECKOUT_ADDRESS_LINE2")}
                                        disabled={isFormDisabled}
                                        validate={[validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                    <Field
                                        name="city"
                                        type="text"
                                        component={renderField}
                                        label={t("LABEL_COMMON_CITY")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.minLength2, validate.maxLength64, validate.required]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                                    <Field
                                        name="state"
                                        component={renderSelect}
                                        label={t("LABEL_COMMON_STATE")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.required]}>
                                        <option value="" disabled>{t("LABEL_COMMON_STATE")}</option>
                                        {states.map((item, index) => <option key={index} value={item}>{item}</option>

                                        )}
                                    </Field>
                                </div>
                                <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                                    <Field
                                        name="zipCode"
                                        component={renderField}
                                        type="text"
                                        label={t("LABEL_COMMON_ZIP")}
                                        required={true}
                                        disabled={isFormDisabled}
                                        validate={[validate.required, validate.postalCode]}
                                        normalize={normalizePostalCode}
                                    />
                                </div>
                            </div>
                        </div>
                    </form >
                )
            }
        </Translation>
    )
}

BillingForm = reduxForm({
    form: 'BillingForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    enableReinitialize: true
})(BillingForm);



/*** CONNECT BILLING FORM COMPONENT WITH REDUX ***/
BillingForm = connect(
    state => ({
        initialValues: state.CheckoutReducer.paymentPage.billingInformation
    }),
    { loadBillingAsShipping: loadFromShipping }
)(BillingForm)

export default BillingForm