import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import * as action from '../Breadcrumb/BreadcrumbRedux/BreadcrumbActionCreator';
import { CATEGORY_SELECTED, LOAD_CATEGORY_NAV_DATA_ASYNC } from '../../modules/Search/SearchNav/CategoryNav/CategoryNavRedux/CategoryNavActionTypes';
import { LIST_DATA_ASYNC } from '../../modules/Search/SearchService/SearchDataServiceActionTypes';
import propTypes from 'prop-types';
import * as utils from '../Utils/Utils';
import AppSettings from '../../core/AppSettings';
import { PASS_ALL_PRODUCTS_BREADCRUMB_FLAG } from './BreadcrumbRedux/BreadcrumbActionType';


// this breadcrumb relies on page data
// see bottom of file to find available props (Breadcrumb.propTypes)
// dev: NikiL

class Breadcrumb extends Component {

    createBreadcrumb() {
        // category/subcategory info
        const { allCategoriesData,
            allCategoriesId,
            allCategoriesLabel,
            searchTerm,
            selectedCategoryData,
            selectedCategory,
            selectedSubcategoryData,
            selectedCategoryId,
            selectedSubcategory,
            selectedSubcategoryId } = this.props;

        // search params
        let year = '';
        let make = '';
        let model = '';
        let vin = '';

        // breadcrumb links
        let selectedCategoryLink = utils.getSessionParams() + "&categoryId=";
        let homeUrl = AppSettings.homeUrl;
        let searchTermLink = '';

        if (searchTerm) {
            searchTermLink = utils.getSessionParams() + "&searchTerm=" + searchTerm;
            if (queryString.parse(window.location.search).categoryId) {
                searchTermLink += "&categoryId=" + queryString.parse(window.location.search).categoryId;
            }
        }

        return (
            <Translation>
                {(t) => (
                    <div className="q-text q-body1 ac-breadcrumb-wrapper">
                        <ul className="stat-text-link" data-dtm="subheader breadcrumb">
                            {/************* TAKE ME HOME **************/}
                            <li>
                                <Link to={`${homeUrl}`}>
                                    <span className="ac-crumb ac-accessible-link"> {t('LABEL_BREADCRUMB_HOME')}</span>
                                </Link>
                            </li>
                            {/************* ALL PRODUCTS *************/}
                            <li>
                                <FontAwesomeIcon icon="angle-right" />
                                <Link
                                    to={{
                                        pathname: utils.getSearchBaseUrl(),
                                        search: selectedCategoryLink + allCategoriesId
                                    }}>
                                    <span onClick={() =>{
                                        this.props.handlePassBreadcrumbFlag(true);
                                        this.props.selectCategory(year, make, model, vin, allCategoriesId, allCategoriesData, this.props.searchData.searchParams, this.props.categoryNavData);
                                    }} className="ac-crumb ac-accessible-link">{t('LABEL_BREADCRUMB_ALL_PARTS')}</span>
                                </Link>
                            </li>
                            {/************** SEARCH RESULTS *************/}
                            {searchTerm &&
                                <li>
                                    <FontAwesomeIcon icon="angle-right" />
                                    <Link
                                        to={{
                                            pathname: utils.getSearchBaseUrl(),
                                            search: searchTermLink
                                        }}>
                                        <span className="ac-crumb ac-accessible-link">{t('LABEL_BREADCRUMB_SEARCH_RESULTS', { searchTerm })}</span>
                                    </Link>
                                </li>
                            }
                            {/************** CATEGORY *************/}
                            {(!searchTerm && selectedCategory && selectedCategory !== allCategoriesLabel.toLowerCase()) &&
                                <li>
                                    <FontAwesomeIcon icon="angle-right" />
                                    <Link
                                        to={{
                                            pathname: utils.getSearchBaseUrl(),
                                            search: selectedCategoryLink + selectedCategoryId
                                        }}>
                                        <span onClick={() => this.props.selectCategory(year, make, model, vin, selectedCategoryId, selectedCategoryData, this.props.searchData.searchParams, this.props.categoryNavData)} className="ac-crumb ac-accessible-link">{selectedCategory}</span>
                                    </Link>
                                </li>
                            }
                            {/************* SUBCATEGORY *************/}
                            {(!searchTerm && selectedSubcategory && selectedCategory !== allCategoriesLabel.toLowerCase() && selectedCategory) &&
                                <li>
                                    <FontAwesomeIcon icon="angle-right" />
                                    <Link
                                        to={{
                                            pathname: utils.getSearchBaseUrl(),
                                            search: selectedCategoryLink + selectedSubcategoryId
                                        }}>
                                        <span onClick={() => this.props.selectCategory(year, make, model, vin, selectedSubcategoryId, selectedSubcategoryData, this.props.searchData.searchParams)} className="ac-crumb ac-accessible-link">{selectedSubcategory}</span>
                                    </Link>
                                </li>
                            }
                            {/************* PAGE NAME *************/}
                            {this.props.pageName &&
                                <li>
                                    <FontAwesomeIcon icon="angle-right" />
                                    <a className="ac-crumb ac-static-link">{this.props.pageName}</a>
                                </li>
                            }
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }

    componentWillUnmount() {
        this.props.handleCategoryChange('', '');
    }

    render() {
        return (
            <div>
                {this.createBreadcrumb()}
            </div>
        )
    }

}

/**************************************************************/
//          Map State to Props
/**************************************************************/
function mapStateToProps(state) {
    // State comes from store
    // Return value goes to props
    return {
        searchData: state.SearchServiceReducer,
        categoryNavData: state.CategoryNavReducer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectCategory: (year, make, model, vin, categoryId, category, searchParams, categoryData) => {
            dispatch({
                type: CATEGORY_SELECTED,
                payload: { selectedCategory: category }
            })
            // This prevents duplicate calls from being made. One for the nav data, and one for the search list (below this)
            // If we don't dispatch this when catData does not exist, then the category accordion will not render due to no data.
            // Only make the call if nava data does not exist -- it would not exist if we used breadcrumbs from PDP.
            if (!categoryData?.parsedCategories || location.pathname != "/search") {
                dispatch({
                    type: LOAD_CATEGORY_NAV_DATA_ASYNC,
                    payload: {
                      year: year,
                      make: make,
                      model: model,
                      vin: vin,
                      categoryId: categoryId
                    }
                })
            }
            dispatch({
                type: LIST_DATA_ASYNC,
                payload: {
                    year: year,
                    make: make,
                    model: model,
                    vin: vin,
                    categoryId: categoryId,
                    orderBy: searchParams.orderBy
                }
            })
            dispatch({
                type: PASS_ALL_PRODUCTS_BREADCRUMB_FLAG,
                isAllProductsBreadcrumbClicked: false
            })
        },
        handleSearchParamsChange: params => {
            dispatch(action.setSearchParams(params));
        },
        handleAllCategoriesChange: (name, id) => {
            dispatch(action.setAllCategories(name, id));
        },
        handleCategoryChange: (name, id) => {
            dispatch(action.setCategory(name, id));
        },
        handleSubCategoryChange: (name, id) => {
            dispatch(action.setSubCategory(name, id));
        },
        handlePageNameChange: (name) => {
            dispatch(action.setPageName(name));
        },
        handlePassBreadcrumbFlag: (flag) => {
            dispatch(action.setAllProductsBreadcrumbFlag(flag));
        }
    }
}

Breadcrumb.propTypes = {
    year: propTypes.number,
    make: propTypes.string,
    model: propTypes.string,
    allCategoriesId: propTypes.string,
    allCategoriesLabel: propTypes.string,
    selectedCategory: propTypes.string,
    selectedCategoryId: propTypes.string,
    selectedSubcategory: propTypes.string,
    selectedSubcategoryId: propTypes.string,
    pageName: propTypes.string,
    rerenderBreadcrumb: propTypes.bool // Not explicitly used. For forced breadcrumb rerender on PDP after fitment reset
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
