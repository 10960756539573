import React, { useState } from 'react';

import '../PasswordReset.scss';
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import Button from '../../../shared/Button/Button';
import SuccessMessage from '../../../shared/SuccessMessage/SuccessMessage';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import FormInstructions from '../../../shared/Form/FormInstructions';
import AppSettings from '../../../core/AppSettings';
import Spinner from '../../../shared/Spinner/Spinner';
import { Translation, Trans } from 'react-i18next';
import { constructAzureLoginURL } from '../../../shared/Authentication/AuthenticationWorker';
import { pageTitle } from "../../../shared/Hooks/Hooks";
import { sendEmail } from '../shared/IAMRequests';
import { Redirect } from 'react-router-dom';
import vaultConstants from '../../../../config/vault_constants';

function EmailConfirmation(props) {
    pageTitle("Email Confirmation");

    // PAGE STATUS
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

    let email = props.location.state ? props.location.state.email : ""; 
    // The user will be redirected to this page if they try to go directly to /forgotUsernamePassword/emailConfirmation. 
    // They will also see an error which can be triggered with the noEmailError variable.
    if(email == "" || email == null) {
        return <Redirect to={{ pathname: '/forgotUsernamePassword', state: { noEmailError: true } }} />
    }

    // These variables will be used to handle the re-direct to log-in page.
    const applicationState = {'redirectPath' : window.location.href };
    const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));
    const userFlow = AppSettings.azureLoginUserFlow;
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    const currentSite = AppSettings.currentSite.key;

    const handleEmailRequest = (email) => {
        // Reset in case the 'Resend verification email' button is pressed again while the message is active
        setError(false);
        setSuccess(false);

        // Activate spinner
        setLoading(true);

        sendEmail(email).then(result => {
            if (result.data.status === "success") {
                setSuccess(true);
                setLoading(false);
            } else {
                handleErrorMessage(request.messageKey, request.messageDescription); 
            }
        }).catch((error) => {
            handleErrorMessage(error, "Unable to send email"); // TODO: Don't hard code this
        });
    }

    function handleErrorMessage(error, errorMessage) {
        // Display error message
        setError(true);

        // Hide spinner
        setLoading(false); 
        if (String(vaultConstants.API_TARGETABLE).includes("dev")) {
            console.log(error, errorMessage);
        }
        
    }

    return (
        <Translation> 
            {
                (t) => (

                    <CenterGrid headerLabel={t("LABEL_PASSWORD_RESET_HEADER")}>
                        <div className="ac-grid-email-confirmation">
                            {loading && 
                                <div className="ac-email-confirmation-spinner-overlay">
                                    <Spinner className="q-loader" />
                                </div> 
                            }
                            
                            {error && <ErrorMessage icon={true} message={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_ERROR_UNABLE_TO_SEND")} /> }
                            {success && <SuccessMessage icon={true} showSuccess={success} message={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUCCESSFULLY_SENT")} />}
                            
                            <FormInstructions instructions={[
                                { text: "LABEL_PASSWORD_RESET_INSTRUCTIONS" },
                                { text: 'LABEL_PASSWORD_RESET_USER_EMAIL', name: email, bold: true },
                                { text: "LABEL_PASSWORD_RESET_INSTRUCTIONS2" }
                            ]}/>
        
                            <Button type="submit"
                                    disabled={false}
                                    localeKey={"LABEL_BUTTON_OK"}
                                    onClick={() => handleUserLogin(userFlow, clientId, applicationStateString, currentSite)}
                                    isLoading={false}
                            />
                            
                            <div className="text-center">
                                <a className="ac-accessible-link-underline" onClick={() => handleEmailRequest(email)} >
                                    {t("LABEL_ACCOUNT_REGISTRATION_RESEND_EMAIL_BUTTON_LABEL")}
                                </a>
                            </div>

                            <br/>
                            <br/>

                            <div className="text-center">
                                <Trans i18nKey="LABEL_PASSWORD_RESET_PHONECALL">
                                    {/* Not hardcoded text - this is done for interpoliation */}
                                    IF YOU NEED HELP
                                    <a className="stat-text-link" data-dtm="email verification" href={"tel:" + phoneNumber}>{{phoneNumber}}</a>
                                </Trans>
                            </div>
                        </div>
                    </CenterGrid>
                )
            }
        </Translation>
    )
};

const handleUserLogin = (userFlow, clientId, applicationStateString, currentSite) => {
    window.location.href = constructAzureLoginURL(userFlow,clientId,applicationStateString,'/authenticate', currentSite);
}

export default EmailConfirmation;