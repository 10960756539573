/** COMPONENT NAME : SHOPPINGCARTLINEITEM(PARENT OF SUB COMPONENT ON LINE ITEM) **/
import React, { useState, useEffect } from 'react';
import ImageAndDescription from "../ImageAndDescription/ImageAndDescription";
import MsrpComponent from "../MSRP/MsrpComponent";
import QtyCounter from "../QtyCounter/QtyCounter";
import MediaQuery from 'react-responsive';
import CartDelivery from '../DeliveryMethod/CartDelivery/CartDelivery';
import AppSettings from '../../../core/AppSettings';
import { useDispatch, useSelector } from "react-redux";
import SharedSpinner from "../../../shared/Spinner/Spinner";

const ProductLineItem = (props) => {

    /** VARIABLES **/
    const mainItem = props.mainItem;
    const item = props.data;
    let installItem; // if the delivery method is INSTALL then we will get a second "item" that has install charges
    const orderItemId = item.orderItemId;
    if (mainItem?.items?.length > 1) { //Install will always generate two objects, this lets us know the item is install
        mainItem.items.map((item, subIndex) => {
            if (item.orderItemType === "Installed") {
                installItem = mainItem.items[subIndex];
            }
        });
    }

    let deliveryInfo;
    installItem ? deliveryInfo = {
        deliveryMethod: installItem.shipModeDescription,
        deliveryPrice: installItem.unitPrice,
        deliveryId: installItem.shipModeId,
        carrier: "INSTALL"
    } :
    deliveryInfo = {
        deliveryMethod: item.shipModeDescription,
        deliveryPrice: item.shippingCharge,
        deliveryId: item.shipModeId,
        carrier: item.carrier 
    };
    const vehicleInfo = props.vehicleInformation;
    const vinNum = vehicleInfo.vin;
    const currentComponent = props.component;
    const qty = parseInt(item.quantity);
    const items = props.items;
    const [showItemSpinner, setShowItemSpinner] = useState(false); //TODO: Need to look into this func
    const orderInfo = useSelector((state) => state.OrderInfoReducer);
    const showMiniCartSpinner = orderInfo?.showMiniCartRemoveSpinner;
    const dispatch = useDispatch();
    const dealer = orderInfo?.dealer;
    /** CREATING OBJECT OF DATA THAT WILL BE REQUIRED ON CARTIMAGEANDDESCRIPTION COMPONENT **/
    // availableDeliveryMethods not required (on order confirmation page)
    let availableDeliveryMethods = {}
    if (item.availableDeliveryMethods) {
        availableDeliveryMethods = item.availableDeliveryMethods[0];
    }
    const imageDescProps = {
        seoUrl: item.seoUrl || '',
        partNumber: item.partNumber,
        partName: item.partName,
        availableDeliveryMethods: availableDeliveryMethods,
        imageName: item.imageName,
        productType: item.productType || '',
        productId: item.productId || ''
    };

    useEffect(() => {
        setShowItemSpinner(false);
    }, [orderInfo]);

    if (currentComponent === "cartWrapper") {
        return <div className="ac-shopcartLineItemWrapper">
                <div className="col-xs-12 small-12 medium-12 large-8 xlarge-8 columns ">
                    <ImageAndDescription 
                        data={imageDescProps} 
                        vehicleInfo={vehicleInfo} 
                        vin={vinNum} 
                        component={currentComponent} 
                        orderInfo={orderInfo} 
                        orderItemId={orderItemId} 
                        value={qty} 
                        dealerName={dealer} 
                        deliveryInfo={deliveryInfo}
                        dispatch={dispatch}
                        setShowItemSpinner={setShowItemSpinner}
                        showItemSpinner={showItemSpinner}
                        installItem={installItem}/>
                </div>
                <div className="col-xs-12 small-12 medium-12 large-2 xlarge-2 columns">
                    <MediaQuery query="(min-width:26.25em)">
                        <MsrpComponent price={item.unitPrice} />
                    </MediaQuery>
                </div>
                <div className="col-xs-12 small-12 medium-12 large-2 xlarge-2 columns ac-MobileQty">
                    {/*** Using Media Query to display MSRP on different line based on the devices ***/}
                    <MediaQuery query="(min-width:26.25em)">
                        <QtyCounter setShowItemSpinner={setShowItemSpinner} dispatch={dispatch} orderItemId={item.orderItemId} laborOrderItemId={installItem?.orderItemId} value={item.quantity} partName={item.partName} items={items}/>
                    </MediaQuery>
                    <MediaQuery query="(max-width:26.24em)">
                        <div className="col-xs-6 small-6 medium-3 large-2 xlarge-2 columns">
                            <QtyCounter setShowItemSpinner={setShowItemSpinner} dispatch={dispatch} orderItemId={item.orderItemId} laborOrderItemId={installItem?.orderItemId} value={item.quantity} partName={item.partName} items={items}/>
                        </div>
                        <div className="col-xs-6 small-6 medium-3 large-2 xlarge-2 columns">
                            <MsrpComponent price={item.unitPrice} />
                        </div>
                    </MediaQuery>

                </div>
                {/** DISPLAY DELIVERY METHOD UNDER THE QTY AND MSRP ON SMALL DEVICES **/}
                <MediaQuery query="(max-width: 26.25em)">
                    <div className="small-12 columns ac-deliveryMobile">
                    {showItemSpinner ? (
                        <SharedSpinner size="sm" />
                        ) : 
                        <CartDelivery
                            currency={AppSettings.currency}
                            orderInfo={orderInfo}
                            orderItemId={item.orderItemId} 
                            quantity={qty}
                            dealerName={dealer}
                            deliveryInfo={deliveryInfo}
                            dispatch={dispatch}
                            setShowItemSpinner={setShowItemSpinner}
                            partNumber={item.partNumber} 
                            productId={item.productId}
                            installItem={installItem}
                        />
                    }
                    </div>
                </MediaQuery>
            </div>
    }

    else if (currentComponent === "summaryRail") {
        return <div className="ac-shopcartLineItemWrapper">
            <div className="col-xs-12 small-12 medium-12 large-12 xlarge-12 columns ">
                <ImageAndDescription data={imageDescProps} vehicleInfo={vehicleInfo} vin={vinNum} component={currentComponent} price={item.unitPrice} orderItemId={item.orderItemId} value={qty}/>
            </div>
        </div>
    }

    else {
        return null;
    }
};

export default ProductLineItem