import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollerToTop } from "../Utils/Utils";


class Spinner extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        // scroll to top of page when spinner 
        // displays if scrollToTop set to true
        if (this.props.scrollToTop) {
            window.scrollTo(0, 0);
            scrollerToTop('root');
        }
    }
    render() {
        return (
            // isLoadingBtnEnabled displays the spinner 
            // in white as a mini version. Used inside buttons
            <div className={this.props.isLoadingBtnEnabled ? 'q-loader ac-loader-btn-disabled' : 'q-loader'}>
            </div>
        )
    }
};


Spinner.propTypes = {
    scrollToTop: PropTypes.bool
}

export default Spinner