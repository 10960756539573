import React from "react";
import PropTypes from "prop-types";
import AppSettings from "../../core/AppSettings";
import CartButtons from "../../shared/CartComponents/CartButton/CartButtons";
import CartCoupons from "../../shared/CartComponents/CartCoupons/CartCoupons.js";
import store from "../../core/Redux/Store";
import { couponCodeRemovePostAsync } from "../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import SummaryRailContainer from "../SummaryRail/SummaryRailContainer";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import RewardsWrapper from "../SummaryRail/RewardsWrapper/RewardsWrapper";
import * as vaultConstants from "../../../config/vault_constants";


export const getCartOrderSummaryFFStatus = () =>
    AppSettings.isLocalHost ? false : String(vaultConstants.FF_2097839_RIGHT_ORDER_SUMMARY) === "true";

/**
 * This component is a container for displaying the items in the cart, the subtotal, and
 * bottom buttons for checkout and continue shopping.
 *
 * @param props
 * orderInfo: Object, Required. orderInfo object from getCartData response,
 * vehicleData: Object, Required. vehicles array from getCartData response,
 * shippingData: Object, Required. Shipping rates data from getShippingRates response,
 * styles: Object, Optional.  Object with brand specific classnames for components on cart page,
 * dispatch: Object, Required. Dispatch function for cart reducer,
 * continueShoppingUrl: Url for Product Search Results page of the last vehicle in the cart
 *
 *
 * @returns {JSX.Element}
 */
const CartOrderSummary = (props) => {
  const handleCouponCodeRemove = (couponCode) => {
    store.dispatch(couponCodeRemovePostAsync(couponCode));
  };
  const { t } = useTranslation();

  return (
      <div id="cartOrderSummary" className="prt-shoppingCartWrapper">
        <div className="cart-order-summary">
          {/* Coupon and Reward code will get passed here */}
          <CartCoupons orderId={props.orderInCart.orderInfo.orderId} />
          {/*{ (*/}
          {/*    <RewardsWrapper*/}
          {/*        adjustedTotal={props.parsedResponse.orderInfo.adjustedTotal}*/}
          {/*        appliedAdjustments={props.parsedResponse.orderInfo.adjustment}*/}
          {/*        appliedPoints={"444"}*/}
          {/*        handleApplyRewardsPoints={props.handleApplyRewardsPoints}*/}
          {/*        isDealerParticipating={props.parsedResponse.dealer.rewardsProgram}*/}
          {/*    />*/}
          {/*)}*/}
          {/*Data to pass tax, core charge, discounts etc. are passed through here */}
          <SummaryRailContainer
              data={props.parsedResponse}
              handleCouponCodeRemove={handleCouponCodeRemove}
              deliveryErrorTax={false}
              pointsAutoRemoved={false}
              voucherAutoRemoved={false}
              voucherAmountWarning={false}
              showLineItems={false}
              RIGHT_ORDER_SUMMARY_FF={props.RIGHT_ORDER_SUMMARY_FF}
          />
          <div className="cart-order-summary-button-wrapper">
            {/*SHOPPING CART BUTTONS*/}
            <CartButtons
                orderNumber={props.orderInCart.orderInfo.orderId}
                orderInCart={props.orderInCart}
                url={props.continueShoppingUrl}
            />
          </div>
        </div>
      </div>
  );
};

/** PROP TYPES VALIDATION **/
CartOrderSummary.proptype = {
  orderInfo: PropTypes.object.isRequired,
  vehicleData: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
  styles: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  continueShoppingUrl: PropTypes.string.isRequired
};
const mapStateToProps = (state) => {
  return {
      authData: state.Authentication
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
      handleApplyRewardsPoints: (rewardsPointsToRemove, rewardsPointsToApply) => {
          store.dispatch(
              updateRewardsPoints(props.parsedResponse.orderInfo.orderId, rewardsPointsToRemove, rewardsPointsToApply)
          );
      }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartOrderSummary);
