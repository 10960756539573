//---------------- REWARDS GET ACCOUNT INFORMATION ACTION TYPES --------------------------------------------
export const REWARDS_SET_ACCOUNT_INFORMATION = 'REWARDS_SET_ACCOUNT_INFORMATION';
export const REWARDS_SET_ACCOUNT_INFORMATION_FAILED = 'REWARDS_SET_ACCOUNT_INFORMATION_FAILED';
export const REWARDS_GET_MEMBER_ACCOUNT_INFO = 'REWARDS_GET_MEMBER_ACCOUNT_INFO';

//---------------- REWARDS GET REDEMPTION INFORMATION ACTION TYPES --------------------------------------------
export const REWARDS_SET_MEMBER_REDEMPTION_INFORMATION = 'REWARDS_SET_MEMBER_REDEMPTION_INFORMATION';
export const REWARDS_SET_MEMBER_REDEMPTION_INFORMATION_FAILED = 'REWARDS_SET_MEMBER_REDEMPTION_INFORMATION_FAILED';
export const REWARDS_GET_MEMBER_REDEMPTION_INFO = 'REWARDS_GET_MEMBER_REDEMPTION_INFO';

//---------------- REWARDS EARN API ACTION TYPES --------------------------------------------
export const REWARDS_SET_POTENTIAL_POINTS_INFORMATION = 'REWARDS_SET_POTENTIAL_POINTS_INFORMATION';
export const REWARDS_SET_POTENTIAL_POINTS_INFORMATION_FAILED = 'REWARDS_SET_POTENTIAL_POINTS_INFORMATION_FAILED';
export const REWARDS_GET_POTENTIAL_POINTS_INFO = 'REWARDS_GET_POTENTIAL_POINTS_INFO';
export const LOYALTY_GET_PD_POTENTIAL_POINTS = 'LOYALTY_GET_PD_POTENTIAL_POINTS';

//******************HEADER REWARDS POINTS******************\\
export const REWARDS_GET_HEADER_REWARDS_POINTS = 'REWARDS_GET_HEADER_REWARDS_POINTS';
export const REWARDS_SET_HEADER_REWARDS_POINTS = 'REWARDS_SET_HEADER_REWARDS_POINTS';
export const REWARDS_SET_ENROLLMENT_STATUS_FETCHED = 'REWARDS_SET_ENROLLMENT_STATUS_FETCHED';
export const REWARDS_SET_HEADER_REWARDS_POINTS_FAILED = 'REWARDS_SET_HEADER_REWARDS_POINTS_FAILED';
export const REWARDS_CLEAR_HEADER_REWARDS_POINTS = "REWARDS_CLEAR_HEADER_REWARDS_POINTS";
export const REWARDS_CLEAR_ACCOUNT_INFORMATION = "REWARDS_CLEAR_ACCOUNT_INFORMATION";

//******************CART POTENTIAL POINTS******************\\

export const REWARDS_GET_CART_POTENTIAL_POINTS = 'REWARDS_GET_CART_POTENTIAL_POINTS'
export const REWARDS_SET_CART_POTENTIAL_POINTS = 'REWARDS_SET_CART_POTENTIAL_POINTS'
export const REWARDS_SET_CART_POTENTIAL_POINTS_FAILED = 'REWARDS_SET_CART_POTENTIAL_POINTS_FAILED'
export const REWARDS_UPDATE_ITEM_POTENTIAL_POINTS = 'REWARDS_UPDATE_ITEM_POTENTIAL_POINTS'
export const REWARDS_REMOVE_ITEM_POTENTIAL_POINTS = 'REWARDS_REMOVE_ITEM_POTENTIAL_POINTS'

//******************CHECKOUT STEP 2******************\\
export const UPDATE_REWARDS_POINTS = 'UPDATE_REWARDS_POINTS';
export const SET_REDEEM_REWARDS_FAILURE = 'SET_REDEEM_REWARDS_FAILURE';
export const REWARDS_DISABLE_USER_INPUT = 'REWARDS_DISABLE_USER_INPUT';
