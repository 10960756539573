import React, { useEffect, useState, useRef } from "react";
import AddNewPayment from "./AddNewPayment/AddNewPayment";
import ViewWallet from "./ViewWallet/ViewWallet";
import SharedButton from "../Button/Button";
import { useTranslation } from "react-i18next";
import { getPaymentMethods } from "./GMWalletService";
import WalletConsentModal from "./WalletModals/WalletConsentModal";
import FoundPaymentModal from "./WalletModals/FoundPaymentModal";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Spinner from "../Spinner/Spinner";
import store from "../../core/Redux/Store";
import {
    resetPaymentInformation,
    updateCreditCardSuccessInformation
} from "../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import { useSelector } from "react-redux";
import {addDTMTag, isExternalWalletType} from "./GMWalletHelper";

/**
 * GM wallet for --> Authenticated users only
 * <GMWallet state={state} dispatch={dispatch} isCheckout isAuthUser={true} params={{totalAmount: 0, currency: "USD", userId: authData.userData.logonId, orderNo: props.cartResponse.parsedResponse.orderId}}/>
 *
 * GM wallet for --> Guest users in Checkout
 * <GMWallet state={state} dispatch={dispatch} isCheckout isAuthUser={false} params={{totalAmount: 0, currency: "USD", userId: props.cartResponse.parsedResponse.orderId, orderNo: props.cartResponse.parsedResponse.orderId}}/>
 *
 * Props:
 * state, dispatch: in the parent component we are calling and setting the initial state of the GMWalletReducer and passing it down
 * isCheckout: is set for whenever the component is used in checkout flow. Set this to false for when used only as an authenticated wallet experience.
 * isAuthUser: is set to determine what type of user is viewing the component. User experience is different for Auth users and  Guest users.
 *
 *
 * */

const GMWallet = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = props;
    const isComponentAlive = useRef(true);
    const isAlive = () => isComponentAlive.current;
    const [reloadPaymentsCheck, setReloadPaymentsCheck] = useState(false);
    const checkoutReducerIsOneTimePayment = useSelector(
        (state) => state.CheckoutReducer.paymentPage.paymentInformation.isOneTimePayment
    );
    const oneTimePayUserId = "EST-" + String(props?.params?.orderNo);

    // when component unmounts, set ref to false -
    // use ref to avoid memory leaks around async code.
    useEffect(() => () => (isComponentAlive.current = false), []);

    useEffect(() => {
        if (state.isOneTimePayment)
            dispatch({
                type: "SET_PARAMS",
                params: {
                    totalAmount: props.params.totalAmount,
                    currency: props.params.currency,
                    userId: oneTimePayUserId,
                    orderNo: props.params?.orderNo?.toString()
                }
            });
        else
            dispatch({
                type: "SET_PARAMS",
                params: {
                    totalAmount: props.params.totalAmount,
                    currency: props.params.currency,
                    userId: props.params.userId,
                    orderNo: props.params?.orderNo?.toString()
                }
            });
    }, [state.isOneTimePayment]);

    //This useEffect helps to reduce the amount of getPaymentMethod api calls for when completing edit, add, or removal of cards
    //will only make the getPaymentmethod api call for successful returns and not for resetting the states
    useEffect(() => {
        if (
            state.editPaymentSuccess === true ||
            state.addPaymentSuccess === true ||
            state.removedCardSuccess === true ||
            state.addFoundCardToWalletSuccess === true
        ) {
            setReloadPaymentsCheck(!reloadPaymentsCheck);
        }
    }, [
        state.addPaymentSuccess,
        state.editPaymentSuccess,
        state.removedCardSuccess,
        state.addFoundCardToWalletSuccess
    ]);

    const handlePaymentsResponse = (response, userId) => {
        //getPaymentMethod Error
        if (response === undefined || response === "error") {
            dispatch({ type: "ERROR_RETRIEVING_PAYMENTS" });
        } else {
            dispatch({ type: "SET_PAY_METHOD_RESPONSE", response: response });

            if (response.storedPaymentMethods) {
                dispatch({ type: "VIEW_WALLET" });
                for (let i = 0; i < response?.storedPaymentMethods.length; i++) {
                    //checks to see if user has previously added a card in the new GM Wallet, the walletTYpe of "ESTORE_WALLET" means that they have a card saved in GM Wallet
                    //if they have one that does not equal ESTORE_WALLET, then we want to show the Look up Pay Method button so they can import other cards found
                    if (isExternalWalletType(response?.storedPaymentMethods[i].walletType)) {
                        dispatch({ type: "SHOW_LOOK_UP_BTN" });
                    } else {
                        dispatch({ type: "USER_HAS_GM_WALLET" });
                    }
                }

                // if gmCard is in wallet
                dispatch({ type: "SET_HAS_GM_CARD", hasGMCard: response?.storedPaymentMethods[0]?.gmCard === true });

                if (state.addPaymentAttempt && state.addPaymentSuccess) {
                    if (
                        state.isAuthUser &&
                        state.numOfCards &&
                        state.numOfCards === response?.storedPaymentMethods.length
                    ) {
                        //successful add payment for duplicate card in wallet, show edit success message instead
                        props.dispatch({ type: "NO_MESSAGES" });
                        props.dispatch({ type: "EDIT_PAYMENT", success: true });
                    } else {
                        //successful add payment for new card to wallet
                        dispatch({ type: "SET_LAST_CARD_ADDED", lastCard: response.storedPaymentMethods[0] });
                        dispatch({ type: "SET_NUM_OF_CARDS", numOfCards: response.storedPaymentMethods.length });
                    }
                } else {
                    //set num of cards to wallet length when no payment has been added
                    dispatch({ type: "SET_NUM_OF_CARDS", numOfCards: response.storedPaymentMethods.length });
                }
                //Guest use case in checkout
                //This sets the credit card information into the Checkout Reducer
                //isOneTimePayment use case as well as it reflects the Auth checkout logic
                if ((props.isCheckout && !props.isAuthUser) || state.isOneTimePayment) {
                    store.dispatch(
                        updateCreditCardSuccessInformation({
                            payment: response.storedPaymentMethods[0],
                            userId: userId,
                            isOneTimePayment: state.isOneTimePayment
                        })
                    );
                    dispatch({
                        type: "SET_PAYMENT_ID",
                        storedPaymentMethodId: response.storedPaymentMethods[0].id
                    });
                }
                //Auth use case in checkout
                //This sets the payment method that was just added to show user it has been selected to use in checkout
                if (state.addPaymentSuccess && props.isCheckout && props.isAuthUser) {
                    store.dispatch(
                        updateCreditCardSuccessInformation({
                            payment: response.storedPaymentMethods[0],
                            userId: userId,
                            isOneTimePayment: state.isOneTimePayment
                        })
                    );
                }
            } else {
                //sets number of cards in wallet to null when no stored payment methods return
                dispatch({ type: "SET_NUM_OF_CARDS", numOfCards: null });
                //when in checkout -> render the View Add New pay component if no payment methods were found from Guest User
                if (props.isCheckout && !props.isAuthUser) {
                    props.dispatch({ type: "VIEW_ADD_NEW_PAY", viewAddNewPayment: true, viewWallet: false });
                }
            }
        }
    };

    useEffect(() => {
        async function gmWalletFunction() {
            dispatch({ type: "SHOW_SPINNER", boolean: true });
            //Had to create a timeout to trigger this getPaymentMethods call due to a new implementation of Webhooks.
            // Webhooks has a slight delay that the EPG backend gets from Adyen. This small delay was causing newly added payments to not reflect unless a refresh of the page was made.
            setTimeout(
                async () => {
                    try {
                        if (!isAlive()) return;
                        if (state.isOneTimePayment) {
                            await getPaymentMethods({
                                totalAmount: props.params.totalAmount,
                                currency: props.params.currency,
                                userId: oneTimePayUserId,
                                orderNo: props.params?.orderNo?.toString()
                            }).then((response) => {
                                if (!response.storedPaymentMethods) {
                                    dispatch({ type: "VIEW_ADD_ONE_TIME_PAYMENT", boolean: true });
                                }
                                handlePaymentsResponse(response, oneTimePayUserId);
                            });
                        } else
                            checkoutReducerIsOneTimePayment && props.isCheckout
                                ? dispatch({ type: "SET_IS_ONE_TIME_PAYMENT_FLOW", boolean: true })
                                : await getPaymentMethods(props.params).then((response) => {
                                    handlePaymentsResponse(response, props.params.userId);
                                });
                    } catch (e) {
                        dispatch({ type: "ERROR_RETRIEVING_PAYMENTS" });
                    } finally {
                        dispatch({ type: "SHOW_SPINNER", boolean: false });
                    }
                },
                state.addPaymentSuccess || state.editPaymentSuccess ? 1000 : 0
            );
        }
        gmWalletFunction();
    }, [reloadPaymentsCheck, state.isOneTimePayment]);

    //GM Wallet tags
    useEffect(() => {
        if (!state.showSpinner) {
            //Do not set tags for on Add New Payment screen
            if (!state.viewAddNewPayment && !state.viewAddOneTimePayment) {
                if (props.isCheckout) {
                    addDTMTag("id", "wallet-add-new-pay-link", "checkout:payment information", "stat-text-link");
                    addDTMTag(
                        "className",
                        "wallet-edit-payment",
                        "checkout:payment information:method",
                        "stat-text-link",
                        true
                    );
                    addDTMTag(
                        "className",
                        "wallet-remove-payment",
                        "checkout:payment information:method",
                        "stat-text-link",
                        true
                    );
                } else {
                    addDTMTag("id", "wallet-add-new-pay-link", "wallet", "stat-text-link");
                    addDTMTag("className", "wallet-edit-payment", "wallet:payment method", "stat-text-link", true);
                    addDTMTag("className", "wallet-remove-payment", "wallet:payment method", "stat-text-link", true);
                }
            }
        }
    }, [state.showSpinner, state.viewAddNewPayment, state.viewAddOneTimePayment]);

    if (state.showSpinner) {
        return <Spinner />;
    } else {
        return (
            (!state.viewAddNewPayment && !state.viewAddOneTimePayment && (
                <>
                    <div className={props.isCheckout ? "wallet-page-container-checkout" : "wallet-page-container"}>
                        {/* Error message displays if there is an internal error */}
                        {state.showErrorMessage && <ErrorMessage icon message={"LABEL_GMWALLET_PAYMENT_ERROR"} />}
                        {state.addPaymentAttempt && !state.addPaymentSuccess && (
                            <ErrorMessage
                                icon
                                message={
                                    props.isCheckout ? "LABEL_GMWALLET_ADD_ERROR_CHECKOUT" : "LABEL_GMWALLET_ADD_ERROR"
                                }
                            />
                        )}
                        {/* success message, appears on wallet home page */}
                        {!state.showSpinner &&
                            state.addPaymentAttempt &&
                            state.addPaymentSuccess &&
                            !state.showErrorMessage && (
                                <SuccessMessage
                                    icon
                                    showSuccess={true}
                                    message={t(
                                        props.isCheckout
                                            ? "LABEL_GMWALLET_ADD_SUCCESS_CHECKOUT"
                                            : "LABEL_GMWALLET_ADD_SUCCESS",
                                        {
                                            cardName: state.lastCardAdded.cardName,
                                            last4: state.lastCardAdded.lastFour
                                        }
                                    )}
                                />
                            )}
                        {state.removedCardSuccess && (
                            <SuccessMessage
                                icon
                                showSuccess={true}
                                message={t("LABEL_GMWALLET_REMOVE_SUCCESS", {
                                    cardName: state.removedCardData.cardName,
                                    last4: state.removedCardData.lastFour
                                })}
                            />
                        )}
                        {state.editPaymentSuccess && (
                            <SuccessMessage icon showSuccess={true} message={t("LABEL_GMWALLET_EDIT_PAYMENT")} />
                        )}
                        {state.addFoundCardToWalletSuccess && (
                            <SuccessMessage icon showSuccess={true} message={t("LABEL_GMWALLET_PAYMENT_SAVED")} />
                        )}
                        {!state.hideConsentModal &&
                            !state.doesUserHaveGMWallet &&
                            JSON.stringify(state.payMethodsResponse) !== "{}" && (
                                <WalletConsentModal state={state} dispatch={dispatch} />
                            )}
                        {state.openFoundPayModal && <FoundPaymentModal state={state} dispatch={dispatch} />}
                        <div
                            className={
                                props.isCheckout
                                    ? "ac-checkout-wizard-header-title q-headline2 gb-headline3"
                                    : "wallet-header q-headline2"
                            }
                        >
                            {(props.isCheckout && !props.isAuthUser) || state.isOneTimePayment
                                ? t("LABEL_GMWALLET_HEADER_CHECKOUT_GUEST")
                                : t("LABEL_GMWALLET_HEADER")}
                        </div>
                        {state.viewWallet ? (
                            <div className={"wallet-subHeader"}>{t("LABEL_GMWALLET_MANAGE_PAYMENT_METHOD_USED")}</div>
                        ) : (
                            <div className={"wallet-subHeader"}>{t("LABEL_GMWALLET_NO_PAYMENT_METHODS")}</div>
                        )}
                        {props.isAuthUser && !state.isOneTimePayment && (
                            <div className="wallet-top-right-link">
                                <span
                                    id="wallet-add-new-pay-link"
                                    className="wallet-link"
                                    onClick={() => {
                                        dispatch({
                                            type: "VIEW_ADD_NEW_PAY",
                                            viewAddNewPayment: true,
                                            viewWallet: false
                                        });
                                        dispatch({ type: "NO_MESSAGES" });
                                    }}
                                >
                                    {t("LABEL_GMWALLET_ADD_PAYMENT")}
                                </span>
                            </div>
                        )}
                        {props.isAuthUser && state.isOneTimePayment && (
                            <div className="wallet-top-right-link">
                                <span
                                    className="wallet-link"
                                    onClick={() => {
                                        dispatch({ type: "SET_IS_ONE_TIME_PAYMENT_FLOW", boolean: false });
                                        dispatch({ type: "NO_MESSAGES" });
                                        store.dispatch(resetPaymentInformation());
                                    }}
                                >
                                    {t("LABEL_GMWALLET_GO_BACK_TO_WALLET")}
                                </span>
                            </div>
                        )}
                        <div className="display-adyen-list">
                            {state.viewWallet && state.doesUserHaveGMWallet ? (
                                <ViewWallet state={state} dispatch={dispatch} isCheckout={props.isCheckout} />
                            ) : (
                                state.showSpinner && <Spinner />
                            )}
                        </div>
                        {props.isAuthUser && props.isCheckout && !state.isOneTimePayment && (
                            <div className="padding-top">
                                <span
                                    id="wallet-checkout-one-time-pay-link"
                                    className="wallet-link stat-text-link"
                                    data-dtm="checkout:payment information"
                                    onClick={() => {
                                        dispatch({ type: "NO_MESSAGES" });
                                        props.dispatch({ type: "SET_IS_ONE_TIME_PAYMENT_FLOW", boolean: true });
                                    }}
                                >
                                    {t("LABEL_GMWALLET_CHECKOUT_ONE_TIME_PAY")}
                                </span>
                            </div>
                        )}
                        {state.showLookUpPayBtn && (
                            <div className="wallet-footer">
                                <div className="bold">{t("LABEL_GMWALLET_NO_PAYMENT_SEEN")}</div>
                                <p>{t("LABEL_GMWALLET_GET_PAYMENT_HISTORY")}</p>
                                <SharedButton
                                    localeKey={t("LABEL_GMWALLET_PAYMENT_LOOKUP")}
                                    onClick={() => dispatch({ type: "OPEN_FOUND_PAY_MODAL", boolean: true })}
                                />
                            </div>
                        )}
                    </div>
                </>
            )) ||
            (state.viewAddNewPayment && (
                <>
                    {/* error message, appears on add new payment page */}
                    {state.addPaymentAttempt && !state.addPaymentSuccess && (
                        <ErrorMessage icon message={t("LABEL_GMWALLET_ADD_ERROR")} />
                    )}
                    {state.showAddToWalletCheckboxError && (
                        <ErrorMessage icon message={"LABEL_GMWALLET_ADD_TO_WALLET_CHECKBOX_REQUIRED"} />
                    )}
                    <AddNewPayment
                        isAuthUser={props.isAuthUser}
                        state={state}
                        dispatch={dispatch}
                        viewAddOneTimePayment={false}
                        isCheckout={state.isCheckout}
                        params={{
                            totalAmount: props.params.totalAmount,
                            currency: props.params.currency,
                            userId: props.params.userId,
                            orderNo: props.params.orderNo
                        }}
                    />
                </>
            )) ||
            (state.viewAddOneTimePayment && (
                <>
                    {/* error message, appears on add new payment page */}
                    {state.addPaymentAttempt && !state.addPaymentSuccess && (
                        <ErrorMessage
                            icon
                            message={
                                props.isCheckout ? "LABEL_GMWALLET_ADD_ERROR_CHECKOUT" : "LABEL_GMWALLET_ADD_ERROR"
                            }
                        />
                    )}
                    <AddNewPayment
                        state={state}
                        dispatch={dispatch}
                        viewAddOneTimePayment={true}
                        isAuthUser={props.isAuthUser}
                        isCheckout={props.isCheckout}
                        params={{
                            totalAmount: props.params.totalAmount,
                            currency: props.params.currency,
                            userId: oneTimePayUserId,
                            orderNo: props.params.orderNo
                        }}
                    />
                </>
            ))
        );
    }
};

export default GMWallet;
