import * as type from './HomeCategoriesActionType';

let initialState = {
    isFetching: false,
    categoriesAssets: [],
    categoriesTitle: "",
    selectedCategoryTitle: ""
}

const HomeCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {

        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.LOAD_HOME_CATEGORIES_DATA_ASYNC:
            return { ...state, isFetching: true };

        case type.SET_HOME_CATEGORIES_DATA:
            return {
                ...state,
                isFetching: false,
                categoriesAssets: action.categoriesAssets,
                categoriesTitle: action.categoriesTitle
            };

        case type.SELECTED_CATEGORY_TITLE:
            return {
                ...state,
                selectedCategoryTitle: action.selectedCategoryTitle
            };
            
        case type.RESET_HOME_CATEGORY:
            return {
                ...state,
                selectedCategoryTitle: ""
            };

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_HOME_CATEGORIES_DATA_ASYNC_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        case type.SET_HOME_CATEGORIES_DATA_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        default:
            return state
    }
};

export default HomeCategoriesReducer;