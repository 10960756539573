import * as type from "./ErrorPageActionType"

/**************************************************************/
// Actions to load data into the ErrorPageReducer state
/**************************************************************/
export const loadErrorPageDataAsync = () => {
  const action = {
    type: type.LOAD_ERROR_PAGE_DATA_ASYNC
  };
  return action;
};

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const setErrorPageData = (errorPageAssets) => {
  const action = {
    type: type.SET_ERROR_PAGE_DATA,
    errorPageAssets: errorPageAssets,
  };
  return action;
};
/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const setErrorPageDataFail = (error) => {
  const action = {
    type: type.SET_ERROR_PAGE_DATA_FAIL,
    error
  }
  return action;
}