import React, { useEffect, useState } from 'react';
import { requestUserLogout } from '../../../../../shared/Authentication/AuthenticationActionCreator';
import { Translation, Trans } from 'react-i18next';
import store from '../../../../../core/Redux/Store';
import { connect } from 'react-redux';
import EnrollmentModal from '../../../../../shared/Authentication/EnrollmentModal/EnrollmentModal';
import AppSettings from '../../../../../core/AppSettings';
import { useFooterDisclaimerLabel, useReplaceAllNamedDisclaimers } from '../../../Footer/FooterDisclaimerLabel';
import { formatNumber } from "../../../../../shared/Internationalization/FormattedNumbers";
import vaultConstants from '../../../../../../config/vault_constants';
import {Link, Redirect} from "react-router-dom";

const RegisteredFlyoutWidget = (props) => {
    const { clientId, userFlow, eSpotFlyoutText } = props;
    const [flyoutText, setFlyoutText] = useState('');

    const [isFlyoutActive, setFlyoutActive] = useState(false);

    // Used to keep the clickable area of the flyout at the correct height -- it can change if the window is resized and text within wraps around.
    const [flyoutHeight, setFlyoutHeight] = useState(0);

    // For the modal when an authenticated non-rewards member.
    const [showModal, setShowModal] = useState(false);

    const brand = AppSettings.currentSite.label;
    const disclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
    const label = useFooterDisclaimerLabel(disclaimer);

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();
    const createMarkup = (text) => {
        return {
            __html: text
        };
    };

    let prevScrollpos = window.pageYOffset;

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('resize', handleResize);
        };
    }, [isFlyoutActive, flyoutHeight]
    );

    useEffect(() => {
        setFlyoutText(eSpotFlyoutText.replace("[TierName]", '').replace(/([1-9][0-9]+)/, formatNumber(parseInt(eSpotFlyoutText.match(/([1-9][0-9]+)/)))));
    }), [eSpotFlyoutText]

    // Resize the clickable area of the flyout as needed -- this occurs if the window is resized enogh for the text to wrap.
    const handleResize = () => {
        if (isFlyoutActive) {
            setFlyoutHeightFromWrapperHeight();
        }
    }

    // If scrolling down, then close the flyout.
    const handleScroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos < currentScrollPos) {
            setFlyoutActive(false);
        }
        prevScrollpos = currentScrollPos;
    }

    const handleClick = (e) => {
        const widgetFlyoutElement = document.getElementById("ac-user-widget");
        const flyoutWrapperElement = document.getElementsByClassName("ac-header-flyout-clickable-area ");

        let targetElement = e.target;

        // Potential explanation of this do/while loop: The target element is the innermost nested element (if nested at all) of the position clicked.
        // At the end of each iteration the next targetElement is the parent of the inner element in the prior iteration. The loop ends when there are no more parent elements.
        // In this situation we look through all of the elements at the position clicked and check if the we clicked the element we were looking for to toggle flyout.
        do {
            // Do not close the flyout if the flyout itself is clicked.
            if (targetElement == flyoutWrapperElement[0]) {
                return;
            }

            // Toggle flyout.
            if (targetElement == widgetFlyoutElement) {
                setFlyoutActive(!isFlyoutActive);
                if (!isFlyoutActive) {
                    // Set clickable area height to prevent flyout from closing when clicking within.
                    setFlyoutHeightFromWrapperHeight();
                }
                return;
            }

            targetElement = targetElement.parentNode;
        } while (targetElement);

        // If anything but the toggle element was clicked, just close the flyout.
        setFlyoutActive(false);
    }

    // Get the size of the wrapper for the flyout, so that the clickable area can have it's height set.
    const setFlyoutHeightFromWrapperHeight = () => {
        const heightobj = document.getElementsByClassName("ac-header-dropdown-content-header-wrapper");
        try {
            // This try statement is needed for when the user is registered and there is no text section with grey background... as of yet.
            setFlyoutHeight(heightobj[0] ? heightobj[0].offsetHeight : '');
        } catch (e) {

        }
    }

    const handleEnroll = () => {
        if (props.authData.userData.firstName) {
            // If the first name exists, then the user has already completed their additional details, and just open the modal
            setShowModal(true);
        } else {
            // If the first name does not exist, then re-direct the user to the additional details page.
            window.location.href = '/register/registrationDetails';
        }
    }

    const enrollModal = (
        <EnrollmentModal
            covisIntId={props.authData.userData.logonId}
            open={showModal}
            onClose={setShowModal}
            label={label}
        />
    );


    // re-direct the user to the payment methods page.
    const handlePaymentMethodClick = () => {
        window.location.href = '/profile/payment';
    }

    const flyoutElement = (
        <Translation>
            {(t) => (
                <div id="ac-signOut-flyout" className="row">
                    <div
                        className={isFlyoutActive ? "medium-5 large-4 xlarge-3 ac-header-user-dropdown-mobile ac-header-user-dropdown q-expander" : "ac-header-user-dropdown-hidden q-expander"}>
                        {
                            // This is only shown if the user is an authenticated user but is not enrolled in Rewards
                            props.authData.enrolledAttributeValue === "0" &&
                            <>
                                {/* If the espot text for the flyout is empty, do not show the wrapper, only the buttons need to be shown. */}
                                {eSpotFlyoutText &&
                                    <div id="ac-my-rewards-flyout" className="ac-header-dropdown-content-header-wrapper">
                                        <div className="ac-header-flyout-clickable-area"
                                            style={{ height: flyoutHeight + "px" }} />
                                        <h5 className="ac-header-dropdown-content-header q-body1">{t("LABEL_ACCOUNT_MY_REWARDS", { brand })}</h5>
                                        <div dangerouslySetInnerHTML={createMarkup(
                                            replaceDisclaimers(flyoutText)
                                        )} />
                                    </div>
                                }
                                <div onClick={() => handleEnroll()} id="ac-enroll-in-rewards-flyout"
                                    className="ac-sign-in-flyout q-headline stat-button-link"
                                    data-dtm="global nav:my account">
                                    {t('LABEL_COMMON_ENROLL_IN_REWARDS')}
                                </div>
                            </>
                        }
                        {/*Display Rewards Member tier name and total Rewards Member points*/}
                        {/*My <Brand> Rewards(#) <tier> member points: <Total rewards points>*/}
                        {props.authData.enrolledAttributeValue === '1' && props.rewardsData.isRewardsActive &&
                            <div id="rewards-account-information-flyout"
                                className="ac-header-dropdown-content-header-wrapper">
                                <div className="ac-header-flyout-clickable-area" style={{ height: flyoutHeight + "px" }} />
                                <h5 className="ac-header-dropdown-content-header q-body1">{t("LABEL_ACCOUNT_MY_ACCOUNT")}</h5>
                                <div dangerouslySetInnerHTML={createMarkup(
                                    replaceDisclaimers(flyoutText)
                                )} />
                            </div>
                        }
                        {/* Payment Methods */}
                        {props.authData.userData && props.authData.userData.country === 'US' &&
                            <div onClick={() => handlePaymentMethodClick()} id="ac-payment-methods-flyout"
                                className="ac-sign-in-flyout q-headline stat-button-link" data-dtm="global nav:my account">
                                {t('LABEL_AUTHENTICATION_PAYMENT_METHODS_LABEL')}
                            </div>
                        }

                        {/* Log Out */}
                        <div onClick={() => handleUserLogout(userFlow, clientId)} id="ac-sign-out-flyout"
                            className="ac-sign-in-flyout q-headline stat-button-link" data-dtm="global nav:my account">
                            {t('LABEL_COMMON_SIGN_OUT')}
                        </div>
                    </div>
                </div>
            )}
        </Translation>);

    if (isFlyoutActive) {
        return flyoutElement;
    } else if (showModal) {
        return enrollModal;
    } else {
        return <div></div>
    }
}

const handleUserLogout = (userFlow, clientId) => {
    store.dispatch(requestUserLogout(userFlow, clientId, ' ', '/'));
}

const mapStateToProps = state => {
    return {
        authData: state.Authentication,
        rewardsData: state.RewardsReducer
    };
};

export default connect(mapStateToProps)(RegisteredFlyoutWidget);
