import { getErrorPageData } from "./ErrorPageService"
import {setErrorPageData, setErrorPageDataFail } from "./ErrorPageActionCreator";
import { call, put } from "redux-saga/effects";
import { getBaseMarketingSpotActivityData } from '../../../shared/Validator/Validator';
/*
* wow it's a parser for the eSpot
* */
export function* callForErrorPageData() {
  let dataAsset = {};
  try {
    const res = yield call(getErrorPageData);

    const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(res.data, 0);
    if(baseMarketingSpotActivityData) {
      dataAsset = parseAssets(baseMarketingSpotActivityData[0]);
      yield put(setErrorPageData(dataAsset));
    } else {
      yield put(setErrorPageDataFail(new Error('invalid marketing data')));
    }

  } catch (error) {
    yield put(setErrorPageDataFail(error));
  }

}
function parseAssets(eSpotData) {

  let attachmentAssets = eSpotData.attachmentAsset[0];

    let imagePath = attachmentAssets.attachmentAssetPath;

  return imagePath;

}




