import React, { Component } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";
import store from "../../../core/Redux/Store";
import ViewOnVehicle from "../../../shared/ViewOnVehicle/ViewOnVehicle";
import ProductDetailsLoyaltyPoints from "../ProductDetailsLoyaltyPoints/ProductDetailsLoyaltyPoints";
import {
    getRewardsPotentialPointsInfo,
    setRewardsPotentialPoints,
    getPdPotentialPoints
} from "../../../shared/Rewards/RewardsRedux/RewardsActionCreator";
import QuantityPicker from "../../../shared/QuantityPicker/QuantityPicker";
import DealerPicker from "./DealerPicker";
import InlineFitment from "../InlineFitment/InlineFitment";
import ErrorBoundary from "../../../shared/ErrorBoundary/ErrorBoundary";

const pdpDeliveryOptions = AppSettings.isLocalHost || vaultConstants.FF_2092326_PDP_DELIVERY_OPTIONS;

class ProductDetailsDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1
        };
    }

    handleQuantityChange = (qty) => {
        this.setState({
            quantity: qty
        });
    };

    componentDidMount() {
        this.dispatchForPotentialPoints();
    }

    componentDidUpdate(prevProps, prevState) {
        // If either of these attributes was updated, try again to get potential points
        if (
            this.props.authData.registrationStatusFetched != prevProps.authData.registrationStatusFetched ||
            this.props.authData.enrolledAttributeValueFetched != prevProps.authData.enrolledAttributeValueFetched ||
            this.props.rewardsData.enrollmentStatusFetched != prevProps.rewardsData.enrollmentStatusFetched
        ) {
            this.dispatchForPotentialPoints();
        }

        // Quantity change update state
        if (this.state.quantity != prevState.quantity) {
            store.dispatch({ type: "SET_QUANTITY", quantity: this.state.quantity.toString() });
        }

        if(prevProps.showError !== this.props.showError){
            return true;
        }
    }

    // Only able to dispatch the action if registration status is available
    // Additionally, if the user is registered, enrollment status is also needed
    dispatchForPotentialPoints() {
        // Reset potential points if points already present
        if (this.props.rewardsData.potentialPoints) {
            store.dispatch(setRewardsPotentialPoints(null));
        }

        if (this.props.authData.registrationStatusFetched) {
            if (this.props.authData.registrationStatus == "R") {
                if (this.props.authData.enrolledAttributeValueFetched) {
                    if (this.props.authData.enrolledAttributeValue == "1") {
                        if (this.props.rewardsData.enrollmentStatusFetched && this.props.rewardsData.isRewardsActive) {
                            store.dispatch(getRewardsPotentialPointsInfo(this.props.item.pdFormattedProductPrice));
                        }
                    } else {
                        // Registered user not enrolled in Loyalty
                        store.dispatch(getPdPotentialPoints(null, this.props.item.pdFormattedProductPrice));
                    }
                }
            } else {
                // Guest user
                store.dispatch(getPdPotentialPoints(null, this.props.item.pdFormattedProductPrice));
            }
        }
    }

    renderFeatures() {
        const template = this.props.item.features.map((feature, index) => <li key={index}>{feature}</li>);
        return template;
    }

    renderAvailableDeliveryMethods() {
        const template = this.props.item.availableDeliveryMethods.map((method, index) => (
            <div
                key={index}
                className="small-4 medium-12 large-4 xlarge-4 columns ac-pd-desc-delivery-item small-margin"
            >
                <img alt={method.name} src={method.image}></img>
            </div>
        ));
        return template;
    }

    renderPrice() {
        const viewOnVehicleData = {
            productName: this.props.item.name,
            installPrice: this.props.item.installPrice,
            productPrice: this.props.item.price,
            partsRPO: this.props.item.productRPOCode,
            colorCode: this.props.item.productColorCode,
            addToCartData: {
                vin: this.props.vin,
                showAddToCartError: this.props.item.showAddToCartError,
                showLoadingBtn: this.props.item.showLoadingBtn,
                name: this.props.item.name
            }
        };

        let priceElement = "";
        if (AppSettings.isT3) {
            if (this.props.item.installPrice) {
                priceElement = (
                    <Translation>
                        {(t) => (
                            <div className="ac-pd-description-wrapper">
                                <h2 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-msrp q-headline2 small-margin">
                                    <b>
                                        <span>{t("LABEL_SEARCH_RESULTS_PART_ONLY")} </span>
                                        <span>{this.props.item.price}*</span>
                                        {/* TODO: VOV should not be within priceElement... */}
                                        {this.props.item.isVOVActive && (
                                            <div className="ac-view-on-vehicle">
                                                <ViewOnVehicle data={viewOnVehicleData} />
                                            </div>
                                        )}
                                    </b>
                                </h2>
                                <h2 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-msrp q-headline2 small-margin">
                                    <b>
                                        <span>{t("LABEL_SEARCH_RESULTS_INSTALLED_PRICE")} </span>
                                        <span>{this.props.item.installPrice}*</span>
                                    </b>
                                </h2>
                            </div>
                        )}
                    </Translation>
                );
            } else {
                priceElement = (
                    <Translation>
                        {(t) => (
                            <div className="ac-pd-description-wrapper">
                                <h2 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-msrp q-headline2 small-margin">
                                    <b>
                                        <span>{t("LABEL_SEARCH_RESULTS_PART_ONLY")} </span>
                                        <span>{this.props.item.price}*</span>
                                        {/* TODO: VOV should not be within priceElement... */}
                                        {this.props.item.isVOVActive && (
                                            <div className="ac-view-on-vehicle">
                                                <ViewOnVehicle data={viewOnVehicleData} />
                                            </div>
                                        )}
                                    </b>
                                </h2>
                            </div>
                        )}
                    </Translation>
                );
            }
        } else {
            priceElement = (
                <Translation>
                    {(t) => (
                        <div className="ac-pd-description-wrapper">
                            <h2 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-msrp q-headline2 small-margin">
                                <b>
                                    <span>{t("LABEL_COMMON_MSRP")} </span>
                                    <span>{this.props.item.price}*</span>
                                    {/* TODO: VOV should not be within priceElement... */}
                                    {this.props.item.isVOVActive && (
                                        <div className="ac-view-on-vehicle">
                                            <ViewOnVehicle data={viewOnVehicleData} />
                                        </div>
                                    )}
                                </b>
                            </h2>
                        </div>
                    )}
                </Translation>
            );
        }

        return priceElement;
    }

    render() {
        const supersessionNumber = sessionStorage.getItem("supersessionOldPartNumber");
        const inlineFitmentFlag = AppSettings.isLocalHost
            ? true
            : String(vaultConstants.FF_2197227_INLINE_FITMENT_PDP_ACCESSORIES) === "true";
        let productDetailsDescription = (
            <Translation>
                {(t) => (
                    <div className="ac-pd-description">
                        <div className="">
                            <h2 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-title q-headline2">
                                {this.props.item.name}
                            </h2>
                        </div>
                        <div className="">
                            <h4 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-part q-descriptive2 medium-margin">
                                {t("LABEL_COMMON_PART_NO")} : {this.props.item.partNumber}
                                {supersessionNumber && (
                                    <span id="gm-part-number-superceded">
                                        {t("LABEL_REPLACE_OLD_PARTNUMBER", { oldPartNumber: supersessionNumber })}
                                    </span>
                                )}
                            </h4>
                        </div>
                        <div id="ac-qty-picker-price-row" className="ac-qty-picker-price-row">
                            <QuantityPicker
                                value={this.state.quantity}
                                onChange={this.handleQuantityChange}
                                analyticsTag={this.props.item.name}
                            />
                            {this.renderPrice()}
                        </div>

                        {!AppSettings.isT3 &&
                            this.props.isDealerized &&
                            this.props.authData.enrolledAttributeValue === "1" &&
                            this.props.rewardsData.isRewardsActive &&
                            (this.props.rewardsData.potentialPoints !== null ||
                                this.props.rewardsData.potentialPointsInfoError) && (
                                <ProductDetailsLoyaltyPoints
                                    dealerData={this.props.dealerData}
                                    potentialPoints={this.props.rewardsData.potentialPoints}
                                    error={this.props.rewardsData.potentialPointsInfoError}
                                    tier={this.props.rewardsData.currentTierName}
                                    isLoyaltyUser={this.props.authData.enrolledAttributeValue === "1"}
                                />
                            )}
                        {!AppSettings.isT3 &&
                            this.props.isDealerized &&
                            (this.props.rewardsData.potentialPoints !== null ||
                                this.props.rewardsData.potentialPointsInfoError) &&
                            this.props.authData.enrolledAttributeValue !== "1" && (
                                <ProductDetailsLoyaltyPoints
                                    dealerData={this.props.dealerData}
                                    potentialPoints={this.props.rewardsData.potentialPoints}
                                    error={this.props.rewardsData.potentialPointsInfoError}
                                    tier={this.props.rewardsData.currentTierName}
                                    isLoyaltyUser={this.props.authData.enrolledAttributeValue === "1"}
                                />
                            )}
                        {!AppSettings.isT3 && (
                            <DealerPicker
                                dealerName={this.props.dealerData.currentSourceName}
                                dealerData={this.props.dealerData}
                                item={this.props.item}
                            />
                        )}
                        <div className="medium-margin">
                            <div className="q-text">
                                <ul id="small-12 medium-12 large-12 xlarge-12 ac-pd-desc-features">
                                    {this.renderFeatures()}
                                </ul>
                            </div>
                        </div>
                        {!AppSettings.isT3 && inlineFitmentFlag && (
                            <ErrorBoundary message="Error loading inline fitment component">
                                <InlineFitment
                                    partNumber={this.props.item.partNumber}
                                    showError={this.props.showError}
                                    flipRerenderBreadcrumb={this.props.flipRerenderBreadcrumb}
                                />
                            </ErrorBoundary>
                        )}
                        {AppSettings.isT3 ||
                            (!pdpDeliveryOptions && (
                                <>
                                    <div className="small-margin">
                                        <h4 className="small-12 medium-12 large-12 xlarge-12 ac-pd-description-part q-descriptive2">
                                            {t("LABEL_PRODUCT_DETAILS_AVAILABLE_DELIVERY")}
                                        </h4>
                                    </div>
                                    <div className="">{this.renderAvailableDeliveryMethods()}</div>
                                </>
                            ))}
                    </div>
                )}
            </Translation>
        );

        return productDetailsDescription;
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.Authentication,
        rewardsData: state.RewardsReducer
    };
};

export default connect(mapStateToProps)(ProductDetailsDescription);
