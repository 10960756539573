import * as type from './OrderConfirmActionTypes';

/*** Order Data **/
export const setOrderData = (orderData, parsedResponse)=>{
  return {
    type:type.ORDER_CONFIRM_SET_ORDER_DATA,
    orderData: orderData,
    parsedResponse: parsedResponse
  }
};

export const getOrderDataAsync = ()=>{
  return {
    type:type.ORDER_CONFIRM_GET_ORDER_DATA_ASYNC
  }
};

export const loadOrderDataFail = (error) => {
  return {
    type: type.ORDER_CONFIRM_ORDER_DATA_FAIL,
    error: error
  }
}

/*** Payment Information **/
export const getPaymentInfoAsync = ()=>{
  return {
    type:type.ORDER_CONFIRM_GET_PAYMENT_INFO_ASYNC
  }
};

export const setPaymentInfo = (paymentInfo)=>{
  return {
    type:type.ORDER_CONFIRM_SET_PAYMENT_INFO,
    paymentInfo: paymentInfo
  }
};

export const loadPaymentInfoFail = (error) => {
  return {
    type: type.ORDER_CONFIRM_PAYMENT_CONFIRM_FAIL,
    error: error
  }
}