import React from 'react';
import { Translation } from 'react-i18next';
import {formatNumber} from '../../../../../client/shared/Internationalization/FormattedNumbers';



const HeaderRewards = ({ rewardsPoints, width })=> {

    if (rewardsPoints && rewardsPoints) {
        const points = rewardsPoints.includes(",") ?  rewardsPoints : formatNumber(rewardsPoints) ;
        return (
            <Translation>{(t) => (
                <div className="ac-header-loyalty-pts">
                    <span>{width >= 570 ?
                        t('LABEL_REWARDS_HEADER_REWARDS_POINTS', { rewardsPoints: points }) :
                        t('LABEL_REWARDS_HEADER_REWARDS_POINTS_SMALL', { rewardsPoints: points })}
                    </span>
                </div>
            )}</Translation>
        );
    }
    else {
        return null;
    }
}

export default HeaderRewards;
