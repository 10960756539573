export function capitalizeString(word) {
    // Format model names.
    // Edge case for EVs and EUVs, LDs and HDs -- just capitalize the whole string
    // EXs: silverado 1500 = Silverado 1500, bolt euv = Bolt EUV,  cruze limited = Cruze Limited
    if (!word) {
        return "";
    }
    let splitWord = word.split(" ");
    for (let i = 0; i < splitWord.length; i++) {
        if (splitWord[i].length > 3) {
            splitWord[i] = splitWord[i].charAt(0).toUpperCase() + splitWord[i].slice(1);
        } else {
            splitWord[i] = splitWord[i].toUpperCase();
        }
    }

    return splitWord.join(" ");
}
