import axios from 'axios';
import AppSettings from '../../../core/AppSettings';

// This API will be used to resend an e-mail if the user did not get it, or find it... Or if the verification expired, then resend it.
export const generateVerificationCode = (username, orderId="") => {
    // Set up the request
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMPerson/register/verificationcodegenerate';

    let body = {
        "username": username
    };

    // Registering flow from the OCP page -- Loyalty 3.8 release
    if (orderId) {
        body = {
            "username": username,
            "orderId": orderId
        };
    }

    // Make the call
    return axios.post(url, body).then( (response) => { return response; }).catch( (error) => { console.log("error:", error); });
}

// This API verifies an account by taking in a username/email, and the token that is gotten from an e-mail.
export const verifyCode = (username, code) => {
    // Set up the request
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMPerson/register/verificationcode';

    let body = {
        "username": username,
        "verificationCode": code
    };
    // Make the call
    return axios.post(url, body).then( (response) => { return response; }).catch( (error) => { console.log("error:", error); });
}

export const getOrderIdData = (orderId, email) => {
    // Set up the request
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMPerson/register/populateordercustomerinfo';

    let body = {
        "order_id": orderId
    };

    if (email) {
        body = {
            "order_id": orderId,
            "username": email
        };
    }

    // Make the call
    return axios.post(url, body).then( (response) => { return response.data; }).catch( (error) => { console.log("error:", error); });
}

export const postUpdateProfile = (covisintID, body) => {
    let url = "/wcs/resources/store/" + AppSettings.storeId + "/GMPerson/user/updateProfile/" + covisintID + "?responseFormat=json";
    return axios.post(url, body).then( (response) => { return response.data; }).catch(error => { console.log("error", error); });
}

export const sendEmailLink = (body) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/GMPerson/user/sendEmailLink`;
    return axios
        .post(url, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("error", error);
        });
};

export const exchangeCode = (body) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/GMPerson/user/verificationcode`;
    return axios
        .post(url, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("error", error);
        });
};

export const registerUser = (body) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/GMPerson/user/registerUser`;
    return axios
        .post(url, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("error", error);
        });
};