import React from 'react';
import { Translation, Trans } from 'react-i18next';
import { formatNumber } from '../../../../client/shared/Internationalization/FormattedNumbers';
import AppSettings from '../../../core/AppSettings';
import { useFooterDisclaimerLabel } from '../../App/Footer/FooterDisclaimerLabel';
import FieldWarningMessage from "../../../shared/FieldWarningMessage/FieldWarningMessage";

function ProductDetailsLoyaltyPoints(props) {
    const { potentialPoints, tier, isLoyaltyUser, error} = props;
    const brand = AppSettings.currentSite.label;

    if(error){
        return (
            <Translation>
                {(t) => (
                    <div className="margin-small" id="rewards-service-error-message">
                        <FieldWarningMessage message={t("LABEL_REWARDS_SERVICE_ERROR", { brand })} />
                    </div>
                )}
            </Translation>
        );
    } else if (potentialPoints && props.dealerData.selectedDealerAllData.rewardsProgram) {
        const points = formatNumber(potentialPoints); //points const stores formatted version of potentialPoints

        // get correct superscript label for the loyalty points disclaimer
        const disclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
        const tierDisclaimer = process.env.REACT_APP_DISCLAIMER_TIER_MEMBER_NAME;
        const label = useFooterDisclaimerLabel(disclaimer);
        const tierLabel = useFooterDisclaimerLabel(tierDisclaimer);

        return (
            <div>
                <h4 className="small-12 medium-12 large-12 xlarge-12 q-descriptive2 medium-margin" id="rewards-potential-points-message">
                    {/* Placeholder text for interpolation */}
                    {isLoyaltyUser ?
                        <Trans i18nKey="LABEL_REWARDS_MEMBER_POTENTIAL_POINTS">
                            {{tier}}{{ potentialPoints: points }} <sup>{{ label: label }}</sup> <sup>{{ tierLabel: tierLabel }}</sup> {{brand}}
                        </Trans> :
                        <Trans i18nKey="LABEL_REWARDS_POTENTIAL_POINTS">
                            {{brand}}{{ potentialPoints: points }} <sup>{{ label: label }}</sup>
                        </Trans>
                    }
                </h4>
            </div>
        );
    } else if (!props.dealerData.selectedDealerAllData.rewardsProgram) {
        return (
            <div>
                <h4 className="small-12 medium-12 large-12 xlarge-12 q-descriptive2 medium-margin" id="rewards-potential-points-message">
                    {/* Placeholder text for interpolation */}
                    <Trans i18nKey="LABEL_LOYALTY_CHECKOUT_NONPARTICIPATION">
                    {{brand}}
                    </Trans>
                </h4>
            </div>
        );
    } else {
        return null;
    }
}

export default ProductDetailsLoyaltyPoints;
