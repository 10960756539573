import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import SharedSpinner from "../Spinner/Spinner";

/**
 * SHARED BUTTON COMPONENT
 * This Button works as a simple standalone component
 * Button documentation exists at https://react-bootstrap.github.io/components/buttons/
 * NOTE: Other props (other than the ones below) exist for Button that could allow for more extension
 *
 * Props:
 *  active: boolean
 *    OPTIONAL (false)
 *    Manually set the visual state of the button to :active
 *
 *  as: string
 *    OPTIONAL ('button')
 *    You can use a custom element type for this component.
 *
 *  block: boolean
 *    OPTIONAL (false)
 *    Spans the full width of the Button parent
 *
 *  buttonText: string
 *    OPTIONAL ('Click me!')
 *    Text to be displayed on the Button
 *
 *  disabled: boolean
 *    OPTIONAL (false)
 *    Disables the Button, preventing mouse events, even if the underlying component is an <a> element
 *
 *  href: string
 *    OPTIONAL ('#')
 *    Providing a href will render an <a> element, styled as a button.
 *
 *  icon: element
 *    OPTIONAL (null)
 *    Icon to be displayed to the left of the button text (Fontawesome Icons)
 *
 *  onClick: function
 *    REQUIRED
 *    Function to be applied to the onClick action of the Button
 *
 *  size: string
 *    OPTIONAL ('')
 *    Component size variations; Available options are ['', 'sm', 'lg']
 *
 *  type: string
 *    OPTIONAL ('button')
 *    Defines HTML button type attribute; Available options are ['button', 'reset', 'submit', null]
 *
 *  variant: string
 *    OPTIONAL ('primary')
 *    One or more button variant combinations; Available options are ['primary', 'secondary', 'tertiary', 'destructive', 'workflow']
 *
 *  analyticsTag: string
 *    OPTIONAL ('stat-button-link')
 *    Adobe Analytics tagging
 *
 * Usage:
 *  1) Import the SharedButton component from ./shared/Buttons/SharedButton
 *
 *  2) Define the required/optional props, e.g.:
 *      const buttonText = "Label";
 *      const clickAction = () => {
 *              alert("TEST");
 *              // INSERT YOUR CODE HERE
 *            };
 *
 *  3) Add the SharedButton component and pass in the items as a prop, e.g.:
 *      <SharedButton
 *        buttonText={buttonText}
 *        onClick={clickAction}
 *      />
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export default function SharedButton(props) {
    // Translations
    const { t } = useTranslation();
    const iconDisplay = props.icon ? props.icon : null;
    const inputClass = props.className && props.className + " ";
    const isLoading = props.isLoading ? props.isLoading : false;

    const inputProps = {
        id: props.id,
        active: props.active,
        as: props.as,
        block: props.block,
        disabled: props.disabled,
        href: props.href,
        onClick: props.onClick,
        size: props.size,
        type: props.type,
        variant: props.variant + " q-button-" + props.variant,
        className: inputClass + "q-button q-" + props.variant + "-button" + " stat-button-link"
    };

    return (
        <>
            <Button {...inputProps} data-dtm={props.analyticsTag}>
                {!props.iconAfterText && iconDisplay}
                {isLoading ? <SharedSpinner size="sm" buttonSpinner={true} /> : t(props.buttonText)}
                {props.iconAfterText && iconDisplay}
            </Button>
        </>
    );
}

SharedButton.propTypes = {
    id: PropTypes.string,
    active: PropTypes.bool,
    as: PropTypes.string,
    block: PropTypes.bool,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.oneOf(["", "sm", "lg"]),
    type: PropTypes.oneOf(["button", "reset", "submit", null]),
    variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "destructive", "workflow"]),
    className: PropTypes.string,
    analyticsTag: PropTypes.string,
    isLoading: PropTypes.bool,
    iconAfterText: PropTypes.bool
};

SharedButton.defaultProps = {
    active: false,
    as: "button",
    block: false,
    buttonText: "Click me!",
    disabled: false,
    href: "#",
    icon: null,
    size: "",
    type: "button",
    variant: "primary",
    analyticsTag: "stat-button-link",
    className: "",
    isLoading: false,
    iconAfterText: false
};
