/**
 * A helper function to remove excess spaces between words in an input string.
 * @param {string} str the string to operate on
 * @param {string?} space the whitespace character to use
 * @returns {string} the string with excess spaces removed
 */
 export const limitWhitespace = (str, space = " ") => {
  const arr = str.split(space);

  // delete from array all empty items
  for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "") {
          arr.splice(i, 1);
          i--;
      }
  }

  return arr.join(space);
};

let parser = undefined;

/**
* Generates the alt text attribute from the markdown for the overlay
* text of a specified marketing asset.
* @param {string} markdown the marketing asset's text to parse
* @param {string?} defaultText the string to substitute if the parser fails
* @returns {string} the formatted alt text for this asset
*/
export const generateAltText = (markdown, defaultText = "carousel marketing offer") => {
  if (!(parser instanceof DOMParser)) parser = new DOMParser();
  const content = parser.parseFromString(markdown, "text/html");
  const markdownBody = content && content.getElementsByTagName("body");
  const elementText = markdownBody.length ? markdownBody[0].textContent : null;
  const altText = elementText ? limitWhitespace(elementText) : null;
  return altText || defaultText;
};
