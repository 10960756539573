import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import store from "../../core/Redux/Store";
import AppSettings, { getPageName } from "../../core/AppSettings";
import * as validate from "../Form/FormValidate/FormValidate";
import Select from "../Select/Select";
import { resetVIN } from "./VehicleSelectRedux/VehicleSelectActionCreator";
import VinInstructions from "./VehicleSelectVinInstructions";
import FieldErrorMessage from "../../shared/FieldErrorMessage/FieldErrorMessage";
import Spinner from "../../shared/Spinner/Spinner";
import VehicleConfig from "../../shared/VehicleConfig/VehicleConfig";
import { selectMake, selectModel, selectYear, toggleVinInstructions, loadVINDataAsync } from './VehicleSelectRedux/VehicleSelectActionCreator';
import { loadVehicleCombosAsync, selectBody } from "../VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import { deleteFitmentCookie } from "./CookieService/FitmentData";
import { RESET_VEHICLE_INFO } from "../Session/VehicleInfo/VehicleInfoActionType";
import vaultConstants from "../../../config/vault_constants";
import { resetVehicleSelectState } from "../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
// Returns the model select with the model options corresponding to the
// currently selected year. Years are mapped to models by the asynchronous
// LOAD_MODELS action that is dispatched by the VehicleSelectContainer.

let newFitmentFlag;

//T3 consideration for new fitment flag
if (AppSettings.isT3) {
    newFitmentFlag = false;
} else if (AppSettings.isLocalHost) {
    newFitmentFlag = true;
} else {
    newFitmentFlag = vaultConstants.FF_2237155_NEW_FITMENT_CONTAINER_ACC;
}

function ModelSelect(props) {
    const { year, showError, value } = props;
    const models = props.yearsToModels[year];
    const modelSelect = (
        <Translation>
            {(t) => (
                <React.Fragment>
                    <Select
                        disabled={props.disabled || showError || !models}
                        defaultValue={t("LABEL_COMMON_CHOOSE_MODEL")}
                        value={value}
                        options={models}
                        onClick={props.onClick}
                    />
                    {showError && (
                        <label className="error ac-error-label">
                            <FontAwesomeIcon icon="exclamation-circle" />
                            {t("LABEL_COMMON_CHOOSE_MODEL_LOAD_ERROR")}
                        </label>
                    )}
                </React.Fragment>
            )}
        </Translation>
    );
    return modelSelect;
}

// Redux Form Field
// https://redux-form.com/7.1.2/docs/gettingstarted.md/
const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? "ac-vehicle-select-field-error" : "ac-vehicle-select-field"}>
            {required == true && <label className="q-label1 ac-vehicle-select-field-label required">{label}</label>}
            {required != true && <label className="q-label1 ac-vehicle-select-field-label">{label}</label>}
            <input {...input} placeholder={label} type={type} {...otherProps} />
            {touched &&
                ((error && <FieldErrorMessage message={error} showError={otherProps} />) ||
                    (warning && <FieldErrorMessage message={warning} showError={otherProps} />))}
        </div>
    </div>
);

// 
/*************************************/
/*        Start Form
/*************************************/
const VehicleSelectForm = (props) => {
    //Getting data from session/cookie
    const homeSearchPageNames = new Set(["", "HomePage"]);
    const pdpSearchPageNames = new Set(["", "Product"]);
    const [displayPartial, setDisplayPartial] = useState(false);
    const vehicleInfoData = useSelector((state) => state.Session);
    const bodyFitmentSelection = useSelector((state) => state.VehicleConfigReducer.fitmentSelections.bodyFitmentSelection);
    const sessionLoading = useRef(true);
    const bodyLoading = useRef(true);

    //Function for change vehicle button to reset to partial fit
    function resetPartialFit() {
        deleteFitmentCookie().then(response => store.dispatch({ type: RESET_VEHICLE_INFO }));
        store.dispatch(resetVehicleSelectState());
    }

    // check to see if current page should partial fitment displayed, not on HP
    function checkPageName() {
        if (homeSearchPageNames.has(getPageName())) {
            setDisplayPartial(false);
        } else if(pdpSearchPageNames.has(getPageName()) && window.location.pathname != "/product/search"){
            setDisplayPartial(false);
        } else {
            setDisplayPartial(true);
        }
    }
  
    //setting styling based on if dropdowns are for partial state
    const [stylingDropdown, setStylingDropdown] = useState("twoColumns");
    function checkPartialDropdowns() {
        if(vehicleInfoData.model && !homeSearchPageNames.has(getPageName())
        && !(pdpSearchPageNames.has(getPageName()) && window.location.pathname != "/product/search")){
            setStylingDropdown("partialDropdowns");
        }
        else{
            setStylingDropdown("twoColumns");
        }
    }
    //Dispatches for year,make,model using the cookie data which allows us to get full fitment info
    useEffect(() => {
        checkPageName();
        checkPartialDropdowns();

        if (vehicleInfoData.model && sessionLoading.current && newFitmentFlag) {
            store.dispatch(selectYear(vehicleInfoData.year));
            store.dispatch(selectMake(vehicleInfoData.make));
            store.dispatch(selectModel(vehicleInfoData.model));
            store.dispatch(loadVehicleCombosAsync());
            sessionLoading.current = false;
        }

        if (bodyFitmentSelection.length > 1 && newFitmentFlag) {
            const selectedBodyValues = {
                bodyId: vehicleInfoData.vehicleConfig.bodyId,
                bodyNumDoors: vehicleInfoData.vehicleConfig.bodyNumDoorsId,
                body: vehicleInfoData.vehicleConfig.body
            }
            store.dispatch(selectBody(selectedBodyValues))
            bodyLoading.current = false;
        }

    }, [vehicleInfoData, bodyFitmentSelection])

    const {
        showLoadingBtn,
        years,
        selectedYear,
        selectedMake,
        codeToMake,
        yearsToMakes,
        selectedModel,
        yearsToModels,
        showLoadModelsError,
        showLoadMakesError,
        vinResponse,
        vinErrorLabel,
        showVinInstructions,
        showLoadVinError
    } = props.fullVehicleInfo;
  
    /****************************************/
    // For T3/Dealer - Model Select dropdown
    /****************************************/
    const MakeSelect = () => {
        const makes = yearsToMakes[selectedYear];
        const value = codeToMake[selectedMake] || selectedMake;
        return (
            // Note: grid is different for t3 (3 ymm dropdowns vs two)
            <div className={vehicleSelectDropdownClass("ac-ymmMake")}>
                <Translation>
                    {(t) => (
                        <React.Fragment>
                            <Select
                                disabled={isMakeSelectDisabled() || showLoadMakesError || !makes}
                                defaultValue={t("LABEL_COMMON_CHOOSE_MAKE")}
                                value={value}
                                options={makes}
                                showError={showLoadMakesError}
                                onClick={props.handleMakeChange}
                            />
                            {showLoadMakesError && (
                                <label className="error ac-error-label">
                                    <FontAwesomeIcon icon="exclamation-circle" />
                                    {t("LABEL_COMMON_CHOOSE_MAKE_LOAD_ERROR")}
                                </label>
                            )}
                        </React.Fragment>
                    )}
                </Translation>
            </div>
        );
    };
    const vehicleSelectDropdownClass = (classes) => {
        let dropdownClasses =
            stylingDropdown === "twoColumns"
                ? "small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left q-grid-row-new-mobile columns "
                : "partialDropdowns small-12 medium-12 large-3 xlarge-3 grid-column-alignment-left q-grid-row-new-mobile columns ";

        // check for extra classes
        classes ? (dropdownClasses += classes) : "";

        return dropdownClasses;
    };

    /****************************************/
    // disable vehicle select search button until
    // YMM or VIN has been properly filled out
    /****************************************/
    function isMakeSelectDisabled() {
        return selectedYear == null || selectedYear == "" || selectedYear == "Year";
    }

    function isModelSelectDisabled() {
        return isMakeSelectDisabled() || selectedMake == null || selectedMake == "" || selectedMake == "Make";
    }

    function isYMMDisabled() {
        return (
            isModelSelectDisabled() ||
            selectedModel == null ||
            selectedModel == "" ||
            selectedModel == "Model" ||
            props.enableSearchBtn
        );
    }

    function isVINDisabled() {
        return !props.enteredVin || showLoadingBtn & !showLoadVinError;
    }

    function isVehicleSelectButtonDisabled() {
        return isYMMDisabled() && isVINDisabled();
    }

    function getVinInstructionsIcon() {
        return showVinInstructions ? "caret-up" : "caret-down";
    }

    /****************************************/
    /* START HANDLE VIN ERRORS - Error Msgs            
  /****************************************/
    let vinErrorMsg = "";

    //get vin error message (from vehicleSelectReducer)
    if (vinResponse.errorMessage) {
        vinErrorMsg = vinResponse.errorMessage;
    }

    //if vin is from a different brand than current store (T1 only), give appropriate error message
    if (vinErrorLabel == "LABEL_BRAND_ERROR") {
        const currentT1Brand = vinResponse.store;
        const vinT1Brand = vinResponse.errorMessage;
        //const storeLink = 'https://accessories.' + vinT1Brand.toLowerCase() + '.com/';
        const locationURL = window.location.href;
        let storeLink = "";

        if (locationURL.indexOf("chevrolet") !== -1) {
            storeLink = locationURL.replace("chevrolet", vinT1Brand.toLowerCase());
        } else if (locationURL.indexOf("buick") !== -1) {
            storeLink = locationURL.replace("buick", vinT1Brand.toLowerCase());
        } else if (locationURL.indexOf("gmc") !== -1) {
            storeLink = locationURL.replace("gmc", vinT1Brand.toLowerCase());
        } else if (locationURL.indexOf("cadillac") !== -1) {
            storeLink = locationURL.replace("cadillac", vinT1Brand.toLowerCase());
        }

        vinErrorMsg = (
            <Translation>
                {(t) => (
                    <span>
                        {t(vinErrorLabel, { currentT1Brand, vinT1Brand })}
                        <a href={storeLink} className="ac-store-link ac-accessible-link-underline">
                            {vinT1Brand} Accessories
                        </a>
                    </span>
                )}
            </Translation>
        );
    }
    // handle other vin errors
    if (vinErrorLabel == "LABEL_COMMON_ENTER_VIN_ERROR" || vinErrorLabel == "LABEL_VIN_ACCESSORIES_UNAVAILABLE_ERROR" || vinErrorLabel == "LABEL_CHECK_VIN_NONGM_ERROR" ) {
        vinErrorMsg = <Translation>{(t) => <span>{t(vinErrorLabel)}</span>}</Translation>;
    }
    //don't show error message after user makes changes to input field
    const clearVinError = () => {
        if (
            vinResponse.errorMessage ||
            vinErrorLabel == "LABEL_BRAND_ERROR" ||
            vinErrorLabel == "LABEL_COMMON_ENTER_VIN_ERROR" ||
            vinErrorLabel == "LABEL_CHECK_VIN_NONGM_ERROR" ||
            vinErrorLabel == "LABEL_VIN_ACCESSORIES_UNAVAILABLE_ERROR"
        ) {
            store.dispatch(resetVIN());
        }
        return null;
    };
  
    const vinError = (value) => (value && vinErrorMsg !== "" ? vinErrorMsg : undefined);
    /****************************************/
    /* END HANDLE VIN ERRORS - Error Msgs
  /****************************************/

    return (
        <Translation setDisplayPartial={setDisplayPartial}>
            {(t) => (
                <>
                <div>
                        {vehicleInfoData.model && newFitmentFlag && displayPartial && <div id="partial-fit-row" className="partial-fitment-updated-dropdrown">
                            <a id="ac-change-vehicle" className="stat-text-link partial-change-vehicle" data-dtm = {"shopping:" + vehicleInfoData.year + " " + vehicleInfoData.model} onClick={() => { resetPartialFit() }}>{t("LABEL_COMMON_CHANGE_VEHICLE")}</a>
                            <h4>{t("LABEL_PARTIAL_FITMENT_TITLE")} {vehicleInfoData.year} {vehicleInfoData.model}, {vehicleInfoData.vehicleConfig.body}</h4>
                            <p>{t("LABEL_PARTIAL_FITMENT_SUBTITLE")}</p>
                        </div>}
                    <div
                        className={
                            "ac-vehicle-select-fields ac-vehicle-select-fields" +
                            ((!vehicleInfoData.model || homeSearchPageNames.has(getPageName()) || (pdpSearchPageNames.has(getPageName()) && window.location.pathname != "/product/search" ))
                                ? "-two-columns columns"
                                : "-partial columns")
                        }
                    >
                        {!displayPartial && <div className={vehicleSelectDropdownClass()}>
                            <Select
                                defaultValue={t("LABEL_COMMON_CHOOSE_YEAR")}
                                value={selectedYear}
                                options={years}
                                onClick={props.handleYearChange}
                            />
                        </div>}

                        {AppSettings.isT3 && !displayPartial && <MakeSelect />}
                        {!displayPartial && <div className={vehicleSelectDropdownClass("ac-ymmModel")}>
                            <ModelSelect
                                disabled={isModelSelectDisabled()}
                                value={selectedModel}
                                year={selectedYear}
                                yearsToModels={yearsToModels}
                                showError={showLoadModelsError}
                                onClick={props.handleModelChange}
                            />
                        </div>}
                        {displayPartial && !vehicleInfoData.model && <div className={vehicleSelectDropdownClass()}>
                            <Select
                                defaultValue={t("LABEL_COMMON_CHOOSE_YEAR")}
                                value={selectedYear}
                                options={years}
                                onClick={props.handleYearChange}
                            />
                        </div>}

                        {displayPartial && !vehicleInfoData.model && <div className={vehicleSelectDropdownClass("ac-ymmModel")}>
                            <ModelSelect
                                disabled={isModelSelectDisabled()}
                                value={selectedModel}
                                year={selectedYear}
                                yearsToModels={yearsToModels}
                                showError={showLoadModelsError}
                                onClick={props.handleModelChange}
                            />
                        </div>}
                        <VehicleConfig
                            handleSearchAccessories={props.handleSearchAccessories}
                            stylingDropdown={stylingDropdown}
                            setStylingDropdown={setStylingDropdown}
                            vehicleSelectDropdownClass={vehicleSelectDropdownClass}
                        />
                    </div>
                 </div>
                    {(!vehicleInfoData.year || homeSearchPageNames.has(getPageName()) || (pdpSearchPageNames.has(getPageName()) && window.location.pathname != "/product/search" )) && 
                    <div className="ac-home-vin columns">
                        <div id="q-separator-container">
                            <div className="q-separator-vin"></div>
                        </div>
                        <div id="ac-vin-text-search-container">
                            <div
                                id="ac-vin-text-separator"
                                className="grid-column-alignment-left q-grid-row-new-mobile columns ac-vehicle-select-or small-12 medium-12 large-3 xlarge-3 columns"
                            >
                                <div id="ac-vin-text-container">
                                    <span id="ac-vin-text" className="q-text q-body2">
                                        {t("LABEL_COMMON_VEHICLE_SELECT_OR_VIN")}
                                    </span>
                                    <div>
                                        <a
                                            id="ac-how-to-vin"
                                            className="q-mod q-mod-button q-link q-button-small q-button-padding-none none-margin stat-text-link  ac-vin-button ac-accessible-link-underline2"
                                            onClick={props.toggleVinInstructions}
                                            data-dtm="search option"
                                            data-dtm-2="vin search"
                                        >
                                            {t("LABEL_COMMON_HOW_TO_FIND_VIN")} {showVinInstructions}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <form
                                id="ac-vin-form"
                                className="small-12 medium-12 large-9 xlarge-9 columns"
                                onSubmit={
                                    typeof props.handleSubmit == "function"
                                        ? props.handleSubmit(props.handleSearchAccessories)
                                        : undefined
                                }
                                onChange={clearVinError}
                            >
                                <div
                                    id="ac-enter-vin"
                                    className="grid-column-alignment-left q-grid-row-new-mobile columns ac-ymmModel ac-vin-input small-12 medium-12 columns"
                                >
                                    <Field
                                        name="vin"
                                        type="text"
                                        maxLength={17}
                                        // react throws error on this custom attribute
                                        // doesn't mind maxLength={17}, but hates -> showError={showLoadVinError}
                                        // using 'field' component from redux-form,
                                        // so below is work araound for now.
                                        showerror={showLoadVinError.toString()}
                                        component={renderField}
                                        label={t("LABEL_COMMON_ENTER_VIN")}
                                        validate={[validate.minLength8, validate.maxLength17, vinError]}
                                        className="stat-input-field"
                                        data-dtm="search option:vin search"
                                        data-dtm-2="vin search"
                                    />
                                    <div className="ac-select-vehicle-small-only">
                                        <VinInstructions showVinInstructions={showVinInstructions} />
                                    </div>
                                </div>
                                <div
                                    id="ac-add-vin"
                                    className="grid-column-alignment-left q-grid-row-new-mobile columns ac-vehicle-select-btn small-12 medium-12 columns"
                                >
                                    <button
                                        id="ac-vin-search-btn"
                                        className="stat-button-link ac-shop-btn gb-primary-button:focus:active "
                                        type="submit"
                                        onClick={() => {
                                            props.handleSearchAccessories("vin");
                                        }}
                                        disabled={isVehicleSelectButtonDisabled()}
                                        data-dtm="search option:vin search"
                                    >
                                        {t("LABEL_COMMON_VEHICLE_SEARCH")}
                                        {showLoadingBtn && vinErrorLabel === "" && props.enteredVin && (
                                            <Spinner isLoadingBtnEnabled="true" />
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    }    
                </>
            )}
        </Translation>
    );
};

export default reduxForm({
    form: "VehicleSelectForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(VehicleSelectForm);
