/** COMPONENT NAME : SHOPPINGCARTWRAPPER (WRAPPING EVERYTHING TOGETHER) ***/

/** IMPORT STATEMENT **/
import React from 'react';
import CartOrderLineItemHeader from '../ItemHeader/ItemHeader'
import CartButtons from '../CartButton/CartButtons'
import ProductLineItem from "../ProductLineItem/ProductLineItem";
import SubtotalComponent from '../Subtotal/SubtotalComponent';
import CartItemTitle from '../LineItemTitle/LineItemTitle'
import PropTypes from "prop-types";
import { ContinueShoppingLink } from "./ContinueShoppingLink";


/** COMPONENT **/
const CartWrapper = (props) => {

    /** VARIABLES **/
    const orderInCart = props.data;
    const orderInfo = orderInCart.orderInfo;
    const totalSubTotal = orderInfo.subtotal;
    const orderId = orderInfo.orderId;
    const recordSetTotal = orderInfo.recordSetTotal;


    /** PREPARE CART DATA **/
    let vehicleData = '';
    if (orderInCart) {
        vehicleData = orderInCart.vehicles.map((items, index) => {
            return {
                vehicleIndex: index + 1,
                vehicleInformation: items.vehiclesInfo,
                items: items.orderItems,
                vin: items.vinNumber
            }
        })
    }

    /** SETTING UP THE URL FOR CONTINUE SHOPPING CART BUTTON, IT SHOULD BE THE LAST VIN/YMM OF THE CART ITEM **/
    const vehicleLength = vehicleData.length;
    const lastVehicle = vehicleData[vehicleLength - 1];
    let continueShoppingUrl = ContinueShoppingLink(lastVehicle);

    return <div className="ac-shoppingCartWrapper">
        <div className="row columns ac-nopadding-x">
            {/** SHOPPING CART ITEM TITLE **/}
            <CartItemTitle itemCount={recordSetTotal} />

        </div>
        {
            /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY VEHICLE INFORMATION  **/
            vehicleData.map((item, index) => {
                return <div className="row" key={index}>
                    <div >
                        <CartOrderLineItemHeader data={item.vehicleInformation} count={item.vehicleIndex} vinNumber={item.vin} />
                    </div>
                    <div className="row">
                        {
                            /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY EACH LINE ITEM **/
                            item.items && item.items.map((subItem, index) => {
                                return <div key={subItem.items[0].productId} className="ac-shoppingLineItemIndex">
                                    {
                                        /** ADDING HORIZONTAL LINE IF THERE ARE IS MORE THAN ONE ITEM **/
                                        index > 0 && <hr />
                                    }
                                    <ProductLineItem data={subItem.items[0]} vin={item.vin} vehicleInformation={item.vehicleInformation} mainItem={subItem} items={subItem.items} component="cartWrapper" />

                                </div>
                            })
                        }
                    </div>

                </div>
            })
        }
        {!props.isOrderSummaryFlagEnabled && (
            <>
                <div className="row">
                    <div className="small-12 medium-12 large-12 xlarge-12 ac-nopadding-x">
                        {/** SHOPPING CART ITEM SUBTOTAL **/}
                        <SubtotalComponent subTotal={totalSubTotal} />
                    </div>
                </div>

                <div className="row">
                    <div className="small-12 medium-12 large-12 xlarge-12 ac-nopadding-x">
                        {/** SHOPPING CART  BUTTONS **/}
                        <CartButtons orderInCart={orderInCart} orderNumber={orderId} url={continueShoppingUrl} />
                    </div>
                </div>
            </>
            )}
    </div>

};

/** PROP TYPES VALIDATION **/
CartWrapper.proptype = {
    data: PropTypes.object.isRequired
};
export default CartWrapper
