import * as type from "./SearchLinkActionType";

export const setYear = (year) => {
    const action = {
        type: type.SET_YEAR,
        year: year
    }
    return action;
}

export const setMake = (make) => {
    const action = {
        type: type.SET_MAKE,
        make: make
    }
    return action;
}

export const setModel = (model) => {
    const action = {
        type: type.SET_MODEL,
        model: model
    }
    return action;
}

export const setCategory = (name, id) => {
    const action = {
        type: type.SET_CATEGORY,
        name: name,
        id: id
    }
    return action;
}

export const setVin = (vin) => {
    const action = {
        type: type.SET_VIN,
        vin: vin
    }
    return action;
}