import React from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';

/**************************************************************/
// INSTRUCTIONAL TEXT DISPLAYED ABOVE FORMS
/**************************************************************/
// NOTE: Local key interpoliation param (in GMResourcesBundle.js) 
//       has to be 'name' (e.g. "LABEL_ACCOUNT_REGISTRATION_EMAIL": "Email: {{ name }}")
//
// EXAMPLE USE:
// <FormInstructions
//    instructions={[
//        { text: "LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUBHEADER1" },
//        { text: 'LABEL_ACCOUNT_REGISTRATION_EMAIL', name: email, bold: true },
//        { text: "LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUBHEADER2" }
//    ]} />
//
// RESULT:
// An email verification has been sent to:
// email: <b>niki.loughney@gm.com</b>
// Please check your email inbox blah blah

FormInstructions.propTypes = {
    text: PropTypes.string, // paragraph text
    name: PropTypes.string, // params used for interpoliation in translations (e.g. email/username)
    bold: PropTypes.bool, // bool value used to bold paragraph text
};

function FormInstructions(props) {

    const instructions = props.instructions.map((item, key) => {
        const text = item.text;
        const name = item.name;
        const bold = item.bold;

        return (
            <Translation key={key}>{(t) => (
                <p>
                    {bold ? <strong>{t(text, { name })}</strong> : <span>{t(text, { name })}</span>}
                    <br />
                    <br />
                </p>
            )}</Translation>)
    })

    return (
        <div className="q-text">
            {instructions}
        </div>
    )
}

export default FormInstructions;

