import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import AppSettings from "../../../core/AppSettings";
import SharedSpinner from "../../../shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import {
    YMM_YEAR,
    YMM_MAKE,
    YMM_MODEL,
    YMM_BODY,
    YMM_WHEEL,
    YMM_DRIVE,
    YMM_ENGINE,
    YMM_TRIM,
    YMM_CHOOSE_YEAR,
    YMM_CHOOSE_MAKE,
    YMM_CHOOSE_MODEL,
    YMM_CHOOSE_BODY,
    YMM_CHOOSE_DRIVE,
    YMM_CHOOSE_ENGINE,
    YMM_CHOOSE_TRIM
} from "../InlineFitment/InlineSharedConstants";
import InlineExtendDropdown from "../InlineFitment/InlineExtendDropdown";

const IS_SINGLE_MAKE_SITE = !AppSettings.isGenericPartsSite;
const TOGGLED_CLASSNAME = (isMakeInput) =>
    isMakeInput && IS_SINGLE_MAKE_SITE ? "ymm-group ac-skip-make" : "ymm-group";

//returns "Choose Year/Make/Model as headers on inline fitment step 1 YMM"
const inlineYMMHeader = (item, t) => {
    let header;
    switch (item.id) {
        case YMM_YEAR:
            header = t(YMM_CHOOSE_YEAR);
            break;
        case YMM_MODEL:
            header = t(YMM_CHOOSE_MODEL);
            break;
        case YMM_MAKE:
            header = t(YMM_CHOOSE_MAKE);
            break;
        case YMM_BODY:
            header = t(YMM_CHOOSE_BODY);
            break;
        case YMM_DRIVE:
            header = t(YMM_CHOOSE_DRIVE);
            break;
        case YMM_TRIM:
            header = t(YMM_CHOOSE_TRIM);
            break;
        case YMM_ENGINE:
            header = t(YMM_CHOOSE_ENGINE);
            break;
        case YMM_WHEEL:
            header = item.title; //this value can be wheel type or box length depending on the fitment
            break;
    }

    return !AppSettings.isGenericPartsSite && item.id === YMM_MAKE ? null : (
        <h5 className="inline-fitment-headers gb-body1">{header}</h5>
    );
};

const InlineSharedDropdown = (props) => {
    const { t } = useTranslation();
    const YMMData = props.ymmData;
    const filterOptions = (item, subItem) => {
        switch (item.id) {
            case YMM_YEAR:
                return subItem.YEAR;
            case YMM_MAKE:
                return subItem.MAKENAME;
            case YMM_MODEL:
                return subItem.MODELNAME;
            case YMM_BODY:
                return subItem.BODY_LABEL;
            case YMM_WHEEL:
                return subItem.WHEEL_LABEL;
            case YMM_TRIM:
                return subItem.SUBMODEL_NAME;
            case YMM_DRIVE:
                return subItem.DRIVE_TYPE_NAME;
            case YMM_ENGINE:
                return subItem.ENGINE_LABEL;
            default:
                return "";
        }
    };

    const inlineFitmentDropdown = (
        <React.Fragment>
            {YMMData.length &&
                YMMData.map((item, index) => {
                    return item.show ? (
                        <div id="inline-fit-dropdown" key={index}>
                            {inlineYMMHeader(item, t)}
                            <Dropdown className={TOGGLED_CLASSNAME(item.id === "YMM_MAKE")} key={index}>
                                <Dropdown.Toggle
                                    className="q-form-dropdown ymm-dropdown"
                                    key={index}
                                    id={item.id}
                                    disabled={item.disabled}
                                >
                                    <span className="ymm-dropdown-title gb-body2" key={index}>
                                        {
                                            /** * Display Spinner when condition meets ***/
                                            item.showSpinner && (
                                                <div className="ymm-spinner">
                                                    <SharedSpinner size={"sm"} />
                                                </div>
                                            )
                                        }
                                        {item.title}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="ymm-dropdown-menu" key={item.id}>
                                    {item.options.length > 0 &&
                                        item.options.map((subItem, index) => {
                                            const options = filterOptions(item, subItem);
                                            return (
                                                <Dropdown.Item
                                                    key={index}
                                                    className="ymm-dropdown-item gb-body2"
                                                    onClick={(e) => {
                                                        item.onClick(e);
                                                    }}
                                                    value={options}
                                                    data-object={JSON.stringify(subItem)}
                                                >
                                                    {options}
                                                </Dropdown.Item>
                                            );
                                        })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    ) : null;
                })}
        </React.Fragment>
    );
    
    if (props.inlineFitmentYMM) {
        return (
            <InlineExtendDropdown
                YMMData={YMMData}
                filterOptions={filterOptions}
                neededFitment={props?.neededFitment}
                catEntryID={props.catEntryID}
                inlineHeader={inlineYMMHeader}
                toggledClass={TOGGLED_CLASSNAME}
                steps={props.steps}
            />
        );
    }

    if (props.inlineFitment) {
        return inlineFitmentDropdown;
    }
};

export default InlineSharedDropdown;
