// FormNormalize contains normalization functions for redux-form fields. A 
// normalizer is a function that gets run every time a value is changed that 
// can transform the value before storing.

// Normalizers are passed five parameters::
//   value - The value of the field on which you have placed the normalizer
//   previousValue - The value of the field on which you have placed the normalizer before the most recent change
//   allValues - All the values in the form, with the current field value set
//   previousAllValues - All the values in the form before the current field is changed
//   name - The name of the field being normalized


/**************************************************************/
// Normalizer functions
/**************************************************************/

// Normalizes phone numbers.
export const normalizePhone = value => {
    if (!value) {
        return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 7) {
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
        6,
        10
    )}`
}

// Normalizes phone numbers.
export const normalizePostalCode = value => {
    if (!value) {
        return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 5) {
        return onlyNums
    }

    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`
}
