/***** COMPONENT NAME : DEALER LOCATOR WRAPPER (Parent of all small components inside the Dealer Locator ) *****/


/*** IMPORT STATEMENT ***/
import React from 'react';
import {Translation} from 'react-i18next';
import ZipCodeInputComponent from "../ZipCodeInput/ZipCodeInputComponent";
import DealerLocatorButtons from "../DealerLocatorButtons/DealerLocatorButtons";
import DealerListWrapper from "../DealerListWrapper/DealerListWrapper";
import Spinner from '../../../../shared/Spinner/Spinner'
import DealerMapWrapper from "../DealerMapWrapper/DealerMapWrapper";
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';




const DealerLocatorWrapper = (props)=>{
    let bac = '';
    if(props.dealerLocatorData.selectedBac !== undefined){
        bac = props.dealerLocatorData.selectedBac;
    }
    const showSpinner = props.dealerLocatorData.showSpinner;
    const dealerMapData = props.dealerLocatorData.dealerMapData;
    const dealers = props.dealerLocatorData.dealers;
    let showDealerList = false;
    if(dealers !== undefined && dealers.length){
        showDealerList = true
    }



    /** FOR LARGER DEVICES **/
    const desktopExperience = <Translation>{(t) => (<div className="row ac-dealerLocatorWrapperDesktop">
        {/********** ZIP CODE LABEL *************/}
        {/*<div className="small-12 ac-zipCodeLabel">
            <label className="q-label2">{t("LABEL_COMMON_ZIP")}</label>
        </div>*/}

        {/********** LEFT CONTENT(DEALER LIST & ZIP CODE INPUT) *************/}
        <div className="small-5 columns ac-dealerLocatorMapLeft">
            <ZipCodeInputComponent zipCode={props.dealerLocatorData.zipCode}
                                   country={props.dealerLocatorData.country}
                                   state={props.dealerLocatorData.state}/>
            <hr/>

            {
                /*** SPINNER COMPONENT **/
                showSpinner && <Spinner/>
            }
            {
                /** DEALER LIST WRAPPER COMPONENT **/
                (!showSpinner && showDealerList) && <DealerListWrapper dealers={props.dealerLocatorData.dealers}/>
            }
            <hr/>
            {
                    /** DEALER LOCATOR BUTTONS COMPONENT **/
                 (!showSpinner && showDealerList) && <DealerLocatorButtons selectedDealer={bac} showLoadingBtn={props.dealerLocatorData.showLoadingBtn}/>
            }
        </div>

        {/********** RIGHT CONTENT (GOOGLE MAPS) *************/}
        <div className="small-7 columns">
            <DealerMapWrapper mapData={dealerMapData} dealerInfo={dealers}/>
        </div>

    </div>)}
            </Translation>;


    /** FOR SMALLER DEVICES **/
    const mobileExperience = <Translation>{(t) => (<div className="row ac-dealerLocatorWrapperMobile">

                                    {/********** ZIP CODE LABEL *************/}
                                  {/*  <div className="small-12 ac-zipCodeLabel">
                                        <label className="q-label2">{t("LABEL_COMMON_ZIP")}</label>
                                    </div>*/}

                                    <div className="small-12">

                                        {/********** LEFT CONTENT(DEALER LIST & ZIP CODE INPUT) *************/}
                                        <ZipCodeInputComponent zipCode={props.dealerLocatorData.zipCode}
                                                               country={props.dealerLocatorData.country}
                                                               state={props.dealerLocatorData.state}/>
                                        <span className="ac-hr"> <hr/></span>
                                    </div>
                                    <div className="small-12">

                                        {/********** MAPS (GOOGLE MAPS) *************/}
                                        <DealerMapWrapper mapData={dealerMapData} dealerInfo={dealers}/>
                                    </div>

                                    <div className="small-12">
                                        <hr/>
                                        {
                                            /*** SPINNER COMPONENT **/
                                            showSpinner && <Spinner/>
                                        }
                                        {
                                            /** DEALER LIST WRAPPER COMPONENT **/
                                            showDealerList && <DealerListWrapper dealers={props.dealerLocatorData.dealers}/>
                                        }

                                    </div>
                                    <div className="small-12">
                                        <hr/>
                                        {
                                            /** DEALER LOCATOR BUTTONS COMPONENT **/
                                            showDealerList && <DealerLocatorButtons selectedDealer={bac} />
                                        }
                                    </div>
                                </div>)}</Translation>



    return (
        <Translation>
            {
                (t)=>(<div className="ac-dealerLocatorWrapper">
                        <div className="row">
                            <div className="small-12 ac-dealerLocator_header">
                                <h4> {t("LABEL_CHECKOUT_DEALER_LOCATOR_LOCATE_YOUR_DEALER")}</h4>
                            </div>
                            <hr/>
                        </div>

                        <div>
                           <MediaQuery query="(max-width: 59.99em)">
                                {mobileExperience}
                            </MediaQuery>
                            <MediaQuery query="(min-width: 60em)">
                                {desktopExperience}
                            </MediaQuery>

                        </div>
                </div>

                )
            }
        </Translation>
    )
};

/*** PROP TYPE TESTING ***/

DealerLocatorWrapper.propTypes = {
    dealerLocatorData:PropTypes.object.isRequired
};

export default DealerLocatorWrapper