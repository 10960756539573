import React from 'react';
import { Translation } from 'react-i18next';
import SideNavigator from '../../shared/SideNavigator/SideNavigator';
import { Route } from 'react-router-dom';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import HistoryOrderDetails from './HistoryOrderDetails/HistoryOrderDetails';
import AccountOverview from './AccountOverview/AccountOverview';
import MyVehicles from './MyVehicles/MyVehicles';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import PaymentMethodAdyen from "./PaymentMethod/PaymentMethodAdyen";
import vaultConstants from "./../../../config/vault_constants";
import AppSettings from "../../core/AppSettings";

/**
 * UserProfile is responsible for displaying the correct Profile form to the user, depending on which URL they're visiting
 * 
 */


function UserProfile(props) {
    const currentPath = props.location.pathname;
    const displayNav = currentPath != "/profile";
    //properties to pass into sideNav to determine links that show
    const sideNavigatorProps = {
        navLocations: [
            {
                displayName: "Account Overview",
                value: "/profile",
                isActive: currentPath == "/profile"
            },
            {
                displayName: "Order History",
                value: "/profile/history",
                isActive: currentPath.includes("/profile/history")
            },
            {
                displayName: "Profile & Settings",
                value: "/profile/settings",
                isActive: currentPath.includes("/profile/settings")
            },
            {
                displayName: "Payment Methods",
                value: "/profile/payment",
                isActive: currentPath.includes("/profile/payment")
            },
            {
                displayName: "My Vehicles",
                value: "/profile/vehicles",
                isActive: currentPath.includes("/profile/vehicles")
            }
        ]
    }
    return (
        <Translation>
            {
                (t) => (
                    <Route path='/profile/payment' component={ PaymentMethodAdyen } key="paymentmethod" />
                    // TODO: If wallet is ever integrated with other user profile items, it's already been mocked out
                    // below
                    // <div className="ac-user-profile row q-gridbuilder small-margin gridfullwidth ac-small-sidepadding">
                    //     {displayNav &&
                    //         <div>
                    //             {/* TODO: hiding the side nav for now since wallet will be integrated 
                    //             without other profile things at least for now */}
                    //             {/* <div className="small-12 medium-12 large-12 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                    //                 <SideNavigator navigation={sideNavigatorProps}></SideNavigator>
                    //             </div> */}


                    //             <div className="small-12 medium-12 large-12 xlarge-9 columns">
                    //                 <Route exact={true} path='/profile' component={AccountOverview} key="accountOverview" />
                    //                 <Route exact={true} path='/profile/history' component={OrderHistory} key="userprofilehistory" />
                    //                 <Route path='/profile/settings' component={ProfileSettings} key="userprofilesettings" />
                    //                 <Route path='/profile/history/details' component={HistoryOrderDetails} key="userprofilehistorydetails" />
                    //                 <Route path='/profile/vehicles' component={MyVehicles} key="userprofilevehicles" />
                    //                 <Route path='/profile/payment' component={PaymentMethod} key="paymentmethod" />
                    //             </div>
                    //         </div>
                    //     }
                    //     {!displayNav &&
                    //         <div className="small-12 columns">
                    //             <Route exact={true} path='/profile' component={AccountOverview} key="accountOverview" />
                    //             <Route exact={true} path='/profile/history' component={OrderHistory} key="userprofilehistory" />
                    //             <Route path='/profile/settings' component={ProfileSettings} key="userprofilesettings" />
                    //             <Route path='/profile/history/details' component={HistoryOrderDetails} key="userprofilehistorydetails" />
                    //             <Route path='/profile/vehicles' component={MyVehicles} key="userprofilevehicles" />
                    //             <Route path='/profile/payment' component={PaymentMethod} key="paymentmethod" />
                    //         </div>
                    //     }
                    // </div>
                )
            }
        </Translation>
    )
}

export default UserProfile
