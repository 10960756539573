import axios from "axios";
import AppSettings from "../../../core/AppSettings";
import { authenticate, hasAuthenticationError } from "../../../shared/Authenticator/Authenticator";
import { checkPartialAuthenticationError } from "../../../shared/Utils/Utils";
import { getCartOrderSummaryFFStatus } from "../../../shared/CartOrderSummary/CartOrderSummary";

const gmUpdateOrderItemURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/update_order_item?responseFormat=json`;
const getShippingRatesUrl = `/wcs/resources/store/${AppSettings.storeId}/GMCart/{orderId}/getShippingRates/{orderItemId}?responseFormat=json`;
const gmDeleteOrderItemURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/delete_order_item?responseFormat=json`;
const RIGHT_ORDER_SUMMARY_FF = getCartOrderSummaryFFStatus();
// === Get Cart Data ===
export const getCartResponse = () => {
    const cartUrl = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/getCartData?nocache=true`;
    return axios.get(cartUrl, { headers: { "Cache-Control": "no-cache" } }).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.log("****Error occur while trying to fetch cart data => ", error);
            throw error;
        }
    );
};

// Make request to change order item quantity
export const getOrderItemChangeQuantityResponse = (payload, addFromCartQueryParam, authData) => {
    const gmUpdateOrderItemCall = gmUpdateOrderItemURL;
    if (addFromCartQueryParam) {
        payload.x_calculateOrder = "1";
        payload.x_calculationUsage = "-1,-2,-5,-6,-7";
    }

    try {
        return cartPostAuthenticated(gmUpdateOrderItemCall, payload, authData);
    } catch (error) {
        console.log("Error occurred while trying to change the quantity => ", error);
        throw error;
    }
};

export const getCartItemDeleteResponse = (payload, addFromCartQueryParam, authData) => {
    const url = gmDeleteOrderItemURL;
    if (addFromCartQueryParam) {
        payload.calculateOrder = "0";
    }

    try {
        return cartPostAuthenticated(url, payload, authData);
    } catch {
        console.log("*** Error occurred while trying to delete item from cart => ", error);
        throw error;
    }
};

/**
 * Fetch the get shipping rates response to populate delivery options modal on Cart.
 * @param orderId
 * @param orderItemId
 * @param bac - Dealer's bac. This was added as a backup in case the backend lost the bac on their end
 * @return {Promise<AxiosResponse<any>>}
 */
export const getShippingRatesResponse = (orderId, orderItemId, bac="") => {
    let url = getShippingRatesUrl.replace("{orderId}", orderId).replace("{orderItemId}", orderItemId);
    bac ? url += `&bac=${bac}` : null;
    return axios
        .get(url)
        .then((response) => response.data)
        .catch((error) => {
            console.log("*** Error occurred while trying to fetch shipping rates => ", error);
            throw error;
        });
};

export const getOrderItemChangeDeliveryMethodResponse = (shipModeId, orderItemId, quantity, carrierCode, orderId, type, productId) => {
    const data = {
        x_action: carrierCode,
        x_type: carrierCode,
        x_calculateOrder: "1",
        orderId: orderId,
        orderItem: [
            {
                orderItemId: orderItemId,
                xitem_shipModeId: shipModeId,
                quantity: quantity.toString(),
                productId: productId
            }
        ]
    };
    if (type ="handler") {
        return axios
        .post(gmUpdateOrderItemURL, data)
        .then((response) => { return response})
        .catch((error) => {
            console.log("*** Error occurred while trying to update delivery method => ", error);
            throw error;
        });
    } else {
        axios
        .post(gmUpdateOrderItemURL, data)
        .then((response) => response)
        .catch((error) => {
            console.log("*** Error occurred while trying to update delivery method => ", error);
            throw error;
        });
    }
};

/**
 * Cart calculate for shipping rates and core charges
 */
export const cartCalculate = () => {
    const cartCalculateUrl = `/wcs/resources/store/${AppSettings.storeId}/cart/calculate`;
// adding -1 to the payload fixes coupons not being properly applied in cart. -3 will fix core charge in cart
    let payload = {};
    if (RIGHT_ORDER_SUMMARY_FF) payload = { calculationUsageId: ["-1","-2","-3","-5","-6"], doPrice: "1", updatePrices: "1" };
    else payload = { calculationUsageId: ["-2", "-6"], doPrice: "1", updatePrices: "1" };

    return axios
        .post(cartCalculateUrl, payload)
        .then((response) => response)
        .catch((error) => {
            console.log("*** Error occurred while trying to calculate cart => ", error);
            throw error;
        });
};

/**
 * postAuthenticated() sends an authenticated POST request to the given URL
 * using the given parameters and returns the result. Only authentication
 * errors are handled here; all other data and error handling must be performed
 * by the calling function.
 * COPY OF POSTAUTHENTICATED IN AUTHENTICATOR.JS BUT REGULAR FUNCTION SINCE NOT USING REDUX IN CART
 */
export async function cartPostAuthenticated(url, data, authData, config) {
    const isUserSignedIn = authData.registrationStatus != "G";

    try {
        // Send POST request assuming current session is authenticated
        let result = await axios.post(url, data, config);
        let resultData = result.data;
        if (resultData) {
            if (hasAuthenticationError(resultData)) {
                await authenticate();
                result = axios.post(url, data, config);
            }
        }

        return result;
    } catch (error) {
        if (hasAuthenticationError(error) && isUserSignedIn && AppSettings.pageName === "Checkout") {
            await checkPartialAuthenticationError();
        } else if (error.response && error.response.status === 401) {
            await authenticate();
            return await axios.post(url, data, config);
        } else if (error.response && error.response.status === 400) {
            return error.response;
        }
    }
}
