import axios from 'axios';
import AppSettings from '../../core/AppSettings';

export const postLogin = (idToken) => {

    const url = `/wcs/resources/store/${AppSettings.storeId}/loginidentity/oauth_validate?updateCookies=true&rememberMe=true&mergeCart=true`

    let data = {
        authorizationProvider: 'gm',
        accessToken: idToken,
    }
    const header = {
        validateStatus:false
    }

    return axios.post(url, data,header);
}

export const postGuestLogout = () => {
    const url = `/wcs/resources/store/${AppSettings.storeId}/guestidentity/@self?updateCookies=true`;

    return axios.delete(url);
}

export const postUserLogout = () => {

    const headers = {
        'Cache-Control': 'no-cache', // prevent IE caching
        'Pragma': 'no-cache', // prevent IE caching
        'Expires': '-1' // prevent IE caching
    };

    const url = `/wcs/resources/store/${AppSettings.storeId}/loginidentity/@self?updateCookies=true`;

    return axios.delete(url, { headers });
}

export const getUserRegistrationStatus = () => {

    const headers = {
        'Cache-Control': 'no-cache', // prevent IE caching
        'Pragma': 'no-cache', // prevent IE caching
        'Expires': '-1' // prevent IE caching
    };

    const url = `/wcs/resources/store/${AppSettings.storeId}/person/@self`;

    return axios.get(url, { headers });

};

/*** Calling node service to check the cookies status ***/
export const nodeAuthCheck = ()=>{
    return axios.get('/node/authCheck');
};
