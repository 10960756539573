import { call, put, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import AppSettings from '../../../core/AppSettings';
import { formattedCurrency } from "../../../shared/Internationalization/FormattedNumbers";
import { loadSimilarItemsDataAsync } from "../ProductDetailsSimilarItems/ProductDetailsSimilarItemsRedux/SimilarItemsActionCreator";
import { setProductDetails, setProductDetailsFail, showAddToCartError, setPDItemPrice } from './ProductDetailsActionCreator';
import { getProductDetailsData, postAddToCart } from './ProductDetailsService';
import { updateOrderItem } from '../../Checkout/CheckoutRedux/CheckoutService';
import queryString from "query-string";
import { callForVINInfo } from '../../../shared/Session/VehicleInfo/VehicleInfoWorker';
import { callCGIToGetDataForVovIcon, setupVoVIconOnProductDetailsPage } from '../../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVWorker';
import { loadDealerPricing } from '../../../shared/Utils/Utils';
import { DEFAULT_ZIPCODE_STORAGE_ID } from '../../DealerLocator/Components/HelperFunctions';
import { gmContractCall } from "../../DealerLocator/Data/ApiService";
import store from "../../../core/Redux/Store";
import { getUserRegistrationStatus } from '../../../shared/Authentication/AuthenticationService';
import vaultConstants from '../../../../config/vault_constants';
import { authenticate } from '../../../shared/Authenticator/Authenticator';
import { getMiniCartData } from '../../App/Header/MiniCart/MiniCartRedux/MiniCartWorker';
// Default values for product page data elements. These values are set if there is any problem
// loading or parsing data.
const PRODUCT_DETAILS_DEFAULT_NAME = 'PRODUCT_DETAILS_DEFAULT_NAME';
const PRODUCT_DETAILS_DEFAULT_PART_NUMBER = 'PRODUCT_DETAILS_DEFAULT_PART_NUMBER';
const PRODUCT_DETAILS_DEFAULT_PRICE = 'PRODUCT_DETAILS_DEFAULT_PRICE';
const PRODUCT_DETAILS_DEFAULT_INSTALL_PRICE = '';
const PRODUCT_DETAILS_DEFAULT_FEATURES = [];
const PRODUCT_DETAILS_DEFAULT_DELIVERY_METHODS = [];
const PRODUCT_DETAILS_DEFAULT_WARRANTY = 'PRODUCT_DETAILS_DEFAULT_WARRANTY';
const PRODUCT_DETAILS_DEFAULT_MAINTENANCE = [];
const PRODUCT_DETAILS_DEFAULT_IMAGES = [];
const PRODUCT_DETAILS_DEFAULT_VIDEOS = [];
const PRODUCT_DETAILS_DEFAULT_DESCRIPTION = 'PRODUCT_DETAILS_DEFAULT_DESCRIPTION';
const PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING = [];
const PRODUCT_DETAILS_DEFAULT_SPECS = [];
const PRODUCT_DETAILS_DEFAULT_VOV = [];
const PRODUCT_DETAILS_DEFAULT_FAQ = [];
const PRODUCT_DETAILS_DEFAULT_UNIQUE_ID = 'PRODUCT_DETAILS_DEFAULT_UNIQUE_ID';
const PRODUCT_DETAILS_DEFAULT_BREADCRUMB = 'PRODUCT_DETAILS_DEFAULT_BREADCRUMB';
const PRODUCT_DETAILS_DEFAULT_WHEEL_PACKAGE_COMPONENTS = [];
const PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS = [];
const PRODUCT_DETAILS_DEFAULT_DOCUMENTATION = [];
const PRODUCT_DETAILS_DEFAULT_PART_TERMINOLOGY_ID = '';

// Gets the product details information from the current state.
export const getProductIdentifier = state => state.ProductDetailsReducer.identifier;
export const getProductUniqueId = state => state.ProductDetailsReducer.uniqueID;
export const getQuantity = state => state.ProductDetailsReducer.quantity;

// Gets the user's default zipcode from session if they are in T1, otherwise we don't need their zip
const getStoredZipcode = () => !AppSettings.isT3 ? window.localStorage.getItem(DEFAULT_ZIPCODE_STORAGE_ID) : "";
const getShipModeId = state => state.ProductDetailsReducer.shipModeId;

// Get vehicle information from the current state.
export const getYear = state => state.Session.year;
export const getMake = state => state.Session.make;
export const getMakeCode = state => state.Session.makeCode;
export const getModel = state => state.Session.model;
export const getVin = state => state.Session.vin;
export const getBodyStyle = state => state.Session.bodyStyle;
export const getWheelBase = state => state.Session.wheelBase;
export const getTrim = state => state.Session.trim;
export const getDriveType = state => state.Session.driveType;
export const getVehicleOptions = state => state.Session.options;
export const getVcdb = state => state.Session.vcdb;
export const getFitmentVehicleInfo = state => state.FitmentCheckReducer.fitmentVehicleInfo;
export const getOrderId = state => state.MiniCartReducer.orderId;
export const getCGIColorRPOs = state => state.VoVReducer.cgiColorCodeList;
export const getCGIPartRPOs = state => state.VoVReducer.cgiPartsRPOList;
export const getPartVoVData = state => state.ProductDetailsReducer.VoV;
export const getVehicleConfig = state => state.Session.vehicleConfig;

//Opens MiniCart Modal and times out after a certain amount of time

function openAndTimeoutModal() {
    store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: true });
    store.dispatch({ type: "RESET_LOADING_BUTTON"});
    setTimeout(() => {
        store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: false });
    }, vaultConstants.MINICART_MODAL_TIMEOUT_TARGETABLE)
}

// Called by watcher in RootSaga.js.
export function* handleLoadData() {
    // Call vin search first to get vehicle info (Bug #1511692 -
    // Need the make for product details search call since the
    // pd description is dependent on the brand, otherwise description
    // is empty. Example of pd search call below:
    // https://localhost:8084/wcs/resources/store/11205/productSearch/hood-deflector-in-smoke-12498604?brand=Chevrolet&bac=112288)
    yield callForVINInfo();

    /** Loading Dealer Price for T3 ***/
    if (AppSettings.isT3 && !Cookies.get('WC_SESSION_ESTABLISHED')) {
        yield call(loadDealerPricing);
    }

    yield callForProductDetailsData();

    yield handleVoVInitialization();

}

/* This function is responsible for a few different items
    1. Retrieving the CGI data
    2. Setting up the VoV icon (i.e. determining if the icon should show)
    3. Initializing the initial color from either
        a) The information passed from VIN data retrieval (in the Session reducer)
        b) The information from the parts call containing the part colorRPO code
    */
function* handleVoVInitialization() {
    yield callCGIToGetDataForVovIcon();
    const CGIPartRPOs = yield select(getCGIPartRPOs);

    //setup VoV Icon and initial color
    yield setupVoVIconOnProductDetailsPage(CGIPartRPOs);
}

function* callForProductDetailsData() {
    const make = yield select(getMake);

    // Calls the service to get product details data and sets the product details state based on
    // the result. If the call cannot be made, cannot be parsed, or fails, the state is set with
    // default values instead.
    let item = {};
    let breadcrumbItem = {};

    try {
        //The product identifier is needed to make the REST service call that returns product
        // details data.
        const productIdentifier = yield select(getProductIdentifier);
        const isValidProductIdentifier = isValidString(productIdentifier);

        if (isValidProductIdentifier.result == false) {
            item = getDefaultItem();
        } else {
            const res = yield call(getProductDetailsData, productIdentifier, make);
            if (res.status === 200) {
                if (res.data.errors) {
                    yield put(setProductDetailsFail(res.data.errors[0].errorMessage));
                }
                else if (res.data.catalogEntry) {
                    item = parseItem(res.data.catalogEntry, make);
                    // Remove dollar sign and commas from the price
                    let formattedPrice = item.price.replace('$', '');
                    formattedPrice = formattedPrice.replace(',', '');
                    yield put(setPDItemPrice(formattedPrice));
                    yield put(loadSimilarItemsDataAsync(item["partTerminologyId"], item["partNumber"]));
                }
                else {
                    item = getDefaultItem();
                    yield put(setProductDetailsFail(error));
                }

                if (res.data.breadCrumbTrailEntryViewExtended) {
                    const breadcrumbItems = res.data.breadCrumbTrailEntryViewExtended;
                    breadcrumbItem = parseBreadcrumb(breadcrumbItems)
                }
            } else {
                item = getDefaultItem();
                yield put(setProductDetailsFail(error));
            }
        }

        yield put(setProductDetails(item, breadcrumbItem));
    }
    catch (error) {
        console.warn('callForProductDetailsData, error: ' + error);
        item = getDefaultItem();
        yield put(setProductDetails(item));
        yield put(setProductDetailsFail(error));
    }
}

const getDefaultItem = () => {
    // Returns an item with all default values.
    let item = {};
    item['name'] = PRODUCT_DETAILS_DEFAULT_NAME;
    item['partNumber'] = PRODUCT_DETAILS_DEFAULT_PART_NUMBER;
    item['price'] = PRODUCT_DETAILS_DEFAULT_PRICE;
    item['installPrice'] = PRODUCT_DETAILS_DEFAULT_INSTALL_PRICE;
    item['images'] = PRODUCT_DETAILS_DEFAULT_IMAGES;
    item['videos'] = PRODUCT_DETAILS_DEFAULT_VIDEOS;
    item['features'] = PRODUCT_DETAILS_DEFAULT_FEATURES;
    item['availableDeliveryMethods'] = PRODUCT_DETAILS_DEFAULT_DELIVERY_METHODS;
    item['warranty'] = PRODUCT_DETAILS_DEFAULT_WARRANTY;
    item['maintenance'] = PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
    item['description'] = PRODUCT_DETAILS_DEFAULT_DESCRIPTION;
    item['troubleshooting'] = PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
    item['specs'] = PRODUCT_DETAILS_DEFAULT_SPECS;
    item['VoV'] = PRODUCT_DETAILS_DEFAULT_SPECS;
    item['faq'] = PRODUCT_DETAILS_DEFAULT_FAQ;
    item['uniqueID'] = PRODUCT_DETAILS_DEFAULT_UNIQUE_ID;
    item['breadcrumb'] = PRODUCT_DETAILS_DEFAULT_BREADCRUMB;
    item['wheelPackageComponent'] = PRODUCT_DETAILS_DEFAULT_WHEEL_PACKAGE_COMPONENTS;
    item['packagingSpecification'] = PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
    item['documentation'] = PRODUCT_DETAILS_DEFAULT_DOCUMENTATION;
    item['partTerminologyId'] = PRODUCT_DETAILS_DEFAULT_PART_TERMINOLOGY_ID;
    return item;
}

const parseItem = (record, make) => {
    // Returns an item with product details parsed from the given record.
    let item = {};

    const isValidRecord = isValidObject(record);
    if (isValidRecord.result == true) {
        // Parses top-level elements if record is valid.
        item['name'] = parseName(record);
        item['partNumber'] = parsePartNumber(record);
        item['price'] = parsePrice(record);
        item['installPrice'] = parseInstallPrice(record);
        item['specs'] = parseSpecs(record);
        item['VoV'] = parseViewOnVehicle(record);
        item['availableDeliveryMethods'] = parseAvailableDeliveryMethods(record);
        item['uniqueID'] = parseUniqueID(record);
        item['breadcrumb'] = parseBreadcrumb(record);
        item['partTerminologyId'] = parsePartTerminologyId(record);


        /** used for wheel package ***/
        if (record.hasOwnProperty('components')) {
            item['wheelPackageComponent'] = parseComponents(record);
        }
        else
            item['wheelPackageComponent'] = PRODUCT_DETAILS_DEFAULT_WHEEL_PACKAGE_COMPONENTS;

        /** Used for attachment on rest call response **/
        if (record.hasOwnProperty('attachments') && (record.attachments.length)) {
            item['documentation'] = parseDocumentation(record);
        }
        else
            item['documentation'] = PRODUCT_DETAILS_DEFAULT_DOCUMENTATION;


        const userData = record.UserData;
        const isValidUserData = isValidObject(userData);
        if (isValidUserData.result == true && userData.length > 0) {
            // Parses userdata elements if userdata is valid.
            item['images'] = parseImages(userData[0]);
            item['videos'] = parseVideos(userData[0]);
            item['features'] = parseFeatures(userData[0]);
            item['warranty'] = parseWarranty(userData[0]);
            item['maintenance'] = parseMaintenance(userData[0]);
            item['description'] = parseDescription(userData[0], make);
            item['troubleshooting'] = parseTroubleshooting(userData[0]);
            item['faq'] = parseFAQ(userData[0]);

            /** Only some items has package contents and packaging Information **/
            if ((userData[0].hasOwnProperty('PackagingInformation'))) {
                item['packagingSpecification'] = parsePackagingSpecification(userData[0]);
            }
            else
                item['packagingSpecification'] = PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
        } else {
            // Returns default values for userdata elements if userdata is not valid.
            item['images'] = PRODUCT_DETAILS_DEFAULT_IMAGES;
            item['videos'] = PRODUCT_DETAILS_DEFAULT_VIDEOS;;
            item['features'] = PRODUCT_DETAILS_DEFAULT_FEATURES;
            item['warranty'] = PRODUCT_DETAILS_DEFAULT_WARRANTY;
            item['maintenance'] = PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
            item['description'] = PRODUCT_DETAILS_DEFAULT_DESCRIPTION;
            item['troubleshooting'] = PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
            item['faq'] = PRODUCT_DETAILS_DEFAULT_FAQ;
            item['packagingSpecification'] = PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
        }
    } else {
        // Returns default item if record is not valid.
        item = getDefaultItem();
    }
    return item;
};

/** PARSING WHEEL PACKAGE DATA **/
const parseComponents = (record) => {
    let customComp = [];
    const wheelPackageData = record.components;

    const isValidComponent = isValidObject(record.components);

    if (isValidComponent.result === false) {
        return customComp;
    }
    else {
        if (wheelPackageData.length) {
            wheelPackageData.map((item) => {
                let wheelArray = {};
                wheelArray['name'] = item.name;
                wheelArray['partNumber'] = item.partNumber;
                wheelArray['quantity'] = item.quantity;
                customComp.push(wheelArray);
            })
        }

        return customComp;
    }
};

/** PARSE DOCUMENTATION ***/
const parseDocumentation = (record) => {
    const attachment = record.attachments;
    const isAttachmentObject = isValidObject(attachment);
    let attachmentObject = {};

    if (isAttachmentObject.result === false) {
        return PRODUCT_DETAILS_DEFAULT_DOCUMENTATION;
    }
    else {
        if (attachment.length) {
            let documentationArray = [];
            let imageArray = [];
            let otherArray = [];
            attachment.map((item) => {
                let attachment = {};
                attachment['URL'] = item.URL;
                attachment['usage'] = item.usage;
                attachment['desc'] = item.shortdesc;
                switch (item.usage) {
                    case ("DOCUMENTS"):
                        documentationArray.push(attachment);
                        break;
                    case ("IMAGES"):
                        imageArray.push(attachment);
                        break;
                    //may not even be needed, just in case
                    default:
                        otherArray.push(attachment);
                }
            })
            attachmentObject = { "documents": documentationArray, "images": imageArray, "other": otherArray }

        }

        return attachmentObject;
    }


};

/** PARSING PACKAGING SPECIFICATION DATA **/
const parsePackagingSpecification = (data) => {
    let packageContents = '';
    let packOf = '';

    if (data.hasOwnProperty('PackageContents')) {
        packageContents = data.PackageContents;
    }
    if (data.hasOwnProperty('PackOf')) {
        packOf = data.PackOf;
    }
    const packageinformation = data.PackagingInformation['EA'] || data.packagingSpecification;
    let packSpecification = {};
    let customPackageSpecification = [];
    const isPackagingObject = isValidObject(packageinformation);
    const isPackageContentsString = isValidString(packageContents);
    const isPackOfContentsString = isValidString(packOf);



    if (isPackagingObject.result === false) {
        return PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
    }
    else if (isPackageContentsString === false) {
        return PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
    }
    else if (isPackOfContentsString === false) {
        return PRODUCT_DETAILS_DEFAULT_PACKAGING_SPECIFICATIONS;
    }
    else {
        if (packageinformation.length) {
            const item = packageinformation;

            if (item.hasOwnProperty('height') && item.height !== '') {
                packSpecification.height = item.height + ' in';
            }
            if (item.hasOwnProperty('weight') && item.weight !== '') {
                packSpecification.weight = item.weight + ' lbs';
            }
            if (item.hasOwnProperty('length') && item.length !== '') {
                packSpecification.length = item.length + ' in';
            }
            if (item.hasOwnProperty('width') && item.length !== '') {
                packSpecification.width = item.width + ' in';
            }
            if (item.hasOwnProperty('quantityEach') && item.quantityEach !== '') {
                packSpecification.quantityEach = item.quantityEach;
            }
            if (item.hasOwnProperty('GTIN') && item.GTIN !== '') {
                packSpecification.GTIN = item.GTIN;
            }


        }
        if (packageContents.length) {
            packSpecification.packageIncludes = packageContents
        }
        if (packOf.length) {
            packSpecification.packOf = packOf
        }
        customPackageSpecification.push(packSpecification);
        return customPackageSpecification;
    }
};

const parsePartTerminologyId = (record) => {
    const partTerminologyId = record.partTerminologyId;

    const isValidPartTerminologyId = isValidString(partTerminologyId);
    if (isValidPartTerminologyId.result == false) {
        return PRODUCT_DETAILS_DEFAULT_PART_TERMINOLOGY_ID;
    }

    return partTerminologyId;
}

const parseName = (record) => {
    const name = record.name;

    const isValidName = isValidString(name);
    if (isValidName.result == false) {
        return PRODUCT_DETAILS_DEFAULT_NAME;
    }

    return name;
}

const parsePartNumber = (record) => {
    const partNumber = record.partNumber;

    const isValidPartNumber = isValidString(partNumber);
    if (isValidPartNumber.result == false) {
        return PRODUCT_DETAILS_DEFAULT_PART_NUMBER;
    }

    return partNumber;
}

const parsePrice = (record) => {
    const prices = record.price;
    const isValidPriceObject = isValidObject(prices)
    if (isValidPriceObject.result == false) {
        return PRODUCT_DETAILS_DEFAULT_PRICE;
    }

    let price = PRODUCT_DETAILS_DEFAULT_PRICE;

    if (AppSettings.isT3) {
        price = parseDealerPrice(prices);
    } else {
        price = parseBrandPrice(prices);
    }

    return price;
}

const parseDealerPrice = (prices) => {
    for (let price of prices) {
        const usage = price.usage;

        if (usage === 'Offer') {
            const value = price.value;

            const isValidDisplayValue = isValidString(value);
            if (isValidDisplayValue.result == false) {
                return PRODUCT_DETAILS_DEFAULT_PRICE;
            }
            // calling currency to pass through the currency formatter
            const currency = price.currency;
            //formatting the currency with value and currency type
            const formattedValue = formattedCurrency(currency, value);

            return formattedValue;
        }
    }

    return PRODUCT_DETAILS_DEFAULT_PRICE;
}

const parseBrandPrice = (prices) => {
    for (let price of prices) {
        const usage = price.usage;

        if (usage === 'Display') {
            const value = price.value;

            const isValidDisplayValue = isValidString(value);
            if (isValidDisplayValue.result == false) {
                return PRODUCT_DETAILS_DEFAULT_PRICE;
            }
            // calling currency to pass through the currency formatter
            const currency = price.currency;
            //formatting the currency with value and currency type
            const formattedValue = formattedCurrency(currency, value);

            return formattedValue;
        }
    }

    return PRODUCT_DETAILS_DEFAULT_PRICE;
}

const parseInstallPrice = (record) => {
    const installPrice = record.installPrice;

    const isValidInstallPrice = isValidString(installPrice);
    if (isValidInstallPrice.result == false) {
        return PRODUCT_DETAILS_DEFAULT_INSTALL_PRICE;
    }
    // calling currency to pass through the currency formatter
    const currency = 'USD';
    //formatting the currency with value and currency type
    const formattedValue = formattedCurrency(currency, installPrice);

    return formattedValue;
}

const parseViewOnVehicle = (record) => {
    const vovAttributes = record.VOV;
    let vovSpecs = [];
    const isValidVoVAttributes = isValidObject(vovAttributes);
    if (isValidVoVAttributes.result === false) {
        return PRODUCT_DETAILS_DEFAULT_VOV;
    }
    for (let vovAttribute of vovAttributes) {
        const name = vovAttribute.name;
        const isValidName = isValidString(name);
        if (isValidName.result == true) {

            let value = vovAttribute.value;

            let isValidValue = isValidObject(value);
            if (!isValidValue.result) {
                isValidValue = isValidString(value);
            }

            if (isValidValue.result == true) {

                vovSpecs.push({ name: name, value: value });

            }
        }
    }
    return vovSpecs;
}

const parseSpecs = (record) => {
    const attributes = record.attributes;
    const isValidAttributes = isValidObject(attributes);
    if (isValidAttributes.result == false) {
        return PRODUCT_DETAILS_DEFAULT_SPECS;
    }

    let specs = [];
    for (let attribute of attributes) {
        const name = attribute.name;
        const isValidName = isValidString(name);

        if (isValidName.result == true) {
            let value = attribute.value;
            const isValidValue = isValidString(value);

            if (isValidValue.result == true) {
                const unit = attribute.unitOfMeasure;
                if (unit) {
                    value += ` ${unit}`;
                }
                specs.push({ name: name, value: value });
            }
        }
    }

    return specs;
}

const parseFAQ = (record) => {
    let faq = [];
    const tempFaq = [];
    if (record.hasOwnProperty('Faq')) {
        const isValidFaq = isValidObject(record.Faq);


        if (isValidFaq.result !== false) {
            const unParseFaq = record.Faq.GM;
            const unParseFaqLength = Object.keys(unParseFaq).length;

            for (let i = 0; i <= unParseFaqLength; i++) {
                if (unParseFaq[i] !== undefined) {
                    tempFaq.push(unParseFaq[i]);
                }
            }
            for (let i = 0; i < tempFaq.length; i++) {
                const data = {}
                data['question'] = tempFaq[i][0];
                data['answer'] = tempFaq[i][1];
                faq.push(data);
            }
            return faq

        }
    }
    else {
        return PRODUCT_DETAILS_DEFAULT_FAQ
    }
}

const getLocation = href => {
    const match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

    // Force empty strings if href doesn't match regular expression to check for standard image URL. This forces use of
    // image-coming-soon image since href isn't valid.
    const fullUrl = match ? href : "";
    const path = match?.[5] || "";

    return {
        href: fullUrl,
        pathname: path
    }
}

/** PARSING IMAGE DATA **/
const parseImages = (userData) => {
    let parsedImages = [];
    const images = userData['images'];
    const isValidImages = isValidObject(images);
    if (isValidImages.result == false) {
        return PRODUCT_DETAILS_DEFAULT_IMAGES;
    }

    const mainImageURL = getLocation(images['mainImage']);

    const mainImage = {
        fullImageURL: mainImageURL.href,
        imagePath: mainImageURL.pathname,
        mimeType: images['mimeType'] || "image/jpeg",
        name: images['name'] || "Image Coming Soon",
        zoomable: images['zoomable'] || "N",
        isMainImage: true
    };

    parsedImages.push(mainImage);

    const alternateImages = images['alternateImages'];
    const isValidAlternateImages = isValidObject(alternateImages);
    if (isValidAlternateImages.result == false) {
        return PRODUCT_DETAILS_DEFAULT_IMAGES;
    }

    if (alternateImages.length) {
        alternateImages.map((altImage) => {
            let altImageUrl = getLocation(altImage['URL']);
            let image = {
                fullImageURL: altImageUrl.href,
                imagePath: altImageUrl.pathname,
                mimeType: altImage['mimeType'],
                name: altImage['name'],
                zoomable: altImage['zoomable'],
                isMainImage: false
            };
            parsedImages.push(image);
        })
    }

    const onVehicleImages = images['onVehicleImages'];
    const isValidOnVehicleImages = isValidObject(onVehicleImages);
    if (isValidOnVehicleImages.result == false) {
        return PRODUCT_DETAILS_DEFAULT_IMAGES;
    }

    if (onVehicleImages.length) {
        onVehicleImages.map(onVehicleImage => {
            let onVehicleUrl = getLocation(onVehicleImage['URL']);
            let image = {
                fullImageURL: onVehicleUrl.href,
                imagePath: onVehicleUrl.pathname,
                mimeType: onVehicleImage['mimeType'],
                name: onVehicleImage['name'],
                zoomable: onVehicleImage['zoomable'],
                isMainImage: false
            };
            parsedImages.push(image);
        })
    }

    return parsedImages;
}

/** PARSING VIDEO DATA **/
const parseVideos = (userData) => {
    let parsedVideos = [];
    const videos = userData['VIDEO'];
    const isValidVideos = isValidObject(videos);
    if (isValidVideos.result == false) {
        return PRODUCT_DETAILS_DEFAULT_VIDEOS;
    }

    if (videos.length) {
        videos.map(video => {
            let videoInfo = {
                videoId: video.VIDEO_ID,
                videoImage: {
                    url: video.IMAGES.URL,
                    mimeType: video.IMAGES.mimeType,
                    name: video.IMAGES.name,
                }
            };
            parsedVideos.push(videoInfo);
        })
    }
    return parsedVideos;
}

const parseAvailableDeliveryMethods = (record) => {
    const attributes = record.shippableAttributes;
    const isValidAttributes = isValidObject(attributes);
    if (isValidAttributes.result == false) {
        return PRODUCT_DETAILS_DEFAULT_DELIVERY_METHODS;
    }

    let availableDeliveryMethods = [];
    for (let attribute of attributes) {
        const name = attribute.name;
        let imageSrc = attribute.imageUrl;

        var isValidName = isValidString(name);
        if (isValidName.result == false) {
            console.log('parseAvailableDeliveryMethods, name invalid: ' + isValidName.err);
            console.log('\trecord:', record);
        }

        var isValidImageSrc = isValidString(imageSrc);
        if (isValidImageSrc.result == false) {
            console.log('parseAvailableDeliveryMethods, imageSrc invalid: ' + isValidImageSrc.err);
            console.log('\trecord:', record);
        }

        imageSrc = replaceImageHost(imageSrc);

        // FedEx requires special handling because dealer response is incorrect.
        if (name.toLowerCase().includes('fedex')) {
            if (!AppSettings.isT3) {
                availableDeliveryMethods.push({ name: name, image: imageSrc });
            }
        } else {
            availableDeliveryMethods.push({ name: name, image: imageSrc });
        }
    }

    return availableDeliveryMethods;
}

const parseBreadcrumb = (record) => {
    if (record && record.length > 0) {
        const breadcrumb = record[0];
        let allCategories = '';
        let allCategoriesId = '';
        let category = '';
        let categoryId = '';
        let subcategory = '';
        let subcategoryId = '';

        const isValidBreadcrumbObject = isValidObject(breadcrumb)
        if (isValidBreadcrumbObject.result == false) {
            return PRODUCT_DETAILS_DEFAULT_BREADCRUMB;
        }

        //if there's no subcategory
        if (!breadcrumb[2]) {
            allCategories = breadcrumb[0].value;
            allCategoriesId = breadcrumb[0].value;
            category = breadcrumb[1].label;
            categoryId = breadcrumb[1].value;

            return { allCategories, allCategoriesId, category, categoryId }
        }
        //if there's a subcategory
        if (breadcrumb[2]) {
            allCategories = breadcrumb[0].value;
            allCategoriesId = breadcrumb[0].value;
            category = breadcrumb[1].label;
            categoryId = breadcrumb[1].value;
            subcategory = breadcrumb[2].label;
            subcategoryId = breadcrumb[2].value;

            return { allCategories, allCategoriesId, category, categoryId, subcategory, subcategoryId }
        }
    }
    return PRODUCT_DETAILS_DEFAULT_BREADCRUMB;

}

const parseFeatures = (userData) => {
    const featuresAndBenefits = userData['FeaturesAndBenefits'];
    const isValidFeaturesAndBenefits = isValidObject(featuresAndBenefits);
    if (isValidFeaturesAndBenefits.result == false) {
        return PRODUCT_DETAILS_DEFAULT_FEATURES;
    }

    const gmObject = featuresAndBenefits['GM'];
    const isValidGMObject = isValidObject(gmObject);
    if (isValidGMObject.result == false) {
        return PRODUCT_DETAILS_DEFAULT_FEATURES;
    }

    const gm = gmObject[0];
    const isValidGM = isValidObject(gm);
    if (isValidGM.result == false) {
        return PRODUCT_DETAILS_DEFAULT_FEATURES;
    }

    const content = gm['content'];
    const isValidFeaturesContent = isValidObject(content)
    if (isValidFeaturesContent.result == false) {
        return PRODUCT_DETAILS_DEFAULT_FEATURES;
    }

    let features = [];
    for (let feature of content) {
        const isValidFeature = isValidString(feature);
        if (isValidFeature.result == false) {
        } else {
            features.push(feature);
        }
    }

    return features;
}

const parseWarranty = (userData) => {
    const warranty = userData['Warranty'];

    const isValidWarranty = isValidString(warranty);
    if (isValidWarranty.result == false) {
        return PRODUCT_DETAILS_DEFAULT_WARRANTY;
    }

    return warranty;
}

const parseMaintenance = (userData) => {
    const goodMaintenance = userData['GoodMaintenance'];
    const isValidGoodMaintenance = isValidObject(goodMaintenance);
    if (isValidGoodMaintenance.result == false) {
        return PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
    }

    const gmObject = goodMaintenance['GM'];
    const isValidGMObject = isValidObject(gmObject);
    if (isValidGMObject.result == false) {
        return PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
    }

    const gm = gmObject[0];
    const isValidGM = isValidObject(gm);
    if (isValidGM.result == false) {
        return PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
    }

    const content = gm['content'];
    const isValidMaintenanceContent = isValidObject(content)
    if (isValidMaintenanceContent.result == false) {
        return PRODUCT_DETAILS_DEFAULT_MAINTENANCE;
    }

    let maintenances = [];
    for (let maintenance of content) {
        const isValidMaintenance = isValidString(maintenance);
        if (isValidMaintenance.result == true) {
            maintenances.push(maintenance);
        }
    }

    return maintenances;
}

const parseDescription = (userData, make) => {
    const brand = queryString.parse(location.search).make;

    let currentSite = AppSettings.currentSite.label || brand || make;
    let unFormattedDescription = "";
    const productDescription = userData.ProductDescription;
    const isValidProductDescription = isValidObject(productDescription);
    if (isValidProductDescription.result == false) {
        return PRODUCT_DETAILS_DEFAULT_DESCRIPTION;
    }

    /** PARSE PRODUCT DESCRIPTION ***/
    if (productDescription.hasOwnProperty(currentSite)) {
        unFormattedDescription = productDescription[currentSite];

    }
    else if (productDescription.hasOwnProperty('GM')) {
        unFormattedDescription = productDescription['GM'];
    }
    else {
        unFormattedDescription = PRODUCT_DETAILS_DEFAULT_DESCRIPTION
    }

    const isValidDescription = isValidString(unFormattedDescription);

    if (isValidDescription.result == false) {
        return PRODUCT_DETAILS_DEFAULT_DESCRIPTION;
    }

    // Check if description has Proposition 65
    let i = unFormattedDescription.indexOf("⚠");
    let ascii = false;
    if (unFormattedDescription.includes("&#9888")) {
        i = unFormattedDescription.indexOf("&#9888");
        ascii = true;
    }

    const generalDescription = i < 0 ? unFormattedDescription : unFormattedDescription.substring(0, i);
    // +1 to chop off the warning symbol character or + 6 to chop off ascii
    const prop65Message =
        i < 0
            ? null
            : ascii
            ? parseProp65Description(unFormattedDescription.substring(i + 6))
            : parseProp65Description(unFormattedDescription.substring(i + 1));


    return { "Description": generalDescription, "prop65Message": prop65Message }
}

const cleanString = (str) => {
    str = str.replace(/âš/g, '');
    str = str.replace(/&lt/g, "<");
    str = str.replace(/&gt/g, ">");
    str = str.replace(/;/g, "");
    str = str.replace(/<[^>]+>/g, '');
    return str;
};

const insertBoldText = (fullText, textToBold) => {
    const i = fullText.indexOf(textToBold);
    const j = i + textToBold.length;
    const boldText = `<b>${textToBold}</b>`
    return fullText.substring(0, i) + boldText + fullText.substring(j, fullText.length);
}

const insertLink = (fullText, url) => {
    const i = fullText.indexOf(url);
    const j = i + url.length;
    const link = `<a href=${url} target="_blank">${url}</a>`;
    return fullText.substring(0, i) + link + fullText.substring(j, fullText.length);
}

const parseProp65Description = (descriptionText) => {
    // Clean the string before starting
    descriptionText = cleanString(descriptionText);

    // Regex expressions to look for
    const BOLD_REGEX = [/.*WARNING:/, /Wash hands after handling\./];

    // If there is a regex match, make it bold
    for (let i = 0; i < BOLD_REGEX.length; i++) {
        const regexMatch = descriptionText.match(BOLD_REGEX[i]);
        if (regexMatch) {
            descriptionText = insertBoldText(descriptionText, regexMatch[0]);
        }
    }

    // Check for a url and make it a link
    const url = descriptionText.match(/https.*\.gov/);
    if (url) {
        descriptionText = insertLink(descriptionText, url[0]);
    }

    return descriptionText;
}

const parseTroubleshooting = (userData) => {
    const customTroubleshotData = [];
    if (userData.hasOwnProperty('Troubleshooting')) {
        const troubleshootingUserData = userData['Troubleshooting'];
        const isValidTroubleshootingUserData = isValidObject(troubleshootingUserData);
        if (isValidTroubleshootingUserData.result == false) {
            return PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
        }

        const gmObject = troubleshootingUserData['GM'];
        const isValidGMObject = isValidObject(gmObject);
        if (isValidGMObject.result == false) {
            return PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
        }

        const gm = gmObject[0];
        const isValidGM = isValidObject(gm);
        if (isValidGM.result == false) {
            return PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
        }

        const content = gm['content'];
        const isValidTroubleshootingContent = isValidObject(content)
        if (isValidTroubleshootingContent.result == false) {
            return PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING;
        }

        let troubleshooting = {};

        if (gm.hasOwnProperty('header')) {
            const header = gm.header;
            const isValidHeader = isValidString(header);
            if (isValidHeader.result === false) {
                troubleshooting['header'] = "";
            }
            else {
                troubleshooting['header'] = header;
            }
        }
        let troubleshootingContent = [];
        for (let element of content) {
            const isValidTroubleshootingContent = isValidString(element);
            if (isValidTroubleshootingContent.result == false) {
                troubleshootingContent.push(element);
            }
        }

        troubleshooting['content'] = troubleshootingContent;
        customTroubleshotData.push(troubleshooting);
        return customTroubleshotData;
    }
    else {
        return PRODUCT_DETAILS_DEFAULT_TROUBLESHOOTING
    }
}

const parseUniqueID = (record) => {
    const uniqueID = record.uniqueID;

    const isValidUniqueID = isValidString(uniqueID);
    if (isValidUniqueID.result == false) {
        return PRODUCT_DETAILS_DEFAULT_UNIQUE_ID;
    }

    return uniqueID;
}


const isValidString = (str) => {
    // Validates an expected string variable for type and emptiness.
    // Returns the result along with an error message if applicable.
    var isValidString = {
        result: true,
        err: ''
    }

    if (typeof str != 'string') {
        isValidString = {
            result: false,
            err: 'unexpected type ' + typeof str
        }
        return isValidString;
    }

    if (str === '') {
        isValidString = {
            result: false,
            err: 'empty string'
        }
        return isValidString;
    }

    return isValidString;
}

const isValidObject = (obj) => {
    // Validates an expected object variable for type and other errors.
    // Returns the result along with an error message if applicable.
    var isValidObject = {
        result: true,
        err: ''
    }

    if (typeof obj != 'object') {
        isValidObject = {
            result: false,
            err: 'unexpected type ' + typeof obj
        }
        return isValidObject;
    }

    return isValidObject;
}


function replaceImageHost(imageSrc) {
    // hopefully very temporary
    const damImgUrlDomain = 'https://ecom-dam.ext.gm.com/parts/images';
    imageSrc = imageSrc.replace(/https?:\/\/\[[a-z]\w*\]/, damImgUrlDomain)
    return imageSrc;
}



// Called by watcher in RootSaga.js.
export function* handleAddToCart() {
    try {
        const orderId = yield select(getOrderId);
        const uniqueId = yield select(getProductUniqueId);

        const storedZipcode = getStoredZipcode();
        const defaultZipcode = storedZipcode.slice(0,5) + storedZipcode.slice(6); 
        // ^ remove hyphen for extended zip, should work like '29585' + '' if short zip is used.
        const shipModeId = yield select(getShipModeId);
        const quantity = yield select(getQuantity);
        const year = yield select(getYear);
        const make = yield select(getMake);
        const makeId = yield select(getMakeCode);
        const model = yield select(getModel);
        const vin = yield select(getVin);
        const fitmentVehicleInfo = yield select(getFitmentVehicleInfo);
        const vcdb = yield select(getVcdb);
        // get URL parameters
        const vehicleConfig = yield select(getVehicleConfig);

        let modelId = "";
        let bodyStyle = '';
        let bodyStyleId = '';
        let wheelBase = '';
        let wheelBaseId = '';
        let trim = '';
        let trimId = '';
        let driveType = '';
        let driveTypeId = '';
        let engineBase = '';
        let engineBaseId = '';
        let bodyNumDoorsId = '';

        // get vehicle info from Fitment Check
        if (fitmentVehicleInfo) {

            // vehicle info saved in VehicleInfoReducer if fitment doesn't pass
            // and user is redirected to search page
            if (vcdb.bodyStyleName !== '' || vcdb.trimName !== '' || vcdb.driveTypeName !== '') {
                bodyStyle = vcdb.bodyStyleName;
                // bodyStyleId = vcdb.bodyTypeId;
                wheelBase = vcdb.wheelBaseName;
                wheelBaseId = vcdb.wheelBaseId;
                trim = vcdb.trimName;
                trimId = vcdb.trimId;
                driveType = vcdb.driveTypeName;
                driveTypeId = vcdb.driveTypeId;
            }

            // vehicle info saved in FitmentReducer.
            // checking first for params from vehicle config then
            // any values saved from a previous fitment check.
            // Note: some ids are not needed to be passed with a fitment check
            else {
                modelId = vehicleConfig.modelId ? vehicleConfig.modelId : ''; //no model id needed for fitment
                bodyStyle = vehicleConfig.body ? vehicleConfig.body : fitmentVehicleInfo.bodyStyle ? fitmentVehicleInfo.bodyStyle : '';
                bodyStyleId = vehicleConfig.bodyId ? vehicleConfig.bodyId : ''; // no body id needed for fitment
                wheelBase = vehicleConfig.wheel ? vehicleConfig.wheel : fitmentVehicleInfo.wheelBase ? fitmentVehicleInfo.wheelBase : '';
                wheelBaseId = vehicleConfig.wheelId ? vehicleConfig.wheelId : fitmentVehicleInfo.wheelBaseId ? fitmentVehicleInfo.wheelBaseId : '';
                trim = vehicleConfig.trim ? vehicleConfig.trim : fitmentVehicleInfo.trim ? fitmentVehicleInfo.trim : '';
                trimId = vehicleConfig.trimId ? vehicleConfig.trimId : fitmentVehicleInfo.trimId ? fitmentVehicleInfo.trimId : '';
                driveType = vehicleConfig.drive ? vehicleConfig.drive : fitmentVehicleInfo.driveType ? fitmentVehicleInfo.driveType : '';
                driveTypeId = vehicleConfig.driveId ? vehicleConfig.driveId : fitmentVehicleInfo.driveTypeId ? fitmentVehicleInfo.driveTypeId : '';
                engineBase = vehicleConfig.engine ? vehicleConfig.engine : ''; // no engine base needed for fitment
                engineBaseId = vehicleConfig.engineId ? vehicleConfig.engineId : ''; // no engine id needed for fitment
                bodyNumDoorsId = vehicleConfig.bodyNumDoors ? vehicleConfig.bodyNumDoors :
                    vehicleConfig.bodyNumDoorsId ? vehicleConfig.bodyNumDoorsId : ''; // only have bodyNumDoorsId if pre-populating inlineFitment on deeplink
            }

        }

        // get vehicle info from VIN
        if (vin) {
            bodyStyle = yield select(getBodyStyle);
            bodyStyleId = vcdb.bodyStyleConfigId;
            wheelBase = yield select(getWheelBase);
            trim = yield select(getTrim);
            driveType = yield select(getDriveType);
            driveTypeId = vcdb.driveTypeId;
        }

        const dealerBac = store.getState().DealerizationReducer.selectedDealerAllData.bac ? store.getState().DealerizationReducer.selectedDealerAllData.bac :
        AppSettings.bac ? AppSettings.bac : "";

        const selfRes = yield call(getUserRegistrationStatus);
        if (selfRes?.data?.userId === "-1002") { //self call comes back as guest, so call guestidentity
            const authResp = yield call(authenticate);
            if (authResp) {
                yield call(gmContractCall, dealerBac);
            }
        }
        const res = yield call(postAddToCart,
            orderId, uniqueId, 
            defaultZipcode, shipModeId, quantity,
            year, make, makeId,
            model, modelId, vin,
            bodyStyle, bodyStyleId,
            wheelBase, wheelBaseId,
            trim, trimId,
            driveType, driveTypeId,
            engineBase, engineBaseId,
            bodyNumDoorsId);

        if (res.request.status === 201) {
            // Makes analtyics direct call on successful add to cart.
            if (typeof _satellite != 'undefined') {
                _satellite.track("scAdd");
            }
            const miniCartRes = yield call(getMiniCartData);
            if (miniCartRes.status === 200) {
                openAndTimeoutModal();
            }else {
                yield put(showAddToCartError(true))
            }
        } else {
            if (res?.data?.errors[0]?.errorKey === "ERROR_CUSTOMER_NOT_DEALERIZED" || res?.data?.errors[0]?.errorKey === "_ERR_GENERIC") {
                const contract = yield call(gmContractCall, dealerBac);
                if (contract) {
                    const secondRes = yield call(postAddToCart,
                        orderId, uniqueId, 
                        defaultZipcode, shipModeId, quantity,
                        year, make, makeId,
                        model, modelId, vin,
                        bodyStyle, bodyStyleId,
                        wheelBase, wheelBaseId,
                        trim, trimId,
                        driveType, driveTypeId,
                        engineBase, engineBaseId,
                        bodyNumDoorsId);
                    if (secondRes.status == "201") {
                        const miniCartRes = yield call(getMiniCartData);
                        if (miniCartRes.status === 200) {
                            openAndTimeoutModal();
                        } else {
                            yield put(showAddToCartError(true))
                        }
                    } else {
                        yield put(showAddToCartError(true));
                    }
                } else {
                    yield put(showAddToCartError(true));
                }
            } else {
                yield put(showAddToCartError(true));
            }
            // Makes analtyics direct call on add to cart fail.
            if (typeof _satellite != 'undefined') {
                _satellite.track("error-add");
            } 
        } 
    }
    catch (error) {
        // Makes analtyics direct call on add to cart fail.
        if (typeof _satellite != 'undefined') {
            _satellite.track("error-add");
        }
        yield put(showAddToCartError(true));
    }
}
