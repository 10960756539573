import AppSettings from "../../../core/AppSettings";
import axios from "axios";

/**
 * Make call to fetch the fitment for a part/accessory for Inline Fitment
 * @param vin
 * @param partNumber
 * @return {Promise<AxiosResponse<any>>}
 */
export const getVPMFitmentCheckResponse = (vin, partNumber) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/fitmentcheck/byvin/${vin}/${partNumber}?responseFormat=JSON`;

    return axios.get(url).then(
        (response) => {
            return response.data;
        },
        (error) => {
            throw error;
        }
    );
};
