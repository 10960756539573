import React from 'react';
import Modal from '../../Modal';
import VehicleSelectContainer from '../VehicleSelectContainer';
import { connect } from 'react-redux';
import * as type from '../VehicleSelectRedux/VehicleSelectActionType';
import {reset} from 'redux-form';
import { resetHomeCategory } from '../../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionCreator';

const VehicleSelectModal = (props) => {
  function handleToggle(){
    props.handleToggle(props.data.isOpen);
  }

  // Returns the year select dropdown and the model select dropdown. The year
  // select displays a list of available years; currently years are set 
  // directly in the reducer. The model select displays the models for the 
  // currently selected year. 
  if (props.data) {
    return (
      <div className="ac-vehicle-select-modal-container">
        <div onClick={props.handleToggle}>{props.children}</div>
        <Modal
          open={props.data.isOpen}
          onClose={handleToggle}
          center
          classNames={{
            modal: 'ac-vehicle-select-modal'
          }}
        >
          <VehicleSelectContainer headerLabel={props.headerLabel} headerLabelDynamicValue={props.headerLabelDynamicValue}/>
        </Modal>
      </div>
    )
  } else {
    return <a onClick={handleToggle}></a>
  }
}

function mapStateToProps(state) {
  // state comes from store
  // return value goes to props
  return {
    data: state.VehicleSelectReducer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleToggle: (isOpen) => {
      if (isOpen){
        //clear VIN if it's open and they close it
        dispatch(reset('VehicleSelectForm'));
      }
      dispatch({ type: type.TOGGLE_MODAL });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelectModal)
