import * as type from './ProductDetailsActionType';

var initialState = {
    identifier: '',
    name: '',
    partNumber: '',
    price: '',
    installPrice: '',
    images: [],
    videos: [],
    features: [],
    availableDeliveryMethods: [],
    warranty: '',
    maintenance: [],
    description: '',
    troubleshooting: [],
    specs: [],
    VoV: [],
    isVOVActive: false,
    productRPOCode: '',
    productColorCode: '',
    faq: [],
    uniqueID: '',
    breadcrumb: {},
    wheelPackageComponent: [],
    documentation: {},
    packagingSpecification: [],
    partTerminologyId: '',
    isLoading: true,
    goToCart: false,
    showError: false,
    showAddToCartError: false,
    showLoadingBtn: false,
    pdFormattedProductPrice: null,
    deliveryOptions: {},
    suppressDeliveryOptions: false,
    suppressAddToCart: false,
    nationalWritingControl: "",
    showCallDealerMsg: false,
    callDealerReason: "",
    shipModeId: "",
    carrierCode: "",
    deliveryOptionsLoading: false,
    quantity: "1"
}

const ProductDetailsDescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.PRODUCT_DETAILS_RESET_PAGE: {
            return Object.assign({}, state, initialState)
        }

        case type.SET_PRODUCT_IDENTIFIER: {
            return Object.assign({}, state, { identifier: action.identifier })
        }

        case type.SET_PRODUCT_DETAILS:
            return Object.assign({}, state, {
                name: action.data.name,
                partNumber: action.data.partNumber,
                price: action.data.price,
                installPrice: action.data.installPrice,
                images: action.data.images,
                videos: action.data.videos,
                features: action.data.features,
                availableDeliveryMethods: action.data.availableDeliveryMethods,
                warranty: action.data.warranty,
                maintenance: action.data.maintenance,
                description: action.data.description,
                troubleshooting: action.data.troubleshooting,
                specs: action.data.specs,
                VoV: action.data.VoV,
                faq: action.data.faq,
                uniqueID: action.data.uniqueID,
                wheelPackageComponent: action.data.wheelPackageComponent,
                documentation: action.data.documentation,
                packagingSpecification: action.data.packagingSpecification,
                breadcrumb: action.crumbData,
                partTerminologyId: action.data.partTerminologyId,
                isLoading: false
            });

        case type.SET_PRODUCT_DETAILS_ITEM_PRICE:
            return {
                ...state,
                pdFormattedProductPrice: action.price
            }

        case type.SET_PRODUCT_DETAILS_FAIL:
            let errorMessage = "LABEL_PRODUCT_DETAILS_ERROR";
            if (action.error == 'PROD_NOT_PUBLISHED') {
                errorMessage = "LABEL_PRODUCT_DETAILS_ERROR_NO_PROD";
            }
            return Object.assign({}, state, {
                message: errorMessage,
                isLoading: false,
                showError: true
            });

        case type.ADD_TO_CART_ASYNC:
            return Object.assign({}, state, { showLoadingBtn: true });

        case type.ADD_TO_CART:
            return state;

        case type.PRODUCT_DETAILS_GO_TO_CART:
            const goToCart = action.goToCart;
            return Object.assign({}, state, {
                goToCart: goToCart,
                showLoadingBtn: false
            });

        case type.SHOW_ADD_TO_CART_ERROR:
            return Object.assign({}, state, {
                showAddToCartError: action.showError,
                showLoadingBtn: false
            });

        case type.UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA:
            return Object.assign({}, state, { isVOVActive: action.isVOVActive, productRPOCode: action.productRPOCode, productColorCode: action.productColorCode });

        case type.SET_TOAST_MESSAGE_ERROR:
            return Object.assign({}, state, {
                type: "SET_TOAST_MESSAGE_ERROR",
                message: "LABEL_DELIVERY_OPTIONS_ERROR",
                showError: true,
                suppressAddToCart: true,
                suppressDeliveryOptions: true
            });

        case type.SET_SUPPRESS_PDP:
            return Object.assign({}, state, {
                type: "SET_SUPPRESS_PDP",
                suppressDeliveryOptions: true,
                suppressAddToCart: true,
                showCallDealerMsg: true,
                callDealerReason: ""
            });

        case type.SET_DELIVERY_OPTIONS_LOADING_STATE: 
            return Object.assign({}, state, {
                type: "SET_DELIVERY_OPTIONS_LOADING_STATE", 
                deliveryOptionsLoading: action.deliveryOptionsLoading
            });

        case type.SET_NATIONAL_ORDER_WRITING_CONTROL:
            return Object.assign({}, state, {
                nationalWritingControl: "Y"
            });

        case type.SET_DELIVERY_OPTIONS:
            return Object.assign({}, state, {
                deliveryOptions: action.deliveryOptions
            });

        case type.SET_SHIP_MODE:
            return Object.assign({}, state, {
                shipModeId: action.shipModeId,
                carrierCode: action.carrierCode
            });
        
        case type.RESET_LOADING_BUTTON:
            return Object.assign({}, state, {
                type: "RESET_LOADING_BUTTON",
                showLoadingBtn: false
            });

        case type.SET_QUANTITY:
            return Object.assign({}, state, {
                quantity: action.quantity
            });
            
        default:
            return state;
    }
}

export default ProductDetailsDescriptionReducer;