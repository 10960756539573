import axios from 'axios';
import AppSettings from '../../../core/AppSettings';

const id = AppSettings.storeId;


/** GET MEMBER INFORMATION API (303) This call is to retrieve member detail such as: total points, apply now, tier name, account active status */
export const getHeaderRewardsMemberDetail = async (isLight) => {
    const isLightContent = isLight ? true : false;
    const gmRewardsHeaderDetailURL = `/wcs/resources/store/${AppSettings.storeId}/GMRewards/accountInformation?lightContent=${isLightContent}&showContent=false`;
    return await axios.get(gmRewardsHeaderDetailURL);
}
/**
 * API-312 moved to server side
 * Redemption Information , triggers handler on the server side
 * @param {object}
 * @returns {object} the potential/guest points from the call
 */
//------------ CALL FOR REWARDS GET REDEMPTION INFORMATION API (312) ----------------------
export const getMemberRedemptionInformation = (transactionPrice) => {
  return axios.post("/node/memberRedemptionInformation", { "Transaction Price": transactionPrice });
}

//------------ CALL FOR REWARDS EARN API (316) -----------------------------------------
/** This call is for cart and PD pages to display potential Rewards*/ 
export const getPotentialRewardsPoints = (amount) => {
    const url = `/wcs/resources/store/${ id }/GMRewards/simulateRewards?amount=${amount}`;
      return axios.get(url)
        .then((response) => response.data )
        .catch((error) => {
            console.warn("Unable to get potential rewards information", error);
            throw error;
        });
};

//----------------- CALLS FOR CHECKOUT STEP 2 REDEEM REWARDS -----------------------------------------------
export const updateRewardsPoints = (orderId, rewardsPoints, type) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMRewardsOrder/' + orderId + '/points';

    if (type === "APPLY") {
        let body = { "amount": rewardsPoints };
        return axios.post(url, body).then((response) => { return response }).catch((error) => { return error.response });
    } else if (type === "REMOVE") {
        let body = {"rewards": [{"rewardsCode": rewardsPoints}]};
        return axios.delete(url, { data: body }).then((response) => { return response }).catch((error) => { return error.response });
    } else {
        return null;
    }
}
/**
 * simulatePorductPointsGuest , triggers handler on the server side to calculated
 * the points for the item if the users is not authenticated ()
 * @param {number}
 * @returns {object} the potential/guest points from the call
 */
export const simulateProductPointsGuest = (price) => {
      const params = [
          { key: price ? price : '' }
      ]
      return axios.post("/node/simulateProductPointsGuest", params);

}
