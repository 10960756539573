import i18next from "i18next";
import React from "react";
import NaturalLanguageSearch from "../../Search/NaturalLanguageSearch/NaturalLanguageSearch";

function HomeSearch() {
    return (
        <div id="ac-home-page-search" className="small-margin">
                <NaturalLanguageSearch  
                />
            </div>
    )

}

export default HomeSearch;