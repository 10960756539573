import { TOGGLE_ACCORDION, SUMMARY_RAIL_SPINNER, APPLY_COUPON_BTN_SPINNER, RESET_COUPON_RESPONSE_MSG, RESET_PROMOTION_ID_RESPONSE_MSG } from './SummaryRailActionType'

export const toggleAccordion = () => {
    return {
        type: TOGGLE_ACCORDION
    }
}

export const showSummaryRailSpinner = (showSpinner) => {
    const action = {
        type: SUMMARY_RAIL_SPINNER,
        showSpinner: showSpinner
    }
    return action;
}

export const showApplyCouponBtnSpinner = (showLoadingBtn) => {
    const action = {
        type: APPLY_COUPON_BTN_SPINNER,
        showLoadingBtn: showLoadingBtn
    }
    return action;
}

export const resetCouponState = () => {
  return {
    type: RESET_COUPON_RESPONSE_MSG
  }
}

export const resetPromotionIdState = () => {
  return {
    type: RESET_PROMOTION_ID_RESPONSE_MSG
  }
}