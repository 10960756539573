import React from 'react';
import { useSelector } from 'react-redux';
import ItemSeller from './ItemSeller';

const sellerStyles = {
  dealerLabel: "q-headline4"
};

/**
 * Contains the compatibility layer to run the Parts UI ItemSeller component on the Accessories application.
 * 
 * @returns {React.ReactElement | React.ReactFragment} The ItemSeller component if the seller is defined, empty fragment if otherwise 
 */
const ItemSellerWrapper = () => {
  // if/when single order, multiple sellers becomes possible, this should be revisited.
  const name = useSelector(state => state.OrderInfoReducer?.parsedResponse?.dealer?.dealerName);

  if (name && typeof name === "string") {
    return (
      <ItemSeller
        sellerName={name}
        styles={sellerStyles}
      />
    );
  } else {
    return <></>;
  }
};

export default ItemSellerWrapper;