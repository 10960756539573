import queryString from "query-string";
import { getSessionParams } from "../Utils/Utils";

export function urlBuilder(catId = "") {
    const categoryId = catId || "";
    let { url } = queryString.parseUrl(document.location.href);
    // replace acc or accessories in url with parts to redirect to the parts store
    if (url.includes("accs")) {
        url = url.replace("accs", "parts");
    } else if (url.includes("accessories")) {
        url = url.replace("accessories", "parts");
    }
    let queryParams = getSessionParams();

    // append categoryId to the queryParams
    queryParams += "&categoryId=" + categoryId;

    // if no categoryId exists, default to the parts store psr page for the current fitment
    url += queryParams;

    return url;
}
