/** COMPONENT : SHOPPING CART DELIVERY METHODS **/

/** IMPORT STATEMENT **/
import React from 'react';
import {Translation} from 'react-i18next';
import PropTypes from 'prop-types';
import AppSettings from '../../../core/AppSettings'

/** BEGINNING OF THE COMPONENT **/
const DeliveryMethod = (props)=>{

    /*** VARIABLES ***/
    const deliveryOptions = props.availableDeliveryMethods;
    const dealerInstallOnly = deliveryOptions.DealerInstallOnly;
    const dealerInstallation = deliveryOptions.DealerInstallation;
    const fedExGround     = deliveryOptions.FedExGroundShipping;
    const isT3 = AppSettings.isT3 || false;


    return (<Translation>
            {
                (t)=>(
                    <div className="ac-DeliveryMethodWrapper">

                        {
                            /*** RENDER ONLY IF DELIVERY OPTIONS ARE AVAILABLE ***/
                            deliveryOptions &&  <div className="ac-availableDeliveryText q-headline4">{t("LABEL_SHOPPING_CART_AVAILABLE_DELIVERY_METHODS")}</div>
                        }
                        <div className="ac-deliveryImages">
                            {
                                /*** RENDER ONLY IF DEALER INSTALL ONLY OPTIONS IS AVAILABLE ***/
                                dealerInstallOnly && <span><img src={dealerInstallOnly} alt=""/></span>
                            }

                            {
                                /*** RENDER ONLY IF FED EX OPTIONS IS AVAILABLE / HIDES ON T3***/

                                (fedExGround && !isT3) && <span><img src={fedExGround} alt=""/></span>
                            }

                            {
                                /*** RENDER ONLY IF DEALER INSTALLATION OPTIONS ARE AVAILABLE ***/
                                dealerInstallation && <span><img src={dealerInstallation} alt=""/></span>
                            }

                        </div>
                    </div>
                    )
                            }
        </Translation>
    )
};

 /** VALIDATING PROP TYPES ***/
DeliveryMethod.propTypes = {
    availableDeliveryMethods:PropTypes.shape({
            DealerInstallOnly:PropTypes.string,
            DealerInstallation:PropTypes.string,
            FedExGroundShipping:PropTypes.string

    })
};
export default DeliveryMethod