import React from "react";
import { useTranslation } from "react-i18next";
import store from "../../../core/Redux/Store";
import { resetErrors } from "../../DealerLocator/Components/HelperFunctions";
import Button from "../../../shared/Button/Button";
import { getDealerizedData } from "../../../shared/Utils/Utils";

export default function DealerPicker (props) {
    const { t } = useTranslation();
    const dealerizedData = getDealerizedData(props.dealerData);
   
    const openDealerLocator = () => {
        store.dispatch({ type: "TOGGLE_CLOSE", preventClose: false });
        store.dispatch({ type: "UPDATE_USER_INPUT_ZIP", userInputZip: props.dealerData.currentZip });
        store.dispatch({ type: "SET_PREV_ZIP", prevZip: props.dealerData.currentZip });
        store.dispatch({ type: "SET_PREV_SRC", prevSelectedSrc: props.dealerData.selectedSource });
        store.dispatch({ type: "SET_PREV_SOURCES", prevSources: props.dealerData.listOfAllSources });
        resetErrors(store.dispatch);
        store.dispatch({
            type: "TOGGLE_PD_VIEW",
            modalOpen: true,
            partNumber: props.dealerData.partNumber
        });
    }

    if (dealerizedData.isDealerized && dealerizedData.isValidated) {
        return (
            <article>
                <h4 className="gb-body1 current-seller-text">{t("LABEL_COMMON_CURRENT_SELLER")}</h4>
                <div className="prt-current-seller gb-body2">
                   <div className='dealer-name'> 
                        {currentSellerStringParser(props.dealerName)} 
                        <span onClick={() => openDealerLocator()} className="prt-product-info-change-dealer" data-dtm = "select a dealer">
                        {t("LABEL_COMMON_CHANGE")}
                    </span>
                   
                   </div> 
                    
                    <div className='seller-location-text'> 
                        {`${currentSellerStringParser(props.dealerData.selectedDealerAllData.city)}, ${props.dealerData.selectedDealerAllData.state} ${props.dealerData.selectedDealerAllData.zip ? 
                            props.dealerData.selectedDealerAllData.zip : 
                            props.dealerData.selectedDealerAllData.zipcode.substring(0,5)}`
                        }
                    </div>
                </div>
            </article>
        );
    } else {
        return (
            <article>
                <h4 className="prt-current-seller-label gb-descriptive1">{t("LABEL_COMMON_CURRENT_SELLER")}</h4>
                <Button
                    localeKey={t("LABEL_PRODUCT_DETAILS_SELECT_SELLER")}
                    onClick={() => openDealerLocator()}
                    id={"current-seller"}
                    className={"gb-secondary-button"}
                    dataDtm={"select a dealer"}
                    isSecondary
                />
            </article>
        );
    }
}

/** 
* parsing dealer name and city so it is in proper title case
**/  
export function currentSellerStringParser(string) {
    if (string && typeof string === 'string') {
        let lowercase = string.toLowerCase().split(" ").filter((str) => str !== "");
        for (let i = 0; i < lowercase.length; i++) {
            // GMC should always be capitalized
            if (lowercase[i].startsWith('gmc')) {
                lowercase[i] = lowercase[i].toUpperCase();
                continue;
            }
            if (lowercase[i] === ('of') || lowercase[i] === ('or')) { continue; } // do nothing, just skip.

            if (lowercase[i].startsWith('llc')) {
                lowercase[i] = lowercase[i].toUpperCase();
                continue;
            } 
            if (lowercase[i].split('.').length - 1 >= 2 ) { 
                // Certain dealer names have two periods that needs to be all capitalized for example "Henna Chevrolet L.P."
                lowercase[i] = lowercase[i].toUpperCase();
                continue;
            }
            
            // Check for hyphenated strings.
            if (lowercase[i].includes('-')) {
                let hyphenStr = lowercase[i].split("-");
                for (let j = 0; j < hyphenStr.length; j++) {
                    // This check should prevent the issue from this bug: 2251634. (trying to uppercase an undefined value... ""[0] is undefined)
                    if (!hyphenStr[j]) { continue; }

                    // GMC instances can still occur in the hyphenated scenario. EX: "Jimmy Buffet Buick-GMC"
                    if (hyphenStr[j].toLowerCase() === 'gmc') {
                        hyphenStr[j] = hyphenStr[j]?.toUpperCase();
                    } else {
                        hyphenStr[j] = hyphenStr[j][0]?.toUpperCase() + hyphenStr[j].substring(1);
                    }
                }
                hyphenStr = hyphenStr.join('-');
                lowercase[i] = hyphenStr;
            } else {
                lowercase[i] = lowercase[i][0].toUpperCase() + lowercase[i].substring(1);
            }
        }
        lowercase = lowercase.join(' ');
        return lowercase;
    } else {
        return;
    }
}
