import * as type from './HomeCarouselActionType';
import update from 'immutability-helper'

let initialState = {
    isFetching: false,
    carouselAssets: []
}

const HomeCarouselReducer = (state = initialState, action) => {
    switch (action.type) {

        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.LOAD_HOME_CAROUSEL_DATA_ASYNC:
            return { ...state, isFetching: true };

        case type.SET_HOME_CAROUSEL_DATA:
            return {
                ...state,
                isFetching: false,
                carouselAssets: action.carouselAssets
            };

        /**************************************************************/
        // Actions triggered by the user
        /**************************************************************/
        case type.PLAY_VIDEO:
            const playVideo = state.carouselAssets.map(assets => {
                //set videoIsPlaying to true for selected video only
                if (assets.videoId === action.payload.videoId) {
                    return { ...assets, videoIsPlaying: true }
                };
                return assets;
            });

            return update(state, {
                carouselAssets: { $set: playVideo }
            });

        case type.STOP_VIDEO:
            const stopVideo = state.carouselAssets.map(assets => {
                if (assets.videoId === action.payload.videoId) {
                    return { ...assets, videoIsPlaying: false }
                };
                return assets;
            });

            return update(state, {
                carouselAssets: { $set: stopVideo }
            });


        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_HOME_CAROUSEL_DATA_ASYNC_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        case type.SET_HOME_CAROUSEL_DATA_FAIL:
            return { ...state, isFetching: false, error: action, showError: true };

        default:
            return state
    }
};

export default HomeCarouselReducer;