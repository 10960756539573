/** COMPONENT NAME : QTY COUNTER **/

/** IMPORT STATEMENT **/
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Translation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { miniCartChangeQuantity } from "../../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartWorker";
import { cartItemRemove } from "../../../modules/Cart/Redux/CartHandler";
import {
    decrementAction,
    incrementAction,
    inputChange,
    removeLineItem,
    validateDecrement,
    validateIncrement,
    validateInputChange,
    validateLineRemove
} from "../../OrderInfo/OrderInfoRedux/OrderInfoCreator";
import QuantityPicker from "../../QuantityPicker/QuantityPicker";
import CartQuantityFormContainer from "./CartQuantityFormContainer";

const QtyCounter = (props) => {
    /** VARIABLES **/
    const [qty, setQty] = useState(props.value);
    const defaultValue = props.value;
    const orderItemId = props.orderItemId;
    const laborOrderItemId = props.laborOrderItemId;
    const currentComponent = props.component;
    const partName = props.partName;
    const authData = useSelector((state) => state.Authentication);

    const handleQuantityChange = (newQty) => {
        // Testing new quantity to only allow numbers in input
        const reg = /^[0-9\b]+$/;
        if (newQty === "" || reg.test(newQty)) {
            setQty(newQty);
        }
    };

    const handleRemoveClick = () => {
        cartItemRemove(props.dispatch, props.orderItemId, authData, props.setShowItemSpinner, props.items);
    };

    const onQuantitySubmit = () => {
        miniCartChangeQuantity(
            props.dispatch,
            props.orderId,
            orderItemId,
            laborOrderItemId,
            qty,
            authData,
            props.setShowItemSpinner
        );
    };

    return (
        <Translation>
            {(t) => (
                <div
                    className={
                        currentComponent === "delivery" || currentComponent === "summaryRail"
                            ? "ac-qtyWrapper-delivery"
                            : "ac-qtyWrapper"
                    }
                >
                    {currentComponent !== "delivery" && currentComponent !== "miniCartWrapper" && (
                        <MediaQuery query="(max-width:26.24em)">
                            <div className="ac-qtyForMobile">{t("LABEL_COMMON_QTY")}:</div>
                        </MediaQuery>
                    )}

                    {currentComponent !== "delivery" &&
                        currentComponent !== "summaryRail" &&
                        currentComponent !== "miniCartWrapper" && (
                            <div>
                                {/** QTY COUNTER **/}
                                <CartQuantityFormContainer
                                    orderItemId={orderItemId}
                                    laborOrderItemId={laborOrderItemId}
                                    currentQuantity={props.value}
                                    dtmPartName={typeof partName == "string" ? partName.toLowerCase() : undefined}
                                />
                            </div>
                        )}
                    {currentComponent === "miniCartWrapper" && (
                        <div>
                            {/** MINICART QTY COUNTER **/}
                            <QuantityPicker
                                value={qty}
                                defaultValue={defaultValue}
                                onChange={handleQuantityChange}
                                onSubmit={onQuantitySubmit}
                                isMiniCart={true}
                            />
                        </div>
                    )}

                    {/** REMOVE LINE ITEM LINK ON MEDIUM AND LARGE DEVICES**/}
                    {currentComponent !== "delivery" && currentComponent !== "summaryRail" && (
                        <a
                            className="q-link-label2 stat-text-link ac-light-link-underline"
                            data-dtm={
                                typeof partName == "string" ? `shopping cart:${partName.toLowerCase()}` : undefined
                            }
                            onClick={handleRemoveClick}
                        >
                            {t("LABEL_CHECKOUT_REMOVE")}
                        </a>
                    )}

                    {/** DISPLAY REMOVE LINK AS BUTTON ON SMALL DEVICES **/}
                    {/* {(currentComponent !== "delivery" && currentComponent !== "summaryRail") && (<MediaQuery query="(max-width:26.24em)">
                    <button className="q-secondary-button q-button ac-delete" onClick={props.handleRemoveItem}>{t("LABEL_CHECKOUT_REMOVE")}</button>
                </MediaQuery>)} */}

                    {(currentComponent === "delivery" || currentComponent === "summaryRail") && (
                        <div className="ac-qty-delivery">
                            {t("LABEL_COMMON_QUANTITY")}: {defaultValue}
                        </div>
                    )}
                </div>
            )}
        </Translation>
    );
};

/** MAP STATE TO PROPS **/
const mapStateToProps = (state) => {
    // All data for the vehicle select is stored in the Redux store
    // state and accessed through the VehicleSelectReducer. mapStateToProps maps
    // the latest state data to props that are connected to VehicleSelect.
    // VehicleSelect then uses props.vehicleInfoData to render data in the component.
    return {
        formData: state.form.CartQuantityForm
    };
};

/** MAP DISPATCH TO PROPS **/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        increment: () => {
            if (validateIncrement(ownProps)) {
                dispatch(incrementAction(ownProps));
            }
        },
        decrement: () => {
            if (validateDecrement(ownProps)) {
                dispatch(decrementAction(ownProps));
            }
        },
        handleInputChange: (e) => {
            if (validateInputChange(e, ownProps)) {
                dispatch(inputChange(e, ownProps));
            }
        },
        handleRemoveItem: () => {
            if (ownProps.items[0]) {
                if (validateLineRemove(ownProps.items[0])) {
                    dispatch(removeLineItem(ownProps.items));
                }
            } else {
                //Used to validate mini cart response
                if (validateLineRemove(ownProps.items)) {
                    dispatch(removeLineItem(ownProps.items));
                }
            }
        }
    };
};
/** VALIDATING PROP TYPES **/
QtyCounter.proptypes = {
    value: PropTypes.string.isRequired,
    orderItemId: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(QtyCounter);
