/**
 * validateDeliveryMethodForm() returns true if the delivery method form passes
 * validation or otherwise returns false along with any relevant errors.
 */
export const isValidDeliveryMethodForm = (values, props) => {
    let result = true;
    let errors = {};

    let isValid = isFormMissingRequiredField(values, props);
    if (isValid.result === true) {
        result = false;
        Object.assign(errors, isValid.errors);
    }

    isValid = isFormValueInvalid(values);
    if (isValid.result === true) {
        result = false;
        Object.assign(errors, isValid.errors);
    }

    isValid = isAsyncValidating(props);
    if (isValid.result === true) {
        result = false;
        Object.assign(errors, isValid.errors);
    }

    return {
        result: result,
        errors: errors
    };
}

/**
 * isFormMissingRequiredField() returns true if delivery method form is missing 
 * a required field value or otherwise returns false.
 */
const isFormMissingRequiredField = (values, props) => {
    let result = false;
    let errors = {};

    const deliveryMethodFields = props.asyncChangeFields;
    for (var field of deliveryMethodFields) {
        if (!values[field]) {
            Object.assign(errors, { [field]: 'LABEL_CHECKOUT_DELIVERY_METHOD_FIELD_REQUIRED_ERROR' })
            result = true;
        }
    }

    return {
        result: result,
        errors: errors
    };
}

/**
 * isFormValueInvalid() returns true if the delivery method form contains any 
 * invalid field values or otherwise returns false.
 */
const isFormValueInvalid = (values) => {
    let result = false;
    let errors = {};

    Object.entries(values).forEach(entry => {
        let field = entry[0];
        let value = entry[1];

        // Presently, the only invalid carrierCode is the default value SELECT.
        if (value.includes(`"carrierCode":"SELECT"`)) {
            Object.assign(errors, { [field]: 'LABEL_CHECKOUT_DELIVERY_METHOD_FIELD_REQUIRED_ERROR' })
            result = true;
        }
    });

    return {
        result: result,
        errors: errors
    };
}

/**
 * isFormValueInvalid() returns true if the delivery method form is currently 
 * performing async validation on any of the delivery method fields or 
 * otherwise returns false.
 */
const isAsyncValidating = (props) => {
    let result = false;
    let errors = {};

    if (props.asyncValidating) {
        result = true;
        Object.assign(errors, { _error: 'pending async validation' });
    }

    return {
        result: result,
        errors: errors
    }
}