import * as type from './OrderInfoActionTypes';

let initialState = {
    isLoading: true,
    response: '',
    lastUpdatedItem: '',
    error: false,
    errorMessage: '',
    shippingRatesError: false,
    orderItemId: '',
    showMiniCartRemoveSpinner: false,

    //Cart show textbox values
    cartQuantityShowTextInput: new Set([]),
    cartQuantityShowSpinner: new Set([]),
    cartRemoveShowSpinner: new Set([])
};

const cartUpdateQtyFailedErrorMessage = 'LABEL_CART_UPDATE_QTY_ERROR';
const cartRemoveItemFailedErrorMessageCallHelp = "LABEL_CART_CALL_HELP_ERROR";
const cartOrderInfoAsyncFail = 'LABEL_CART_GET_CART_RESPONSE_ERROR';
const switchOrgFailed = 'LABEL_SERVER_ERROR_MSG';

const OrderInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.ORDER_INFO_RESET:
            return Object.assign({}, state, initialState);

        case type.ORDER_INFO_ORDER_IN_CART:
            return Object.assign({}, state, {
                response: action.payload.response,
                parsedResponse: action.payload.parsedResponse,
                isLoading: false,
                error: false,
                errorMessage: ''
            });
        case type.ORDER_INFO_QTY_INCREMENT:
            return Object.assign({}, state, {
                lastUpdatedItem: action.payload, isLoading: false
            });

        case type.ORDER_INFO_QTY_DECREMENT:
            return Object.assign({}, state, {
                lastUpdatedItem: action.payload, isLoading: false
            });

        case type.ORDER_INFO_LINE_ITEM_REMOVE:
            return Object.assign({}, state, {
                lastUpdatedItem: action.payload, isLoading: false
            });

        case type.ORDER_INFO_SET_SHIPPING_RATES:
            return Object.assign({}, state, {
                shippingRates: action.payload,
                fedexRateError: action.fedexRateError
            });

        case type.ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartOrderInfoAsyncFail,
                isLoading: false
            });

        case type.ORDER_INFO_QTY_CHANGE_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartUpdateQtyFailedErrorMessage,
                isLoading: false
            });

        case type.ORDER_INFO_LINE_ITEM_REMOVE_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartRemoveItemFailedErrorMessageCallHelp,
                isLoading: false
            });

        case type.ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC_FAILED:
            return Object.assign({}, state, { error: true, errorMessage: switchOrgFailed, isLoading: false });

        case type.ORDER_INFO_SET_SHIPPING_RATES_FAIL:
            return Object.assign({}, state, { shippingRatesError: true });

        case type.ORDER_INFO_CART_QUANTITY_SHOW_TEXT_INPUT:
            let cartQuantityShowTextInput = state.cartQuantityShowTextInput;
            if (action.showTextInput === true) {
                cartQuantityShowTextInput.add(action.orderItemId);
            } else if (cartQuantityShowTextInput.has(action.orderItemId)) {
                cartQuantityShowTextInput.delete(action.orderItemId);
            }
            return Object.assign({}, state, {
                cartQuantityShowTextInput: cartQuantityShowTextInput
            });

        case type.ORDER_INFO_CART_QUANTITY_SHOW_SPINNER:
            let cartQuantityShowSpinner = state.cartQuantityShowSpinner;
            if (action.showSpinner === true) {
                cartQuantityShowSpinner.add(action.orderItemId);
            } else if (cartQuantityShowSpinner.has(action.orderItemId)) {
                cartQuantityShowSpinner.delete(action.orderItemId);
            }
            return Object.assign({}, state, {
                cartQuantityShowSpinner: cartQuantityShowSpinner
            });

        case type.ORDER_INFO_CART_REMOVE_SHOW_SPINNER:
            let cartRemoveShowSpinner = state.cartRemoveShowSpinner;
            if (action.showRemoveSpinner === true) {
                cartRemoveShowSpinner.add(action.orderItemId);
            } else if (cartRemoveShowSpinner.has(action.orderItemId)) {
                cartRemoveShowSpinner.delete(action.orderItemId);
            }
            return Object.assign({}, state, {
                cartRemoveShowSpinner: cartRemoveShowSpinner
            });
        
        case type.ORDER_INFO_MINI_CART_REMOVE_SHOW_SPINNER:
            return Object.assign({}, state, {
                showMiniCartRemoveSpinner: action.showRemoveSpinner,
                orderItemId: action.orderItemId
            });

        default:
            return state;
    }
}

export default OrderInfoReducer;
