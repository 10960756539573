import { call } from 'redux-saga/effects';
import AppSettings from '../../../core/AppSettings';
import { postAuthenticated } from '../../../shared/Authenticator/Authenticator';
import axios from "axios";

const stateDataUrl = `/wcs/resources/store/${AppSettings.storeId}/country/country_state_list?countryCode=US`;
const paymentMethodUrl = `/wcs/resources/store/${AppSettings.storeId}/cart/payment/card/${AppSettings.bac}`;
const cVV_InfoUrl = "/wcs/resources/store/" + AppSettings.storeId + "/espot/CVV_Info";

// Email campaigne: Defined here instead of in utils file because of tests not working properly.
const getBacIfT1 = () => {
    if(!AppSettings.isT3) {
        const raw = localStorage.getItem("bacNumber");
        const bac = raw ? JSON.parse(raw) : "";
        return bac;
    }
    return '';
}

const bac = getBacIfT1() || "";
let dealerName = AppSettings.dealerName || "";
if(dealerName){
    dealerName = AppSettings.dealerName.replace('/','');
}

/**************************************************************/
// Services checkout step 1 (contact form)
/**************************************************************/
export function* postContactForm(orderId, firstName, lastName, address1, address2, city,
    stateName, stateCode, postalCode, phone, email, skipAddressValidation,officeAddress) {

    let body = {
        "contactMe": false,
        "email1": email,
        "firstName": firstName,
        "lastName": lastName,
        "officeAddress": officeAddress,
        "orderId": orderId,
        "phone1": phone,
        "addressType": "C",
        "address1": address1,
        "address2": address2 || "",
        "address3": "",
        "city": city,
        "country": "United States",
        "countryCode": "US",
        "selectedState": {
            "label": stateCode,
            "value": stateName
        },
        "state": stateCode,
        "zipCode": postalCode,
        "skipAddressValidation": skipAddressValidation,
        "shipModeId": "",
        "domain": dealerName,
        "bac": bac,
        // "testErrorFlow": "true"

    };
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart/@self/GMCheckout';
    let data = yield call(postAuthenticated, url, body, {});
    return data;
}

/**************************************************************/
// Services checkout step 2 (delivery form)
/**************************************************************/

export const getCountryStateList = () => {
    return axios.get(stateDataUrl).then(response => response.data).catch((error) => {console.log("error", error); throw error});
}

export function * updateOrderItem(payload) {
    const gmUpdateOrderItemURL = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart/@self/update_order_item?responseFormat=json'; //&testErrorFlow=true
    try {
        const data = yield call(postAuthenticated, gmUpdateOrderItemURL, payload);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to update delivery method", error);
        throw error
    }
}

export const getCouponDisplayData = (orderId)=>{
    const gmGetCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/valid_coupon/' + orderId;
    return axios.get(gmGetCouponURL).then(response=>response.data);
};

export function* postCouponCode(couponCode) {
    let postCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/cart/@self/assigned_promotion_code';
    let payload = { promoCode: couponCode };

    try {
        const data = yield call(postAuthenticated, postCouponURL, payload);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to post coupon code", error);
        throw error
    }
}

export async function removeCouponCode(couponCode) {
    let removeCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/cart/@self/assigned_promotion_code/' + couponCode;

    try {
        const data = await axios.delete(removeCouponURL);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to post remove coupon code", error);
        return error
    }
}

export function* postCouponPromotionId(promoId, orderId) {
    let getOrderId = orderId || AppSettings.orderId;
    let postCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/assigned_coupon_code/apply/' + promoId +  '?orderId='+ getOrderId; // + '?testErrorFlow=true'

    if(AppSettings.isT3){
        postCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/assigned_coupon_code/apply/' + promoId  + '?bac=' + AppSettings.bac + '&orderId='+ AppSettings.orderId;
    }
    try {
        const data = yield call(postAuthenticated, postCouponURL);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to post coupon promotion id", error);
        throw error
    }
}

// NECCESSARY FOR SUBMIT ORDER
export function* postPreCheckout(orderValues,bac) {
    let url = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart/@self/precheckout'+'?bac='+bac;
    if(AppSettings.isT3){
       url = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart/@self/precheckout';
    }
    let body = {
        "catalogId": orderValues.catalogId ? orderValues.catalogId : '',
        "langId": "",
        "x_validateShipMode": "true",
        "storeId": orderValues.storeId ? orderValues.storeId : '',
        "orderId": orderValues.orderId ? orderValues.orderId : '',
        "pageName":'payment'
    }
     //?testErrorFlow=true
    try {
        const data = yield call(postAuthenticated, url, body);
        return data;
    }
    catch (error) {
        console.log("Error occurred while trying to post pre checkout data: ", error);
        throw error
    }
}

/**************************************************************/
// CHECKOUT STEP 3 (Review and Place Order)
/**************************************************************/
export const checkPaymentMethod = ()=>{
    return axios.get(paymentMethodUrl).then((response)=>response.data)
                                      .catch((error) => {console.log("Error occurred while trying to load payment method",error);throw error});
};

/*** ESpot to get CVV Info **/
export const cvvInfoService = ()=>{
    return axios.get(cVV_InfoUrl).then((response)=> response)
                                 .catch((error)=> {console.log("Error occurred while trying to load espot cvv info",error);throw error});
};

/** ESpot to get User 4 marketing text at checkout **/

export const gmCardPromo = ()=>{
    return axios.get("/wcs/resources/store/" + AppSettings.storeId + "/espot/Checkout_Card_Promo?noCache=true").then((response)=> response)
                                 .catch((error)=> {console.log("Error occurred while trying to load espot gmCardPromo info",error);throw error});
};


/**************************************************************/
// Services checkout step 4 (Review and Place Order)
/**************************************************************/

//SUBMIT PAYMENT
export function* postSubmitPaymentInfo(paymentValues) {
    const url = `/wcs/resources/store/${AppSettings.storeId}/cart/payment/submit?responseFormat=json&orderId=${AppSettings.orderId}`;
    try {
        const data = yield call(postAuthenticated, url, paymentValues, {});
        //console.log('Submit Payment Data: ', data);
        return data;
    }
    catch (error) {
        //console.log("Error occurred while trying to post submit payment info: ", error);
        throw error
    }
}

// SUBMIT ORDER
export function* postSubmitOrderInfo(orderValues) {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/cart/@self/checkout';
    let data = yield call(postAuthenticated, url, orderValues);
    return data;
}

