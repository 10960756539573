import queryString from "query-string";
import { call, put, select } from "redux-saga/effects";
import AppSettings from "../../../core/AppSettings";
import store from "../../../core/Redux/Store";
import * as actionType from "../../../shared/OrderInfo/OrderInfoRedux/OrderInfoActionTypes";
import * as cartActionType from "../../Cart/Redux/CartActionTypes";
import { getOrderInfo, getShippingRateData } from "../../../shared/OrderInfo/OrderInfoRedux/OrderInfoWorker";
import {
    showApplyCouponBtnSpinner,
    showSummaryRailSpinner
} from "../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator";
import { scrollerToTop } from "../../../shared/Utils/Utils";
import { isValidValue } from "../../../shared/Validator/Validator";
import * as checkoutActionType from "../CheckoutRedux/CheckoutActionType";
import {
    applyCouponFail,
    checkPaymentMethodCreator,
    checkPaymentMethodError,
    couponFail,
    defaultDeliveryMethodFail,
    deliveryPageFail,
    deliveryPageFailTax,
    editForm,
    enablePaymentUserFlag,
    handleCouponResponseMsg,
    handlePromoIdResponseMsg,
    loadSpinnerOnPaymentPage,
    nextStep,
    prevStep,
    removeCouponFail,
    resetOrderProcessingModal,
    sendToHomePg,
    sendToOrderConfirmation,
    setCheckoutShippingContactInitialValues,
    setCountryStateList,
    setCouponData,
    setDeliveryErrorFalse,
    setIsRoscaChecked,
    setLoading,
    setOrderInfo,
    setPaymentInfo,
    shouldPreventStepOneSkip,
    showModal,
    submitContactFormAsyncFail,
    submittingOrderFail,
    submittingOrderSoftFail,
    submittingOrderVoucherFail,
    submittingPaymentFail
} from "./CheckoutActionCreator";
import {
    checkPaymentMethod,
    getCountryStateList,
    getCouponDisplayData,
    postContactForm,
    postCouponCode,
    postCouponPromotionId,
    postPreCheckout,
    removeCouponCode,
    postSubmitOrderInfo,
    postSubmitPaymentInfo
} from "./CheckoutService";
import { normalizePhone, normalizePostalCode } from "../../../shared/Form/FormNormalize/FormNormalize";
import { checkoutErrorTranslation } from "../CheckoutUtils";

const getCustomerInfo = (state) => state.OrderInfoReducer.parsedResponse.customerInfo;
const getShippinginfo = (state) => state.OrderInfoReducer.parsedResponse.shipping;
const getOrderId = (state) => state.OrderInfoReducer.parsedResponse.orderId;
const getEmail = (state) => state.OrderInfoReducer.parsedResponse.customerInfo.email;
const getStateNameToCode = (state) => state.CheckoutReducer.shippingContactForm.stateNameToCode;
const getStateCodeToName = (state) => state.CheckoutReducer.shippingContactForm.stateCodeToName;
const getPaymentInfo = (state) => state.CheckoutReducer.paymentPage.paymentInformation.response;
const getPaymentType = (state) => state.CheckoutReducer.paymentPage.payMethodId;
const getBillingInfo = (state) => state.CheckoutReducer.paymentPage.billingInformation;
const getBacFromOrder = (state) => state.OrderInfoReducer.parsedResponse.dealer.dealerBac;
const getIsRoscaChecked = (state) => state.CheckoutReducer.isRoscaChecked;
const getZeroDollarAmount = (state) => state.CheckoutReducer.zeroCheckoutAmount;
const getPaymentInfoAdyen = (state) => state.CheckoutReducer.paymentPage.paymentInformation;
const getCurrentStep = (state) => state.CheckoutReducer.currentStep;

// Handler for all checkout step changes
export function* handleCheckoutStepChange(action) {
    yield getOrderInfo();
}

/**************************************************************/
// Handlers for checkout step 1 (contact form)
/**************************************************************/
export function* handleLoadInitialContactForm(action) {
    try {
        yield handleLoadCountryStateList();
        yield handleLoadContactForm();
    } catch (error) {
        // sent user to home page
        yield put(sendToHomePg(error));
    }
}

export function* handleLoadContactForm(action) {
    yield getOrderInfo();

    const shippingInfo = yield select(getShippinginfo);

    const customerInfo = yield select(getCustomerInfo);
    const stateCodeToName = yield select(getStateCodeToName);

    // Need to remove extension from phone in case backend is returning GPMS data and normalize number after
    // This is only handling SINGLE digit extensions. Will need to handle multiple digits in the future due to changes
    // in requirements
    let phoneNumber = customerInfo.phone || "";
    if (phoneNumber.startsWith("+")) {
        phoneNumber = normalizePhone(phoneNumber.slice(2));
    }

    // Normalize postal code for case when backend gives back 9 digit zip without hyphen
    let postalCode = shippingInfo.zipcode || "";
    if (postalCode.length > 5 && !postalCode.includes("-")) {
        postalCode = normalizePostalCode(postalCode);
    }

    const initialValues = {
        firstName: customerInfo.firstName || "",
        lastName: customerInfo.lastName || "",
        email: customerInfo.email || "",
        phone: phoneNumber,
        companyName: customerInfo.officeAddress || "",
        address1: shippingInfo.address1 || "",
        address2: shippingInfo.address2 || "",
        city: shippingInfo.city || "",
        postalCode: postalCode,
        state: stateCodeToName[shippingInfo.state] || ""
    };

    const setCheckoutShippingContactInitialValuesAction = setCheckoutShippingContactInitialValues(initialValues);
    yield put(setCheckoutShippingContactInitialValuesAction);
}

export function* handleLoadCountryStateList(action) {
    const res = yield call(getCountryStateList);
    const countryCodeStates = res.countryCodeStates;
    const stateNameToCode = {};
    const stateCodeToName = {};
    const stateList = [];
    for (var countryCodeObject of countryCodeStates) {
        const displayName = countryCodeObject["displayName"];
        const code = countryCodeObject["code"];
        stateNameToCode[displayName] = code;
        stateCodeToName[code] = displayName;
        stateList.push(displayName);
    }

    const setCountryStateListAction = setCountryStateList(stateList, stateNameToCode, stateCodeToName);
    yield put(setCountryStateListAction);
}

export function* handleContactFormSubmit(action) {
    try {
        const form = action.form;
        const skipAddressValidation = action.skipAddressValidation;
        let firstName = "";
        if (form["firstName"]) {
            firstName = form["firstName"];
        }

        let lastName = "";
        if (form["lastName"]) {
            lastName = form["lastName"];
        }

        let address1 = "";
        if (form["address1"]) {
            address1 = form["address1"];
        }

        let address2 = "";
        if (form["address1"]) {
            address2 = form["address2"];
        }

        let companyName = "";
        if (form["companyName"]) {
            companyName = form["companyName"];
        }

        let city = "";
        if (form["city"]) {
            city = form["city"];
        }

        let stateName = "";
        let stateCode = "";
        if (form["state"]) {
            stateName = form["state"];
            const stateNameToCode = yield select(getStateNameToCode);
            stateCode = stateNameToCode[stateName];
        }

        let postalCode = "";
        if (form["postalCode"]) {
            postalCode = form["postalCode"];
        }

        let phone = "";
        if (form["phone"]) {
            phone = form["phone"];
        }

        let email = "";
        if (form["email"]) {
            email = form["email"];
        }

        const orderId = yield select(getOrderId);

        const initialValues = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            address1: address1,
            address2: address2,
            officeAddress: companyName,
            city: city,
            postalCode: postalCode,
            state: stateName
        };

        const setCheckoutShippingContactInitialValuesAction = setCheckoutShippingContactInitialValues(initialValues);
        yield put(setCheckoutShippingContactInitialValuesAction);
        yield put(setLoading(true));
        store.dispatch(shouldPreventStepOneSkip());
        const response = yield call(
            postContactForm,
            orderId,
            firstName,
            lastName,
            address1,
            address2,
            city,
            stateName,
            stateCode,
            postalCode,
            phone,
            email,
            skipAddressValidation,
            companyName
        );
        let isPOBox = checkIsPOBox(response["data"]);
        const currentStep = yield select(getCurrentStep);
        if (response.status === 200 && !response?.data?.errors) {
            //The following line needs to be refactored once contact information and shipping become their own seperate steps
            if (currentStep === 1 && hasContactFormResponseError(response.data)) {
                yield handleAddressValidationError(response, form);
            } else if (isPOBox === "true") {
                const showModalAction = showModal(form, response, true, true, false, true);
                yield put(showModalAction);
            } else if (currentStep === 1) {
                yield loadDeliveryFormData(orderId);
            }
            //If user is submitting on step 4, user has updated their contact/shipping information and step 4 needs latest cart data
            else if (currentStep === 4) {
                yield getOrderInfo();
            }
        } else if (
            response.status === 400 &&
            response.data?.errors[0]?.errorCode === "ECOM-OIA:ERROR_DEALER_NOT_TAX_REMITTABLE"
        ) {
            const checkoutError = checkoutErrorTranslation("bacTaxRemittable");
            yield put(submitContactFormAsyncFail(checkoutError));
        } else {
            yield put(submitContactFormAsyncFail());
        }

        yield put(setLoading(false));
    } catch (error) {
        //I thought this is where we would handle postContactForm errors, that's in handleAddressValidationError().
        //If this errors out, a number of things could have gone wrong. This currently does nothing. - Jia
        //Handles tax errors and is a catch all for any other error not already handled for checkout call - Niki
        yield put(submitContactFormAsyncFail());
    }
}

const checkIsPOBox = (data) => {
    if (data["address"]) {
        const addressObject = data["address"];
        return addressObject.isPOBox;
    } else {
        return "false";
    }
};

/**
 * hasContactFormResponseError() returns true if the POST response data
 * contains an address validation error or other error, otherwise returns false.
 */
const hasContactFormResponseError = (data) => {
    if (data) {
        const addressErrors = data["addressErrors"];
        if (addressErrors) {
            return true;
        }

        const addressValid = data["addressValid"];
        if (addressValid && addressValid === "false") {
            return true;
        }

        const errors = data.errors;
        if (errors && !(errors[0]?.errorKey?.indexOf("DELIVERY_OPTIONS") > -1)) {
            return true;
        }
    }

    return false;
};

/**
 *  Need to check if the State or Zip are incorrect between the form and the response. If FedEx finds an issue
 *  with State/Zip then the required flow is to only allow the user to either edit or continue with FedEx suggested.
 * @param addressErrors
 * @return boolean true if State/Zip mismatch, false if State/Zip match
 */
export function checkStateZipMismatch(addressErrors) {
    let stateZipMismatch = false;
    if (addressErrors["invalidStateZip"] === "true") {
        stateZipMismatch = true;
    }
    return stateZipMismatch;
}
/**
 * hasAddressValidationError() returns true if the data contains an address
 * validation error or otherwise returns false. If errors array has a tax error, will show generic error message
 * errorKey 0000 will still bring up the modal so the user can continue without FedEx
 * @param response  The FedEx response returned
 * @param form      The user input data from Checkout Step 1
 */
export function* handleAddressValidationError(response, form) {
    const data = response.data;
    if (data) {
        const addressValid = data["addressValid"];
        const addressErrors = data["addressErrors"];
        let stateZipMismatch = addressErrors ? checkStateZipMismatch(addressErrors) : false;
        if (data["addressValidationUnavailable"] === "true") {
            // FedEx service is unavailable to validate address - proceed anyway
            const orderId = yield select(getOrderId);
            yield loadDeliveryFormData(orderId);
        } else if (data["addressErrors"]) {
            // FedEx service is available, but there is an address error for the validated address, just a typo or something
            const showModalAction = showModal(form, response, false, true, stateZipMismatch);
            yield put(showModalAction);
        } else if (addressValid && addressValid === "false") {
            // FedEx service is available, address is invalid, and FedEx can't handle it
            const showModalAction = showModal(form, response, false, true, stateZipMismatch);
            yield put(showModalAction);
        } else {
            const errors = data.errors;
            let errorMessageCreated = false;
            //any error response can be handled differently here
            for (var i = 0; i < errors.length; ++i) {
                //this is the key for tax and the tax error message is just generic error message
                if (errors[i].errorKey === "0150") {
                    yield put({ type: checkoutActionType.SHOW_GENERIC_ERROR_ALERT });
                    errorMessageCreated = true;
                    break;
                }
                // '0144' - address not supported by a dealer
                if (errors[i].errorKey === "0144") {
                    yield put({ type: checkoutActionType.SHOW_ADDRESS_NOT_SUPPORTED_ALERT });
                    errorMessageCreated = true;
                    break;
                }
                // Old error handling for FedEx
                // Not sure if this case provides any value anymore, but leaving it in so nothing breaks
                if (errors[i].errorKey === "0000") {
                    const showModalAction = showModal(form, response, false, false);
                    yield put(showModalAction);
                    break;
                }
                // No dealers found with inputed address
                if (errors[i].errorKey === "ERROR_DEALER_LOCATER_NO_DEALERS_FOUND") {
                    yield put({ type: checkoutActionType.SHOW_ADDRESS_NOT_SUPPORTED_ALERT });
                    errorMessageCreated = true;
                    break;
                }
            }

            if (!errorMessageCreated) {
                yield put({ type: checkoutActionType.SHOW_GENERIC_ERROR_ALERT });
            }
        }
    }
}

/**
 * This re-sends the address request from the modal, depending on the user's selection
 * @param action    the full array with:
 *                  selectedAddress The chosen address information the user submits from the addy vali modal
 *                  form            The address information the user filled in from the form
 *                                      (required for extraneous info not accounted for by the addy vali modal)
 */
export function* handleAddressModalSubmit(action) {
    yield put(setLoading(true));
    const selectedAddress = action.selectedAddress;
    const form = action.form;
    const skipAddressValidation = action.skipAddressValidation;
    let address1 = "";
    selectedAddress ? (address1 = selectedAddress["address1"]) : (address1 = form["address1"]);
    const address2 = form["address2"];
    let city = "";
    selectedAddress ? (city = selectedAddress["city"]) : (city = form["city"]);
    const firstName = form["firstName"];
    const lastName = form["lastName"];
    const phone = form["phone"];
    const email = form["email"];
    const orderId = yield select(getOrderId);
    let postalCode = "";
    if (selectedAddress) {
        if (selectedAddress["zipCode"]) {
            postalCode = selectedAddress["zipCode"];
        } else {
            postalCode = selectedAddress["postalCode"];
        }
    } else {
        postalCode = form["postalCode"];
    }
    let stateName = "";
    selectedAddress ? (stateName = selectedAddress["state"]) : (stateName = form["state"]);
    let stateNameToCode = yield select(getStateNameToCode);
    let stateCode = stateNameToCode[stateName];
    if (!stateCode) {
        stateCode = "";
        selectedAddress ? (stateCode = selectedAddress["state"]) : (stateCode = form["state"]);
        let stateCodeToName = yield select(getStateCodeToName);
        stateName = stateCodeToName[stateCode];
    }

    const res = yield call(
        postContactForm,
        orderId,
        firstName,
        lastName,
        address1,
        address2,
        city,
        stateName,
        stateCode,
        postalCode,
        phone,
        email,
        skipAddressValidation
    );
    yield put({ type: checkoutActionType.CLOSE_ADDRESS_MODAL });
    const currentStep = yield select(getCurrentStep);
    if (currentStep === 1) {
        yield loadDeliveryFormData(orderId);
    } //If user is submitting on step 4, user has updated their contact/shipping information and step 4 needs latest cart data
    else if (currentStep === 4) {
        yield getOrderInfo();
    }
    yield put(setLoading(false));
}

/**************************************************************/
// Handlers for checkout step 2 (delivery form)
/**************************************************************/
export function* loadDeliveryFormData(orderId) {
    yield loadCouponData(orderId);
    if (AppSettings.isT3) {
        yield getShippingRateData();
    }

    const promoCode = getPromoCode() || "";

    if (promoCode !== "") {
        const payloadToSubmit = {
            payload: {
                couponCode: promoCode
            }
        };
        yield handleCouponCodeSubmit(payloadToSubmit);
    }
    yield put(nextStep());
}

export function* handleGetCouponData(action) {
    if (action.orderId) {
        yield loadCouponData(action.orderId);
    }
}

export function* loadCouponData(orderId) {
    let formatCouponResponse = {};

    try {
        const couponData = yield call(getCouponDisplayData, orderId);
        formatCouponResponse = formatCouponDisplayData(couponData);
        yield put(setCouponData(formatCouponResponse));
    } catch (error) {
        yield put(couponFail());
    }
}

export function* handleOnDeliveryFormPrevious(action) {
    yield put(setLoading(true));
    yield handleLoadContactForm();
    yield put(prevStep());
    yield put(setLoading(false));
}

export function* handleCouponCodeSubmit(action) {
    try {
        const couponCode = action.payload.couponCode;
        let res = "";

        if (couponCode !== "") {
            res = yield call(postCouponCode, couponCode);
        }

        if (res.data.errors) {
            if (taxError(res)) {
                yield put(deliveryPageFailTax());
            } else {
                yield put(couponFail(res.data.errors[0].errorMessage));
            }
        } else {
            // coupon messages from response
            // to display for error handling
            let couponMsg = "";
            if (res.data) {
                couponMsg = res.data.Message;
            }

            yield put({ type: actionType.ORDER_INFO_ORDER_IN_CART_ASYNC });
            yield put({ type: cartActionType.UPDATE_COUPON_BANNER, checkCouponBanner: true });
            yield put(handleCouponResponseMsg(couponMsg));
        }
    } catch (error) {
        yield put(couponFail(error));
    } finally {
        yield put(showApplyCouponBtnSpinner(false));
        yield put(showSummaryRailSpinner(false));
    }
}

export function* handleCouponCodeRemove(action) {
    yield put(showSummaryRailSpinner(true));
    try {
        const couponCode = action.payload;

        const res = yield call(removeCouponCode, couponCode);

        // errors return as res.response, normal returns just res
        if (res?.response?.data) {
            if (taxError(res.response)) {
                yield put(deliveryPageFailTax());
            } else {
                yield put(removeCouponFail());
                yield put(showSummaryRailSpinner(false));
            }
        } else {
            yield put({ type: actionType.ORDER_INFO_ORDER_IN_CART_ASYNC });
            yield put({ type: cartActionType.UPDATE_COUPON_BANNER, checkCouponBanner: true });
        }
    } catch (error) {
        yield put(removeCouponFail());
        yield put(showSummaryRailSpinner(false));
    }
}

export function* handleCouponPromotionIdSubmit(action) {
    try {
        const promoId = action.promoId;
        const orderId = yield select(getOrderId);

        const res = yield call(postCouponPromotionId, promoId, orderId);

        if (res.data.errors) {
            if (taxError(res)) {
                yield put(deliveryPageFailTax());
            } else {
                yield put(couponFail(error));
            }
        } else {
            // coupon messages from response
            // to display for error handling
            let promoIdMsg = "";
            if (res.data) {
                promoIdMsg = res.data.Message;
            }

            yield put({ type: actionType.ORDER_INFO_ORDER_IN_CART_ASYNC });
            yield put({ type: cartActionType.UPDATE_COUPON_BANNER, checkCouponBanner: true });
            yield put(handlePromoIdResponseMsg(promoIdMsg));
        }
        yield put(showSummaryRailSpinner(false));
    } catch (error) {
        yield put(applyCouponFail());
        yield put(showSummaryRailSpinner(false));
    }
}

export function formatCouponDisplayData(couponData) {
    let couponDataResult = [];
    const PRODUCT_LEVEL_CONDITION = "productLevel";

    if (couponData.GMOffers) {
        let manufactureCoupons = couponData.GMOffers;
        let GMOffers = [];
        manufactureCoupons.forEach((coupon) => {
            let promoId = coupon.promotionId;
            let couponCode = coupon.couponCode;
            let codeAndName = coupon.codeAndName;
            let customConditions = [];
            let conditions = coupon.conditions;
            //this filters out the 'Product Level' bullet point for the manufacturing coupon descriptions
            conditions.forEach((condition) => {
                //it filters out 'productLevel' as that is what coupon.conditions returns for product level coupons
                if (condition !== PRODUCT_LEVEL_CONDITION) {
                    customConditions.push(condition);
                }
            });
            let couponPush = {
                promotionId: promoId,
                conditions: customConditions,
                codeAndName: codeAndName,
                couponCode: couponCode
            };
            GMOffers.push(couponPush);
        });
        couponDataResult.push({ GMOffers: GMOffers });
    } else {
        let GMOffers = [];
        couponDataResult.push({ GMOffers: GMOffers });
    }
    if (couponData.DealerOffers) {
        let dealerCoupons = couponData.DealerOffers;
        let DealerOffers = [];
        dealerCoupons.forEach((coupon) => {
            let promoId = coupon.promotionId;
            let couponCode = coupon.couponCode;
            let conditions = coupon.conditions;
            let codeAndName = coupon.codeAndName;
            let couponPush = {
                promotionId: promoId,
                conditions: conditions,
                codeAndName: codeAndName,
                couponCode: couponCode
            };
            DealerOffers.push(couponPush);
        });
        couponDataResult.push({ DealerOffers: DealerOffers });
    } else {
        let DealerOffers = [];
        couponDataResult.push({ DealerOffers: DealerOffers });
    }

    return couponDataResult;
}

export function* handleDeliveryFormSubmit() {
    yield put(setLoading(true));
    const orderId = yield select(getOrderId);
    const bac = yield select(getBacFromOrder);

    const preCheckoutValues = {
        catalogId: "10052",
        langId: "",
        storeId: AppSettings.storeId,
        orderId: orderId
    };

    const res = yield call(postPreCheckout, preCheckoutValues, bac);

    if (res.data && res.data.errors) {
        yield put(setLoading(false));
        if (taxError(res)) {
            yield put(deliveryPageFailTax());
        }
        // handle FedEx delivery selection application failure
        if (isMissingDeliveryParam(res)) {
            yield getOrderInfo();
            // get missing params (order item ids) and save to reducer
            let getMissingParams = res.data.errors[0].errorParameters;
            const missingParams = getMissingParams.replace(/.*orderItemId=(.*),.*/, "$1"); // parse string
            yield put(deliveryPageFail(missingParams, "LABEL_CHECKOUT_SELECT_VALID_DELIVERY_OPTIONS"));
        } else {
            yield put(deliveryPageFail());
        }
    } else {
        yield put(nextStep());
        yield put(setDeliveryErrorFalse());
        yield put(setLoading(false));
    }
}

/**************************************************************/
// Handlers for checkout step 4 (Review and Place Order)
/**************************************************************/
export function* handlePostSubmitOrder() {
    const isRoscaChecked = yield select(getIsRoscaChecked);
    const customerInfo = yield select(getCustomerInfo);
    const paymentInfo = yield select(getPaymentInfoAdyen);
    const payMethodId = yield select(getPaymentType);
    const billingInfo = yield select(getBillingInfo);
    const isZeroDollarAmount = yield select(getZeroDollarAmount);
    const orderId = yield select(getOrderId);
    const { stateNameToCode } = store.getState().CheckoutReducer.shippingContactForm;

    // Payment values for submit payment
    let paymentValues;
    const isPayInStore = payMethodId.toLowerCase() === "payinstore";

    // PAY IN STORE (T3 Only)
    if (isPayInStore && AppSettings.isT3) {
        paymentValues = {
            selectedMonth: "",
            selectedYear: "",
            ipAddress: "",
            // ipAddress: paymentInfo.customer.ipAddress,
            payMethodId: "PayInStore",
            stateCode: "",
            email: customerInfo.email,
            profileId: `EST-${orderId || ""}`,
            skipAddressValidation: false
        };
    }

    // CREDIT CARD
    // test if credit card info exists
    // to catch error (JIC)

    if (paymentInfo) {
        if (!isPayInStore && paymentInfo.payment) {
            /*** Default to empty string, this would work for lower environment ***/
            let ipAddress = "";
            /*** for Production ,we gets object called customer, taking IPAddress from there ***/
            if (paymentInfo.hasOwnProperty("customer")) {
                ipAddress = paymentInfo.customer.ipAddress;
            }
            /*** For lower environment, we have seen object comes back as devices...***/
            if (ipAddress === "" && paymentInfo.hasOwnProperty("device")) {
                ipAddress = paymentInfo.device.ipAddress;
            }
            paymentValues = {
                selectedMonth: paymentInfo.payment.expiryMonth,
                selectedYear: paymentInfo.payment.expiryYear,
                brand: paymentInfo.payment.brand,
                selectedState: {
                    label: billingInfo.state,
                    value: billingInfo.state
                },
                email: customerInfo.email,
                nameOnCard: paymentInfo.payment.holderName,
                firstName: billingInfo.firstName,
                lastName: billingInfo.lastName,
                city: billingInfo.city,
                state: stateNameToCode[billingInfo.state],
                zipCode: billingInfo.zipCode,
                address1: billingInfo.address1,
                skipAddressValidation: false,
                ipAddress: ipAddress,
                payMethodId: payMethodId,
                paymentId: paymentInfo.payment.paymentId ? paymentInfo.payment.paymentId : paymentInfo.payment.id,
                sessionId: paymentInfo.sessionId ? paymentInfo.sessionId : "",
                profileId: paymentInfo.userId,
                lastFour: paymentInfo.payment.lastFour
            };
        }
    }

    // PLACE ORDER CALLS
    if (isRoscaChecked == false || AppSettings.isT3) {
        try {
            let hasPaymentSubmitResponse = false;
            let paymentSubmitResponse = "";

            if (!isZeroDollarAmount) {
                paymentSubmitResponse = yield call(postSubmitPaymentInfo, paymentValues);
                if (paymentSubmitResponse.status === 200 && paymentSubmitResponse.data) {
                    hasPaymentSubmitResponse = true;
                }
            }
            // 1.) SUBMIT PAYMENT

            if (
                hasPaymentSubmitResponse ||
                isZeroDollarAmount ||
                (payMethodId.toLowerCase() == "payinstore" && AppSettings.isT3)
            ) {
                if (hasPaymentSubmitResponse && paymentSubmitResponse.data.ErrorMsg === "TECHNICAL ERROR") {
                    yield put(setPaymentInfo(paymentSubmitResponse.data.ErrorMsg));
                    yield put(submittingOrderFail());
                    scrollerToTop("root");
                } else if (hasPaymentSubmitResponse && paymentSubmitResponse.data.ErrorMsg === "PAYMENT ERROR") {
                    // need to take back to step 3 and show payment error message
                    yield put(prevStep());
                    yield put(resetOrderProcessingModal());
                    yield put(enablePaymentUserFlag());
                    scrollerToTop("root");
                } else {
                    let parsed = queryString.parse(location.search);

                    // 2.) SUBMIT ORDER
                    const orderValues = {
                        revPayAuth: parsed.revPayAuth == "true" ? "true" : "false", // set to true to force failed place order
                        orderId: isZeroDollarAmount ? parsed.orderId : paymentSubmitResponse.data.OrderId,
                        notifyOrderSubmitted: "1",
                        notifyMerchant: "1",
                        notifyShopper: "1",
                        notify_EMailSender_recipient: customerInfo.email
                    };

                    const orderSubmitResponse = yield call(postSubmitOrderInfo, orderValues);

                    let orderId = "";
                    let orderStatus = "";

                    if (orderSubmitResponse.status === 201) {
                        // orderId = orderSubmitResponse.data.orderId;
                        // temporary fix for missing orderId in response
                        orderId = AppSettings.orderId;
                        orderStatus = "SUBMIT_SUCCESS";
                        yield put(setOrderInfo(orderId, orderStatus));
                        yield put(sendToOrderConfirmation(true));
                    } else {
                        orderStatus = "SUBMIT_FAIL";
                        yield put(setOrderInfo(orderId, orderStatus));

                        const missingDeliveryMethodParameters = isValidValue(orderSubmitResponse.data.errors[0])
                            ? orderSubmitResponse.data.errors[0].errorParameters
                            : "";
                        const isMissingDeliveryMethod =
                            missingDeliveryMethodParameters.indexOf("reason=Missing Delivery Method") > -1;

                        if (isMissingDeliveryMethod) {
                            yield put(defaultDeliveryMethodFail("LABEL_DEFAULT_DELIVERY_METHOD_SELECTED_ERROR"));
                            yield put(editForm(2));
                        } else if (
                            orderSubmitResponse.data &&
                            (orderSubmitResponse.data.errors[0].errorKey == "_ERR_VOUCHER_INVALID" ||
                                orderSubmitResponse.data.errors[0].errorKey == "_ERR_VOUCHER_NOT_FOUND" ||
                                orderSubmitResponse.data.errors[0].errorKey == "_ERR_CANNOT_VALIDATE_VOUCHER")
                        ) {
                            yield put(submittingOrderVoucherFail());
                        } else if (
                            orderSubmitResponse.data &&
                            orderSubmitResponse.data.errors[0].errorKey == "_ERR_PAY_INVALID_CREDIT_CARD_NUMBER"
                        ) {
                            yield put(submittingPaymentFail());
                        } else {
                            yield put(submittingOrderFail(error));
                        }
                    }
                }
            }
        } catch (error) {
            yield put(submittingOrderFail(error));
        }
    } else {
        yield put(setIsRoscaChecked(null, "LABEL_CHECKOUT_ROSCA_ERROR"));
        yield put(submittingOrderSoftFail());
    }
}

/**************************************************************/
// Utility functions
/**************************************************************/
const isValidString = (str) => {
    // Validates an expected string variable for type and emptiness.
    // Returns the result along with an error message if applicable.
    var isValidString = {
        result: true,
        err: ""
    };

    if (typeof str != "string") {
        isValidString = {
            result: false,
            err: "unexpected type " + typeof str
        };
        return isValidString;
    }

    if (str === "") {
        isValidString = {
            result: false,
            err: "empty string"
        };
        return isValidString;
    }

    return isValidString;
};

const isValidObject = (obj) => {
    // Validates an expected object variable for type and other errors.
    // Returns the result along with an error message if applicable.
    var isValidObject = {
        result: true,
        err: ""
    };

    if (typeof obj != "object") {
        isValidObject = {
            result: false,
            err: "unexpected type " + typeof str
        };
        return isValidObject;
    }

    return isValidObject;
};

/*** CHECKOUT STEP 3
 * PAY METHOD ID TO DETERMINE PAY IN STORE OR CREDIT CARD
 * ***/
export function* paymentMethodWorker() {
    try {
        const response = yield call(checkPaymentMethod);
        const paymentResponse = testPaymentMethod(response);
        const PAY_IN_STORE = "payInStore";
        const CREDIT_CARD = "creditCard";

        if (paymentResponse) {
            yield put(checkPaymentMethodCreator(CREDIT_CARD));
        } else if (!paymentResponse) {
            yield put(checkPaymentMethodCreator(PAY_IN_STORE));
            yield put(loadSpinnerOnPaymentPage());
        }
    } catch (error) {
        yield put(checkPaymentMethodError(error));
    }
}

/** IF RESPONSE HAS ANY OF THE PROPERTIES, IT WILL RETURN TRUE OTHERWISE IF WILL RETURN FALSE **/
export const testPaymentMethod = (response) => {
    const UNAVAILABLE_PAYMENT_MESSAGE = "payment is unavailable";
    /** check the response message to see if payment is available or not **/
    if (response.msg && response.msg === UNAVAILABLE_PAYMENT_MESSAGE) {
        return false;
    }
    return (
        response.hasOwnProperty("americanExpress") ||
        response.hasOwnProperty("visa") ||
        response.hasOwnProperty("mastercard") ||
        response.hasOwnProperty("discover")
    );
};

/** HANDLE TAX ERROR RESPONSE, RETURN TRUE IF TAX ERROR FOUND, FALSE OTHERWISE. CAN BE USED TO FILTER FOR OTHER ERROR NUMBERS AS WELL BUT WOULD NEED TO CHANGE RESPONSE. **/
export const taxError = (error) => {
    let errors = error.data.errors;
    for (var i = 0; i < errors.length; i++) {
        if (error.data.errors[i].errorKey === "0150") {
            return true;
        }
    }
    return false;
};

// Tests for precheckout failure scenario when delivery (FedEx) selection isn't applied correctly
export const isMissingDeliveryParam = (error) => {
    let errors = error.data.errors;
    for (var i = 0; i < errors.length; i++) {
        if (error.data.errors[i].errorKey == "_ERR_MISSING_CMD_PARAMETER") {
            return true;
        }
    }
    return false;
};

/** Get Promo Code ***/
export const getPromoCode = () => {
    const promoId = localStorage.getItem("promoCode");
    if (promoId && promoId !== "") {
        return promoId;
    } else return "";
};
