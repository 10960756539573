import React, { useState } from "react";
import Button from "../../../../shared/Button/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import { resetErrors } from "../../Components/HelperFunctions";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import MediaQuery from "react-responsive";

/**
 * Zip code search function takes user input (max length 5) and updates currentZip.
 * When the value of currentZip changes, a GET call is made in DealerLocator.js
 *
 * @param {*} props
 * dispatch (required) dealerizationState reducer
 * state (required) dealerizationState reducer updater
 * @return {ZipCodeSearch} React Component with search bar for zipcode
 */

export default function ZipCodeSearch(props) {
    const { t } = useTranslation();
    const userInputZip = useSelector((state) => state.DealerizationReducer.userInputZip);
    const [zipMaxLength, setZipMaxLength] = useState(userInputZip ? userInputZip.length : 5);
    const ENTER_CHARCODE = 13;
    const PLUS_CHARCODE = 43;
    const PERIOD_CHARCODE = 46;
    const HYPHEN_CHARCODE = 45;
    const REGEX_NUM_ONLY = /^[0-9-]*$/;
    const MAX_WIDTH = props.state.showMap ? "979" : "639";
    const MIN_WIDTH = props.state.showMap ? "980" : "640";

    /**
     * checking if the user hits enter while doing the zipcode search.
     * @param {*} event user keypress
     */
    const handleKeyPress = (event) => {
        if (
            event.which === PLUS_CHARCODE ||
            event.which === PERIOD_CHARCODE ||
            (event.which === HYPHEN_CHARCODE && event.target.value.length !== 5)
        ) {
            event.preventDefault();
        } else {
            //When hyphen is entered when 5 digit zip is already there, increase max zip length to 10 for 9 digit zip.
            if (event.which === HYPHEN_CHARCODE) {
                setZipMaxLength(10);
            }
        }
        if (event.charCode === ENTER_CHARCODE) {
            if (props.state.userInputZip.length !== zipMaxLength) {
                props.dispatch({ type: "SET_ZIP_ERROR_MSG", zipErrorMsg: t("LABEL_DEALER_ERROR_LOCATOR_INVALID_ZIP") });
                props.dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
                props.dispatch({ type: "TOGGLE_ZIP_ERROR", showZipcodeError: true });
                props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
            } else {
                handleZipcodeSearch();
            }
        }
    };

    const handleKeyUp = (e) => {
        if (e.target.value.length <= 5) {
            setZipMaxLength(5);
        }
    };

    /**
     * updates currentZip in reducer so parent can make a backend call
     * to update the list of sources.
     */
    const handleZipcodeSearch = () => {
        props.dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: props.state.userInputZip.slice(0, 5) });
        props.dispatch({ type: "SOURCE_RESULTS", sourceResults: "Zip" });
        const mapArg = {
            coordinates: { lat: 37.0902, lng: -95.7129 },
            googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-adamopelag",
            zoom: 4
        };
        props.dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
        (props.state.showLocationError || props.state.showZipcodeError) && resetErrors(props.dispatch);
        props.setDealerList((prevDealerList) => !prevDealerList);
    };

    /**
     * updates the user input for the zip code
     * @param {*} e event to track what user input in the form control
     */
    const handleZipInput = (e) => {
        if (e.target.value.match(REGEX_NUM_ONLY) && e.target.value.length <= zipMaxLength) {
            props.dispatch({ type: "UPDATE_USER_INPUT_ZIP", userInputZip: e.target.value });
        }
    };

    /* UI render of the zip code search bar. */
    return (
        <div className="search-container">
            {/** DESKTOP VIEW*/}
            <MediaQuery query={`(min-width: ${MIN_WIDTH}px)`}>
                <InputGroup>
                    <FormControl
                        type={"text"}
                        placeholder={t("LABEL_COMMON_ZIP_POSTAL")}
                        onChange={handleZipInput}
                        name={"dealerLocatorZip"}
                        id={"dealerization-search-bar"}
                        value={props.state.userInputZip}
                        onKeyPress={handleKeyPress}
                        onKeyUp={handleKeyUp}
                        className="stat-search-input"
                        data-dtm="modal:dealer search"

                    />
                    <Button
                        localeKey={t("LABEL_COMMON_SEARCH")}
                        onClick={() => handleZipcodeSearch()}
                        id={"dealerization-search-btn"}
                        className="gb-primary-button stat-search-submit"
                        disabled={props.state.userInputZip.length < zipMaxLength || props.state.showSpinner}
                        dataDtm="modal:dealer search"
                    />
                </InputGroup>
            </MediaQuery>

            {/** MOBILE VIEW */}
            <MediaQuery query={`(max-width: ${MAX_WIDTH}px)`}>
                <InputGroup>
                    <FormControl
                        type={"text"}
                        placeholder={t("LABEL_COMMON_SEARCH") + " " + t("LABEL_COMMON_ZIP_POSTAL")}
                        onChange={handleZipInput}
                        name={"dealerLocatorZip"}
                        id={"dealerization-search-bar"}
                        value={props.state.userInputZip}
                        onKeyPress={handleKeyPress}
                        onKeyUp={handleKeyUp}
                        className="stat-search-input"
                        data-dtm="modal:dealer search"
                    />
                    <Button
                        localeKey={""}
                        fontAwesomeRender={faSearch}
                        onClick={() => handleZipcodeSearch()}
                        type={"submit"}
                        id={"dealerization-search-btn"}
                        className="gb-primary-button stat-search-submit"
                        disabled={props.state.userInputZip.length < zipMaxLength || props.state.showSpinner}
                        dataDtm="modal:dealer search"
                    />
                </InputGroup>
            </MediaQuery>
            {props.state.showZipcodeError && (
                <div className="prt-dealerization-error-msg">{props.state.zipErrorMsg}</div>
            )}
        </div>
    );
}

// PropType Checking
ZipCodeSearch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
