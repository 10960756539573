import * as type from "./VoVActionTypes";

const initialState = {
    spinner: true,
    isImageWithParts: true,
    partsRPO: "",
    modelYear: "",
    seriesCode: "",
    countryCode: "US",
    selectedColorCode: "",
    cclvalues: "",
    colorData: "",
    isColorConfigurationAvailable: false,
    error: false,
    errorType: "",
    cgiPartsRPOList: "",
    cgiColorCodeList: "",
    isCgiPartsRpoCallRun: false,
    baseUrl: "/standardurl/urlservice",
    showAccToggle: true, //Display toggle unless load for images without parts isn't successful
    showChangeColor: true, //Display color configuration only if BYO service call is successful
    showError: false, // Note: this may be a duplicate value for 'error'
    showSpinner: true, // Note: this may be a duplicate value for 'spinner'
    isEmptyCategoryEntryView: false
};
const VoVReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.VOV_IMAGE_DATA_INITIALIZATION: {
            const actionPayload = action.payload;
            return Object.assign({}, state, {
                partsRPO: actionPayload.partsRPO || state.partsRPO,
                modelYear: actionPayload.modelYear || state.modelYear,
                seriesCode: actionPayload.seriesCode || state.seriesCode,
                selectedColorCode: actionPayload.colorCode || state.selectedColorCode
            });
        }
        case type.SET_SPINNER_TRUE:
            return Object.assign({}, state, {
                spinner: true
            });
        case type.VOV_DATA_ASYNC:
            return Object.assign({}, state, {
                imageDataWithParts: "",
                imageDataWithoutParts: "",
                spinner: true
            });
        case type.SET_SPINNER_FALSE:
            return Object.assign({}, state, {
                spinner: false
            });

        case type.VOV_CHANGE_COLOR:
            return Object.assign({}, state, {
                selectedColorCode: action.payload.colorId
            });
        case type.VOV_SET_INITIAL_COLOR:
            return Object.assign({}, state, {
                selectedColorCode: action.payload.colorId
            });
        case type.VOV_DATA_WITH_PARTS:
            return Object.assign({}, state, {
                imageDataWithParts: action.payload.imageWithParts,
                cclvalues: action.payload.imageWithParts.cclvalues,
                error: false,
                errorType: "",
                showError: false,
                showSpinner: false
            });
        case type.VOV_DATA_WITHOUT_PARTS:
            return Object.assign({}, state, {
                imageDataWithoutParts: action.payload.imageWithoutParts,
                error: false,
                errorType: "",
                showError: false,
                showSpinner: false
            });
        case type.CHANGE_IMAGE_TOGGLE:
            return Object.assign({}, state, {
                isImageWithParts: !state.isImageWithParts
            });
        case type.COLOR_CONFIG_DATA:
            return Object.assign({}, state, {
                colorData: action.payload.colorData,
                isColorConfigurationAvailable: true
            });
        case type.ERROR_WHILE_TRYING_TO_RETRIEVE_DATA:
            return Object.assign({}, state, {
                error: true,
                errorType: action.payload.errorType,
                spinner: false,
                showError: true,
                showSpinner: false
            });
        case type.RESET_DATA_ON_CLOSE_MODEL:
            return Object.assign({}, state, {
                spinner: true,
                imageDataWithoutParts: "",
                imageDataWithParts: "",
                isImageWithParts: true
            });
        case type.RETRIEVE_PARTS_RPO_FROM_CGI:
            return Object.assign({}, state, {
                cgiPartsRPOList: action.payload.partsRPO,
                cgiColorCodeList: action.payload.colorCode,
                isCgiPartsRpoCallRun: true
            });
        case type.RESET_VOV_PARTSRPO_AND_COLORSCODE:
            return Object.assign({}, state, {
                cgiPartsRPOList: "",
                cgiColorCodeList: ""
            });

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_IMAGE_DATA_SERVICE_FAIL:
            return { ...state, initialState };

        case type.LOAD_VOV_IMAGE_DATA_WITHOUT_PARTS_FAIL:
            return { ...state, error: action, showAccToggle: false };

        case type.LOAD_VOV_IMAGE_DATA_WITH_PARTS_FAIL:
            return { ...state, error: action, showSpinner: false, spinner: false, showError: true };

        case type.LOAD_COLOR_CONFIGURATION_FAIL:
            return { ...state, error: action, showSpinner: false, spinner: false, showChangeColor: false };

        case type.LOAD_VOV_FAIL:
            return { ...state, initialState };
        case type.EMPTY_CATELOG_ENTRY_VIEW_ON_SEARCH_RESULT:
            return { ...state, isEmptyCategoryEntryView: true };

        default:
            return state;
    }
};

export default VoVReducer;
