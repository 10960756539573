import React from 'react';
import { Translation } from "react-i18next";

const ProductSpecifications = (props)=>{
    const productSpecsData = props.productSpecsData;
    const productSpecsTitle = props.productSpecsTitle;

    return (
        <Translation>
            {(t) => (
                <div className="ac-tabContent">
                    <h2 className="q-headline2">{t(productSpecsTitle)}</h2>
                    <table>
                        <tbody>
                        {productSpecsData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="q-text">{item.name}</td>
                                    <td className="q-text">{item.value}</td>
                                </tr>
                            )
                        })
                        }
                        </tbody>
                    </table>
                </div>
            )}
        </Translation>
    );
}

export default ProductSpecifications