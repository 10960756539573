import React from 'react';
import { Translation } from "react-i18next";
import { connect, useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { submit } from 'redux-form';
import { showShippingModal } from '../../modules/Checkout/CheckoutRedux/CheckoutActionCreator';



const DESKTOP_BREAK_POINTS = "(min-width: 60em)";
const MOBILE_BREAK_POINT = "(max-width: 59.99em)";

const CheckoutPageButtons = (props) => {
    const onPrev = props.onPrev;
    const onNext = props.onNext;
    const handleDisabled = props.handleDisabled;
    const showPrimaryButton = props.primaryButton;
    const showSecondaryButton = props.secondaryButton;
    const statsProps = props.statsProps || {};
    const className = statsProps.className || "";
    const dataDtm = statsProps.dataDtm || "";
    const dataDtm2 = statsProps.dataDtm2 || "";
    const formName = props.formName;
    const dispatch = useDispatch();
    const toggleShippingErrorModal = useSelector((state) => state.CheckoutReducer.toggleShippingErrorModal);

    /*This handler is used for the onclick event when the user clicks the next button on checkout. If there is a shipping error, the shipping error modal will be opened.
    Otherwise the formdata will be submitted, allowing the user to go to the next step in checkout. */
    const handleNext = toggleShippingErrorModal ? ()=> dispatch(showShippingModal(true)) : (formName) ? () => dispatch(submit(formName)) : onNext;

    const mobileExperience = (<Translation>
        {(t) => (<div className="ac-checkoutButtonsMobile">

            {
                showPrimaryButton && <div className="small-12 medium-12 grid-column-alignment-right columns">
                    <button
                        className={`ac-checkout-button q-button q-primary-button gm-primary-btn ${className}`}
                        data-dtm={dataDtm}
                        data-dtm2={dataDtm2}
                        type="submit"
                        disabled={handleDisabled}
                        onClick={handleNext}
                    >{t("LABEL_COMMON_NEXT")}</button>
                </div>
            }

            {
                showSecondaryButton && <div className="small-12 medium-12 grid-column-alignment-left columns">
                    <button className="ac-checkout-button q-button q-secondary-button stat-button-link" type="button" data-dtm={dataDtm} data-dtm2={dataDtm2} onClick={onPrev}>{t("LABEL_COMMON_PREV")}</button>
                </div>
            }

        </div>
        )}
    </Translation>
    );

    const desktopExperience = (<Translation>
        {(t) => (
            <div className="ac-checkoutButtonsDesktop">
                {
                    showSecondaryButton && <div className="small-12 medium-12 large-12 xlarge-6 grid-column-alignment-left columns">
                        <button className="ac-checkout-button q-button q-primary-button q-secondary-button stat-button-link" data-dtm={dataDtm} type="button" onClick={onPrev}>Prev</button>
                    </div>
                }
                {
                    showPrimaryButton && <div className="small-12 medium-12 large-12 xlarge-6 grid-column-alignment-right columns">
                        <button
                            className={`ac-checkout-button q-button q-primary-button gm-primary-btn ${className}`}
                            data-dtm={dataDtm}
                            data-dtm2={dataDtm2}
                            type="submit"
                            disabled={handleDisabled}
                            onClick={handleNext}
                        >{t("LABEL_COMMON_NEXT")}</button>
                    </div>

                }


            </div>

        )}
    </Translation>
    );
    return (
        <Translation>
            {(t) => (
                <div className="row ac-checkoutButtons">
                    <MediaQuery query={MOBILE_BREAK_POINT}>
                        {mobileExperience}
                    </MediaQuery>
                    <MediaQuery query={DESKTOP_BREAK_POINTS}>
                        {desktopExperience}
                    </MediaQuery>
                </div>

            )}
        </Translation>
    );
};
export default connect()(CheckoutPageButtons)


