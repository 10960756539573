import React, { Component } from 'react';
import CartWrapper from "../../shared/CartComponents/CartWrapper/CartWrapper";
import store from '../../core/Redux/Store';
import { connect } from 'react-redux';
import { ORDER_INFO_ORDER_IN_CART_ASYNC, ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC } from '../../shared/OrderInfo/OrderInfoRedux/OrderInfoActionTypes'
import { Translation, withTranslation } from 'react-i18next';
import Spinner from '../../shared/Spinner/Spinner';
import EmptyCart from '../../shared/CartComponents/EmptyCart/EmptyCart';
import PageNameHeader from '../../shared/PageNameHeader/PageNameHeader';
import AppSettings from '../../core/AppSettings';
import {handleUserLogin, titleSetUp } from "../../shared/Utils/Utils";
import ErrorMessage from "../../shared/ErrorMessage/ErrorMessage";
import CartRewardsPoints from "../../shared/CartComponents/CartRewardsPoints/CartRewardsPoints";
import GMRewardsCardOffer from "../../shared/CartComponents/GMRewardsCardOffer/GMRewardsCardOffer";
import CartOrderSummary from "../../shared/CartOrderSummary/CartOrderSummary";
import {ContinueShoppingLink} from "../../modules/Cart/Redux/CartHandler";
import {cartReset} from "../../shared/OrderInfo/OrderInfoRedux/OrderInfoCreator";
import {getCartOrderSummaryFFStatus} from "../../shared/CartOrderSummary/CartOrderSummary";
import queryString from "query-string";

class Cart extends Component {
    constructor(props) {
        super(props);
        /** Setting up Page Title **/
        titleSetUp(this.props.t("LABEL_CHECKOUT_SHOPPING_CART"));
        this.state = {
            analyticsFired: false
        };
    }

    componentDidMount() {
        const checkAuth = queryString.parse(location.search).authCheck === "true";
        const isAuthSessionStorage = window.sessionStorage.getItem("isAuthenticated") === "true";
        if (!isAuthSessionStorage && checkAuth) {
            handleUserLogin("/Cart?authCheck=false");
        }
    }

    componentWillMount() {
        /** DISPATCHING AN ACTION ON PAGE LOAD TO GET THE CART DATA **/
        if (AppSettings.isT3) {
            store.dispatch({
                type: ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC
            });
        } else {
            store.dispatch({
                type: ORDER_INFO_ORDER_IN_CART_ASYNC
            });
        }
    }

    componentDidUpdate(prevProps) {
        // Makes analtyics direct call on every cart page visit.
        if (typeof _satellite != 'undefined' && !this.state.analyticsFired) {

            let cartVisited = sessionStorage.getItem('cv');

            if (cartVisited == 1) {
                _satellite.track("view-cart");
                this.setState({
                    analyticsFired: true
                });
            } else {
                sessionStorage.setItem('cv', 1);
                _satellite.track("open-cart");
                this.setState({
                    analyticsFired: true
                });
            }
        }
    }

    componentWillUnmount() {
        // Reset cart redux state so that spinner is displayed on next cart navigation
        const resetCartAction = cartReset();
        store.dispatch(resetCartAction);
    }

    render() {
        const cartResponse = this.props.cartResponse;
        const cartData = this.props.cartData;
        let orderInfoError = cartResponse.error;
        let orderInfoErrorMessage = cartResponse.errorMessage;
        let cartError = cartData.error;
        let cartErrorMessage = cartData.errorMessage;
        const orderInCart = cartResponse.response.order;
        const showSpinner = cartResponse.isLoading;
        let showEmptyCart = false;
        let renderCart = false;
        const RIGHT_ORDER_SUMMARY_FF = getCartOrderSummaryFFStatus();
        const BRAND = AppSettings.currentSite.key;
        let vehicleData = [];
        let continueShoppingUrl ="";
        /** PREPARE CART DATA **/
        if (orderInCart) {
            showEmptyCart = !showSpinner && !orderInCart.vehicles.length && !orderInfoError;
            renderCart = !showSpinner && orderInCart.vehicles.length > 0;
            vehicleData = orderInCart.vehicles.map((items, index) => {
                return {
                    vehicleIndex: index + 1,
                    vehicleInformation: items.vehiclesInfo,
                    items: items.orderItems,
                    vin: items.vinNumber
                }
            })

            /** SETTING UP THE URL FOR CONTINUE SHOPPING CART BUTTON, IT SHOULD BE THE LAST VIN/YMM OF THE CART ITEM **/
            const vehicleLength = vehicleData.length;
            if (vehicleLength) {
                const lastVehicle = vehicleData[vehicleLength - 1];
                continueShoppingUrl = ContinueShoppingLink(lastVehicle);
            }

        } else {
            showEmptyCart = !showSpinner && !orderInfoError;
        }

        return (<Translation>
                {
                    (t) => (
                        <main>
                            <div className="ac-cart">
                                <div className="row">
                                    <PageNameHeader pageHeader={t("LABEL_CHECKOUT_SHOPPING_CART")} />
                                </div>
                                {
                                    !AppSettings.isT3 && renderCart &&
                                    (this.props.cartResponse || this.props.rewardsData.potentialPointsInfoError) &&
                                    <div className="row ac-cart-loyalty-error">
                                        <CartRewardsPoints
                                            currentTierName={this.props.rewardsData.currentTierName}
                                            cartInfo={this.props.cartResponse.response.order}
                                            isRewardsUser={this.props.authData.enrolledAttributeValue === "1"}
                                            isRewardsActive={this.props.rewardsData.isRewardsActive}
                                            error={this.props.rewardsData.potentialPointsInfoError} />
                                    </div>
                                }
                                {
                                    orderInfoError && <ErrorMessage icon={true} message={orderInfoErrorMessage} />
                                }
                                                                {
                                    cartError && <ErrorMessage icon={true} message={cartErrorMessage} />
                                }
                                {
                                    /** SHOW SPINNER WHEN REST RESPONSE IS NOT READY **/
                                    showSpinner && <Spinner />
                                }
                                {
                                    /** SHOW EMPTY CART WHEN NO ITEMS ON CART **/
                                    showEmptyCart && <EmptyCart />
                                }
                                <div className="flex-grid">
                                    <div className="flex-col">
                                        {
                                            /** RENDER CART WHEN DATA IS LOADED CORRECTLY**/
                                            renderCart &&
                                            <CartWrapper
                                                data={orderInCart}
                                                continueShoppingUrl={continueShoppingUrl}
                                                isOrderSummaryFlagEnabled={RIGHT_ORDER_SUMMARY_FF && !AppSettings.isT3}
                                            />
                                        }
                                    </div>
                                    <div>
                                        {renderCart && RIGHT_ORDER_SUMMARY_FF && !AppSettings.isT3 && (
                                            <CartOrderSummary
                                                parsedResponse={cartResponse.parsedResponse}
                                                orderInfo={cartResponse?.response?.order}
                                                orderInCart={orderInCart}
                                                //vehicleData={vehicleData}
                                                //shippingData={shippingData}
                                                //styles={styles[BRAND]}
                                                // dispatch={dispatch}
                                                continueShoppingUrl={continueShoppingUrl}
                                                // handleCouponCodeSubmit={handleCouponCodeSubmit}
                                                RIGHT_ORDER_SUMMARY_FF={RIGHT_ORDER_SUMMARY_FF}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {!showSpinner && this.props.rewardsData.isRewardsActive && this.props.rewardsData.applyForGMCard === 'Y' && <GMRewardsCardOffer />}
                        </main>
                    )
                }
            </Translation>
        )
    }
}

/** MAP STATE TO PROPS **/
const mapStateToProps = state => {
    return {
        cartResponse: state.OrderInfoReducer,
        cartData: state.CartReducer,
        rewardsData: state.RewardsReducer,
        authData: state.Authentication
    };
};
export default withTranslation()(connect(mapStateToProps)(Cart))
