import React, {Component} from 'react';
import { Translation } from 'react-i18next';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const EditAddress = (props) => {
  return (
    <div>
      <Translation>
        {(t) => (
          <a onClick={props.handleCloseModal} className={"q-button q-secondary-button gm-secondary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery">
            {t('LABEL_ADDRESS_VALIDATION_EDIT_BUTTON')}
          </a>
        )}
      </Translation>
    </div>
  )
};

export default EditAddress