import * as type from './RewardsActionTypes';

const initialState = {

    //--------------- DATA FROM GET REDEMPTION INFORMATION CALL ---------------
    memberRedemptionInfoError: null,
    redemptionDollars: null,
    redemptionPoints: null,

    //--------------- DATA FROM EARN API CALL (Potential Points for PDPage) ---------------------------------
    potentialPoints: null,
    potentialPointsInfoError: null,
    
    //************** DATA FROM GET HEADER REWARDS POINTS CALL ------------------
    isRewardsActive: false,
    totalPoints: null,
    enrollmentStatusFetched: false,
    isEnrolledInRewards: false,
    attributeValueGMRewards: null,
    currentTierName: null,
    applyForGMCard: null,
    nonVehicleRedeemDollars: null,
    nonVehicleRedeemPoints: null,
    nonVehicleRedeemActualDollars:null,
    nonVehicleRedeemActualPoints:null,
    canRedeem: false,

    //********** Cart Potential Rewards Points *********\\
    cartPotentialRewardsPoints: null,
    cartPotentialRewardsPointsError: null,

    //********** Checkout Step 2 *********\\
    redeemRewardsError: null,
    isRewardsInputDisabled: null,
}

const RewardsReducer = (state = initialState, action) => {

    //--------- REDUCERS FOR GET MEMBER INFORMATION DATA ------------------------------
    switch (action.type) {

        //--------- REDUCERS FOR GET REDEMPTION INFORMATION DATA --------------------------
        case type.REWARDS_GET_MEMBER_REDEMPTION_INFO:
            return {...state}

        case type.REWARDS_SET_MEMBER_REDEMPTION_INFORMATION:
            return {
                ...state,
                redemptionDollars: action.redemptionDollars ? action.redemptionDollars: null,
                redemptionPoints: action.redemptionPoints ? action.redemptionPoints: null
            };

        case type.REWARDS_SET_MEMBER_REDEMPTION_INFORMATION_FAILED:
            return {
                ...state,
                memberRedemptionInfoError: action.memberRedemptionInfoError
            };

        //----------------REDUCERS FOR EARN API DATA ------------------------------------------
        case type.REWARDS_GET_POTENTIAL_POINTS_INFO:
            return {...state}

        //***************Cart Potential Points**************\\
        case type.REWARDS_GET_CART_POTENTIAL_POINTS:
            return {...state}

        case type.REWARDS_SET_CART_POTENTIAL_POINTS:
            return {
                ...state,
                cartPotentialRewardsPoints: action.potentialPoints,
                cartPotentialRewardsPointsError: null
            }

        case type.REWARDS_SET_CART_POTENTIAL_POINTS_FAILED:
            return {...state, cartPotentialRewardsPointsError: action.error}

        case type.REWARDS_REMOVE_ITEM_POTENTIAL_POINTS:
            let pointsArray = state.cartPotentialRewardsPoints;
            if (state.cartPotentialRewardsPoints) {
                pointsArray = state.cartPotentialRewardsPoints.filter(items => items.id !== action.itemId);
            }
            return {
                ...state,
                cartPotentialRewardsPoints: pointsArray,
                cartPotentialRewardsPointsError: null
            }

        //------------------ REDUCERS FOR GET HEADER POINTS DATA ---------------------------

        case type.REWARDS_SET_POTENTIAL_POINTS_INFORMATION:
            return {
                ...state,
                potentialPoints: action.potentialPoints
            };

        case type.REWARDS_SET_POTENTIAL_POINTS_INFORMATION_FAILED:
            return {
                ...state,
                potentialPointsInfoError: action.potentialPointsInfoError
            };

        case type.LOYALTY_GET_PD_POTENTIAL_POINTS:
            return { ...state };
        

        //------------------ REDUCERS FOR GET HEADER POINTS DATA ---------------------------
        case type.REWARDS_GET_HEADER_REWARDS_POINTS:
            return {...state};

        case type.REWARDS_SET_HEADER_REWARDS_POINTS:
            return {
                ...state,
                isRewardsActive: !!action.rewardsStatus,
                totalPoints: action.totalPoints && action.totalPoints !== 0 ? action.totalPoints : null,
                isEnrolledInRewards: action.isEnrolledInRewards === "1",
                attributeValueGMRewards: action.attributeValueGMRewards,
                currentTierName: action.currentTierName ? action.currentTierName : null,
                applyForGMCard: action.applyForGMCard ? action.applyForGMCard : null,
                nonVehicleRedeemDollars: action.nonVehicleRedeemDollars ? action.nonVehicleRedeemDollars : null,
                nonVehicleRedeemPoints: action.nonVehicleRedeemPoints ? action.nonVehicleRedeemPoints : null,
                canRedeem: action.canRedeem ? action.canRedeem: null,
                nonVehicleRedeemActualDollars: action.nonVehicleRedeemActualDollars ? action.nonVehicleRedeemActualDollars : null,
                nonVehicleRedeemActualPoints: action.nonVehicleRedeemActualPoints ? action.nonVehicleRedeemActualPoints : null ,
            };
        case type.REWARDS_CLEAR_HEADER_REWARDS_POINTS:
            return {
                ...state,
                isRewardsActive: null,
                totalPoints: null,
                isEnrolledInRewards: null,
                attributeValueGMRewards: null,
                currentTierName: null,
                applyForGMCard: null,
                nonVehicleRedeemDollars: null,
                nonVehicleRedeemPoints: null,
                canRedeem: null
            };

        case type.REWARDS_SET_ENROLLMENT_STATUS_FETCHED:
            return {
                ...state,
                enrollmentStatusFetched: true,
            }

        //------------------ CHECKOUT STEP 2 ---------------------------
        case type.SET_REDEEM_REWARDS_FAILURE:
            return {
                ...state,
                redeemRewardsError: action.redeemRewardsError
            }
        
        case type.REWARDS_DISABLE_USER_INPUT:
          return {
              ...state,
              isRewardsInputDisabled: action.disable

          }
        default:
            return state;
    }
}
export default RewardsReducer;
