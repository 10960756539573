import React from 'react';
import { Translation } from "react-i18next";

const PackagingInformation = (props)=>{

    const title = props.packInfoTitle;
    const packInfoData  = props.packInfoData[0];
    return (<Translation>
            {(t) => (
                <div className="ac-tabContent">
                    <h2 className="q-headline2">{t(title)}</h2>
                    <table className="small-12 xlarge-6">
                        <tbody>
                        {
                            packInfoData.hasOwnProperty('quantityEach') && <tr>
                                <td>{t("LABEL_COMMON_PACKAGING_QUANTITY")}</td>
                                <td>{packInfoData.quantityEach}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('packageIncludes') && <tr>
                                <td>{t("LABEL_COMMON_PACKAGING_INCLUDES")}</td>
                                <td>{packInfoData.packageIncludes}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('packOf') && <tr>
                                <td>P{t("LABEL_COMMON_PACK_OF")}</td>
                                <td>{packInfoData.packOf}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('height') && <tr>
                                <td>{t("LABEL_COMMON_HEIGHT")}</td>
                                <td>{packInfoData.height}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('length') && <tr>
                                <td>{t("LABEL_COMMON_LENGTH")}</td>
                                <td>{packInfoData.length}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('width') && <tr>
                                <td>{t("LABEL_COMMON_WIDTH")}</td>
                                <td>{packInfoData.width}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('weight') && <tr>
                                <td>{t("LABEL_COMMON_WEIGHT")}</td>
                                <td>{packInfoData.weight}</td>

                            </tr>
                        }
                        {
                            packInfoData.hasOwnProperty('GTIN') && <tr>
                                <td>{t("LABEL_COMMON_UPC_GTIN")}</td>
                                <td>{packInfoData.GTIN}</td>

                            </tr>
                        }
                        </tbody>
                    </table>

                </div>
            )}
        </Translation>
    )
};



export default PackagingInformation