/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const CHECKOUT_NEXT_STEP = 'CHECKOUT_NEXT_STEP';
export const CHECKOUT_PREV_STEP = 'CHECKOUT_PREV_STEP';
export const CHECKOUT_SET_LOADING = 'CHECKOUT_SET_LOADING';

/**************************************************************/
// Actions for checkout step 1 (contact form)
/**************************************************************/
export const CHECKOUT_LOAD_INITIAL_CONTACT_FORM = 'CHECKOUT_LOAD_INITIAL_CONTACT_FORM';
export const CHECKOUT_LOAD_DATA_ASYNC = 'CHECKOUT_LOAD_DATA_ASYNC';
export const CHECKOUT_SHIPPING_CONTACT_SET_INITIAL_VALUES = 'CHECKOUT_SHIPPING_CONTACT_SET_INITIAL_VALUES';
export const CHECKOUT_SEND_TO_HOME_PG = 'CHECKOUT_SEND_TO_HOME_PG';
export const CHECKOUT_LOAD_COUNTRY_STATE_LIST_ASYNC = 'CHECKOUT_LOAD_COUNTRY_STATE_LIST_ASYNC';
export const CHECKOUT_SET_COUNTRY_STATE_LIST = 'CHECKOUT_SET_COUNTRY_STATE_LIST';
export const CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC = 'CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC';
export const CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC_FAIL = 'CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC_FAIL';
export const CHECKOUT_STATE_DATA_ASYNC = 'CHECKOUT_STATE_DATA_ASYNC';
export const SHOW_ADDRESS_MODAL = 'SHOW_ADDRESS_MODAL';
export const CLOSE_ADDRESS_MODAL = 'CLOSE_ADDRESS_MODAL';
export const SHOW_ADDRESS_ALERT = 'SHOW_ADDRESS_ALERT';
export const SHOW_ADDRESS_NOT_SUPPORTED_ALERT = 'SHOW_ADDRESS_NOT_SUPPORTED_ALERT';
export const SHOW_GENERIC_ERROR_ALERT = 'SHOW_GENERIC_ERROR_ALERT';
export const HIDE_ADDRESS_ALERT = 'HIDE_ADDRESS_ALERT';
export const CHECKOUT_SUBMIT_MODAL = 'CHECKOUT_SUBMIT_MODAL';
/**************************************************************/
// Actions for checkout step 2 (delivery form)
/**************************************************************/
export const CHECKOUT_DELIVERY_METHOD_CHANGE_ASYNC = 'CHECKOUT_DELIVERY_METHOD_CHANGE_ASYNC';
export const CHECKOUT_DELIVERY_METHOD_SHOW_SPINNER = 'CHECKOUT_DELIVERY_METHOD_SHOW_SPINNER';
export const CHECKOUT_COUPON_CODE_POST_ASYNC = 'CHECKOUT_COUPON_CODE_POST_ASYNC';
export const CHECKOUT_COUPON_CODE_REMOVE_POST_ASYNC = 'CHECKOUT_COUPON_CODE_REMOVE_POST_ASYNC';
export const CHECKOUT_LOAD_COUPON_DATA_ASYNC = 'CHECKOUT_LOAD_COUPON_DATA_ASYNC';
export const CHECKOUT_COUPON_PROMOTION_ID_POST_ASYNC = 'CHECKOUT_COUPON_PROMOTION_ID_POST_ASYNC';
export const HANDLE_COUPON_RESPONSE_MSG = 'HANDLE_COUPON_RESPONSE_MSG';
export const HANDLE_PROMOTION_ID_RESPONSE_MSG = 'HANDLE_PROMOTION_ID_RESPONSE_MSG';
export const CHECKOUT_ON_DELIVERY_FORM_PREVIOUS = 'CHECKOUT_ON_DELIVERY_FORM_PREVIOUS';
export const CHECKOUT_SET_DELIVERY_ERROR_TRUE = 'CHECKOUT_SET_DELIVERY_ERROR_TRUE';
export const CHECKOUT_SET_DELIVERY_ERROR_FALSE = 'CHECKOUT_SET_DELIVERY_ERROR_FALSE';
export const CHECKOUT_DELIVERY_METHOD_CHANGE_FAIL = 'CHECKOUT_DELIVERY_METHOD_CHANGE_FAIL';
export const CHECKOUT_DELIVERY_PAGE_FAIL_TAX = 'CHECKOUT_DELIVERY_PAGE_FAIL_TAX';
export const CHECKOUT_DELIVERY_PAGE_TAX_FALSE = 'CHECKOUT_DELIVERY_PAGE_TAX_FALSE';
export const CHECKOUT_COUPON_SUBMIT_FAIL = "CHECKOUT_COUPON_SUBMIT_FAIL";
export const CHECKOUT_APPLY_COUPON_FAIL = "CHECKOUT_APPLY_COUPON_FAIL";
export const CHECKOUT_REMOVE_COUPON_FAIL = "CHECKOUT_REMOVE_COUPON_FAIL";
export const CHECKOUT_DELIVERY_PAGE_FAIL = "CHECKOUT_DELIVERY_PAGE_FAIL";
export const CHECKOUT_SUBMIT_DELIVERY_FORM_ASYNC = "CHECKOUT_SUBMIT_DELIVERY_FORM_ASYNC";
export const CHECKOUT_DELIVERY_PAGE_SWITCH_ORG_FLAG = "CHECKOUT_DELIVERY_PAGE_SWITCH_ORG_FLAG";
export const GET_COUPON_DATA = "GET_COUPON_DATA";
export const CHECKOUT_SHIPPING_ERROR = "CHECKOUT_SHIPPING_ERROR";
export const CHECKOUT_TOGGLE_SHIPPING_ERROR = "CHECKOUT_TOGGLE_SHIPPING_ERROR";

/**************************************************************/
// Actions for checkout step 3 (payment form)
/**************************************************************/
export const CHECKOUT_PAYMENT_BILLING_AS_SHIPPING = "CHECKOUT_PAYMENT_BILLING_AS_SHIPPING";
export const CHECKOUT_PAYMENT_BILLING_NOT_AS_SHIPPING = "CHECKOUT_PAYMENT_BILLING_NOT_AS_SHIPPING";
export const CHECKOUT_PAYMENT_SPINNER = "CHECKOUT_PAYMENT_SPINNER";
export const UPDATE_CREDIT_CARD_SUCCESS = "UPDATE_CREDIT_CARD_SUCCESS";
export const RESET_PAYMENT_INFORMATION = "RESET_PAYMENT_INFORMATION";
export const UPDATE_BILLING_INFORMATION = "UPDATE_BILLING_INFORMATION";
export const SHOW_PAYMENT_TECHNICAL_ERROR = "SHOW_PAYMENT_TECHNICAL_ERROR";
export const RESET_ALL_ERRORS_BEFORE_SUBMIT = "RESET_ALL_ERRORS_BEFORE_SUBMIT";
export const CHECK_PAYMENT_METHOD_ASYNC = "CHECK_PAYMENT_METHOD_ASYNC";
export const CHECK_PAYMENT_METHOD = "CHECK_PAYMENT_METHOD";
export const CHECK_PAYMENT_METHOD_ERROR = "CHECK_PAYMENT_METHOD_ERROR";
export const SHOW_PAYMENT_USER_ERROR = "SHOW_PAYMENT_USER_ERROR";
export const SHOW_MISSING_PAYMENT_ERROR = "SHOW_MISSING_PAYMENT_ERROR";



/**************************************************************/
// Actions for checkout step 4 (review form)
/**************************************************************/
export const CHECKOUT_EDIT_FORM = "CHECKOUT_EDIT_FORM";
export const CHECKOUT_ROSCA_CHECKBOX = "CHECKOUT_ROSCA_CHECKBOX";
export const NEVER_CLOSE_MODAL = "NEVER_CLOSE_MODAL";
export const SUBMITTING_ORDER = "SUBMITTING_ORDER";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const SET_ORDER_INFO = "SET_ORDER_INFO";
export const SEND_TO_ORDER_CONFIRMATION = "SEND_TO_ORDER_CONFIRMATION";
export const SUBMITTING_ORDER_FAIL = "SUBMITTING_ORDER_FAIL";
export const SUBMITTING_PAYMENT_FAIL = "SUBMITTING_PAYMENT_FAIL";
export const SUBMITTING_ORDER_VOUCHER_FAIL = "SUBMITTING_ORDER_VOUCHER_FAIL";
export const SUBMITTING_ORDER_SOFT_FAIL = "SUBMITTING_ORDER_SOFT_FAIL";
export const RESET_ORDER_PROCESSING_MODAL = "RESET_ORDER_PROCESSING_MODAL";
export const RESET_BILLING_INFO = "RESET_BILLING_INFO";
export const CHECKOUT_PREVENT_STEP_ONE_SKIP = "CHECKOUT_PREVENT_STEP_ONE_SKIP"

/**************************************************************/
// Actions for zero checkout (contact form)
/**************************************************************/
export const CHECKOUT_ZERO_DOLLAR_AMOUNT = 'CHECKOUT_ZERO_DOLLAR_AMOUNT';
export const TURN_OFF_SPINNER_FOR_ZERO_CHECKOUT = 'TURN_OFF_SPINNER_FOR_ZERO_CHECKOUT';
export const HANDLE_DEFAULT_DELIVERY_METHOD_FAIL = "HANDLE_DEFAULT_DELIVERY_METHOD_FAIL";
