import i18next from "i18next";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import vaultConstants from "../../../../config/vault_constants";
import AppSettings from "../../../core/AppSettings";
import store from "../../../core/Redux/Store";
import MiniProductCard from "../../../shared/MiniProductCard/MiniProductCard";
import {
    appendCookieFitmentToUrl,
    constructProductUrl,
    formatProductPrice,
    jsonToUrlParams
} from "../../../shared/Utils/Utils";

function NLSSuggestions(props) {
    const urlParams = queryString.parse(window.location.search);
    let shopWithoutFitmentFeatureFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2169904_SWOF_SEARCH_PAGE) === "true";

    function subheader(title) {
        return (
            <div className="ac-search-suggestions-subheader" onMouseMove={(e) => props.onMouseMove(e)}>
                <span>{i18next.t(title)}</span>
                <hr />
            </div>
        );
    }

    /* Searches the searchSuggestion for the searchTerm
       If found, the searchTerm is wrapped in a span tag within the searchSuggestion so styling can be added 
       Otherwise returns the searchSuggestion with no changes */
    function findSearchTerm(searchTerm, searchSuggestion) {
        // Escape special characters before converting to regex
        const i = searchSuggestion.search(new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"));
        if (i > -1) {
            const j = i + searchTerm.length;
            const prefix = searchSuggestion.substring(0, i);
            const match = <span className="ac-search-term-match">{searchSuggestion.substring(i, j)}</span>;
            const suffix = searchSuggestion.substring(j, searchSuggestion.length);
            return (
                <>
                    {prefix}
                    {match}
                    {suffix}
                </>
            );
        } else {
            return searchSuggestion;
        }
    }
    function supercessionCheck() {
        if (props.oldPartNumber) {
            // Save it in storage so that if they click the part, we can populate the supercession label in PDP.
            sessionStorage.setItem("supersessionOldPartNumber", props.oldPartNumber);
        } else {
            // Remove it in case it was previously saved with a different part no.
            sessionStorage.removeItem("supersessionOldPartNumber");
        }
    }
    const products =
        props.searchSuggestions && props.searchSuggestions.suggestionView.Product
            ? props.searchSuggestions.suggestionView.Product.map((item, index) => {
                  const checkT3Product = AppSettings.isT3 ? urlParams.bac : "";
                  const url = constructProductUrl(item.seoUrl, {
                      bodyNumDoors: urlParams.bodyNumDoors,
                      bac: checkT3Product
                  });

                  const imagePath = item.UserData[0] && item.UserData[0].images && item.UserData[0].images.mainImage;
                  const imageSize = "/640X640";

                  const price = AppSettings.isT3
                      ? formatProductPrice(item.price, "offer")
                      : formatProductPrice(item.price, "display");
                  const priceLabel = AppSettings.isT3
                      ? i18next.t("LABEL_SEARCH_RESULTS_PART_ONLY")
                      : i18next.t("LABEL_COMMON_MSRP");

                  return (
                      <li key={item.partNumber} className="ac-search-suggestion-item">
                          <Link
                              to={url}
                              onClick={() => {
                                  supercessionCheck();
                              }}
                              id={`ac-search-suggestion-item-${index + 1}`}
                              className="stat-text-link"
                              data-dtm={`shopping products`}
                              onMouseMove={(e) => props.onMouseMove(e)}
                          >
                              <MiniProductCard
                                  imagePath={imagePath + imageSize}
                                  title={findSearchTerm(props.searchTerm, item.name)}
                                  productName={item.name}
                                  partNumber={findSearchTerm(props.searchTerm, item.partNumber)}
                                  price={price}
                                  priceLabel={priceLabel}
                                  oldPartNumber={props.oldPartNumber}
                                  priceAsterisk={AppSettings.isT3 ? "" : "*"}
                              />
                          </Link>
                      </li>
                  );
              })
            : null;

    let indexAdjustment = props.searchSuggestions.suggestionView.Product
        ? props.searchSuggestions.suggestionView.Product.length
        : 0;

    const categories =
        props.searchSuggestions && props.searchSuggestions.suggestionView.Category
            ? props.searchSuggestions.suggestionView.Category.map((item, index) => {
                  const params = { ...urlParams };
                  params.categoryId = item.value;
                  params.searchTerm = item.name;
                  let route = AppSettings.routePaths["search"];
                  let currPath = window.location.pathname;

                  // If the flag is on we reroute them to PSR, and do not need the searchTerm.
                  if (shopWithoutFitmentFeatureFlag && currPath !== AppSettings.routePaths["home"]) {
                      route = AppSettings.routePaths["psr"];
                      delete params.searchTerm;
                  }

                  //If coming from HP reroute to PSR, but do not remove searchTerm.
                  if (shopWithoutFitmentFeatureFlag && currPath === AppSettings.routePaths["home"]) {
                      route = AppSettings.routePaths["psr"];
                      // bug fix, deleting search term here because it would redirect us to SERP when clicking on a category
                      delete params.searchTerm;
                  }

                  return (
                      <li
                          key={item.value}
                          className="ac-search-suggestion-item"
                          onClick={(e) => {
                              props.handleCategoryClick(item.value);
                          }}
                      >
                          <Link
                              to={`${route}${jsonToUrlParams(params)}`}
                              id={`ac-search-suggestion-item-${index + indexAdjustment + 1}`}
                              className="stat-text-link"
                              data-dtm={`categories`}
                              onClick={() => store.dispatch({ type: "RESET_PAGE_NUMBER" })}
                              onMouseMove={(e) => props.onMouseMove(e)}
                          >
                              {findSearchTerm(props.searchTerm, item.name)}
                          </Link>
                      </li>
                  );
              })
            : null;

    indexAdjustment += props.searchSuggestions.suggestionView.Category
        ? props.searchSuggestions.suggestionView.Category.length
        : 0;

    const keywords =
        props.searchSuggestions && props.searchSuggestions.suggestionView.Keyword
            ? props.searchSuggestions.suggestionView.Keyword.map((item, index) => {
                  const params = { ...urlParams };
                  params.searchTerm = item.term;
                  delete params.categoryId;

                  //Bug 1289064 - Adding dealerName into Url for T3 sites
                  const url = AppSettings.isT3
                      ? `${AppSettings.dealerName}${AppSettings.routePaths["search"]}${jsonToUrlParams(
                            appendCookieFitmentToUrl(params, props.sessionData)
                        )}`
                      : `${AppSettings.routePaths["search"]}${jsonToUrlParams(
                            appendCookieFitmentToUrl(params, props.sessionData)
                        )}`;

                  return (
                      <li
                          key={item.term}
                          className="ac-search-suggestion-item"
                          onClick={(e) => {
                              props.setSearchTerm(e.currentTarget.innerText);
                          }}
                      >
                          <Link
                              to={url}
                              id={`ac-search-suggestion-item-${index + indexAdjustment + 1}`}
                              className="stat-text-link"
                              data-dtm={`suggestions`}
                              onClick={() => store.dispatch({ type: "RESET_PAGE_NUMBER" })}
                              onMouseMove={(e) => props.onMouseMove(e)}
                          >
                              {findSearchTerm(props.searchTerm, item.term)}
                          </Link>
                      </li>
                  );
              })
            : null;

    return (
        <div className="ac-search-suggestions">
            {products && products.length > 0 && (
                <>
                    {subheader("LABEL_SEARCH_NLS_SHOPPING_PRODUCTS")}
                    <ul>{products}</ul>
                </>
            )}
            {categories && categories.length > 0 && (
                <>
                    {subheader("LABEL_SEARCH_NLS_CATEGORIES")}
                    <ul>{categories}</ul>
                </>
            )}
            {keywords && keywords.length > 0 && (
                <>
                    {subheader("LABEL_SEARCH_NLS_SUGGESTIONS")}
                    <ul>{keywords}</ul>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        sessionData: state.Session
    };
};
export default connect(mapStateToProps)(NLSSuggestions);
