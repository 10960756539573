import React, {Component} from 'react';
import { Translation } from 'react-i18next';
export {UseSelected};

class UseSelected extends Component {
  /**
   *
   *  There's a few different expected flows depending on the address validation. With those, the appropriately
   *  translated text must be returned. I opted for if/else returns of the entire <div> because of the lack of
   *  ability to use variables within the Translation tag we use for translations
   * @param validity          Whether the address is recognized as valid by FedEx
   * @param fedExAvailability The availability of FedEx's address validation service: true if available; false if not
   * @param stateZipMismatch  The user's address input vs the FedEx validation: true if mismatch; false if match
   * @param POBox Whether the address entered is recognized by FedEx to be a POBox or not (True means POBox and False means not a POBox)
   * @param suggestedAddress Suggested Address retruned by Fedex in response. 
   * @returns The appropriate button text per flow, depending on the above
   */
  populateButtonText(validity, fedExAvailability, stateZipMismatch, POBox, suggestedAddress) {
    if (!validity && stateZipMismatch && !suggestedAddress) {
      /* If fedex address validation is false & state and zip do not match, disable button and
       * force user to edit address before proceeding. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={true}>
                {t('LABEL_USE_ENTERED_BUTTON_TEXT')}
              </button>
            )}
          </Translation>
        </div>
      );
    } else if (!validity && !fedExAvailability || POBox) {
      /* If fedex returns bad response, fedex is down or address is a POBox, disable button and
       * force user to edit address before proceeding. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={true}>
                {t('LABEL_USE_ENTERED_BUTTON_TEXT')}
              </button>
            )}
          </Translation>
        </div>
      );
    } else if (!validity && !stateZipMismatch && !suggestedAddress) {
      /* If fedex address validation is false & state and zip match & fedex does not find a suggested address, allow user to
       * proceed with entered address. There are cases where new streets are not yet
       * in fedex system, etc. The important thing is that state and zip match for calculation purposes. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={this.props.isLoading}>
                {t('LABEL_USE_ENTERED_BUTTON_TEXT')}
              </button>
            )}
          </Translation>
        </div>
      );
    } else if (!stateZipMismatch && suggestedAddress) {
      /* If fedex cannot validate address but has a suggested address and original address' state and zip match,
       * user can choose to either use original address or use suggested address. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={this.props.isLoading}>
                {t('LABEL_ADDRESS_VALIDATION_USE_BUTTON')}
              </button>
            )}
          </Translation>
        </div>
      )
    } else if (suggestedAddress) {
      /* If fedex returns a suggested address, enable button for user to select
       * suggest address. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={this.props.isLoading}>
                {t('LABEL_USE_SUGGESTED_BUTTON_TEXT')}
              </button>
            )}
          </Translation>
        </div>
      )
    } else {
      /* If no other conditions are met, prevent user from
       * force user to edit address before proceeding. */
      return (
        <div>
          <Translation>
            {(t) => (
              <button className={"q-button q-primary-button gm-primary-btn stat-button-link"} data-dtm="checkout step1:accurate delivery" type="submit" disabled={true}>
                {t('LABEL_USE_ENTERED_BUTTON_TEXT')}
              </button>
            )}
          </Translation>
        </div>
      )
    }
  }

  render(props) {
    const data = this.props;
    const validity = data.validity;
    const fedExAvailability = data.fedExAvailability;
    const stateZipMismatch = data.stateZipMismatch;
    const POBox = data.POBox;
    const suggestedAddress = data.suggestedAddress;

    let buttonText = this.populateButtonText(validity, fedExAvailability, stateZipMismatch, POBox, suggestedAddress);

    return (
      <div>
        {buttonText}
      </div>
    )
  }
}

export default UseSelected