import axios from "axios";
import AppSettings from "../../core/AppSettings";
/**
 SHARED SERVICES
 The purpose of this file to have a central location for REST calls that are used throughout the application
 */

//----- getDeliveryOptionsByZip: Used to get combination of city, state and countys based on users selected zipcode ------------------
export const getDeliveryOptionsByZip = (partNumbers, zipCode,country , bac, classification = "") => {
    // If classification exists then, add it as a parameter, otherwise make it an empty string.
    const classificationParam = classification ? "&classification=" + classification : "";
    const bacParam = bac ? "&bac=" + bac : "";
    let url = `${AppSettings.baseWCSURL}/productSearch/${partNumbers}/deliveryOptions?zipCode=${zipCode}&country=${country}${bacParam}${classificationParam}`;

    return axios
        .get(url)
        .then((response) => {
            if (response.data) {
                return response.data;
            } else {
                return false;
            }
        })
        .catch((error) => {
            throw error;
        });
};

//----- getDeliveryOptionsByCityState: Used to get delivery options based on users zipcode, city, state, and country --------------
export const getDeliveryOptionsByCityState = (partNumbers, zipCode, city, state, country, bac, classification = "") => {
    // If classification exists then, add it as a parameter, otherwise make it an empty string.
    const classificationParam = classification ? "&classification=" + classification : "";
    const bacParam = bac ? "&bac=" + bac : "";
    let url = `${AppSettings.baseWCSURL}/productSearch/${partNumbers}/deliveryOptions?zipCode=${zipCode}&city=${city}&state=${state}&country=${country}${bacParam}${classificationParam}`;
    return axios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (bac) response.data.originalRequestBAC = bac;
                return response.data;
            } else {
                return false;
            }
        })
        .catch((error) => {
            throw error;
        });
};

export const makeInventoryCheck = (bac, partNumbers) => {
    let url =
        AppSettings.baseWCSURL + "/inventoryavailability/byPartNumber/" + partNumbers + "?physicalStoreName=" + bac;

    return axios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (response.status !== 200) {
                    throw error;
                }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
