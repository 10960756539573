import { DELIVERY_METHOD_FIELD_ERROR_ORDER_SUMMARY, validateDeliveryMethodFormField } from './DeliveryFormAsyncValidateFields';

/**
 * asyncValidate() returns a Promise that will resolve if the delivery method 
 * validation is passed or otherwise will reject with an object of validation 
 * errors. 
 * 
 * The delivery method form is configured to only call asyncValidate() 
 * for field-level validation.
 */
const asyncValidate = (values, dispatch, props, blurredField) => {
    // If blurredField is set, then asyncValidate was called by a change in 
    // value of one of the delivery method fields. In this case, perform 
    // field-level validation on that field.
    if (values && blurredField) {
        return validateDeliveryMethodFormField(values, dispatch, props, blurredField)
            .catch(error => {
                switch (error) {
                    case DELIVERY_METHOD_FIELD_ERROR_ORDER_SUMMARY:
                        throw { [blurredField]: 'LABEL_CHECKOUT_DELIVERY_METHOD_FIELD_ORDER_SUMMARY_ERROR' }
                    default:
                        throw { [blurredField]: 'LABEL_CHECKOUT_DELIVERY_METHOD_FIELD_GENERIC_ERROR' }
                }
            });
    }
}

export default asyncValidate