import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';

const FieldWarningMessage = (props) => {
    const warningMessage = props.message;
    const className = props.className ? props.className : "ac-error-label"
    return (
        <label className={className}>
            <FontAwesomeIcon className="ac-warning" icon={faExclamationCircle} />{warningMessage}
        </label>
    );
}

export default FieldWarningMessage