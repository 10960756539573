
import React from 'react';
import propTypes from 'prop-types';

const PageNameHeader = (props) => {

    return (
        <div className="ac-pg-header-wrapper small-12 grid-column-alignment-left columns">
            <h1 className="q-headline2">{props.pageHeader}</h1>
            <hr className="pg-header-hr"/>
        </div>
    )
}

PageNameHeader.propTypes = {
    pageHeader: propTypes.string.isRequired
}

export default PageNameHeader