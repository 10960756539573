import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { normalizePhone, normalizePostalCode } from "../../../../shared/Form/FormNormalize/FormNormalize";
import * as options from "../../../../shared/Form/FormOptions/FormOptions";
import * as validate from "../../../../shared/Form/FormValidate/FormValidate";
import AppSettings from "../../../../core/AppSettings";
import CheckoutPageButtons from "../../../../shared/CheckoutPageButtons/CheckoutPageButtons";
import { withTranslation } from "react-i18next";
import i18n from "../../../../core/i18n/i18n";
import vaultConstants from "../../../../../config/vault_constants";
import { renderField, renderSelect } from "../ShippingAndContact/ShippingAndContactForm";
import { handleContactFormSubmit } from "../../CheckoutRedux/CheckoutWorker";



const ReviewFormEdit = (props) => {
    const { handleSubmit, pristine, reset, submitting, stateList, initialValues, submitFailed, triggerSubmit, editForm, onSubmit } = props;

    //scroll to location on submit error
    useEffect(() => {
        const errorFields = document.getElementsByClassName("error ac-error-label");
        if (submitFailed && errorFields.length) {
            //just grab the first one if there's multiple
            const location = errorFields[0].getBoundingClientRect().top;

            /*calculate position to scroll to based on a) error element location b) current scroll location and
             extra 400 padding to put the element in the middle of the screen*/
            window.scrollTo(0, location + window.pageYOffset - 400);
        }
    }, [triggerSubmit]);

    const renderEmailAndPhone = () =>(
    <form className="ac-shipping-contact-form">
                    <div className="row">
                        <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                            <Field
                                name="email"
                                type="email"
                                component={renderField}
                                label={i18n.t("LABEL_COMMON_EMAIL")}
                                required={true}
                                validate={[validate.required, validate.minLength2, validate.email]}
                                minLength={2}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="small-12 medium-12 large-8 xlarge-8 grid-column-alignment-left columns">
                            <Field
                                name="phone"
                                component={renderField}
                                type="text"
                                label={i18n.t("LABEL_COMMON_PHONE")}
                                required={true}
                                validate={[validate.required, validate.phoneNumber]}
                                normalize={normalizePhone}
                            />
                            <label className="prt-tcpa-disclosure" htmlFor="checkoutPhone">
                                    {i18n.t("LABEL_TCPA_DISCLOSURE")}
                            </label>
                            <button
                                type="button"
                                className={
                                    "ac-coupon-apply-button q-button q-primary-button gm-primary-btn stat-button-link"
                                }
                                
                                data-dtm="checkout step4:contact information"
                                onClick={handleSubmit}
                            >
                                {i18n.t("LABEL_SAVE_CONTACT_INFORMATION")}
                            </button>
                        </div>
                    </div>
                </form>
    );
    
    const renderAddressEditform = () => (
    <form className="ac-shipping-contact-form" onSubmit={handleSubmit}>
            <div className="gmit-gf-groupbox-content">
                <div className="row">
                    <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                        <Field
                            name="firstName"
                            type="text"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_FIRST_NAME")}
                            required={true}
                            validate={[validate.required, validate.minLength2, validate.maxLength64]}
                            className="stat-input-field"
                            maxLength={64}
                            minLength={2}
                        />
                    </div>
                    <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                        <Field
                            name="lastName"
                            type="text"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_LAST_NAME")}
                            required={true}
                            validate={[validate.required, validate.minLength2, validate.maxLength64]}
                            maxLength={64}
                            minLength={2}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                        <Field
                            name="companyName"
                            type="text"
                            component={renderField}
                            label={i18n.t("LABEL_COMMON_COMPANY_NAME")}
                            validate={[validate.minLength2, validate.maxLength64]}
                            maxLength={64}
                            minLength={2}
                        />
                    </div>
                </div>

                {!AppSettings.isT3 && renderCustomerAddressForm(stateList)}
                
                
            </div>
        </form>
    
    );
      switch(editForm){
        case 1: return renderAddressEditform()

        case 2: return renderEmailAndPhone()
      }

    
 };

export default withTranslation()(
reduxForm({
        form: "ReviewFormEdit", // a unique identifier for this form
        destroyOnUnmount: false, // <------ preserve form data
        enableReinitialize: true,
        forceUnregisterOnUnmount: true // <------ unregister fields on unmount
    })(ReviewFormEdit));
