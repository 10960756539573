import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Spinner from "../Spinner/Spinner";
import {
    resetVehicleConfig,
    selectBody,
    selectDrive,
    selectEngine,
    selectTrim,
    selectWheel
} from "./VehicleConfigRedux/VehicleConfigActionCreator";
import AppSettings, { getPageName } from "../../core/AppSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vaultConstants from "../../../config/vault_constants";

function VehicleConfigForm(props) {
    const [isFullVehicleDetails, setIsFullVehicleDetails] = useState(false);
    const [searchable, setSearchable] = useState();
    const [buttonVisible, setButtonVisible] = useState(false);
    const [showFullDetailsBtn, setShowFullDetailsBtn] = useState(false);
    const [fitmentFinished, setFitmentFinished] = useState(false);
    const stylingDropdown = props.stylingDropdown;
    const vehicleInfoData = useSelector((state) => state.Session);
    const homeSearchPageNames = new Set(["", "HomePage"]);
    const pdpSearchPageNames = new Set(["", "Product"]);
    const [displayPartial, setDisplayPartial] = useState(false);
    let newFitmentFlag;

    // check to see if current page should partial fitment displayed, not on HP
    function checkPageName() {
        if (homeSearchPageNames.has(getPageName())) {
            setDisplayPartial(false);
        } else if(pdpSearchPageNames.has(getPageName()) && window.location.pathname != "/product/search"){
            setDisplayPartial(false);
        } else {
            setDisplayPartial(true);
        }
    }

    //T3 consideration for new fitment flag
    if (AppSettings.isT3) {
        newFitmentFlag = false;
    } else if (AppSettings.isLocalHost) {
        newFitmentFlag = true;
    } else {
        newFitmentFlag = vaultConstants.FF_2237155_NEW_FITMENT_CONTAINER_ACC;
    }

    useEffect(() => {
        checkPageName();
        if (!props.InlineFitmentYMM) {
            props.reset();
        }
    }, [props.selectedYear, props.selectedMake, props.selectedModel, props.isVehicleSelectModalOpen]);

    //Automatically setting dropdown selections if there is only one options
    useEffect(() => {
        const body = props.bodyFitmentSelection;
        if (body.length == 1 && !props.fitmentVehicleInfo.bodyStyleId) {
            const bodyType = body[0];
            props.setBody(bodyType);
        }

        const wheel = props.wheelFitmentSelection;
        // todo: handle case when wheel was previously selected for diff vehicle
        if (wheel.length == 1 && !props.fitmentVehicleInfo.wheelBaseId) {
            const wheelType = wheel[0];
            props.setWheel(wheelType);
        }

        const trim = props.trimFitmentSelection;
        // todo: handle case when trim was previously selected for diff vehicle
        if (trim.length == 1 && !props.fitmentVehicleInfo.trimId) {
            const trimType = trim[0];
            props.setTrim(trimType);
        }

        const drive = props.driveFitmentSelection;
        // // todo: handle case when drive was previously selected for diff vehicle
        if (drive.length == 1 && !props.fitmentVehicleInfo.driveTypeId) {
            const driveType = drive[0];
            props.setDrive(driveType);
        }

        const engine = props.engineFitmentSelection;
        // todo: handle case when engine was previously selected for diff vehicle
        if (engine.length == 1 && !props.fitmentVehicleInfo.engineId) {
            const engineType = engine[0];
            props.setEngine(engineType);
        }
    });

    // enable button when all available selection have been made
    useEffect(() => {
        //If there is only one selection available for all the optional fitments then there is no need to show full details toggle
        const noOptionalFitment =
            props.vehicleParams.bodyParam &&
            props.wheelFitmentSelection.length <= 1 &&
            props.trimFitmentSelection.length <= 1 &&
            props.driveFitmentSelection.length <= 1 &&
            props.engineFitmentSelection.length <= 1;

        if (
            props.vehicleParams.bodyParam &&
            props.vehicleParams.driveParam &&
            props.vehicleParams.trimParam &&
            props.vehicleParams.wheelParam &&
            props.vehicleParams.engineParam
        ) {
            setFitmentFinished(true);
        } else {
            setFitmentFinished(false);
        }

        //Only allow user to see button if Model has been selected
        if (!props.selectedModel) {
            setButtonVisible(false);
        }
        //If the user is attempting to search by full details and the full fitment criteria
        //are not given the button is disabled
        if (newFitmentFlag) {
            if (isFullVehicleDetails && !fitmentFinished) {
                setSearchable(false);
            }
        } else if (
            props.fitmentVehicleInfo.bodyStyleId &&
            props.fitmentVehicleInfo.driveTypeId &&
            props.fitmentVehicleInfo.trimId &&
            props.fitmentVehicleInfo.wheelBaseId &&
            props.fitmentVehicleInfo.engineId
        ) {
            props.handleSearchAccessories();
        }
        //Handling showing button and full details
        if (props.selectedModel) {
            setButtonVisible(true);
            if (fitmentFinished) {
                setSearchable("full");
            }
        }
        if (props.vehicleParams.bodyParam && !isFullVehicleDetails && !noOptionalFitment) {
            setSearchable("partial");
            setShowFullDetailsBtn(true);
        }
        if (!props.vehicleParams.bodyParam) {
            setSearchable();
            setShowFullDetailsBtn(false);
        }
        if (vehicleInfoData.model && displayPartial) {
            setShowFullDetailsBtn(true);
        }
    }, [
        props.selectedModel,
        props.vehicleParams.bodyParam,
        props.vehicleParams.driveParam,
        props.vehicleParams.trimParam,
        props.vehicleParams.wheelParam,
        props.vehicleParams.engineParam,
        props.fitmentVehicleInfo.engineId,
        props.fitmentVehicleInfo.driveTypeId,
        isFullVehicleDetails,
        fitmentFinished
    ]);

    //Reset to partial fitment if user changes model
    useEffect(() => {
        setIsFullVehicleDetails(false);
    }, [props.selectedModel, props.selectedYear]);

    //disable descending drop downs until previous options have been selected because
    //selections are filtered as the user selects options
    function isWheelSelectDisabled() {
        return props.vehicleParams.bodyParam == "";
    }

    function isTrimSelectDisabled() {
        return props.vehicleParams.bodyParam == "" || props.vehicleParams.wheelParam == "";
    }

    function isDriveSelectDisabled() {
        return (
            props.vehicleParams.bodyParam == "" ||
            props.vehicleParams.wheelParam == "" ||
            props.vehicleParams.trimParam == ""
        );
    }

    function isEngineSelectDisabled() {
        return (
            props.vehicleParams.bodyParam == "" ||
            props.vehicleParams.wheelParam == "" ||
            props.vehicleParams.trimParam == "" ||
            props.vehicleParams.driveParam == ""
        );
    }

    function isBodyDisabled() {
        return props.selectedModel == "";
    }

    //Original dropdown building before new partial fitment - keeping
    //this until feature has been successfully deployed
    function OriginalVehicleConfigDropdowns() {
        const { t, vehicleParams } = props;

        let configDropdowns = [];

        //
        // BODY DROPDOWN
        //
        const body = props.bodyFitmentSelection;
        const bodyOptions = body.map((bodyType, key) => (
            <option id={bodyType} key={key} data-object={JSON.stringify(bodyType)} value={bodyType.BODY_TYPE_ID}>
                {bodyType.BODY_TYPE_NAME}
            </option>
        ));

        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_BODY_STYLE"),
                vehicleParams.bodyParam,
                bodyOptions,
                props.handleBodyChange
            )
        );

        //
        // WHEEL DROPDOWN
        //
        const wheel = props.wheelFitmentSelection;
        let boxOrWheel = "";

        const wheelOptions = wheel.map((wheelType, key) => (
            <option key={key} data-object={JSON.stringify(wheelType)} value={wheelType.WHEEL_BASE_ID}>
                {wheelType.WHEEL_LABEL}
            </option>
        ));

        //box/wheel dropdown select label should display box or wheel depending on data
        if (wheel[0]) {
            boxOrWheel = props.wheelFitmentSelection[0].WHEEL_DROPDOWN_LABEL;
        }

        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_BOX_OR_WHEEL_TYPE", { boxOrWheel }),
                vehicleParams.wheelParam,
                wheelOptions,
                props.handleWheelChange,
                isWheelSelectDisabled()
            )
        );

        //
        // TRIM DROPDOWN
        //
        const trim = props.trimFitmentSelection;
        const trimOptions = trim.map((trimType, key) => (
            <option key={key} data-object={JSON.stringify(trimType)} value={trimType.SUBMODEL_ID}>
                {trimType.SUBMODEL_NAME}
            </option>
        ));

        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_TRIM"),
                vehicleParams.trimParam,
                trimOptions,
                props.handleTrimChange,
                isTrimSelectDisabled()
            )
        );

        //
        // DRIVE DROPDOWN
        //
        const drive = props.driveFitmentSelection;
        const driveOptions = drive.map((driveType, key) => (
            <option key={key} data-object={JSON.stringify(driveType)} value={driveType.DRIVE_TYPE_ID}>
                {driveType.DRIVE_TYPE_NAME}
            </option>
        ));

        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_DRIVE_TYPE"),
                vehicleParams.driveParam,
                driveOptions,
                props.handleDriveChange,
                isDriveSelectDisabled()
            )
        );

        //
        // ENGINE DROPDOWN
        //
        const engine = props.engineFitmentSelection;
        const engineOptions = engine.map((engineType, key) => (
            <option key={key} data-object={JSON.stringify(engineType)} value={engineType.ENGINE_BASE_ID}>
                {engineType.ENGINE_LABEL}
            </option>
        ));

        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_ENGINE_TYPE"),
                vehicleParams.engineParam,
                engineOptions,
                props.handleEngineChange,
                isEngineSelectDisabled()
            )
        );

        return configDropdowns;
    }

    function VehicleConfigDropdowns() {
        const { t, vehicleParams } = props;

        let configDropdowns = [];

        //
        // BODY DROPDOWN
        //
        const body = props.bodyFitmentSelection;
        const bodyOptions = body.map((bodyType, key) => (
            <option id={bodyType} key={key} data-object={JSON.stringify(bodyType)} value={bodyType.BODY_TYPE_ID}>
                {bodyType.BODY_TYPE_NAME}
            </option>
        ));
        configDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_BODY_STYLE"),
                vehicleParams.bodyParam,
                bodyOptions,
                props.handleBodyChange,
                isBodyDisabled()
            )
        );

        return configDropdowns;
    }

    //Additional fitment that is not required but optional (SWOF)
    function PartialVehicleConfigDropdowns() {
        const { t, vehicleParams } = props;
        let partialConfigDropdowns = [];

        //
        // WHEEL DROPDOWN
        //
        const wheel = props.wheelFitmentSelection;
        let boxOrWheel = "";

        const wheelOptions = wheel.map((wheelType, key) => (
            <option key={key} data-object={JSON.stringify(wheelType)} value={wheelType.WHEEL_BASE_ID}>
                {wheelType.WHEEL_LABEL}
            </option>
        ));

        //box/wheel dropdown select label should display box or wheel depending on data
        if (wheel[0]) {
            boxOrWheel = props.wheelFitmentSelection[0].WHEEL_DROPDOWN_LABEL;
        }

        partialConfigDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_BOX_OR_WHEEL_TYPE", { boxOrWheel }),
                vehicleParams.wheelParam,
                wheelOptions,
                props.handleWheelChange,
                isWheelSelectDisabled(),
                true
            )
        );

        //
        // TRIM DROPDOWN
        //
        const trim = props.trimFitmentSelection;
        const trimOptions = trim.map((trimType, key) => (
            <option key={key} data-object={JSON.stringify(trimType)} value={trimType.SUBMODEL_ID}>
                {trimType.SUBMODEL_NAME}
            </option>
        ));

        partialConfigDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_TRIM"),
                vehicleParams.trimParam,
                trimOptions,
                props.handleTrimChange,
                isTrimSelectDisabled(),
                true
            )
        );

        //
        // DRIVE DROPDOWN
        //
        const drive = props.driveFitmentSelection;
        const driveOptions = drive.map((driveType, key) => (
            <option key={key} data-object={JSON.stringify(driveType)} value={driveType.DRIVE_TYPE_ID}>
                {driveType.DRIVE_TYPE_NAME}
            </option>
        ));

        partialConfigDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_DRIVE_TYPE"),
                vehicleParams.driveParam,
                driveOptions,
                props.handleDriveChange,
                isDriveSelectDisabled(),
                true
            )
        );

        //
        // ENGINE DROPDOWN
        //
        const engine = props.engineFitmentSelection;
        const engineOptions = engine.map((engineType, key) => (
            <option key={key} data-object={JSON.stringify(engineType)} value={engineType.ENGINE_BASE_ID}>
                {engineType.ENGINE_LABEL}
            </option>
        ));

        partialConfigDropdowns.push(
            renderDropdown(
                t("LABEL_COMMON_CHOOSE_ENGINE_TYPE"),
                vehicleParams.engineParam,
                engineOptions,
                props.handleEngineChange,
                isEngineSelectDisabled(),
                true
            )
        );
        return partialConfigDropdowns;
    }

    function toggleFullVehicleDetails() {
        setIsFullVehicleDetails(!isFullVehicleDetails);
    }

    function renderDropdown(label, value, options, onChange, disabled, optional) {
        let classNames = "ac-vehicle-config-dropdowns small-12 medium-6 large-6 columns new-swof-columns";
        AppSettings.isT3 ? (classNames += "xlarge-4") : "";

        return (
            <div key={label} className={props.vehicleSelectDropdownClass()} style={{ float: "left" }}>
                {/* hide dropdowns until they're selections are ready/filtered 
          by parent dropdowns or if there is only one option */}
                {/* !!TODO!!: Bug fix - save value to reducer for items with only one option (those are 
      supposed to be preselected for user and should be autosaved) */}
                <div
                    className={
                        "q-form-dropdown-label stat-dropdown-label " + (disabled || options.length <= 1 ? " hide" : "")
                    }
                >
                    {label.replace("Choose ", "") + (optional ? " (Optional)" : "")}
                </div>
                <select
                    className={"q-form-dropdown stat-dropdown " + (disabled || options.length <= 1 ? " hide" : "")}
                    id={label.replace(/ /g, "-") + "-dropdown"}
                    data-dtm={"search option:year-model search"}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                >
                    <option hidden value="">
                        {label}
                    </option>
                    {options}
                </select>
            </div>
        );
    }
    return (
        <>
            {/* SPINNER */}
            {props.isFetchingVehicleCombinations && (
                <div className="ac-config-overlay">
                    <div className="ac-config-spinner">
                        <Spinner />
                    </div>
                </div>
            )}
            {/* ERROR */}
            {props.showError && (
                <div className="small-12 columns">
                    <ErrorMessage icon={true} message={i18next.t("LABEL_SERVER_ERROR_MSG")} />
                </div>
            )}
            {/* DEFAULT DROPDOWNS ON HOMEPAGE*/}
            {!props.isFetchingVehicleCombinations && !displayPartial &&
                (!displayPartial && newFitmentFlag ? <VehicleConfigDropdowns /> : <OriginalVehicleConfigDropdowns />)}
            {!displayPartial && newFitmentFlag && isFullVehicleDetails && !props.isFetchingVehicleCombinations && (
                <PartialVehicleConfigDropdowns />
            )}

            {newFitmentFlag && !displayPartial && (
                <>
                    <div
                        className={
                            "small-12 medium-12 large-6 xlarge-" +
                            (stylingDropdown === "twoColumns" ? 6 : stylingDropdown === "partialDropdowns" ? 4 : 3) +
                            " columns"
                        }
                    >
                        {buttonVisible ? (
                            <button
                                id="ac-shop-products-btn"
                                data-dtm="search option"
                                className="stat-button-link ac-shop-btn gb-primary-button:focus:active "
                                disabled={!searchable}
                                onClick={() => {
                                    props.handleSearchAccessories(searchable);
                                }}
                            >
                                {i18next.t("LABEL_COMMON_HOMEPAGE_SEARCH")}
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="small-12 medium-12 large-12 xlarge-12 columns">
                        <a
                            id="ac-partial-fitment-search"
                            data-dtm = "search option"
                            className={
                                showFullDetailsBtn
                                    ? "q-link q-button-small q-button-padding-none stat-text-link ac-accessible-link stat-expand-icon"
                                    : "hide"
                            }
                            onClick={toggleFullVehicleDetails}
                        >
                            {isFullVehicleDetails
                                ? i18next.t("LABEL_COMMON_HIDE_FULL_VEHICLE_DETAILS")
                                : i18next.t("LABEL_COMMON_SHOP_FULL_VEHICLE_DETAILS")}
                            <FontAwesomeIcon icon={isFullVehicleDetails ? "angle-up" : "angle-down"} />
                        </a>
                    </div>
                </>
            )}

            {/* DROPDOWNS NOT ON HOMEPAGE WITHOUT SESSION*/}
            {!props.isFetchingVehicleCombinations && displayPartial && !vehicleInfoData.model &&
                (displayPartial && newFitmentFlag ? <VehicleConfigDropdowns /> : <OriginalVehicleConfigDropdowns />)}
            {displayPartial && newFitmentFlag && isFullVehicleDetails && !props.isFetchingVehicleCombinations && !vehicleInfoData.model && (
                <PartialVehicleConfigDropdowns />
            )}

            {newFitmentFlag && displayPartial && !vehicleInfoData.model && (
                <>
                    <div
                        className={
                            "small-12 medium-12 large-6 xlarge-" +
                            (stylingDropdown === "twoColumns" ? 6 : stylingDropdown === "partialDropdowns" ? 4 : 3) +
                            " columns"
                        }
                    >
                        {buttonVisible ? (
                            <button
                                id="ac-shop-products-btn"
                                data-dtm="search option"
                                className="stat-button-link ac-shop-btn gb-primary-button:focus:active "
                                disabled={!searchable}
                                onClick={() => {
                                    props.handleSearchAccessories(searchable);
                                }}
                            >
                                {i18next.t("LABEL_COMMON_HOMEPAGE_SEARCH")}
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="small-12 medium-12 large-12 xlarge-12 columns">
                        <a
                            id="ac-partial-fitment-search"
                            data-dtm = "search option"
                            className={
                                showFullDetailsBtn
                                    ? "q-link q-button-small q-button-padding-none stat-text-link ac-accessible-link stat-expand-icon"
                                    : "hide"
                            }
                            onClick={toggleFullVehicleDetails}
                        >
                            {isFullVehicleDetails
                                ? i18next.t("LABEL_COMMON_HIDE_FULL_VEHICLE_DETAILS")
                                : i18next.t("LABEL_COMMON_SHOP_FULL_VEHICLE_DETAILS")}
                            <FontAwesomeIcon icon={isFullVehicleDetails ? "angle-up" : "angle-down"} />
                        </a>
                    </div>
                </>
            )}

            {/* DROPDOWNS NOT ON HOMEPAGE WITH SESSION*/}
            {vehicleInfoData.model && isFullVehicleDetails && newFitmentFlag && displayPartial && <PartialVehicleConfigDropdowns />}
            {vehicleInfoData.model && isFullVehicleDetails && newFitmentFlag && displayPartial && <div
                className={
                    "small-12 medium-12 large-3 xlarge- partial-no-padding " +
                    (stylingDropdown === "twoColumns" ? 6 : stylingDropdown === "partialDropdowns" ? 4 : 3) +
                    " columns"
                }
            >
                {buttonVisible ? (
                    <button
                        id="ac-shop-products-btn"
                        data-dtm="search option"
                        className="stat-button-link ac-shop-btn "
                        disabled={!searchable}
                        onClick={() => {
                            props.handleSearchAccessories(searchable);
                        }}
                    >
                        {i18next.t("LABEL_COMMON_HOMEPAGE_SEARCH")}
                    </button>
                ) : (
                    ""
                )}
            </div>
            }
            {vehicleInfoData.model && newFitmentFlag && displayPartial &&
                <div className="small-12 medium-12 large-12 xlarge-12 columns partial-no-padding ">
                    <a
                        id="ac-partial-fitment-search"
                        data-dtm = "search option"
                        className={
                            showFullDetailsBtn
                                ? "partial-no-padding q-link q-button-small q-button-padding-none stat-text-link ac-accessible-link stat-expand-icon"
                                : "hide"
                        }
                        onClick={toggleFullVehicleDetails}
                    >
                        {isFullVehicleDetails
                            ? i18next.t("LABEL_COMMON_HIDE_FULL_VEHICLE_DETAILS")
                            : i18next.t("LABEL_COMMON_SHOP_FULL_VEHICLE_DETAILS")}
                        <FontAwesomeIcon icon={isFullVehicleDetails ? "angle-up" : "angle-down"} />
                    </a>
                </div>
            }

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedYear: state.VehicleSelectReducer.selectedYear,
        selectedMake: state.VehicleSelectReducer.selectedMake,
        selectedModel: state.VehicleSelectReducer.selectedModel,
        isVehicleSelectModalOpen: state.VehicleSelectReducer.isOpen
    };
};

function mapDispatchToProps(dispatch, ownProps) {
    const vehicleCombinations = ownProps.vehicleCombinations;
    const bodyFitmentSelection = ownProps.bodyFitmentSelection;
    const wheelFitmentSelection = ownProps.wheelFitmentSelection;
    const trimFitmentSelection = ownProps.trimFitmentSelection;
    const driveFitmentSelection = ownProps.driveFitmentSelection;
    const engineFitmentSelection = ownProps.engineFitmentSelection;
    const vehicleParams = ownProps.vehicleParams;
    const fitmentVehicleInfo = ownProps.fitmentVehicleInfo;
    const dropdownLabel = ownProps.wheelDropDownLabel;

    return {
        handleBodyChange: (e) => {
            const selectedBodyValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
            dispatch(
                selectBody(
                    selectedBodyValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    dropdownLabel,
                    bodyFitmentSelection
                )
            );
        },
        handleWheelChange: (e) => {
            const selectedWheelValues = JSON.parse(
                e.target.options[e.target.selectedIndex].getAttribute("data-object")
            );
            dispatch(
                selectWheel(
                    selectedWheelValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection
                )
            );
        },
        handleTrimChange: (e) => {
            const selectedTrimValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
            dispatch(
                selectTrim(
                    selectedTrimValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection
                )
            );
        },
        handleDriveChange: (e) => {
            const selectedDriveValues = JSON.parse(
                e.target.options[e.target.selectedIndex].getAttribute("data-object")
            );
            dispatch(
                selectDrive(
                    selectedDriveValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        handleEngineChange: (e) => {
            const selectedEngineValues = JSON.parse(
                e.target.options[e.target.selectedIndex].getAttribute("data-object")
            );
            dispatch(
                selectEngine(
                    selectedEngineValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection,
                    engineFitmentSelection
                )
            );
        },
        setBody: (bodyValues) => {
            dispatch(
                selectBody(
                    bodyValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        setWheel: (wheelValues) => {
            dispatch(
                selectWheel(
                    wheelValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        setTrim: (trimValues) => {
            dispatch(
                selectTrim(
                    trimValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        setDrive: (driveValues) => {
            dispatch(
                selectDrive(
                    driveValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        setEngine: (engineValues) => {
            dispatch(
                selectEngine(
                    engineValues,
                    fitmentVehicleInfo,
                    vehicleParams,
                    vehicleCombinations,
                    bodyFitmentSelection,
                    wheelFitmentSelection,
                    trimFitmentSelection,
                    driveFitmentSelection
                )
            );
        },
        reset: () => {
            dispatch(resetVehicleConfig());
        }
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VehicleConfigForm));
