import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../shared/Button/Button";
import accHistory from "../../../App/History";

/**
 * Render a warning to tell the user to empty their cart
 * to be able to redealerize.
 */
export default function CartRefillWarning(props) {
    const { t } = useTranslation();

    function handleGoToCart() {
        accHistory.push("/cart");
        props.handleClose();
    }

    /* Render Cart Re-Fill Warning*/
    return (
        <>
            <div id="prt-dealer-locator-warning-container" className="prt-dealer-locator-warning">
                <span className="prt-dealer-locator-warning-title">
                    {t("DEALER_LOCATOR_REDEALERIZE_WARNING_TITLE")}
                </span>
                <hr className="prt-dealer-locator-warning-separator" />
                <span className="prt-dealer-locator-warning-description">
                    {t("DEALER_LOCATOR_REDEALERIZE_WARNING_DESCRIPTION")}
                </span>
            </div>
            <Button
                className="prt-dealer-locator-cart-button"
                onClick={handleGoToCart}
                localeKey={t("LABEL_COMMON_GO_TO_CART")}
            />
        </>
    );
}
