import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import PersonalInfoDetails from './PersonalInfoDetails/PersonalInfoDetails';

class PersonalInformation extends Component {
    render(){
        return(
            <Translation>
                {
                    (t)=>(
                        <div className="ac-personal-info small-12 grid-column-alignment-left columns">
                            <h3 className="q-headline3">{t("LABEL_PROFILE_PERSONAL_INFO")}</h3>
                            <div>
                                <PersonalInfoDetails
                                    profilesettings={this.props.profilesettings}
                                    togglePhoneEdit={this.props.togglePhoneEdit}
                                    resetProfileToggle={this.props.resetProfileToggle}/>
                            </div>

                        </div>
                    )
                }

            </Translation>
        )
    }
}

export default PersonalInformation;