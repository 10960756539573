
import React, { Component } from 'react';

export class MobileTabs extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <div>

                {
                    /*************** DESCRIPTION TITLE AND CONTENT *******************************************/

                    this.props.isDescription && <div className={this.props.reducerData.descriptionClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_DESCRIPTION" onClick={this.props.openPanel}>{this.props.descriptionTitle}</div>
                        <div className="q-expander-content">
                            {this.props.descriptionContent}
                        </div>
                    </div>
                }

                {
                    /*************** WHEEL PACKAGE TITLE AND CONTENT *******************************************/

                    this.props.isWheelPackage && <div className={this.props.reducerData.wheelPackageClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_WHEEL_PACKAGE" onClick={this.props.openPanel}>{this.props.wheelPackageTitle}</div>
                        <div className="q-expander-content">
                            {this.props.wheelPackageContent}
                        </div>
                    </div>
                }

                {
                    /*************** WARRANTY TITLE AND CONTENT *******************************************/
                    this.props.isWarranty && <div className={this.props.reducerData.warrantyClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_WARRANTY" onClick={this.props.openPanel}>{this.props.warrantyTitle}</div>
                        <div className="q-expander-content">
                            {this.props.warrantyContent}
                        </div>
                    </div>
                }

                {
                    /*************** SPECIFICATION TITLE AND CONTENT *******************************************/
                    this.props.isSpecs && <div className={this.props.reducerData.specificationClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_SPECIFICATION" onClick={this.props.openPanel}>{this.props.specificationTitle}</div>
                        <div className="q-expander-content">
                            {this.props.specificationContent}
                        </div>
                    </div>
                }

                {
                    /*************** DOCUMENTATION TITLE AND CONTENT *******************************************/
                    this.props.isDocumentation && <div className={this.props.reducerData.documentationClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_DOCUMENTATION" onClick={this.props.openPanel}>{this.props.documentationTitle}</div>
                        <div className="q-expander-content">
                            {this.props.documentationContent}
                        </div>
                    </div>
                }

                {
                    /*************** FAQ TITLE AND CONTENT *******************************************/
                    this.props.isFaq && <div className={this.props.reducerData.faqClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_FAQ" onClick={this.props.openPanel}>{this.props.faqTitle}</div>
                        <div className="q-expander-content">
                            {this.props.faqContent}
                        </div>
                    </div>
                }

                {
                    /*************** TROUBLESHOOTING TITLE AND CONTENT *******************************************/
                    this.props.isTroubleshooting && <div className={this.props.reducerData.troubleshootingClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_TROUBLESHOOTING" onClick={this.props.openPanel}>{this.props.troubleshootingTitle}</div>
                        <div className="q-expander-content">
                            {this.props.troubleshootContent}
                        </div>
                    </div>
                }

                {
                    /*************** MAINTENANCE TITLE AND CONTENT *******************************************/
                    this.props.isMaintenance && <div className={this.props.reducerData.maintenanceClass}>
                        <div className="q-headline q-expander-button stat-expand-icon" data-dtm={"product details"} id="CLICKED_ON_MAINTENANCE" onClick={this.props.openPanel}>{this.props.maintenanceTitle}</div>
                        <div className="q-expander-content">
                            {this.props.maintenanceContent}
                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default MobileTabs



