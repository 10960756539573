import * as type from "./VehicleSelectActionType";
import AppSettings from "../../../core/AppSettings";

function initialState() {
    let years = [];
    const currentYear = new Date().getFullYear();
    for (let y = currentYear; y >= currentYear - 10; y--) {
        years.push(y);
    }
    return {
        showLoadingBtn: false,
        //Category Landing Page
        clpRedirect: false,
        clpCategory: "",
        // YMM
        years: years,
        selectedYear: "",
        selectedMake: AppSettings.currentSite.label,
        selectedModel: "",
        yearsToModels: {},
        makeToCode: { Buick: "45", Cadillac: "46", Chevrolet: "47", GMC: "48" },
        codeToMake: { 45: "Buick", 46: "Cadillac", 47: "Chevrolet", 48: "GMC" },
        modelToModelID: {},
        showLoadMakesError: false,
        showLoadModelsError: false,
        t3DefaultMake: "",

        // VIN
        vin: "",
        vinResponse: {},
        showVinInstructions: false,
        showLoadVinError: false,
        rawMakes: "",
        rawModels: "",
        yearsToMakes: {},

        //modal
        isOpen: false
    };
}

const VehicleSelectActionType = (state = initialState(), action) => {
    switch (action.type) {
        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.SET_CLP_REDIRECT:
            return Object.assign({}, state, { clpRedirect: true, clpCategory: action.category });

        case type.SET_YEARS:
            return Object.assign({}, state, { years: action.years });

        case type.LOAD_MAKES:
            return Object.assign({}, state, {
                yearsToMakes: action.yearsToMakes,
                rawMakes: action.rawMakes,
                showLoadMakesError: false,
                showLoadModelsError: false
            });

        case type.LOAD_MODELS:
            return Object.assign({}, state, {
                yearsToModels: action.yearsToModels,
                rawModels: action.rawModels,
                showLoadModelsError: false
            });

        case type.LOAD_VIN_DATA_ASYNC:
            return Object.assign({}, state, { vinErrorLabel: "", showLoadingBtn: true, showLoadVinError: false });

        case type.SET_VIN:
            return Object.assign({}, state, {
                vinResponse: action.response,
                vin: action.vin,
                vinErrorLabel: action.msg
            });

        case type.RESET_VIN:
            return {
                ...state,
                vinResponse: {},
                vin: "",
                vinErrorLabel: "",
                showLoadingBtn: false,
                showLoadVinError: false
            };

        case type.RESET_VEHICLE_SELECT_STATE:
            //don't clear out years which are fetched via AJAX
            return { ...initialState(), years: state.years };

        case type.SET_T3_DEFAULT_MAKE:
            return Object.assign({}, state, { t3DefaultMake: action.t3DefaultMake });

        case type.SET_MODEL_TO_MODEL_ID:
            return Object.assign({}, state, { modelToModelID: action.modelToModelID });

        /**************************************************************/
        // Actions triggered by the user
        /**************************************************************/
        case type.SELECT_YEAR:
            if (!AppSettings.isT3) {
                return Object.assign({}, state, {
                    selectedYear: action.year,
                    selectedMake: AppSettings.currentSite.label,
                    selectedModel: ""
                });
            } else {
                const t3DefaultMake = state.t3DefaultMake;
                return Object.assign({}, state, {
                    selectedYear: action.year,
                    selectedMake: t3DefaultMake,
                    selectedModel: ""
                });
            }

        case type.SELECT_MAKE:
            //const code = state.makeToCode[action.make];
            return Object.assign({}, state, { selectedMake: action.make, selectedModel: "" });

        case type.SELECT_MODEL:
            return Object.assign({}, state, { selectedModel: action.model });

        case type.TOGGLE_VIN_INSTRUCTIONS:
            let showVinInst = !state.showVinInstructions;
            return Object.assign({}, state, { showVinInstructions: showVinInst });

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_MODELS_FAIL:
            return { ...state, yearsToModels: {}, showLoadModelsError: true };
        case type.LOAD_MAKES_FAIL:
            return { ...state, yearsToMakes: {}, showLoadMakesError: true };
        case type.LOAD_VIN_DATA_ASYNC_FAIL:
            return { ...state, vinErrorLabel: "LABEL_COMMON_ENTER_VIN_ERROR", showLoadVinError: true, showError: true };
        case type.SET_VIN_FAIL:
            return { ...state, vinErrorLabel: "LABEL_COMMON_ENTER_VIN_ERROR", showLoadVinError: true, showError: true };

        /**************************************************************/
        // Handling modal
        /**************************************************************/
        case type.TOGGLE_MODAL:
            let isOpen = !state.isOpen;
            if (state.isOpen) {
                //closing modal = reset
                return { ...initialState(), years: state.years };
            } else {
                return Object.assign({}, state, { isOpen: isOpen });
            }

        default:
            return state;
    }
};

export default VehicleSelectActionType;
