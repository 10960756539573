import axios from 'axios';
import AppSettings from '../../../../core/AppSettings';

const headerESpotUrl = "/wcs/resources/store/" + AppSettings.storeId + "/espot/HeaderStoreLogo_Content";
const dealerDataUrl = "/wcs/resources/store/" + AppSettings.storeId + "/GMPartner/" + AppSettings.bac + "/dealer" + AppSettings.dealerName + "?langId=-1";


const flyoutEspot = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Auth_Flyout?noCache=true";


export const getFlyoutText = () => {
    return axios.get(flyoutEspot).then((response) => { return response; }, (error) => { return error; });
};

export const getLogo = () => {
    return axios.get(headerESpotUrl).then((response) => { return response; }, (error) => { return error; });
};

export const getDealerData = () => {
    return axios.get(dealerDataUrl).then((response) => { return response; }, (error) => { return error; });
};
