/** COMPONENT NAME : DEALER LIST WRAPPER (Parent of the Dealer List, Loop through the List dealer )**/

/** IMPORT STATEMENT **/
import React from 'react';
import DealerList from "../DealerList/DealerList";
import PropTypes from 'prop-types';

const DealerListWrapper = (props)=>{
    const {dealers} = props;
        return <div className="ac-dealerListWrapper">
        {
            dealers.map((item,index)=>{
                let lastItem = false;
                let indexItem = index + 1;
                if(indexItem === dealers.length){
                    lastItem = true;
                }
                return <DealerList item={item} key={index} count={indexItem} lastIndex={lastItem}/>

            })
        }
    </div>
};

/*** PROP TYPES VALIDATION ***/
 DealerListWrapper.propTypes = {
        dealers : PropTypes.array
 };

export default DealerListWrapper

