import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import store from '../../../core/Redux/Store';

import HomeCarousel from './HomeCarousel';
import { loadHomeCarouselDataAsync } from './HomeCarouselRedux/HomeCarouselActionCreator';
// The home carousel recieves it's data from an espot.
//      The response is parsed in HomeCarouselWorker.js
//      and the result is mapped in HomeCarousel.js where Slick
//      Slider is used to create the carousel.
//
//  Dev: NikiL

class HomeCarouselContainer extends Component {

    componentDidMount(){
        const action = loadHomeCarouselDataAsync()
        store.dispatch(action);
    }
    
    render(){
        return (
            <div className="q-multimedia-scroller q-mod q-mod-multimedia-scroller">
                <HomeCarousel />
            </div>
        )
    }
}

const wrapper = document.getElementById("home-carousel");
wrapper ? ReactDOM.render(<HomeCarouselContainer />, wrapper) : false;

export default HomeCarouselContainer;