import * as type from './OrderConfirmActionTypes';

let initialState = {
  orderInfoDataLoaded: false,
  orderPaymentDataLoaded: false,
  pageLoaded: false,
  paymentInfo: {},
  order:{}
};

const OrderConfirmReducer = (state = initialState, action) => {
  switch (action.type) {

    case type.ORDER_CONFIRM_SET_ORDER_DATA:
      return Object.assign({},state,{order:action.orderData,orderInfoDataLoaded:true, pageLoaded: true, parsedResponse: action.parsedResponse});
    
    case type.ORDER_CONFIRM_SET_PAYMENT_INFO:
      return Object.assign({},state,{paymentInfo:action.paymentInfo,orderPaymentDataLoaded:true, pageLoaded: true});

    case type.ORDER_CONFIRM_ORDER_DATA_FAIL:
      return Object.assign({}, state, {pageLoaded: true,error: action.error});

    case type.ORDER_CONFIRM_PAYMENT_CONFIRM_FAIL:
      return Object.assign({}, state, {pageLoaded: true,error: action.error, });

    default:
      return state;
  }
}

export default OrderConfirmReducer;