/*** DEALER LOCATOR WORKER ***/

/** IMPORT STATEMENT **/
import {call,put,select} from 'redux-saga/effects';
import {reset} from 'redux-form';
import {getDealerListData,
        updateSelectedDealer} from './DealerLocatorService';
import {formatDealerData,
        loadDealerData,
        loadDealerDataAsyncFailed,
        noDealerFoundError,
        closeChangeDealerLocatorModal,
        failedDealerLocatorUpdate} from './DealerLocatorActionCreators';
import {ORDER_INFO_ORDER_IN_CART_ASYNC,ORDER_INFO_SHIPPING_RATES} from "../../OrderInfo/OrderInfoRedux/OrderInfoActionTypes";
import {loadCouponData} from "../../../modules/Checkout/CheckoutRedux/CheckoutWorker";

const getOrderId = state => state.OrderInfoReducer.parsedResponse.orderId;





/*** GENERATOR FUNCTION TO GET THE DEALER LIST DATA, DISPATCHING AN ACTION BASED ON THE REST CALL RESPONSE***/
export function * getDealerData(action){

    const successCode = "AC-DIG-200";
    const badResponseCode = "AC-DIG-430";

    try {

        const response = yield call(getDealerListData,action);
        const responseCode = response.responseCode;


        if(responseCode === successCode && response.dealers.length){
            const dealerData = formatDealerData(response.dealers);
            yield put(loadDealerData(dealerData));


         }
        else {
            yield put(noDealerFoundError());
            console.log("No Dealer Found =>",response);
        }

    }
    catch(error){
       yield put(loadDealerDataAsyncFailed(error));
    }
};


/** GENERATOR FUNCTION TO HANDLE REST CALL WHEN USER CHANGE THE DEALER ***/
export function * handleContinueButton(action){

    try {
        const response =  yield call(updateSelectedDealer,action.payload.selectedDealer);
        if(response.status === 200){
            const orderId = yield select(getOrderId);
            yield loadCouponData(orderId);
            yield put({type:ORDER_INFO_SHIPPING_RATES});
            yield put({type:ORDER_INFO_ORDER_IN_CART_ASYNC});
            yield put(reset("DeliveryForm"));
            yield put(closeChangeDealerLocatorModal());
        }
    }
    catch(error){
        yield put(failedDealerLocatorUpdate(error));
    }
}