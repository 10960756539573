//commerce user login
export const AUTHENTICATION_USER_LOGIN_REQUEST = 'AUTHENTICATION_LOGIN_REQUEST';
export const AUTHENTICATION_USER_LOGIN_SUCCESS = 'AUTHENTICATION_USER_LOGIN_SUCCESS';
export const AUTHENTICATION_USER_LOGIN_FAILURE = 'AUTHENTICATION_USER_LOGIN_FAILURE';
export const AUTHENTICATION_USER_LOGIN_UNVERIFIED_FAILURE = 'AUTHENTICATION_USER_LOGIN_UNVERIFIED_FAILURE';
export const AUTHENTICATION_NOTIFICATION_MODAL = 'AUTHENTICATION_NOTIFICATION_MODAL';
export const AUTHENTICATION_CHECK_SSO = 'AUTHENTICATION_CHECK_SSO';

//commerce user logout
export const AUTHENTICATION_USER_LOGOUT_REQUEST = 'AUTHENTICATION_USER_LOGOUT_REQUEST';
export const AUTHENTICATION_USER_LOGOUT_SUCCESS = 'AUTHENTICATION_USER_LOGOUT_SUCCESS';
export const AUTHENTICATION_USER_LOGOUT_FAILURE = 'AUTHENTICATION_USER_LOGOUT_FAILURE';

//guest user logout
export const AUTHENTICATION_GUEST_LOGOUT_REQUEST = 'AUTHENTICATION_GUEST_LOGOUT_REQUEST';
export const AUTHENTICATION_GUEST_LOGOUT_SUCCESS = 'AUTHENTICATION_GUEST_LOGOUT_SUCCESS';
export const AUTHENTICATION_GUEST_LOGOUT_FAILURE = 'AUTHENTICATION_GUEST_LOGOUT_FAILURE';

//token and state parsing
export const AUTHENTICATION_SET_PARSED_VALUES = 'AUTHENTICATION_SET_PARSED_VALUES';

//registration status determination
export const AUTHENTICATION_REGISTRATION_STATUS_REQUEST = 'AUTHENTICATION_REGISTRATION_STATUS_REQUEST';
export const SET_REGISTRATION_STATUS = 'SET_REGISTRATION_STATUS';

//user information
export const AUTHENTICATION_RETRIVE_USER_INFO = 'AUTHENTICATION_RETRIVE_USER_INFO';
export const AUTHENTICATION_SET_USER_INFO = 'AUTHENTICATION_SET_USER_INFO';
export const AUTHENTICATION_SET_ENROLLED_ATTRIBUTE_VALUE = 'AUTHENTICATION_SET_ENROLLED_ATTRIBUTE_VALUE';

// auth cookie status
export const AUTHENTICATION_SET_COOKIE_EXPIRY_STATUS = 'AUTHENTICATION_SET_COOKIE_EXPIRY_STATUS';


// render app
export const RENDER_APP = 'RENDER_APP_AFTER_SELF_CALL_IS_RESLOVED';
export const ADD_WRAPPER_FOR_PAGE_REDIRECT = 'ADD_WRAPPER_FOR_PAGE_REDIRECT';

//SSO check
export const AZURE_SINGLE_SIGN_ON_CHECK = 'AZURE_SINGLE_SIGN_ON_CHECK';


