import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../../core/Redux/Store';
import ConfirmationContainer from './ConfirmationContainer/ConfirmationContainer';
import SummaryRailContainer from '../../shared/SummaryRail/SummaryRailContainer';
import Spinner from '../../shared/Spinner/Spinner';
import PageNameHeader from '../../shared/PageNameHeader/PageNameHeader';
import { ORDER_CONFIRM_GET_ORDER_DATA_ASYNC, ORDER_CONFIRM_GET_PAYMENT_INFO_ASYNC } from './OrderConfirmRedux/OrderConfirmActionTypes';
import { Translation } from 'react-i18next';
import { titleSetUp, setBacIfT1,removePromoCode } from "../../shared/Utils/Utils";
import { withTranslation } from 'react-i18next';
import GMRewardsCardOffer from '../../shared/CartComponents/GMRewardsCardOffer/GMRewardsCardOffer';

class OrderConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analyticsFired: false
    };

    /** Title set up **/
    titleSetUp(this.props.t("LABEL_ORDER_CONFIRMATION"));
    /** Remove bac from T1 when order is complete  **/
    setBacIfT1(-9999);

    /*** Remove PromoCode from local storage ***/
    removePromoCode();
  }


  componentDidUpdate() {
    // Makes analtyics direct call for purchase confirmation/receipt page.
    if (typeof _satellite != 'undefined' && !this.state.analyticsFired) {
      _satellite.track('purchased');
      this.setState({
        analyticsFired: true
      });
    }
  }

  componentWillMount() {
    store.dispatch({ type: ORDER_CONFIRM_GET_ORDER_DATA_ASYNC });
    store.dispatch({ type: ORDER_CONFIRM_GET_PAYMENT_INFO_ASYNC });
  }

  render() {

    // handle data
    const orderDataInfo = this.props.orderData.order.order;
    const paymentInfo = this.props.orderData.paymentInfo;
    const orderPaymentDataLoaded = this.props.orderData.orderPaymentDataLoaded;
    const orderInfoDataLoaded = this.props.orderData.orderInfoDataLoaded;
    const pageLoaded = this.props.orderData.pageLoaded;

    let parsedOrderInCart = '';
    if (orderDataInfo) {
      parsedOrderInCart = this.props.orderData.parsedResponse;
    }

    return (
      <Translation>
        {(t) => (
          <div className="ac-order-confirm">
            <div className="row q-gridbuilder gridfullwidth ac-small-sidepadding">
              <PageNameHeader pageHeader={t("LABEL_ORDER_CONFIRMATION")} />
              {
                !pageLoaded && 
                <div className="small-12 columns"><Spinner /></div>
              }
              <div className="small-12 medium-12 large-6 xlarge-6 grid-column-alignment-left columns">
                {
                  /** RENDER LEFT PAYMENT INFO WHEN DATA IS LOADED CORRECTLY**/
                  orderPaymentDataLoaded && pageLoaded && 
                  <ConfirmationContainer order={orderDataInfo} payment={paymentInfo} />
                }
              </div>
              <div className="small-12 medium-12 large-6 xlarge-6 columns ac-summary-wrapper">
                {
                  /** RENDER SUMMARY RAIL WHEN DATA IS LOADED CORRECTLY**/
                  orderInfoDataLoaded && pageLoaded && 
                  <SummaryRailContainer data={parsedOrderInCart} />
                }
              </div>
            </div>
            {pageLoaded && this.props.rewardsData.isRewardsActive && this.props.rewardsData.applyForGMCard === 'Y' && <GMRewardsCardOffer/>}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderData: state.OrderConfirmReducer,
    rewardsData: state.RewardsReducer
  }
}

export default withTranslation()(connect(mapStateToProps)(OrderConfirmation));
