import * as types from './DealerLocatorActionTypes'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next'



/** DISPATCH AN ACTION TO CANCEL THE MODAL **/
export const closeChangeDealerLocatorModal = ()=>({type:types.CANCEL_BUTTON});

/** DISPATCH AN ACTION TO MAKE ASYNC CALL, OPEN THE MODAL AND SHOW MAP **/
export const loadDealerLocatorDataAsync = (param)=>({type:types.LOAD_DEALER_DATA_ASYNC,payload:param});

/*** DISPATCH AN ACTION WHEN REST CALL FAILED ***/
export const loadDealerDataAsyncFailed = (error)=>({type:types.LOAD_DEALER_DATA_ASYNC_FAILED,error:error});

/** DISPATCH AN ACTION WHEN NO DEALERS WERE FOUND ***/
export const noDealerFoundError = ()=>({type:types.NO_DEALER_FOUND_ERROR});

/** UPDATE DEALER SEARCH BASED ON THE NEW ZIP CODE VALUE **/
export const updateDealerSearch = (value)=>({type:types.UPDATE_ZIP_CODE,payload:value});

/** TRACK AND UPDATE NEW ZIP CODE INPUT **/
export const updateInputValue = (value)=>({type:types.CHANGE_ZIP_CODE,payload:value});

/** DISPATCH AN ACTION TO HANDLE REST CALL WHEN USER CLICK ON CONTINUE BUTTON AFTER SELECTING A DEALER  **/
export const handleContinueButton = (value)=>({type:types.CONTINUE_BUTTON,payload:value});

/** DISPATCH AN ACTION TO SHOW ERROR WHEN UPDATE DEALER IS FAILED**/
export const failedDealerLocatorUpdate = (error)=>({type:types.FAILED_UPDATE_DEALER,error:error});

/** DISPATCH AN ACTION TO UPDATE THE COORDINATES WHEN USER CHANGE THE DEALER **/
export const handleSelectDealer = (value)=>({type:types.SELECT_DEALER,payload:{
        selectedBac:value.bac,
        selectedCoordinates:value.coordinates

    }});

/** DISPATCH AN ACTION WITH PAYLOAD WHEN ASYNC REST CALL IS SUCCESS ***/
export const loadDealerData = (data)=>({type:types.LOAD_DEALER_DATA,payload:{
        dealers:data,
        coordinates:data[0].coordinates
    }});


/** FUNCTION TO VALIDATE USER INPUT, RETURN TRUE IF ITS NUMBER, ELSE IT WOULD RETURN EMPTY**/
export const validateInput = (val)=> val.replace(/[^\d]/g, '');

/** LOOP THROUGH THE ARRAY AND REMOVE CLASS THAT HAS 'SELECTED' CLASS NAME BEFORE ADDING IT TO NEW ELEMENT **/
const removeClassName = ()=>{
    const checkEl = document.getElementsByClassName("ac-dealerListInfoWrapper");
        for(let i=0; i<checkEl.length;i++){
        checkEl[i].classList.remove('selected');
    }
};

/** FUNCTION THAT ACCEPTS ID AS PARAM, MOVE THE SCROLLER VERTICAL TO PUT THE DEALER INFO ON THE CENTER,
 * (THE ID IS DYNAMIC AND SHOULDN'T BE USED FOR STATIC CONTENT)
 * IT WILL ALSO ADD THE SELECTED CLASS NAME TO DOM, SO ADDITIONAL  STYLE CAN BE APPLIED.
 *
 * ***/
export const handleScroller = (id)=>{
    const el = document.getElementById(id);
    el.scrollIntoView({behavior:"smooth"});
    removeClassName();
    el.classList.add('selected');
    };


/** THIS FUNCTION WILL TAKE RAW DATA AS PARAM, FORMAT IT AND RETURN ONLY THE DATA THAT NEEDED FOR COMPONENT**/
export const formatDealerData = (param)=>{
    let dealers = [];
    const rawData = param;
    for(let i=0; i<rawData.length;i++){
        const markerCount = i + 1;
        let data = {};
        let geoLocation = {};

        let address = rawData[i].addressInfo.localizations[0].NAAddress;
        let distanceInfo = rawData[i].proximityInfo;
        let phoneNo = rawData[i].contactInfo.phone;
        let rawDistance = parseFloat(distanceInfo.distance);

        if(distanceInfo.uom === 'mi'){
           data.distanceMetric = 'Miles';
        }
        else
            data.distanceMetric = distanceInfo.uom;

        data.address1 = address.address1;
        data.address2 = address.address2;
        data.city = address.city;
        data.postalCode = address.postalCode.slice(0,5);
        data.phone = phoneNo.slice(0,3) + '-' + phoneNo.slice(3,6) + '-' + phoneNo.slice(6,10);
        data.state = address.state;
        data.bac = rawData[i].bac;
        data.name = rawData[i].name;
        data.distance = rawDistance.toFixed(2);
        geoLocation.lat = parseFloat(distanceInfo.latitude);
        geoLocation.lng = parseFloat(distanceInfo.longitude);
        data.coordinates = geoLocation;
        data.marker =  'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + markerCount + '|FF0000|000000';
        data.rewardsProgram = rawData[i].rewardsProgram;
        dealers.push(data);
    }

    return dealers

};

/** FUNCTION THAT WATCH EVERY INPUT, DISPATCH AN ACTION TO SHOW ERROR MESSAGE IF THERE IS ANY ***/
export const checkError = (value)=>{
    const required = i18next.t("LABEL_CHECKOUT_DEALER_LOCATOR_LOCATE_ZIP_CODE_REQUIRED");
    const invalidZip = i18next.t("LABEL_CHECKOUT_DEALER_LOCATOR_LOCATE_INVALID_ZIP_CODE");


    if(value ==='' && value.length === 0){
        return({type:types.DISPATCH_ERROR_MESSAGE,error:required});
    }
    if(value !=='' && value.length < 5){
        return({type:types.DISPATCH_ERROR_MESSAGE,error:invalidZip});
    }

    if(value.length === 5){
            return({type:types.VALID_ZIP_CODE,error:''});
    }
};

