import React, { Component, useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import Modal from "../Modal";
import Spinner from "../Spinner/Spinner";
import AppSettings from "../../core/AppSettings";
import ReactPlayerLoader from "@brightcove/react-player-loader";

const ZoomSvg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/search.svg";
const VideoExitBtnImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/ui_close-icon_v01.svg";
const VideoPlayBtnImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/video_play_btn.svg";

let reactPlayerLoader;

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className + " ac-gallery-arrow stat-arrow-prev"} style={{ ...style }} onClick={onClick} />;
}
function NextArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className + " ac-gallery-arrow stat-arrow-next"} style={{ ...style }} onClick={onClick} />;
}
function GalleryVideo(props) {
    const { video, startVideo, stopVideo, videoState } = props;
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    // watch for changes in the videoState of redux to update isPlaying state in GalleryVideo component
    useEffect(() => {
        if (!videoState && isPlaying) {
            setIsPlaying(false);
        }
    }, [videoState]);

    return (
        <>
            {!isPlaying ? (
                <>
                    <span
                        onClick={() => {
                            setIsPlaying(true);
                            startVideo();
                        }}
                        className="ac-gallery-video-play-btn"
                    >
                        <img src={VideoPlayBtnImg} alt="play" />
                    </span>
                    <img
                        className="q-rectangle-image-responsive ac-gallery-image"
                        src={video.url}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.parentElement.classList.add("image-container-error");
                            e.target.src = imgNotFound;
                        }}
                    />
                </>
            ) : (
                <>
                    {/* BRIGHTCOVE PLAYER LOADER this component allows us to not have to call the Brightcove script*/}
                    <ReactPlayerLoader
                        accountId="5358100852001"
                        videoId={video.videoId}
                        playerId="ClrGDcamQr"
                        embedId="default"
                        ref={videoRef}
                        attrs={{ className: "video-js ac-gallery-video" }}
                        options={{ autoplay: true, controls: true, playsInline: true }}
                    />
                    <span
                        onClick={() => {
                            setIsPlaying(false);
                            stopVideo();
                        }}
                        className="ac-gallery-video-stop-btn"
                    >
                        <img src={VideoExitBtnImg} alt="stop" />
                    </span>
                </>
            )}
        </>
    );
}

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            open: false,
            activeIndex: 0,
            galleryLoading: true,
            modalImage: "",
            modalImageLoading: false,
            videoIsPlaying: false
        };
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onExitModal = this.onExitModal.bind(this);
        this.onImageModalLoad = this.onImageModalLoad.bind(this);
        this.startVideo = this.startVideo.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
        this.selectImage = this.selectImage.bind(this);
    }
    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            galleryLoading: false
        });
    }

    onOpenModal(image, zoomable) {
        if (zoomable) {
            this.setState({
                open: true,
                modalImage: image,
                modalImageLoading: true
            });
        }
    }

    onCloseModal() {
        this.setState({
            open: false
        });
    }

    onExitModal() {
        this.setState({
            modalImage: ""
        });
    }

    onImageModalLoad() {
        this.setState({
            modalImageLoading: false
        });
    }

    hidePrimaryImage() {
        const images = this.props.images;
        for (let image in images) {
            if (images[image].url.includes("Primary")) {
                images.splice(image, 1);
            }
        }
    }

    startVideo() {
        this.setState({
            videoIsPlaying: true
        });
    }
    stopVideo() {
        this.setState({
            videoIsPlaying: false
        });
    }
    selectImage(i) {
        this.setState({
            activeIndex: i
        });
    }

    renderMainSlider() {
        const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";
        const modalImageLoading = this.state.modalImageLoading;
        const settings = {
            className: "q-slider q-with-caption q-slider-hover-arrow",
            asNavFor: this.state.nav2,
            dots: true,
            // stop video when any swipe or arrow events occur in Slider
            beforeChange: () => this.stopVideo(),
            // sets activeIndex state when active slide changes in main Slider and syncs nav2 to current slide
            afterChange: (currentSlide) => {
                this.setState({ activeIndex: currentSlide });
                this.state.nav2?.slickGoTo?.(currentSlide);
            },
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };
        if (this.props.packagingSpecs && this.props.images.length > 1 && this.props.packagingSpecs.packOf > 1) {
            this.hidePrimaryImage();
        }
        return (
            <div className="q-gallery-full-scroller z-index-fix">
                <div className="ac-no-background q-multimedia-scroller q-mod q-mod-multimedia-scroller">
                    <Slider {...settings} ref={(slider) => (this.slider1 = slider)}>
                        {
                            // Map through assets list to find images and videos
                            this.props.assets.map((asset, i) => {
                                // Product Images
                                if (asset.type && asset.type.includes("image")) {
                                    return (
                                        <div key={i} className="q-mod q-mod-slide q-slider-item">
                                            <div className="ac-image-container">
                                                <img
                                                    className="q-rectangle-image-responsive ac-gallery-image"
                                                    src={asset.url + (this.props.thumbnailImageExtension || "")}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.parentElement.classList.add("image-container-error");
                                                        e.target.src = imgNotFound;
                                                    }}
                                                    data-dtm={this.props.name}
                                                />
                                                <a onClick={() => this.onOpenModal(asset.url, asset.zoomable)}>
                                                    <img
                                                        className={
                                                            asset.zoomable
                                                                ? "q-icon-svg ac-zoom-svg stat-icon-link"
                                                                : "ac-zoom-hide"
                                                        }
                                                        src={ZoomSvg}
                                                    ></img>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                }
                                // Product Videos
                                if (asset.videoId) {
                                    return (
                                        <div key={i} className="q-mod q-mod-slide q-slider-item">
                                            <div className="ac-image-container">
                                                <GalleryVideo
                                                    video={asset}
                                                    startVideo={this.startVideo}
                                                    stopVideo={this.stopVideo}
                                                    videoState={this.state.videoIsPlaying}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                    </Slider>

                    <Modal
                        open={this.state.open}
                        onClose={this.onCloseModal}
                        onExited={this.onExitModal}
                        animationDuration={50}
                        center
                        classNames={{
                            modal: "ac-gallery-modal"
                        }}
                    >
                        {modalImageLoading && <Spinner />}
                        <img
                            id="ac-zoomed-image"
                            className="ac-modal-image stat-image-link"
                            src={this.state.modalImage + (this.props.fullSizeImageExtension || "")}
                            onLoad={() => this.onImageModalLoad()}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = imgNotFound;
                            }}
                        />
                    </Modal>
                </div>
            </div>
        );
    }

    renderThumbnailSlider() {
        const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";
        if (this.props.assets.length > 1) {
            //Below is necessary documented bug where initialState doesn't work on Slick slider. Forcing the gallery to render the first image
            if (this.state.nav1 && this.state.galleryLoading) {
                this.state.nav1?.slickGoTo?.(0);
            }
            if (this.props.packagingSpecs && this.props.packagingSpecs.packOf > 1) {
                this.hidePrimaryImage();
            }
            return (
                <div className="row collapse ac-no-background q-gallery-thumbnail-scroller">
                    <Slider
                        className="q-filmstrip ac-lower-arrow-index"
                        ref={(slider) => (this.slider2 = slider)}
                        slidesToShow={this.props.assets.length > 3 ? 3 : this.props.assets.length}
                        slidesToScroll={1}
                        asNavFor={this.state.nav1}
                    >
                        {this.props.assets.map((asset, i) => (
                            <div key={i} className="ac-slider-item stat-image-link" data-dtm={this.props.name}>
                                {asset.videoId && (
                                    <span
                                        className="ac-gallery-thumbnail-video-play-btn"
                                        onClick={() => {
                                            this.state.nav1.slickGoTo(i);
                                            this.selectImage(i);
                                        }}
                                    >
                                        <img src={VideoPlayBtnImg} alt="play" />
                                    </span>
                                )}
                                <img
                                    tabIndex="0"
                                    className={
                                        this.state.activeIndex === i
                                            ? "ac-gallery-selected q-gallery-thumbnail-scroller-item stat-thumbnail"
                                            : "q-gallery-thumbnail-scroller-item stat-thumbnail"
                                    }
                                    onClick={() => {
                                        this.state.nav1.slickGoTo(i);
                                        this.selectImage(i);
                                    }}
                                    src={asset.url + (this.props.thumbnailImageExtension || "")}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = imgNotFound;
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    render() {
        return (
            <div className="ac-no-background q-gallery-layer q-mod q-mod-gallery-layer">
                <div className="ac-no-background q-gallery-container ac-gallery-container">
                    {this.renderMainSlider()}
                    {this.renderThumbnailSlider()}
                </div>
            </div>
        );
    }
}

export default Gallery;
