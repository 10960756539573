import React from 'react';
import propTypes from 'prop-types';
import { Translation } from 'react-i18next';

import Spinner from '../Spinner/Spinner';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/**************************************************************/
//                  BUTTON
/**************************************************************/
// NOTES:
// - Default is primary button style
// - Use prop isSecondary={true} for that secondary look
// - Compatible with redux forms :)

/*

Examples:

1.) Regular Button (Secondary):

    <Button
        isSecondary={true}
        localeKey="LABEL_BUTTON_TEXT"
        onClick={() => doButtonAction()} />

2.) Redux Form Button (Primary):

    <Button
        localeKey="LABEL_BUTTON_TEXT"
        type="submit"
        disabled={submitting} />

3.) Button with data-dtm and a button spinner enabled
    <Button
        dataDtm={name}
        localeKey='LABEL_BUTTON_TEXT'
        disabled={showLoadingBtn}
        isLoading={showLoadingBtn} /> // note that button spinner can be triggered at same time as 'disabled'

*/
/*
**************FONT AWESOME BUTTON IN LABEL***********
To add fontAwesome in your button label you will need these props:
Example of using the fontAwesome Props:

                            fontAwesomeRender={fontIconMinus}
                            fontAwesomeSize={'lg'}

*/

Button.propTypes = {
    localeKey: propTypes.string.isRequired, // translation text, required
    onClick: propTypes.func, // button action
    disabled: propTypes.bool, // to disable or not to disable
    isSecondary: propTypes.bool, // when secondary > primary
    type: propTypes.string, // default is 'button', but can be something else (e.g. submit for redux forms)
    isLoading: propTypes.bool, // display mini button spinner. Note: Can be triggered same time as 'disabled' (see example 3 above)
    dataDtm: propTypes.string,
    dataDtm2: propTypes.string,
    fontAwesomeRender: propTypes.any,
    fontAwesomeSize: propTypes.string,
    className: propTypes.string,
    id: propTypes.string,
}

function Button(props) {
    var className = props.className ? props.className : '';
    var buttonId = props.id ? props.id : 'button';

    return (
        <Translation>
            {(t) => (
                <button
                    className={props.isSecondary ?
                        "q-secondary-button q-mod q-mod-button q-button q-button-small q-button-padding-none small-margin stat-button-link " + className :
                        "q-primary-button q-mod q-mod-button q-button q-button-small q-button-padding-none small-margin stat-button-link " + className}
                    id={buttonId}
                    onClick={props.onClick}
                    disabled={props.disabled} style={props.disabled ? { cursor: 'not-allowed' } : {}}
                    type={props.type ? props.type : 'button'}
                    data-dtm={props.dataDtm}
                    data-dtm2={props.dataDtm2}>

                    {/* FONTAWESOME RENDER IN LABEL */}
                    {props.fontAwesomeRender &&
                    <FontAwesomeIcon icon={props.fontAwesomeRender}
                                     size={props.fontAwesomeSize}/>}

                    {/* BUTTON LABEL */}
                    {t(props.localeKey)}

                    {/* BUTTON SPINNER */}
                    {props.isLoading && <Spinner isLoadingBtnEnabled="true"/>}
                </button>
            )}</Translation>
    )
}

export default Button;
