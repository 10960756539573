import * as type from './FitmentCheckActionType'
import {addDriveFitmentSelection, addEngineFitmentSelection, addTrimFitmentSelection,
    addWheelFitmentSelection} from './FitmentCheckUtils'

/**************************************************************/
// Actions to load data into the FitmentReducer state
/**************************************************************/
export const loadVehicleCombinationsAsync = () => {
    const action = {
        type: type.LOAD_VEHICLE_COMBINATIONS_ASYNC
    }
    return action;
}

export const loadFitmentCheckAsync = (ownProps) => {
    const action = {
        type: type.LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC,
        fitmentStep: ownProps
    }
    return action;
};

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const setVehicleCombinations = (combinations, parsedCombinations) => {
    const action = {
        type: type.SET_VEHICLE_COMBINATIONS,
        combinations: combinations,
        parsedCombinations: parsedCombinations
    }
    return action;
}

export const setFitmentCheck = (fitmentDetails, fitmentStatus, selectionValues) => {
    const action = {
        type: type.SET_FITMENT_CHECK,
        fitmentDetails: fitmentDetails,
        fitmentStatus: fitmentStatus,
        selectionValues: selectionValues
    }
    return action;
}

export const setVehicleFitment = (data) => {
    return {
        type: type.SET_VEHICLE_FITMENT,
        data
    }
}

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadVehicleCombinationsAsyncFail = (error) => {
    const action = {
        type: type.LOAD_VEHICLE_COMBINATIONS_ASYNC_FAIL,
        error
    }
    return action;
}

export const loadFitmentCheckAsyncFail = (error) => {
    const action = {
        type: type.LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC_FAIL,
        error
    }
    return action;
}

export const setVehicleCombinationsFail = (error) => {
    const action = {
        type: type.SET_VEHICLE_COMBINATIONS_FAIL,
        error
    }
    return action;
}

export const setFitmentCheckFail = (error) => {
    const action = {
        type: type.SET_FITMENT_CHECK_FAIL,
        error
    }
    return action;
}

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const closeModal = () => ({
    type: type.CLOSE_FITMENT_MODAL
});

export const showFitmentModal = (showModal) => ({
    type: type.SHOW_FITMENT_MODAL,
    showModal: showModal
})

export const returnToSearch = (returnToSearch) => {
    const action = {
        type: type.RETURN_TO_SEARCH,
        returnToSearch: returnToSearch
    }
    return action;
}

//These select actions filter the json further based on user selection.
//Params are being passed in from the FitmentCheckForm.js mapDispatchToProps function
export const selectBody = (selectedBodyValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, dropdownLabel, bodyFitmentSelection) => {
    const bodyId = selectedBodyValues.BODY_TYPE_ID;
    const bodyNumDoors = selectedBodyValues.BODY_NUM_DOORS_ID;
    const bodyStyle = selectedBodyValues.BODY_TYPE_NAME;
    
    let tempFitmentCombination = [];
    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (vehicleCombinations[index].BODY_TYPE_ID == bodyId) {
            tempFitmentCombination.push(vehicleCombinations[index]);
        }
    }

    let wheelFitmentSelection = [];
    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];

        addWheelFitmentSelection(record, wheelFitmentSelection);
        addTrimFitmentSelection(record, trimFitmentSelection);
        addDriveFitmentSelection(record, driveFitmentSelection);
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let wheelParam = "";
    let trimParam = "";
    let driveParam = "";
    let engineParam = "";

    if (wheelFitmentSelection.length == 1) {
        wheelParam = wheelFitmentSelection[0].WHEEL_BASE_ID;
    }
    if (trimFitmentSelection.length == 1) {
        trimParam = trimFitmentSelection[0].SUBMODEL_ID;
    }
    if (driveFitmentSelection.length == 1) {
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_BODY,

        vehicleParams: {
            bodyNumDoorsParam: bodyNumDoors || '',
            bodyParam: bodyId || '',
            wheelParam: wheelParam || '',
            trimParam: trimParam || '',
            driveParam: driveParam || '',
            engineParam: engineParam || ''
        },
        fitmentVehicleInfo: {
            bodyStyle: bodyStyle || '', 
            bodyStyleId: bodyId || '',
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: fitmentVehicleInfo.driveType, 
            driveTypeId: fitmentVehicleInfo.driveTypeId, 
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    }

    return vehicleInfo;
}

export const selectWheel = (selectedWheelValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection, wheelFitmentSelection) => {
    const wheelBase = selectedWheelValues.WHEEL_BASE_OVERRIDE;
    const wheelId = selectedWheelValues.WHEEL_BASE_ID;
    let {bodyParam} = vehicleParams;
    let tempFitmentCombination = [];
    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (isEmpty(bodyParam)) {
            if (vehicleCombinations[index].WHEEL_BASE_ID == wheelId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else {
            if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].WHEEL_BASE_ID == wheelId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        }
    }

    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];

        addTrimFitmentSelection(record, trimFitmentSelection);
        addDriveFitmentSelection(record, driveFitmentSelection);
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let trimParam = "";
    let driveParam = "";
    let engineParam = "";
    if (trimFitmentSelection.length == 1) { // && showTrimDropdown == true
        trimParam = trimFitmentSelection[0].SUBMODEL_ID;
    }

    if (driveFitmentSelection.length == 1) { //&& driveDropdown == true
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }

    if (engineFitmentSelection.length == 1) { //&& showEngineDropdown == true
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_WHEEL,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || '',
            bodyParam: bodyParam || '',
            wheelParam: wheelId || '',
            trimParam: trimParam || '',
            driveParam: driveParam || '',
            engineParam: engineParam || ''
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle, 
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBaseId: wheelId || '',
            wheelBase: wheelBase || '',
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: fitmentVehicleInfo.driveType, 
            driveTypeId: fitmentVehicleInfo.driveTypeId, 
            modelId: fitmentVehicleInfo.modelId, 
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    }

    return vehicleInfo;

}

export const selectTrim = (selectedTrimValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection, wheelFitmentSelection, trimFitmentSelection) => {
    const trimId = selectedTrimValues.SUBMODEL_ID;
    const trimName = selectedTrimValues.SUBMODEL_NAME;
    let tempFitmentCombination = [];
    let {bodyParam, wheelParam} = vehicleParams;

    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (isEmpty(bodyParam) && isEmpty(wheelParam)) {
            if (vehicleCombinations[index].SUBMODEL_ID == trimId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else if (isNotEmpty(bodyParam) && isEmpty(wheelParam)) {
            if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }

        } else if (isEmpty(bodyParam) && isNotEmpty(wheelParam )) { //vehicleInfo.wheelParam <- this should just be from state?
            if (vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }

        } else {
            if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        }
    }

    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];
        addDriveFitmentSelection(record, driveFitmentSelection)
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let driveParam = "";
    let engineParam = "";
    if (driveFitmentSelection.length == 1) {
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_TRIM,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || '',
            bodyParam: vehicleParams.bodyParam || '',
            wheelParam: vehicleParams.wheelParam || '',
            trimParam: trimId || '',
            driveParam: driveParam || '',
            engineParam: engineParam || ''
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle, 
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: trimName || '',
            trimId: trimId || '', 
            driveType: fitmentVehicleInfo.driveType, 
            driveTypeId: fitmentVehicleInfo.driveTypeId, 
            modelId: fitmentVehicleInfo.modelId, 
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    }

    return vehicleInfo;
}

export const selectDrive = (selectedDriveValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection,
                            wheelFitmentSelection, trimFitmentSelection, driveFitmentSelection) => {
    const driveType = selectedDriveValues.DRIVE_TYPE_NAME;
    const driveId = selectedDriveValues.DRIVE_TYPE_ID;
    let tempFitmentCombination = [];
    let {bodyParam, wheelParam, trimParam} = vehicleParams;
    for (let index = 0; index < vehicleCombinations.length; index++) {
        
        //skip the combo if it's not even this driveId
        if (vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {

            if (isEmpty(bodyParam) &&
                isEmpty(wheelParam) &&
                isEmpty(trimParam)) {
                if (vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isNotEmpty(bodyParam) &&
                isEmpty(wheelParam) &&
                isEmpty(trimParam)) {
                if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID === driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isEmpty(bodyParam) &&
                isNotEmpty(wheelParam) &&
                isEmpty(trimParam)) {
                if (vehicleCombinations[index].WHEEL_BASE_ID === wheelParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isEmpty(bodyParam) &&
                isEmpty(wheelParam) &&
                isNotEmpty(trimParam)) {

                if (vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isNotEmpty(bodyParam) &&
                isNotEmpty(wheelParam) &&
                isEmpty(trimParam)) {

                if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isEmpty(bodyParam) &&
                isNotEmpty(wheelParam) &&
                isNotEmpty(trimParam)) {

                if (vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else if (isNotEmpty(bodyParam) &&
                isEmpty(wheelParam) &&
                isNotEmpty(trimParam)) {

                if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }

            } else {
                if (vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            }
        }
    }

    let engineFitmentSelection = [];
    let records = tempFitmentCombination;

    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        addEngineFitmentSelection(records[indexRecord], engineFitmentSelection);
    }

    let engineParam = "";
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_DRIVE,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || '',
            bodyParam: vehicleParams.bodyParam || '',
            wheelParam: vehicleParams.wheelParam || '',
            trimParam: vehicleParams.trimParam || '',
            driveParam: driveId || '',
            engineParam: engineParam || ''
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle, 
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId, 
            driveType: driveType || '', 
            driveTypeId: driveId || '', 
            modelId: fitmentVehicleInfo.modelId, 
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    }

    return vehicleInfo;


}

export const selectEngine = (selectedEngineValues, fitmentVehicleInfo, vehicleParams, bodyFitmentSelection,
                             wheelFitmentSelection,
                             trimFitmentSelection, driveFitmentSelection,
                             engineFitmentSelection) => {

    const engineId = selectedEngineValues.ENGINE_BASE_ID;
    let vehicleInfo = {
        type: type.SELECT_ENGINE,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || '',
            bodyParam: vehicleParams.bodyParam || '',
            wheelParam: vehicleParams.wheelParam || '',
            trimParam: vehicleParams.trimParam || '',
            driveParam: vehicleParams.driveParam || '',
            engineParam: engineId || ''
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle, 
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId, 
            driveType: fitmentVehicleInfo.driveType, 
            driveTypeId: fitmentVehicleInfo.driveTypeId, 
            modelId: fitmentVehicleInfo.modelId, 
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    };

    return vehicleInfo;
};

function isEmpty(value){
    return (value == '' || value == undefined);
}

function isNotEmpty(value){
    return value != '' && value != undefined;
}