import * as type from "./CartActionTypes";

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderInfoDataAsync = (response, parsedResponse) => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART,
        payload: {
            response: response,
            parsedResponse: parsedResponse
        }
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderInfoDataAsyncFail = (error) => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL,
        error: error
    };
};

/*** Order Info Async **/
export const loadOrderInfoAsync = () => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART_ASYNC
    };
};

export const updateCouponBanner = (checkCouponBanner) => {
    const action = {
        type: type.UPDATE_COUPON_BANNER,
        checkCouponBanner: checkCouponBanner
    };
    return action;
};
