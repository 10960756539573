import React, { useEffect, useState } from 'react';
import GuestUserWidget from './GuestFlyout/GuestUserWidget';
import RegisteredUserWidget from './RegisteredFlyout/RegisteredUserWidget';

const UserWidget = (props) => {
    const { isSignInActive, isUserActive, userFlow, clientId, applicationStateString, currentSite, authenticationData, rewardsData } = props

    const [isDropdownActive, setDropdownActive] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    let prevScrollpos = window.pageYOffset;

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleClick);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('resize', handleResize);
        };
    }, [isDropdownActive, width]);

    // This will help determine when to turn the User Label into an icon depending on screen width.
    const handleResize = () => {
        setWidth(window.innerWidth);
    }

    const handleScroll = () => {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos < currentScrollPos) {
            setDropdownActive(false);
        }
        prevScrollpos = currentScrollPos;
    }

    const handleClick = (e) => {
        const flyoutWrapperElement = document.getElementsByClassName("ac-header-flyout-clickable-area ");
        const widgetFlyoutElement = document.getElementById("ac-user-widget");

        let targetElement = e.target;

        // Do not close the flyout if the flyout itself is clicked.
        if (targetElement == flyoutWrapperElement[0]) {
            return;
        }
        // Potential explanation of this do/while loop: The target element is the innermost nested element (if nested at all) of the position clicked.
        // At the end of each iteration the next targetElement is the parent of the inner element in the prior iteration. The loop ends when there are no more parent elements.
        // In this situation we look through all of the elements at the position clicked and check if the we clicked the element we were looking for to toggle flyout.
        do {
            // Toggle the flyout.
            if (targetElement == widgetFlyoutElement) {
                setDropdownActive(!isDropdownActive);
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);

        // If anything else was clicked, just close the flyout.
        setDropdownActive(false);
    }

    const showRegistededUserWidget = !isSignInActive && isUserActive;
    const showGuestUserWidget = !isUserActive && isSignInActive;

    if (showRegistededUserWidget || showGuestUserWidget) {
        return (
            <div id="ac-user-widget" className="stat-button-link" data-dtm="global nav">
                {showRegistededUserWidget && <RegisteredUserWidget
                    authenticationData={authenticationData}
                    isRewardsActive={rewardsData.isRewardsActive}
                    rewardsPoints={rewardsData.totalPoints}
                    applicationStateString={applicationStateString}
                    currentSite={currentSite}
                    userFlow={userFlow}
                    clientId={clientId}
                    isDropdownActive={isDropdownActive}
                    width={width}
                />}
                {showGuestUserWidget && <GuestUserWidget
                    isActive={isSignInActive}
                    isDropdownActive={isDropdownActive}
                    width={width}
                />}
            </div>
        );
    } else {
        return null;
    }
}

export default UserWidget
