import React from "react";
import PropTypes from "prop-types";
import Spinner from "../../../../shared/Spinner/Spinner";
import SubcategoryCard from "../SubcategoryCard/SubcategoryCard";

/**
 * Container component for the right-hand pane on the Category Landing Page. Contains all the cards for subcategories
 * @param props
 * isLoading: Boolean. From parent to determine whether to show spinner or content
 * selectedCategoryInfo: Object. Contains data for the selected category to display its cards
 * subcategoryImages: Object. Contains map from subcategory uniqueIds to their respective image urls
 * fitmentInfo: Object. Contains all fitment information or vin number
 * isMobile: Boolean. Used to determine which version of page should display
 * mobileCardLimit: Number. This will be the maximum amount of cards to display on mobile view before clicking show all
 * showAll: Boolean. Used to either show limited amount of cards or all of them
 * @return {JSX.Element}
 * @constructor
 */
const CategoriesViewSection = (props) => {
    const { isLoading, selectedCategoryInfo, fitmentInfo, isMobile, showAll, subcategoryImages } = props;
    const cardLimit = showAll ? selectedCategoryInfo?.subCategories?.length || null : props.mobileCardLimit;

    return (
        <div className="clp-rhs-pane">
            {isLoading || !selectedCategoryInfo ? null : (
                <h4 className="q-headline3 label-selected-category">{selectedCategoryInfo.mainCategory}</h4>
            )}
            {isLoading ? (
                <Spinner className="q-loader" />
            ) : (
                <div className="clp-category-card-row">
                    {selectedCategoryInfo &&
                        selectedCategoryInfo.subCategories?.length > 0 &&
                        selectedCategoryInfo.subCategories.map((subCategory, index) => {
                            // Limit the cards to 5 if on mobile and haven't clicked on 'Show All' button
                            if (!isMobile || (isMobile && index < cardLimit)) {
                                return (
                                    <div
                                        key={index}
                                        className="category-card-wrapper small-12 large-6 xlarge-4 xxlarge-3"
                                    >
                                        <SubcategoryCard
                                            count={subCategory.count}
                                            label={subCategory.label}
                                            parentUniqueID={subCategory.parentCategoryID}
                                            uniqueID={subCategory.uniqueID}
                                            imageUrl={subCategory.imageUrl}
                                            subcategoryImages={subcategoryImages}
                                            fitmentInfo={fitmentInfo}
                                            mainCategory={props.selectedCategoryInfo.mainCategory}
                                        />
                                    </div>
                                );
                            }
                        })}
                </div>
            )}
        </div>
    );
};

CategoriesViewSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    selectedCategoryInfo: PropTypes.object,
    subcategoryImages: PropTypes.object,
    fitmentInfo: PropTypes.object,
    isMobile: PropTypes.bool,
    mobileCardLimit: PropTypes.number,
    showAll: PropTypes.bool
};

export default CategoriesViewSection;
