import React, { Component } from "react";
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { cartQuantityShowTextInput, updateOrderItemQuantity } from "../../OrderInfo/OrderInfoRedux/OrderInfoCreator";
import CartQuantityForm from "./CartQuantityForm";
import store from '../../../core/Redux/Store';
import Spinner from '../../Spinner/Spinner';

const INPUT_TYPE_SELECT = "select";
const INPUT_TYPE_TEXT = "text";
const SPINNER =
    <div className="ac-spinner-overlay">
        <div className="ac-spinner-spinner">
            <Spinner />
        </div>
    </div>

class CartQuantityFormContainer extends Component {

    componentDidMount() {
        // Displays quantity field as text input if the current quantity is 
        // greater than 9.
        if (this.props.currentQuantity > 9) {
            const showTextInput = true;
            store.dispatch(
                cartQuantityShowTextInput(showTextInput, this.props.orderItemId)
            );
        }
    }

    /**
     * handleChange() handles the event where quantity is changed by choosing 
     * one of the select options. 
     * 
     * If the user chooses a value less than 10, the order item is updated to 
     * reflect this selection. 
     * 
     * If the user chooses to enter a value equal to or greater than 10, the 
     * input type changes to text.
     */
    handleChange = (values, dispatch, props, previousValues) => {
        const previousQuantity = previousValues.quantity;
        if (props.inputType === INPUT_TYPE_SELECT && Object.entries(values).length !== 0) {
           const newQuantity = values.quantity;
            if (newQuantity !== "10+") {
                if (previousQuantity && previousQuantity != newQuantity) {
                    // Updates order item for quantity values less than 10.
                    dispatch(
                        updateOrderItemQuantity(this.props.orderItemId, this.props.laborOrderItemId, newQuantity)
                    );
                }
            } else {
                // Changes the input type to text.
                dispatch(
                    cartQuantityShowTextInput(true, this.props.orderItemId)
                );

                // Fills the newly-rendered text input with the quantity that 
                // had been applied prior to selecting 10+.
                dispatch(
                    change(`cartQuantityForm-${this.props.orderItemId}`,
                        "quantity", previousQuantity, true, true)
                );
            }
        }
    }

    /**
     * handleSubmit() handles the event where quantity is changed by typing a 
     * value into the text input and clicking the submit button.
     * 
     * When the text input form is submitted, the order item is updated to 
     * reflect the typed quantity.
     */
    handleSubmit = (values, dispatch) => {
        const newQuantity = values.quantity;
        dispatch(updateOrderItemQuantity(this.props.orderItemId, this.props.laborOrderItemId, newQuantity));
    }

    /**
     * render() returns a CartQuantityForm. 
     * 
     * CartQuantityForm contains the quantity field for an individual order 
     * item. The quantity field may be rendered as either a select (dropdown) 
     * input or a text input.
     * 
     * Select is the default input type used for quantities less than 10. If  
     * the current quantity is equal to or greater than 10 or if the user 
     * chooses to enter such a quantity, the text input type is used.
     */
    render() {
        // Sets the quantity value to display on initial page render.
        const initialValues = { quantity: this.props.currentQuantity };

        // Determines what type of input to render.
        let cartQuantityInputType = INPUT_TYPE_SELECT;
        const cartQuantityShowTextInput =
            this.props.orderInfo.cartQuantityShowTextInput;
        if (cartQuantityShowTextInput.has(this.props.orderItemId)) {
            cartQuantityInputType = INPUT_TYPE_TEXT;
        }

        // Determines whether or not to show spinner.
        let showSpinner = false;
        let cartQuantityShowSpinner = this.props.orderInfo.cartQuantityShowSpinner;
        if (cartQuantityShowSpinner.has(this.props.orderItemId)) {
            showSpinner = true;
        }

        // Determines whether or not to show spinner if item was removed.
        let showRemoveSpinner = false;
        const { cartRemoveShowSpinner } = this.props.orderInfo;
        if (cartRemoveShowSpinner.has(this.props.orderItemId)) {
            showRemoveSpinner = true;
        }

        return (
            <div className="ac-cart-quantity-form-container">
                {(showSpinner || showRemoveSpinner) && SPINNER}
                <CartQuantityForm
                    form={`cartQuantityForm-${this.props.orderItemId}`}
                    orderItemId={this.props.orderItemId}
                    initialValues={initialValues}
                    inputType={cartQuantityInputType}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit} 
                    currentQty={this.props.currentQuantity}
                    dtmPartName={this.props.dtmPartName} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderInfo: state.OrderInfoReducer,
        formData: state.form
    }
}

export default connect(mapStateToProps)(CartQuantityFormContainer)