import * as type from "./VehicleInfoActionType";

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const setYearMakeModel = (year, make, model) => {
    const action = {
        type: type.SET_YEAR_MAKE_MODEL,
        year: year,
        make: make,
        model: model
    };
    return action;
};

export const setVINAsync = () => {
    const action = {
        type: type.SET_VIN_ASYNC
    };
    return action;
};

// get vehicle info from vin call
export const setVINSearch = (vehicleInfo) => {
    let marketing = vehicleInfo.marketing;
    let bodyAndWheel = "";
    let trim = "";
    let driveType = "";

    // Vehicles before 2017 do no contain marketing data
    // so the vehicle content displayed in the vehicle header
    // will differ between VINs before 2017 and after.
    // Also, some vehicles from 2017 onward may not have marketing data either,
    // defaulting to vehicle info in that case as well
    if (vehicleInfo.year < 2017 || !vehicleInfo.year || !marketing.seriesConfig || !marketing.seriesTitle) {
        trim = vehicleInfo.trim;
        driveType = vehicleInfo.driveType;
    } else {
        // Parsing values for VIN Vehicle Header Display
        //
        // check if seriesConfig exists, otherwise use seriesTitle for body/wheel
        bodyAndWheel = marketing.seriesConfig
            ? marketing.seriesConfig
            : marketing.seriesTitle
            ? marketing.seriesTitle
            : "";
        trim = marketing.seriesPeg ? marketing.seriesPeg : "";
        // if seriesDrive doesn't exist, we can default to driveType
        driveType = marketing.seriesDrive ? marketing.seriesDrive : bodyAndWheel ? vehicleInfo.driveType : "";
    }

    const action = {
        type: type.SET_VIN_SEARCH,
        year: vehicleInfo.year,
        make: vehicleInfo.make,
        model: vehicleInfo.model,
        vin: vehicleInfo.vin,
        bodyStyle: vehicleInfo.bodyStyle,
        wheelBase: vehicleInfo.wheelBase,
        trim: trim,
        driveType: driveType,
        vcdb: vehicleInfo.vcdb,
        marketing: marketing,
        options: vehicleInfo.options,
        bodyAndWheel: bodyAndWheel,
        bodyNumDoors: vehicleInfo.numOfDoors ? vehicleInfo.numOfDoors : "",
        engine: vehicleInfo.engine ? vehicleInfo.engine : ""
    };
    return action;
};
export const updateVehicleInfoByData = (vehicleInfo) => {
    const action = {
        type: type.UPDATE_VEHICLE_INFO_BY_DATA,
        vehicleInfo: vehicleInfo
    };
    return action;
};

export const updateVehicleInfoByUrl = (params) => {
    const action = {
        type: type.UPDATE_VEHICLE_INFO_BY_URL,
        params: params
    };
    return action;
};

export const setVINAsyncFail = () => {
    const action = {
        type: type.SET_VIN_ASYNC_FAIL
    };
    return action;
};

export const setVINSearchFail = (error) => {
    const action = {
        type: type.SET_VIN_SEARCH_FAIL,
        error
    };
    return action;
};

export const updateVehicleInfo = (params) => {
    const action = {
        type: type.UPDATE_VEHICLE_INFO,
        params: params
    };
    return action;
};

export const resetSessionVehicleInfo = () => {
    return {
        type: type.RESET_VEHICLE_INFO,
    }
}
