import React, { Component } from "react";
import Gallery from '../../../shared/Gallery/Gallery';

//const baseUrl = 'https://ecom-dam.ext.gm.com/parts/images';

//TODO : get finalized version of what determines if an image is Zoomable, can't assume it'll come in the backend call yet
//const imageProps = ['18_CHE_CAM_62F_LVL4_1_1_01.jpg', '23507995_Primary.jpg', '23507995_Alternate1.jpg'];

/*const imageProps = [{
  name: '18_CHE_CAM_62F_LVL4_1_1_01.jpg',
  zoomable: 'N'
},
{
  name: '23507995_Primary.jpg',
  zoomable: 'Y'
},
{
  name: '23507995_Alternate1.jpg',
  zoomable: 'Y'
}
]*/

export default class ProductDetailsGallery extends Component {
  constructor(props) {
    super(props);
  }

  imageList() {
    let images = [];
    this.props.item.images.map(image => {
      if (image.name == 'On-Vehicle Image') {
        return;
      }
      let imageInfo = {
        url: image.fullImageURL,
        zoomable: (image.zoomable == 'Y'),
        type: image.mimeType,
        name: image.name
      }
      images.push(imageInfo)
    });
    return images;
  }

  onVehicleList() {
    let onVehicleImages = [];
    this.props.item.images.map(image => {
      if (image.name !== 'On-Vehicle Image') {
        return;
      }
      let onVehicleImageInfo = {
        url: image.fullImageURL,
        zoomable: (image.zoomable == 'Y'),
        type: image.mimeType,
        name: image.name
      }
      onVehicleImages.push(onVehicleImageInfo)
    });
    return onVehicleImages;
  }

  videoList() {
    if (!this.props.item.videos) {
      return;
    }
    const videos = this.props.item.videos.map(video => ({
      url: video.videoImage.url,
      name: video.videoImage.name,
      videoId: video.videoId
    })
    );
    return videos;
  }

  assetList() {
    // orders all images and videos in this order for B2C - thumbnail, primary, alternate, videos, onVehicle images
    let assets;
    const images = this.imageList()
    const videos = this.videoList()
    const onVehicleImages = this.onVehicleList()
    if (images) {
      assets = images;
    }
    if (videos) {
      assets = assets.concat(videos);
    }
    if (onVehicleImages) {
      assets = assets.concat(onVehicleImages);
    }
    return assets;
  }

  render() {
    
    return (
      <Gallery
        images={this.imageList()}
        videos={this.videoList()}
        assets={this.assetList()}
        name={this.props.item.name}
        packagingSpecs={this.props.item.packagingSpecification[0]}
        thumbnailImageExtension='/640X640'
        fullSizeImageExtension='/1000X1000' />
    );
  }
}