import React from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import AppSettings from "../../../../core/AppSettings";
import Spinner from "../../../../shared/Spinner/Spinner";
import Modal from "../../../../shared/Modal";
import {neverCloseModal} from "../../CheckoutRedux/CheckoutActionCreator";
import RoscaDisclosure from './RoscaDisclosure';
import {Toggle} from "../../../../shared/Hooks/Hooks";
import store from "../../../../core/Redux/Store";

const PlaceOrderBtn = props => {
  // grab data from checkout wizard
  const { handleSubmitOrder, showOrderProcessingModal, hideCheckoutAlert, roscaDisclosureMsg, isRoscaChecked } = props;
  // disable place order button when place order calls
  //are being made or when there's been a technical error
  //and an order cannot be placed at this time
  function isOrderSubmitting() {
    return showOrderProcessingModal == true; // modal displays when calls are being made
  }

  function isOrderSubmitFailed() {
      return hideCheckoutAlert == false; // error msg is displayed when order can't be placed
  }

  // || (isRoscaChecked || !isRoscaChecked)
  function isPlaceOrderBtnDisabled() {
    return isOrderSubmitting() || isOrderSubmitFailed();
  }
  
  return (
    <Translation>
      {t => (
        <form className="ac-review-form columns small-12 xlarge-6">
          {/************* PLACE ORDER *************/}
          <div className="ac-place-order small-12 columns grid-column-alignment-center ac-small-margintop">
             {/*ROSCA Disclosure */}
            {!AppSettings.isT3 && <RoscaDisclosure isRoscaChecked={isRoscaChecked} roscaDisclosureMsg={roscaDisclosureMsg}
                             cartResponse={props.cartResponse}
            />}
            <div className="gmit-gf-request-type ac-padding-top-sm">
            </div>
            <button
              className="ac-checkout-button q-button q-primary-button gm-primary-btn stat-button-link"
              data-dtm="checkout step4"
              type="button"
              onClick={handleSubmitOrder}
              disabled={isPlaceOrderBtnDisabled()}>
              {t("LABEL_CHECKOUT_PLACE_ORDER")}
            </button>
          </div>
          {/* The React modal requires onClose to be defined, 
            but this order processing modal should not be closed 
            by the user when placing order (modal close button is 
            also hidden with css) */}
          <Modal
            open={showOrderProcessingModal}
            onClose={props.neverCloseOrderProcessingModal}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            focusTrapped={false}
            center>
            <div className="ac-submit-order-modal">
              <Spinner />
              <h3>{t("LABEL_CHECKOUT_SUBMIT_ORDER_USER_INSTRUCTIONS")}</h3>
            </div>
          </Modal>
        </form>
      )}
    </Translation>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    //keep modal open forever
    neverCloseOrderProcessingModal: () => {
      dispatch(neverCloseModal());
    }
  };
}

export default connect(null, mapDispatchToProps)(PlaceOrderBtn);
