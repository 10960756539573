import React from 'react';
import { Translation } from 'react-i18next';
import { Field } from 'redux-form';
import FieldErrorMessage from '../../../../../../shared/FieldErrorMessage/FieldErrorMessage';

const DEFAULT_SHIP_MODE_ID = '13701';
const DEFAULT_SHIP_CARRIER_CODE = 'SELECT';
const DEALER_INSTALL_CONTACT_DEALER = 'Dealer Installation - Contact Dealer for Cost';
const INSTALL_ORDER_ITEM_TYPE = "Installed";

/**
 * deliveryMethodSelectOptions() returns the options for an individual delivery 
 * method select field. There is one option per shipping charge.
 */
const deliveryMethodSelectOptions = (shipChargesByShipMode, orderItemId, productId, quantity) => {
    const options = shipChargesByShipMode.map((charge) => {
        // Option value contains all item-specific data that must be sent to 
        // the API in order to apply the selected shipping charge. The value 
        // set here is ultimately read by the asyncValidation function. It may 
        // be worth researching if there is a more efficient way to send data 
        // to that function.
        const value = {
            carrierCode: charge.carrierCode,
            shipModeId: charge.shipModeId,
            orderItemId: orderItemId,
            productId: productId,
            quantity: quantity
        }

        if (charge.carrierCode === DEFAULT_SHIP_CARRIER_CODE) {
            return (
                <option
                    hidden
                    key={charge.description}
                    value={JSON.stringify(value)}>
                    {charge.description}
                </option>
            );
        }
        if (charge.description === DEALER_INSTALL_CONTACT_DEALER) {
            return null
        }

        return (
            <option
                key={charge.description}
                value={JSON.stringify(value)}>
                {charge.description}
            </option>
        );
    });

    return options;
}

const deliveryMethodSelectDefaultValue = (shipChargesByShipMode, items) => {
    let defaultValue = {};
    if (items != undefined) {
        let itemShipModeId = items[0].shipModeId;
        let item = items[0];

        //We have two items meaning there is an install item, requires a workaround to pull the shipModeId from that item
        if (items.length > 1) {
            items.forEach(function (item) {
                if (item.orderItemType == INSTALL_ORDER_ITEM_TYPE) {
                    itemShipModeId = item.shipModeId;
                }
            })
        }
        for (let charge of shipChargesByShipMode) {
            if (charge.shipModeId === itemShipModeId) {
                defaultValue = JSON.stringify({
                    carrierCode: charge.carrierCode,
                    shipModeId: charge.shipModeId,
                    orderItemId: item.orderItemId,
                    productId: item.productId,
                    quantity: item.quantity
                });
                break;
            }
        }
    }
    return defaultValue;
}

/**
 * renderSelect() controls how the delivery method select field is rendered. 
 * There are multiple scenarios to consider. 
 */
const renderSelect = ({ vehicle, product, disabled, input, label, defaultValue, isMissingParams, meta: { touched, error, warning, dirty, pristine, submitFailed }, children }) => (
    <Translation>{(t) => (
        <div className="gmit-gf-dropdown">
            <label className="gmit-gf-label-text required">{label}</label>
            {pristine && (
                <select
                    {...input}
                    value={defaultValue}
                    className="q-dropdown q-form-dropdown stat-dropdown"
                    id={defaultValue.toString().replace(/ /g, '-') + "-dropdown"}
                    data-dtm={`checkout step2:${vehicle}:${product}:dropdown`}
                    disabled={disabled}
                >
                    {children}
                </select>
            )}
            {dirty && (
                <select
                    {...input}
                    className="q-dropdown q-form-dropdown stat-dropdown"
                    id={defaultValue.toString().replace(/ /g, '-') + "-dropdown"}
                    data-dtm={`checkout step2:${vehicle}:${product}:dropdown`}
                    disabled={disabled}
                >
                    {children}
                </select>
            )}
            {error && (
                <FieldErrorMessage
                    message={t(error)}
                    showError={true}
                />
            )}
            {isMissingParams && (
                <FieldErrorMessage
                    message={t('LABEL_CHECKOUT_DELIVERY_METHOD_FIELD_GENERIC_ERROR')}
                    showError={true}
                />
            )}
        </div>
    )}
    </Translation>
);

/**
 * DeliveryMethodSelect returns a delivery method select field. 
 */
const DeliveryMethodSelect = props => {

    const { vehicle, product, shippingRates, showFedExError, shipModeId, orderItemId, productId, quantity, items, disabled, deliveryMissingParams } = props

    // check for missing params see (_ERR_MISSING_CMD_PARAMETER in CheckoutWorker) 
    // and display error message for associated line item
    let isMissingParams = false
    if (deliveryMissingParams) {
        if (deliveryMissingParams.includes(orderItemId)) {
            isMissingParams = true;
        }
    }

    return (
        <Translation>
            {(t) => (
                <div>
                    <Field
                        name={`deliveryMethod-${orderItemId}`}
                        label="Delivery Method"
                        component={renderSelect}
                        props={{
                            disabled: disabled,
                        }}
                        vehicle={vehicle}
                        product={product}
                        isMissingParams={isMissingParams}
                        defaultValue={deliveryMethodSelectDefaultValue(shippingRates.shipChargesByShipMode, items)}>
                        {deliveryMethodSelectOptions(shippingRates.shipChargesByShipMode, orderItemId, productId, quantity)}
                    </Field>
                    <FieldErrorMessage
                        message={t("LABEL_ERROR_MSG_FEDEX_DOWN")}
                        showError={showFedExError}
                    />
                </div>
            )}
        </Translation>
    )
}

export default DeliveryMethodSelect;