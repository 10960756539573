import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translation } from 'react-i18next';

import * as formValidate from '../../../shared/Form/FormValidate/FormValidate';
import FormInstructions from '../../../shared/Form/FormInstructions';
import Button from '../../../shared/Button/Button';
import { PasswordInputField } from '../../../shared/Form/FormFields/FormFields';

/**************************************************************/
//   HANDLE SPECIAL VALIDATION CASES SPECIFIC TO THIS FORM 
// (cases seperate from shared/Form/FormValidate/FormValidate) 
/**************************************************************/
const warn = values => {
    const warnings = {}
    if (values.password) {
        if (process.env.REACT_APP_AUTH_OC_PWD_ONE_NUMBER && /.*[0-9]+.*$/i.test(values.password)) {
            warnings.Req1 = "contains at least 1 number"
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH && values.password.length >= 8) {
            warnings.Req2 = "contains correct length of characters"
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_UPPER_CASE_LETTER && /[A-Z]/.test(values.password)) {
            warnings.Req3 = "contains upper case letter";
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_LOWER_CASE_LETTER && /[a-z]/.test(values.password)) {
            warnings.Req4 = "contains lower case letter";
        }
        if (process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR && /.*[!@#$_*]/i.test(values.password)) {
            warnings.Req5 = "contains special characters";
        }
    }
    return warnings
}


function CreatePasswordForm(props) {

    // TOGGLE PASSWORD VISIBILITY
    const [passwordType, togglePasswordType] = useState('LABEL_COMMON_SHOW_PASSWORD');
    const { email, handleSubmit, submitting, onPasswordChange, isLoading, password } = props;

    // Password requirements from vault
    const minLength = process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH;
    const maxLength = process.env.REACT_APP_AUTH_OC_PWD_MAX_LENGTH;
    const oneNumber = process.env.REACT_APP_AUTH_OC_PWD_ONE_NUMBER;
    const specialChar = process.env.REACT_APP_AUTH_OC_PWD_SPECIAL_CHAR;
    const upperCase = process.env.REACT_APP_AUTH_OC_PWD_UPPER_CASE_LETTER;
    const lowerCase = process.env.REACT_APP_AUTH_OC_PWD_LOWER_CASE_LETTER;
   
    return (
        <Translation>
            {(t) => (
                <form className="ac-password-form" onSubmit={handleSubmit}>
                    {/* Instructions to use on updating their password */}
                    <FormInstructions
                        instructions={[
                            { text: 'LABEL_ACCOUNT_CREATION_PASSWORD_INSTRUCTIONS' },
                            { text: 'LABEL_ACCOUNT_REGISTRATION_EMAIL', name: email } //displays username associated with account
                        ]} />


                    {/* New password input field with async validation */}
                    <Field
                        name="password"
                        component={PasswordInputField}
                        onChange={onPasswordChange}
                        passwordType={passwordType}
                        togglePasswordType={togglePasswordType}
                        fieldLabel={t("LABEL_COMMON_PASSWORD")}
                        requirementLabels={[oneNumber ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT") : null,
                                            minLength ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT2", { number: process.env.REACT_APP_AUTH_OC_PWD_MIN_LENGTH }) : null,
                                            upperCase ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_UPPER_CASE") : null,
                                            lowerCase ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_LOWER_CASE") : null,
                                            specialChar ? t("LABEL_PASSWORD_RESET_ENTER_PASSWORD_REQUIREMENT_SPECIAL_CHAR") : null
                                        ]}
                        required={true}
                        synchronous={true}
                        validate={[
                            formValidate.required, 
                            formValidate.oneNumberPwd, 
                            formValidate.minLengthPwd, 
                            formValidate.maxLengthPwd, 
                            formValidate.specialCharsPwd,
                            formValidate.specialCharsPwd,
                            formValidate.upperCasePwd,
                            formValidate.lowerCasePwd
                        ]}
                        minLength={minLength ? minLength : 8}
                        maxLength={maxLength ? maxLength : 256}
                        password={password} 
                        capitalized={true}
                        classes={"stat-input-field"}
                        dataDtm={"create password"}
                        btnDataDtm={"create password"}/>

                    {/* Update/Save password and send user to login page */}
                    <Button
                        className="stat-button-link"
                        localeKey="LABEL_COMMON_NEXT"
                        type="submit"
                        disabled={submitting}
                        isLoading={isLoading}
                        dataDtm={"create password"} />
                </form>
            )}
        </Translation>
    )
}

export default (reduxForm({
    form: 'RequestPasswordResetForm', // form name
    destroyOnUnmount: true, // preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true, // unregister fields on unmount
    warn,
})(CreatePasswordForm))