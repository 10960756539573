import React from 'react';
import { Trans } from 'react-i18next';
import propTypes from 'prop-types';
import { Translation } from 'react-i18next';
import AppSettings from '../../core/AppSettings';
import CheckBox from '../CheckBox/CheckBox';

/********************** README **********************/
// - Pass a locale key (from client\assets\locales) as a prop (localeKey) to Footnote to get started :)
// - A checkbox next to footnote is optional
// - Footnote does not require any links, but can support up to three
// - target="_blank" is default for links 
// - different data-dtm props can be passed for different components of the footnote

/*

Example: 

    Key:
    "LABEL_GENERIC_FOOTNOTE": "I want to enroll in brand emails and I accept the <1>Terms & Conditions</1> and the <3>Privacy Statement</3>."

    Use:
    <Footnote 
        localeKey="LABEL_GENERIC_FOOTNOTE"
        href={["https://www.gm.com/images/termsandconditions.pdf", "https://www.gm.com/images/privacystatement.pdf"]}  
        showCheckbox={true}
        isChecked={isChecked} />

    Result:
    Footnote with checkbox and two links

*/

function Footnote(props) {

    // default links open new tab
    let linkTarget = '_blank'
    const brand = AppSettings.currentSite.label;

    if (props.linkTarget) {
        linkTarget = props.target
    }
    let className = props.className ? props.className : null;

    // DATA DTM
    // footnote dtm
    let dataDtm = props.dataDtm ? props.dataDtm : null;
    let dataDtm2 = props.dataDtm2 ? props.dataDtm2 : null;
    let dataDtmLink = props.dataDtmLink ? props.dataDtmLink : null;
    // checkbox dtm
    let dataDtmCheckBox = props.dataDtmCheckBox ? props.dataDtmCheckBox : null;
    let dataDtmCheckBoxLink = props.dataDtmCheckBoxLink ? props.dataDtmCheckBoxLink : null;

    return (
        <Translation>{(t) => (
            <div className={("ac-footnote q-link small-margin small-12 q-text q-body2 " + (className ? className : ""))} style={{ display: 'flex' }} data-dtm={dataDtm} data-dtm2={dataDtm2}>
                {/* Optional Checkbox */}
                {props.showCheckbox &&
                <CheckBox className="left stat-checkbox" id={props.id} onClick={props.onClick} checked={props.checked} onChange={props.onChange}
                          input={props.input} localKey={props.localeKey} brand={brand} linkTarget={linkTarget} href={props.href} 
                          dataDtmCheckBox={dataDtmCheckBox} dataDtmCheckBoxLink={dataDtmCheckBoxLink}
                />}

                {/* Footnote with 0-3 links - accepts a key and dynamic values for brand name, etc */}
                {!props.showCheckbox && <span>
                    <Trans i18nKey={props.localeKey.toString()} values={{brand: brand}}>
                        By clicking the button, you agree to the
                        &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[0] : props.href} target={linkTarget} data-dtm={dataDtmLink ? dataDtmLink[0] : null}>first thing</a>, and
                        &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[1] : props.href} target={linkTarget} data-dtm={dataDtmLink ? dataDtmLink[1] : null}>second thing</a>, and
                        &nbsp;<a className="show-disclosure stat-text-link" href={props.href ? props.href[2] : props.href} target={linkTarget} data-dtm={dataDtmLink ? dataDtmLink[2] : null}>last thing</a>.
                    </Trans>
                </span>}
            </div>
        )}</Translation>
    )
}

Footnote.propTypes = {
    localeKey: propTypes.string.isRequired,
    isChecked: propTypes.bool,
    href: propTypes.array,
    target: propTypes.string
}

export default Footnote;
