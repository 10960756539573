import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import vaultConstants from '../../../config/vault_constants';
import AppSettings from '../../core/AppSettings';
import store from '../../core/Redux/Store';
import {
    enableMissingPaymentFlag,
    nextStep,
    resetAllErrors,
    setLoading,
    updateCreditCardSuccessInformation
} from "../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import { getRegistrationStatus } from '../Authentication/AuthenticationActionCreator';
import { pageTitle } from "../Hooks/Hooks";
import Spinner from '../Spinner/Spinner';
import { getWalletData, walletLoadError } from './WalletRedux/WalletActionCreator';
import { WalletTranslations } from './WalletTranslations';

function WalletWidget(props) {
    pageTitle("Payment Methods");

    const { enableCheckout, onLoad, merchantId } = props;
    const { gpmsWalletData, gpmsCountryCode } = props.walletData;

    const [isLoading, setIsLoading] = useState(true);
    const [domChange, setDomChange] = useState(false);
    const [elementsFound, setElementsFound] = useState(false);
    
    //asigns DTM and class name to components based on their IDs
    function addDtmAttributes(id, className, dataDtm) {
        const element = document.getElementById(id);
        if (element) {
            if(element.className.indexOf(className) < 0) {
                element.className += element.className ? " " + className : className;
            }
            element.setAttribute("data-dtm", dataDtm);
        }
    }
    useEffect(() => {
        if(domChange) {
            setTimeout(()=>{
                addDtmAttributes("gmpayui-card-number","stat-input-field","add payment");
                addDtmAttributes("gmpayui-name","stat-input-field","add payment");
                addDtmAttributes("gmwallet-add-payment-save-button","stat-button-link","add payment");
                addDtmAttributes("gmpayui-security-code","stat-input-field","add payment");
            //Delete payment Toast message
                addDtmAttributes("gmwallet-delete-cancel-btn","stat-button-link","remove payment method");
                addDtmAttributes("gmwallet-delete-submit-btn","stat-button-link","remove payment method");
                addDtmAttributes("gmwallet-add-payment-method","stat-text-link","edit payment")
                addDtmAttributes("gmwallet-edit-card-holder-name","stat-input-field", "edit payment");
                addDtmAttributes("gmwallet-edit-payment-save-btn","stat-button-link","edit paymemt");
                addDtmAttributes("gmwallet-add-new-payment-method-btn","stat-text-link","payment methods");
            }, 10000)

        }
    }, [domChange, elementsFound]); 

    useEffect(() => {
        if(!isLoading) {
            //Add data-dtm tags if page DOM is updated with "add new payment" elements
            const [target] = document.getElementsByClassName("gmwallet-container");
            console.log(target);
            function callback(mutationRecord, observer) {
                console.log("PARAMS: ", mutationRecord, observer)
                console.log('DOM mutation detected')
                setDomChange(true);
            }
            const observer = new MutationObserver(callback);
            const config = {
                childList: true,
            };
            observer.observe(target, config);

            addDtmAttributes("gmwallet-add-new-payment-method-btn","stat-text-link","payment methods");
            addDtmAttributes("gmwallet-edit-remove-btns-wrapper", "stat-text-link","payment method1")
            //Edit Payment Method tags
            addDtmAttributes("gmwallet-add-payment-method","stat-text-link","edit payment")
            addDtmAttributes("gmwallet-edit-card-holder-name","stat-input-field", "edit payment");
            addDtmAttributes("gmwallet-edit-payment-save-btn","stat-button-link","edit paymemt");

            //Delete payment Toast message
            addDtmAttributes("gmwallet-delete-cancel-btn","stat-button-link","remove payment method");
            addDtmAttributes("gmwallet-delete-submit-btn","stat-button-link","remove payment method");
            //Add Payment Method
            addDtmAttributes("gmwallet-add-payment-save-button","stat-button-link","add payment");
            addDtmAttributes("gmwallet-checkbox-label","stat-text-link","add payment");
            addDtmAttributes("gmwallet-edit-pay-form","stat-input-field","edit payment");
            addDtmAttributes("gmpayui-name","stat-input-field","add payment");
       
            }
    }, [isLoading]); 

    useEffect(() => {
        if (gpmsWalletData && !gpmsWalletData.error) {
            // Append Wallet Script
            const script = document.createElement("script");
            script.src = process.env.REACT_APP_WALLET_URL;
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                /** THIS ENVIRONMENT NAME IS HANDLE BY VAULT ***/
                let environmentName = String(vaultConstants.SERVER_ENVIRONMENT_NAME_HOST);
                loadGMWalletWidget(gpmsWalletData, environmentName);
                if (onLoad) {
                    onLoad();
                }
            }
            // If script isn't loaded correctly (wallet script unavailable), display error message 
            // on payment methods page and display guets payment widget in checkout
            script.onerror = () => {
                setIsLoading(false);
                store.dispatch(walletLoadError());
            }
        }
    }, [gpmsWalletData]);

    // Message banner state
    const [walletMessageBanner, setWalletMessage] = useState({
        message: '',
        type: ''
    });

    // Handle message banner
    useEffect(() => {
        if (walletMessageBanner.type) {
            function checkBanner() {
                // wait for banner in DOM, need to check if it exists
                const walletBannerWrapper = document.getElementById("gmwallet-msg-banner");
                if (!walletBannerWrapper) {
                    window.setTimeout(checkBanner, 100); /* this checks the flag every 100 milliseconds*/
                } else {
                    // create banner message
                    createWalletMessage();
                }
            }
            checkBanner();
        }
    }, [walletMessageBanner, isLoading]);

    function loadGMWalletWidget(gpmsWalletData, environmentName) {

        /** Setting up up default debug flag to true **/
        let isDebug = true;

        /** Environment name value will be replaced by vault, checking to see if its prod or not **/
        if (environmentName === 'accsprodw' || environmentName === 'accsprodm') {
            isDebug = false;
        }
        // Initialize widget
        const widget = new GMWallet({
            // Id for widget container (Required)
            containerId: "wallet-widget",

            // EPG Application Status Service URL (Required)
            // Checks if the application id is 'Active' in MyWallet application
            applicationStatusService: process.env.REACT_APP_WALLET_APP_STATUS_URL,

            // CRUD services URL (same as GPMS)
            walletRestService: `/wcs/resources/store/${AppSettings.storeId}/wallet/profile`,

            // GPMS Wallet Data (Required)
            walletData: gpmsWalletData,

            enableCheckout: enableCheckout,

            // default is false
            showMessageBanners: true,
            closeIcon: <svg class="styles_closeIcon__1QwbI" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 36 36"><svg viewBox="0 0 32 32" id="closeModalBtn"><path fill="#060505" clip-rule="evenodd" d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16   c8.836,0,16-7.163,16-16C32,7.163,24.836,0,16,0z M16,30C8.268,30,2,23.732,2,16C2,8.268,8.268,2,16,2s14,6.268,14,14   C30,23.732,23.732,30,16,30z" fill-rule="evenodd"></path><path fill="#060505" clip-rule="evenodd" d="M22.729,21.271l-5.268-5.269l5.238-5.195   c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.39-1.034-0.39-1.428,0l-5.231,5.188l-5.309-5.31c-0.394-0.396-1.034-0.396-1.428,0   c-0.394,0.395-0.394,1.037,0,1.432l5.301,5.302l-5.331,5.287c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.429,0   l5.324-5.28l5.276,5.276c0.394,0.396,1.034,0.396,1.428,0C23.123,22.308,23.123,21.667,22.729,21.271z" fill-rule="evenodd"></path></svg></svg>,

            clientId: process.env.REACT_APP_WALLET_CLIENT_ID,
            profileId: store.getState().Authentication.userData.logonId,

            // Wallet Credit Card Options
            creditCardOptions: {

                // Use MasterCard test servers, FALSE FOR PRODUCTION!
                useTestServers: isDebug,

                // MasterCard merchantId (Required)
                // Use dealer's merchant ID in checkout, 
                // otherwise use wallet merchant Id based on user profile locale
                merchantId: merchantId ? merchantId : `GM-${gpmsCountryCode}-WAL`,

                //Display images for accepted cards, false by default
                showCardImages: true
            },
            translations: WalletTranslations(),
            statusCallback: function (data) {
                data && handleStatus(data);
            },
            onSuccessCallback: function (data) {
                data && handleWalletSuccess(data);
            },
            onErrorCallback: function (error) {
                error && handleWalletError(error);
            },
            onSubmitCallback: function (data) {
                data && handleWalletSubmit(data);
            }
        });

    }

    function handleWalletSuccess(data) {
        // reinitialize wallet with new data...
        store.dispatch(getWalletData());

        let successMessage = '';

        let brand = data.creditCard && data.creditCard.brandDisplayName ? data.creditCard.brandDisplayName : null;
        let last4 = data.creditCard && data.creditCard.last4 ? data.creditCard.last4 : null;
        let newBrand = data.creditCard && data.creditCard.newDefaultBrandDisplayName ? data.creditCard.newDefaultBrandDisplayName : null;
        let newLast4 = data.creditCard && data.creditCard.newDefaultLast4 ? data.creditCard.newDefaultLast4 : null;

        // HANDLE SUCCESS MESSAGING
        switch (data.type) {
            case "CREATE":
                successMessage = i18next.t('LABEL_WALLET_ADD_PAY_METHOD_SUCCESS', { paymentType: brand, last4: last4 });
                break;
            case "EDIT":
                successMessage = i18next.t('LABEL_WALLET_EDIT_SUCCESS', { paymentType: brand, last4: last4 });
                break;
            case "DELETE":
                if (newBrand && newLast4) {
                    successMessage = i18next.t('LABEL_WALLET_DELETE_DEFAULT_PAY_METHOD_SUCCESS', { paymentType: brand, last4: last4, newPaymentType: newBrand, newLast4: newLast4 });
                }
                else if (brand && last4) {
                    successMessage = i18next.t('LABEL_WALLET_DELETE_PAY_METHOD_SUCCESS', { paymentType: brand, last4: last4 });
                }
                break;
        }

        setWalletMessage({ message: successMessage, type: 'success' })
    }

    function handleWalletError(error) {
        store.dispatch(setLoading(false));
        let errorMessage = ''
        if (error == "Request failed with status code 401") {
            store.dispatch(getRegistrationStatus());
        }
        setWalletMessage({ message: errorMessage, type: 'error' })
    }

    function handleWalletSubmit(data) {
        if (!data.errors) {
            store.dispatch(resetAllErrors());
            store.dispatch(updateCreditCardSuccessInformation(data));
            store.dispatch(nextStep());
            store.dispatch(setLoading(false));
        }
        else {
            store.dispatch(setLoading(false));
            store.dispatch(resetAllErrors());
            store.dispatch(enableMissingPaymentFlag());
        }
    }

    // Wallet status
    function handleStatus(data) {
        switch (data.status) {
            case "PENDING":
                setWalletMessage({ message: '', type: '' })
                setIsLoading(true);
                break;
            case "UNAUTHORIZED":
                store.dispatch(setLoading(false));
                store.dispatch(walletLoadError());
                setIsLoading(false);
                break;
            case "FAILURE":
                setIsLoading(false);
                break;
            case "AUTHORIZED":
            case "SUCCESS":
            default:
                setIsLoading(false);
                break;
        }
    }

    function createWalletMessage() {
        // create banner below wallet header to display messaging
        const walletBannerWrapper = document.getElementById("gmwallet-msg-banner");
        const walletBanner = document.getElementById("gmwallet-msg");
        const walletBannerIcon = document.getElementsByClassName("ac-bannerIcon");

        if (walletBanner) {
            // Scroll to banner
            walletBanner.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

            // SVG banner icon - displays different icon depending on message type
            const bannerIcon = walletMessageBanner.type === 'success' ?
                '<span class="ac-bannerIcon">' +
                '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>' +
                '</span>' :
                walletMessageBanner.type === 'error' ?
                    '<span class="ac-bannerIcon">' +
                    '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" class="svg-inline--fa fa-exclamation-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>' +
                    '</span>' : '';

            // Set message
            if (walletMessageBanner.message && walletBanner.innerHTML === "") {
                walletBanner.innerHTML = walletMessageBanner.message;
            }
            // If no message, hide banner
            if (walletMessageBanner.message === "" && walletBanner.innerHTML === "") {
                walletBannerWrapper.style.display = "none";
            }
            // Setup icons
            if (!walletBannerIcon[0]) {
                walletBanner.insertAdjacentHTML('afterbegin', bannerIcon);
            }
        }
    }

    return (
        <div className="ac-wallet-widget-container">
            {/* Spinner */}
            {!props.walletData.error && isLoading &&
                <div className="ac-wallet-widget-spinner-overlay">
                    <Spinner className="q-loader" />
                </div>}
            {/* Wallet Widget */}
            <div id="wallet-widget" />
        </div>
    )
};

const mapStateToProps = state => {
    return {
        walletData: state.WalletReducer
    }
};

export default connect(mapStateToProps)(WalletWidget);