import React, { Component } from 'react';
import i18next from 'i18next';

function HistoryShipStatus() {
    return (
        <div className="ac-history-ship-status-wrapper">
            <div className="ac-order-desc-label">{i18next.t('LABEL_ORDER_HISTORY_STATUS_CAPS')}</div>
            <div className="ac-order-desc-value q-body1">Shipped</div>
            <h6 className="q-body2" >
                <a href="#">Track Your Package</a>
            </h6>
        </div>
    )
}

export default HistoryShipStatus