import * as type from './HeaderActionType';

var initialState = {
  logo: '',
  flyoutText: '',
  loading: true
}

const HeaderReducer = (state = initialState, action) => {
  switch (action.type) {

    case type.SET_LOGO:
      return Object.assign({}, state, {
        logo: action.logo
      });

    case type.SET_DEALER_HEADER:
      return Object.assign({}, state, {
        address: action.address,
        phone: action.phone,
        name: action.name,
        orgId:action.orgId
      });

    case type.SET_FLYOUT_TEXT:
      return Object.assign({}, state, {
        flyoutText: action.flyoutText
      });

    case type.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.loading
      });

    case type.SET_LOGO_FAIL:
      var error = action.error;
      // console.log('SET_LOGO_FAIL: ' + error);
      return Object.assign({}, state, {
        logo: ''
      });

    case type.SET_FLYOUT_TEXT_FAIL:
      var error = action.error;
      // console.log('SET_LOGO_FAIL: ' + error);
      return Object.assign({}, state, {
        flyoutText: ''
      });

    default:
      return state;
  }
}

export default HeaderReducer;