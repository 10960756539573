import React, { Component } from "react";
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import { loadFitmentCheckAsync, closeModal, selectBody, selectWheel, selectTrim, selectDrive, selectEngine } from './FitmentCheckRedux/FitmentCheckActionCreator';
import Spinner from '../../../shared/Spinner/Spinner';
import ErrorMessage from "../../../shared/ErrorMessage/ErrorMessage";
import i18next from 'i18next';

let showBodyDropDown = false;
let showWheelDropDown = false;
let showTrimDropDown = false;
let showDriveDropDown = false;
let showEngineDropDown = false;

class FitmentCheckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBodyDropDown: false,
      showWheelDropDown: false,
      showTrimDropDown: false,
      showDriveDropDown: false,
      showEngineDropDown: false
    };

    this.isWheelSelectDisabled = this.isWheelSelectDisabled.bind(this);
    this.isTrimSelectDisabled = this.isTrimSelectDisabled.bind(this);
    this.isDriveSelectDisabled = this.isDriveSelectDisabled.bind(this);
    this.isEngineSelectDisabled = this.isEngineSelectDisabled.bind(this);
  }

  //disable descending drop downs until previous options have been selected because
  //selections are filtered as the user selectis options
  isWheelSelectDisabled() {
    return (showBodyDropDown && this.props.vehicleParams.bodyParam == '');
  }

  isTrimSelectDisabled() {
    return (showBodyDropDown && this.props.vehicleParams.bodyParam == '') || (showWheelDropDown && this.props.vehicleParams.wheelParam == '');
  }

  isDriveSelectDisabled() {
    return (showBodyDropDown && this.props.vehicleParams.bodyParam == '') || (showWheelDropDown && this.props.vehicleParams.wheelParam == '') || (showTrimDropDown && this.props.vehicleParams.trimParam == '');
  }

  isEngineSelectDisabled() {
    return (showBodyDropDown && this.props.vehicleParams.bodyParam == '') || (showWheelDropDown && this.props.vehicleParams.wheelParam == '') || (showTrimDropDown && this.props.vehicleParams.trimParam == '') || (showDriveDropDown && this.props.vehicleParams.driveParam == '');
  }

  isFitmentCheckButtonDisabled() {
    return (showBodyDropDown && this.props.vehicleParams.bodyParam == '') || (showWheelDropDown && this.props.vehicleParams.wheelParam == '') || (showTrimDropDown && this.props.vehicleParams.trimParam == '') || (showDriveDropDown && this.props.vehicleParams.driveParam == '') || (showEngineDropDown && this.props.vehicleParams.engineParam == '');
  }

  isFitmentCheckButtonDisplayed() {
    if ((this.props.isFetchingVehicleCombinations ||
      (!this.props.isFetchingVehicleCombinations && this.props.isFetchingFitmentCheck))) {
      return "hidden";
    }
  }

  renderDropdown(label, defaultValueLabel, value, options, onChange, disabled) {
    console.warn('fitment dropdown value', label)
    return (
      <div key={label}>
        <label className="gmit-gf-label-text">{(label)}</label>
        <select className="q-form-dropdown medium-margin stat-dropdown"
          id={defaultValueLabel.replace(/ /g, '-') + "-dropdown"}
          data-dtm={label}
          value={value}
          onChange={onChange}
          disabled={disabled}>
          <option hidden value="">{(defaultValueLabel)}</option>
          {options}
        </select>
      </div>
    )
  }

  getFitmentMap(fitmentDetails) {
    let obj = {};
    for (let i = 0; i < fitmentDetails.length; i++) {
      obj[fitmentDetails[i]] = true;
    }
    return obj;
  }

  getFitmentDropdowns() {
    const { t, vehicleParams, fitmentDetails } = this.props;

    showBodyDropDown = false;
    showWheelDropDown = false;
    showTrimDropDown = false;
    showDriveDropDown = false;
    showEngineDropDown = false;

    // show modal only if there is fitment check details data (fitmentStatus = "more info needed")
    if (fitmentDetails) {
      let fitmentMap = this.getFitmentMap(fitmentDetails);
      showBodyDropDown = fitmentMap["bodyStyle"] || false;
      showWheelDropDown = fitmentMap["wheelBase"] || false;
      showTrimDropDown = fitmentMap["trim"] || false;
      showDriveDropDown = fitmentMap["driveType"] || false;
      showEngineDropDown = fitmentMap["engineBase"] || false;

      let fitmentDropdowns = [];

      if (showBodyDropDown) {
        const body = this.props.bodyFitmentSelection;
        /* If the accessory doesn't fit the selected vehicle,
            the search page redirect call needs the 'body number of doors'
            in addition to the 'body type id' for its params. Hence the crazy
            value passed below (value passed to selectBody action - FitmentCheckActionCreator.js)
        */
        const options = body.map((bodyType) => (
          <option id={bodyType} key={bodyType.BODY_TYPE_ID}
            data-object={JSON.stringify(bodyType)}
            value={bodyType.BODY_TYPE_ID}>{bodyType.BODY_TYPE_NAME}</option>));

        fitmentDropdowns.push(this.renderDropdown(t('LABEL_FITMENT_BODY_STYLE_CAPS'),
          t('LABEL_FITMENT_SELECT_BODY_STYLE'),
          vehicleParams.bodyParam,
          options,
          this.props.handleBodyChange));
      }

      if (showWheelDropDown) {
        const wheel = this.props.wheelFitmentSelection;
        //box/wheel dropdown select should display box or wheel depending on data
        const boxOrWheel = this.props.wheelDropDownLabel;
        const options = wheel.map((wheelType) => (
          <option key={wheelType.WHEEL_BASE_ID} data-object={JSON.stringify(wheelType)} value={wheelType.WHEEL_BASE_ID}>
            {wheelType.WHEEL_LABEL}</option>))

        fitmentDropdowns.push(this.renderDropdown(t('LABEL_FITMENT_BOX_OR_WHEEL_TYPE_CAPS'),
          t('LABEL_FITMENT_SELECT_BOX_OR_WHEEL_TYPE', { boxOrWheel }),
          vehicleParams.wheelParam,
          options,
          this.props.handleWheelChange,
          this.isWheelSelectDisabled()));
      }

      if (showTrimDropDown) {
        const trim = this.props.trimFitmentSelection;
        const options = trim.map((trimType) => (
          <option key={trimType.SUBMODEL_ID} data-object={JSON.stringify(trimType)} value={trimType.SUBMODEL_ID}>
            {trimType.SUBMODEL_NAME}</option>))

        fitmentDropdowns.push(this.renderDropdown(t('LABEL_FITMENT_TRIM_TYPE_CAPS'),
          t('LABEL_FITMENT_SELECT_TRIM'),
          vehicleParams.trimParam,
          options,
          this.props.handleTrimChange,
          this.isTrimSelectDisabled()));
      }

      if (showDriveDropDown) {
        const drive = this.props.driveFitmentSelection;
        const options = drive.map((driveType) => (
          <option key={driveType.DRIVE_TYPE_ID}
            data-object={JSON.stringify(driveType)}
            value={driveType.DRIVE_TYPE_ID}>
            {driveType.DRIVE_TYPE_NAME}</option>));

        fitmentDropdowns.push(this.renderDropdown(t('LABEL_FITMENT_DRIVE_TYPE_CAPS'),
          t('LABEL_FITMENT_SELECT_DRIVE_TYPE'),
          vehicleParams.driveParam,
          options,
          this.props.handleDriveChange,
          this.isDriveSelectDisabled()));
      }

      if (showEngineDropDown) {
        const engine = this.props.engineFitmentSelection;
        const options = engine.map((engineType) => (
          <option key={engineType.ENGINE_BASE_ID}
            data-object={JSON.stringify(engineType)}
            value={engineType.ENGINE_BASE_ID}>
            {engineType.ENGINE_LABEL}</option>));

        fitmentDropdowns.push(this.renderDropdown(t('LABEL_FITMENT_BOX_ENGINE_TYPE_CAPS'),
          t('LABEL_FITMENT_SELECT_ENGINE_TYPE'),
          vehicleParams.engineParam,
          options,
          this.props.handleEngineChange,
          this.isEngineSelectDisabled()));
      }

      return (
        <div className="q-gridbuilder gridfullwidth gmit-gf-request-type">
          {
            this.props.showError && <ErrorMessage icon={true} message={i18next.t("LABEL_SERVER_ERROR_MSG")}/>
          }
          <div>{fitmentDropdowns}</div>
        </div>
      )

    } else {
      return null
    }

  }

  // The fitment check button remains disabled until all visible dropdowns have been selected
  // TODO: add form validation and inline error messages to dropdowns for better user experience
  fitmentModalButton() {
    return (
      <Translation>
        {(t) => (
          <div className="small-12">
            <button
              className="q-button q-primary-button gm-primary-btn stat-button-link"
              disabled={this.isFitmentCheckButtonDisabled()}
              onClick={this.props.checkFit}
              data-dtm="apply">
              {t('LABEL_COMMON_CONTINUE')}
            </button>
          </div>
        )}
      </Translation>
    )
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div>
            <h5 className="q-headline2 q-bold2 ac-fitment-header">{t('LABEL_PRODUCT_DETAILS_PROVIDE_MORE_INFO')}</h5>
            <hr />
            <div className="q-gridbuilder gridfullwidth">
              <div className={"ac-fitment-dropdown-container-" + this.props.fitmentStep}>
                {(this.props.isFetchingVehicleCombinations ||
                  (!this.props.isFetchingVehicleCombinations && this.props.isFetchingFitmentCheck))
                  && <div className="ac-fitment-spinner-overlay">
                    <div className="ac-fitment-spinner">
                      <Spinner />
                    </div>
                  </div>}
                {(!this.props.isFetchingVehicleCombinations ||
                  (!this.props.isFetchingVehicleCombinations && !this.props.isFetchingFitmentCheck))
                  && this.getFitmentDropdowns()}
              </div>
              <div className={this.isFitmentCheckButtonDisplayed()}>
                {this.fitmentModalButton()}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}


function mapDispatchToProps(dispatch, ownProps) {

  const vehicleCombinations = ownProps.vehicleCombinations;
  const bodyFitmentSelection = ownProps.bodyFitmentSelection;
  const wheelFitmentSelection = ownProps.wheelFitmentSelection;
  const trimFitmentSelection = ownProps.trimFitmentSelection;
  const driveFitmentSelection = ownProps.driveFitmentSelection;
  const engineFitmentSelection = ownProps.engineFitmentSelection;
  const vehicleParams = ownProps.vehicleParams;
  const fitmentVehicleInfo = ownProps.fitmentVehicleInfo;
  const dropdownLabel = ownProps.wheelDropDownLabel;

  return {
    checkFit: () => {
      dispatch(loadFitmentCheckAsync('modal'));
    },
    handleBodyChange: (e) => {
      const selectedBodyValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));//JSON.parse(e.target.value);
      dispatch(selectBody(selectedBodyValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, dropdownLabel, bodyFitmentSelection));
    },
    handleWheelChange: (e) => {
      const selectedWheelValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
      dispatch(selectWheel(selectedWheelValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection, wheelFitmentSelection));
    },
    handleTrimChange: (e) => {
      const selectedTrimValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
      dispatch(selectTrim(selectedTrimValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection, wheelFitmentSelection, trimFitmentSelection));
    },
    handleDriveChange: (e) => {
      const selectedDriveValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
      dispatch(selectDrive(selectedDriveValues, fitmentVehicleInfo, vehicleParams, vehicleCombinations, bodyFitmentSelection, wheelFitmentSelection, trimFitmentSelection, driveFitmentSelection));
    },
    handleEngineChange: (e) => {
      const selectedEngineValues = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute("data-object"));
      dispatch(selectEngine(selectedEngineValues, fitmentVehicleInfo, vehicleParams, bodyFitmentSelection, wheelFitmentSelection, trimFitmentSelection, driveFitmentSelection, engineFitmentSelection));
    },
  }
};

export default withTranslation()(connect(null, mapDispatchToProps)(FitmentCheckForm));