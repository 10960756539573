import * as type from "./SearchLinkActionType";

var initialState = {
    "year" : "",
    "make" : "",
    "model" : "",
    "categoryName" : "",
    "categoryId" : "",
    "vin": ""
}

const SearchLinkReducer = (state = initialState, action) => {
    switch(action.type) {
        case  type.SET_YEAR:
            return Object.assign({}, state, { year: action.year });
        case  type.SET_MAKE:
            return Object.assign({}, state, { make: action.make });
        case  type.SET_MODEL:
            return Object.assign({}, state, { model: action.model });
        case  type.SET_CATEGORY:
            return Object.assign({}, state, { categoryId: action.id, categoryName: action.name });
        case type.SET_VIN:
            return Object.assign({}, state, { vin: action.vin });
        default: return state;
    }
}

export default SearchLinkReducer;