import axios from 'axios';
import AppSettings from "../../../core/AppSettings";



const enrolModalEspotURL = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Enroll_Modal?noCache=true";

export const getEnrollModalEspot = () => {
    return axios.get(enrolModalEspotURL).then((response) => { return response; }, (error) => { return error; });
};


//Enroll Confirmation Modal Espot ( Modal State 2)
const enrollConfirmationModalEspotURL = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Enroll_Confirm_Modal?noCache=true";
export const getEnrollConfirmationModalEspot = () => {
    return axios.get(enrollConfirmationModalEspotURL).then((response) => { return response; }, (error) => { return error; });
};
