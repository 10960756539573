import * as type from './OrderInfoActionTypes';
import store from "../../../core/Redux/Store";

/** FORMATTING INCREMENT ACTION BEFORE DISPATCH, INCREASE QTY BY 1**/
export const incrementAction = (param) => {
    return {
        type: type.ORDER_INFO_QTY_INCREMENT,
        payload: {
            lineItemId: param.orderItemId,
            laborOrderItemId: param.laborOrderItemId,
            qty: parseFloat(param.value) + 1
        }
    }
};

/** FORMATTING DECREMENT ACTION BEFORE DISPATCH,DECREASE QTY BY 1 **/
export const decrementAction = (param) => {
    const quantity = parseFloat(param.value);
    if (quantity > 1) {
        return {
            type: type.ORDER_INFO_QTY_DECREMENT,
            payload: {
                lineItemId: param.orderItemId,
                laborOrderItemId: param.laborOrderItemId,
                qty: parseFloat(param.value) - 1
            }
        }
    }

};

/** FORMATTING CHANGE INPUT ACTION BEFORE DISPATCH,TAKE A NEW QTY FROM USER INPUT **/
export const inputChange = (event, param) => {
    const val = event.currentTarget.value;
    return {
        type: type.ORDER_INFO_QTY_INPUT_CHANGE,
        payload: {
            lineItemId: param.orderItemId,
            laborOrderItemId: param.laborOrderItemId,
            qty: val
        }
    }
};

export const updateOrderItemQuantity = (orderItemId, laborOrderItemId, newQuantity) => {
    const orderId = store.getState().OrderInfoReducer.response.order.orderInfo.orderId;
    return {
        type: type.ORDER_INFO_QTY_INPUT_CHANGE,
        payload: {
            lineItemId: orderItemId,
            laborOrderItemId: laborOrderItemId,
            qty: newQuantity,
            orderId: orderId
        }
    }
};

/** FORMATTING REMOVE LINE ITEM ACTION BEFORE DISPATCH **/
export const removeLineItem = (param) => {
    return {
        type: type.ORDER_INFO_LINE_ITEM_REMOVE,
        payload: {
            orderItemIds: param
        }
    }
};

/** VALIDATING ITEMID AND QTY BEFORE DISPATCHING AN ACTION,FUNCTION RETURNS TRUE IF CONDITION IS MET**/
export const validateIncrement = (ownProps) => {
    const itemId = ownProps.orderItemId;
    const qty = ownProps.value;
    return (itemId !== undefined && itemId !== null && itemId !== '' && qty !== undefined && qty !== null && qty !== '')
};

/** VALIDATING ITEMID AND QTY BEFORE DISPATCHING AN ACTION,FUNCTION RETURNS TRUE IF CONDITION IS MET**/
export const validateDecrement = (ownProps) => {
    const itemId = ownProps.orderItemId;
    const qty = ownProps.value;
    return (itemId !== undefined && itemId !== null && itemId !== '' && qty !== undefined && qty !== null && qty !== '' && qty > 1)
};

/** VALIDATING ITEMID AND QTY BEFORE DISPATCHING AN ACTION,FUNCTION RETURNS TRUE IF CONDITION IS MET**/
export const validateInputChange = (event) => {
    const val = event.currentTarget.value;
    const pattern = /^\d+$/;
    const testNumber = pattern.test(val);
    return (testNumber && val > 0)
};

/** VALIDATING ITEMID BEFORE DISPATCHING AN ACTION,FUNCTION RETURNS TRUE IF CONDITION IS MET**/
export const validateLineRemove = (ownProps) => {
    const itemId = ownProps.orderItemId;
    return (itemId !== undefined && itemId !== null && itemId !== '')
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderInfoDataAsync = (response, parsedResponse) => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART,
        payload: {
            response: response,
            parsedResponse: parsedResponse
        }
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderInfoDataAsyncFail = (error) => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL,
        error: error
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadShippingRatesAsync = (response, fedexRateError) => {
    return {
        type: type.ORDER_INFO_SET_SHIPPING_RATES,
        payload: response,
        fedexRateError: fedexRateError
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadShippingRatesAsyncFail = (error) => {
    return {
        type: type.ORDER_INFO_SET_SHIPPING_RATES_FAIL,
        error: error
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderItemChangeQuantityResponseFail = (error) => {
    return {
        type: type.ORDER_INFO_QTY_CHANGE_FAIL,
        error: error
    };
};

/**SETTING UP TYPE AND PAYLOAD TO DISPATCH **/
export const loadOrderItemDeleteResponseFail = (error) => {
    return {
        type: type.ORDER_INFO_LINE_ITEM_REMOVE_FAIL,
        error: error
    };
};

/*** CALL SWITCH ORG FOR T3 ORDERS **/
export const loadSwitchOrgAsync = () => {
    return {
        type: type.ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC
    }
};

/*** CALL SWITCH ORG FOR T3 FAILED **/
export const loadSwitchOrgAsyncFailed = (err) => {
    return {
        type: type.ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC_FAILED,
        payload: err
    }
};

/*** Order Info Async **/
export const loadOrderInfoAsync = () => {
    return {
        type: type.ORDER_INFO_ORDER_IN_CART_ASYNC
    }
};


export const cartQuantityShowTextInput = (showTextInput, orderItemId) => {
    const action = {
        type: type.ORDER_INFO_CART_QUANTITY_SHOW_TEXT_INPUT,
        showTextInput: showTextInput,
        orderItemId: orderItemId
    }
    return action;
}

export const cartQuantityShowSpinner = (showSpinner, orderItemId) => {
    const action = {
        type: type.ORDER_INFO_CART_QUANTITY_SHOW_SPINNER,
        showSpinner: showSpinner,
        orderItemId: orderItemId
    }
    return action;
}

export const cartRemoveShowSpinner = (showSpinner, orderItemId) => {
    const action = {
        type: type.ORDER_INFO_CART_REMOVE_SHOW_SPINNER,
        showRemoveSpinner: showSpinner,
        orderItemId: orderItemId
    }
    return action;
}

export const miniCartRemoveShowSpinner = (showSpinner, orderItemId) => {
    const action = {
        type: type.ORDER_INFO_MINI_CART_REMOVE_SHOW_SPINNER,
        showRemoveSpinner: showSpinner,
        orderItemId: orderItemId
    }
    return action;
}

export const cartReset = () => {
    const action = {
        type: type.ORDER_INFO_RESET
    }
    return action;
}
