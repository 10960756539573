import * as type from './AuthenticationActionTypes';

/** USER LOGIN */
export const requestUserLogin = (idToken, state) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGIN_REQUEST,
        idToken: idToken,
        state: state,
        isFetching: true,
        isAuthenticated: false
    }
    return action;
}

export const userLoginSuccess = () => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true
    }
    return action;
}

export const userLoginError = (message) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGIN_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message
    }
    return action;
}

export const unverifiedLoginError = (message, unverifiedEmail) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGIN_UNVERIFIED_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        unverifiedEmail: unverifiedEmail,
        message
    }
    return action;
}

export const showAuthModal = (showModal) => {
    const action = {
        type: type.AUTHENTICATION_NOTIFICATION_MODAL,
        showAuthModal: showModal
    }
    return action;
}


/** CHECK SSO */
export const setSSOCheck = (checkSSO) => {
    const action = {
        type: type.AUTHENTICATION_CHECK_SSO,
        checkSSO: checkSSO
    }
    return action;
}

/** USER LOGOUT */
export const requestUserLogout = (userFlow, clientId, state, redirectPath) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGOUT_REQUEST,
        redirectPath: redirectPath,
        clientId: clientId,
        userFlow: userFlow,
        state: state,
        isFetching: true,
        isAuthenticated: false
    }
    return action;
}

export const userLogoutSuccess = (redirectPath) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false,
        redirectPath: redirectPath
    }
    return action;
}

export const userLogoutError = (message) => {
    const action = {
        type: type.AUTHENTICATION_USER_LOGOUT_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message
    }
    return action;
}

/** GUEST LOGOUT */
export const requestGuestLogout = () => {
    const action = {
        type: type.AUTHENTICATION_GUEST_LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false
    }
    return action;
}

export const guestLogoutSuccess = () => {
    const action = {
        type: type.AUTHENTICATION_GUEST_LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false
    }
    return action;
}

export const guestLogoutError = (message) => {
    const action = {
        type: type.AUTHENTICATION_GUEST_LOGOUT_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message
    }
    return action;
}

/** REGISTRATION STATUS */
export const getRegistrationStatus = () => {
    const action = {
        type: type.AUTHENTICATION_REGISTRATION_STATUS_REQUEST
    }
    return action;
}

export const setRegistrationStatus = (status) => {
    const action = {
        type: type.SET_REGISTRATION_STATUS,
        registrationStatus: status,
        registrationStatusFetched: true
    }
    return action;
}

export const setUserInformation = (userData) => {
    const action = {
        type: type.AUTHENTICATION_SET_USER_INFO,
        userData: userData
    }
    return action;
}

export const setEnrolledAttributeValue = (enrolledAttributeValue) => {
    const action = {
        type: type.AUTHENTICATION_SET_ENROLLED_ATTRIBUTE_VALUE,
        enrolledAttributeValue: enrolledAttributeValue
    }

    return action;
}

/** AUTH STATE VALUES */
export const setAuthenticationStateValues = (idToken, stateJSON) => {
    const action = {
        type: type.AUTHENTICATION_SET_PARSED_VALUES,
        state: stateJSON,
        idToken: idToken
    }
    return action;
}

/**  AUTH COOKIE STATUS **/
export const setAuthenticationCookieIsSessionExpired = (status) => {
    const action = {
        type: type.AUTHENTICATION_SET_COOKIE_EXPIRY_STATUS,
        isSessionExpired: status
    }
    return action;
}

/** Dispatch to call SSO Worker **/
export const azureSingleSignOnCheck = () => {
    const action = {
        type: type.AZURE_SINGLE_SIGN_ON_CHECK,
     }
    return action;
};

/** Render app ***/
export const renderApp = (value)=>{
    const action = {
        type: type.RENDER_APP,
        payload:value
    }
    return action;
};

/*** Add wrapper for page redirect ***/
export const addWrapperForRedirect = (value)=>{
    const action = {
        type: type.ADD_WRAPPER_FOR_PAGE_REDIRECT,
        addWrapper:value
    }
    return action;
};
