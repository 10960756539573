///icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faAward,
    faCar,
    faCaretDown,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckCircle,
    faCircle,
    faCreditCard,
    faExclamation,
    faExternalLinkAlt,
    faHistory,
    faShoppingCart,
    faTimes,
    faUserAlt,
    faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import React, { useEffect, useRef, useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import AppSettings, { getPageName } from "../../core/AppSettings";
import DTM from "../../shared/Analytics/DTM";
import vaultConstants from "../../../config/vault_constants";
import VaultPortalUI, {
    VAULT_CONTROLLER_DOM_ID,
    ENABLE_VAULT_CONTROLLER_SOURCE
} from "../../../config/VaultUI/VaultUI";
import Authentication from "../../shared/Authentication/Authentication";
import AuthenticationModal from "../../shared/Authentication/AuthenticationModal/AuthenticationModal";
import EnrolledConfirmationModal from "../../shared/Authentication/EnrollmentModal/EnrolledConfirmationModal";
import ErrorPage from "../../shared/ErrorPage/ErrorPage";
import PageNotFound from "../../shared/PageNotFound/PageNotFound";
import ScrollToTop from "../../shared/ScrollToTop/ScrollToTop";
import { axiosSetup } from "../../shared/Utils/AxiosConfig";
import AccountConfirmation from "../AccountCreation/AccountConfirmation/AccountConfirmation";
import AccountDetails from "../AccountCreation/AccountDetails/AccountDetails";
import CreatePassword from "../AccountCreation/CreatePassword/CreatePassword";
import RegisterEmail from "../AccountCreation/EnterEmail/RegisterEmail";
import AccountVerification from "../AccountCreation/ExceptionFlows/AccountVerification";
import UserAlreadyExists from "../AccountCreation/UserAlreadyExists/UserAlreadyExists";
import AddOrderItemWithPromotionCodeOrCoupon from "../API/AddOrderItemWithPromotionCodeOrCoupon/AddOrderItemWithPromotionCodeOrCoupon";
import Cart from "../Cart/Cart";
import Roadblock from "../Cart/Roadblock";
import Checkout from "../Checkout/Checkout";
import Home from "../Home/Home";
import OrderConfirmation from "../OrderConfirmation/OrderConfirmation";
import EmailConfirmation from "../PasswordReset/EmailConfirmation/EmailConfirmation";
import PasswordResetExpired from "../PasswordReset/PasswordResetExpired/PasswordResetExpired";
import RequestPasswordReset from "../PasswordReset/RequestPasswordReset/RequestPasswordReset";
import UpdatePassword from "../PasswordReset/UpdatePassword/UpdatePassword";
import ProductDetails from "../ProductDetails/ProductDetails";
import Search from "../Search/Search";
import AllProductsResultsContainer from "../Search/AllProductsResultsContainer";
import ProductSearchResultsContainer from "../Search/ProductSearchResultsContainer";

import CategoryLandingPage from "../CategoryLandingPage/CategoryLandingPage";
import UserProfile from "../UserProfile/UserProfile";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import accHistory from "./History";
import SessionTimeout from "../../shared/Authentication/SessionTimeout/SessionTimeout";
import CouponsBanner from "../../shared/CouponsBanner/CouponsBanner";

import { autoDealerizeByBestAvailableLocation } from "../DealerLocator/AutoDealerize";
import HomeSearch from "../Home/HomeSearch/HomeSearch";
import { validateFitmentCookie } from "../../shared/VehicleSelect/CookieService/FitmentData";
import store from "../../core/Redux/Store";
import { updateVehicleInfoByData } from "../../shared/Session/VehicleInfo/VehicleInfoActionCreator";
import { setFitmentCookie } from "../../shared/VehicleSelect/CookieService/FitmentData";
import { loadVINDataAsync } from "../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator";
import { useSelector } from "react-redux";
import { jsonToUrlParams } from "../../shared/Utils/Utils";
import { syncUrlWithFitmentCookie } from "../Search/SearchUtils";

library.add(
    faExclamationCircle,
    faExclamation,
    faShoppingCart,
    faCaretDown,
    faCaretUp,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faCaretRight,
    faCircle,
    faCheck,
    faTimes,
    faCheckCircle,
    faUserCircle,
    faCreditCard,
    faCar,
    faUserAlt,
    faExternalLinkAlt,
    faHistory,
    faAward,
    faAngleUp,
    faAngleDown
);

const isT3 = AppSettings.isT3;
const dealerName = AppSettings.dealerName;
const featureCLPFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2097306_CLP_ROUTE;
const featureCookieFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2246212_FITMENT_COOKIE;

//Setup axios
axiosSetup();

// ========= CHECK IF TESTING =========
const isJestProcess = process.env?.JEST_WORKER_ID !== undefined;

//Run Dynatrace on applicaiton
// loadDynatraceScript();

const couponBannerPageNames = new Set(["HomePage", "Search", "Product", "Cart", "Authenticate"]);
const homeSearchPageNames = new Set(["", "HomePage"]);

const App = () => {
    const [isLoaded, setisLoaded] = useState(false);
    const [displayCoupon, setdisplayCoupon] = useState(false);
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const [vaultUIActive, setVaultUIActive] = useState(false);
    const initialRender = useRef(true);
    const sessionReducerData = useSelector((state) => state.Session);

    // check to see if current page should have search bar displayed
    function checkPageName() {
        if (
            (AppSettings.isLocalHost && homeSearchPageNames.has(getPageName())) ||
            (vaultConstants.FF_2237162_HOME_PAGE_SEARCH_BAR && homeSearchPageNames.has(getPageName()))
        ) {
            setDisplaySearchBar(true);
        } else {
            setDisplaySearchBar(false);
        }
    }

    document.onreadystatechange = function () {
        // display sites after assets have loaded
        // prevents async page flickering
        if (document.readyState === "complete") {
            setisLoaded(true);
        }
        // Only display Coupon Banner when on HomePage, SRP, PDP, and Cart.
        if (couponBannerPageNames.has(AppSettings.pageName)) {
            setdisplayCoupon(true);
        }
    };

    useEffect(() => {
        if (featureCookieFlag) {
            // read fitment cookie on every reload
            let URLLocation = isT3 ? location.pathname.split("/")[2] : location.pathname.split("/")[1];
            let urlParameters = AppSettings.urlParameters();
            // read fitment cookie on every reload
            if (!isT3 && (!URLLocation || URLLocation==="authenticate" || URLLocation === "checkoutmethod" || URLLocation === "checkout" || URLLocation ==="orderconfirmation")) {
                //at homepage
                validateFitmentCookie().then((data) => {
                    const fitmentCookie = data.data.cookie;
                    if (fitmentCookie) {
                        // saves cookie into session storage
                        store.dispatch(updateVehicleInfoByData(fitmentCookie));
                    }
                });
            }
            // for deep linked will see what page your on and update cookie using the URL
            if (URLLocation == "categories" || URLLocation == "product" || URLLocation == "search") {
                if (urlParameters.vin) {
                    let UrlVin = urlParameters.vin;
                    let searchType = "vin";
                    store.dispatch(loadVINDataAsync(searchType, UrlVin));
                    return;
                }
                // URL does not provide makeID. added makeID for consistency with data from homepage fitment
                switch (urlParameters.make) {
                    case "Buick":
                        urlParameters.makeId = "45";
                        break;
                    case "Cadillac":
                        urlParameters.makeId = "46";
                        break;
                    case "Chevrolet":
                        urlParameters.makeId = "47";
                        break;
                    case "GMC":
                        urlParameters.makeId = "48";
                        break;
                }

                if (isT3) {
                    store.dispatch(updateVehicleInfoByData({
                        body: urlParameters.body || "",
                        bodyId: urlParameters.bodyId || "",
                        bodyNumDoorsId: urlParameters.bodyNumDoorsId || "",
                        drive: urlParameters.drive || "",
                        driveId: urlParameters.driveId || "",
                        engine: urlParameters.engine || "",
                        engineId: urlParameters.engineId || "",
                        make: urlParameters.make || "",
                        makeId: urlParameters.makeId || "",
                        model: urlParameters.model || "",
                        modelId: urlParameters.modelId || "",
                        trim: urlParameters.trim || "",
                        trimId: urlParameters.trimId || "",
                        vin: urlParameters.vin || "",
                        wheel: urlParameters.wheel || "",
                        wheelId: urlParameters.wheelId || "",
                        year: urlParameters.year || ""
                    }))
                } else {
                    setFitmentCookie(urlParameters);
                }
            }
        }
    }, []);

    useEffect(() => {
        autoDealerizeByBestAvailableLocation();
        // Check to see if search bar should be displayed on page load.
        checkPageName();
    }, []);

    //Detects if url route has changed, and if it has, will determine if search bar should appear on page, and if url is matching with fitment cookie.

    useEffect(() => {

        const unlisten = accHistory.listen(() => {
            checkPageName();
            syncUrlWithFitmentCookie(sessionReducerData, initialRender);
        });
        if (!initialRender.current) {
            //don't want to fire on initial render as url should take precedence for deep linking.
            syncUrlWithFitmentCookie(sessionReducerData, initialRender);
        }
        if (initialRender.current) {
            initialRender.current = false;
        }

        return unlisten;
    }, [sessionReducerData]);

    useEffect(
        ENABLE_VAULT_CONTROLLER_SOURCE
            ? () => {
                  const thisObserver = new MutationObserver((records, observer) => {
                      const onFound = () => {
                          setVaultUIActive(true);
                          observer.disconnect();
                      };

                      for (const record of records) {
                          if (record.addedNodes.length > 0) {
                              const found = Array.from(record.addedNodes).some(
                                  (node) => node?.previousSibling?.id === VAULT_CONTROLLER_DOM_ID
                              );
                              if (found) onFound();
                          }
                      }
                  });

                  const body = document.getElementsByTagName("body").item(0);
                  thisObserver.observe(body, { childList: true });
              }
            : () => {},
        []
    );

    const VaultUI = <VaultPortalUI />;

    return (
        <Router history={accHistory}>
            <ScrollToTop>
                <div className="App">
                    {vaultUIActive && VaultUI}
                    {/* initialize DTM on page load (without waiting for readyState) */}
                    <DTM />
                    {/*** Show Page spinner until self call is resolved ****/}
                    {isLoaded && (
                        <>
                            <Header setdisplayCoupon={setdisplayCoupon} setDisplaySearchBar={setDisplaySearchBar} />
                            <div className="ac-main-container">
                                {displayCoupon && <CouponsBanner />}
                                {!isT3 && displaySearchBar && <HomeSearch />}
                                {/* Displayed only if login fails. See AuthenticationModal.js for more info */}
                                <AuthenticationModal />
                                <SessionTimeout />
                                {localStorage.getItem("showEnrolledConfirmationModal") && <EnrolledConfirmationModal />}
                                {/* add routes here */}
                                <Switch>
                                    {isT3
                                        ? [
                                              <Route exact path={dealerName} component={Home} key="dealer-home" />,
                                              <Route
                                                  path={dealerName + "/search"}
                                                  component={Search}
                                                  key="dealer-search"
                                              />,
                                              <Route
                                                  path={dealerName + AppSettings.routePaths["product"]}
                                                  component={ProductDetails}
                                                  name="details"
                                                  key="dealer-details"
                                              />,
                                              <Route path={dealerName + "/cart"} component={Cart} key="dealer-cart" />,
                                              <Route
                                                  path={dealerName + "/checkout"}
                                                  component={Checkout}
                                                  key="dealer-checkout"
                                              />,
                                              <Route
                                                  path={dealerName + "/orderconfirmation"}
                                                  component={OrderConfirmation}
                                                  key="dealer-orderconfirmation"
                                              />,
                                              <Route
                                                  path={dealerName + "/error"}
                                                  component={ErrorPage}
                                                  key="dealer-error"
                                              />,
                                              <Route component={PageNotFound} key="dealer-pg-not-found" />
                                          ]
                                        : [
                                              <Route exact path="/(|home)/" component={Home} key="home" />,
                                              <Route
                                                  path="/search"
                                                  component={AllProductsResultsContainer}
                                                  key="search"
                                              />,
                                              <Route
                                                  path="/product/search"
                                                  component={ProductSearchResultsContainer}
                                                  key="productSearch"
                                              />,
                                              featureCLPFlag && (
                                                  <Route
                                                      path={"/categories"}
                                                      component={CategoryLandingPage}
                                                      key="categories"
                                                  />
                                              ),
                                              <Route
                                                  path={AppSettings.routePaths["product"]}
                                                  component={ProductDetails}
                                                  name="details"
                                                  key="details"
                                              />,
                                              <Route path="/cart" component={Cart} key="cart" />,
                                              <Route path="/checkout" component={Checkout} key="checkout" />,
                                              <Route
                                                  path="/orderconfirmation"
                                                  component={OrderConfirmation}
                                                  key="orderconfirmation"
                                              />,
                                              <Route path="/error" component={ErrorPage} key="error" />,
                                              <Route
                                                  path="/AddOrderItemWithPromotionCodeOrCoupon"
                                                  component={AddOrderItemWithPromotionCodeOrCoupon}
                                                  key="AddOrderItemWithPromotionCodeOrCoupon"
                                              />,
                                              <Route
                                                  path="/checkoutmethod"
                                                  component={Roadblock}
                                                  key="checkoutmethod"
                                              />,
                                              <Route
                                                  path="/authenticate"
                                                  component={Authentication}
                                                  key="authenticate"
                                              />,
                                              <Route
                                                  exact
                                                  path="/forgotUsernamePassword"
                                                  component={RequestPasswordReset}
                                                  key="forgotUsernamePassword"
                                              />,
                                              <Route
                                                  path="/forgotUsernamePassword/emailConfirmation"
                                                  component={EmailConfirmation}
                                                  key="emailconfirmation"
                                              />,
                                              <Route
                                                  path="/forgotUsernamePassword/updatePassword"
                                                  component={UpdatePassword}
                                                  key="updatepassword"
                                              />,
                                              <Route
                                                  path="/forgotUsernamePassword/resetExpired"
                                                  component={PasswordResetExpired}
                                                  key="resetexpired"
                                              />,
                                              <Route exact path="/register" component={RegisterEmail} key="register" />,
                                              <Route
                                                  path="/register/createPassword"
                                                  component={CreatePassword}
                                                  key="createpassword"
                                              />,
                                              <Route
                                                  path="/register/accountConfirmation"
                                                  component={AccountConfirmation}
                                                  key="accountconfirmation"
                                              />,
                                              <Route
                                                  path="/register/accountConfirmation/*" //Adding astricks as part of new CAPS V3 implementation. Email link now appends unique customer emailID to subdomain. EX: https://localhost:8093/register/accountConfirmation/testnewlink@yopmail.com
                                                  component={AccountConfirmation}
                                                  key="accountconfirmation"
                                              />,
                                              <Route
                                                  path="/register/accountVerification"
                                                  component={AccountVerification}
                                                  key="accountVerification"
                                              />,
                                              <Route
                                                  path="/register/userAlreadyExists"
                                                  component={UserAlreadyExists}
                                                  key="useralreadyexists"
                                              />,
                                              <Route
                                                  path="/register/registrationDetails"
                                                  component={AccountDetails}
                                                  key="accountdetails"
                                              />,
                                              <Route
                                                  path="/profile/payment"
                                                  component={UserProfile}
                                                  key="userprofile"
                                              />,
                                              <Route component={PageNotFound} key="pg-not-found" status={404} />
                                          ]}
                                </Switch>
                            </div>
                            <Footer />
                        </>
                    )}
                </div>
            </ScrollToTop>
        </Router>
    );
};

export default App;
