import * as type from './CategoryNavActionTypes';
import queryString from 'query-string';


export const resetCategoryData = () => {
  const action = {
    type: type.RESET_CATEGORY_NAV_DATA
  }
  return action;
}

/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const loadCategoryNavDataAsync = () => {
  let parsed = queryString.parse(location.search);

  let vehicleMake = parsed.make;

  switch (vehicleMake) {
    case '45':
      vehicleMake = "Buick"
      break;
    case '46':
      vehicleMake = "Cadillac"
      break;
    case '47':
      vehicleMake = "Chevrolet"
      break;
    case '48':
      vehicleMake = "GMC"
      break;
  }

  /* categoryId always seems to come back null
  * I'm defaulting it to the Top Level Parent categoryId if null
  * -J-Sully
  * */
  if (parsed.categoryId === "") {
    //default to parent category
    parsed.categoryId = "12001"
  }

  const action = {
    type: type.LOAD_CATEGORY_NAV_DATA_ASYNC,
    payload: {
      year: parsed.year,
      make: vehicleMake,
      model: parsed.model,
      vin: parsed.vin,
      categoryId: parsed.categoryId
    }
  }
  return action;
}