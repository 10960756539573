export const VOV_CHANGE_COLOR = 'VOV_CHANGE_COLOR';
export const VOV_SET_INITIAL_COLOR = 'VOV_SET_INITIAL_COLOR';
export const SET_SPINNER_TRUE = 'SHOW_SPINNER_TRUE';
export const SET_SPINNER_FALSE = 'SET_SPINNER_FALSE';
export const VOV_IMAGE_DATA_INITIALIZATION = 'VOV_IMAGE_DATA_INITIALIZATION';
export const VOV_DATA_ASYNC = 'VOV_DATA_ASYNC';
export const VOV_DATA_WITH_PARTS = 'VOV_DATA_WITH_PARTS';
export const VOV_DATA_WITHOUT_PARTS = 'VOV_DATA_WITHOUT_PARTS';
export const CHANGE_IMAGE_TOGGLE = 'CHANGE_IMAGE_TOGGLE';
export const COLOR_CONFIG_DATA = 'COLOR_CONFIG_DATA';
export const ERROR_WHILE_TRYING_TO_RETRIEVE_DATA = 'ERROR_WHILE_TRYING_TO_RETRIEVE_DATA';
export const RESET_DATA_ON_CLOSE_MODEL = "RESET_DATA_ON_CLOSE_MODEL";
export const RETRIEVE_PARTS_RPO_FROM_CGI_ASYNC = 'RETRIEVE_PARTS_RPO_FROM_CGI_ASYNC';
export const RETRIEVE_PARTS_RPO_FROM_CGI = 'RETRIEVE_PARTS_RPO_FROM_CGI';
export const UPDATE_SEARCH_RESULT_WITH_VOV_DATA = 'UPDATE_SEARCH_RESULT_WITH_VOV_DATA';
export const UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA = 'UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA';
export const UPDATE_FULL_SEARCH_WITH_VOV_DATA = "UPDATE_FULL_SEARCH_WITH_VOV_DATA";
export const EMPTY_CATELOG_ENTRY_VIEW_ON_SEARCH_RESULT = 'EMPTY_CATELOG_ENTRY_VIEW_ON_SEARCH_RESULT';
/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_IMAGE_DATA_SERVICE_FAIL = 'LOAD_IMAGE_DATA_SERVICE_FAIL';
export const LOAD_VOV_IMAGE_DATA_WITH_PARTS_FAIL = 'LOAD_VOV_IMAGE_DATA_WITH_PARTS_FAIL';
export const LOAD_VOV_IMAGE_DATA_WITHOUT_PARTS_FAIL = 'LOAD_VOV_IMAGE_DATA_WITHOUT_PARTS_FAIL';
export const LOAD_COLOR_CONFIGURATION_FAIL = 'LOAD_COLOR_CONFIGURATION_FAIL';
export const LOAD_VOV_FAIL = 'LOAD_VOV_FAIL';
export const RESET_VOV_PARTSRPO_AND_COLORSCODE = 'RESET_VOV_PARTSRPO_AND_COLORSCODE';
