/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_HOME_CAROUSEL_DATA_ASYNC = 'LOAD_HOME_CAROUSEL_DATA_ASYNC';

/**************************************************************/
// Actions to set data (handle in HomeCarouselReducer switch statement) 
/**************************************************************/
export const SET_HOME_CAROUSEL_DATA = 'SET_HOME_CAROUSEL_DATA';

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const PLAY_VIDEO = 'PLAY_VIDEO';
export const STOP_VIDEO = 'STOP_VIDEO';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_HOME_CAROUSEL_DATA_ASYNC_FAIL = "LOAD_HOME_CAROUSEL_DATA_ASYNC_FAIL";
export const SET_HOME_CAROUSEL_DATA_FAIL = 'SET_HOME_CAROUSEL_DATA_FAIL';