import { formattedPhoneNumber } from "../../../shared/Internationalization/FormattedNumbers";
import { validateUSAZip } from '../../../shared/Validator/Validator';
import ZipCodeSearch from "./Buttons/ZipCodeSearch";
import SellerSearch from "./Buttons/SellerSearch";
import CitySearch from "./Buttons/CitySearch";
import React from "react";
/**
 * this file contains miscellanous functionality for the dealerization widget
 * to make the main files more readable.
 */

export const DEFAULT_ZIPCODE_STORAGE_ID = "MOST_RECENT_ZIPCODE_SEARCHED";
/**
 * Stores a zipcode in local storage and under the default lookup key 
 * @param {any} zip the zip code to store
 */
export const storeSearchZip = function (zip) {
    validateUSAZip(zip) && window.localStorage.setItem(DEFAULT_ZIPCODE_STORAGE_ID, zip)
};

/**
 * cleanResponseArr()
 * cleans up response data to be able to create labels
 * @param {Array} arr array of dealers.
 * @return {Array} array of dealers' information
 */
export const cleanResponseArr = (arr) => {
    return (
        Array.isArray(arr) ?
        arr.map((dealer, index) => {
            const addrInfo = dealer.addressInfo.localizations[0].NAAddress;
            return {
                phone: formattedPhoneNumber(dealer.contactInfo.phone),
                dealerName: dealer.name,
                rewardsProgram: dealer.rewardsProgram === "true" ? true : false,
                addr1: addrInfo.address1,
                city: addrInfo.city,
                state: addrInfo.state,
                country: addrInfo.countryName || addrInfo.country || "",
                zip: addrInfo.postalCode.substring(0, 5),
                distance: parseFloat(dealer.proximityInfo.distance).toFixed(1).toString(),
                vendorMake: dealer.vendorMake,
                id: index.toString() + "_" + dealer.bac,
                pos: {
                    lat: dealer.proximityInfo.latitude,
                    long: dealer.proximityInfo.longitude
                },
                nationalRetailPlan: dealer.nationalRetailPlan || "false",
                bac: dealer.bac,
                partInfo: dealer.partInfo || {}
            };
        }) : []
    );
};

/**
 * sets all errors to false for the dealerization modal
 * @param {*} dispatch dealerizer dispatch for the reducer
 * @param {*} saveError state that shows the save button's error if it were to occur
 */
export const resetErrors = (dispatch) => {
    dispatch({ type: "TOGGLE_ZIP_ERROR", showZipcodeError: false });
    dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: false });
    dispatch({ type: "TOGGLE_SAVE_ERROR", showSaveError: false });
};

/**
 * Grabs param substring from GWMP_Location cookie. Can add export if needed, but this function
 * is only reallt used for useDefaultZip
 *
 * @param {string} cookie location cookie from GWMP_Location
 * @return {*} returns an empty string or object with country_code, region_code and zip_code
 */

export const grabLocationParamFromCookie = (cookie) => {
    let parsedParam = "";
    if (cookie) {
        parsedParam = cookie
            .split(",")
            .map((param) => param.split("="))
            .reduce((locationParam, param) => {
                locationParam[decodeURIComponent(param[0].trim())] = decodeURIComponent(param[1].trim());
                return locationParam;
            }, {});
    }
    return parsedParam;
};

/**
 * parseZipCode()
 * cookie value for zipcode is a list of zip codes
 * @param {*} zip zipcode value pulled from cookie
 */
export const parseZipCode = (zip) => {
    let parsedZip = "";
    if (zip && typeof zip === "string") {
        parsedZip = zip.substring(0, 5);
    }
    return parsedZip;
};

/**
 * useDefaultZip()
 * use the GMWP_zipcode
 * @param {*} dispatch reducer updater
 * @param {*} cookie GMWP_cookie
 */
export const useDefaultZip = (dispatch, cookie) => {
    const currentSrcName = parseZipCode(grabLocationParamFromCookie(cookie).zip);
    dispatch({ type: "RESET_DEFAULT_ZIP" });
    dispatch({ type: "UPDATE_CURRENT_SRC", currentSourceName: currentSrcName });
    dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: currentSrcName });
    dispatch({ type: "UPDATE_USER_INPUT_ZIP", userInputZip: currentSrcName });
};

export const validateMake = (makes) => {
    if (makes) {
        if (Array.isArray(makes)) {
            makes.map((make) => {
                if (
                    make.toLowerCase() !== "chevrolet" ||
                    make.toLowerCase() !== "buick" ||
                    make.toLowerCase() !== "cadillac" ||
                    make.toLowerCase() !== "gmc"
                ) {
                    return false;
                }
            });
            // If all of them did match the brands, then return true after iterating through it.
            return true;
        }
    } else {
        if (makes === undefined) {
            //can save cookie if make is not there
            return true;
        }
    }

    return false;
};

export const validateBac = (bac) => {
    if (Array.isArray(bac) && bac.length === 1 && !isNaN(bac[0])) {
        return true;
    }
    return false;
};

export const isValidUSZip = (sZip) => {
    let x = sZip;
    if (typeof x === "string" && !isNaN(sZip)) {
        if (x.includes("-")) {
            x = sZip.replace("-", "");
        }
        if (x.length !== 5 && x.length !== 9) {
            return false;
        } else {
            return true;
        }
    }

    return false;
};

//Show the search based on current search type
export const switchSearch = (props) => {
    switch (props.state.searchType) {
        case "Zip":
            return <ZipCodeSearch dispatch={props.dispatch} state={props.state} setDealerList={props.setDealerList} />;
        case "City":
            return <CitySearch dispatch={props.dispatch} state={props.state} setDealerList={props.setDealerList} />;
        case "Seller":
            return <SellerSearch dispatch={props.dispatch} state={props.state} setDealerList={props.setDealerList} />;
    }
};