import AppSettings from "../../../core/AppSettings";
import axios from "axios";

/**
 * Make SearchBy/YMM call to fetch search data, returns it with no redux.
 * @param year
 * @param make
 * @param makeId
 * @param model
 * @param modelId
 * @param categoryId
 * @param pageNumber
 * @param orderBy
 * @param bodyId
 * @param wheelId
 * @param trimId
 * @param driveId
 * @param engineId
 * @param bodyNumDoorsId
 * @param searchTerm
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSearchResultsByYMMNoRedux = (
    year,
    make,
    makeId,
    model,
    modelId,
    categoryId,
    pageNumber,
    orderBy,
    bodyId,
    wheelId,
    trimId,
    driveId,
    engineId,
    bodyNumDoorsId,
    searchTerm
) => {
    //Don't want to pass in 'undefined' for optional parameters
    let order = orderBy ? orderBy : "";
    let catId = categoryId ? categoryId : "";
    let pageNum = pageNumber ? pageNumber : "";

    //Old Url
    //let url = `/wcs/resources/store/${AppSettings.storeId}/Search/ByYMM/${year}/${make}/${model}?noCache=true&langId=-1&catalogId=10052&currency=USD&responseFormat=JSON&searchType=100&pageNumber=${pageNum}&pageSize=24&categoryId=${catId}&orderBy=${order}`;
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/product?year=${year}&make=${make}&model=${model}&noCache=true&langId=-1&catalogId=${AppSettings.catalogId}&currency=USD&responseFormat=JSON&searchType=100&pageNumber=${pageNum}&pageSize=24&categoryId=${catId}&orderBy=${order}`;

    modelId ? (url += "&modelId=" + modelId) : "";
    bodyId ? (url += "&bodyType=" + bodyId) : "";
    wheelId ? (url += "&wheelBase=" + wheelId) : "";
    trimId ? (url += "&trim=" + trimId) : "";
    driveId ? (url += "&driveType=" + driveId) : "";
    engineId ? (url += "&engineBase=" + engineId) : "";
    bodyNumDoorsId ? (url += "&bodyNumDoors=" + bodyNumDoorsId) : "";
    searchTerm ? (url += "&searchTerm=" + searchTerm) : "";

    return axios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (response.status !== 200) {
                    throw error;
                }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

/**
 * Make Search with no fitment info to fetch search data.
 * @param categoryId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSearchResultsWithNoFitment = (categoryId) => {
    let catId = categoryId ? categoryId : "";
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/product?categoryId=${catId}`;

    return axios
        .get(url)
        .then((response) => {
            if (response.data) {
                if (response.status !== 200) {
                    throw error;
                }
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

/**
 * Simple get search results by vin and returns response. No redux.
 * @param vehicleVin
 * @param orderBy
 * @param categoryId
 * @param pageNumber
 * @param searchTerm
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSearchResultsByVINNoRedux = (vehicleVin, orderBy, categoryId, pageNumber, searchTerm) => {
    //Don't want to pass in 'undefined' for optional parameters
    let order = orderBy ? orderBy : "";
    let catId = categoryId ? categoryId : "";
    let pageNum = pageNumber ? pageNumber : "1";
    let url =
        "/wcs/resources/store/" +
        AppSettings.storeId +
        "/Search/ByVIN/" +
        vehicleVin +
        `?&langId=-1&catalogId=${AppSettings.catalogId}&currency=USD&responseFormat=JSON&searchType=100&pageNumber=` +
        pageNum +
        "&pageSize=24&categoryId=" +
        catId +
        "&orderBy=" +
        order;
    searchTerm ? (url += "&searchTerm=" + searchTerm) : "";

    return axios.get(url).then((response) => {
        if (response.data) {
            if (response.status !== 200) {
                throw error;
            }
        }
        return response.data;
    });
};

/**
 * Make call to fetch the category data for both the left-hand nav and the right-hand display
 * @param categoryId
 * @return {Promise<AxiosResponse<any>>}
 */
export const getCategoryResponse = (categoryId) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/Search/category/${categoryId}?noCache=true&langId=-1&brand=${AppSettings.brand}`;

    return axios.get(url).then(
        (response) => {
            return response.data;
        },
        (error) => {
            throw error;
        }
    );
};
