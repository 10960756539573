import React from 'react';
import i18next from 'i18next'
import { Translation } from 'react-i18next';




class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            };
    }


    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //currently we are not using any logger service
    }

    render() {
        const message = this.props.message || "LABEL_ERROR_BOUNDARY_GENERIC_ERROR";
       return this.state.hasError ?
           <Translation>
               {(t) => (<h4>{t(message)}</h4>)}
           </Translation>
           : this.props.children;
    }
}

export default ErrorBoundary
