import * as type from './ProductDetailsActionType';

/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const loadProductDetailsAsync = () => {
    const action = {
        type: type.LOAD_PRODUCT_DETAILS_ASYNC
    }
    return action;
}

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const setProductDetails = (item, crumbs) => {
    const action = {
        type: type.SET_PRODUCT_DETAILS,
        data: item,
        crumbData: crumbs
    }
    return action;
}

export const setProductIdentifier = (identifier) => {
    const action = {
        type: type.SET_PRODUCT_IDENTIFIER,
        identifier: identifier
    }
    return action;
}

export const setPDItemPrice = (price) => {
    const action = {
        type: type.SET_PRODUCT_DETAILS_ITEM_PRICE,
        price: price
    }
    return action
}

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const loadProductDetailsAsyncFail = (error) => {
    const action = {
        type: type.LOAD_PRODUCT_DETAILS_ASYNC_FAIL,
        error
    }
    return action;
}

export const setProductDetailsFail = (error) => {
    const action = {
        type: type.SET_PRODUCT_DETAILS_FAIL,
        error
    }
    return action;
}

/**************************************************************/
// User actions (handle in Reducer switch statement) 
/**************************************************************/
export const addToCartAsync = () => {
    const action = {
        type: type.ADD_TO_CART_ASYNC,
        data: 'item'
    }
    return action;
}

export const goToCart = (goToCart) => {
    const action = {
        type: type.PRODUCT_DETAILS_GO_TO_CART,
        goToCart: goToCart
    }
    return action;
}

export const showAddToCartError = (showError) => {
    const action = {
        type: type.SHOW_ADD_TO_CART_ERROR,
        showError: showError
    }
    return action;
}

/**************************************************************/
// Action to reset page to prevent old data from flashing
/**************************************************************/
export const resetPage = () => {
    const action = {
        type: type.PRODUCT_DETAILS_RESET_PAGE
    }
    return action;
}