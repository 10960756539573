import axios from "axios";
import AppSettings from "../../../core/AppSettings";
import { setUserInformation } from "../../../shared/Authentication/AuthenticationActionCreator";
import { getUserRegistrationStatus } from "../../../shared/Authentication/AuthenticationService";
import { authenticate } from "../../../shared/Authenticator/Authenticator";
import store from "../../../core/Redux/Store";
import vaultConstants from "../../../../config/vault_constants";

/**
 * Get list of 10 dealers within x distance radius based off of user zipcode.
 * This will also return prices if the part number is passed as a param.
 * @param {*} param
 */
const TIMEOUT_TIME = 15000;
const restApiContractsFeatureFlag = AppSettings.isLocalHost
    ? true
    : String(vaultConstants.FF_2243221_REST_API_CONTRACTS) === "true";

export const getDealersByZipcode = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byPostalCode/${param.location}?partNumber=${param.partNumber}`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byPostalCode/${param.location}`;

    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    ); 
};

export const getDealersByCity = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCityState/${param.location}?partNumber=${param.partNumber}`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCityState/${param.location}`;

    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    );
};

export const getDealersByName = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byDealerName/${param.location}?partNumber=${param.partNumber}`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byDealerName/${param.location}`;

    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    );
};

export const getClosestDealerByZipcode = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byPostalCode/${param.location}?partNumber=${param.partNumber}&maxCount=1`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byPostalCode/${param.location}?maxCount=1`;

    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    ); 
};

export const getClosestDealerByCoords = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCoordinates/${param.lat}/${param.long}?partNumber=${param.partNumber}&maxCount=1`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCoordinates/${param.lat}/${param.long}?maxCount=1`;
    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    );
};

export const getDealersByCoords = (param) => {
    const dealerLocateUrl = param.partNumber
        ? `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCoordinates/${param.lat}/${param.long}?partNumber=${param.partNumber}`
        : `/wcs/resources/${AppSettings.storeId}/dealerLocate/byCoordinates/${param.lat}/${param.long}`;
    return axios.get(dealerLocateUrl, { timeout: TIMEOUT_TIME }).then(
        (response) => response,
        (error) => {
            throw error;
        }
    );
};

/**
 *  converts latitude and longitude coordinates to zipcode using google reverse geocoding api
 *  returns zip code information as an object
 * @param {*} lat
 * @param {*} long
 */
export const getZipCodeFromCoordinates = (lat, long) => {
    return axios.get(`/dig/${lat},${long}`, { timeout: TIMEOUT_TIME }).then(
        (response) => response.data,
        (error) => {
            throw error;
        }
    );
};

/**
 * Gets dealer's data based off of BAC.
 * If the site is AC Delco, you must also have the make in the param
 * @param {*} param
 * param = {bac: ["XXXXX"], make: ["YYYYY"]}
 * As of 5/18/22 back-end is also providing a version=v2 that gives us a response similar to the other 'dealer by' calls - 
 * if we have time we can take in and parse that response to make it more aligned with other parsing. Until then 
 * v1 will provide the same structure of response we have always used.
 */

export const getDealerInfoByBac = (param) => {
    const url = `/wcs/resources/${AppSettings.storeId}/dealerLocate/byBac/${param.bac[0]}?make=${param.make[0]}&version=v1`;

    return axios
        .get(url, { timeout: TIMEOUT_TIME })
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

// creates GM contract to dealerize a user.
export const gmContractCall = (bac) => {
    if (restApiContractsFeatureFlag) {
        const postUrl = `/wcs/resources/store/${AppSettings.storeId}/GMDealerSetting/setup_contract?catalogId=${AppSettings.catalogId}&langId=${AppSettings.langId}&bac=${bac}&responseFormat=json`;
        return axios
            .post(postUrl, {}, { timeout: TIMEOUT_TIME })
            .then((response) => response)
            .catch((error) => {
                throw error;
            });
    } else {
        const postUrl = `${AppSettings.oldUIUrl}/GMContractSetup?catalogId=${AppSettings.catalogId}&storeId=${AppSettings.storeId}&langId=${AppSettings.langId}&bac=${bac}`;
        return axios
            .get(postUrl, { timeout: TIMEOUT_TIME })
            .then((response) => response)
            .catch((error) => {
                throw error;
            });
    }
};

/**
 * prior to dealerizing, need to validate if user is guest user instead of generic(-1002) user
 * @param {*} bac - bac of selected dealer
 * @returns contract call
 */

export const updateSelectedDealer = (bac) => {
    return getUserRegistrationStatus()
        .then((res) => {
            if (res.data.userId === "-1002") {
                return authenticate().then(
                    (resp) => {
                        if (resp) {
                            return getUserRegistrationStatus().then((response) => {
                                if (response.data.userId !== "-1002") {
                                    store.dispatch(setUserInformation(response.data));
                                    return gmContractCall(bac);
                                }
                            });
                        }
                    },
                    (error) => {
                        //identity call didn't work, try again
                        return authenticate().then((resp) => {
                            if (resp) {
                                return getUserRegistrationStatus()
                                    .then((response) => {
                                        if (response.data.userId !== "-1002") {
                                            return gmContractCall(bac);
                                        }
                                    })
                                    .catch((error) => {
                                        return gmContractCall(bac);
                                    });
                            }
                        });
                    }
                );
            } else {
                return gmContractCall(bac);
            }
        })
        .catch((error) => {
            return gmContractCall(bac);
        });
};

/**
 * A single, minimal object to represent a dealerization call in the queue
 * 
 * @typedef Task
 * @type {object}
 * @property {function} exec a function called to run the task 
 * @property {boolean} readOnly true if the task is read-only
 * @param {boolean} isDelete true if the task is a delete
 *  
 */
class Task {
    /**
     * Constructor for the Task class
     * 
     * @param {function} exec the resolve function to signal execution
     * @param {boolean} readOnly true if the task is read-only
     * @param {boolean} isDelete true if the task is a delete
     * @returns {Task}
     */
    constructor(exec, readOnly, isDelete) {
        if (typeof exec != "function") {
            throw new Error("Task(): exec must be a function");
        }
        this.exec = exec;
        this.readOnly = typeof readOnly == "boolean" ? readOnly : true;
        this.isDelete = typeof isDelete == "boolean" ? isDelete : false;
    }
}

/**
 * State of the dealerization cookie manager.
 * Never consumed by React/Redux.
 * 
 * When a SET operation is issued, that SET operation
 * should block all other operations to prevent race
 * conditions from causing issues.
 * 
 * @typedef DealerizationServiceState
 * @type {object}
 * @property {Task[]} queue the queue of waiting tasks when in synchronous mode
 * @property {boolean} syncLock indicates if synchronous call mode is enabled
 * @property {function} onDone the static callback for any completing Task
 * @property {function} onStart the static function to begin accepting calls from the queue
 * @property {function} onSetDone the static function for when a SET operation completes
 * to dispatch the next call or release the hold
 */

/**
 * @type {DealerizationServiceState}
 */
const ServiceState = {
    queue: [],
    syncLock: false,
    
    /**
     * When sync-lock is first enabled, run this method to
     * begin execution of the first Task, which should 
     * already be in the queue.
     * @this ServiceState
     */
    onStart: function() {
        this.syncLock = true;
        this.onDone();
    },

    /**
     * Drops all scheduled DELETEs as a SET just completed
     * @this ServiceState
     */
    onSetDone: function() {
        const deletes = [];
        const nonDeletes = [];

        for (const task of this.queue) {
            if (task.isDelete) {
                deletes.push(task);
            } else {
                nonDeletes.push(task);
            }
        }
        
        // abort all delete calls and set queue to hold refs to rest of calls
        if (deletes.length) {
            deletes.forEach(task => task.exec(true));
            this.queue = nonDeletes;
        }

        this.onDone();
    },

    /**
     * When sync-lock is enabled, run this onDone method
     * to dispatch the next queued request or clear the
     * syncLock state.
     * @this ServiceState
     */
    onDone: function() {
        if (this.queue.length) {
            const next = this.queue.shift();
            next.exec();
        } else {
            this.syncLock = false;
        }
    }
};

// three helpers to make scheduling easier to read below.
const scheduleSetTask = () => new Promise((resolve) => ServiceState.queue.push(new Task(resolve, false)));
const scheduleDeleteTask = () => new Promise((resolve) => ServiceState.queue.push(new Task(resolve, false, true)));
const scheduleReadOnlyTask = () => new Promise((resolve) => ServiceState.queue.push(new Task(resolve, true)));

/**
 * 
 * @typedef AxiosAdjacentResponse
 * @type {object}
 * @property {0} status a status number (0)
 * @property {{}} data an empty data object
 * @property {true} skipped a flag to indicate that this call was skipped
 * 
 * @returns {Promise<AxiosAdjacentResponse>} a promise that will resolve immediately with a response similar to that of axios.
 */
const DroppedCallPromise = () => new Promise(
    (resolve) => resolve({ status: 0, data: {}, skipped: true })
);

/**
 * @typedef AxiosOrAxiosAdjacentResponse
 * @type { import("axios").AxiosResponse | AxiosAdjacentResponse }
 * @desc An axios response or a similar object
 */

/**
 * Sets a dealer cookie.
 * 
 * IMPORTANT: A SET operation will invalidate/overwrite all outstanding DELETE operations.
 * If a scheduled delete() were to dispatch before set() returns, then it may end up 
 * deleting the wrong cookie. 
 * 
 * @param {string} bac the bac to set
 * @param {string} make the make to set
 * @param {any} location the location argument
 * @returns {Promise<AxiosOrAxiosAdjacentResponse>} the server's response
 */
export const setDealerCookie = async (bac, make, location) => {
    const setCookieUrl = `/node/setDealerCookie?location=${location}&bac=${[bac]}&make=${[make]}`;
    const resolvesWhenReady = scheduleSetTask();
    
    if (!ServiceState.syncLock) {
        ServiceState.onStart();
    }

    await resolvesWhenReady;
    return axios
        .get(setCookieUrl)
        .then((response) => response)
        .catch((error) => {
            throw error;
        })
        .finally(() => ServiceState.onSetDone());
};

export const validateDealerCookie = async () => {
    const validateCookieUrl = `/node/validateDealerCookie?store=${AppSettings.storeId}&generic=false`;
    
    if (ServiceState.syncLock) {
        await scheduleReadOnlyTask();
    }

    return axios.get(validateCookieUrl).then((response) => response).finally(() => ServiceState.onDone());
};

export const deleteDealerCookie = async () => {
    const deleteCookieUrl = `/node/deleteDealerCookie`;
    const resolvesWhenReady = scheduleDeleteTask();

    if (!ServiceState.syncLock) {
        ServiceState.onStart();
    }

    const abort = await resolvesWhenReady;
    if (abort) return DroppedCallPromise();

    return axios.get(deleteCookieUrl).then((response) => response).finally(() => ServiceState.onDone());
};

export const readDealerCookie = async () => {
    const readCookieUrl = `/node/readDealerCookie`;

    if (ServiceState.syncLock) {
        await scheduleReadOnlyTask();
    }

    return axios.get(readCookieUrl).then((response) => response).finally(() => ServiceState.onDone());
};
