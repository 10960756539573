import queryString from "query-string";

const storeId = "11201";

const sitesStoreMap = {
    11201: {
        key: "chevrolet",
        label: "Chevrolet"
    },
    11202: {
        key: "buick",
        label: "Buick"
    },
    11203: {
        key: "gmc",
        label: "GMC"
    },
    11204: {
        key: "cadillac",
        label: "Cadillac"
    },
    11205: {
        key: "dealer",
        label: ""
    }
};

const routePaths = {
    home: "/",
    search: "/search",
    product: "/product",
    cart: "/cart",
    checkout: "/checkout"
};

function isT3() {
    return sitesStoreMap[storeId].key === "dealer";
}
function getBasename() {
    let basename =
        document.getElementsByName("x-basename") && document.getElementsByName("x-basename")[0]
            ? document.getElementsByName("x-basename")[0].content
            : "";
    //handlebars vars are not resolved when not running node
    basename = basename === "{{basename}}" ? "" : basename;
    return basename;
}
function getCurrentSite() {
    return sitesStoreMap[storeId];
}

export function getPageName() {
    let pageName = "";
    let path = window.location.pathname;
    let pathCat = path.split("/");
    switch (pathCat[1]) {
        case "":
            pageName = "HomePage";
            break;
        case "search":
            pageName = "Search";
            break;
        case "product":
            pageName = "Product";
            break;
        case "cart":
            pageName = "Cart";
            break;
        case "checkoutmethod":
            pageName = "CheckoutMethod";
            break;
        case "checkout":
            pageName = "Checkout";
            break;
        case "orderconfirmation":
            pageName = "OrderReceipt";
            break;
        case "forgotUsernamePassword":
            pageName = "ForgotUsernamePassword";
            break;
        case "register":
            pageName = "Register";
            break;
        case "authenticate":
            pageName = "Authenticate";
            break;
        default:
            pageName = "HomePage";
    }
    return pageName;
}

const AppSettings = {
    storeId: storeId,
    routePaths: routePaths,
    thisMake: "Chevrolet",
    currency: "USD",
    sitesStoreMap: sitesStoreMap,
    isT3: isT3(),
    basename: getBasename(),
    currentSite: getCurrentSite(),
    isTesting: true,
    isLocalHost: true,
    urlParameters: () => queryString.parse(location.search)
};

export default AppSettings;
