import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SharedButton from "../../../shared/Button/SharedButton";
import { setFitmentCookie } from "../../../shared/VehicleSelect/CookieService/FitmentData";
import { useSelector } from "react-redux";
import accHistory from "../../App/History";
import store from "../../../core/Redux/Store";
import { setVehicleFitment } from "../FitmentCheck/FitmentCheckRedux/FitmentCheckActionCreator";
import queryString from "query-string";
import { checkIsFitmentUpdateNeeded, constructNewProductUrl } from "./HelperFunctions";
import { useLocation } from "react-router";

/**
 *
 * @param {*} props
 * @returns Final Fits/Doesn't Fit view of InlineFitment
 */
export default function InlineFitmentResults(props) {
    const { t } = useTranslation();
    const session = useSelector((state) => state.Session);
    const location = useLocation();

    useEffect(() => {
        // Once this component renders update fitmentCookie & url with updated fitment.Do nothing if fitment not changed
        if (checkIsFitmentUpdateNeeded(props.vehicleInfo, session)) {
            // Update cookie. which will update session reducer.
            setFitmentCookie(props.vehicleInfo);
        }
    }, []);

    useEffect(() => {
        // Update fitmentReducer
        store.dispatch(setVehicleFitment(queryString.parse(location.search)));
    }, [location]);

    const handleBreadcrumbs = () => {
        return (
            <>
                <b>{props.vehicleBreadcrumb[0]}, </b>
                {props.vehicleBreadcrumb.slice(1).join(", ")}
            </>
        );
    };

    const fitsSuccess = () => {
        return (
            <>
                <FontAwesomeIcon id="inline-fitment-results-icons" icon={faCheckCircle} />
                <span>{t("LABEL_COMMON_FITS")} </span>
            </>
        );
    };

    const fitsError = () => {
        return (
            <>
                <FontAwesomeIcon id="inline-fitment-results-icons" icon={faTimesCircle} />
                <span>{t("LABEL_COMMON_DOES_NOT_FIT")} </span>
            </>
        );
    };
    return (
        <>
            {props.fitmentError && (
                <div id="failedfit-errorbox" className="failed-fit">
                    {t("LABEL_ADD_TO_CART_FAILED_FITMENT_ERROR")}
                </div>
            )}
            <h6 className="gb-body3" id="inline-fitment-results">
                <span id={props.fits ? "inline-fitment-success" : "inline-fitment-fail"}>
                    {props.fits ? fitsSuccess() : fitsError()}
                </span>
                {handleBreadcrumbs()}
            </h6>
            {!props.fits && (
                <div>
                    <SharedButton
                        id="prt-inlinefitment-viewallproducts-btn"
                        className="gb-primary-button inline-fitment-results-button"
                        analyticsTag={"vehicle fit:does not fit"}
                        buttonText="View all products for this vehicle"
                        onClick={props.handlePSRRedirect}
                    />
                </div>
            )}
            <a
                className="reset-vehicle-fit stat-text-link"
                data-dtm={"vehicle fit:" + (props.fits ? "fits" : "does not fit")}
                id="inline-reset-vehicle-fit"
                onClick={() => props.onReset()}
            >
                {t("LABEL_RESET_VEHICLE_INLINE_FIT")}
            </a>
        </>
    );
}

InlineFitmentResults.propTypes = {
    vehicleBreadcrumb: PropTypes.array.isRequired,
    vehicleInfo: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired
};
