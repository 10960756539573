import i18next from 'i18next';
import React, { Component } from 'react';
import Favicon from 'react-favicon';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import AppSettings from '../../../core/AppSettings';
import store from '../../../core/Redux/Store';
import { isIEBrowser } from "../../../shared/Utils/Utils";
import accHistory from "../History";
import { loadHeaderDataAsync } from './HeaderRedux/HeaderActionCreator';
import MiniCart from './MiniCart/MiniCart';
import GuestFlyoutWidget from './UserWidget/GuestFlyout/GuestFlyoutWidget';
import RegisteredFlyoutWidget from './UserWidget/RegisteredFlyout/RegisteredFlyoutWidget';
import UserWidget from './UserWidget/UserWidget';
import DealerLocator from '../../DealerLocator/DealerLocator';

let prevScrollpos = window.pageYOffset;
const supportLink = process.env.REACT_APP_DAM_SOURCE + process.env.REACT_APP_FAQ;

class Header extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    store.dispatch(loadHeaderDataAsync());

    if(isIEBrowser()){
      const pathName = location.pathname;
      let searchParam = location.search;
      if(searchParam.indexOf('authCheck=true') > -1){
        searchParam = searchParam.replace('?authCheck=true',"");
        searchParam = searchParam.replace('&authCheck=true',"");
        accHistory.push(pathName + searchParam );
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

  }

  handleScroll() {
    // possible fix for safari mobile errors
    if (document.readyState === "complete") {
      let headerHeight = document.getElementById('navbar').offsetHeight;
      let currentScrollPos = window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar").style.top = "0";
      } else if (prevScrollpos > headerHeight) {
        document.getElementById("navbar").style.top = '-' + headerHeight + 'px';
      }
      prevScrollpos = currentScrollPos;
    }
  }

  // updates pageName in AppSettings when home button is clicked, updates displayCoupon in App.js
  handlePageChange() {
    AppSettings.pageName = "HomePage";
    this.props.setdisplayCoupon(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authenticationData.registrationStatus != this.props.authenticationData.registrationStatus) {
      store.dispatch(loadHeaderDataAsync());
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {  
    if (this.props.headerData) {

      const redirectPath = (window.location.pathname === '/register' || window.location.pathname == '/register/userAlreadyExists') ? '/' : window.location.href;
      const applicationState = { 'redirectPath': redirectPath };
      const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));
      const createAcctEmail = window.location.pathname == '/register/userAlreadyExists' ? this.props.createAccountData.email : null;
      const userFlow = AppSettings.azureLoginUserFlow;
      const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
      const currentSite = AppSettings.currentSite.key;


      // LOGO AND TITLE
      const title = <>
        <Link to={AppSettings.homeUrl} onClick={this.handlePageChange} className="ac-header-logo stat-logo" data-dtm="global nav">
          <img src={this.props.headerData.logo} alt={i18next.t('LABEL_ACCESSORIES_SITE_TITLE')} />
        </Link>
        
        {AppSettings.isT3 &&
          <Link to={AppSettings.homeUrl} onClick={this.handlePageChange} className="ac-header-title stat-logo" data-dtm="global nav">
            <span>{i18next.t('LABEL_ACCESSORIES_SITE_TITLE')}</span>
          </Link>
        }
      </>

      // DEALER INFO
      const dealerInfo = AppSettings.isT3 ?
        <span>
          {this.props.headerData.address} <br />
          {this.props.headerData.phone}
        </span>
        : null;

      // USER WIDGET
      const userWidget = <UserWidget
        authenticationData={this.props.authenticationData}
        rewardsData={this.props.rewardsData}
        isUserActive={!AppSettings.isT3 && this.props.authenticationData.registrationStatus == 'R' && this.props.authenticationData.registrationStatusFetched}
        isSignInActive={!AppSettings.isT3 && this.props.authenticationData.registrationStatus == 'G' && this.props.authenticationData.registrationStatusFetched}
        userFlow={userFlow}
        clientId={clientId}
        currentSite={currentSite} />

      const pathName = window.location.pathname;
      const CHECKOUT = pathName.includes("checkout");
      // Removes Support Link and User Widget at Checkout or t3
      const dealerDisplay = () => {
          if (CHECKOUT || AppSettings.isT3) {
              return "";
          } else if (screen.width > 768) {
              return (
                  <div
                      id="ac-header-support-dealer-section"
                      className="ac-header-section ac-header-top gb-label2 prt-buick-label2 prt-header-section-adjustments"
                  >
                      <a href={supportLink} target="_blank" className="stat-text-link" data-dtm="global nav:eyebrow">
                          {i18next.t("LABEL_SUPPORT_LINK")}
                      </a>
                      <div id="ac-header-dealer" className="ac-header-right-element">
                      <DealerLocator/>
                      </div>
                  </div>
              );
          } else {
              return (
                  <div className="ac-header-row ac-mobile-header-row1">
                      <div id="ac-header-dealer-info-section" className="ac-header-section ac-header-top gb-label2">
                          <a
                              href={supportLink}
                              target="_blank"
                              className="prt-mobile-support-link stat-text-link"
                              data-dtm="global nav:eyebrow"
                          >
                              {i18next.t("LABEL_SUPPORT_LINK")}
                          </a>
                          <DealerLocator/>
                      </div>
                  </div>
              );
          }
      };

      const checkPage = (this.props.miniCartData.loaded || this.props.miniCartData.error) ? dealerDisplay() : null;

      return (
        <div>
          <nav className="ac-header gm-ow-header" id="navbar">
            {AppSettings.isT3 && <Favicon url={this.props.headerData.logo} />}
            <section>
              {/* DESKTOP */}
              <MediaQuery query="(min-width: 769px)">
                <div className="ac-header-row">
                    <div id="ac-header-logo-section" className="ac-header-section ">
                        {title}
                    </div>
                    <div id="ac-header-dealer-info-section" className="ac-header-section">
                      {dealerInfo}
                    </div>
                    <div id="ac-header-nav-section" className="ac-header-justify-right ">
                        {checkPage}
                        <div
                            id="ac-header-user-cart-section"
                            className="ac-header-section ac-header-bottom prt-header-section-adjustments"
                        >
                            {userWidget}
                            <div id="ac-header-cart-section" className="ac-header-minicart-container">
                                {<MiniCart />}
                            </div>
                        </div>
                    </div>
                </div>
              </MediaQuery>

              {/* MOBILE */}
              <MediaQuery query="(max-width: 768px)">
                {checkPage}
                <div className="ac-header-row prt-mobile-header-row2">
                    <div id="ac-header-logo-section" className="ac-header-section">
                        {title}
                    </div>
                    <div className="prt-mobile-widget-and-minicart">
                        <div
                            id="ac-header-user-widget-section"
                            className="ac-header-section ac-header-justify-right"
                        >
                            {userWidget}
                        </div>
                        <div
                            id="ac-header-minicart-section"
                            className="ac-header-section ac-header-justify-right"
                        >
                            <MiniCart />
                        </div>
                    </div>
                </div>
                <div className="ac-header-row">                    
                  <div id="ac-header-dealer-info-section" className="ac-header-section">
                        {dealerInfo}
                  </div>
                </div>
              </MediaQuery>
            </section>
          </nav>

          {/* Know which flyout to activate depending on whether the user is signed in or not. */}
          {!AppSettings.isT3 && this.props.authenticationData.registrationStatus == 'G' && this.props.authenticationData.registrationStatusFetched &&
            <GuestFlyoutWidget
              userFlow={userFlow}
              clientId={clientId}
              applicationStateString={applicationStateString}
              createAcctEmail={createAcctEmail}
              currentSite={currentSite}
              eSpotFlyoutText={this.props.headerData.flyoutText} >
            </GuestFlyoutWidget >
          }
          {!AppSettings.isT3 && this.props.authenticationData.registrationStatus == 'R' && this.props.authenticationData.registrationStatusFetched &&
            <RegisteredFlyoutWidget
              eSpotFlyoutText={this.props.headerData.flyoutText}
              userFlow={userFlow}
              clientId={clientId} >
            </RegisteredFlyoutWidget>
          }
        </div>
      );
    }
  }
}


const mapStateToProps = state => {
  return {
    headerData: state.HeaderReducer,
    authenticationData: state.Authentication,
    rewardsData: state.RewardsReducer,
    createAccountData: state.AccountCreationReducer,
    miniCartData: state.MiniCartReducer
  }
}

export default connect(mapStateToProps)(Header)
