import { getVehicleCombinationsData } from "./VehicleConfigService";
import { setVehicleCombinations, setVehicleCombinationsFail } from './VehicleConfigActionCreator';
import { addBodyFitmentSelection, addDriveFitmentSelection, addEngineFitmentSelection, addTrimFitmentSelection, addWheelFitmentSelection } from './VehicleConfigUtils';
import { call, put, select } from "redux-saga/effects";

/**************************************************************/
// GET VALUES FROM REDUCERS FOR API CALLS
/**************************************************************/
export const getProductID = state => state.ProductDetailsReducer.uniqueID;
export const getFitmentCombinations = state => state.FitmentCheckReducer.vehicleCombinations;
export const getVehicleParams = state => state.FitmentCheckReducer.vehicleParams;
export const getYear = state => state.VehicleSelectReducer.selectedYear;
export const getMake = state => state.VehicleSelectReducer.selectedMake;
export const getModel = state => state.VehicleSelectReducer.selectedModel;
export const getBodyParam = state => state.FitmentCheckReducer.vehicleParams.bodyParam;
export const getWheelParam = state => state.FitmentCheckReducer.vehicleParams.wheelParam;
export const getTrimParam = state => state.FitmentCheckReducer.vehicleParams.trimParam;
export const getDriveParam = state => state.FitmentCheckReducer.vehicleParams.driveParam;
export const getEngineParam = state => state.FitmentCheckReducer.vehicleParams.engineParam;

/**************************************************************/
// VEHICLE COMBINATIONS CALL
// This data is loaded when the PD page loads
/**************************************************************/
export function* callForVehicleCombinations() {
    //get params for api call (see FitmentCheckService.js)
    const year = yield select(getYear);
    const make = yield select(getMake);
    const model = yield select(getModel);

    let combinations = {}
    let parsedCombinations = {}

    try {
        const vehicleCombinationsResponse = yield call(getVehicleCombinationsData, year, make, model);
        //get all combinations from call
        combinations = vehicleCombinationsResponse.records;
        //parse combinations for each fitment check (body, wheel, trim, drive, engine)
        parsedCombinations = parseVehicleCombinations(combinations);

        //return all combinations, and parsed combinations
         if(!combinations.length){
           yield put(setVehicleCombinationsFail(error));    
         }

        yield put(setVehicleCombinations(combinations, parsedCombinations));
    }

    catch (error) {
        // Dispatches SET_VEHICLE_CONFIG_COMBINATIONS_FAIL to handle the error.
        yield put(setVehicleCombinationsFail(error));
    }
}


/**************************************************************/
// PARSE JSON FROM VEHICLE COMBINATIONS CALL
// Flat json is parsed into bodyFitmentSelection, wheelFitmentSelection,
// trimFitmentSelection, driveFitmentSelection, and engineFitmentSelection.
// These values are used to determine dropdown selection options in
// the fitment modal which is then used for further filtering
/**************************************************************/
export function parseVehicleCombinations(combinations) {

    let bodyFitmentSelection = [];
    let wheelFitmentSelection = [];
    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    for (let indexRecord = 0; indexRecord < combinations.length; indexRecord++) {
        /********** CREATE BODY SELECTION ARRAY *****************/
        addBodyFitmentSelection(combinations[indexRecord], bodyFitmentSelection);

        /********** CREATE WHEEL SELECTION ARRAY *****************/
        addWheelFitmentSelection(combinations[indexRecord], wheelFitmentSelection);

        /********** CREATE TRIM SELECTION ARRAY *****************/
        addTrimFitmentSelection(combinations[indexRecord], trimFitmentSelection);

        /********** CREATE DRIVE SELECTION ARRAY *****************/
        addDriveFitmentSelection(combinations[indexRecord], driveFitmentSelection);

        /********** CREATE ENGINE SELECTION ARRAY *****************/
        addEngineFitmentSelection(combinations[indexRecord], engineFitmentSelection);
    }

    let fitmentSelections = {
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection,
    };

    return fitmentSelections;

};