import i18next from 'i18next';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Field, reduxForm } from 'redux-form';
import * as validate from '../../Form/FormValidate/FormValidate';
import FieldErrorMessage from '../../FieldErrorMessage/FieldErrorMessage';

// redux form props: https://redux-form.com/6.0.0-alpha.4/docs/api/field.md/
const qtyInputField = props => ({ input, type, meta: { touched, error } }) => (
    <div>
        <input {...input}
            className="ac-qty-input"
            type={type}
            placeholder={input.value ? input.value : props.currentQty}
            value={input.value}
            autoComplete="off"
            min="0" />
        {touched && ((error && <FieldErrorMessage message={error} />))}
        <button className={((props.currentQty != input.value && input.value != '') ? "q-button-small q-button q-primary-button stat-button-link ac-update ac-hide-on-print" : "ac-update-hidden")}
            type="submit"
            disabled={error || props.pristine || props.submitting}>
            {i18next.t("LABEL_CHECKOUT_UPDATE")}
        </button>
    </div>
)

const CartQuantityForm = props => {
    const { handleSubmit, pristine, reset, submitting, inputType, currentQty } = props;

    if (!inputType || inputType === "select")
        return (
            <form onSubmit={handleSubmit} autoComplete="new-password">
                <Field
                    name="quantity"
                    component="select"
                    className="dropdown q-dropdown stat-dropdown"
                    id="cart-quantity-dropdown"
                    data-dtm={props.dtmPartName ? `shopping cart:${props.dtmPartName}:quantity` : undefined}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10+">10+</option>
                </Field>
            </form>
        );
    else if (inputType === "text")
        return (
            <form onSubmit={handleSubmit}>
                {/* TODO: limit the max length user can type into field (before updating) and prevent 
                negative numbers from being typed. Type=number makes this hard 
                to do, but i'm using this to prevent special characters (i.e. !@#$%) from 
                being typed into the input box before validation, HOWEVER, this doesn't 
                prevent the user from entering the negative symbol (-). This is a browser 
                issue, but there are work arounds, it's just a lot of lines of code.
                Temp solution is to allow this bad input and display an error message (see FieldErrorMessage). 
                Note that each browser treats this input field a little different, but generally behaves as 
                described above.
                Redux form type=number example: https://redux-form.com/6.4.3/examples/fieldlevelvalidation/*/}
                <Field
                    name="quantity"
                    component={qtyInputField(props)}
                    type="number"
                    autoFocus
                    validate={[validate.qtyInputLength, validate.qtyInputMinValue0]}
                />
            </form>
        );
}

export default reduxForm({})(CartQuantityForm)