import {
    getSearchResultsByYMMNoRedux,
    getSearchResultsByVINNoRedux,
    getSearchResultsWithNoFitment,
    getCategoryResponse
} from "./CategoryLandingPageService";
import AppSettings from "../../../core/AppSettings";
import { isValidResponse } from "../../../shared/Utils/Utils";
import vaultConstants from "../../../../config/vault_constants";
const NO_RESULTS_FOUND = "No results found";

/**
 * Gets search data like Product Search results page, sets values in component using set* functions
 * @param {object} fitmentInfo
 * @param {function} setIsLoading
 * @param {function} setNoResults
 * @param {function} setPageError
 * @param {function} setSearchData
 * @param {function} setCategoryInfo
 * @param {function} setSelectedCategoryInfo
 * @param {function} setSubcategoryImages
 * @param {function} setFitmentVin
 * @param {TFunction<"translation">} t
 * @param  {string} categoryId
 * @return {Promise<void>}
 */
export const getCategoryData = async (
    fitmentInfo,
    setIsLoading,
    setNoResults,
    setPageError,
    setSearchData,
    setCategoryInfo,
    setSelectedCategoryInfo,
    setSubcategoryImages,
    setFitmentVin,
    t,
    categoryId
) => {
    // Using default values for getSearchResults calls
    const DEFAULT_CATEGORY = "";
    const DEFAULT_PAGE_NUMBER = "";
    const DEFAULT_SORT_BY_ID = "";
    const DEFAULT_SEARCH_TERM = "";

    let searchResultsInitialData = {};
    try {
        setIsLoading(true);
        if (fitmentInfo.vin) {
            searchResultsInitialData = await getSearchResultsByVINNoRedux(
                fitmentInfo.vin,
                DEFAULT_SORT_BY_ID,
                DEFAULT_CATEGORY,
                DEFAULT_PAGE_NUMBER,
                ""
            );
            let fitmentVin = {
                year: searchResultsInitialData?.vehicleInfo[0].year,
                make: searchResultsInitialData?.vehicleInfo[0]?.make,
                model: searchResultsInitialData?.vehicleInfo[0]?.model,
                vin: searchResultsInitialData?.vehicleInfo[0]?.vin,
                bodyStyle: searchResultsInitialData?.vehicleInfo[0]?.bodyStyle,
                bodyAndWheel: searchResultsInitialData?.vehicleInfo[0]?.bodyAndWheel,
                wheelBase: searchResultsInitialData?.vehicleInfo[0]?.wheelBase,
                trim: searchResultsInitialData?.vehicleInfo[0]?.trim,
                driveType: searchResultsInitialData?.vehicleInfo[0]?.driveType
            };
            setFitmentVin(fitmentVin);
        } else if (fitmentInfo.year) {
            searchResultsInitialData = await getSearchResultsByYMMNoRedux(
                fitmentInfo.year,
                fitmentInfo.make,
                fitmentInfo.makeId,
                fitmentInfo.model,
                fitmentInfo.modelId,
                DEFAULT_CATEGORY,
                DEFAULT_PAGE_NUMBER,
                DEFAULT_SORT_BY_ID,
                fitmentInfo.bodyId,
                fitmentInfo.wheelId,
                fitmentInfo.trimId,
                fitmentInfo.driveId,
                fitmentInfo.engineId,
                fitmentInfo.bodyNumDoorsId,
                DEFAULT_SEARCH_TERM
            );
            setFitmentVin(null);
        }
        //No fitment info
        else {
            searchResultsInitialData = await getSearchResultsWithNoFitment(
                fitmentInfo.year,
                fitmentInfo.make,
                fitmentInfo.makeId,
                fitmentInfo.model,
                fitmentInfo.modelId,
                DEFAULT_CATEGORY,
                DEFAULT_PAGE_NUMBER,
                DEFAULT_SORT_BY_ID,
                fitmentInfo.bodyId,
                fitmentInfo.wheelId,
                fitmentInfo.trimId,
                fitmentInfo.driveId,
                fitmentInfo.engineId,
                fitmentInfo.bodyNumDoorsId,
                DEFAULT_SEARCH_TERM
            );
            setFitmentVin(null);
        }
        // Set error true here if get no data from calls
        if (!searchResultsInitialData) {
            // TODO: Set error for call here
        } else {
            setSearchData(searchResultsInitialData);
            const { parsedCategories, selectedCategoryObject } = parseForCategoryInfo(
                searchResultsInitialData,
                categoryId,
                t
            );
            setCategoryInfo(parsedCategories);
            setSelectedCategoryInfo(selectedCategoryObject);

            // Fetch subcategory image urls for cards
            await getSubcategoryImageUrls(
                categoryId,
                true,
                setSubcategoryImages,
                setIsLoading,
                setNoResults,
                setPageError
            );
        }
    } catch (error) {
        setPageError("An error has occurred. Please try again later.");
        console.warn("ERROR: ", error);
    } finally {
        setIsLoading(false);
    }
};

/**
 * Used to make api call to /Search/{categoryId} to retrieve image urls for subcategories within selected category
 * @param categoryId - The uniqueID of the currently selected category
 * @param isInitialLoad - Boolean. Used to determine whether or not to set isLoading to false at end of function
 * @param setSubcategoryImages - Function. Used to set subcategoryImages state variable with parsed image url object
 * @param setIsLoading - Function. Used to set isLoading state variable
 * @param setNoResults - Function. Used to set noResults state variable
 * @param setPageError - Function. Used to set pageError state variable
 * @return {Promise<void>}
 */
export const getSubcategoryImageUrls = async (
    categoryId,
    isInitialLoad,
    setSubcategoryImages,
    setIsLoading,
    setNoResults,
    setPageError
) => {
    let searchResultsInitialData = {};
    try {
        setIsLoading(true);

        searchResultsInitialData = await getCategoryResponse(categoryId);
        if (isValidResponse(searchResultsInitialData)) {
            const subcategoryImages = parseSubcategoryImages(searchResultsInitialData);
            setSubcategoryImages(subcategoryImages);
        }
    } catch (error) {
        if (error === NO_RESULTS_FOUND) {
            setNoResults(true);
        } else {
            setPageError("An error has occurred. Please try again later.");
            console.warn("ERROR: ", error);
        }
    } finally {
        // This is set already in initial load after this function returns, don't need to do it here
        if (!isInitialLoad) {
            setIsLoading(false);
        }
    }
};

/**
 * Parses image urls for each subcategory within selected category. Returns map of subcategory id's to their respective
 * image url. This object will be used to add the image url to the subcategory object in parseForCategoryInfo
 * @param searchData
 * @return {{}}
 * {
 *     3074457345616876282: "image url in DAM",
 *     ...
 * }
 */
export const parseSubcategoryImages = (searchData) => {
    const subcategoryImages = {};
    if (searchData.catalogGroupView?.length > 0) {
        searchData.catalogGroupView.forEach((subcategoryObject) => {
            if (Object.keys(subcategoryObject["UserData"]).length > 0) {
                if (subcategoryObject["UserData"]["mainImage"] !== undefined) {
                    subcategoryImages[subcategoryObject.catgroup_id] = subcategoryObject["UserData"]["mainImage"];
                } else {
                    subcategoryImages[subcategoryObject.catgroup_id] = null;
                }
            }
        });
    } else {
        // If we don't have anything in the catalogGroupView object, then the categoryId is invalid or something is up
        // with the selected category. Throw this up the chain to handle above
        throw NO_RESULTS_FOUND;
    }
    return subcategoryImages;
};

/***
 * Use to parse category info from search call and set it to state value in CategoryLandingPage component
 * @param {object} searchData - All search data to parse info from
 * @param {string} categoryId - The uniqueID of the currently selected category
 * @param {function} t - Translation function
 * @return {{parsedCategories: *[], selectedCategoryObject: {}}}
 * Returns an object containing:
 * -An array of category objects that each contain their own info and subcategories. Includes All Categories
 *     [
 *          {
 *              "count": "10",
 *              "mainCategory": "Alternators & Starters",
 *              "position": "15",
 *              "uniqueID": "3074457345616910176",
 *              "subcategories": [
 *                  {
 *                      "count": "7",
 *                      "label": "Alternators & Starter Components",
 *                      "parentCategoryID":  "3074457345616910176",
 *                      "uniqueID": "3074457345616910199"
 *                  }
 *                  ...
 *              ]
 *          }
 *          ...
 *     ]
 * -An object that is an entry from the array above that holds information for the currently selected category
 */
const parseForCategoryInfo = (searchData, categoryId, t) => {
    let entryData = {};
    let ALL_PRODUCTS_UNIQUE_ID = "";
    let currentIndex = -1;
    // This will hold an array for each parentUniqueId of objects for each of its subcategories
    let parentSubcategories = {};
    // This will hold an object for each parent category containing it's info and subcategories (filled in at end)
    let parentObjects = {};

    const mainCategories = [];
    let selectedCategoryInfo = {};
    const clpEnhancementsFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2207674_CLP_ENHANCEMENTS;

    if (searchData && searchData.facetView && searchData.facetView.length) {
        // Getting the unique id of the all-categories category
        searchData.facetView.map((item) => {
            if (item.name === "ParentCatalogGroup") {
                entryData = item.entry;
                ALL_PRODUCTS_UNIQUE_ID = item.entry[0].value;
            }
        });
    }

    // Loop through entries to get categories and subcategories
    entryData.map((entryItem) => {
        let entryUniqueId = "";
        let currentMainCategory = "";
        // Check for categories that have all_categories unique_id as parent, this means they are parent category
        if (entryItem.extendedData.parentIds === ALL_PRODUCTS_UNIQUE_ID || !entryItem.extendedData.parentIds) {
            entryUniqueId = entryItem.extendedData.uniqueId;
            currentMainCategory = entryItem.label;
            ++currentIndex;

            // Keep track of parent info in parentObjects
            if (!parentObjects[entryUniqueId]) {
                parentObjects[entryUniqueId] = {
                    position: currentIndex.toString(),
                    uniqueID: entryUniqueId.toString(),
                    mainCategory: currentMainCategory,
                    count: entryItem.count,
                    subCategories: []
                };
            }
        } else {
            if (entryItem.extendedData.parentIds && entryItem.extendedData.parentIds.split("_").length > 1) {
                // For subcategories, parentIds is in this format: XX_XX, where first number is all_categories id
                // and second is the parent's (a main category) id
                const subParentIds = entryItem.extendedData.parentIds.split("_");
                // Handle if bug appears where unique id's are swapped in subParentIds
                const subParentId = subParentIds[0] === ALL_PRODUCTS_UNIQUE_ID ? subParentIds[1] : subParentIds[0];
                // If array for subcategory parent hasn't been made, make it before pushing to it
                if (!parentSubcategories[subParentId]) {
                    parentSubcategories[subParentId] = [];
                }

                // Add this subcategory to its respective parent's array in parentSubcategories
                parentSubcategories[subParentId].push({
                    uniqueID: entryItem.value,
                    label: entryItem.label,
                    count: entryItem.count,
                    parentCategoryID: subParentId
                });
            }
        }
    });

    // Sort arrays of subcategories for each parent category and set its subCategories array
    for (const parentUniqueId in parentObjects) {
        if (parentUniqueId !== ALL_PRODUCTS_UNIQUE_ID) {
            if (parentSubcategories.hasOwnProperty(parentUniqueId)) {
                parentSubcategories[parentUniqueId].sort((a, b) => a.label.localeCompare(b.label));
                parentObjects[parentUniqueId].subCategories = parentSubcategories[parentUniqueId];
            } else {
                // Skip categories with no subcategories. This should only happen if something is wrong with the data
                continue;
            }
        }
        if (parentUniqueId === categoryId) {
            selectedCategoryInfo = parentObjects[parentUniqueId];
        }
        // Push everything that isn't All Products parent
        if (parentUniqueId !== ALL_PRODUCTS_UNIQUE_ID) {
            mainCategories.push(parentObjects[parentUniqueId]);
        }
    }

    // Sort main categories by label
    mainCategories.sort((a, b) => a.mainCategory.localeCompare(b.mainCategory));

    if (clpEnhancementsFlag) {
        // Unshift All Products onto front of array and rename
        parentObjects[ALL_PRODUCTS_UNIQUE_ID].mainCategory = "All Products";
        mainCategories.unshift(parentObjects[ALL_PRODUCTS_UNIQUE_ID]);
    }

    return { parsedCategories: mainCategories, selectedCategoryObject: selectedCategoryInfo };
};

/***
 *  Use this to create the URL for the Product Search Results page filtered by the category that is passed in.
 *  Navigate to the URL that is returned from this
 * @param fitmentInfo {object} : An object containing all of the necessary fitment info from the URL, or the vin
 * number.
 * @param selectedCategoryId {String} : The uniqueId of the category that is selected/we're navigating to
 * @return {string}
 */
export const buildSearchResultsURL = (fitmentInfo, selectedCategoryId) => {
    let url = "/search";

    // Only need vin if vin search
    if (fitmentInfo.vin) {
        url += `?vin=${fitmentInfo.vin}`;
    } else {
        const {
            body,
            bodyId,
            bodyNumDoorsId,
            drive,
            driveId,
            engine,
            engineId,
            make,
            makeId,
            model,
            modelId,
            trim,
            trimId,
            wheel,
            wheelId,
            year
        } = fitmentInfo;

        body ? (url += `?body=${body}`) : "";
        bodyId ? (url += `&bodyId=${bodyId}`) : "";
        bodyNumDoorsId ? (url += `&bodyNumDoors=${bodyNumDoorsId}`) : "";
        drive ? (url += `&drive=${drive}`) : "";
        driveId ? (url += `&driveId=${driveId}`) : "";
        engine ? (url += `&engine=${engine}`) : "";
        engineId ? (url += `&engineId=${engineId}`) : "";
        make ? (url += `&make=${make}`) : "";
        if (body) {
            makeId ? (url += `&makeId=${makeId}`) : "";
        } else {
            makeId ? (url += `?makeId=${makeId}`) : "";
        }
        model ? (url += `&model=${model}`) : "";
        modelId ? (url += `&modelId=${modelId}`) : "";
        trim ? (url += `&trim=${trim}`) : "";
        trimId ? (url += `&trimId=${trimId}`) : "";
        wheel ? (url += `&wheel=${wheel}`) : "";
        wheelId ? (url += `&wheelId=${wheelId}`) : "";
        year ? (url += `&year=${year}`) : "";
    }
    url += `&categoryId=${selectedCategoryId}`;

    if (AppSettings.isT3) {
        url = `${AppSettings.dealerName}${url}&bac=${AppSettings.bac}`;
    }

    return url;
};
