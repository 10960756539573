/*** COMPONENT NAME : DEALER MAP (LOADING A GOOGLE MAP IN DEALER LOCATOR USING REACT-GOOGLE-MAPS LIBRARY) ***/

/** IMPORT STATEMENT **/
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import MarkerComponent from '../MarkerComponent/MarkerComponent';
import PropTypes from 'prop-types';



const DealerMap = withScriptjs(withGoogleMap((props) =>
    <div> <GoogleMap
        zoom={props.zoom}
        center={props.coordination}>

       {
           /*** LOOPING THROUGH THE DEALER DATA TO SHOW MARKER INSIDE THE GOOGLE MAP **/
            props.isMarkerShown && props.dealerData.map((item,index)=>{
                return <MarkerComponent data={item} count={index+1} key={index}/>
            })
        }
    </GoogleMap>
    </div>
));

/*** PROP TYPES VALIDATION ***/
DealerMap.propTypes = {
    loadingElement:PropTypes.object.isRequired,
    containerElement:PropTypes.object.isRequired,
    mapElement:PropTypes.object.isRequired,
    dealerData:PropTypes.array,
    zoom :PropTypes.number.isRequired,
    coordination :PropTypes.object.isRequired,
    googleMapURL:PropTypes.string.isRequired,
    isMarkerShown :PropTypes.bool.isRequired
};



export default DealerMap