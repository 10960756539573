import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/**
 * Simple component to display a "Seller" and the name of the seller associated with an item
 * @param props
 * sellerName: String, Required. The name of the seller that will be displayed
 * styles: Object, Optional. Contains classnames to style the text in this component
 * @returns {JSX.Element}
 * @constructor
 */
const ItemSeller = (props) => {
    const { t } = useTranslation();
    const sellerLabelClass = props.styles?.dealerLabel || "";
    const sellerClass = props.styles?.dealer || "";

    return (
        <div className="prt-seller-container">
            <p className={sellerLabelClass + " prt-seller-label"}>{t("LABEL_COMMON_SELLER")}</p>
            <div className={"prt-seller-content " + sellerClass}>{props.sellerName}</div>
        </div>
    );
};

ItemSeller.propTypes = {
    sellerName: PropTypes.string.isRequired,
    styles: PropTypes.object
};

export default ItemSeller;
