
import React, { Component } from 'react';
import CategoryNav from './CategoryNav/CategoryNav';

/*
*********
SearchNav contains the search restriction options for the search page. Currently only contains the CategoryNav component, but can be modified to add more
*********
*/
class SearchNav extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <CategoryNav categoryNavData={this.props.categoryNavData} vehicleConfig={this.props.vehicleConfig}></CategoryNav>
            </div>
        );
    }
}

export default SearchNav
