import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import vaultConstants from "../../../config/vault_constants";
import AppSettings from "../../core/AppSettings";
import accHistory from "../App/History";
import AddtoCart from "./AddToCart/AddtoCart";
import DeliveryOptionsCompatibilityWrapper from "./DeliveryOptions/DeliveryOptionsCompatibilityWrapper";
import FitmentCheckContainer from "./FitmentCheck/FitmentCheckContainer";

const pdpDeliveryOptions = AppSettings.isLocalHost || vaultConstants.FF_2092326_PDP_DELIVERY_OPTIONS;

/**
 * Turns a list of fitments with nested lists of part numbers into a flat list of part numbers.
 *
 * @param {string[]} collection the array to reduce part numbers into
 * @param {{orderItems?: Array}} nextFitment the object describing the next vehicle in the order
 * @returns {string[]} all part numbers in collection and nextFitment
 */
const flattenFitmentsToPartNumberList = (collection, nextFitment) => {
    const { orderItems } = nextFitment;

    if (Array.isArray(orderItems) && orderItems.length > 0) {
        for (const itemEntry of orderItems) {
            if (itemEntry?.partNumber) {
                collection.push(itemEntry.partNumber);
            }
        }
    }

    return collection;
};

/**
 * Custom hook to get the current list of product numbers in cart.
 *
 * IS NULL UNTIL THE MINICART IS LOADED AND PROCESSED.
 * this enables us to differentiate between empty cart and loading cart scenarios.
 *
 * Any time that the list changes, its length should change. We would not have
 * a simultaneous add and delete.
 *
 * @returns {string[] | null} if miniCart is still loading, null, else, the list of part numbers currently in the cart
 */
const useProductNumbersInCart = () => {
    const [productList, setProductList] = useState(null);
    const miniCart = useSelector((state) => state.MiniCartReducer);
    const fitments = miniCart?.data?.vehicles || [];

    if (!miniCart.loaded) {
        if (productList !== null) {
            setProductList(null);
        }
    } else if (!Array.isArray(fitments) || fitments.length === 0) {
        if (productList?.length !== 0) {
            setProductList([]);
        }
    } else {
        const currentItems = fitments.reduce(flattenFitmentsToPartNumberList, []);
        if (currentItems.length !== productList?.length) {
            setProductList(currentItems);
        }
    }

    return productList;
};

/**
 * Helper component to build the interaction area of Product Details
 * Delivery Options + Add to Cart + View in Cart
 * 
 * @typedef ProductDetailsActionAreaProps
 * @type {object}
 * @property {boolean} isDealerized Whether the user is dealerized. Expected to be always false on T3.
 *
 * @param {ProductDetailsActionAreaProps} props the react props
 * @returns {React.ReactElement} the section of PDP below item description where user input happens.
 */
const ProductDetailsActionArea = (props) => {
    const { isDealerized } = props;
    const { t } = useTranslation();
    const item = useSelector((state) => state.ProductDetailsReducer);
    const ymmParams = useSelector((state) => state.Session);
    const fitmentData = useSelector((state) => state.FitmentCheckReducer);
    const priceExists = "price" in item && item.price?.length > 0;

    // const suppressDeliveryOptions = !this.props.item.suppressDeliveryOptions;
    const currentItems = useProductNumbersInCart();
    const isMiniCartLoaded = Array.isArray(currentItems);
    const isThisItemInCart = currentItems?.includes(item.partNumber);

    // we must have miniCart to know if user
    //should get add to cart or view cart button
    if (!isMiniCartLoaded || !isDealerized || !priceExists) {
        return (
            <div className="small-12 xlarge-6 columns overlay-inherit">
                <FitmentCheckContainer uniqueID={item.uniqueID} />
                <AddtoCart
                    // Including this add to cart to be consistent with current implementation.
                    vin={ymmParams.vin}
                    showAddToCartError={item.showAddToCartError}
                    showLoadingBtn={!isMiniCartLoaded || item.showLoadingBtn || fitmentData.showLoadingBtn}
                    name={item.name}
                    shipModeId={item.shipModeId}
                    deliveryOptionsLoading={item.deliveryOptionsLoading}
                    changeErrorStatus={props.changeErrorStatus}
                />
            </div>
        );
    } else if (isThisItemInCart && pdpDeliveryOptions) {
        return (
            <div className="small-12 xlarge-6 columns overlay-inherit">
                <p className="q-headline3 medium-margin">{t("LABEL_COMMON_ITEM_ALREADY_IN_CART")}</p>
                <div className="small-12 right">
                    <div onClick={() => accHistory.push("/cart")}>
                        <button
                            className="ac-add-to-cart-btn q-button q-primary-button gm-primary-btn stat-button-link"
                            disabled={props.showLoadingBtn || props.deliveryOptionsLoading}
                        >
                            {t("LABEL_COMMON_VIEW_CART")}
                            {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true" />}
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="add-cart-columns">
                {pdpDeliveryOptions /* && !suppressDeliveryOptions */ && <DeliveryOptionsCompatibilityWrapper />}
                <FitmentCheckContainer uniqueID={item.uniqueID} />
                <AddtoCart
                    vin={ymmParams.vin}
                    showAddToCartError={item.showAddToCartError}
                    showLoadingBtn={item.showLoadingBtn || fitmentData.showLoadingBtn}
                    name={item.name}
                    shipModeId={item.shipModeId}
                    deliveryOptionsLoading={item.deliveryOptionsLoading}
                    changeErrorStatus={props.changeErrorStatus}
                />
            </div>
        );
    }
};

export default ProductDetailsActionArea;
