import React, { useState, useEffect } from 'react';
import HistoryOrderItem from '../HistoryOrderItem/HistoryOrderItem';
import CartOrderLineItemHeader from "../../../../shared/CartComponents/ItemHeader/ItemHeader";
import i18next from 'i18next';

function HistoryVehicle(props) {
    const { vehicle, orderInfo } = props;
    const [vehicleInfo, setVehicleInfo] = useState({});
    const [orderItems, setOrderItems] = useState([{}]);

    useEffect(() => {
        if (vehicle != undefined && vehicle.vehiclesInfo != undefined) {
            setOrderItems(vehicle.orderItems);
            setVehicleInfo(vehicle.vehiclesInfo);
        }
    });


    return (
        <div className="ac-history-vehicle small-12 grid-column-alignment-left columns ac-nopadding-x">
            {/* <div className="ac-history-vehicle-title">
                            <h2 className="q-headline2">{vehicleInfo.year} {vehicleInfo.make} {vehicleInfo.model}</h2>

                        </div>*/}
            <div className="ac-history-vehicle-header">
                {
                    vehicleInfo.ymm !== undefined && <CartOrderLineItemHeader data={vehicleInfo} />
                }

            </div>
            {orderItems.map(function (orderItem, i) {
                return (
                    <HistoryOrderItem
                        key={i}
                        orderItem={orderItem}
                        orderInfo={orderInfo}
                    />
                )
            })
            }
        </div>

    );
}

export default HistoryVehicle