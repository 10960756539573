import React from "react";
import SlickSlider from 'react-slick';
import { NextArrow, PrevArrow } from "../../../../modules/Home/HomeCarousel/HomeCarousel";
import Spinner from '../../../../shared/Spinner/Spinner';
import AppSettings from "../../../../core/AppSettings";
import { connect } from 'react-redux';
import { hideSpinner } from "../../ViewOnVehicleRedux/VoVActionCreator";



const VoVSlider = (props) => {
    const showSpinner = props.vovReducerData.spinner;
    let vovData = props.vovReducerData.imageDataWithParts;

    if (!props.vovReducerData.isImageWithParts) {
        vovData = props.vovReducerData.imageDataWithoutParts;

    }
    let loopVoVData = false;
    if (vovData !== undefined && vovData !== '' && vovData.length) {
        loopVoVData = true;
    }


    /*** Default Image if unexpected things occur ***/
    const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";



    const sliderSettings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3500,
        arrows: true,
        infinite: true,
        nextArrow: <NextArrow dataDtm={"vov:"+props.dataDtm}/>,
        prevArrow: <PrevArrow dataDtm={"vov:"+props.dataDtm}/>,
        dotsClass: 'q-content-bar',
        appendDots: dots => (
            <div>
                <div className="slick-indicator-container js-append-dots">
                    <ul className="slick-dots stat-dots" data-dtm={"vov:"+props.dataDtm}>{dots}</ul> 
                </div>
                <div className="q-semi-opaque-bar"></div>
            </div>)
    };

    return (
        <div className="q-gallery-full-scroller ac-vov-slider-container">
            <div className="ac-no-background q-multimedia-scroller q-mod q-mod-multimedia-scroller">

                {/* Spinner */}
                {showSpinner && <div className="ac-vov-slider-spinner"><Spinner /></div>}

                {/* Carousel */}
                <SlickSlider {...sliderSettings} className="q-slider q-with-caption js-has-caption q-slider-hover-arrow">
                    {
                        loopVoVData && vovData.map((item, index) => {
                            const lengthOfVoVData = vovData.length;
                            return (
                                <img key={index} src={item.url} alt="image Not Found" className="q-rectangle-image-responsive q-image"
                                    onLoad={() => props.isImageLoaded(index, lengthOfVoVData)}
                                    onError={(e) => { e.target.onerror = null; e.target.src = `${imgNotFound}` }} />
                            )
                        })
                    }
                </SlickSlider>

            </div>
        </div>)
}

const mapDispatchToProps = (dispatch) => {
    return {
        /** once all image has been loaded successfully, func will dispatch an action to hide the spinner **/
        isImageLoaded: (...options) => {
            const imageCount = options[0] + 1;
            const vovLength = options[1];
            if (imageCount === vovLength) {
                dispatch(hideSpinner());

            }
        }
    }
}
export default connect(null, mapDispatchToProps)(VoVSlider)