import * as type from "./FeaturedCategoriesActionType";

/**************************************************************/
// Actions to load data into the FeaturedCarouselReducer state
/**************************************************************/
export const loadFeaturedCategoriesDataAsync = () => {
    const action = {
        type: type.LOAD_FEATURED_CATEGORIES_DATA_ASYNC
    };
    return action;
};

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const setFeaturedCategoriesData = (assets, title) => {
    const action = {
        type: type.SET_FEATURED_CATEGORIES_DATA,
        categoriesAssets: assets,
        categoriesTitle: title
    };
    return action;
};

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadFeaturedCategoriesDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_FEATURED_CATEGORIES_DATA_ASYNC_FAIL,
        error
    };
    return action;
};

export const setFeaturedCategoriesDataFail = (error) => {
    const action = {
        type: type.SET_FEATURED_CATEGORIES_DATA_FAIL,
        error
    };
    return action;
};
