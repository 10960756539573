import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppSettings from '../../../core/AppSettings';
import i18n from '../../../core/i18n/i18n';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import { formattedCurrency } from "../../../shared/Internationalization/FormattedNumbers";
import ProductCard from '../../../shared/ProductCard/ProductCard';
import Spinner from '../../../shared/Spinner/Spinner';
import * as utils from '../../../shared/Utils/Utils';
import { loadMore } from '../SearchService/SearchDataServiceActionCreator';
import { setCatalogDataServiceValue } from '../SearchService/SearchDataServiceWorker';

// error image for product cards
const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png"

class SearchResultsList extends Component {
  createProductList() {
  const shopWithoutFitment = this.props.searchData.isShopWithoutFitmentActive;

    // Checks for existing responses whether it's shop without fitment or not.
    if ((this.props.searchData?.searchResponse)) {
      if (this.props.searchData?.searchResponse?.catalogEntryView) {
        const pageNum = this.props.searchData.pageNumber;
        let searchDataItems = this.props.searchData.searchResponse.catalogEntryView;
        // check 
        if (pageNum > 1) {
          searchDataItems = this.props.searchData.catalogEntryView;
        }
        let searchDataItemsTotalMatches = this.props.searchData.searchResponse.recordSetTotal;
        let { searchParams } = this.props.searchData;
        let { vcdb } = searchParams;

        const categoryId = searchParams.categoryId;

        let bodyNumDoors = '';
        let bodyStyle = '';
        let driveType = '';
        let engineBase = '';
        let trim = '';
        let wheelBase = '';
        let bodyStyleName = '';
        let trimName = '';
        let driveTypeName = '';
        let wheelBaseName = '';

        if (vcdb) {
          bodyNumDoors = vcdb.bodyNumDoors || '';
          bodyStyle = vcdb.bodyTypeId || '';
          driveType = vcdb.driveTypeId || '';
          engineBase = vcdb.engineBaseId || '';
          trim = vcdb.trimId || '';
          wheelBase = vcdb.wheelBaseId || '';
          bodyStyleName = this.props.bodyStyleName || '';
          trimName = this.props.trimName || '';
          driveTypeName = this.props.driveTypeName || '';
          wheelBaseName = this.props.wheelBaseName || '';
        }

        let t = this.props.t;
        let noItemsMessage = '';
        let resetCategory = false;

        if (searchDataItems.length <= 0) {

          if (this.props.searchData.fullSearchResponse != undefined && this.props.searchData.fullSearchResponse.catalogEntryView != undefined) {

            //Check if this was a fitment search, in which case we DON'T want to display all accessories
            //Fitment error message is handled in Search component
            if (!(bodyNumDoors || bodyStyle || driveType || engineBase || trim || wheelBase)) {

              searchDataItems = this.props.searchData.fullSearchResponse.catalogEntryView;
              searchDataItemsTotalMatches = this.props.searchData.fullSearchResponse.recordSetTotal;
              if (searchDataItems.length > 0) {
                resetCategory = true;
                noItemsMessage = 'LABEL_SEARCH_RESULTS_UNFORTUNATELY';
              } else {
                //both category specific and the full response gave 0 items
                noItemsMessage = 'LABEL_SEARCH_NO_RESULTS';
              }
            }
          }
        }

        // load more spinner is handled in SearchDataServiceReducer, based on 
        // the load more action and when the new search call to retrieve 
        // data for the next page has completed
        const showLoadMoreSpinner = this.props.searchData.nextPageIsLoading;

        return (
          <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">

            {noItemsMessage &&
              <ErrorMessage message={noItemsMessage} icon={true} type="warning"></ErrorMessage>
            }

            {searchDataItems.map((item, index) => {

              // T3/T1 product card differences go here
              if (AppSettings.isT3) {
                var productPriceLabel = t('LABEL_SEARCH_RESULTS_PART_ONLY');
                var productInstallPriceLabel = t('LABEL_SEARCH_RESULTS_INSTALLED_PRICE') + ": ";
                var productPriceDisclosure = t('LABEL_SEARCH_RESULTS_CONTACT_DEALER');
              } else {
                var productPriceLabel = t('LABEL_COMMON_MSRP');
                var productPriceAsterisk = "*"
              }

              if (this.props.categoryNavData.selectedCategory) {
                if (this.props.categoryNavData.parsedCategories) {
                  var allCats = this.props.categoryNavData.parsedCategories[0];
                  var allCatsId = allCats.uniqueId;
                }

                // check if a category has been selected
                if (this.props.categoryNavData.selectedCategory.parentCategory) {
                  // if category has parent category id that's equal to the special categories id (all products)
                  if (this.props.categoryNavData.selectedCategory.parentCategory.uniqueId == allCatsId) {
                    var cat = this.props.categoryNavData.selectedCategory
                  }
                  else {
                    var cat = this.props.categoryNavData.selectedCategory.parentCategory
                    var subcat = this.props.categoryNavData.selectedCategory
                  }
                }
              }
              if (cat && cat.label) {
                var selectedCat = (cat.label).toLowerCase();
              }
              if (subcat && subcat.label) {
                var selectedSubcat = (subcat.label).toLowerCase();
              }

              // name
              const productName = item.name;
              // image
              let productImg = '';

              if (item.UserData[0] != undefined && item.UserData[0].images != undefined) {
                productImg = item.UserData[0].images.mainImage;

              }
              
              //fallback MSRP price
              let cost = item.price[0].value;

              let installPrice;
              if (AppSettings.isT3) {
                //empty install price check
                if (item.installPrice != "0.0" && isValidString(item.installPrice)) {
                  installPrice = item.installPrice;
                }
                item.price.forEach(function (price) {
                  if (price.usage == "Offer") {
                    if (isValidString(price.value)) {
                      cost = price.value;
                    }
                  }
                });
              }
              else {
                item.price.forEach(function (price) {
                  if (price.usage == "Display") {
                    if (isValidString(price.value)) {
                      cost = price.value;
                    }
                  }
                });
              }

              // Grabbing currency (USD, EUR) stuff
              let currency = item.price[0].currency;
              item.price.forEach(function (price) {
                currency = price.currency;
              });


              // Product price
              const productPrice = formattedCurrency(currency, cost);

              // Install price
              if (installPrice !== undefined && AppSettings.isT3) {
                installPrice = formattedCurrency(currency, installPrice);
              }

              // Sets view on vehicle values for current item. These values are 
              // passed as props to ProductCard and then down to ViewOnVehicle.
              //
              // - partsRPO is set directly from the view on vehicle attributes 
              // in the WCS search call.
              //
              // - colorCode is set conditionally. 
              //
              //  - showViewOnVehicle is set directly from the view on vehicle 
              // attributes in the WCS search call. If that value is 'N', then 
              // view on vehicle is not shown, regardless of any other data.
              let partsRPO = '';
              let partColorCode = '';
              let showViewOnVehicle = false;
              const viewOnVehicleAttributes = item.VOV;
              if (viewOnVehicleAttributes) {
                for (var attribute of viewOnVehicleAttributes) {
                  if (attribute.name && attribute.value) {
                    switch (attribute.name) {
                      case 'VOV':
                        if (attribute.value === 'Y') {
                          showViewOnVehicle = true;
                        }
                        break;
                      case 'RPO':
                        partsRPO = attribute.value;
                        break;
                      case 'Color RPO':
                        partColorCode = attribute.value;
                        break;
                    }
                  }
                }
              }
              let colorCode = this.colorCode(this.props.searchData, this.props.viewOnVehicleData, partColorCode);

              // create product details link 
              const additionalParams = {
                bodyNumDoors: queryString.parse(location.search).bodyNumDoors,
                categoryId: categoryId || AppSettings.urlParameters()["categoryId"],
                searchTerm: this.props.searchTerm,
                categoryName: selectedCat,
                subcategoryName: selectedSubcat
              }
              const productLink = utils.constructProductUrl(item.seoUrl, additionalParams);

              return (
                // populate Search Results List 

                <div key={index} className="ac-product-cards-wrapper">
                  <ProductCard
                    {...item}
                    productLink={productLink}
                    productName={productName}
                    productImg={productImg}
                    imgNotFound={imgNotFound}
                    productImgSize="/640X640"
                    productPrice={productPrice}
                    installPrice={installPrice}
                    productPriceLabel={productPriceLabel}
                    productInstallPriceLabel={productInstallPriceLabel}
                    productPriceAsterisk={productPriceAsterisk}
                    productPriceDisclosure={productPriceDisclosure}
                    buttonLabel={i18n.t("LABEL_SEARCH_VIEW_DETAILS")}
                    partsRPO={partsRPO}
                    colorCode={colorCode}
                    showViewOnVehicle={showViewOnVehicle} />
                </div>
              )
            })}
            {/* load more button displays only if there are more items to show */}
            {searchDataItems.length < searchDataItemsTotalMatches &&
              <div className="small-12 columns">
                <button onClick={() => this.props.loadNextPage(this.props.searchData.searchParams, pageNum, resetCategory)} type="button" className="ac-load-more-btn q-mod q-mod-button q-button q-secondary-button q-button-small q-button-padding-none small-margin stat-button-link" data-dtm="all products">
                  {t('LABEL_SEARCH_LOAD_MORE')}
                </button>
              </div>
            }
            {
              /** SHOW SPINNER WHEN MORE SEARCH LIST ITEMS ARE LOADING **/
              <div className="ac-search-spinner">&nbsp;{showLoadMoreSpinner && <Spinner className="ac-search-spinner-testing" />}</div>
            }

          </div>
        )
      }
    }
  }



  render() {
    const showSpinner = this.props.searchData.searchResultsListIsLoading;

    return (
      <div>
        {
          showSpinner ? <Spinner /> : this.createProductList()
        }

      </div>
    )
  }

  //   - If searching by VIN, then colorCode is set based on the 
  //     vehicle info primary color. 
  //   - If searching by YMM and a part color RPO is available,  
  //     then colorCode is set equal to the color RPO of
  //     the current part.
  //   - If searching by YMM and color RPO is not available for the
  //     current part, colorCode is set equal to the first element 
  //     in the color code list returned by CGI.
  colorCode = (searchData, viewOnVehicleData, partColorCode) => {
    let colorCode = '';

    if (searchData.searchParams.vin) {
      const vin = searchData.searchParams.vin;
      const vehicleInfo = searchData.vehicleInfo;
      if (vehicleInfo) {
        for (var vehicle of vehicleInfo) {
          if (vehicle.vin === vin && vehicle.options && vehicle.options.primaryColor) {
            colorCode = vehicle.options.primaryColor;
            return colorCode;
          }
        }
      }
    }

    if (partColorCode !== '') {
      colorCode = partColorCode;
      return colorCode;
    }

    if (viewOnVehicleData.cgiColorCodeList) {
      const cgiColorCodes = viewOnVehicleData.cgiColorCodeList;
      if (cgiColorCodes.length > 0) {
        colorCode = cgiColorCodes[0];
        return colorCode;
      }
    }

    return colorCode;
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    loadNextPage: (searchParams, pageNum, resetCategory) => {
      if (resetCategory) {
        setCatalogDataServiceValue(props.searchData.fullSearchResponse);
        searchParams.categoryId = "";
        dispatch(loadMore(searchParams, pageNum));

      }
      else {
        dispatch(loadMore(searchParams, pageNum));
      }
    }
  }
}


/**
 * The following function will take a string and determine if it is valid or not,
 * if a string is emptry or not of type string it will return false , else it will
 * return true 
 * 
 * @param {string}   string 
 * @returns {boolean} returns a boolean
 */
function isValidString(str) {
  if (typeof str != 'string') {
    return false;
  } 
  else if (str === '') {
    return false;
  }
  else {
    return true
  };
}

export default connect(null, mapDispatchToProps)(SearchResultsList)