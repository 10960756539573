import React, {useEffect, useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import { normalizePhone, normalizePostalCode } from '../../../shared/Form/FormNormalize/FormNormalize';
import * as validate from '../../../shared/Form/FormValidate/FormValidate';
import {Translation} from 'react-i18next';
import Button from "../../../shared/Button/Button";
import {renderCheckboxField, renderInputField, renderSelectDropdown} from "../../../shared/Form/FormFields/FormFields";
import {Toggle} from "../../../shared/Hooks/Hooks";
import Footnote from "../../../shared/Footnote/Footnote";
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";


let AccountDetailsForm = props => {
    const { handleSubmit, stateList, initialValues, submitFailed, isLoading, phoneTypes, onCityChange, onFirstNameChange,
        onLastNameChange, onAddress1Change, onAddress2Change, onStateChange, onPostalChange, onPhoneChange, onPhoneTypeChange,
        onEnrollRewardsChange, isEnrollRewardsChecked, privacyLink, errorMessage, brand, prefillEnrollment} = props

    const [findError, setFindError] = useState(false);
    const rewardsTerms = process.env.REACT_APP_REWARDS_BASE_URL + props.brand + process.env.REACT_APP_REWARDS_FILENAME;
    const FF_2274900_CA_RESIDENTS_FOOTNOTE = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2274900_CA_RESIDENTS_FOOTNOTE) === 'true';
    const caDisclosure = process.env.REACT_APP_CA_FINANCIAL_INCENTIVE_DISCLOSURE;

    //this toggles the state 'findError' to run the useEffect and scroll to location on page if errors found
    function toggleValueOnClick() {
        Toggle(findError,setFindError);
    }

    useEffect(()=>{
        // Scenario where user comes in here with user Data on page load is only when he comes from OCP
        // On page load check if it's got data, if so, precheck the checkbox for enrollment.
        if (prefillEnrollment) {
            var checkbox = document.getElementsByClassName("stat-checkbox");
            checkbox[0].checked = true;
            // While the checkbox is checked, the value to send in the body as true (for enrollment) must also be set.
            onEnrollRewardsChange();
        }
    }, []);

    useEffect(() => {
        const errorFields = document.getElementsByClassName('gmit-gf-error-label');
        const errorFields2 = document.getElementsByClassName('ac-errorParent ac-errorMessage') || '';

        //this finds the error fields that appear under the labels
        if(submitFailed && errorFields.length){
            //just grab the first one if there's multiple
            const location1 = errorFields[0].getBoundingClientRect().top;

            /*calculate position to scroll to based on a) error element location b) current scroll location and
             extra 400 padding to put the element in the middle of the screen*/
            window.scrollTo(0, location1 + window.pageYOffset - 400);
        }

        //this finds the className error fields at the top of the page if call fails
        if(errorFields2.length && errorMessage){
            //just grab the first one if there's multiple
            const location2 = errorFields2[0].getBoundingClientRect().top;

            /*calculate position to scroll to based on a) error element location b) current scroll location and
             extra 400 padding to put the element in the middle of the screen*/
            window.scrollTo(0, location2 + window.pageYOffset - 400);
        }

    }, [submitFailed,findError, errorMessage]);

    return (
        <Translation>
            {(t) => (
                <form className="ac-account-details-form" onSubmit={handleSubmit}>
                    <div className="gmit-gf-groupbox-content">
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="firstName"
                                    type="text"
                                    onChange={onFirstNameChange}
                                    component={renderInputField}
                                    label={t("LABEL_COMMON_FIRST_NAME")}
                                    placeholder={t("LABEL_COMMON_FIRST_NAME")}
                                    showAsterisk={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="lastName"
                                    type="text"
                                    onChange={onLastNameChange}
                                    component={renderInputField}
                                    label={t("LABEL_COMMON_LAST_NAME")}
                                    placeholder={t("LABEL_COMMON_LAST_NAME")}
                                    showAsterisk={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="address1"
                                    type="text"
                                    onChange={onAddress1Change}
                                    component={renderInputField}
                                    label={t("LABEL_CHECKOUT_ADDRESS_LINE1")}
                                    placeholder={t("LABEL_CHECKOUT_ADDRESS_LINE1")}
                                    showAsterisk={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="address2"
                                    type="text"
                                    onChange={onAddress2Change}
                                    component={renderInputField}
                                    label={t("LABEL_CHECKOUT_ADDRESS_LINE2_OPTIONAL")}
                                    placeholder={t("LABEL_CHECKOUT_ADDRESS_LINE2_OPTIONAL")}
                                    validate={[validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                           <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="city"
                                    type="text"
                                    onChange={onCityChange}
                                    component={renderInputField}
                                    label={t("LABEL_COMMON_CITY")}
                                    placeholder={t("LABEL_COMMON_CITY")}
                                    showAsterisk={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="state"
                                    component={renderSelectDropdown}
                                    onChange={onStateChange}
                                    label={t("LABEL_COMMON_STATE")}
                                    required={true}
                                    validate={[validate.required]}
                                    showAsterisk={true}
                                    defaultValue={props.initialValues.state}
                                    classes={"stat-dropdown"}
                                    dataDtm={"additional info"}
                                >
                                    <option hidden value="">State</option>
                                    {stateList.map(stateOption =>
                                        <option value={stateOption} key={stateOption}>
                                            {stateOption}
                                        </option>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="postalCode"
                                    component={renderInputField}
                                    type="text"
                                    onChange={onPostalChange}
                                    label={t("LABEL_COMMON_ZIP")}
                                    placeholder={t("LABEL_COMMON_ZIP")}
                                    showAsterisk={true}
                                    validate={[validate.required, validate.postalCode]}
                                    normalize={normalizePostalCode}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="phone"
                                    component={renderInputField}
                                    type="text"
                                    onChange={onPhoneChange}
                                    label={t("LABEL_COMMON_PHONE")}
                                    placeholder={t("LABEL_COMMON_PHONE")}
                                    validate={[validate.required, validate.phoneNumber]}
                                    showAsterisk={true}
                                    normalize={normalizePhone}
                                    maxLength={14}
                                    classes={"stat-input-field"}
                                    dataDtm={"additional info"}
                                />
                            </div>
                            <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                <Field
                                    name="phoneType"
                                    component={renderSelectDropdown}
                                    onChange={onPhoneTypeChange}
                                    label={t("LABEL_COMMON_PHONE_TYPE")}
                                    required={true}
                                    classes={"stat-dropdown"}
                                    dataDtm={"additional info"}
                                >
                                    {phoneTypes.map(phoneOption =>
                                        <option value={phoneOption} key={phoneOption}>
                                            {phoneOption}
                                        </option>
                                    )}
                                </Field>
                            </div>
                        </div>
                            <div className="small-12">
                                <div className="ac-enroll-checkbox">
                                    <Field
                                        name="RewardsCheckbox"
                                        onChange={onEnrollRewardsChange}
                                        checked={isEnrollRewardsChecked}
                                        component={renderCheckboxField}
                                        href={[rewardsTerms,privacyLink]}
                                        localeKey="LABEL_ACCOUNT_DETAILS_FOOTNOTE"
                                        value={brand}
                                        dataDtmCheckBox={"additional info"}
                                        dataDtmCheckBoxLink={["additional info","additional info"]}
                                    />
                                </div>
                            </div>

                        {FF_2274900_CA_RESIDENTS_FOOTNOTE &&
                            <div id="ac-ca-residents-footnote">
                                <Footnote
                                    localeKey="LABEL_ACCOUNT_CA_RESIDENTS_FOOTNOTE"
                                    href={[caDisclosure]}
                                />
                            </div>
                        }

                    </div>
                    <div className="small-12">
                        <Button
                            localeKey={props.ff_2224243_caps_v3_migration ? t("LABEL_ACCOUNT_DETAILS_REGISTER_BTN") : t("LABEL_ACCOUNT_DETAILS_SUBMIT_BTN")}
                            type="submit"
                            onClick={toggleValueOnClick}
                            disabled={isLoading}
                            isLoading={isLoading}
                            dataDtm={"additional info"}
                        />
                    </div>
                </form >
            )}
        </Translation>
    )
}
AccountDetailsForm = reduxForm({
    form: 'AccountDetailsForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(AccountDetailsForm);

export default AccountDetailsForm;
