import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import { parseAssets } from "./CarouselHelper";
import { getMarketingCarouselData } from "./CarouselService";
import CarouselSlider from "./CarouselSlider";
import ReactDOM from "react-dom";
/**************************************************************/
//                  CAROUSEL
/**************************************************************/
// NOTES:
// - This component was made to be edited for future work to add on multiple carousel services for different pages as well as multiple carousels for one single page.

// - Pass in PageName prop to distinguish where the carousel will live
// - Pass in the carouselNumber to specify the 'number' you wish to assign a specific carousel on the page if multiple carousels live on one page. This will require a new service to be added in CarouselService.js

/*

Examples:

1.) Regular Carousel (one per page):

    <Carousel
    pageName={'productSearch'}/>

2.) Multiple Carousel's per page:

    <Carousel
    pageName={'productSearch'}
    carouselNumber={'1'}/>

    <Carousel
    pageName={'productSearch'}
    carouselNumber={'2'}/>
*/

//Example code inside of getCarouselData() for distinguishing which ESPOT Marketing call to make
/*  if(props.pageName == '/'){
        response = await getMarketingCarouselData();
    }
    else if(props.pageName == '/SearchResults'){
        if(props.carouselNumber === '1'){
              response = await getSearchResultsCarouselData();
          }
        else if(props.carouselNumber === '2'){
            response = await getSearchResultsCarouselData2();
          }
    }
*/

Carousel.propTypes = {
    pageName: propTypes.string.isRequired, //
    carouselNumber: propTypes.string, // this is if multiple carousels will be rendered in the same page
    customResponse: propTypes.object // this is to circumvent the default homepage espot call for custom pages
};

function Carousel(props) {
    const [carouselAssets, setCarouselAssets] = useState({});
    const [isActive, setIsActive] = useState(true);
    const isComponentAlive = useRef(true);

    useEffect(() => {
        getCarouselData();
        return () => (isComponentAlive.current = false);
    }, []);

    useEffect(() => {
        getCarouselData();
    }, [props.customResponse]);

    const getCarouselData = async () => {
        let response;
        if (props.customResponse) {
            response = { ...props.customResponse };
        } else if (props.pageName === "/") {
            response = await getMarketingCarouselData();
        }
        try {
            //get all data from response
            let carouselEspotData;
            if (response?.MarketingSpotData?.[0]?.baseMarketingSpotActivityData) {
                carouselEspotData = response?.MarketingSpotData?.[0]?.baseMarketingSpotActivityData;
                setIsActive(true);
            } else {
                setIsActive(false);
            }

            //take only relevant data from response
            if (carouselEspotData && carouselEspotData.length > 0) {
                let parsedCarouselEspotData = parseAssets(carouselEspotData);
                for (const asset of carouselEspotData) {
                    if (asset.contentUrl) {
                        for (const parsedAsset of parsedCarouselEspotData) {
                            if (parsedAsset.assetName === asset.baseMarketingSpotActivityName) {
                                parsedAsset["contentUrl"] = asset.contentUrl;
                            }
                        }
                    }
                }
                isComponentAlive?.current && setCarouselAssets(parsedCarouselEspotData);
            }
        } catch (error) {
            console.warn("carousel data error:\n", error);
        }
    };

    return (
        (carouselAssets && carouselAssets.length > 0 && isActive && (
            <CarouselSlider carouselAssets={carouselAssets} dataDtm={props.dataDtm} />
        )) ||
        null
    );
}

const wrapper = document.getElementById("home-carousel");
wrapper ? ReactDOM.render(<Carousel />, wrapper) : false;

export default Carousel;
