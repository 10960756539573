import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import GetLocationButton from "./Buttons/GetLocationButton";

/**
 * @param {*} props
 *  state: reducer state for dealerization modal
 *  dispatch: reducer dispatch for dealerization modal
 * @return {div}
 *  header component for dealerization  modal with the 'use location' button
 * ... pretty much everything above the -- or -- separator
 *  map view does not have the following...:
 *     'Get Location' button
 *      description
 */
export default function DealerLocatorHeader(props) {
    const { t } = useTranslation();
    const capitalizedSrc = props.state.sellerSource.charAt(0).toUpperCase() + props.state.sellerSource.slice(1);

    const descAndBtn = (
        <>
            {t("LABEL_DEALER_LOCATOR_SELECT_SRC_DESCRIPT", { source: props.state.sellerSource })}
            <GetLocationButton state={props.state} dispatch={props.dispatch} setDealerList={props.setDealerList} />
        </>
    );

    /* Render Header of Dealerization Modal*/
    return (
        <div className="prt-dealerization-header-section">
            <h3>{t("LABEL_DEALER_LOCATOR_SELECT_SRC_HEADER", { source: capitalizedSrc })}</h3>
            {!props.mapView && descAndBtn}
        </div>
    );
}

DealerLocatorHeader.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired,
    mapView: PropTypes.bool
};
