import { getVaultContainer } from "./VaultUI";
import vaultStylesText from "./VaultCSS.css";

/**
 * @typedef cssInjectorResult
 * @type {[boolean, string=]}
 * @property {boolean} 0 true if the script was injected
 * @property {string=} 1 err a string describing the error that occured
 */

/**
 * Injects a stylesheet that makes the Vault UI modal look nice.
 * @returns {cssInjectorResult}
 */
const injectVaultCSS = () => {
    const container = getVaultContainer();

    if (container) {
        const sheet = document.createElement("style");
        sheet.innerText = vaultStylesText;
        container.appendChild(sheet);
        return [true];
    } else {
        return [false, "Vault Container not found in DOM."];
    }
};

export default injectVaultCSS;
