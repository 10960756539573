import React, {Component} from 'react';
import UseSelected from "./UseSelected";
import { Translation } from 'react-i18next';
export { RadioButtons };

class RadioButtons extends Component {
  constructor(props) {
    /*dictates what to default the selectedOption to*/
    let selectedOption = {};
    const fedExAvailability = props.fedExAvailability;
    const validity = props.validity;
    const resAddress = props.validatedResponse.data;
    const stateZipMismatch = props.stateZipMismatch

    if (validity) {
      selectedOption = "response";
    }
    else if(!stateZipMismatch && !resAddress?.address || !fedExAvailability){
      selectedOption = "form";
    }
    else{
      selectedOption = "response";
    }
    super(props);
    this.state = {
      selectedOption: selectedOption
    };
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };
  
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
    const data = this.props;
    const form = data.originalAddressForm;
    let selectedAddress = "";
    if (this.state.selectedOption !== "" ) {
      if (this.state.selectedOption === "form") {
        selectedAddress = form
      } else {
        selectedAddress = this.props.validatedResponse.data.address
      }
    } else {
      selectedAddress = form
    }
    this.props.handleAddressModalSubmit(this.props.originalAddressForm, selectedAddress);
  };

  /**
   * There's a few different expected flows depending on the address validation. With those, the appropriately
   *  translated text and radio options must be returned.
   *  I opted for if/else returns of the entire <div> because of the lack of
   *  ability to use variables within the Translation tag we use for translations
   *  The form and res address data creates the text lines by each radio button selection
   *  validity          The FedEx response: true if valid; false if invalid
   *  fedExAvailability The availability of FedEx's address validation service: true if available; false if not
   *  stateZipMismatch  The user's address input vs the FedEx validation: true if mismatch; false if match
   *  POBox Whether the address entered is recognized by FedEx to be a POBox or not (True means POBox and False means not a POBox)
   * @returns The appropriate radio button text and options per flow, depending on the above
   */
  createRadioSelections() {
    const data = this.props;
    const form = data.originalAddressForm;
    const res = data.validatedResponse.data;
    const resAddress = res.address;
    const validity = data.validity;
    const fedExAvailability = this.props.fedExAvailability;
    const stateZipMismatch = this.props.stateZipMismatch;
    const POBox = this.props.POBox;
    let resLine1 = '';
    let resLine2 =  '';

    if (resAddress?.address1) {
      resLine1 = resAddress['address1'] + ' ' + resAddress['address2'];
      resLine2 = resAddress['city'] + ' ' + resAddress['state'] + ' ' + resAddress['zipCode'];
    }
      let enteredLine1 = form['address1'] + ' ' + form['address2'];
      let enteredLine2 = form['city'] + ' ' + form['state'] + ' ' + form['postalCode'];

      if (!validity && stateZipMismatch && !resAddress?.address1) {
        /* If fedex address validation is false & state and zip do not match, render validation error
         * and display the address the user entered in. */
        return (
          <div>
            <div>
              <Translation >
                {(t) => (
                  <span className="radio-header-text">
              {t('LABEL_CHECKOUT_ENTERED_ADDRESS')}
            </span>
                )}
              </Translation>
            </div>
            <div className="ac-small-marginbottom">
              <label className="ac-label-disabled">
                <ul className="ac-radio-list">
                  <li className="radio-button-text">
                    {enteredLine1}
                  </li>
                  <li className="radio-button-text">
                    {enteredLine2}
                  </li>
                </ul>
              </label>
            </div>
          </div>
        )
      } else if (!validity && !fedExAvailability || POBox) {
        /* If fedex returns bad response AND fedex is down or address is a POBox, render validation error
         * and display the address the user entered in. */
      return (
        <div>
          <div>
            <Translation>
              {(t) => (
                <span className="radio-header-text">
					  {t('LABEL_CHECKOUT_ENTERED_ADDRESS')}
					</span>
              )}
            </Translation>
          </div>
          <div  className="ac-small-marginbottom">
            <label className="ac-label-disabled">
              <ul className="ac-radio-list">
                <li className="radio-button-text">
                  {enteredLine1}
                </li>
                <li className="radio-button-text">
                  {enteredLine2}
                </li>
              </ul>
            </label>
          </div>
        </div>
      )
    } else if (!validity && !stateZipMismatch && !resAddress?.address1) {
      /* If fedex address validation is false & state and zip match & fedex does not find a suggested address,
       * allow user to proceed with entered address. There are cases where new streets are not yet
       * in fedex system, etc. The important thing is that state and zip match for calculation purposes. */
      return (
        <div>
          <div>
            <div>
              <Translation>
                {(t) => (
                  <span className="radio-header-text">
						  {t('LABEL_ADDRESS_VALIDATION_USE_ENTERED')}
						</span>
                )}
              </Translation>
            </div>
            <div  className="ac-small-marginbottom">
              <label>
                <input className="ac-radio-input" type="radio" name="address"
                       value={"form"}
                       checked={this.state.selectedOption === "form"}
                       onChange={this.handleOptionChange}
                />
                <ul className="ac-radio-list">
                  <li className="radio-button-text">
                    {enteredLine1}
                  </li>
                  <li className="radio-button-text">
                    {enteredLine2}
                  </li>
                </ul>
              </label>
            </div>
          </div>
        </div>
      )
    } else if (!stateZipMismatch && resAddress?.address1) {
      /*If fedex cannot validate address but has a suggested address and original address' state and zip match,
       * user can choose to either use original address or use suggested address. */
      return (
        <div>
          <div>
            <div>
              <Translation>
                {(t) => (
                  <span className="radio-header-text">
						  {t('LABEL_ADDRESS_VALIDATION_USE_ENTERED')}
						</span>
                )}
              </Translation>
            </div>
            <div  className="ac-small-marginbottom">
              <label>
                <input className="ac-radio-input" type="radio" name="address"
                        value={"form"}
                        checked={this.state.selectedOption === "form"}
                        onChange={this.handleOptionChange}
                />
                <ul className="ac-radio-list">
                  <li className="radio-button-text">
                    {enteredLine1}
                  </li>
                  <li className="radio-button-text">
                    {enteredLine2}
                  </li>
                </ul>
              </label>
            </div>
          </div>
          <div>
            <Translation >
              {(t) => (
                <span className="radio-header-text">
					  {t('LABEL_ADDRESS_VALIDATION_USE_SUGGESTED')}
					</span>
              )}
            </Translation>
          </div>
          <div className="ac-small-marginbottom">
            <label>
              <input className="ac-radio-input" type="radio" name="address"
                     value={"response"}
                     checked={this.state.selectedOption === "response"}
                     onChange={this.handleOptionChange}
              />
              <ul className="ac-radio-list">
                <li className="radio-button-text">
                  {resLine1}
                </li>
                <li className="radio-button-text">
                  {resLine2}
                </li>
              </ul>
            </label>
          </div>
        </div>
      )
    } else if (resAddress?.address1) {
      /*If fedex returns a suggested address, allow user to pick between editing address
       * or use "Suggested Address". */
      return (
        <div>
          <div>
            <div>
              <Translation>
                {(t) => (
                  <span className="radio-header-text">
						  {t('LABEL_CHECKOUT_ENTERED_ADDRESS')}
						</span>
                )}
              </Translation>
            </div>
            <div  className="ac-small-marginbottom">
              <label>
                <ul className="ac-radio-list">
                  <li className="radio-button-text">
                    {enteredLine1}
                  </li>
                  <li className="radio-button-text">
                    {enteredLine2}
                  </li>
                </ul>
              </label>
            </div>
          </div>
          <div>
            <Translation >
              {(t) => (
                <span className="radio-header-text">
					  {t('LABEL_ADDRESS_VALIDATION_USE_SUGGESTED')}
					</span>
              )}
            </Translation>
          </div>
          <div className="ac-small-marginbottom">
            <label>
              <input className="ac-radio-input" type="radio" name="address"
                     value={"response"}
                     checked={this.state.selectedOption === "response"}
                     onChange={this.handleOptionChange}
              />
              <ul className="ac-radio-list">
                <li className="radio-button-text">
                  {resLine1}
                </li>
                <li className="radio-button-text">
                  {resLine2}
                </li>
              </ul>
            </label>
          </div>
        </div>
      )
    } else {
      /*If no other conditions are met, display entered address and
       * force user to edit address before proceeding. */
      return (
        <div>
          <div>
            <Translation >
              {(t) => (
                <span className="radio-header-text">
                  {t('LABEL_CHECKOUT_ENTERED_ADDRESS')}
                </span>
              )}
            </Translation>
          </div>
          <div className="ac-small-marginbottom">
            <label className="ac-label-disabled">
              <ul className="ac-radio-list">
                <li className="radio-button-text">
                  {enteredLine1}
                </li>
                <li className="radio-button-text">
                  {enteredLine2}
                </li>
              </ul>
            </label>
          </div>
        </div>
      )
    }
  }

  render (props) {
    const radioSelections = this.createRadioSelections();
    return (
      <div>
        <form onSubmit={this.handleFormSubmit}>
          {radioSelections}
          <div className="ac-address-modal-use-button">
            <UseSelected
              handleContactFormSubmit={this.props.handleContactFormSubmit}
              validity={this.props.validity}
              fedExAvailability={this.props.fedExAvailability}
              stateZipMismatch={this.props.stateZipMismatch}
              POBox={this.props.POBox}
              isLoading={this.props.isLoading}
              suggestedAddress={
                this.props.validatedResponse.data.address
              }
            />
          </div>
        </form>
      </div>
    )
  }
}


export default RadioButtons
