import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import store from "../../../core/Redux/Store";
import { FooterDisclaimerLabel } from "../../../modules/App/Footer/FooterDisclaimerLabel";
import CouponsForm from "./CouponsForm";
import CouponsModal from "../../../modules/Checkout/Modals/Coupons/CouponsModal";
import ErrorMessage from "../../../shared/ErrorMessage/ErrorMessage";
import { useSelector } from "react-redux";
import {
    resetPromotionIdState,
    showSummaryRailSpinner
} from "../../SummaryRail/SummaryRailRedux/SummaryRailActionCreator";
import {couponPromotionIdPostAsync, getCouponData} from "../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import vaultConstants from "../../../../config/vault_constants";

/**
 * This is the container for grabbing the recent available coupons to show in the Manufacturing and Dealer offers
 * and the container for the couponsForm on the cart order summary that lets users manually enter coupon codes.
 *
 * @param props
 * orderId={props.orderInfo.orderId}
 *
 * @returns {JSX.Element}
 */

//TODO:need to clean up some imports after seeing which ones are needed or not
export default function CartCoupons(props) {
    const [showModal, setShowModal] = useState(false);
    const [isDealer, setIsDealer] = useState(false);
    const [dealerOffers, setDealerOffers] = useState([]);
    const [manuOffers, setManuOffers] = useState([]);
    const [selectedOffers, setSelectedOffers] = useState([]);
    // get disclaimer name for coupons
    const disclaimer = process.env.REACT_APP_DISCLAIMER_CHECKOUT_COUPON;
    const { t } = useTranslation();
    const checkoutData = useSelector((state) => state.CheckoutReducer);
    const cartResponse = useSelector((state) => state.CartReducer);
    const summaryRailsState = useSelector((state) => state.RailsReducer);

    useEffect(() => {
        store.dispatch(getCouponData(props.orderId));
        if (String(vaultConstants.API_TARGETABLE).includes("dev")) {
            console.log(props.orderId);
        }
    }, []);

    useEffect(() => {
        generateOffers();
    }, [checkoutData.couponDisplayData]);

    // show dealer offers
    const showDealerOffers = () => {
        setIsDealer(true);
        setSelectedOffers(dealerOffers);
        toggleModal();
        resetPromotionId();
    };

    // show manufacturing offers
    const showManuOffers = () => {
        setIsDealer(false);
        setSelectedOffers(manuOffers);
        toggleModal();
        resetPromotionId();
    };
    const toggleModal = () => {
        setShowModal(!showModal);
    };
    const generateOffers = () => {
        const couponData = checkoutData.couponDisplayData;
        let manuData = [];
        let dealerData = [];
        couponData.forEach(function (index) {
            if (index.GMOffers) {
                manuData = index.GMOffers;
            }
            if (index.DealerOffers) {
                dealerData = index.DealerOffers;
            }
        });
        setManuOffers(manuData);
        setDealerOffers(dealerData);
    };
    const applyPromotionCode = (couponCode) => {
        store.dispatch(showSummaryRailSpinner(true));
        store.dispatch(couponPromotionIdPostAsync(couponCode));
        toggleModal();
    };
    const resetPromotionId = () => {
        if (checkoutData.promoIdMsg) {
            const clearError = resetPromotionIdState();
            store.dispatch(clearError);
        }
    };
    return (
        <div
        className={
            /*TODO: need to uncomment this spinner out when we can get it working: this.props.summaryRailsState.showSpinner ? 'ac-summary-rail-coupon-code ac-summary-rail-untouchable' : */ "ac-summary-rail-coupon-code"
        }
    >
        {/* Coupon Offer Links */}
        <div className="ac-coupon-links-wrapper">
            {/* T3/Dealer: Display coupon modal link if any coupon offers */}
            {(dealerOffers.length > 0 && (
                <div className="ac-coupon-offer-link-cart">
                    <a
                        className="ac-coupon-offer-link-cart stat-text-link"
                        data-dtm="order summary"
                        onClick={showDealerOffers}
                    >
                       <span>{t("LABEL_COUPON_VIEW_DEALER")}</span>
                        <span className="q-link-label2">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                    </a>
                </div>
            )
            )}

            {/* T1 Only: Display coupon modal link if any coupon offers */}
            {(manuOffers.length > 0 && (
                <div className="ac-coupon-offer-link-cart">
                    <a
                        className="ac-coupon-offer-link-cart stat-text-link"
                        data-dtm="checkout step2"
                        onClick={showManuOffers}
                    >
                        <span>{t("LABEL_COUPON_VIEW_MANUFACTURER")}</span>
                        <span className="q-link-label2">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                    </a>
                </div>
            ))}

            {/* Display error message above form (e.g. duplicate coupon codes) */}
            {checkoutData.promoIdMsg && (
                <div className="columns ac-nopadding-x">
                    <ErrorMessage icon={true} message={checkoutData.promoIdMsg ? checkoutData.promoIdMsg : ""} />
                </div>
            )}

            <div className="ac-coupon-code">
                {/* Coupon Header */}
                <h4 className="q-headline">
                    <b className="gb-body2 heavy">Have a promo code?</b>
                    {/* get correct superscript label for the checkout coupon disclaimer in useFooterDisclaimerLabel function component */}
                    <b className="gb-body2 heavy">
                        <FooterDisclaimerLabel
                            disclaimerName={disclaimer}
                            children={(label) => <sup className="prt-coupon-disclaimer-sup">{label}</sup>}
                        />
                    </b>
                </h4>

                {/*Display error if user selected a coupon from coupon banner and is not being applied to order */}
                {cartResponse.parsedResponse.cost.notAdjustedPromotions &&
                    cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage && (
                        <div className="columns ac-nopadding-x">
                            <ErrorMessage
                                icon={true}
                                message={
                                    cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage
                                        ? cartResponse.parsedResponse.cost.notAdjustedPromotions.fullCouponErrorMessage
                                        : ""
                                }
                            />
                        </div>
                    )}
            </div>

            {/* Coupon Inline Input/Button Form */}
            <div className="cart-coupon">
                <CouponsForm
                    couponMsg={checkoutData.couponMsg ? checkoutData.couponMsg : ""}
                    showLoadingBtn={summaryRailsState.showLoadingBtn}
                />
            </div>

            {/* Coupon Modal with Offers */}
            <CouponsModal
                showModal={showModal}
                toggleModal={toggleModal}
                offersLabel={isDealer}
                offers={selectedOffers}
                apply={applyPromotionCode}
            />
        </div>
    </div>
    );
}
