import * as type from './WalletActionType';

export const getWalletData = () => {
    const action = {
        type: type.GET_WALLET_DATA
    }
    return action;
}

export const setWalletData = (walletData) => {
    const action = {
        type: type.SET_WALLET_DATA,
        walletData: walletData
    }
    return action;
}

export const setGpmsLocale = (locale) => {
    const action = {
        type: type.SET_GPMS_LOCALE,
        locale: locale
    }
    return action;
}

export const setWalletDataError = (error) => {
    const action = {
        type: type.SET_WALLET_DATA_ERROR,
        error: error
    }
    return action;
}

export const walletLoadError = () => {
    const action = {
        type: type.WALLET_LOAD_ERROR,
    }
    return action
}
