import React, { useState, useEffect } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import vaultConstants from "../../../../config/vault_constants";
import { loadFitmentCheckAsync } from "../FitmentCheck/FitmentCheckRedux/FitmentCheckActionCreator";
import { addToCartAsync, showAddToCartError } from "../ProductDetailsRedux/ProductDetailsActionCreator";
import FieldErrorMessage from "../../../shared/FieldErrorMessage/FieldErrorMessage";
import Spinner from "../../../shared/Spinner/Spinner";
import AppSettings from "../../../core/AppSettings";

const pdpDeliveryOptions = AppSettings.isLocalHost || vaultConstants.FF_2092326_PDP_DELIVERY_OPTIONS;

const AddToCart = (props) => {
    const inlineFitmentFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2197227_INLINE_FITMENT_PDP_ACCESSORIES) === "true";

    const [showDealerizationError, setShowDealerizationError] = useState(false);
    useEffect(() => setShowDealerizationError(false), [props.shipModeId]);

    if (inlineFitmentFlag) {
        const handleAddToCart = () => {
            let parsed = queryString.parse(location.search);
            const resultsElem = !!document.getElementById("inline-fitment-results");
            const successElem = !!document.getElementById("inline-fitment-success");
            const failElem = !!document.getElementById("inline-fitment-fail");

            if (!AppSettings.isT3 && pdpDeliveryOptions && props.shipModeId === "") {
                setShowDealerizationError(true);
            } else if ((props.shipModeId && !successElem && !resultsElem) || (props.shipModeId && failElem)) {
                ForcedFitmentError();
            } else if (props.vin || parsed.vin) {
                props.addToCart();
            } else {
                props.fitmentCheckAndShowModal();
            }
        };
    
        const ForcedFitmentError = () => {
            let inlineSect = document.getElementById("inline-fitment-section");
            let scrollLocation = inlineSect.getBoundingClientRect().top + window.scrollY;

            props.changeErrorStatus(true);

            //Use inline fitment section as anchor to scroll up to plus padding
            window.scrollTo({ top: scrollLocation - 200, behavior: "smooth" });

            //Makes error element disappear after use clicks anywhere within inline fitment section
            inlineSect.addEventListener("click", () => {
                props.changeErrorStatus(false);
            });
        };
    
        return (
            <Translation>
                {(t) => (
                    <div className="small-12 right">
                        <div onClick={handleAddToCart}>
                            <button
                                className="ac-add-to-cart-btn q-button q-primary-button gm-primary-btn stat-button-link"
                                disabled={props.showLoadingBtn || props.deliveryOptionsLoading}
                                data-dtm={props.dataDtm || props.name}
                            >
                                {t("LABEL_COMMON_ADD_TO_CART")}
                                {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true" />}
                            </button>
                        </div>
                        <div className="ac-small-margintop">
                            <FieldErrorMessage
                                message={t("LABEL_ADD_TO_CART_ERROR")}
                                showError={props.showAddToCartError}
                            />
                            {pdpDeliveryOptions && (
                                <FieldErrorMessage
                                    message={t("LABEL_ADD_TO_CART_NO_DEALER_ERROR")}
                                    showError={showDealerizationError}
                                />
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    } else {
        let addToCartAction = "";
        // VIN ADD TO CART:
        // If there's a vin, no need for fitment check
        let parsed = queryString.parse(location.search);

        if (!AppSettings.isT3 && pdpDeliveryOptions && props.shipModeId === "") {
            addToCartAction = () => setShowDealerizationError(true);
        } else if (props.vin || parsed.vin) {
            addToCartAction = props.addToCart;
        }
        // YMM ADD TO CART:
        // Fitment check needed
        else {
            addToCartAction = props.fitmentCheckAndShowModal;
        }

        return (
            <Translation>
                {(t) => (
                    <div className="small-12 right">
                        <div onClick={addToCartAction}>
                            <button
                                className="ac-add-to-cart-btn q-button q-primary-button gm-primary-btn stat-button-link"
                                disabled={props.showLoadingBtn || props.deliveryOptionsLoading}
                                data-dtm={props.dataDtm || props.name}
                            >
                                {t("LABEL_COMMON_ADD_TO_CART")}
                                {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true" />}
                            </button>
                        </div>
                        <div className="ac-small-margintop">
                            <FieldErrorMessage
                                message={t("LABEL_ADD_TO_CART_ERROR")}
                                showError={props.showAddToCartError}
                            />
                            {pdpDeliveryOptions && (
                                <FieldErrorMessage
                                    message={t("LABEL_ADD_TO_CART_NO_DEALER_ERROR")}
                                    showError={showDealerizationError}
                                />
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        //check fitment and show modal
        fitmentCheckAndShowModal: () => {
            dispatch(showAddToCartError(false));
            dispatch(loadFitmentCheckAsync("cart"));
        },
        addToCart: () => {
            dispatch(addToCartAsync());
        }
    };
};

export default connect(null, mapDispatchToProps)(AddToCart);
