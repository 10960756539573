export const CART_PAGE_INIT = "CART_PAGE_INIT";
export const CART_PAGE_SPINNER_OFF = "CART_PAGE_SPINNER_OFF";
export const CART_PAGE_FAILED_API_CALL = "CART_PAGE_FAILED_API_CALL";
export const CART_PAGE_ITEM_REMOVE_FAIL = "CART_PAGE_ITEM_REMOVE_FAIL";
export const CART_QTY_CHANGE_FAIL = "ORDER_INFO_QTY_CHANGE_FAIL";
export const CART_GET_SHIPPING_RATES_ERROR = "CART_GET_SHIPPING_RATES_ERROR";
export const CART_DELIVERY_CHANGE_FAIL = "CART_DELIVERY_CHANGE_FAIL";
export const CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY = "CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY";
export const CART_HIDE_ALERT = "CART_HIDE_ALERT";
export const CART_RESET_REDUCER = "CART_RESET_REDUCER";

export const ORDER_INFO_ORDER_IN_CART_ASYNC = "ORDER_INFO_ORDER_IN_CART_ASYNC";
export const ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL = "ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL";
export const ORDER_INFO_ORDER_IN_CART = "ORDER_INFO_ORDER_IN_CART";
export const UPDATE_COUPON_BANNER = "UPDATE_COUPON_BANNER";
