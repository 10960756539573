
import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { call } from 'redux-saga/effects';
import AppSettings from '../../core/AppSettings';
import store from '../../core/Redux/Store'
import {checkPartialAuthenticationError} from "../Utils/Utils";

// Authentication service that returns authentication data
const authenticationServiceUrl = '/wcs/resources/store/' + AppSettings.storeId + '/guestidentity?updateCookies=true';

// Properties that indicate authentication errors
const authenticationErrorCodes = ['USR.CWXFR0130E', 'CMN0409E', 'CWXBB1103E', 'CMN1039E'];
const authenticationErrorKeys = ['USR.CWXFR0130E', '_ERR_ACTIVITY_TOKEN', '_ERR_INVALID_COOKIE'];
const authenticationErrorMessages = ['This request cannot run as a generic user.'];
const authentication401Message = 'Error: Request failed with status code 401';

/**
 * postAuthenticated() sends an authenticated POST request to the given URL 
 * using the given parameters and returns the result. Only authentication 
 * errors are handled here; all other data and error handling must be performed 
 * by the calling function.
 */
export function* postAuthenticated(url, data, config) {
    console.log("Authentication function");
    let parseOrderId = '';
    let orderId = '';
    const isUserSignedIn = store.getState().Authentication.registrationStatus != 'G';
    try {
        // Sends the POST request assuming the current session is authenticated.
        let result = yield call(post, url, data, config);
        console.log("Result of authentication", result);

        // If the current session is not authenticated or any other authentication 
        // error was encountered, authenticates and then retries POST request.
        let resultData = result.data;
        if (resultData) {
            console.log("Authentication result data", resultData);
            if (hasAuthenticationError(resultData)) {
                // If user is in checkout and was authenticated, send to roadblock page 
                if (isUserSignedIn && AppSettings.pageName === 'Checkout') {
                   checkPartialAuthenticationError();
                    }
                yield call(authenticate);
                console.log("Finished authenticate yield");
                result = yield call(post, url, data, config);
            }
        }

        return result;
    } catch (error) {
        if (hasAuthenticationError(error) && isUserSignedIn && AppSettings.pageName === 'Checkout') {
            yield checkPartialAuthenticationError();
         } else if (error.response && error.response.status === 401) {
            yield call(authenticate);
            let result = yield call(post, url, data, config);
            return result;
        }
        else if (error.response && error.response.status === 400) {
            return error.response
        }
    }
}

/**
 * post() sends a POST request to the given URL using the given parameters and 
 * returns the result. If available, HttpOnly cookies WCToken and 
 * WCTrustedToken are automatically included in the POST request to indicate 
 * the authenticated identity of the current session.
 */
const post = (url, data, config) => {
    return axios.post(url, data, config)
        .then(result => {
            return result;
        })
}

/**
 * authenticate() sets authentication data by sending a POST request to the 
 * authentication service. The authentication service replies with an identity 
 * for the user by setting HttpOnly cookies WCToken and WCTrustedToken on the 
 * client.
 */
export const authenticate = () => {
    return axios.post(authenticationServiceUrl)
        .then(result => {
            return result;
        })
}

/**
 * hasAuthenticationError() returns true if the data contains an authentication 
 * error or otherwise returns false.
 */
export const hasAuthenticationError = (data) => {
    // Case for error thrown to catch block:
    console.log("Has authentication error", data, authentication401Message);
    if (data == authentication401Message) {
        return true;
    }

    if (data) {
        const errors = data.errors;
        if (errors) {
            for (var error of errors) {
                const errorCode = error['errorCode'];
                const errorKey = error['errorKey'];
                const errorMessage = error['errorMessage'];

                if (authenticationErrorCodes.includes(errorCode)
                    || authenticationErrorKeys.includes(errorKey)
                    || authenticationErrorMessages.includes(errorMessage)) {
                    return true;
                }
            }
        }
    }

    return false;
}



// export function* postAuthenticated(url, data, config) {
//     let headers = yield getAuthenticationHeaders();
//     config['headers'] = headers;
//     let result = yield call(post, url, data, config);
//     return result;
// }

export function* getAuthenticated(url) {
    let headers = getAuthenticationHeadersFromCookies();
    const config = {
        headers: headers
    }

    let result = yield call(get, url, config);
    return result;
}

const get = (url, config) => {
    return axios.get(url, config)
        .then(result => {
            console.log('get result', result);
            return result;
        })
}


export function* getAuthenticationHeaders() {
    let headers = getAuthenticationHeadersFromCookies();
    if (isEmpty(headers)) {
        headers = yield getAuthenticationHeadersFromApi();
    }
    return headers;
}

export function* getAuthenticationHeadersFromApi() {
    const response = yield call(getTokens);
    const WCToken = response.WCToken;
    const WCTrustedToken = response.WCTrustedToken;
    setAuthenticationCookies(WCToken, WCTrustedToken);

    const headers = buildAuthenticationHeaders(WCToken, WCTrustedToken);
    return headers;
}

const getAuthenticationHeadersFromCookies = () => {
    const WCTokenCookie = Cookies.get('WCToken');
    const WCTrustedTokenCookie = Cookies.get('WCTrustedToken');
    const headers = buildAuthenticationHeaders(WCTokenCookie, WCTrustedTokenCookie);
    return headers;
}

const buildAuthenticationHeaders = (WCToken, WCTrustedToken) => {
    let headers = {};
    if (WCToken && WCTrustedToken) {
        headers = {
            'WCToken': WCToken,
            'WCTrustedToken': WCTrustedToken
        }
    }
    return headers;
}

const setAuthenticationCookies = (WCToken, WCTrustedToken) => {
    Cookies.set('WCToken', WCToken, {
        path: '/',
        httpOnly: false,
    });

    Cookies.set('WCTrustedToken', WCTrustedToken, {
        path: '/',
        httpOnly: false
    });
}

const getTokens = () => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/guestidentity';
    return axios.post(url, {})
        .then(response => {
            return response.data
        })
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
