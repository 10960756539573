import React, { Component } from 'react';
import queryString from 'query-string';


/**************************************************************/
//          SubCategoryItemComponent
/**************************************************************/

class SubCategoryItem extends Component {
    constructor(props) {
        super(props);
    }
    handleClick(subCategory,searchParams){
        this.props.closeAccordion([]);
        this.props.selectCategory(subCategory, searchParams);
        this.props.handleURL(subCategory);
    }
    render() {
        const props = this.props;
        let subCategory = props.category;
        let currentCategory = this.props.currentCategory;
        let activeClass = false;
        let queryParams = queryString.parse(document.location.search);

        if ((currentCategory.label === subCategory.label) || (queryParams.categoryId === subCategory.uniqueId))  {
            activeClass = true;
        }
        if (subCategory !== undefined) {
            return (
                <li
                    className={activeClass ? "ac-subcat-items-active q-text q-body2 stat-dropdown" : "q-text q-body2 stat-dropdown"}
                    data-dtm={this.props.fitmentErrorActive ? "all fitted products:" +subCategory.label : "all products:" +subCategory.label}
                    onClick={() => this.handleClick(subCategory, this.props.searchParams)}>
                    {subCategory.label} ({subCategory.count})
                </li>
            );
        }
        else {
            return (<li>...</li>)
        }
    }
}

export default SubCategoryItem