import React from "react";
import DealerMap from "./DealerMap";
import PropTypes from "prop-types";

/**
 * DealerMap's Parent component
 * Contains data to feed into the Google Maps API
 *
 * @param {*} props
 */
export default function MapWrapper(props) {
    const mapElement = props.state.dealerMapData;

    return (
        <DealerMap
            centerCoord={mapElement.coordinates}
            zoom={mapElement.zoom}
            dealerData={props.state.listOfAllSources}
            isMarkerShown={true}
            containerElement={<div className="prt-dealer-locator-container" />}
            loadingElement={<div className="prt-dealer-locator-loading-element" />}
            mapElement={<div className="prt-dealer-locator-map-element" />}
            googleMapURL="https://maps.googleapis.com/maps/api/js?client=gme-adamopelag"
            state={props.state}
            dispatch={props.dispatch}
        />
    );
}

/*** PROP TYPES VALIDATION ***/
MapWrapper.propTypes = {
    state: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired
};
