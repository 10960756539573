import axios from 'axios';
import AppSettings from '../../../core/AppSettings';

// YMM
export const getYears = (startingYear) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/yearmakemodel/years/' + AppSettings.country + '/' + startingYear;
    return axios.get(url).then(response => response.data);
}

export const getVehicleSelectData = (year, make) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/yearmakemodel/vehicles/' + AppSettings.country + '/' + year + '/' + make;
    return axios.get(url).then(response => response.data);
}

/* getMakes function for getting the makes for the YMM dropdown
*  sends request with year selected and current dealer BAC
*  returns array of relevant makes
* */
export const getMakes = (year, bac) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/yearmakemodel/vehicles/' + AppSettings.country + '/' + year + "?bac=" + bac;
    return axios.get(url).then(response => response.data);
};

//"/wcs/resources/store/11205/GMPartner/114906/dealer/hennachevyaustin?bac=114906&langId=-1"
/* makes a call to grab dealer information
* returns full dealer array to parse and grab default make from*/
export const getDefaultMake = () => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMPartner/' + AppSettings.bac + '/dealer' + AppSettings.dealerName + '?langId=-1';
    return axios.get(url).then(response => response.data);
};

// VIN
export const getVINData = (vin) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/Search/VehicleInfo/byVIN/' + vin + '?responseFormat=json';
    return axios.get(url).then(response => response.data);
}