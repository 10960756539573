import AppSettings from "../../core/AppSettings";
import vaultConstants from "./../../../config/vault_constants";

const envTrack = AppSettings.isLocalHost ? "dev1" : String(vaultConstants.ENVIRONMENT_TRACK);
const epgClientID = AppSettings.isLocalHost
    ? process.env.REACT_APP_WALLET_CLIENT_ID
    : vaultConstants.WALLET_CLIENT_ID_TARGETABLE;
const APIM_ENV_PARAM = envTrack === "prod" ? "" : "?uat-env=" + envTrack.slice(0, -1).toUpperCase();
/**
 * @params payload values to pass from UI to backend
 // Parameter name	Required	Description
 // clientId		-yes-   The application identifier.
 // countryCode		The shopper's country code. This is used to filter the list of available payment methods to your shopper.
 // userId	        Shopper reference id
 * posts a new session that is then returned with a session.Id and session.data which is then used in the Adyen configuration object
 * */
export const getPaymentMethods = (params) => {
    const payload = {
        amount: {
            currency: params.currency,
            value: 0
        },
        clientId: epgClientID,
        countryCode: "US",
        userId: params.userId,
        environment: envTrack ? envTrack : "prod"
    };

    return useFetch("/getPaymentMethods", "POST", payload);
};

export const removePaymentMethod = (removePayObj) => {
    const payload = {
        clientId: epgClientID,
        paymentId: removePayObj.paymentId,
        userId: removePayObj.userId,
        environment: envTrack ? envTrack : "prod"
    };

    return useFetch("/removePaymentMethod", "POST", payload);
};
/**
 * @authPayObj payload values to pass from UI to backend
 * this is used to edit the users stored credit cards information. ( name, expiry date, cvv)
 * */
export const authorizeEditPayment = (authPayObj) => {
    const payload = {
        amount: {
            currency: authPayObj.currency,
            value: 0
        },
        captureDelayHours: "0",
        clientId: epgClientID,
        environment: envTrack ? envTrack : "prod", //checks if null, default to prod
        orderNo: authPayObj.orderNo,
        paymentMethod: {
            storedPaymentMethodId: authPayObj.storedPaymentMethodId,
            expiryMonth: authPayObj.expiryMonth,
            expiryYear: authPayObj.expiryYear,
            holderName: authPayObj.holderName,
            type: "scheme"
        },
        returnUrl: "gm.com",
        shopperInteraction: authPayObj.shopperInteraction,
        storePaymentMethod: authPayObj.storePaymentMethod,
        userId: authPayObj.userId
    };

    return useFetch("/cardUpdate", "POST", payload);
};

/**
 * @params payload values to pass from UI to backend
 * returns a new session that is then returned with a session.Id and session.data which is then used in the Adyen configuration object
 * This is called to add a new payment
 * */
export const getSession = (params) => {
    const payload = {
        amount: {
            currency: params.currency,
            value: 0
        },
        clientId: epgClientID,
        countryCode: "US",
        orderNo: params.orderNo,
        returnUrl: "gm.com",
        userId: params.userId,
        storePaymentMethod: params.storePaymentMethod,
        environment: envTrack ? envTrack : "prod" //checks if null, defualt to prod
    };

    return useFetch("/session", "POST", payload);
};

/**
 * @data payload with payment id information
 * @params payload values to pass from UI to backend
 * This is called to make a payment with a stored token "storedPaymentMethodId" in checkout
 * */
//may not be needed anymore. Dropin makes a call directly to Adyen. This method requires PCI zone.
export const authorize = (data, params) => {
    const payload = {
        paymentMethod: data.paymentMethod,
        amount: {
            value: params.totalAmount,
            currency: params.currency
        },
        reference: "Acc-0001",
        orderNo: params.orderNo,
        clientId: epgClientID,
        returnUrl: "https://your-company.com/...",
        splits: [],
        captureDelayHours: "0",
        userId: params.userId ? params.userId : "UnknownUser"
    };

    return useFetch("/authorize", "POST", payload);
};
/**
 * @params payload values to pass from UI to backend
 * when user selects 'Find Existing' button this api is called to update the stored payment type
 * */
export const updateWallet = (payments, userId) => {
    const payload = {
        clientId: epgClientID,
        userId: userId,
        walletType: "EST",
        payments: payments
    };

    return useFetch("/updateWalletType", "POST", payload);
};

/**
 *
 * @param pathName
 * @param method
 * @param payload
 * @returns {Promise<any>}
 * This will use fetch instead of Axios so that we can omit the credentials from the request headers.
 * This removes the 'Cookie' header from the request.
 * Reason for this: In certain browser sessions, the cookie header was too big and was causing our Spring Boot Application (EPG backend) to return a 400 bad request,
 * to fix this we are removing the 'Cookie' entirely from the request.
 */
const useFetch = (pathName, method, payload) => {
    return fetch(pathName, {
        method: method,
        mode: "cors",
        credentials: "omit",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    }).then(
        (response) => {
            let res = response.json().then((resBody) => resBody);
            return res;
        },
        (error) => {
            return error;
        }
    );
};
