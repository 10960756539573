import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryOptionsByCityState, getDeliveryOptionsByZip } from "../../../shared/Utils/sharedServices";
import { SET_SHIP_MODE } from "../ProductDetailsRedux/ProductDetailsActionType";
import DeliveryOptions from "./DeliveryOptions";

/**
 * Delivery Options wrapper
 * 
 * Provides some props and hook-based functionality to the child DeliveryOptions instance
 * 
 * @returns {React.ReactElement} JSX element
 */
const DeliveryOptionsCompatibilityWrapper = () => {
    const cartStateClassification = useSelector(
        (state) => state.MiniCartReducer?.data?.dealerizationInfo?.classification
    );
    const productDetailsState = useSelector(state => state.ProductDetailsReducer);
    const productDetailsDispatch = useDispatch();
    const [deliveryOptionsLoading, setDeliveryOptionsLoading] = useState(0);

    const dealerData = useSelector(state => state.DealerizationReducer);
    const { bac,  city, state, country, zip, zipcode: zip2 } = dealerData.selectedDealerAllData;
    const zipcode = dealerData.currentZip || zip || zip2; // same logic exists on parts.
    const latestDeliveryCall = useRef();
    
    // closures are causing trouble with the bac comparison later. Passing this mutable reference resolves.
    const activeBac = useRef(bac);
    useEffect(() => activeBac.current = bac, [bac]);

    useEffect(() => {
        productDetailsDispatch({
            type: "SET_DELIVERY_OPTIONS_LOADING_STATE", 
            deliveryOptionsLoading: deliveryOptionsLoading > 0 
        });
    }, [deliveryOptionsLoading]);

    useEffect(() => {
        //Prevent this call from being made multiple times with the deliveryOptionsLoading bool. Only make it if zipcode exists.
        if (
            dealerData.contractStatus &&
            !deliveryOptionsLoading /* &&
            !props.state.suppressDeliveryOptions */
        ) {
            //Issues with Contract Setup Call taking a second to write to DB, must wait for that to happen before calling Delivery Options
            setTimeout(() => {
                getDeliveryData();
            }, 500);
        }
    }, [dealerData.contractStatus, dealerData.selectedDealerAllData, dealerData.currentZip/* productDetailsState.inverntoryStatus */]);

    const handleShipModeId = (mode, code) => {
        productDetailsDispatch({
            type: SET_SHIP_MODE,
            shipModeId: mode,
            carrierCode: code
        });
    };

    //Need to get delivery options. Logic to pass the data down to delivery options component and loading hook here.
    const getDeliveryData = async () => {
        try {
            setDeliveryOptionsLoading(count => count + 1);
            let res = "";
            const key = new Date();
            latestDeliveryCall.current = key;
            //if dealerizied by zip search, use user input zip instead of dealer zip, and remove dealer city/state details as they might conflict with user zip.
            if (dealerData.currentZip) {
                res = await getDeliveryOptionsByZip(productDetailsState.partNumber, dealerData.currentZip, country, bac, cartStateClassification);
            }
            else {
                res = await getDeliveryOptionsByCityState(
                    productDetailsState.partNumber,
                    zipcode,
                    city,
                    state,
                    country,
                    bac,
                    cartStateClassification
                );
            }
            

            const handleResponseData = (response) => {
                if ("originalRequestBAC" in response) {
                    // PDP current dealer may have changed, check that BAC is still correct:
                    // drop response if there is a mismatch
                    if (activeBac.current !== response.originalRequestBAC) {
                        return;
                    }
                }
                //Error handling when response is 200, but response is an error outside of the flow. ex: "system not responding"
                if (response?.errors || response?.errorMessage?.includes("exception")) {
                    productDetailsDispatch({
                        type: "SET_TOAST_MESSAGE_ERROR",
                        toastMessage: "LABEL_DELIVERY_OPTIONS_ERROR",
                        suppressAddToCart: true,
                        suppressDeliveryOptions: true
                    });
                    window.scrollTo(0, 0);
                    return;
                }
                if (response?.errorParam?.[0]?.includes("national")) {
                    productDetailsDispatch({ type: "SET_NATIONAL_ORDER_WRITING_CONTROL", nationalWritingControl: "Y" });
                } else if (response?.errorParam?.[0]?.includes("Manual")) {
                    //For this error we do not want to continue error handling as it is already handled in logic above.
                    return;
                } else if (response?.supressedDeliveryOptions?.length > 1) {
                    // Ensure that the call dealer error will show when there 2 suppressed options
                    productDetailsDispatch({
                        type: "SET_SUPPRESS_PDP",
                        suppressDeliveryOptions: true,
                        suppressAddToCart: true,
                        showCallDealerMsg: true,
                        callDealerReason: ""
                    });
                } else if (response?.errorMessage) {
                    // Handle "other" errors
                    let errorHandler = deliveryOptionsErrorHandler(response);
                    if (errorHandler) {
                        productDetailsDispatch({
                            type: "SET_TOAST_MESSAGE_ERROR",
                            toastMessage: errorHandler,
                            suppressAddToCart: true,
                            suppressDeliveryOptions: true
                        });
                        window.scrollTo(0, 0);
                    }
                } else {
                    productDetailsDispatch({ type: "SET_DELIVERY_OPTIONS", deliveryOptions: response });
                }
            };
            //In the scenario where there are multiple rerenders, the delivery options call will be called multiple times. This ensures that the data from the latest fired call will be used. 
            if (key === latestDeliveryCall.current) {
                handleResponseData(res);
            }
        } catch (e) {
            // Error handling for the delivery call timing out, or returning anything other than a successful call..
            productDetailsDispatch({
                type: "SET_TOAST_MESSAGE_ERROR",
                toastMessage: "LABEL_DELIVERY_OPTIONS_ERROR",
                suppressAddToCart: true,
                suppressDeliveryOptions: true
            });
            window.scrollTo(0, 0);
            console.error("data fetching failed", e)
        } finally {
            setDeliveryOptionsLoading(count => count - 1);
        }
    };

    return (
        <DeliveryOptions 
            setShipModeId={handleShipModeId}
            deliveryOptionsData={productDetailsState.deliveryOptions}
            deliveryOptionsLoading={deliveryOptionsLoading > 0}
        />
    );
};

export default DeliveryOptionsCompatibilityWrapper;