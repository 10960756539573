import i18next from "i18next";
import queryString from "query-string";
import React, { Component } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import PartsShopCards from "../../shared/PartsShopCards/PartsShopCards";
import MediaQuery from "react-responsive";
import { Redirect, withRouter } from "react-router-dom";
import AppSettings from "../../core/AppSettings";
import store from "../../core/Redux/Store";
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../shared/ErrorMessage/ErrorMessage";
import { updateVehicleInfoByUrl } from "../../shared/Session/VehicleInfo/VehicleInfoActionCreator";
import { constructProductUrl, jsonToUrlParams, scrubFormField, setBacIfT1, titleSetUp } from "../../shared/Utils/Utils";
import VehicleHeader from "../../shared/VehicleHeader/VehicleHeader";
import NaturalLanguageSearch, { MIN_SEARCH_LENGTH } from "./NaturalLanguageSearch/NaturalLanguageSearch";
import {
    loadCategoryNavDataAsync,
    resetCategoryData
} from "./SearchNav/CategoryNav/CategoryNavRedux/CategoryNavActionCreator";
import SearchNav from "./SearchNav/SearchNav";
import SortBySelect from "./SearchResultsList/SearchResultListSort/SortBySelect.js";
import SearchResultsList from "./SearchResultsList/SearchResultsList";
import { loadSearchServiceDataAsync } from "./SearchResultsList/SearchResultsListRedux/SearchResultsListActionCreator";
import {
    loadSpinner,
    setCurrentVehicleActive,
    setShopWithoutFitment
} from "./SearchService/SearchDataServiceActionCreator";
import FacetSingleSelect from "../../shared/FitmentFilters/FacetSingleSelect";
import SharedInputTags from "./InputTag/SharedInputTags";
import { getFacetsArray, getKeyByValue, removeFacetFromUrl } from "./SearchUtils";
import accHistory from "../App/History";
import CurrentVehicle from "../Home/CurrentVehicle/CurrentVehicle";
import VehicleSelectContainer from "../../shared/VehicleSelect/VehicleSelectContainer";
import vaultConstants from "../../../config/vault_constants";
import { mispellingsFormat } from "../../shared/Utils/Utils";
import { clearCookie, sequentialFitmentDelete } from "../../shared/FitmentFilters/filtersUtils";

const featureCookieFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2246212_FITMENT_COOKIE;

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isYearSelected: false,
            width: window.innerWidth,
            lastActiveFilter: 0,
            selectedCategory: ""
        };

        /** Setting up Page Title **/
        titleSetUp("Search Results");

        /*** T1 LocalStorage for campaignes  ***/
        setBacIfT1(AppSettings.bac);
        this.handleSearchLegacyUrl(this.props.location.pathname, this.props.location.search);

        // Loads vehicle information into the current state.
        if (!featureCookieFlag) {
            this.refreshVehicleInfoByUrl();
        }
        this.setShowCurrentVehicle = this.setShowCurrentVehicle.bind(this);
    }
    handleIsYearSelected = (selected) => {
        this.setState({
            isYearSelected: selected
        });
    };

    handleLastActiveFilter = (sequenceNumber) => {
        this.setState({
            lastActiveFilter: sequenceNumber
        });
    };

    // TEMPORARY: DELETE handleSearchLegacyUrl
    // WHEN BRANDS AND CDK UPDATE THEIR LINKS
    //
    // Convert legacy urls into params
    handleSearchLegacyUrl = (path, params) => {
        const parsedParams = queryString.parseUrl(params);
        let { query } = parsedParams;

        // B2C ONLY
        // takes '/year/make/model/categoryId'
        // and converts into params. If checks aren't
        // met and the below can't convert the url properly,
        // user is sent to the 404 page. Note that this catches
        // anything in the url that doesn't follow ymm pattern
        // on the search page (i.e 'search/niki' = 404 page).
        // As of 2022, we are adding shop without fitment though.
        let isShopWithoutFitmentActive = false;
        if (!AppSettings.isT3) {
            const searchPathName = path.split("/").slice(2);

            const swofFeatureFlag = AppSettings.isLocalHost
                ? true
                : String(vaultConstants.FF_2169904_SWOF_SEARCH_PAGE) === "true";
            // If there's no year or model then we can assume Shop Without Fitment will be active, while keeping VIN in mind.
            if (swofFeatureFlag && query.vin === undefined) {
                store.dispatch(setShopWithoutFitment(true));
                isShopWithoutFitmentActive = true;
            }
            if (!searchPathName || searchPathName == "") {
                return false;
            }
            //Need to account for any additional query params ex. categoryId, eVar36 etc
            let additionalQueryParamsString = "";
            for (var key in query) {
                additionalQueryParamsString += `&${key}=${query[key]}`;
            }
            // Check if first value is 4 digits (year)
            // and if there's at least 3 seperate values in path
            // (ymm pattern check)
            if (/^\d{4}$/.test(searchPathName[0]) && searchPathName[2]) {
                const year = "year=" + searchPathName[0];
                const make = "make=" + searchPathName[1];
                const model = "model=" + searchPathName[2];

                if (additionalQueryParamsString) {
                    this.props.history.push(`/search/?${year}&${make}&${model}${additionalQueryParamsString}`);
                } else {
                    // resolve url into params
                    this.props.history.push(`/search/?${year}&${make}&${model}`);
                }
            }
            // Check if first value is 17 characters (vin pattern check)
            // takes '/<vin>' and converts into param.
            else if (/[a-zA-Z0-9]{17}$/.test(searchPathName[0])) {
                const vin = `vin=${searchPathName[0]}`;

                let additionalQueryParamsString = "";
                for (var key in query) {
                    additionalQueryParamsString += `&${key}=${query[key]}`;
                }

                if (additionalQueryParamsString) {
                    this.props.history.push(`/search/?${vin}${additionalQueryParamsString}`);
                } else {
                    //resolve url into params
                    this.props.history.push(`/search/?${vin}`);
                }
            }

            // If path doesn't follow ymm or vin pattern,
            // send to 404 page.
            else {
                if (this.props.location.pathname != "/product/search") {
                    this.props.history.push("/404");
                }
            }
        }

        // DEALER ONLY
        // takes /<vin>?bac=<bacnumber>&categoryId=<categoryid>
        // and converts into params.
        if (AppSettings.isT3) {
            const dealerPathName = path.split("/").slice(3);
            if (!dealerPathName || dealerPathName == "") {
                return false;
            }
            let additionalQueryParamsString = "";

            // Check if first value is 17 characters (vin)
            // and bac exists in query
            if (/[a-zA-Z0-9]{17}$/.test(dealerPathName[0]) && query.bac) {
                const vin = `vin=${dealerPathName[0]}`;
                const bac = `bac=${query.bac}`;

                for (var key in query) {
                    //we handle bac uniquely, so leaving that one out of the additionalQueryParamsString
                    if (key !== "bac") {
                        additionalQueryParamsString += `&${key}=${query[key]}`;
                    }
                }
                if (additionalQueryParamsString) {
                    //resolve url into params
                    this.props.history.push(
                        `${AppSettings.dealerName}/search/?${vin}&${bac}${additionalQueryParamsString}`
                    );
                } else {
                    //resolve url into params
                    this.props.history.push(`${AppSettings.dealerName}/search/?${vin}&${bac}`);
                }
            }
            // If path doesn't follow vin pattern,
            // send to 404 page.
            else {
                if (query.bac) {
                    this.props.history.push(`${AppSettings.dealerName}/404?bac=${query.bac}`);
                }
            }
        }
    };
    // TEMPORARY: DELETE handleSearchLegacyUrl
    // WHEN BRANDS AND CDK UPDATE THEIR LINKS

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.refreshData();
    }

    setShowCurrentVehicle(isActive) {
        store.dispatch(setCurrentVehicleActive(isActive));
    }

    /** Checks the URL params for changed vehicle parameters i.e the vehicle changed **/
    isVehicleChanged(prevParams, currParams) {
        const prevParsedParams = queryString.parse(prevParams);
        const currParsedParams = queryString.parse(currParams);
        if (
            prevParsedParams.year !== currParsedParams.year ||
            prevParsedParams.make !== currParsedParams.make ||
            prevParsedParams.model !== currParsedParams.model ||
            prevParsedParams.vin !== currParsedParams.vin
        ) {
            return true;
        } else {
            return false;
        }
    }

    refreshData() {
        store.dispatch({ type: "RESET_PAGE_NUMBER" });
        if (this.isNlsSearch()) {
            if (!featureCookieFlag) {
                this.refreshVehicleInfoByUrl();
            }
        } else {
            // load category nav data to state
            const loadCategoryNavData = loadCategoryNavDataAsync();
            store.dispatch(loadCategoryNavData);
        }

        const searchTerm = queryString.parse(this.props.location.search).searchTerm;
        // Functionality is changing. New call will be made before making the YMM/VIN call if there is a searchTerm in the params.
        if (searchTerm !== undefined) {
            // Begin by checking if search term exists. If it does not, then continue with YMM/VIN search
            // components that will take them to the Parts page
            const beginSpinner = loadSpinner(true);
            store.dispatch(beginSpinner);
        } else {
            // load search service data to state
            const loadSearchServiceData = loadSearchServiceDataAsync();
            store.dispatch(loadSearchServiceData);
        }

        /*reset sort by form*/
        const sortBy = document.getElementById("sortBy");
        if (sortBy) {
            sortBy.reset();
        }
    }

    refreshVehicleInfoByUrl() {
        // This deleted the vehicle info data since there are no parameters from the url for vin search. Once they've been grabbed on page load,
        // they do not need to be cleared again, this allows us to not make the vehicle search call again and prevents unnecessary calls.
        if (!this.props.searchData.searchCallLock) {
            const params = this.props.location.search;
            const updateVehicleInfoAction = updateVehicleInfoByUrl(params);
            store.dispatch(updateVehicleInfoAction);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
        const resetCategoryDataAction = resetCategoryData();
        store.dispatch(resetCategoryDataAction);
    }

    changeTitle = () => {
        titleSetUp(this.state.selectedCategory ? this.state.selectedCategory : i18next.t("LABEL_HEADER_APP"));
    };

    componentDidUpdate(prevProps) {
        this.changeTitle();

        let vehChanged = false;
        if (this.props.location !== prevProps.location) {
            if (this.props.location.search !== prevProps.location.search) {
                if (!featureCookieFlag) {
                    this.refreshVehicleInfoByUrl();
                }
                //a vehicle change should reset the catnav as well, resolves an issue where categories weren't populating correctly
                vehChanged = this.isVehicleChanged(prevProps.location.search, this.props.location.search);
                if (vehChanged) {
                    const resetCategoryNavData = resetCategoryData();
                    store.dispatch(resetCategoryNavData);
                }
            }
            this.refreshData();
            /*removed this.refreshData() from this line because
             * its functionality is handled by other components or by original mount*/
        }
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    getErrorMessage() {
        let error = "";
        let parsed = queryString.parse(this.props.location.search);
        let { fitmentID } = parsed;

        if (fitmentID) {
            //Check for fitment message
            error = "LABEL_FITMENT_NO_FIT_BELOW";
            const { searchData } = this.props;
            if (searchData) {
                const { recordSetTotal } = searchData;
                if (recordSetTotal === 0) {
                    error = "LABEL_FITMENT_NO_FIT";
                }
            }
        }

        return error;
    }

    tagHandler = async (facetToBeRemoved = "") => {
        let shouldClearAll = facetToBeRemoved == "" ? true : false;
        let facetType = getKeyByValue(queryString.parse(location.search), facetToBeRemoved);
        // Reset the display sequence of the filters.
        if (facetToBeRemoved == "" || facetType == "year") {
            this.handleLastActiveFilter(0);
        }
        let q = sequentialFitmentDelete(facetType, AppSettings.urlParameters(), shouldClearAll);
        let customURL = location.pathname + jsonToUrlParams(q);
        await clearCookie(q);
        accHistory.push(customURL);
    };

    isNlsSearch() {
        return (
            queryString.parse(this.props.location.search).searchTerm &&
            queryString.parse(this.props.location.search).searchTerm.length >= MIN_SEARCH_LENGTH
        );
    }

    render() {
        const { width } = this.state;
        const isMobile = width < AppSettings.MEDIUM_BREAKPOINT;
        let errorMessage = this.getErrorMessage();
        let { searchData } = this.props;
        let swofFeatureFlag = AppSettings.isLocalHost
            ? true
            : String(vaultConstants.FF_2169904_SWOF_SEARCH_PAGE) === "true";
        if (AppSettings.isT3) {
            swofFeatureFlag = false;
        }

        /********* BREADCRUMB COMPONENT PROPS START*********/
        let categoryNavData = "";
        let selectedCategoriesNavData = "";
        let allCategoriesData = {};
        let allCategoriesId = "";
        let allCategoriesLabel = "";
        let selectedCategoryData = {};
        let selectedCategory = "";
        let selectedCategoryId = "";
        let selectedSubcategoryData = {};
        let selectedSubcategory = "";
        let selectedSubcategoryId = "";

        // filters through category data to gather info for
        // 'all products' category, category, and subcategory
        // and pass to the breadcrumb

        // get 'all products' category info
        if (this.props.categoryNavData.parsedCategories) {
            categoryNavData = this.props.categoryNavData;
            allCategoriesId = categoryNavData.parsedCategories[0].uniqueId;
            allCategoriesLabel = categoryNavData.parsedCategories[0].label;
        }

        if (this.props.categoryNavData.selectedCategory) {
            selectedCategoriesNavData = this.props.categoryNavData.selectedCategory;

            // check for a subcategory
            if (
                selectedCategoriesNavData.categories &&
                selectedCategoriesNavData.categories.uniqueId !== allCategoriesId
            ) {
                allCategoriesData = selectedCategoriesNavData.categories.parentCategory;
                selectedCategoryData = selectedCategoriesNavData.categories;
                selectedCategory = selectedCategoriesNavData.categories.label;
                selectedCategoryId = selectedCategoriesNavData.categories.uniqueId;
                selectedSubcategoryData = selectedCategoriesNavData;
                selectedSubcategory = selectedCategoriesNavData.label;
                selectedSubcategoryId = selectedCategoriesNavData.uniqueId;
            }

            // when there is no subcategory and the parent category is 'all products'
            if (selectedCategoriesNavData.parentCategory) {
                if (
                    selectedCategoriesNavData.parentCategory.label &&
                    selectedCategoriesNavData.parentCategory.uniqueId === allCategoriesId
                ) {
                    allCategoriesData = selectedCategoriesNavData.parentCategory;
                    selectedCategoryData = selectedCategoriesNavData;
                    selectedCategory = selectedCategoriesNavData.label;
                    selectedCategoryId = selectedCategoriesNavData.uniqueId;
                    selectedSubcategoryData = "";
                }
            }

            // when no category has been selected (all products)
            if (!selectedCategoriesNavData.parentCategory && selectedCategoriesNavData.uniqueId === 12001) {
                allCategoriesData = selectedCategoriesNavData;
                selectedCategoryData = "";
                selectedSubcategoryData = "";
            }
            /********* BREADCRUMB COMPONENT PROPS END *********/
        }

        this.state.selectedCategory = selectedCategory;
        if (selectedSubcategory) {
            if (selectedCategory) {
                this.state.selectedCategory =
                    selectedSubcategory + i18next.t("LABEL_HEADER_SEPARATOR") + selectedCategory;
            } else {
                this.state.selectedCategory = "";
            }
        }

        const nlsSearch = this.isNlsSearch();
        const searchEngineResultsPageWithYmm = nlsSearch && searchData.searchParams.searchMode == "YMM";
        const nlsNoResultsFound =
            nlsSearch &&
            this.props.searchData &&
            !this.props.searchData.searchResultsListIsLoading &&
            this.props.searchData.searchResponse.catalogEntryView &&
            this.props.searchData.searchResponse.catalogEntryView.length == 0;
        let nlsResultsCount = null;

        // Get vehicle data for the current vehicle component
        let vehicleData = this.props.sessionData;
        if (nlsSearch && !this.props.searchData.redirectToPartsResults && !searchData.showError) {
            nlsResultsCount = (
                <MediaQuery query="(min-width: 60em)">
                    <div className="ac-search-result-count q-bold2">
                        {nlsNoResultsFound
                            ? i18next.t("LABEL_SEARCH_NLS_NO_MATCHES")
                            : i18next.t("LABEL_SEARCH_NLS_MATCHES", {
                                  count: this.props.searchData?.searchResponse?.catalogEntryView?.length,
                                  total: this.props.searchData.searchResponse.recordSetTotalMatches
                              })}
                    </div>
                </MediaQuery>
            );
        }
        let headerLabel = "";
        if (!this.props.sessionData.model) {
            headerLabel = "LABEL_COMMON_VEHICLE_SELECT_NON_HOME_HEADER";
        }

        if (
            nlsSearch &&
            this.props.searchData.catalogEntryView.length == 1 &&
            this.props.searchData.catalogEntryView[0].seoUrl.split("-").pop() ===
                queryString.parse(this.props.location.search).searchTerm
        ) {
            const productUrl = constructProductUrl(this.props.searchData.catalogEntryView[0].seoUrl, {
                searchTerm: queryString.parse(this.props.location.search).searchTerm,
                bodyNumDoors: queryString.parse(location.search).bodyNumDoors
            });
            return <Redirect to={productUrl} />;
        } else {
            let filterTags = getFacetsArray(searchData.searchResponse?.facetView);
            return (
                <Translation>
                    {(t) => (
                        <div>
                            <div className="ac-search ac-small-sidepadding">
                                <div className="row q-gridbuilder small-margin ac-search grid gridfullwidth">
                                    <div className="small-12 medium-12 large-12 xlarge-12  grid-column-alignment-left small-margin">
                                        <Breadcrumb
                                            allCategoriesData={allCategoriesData}
                                            searchTerm={queryString.parse(this.props.location.search).searchTerm}
                                            selectedCategoryData={selectedCategoryData}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryId={selectedCategoryId}
                                            selectedSubcategoryData={selectedSubcategoryData}
                                            selectedSubcategory={selectedSubcategory}
                                            selectedSubcategoryId={selectedSubcategoryId}
                                            searchParams={categoryNavData.searchParams}
                                            vehicleConfig={this.props.sessionData.vehicleConfig}
                                            allCategoriesId={allCategoriesId}
                                            allCategoriesLabel={allCategoriesLabel}
                                        />
                                    </div>
                                    <NaturalLanguageSearch isAllProducts={this.props.isAllProducts} />
                                    {!swofFeatureFlag && !this.props.searchData.redirectToPartsResults && (
                                        <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left">
                                            <VehicleHeader
                                                year={this.props.sessionData.year}
                                                make={this.props.sessionData.make}
                                                model={this.props.sessionData.model}
                                                bodyAndWheel={this.props.sessionData.bodyAndWheel}
                                                bodyStyle={this.props.sessionData.bodyStyle}
                                                driveType={this.props.sessionData.driveType}
                                                wheelBase={this.props.sessionData.wheelBase}
                                                trim={this.props.sessionData.trim}
                                                engineBase={this.props.sessionData.engine}
                                                vehicleConfig={this.props.sessionData.vehicleConfig}
                                                showVehicleInfo={
                                                    this.props.searchData.searchResponse.recordSetTotal > 0
                                                }
                                                SWOFFlag={!swofFeatureFlag}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row q-gridbuilder small-margin ac-search gridfullwidth ac-results-wrapper">
                                    {!nlsSearch
                                        ? ((!searchData.showError && this.props.isAllProducts) || !swofFeatureFlag) &&
                                          !isMobile && (
                                              <div className="small-12 medium-12 large-12 xlarge-3 grid-column-alignment-left columns ac-nopadding-x ac-accordion-margin">
                                                  <SearchNav
                                                      categoryNavData={categoryNavData}
                                                      vehicleConfig={this.props.sessionData.vehicleConfig}
                                                  />
                                              </div>
                                          )
                                        : searchEngineResultsPageWithYmm &&
                                          !this.props.searchData.redirectToPartsResults &&
                                          swofFeatureFlag && (
                                              <div
                                                  className={
                                                      nlsNoResultsFound
                                                          ? "hide"
                                                          : "small-12 medium-12 large-12 xlarge-3 grid-column-alignment-left columns ac-nopadding-x ac-search-category"
                                                  }
                                              >
                                                  {!searchData.showError &&
                                                      searchData.searchResponse?.facetView?.map((facet, index) => {
                                                          if (facet.name !== "ParentCatalogGroup") {
                                                              if (facet.extendedData.displayable === "1") {
                                                                  const title = facet.extendedData.fname
                                                                      ? facet.extendedData.fname
                                                                      : facet.name.charAt(0).toUpperCase() +
                                                                        facet.name.slice(1);
                                                                  const type = facet.value;
                                                                  const facetEntryArray = facet.entry;
                                                                  return (
                                                                      <FacetSingleSelect
                                                                          key={index}
                                                                          facetView={
                                                                              searchData.searchResponse?.facetView
                                                                          }
                                                                          displaySequence={index}
                                                                          fitmentType={type}
                                                                          extendedData={facet.extendedData}
                                                                          accordionTitle={title}
                                                                          facetEntry={facetEntryArray}
                                                                          currentCategory={
                                                                              categoryNavData?.currentCategory?.uniqueId
                                                                          }
                                                                          facet={facet.entry}
                                                                          lastActiveFilter={this.state.lastActiveFilter}
                                                                          setLastActiveFilter={
                                                                              this.handleLastActiveFilter
                                                                          }
                                                                          setIsYearSelected={this.handleIsYearSelected}
                                                                          isYearSelected={this.state.isYearSelected}
                                                                          isSearchDataLoading={
                                                                              this.props.searchData
                                                                                  .searchResultsListIsLoading
                                                                          }
                                                                      />
                                                                  );
                                                              }
                                                          }
                                                      })}
                                              </div>
                                          )}
                                    <div
                                        className={
                                            "small-12 medium-12 large-12 columns ac-nopadding-x ac-search-results-list-wrapper xlarge-" +
                                            ((this.props.isAllProducts && !nlsSearch) ||
                                            (AppSettings.isT3 && !nlsSearch) ||
                                            (searchEngineResultsPageWithYmm && swofFeatureFlag)
                                                ? "9"
                                                : "12")
                                            // If nls and no vin, we want 9. if nls and a vin... we want 12
                                        }
                                    >
                                        {(!nlsSearch ||
                                            (nlsSearch && searchData.searchParams.searchMode == "VIN") ||
                                            this.props.searchData.redirectToPartsResults) &&
                                            vehicleData &&
                                            swofFeatureFlag && (
                                                <div>
                                                    {this.props.isAllProducts ? (
                                                        <section id="ac-ymm" className="ac-fitment">
                                                            {this.props.sessionData.model &&
                                                            this.props.sessionData.vehicleConfig.engineId &&
                                                            searchData.currentVehicleActive ? (
                                                                <CurrentVehicle
                                                                    currentVehicle={vehicleData}
                                                                    setShowCurrentVehicle={this.setShowCurrentVehicle}
                                                                />
                                                            ) : (
                                                                <VehicleSelectContainer headerLabel={headerLabel} />
                                                            )}
                                                        </section>
                                                    ) : (
                                                        <section className="ac-alt-bg search-current-vehicle">
                                                            {this.props.sessionData.model &&
                                                            this.props.sessionData.vehicleConfig.engineId &&
                                                            searchData.currentVehicleActive ? (
                                                                <CurrentVehicle
                                                                    currentVehicle={vehicleData}
                                                                    setShowCurrentVehicle={this.setShowCurrentVehicle}
                                                                />
                                                            ) : (
                                                                <VehicleSelectContainer headerLabel={headerLabel} />
                                                            )}
                                                        </section>
                                                    )}

                                                    {this.props.isAllProducts && isMobile && (
                                                        <SearchNav
                                                            categoryNavData={categoryNavData}
                                                            vehicleConfig={this.props.sessionData.vehicleConfig}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        {searchEngineResultsPageWithYmm &&
                                            swofFeatureFlag &&
                                            !this.props.searchData.redirectToPartsResults &&
                                            filterTags.length > 0 && (
                                                <div id="results-tag-section">
                                                    {nlsSearch && (
                                                        <SharedInputTags
                                                            tags={getFacetsArray(searchData.searchResponse?.facetView)}
                                                            clickHandler={this.tagHandler}
                                                            shouldCapitalize={false}
                                                            clearAllOption
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        <div className="ac-search-results-list-header-wrapper">
                                            {!this.props.searchData.searchResultsListIsLoading && nlsResultsCount}
                                            <div className="ac-search-results-list-header-sort">
                                                {this.props.searchData.catalogEntryView.length > 0 &&
                                                    !this.props.searchData.redirectToPartsResults && <SortBySelect />}
                                            </div>
                                        </div>
                                        <div className="small-12 columns ac-nopadding-x">
                                            {searchData.showError ? (
                                                <ErrorMessage message={t("LABEL_SEARCH_RESULTS_ERROR")} icon={true} />
                                            ) : (
                                                <React.Fragment>
                                                    {errorMessage &&
                                                        !this.props.searchData.searchResultsListIsLoading && (
                                                            <ErrorMessage message={t(errorMessage)} icon={true} />
                                                        )}

                                                    {this.props.searchData.redirectToPartsResults ? (
                                                        <PartsShopCards
                                                            vehicleCombinations={
                                                                this.props.vehicleFitment.vehicleCombinations
                                                            }
                                                            eSpotData={this.props.searchData.associationData}
                                                            vehicleInfo={this.props.sessionData}
                                                        />
                                                    ) : nlsNoResultsFound ? (
                                                        <div className="ac-no-results-wrapper">
                                                            <h2
                                                                id="no-results-header"
                                                                className="ac-no-results-header q-bold2"
                                                            >
                                                                {t("LABEL_SEARCH_NLS_NO_RESULTS", {
                                                                    searchTerm: queryString.parse(
                                                                        this.props.location.search
                                                                    ).searchTerm
                                                                })}
                                                            </h2>
                                                            <h5
                                                                id="let-us-help-header"
                                                                className="ac-let-us-help-header"
                                                            >
                                                                <b>{t("LABEL_SEARCH_NLS_NO_RESULTS_INFO1")}</b>
                                                            </h5>
                                                            <p
                                                                id="check-spelling-header"
                                                                className="ac-check-spelling-header"
                                                            >
                                                                {t("LABEL_SEARCH_NLS_NO_RESULTS_INFO2")}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {!this.props.searchData.searchResultsListIsLoading &&
                                                                this.props.searchData.searchResponse.metaData &&
                                                                this.props.searchData.searchResponse.metaData
                                                                    .spellcheck &&
                                                                this.props.searchData.searchResponse.metaData.spellcheck
                                                                    .length > 0 && (
                                                                    <div
                                                                        id="ac-misspelling-wrapper"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: t("LABEL_SEARCH_NLS_MISSPELLING", {
                                                                                searchTerm: scrubFormField(
                                                                                    queryString.parse(
                                                                                        this.props.location.search
                                                                                    ).searchTerm
                                                                                ),
                                                                                searchTermCorrection: scrubFormField(
                                                                                    mispellingsFormat(
                                                                                        this.props.searchData
                                                                                            .searchResponse.metaData
                                                                                            .spellcheck[0]
                                                                                    )
                                                                                )
                                                                            })
                                                                        }}
                                                                    ></div>
                                                                )}
                                                            <SearchResultsList
                                                                t={t}
                                                                categoryNavData={categoryNavData}
                                                                searchData={this.props.searchData}
                                                                vehicleConfig={this.props.sessionData.vehicleConfig}
                                                                bodyStyleName={this.props.sessionData.bodyStyleName}
                                                                trimName={this.props.sessionData.trimName}
                                                                driveTypeName={this.props.sessionData.driveTypeName}
                                                                wheelBaseName={this.props.sessionData.wheelBaseName}
                                                                searchTerm={
                                                                    queryString.parse(this.props.location.search)
                                                                        .searchTerm
                                                                }
                                                                viewOnVehicleData={this.props.viewOnVehicleData}
                                                            />
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Translation>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        categoryNavData: state.CategoryNavReducer,
        searchData: state.SearchServiceReducer,
        vehicleSelectData: state.VehicleSelectReducer,
        reduxFormData: state.form,
        searchLinkParams: state.SearchLinkReducer,
        sessionData: state.Session,
        viewOnVehicleData: state.VoVReducer,
        vehicleFitment: state.FitmentCheckReducer
    };
};

export default withRouter(connect(mapStateToProps)(Search));
