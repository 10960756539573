import axios from 'axios';

import AppSettings from '../../../core/AppSettings';

const gmOrderHistoryResponseURL = "/wcs/resources/store/" + AppSettings.storeId + "/order/@history";
const getOrderDataUrl = "/wcs/resources/store/" + AppSettings.storeId + "/GMOrder/getOrderData/" + AppSettings.orderId;
const getPaymentInfoUrl = "/wcs/resources/store/" + AppSettings.storeId + "/cart/payment/info/" + AppSettings.orderId;
const gmProfileInfoURL = "";

/** REST CALL TO GET ORDER DATA **/
export const getOrderDataResponse = () => {
  return axios.get(getOrderDataUrl)
      .then((response) => {return response.data; } , (error) => { throw "GET PERSONAL INFO DATA ERROR: " + error; })  
};

/** REST CALL TO PAYMENT INFO DATA **/
export const getPaymentInfoResponse = () => {
    return axios.get(getPaymentInfoUrl)
        .then((response) => {return response.data; })  
  };

/** REST CALL TO GET ORDER HISTORY DATA **/
export const getOrderHistoryResponse = ()=>{
    return axios.get(gmOrderHistoryResponseURL).then((response) => { return response.data; }, (error) => { throw "GET ORDERHISTORY DATA ERROR: " + error; });
};

//TODO integrate with C360 Profile API
export const getPersonalInfo = ()=>{
    return axios.get(gmProfileInfoURL).then((response) => { return response.data; }, (error) => { throw "GET PERSONAL INFO DATA ERROR: " + error; });
};