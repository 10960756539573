import React from 'react';
import i18next from 'i18next';

function HistoryOrderSummary(props) {
    const { orderInfo, shippingInfo } = props;
    const shippingActive = orderInfo.fedEx > 0;
    return (
        <div className="ac-history-order-summary small-12 grid-column-alignment-left columns ac-nopadding-x">
            <h2 className="q-headline2">{i18next.t('LABEL_RECEIPT_ORDER_SUMMARY')}</h2>
            <div className="ac-history-order-summary-line small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="small-6 grid-column-alignment-left columns ac-nopadding-x">
                    {i18next.t('LABEL_COMMON_SUBTOTAL')} ( {orderInfo.recordSetTotal} {orderInfo.recordSetTotal > 1 ? i18next.t('LABEL_COMMON_ITEMS') : i18next.t('LABEL_COMMON_ITEM')} )
                            </div>
                <div className="small-6 grid-column-alignment-right columns ac-nopadding-x">
                    {orderInfo.formattedSubtotal}
                </div>
            </div>
            {/*orderInfo.adjustment.map(function (adjustment, i) {
                            //TODO -- need to do some investigation here to determine what adjustments should/shouldn't show
                            return (
                                <div className="ac-history-order-summary small-12 grid-column-alignment-left columns ac-nopadding-x">

                                </div>
                            )
                        })
                    */}
            {
                //TODO maybe these should be their own components?
                shippingActive &&
                <div className="ac-history-order-summary-line small-12 grid-column-alignment-left columns ac-nopadding-x">
                    <div className="small-6 grid-column-alignment-left columns ac-nopadding-x">
                        {i18next.t('LABEL_COMMON_SHIPPING')}
                    </div>
                    <div className="small-6 grid-column-alignment-right columns ac-nopadding-x">
                        {orderInfo.formattedFedExAmmount}
                    </div>
                </div>
            }
            <div className="ac-history-order-summary-line small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="small-6 grid-column-alignment-left columns ac-nopadding-x">
                    {i18next.t('LABEL_COMMON_TAX')}
                    <div className="ac-history-order-summary-zip">
                        {i18next.t('LABEL_ORDER_HISTORY_BASED_ON_ZIP_CODE')} <div>( {shippingInfo.zipcode} )   </div>
                    </div>
                </div>
                <div className="small-6 grid-column-alignment-right columns ac-nopadding-x">
                    {orderInfo.formattedTotalTax}
                </div>
            </div>
            <hr></hr>
            <div className="ac-history-order-summary-line small-12 grid-column-alignment-left columns ac-nopadding-x">
                <div className="small-6 grid-column-alignment-left columns ac-nopadding-x q-body1">
                    {i18next.t('LABEL_ORDER_HISTORY_TOTAL_PRICE')}
                </div>
                <h1 className="q-headline1 small-6 grid-column-alignment-right columns ac-nopadding-x">
                    {orderInfo.formattedTotal}
                </h1>
            </div>

        </div>
    )
}

export default HistoryOrderSummary