import React, { useState, useEffect } from "react";
import { Translation, Trans, useTranslation } from "react-i18next";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import Button from "../Button/Button";
import EnrollmentModal from "../Authentication/EnrollmentModal/EnrollmentModal";
import { applyCoupon, getCouponTiles, removeAppliedCoupon, getPromotionsTiedToUser } from "./CouponsBannerService";
import { getBaseMarketingSpotActivityData, isValidValue } from "../Validator/Validator";
import {
    useFooterDisclaimerLabel,
    useReplaceAllNamedDisclaimers
} from "../../modules/App/Footer/FooterDisclaimerLabel";
import AppSettings from "../../core/AppSettings";
import FieldErrorMessage from "../FieldErrorMessage/FieldErrorMessage";
import { authenticate } from "../Authenticator/Authenticator";
import accHistory from "../../modules/App/History";
import store from "../../core/Redux/Store";
import { setMiniCartOrderId } from "../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartCreator";
import LeavingSiteModal from "../Modal/LeavingSiteModal/LeavingSiteModal";
import { fetchCartData } from "../../modules/Cart/Redux/CartHandler";
import { updateCouponBanner } from "../../modules/Cart/Redux/CartActionCreator";

function CouponBanner(props) {
    const { t } = useTranslation();
    const fontIconPlus = faPlus;
    const fontIconMinus = faMinus;
    const authData = props.authenticationData;
    const rewardsData = props.rewardsData;
    const firstTimeUserKey = "isFirstTimeUser";
    const couponBannerTimeout = process.env.REACT_APP_COUPON_BANNER_AUTO_OPEN_TIMEOUT;
    const dispatch = useDispatch();
    const checkCouponBanner = useSelector((state) => state.CartReducer.checkCouponBanner);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalId, setModalId] = useState(document.getElementById(""));
    const [bannerId, setBannerId] = useState(document.getElementById(""));
    const [firstTimeUserLogic, setFirstTimeUserLogic] = useState(
        localStorage.getItem("isFirstTimeUser") ? localStorage.getItem("isFirstTimeUser") : ""
    );
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
    const [couponOffers, setCouponOffers] = useState([]);
    const [numOfCoupons, setNumOfCoupons] = useState(0);
    const [rewardsEnrollContent, setRewardsEnrollContent] = useState("");
    const [rewardsApplyNowContent, setRewardsApplyNowContent] = useState("");
    const [loyaltyAndTierContent, setLoyaltyAndTierContent] = useState("");
    const [defaultRewardsContent, setDefaultRewardsContent] = useState([]);
    const [applyCouponSpinner, setApplyCouponSpinner] = useState({ isLoading: true, index: "" });
    const [modalVisibility, setModalVisibility] = useState(false);
    const couponDisclaimer = process.env.REACT_APP_DISCLAIMER_CHECKOUT_COUPON;
    const couponLabel = useFooterDisclaimerLabel(couponDisclaimer);
    const [scrollDistanceFromTop, setScrollDistanceFromTop] = useState(0);
    const [duplicateCouponCheck, setDuplicateCouponCheck] = useState(false);
    // get correct superscript label for the loyalty account disclaimer referenced in flyout and update flyout text

    //set correct superscript label for disclaimers with rewards based tiles
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();
    const createMarkup = (text) => {
        return {
            __html: text
        };
    };

    const rewardsDisclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
    const rewardsLabel = useFooterDisclaimerLabel(rewardsDisclaimer);
    const earnDisclaimer = process.env.REACT_APP_DISCLAIMER_EARN_NAME;
    const earnLabel = useFooterDisclaimerLabel(earnDisclaimer);
    const rewardsEnrollContentFinal = rewardsEnrollContent && replaceDisclaimers(rewardsEnrollContent);
    const rewardsApplyNowContentFinal =
        rewardsApplyNowContent && rewardsApplyNowContent.replace("<sup>3</sup>", "<sup>" + rewardsLabel + "</sup>");
    const loyaltyAndTierContentFinal =
        loyaltyAndTierContent && parseInt(rewardsData.totalPoints) > 0 && replaceDisclaimers(loyaltyAndTierContent);

    const [couponError, setCouponError] = useState(null);
    const [couponsSelected, setCouponsSelected] = useState([]);

    //firstTimeLocalStorageValue is undefined if a firstTime user enters and it cannot find the key 'isFirstTimeUser'
    //keeping this variable to avoid too many re-render errors
    const firstTimeLocalStorageValue = localStorage.getItem(firstTimeUserKey)
        ? localStorage.getItem(firstTimeUserKey)
        : "";

    //modal styling that is sent to the <Modal component
    const modalStyling = {
        modal: "ac-coupon-banner-modal",
        overlay: "ac-coupon-banner-overlay"
    };

    useEffect(() => {
        if (authData.registrationStatusFetched) {
            getEspotCoupons();
            if (
                firstTimeLocalStorageValue === "" &&
                AppSettings.pageName === "HomePage" &&
                !(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT)
            ) {
                setModalOpen(true);
                setModalId(document.getElementById("coupon-banner-body"));
            }
        }
    }, [authData.registrationStatusFetched, authData.userData, duplicateCouponCheck]);

    useEffect(() => {
        if (authData.registrationStatusFetched && checkCouponBanner) {
            getEspotCoupons();
            dispatch(updateCouponBanner(false));
        }
    }, [checkCouponBanner]);

    useEffect(() => {
        setModalId(document.getElementById("coupon-banner-body"));
        setBannerId(document.getElementById("ac-coupon-banner"));

        if (
            firstTimeUserLogic === "" &&
            modalId &&
            AppSettings.pageName === "HomePage" &&
            !(window.innerWidth < AppSettings.MEDIUM_BREAKPOINT)
        ) {
            timeoutLogic(modalId);
        }
    }, [document.getElementById("coupon-banner-body"), modalOpen, modalId]);

    const getEspotCoupons = async () => {
        try {
            //clears the values as the getEspotCoupons function may be called twice
            // on initial login to grab correct content from espot
            const couponsObject = [];
            const defaultRewardsObject = [];
            setRewardsEnrollContent("");
            setRewardsApplyNowContent("");
            setLoyaltyAndTierContent("");
            const response = await getCouponTiles();
            const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(response.data, 0);

            if (baseMarketingSpotActivityData && baseMarketingSpotActivityData.length > 0) {
                baseMarketingSpotActivityData.forEach(function (espot) {
                    let promotionId = "";
                    let couponName = "";
                    let couponCode = "";
                    let couponDescription = "";
                    let couponEndDate = "";

                    espot.properties.forEach(function (value) {
                        if (value.baseMarketingKey === "PromotionId") {
                            promotionId = value.baseMarketingValue;
                        }
                        if (value.baseMarketingKey === "couponName") {
                            couponName = value.baseMarketingValue;
                        }
                        if (value.baseMarketingKey === "couponCode") {
                            couponCode = value.baseMarketingValue;
                        }
                        if (value.baseMarketingKey === "couponDescription") {
                            couponDescription = value.baseMarketingValue;
                        }
                        if (value.baseMarketingKey === "couponEndDate") {
                            couponEndDate = value.baseMarketingValue;
                        }
                    });
                    promotionId &&
                        couponsObject.push({
                            promotionId: promotionId,
                            couponName: couponName,
                            couponCode: couponCode,
                            couponDescription: couponDescription,
                            couponEndDate: couponEndDate
                        });

                    //this only enters if the content is NOT a coupon. This will be a rewards content or any other content that business decides to add
                    if (!promotionId) {
                        let showEnrollButtonTile =
                            espot.baseMarketingSpotActivityName &&
                            (espot.baseMarketingSpotActivityName.toLowerCase().includes("guest") ||
                                espot.baseMarketingSpotActivityName.toLowerCase().includes("button")) &&
                            espot.marketingContentDescription[0].marketingText.toLowerCase().includes("enroll");
                        let showRewardsButtonTile =
                            espot.baseMarketingSpotActivityName &&
                            espot.baseMarketingSpotActivityName.toLowerCase().includes("gm card");

                        if (espot.marketingContentDescription[0].marketingText) {
                            //The UI will only show one tile that has the Enroll button to it
                            if (showEnrollButtonTile) {
                                setRewardsEnrollContent(espot.marketingContentDescription[0].marketingText);
                            } else if (showRewardsButtonTile) {
                                setRewardsApplyNowContent(espot.marketingContentDescription[0].marketingText);
                            } else if (
                                espot.baseMarketingSpotActivityName === "Loyalty Point and Tier Status Content"
                            ) {
                                setLoyaltyAndTierContent(espot.marketingContentDescription[0].marketingText);
                            } else if (espot.baseMarketingSpotActivityName === "Loyalty Card Member Content") {
                                setLoyaltyAndTierContent(espot.marketingContentDescription[0].marketingText);
                            } else {
                                //this will allow multiple default content tiles that business wishes to add in without a button
                                defaultRewardsObject.push({
                                    defaultRewardsContent: espot.marketingContentDescription[0].marketingText
                                });
                            }
                        }
                    }
                });
                setCouponOffers(couponsObject);
                setNumOfCoupons(couponsObject.length);
                setDefaultRewardsContent(defaultRewardsObject);
            }

            ////************************************************************************************
            ///Get already applied Promotions tied to any user that enters page. Will not get called for first time users or if the espot data returns no coupons.
            if (couponsObject && couponsObject.length > 0 && firstTimeLocalStorageValue !== "") {
                let alreadyAppliedPromos = [];
                const response = await getPromotionsTiedToUser();
                if (response.status !== 200 || response.data.errors) {
                    console.log("Error retrieving promotions tied to user");
                } else {
                    //this is important to set the orderId if an orderId does not exist for when user applies a coupon to an empty order,
                    //it is needed for when user goes to add their first item to cart to avoid /GMCart from creating a new order
                    if (response.data.orderId && !isValidValue(store.getState().MiniCartReducer.orderId)) {
                        store.dispatch(setMiniCartOrderId(response.data.orderId));
                    }
                    //this will set the applied promos as 'checked' in the coupon banner
                    response.data.appliedPromotions &&
                        response.data.appliedPromotions.map((data, index) => {
                            if (data.promotionId) {
                                alreadyAppliedPromos.push(data.promotionId);
                            }
                        });
                    setCouponsSelected(alreadyAppliedPromos);
                }
            }
        } catch (e) {
            console.warn(e);
        }
    };

    //redirects user depending on enrollment status
    const enrollButtonRedirectLogic = () => {
        if (authData.registrationStatus === "R" && authData.enrolledAttributeValue === "0") {
            setShowEnrollmentModal(true);
        } else {
            accHistory.push("/register");
        }
    };

    //only shows up when the user is authenticated but not enrolled, if user clicks rewards tile
    const enrollModal = (
        <EnrollmentModal
            covisIntId={props.authenticationData.userData.logonId}
            open={showEnrollmentModal}
            onClose={setShowEnrollmentModal}
        />
    );

    const applyButton = async (index, promoId, promoCode, authenticateCalled) => {
        let updatedCouponArray = [];
        //Resetting error so it disappears
        setCouponError(null);
        setApplyCouponSpinner({ isLoading: true, index: index });

        //checks to see if user wishes to apply/remove coupon
        const removeCoupon = couponsSelected.includes(promoId);

        if (removeCoupon) {
            try {
                const response = await removeAppliedCoupon(promoCode);
                if (response.status !== 200) {
                    setCouponError({ type: "remove", index: index });
                } else {
                    //this cleans up the array and updates it to removes the promoCode that has been selected (un-apply coupon)
                    updatedCouponArray = couponsSelected.filter((promo) => promo !== promoCode);
                    setCouponError(null);
                    setCouponsSelected(updatedCouponArray);
                }
            } catch (e) {
                console.log("There was an error removing the coupon, error =>", e);
            } finally {
                setApplyCouponSpinner({ isLoading: false, index: index });
            }
        } else {
            try {
                const response = await applyCoupon(promoCode);

                if (
                    response.status !== 200 ||
                    response.data.Message ||
                    response.data.errors ||
                    (authenticateCalled && response.data.appliedPromotions.length === 0)
                ) {
                    // Uses the duplicate coupon error to rerender the coupon banner and data, as it happens when user has multiple browsers
                    // and is trying to apply a coupon from an older browser session.
                    // Also will show applying a duplicate coupon as just applied once with no error message.
                    if (response.data.Message === "Duplicate Coupon Code") {
                        setDuplicateCouponCheck(!duplicateCouponCheck);
                    } else {
                        setCouponError({ type: "apply", index: index });
                    }
                } else if (
                    response.data.appliedPromotions.length === 0 &&
                    !response.data.orderId &&
                    !authenticateCalled
                ) {
                    callAuthenticate(index, promoId, promoCode, true);
                } else {
                    //sets the updatedCouponArray to filter through the response to then set it for the setCouponsSelected hook so that the UI can show its been applied
                    response.data.appliedPromotions &&
                        response.data.appliedPromotions.length > 0 &&
                        response.data.appliedPromotions.map((promo, index) => {
                            updatedCouponArray.push(promo.promotionId);
                        });
                    setCouponError(null);
                    setCouponsSelected(updatedCouponArray);
                }
            } catch (e) {
                console.log("There was an error applying the coupon, error =>", e);
            } finally {
                setApplyCouponSpinner({ isLoading: false, index: index });
            }
        }
        fetchCartData(dispatch);
        //setUpdateCouponBanner(true);
    };

    const callAuthenticate = async (index, promoId, promoCode, authenticateCalled) => {
        await authenticate();
        applyButton(index, promoId, promoCode, authenticateCalled);
    };

    const timeoutLogic = (modalId) => {
        // setting this to the now set local storage value so the timeoutLogic function does not get called more than once
        setFirstTimeUserLogic(localStorage.getItem(firstTimeUserKey));
        let timeout = setTimeout(() => {
            setModalOpen(false);
            localStorage.setItem(firstTimeUserKey, "false");
        }, couponBannerTimeout);

        if (modalId) {
            modalId.onclick = function () {
                clearTimeout(timeout);
                localStorage.setItem(firstTimeUserKey, "false");
            };
        }
        //this avoids the banner from closing if the user selects the banner on
        // first time to close it but decides to open it back up again within the set timeout
        if (bannerId) {
            bannerId.onclick = function () {
                clearTimeout(timeout);
                localStorage.setItem("isFirstTimeUser", "false");
            };
        }
    };

    //Sets couponError hook to proper value if error exists
    const checkCouponError = (index) => {
        if (couponError) {
            if (couponError.index == index) {
                if (couponError.type == "apply") {
                    return (
                        <Translation>
                            {(t) => (
                                <div className="ac-error-message">
                                    <FieldErrorMessage showError={true} message={t("LABEL_COUPON_APPLY_ERROR")} />
                                </div>
                            )}
                        </Translation>
                    );
                } else {
                    return (
                        <Translation>
                            {(t) => (
                                <div className="ac-error-message">
                                    <FieldErrorMessage showError={true} message={t("LABEL_COUPON_REMOVE_ERROR")} />
                                </div>
                            )}
                        </Translation>
                    );
                }
            }
        }
    };

    // ------------------------- Tile(s) Render -------------------------------------------------------------------------------------------------------------
    const couponTiles = (
        <Translation>
            {(t) =>
                couponOffers &&
                couponOffers.length > 0 &&
                couponOffers.map((offer, index) => {
                    return (
                        <div className="ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll " key={index}>
                            <h3 className="ac-coupon-code-name text-center">
                                <div id="coupon-title" className="ac-scroller-title">
                                    <b>{offer.name ? offer.name : offer.couponName}</b>
                                    <sup>{couponLabel}</sup>
                                </div>
                            </h3>
                            <div className="ac-promo-conditions-margin">
                                <h4>
                                    {t("LABEL_COUPON_PROMO_CODE")} {offer.couponCode}
                                </h4>
                                <ul className={"q-text q-body2 ac-coupon-conditions-margin"}>
                                    {offer.couponEndDate && (
                                        <li> {t("LABEL_LOYALTY_VOUCHER_EXPIRATION_DATE") + offer.couponEndDate}</li>
                                    )}
                                    {offer.couponDescription && (
                                        <li>
                                            <p className="ac-scroller-description">{offer.couponDescription}</p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <Button
                                type="button"
                                className="ac-error-clearance"
                                dataDtm={`global coupon banner:${offer.name ? offer.name : offer.couponName}`}
                                isLoading={applyCouponSpinner.isLoading && applyCouponSpinner.index === index}
                                localeKey={
                                    couponsSelected.includes(offer.promotionId)
                                        ? "✓ " + t("LABEL_COUPON_APPLIED")
                                        : t("LABEL_COUPON_APPLY")
                                }
                                onClick={() => applyButton(index, offer.promotionId, offer.couponCode)}
                            />
                            {checkCouponError(index)}
                        </div>
                    );
                })
            }
        </Translation>
    );

    const rewardsTile = rewardsEnrollContent && (
        <Translation>
            {(t) => (
                <div
                    id="enroll-rewards-content-tile"
                    className="ac-rewards-tile ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll"
                    style={{ cursor: "pointer" }}
                >
                    <div className="ac-espot-content-rewards-center">
                        <div dangerouslySetInnerHTML={createMarkup(rewardsEnrollContentFinal)} />
                    </div>
                    <div className="ac-rewards-button-padding-top">
                        <Button
                            id="enroll-rewards-button"
                            type="button"
                            dataDtm={"global coupon banner:rewards"}
                            localeKey={t("LABEL_CONFIRMATION_ENROLL_LOWERCASE")}
                            onClick={() => enrollButtonRedirectLogic()}
                        />
                    </div>
                </div>
            )}
        </Translation>
    );
    let loyaltyAndTierTile = null;
    //Checking for new users with 0 points to not show the amount they can redeem as they have none
    if (loyaltyAndTierContent && parseInt(rewardsData.totalPoints) > 0) {
        loyaltyAndTierTile = (
            <Translation>
                {(t) => (
                    <div
                        id="apply-rewards-content-tile"
                        className="ac-rewards-tile ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll"
                    >
                        <div className="ac-espot-content-loyalty">
                            <div dangerouslySetInnerHTML={createMarkup(loyaltyAndTierContentFinal)} />
                        </div>
                    </div>
                )}
            </Translation>
        );
    } else if (loyaltyAndTierContent && (!rewardsEnrollContentFinal || !rewardsApplyNowContentFinal)) {
        loyaltyAndTierTile = (
            //This will show the user with 0 points a different message telling them they can earn points
            <div
                id="apply-rewards-content-tile"
                className="ac-rewards-tile ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll"
            >
                <div className="ac-espot-content-loyalty">
                    <h3
                        dangerouslySetInnerHTML={createMarkup(
                            t("LABEL_ACCOUNT_MY_REWARDS_COUPON_BANNER", {
                                brand: AppSettings.currentSite.label,
                                label: rewardsLabel
                            })
                        )}
                    />
                    <h4
                        dangerouslySetInnerHTML={createMarkup(
                            t("REWARDS_COUPON_REDEEM_NO_POINTS_NO_REDEEMABLE_DOLLARS", { earnLabel })
                        )}
                    />
                </div>
            </div>
        );
    }

    const gmCardApplyTile = rewardsApplyNowContent && (
        <Translation>
            {(t) => (
                <div
                    id="apply-rewards-content-tile"
                    className="ac-rewards-tile ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll"
                >
                    <div className="ac-espot-content-rewards-card">
                        <div dangerouslySetInnerHTML={createMarkup(rewardsApplyNowContentFinal)} />
                    </div>
                    <div className="ac-rewards-button-padding-top">
                        <Button
                            id="apply-rewards-button"
                            type="button"
                            dataDtm={"global coupon banner:gm card"}
                            localeKey={t("LABEL_COMMON_APPLY_TODAY")}
                            onClick={() => closeModals()}
                        />
                    </div>
                </div>
            )}
        </Translation>
    );

    const defaultRewardsContentTile = (
        <Translation>
            {(t) =>
                defaultRewardsContent &&
                defaultRewardsContent.length > 0 &&
                defaultRewardsContent.map((content, index) => (
                    <div
                        className="ac-rewards-tile ac-banner-offer-box stat-button-link ac-coupon-modal-apply-enroll"
                        key={index}
                    >
                        <div className="ac-espot-content-rewards-center">
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    content.defaultRewardsContent.replace(
                                        "<sup>2</sup>",
                                        "<sup>" + rewardsLabel + "</sup>"
                                    )
                                )}
                            />
                        </div>
                    </div>
                ))
            }
        </Translation>
    );

    // --------------------- Interstital leaving site modal ----------------------------------------------------------------------------------------------
    const leavingSiteModal = <LeavingSiteModal open={modalVisibility} onClose={() => closeSiteModal()} />;

    const closeModals = () => {
        setModalVisibility(true);
    };

    const closeSiteModal = () => {
        setModalOpen(true);
        setModalVisibility(false);
    };

    // ------------------------- Final Render -------------------------------------------------------------------------------------------------------------
    const prepareModalOpen = () => {
        let scrollDown =
            window.pageYOffset !== undefined
                ? window.pageYOffset
                : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        setScrollDistanceFromTop(scrollDown);
        setModalOpen(!modalOpen);
    };

    if (authData.registrationStatusFetched && !AppSettings.isT3) {
        if (couponOffers && couponOffers.length > 0) {
            return (
                <Translation>
                    {(t) => (
                        <div id="ac-coupon-banner">
                            <Button
                                type="button"
                                className={"stat-expand-icon"}
                                dataDtm={"global coupon banner"}
                                disabled={false}
                                localeKey={t("LABEL_COUPON_BANNER_TITLE", { numberOfDiscounts: numOfCoupons })}
                                onClick={() => prepareModalOpen()}
                                isLoading={false}
                                isSecondary={true}
                                fontAwesomeRender={modalOpen ? fontIconMinus : fontIconPlus}
                                fontAwesomeSize={"lg"}
                            />
                            <Modal
                                classNames={modalStyling}
                                open={modalOpen}
                                onClose={() => setModalOpen(false)}
                                closeOnOverlayClick={true}
                                showCloseIcon={false}
                                focusTrapped={false}
                                marginTop={-scrollDistanceFromTop}
                            >
                                <div id="coupon-banner-body" className="ac-banner-coupon-modal ac-grid-style-coupons">
                                    {/* Coupon Offer Boxes */}
                                    {couponOffers && couponTiles}
                                    {/*Rewards Tile after Coupons*/}
                                    {rewardsTile}
                                    {defaultRewardsContent.length > 0 && defaultRewardsContentTile}
                                    {gmCardApplyTile}
                                    {gmCardApplyTile && leavingSiteModal}
                                    {enrollModal}
                                    {loyaltyAndTierContent && loyaltyAndTierTile}
                                </div>
                            </Modal>
                        </div>
                    )}
                </Translation>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        authenticationData: state.Authentication,
        rewardsData: state.RewardsReducer
    };
};

export default connect(mapStateToProps)(CouponBanner);
