import * as type from './AccountCreationActionType';

const initialState = {
    email: null
}

const AccountCreationReducer = (state = initialState, action) => {

    switch (action.type) {

        case type.SET_CREATE_ACCOUNT_EMAIL:
            return {
                ...state,
                email: action.email
            }

        default: return state;
    }

}

export default AccountCreationReducer