/*** COMPONENT NAME : ZIPCODEINPUT (input to change and update the zip code) **/


/*** IMPORT STATEMENT ***/
import React from 'react';
import { connect } from 'react-redux';
import { validateInput, updateDealerSearch, updateInputValue, checkError } from '../../DealerLocatorRedux/DealerLocatorActionCreators';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { withTranslation } from 'react-i18next';



const ZipCodeInputComponent = (props) => {
    const propsData = props.DealerLocatorReducer;
    const showError = propsData.showError;
    const errorMessage = propsData.errorMessage;

    if (showError) {
        setTimeout(() => {
            const scrollElement = document.getElementById('zipCodeError');
            if (scrollElement !== undefined && scrollElement !== null) {
                scrollElement.scrollIntoView({ behavior: "smooth" })
            }

        }, 100);

    }
    return (<Translation>
        {
            (t) => (<div className="ac-zipCodeComponent">

                {
                    showError && <div className="ac-zipCodeErrorMessage" id="zipCodeError">
                        <ErrorMessage icon={true} message={t(errorMessage)} />
                    </div>
                }

                <div className="ac-zipCodeLabel">
                    <label className="q-label2">{t("LABEL_COMMON_ZIP")}</label>
                </div>

                <div className="q-search-field">
                    <input type="text" value={props.zipCode} onChange={props.zipCodeInputChange} placeholder={t("LABEL_CHECKOUT_ENTER_ZIP")} className="stat-search-input" data-dtm="checkout step2:locate your dealer" />
                    <button onClick={props.changeDealer} className="button q-icon-search stat-search-submit" onClick={props.changeDealer} data-dtm="checkout step2:locate your dealer"></button>
                </div>



            </div>
            )
        }
    </Translation>
    )
};

/** MAP STATE TO PROPS **/
const mapStateToProps = (state) => state;



/*** MAP DISPATCH TO PROPS
 * changeDealer :UPDATE THE DEALER LIST BASED ON THE NEW ZIP CODE PROVIDED
 *
 * zipCodeInputChange: TRACK ALL CHANGES INSIDE THE INPUT TAG
 *
 * ***/

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        changeDealer: () => {
            dispatch(updateDealerSearch(ownProps))

        },
        zipCodeInputChange: (e) => {
            let value = e.currentTarget.value;

            if (value.length > 6) {
                let tempValue = value.split('-');
                value = tempValue[0];
            }


            const val = validateInput(value);
            const lengthOfVal = val.length;

            if (lengthOfVal < 6) {
                dispatch(updateInputValue(val));

                dispatch(checkError(val));
            }

        }
    }
};

/*** PROP TYPES VALIDATION ***/
ZipCodeInputComponent.propTypes = {
    zipCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
};


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ZipCodeInputComponent));