import * as type from './SearchDataServiceActionTypes';

//TODO handle vin
let initialState = {
    searchResultsListIsLoading: true,
    nextPageIsLoading: false,
    catalogEntryView: [],
    searchResponse: {

    },
    searchParams: {
        year: "",
        make: "",
        model: "",
        vin: "",
        bodyStyle: "",
        wheelBase: "",
        trim: "",
        driveType: "",
        orderBy: "",
        categoryId: "",
        searchMode: ""
    },
    fullSearchResponse: {

    },
    fullSearchParams: {
        year: "",
        make: "",
        model: "",
        vin: "",
        bodyStyle: "",
        wheelBase: "",
        trim: "",
        driveType: "",
        searchMode: ""
    },
    pageNumber: 1,
    currentPage: 1,
    showError: false,
    showInvalidSearchError: false,
    error: "",
    redirectToPartsResults: false,
    searchCallLock: false,
    associationData: {},
    isShopWithoutFitmentActive: false,
    currentVehicleActive: true,
    currentUrlParams: {},

    // VOV Values
    vov: { searchResultPartsRPO: "", searchResultColorCode: "" }
}

const SearchDataServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.UPDATE_SEARCH_DATA: {
            if (action.payload.response && action.payload.params) {
                return Object.assign({}, state, {
                    searchResultsListIsLoading: false,
                    searchResponse: action.payload.response,
                    searchParams: action.payload.params,
                    pageNumber: state.pageNumber,
                    showError: false,
                    showInvalidSearchError: false
                });
            }
            else {
                return Object.assign({}, state, action.payload);
            }
        }
        case type.UPDATE_CATEGORY_DATA: {
            if (action.payload.response && action.payload.params) {
                return Object.assign({}, state, {
                    fullSearchResponse: action.payload.response,
                    fullSearchParams: action.payload.params
                });
            }
            else {
                return Object.assign({}, state, action.payload);
            }
        }
        case type.LIST_DATA_ASYNC: {
            return Object.assign({}, state, { searchResultsListIsLoading: true, showError: false, showInvalidSearchError: false, redirectToPartsResults: false });
        }
        case type.LIST_DATA_IN_PAGELOAD: {
            return Object.assign({}, state, action.payload, { searchResultsListIsLoading: false, nextPageIsLoading: false, showError: false, showInvalidSearchError: false, searchCallLock: false });
        }
        case type.LOAD_MORE: {
            let currentPage = 1;
            if (action.pageNumber) {
                currentPage = action.pageNumber + 1;
            }
            return Object.assign({}, state, { pageNumber: state.pageNumber + 1, currentPage: currentPage, nextPageIsLoading: true, showInvalidSearchError: false });
        }
        case type.RESET_PAGE_NUMBER: {
            return Object.assign({}, state, { pageNumber: 1 });
        }
        case type.LIST_DATA_INVALID_SEARCH: {
            return { ...state, error: action, showInvalidSearchError: true, searchResultsListIsLoading: false, nextPageIsLoading: false, searchCallLock: false };
        }
        case type.LIST_DATA_IN_PAGELOAD_FAILED: {
            return { ...state, error: action, showError: true, searchResultsListIsLoading: false, nextPageIsLoading: false, showInvalidSearchError: false.valueOf, searchCallLock: false };
        }
        case type.UPDATE_SEARCH_RESULT_WITH_VOV_DATA:
            return Object.assign({}, state, {
                searchResponse: action.searchResponse,
                vov: { searchResultPartsRPO: action.partsRPO, searchResultColorCode: action.colorCode }
            });
        case type.UPDATE_FULL_SEARCH_WITH_VOV_DATA:
            return Object.assign({}, state, {
                fullSearchResponse: action.fullSearchResponse,
                vov: { searchResultPartsRPO: action.partsRPO, searchResultColorCode: action.colorCode }
            })
        case type.NLS_LOAD_SPINNER:
            return Object.assign({}, state, {
                searchResultsListIsLoading: action.searchResultsListIsLoading
            })
        case type.REDIRECT_TO_PARTS_TILES:
            return Object.assign({}, state, {
                searchResultsListIsLoading: false,
                redirectToPartsResults: true,
                showError: false,
                associationData: action.associationData
            })
        case type.LOCK_SEARCH:
            return Object.assign({}, state, {
                searchCallLock: action.searchCallLock,
                currentUrlParams: action.currentUrlParams
            })
        case type.SET_SHOP_WITHOUT_FITMENT:
            return Object.assign({}, state, {
                isShopWithoutFitmentActive: action.isShopWithoutFitmentActive
            })
        case type.SET_CURRENT_VEHICLE_ACTIVE:
            return Object.assign({}, state, {
                currentVehicleActive: action.currentVehicleActive
            })
        default:
            return state;
    }

}

export default SearchDataServiceReducer;