import React from "react";
import ErrorBoundary from "../../shared/ErrorBoundary/ErrorBoundary";
import Search from "./Search";

/**This component will serve as the component to be rendered in the App.js routing. 
 * The main purpose of this is to give the Search component the isAllProducts prop set to false prop when we are routing to /product/search
 *
 * @param {*} 
 * @returns Search component
 *
 */
const ProductSearchResultsContainer = () => {
    return (
        <ErrorBoundary>
            <Search isAllProducts={false} />
        </ErrorBoundary>
    );
};

export default ProductSearchResultsContainer;
