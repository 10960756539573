import axios from "axios";
import AppSettings from "../../core/AppSettings";
/**************************************************************/
//                  CAROUSEL SERVICES
/**************************************************************/
// NOTES:
// - Add multiple carousel services below

export const getMarketingCarouselData = () => {
    const url = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Homepage_Hero";
    return axios.get(url).then((response) => response.data);
};
