/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_FEATURED_CATEGORIES_DATA_ASYNC = "LOAD_FEATURED_CATEGORIES_DATA_ASYNC";

/**************************************************************/
// Actions to set data (handle in HomeCarouselReducer switch statement)
/**************************************************************/
export const SET_FEATURED_CATEGORIES_DATA = "SET_FEATURED_CATEGORIES_DATA";

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_FEATURED_CATEGORIES_DATA_ASYNC_FAIL = "LOAD_FEATURED_CATEGORIES_DATA_ASYNC_FAIL";
export const SET_FEATURED_CATEGORIES_DATA_FAIL = "SET_FEATURED_CATEGORIES_DATA_FAIL";
