import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import store from '../../../../../core/Redux/Store';
import { resetPaymentInformation } from '../../../CheckoutRedux/CheckoutActionCreator';
import { loadGMPaymentScripts } from './GMPaymentUI';

class PaymentForm extends Component {

    componentWillMount() {
        /***RESET PAYMENT INFORMATION ***/
        store.dispatch(resetPaymentInformation());
        /*** LOAD PAYMENT SCRIPTS ***/
        loadGMPaymentScripts();
    }

    render() {
        return (<Translation>
            {
                (t) => (
                    <div className="ac-paymentWrapper">
                        <div><h2 className="q-headline2 ac-checkout-wizard-header-title">{t("LABEL_CHECKOUT_CREDIT_DEBIT_INFO")}</h2></div>
                        <div id="accessories-payment-widget" className="gmpayment-container"></div>
                    </div>
                )
            }
        </Translation>
        )
    }
}

export default PaymentForm