import React, { useEffect } from 'react';
import { Translation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import store from '../../../core/Redux/Store';
import FieldWarningMessage from '../../FieldWarningMessage/FieldWarningMessage';
import SummaryRailRewardsPoints from '../RedeemRewards/RewardsPoints/SummaryRailRewardsPoints';
import AppSettings from '../../../core/AppSettings';
import { getMemberRedemptionInfo } from '../../Rewards/RewardsRedux/RewardsActionCreator';
import { useFooterDisclaimerLabel } from '../../../modules/App/Footer/FooterDisclaimerLabel';
import { isValidValue } from '../../Validator/Validator';

function RewardsWrapper(props) {
    const memberRedemptionInfoError = props.rewardsData.memberRedemptionInfoError;
    const brand = AppSettings.currentSite.label;
    const canRedeem = props.rewardsData.canRedeem;
    const disclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
    const label = useFooterDisclaimerLabel(disclaimer);

    let appliedVouchers = [];
    // There shouldn't be more than one points adjustment, but make it an array just in case
    let appliedDollars = [];
    props.appliedAdjustments.forEach((adjustment, index) => {
        if (adjustment.code === process.env.REACT_APP_LOYALTY_VOUCHER_ADJ_CODE) {
            appliedVouchers.push(adjustment.couponCode);
        } else if (adjustment.code === process.env.REACT_APP_LOYALTY_POINTS_ADJ_CODE) {
            appliedDollars.push(adjustment.couponCode);
        }
    });

    useEffect(() => {
        //call to get RedemptionInformation from rewards placed in AuthenticationWorker also
        store.dispatch(getMemberRedemptionInfo(props.adjustedTotal));
    }, [props.adjustedTotal]);

    if (isValidValue(memberRedemptionInfoError) || !props.isDealerParticipating) {
        const errorMessage = (!props.isDealerParticipating) ? "LABEL_REWARDS_DEALER_PARTICIPATION_ERROR" :
            (memberRedemptionInfoError === "NO POINT BALANCE" && props.rewardsData.nonVehicleRedeemActualDollars > 0
                && props.rewardsData.totalPoints) ? "LABEL_REWARDS_CHECKOUT_NOT_ENOUGH_POINTS" :
                memberRedemptionInfoError === "NO POINT BALANCE" && props.rewardsData.nonVehicleRedeemActualDollars == undefined
                && props.rewardsData.totalPoints ? "LABEL_REWARDS_CHECKOUT_NOT_ENOUGH_POINTS2":
                (memberRedemptionInfoError === "NO POINT BALANCE" && !props.rewardsData.totalPoints) ?
                    "LABEL_REWARDS_CHECKOUT_NOT_ENOUGH_POINTS2" :
                        "LABEL_REWARDS_SERVICE_ERROR";

        return (
            <Translation>
                {(t) => (
                    // Without wrapping the label inside of div, ul, li, there is no left padding.
                    <div>
                        <ul>
                            <li>
                                <h4><b>
                                    <Trans i18nKey="LABEL_REWARDS_CHECKOUT_HEADER">
                                    {{brand: brand}} REWARDS POINTS<sup>{{ label: label }}</sup>
                                    </Trans>
                                    </b></h4>
                            </li>
                            <div className="ac-small-marginbottom">
                                <li>
                                    <FieldWarningMessage message={t(errorMessage, { brand })} />
                                </li>
                            </div>
                        </ul>
                    </div>
                )}
            </Translation>
        );
    } else {
        return (
            <Translation>
                {(t) => (
                    props.rewardsData.isRewardsActive &&
                        <div className="ac-summary-rail-rewards-container">
                            <div>
                                <div>
                                    <h4><b>
                                        <Trans i18nKey="LABEL_REWARDS_CHECKOUT_HEADER">
                                        {{ brand: brand }}REWARDS POINTS<sup>{{ label: label }}</sup>
                                        </Trans>
                                    </b></h4>
                                </div>
                                <SummaryRailRewardsPoints
                                    pointAllowancesError={props.rewardsData.redeemRewardsError}
                                    appliedDollars={appliedDollars}
                                    appliedPoints={props.appliedPoints}
                                    handleApplyRewardsPoints={props.handleApplyRewardsPoints}
                                    subtotal={props.adjustedTotal}
                                    // following values comes from new rewards 2.0 API calls
                                    tierName={props.rewardsData.currentTierName}
                                    totalPointDollars={props.rewardsData.redemptionDollars}
                                    totalPointPoints={props.rewardsData.redemptionPoints}
                                    nonVehicleRedeemDollars={props.rewardsData.nonVehicleRedeemDollars}
                                />
                            </div>
                        </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    return {
        rewardsData: state.RewardsReducer
    }
}

export default connect(mapStateToProps)(RewardsWrapper);
