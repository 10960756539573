import * as actionType from './VoVActionTypes';

/*** ACTION CREATOR THAT RETURNS PLAIN OBJECT ***/

export const vovDataAsync = () => {
    return {
        type: actionType.VOV_DATA_ASYNC
    }
};

export const changeVehicleColor = (id) => {
    return {
        type: actionType.VOV_CHANGE_COLOR,
        payload: {
            colorId: id
        }
    }

};

export const setInitialVehicleColor = (id)=>{
    return {
        type:actionType.VOV_SET_INITIAL_COLOR,
        payload:{
            colorId:id
         }
    }
};

export const vovDataInitialization = (props) => {
    return {
        type: actionType.VOV_IMAGE_DATA_INITIALIZATION,
        payload: props.data
    }
};

export const showSpinner = () => {
    return {
        type: actionType.SET_SPINNER_TRUE
    }
};

export const hideSpinner = () => {
    return {
        type: actionType.SET_SPINNER_FALSE
    }
};



export const getVovDataWithParts = (data) => {
    return {
        type: actionType.VOV_DATA_WITH_PARTS,
        payload: {
            imageWithParts: data
        }
    }
};

export const getVovDataWithoutParts = (data) => {
    return {
        type: actionType.VOV_DATA_WITHOUT_PARTS,
        payload: {
            imageWithoutParts: data
        }
    }
};

export const changeImageToggle = () => {
    return {
        type: actionType.CHANGE_IMAGE_TOGGLE
    }
};

export const colorConfigData = (data) => {
    return {
        type: actionType.COLOR_CONFIG_DATA,
        payload: {
            colorData: data
        }
    }

};


export const resetDataOnClose = () => {
    return {
        type: actionType.RESET_DATA_ON_CLOSE_MODEL
    }
};

export const retrievePartsRPOFromCGI = (...data) => {
    return {
        type: actionType.RETRIEVE_PARTS_RPO_FROM_CGI,
        payload: {
            partsRPO: data[0],
            colorCode: data[1]
        }
    }
};

export const retrivePartsRPOFromCGIAsync = () => {
    return {
        type: actionType.RETRIEVE_PARTS_RPO_FROM_CGI_ASYNC,
    }
};

export const updateSearchResultWithVovData = (searchResponse, searchResultPartsRPO, searchResultColorCode) => {
    return {
        type: actionType.UPDATE_SEARCH_RESULT_WITH_VOV_DATA,
        searchResponse: searchResponse,
        partsRPO: searchResultPartsRPO,
        colorCode: searchResultColorCode
    }
};

export const updateFullSearchResponseWithVoVData = (searchResponse, searchResultPartsRPO, searchResultColorCode) => {
    return {
        type: actionType.UPDATE_FULL_SEARCH_WITH_VOV_DATA,
        fullSearchResponse: searchResponse,
        partsRPO: searchResultPartsRPO,
        colorCode: searchResultColorCode
    }
};

export const updateProductDetailsWithVovData = (showVoVIcon, productDetailsPartsRPO, productDetailsColorCode) => {
    return {
        type: actionType.UPDATE_PRODUCT_DETAILS_WITH_VOV_DATA,
        isVOVActive: showVoVIcon,
        productRPOCode: productDetailsPartsRPO ,
        productColorCode : productDetailsColorCode
    }
}

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
// see getImageDataService in VoVService.js
export const loadImageDataServiceFail = (error) => {
    const action = {
        type: actionType.LOAD_IMAGE_DATA_SERVICE_FAIL,
        error
    }
    return action;
}

export const loadVOVImageDataWithPartsFail = (error) => {
    const action = {
        type: actionType.LOAD_VOV_IMAGE_DATA_WITH_PARTS_FAIL,
        error
    }
    return action;
}

export const loadVOVImageDataWithoutPartsFail = (error) => {
    const action = {
        type: actionType.LOAD_VOV_IMAGE_DATA_WITHOUT_PARTS_FAIL,
        error
    }
    return action;
}

// Note: the call for this error handling action is out of scope
export const loadColorConfigurationFail = (error) => {
    const action = {
        type: actionType.LOAD_COLOR_CONFIGURATION_FAIL,
        error
    }
    return action;
}

// generic error action used as a catch all
export const loadVOVFail = (error) => {
    return {
        type: actionType.LOAD_VOV_FAIL,
        error
    }
};

//reset PartsRPO and Color code for the vehicle
export const resetPartsRPOAndColorCode = ()=>{
    return {
        type:actionType.RESET_VOV_PARTSRPO_AND_COLORSCODE
    }
};

//Empty CategoryEntryItem
export const emptyCatelogEntryViewOnSearchResultPage = ()=>{
    return {
        type:actionType.EMPTY_CATELOG_ENTRY_VIEW_ON_SEARCH_RESULT
    }
};