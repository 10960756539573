import React, { Component } from 'react';
import AppSettings from '../../core/AppSettings';
import {digitalDataImplementation} from "./DTM_DataLayer";
import { connect } from 'react-redux';

function loadDTMScript(src) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
            resolve();
        });
        script.addEventListener('error', function (e) {
            reject(e);
        });
        document.body.appendChild(script);
    })
};


let satelliteSRC = process.env.REACT_APP_DTM_SCRIPT;

let DTMscript = loadDTMScript(satelliteSRC);

class DTM extends React.Component {

    constructor(props) {
        super(props);
    }

    loadSatelliteScript() {
        DTMscript.then(function () {

        }).catch(function () {
            console.log("Failed to load _satellite script ");
        })
    }

    loadDataLayer() {
        digitalDataImplementation(this.props);
    }
    render() {
        {
            { this.loadSatelliteScript }
           this.loadDataLayer();

        }

        return (
            <div style={{ display: 'none' }}>
            </div>
        );
    }
    
}

const mapStateToProps = state => {

    return {
        orderData: state.OrderInfoReducer,
        orderConfirmData: state.OrderConfirmReducer,
        miniCartData: state.MiniCartReducer,
        checkoutData: state.CheckoutReducer,
        categoryData: state.CategoryNavReducer, 
        productData: state.ProductDetailsReducer,
        vehicleData: state.Session,
        authenticationData: state.Authentication,
        searchData: state.SearchServiceReducer,
        dealerizationData: state.DealerizationReducer
    }
}
 
export default connect(mapStateToProps)(DTM);