import React from 'react';
import { Translation } from 'react-i18next';

const VehicleSelectHeader = props => {

  const headerLabel = props.headerLabel
 
  function HeaderLabelDynamicValue(props) {
    const headerLabelDynamicValue = props.headerLabelDynamicValue
    if (headerLabelDynamicValue) {
      return (
        <span className="ac-vehicle-select-dynamic-value-label">{headerLabelDynamicValue}</span>
      )
    }
     else {
      return '';
    }
  }

  if(headerLabel === "LABEL_COMMON_VEHICLE_SELECT_NON_HOME_HEADER"){
    return (
      <Translation>
        {(t) => (
          <div className="ac-vehicle-select-instructions medium-margin">
            <h2 className="q-headline2 q-bold2 ac-vehicle-select-clp-header">{t(headerLabel)}<HeaderLabelDynamicValue headerLabelDynamicValue={props.headerLabelDynamicValue}/></h2>
            <p>{t("LABEL_COMMON_VEHICLE_SELECT_NON_HOME_INSTRUCTIONS")}</p>
          </div>
        )}
      </Translation>
    );
  }
  
  else{
    return (
      <Translation>
        {(t) => (
          <div className="ac-vehicle-select-instructions medium-margin">
            <h2 className="q-headline2 q-bold2 ac-vehicle-select-header">{t(headerLabel)}<HeaderLabelDynamicValue headerLabelDynamicValue={props.headerLabelDynamicValue}/></h2>
          </div>
        )}
      </Translation>
    );
    }
  }
    

export default VehicleSelectHeader;
