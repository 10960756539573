import React, { useState, useEffect, useReducer } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SharedProgressBar from "../../../shared/ProgressBar/SharedProgressBar";
import SharedSpinner from "../../../shared/Spinner/Spinner";
import { YMM_ENGINE, YMM_WHEEL, YMM_BODY, YMM_DRIVE, YMM_TRIM } from "../InlineFitment/InlineSharedConstants";

/**
 * Checks ymmData object(from SharedYMMSearchExtendDropDown) for latest object(s) that were set to show and renders them.
 * @param {} props
 */
export default function InlineFitmentYMMExtendDropdown(props) {
    const YMMData = props.YMMData;
    const { t } = useTranslation();
    const [currFields, setCurrFields] = useState([]);
    const [stepCount, setStepCount] = useState(0);

    useEffect(() => {
        for (let i = YMMData.length - 1; i >= 0; i--) {
            if (YMMData[i]?.show) {
                if (YMMData[i].id === YMM_WHEEL || YMMData[i].id === YMM_ENGINE) {
                    setCurrFields([YMMData[i - 1], YMMData[i]]);
                } else {
                    setCurrFields([YMMData[i]]);
                    if (YMMData[i].id === YMM_BODY && props?.neededFitment?.includes("bodyStyle")) {
                        setStepCount(0);
                    } else if (YMMData[i].id === YMM_TRIM && props?.neededFitment?.includes("trim")) {
                        if (props?.neededFitment?.includes("bodyStyle")) {
                            setStepCount(1);
                        } else {
                            setStepCount(0);
                        }
                    } else if (YMMData[i].id === YMM_DRIVE && props?.neededFitment?.includes("driveType")) {
                        if (props?.neededFitment?.includes("bodyStyle") && props?.neededFitment?.includes("trim")) {
                            setStepCount(2);
                        } else if (
                            props?.neededFitment?.includes("bodyStyle") ||
                            props?.neededFitment?.includes("trim")
                        ) {
                            setStepCount(1);
                        } else {
                            setStepCount(0);
                        }
                    }
                }
                break;
            }
        }
    }, [YMMData]);


    return currFields.map((currField, i) => {
        return currField.show ? (
            <div id="inline-extend" key={i}>
                {props.inlineHeader(currField, t)}
                <Dropdown
                    data-dtm={"vehicle fit:config:trim"}
                    className={props.toggledClass(currField.id === "YMM_MAKE")}
                >
                     {(i === 0 && currField.show) || (i === 1 && !currFields[0]?.show) ? (
                        <SharedProgressBar completedSteps={stepCount} stepCount={props.steps} />
                    ) : null}
                    <Dropdown.Toggle
                        data-dtm={
                            "vehicle fit:config:" +
                            currField.title.substring(currField.title.indexOf(" ") + 1).toLowerCase()
                        }
                        className="q-form-dropdown ymm-dropdown stat-dropdown background-dropdown"
                        id={currField.id}
                        disabled={currField.disabled}
                    >
                        <span id="inline-fitment-extend-dropdowns" className="ymm-dropdown-title gb-body2">
                            {currField.showSpinner && (
                                <div className="ymm-spinner">
                                    <SharedSpinner size={"sm"} />
                                </div>
                            )}
                            {currField.title}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="ymm-dropdown-menu">
                        {currField.options.length > 0 &&
                            currField.options.map((subItem, index) => {
                                const options = props.filterOptions(currField, subItem);
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        className="ymm-dropdown-item gb-body2 stat-text-link"
                                        data-dtm={
                                            "vehicle fit:" +
                                            currField.title.substring(currField.title.indexOf(" ") + 1).toLowerCase()
                                        }
                                        onClick={(e) => {
                                            currField.onClick(e);
                                        }}
                                        value={options}
                                        data-object={JSON.stringify(subItem)}
                                    >
                                        {options}
                                    </Dropdown.Item>
                                );
                            })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        ) : null;
    });
}
