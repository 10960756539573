/**************************************************************/
// Actions to load data into the header state
/**************************************************************/
export const LOAD_HEADER_DATA_ASYNC = 'LOAD_HEADER_DATA_ASYNC';
export const SET_LOGO = 'SET_LOGO';
export const SET_LOADING = 'SET_LOADING';
export const SET_DEALER_HEADER = 'SET_DEALER_HEADER';
export const SET_FLYOUT_TEXT = 'SET_FLYOUT_TEXT'; 

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const LOAD_HEADER_DATA_ASYNC_FAIL = 'LOAD_HEADER_DATA_ASYNC_FAIL';
export const SET_LOGO_FAIL = 'SET_LOGO_FAIL';
export const SET_DEALER_HEADER_FAIL = 'SET_DEALER_HEADER_FAIL';
export const SET_FLYOUT_TEXT_FAIL = 'SET_FLYOUT_TEXT_FAIL';