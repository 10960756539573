
import React, { Component } from 'react';
import { Translation } from 'react-i18next';

export class TroubleshootTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let troubleObject = [];    
        let troubleshootData = this.props.troubleshootData.content;
        if (troubleshootData !== undefined) {
            troubleshootData.map((item) => {
                troubleObject.push(item);
            })
        }
        return(           
            <Translation>{(t) => (
            <div>
                <div className="ac-tabContent">
                    <h2 className="q-headline2">{t(this.props.troubleshootTitle)}</h2>
                    <h5>{this.props.troubleshootData.header}</h5>
                <h5 className="q-text">
                    {
                        troubleObject.map((item, index) => {
                            return <li key={index}>
                                {item}
                            </li>
                        })
                    }
                </h5>
                </div>
            </div>           
            )}            
            </Translation>
        );
    }
}

export default TroubleshootTab
