import React, { useEffect } from 'react';
import Modal from '../../../shared/Modal/Modal';
import MapBody from "./BodyContent/MapBody";
import RegularBody from "./BodyContent/RegularBody";
import { useDispatch } from "react-redux";
import {resetErrors, storeSearchZip} from "./HelperFunctions";

const DealerSelectModal = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.dealerizationState.listOfAllSources?.length > 0) {
            storeSearchZip(props.dealerizationState.currentZip);
        }
    }, [props.dealerizationState.listOfAllSources]);

    const handleSearchType = (input) => {
        dispatch({ type: "UPDATE_SEARCH_TYPE", searchType: input });
        dispatch({type: "RESET_DEALER_ERRORS"});
    };

    const handleClose = () => {
        if (!props.dealerizationState.preventClose) {
            dispatch({ type: "TOGGLE_DEALER_MODAL", modalOpen: false });
            dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: props.dealerizationState.prevSources });
            dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: props.dealerizationState.prevZip }); //If user closes modal without saving, revert back to using previous zip
            dispatch({ type: "UPDATE_USER_INPUT_ZIP", userInputZip: props.dealerizationState.prevZip });
            dispatch({
                type: "UPDATE_SELECTED_SOURCE",
                selectedSource: props.dealerizationState.prevSelectedSrc,
                selectedDealerAllData: props.dealerizationState.selectedDealerAllData
            });
        }

        resetErrors(dispatch);
    };
    if (props.MAP_FLAG) {
        return (
            <div>
                <Modal
                    modalId={props.MODAL_STYLE + (props.CART_ITEMS ? "-cart-warning" : "")}
                    open={props.dealerizationState.modalOpen}
                    onClose={handleClose}
                >
                    <MapBody
                        cartFlag={props.CART_ITEMS}
                        state={props.dealerizationState}
                        dispatch={dispatch}
                        handleClose={handleClose}
                        setDealerList={props.setDealerList}
                        handleSearchType={handleSearchType}
                    />
                </Modal>
            </div>
        )
    }
    else {
        return (
            <div>
                <Modal
                modalId={props.MODAL_STYLE}
                open={props.dealerizationState.modalOpen}
                onClose={handleClose}
                >
                    <RegularBody
                        cartFlag={props.CART_ITEMS}
                        state={props.dealerizationState}
                        dispatch={dispatch}
                        setDealerList={props.setDealerList}
                        handleClose={handleClose}
                        handleSearchType={handleSearchType}
                    />
                </Modal>
            </div>
        )
    }

}

export default DealerSelectModal;