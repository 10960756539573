import { isValidResponse } from "../../../shared/Utils/Utils";
import { getVPMFitmentCheckResponse } from "./InlineFitmentService";
import AppSettings from "../../../core/AppSettings";
import accHistory from "../../App/History";
import { jsonToUrlParams } from "../../../shared/Utils/Utils";

/**
 * Gets the error/passing information of the inputted vin
 * @param {object} vin
 * @param {object} partNumber
 * @param {function} setVINCheckMessage
 * @param {function} setIsLoading
 * @param {function} setIsFitmentError
 * @param {function} setVinResults
 * @param {function} setVinBreadcrumb
 * @param {function} setCurrVin
 * @param {function} setCurrentVehicleInfo
 */
export const checkInputtedVIN = async (
    vin,
    partNumber,
    setVINCheckMessage,
    setIsLoading,
    setIsFitmentError,
    setVinResults,
    setVinBreadcrumb,
    setCurrVin,
    setCurrentVehicleInfo
) => {
    let checkVINInitialData = {};
    try {
        setIsLoading(true);

        checkVINInitialData = await getVPMFitmentCheckResponse(vin, partNumber);
        if (isValidResponse(checkVINInitialData)) {
            setVINCheckMessage(
                parseVPMFitmentInfo(
                    checkVINInitialData,
                    setIsFitmentError,
                    setVinResults,
                    setVinBreadcrumb,
                    vin,
                    setCurrVin,
                    setCurrentVehicleInfo
                )
            );
        } else {
            console.warn("ERROR: ", checkInputtedVIN);
            checkVINInitialData = {
                errors: [
                    {
                        errorKey: "0",
                        excData: {
                            ErrorCode: "ECOM-CFC:0121"
                        },
                        errorMessage: "Not a valid VIN . At least last 8 characters of VIN is needed to search",
                        errorCode: "ECOM-CFC:0121"
                    }
                ]
            };
            setVINCheckMessage(
                parseVPMFitmentInfo(
                    checkVINInitialData,
                    setIsFitmentError,
                    setVinResults,
                    setVinBreadcrumb,
                    vin,
                    setCurrVin,
                    setCurrentVehicleInfo
                )
            );
        }
    } catch (error) {
        checkVINInitialData = {
            errors: [
                {
                    errorKey: "0",
                    excData: {
                        ErrorCode: "ECOM-CFC:0121"
                    },
                    errorMessage: "Not a valid VIN . At least last 8 characters of VIN is needed to search",
                    errorCode: "ECOM-CFC:0121"
                }
            ]
        };
        setVINCheckMessage(
            parseVPMFitmentInfo(
                checkVINInitialData,
                setIsFitmentError,
                setVinResults,
                setVinBreadcrumb,
                vin,
                setCurrVin,
                setCurrentVehicleInfo
            )
        );
        console.warn("ERROR: ", error);
    } finally {
        setIsLoading(false);
    }
};

/**
 * Obtains the error message from the endpoint response
 * @param {object} vinData
 * @param {function} setIsFitmentError
 * @param {function} setVinResults
 * @param {function} setVinBreadcrumb
 * @param {object} vin
 * @param {function} setCurrVin
 * @param {function} setCurrentVehicleInfo
 * @returns
 */
export const parseVPMFitmentInfo = (
    vinData,
    setIsFitmentError,
    setVinResults,
    setVinBreadcrumb,
    vin,
    setCurrVin,
    setCurrentVehicleInfo
) => {
    const errorMessage = {};
    if (vinData.hasOwnProperty("errors")) {
        setIsFitmentError(true);
        switch (vinData["errors"][0]["errorKey"]) {
            case "0121":
                errorMessage[vinData["errors"][0]["errorKey"]] = "LABEL_CHECK_VIN_INVALID_ERROR";
                break;
            case "0131":
                errorMessage[vinData["errors"][0]["errorKey"]] = "LABEL_CHECK_VIN_NONGM_ERROR";
                break;
            case "0130":
                errorMessage[vinData["errors"][0]["errorKey"]] = [
                    "LABEL_CHECK_VIN_INVALIDMAKE_ERROR_FIRST_PART",
                    "LABEL_CHECK_VIN_INVALIDMAKE_ERROR_SECOND_PART",
                    vinData["errors"][0]["errorMessage"],
                    vinData["errors"][0]["store"]
                ];
                break;
            default:
                errorMessage[vinData["errors"][0]["errorKey"]] = "LABEL_CHECK_VIN_VPM_ERROR";
                break;
        }
        return errorMessage;
    } else {
        setIsFitmentError(false);
        const breadcrumbs = [];
        const vehicleInfo = vinData.vehicleInfo;
        setCurrentVehicleInfo(createVehicleInfoObjectVIN(vehicleInfo));

        breadcrumbs.push(`${vehicleInfo.year} ${AppSettings.isT3 ? vehicleInfo.make + " " : ""}${vehicleInfo?.model}`);

        vehicleInfo.body && breadcrumbs.push(vehicleInfo.body);
        vehicleInfo.wheel && breadcrumbs.push(vehicleInfo.wheel);
        vehicleInfo.trim && breadcrumbs.push(vehicleInfo.trim);
        vehicleInfo.drive && breadcrumbs.push(vehicleInfo.drive);
        vehicleInfo.engine && breadcrumbs.push(vehicleInfo.engine);
        setVinBreadcrumb(breadcrumbs);
        if (vinData.fitment.fitmentDetails === "no") {
            setVinResults(false);
        } else {
            setVinResults(true);
        }
        setCurrVin(vin);
        return {};
    }
};

export const handlePSRRedirectVin = (currVin) => {
    const urlParams = AppSettings.urlParameters();

    delete urlParams["year"];
    delete urlParams["make"];
    delete urlParams["makeId"];
    delete urlParams["model"];
    delete urlParams["modelId"];
    delete urlParams["body"];
    delete urlParams["bodyId"];
    delete urlParams["trim"];
    delete urlParams["trimId"];
    delete urlParams["drive"];
    delete urlParams["driveId"];
    delete urlParams["engine"];
    delete urlParams["engineId"];
    delete urlParams["wheel"];
    delete urlParams["wheelId"];
    delete urlParams["bodyNumDoorsId"];
    delete urlParams["bodyNumDoors"];
    urlParams["vin"] = currVin;
    accHistory.push("/search" + jsonToUrlParams(urlParams));
};

export const handlePSRRedirectYMM = (ymmData, ymmExtendData, bodyNumDoorsParam, modelId) => {
    const urlParams = AppSettings.urlParameters();
    urlParams["year"] = ymmData.selectedYear || "";
    urlParams["make"] = ymmData.selectedMake || "";
    urlParams["model"] = ymmData.selectedModel || "";
    urlParams["modelId"] = modelId;
    urlParams["body"] = ymmExtendData.bodyStyle || "";
    urlParams["bodyId"] = ymmExtendData.bodyStyleId || "";
    urlParams["trim"] = ymmExtendData.trim || "";
    urlParams["trimId"] = ymmExtendData.trimId || "";
    urlParams["drive"] = ymmExtendData.driveType || "";
    urlParams["driveId"] = ymmExtendData.driveTypeId || "";
    urlParams["engine"] = ymmExtendData.engine || "";
    urlParams["engineId"] = ymmExtendData.engineId || "";
    urlParams["wheel"] = ymmExtendData.wheelBase || "";
    urlParams["wheelId"] = ymmExtendData.wheelBaseId || "";
    urlParams["bodyNumDoorsId"] = bodyNumDoorsParam || "";
    delete urlParams["categoryId"];

    accHistory.push("/search" + jsonToUrlParams(urlParams));
};

/**
 * Creates smaller vehicleInfo object from FitmentCheck response
 * This will be used to compare against current session data to see if we need to update
 * @param {object} vehicleInfo
 */
const createVehicleInfoObjectVIN = (vehicleInfo) => {
    return {
        vin: vehicleInfo.vin || "",
        year: vehicleInfo.year || "",
        make: vehicleInfo.make || "",
        makeId: vehicleInfo?.vcdb?.makeId || "",
        model: vehicleInfo.model || "",
        modelId: vehicleInfo?.vcdb?.modelId || "",
        body: vehicleInfo.bodyStyle || "",
        bodyId: vehicleInfo?.vcdb?.bodyStyleConfigId || "",
        wheel: vehicleInfo.wheelBase || "",
        wheelId: vehicleInfo?.vcdb?.wheelBaseId || "",
        trim: vehicleInfo.trim || "",
        trimId: vehicleInfo?.vcdb?.trimId || "",
        drive: vehicleInfo.driveType || "",
        driveId: vehicleInfo?.vcdb?.driveTypeId || "",
        engine: vehicleInfo.engine || "",
        engineId: vehicleInfo?.vcdb?.engineId || "",
        // This is bodyNumDoorsId because fitment cookie saves it as such
        bodyNumDoorsId: vehicleInfo?.vcdb?.bodyNumDoors || ""
    };
};

/**
 * Creates vehicleInfo object from VehicleSelect and VehicleConfig reducers after making fitment selections
 * This will be used to compare against current session data to see if we need to update
 * @param {object} vehicleSelectInfo - VehicleSelectReducer object
 * @param {object} vehicleConfigInfo - VehicleConfigReducer.fitmentVehicleInfo object
 * @param {string} modelId - Selected modelId from VehicleSelectReducer.modelToModelId[model]
 * @param {string} bodyNumDoorsParam - BodyNumDoorsId from VehicleConfigReducer.vehicleParams.bodyNumDoorsParam
 */
export const createVehicleInfoObjectYMM = (vehicleSelectInfo, vehicleConfigInfo, modelId, bodyNumDoorsParam) => {
    return {
        vin: "",
        year: vehicleSelectInfo.selectedYear || "",
        make: vehicleSelectInfo.selectedMake || "",
        makeId: vehicleSelectInfo.selectedMake ? vehicleSelectInfo.makeToCode[vehicleSelectInfo.selectedMake] : "",
        model: vehicleSelectInfo.selectedModel || "",
        modelId: modelId || "",
        body: vehicleConfigInfo.bodyStyle || "",
        bodyId: vehicleConfigInfo.bodyStyleId || "",
        wheel: vehicleConfigInfo.wheelBase || "",
        wheelId: vehicleConfigInfo.wheelBaseId || "",
        trim: vehicleConfigInfo.trim || "",
        trimId: vehicleConfigInfo.trimId || "",
        drive: vehicleConfigInfo.driveType || "",
        driveId: vehicleConfigInfo.driveTypeId || "",
        engine: vehicleConfigInfo.engine || "",
        engineId: vehicleConfigInfo.engineId || "",
        // This is bodyNumDoorsId because fitment cookie saves it as such
        bodyNumDoorsId: bodyNumDoorsParam || ""
    };
};

/**
 * Returns whether we need to update session data depending on if sessionData is different from most recent inline
 * fitment selection
 * @param currentVehicleInfo {object} - Most recently selected vehicle info from inline fitment
 * @param sessionData {object} - Session reducer data
 */
export const checkIsFitmentUpdateNeeded = (currentVehicleInfo, sessionData) => {
    // If either objects have vin, only need to check if VIN number matches
    if (currentVehicleInfo.vin || sessionData.vin) {
        return currentVehicleInfo.vin !== sessionData.vin;
    }

    const excludedFitmentKeys = new Set(["vin", "makeId", "year", "make"]);

    // If any current vehicle fitment information is different from session data return true
    for (const key in currentVehicleInfo) {
        if (!excludedFitmentKeys.has(key) && currentVehicleInfo[key] !== sessionData.vehicleConfig[key]) {
            return true;
        }
    }

    // Make and year get reset in sessionData.vehicleConfig when navigating to pdp for some reason but still in base obj
    // Last check for difference, if not different return false
    return currentVehicleInfo.make !== sessionData.make || currentVehicleInfo.year !== sessionData.year;
};

/**
 * Builds new Product Details url with updated fitment information from Session reducer
 * @param session {object} Session reducer object
 * @returns {string}
 */
export const constructNewProductUrl = (session) => {
    let url = location.pathname;
    const vehicleConfig = session.vehicleConfig;
    delete vehicleConfig.categoryName;
    delete vehicleConfig.subcategoryName;
    delete vehicleConfig.searchTerm;

    const urlParameters = AppSettings.urlParameters();
    const { searchTerm, categoryId } = urlParameters;
    //Pull from old url: searchTerm, categoryId
    categoryId ? (vehicleConfig.categoryId = categoryId) : delete vehicleConfig.categoryId;
    searchTerm ? (vehicleConfig.searchTerm = searchTerm) : delete vehicleConfig.searchTerm;

    if (session.vin) {
        vehicleConfig.vin = session.vin;
    } else {
        vehicleConfig.year = session.year;
        vehicleConfig.make = session.make;
        vehicleConfig.model = session.model;
    }
    // TODO: Test if we need this or if bodyNumDoors is already in session.vehicleConfig. Following ymmRedirect in
    //  VehicleSelectWorker, where bodyNumDoors is set to bodyNumDoorsId
    vehicleConfig.bodyNumDoorsId ? (vehicleConfig["bodyNumDoors"] = vehicleConfig.bodyNumDoorsId) : null;
    url += jsonToUrlParams(vehicleConfig);
    return url;
};
