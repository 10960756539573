import React, { useEffect, useState, useReducer } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import AppSettings from "../../../../../core/AppSettings";
import store from "../../../../../core/Redux/Store";
import ErrorMessage from "../../../../../shared/ErrorMessage/ErrorMessage";
import { scrollerToTop } from "../../../../../shared/Utils/Utils";
import {
    checkPaymentMethodAsync,
    checkPaymentMethodCreator,
    loadBillingAsShipping as loadFromShipping,
    loadSpinnerOnPaymentPage
} from "../../../CheckoutRedux/CheckoutActionCreator";
import BillingForm from "./BillingForm/BillingForm";
import PayInStore from "./PayInStore/PayInStore";
import GMWallet from "../../../../../shared/GMWallet/GMWallet";
import PropTypes from "prop-types";
import { GMWalletReducer, initialGMWalletState } from "../../../../../shared/GMWallet/GMWalletReducer";
import { get_FF_1745770_USE_AZURE_WALLET_WIDGET } from "../../../../../shared/Utils/FeatureFlags";
import AdyenWalletWidget from "../../../../../shared/Wallet/AdyenWalletWidget";

/*** CHECKOUT STEP 3 PAYMENT PAGE ***/
const AdyenPaymentPage = (props) => {
    const { showSpinner, showTechnicalError, payMethodId, payMethodError, authData, billingInformation, rewardsData } =
        props;
    const [state, dispatch] = useReducer(GMWalletReducer, initialGMWalletState);

    //let dealerBac = store.getState().MiniCartReducer.data.dealerizationInfo.bac;
    const [checkboxChecked, toggleCheckbox] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const useWalletWidget = get_FF_1745770_USE_AZURE_WALLET_WIDGET();

    useEffect(() => {
        /** ADDED CHECK HERE TO ENSURE IT DISPATCH ONLY ONE TIME ***/
        const payMethodTriggered = props.triggeredPayMethod;
        const isT3 = AppSettings.isT3;
        store.dispatch(loadSpinnerOnPaymentPage());

        if (!isT3) {
            store.dispatch(checkPaymentMethodCreator("creditCard"));
        }
        if (!payMethodTriggered && isT3) {
            store.dispatch(checkPaymentMethodAsync());
        }

        window.scrollTo(0, 0);
        scrollerToTop("root");
    }, []);

    useEffect(() => {
        //setting the wallet state before it renders the GMWallet
        dispatch({
            type: "SET_WALLET_CONDITIONS",
            isAuthUser: props.authData.registrationStatus !== "G",
            isCheckout: true
        });
    }, [props.authData]);

    useEffect(() => {
        setInitialValues(billingInformation);
    }, [props.billingInformation]);

    const handleBillingSameAsShippingCheckbox = (orderData) => {
        if (!checkboxChecked) {
            toggleCheckbox(true);
            store.dispatch(loadFromShipping(orderData));
        } else {
            toggleCheckbox(false);
            store.dispatch(loadFromShipping());
        }
    };

    if (payMethodError) {
        return (
            <Translation>
                {(t) => (
                    <div>
                        <ErrorMessage message={t("LABEL_SERVER_ERROR_MSG")} icon={true} />
                    </div>
                )}
            </Translation>
        );
    } else if (payMethodId === "payInStore") {
        return <PayInStore nextButton={props.payInStoreNext} prevButton={props.payInStorePrev} />;
    } else if (payMethodId === "creditCard") {
        const registeredUser = authData.registrationStatusFetched && authData.registrationStatus != "G";
        const isUSUser = authData.userData && authData.userData.country === "US" ? true : false;
        // If wallet fails to load, display guest payment as default
        const paymentInput =
            registeredUser && isUSUser ? (
                authData.registrationStatusFetched &&
                !authData.isSessionExpired && (
                    <GMWallet
                        state={state}
                        dispatch={dispatch}
                        isCheckout
                        isAuthUser={true}
                        params={{
                            totalAmount: 0,
                            currency: AppSettings.currency,
                            userId: authData.userData.logonId,
                            orderNo: props?.cartResponse?.response?.order?.orderInfo?.orderId
                        }}
                    />
                )
            ) : (
                <GMWallet
                    state={state}
                    dispatch={dispatch}
                    isCheckout
                    isAuthUser={false}
                    params={{
                        totalAmount: 0,
                        currency: AppSettings.currency,
                        userId: "EST-" + props.cartResponse.response.order?.orderInfo?.orderId,
                        orderNo: props?.cartResponse?.response?.order?.orderInfo?.orderId
                    }}
                />
            );

        const walletWidgetPaymentInput =
            registeredUser && isUSUser ? (
                authData.registrationStatusFetched &&
                !authData.isSessionExpired && (
                    <AdyenWalletWidget
                        isCheckout
                        isAuthUser
                        params={{
                            totalAmount: 0,
                            currency: AppSettings.currency,
                            userId: authData.userData.logonId,
                            orderNo: props?.cartResponse?.response?.order?.orderInfo?.orderId
                        }}
                    />
                )
            ) : (
                <AdyenWalletWidget
                    isCheckout
                    isAuthUser={false}
                    params={{
                        totalAmount: 0,
                        currency: AppSettings.currency,
                        userId: "EST-" + props.cartResponse.response.order?.orderInfo?.orderId,
                        orderNo: props?.cartResponse?.response?.order?.orderInfo?.orderId
                    }}
                />
            );

        return (
            <>
                {/* SHOW SESSION EXPIRED ERROR ON PAYMENT PAGE  */}
                {authData.isSessionExpired && (
                    <Translation>
                        {(t) => (
                            <div>
                                <ErrorMessage
                                    message={t("LABEL_AUTHENTICATION_COOKIE_EXPIRED_ERROR_MESSAGE")}
                                    icon={true}
                                />
                            </div>
                        )}
                    </Translation>
                )}
                {useWalletWidget ? walletWidgetPaymentInput : paymentInput}
                {!showSpinner && (
                    <BillingForm
                        stateData={props.StatesData}
                        onSubmit={props.onSubmit}
                        handleCheckBox={props.handleBillingInformationCheckBox}
                        cartResponse={props.cartResponse}
                        enableBillingAsShipping={props.enableBillingAsShipping}
                        handlePaymentPrevious={props.handlePaymentPrevious}
                        initialValues={initialValues}
                        checkboxChecked={checkboxChecked}
                        handleBillingSameAsShippingCheckbox={handleBillingSameAsShippingCheckbox}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state) => {
    return {
        billingInformation: state.CheckoutReducer.paymentPage.billingInformation,
        StatesData: state.CheckoutReducer.shippingContactForm,
        showSpinner: state.CheckoutReducer.paymentPage.showSpinner,
        payMethodId: state.CheckoutReducer.paymentPage.payMethodId,
        triggeredPayMethod: state.CheckoutReducer.paymentPage.triggeredPayMethod,
        payMethodError: state.CheckoutReducer.paymentPage.payMethodError,
        authData: state.Authentication
    };
};
AdyenPaymentPage.propTypes = {
    previousPage: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleBillingInformationCheckBox: PropTypes.any,
    cartResponse: PropTypes.object.isRequired,
    enableBillingAsShipping: PropTypes.bool.isRequired,
    handlePaymentPrevious: PropTypes.func,
    payInStoreNext: PropTypes.func.isRequired,
    payInStorePrev: PropTypes.func.isRequired
};
export default connect(mapStateToProps)(AdyenPaymentPage);
