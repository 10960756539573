import AppSettings from '../../../core/AppSettings';
import { Translation } from 'react-i18next';
import React, { Component } from 'react';
import {setPromoCodeLocalStorage} from '../../../shared/Utils/Utils';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class AddOrderItemWithPromotionCodeOrCoupon extends Component {

  // v7 OOB API we are trying to match
  // https://localhost/webapp/wcs/stores/servlet/AddOrderItemWithPromotionCodeOrCoupon?promoCode=url10off-GM&catalogId=10052&storeId=11201&quantity=1&URL=OrderCalculate?URL=/product/2016/Chevrolet/Cruze/button-style-center-caps-in-aluminum-with-bowtie-logo-19303234?categoryId=13615&calculationUsageId=-1
    
  // v9 API we are using to replicate AddOrderItemWithPromotionCodeOrCoupon with nodeJS/React
  // https://api-np1.gm.com/global-ecom/{{envId}}/wcs/resources/store/11201/assigned_coupon_code/applycode/KTestTestingSwap
  
  constructor(props){
    super(props);
    setPromoCodeLocalStorage();
    
  };

  getUrlSearchParam() {
    var param ={};
    var urlSearchString = this.props.location.search.replace("?", "").split("&");

    // loop through array afetr split to create key value pair from params
    for (var i = 0, len = urlSearchString.length;i < len; i++) {
        var tempArray = urlSearchString[i].split("=");
        param[tempArray[0]] = tempArray[1];
    }

    // parse for redirect url specifcally since above will not get correct redirect url
    // that also have query params
    // below will create/overwrite the url key with the correct url
    if (this.props.location.search.split("URL=")[2] != undefined) {
      var redirectUrlString = this.props.location.search.split("URL=")[2].replace("&calculationUsageId=-1", "");
      param["URL"] = redirectUrlString;
    } else {
      param["URL"] = "/error";
    }
    
    return param;
  }; 

  redirectToErrorPage () {
    return <Redirect to="/error" />
  };

  validateUrl(url) {
    
    //<Redirect to="/search?year=2018&make=Chevrolet&model=Silverado%201500&categoryId=" />
    // check for T1 homepage (special due to 1 character '/')
    if (url.length == 1) {
      if (url == AppSettings.routePaths.home) {
        return <Redirect to={url} />
      } else {
        return this.redirectToErrorPage();
      };
    } else {
      var indexOfSecondSlash = url.indexOf("/", 1);
      // check T1 search
      if (url.startsWith(AppSettings.routePaths.search)) {
        return <Redirect to={url} />
      }
      // check T1 product page
      if (url.startsWith(AppSettings.routePaths.product)) {
        return <Redirect to={url} />
      }
      // check T3 search
      if (url.indexOf(AppSettings.routePaths.search, 1) >= 0 && url.indexOf(AppSettings.routePaths.search, 1) == indexOfSecondSlash) {
        return <Redirect to={url} />
      }
      // check T3 product
      if (url.indexOf(AppSettings.routePaths.search, 1) >= 0 && url.indexOf(AppSettings.routePaths.product, 1) == indexOfSecondSlash) {
        return <Redirect to={url} />
      }

      return this.redirectToErrorPage();
    }
  };

  executePromotionCoupon() {
    // get params from url
    var param = this.getUrlSearchParam();

    // set promoCode
    if (param.hasOwnProperty("promoCode") && param.promoCode != null && param.promoCode != "") {
      setPromoCodeLocalStorage(param.promoCode);
    } 
    return this.validateUrl(param.URL);
  };


  render() {
    return (
      <Translation>
        {
        (t) => (
          <div className="ac-home">
            
            <div>
              <span>
                Loading promotion coupon code and redirecting Accessories site
              </span>
              <div>{this.getUrlSearchParam().promoCode}</div>
            </div>
            {this.executePromotionCoupon()}
          </div >
           
          )
        }
      </Translation>
    );
  }
}

export default withTranslation()(AddOrderItemWithPromotionCodeOrCoupon);

