import AppSettings from "../../core/AppSettings";
import i18next from "i18next";
import React from "react";
/**************************************************************/
// HOME CAROUSEL CALL
// Espot response has a lot of unnecessary info for UI. The JSON
// is parsed here and only the relevant data is sent to the
/**************************************************************/

//CUSTOM CAROUSEL ICONS
export const VideoExitBtnImg =
    "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/ui_close-icon_v01.svg";
export const VideoPlayBtnImg =
    "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/video_play_btn.svg";

//CUSTOM CAROUSEL ARROWS
export function PrevArrow(props) {
    const { onClick } = props;
    const dtm = props.dataDtm || "shop by category";
    return (
        <button
            aria-label={i18next.t("LABEL_CAROUSEL_PREVIOUS")}
            className="slick-prev slick-arrow gallery-image-arrow ac-home-carousel-arrow stat-arrow-prev"
            title={i18next.t("LABEL_CAROUSEL_PREVIOUS")}
            onClick={onClick}
            data-dtm={dtm}
        >
            {i18next.t("LABEL_CAROUSEL_PREVIOUS")}
        </button>
    );
}

export function NextArrow(props) {
    const { onClick } = props;
    const dtm = props.dataDtm || "shop by category";
    return (
        <button
            aria-label={i18next.t("LABEL_CAROUSEL_NEXT")}
            className="slick-next slick-arrow gallery-image-arrow ac-home-carousel-arrow stat-arrow-next"
            title={i18next.t("LABEL_CAROUSEL_NEXT")}
            onClick={onClick}
            data-dtm={dtm}
        >
            {i18next.t("LABEL_CAROUSEL_NEXT")}
        </button>
    );
}

/**************************************************************/
//     SLICK SLIDER CUSTOM SETTINGS
/**************************************************************/
export const slickSliderSettings = (dataDtm) => {
    let analyticsTag = dataDtm ? dataDtm : "shop by category";

    return {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: true,
        infinite: true,
        nextArrow: <NextArrow dataDtm={analyticsTag} />,
        prevArrow: <PrevArrow dataDtm={analyticsTag} />,
        dotsClass: "q-content-bar", // slick dots wrapper class
        appendDots: (dots) => (
            <div>
                <div className="slick-indicator-container js-append-dots">
                    <ul className="slick-dots stat-dots" data-dtm="shop by category">
                        {dots}
                    </ul>
                </div>
                <div className="q-semi-opaque-bar" />
            </div>
        )
    };
};

/**************************************************************/
// PARSE JSON FOR HOME CAROUSEL
// Assets extracted from espot:
//      Asset Name (used only as alt text and not for functionality),
//      Marketing Text,
//      SharedCarousel Image (+small & medium sizes),
//      Video Placeholder Image (+small & medium sizes),
//      Video Id
/**************************************************************/
export const parseAssets = (homeCarouselResponse) => {
    let assets = {
        assetName: "",
        imagePath: "",
        imagePathSm: "",
        imagePathMd: "",
        videoPlaceholderImgPath: "",
        videoPlaceholderImgPathmd: "",
        videoPlaceholderImgPathSm: "",
        videoId: "",
        marketingText: ""
    };

    // Mapping data to extract only the relevant information from the espot
    assets =
        homeCarouselResponse.length &&
        homeCarouselResponse.map((item) => {
            //check for marketing text/markup (overlay text on carousel images)
            let marketingText = "";

            if (item.marketingContentDescription && item.marketingContentDescription[0].marketingText) {
                marketingText = item.marketingContentDescription[0].marketingText;
            }

            // note: video and image paths are in the same location in espot
            //       and aren't differentiated, so the logic below sorts through
            //       what is an image slide and what is a video slide
            let assetType =
                item.attachmentAsset && item.attachmentAsset[0] && item.attachmentAsset[0].xasse_type
                    ? item.attachmentAsset[0].xasse_type
                    : "";

            let imagePath = "";
            let imagePathMd = "";
            let imagePathSm = "";
            let videoPlaceholderImgPath = "";
            let videoPlaceholderImgPathMd = "";
            let videoPlaceholderImgPathSm = "";
            let videoId = "";
            let videoIsPlaying = false;

            // parse asset url paths to extract the video id,
            // video placeholder image paths, and image paths

            if (assetType === "image") {
                imagePath = item.attachmentAsset[0].attachmentAssetPath;
                imagePathMd = imagePath.replace(/-Large./, "-Medium.");
                imagePathSm = imagePath.replace(/-Large./, "-Small.");
            }
            if (assetType === "video") {
                videoPlaceholderImgPath = item.attachmentAsset[0].xasse_image1;
                videoPlaceholderImgPathMd = videoPlaceholderImgPath.replace(/-Large./, "-Medium.");
                videoPlaceholderImgPathSm = videoPlaceholderImgPath.replace(/-Large./, "-Small.");
                videoId = item.attachmentAsset[0].attachmentAssetPath;
            }

            // return clean data
            return {
                assetName: item.baseMarketingSpotActivityName,
                marketingText: marketingText,
                imagePath: imagePath,
                imagePathSm: imagePathSm,
                imagePathMd: imagePathMd,
                videoPlaceholderImgPath: videoPlaceholderImgPath,
                videoPlaceholderImgPathMd: videoPlaceholderImgPathMd,
                videoPlaceholderImgPathSm: videoPlaceholderImgPathSm,
                videoId: videoId,
                videoIsPlaying: videoIsPlaying
            };
        });

    return assets;
};

/**
 * A helper function to remove excess spaces between words in an input string.
 * @param {string} str the string to operate on
 * @param {string?} space the whitespace character to use
 * @returns {string} the string with excess spaces removed
 */
export const limitWhitespace = (str, space = " ") => {
    const arr = str.split(space);

    // delete from array all empty items
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "") {
            arr.splice(i, 1);
            i--;
        }
    }

    return arr.join(space);
};

let parser = undefined;

/**
 * Generates the alt text attribute from the markdown for the overlay
 * text of a specified marketing asset.
 * @param {string} markdown the marketing asset's text to parse
 * @param {string?} defaultText the string to substitute if the parser fails
 * @returns {string} the formatted alt text for this asset
 */
export const generateAltText = (markdown, defaultText) => {
    if (!(parser instanceof DOMParser)) parser = new DOMParser();
    const content = parser.parseFromString(markdown, "text/html");
    const elementText = content?.getElementsByTagName("body")?.[0]?.textContent;
    const altText = elementText ? limitWhitespace(elementText) : null;
    return (altText || defaultText) ?? "carousel marketing offer"; // defaultText could be ""
};
