import PropTypes from "prop-types";
import React from 'react';
import SummaryRail from './SummaryRail';

const SummaryRailContainer = (props) => {

    return (
        <SummaryRail
            data={props.data}
            handleCouponCodeRemove={props.handleCouponCodeRemove}
            deliveryErrorTax={props.deliveryErrorTax}
            voucherAmountWarning={props.voucherAmountWarning}
            RIGHT_ORDER_SUMMARY_FF={props.RIGHT_ORDER_SUMMARY_FF}
        />
    );
}

SummaryRailContainer.proptype = {
    data: PropTypes.object.isRequired
};
export default SummaryRailContainer
