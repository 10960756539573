import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Card } from "react-bootstrap";
import propTypes from "prop-types";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faPlus, faMinus } from "../../../node_modules/@fortawesome/free-solid-svg-icons";

/**
 *  This is the SharedAccordion component. Fill the below props to dictate the features of the accordion:
 Usage instructions. ctrl+f and search the relevant Step X) for the example:
 Step 0) Import the SharedAccordion component using the relevant path,
          also decide if your bodyContent is going to be text or another component - other component will need import
 Step 1) Create or set the accordionsArray with 'position', 'titleName', and 'bodyContent' keys to pass SharedAccordion
          Note: bodyContent can be either string or component and should be translated beforehand
 Step 2) Declare the SharedAccordion component and pass the props.
          A list of the props, the type of the prop, any defaults, and their descriptions are explained below:

 Available Props:
 accordions= array;
              REQUIRED You must define the array for the accordion to grab necessary props from;
              The accordion array requires these object keys:
              [{
                position: '#',
                titleName: 'accordionTitle',
                bodyContent: 'string' OR <Component/>
              }];
 defaultActiveKey= string;
              Optional (defaults to all accordions closed);
              This can contain a string numerical that denotes the default accordion category to be open, if any.
  objTitle = bool;
              Optional (defaults to false). More for newSharedMultiSelect, but this is if
              the title needs to contain objects rather than just a string
  objBody = bool;
              Optional (defaults to false). ore for newSharedMultiSelect, but this is if
              the title needs to contain objects rather than just a string (gets rid of <div> cannot be in <p>)
  plusMinusIcon = bool;
              Optional (defaults to false) but this is if
              the icon needs to contain a plus/minus icon rather than just a arrow up/down
  toggleCaretIcon = bool;
              Optional (defaults to false) but this is if the icon needs to be toggled

 // Step 0) Import the SharedAccordion component from the shared dir (use the correct path from your component)
 import {SharedAccordion} from "../../shared/Accordion/SharedAccordion";

 // Step 1) Create or set the array with position, titleName, and bodyContent
    render(){
        const accordionsArray = [
            {position:'0', titleName:'products', bodyContent:'wow look products hah'},
            {position:'1', titleName:'details', bodyContent:'wow look details hah'},
            {position:'2', titleName:'warranty', bodyContent:'wow look warranty hah'}
        ];

    // ALTERNATIVELY Create the Array with components to fill the bodyContent
            // this is translating assuming it's within a FUNCTION
        const Component1 = <Button>{t('LABEL_COMPONENT_1')}</Button>;
            // this is translating assuming it's within a CLASS
        const Component2 = <Button>{i18n.t('LABEL_PLAINSTRING')}</Button>;
        const Component3 = <Button>Component3</Button>;
        const accordionsArray = [
            {position:'0', titleName:'products', bodyContent: Component1},
            {position:'1', titleName:'details', bodyContent: Component2},
            {position:'2', titleName:'warranty', bodyContent: Component3}
        ];
 // Step 2) Declare the SharedAccordion component with props
            <SharedAccordion
              accordions={accordionsArray}
              defaultActiveKey="0"
            />
        }
    }

 export default App
 * */

SharedAccordion.propTypes = {
    accordions: propTypes.array.isRequired,
    defaultActiveKey: propTypes.any,
    objTitle: propTypes.bool,
    objBody: propTypes.bool,
    iconPlusMinus: propTypes.bool,
    fontAwesomeSize: propTypes.string,
    leftOfTitle: propTypes.bool,
    shouldScroll: propTypes.bool
};

SharedAccordion.defaultProps = {
    objTitle: false,
    objBody: false,
    leftOfTitle: true
};

var showText = true

export default function SharedAccordion(props) {
    const [activeKey, setKey] = useState(props.defaultActiveKey);

    const handleOpenClose = (position) => {
        showText = position !== activeKey;
        if (position !== activeKey) {
            setKey(position);
            setTimeout(() => {
                document.getElementsByClassName("ac-accordion-card")[position - 1]?.scrollIntoView(top);
            }, 500);
        } else {
            setKey(null);
        }
    };

    const { t } = useTranslation();

    // The class is concatenated based on the props, and whether the accordion/category is active
    const iconClass = (active) => {
        let _class = props.leftOfTitle ? "ac-accordion-caret-left stat-expand-icon" : "ac-accordion-caret-right ";
        _class += activeKey === active ? "ac-accordion-active" : "ac-accordion-inactive";
        return _class;
    };

    const AccordionCard = ({ accordions }) => (
        <>
            {accordions.map((accordion) => (
                <Card key={accordion.position} className="ac-accordion-card">
                    <Accordion.Toggle
                        className="ac-accordion-header gb-body2 heavy stat-expand-icon"
                        data-dtm= {props.dataDtm ? props.dataDtm :"shop by category"}
                        as={Card.Header}
                        eventKey={accordion.position}
                        onClick={() => handleOpenClose(accordion.position)}
                    >
                        {props.objTitle ? (
                            <span className="ac-accordion-header-label gb-body2">{accordion.titleName}</span>
                        ) : (
                            t(accordion.titleName)
                        )}
                        {props.iconPlusMinus ? (
                            <div className={iconClass(accordion.position)}>
                                {" "}
                                <FontAwesomeIcon
                                    data-dtm="shop by category"
                                    size={props.fontAwesomeSize}
                                    icon={activeKey === accordion.position ? faMinus : faPlus}
                                />
                            </div>
                        ) : (
                            <div className={iconClass(accordion.position)}>
                                {" "}
                                <FontAwesomeIcon icon={activeKey === accordion.position ? faAngleUp : faAngleDown} />
                            </div>
                         ) }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={accordion.position}>
                        <Card.Body className="ac-accordion-body">
                            {showText ? <div>{accordion.bodyContent}</div> : <></>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </>
    );

    if (props.shouldScroll) {
       document.getElementsByClassName("ac-accordion-card")[props.defaultActiveKey]?.scrollIntoView(true);
    }

    return (
        <Accordion role={"accordion"} className="ac-accordion" defaultActiveKey={props.defaultActiveKey}>
            <AccordionCard accordions={props.accordions} />
        </Accordion>
    );
}
