import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import {Translation} from "react-i18next";

const SuccessMessage = (props) => {
    const showSuccess = props.showSuccess;

    let fontIcon = faCheck;

    if (showSuccess === false) {
        return (null);
    }
    else {
        const successMessage = props.message;
        const successIcon = props.icon;

        return (
            <div className="ac-successParent ac-successMessage">
                {
                    successIcon && <span className="ac-icon ac-success-icon">
                                    <FontAwesomeIcon icon={fontIcon} />
                                    </span>

                }
                <span className="ac-message ac-successMessage">{successMessage}</span>
            </div>
        )
    }
}

export default SuccessMessage;