import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import AppSettings from "../../core/AppSettings";
import store from "../../core/Redux/Store";
import {
    updateCreditCardSuccessInformation
} from "../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import { pageTitle } from "../Hooks/Hooks";
import Spinner from "../Spinner/Spinner";
import vaultConstants from "../../../config/vault_constants";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
function AdyenWalletWidget(props) {
    pageTitle("Payment Methods");

    let checkoutReducer = useSelector((state) => state.CheckoutReducer);

    let isCheckout = props.isCheckout;
    let isAuthorized = props.isAuthUser;
    let isOneTimePayment = checkoutReducer?.paymentPage?.paymentInformation?.isOneTimePayment;
    let shopperReference = props.params.userId;
    let totalAmount = props.params.totalAmount;
    let currency = props.params.currency;
    //let showPayButton = "true";
    // let hasHolderName = "true";
    // let holderNameRequired = "true";
    //let enableStoreDetails= "true";
    //let billingAddressRequired= "false";
    let epgEnvironment = process.env.REACT_APP_GM_WALLET_EPG_ENVIRONMENT;
    const adyenConfigClientKey = process.env.REACT_APP_GM_WALLET_ADYEN_CLIENT_KEY_TARGETABLE;
    let currentChosenPayment = checkoutReducer?.paymentPage?.paymentInformation;
    let orderId = props.params.orderNo; // queryString.parse(location.search).orderId;
    const epgClientID = AppSettings.isLocalHost
        ? process.env.REACT_APP_WALLET_CLIENT_ID
        : vaultConstants.WALLET_CLIENT_ID_TARGETABLE;

    useEffect(() => {
        loadGMWalletWidget();
    }, []);


    function loadGMWalletWidget() {
        // Initialize widget
        if (!props.walletData.walletLoadError) {
            const widget = new GMWallet({
                containerId: "adyen-dropin",
                isCheckout: isCheckout,
                isAuthorized: isAuthorized,
                isOneTimePayment: isOneTimePayment,
                shopperReference: shopperReference,
                orderId: orderId,
                epgEnvironment: epgEnvironment,
                clientId: epgClientID,
                appId: "EST",
                adyenConfigClientKey: adyenConfigClientKey,
                currentChosenPayment: currentChosenPayment,
                currency: currency,
                totalAmount: totalAmount,
                onSuccessCallback: function (data) {
                    store.dispatch(
                        updateCreditCardSuccessInformation({
                            payment: data?.payment,
                            userId: data?.userId,
                            isOneTimePayment: data?.isOneTimePayment
                        })
                    );
                },
                onErrorCallback: function (error) {
                    alert(JSON.stringify(error));
                }
            });
        }
    }

    return (
        <div className="ac-wallet-widget-container"  >

                {/* Spinner */}
                {/*{!props.walletData.error && isLoading && (*/}
                {props?.isLoading && (
                    <div className="ac-wallet-widget-spinner-overlay">
                        <Spinner className="q-loader"/>
                    </div>
                )}
                {/* Wallet Widget */}
                {props.walletData.walletLoadError &&
                    <ErrorMessage icon message={"LABEL_GMWALLET_ERROR_LOADING_WIDGET"}/>}
                <div id="adyen-dropin"/>
            </div>
            );
        }

            const mapStateToProps = (state) => {
            return {
            walletData: state.WalletReducer
        };
        };

            export default connect(mapStateToProps)(AdyenWalletWidget);
