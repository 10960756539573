import * as type from '../../SearchService/SearchDataServiceActionTypes';

/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const loadSearchServiceDataAsync = () => {
    const action = {
        type: type.LIST_DATA_ASYNC
    }
    return action;
}