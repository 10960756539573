import axios from 'axios';
import queryString from 'query-string'


/*** CGI SERVICE TO RETRIEVE ALL IMAGES URLS AND OTHER DATA **/
export const getImageDataService = (url)=>{
        return axios.get(url).then((success)=>{return success.data}).catch((error)=>{throw error});
}

/** Calling BYO (Build your Own ) Service owned by Quantum Team ***/
export const getColorConfigDataService = (data)=>{
    const url = `https://www.chevrolet.com/byo-vc/services/fullyConfigured/US/en/${data.make}/${data.year}/${data.model}/${data.code}`;
    return axios.get(url).then((response)=>response.data).catch((error)=>{throw error});
}

/** Calling VPM YMM Service to retrieve marketing config (generally used when not already available via session) ***/
export const getVehicleInfoForYearMakeModel = (year, makeId, modelId, bodyId, wheelId, trimId, driveId, engineId)=>{
    const url = `/rest/v2/vehicleInfo/search/byYMM?year=${year}&makeId=${makeId}&modelId=${modelId}&bodytypeId=${bodyId}&wheelBaseId=${wheelId}&submodelId=${trimId}&driveTypeId=${driveId}&engineId=${engineId}`
    return axios.get(url).then((response)=>response.data).catch((error)=>{throw error});
}

/*** BYO SERVICE TO RETRIEVE BODYSTYLE ***/
export const byoBodyStyleService = ()=>{
    const ymmData = queryString.parse(location.search);
    const make = ymmData.make.toLowerCase();

    const url = `https://www.chevrolet.com/byo-vc/api/v2/bodystyles/en/US/${make}`;
    return axios.get(url).then((response)=>{
       return response.data;
    }).catch((error)=>{
        throw error
    })

};