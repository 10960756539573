import PropTypes from "prop-types";
import React, { Component } from 'react'
import { Translation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import AppSettings from '../../../../../core/AppSettings';
import ImageAndDescription from '../../../../../shared/CartComponents/ImageAndDescription/ImageAndDescription.js';
import CartOrderLineItemHeader from '../../../../../shared/CartComponents/ItemHeader/ItemHeader';
import DealerLocator from "../../../../../shared/DealerLocator/DealerLocator";
import Spinner from "../../../../../shared/Spinner/Spinner";
import DeliveryMethodSelect from './DeliveryMethodSelect/DeliveryMethodSelect';
import asyncValidate from './DeliveryFormValidation/DeliveryFormAsyncValidate';
import ErrorMessage from "../../../../../shared/ErrorMessage/ErrorMessage";
import { scrollerToTop } from "../../../../../shared/Utils/Utils";

// Install values (needed for special handling of install order item)
const INSTALL_ORDER_ITEM_TYPE = "Installed";

let asyncChangeFields = [];
let deliveryMethodInitialValues = {};

class DeliveryFormT3 extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        scrollerToTop('root');
    }

    render() {
        const { handleSubmit, handleDeliveryFormSubmit, submitting, previousPage, error, deliveryErrorTax, deliveryMethodShowSpinner, deliveryMissingParams, isRewardsInputDisabled } = this.props;
        const orderInCart = this.props.data.response.order;
        const orderId = orderInCart.orderInfo.orderId;
        const { shippingRates, fedexRateError, shippingRatesError } = this.props.data;
        let showDealer = false;
        let vehicleData = '';

        function getDeliveryMethodInitialValue(shipChargesByShipMode, items) {
            let defaultValue = {};
            if (items != undefined) {
                let itemShipModeId = items[0].shipModeId;
                let item = items[0];

                //We have two items meaning there is an install item 
                if (items.length > 1) {
                    items.forEach(function (item) {
                        if (item.orderItemType == INSTALL_ORDER_ITEM_TYPE) {
                            itemShipModeId = item.shipModeId;
                        }
                    })
                }
                for (let charge of shipChargesByShipMode) {
                    if (charge && charge.shipModeId === itemShipModeId) {
                        defaultValue = JSON.stringify({
                            carrierCode: charge.carrierCode,
                            shipModeId: charge.shipModeId,
                            orderItemId: item.orderItemId,
                            productId: item.productId,
                            quantity: item.quantity
                        });
                        break;
                    }
                }
            }
            return defaultValue;
        }

        function getImageDescProps(item) {
            const imageDescProps = {
                seoUrl: item.seoUrl,
                partNumber: item.partNumber,
                partName: item.partName,
                imageName: item.imageName,
                productType: item.productType,
                productId: item.productId
            };

            if (item.availableDeliveryMethods && item.availableDeliveryMethods.length) {
                imageDescProps.availableDeliveryMethods = item.availableDeliveryMethods[0];
            }
            return imageDescProps;
        }
        const dealerLocatorInfo = {
            zipCode: orderInCart.shipping.zipcode || "",
            state: orderInCart.shipping.state || "",
            country: orderInCart.shipping.countryCode || "",
            dealer: orderInCart.dealer || ""
        };

        /*** show Dealer link on T1 site only when shipping information is available ***/
        if (orderInCart.shipping !== undefined && !(AppSettings.isT3)) {
            showDealer = true;
        }


        if (orderInCart) {
            vehicleData = orderInCart.vehicles.map((items, index) => {
                return {
                    vehicleIndex: index + 1,
                    vehicleInformation: items.vehiclesInfo,
                    items: items.orderItems,
                    vin: items.vinNumber
                }
            })
        }
        if (shippingRatesError) {
            return <div>
                <ErrorMessage icon={true} message={"LABEL_FED_EX_SHIPPING_RATES_ERROR"} />
            </div>
        }
        else
            return (
                <Translation>{(t) => (
                    <form className="ac-delivery-form" onSubmit={handleSubmit((values) => { handleDeliveryFormSubmit(values, this.props) })}>
                        {
                            /** DISPLAY ERROR MSG IF SUBMIT FAILS AND THERE ARE OPTIONS NOT SELECTED OR INVALID DELIVERY OPTIONS SELECTED **/
                            ((this.props.submitFailed && this.props.invalid) || (this.props.submitSucceeded && this.props.invalid)) &&
                            <div className="ac-missing-delivery-method"><ErrorMessage icon={true} message={"LABEL_CHECKOUT_SELECT_VALID_DELIVERY_OPTIONS"} /></div>
                        }
                        <div className="gmit-gf-groupbox-content">
                            <div className="row">
                                <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                                    <div className="ac-shoppingCartWrapper">
                                        {
                                            /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY VEHICLE INFORMATION  **/
                                            vehicleData.map((item, index) => {
                                                return <div key={index}>
                                                    <div>
                                                        <CartOrderLineItemHeader data={item.vehicleInformation}
                                                            count={item.vehicleIndex} vinNumber={item.vin} />
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            /** LOOPING THROUGH THE SHOPPING CART ITEMS TO DISPLAY EACH LINE ITEM **/
                                                            item.items && item.items.map((subItem, index) => {
                                                                const orderItemId = subItem.items[0].orderItemId;
                                                                let currentShippingRate = {};
                                                                for (var rate of shippingRates) {
                                                                    if (rate.orderItemId === orderItemId) {
                                                                        currentShippingRate = rate;
                                                                    }
                                                                }
                                                                //need to disable fields when a network call is occuring to prevent continuous calls
                                                                const disableFields = (deliveryMethodShowSpinner.size > 0) || isRewardsInputDisabled;

                                                                const deliveryMethodFieldName = `deliveryMethod-${orderItemId}`;
                                                                if (!asyncChangeFields.includes(deliveryMethodFieldName)) {
                                                                    asyncChangeFields.push(`deliveryMethod-${orderItemId}`);
                                                                }

                                                                deliveryMethodInitialValues[deliveryMethodFieldName] = getDeliveryMethodInitialValue(currentShippingRate.shipChargesByShipMode, subItem.items)
                                                                return <div key={index} className="ac-delivery-form-line-item ac-shoppingLineItemIndex">
                                                                    {
                                                                        /** ADDING HORIZONTAL LINE IF THERE ARE IS MORE THAN ONE ITEM **/
                                                                        index > 0 && <hr />
                                                                    }
                                                                    <div className="row ac-delivery-line-item-row">

                                                                        {
                                                                            deliveryMethodShowSpinner.has(subItem.items[0].orderItemId) &&
                                                                            <div className="ac-delivery-form-overlay"><div className="ac-delivery-form-spinner"><Spinner /></div></div>
                                                                        }

                                                                        <div className="col-xs-12 small-12 medium-12 large-12 xlarge-6 columns ">
                                                                            <ImageAndDescription
                                                                                data={getImageDescProps(subItem.items[0])}
                                                                                vehicleInfo={item.vehicleInformation}
                                                                                value={subItem.items[0].quantity}
                                                                                price={subItem.items[0].unitPrice}
                                                                                vin={item.vin}
                                                                                component="delivery"
                                                                            />
                                                                        </div>
                                                                        <div className="col-xs-12 small-12 medium-12 large-12 xlarge-6 columns ac-delivery-method-form-wrapper">
                                                                            <DeliveryMethodSelect
                                                                                vehicle={`${item.vehicleInformation.ymm} ${item.vehicleInformation.trim}`}
                                                                                disabled={disableFields}
                                                                                product={subItem.items[0].partName}
                                                                                items={subItem.items}
                                                                                orderItemId={subItem.items[0].orderItemId}
                                                                                shippingRates={currentShippingRate}
                                                                                quantity={subItem.items[0].quantity}
                                                                                addressId={orderInCart.orderInfo.addressId}
                                                                                shipModeId={subItem.items[0].shipModeId}
                                                                                orderId={orderId}
                                                                                productId={subItem.items[0].productId}
                                                                                showFedExError={fedexRateError}
                                                                                deliveryMissingParams={deliveryMissingParams}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="small-12 medium-12 large-12 xlarge-12">
                            {
                                /** SHOW DEALER INFO AND DEALER LOCATOR FOR T1 ORDER **/
                                showDealer &&
                                <DealerLocator zipCode={dealerLocatorInfo.zipCode} state={dealerLocatorInfo.state} country={dealerLocatorInfo.country} />
                            }
                        </div>
                        {deliveryErrorTax && (<div className="ac-delivery-tax-error">{t("LABEL_CHECKOUT_DELIVERY_SALES_TAX_ERROR")}</div>)}
                    </form>
                )}</Translation>
            )
    }
}
DeliveryFormT3.proptype = {
    data: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'DeliveryForm', // a unique identifier for this form
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncValidate,
    initialValues: deliveryMethodInitialValues,
    asyncChangeFields: asyncChangeFields,
    shouldAsyncValidate: (params) => {
        // shouldAsyncValidate() configures the form to only call 
        // asyncValidate() for field-level validation. This is because all 
        // form-level validation may be performed synchronously.
        const { syncValidationPasses, trigger } = params;
        if (!syncValidationPasses) {
            return false
        }

        // A trigger value of 'change' or 'blur' indicates field-level 
        // validation. The third possible value, 'submit', is excluded.
        switch (trigger) {
            case 'blur':
            case 'change':
                return true
            default:
                return false
        }
    }
})(DeliveryFormT3)
