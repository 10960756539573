/** COMPONENT NAME : DEALER MAP WRAPPER (PARENT OF GOOGLE MAP ) **/

/** IMPORT STATEMENT **/
import React from 'react';
import DealerMap from '../DealerMap/DealerMap';
import PropTypes from 'prop-types';


const DealerMapWrapper = (props)=>{
    const mapElement = props.mapData;
    let showMarkers = false;

    if(props.dealerInfo !== undefined && props.dealerInfo.length){
        showMarkers = true;
    }

    return <DealerMap
            loadingElement={<div className="ac-loadingElement" />}
            containerElement={<div className="ac-containerElement" />}
            mapElement={<div className="ac-mapElement" />}
            dealerData={props.dealerInfo}
            zoom = {mapElement.zoom}
            coordination = {mapElement.coordinates}
            googleMapURL={mapElement.googleMapURL}
            isMarkerShown = {showMarkers}/>

};

 /*** PROP TYPES VALIDATION ***/
 DealerMapWrapper.propTypes = {
    mapData : PropTypes.object.isRequired,
     dealerInfo:PropTypes.array
 };

export default DealerMapWrapper

