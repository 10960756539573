import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translation } from 'react-i18next';

import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import * as formValidate from '../../../shared/Form/FormValidate/FormValidate';
import FormInstructions from '../../../shared/Form/FormInstructions';
import Button from '../../../shared/Button/Button';
import { renderInputField } from '../../../shared/Form/FormFields/FormFields';

function RequestPasswordResetForm(props) {

    const { handleSubmit, submitting, onEmailChange, isLoading, isFormDisabled, errorMessage } = props;

    return (
        <Translation>
            {(t) => (
                <form className="ac-email-form" onSubmit={handleSubmit}>
                    <FormInstructions 
                        instructions={[
                            { text: "LABEL_REQUEST_PASSWORD_RESET_INSTRUCTIONS" }
                        ]} />

                    {errorMessage && <ErrorMessage icon={true} message={errorMessage} />}

                    <Field
                        name="email"
                        component={renderInputField}
                        type="email"
                        onChange={onEmailChange}
                        label={t("LABEL_COMMON_EMAIL")}
                        required={true}
                        validate={[formValidate.required, formValidate.email]}
                        minLength={6}
                        disabled={isFormDisabled}
                        capitalized={true} />

                    <Button 
                        localeKey={t("LABEL_COMMON_NEXT")}
                        type="submit" 
                        disabled={submitting || isFormDisabled}
                        isLoading={isLoading}/>
                </form>
            )}
        </Translation>
    )
}

export default (reduxForm({
    form: 'RequestPasswordResetForm', // form name
    destroyOnUnmount: true, // preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // unregister fields on unmount
})(RequestPasswordResetForm))