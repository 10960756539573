import { call, put } from 'redux-saga/effects';
import {setOrderData, loadOrderDataFail, loadPaymentInfoFail, setPaymentInfo} from "./OrderConfirmActionCreator";

import {getOrderData, getBillingInfo} from "./OrderConfirmService";

/** WORKER TO CALL GET CART RESPONSE AND DISPATCH A FUNCTION */
export function* getOrderInformation() {

  try {
    let parsedResponse = {};
    const res = yield call(getOrderData);

    if(res) {
      parsedResponse = parseOrderInfoResponse(res);
    }

    yield put(setOrderData(res, parsedResponse));

  }
  catch (error) {
    yield put(loadOrderDataFail(error));
  }
}

/** WORKER TO CALL GET CART RESPONSE AND DISPATCH A FUNCTION */
export function* getPaymentInfo() {

  try {
    const res = yield call(getBillingInfo);

    yield put(setPaymentInfo(res));

  }
  catch (error) {
    yield put(loadPaymentInfoFail(error));
  }
}

/** FUNCTION CALLS WHEN QTY CHANGE **/
// export function* orderItemChangeQuantity(action) {
//   try {
//     const payload = {x_action:"change_quantity",orderItem:[{"orderItemId":action.payload.lineItemId,"quantity":action.payload.qty.toString()}]};

//     const res = yield call (getOrderItemChangeQuantityResponse,payload);
//     yield put(loadOrderInfoAsync());
//     }
//   catch (error) {
//     yield put(loadOrderItemChangeQuantityResponseFail(error));
//   }
// }

/** FUNCTION CALLS WHEN ITEM REMOVE FROM CART **/
// export function* orderItemRemove(action) {
//   try {
//     const payload = {
//       orderItem: [{ orderItemId: action.payload.orderItemId }]
//     };

//     const res = yield call(getOrderItemDeleteResponse, payload);
//     yield put(loadOrderInfoAsync());

//     }
//   catch (error) {
//     yield put(loadOrderItemDeleteResponseFail(error));
//   }
// }

/** SWITCH ORG WORKER FOR T3 ORDERS **/
// export function * getSwitchOrg(){
//   try{
//     const response = yield call (callSwitchOrg);
//     if(response){
//       yield put(loadOrderInfoAsync());
//     }
//   }
//   catch(error){
//     yield put (loadSwitchOrgAsyncFailed(error));
//   }
// }

/** WORKER TO GET SHIPPING RATES AND DISPATCH ACTION **/
// export function* getShippingRateData() {
//   try {
//     let shipRatesFormat = {};
//     const orderInfo = yield select(getOrderId);
//     const res = yield call(getShippingRates, orderInfo.parsedResponse.orderId);
//     if (res) {
//       shipRatesFormat = formatShippingRates(res);
//     }
//     yield put(loadShippingRatesAsync(shipRatesFormat, res.FedexRateError));

//   }
//   catch (error) {
//     yield put(loadShippingRatesAsyncFail(error));
//   }
// }

/** FUNCTION TO PARSE CART RESPONSE */
export function parseOrderInfoResponse(response) {

  let parsedOrderInfoResponse = {
    cost: '',
    customerInfo: '',
    vehicles: ''
  };

  if (response.order) {
    let order = response.order;
    parsedOrderInfoResponse = {
      orderId: order.orderInfo.orderId,
      cost: parseCost(order),
      orderInfo: order.orderInfo,
      customerInfo: order.customerInfo,
      vehicles: parseVehicles(order),
      shipping: Object.assign(order.shipping, { shipAddressId: order.shipAddressId }),
      dealer: order.dealer
    }
  }

  return parsedOrderInfoResponse;
}

/*** FUNCTION TO FORMAT SHIPPING RATES **/
// export function formatShippingRates(response) {

//   let orderItemsResult = [];

//   if (response.orderItems) {
//     let orderItems = response.orderItems;
//     let currency = response.currency;
//     orderItems.forEach((orderItem) => {
//       let itemId = orderItem.orderItemId;
//       let shipChargesByShipMode = parseShipCharges(orderItem.shipChargesByShipMode, currency);
//       let orderItemPush = {orderItemId: itemId, shipChargesByShipMode: shipChargesByShipMode};
//       orderItemsResult.push(orderItemPush);
//     });
//   }

//   return orderItemsResult;
// }

/** FUNCTION TO PARSE COST **/
function parseCost(order) {

  const orderInCart = order || {};
  const orderInfo = orderInCart.orderInfo || {};
  let currency = 'USD';
  if (orderInfo.currency) {
    currency = orderInfo.currency;
  }
  const lang = 'en-EN';

  let formattedAmounts = {
    totalSubtotal: formatCurrency(orderInfo.subtotal, lang, currency),
    recordSetTotal: orderInfo.recordSetTotal,
    installation: formatCurrency(orderInfo.installation, lang, currency),
    total: formatCurrency(orderInfo.total, lang, currency),
    shipping: formatCurrency(orderInfo.fedEx, lang, currency),
    totalShippingTax: formatCurrency(orderInfo.totalShippingTax, lang, currency),
    totalAdjustment: formatCurrency(orderInfo.totalAdjustment, lang, currency),
    totalSalesTax: formatCurrency(orderInfo.totalSalesTax, lang, currency),
    totalTax: formatCurrency(orderInfo.totalTax, lang, currency),
    adiTotalCost: formatCurrency(orderInfo.adiTotalCost, lang, currency)
  };

  if(orderInfo.adjustment.length > 0){
      let couponData = formatCouponData(orderInfo.adjustment, orderInfo.subtotal, lang, currency);
      formattedAmounts.coupons = couponData;
  }
  return formattedAmounts;

}

/** FUNCTION TO PARSE SHIPPING CHARGE **/
// function parseShipCharges(shipCharges, curr){
//     let currency = curr;
//     let lang = "en-EN";
//     let shipChargesResult = [];

//     shipCharges.forEach((charge)=>{
//       let chargeResult = "";

//       if(charge.rate === "0.00"){
//         chargeResult = {description: charge.description, carrierCode: charge.carrierCode, shipModeId: charge.id};
//       }else{
//         let rate = new Intl.NumberFormat(lang, {style: 'currency', currency: currency}).format(Number(charge.rate));
//         chargeResult = {description: charge.description + " " + rate, carrierCode: charge.carrierCode, shipModeId: charge.id};
//       }

//       shipChargesResult.push(chargeResult);
//     });

//     return shipChargesResult;

// }

/** FUNCTION TO FORMAT CURRENCY **/
function formatCurrency(amount, lang, currency) {
    if (!amount || amount === null || amount === "0") {
        amount = '';
    } else {
        amount = new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: currency
        }).format(Number(amount));
    }
    return amount;
}


function formatCouponData(couponData, totalSubtotal, lang, currency) {
    let coupons = {
        manufacturer_list: [],
        dealer_list: [],
        shipping_list: [],
        familyFirst_list: []
    }
    if(couponData.length > 0){
        couponData.forEach(function(fee){
            if(fee.usage === 'Discount' && fee.promotionType === 'GM'){
                coupons.manufacturer_list.push({"manufactureCoupon": fee.amount, "manufacturePromotionCode": fee.promotionCode, "manufacturePromotionId": fee.promotionId});
            }
            else if(fee.usage === 'Discount' && fee.promotionType === 'DEALER'){
                coupons.dealer_list.push({"dealerCoupon": fee.amount, "dealerPromotionCode": fee.promotionCode, "dealerPromotionId": fee.promotionId});
            }
            else if(fee.promotionType === 'SHP'){
                coupons.shipping_list.push({"shippingCoupon": formatCurrency(fee.amount, lang, currency), "shippingPromotionCode": fee.promotionCode, "shippingPromotionId": fee.promotionId});
            }
            else if(fee.promotionType === 'FF'){
                coupons.familyFirst_list.push({"familyFirstCoupon": fee.amount, "familyFirstPromotionCode": fee.promotionCode, "familyFirstPromotionId": fee.promotionId});
            }
        });
    }
    if(coupons.manufacturer_list.length > 0 || coupons.familyFirst_list.length > 0 || coupons.dealer_list.length > 0){
        let subtotal = Number(parseFloat(totalSubtotal).toFixed(2));
        if(coupons.manufacturer_list.length > 0){
            coupons.manufacturer_list.forEach(function(coup){
                subtotal = subtotal + coup.manufactureCoupon;
            });
            coupons.afterManufacturer = formatCurrency(subtotal, lang, currency);
            coupons.manufacturer_list.forEach(function(coup){
                coup.manufactureCoupon = new Intl.NumberFormat(lang, {
                    style: 'currency',
                    currency: currency
                }).format(Number(coup.manufactureCoupon));
            })
        }
        else if(coupons.familyFirst_list.length > 0){
            coupons.familyFirst_list.forEach(function(coup){
                subtotal = subtotal + coup.familyFirstCoupon;
            });
            coupons.afterFamilyFirst = formatCurrency(subtotal, lang, currency);
            coupons.familyFirst_list.forEach(function(coup){
                coup.familyFirstCoupon = new Intl.NumberFormat(lang, {
                    style: 'currency',
                    currency: currency
                }).format(Number(coup.familyFirstCoupon));
            })
        }
        if(coupons.dealer_list.length > 0){
            coupons.dealer_list.forEach(function(coup){
                subtotal = subtotal + coup.dealerCoupon;
            });
            coupons.afterDealer = formatCurrency(subtotal, lang, currency);
            coupons.dealer_list.forEach(function(coup){
                coup.dealerCoupon = new Intl.NumberFormat(lang, {
                    style: 'currency',
                    currency: currency
                }).format(Number(coup.dealerCoupon));
            })
        }
    }
    return coupons;
}

/** FUNCTION TO PARSE VEHICLE **/
function parseVehicles(order) {
  let vehicles = {
    orderItems: [],
    vehiclesInfo: {}
  };
  if (order.vehicles) {

    vehicles = order.vehicles.map(function (x) {

      return {
        orderItems: x.orderItems,
        vehiclesInfo: Object.assign(x.vehiclesInfo, { vin: x.vinNumber })
      }

    });

  }
  return vehicles;

}
