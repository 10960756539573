import AppSettings from "../../core/AppSettings";
import { jsonToUrlParams } from "../../shared/Utils/Utils";
import accHistory from "../App/History";

// Helper function that returns a key, given an object and a value
export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

export const removeFacetFromUrl = (
    removeFacet = "",
    clearAll = false,
    urlParams = "",
    pagePath = "search",
    facetData = []
) => {
    let facetsArr = getFacetsfromFacetData(facetData);
    if (facetsArr) {
        if (clearAll) {
            // Clear all of the possible facets as per the facetData object.
            for (let facet of facetsArr) {
                if (urlParams[facet]) {
                    delete urlParams[facet];
                }
            }
        } else {
            // Iterate through the urlParams and see if removeFacet matches any of the url params
            for (let index in facetsArr) {
                // if one of the facets items in the array matches with a url param.
                if (urlParams[facetsArr[index]]) {
                    // then check if the removeFacet arg matches the value in the url parameter.
                    if (urlParams[facetsArr[index]].includes(",")) {
                        // TODO, multiple select facets will change this logic. look for commas when they are available
                    } else {
                        // facets with only one selected under that facet category if they match the passed arg.
                        if (urlParams[facetsArr[index]] == removeFacet) {
                            delete urlParams[facetsArr[index]];

                            // Edge case for ACDELCO -- if Make is removed, also remove model
                            if (AppSettings.isGenericPartsSite) {
                                if (facetsArr[index].toLowerCase() == "make") {
                                    delete urlParams.model;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return pagePath + jsonToUrlParams(urlParams);
};

// Get the facets from the backend response, so that we know which parameters to look for.
export const getFacetsArray = (facets) => {
    // Step 1 - get the potential facets from the data returned by backend
    const facetArr = getFacetsfromFacetData(facets);
    // Don't continue if no facets facets found.
    if (!facetArr.length) {
        return [];
    }

    // Step 2 - out of the potential facets, find the ones that exist in the url.
    const url = AppSettings.urlParameters();
    const tagArr = [];
    // iterate through potential facets and compare to the url parameters and find matching ones
    for (let i = 0; i < facetArr.length; i++) {
        // if the url object has the key (parameter), then push the value to the tagArr
        if (url[facetArr[i]]) {
            tagArr.push(url[facetArr[i]]);
        }
    }
    return tagArr;
};

const getFacetsfromFacetData = (facets) => {
    const facetArr = [];
    if (typeof facets == "object" && facets.length) {
        facets.forEach((element, index) => {
            // Avoid index 0 and any objects with classification name.
            if (index > 0 && element.name.toLowerCase() != "classification") {
                switch(element.name.toLowerCase()){
                    case "bodytype":
                        element.name = "body";
                        break;
                    case "wheelbase":
                        element.name = "wheel";
                        break;
                    case "drivetype":
                        element.name = "drive";
                        break;
                    case "enginebase":
                        element.name = "engine";
                        break;
                    default:
                        break;        
                }
                facetArr.push(element.name.toLowerCase());
            }
        });
    }

    return facetArr;
};

/**
 * Checks if an objects fitment details is identical to 
 * @param {*} currObj - object to compare fitment to urlParams object.
 * @returns true if fitment details for both objects match, false otherwise.
 */ 
 export const checkFitmentWithUrlParams = (currObj) =>{
    const urlParams = AppSettings.urlParameters();
    const fitmentItems = ["bodyNumDoors", "bodyId", "driveId", "engineId", "make", "model", "trim", "wheelId", "year"];
    for(const i of fitmentItems){
        if(urlParams[i]?.toLowerCase()!==currObj[i]?.toLowerCase()){
            return false;
        }
    }
    return true;
}


export const syncUrlWithFitmentCookie = (sessionReducerData, initialRender) =>{
    const URLLocation = location.pathname.split("/")[1];
    const urlParameters = AppSettings.urlParameters();
    if(URLLocation === "categories" || URLLocation === "product" || URLLocation ==="search" && !initialRender.current){
        //if latest fitment is using vin, compare vins
        if(sessionReducerData.vin){ 
            if(urlParameters.vin !== sessionReducerData.vin){
                urlParameters["vin"] = sessionReducerData.vin;
                delete urlParameters["year"];
                delete urlParameters["make"];
                delete urlParameters["makeId"];
                delete urlParameters["model"];
                delete urlParameters["modelId"];
                delete urlParameters["body"];
                delete urlParameters["bodyId"];
                delete urlParameters["trim"];
                delete urlParameters["trimId"];
                delete urlParameters["drive"];
                delete urlParameters["driveId"];
                delete urlParameters["engine"];
                delete urlParameters["engineId"];
                delete urlParameters["wheel"];
                delete urlParameters["wheelId"];
                delete urlParameters["bodyNumDoorsId"];
                delete urlParameters["bodyNumDoors"];
                accHistory.replace(location.pathname+jsonToUrlParams(urlParameters));
            }
        }
        else{
            let redirect;
            const fitmentParams = ["year", "make", "makeId", "model", "modelId", "body", "bodyId", "trim", "trimId", "drive", "driveId", "engine", "engineId", "wheel", "wheelId", "bodyNumDoorsId"]
            for(const param of fitmentParams){
                if(sessionReducerData.vehicleConfig?.[param]){
                    if(param==="bodyNumDoorsId"){
                        if(sessionReducerData.vehicleConfig[param]!==urlParameters.bodyNumDoors){
                            urlParameters.bodyNumDoors=sessionReducerData.vehicleConfig[param];
                            redirect=true;
                        }
                    }
                    if(sessionReducerData.vehicleConfig?.[param]!==urlParameters[param]){
                        urlParameters[param] = sessionReducerData.vehicleConfig[param];
                        redirect=true;
                    }
                }
                else{
                    delete urlParameters[param];
                }
            }
            
            delete urlParameters["vin"];
            if(redirect){
                accHistory.replace(location.pathname+jsonToUrlParams(urlParameters));
            }
        

        }

    }
}