import React, { useState, useEffect } from 'react';
import HistoryShipStatus from '../HistoryShipStatus/HistoryShipStatus';
import i18n from '../../../..//core/i18n/i18n';
import i18next from 'i18next';

function HistoryOrderItem(props) {

    const { orderInfo } = props;
    const [orderItem, setOrderItem] = useState({});
    const [formattedTotal, setFormattedTotal] = useState("");
    const language = i18n.language;
    const imageSize = "/640X640";

    useEffect(() => {
        if (props.orderItem.items != undefined && props.orderItem.items[0] != undefined) {
            setOrderItem(props.orderItem.items[0]);

            let parsedTotalString = new Intl.NumberFormat(language, {
                style: 'currency',
                currency: orderInfo.currency
            }).format(Number(props.orderItem.items[0].total));

            setFormattedTotal(parsedTotalString);
        }
    }, [props.orderItem]);

    return (
        <div className="ac-history-order-item-wrapper small-12 grid-column-alignment-left columns ac-nopadding-x">
            <div className="small-12 medium-12 large-6 grid-column-alignment-left columns ac-nopadding-x">
                <div className="small-6 grid-column-alignment-left columns ac-nopadding-x">
                    <img className="ac-history-image-thumb" src={orderItem.imageName + imageSize}></img>
                </div>
                <div className="ac-history-order-item-description small-6 grid-column-alignment-left columns ac-nopadding-x">
                    <div className="small-12 q-body1">{orderItem.partName}</div>
                    <div className="small-12 q-body3">{i18next.t('LABEL_COMMON_QUANTITY')} : {orderItem.quantity}</div>
                </div>
            </div>
            <div className="small-6 medium-6 large-3 ac-history-order-item-ship columns grid-column-alignment-center ac-nopadding-x">
                <HistoryShipStatus></HistoryShipStatus>
            </div>
            <div className="small-6 medium-6 large-3 grid-column-alignment-right columns ac-nopadding-x">
                <div className="q-body1 ac-history-order-item-price ac-order-desc-value">{formattedTotal}</div>
            </div>
        </div>
    )
}

export default HistoryOrderItem