import * as type from './RewardsActionTypes';

//----------------------- REWARDS GET REDEMPTION INFO ACTIONS ----------------------------
export const getMemberRedemptionInfo = (transactionPrice) => {
    const action = {
        type: type.REWARDS_GET_MEMBER_REDEMPTION_INFO,
        transactionPrice: transactionPrice,
    }
    return action;
}

export const setMemberRedemptionInfo = (redemptionDollars, redemptionPoints) => {
    const action = {
        type: type.REWARDS_SET_MEMBER_REDEMPTION_INFORMATION,
        redemptionDollars: redemptionDollars,
        redemptionPoints: redemptionPoints,
    }
    return action;
}

export const setMemberRedemptionInfoFailed = (error) => {
    const action = {
        type: type.REWARDS_SET_MEMBER_REDEMPTION_INFORMATION_FAILED,
        memberRedemptionInfoError: error
    }
    return action;
}

//----------------- REWARDS EARN API ACTIONS -----------------------------------------------
export const setRewardsPotentialPoints = (potentialPoints) => {
    const action = {
        type: type.REWARDS_SET_POTENTIAL_POINTS_INFORMATION,
        potentialPoints: potentialPoints
    }
    return action;
}

export const setPotentialPointsInfoFailed = (potentialPointsInfoError) => {
    const action = {
        type: type.REWARDS_SET_POTENTIAL_POINTS_INFORMATION_FAILED,
        potentialPointsInfoError: potentialPointsInfoError
    }
    return action;
}

export const getRewardsPotentialPointsInfo = (amount) => {
    const action = {
        type: type.REWARDS_GET_POTENTIAL_POINTS_INFO,
        amount: amount,
    }
    return action;
}
//--------------------------------------------------------------------
export const getPdPotentialPoints = (memberNumber, price) => {
  const action = {
      type: type.LOYALTY_GET_PD_POTENTIAL_POINTS,
      memberNumber: memberNumber,
      price: price
  }
  return action;
}

//************** Rewards Header *********************\\
export const getRewardsHeaderPoints = () => {
    const action = {
        type: type.REWARDS_GET_HEADER_REWARDS_POINTS
    }
    return action;
}


export const setRewardsHeaderPoints = (rewardsStatus, totalPoints, isEnrolledInRewards, attributeValueGMRewards,
                                       currentTierName, applyForGMCard, nonVehicleRedeemDollars, nonVehicleRedeemPoints,  canRedeem,
                                       nonVehicleRedeemActualDollars, nonVehicleRedeemActualPoints) => {
    const action = {
        type: type.REWARDS_SET_HEADER_REWARDS_POINTS,
        rewardsStatus: rewardsStatus,
        totalPoints: totalPoints,
        isEnrolledInRewards: isEnrolledInRewards,
        attributeValueGMRewards: attributeValueGMRewards,
        currentTierName: currentTierName,
        applyForGMCard:applyForGMCard,
        nonVehicleRedeemDollars:nonVehicleRedeemDollars,
        nonVehicleRedeemPoints: nonVehicleRedeemPoints,
        canRedeem:canRedeem,
        nonVehicleRedeemActualDollars:nonVehicleRedeemActualDollars,
        nonVehicleRedeemActualPoints: nonVehicleRedeemActualPoints

    }
    return action;

}


export const clearRewardsHeaderPoints = () => {
    const action = {
        type: type.REWARDS_CLEAR_HEADER_REWARDS_POINTS,
    }
    return action;
}

export const setRewardsEnrollmentStatusFetched = () => {
    const action = {
        type: type.REWARDS_SET_ENROLLMENT_STATUS_FETCHED
    }
    return action;
}

export const setRewardsHeaderPointsFailed = (error) => {
    const action = {
        type: type.REWARDS_SET_HEADER_REWARDS_POINTS_FAILED,
        rewardsError: error
    }
    return action;
}


//************** Rewards Cart Potential Points *********************\\
export const getCartPotentialRewardsPoints = (cartData) => {
    const action = {
        type: type.REWARDS_GET_CART_POTENTIAL_POINTS,
        cartData: cartData
    }
    return action;
}

export const setCartPotentialRewardsPoints = (potentialPoints) => {
    const action = {
        type: type.REWARDS_SET_CART_POTENTIAL_POINTS,
        potentialPoints: potentialPoints
    }
    return action;
}

export const setCartPotentialRewardsPointsFailed = (error) => {
    const action = {
        type: type.REWARDS_SET_CART_POTENTIAL_POINTS_FAILED,
        error: error
    }
    return action;
}

export const updateItemPotentialRewardsPoints = (itemId, quantity) => {
    const action = {
        type: type.REWARDS_UPDATE_ITEM_POTENTIAL_POINTS,
        itemId: itemId,
        quantity: quantity
    }
    return action;
}

export const removeItemPotentialRewardsPoints = (itemId) => {
    const action = {
        type: type.REWARDS_REMOVE_ITEM_POTENTIAL_POINTS,
        itemId: itemId
    }
    return action;
}

export const updateRewardsPoints = (orderId, rewardsPointsToRemove, rewardsPointsToApply) => {
    const action = {
        type: type.UPDATE_REWARDS_POINTS,
        orderId: orderId,
        rewardsPointsToRemove: rewardsPointsToRemove,
        rewardsPointsToApply: rewardsPointsToApply
    }
    return action;
}

export const setRedeemRewardsError = (error) => {
    const action = {
        type: type.SET_REDEEM_REWARDS_FAILURE,
        redeemRewardsError: error
    }
    return action;
}

export const disableRewardsInput = (disable) => {
  const action = {
      type: type.REWARDS_DISABLE_USER_INPUT,
      disable: disable
  }
  return action;
}
