import React from "react";
import AppSettings from "../../../core/AppSettings";
import JellybeanImage from "../../../shared/JellybeanImage/JellybeanImage";
import { deleteFitmentCookie } from "../../../shared/VehicleSelect/CookieService/FitmentData";
import { useTranslation } from "react-i18next";
import { ymmRedirect, vinRedirect } from "../../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectWorker";
import { RESET_VEHICLE_INFO } from "../../../shared/Session/VehicleInfo/VehicleInfoActionType";
import store from "../../../core/Redux/Store";
import { resetVehicleConfig } from "../../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";
import { resetVehicleSelectState} from "../../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionCreator"
import { isValidValue } from "../../../shared/Validator/Validator";
import { useSelector } from "react-redux";
/**
 * This component is to display the current vehicle on the homepage once a car has been fitted.
 * you can fit another vehicle via the "Change Vehicle" button
 *
 * @param {Object} currentVehicle redux store session object
 * @param {function} setShowCurrentVehicle hook used to hide this component
 *
 * @returns {JSX.Element}
 */
function CurrentVehicle({ currentVehicle, setShowCurrentVehicle, isHomePage }) {
    const { t } = useTranslation();
    let year = "";
    let model = "";
    let bodyStyle = "";
    let trim = "";
    let engine = "";
    let boxLength = "";
    const urlParameters = AppSettings.urlParameters();
    const fitmentCheckInfo = useSelector((state) => state.FitmentCheckReducer.fitmentSelections);

    year = currentVehicle.year ? currentVehicle.year : "";
    model = currentVehicle.model ? currentVehicle.model : "";
    bodyStyle = currentVehicle.vehicleConfig.body ? currentVehicle.vehicleConfig.body : "";
    trim = currentVehicle.vehicleConfig.trim ? currentVehicle.vehicleConfig.trim : "";
    engine = currentVehicle.vehicleConfig.engine ? currentVehicle.vehicleConfig.engine : "";
    boxLength = currentVehicle.vehicleConfig.wheel && fitmentCheckInfo.wheelFitmentSelection.length > 1 ? currentVehicle.vehicleConfig.wheel : "";

    if (urlParameters.vin) {
        year = currentVehicle.year || "";
        model = currentVehicle.model || "";
        bodyStyle = currentVehicle?.vehicleConfig?.body || currentVehicle.bodyStyle || "";
        trim = currentVehicle?.vehicleConfig?.trim || currentVehicle.trim || "";
        engine = currentVehicle?.vehicleConfig?.engine || currentVehicle.engine || "";
        boxLength = currentVehicle?.vehicleConfig?.wheel || currentVehicle.wheel || "";
    }

    let fitment = [boxLength, trim, engine];
    let concatFitment = bodyStyle;
    fitment.forEach((element) => {
        if ( isValidValue(element) && (element !== '-') && (element !== 'U/K')) {
            concatFitment = concatFitment + ", " + element;
        }
    });

    function allProductRedirect(currentVehicle){
        let categoryId ='';
        let clpRedirect = false;
        let clpCategory = '';
        if(currentVehicle.vin){
            vinRedirect(currentVehicle.vin, categoryId, clpRedirect, clpCategory)
        } else {
            // if it doesnt have engineId that means we are in a partial fitment flow
            if(!currentVehicle.vehicleConfig.engineId) {
                ymmRedirect(
                    currentVehicle.year,
                    currentVehicle.make,
                    currentVehicle.model,
                    currentVehicle.vehicleConfig.modelId,
                    currentVehicle.vehicleConfig.bodyId,
                    currentVehicle.vehicleConfig.body
                )
            }else {
                // full fitment flow
                ymmRedirect(
                    currentVehicle.year,
                    currentVehicle.make,
                    currentVehicle.model,
                    currentVehicle.vehicleConfig.modelId,
                    currentVehicle.vehicleConfig.bodyId,
                    currentVehicle.vehicleConfig.body,
                    categoryId ,
                    clpRedirect,
                    clpCategory,
                    currentVehicle.vehicleConfig.wheelId,
                    currentVehicle.vehicleConfig.wheel,
                    currentVehicle.vehicleConfig.trimId,
                    currentVehicle.vehicleConfig.trim,
                    currentVehicle.vehicleConfig.driveId,
                    currentVehicle.vehicleConfig.drive,
                    currentVehicle.vehicleConfig.engineId,
                    currentVehicle.vehicleConfig.engine,
                    currentVehicle.vehicleConfig.bodyNumDoorsId
                    
                )
            }
        }
    }
    //Function for change vehicle button 
    function resetPartialFit() {
            deleteFitmentCookie().then(response => store.dispatch({ type: RESET_VEHICLE_INFO }));
            store.dispatch(resetVehicleSelectState());
            store.dispatch(resetVehicleConfig());
            setShowCurrentVehicle(false);
        }   

    let ShopVehicleBtn = (
        <button 
            className="q-primary-button stat-button-link" 
            id="shop-this-vehicle-btn" 
            type="submit"
            data-dtm = {"shopping:" + currentVehicle.year + " " + currentVehicle.model}
            onClick={()=>{
                allProductRedirect(currentVehicle)
            }}
        >
            {t("LABEL_COMMON_SHOP_CURRENT_VEHICLE")}
        </button>
    );
    let changeVehicleLink = (
        <a
            id="ac-change-vehicle"
            data-dtm = {"shopping:" + currentVehicle.year + " " + currentVehicle.model}
            className= 'ac-change-vehicle stat-text-link'
            onClick={() => resetPartialFit()}
        >
            {t("LABEL_COMMON_CHANGE_VEHICLE")}
        </a> 
    );

    return (
                    <div id = "ac-current-vehicle-wrapper" className="ac-current-vehicle-wrapper">
                        <div id = "ac-homepage-static-vehicle-heading" className="ac-homepage-static-vehicle-heading">
                            {t("LABEL_SEARCH_CURRENTLY_SHOPPING_TITLE_CASE")}
                        </div>
                        <div id = "current-vehicle-txt-container" className="current-vehicle-txt-container">
                            <div id = "ac-current-vehicle-txt-box" className="ac-current-vehicle-txt-box">
                                <JellybeanImage />
                                <div id = "current-vehicle-text" className="current-vehicle-text">
                                    <b>
                                        {year} {model}{" "}
                                    </b>
                                    {changeVehicleLink}
                                    <p> {concatFitment}</p>
                                    
                                </div>
                            </div>
                            <div id = "ac-current-vehicle-clickables" className="ac-current-vehicle-clickables">
                                {isHomePage ? ShopVehicleBtn: null}
                            </div>
                        </div>   
                        </div>
    );
}

export default CurrentVehicle;
