import React, {useEffect, useState} from 'react';
import {Translation} from 'react-i18next';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import AppSettings from '../../../core/AppSettings';
import {getBaseMarketingSpotActivityData} from "../../Validator/Validator";
import {getCartPotentialPointsEspot} from "./CartRewardsPointsService";
import {formatNumber} from "../../Internationalization/FormattedNumbers";
import {
    useReplaceAllNamedDisclaimers
} from '../../../modules/App/Footer/FooterDisclaimerLabel';
import { setPotentialPointsInfoFailed } from '../../Rewards/RewardsRedux/RewardsActionCreator';
import store from '../../../core/Redux/Store';

const CartRewardsPoints = ({cartInfo, isRewardsUser, isRewardsActive, error}) => {

    const [cartMessage, setCartMessage] = useState("");

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers()
    const createMarkup = (text) => {
        return {
            __html: text
        };
    }


    useEffect(() => {
        // Display points for non rewards members i.e. guests and non enrolled status (users 1 & 2)
        // "Active" rewards members (users 3 & 4) with tier name being able
        // to be retrieved from rewards If rewards is not in "Active" status we are suppressing any
        // rewards messaging
        if (isRewardsActive || !isRewardsUser) {
            getRewardsPotentialPointsMessage()
            //check for a change in potential points in order to run the call to espot again
            // to get an update on the potential points message due to the change in the
            // quantity in the cart
        }
    }, [isRewardsActive, cartInfo]);


    //get Espot data for cart potential points message
    const getRewardsPotentialPointsMessage = async () => {
        try {
            const response = await getCartPotentialPointsEspot();
            const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(response.data, 0);
            if (baseMarketingSpotActivityData[0].marketingContentDescription[0]) {
                const noTierMessage = baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText;
                //If tier name does not come back we will replace it with an empty string
                //format potential points to add a comma if large enough e.g 4225 -> 4,225
                setCartMessage(noTierMessage
                    .replace("[TierName]", "")
                    .replace(/([1-9][0-9]+)/, formatNumber(parseInt(noTierMessage.match(/([1-9][0-9]+)/)))));
            } else {
                store.dispatch(setPotentialPointsInfoFailed("Unable to retrieve content from espot"));
            }
        } catch
            (e) {
                store.dispatch(setPotentialPointsInfoFailed("Unable to retrieve content from espot"));
                console.warn("Unable to retrieve content from espot -->", e);
        }
    };

    const brand = AppSettings.currentSite.label;

    if (error) {
        return (
            <>
                <Translation>
                    {(t) => (
                        <ErrorMessage icon={true} message={t("LABEL_REWARDS_SERVICE_ERROR", {brand})}/>
                    )}
                </Translation>
                <hr className="pg-header-hr"/>
            </>
        );
    } else if (cartInfo && cartMessage) {
        return (
            <div id="cart-rewards-points-information"
                 className="ac-pg-header-wrapper small-12 grid-column-alignment-left columns">
                <div dangerouslySetInnerHTML={createMarkup(
                    replaceDisclaimers(cartMessage)
                )}/>
                <hr className="pg-header-hr"/>
            </div>

        );
    } else {
        return null;
    }
}

export default CartRewardsPoints;
