import { call, put, select } from "redux-saga/effects";
import { reset } from "redux-form";
import AppSettings from "../../../core/AppSettings";
import {
    loadMakes,
    loadMakesFail,
    loadModels,
    loadModelsFail,
    setYears,
    setVIN,
    setVINFail,
    resetVehicleSelectState,
    setT3DefaultMake,
    setModelToModelID
} from "./VehicleSelectActionCreator";
import { getDefaultMake, getYears, getMakes, getVehicleSelectData, getVINData } from "./VehicleSelectService";
import accHistory from "../../../modules/App/History";
import { resetPartsRPOAndColorCode } from "../../ViewOnVehicle/ViewOnVehicleRedux/VoVActionCreator";
import vaultConstants from "../../../../config/vault_constants";
import { getUrlCategory } from "../../Utils/Utils";
import { setShopWithoutFitment } from "../../../modules/Search/SearchService/SearchDataServiceActionCreator";
import { setFitmentCookie } from "../CookieService/FitmentData";
import { jsonToUrlParams } from "../../Utils/Utils";
import { isValidValue } from "../../Validator/Validator";

/**************************************************************/
// GET VALUES FROM REDUCERS FOR API CALLS
/**************************************************************/
// YMM
export const getYear = (state) => state.VehicleSelectReducer.selectedYear;
export const getMake = (state) => state.VehicleSelectReducer.selectedMake;
export const getModel = (state) => state.VehicleSelectReducer.selectedModel;
export const getBody = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.bodyStyle;
export const getBodyId = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.bodyStyleId;
export const getWheel = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.wheelBase;
export const getWheelId = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.wheelBaseId;
export const getTrim = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.trim;
export const getTrimId = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.trimId;
export const getDrive = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.driveType;
export const getDriveId = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.driveTypeId;
export const getEngine = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.engine;
export const getEngineId = (state) => state.VehicleConfigReducer.fitmentVehicleInfo.engineId;
export const getYearsToModels = (state) => state.VehicleSelectReducer.yearsToModels;
export const getYearsToMakes = (state) => state.VehicleSelectReducer.yearsToMakes;
export const getCategoryId = (state) => state.SearchLinkReducer.categoryId;
export const getMakeToCode = (state) => state.VehicleSelectReducer.makeToCode;
export const getModelToModelID = (state) => state.VehicleSelectReducer.modelToModelID;
export const getBodyNumDoorsID = (state) => state.VehicleConfigReducer.vehicleParams.bodyNumDoorsParam;
export const getClpRedirect = (state) => state.VehicleSelectReducer.clpRedirect;
export const getClpCategory = (state) => state.VehicleSelectReducer.clpCategory;
// VIN
export const getVin = (state) => state.form.VehicleSelectForm;

const clpFeatureFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2097306_CLP_ROUTE;
const featureCookieFlag = AppSettings.isLocalHost ? true : vaultConstants.FF_2246212_FITMENT_COOKIE;

/**************************************************************/
//               Handle YMM Select Fields
/**************************************************************/
export function* handleLoadYears(action) {
    const startingYear = action.startingYear;
    try {
        const response = yield call(getYears, startingYear);
        const years = parseRecords(response, "YEAR");

        // Dispatches a SET_YEARS action to set years into the vehicle select
        // dropdown.
        yield put(setYears(years));
        // getting the default make, if and only, for dealers and setting it
        if (AppSettings.isT3) {
            const responseDefaultMake = yield call(getDefaultMake);
            const defaultMake = parseDefaultMake(responseDefaultMake);
            if (defaultMake === "Cadillac") {
                yield put(setT3DefaultMake(defaultMake));
            }
        }
    } catch (error) {
        // Dispatches an ACTION_PAGE_LOAD_FAIL to handle the error.
        // On failure, uses default years. Ok to ignore.
        console.log("handleLoadYears error", error);
    }
}

export function* handleSelectYear() {
    /*only call for makes if it's t3*/
    if (AppSettings.isT3) {
        /*gotta check for default makes, and if it's Cadillac, default that make in the dropdown*/
        const responseDefaultMake = yield call(getDefaultMake);
        const defaultMake = parseDefaultMake(responseDefaultMake);
        //if it's t3, we'll call and set the makes and models
        yield callForMakes();
        if (defaultMake === "Cadillac") {
            yield callForModels();
        }
    } else {
        yield callForModels();
    }
}

export function* handleSelectMake() {
    if (AppSettings.isT3) {
        yield callForModels();
    }
}

function* callForMakes() {
    const year = yield select(getYear);
    const bac = AppSettings.bac;
    let yearsToMakes = yield select(getYearsToMakes);

    try {
        const response = yield call(getMakes, year, bac);
        const makes = parseRecords(response, "MAKENAME");
        yearsToMakes = { ...yearsToMakes, [year]: makes };

        // Dispatches a LOAD_MAKES action to load year and makes data into
        // the vehicle makes select dropdown.
        yield put(loadMakes(yearsToMakes, makes));
    } catch (error) {
        // Dispatches an ACTION_PAGE_LOAD_FAIL to handle the error.
        yield put(loadMakesFail(error));
    }
}

function* callForModels() {
    const year = yield select(getYear);
    const make = yield select(getMake);
    const makeToCode = yield select(getMakeToCode);
    let yearsToModels = yield select(getYearsToModels);

    try {
        const response = yield call(getVehicleSelectData, year, makeToCode[make]);
        const models = parseRecords(response, "MODELNAME");
        yearsToModels[year] = models;

        // Dispatches a LOAD_MODELS action to load year and model data into
        // the vehicle select dropdowns.
        yield put(loadModels(yearsToModels, models));

        // Sets model ID map for use with vehicle config.
        const modelToModelID = parseModelIDs(response);
        yield put(setModelToModelID(modelToModelID));
    } catch (error) {
        // Dispatches an ACTION_PAGE_LOAD_FAIL to handle the error.
        yield put(loadModelsFail(error));
    }
}

/**************************************************************/
//        Handle Vehicle Select Search (YMM and VIN)
/**************************************************************/
export function* handleVehicleSelectSearch(action) {
    const searchType = action.searchType;
    // action.UrlVin will be present when you are deeplink/refreshing page with vin in the URL it will do a vin search and set the cookie
    // in turn when this function checks for 'vin' its checking if its getting vin from vin search button click
    let vin = "";
    let categoryId, clpRedirect, clpCategory;

    if (!action.UrlVin) {
        const getVinFromForm = yield select(getVin);
        categoryId = yield select(getCategoryId);
        clpRedirect = yield select(getClpRedirect);
        clpCategory = yield select(getClpCategory);
        // Reset Shop Without Fitment if a new search was attempted.
        yield put(setShopWithoutFitment(false));
        if (getVinFromForm.values) {
            vin = getVinFromForm.values.vin;
        }
    }

    // if user has selected YMM and has not entered a vin,
    // redirect to search page
    if (searchType !== "vin") {
        // vehicle config
        const year = yield select(getYear);
        const make = yield select(getMake);
        const makeToCode = yield select(getMakeToCode);
        const makeCode = makeToCode[make];
        const model = yield select(getModel);
        const bodyId = yield select(getBodyId);
        const body = yield select(getBody);
        const wheelId = yield select(getWheelId);
        const wheel = yield select(getWheel);
        const trimId = yield select(getTrimId);
        const trim = yield select(getTrim);
        const driveId = yield select(getDriveId);
        const drive = yield select(getDrive);
        const engine = yield select(getEngine);
        const engineId = yield select(getEngineId);
        const bodyNumDoorsId = yield select(getBodyNumDoorsID); // why do we need this?
        const modelToModelID = yield select(getModelToModelID);
        const modelId = modelToModelID[model];

        if (year && make && model) {
            yield put(resetPartsRPOAndColorCode());

            const partialFitmentObj = {
                vin: "",
                year: year,
                make: make,
                makeId: makeCode ? makeCode : "",
                model: model,
                modelId: modelId ? modelId : "",
                body: body ? body : "",
                bodyId: bodyId ? bodyId : "",
                wheel: "",
                wheelId: "",
                trim: "",
                trimId: "",
                drive: "",
                driveId: "",
                engine: "",
                engineId: "",
                bodyNumDoorsId: bodyNumDoorsId ? bodyNumDoorsId : ""
            };

            const fitmentObj = {
                vin: "",
                year: year,
                make: make,
                makeId: makeCode,
                model: model,
                modelId: modelId,
                body: body ? body : "",
                bodyId: bodyId ? bodyId : "",
                wheel: wheel ? wheel : "",
                wheelId: wheelId ? wheelId : "",
                trim: trim ? trim : "",
                trimId: trimId ? trimId : "",
                drive: drive ? drive : "",
                driveId: driveId ? driveId : "",
                engine: engine ? engine : "",
                engineId: engineId ? engineId : "",
                bodyNumDoorsId: bodyNumDoorsId ? bodyNumDoorsId : ""
            };
            if (searchType === "partial") {
                if (featureCookieFlag) {
                    yield call(setFitmentCookie, partialFitmentObj); //wait for session reducer to be updated before going to search.
                }
                ymmRedirect(year, make, model, modelId, bodyId, body, categoryId, clpRedirect, clpCategory);
            } else {
                if (featureCookieFlag) {
                    yield call(setFitmentCookie, fitmentObj); //wait for session reducer to be updated before going to search.
                }

                if (AppSettings.isT3) {
                    yield put(resetVehicleSelectState());
                }
                ymmRedirect(
                    year,
                    make,
                    model,
                    modelId,
                    bodyId,
                    body,
                    categoryId,
                    clpRedirect,
                    clpCategory,
                    wheelId,
                    wheel,
                    trimId,
                    trim,
                    driveId,
                    drive,
                    engineId,
                    engine,
                    bodyNumDoorsId
                );
            }
        }
    }

    /**************************************************************/
    //               Handle VIN Input Search
    /**************************************************************/
    // call for vin data only if user entered a vin

    if (searchType === "vin" && (vin || action.UrlVin)) {
        let VINDataResponse;
        try {
            //if variable vin is present that means we are coming from vin fitment button click.
            if (vin) {
                VINDataResponse = yield call(getVINData, vin);
            } else {
                // if we are running this call, we are getting vin from URL deep link
                VINDataResponse = yield call(getVINData, action.UrlVin);
            }
            let currentYear = new Date().getFullYear();

            // If VIN is invalid, return error message response
            if (VINDataResponse.response?.errors) {
                const invalidVIN = parseVINResponse(VINDataResponse.response);
                yield put(setVIN(invalidVIN, vin, "VIN_ERROR"));
            }

            //if VIN is for a different store brand, return vehicle info.
            //handle this scenario in vehicleSelectVin.js
            else if (VINDataResponse.response?.error) {
                const invalidVIN = parseVINResponse(VINDataResponse.response);
                yield put(setVIN(invalidVIN, vin, "LABEL_BRAND_ERROR"));
                return;
            }
            // checking to see if the vehicle is older than 10 years
            else if (
                isValidValue(VINDataResponse.vehicleInfo.year) &&
                isValidValue(VINDataResponse.vehicleInfo.model) &&
                currentYear - VINDataResponse.vehicleInfo.year > 10
            ) {
                yield put(setVIN(VINDataResponse, vin, "LABEL_CHECK_VIN_NONGM_ERROR"));
                return;
            }

            // Check if valid VIN
            else if (
                isValidValue(VINDataResponse.vehicleInfo.year) &&
                isValidValue(VINDataResponse.vehicleInfo.model)
            ) {
                const vehicleInfo = parseVINResponse(VINDataResponse);
                const vehicleMakeFromVIN = vehicleInfo.make;
                const storeBrand = AppSettings.sitesStoreMap[AppSettings.storeId].key;
                const vinObj = {
                    vin: vin ? vin : action.UrlVin,
                    year: vehicleInfo.year ? vehicleInfo.year : "",
                    make: vehicleInfo.make ? vehicleInfo.make : "",
                    makeId: vehicleInfo.vcdb.makeId ? vehicleInfo.vcdb.makeId : "",
                    model: vehicleInfo.model ? vehicleInfo.model : "",
                    modelId: vehicleInfo.vcdb.modelId ? vehicleInfo.vcdb.modelId : "",
                    body: vehicleInfo.bodyStyle ? vehicleInfo.bodyStyle : "",
                    bodyId: vehicleInfo.vcdb.bodyStyleConfigId ? vehicleInfo.vcdb.bodyStyleConfigId : "",
                    wheel: vehicleInfo.wheelBase ? vehicleInfo.wheelBase : "",
                    wheelId: vehicleInfo.vcdb.wheelBaseId ? vehicleInfo.vcdb.wheelBaseId : "",
                    trim: vehicleInfo.trim ? vehicleInfo.trim : "",
                    drive: vehicleInfo.driveType ? vehicleInfo.driveType : "",
                    driveId: vehicleInfo.vcdb.driveTypeId ? vehicleInfo.vcdb.driveTypeId : "",
                    engine: vehicleInfo.engine ? vehicleInfo.engine : "",
                    engineId: vehicleInfo.vcdb.engineId ? vehicleInfo.vcdb.engineId : ""
                };

                //if VIN is valid, and brand is valid for current store (or store is T3)
                //return vehicle info and send user to search page
                if (vehicleMakeFromVIN.toUpperCase() == storeBrand.toUpperCase() || AppSettings.isT3) {
                    yield put(setVIN(vehicleInfo, vin));
                    yield put(reset("VehicleSelectForm"));
                    yield put(resetVehicleSelectState());
                    yield put(resetPartsRPOAndColorCode());

                    if (featureCookieFlag) {
                        setFitmentCookie(vinObj);
                    }
                    //redirect only if vin is comming from vin fitment button click
                    if (vin) {
                        vinRedirect(vin, categoryId, clpRedirect, clpCategory);
                    }
                }
            }
            // catch other invalid vin errors with valid response (e.g. input a legacy VIN (pontiac) or a VIN that doesn't exist (11111111))
            else {
                yield put(setVIN(VINDataResponse, vin, "LABEL_VIN_ACCESSORIES_UNAVAILABLE_ERROR"));
            }
        } catch (error) {
            yield put(setVINFail(error));
        }
    }
}

/**************************************************************/
//                        Parsing JSON
/**************************************************************/
const parseRecords = (response, key) => {
    let results = [];
    if (response) {
        const records = response["records"];

        results = records.map((record) => record[key]);
    }

    return results;
};

const parseModelIDs = (response) => {
    let modelToModelID = {};
    if (response) {
        const records = response["records"];

        records.map((record) => {
            modelToModelID[record.MODELNAME] = record.MODEL_ID;
        });
    }
    return modelToModelID;
};

/**
 * @param response the dealer info array
 * the response is parsed for the Default Make, which is then compared to see if it is Cadillac
 * @returns {string} either Cadillac or null
 */
const parseDefaultMake = (response) => {
    let defaultMake = "";
    if (response && response.attributes) {
        defaultMake = response["attributes"]["MAKE"]["value"];
        console.warn("defaultMake", defaultMake);
    }
    //defaultMake should be either cadillac, or null. why? bc well...
    const setDefaultMake = setDefaultMakeToCadillacBecauseTheRequirementsAreRidiculous(defaultMake);
    return setDefaultMake;
};
/**
 * @param defaultMake is compared here to see if it's Cadillac.
 * @returns {string} either Cadillac or null
 */
const setDefaultMakeToCadillacBecauseTheRequirementsAreRidiculous = (defaultMake) => {
    let setDefaultMake = "";
    if (defaultMake.toUpperCase() === "CADILLAC") {
        setDefaultMake = "Cadillac";
    }
    return setDefaultMake;
};

const parseVINResponse = (response) => {
    let results = {};

    // if vin is valid
    if (response.vehicleInfo) {
        results = response.vehicleInfo;
    }

    // if vin is invalid
    if (response.errors) {
        results = response.errors[0];
    }

    if (response.error) {
        results = response.error;
    }

    return results;
};

export function ymmRedirect(
    year,
    make,
    model,
    modelId,
    bodyId,
    body,
    categoryId,
    clpRedirect,
    clpCategory,
    wheelId,
    wheel,
    trimId,
    trim,
    driveId,
    drive,
    engineId,
    engine,
    bodyNumDoorsId
) {
    //determines whether to redirect to Category Landing Page or Product Search Results.
    let URLLocation = window.location.pathname;
    let path = "/search";
    if (window.location.pathname === "/product/search") {
        path = "/product/search";
    } else if (clpFeatureFlag && !AppSettings.isT3) {
        if (clpCategory) {
            clpCategory = getUrlCategory(clpCategory);
        }
        if (clpRedirect) {
            path = "/categories/" + clpCategory;
        }
    }

    categoryId = AppSettings.urlParameters().categoryId || categoryId;
    const searchTerm = AppSettings.urlParameters().searchTerm || "";

    let params = {};
    year ? (params.year = year) : null;
    make ? (params.make = make) : null;
    model ? (params.model = model) : null;
    modelId ? (params.modelId = modelId) : null;
    body ? (params.body = body) : null;
    bodyId ? (params.bodyId = bodyId) : null;
    wheel ? (params.wheel = wheel) : null;
    wheelId ? (params.wheelId = wheelId) : null;
    trim ? (params.trim = trim) : null;
    trimId ? (params.trimId = trimId) : null;
    drive ? (params.drive = drive) : null;
    driveId ? (params.driveId = driveId) : null;
    engine ? (params.engine = engine) : null;
    engineId ? (params.engineId = engineId) : null;
    categoryId ? (params.categoryId = categoryId) : null;
    bodyNumDoorsId ? (params.bodyNumDoors = bodyNumDoorsId) : null;
    searchTerm ? (params.searchTerm = searchTerm) : null;

    params = path + jsonToUrlParams(params);

    if (AppSettings.isT3) {
        params = `${AppSettings.dealerName}${params}&bac=${AppSettings.bac}`;
    }

    if (URLLocation !== path) {
        accHistory.push(params);
    } else {
        accHistory.replace(params);
    }
}

export function vinRedirect(vin, categoryId, clpRedirect, clpCategory) {
    let path = "/search";
    if (window.location.pathname === "/product/search") {
        path = "/product/search";
    } else if (clpFeatureFlag && !AppSettings.isT3) {
        if (clpCategory) {
            clpCategory = getUrlCategory(clpCategory);
        }

        if (clpRedirect) {
            path = "/categories/" + clpCategory;
        }
    }

    let url = `${path}?vin=${vin}&categoryId=${categoryId}`;
    if (AppSettings.isT3) {
        url = `${AppSettings.dealerName}${url}&bac=${AppSettings.bac}`;
    }
    accHistory.push(url);
}
