import React, { useState, useEffect } from 'react';
import { Translation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import store from '../../../core/Redux/Store';
import AppSettings from '../../../core/AppSettings';
import { constructAzureLoginURL } from '../AuthenticationWorker';
import { showAuthModal } from '../AuthenticationActionCreator';
import Modal from '../../Modal';
import Button from '../../Button/Button';
import { Toggle } from '../../Hooks/Hooks';

function AuthenticationModal(props) {

    // Login URL values also used to redirect user back to page they were on
    const applicationState = { 'redirectPath': window.location.href };
    const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));
    const userFlow = AppSettings.azureLoginUserFlow;
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    const currentSite = AppSettings.currentSite.key;

    // Handle modal state to know when to show/hide it
    const [modalVisibility, setModalVisibility] = useState(false);

    // Handle login from modal
    function handleLogin() {
        store.dispatch(showAuthModal(false))
        window.location.href = constructAzureLoginURL(userFlow, clientId, applicationStateString, '/authenticate', currentSite);
    }

    function handleCloseModal() {
        // Hide modal
        Toggle(modalVisibility, setModalVisibility);
        store.dispatch(showAuthModal(false));
    }

    useEffect(() => {
        if (props.authData.showAuthModal && props.authData.registrationStatus == 'G') {
            Toggle(modalVisibility, setModalVisibility);
        }
    }, [props.authData.showAuthModal])


    if (modalVisibility) {
        // Get the phone number that users can use to call for help, if needed.
        const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

        return (
            <Modal
                open={modalVisibility}
                onClose={() => handleCloseModal()}
                closeOnOverlayClick={false}
                center>
                <Translation>
                    {(t) => (
                        <>
                            <div className="q-headline2 ac-capitalize">
                                {t('LABEL_AUTHENTICATION_LOGIN_FAILED_HEADER')}
                            </div>

                            <p>
                                {/* Error Message */}
                                {props.authData.errorMessage && props.authData.errorMessage ||
                                    <Trans i18nKey="LABEL_AUTHENTICATION_LOGIN_FAILED_ERROR_INSTRUCTIONS">
                                        {/* Not hardcoded text - this is done for interpoliation */}
                                        Contact us at: <a href={"tel:" + phoneNumber}>{{ phoneNumber }}</a>
                                    </Trans>
                                }
                            </p>

                            <div className='ac-auth-modal-cta-btn-wrapper ac-small-margintop'>
                                <Button
                                    isSecondary={true}
                                    localeKey="LABEL_COMMON_TRY_AGAIN"
                                    onClick={() => handleLogin()} />
                                <Button
                                    localeKey="LABEL_COMMON_CANCEL"
                                    onClick={() => handleCloseModal()} />
                            </div>
                        </>
                    )}
                </Translation>
            </Modal>
        )
    }
    else {
        return null
    }

}


const mapStateToProps = state => {
    return {
        authData: state.Authentication
    }
}

export default connect(mapStateToProps)(AuthenticationModal);