import AppSettings from '../../../core/AppSettings';
export const ContinueShoppingLink = (lastVehicleInfo)=>{
    //T1 Flow
    if(AppSettings.isT3){
        return T3Flow(lastVehicleInfo)
    }
    //T3 Flow
    else {
        return T1Flow(lastVehicleInfo)
    }
};

/** Testing for vin search ***/
const isVin = (lastVehicle)=>{
    return lastVehicle.vin !== '' && lastVehicle.vin !== undefined && lastVehicle.vin !== null
};

/*** Test if any of fitment data is available ***/
const checkFitmentStuff = (lastVehicle)=>{
    return (lastVehicle.vehicleInformation.bodyStyleId ||
            lastVehicle.vehicleInformation.wheelBaseId ||
            lastVehicle.vehicleInformation.trimId ||
            lastVehicle.vehicleInformation.driveTypeId ||
            lastVehicle.vehicleInformation.engineBaseId)
}




//T3 YMM Flow
const T3Flow = (lastVehicle)=>{
 const checkVin = isVin(lastVehicle);
 const isFitmentAvailable = checkFitmentStuff(lastVehicle);
 const urlWithFitmentInformation = addFitmentStuff(lastVehicle);

 if(checkVin){
     return `${AppSettings.dealerName}/search?vin=${lastVehicle.vin}&bac=${AppSettings.bac}`;
 }
 else {
     if (isFitmentAvailable){
         return `${AppSettings.dealerName}/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&model=${lastVehicle.vehicleInformation.model}&${urlWithFitmentInformation}&bac=${AppSettings.bac}`;
     }
     else{
         return `${AppSettings.dealerName}/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&model=${lastVehicle.vehicleInformation.model}&bac=${AppSettings.bac}`;
     }
 }

};

// T1 YMM Flow
const T1Flow = (lastVehicle)=>{
    const checkVin = isVin(lastVehicle);
    const isFitmentAvailable = checkFitmentStuff(lastVehicle);
    const urlWithFitmentInformation = addFitmentStuff(lastVehicle);

    if(checkVin){
        return `/search?vin=${lastVehicle.vin}`;
    }
    else {
        if (isFitmentAvailable){
            return `/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&model=${lastVehicle.vehicleInformation.model}${urlWithFitmentInformation}`;
        }
        else{
            return `/search?year=${lastVehicle.vehicleInformation.year}&make=${lastVehicle.vehicleInformation.make}&model=${lastVehicle.vehicleInformation.model}`;
        }
    }
};


// check fitment Trim
const addFitmentStuff = (lastVehicle)=>{
    let continueShoppingUrl = '';
    if (lastVehicle.vehicleInformation.modelId) {
        continueShoppingUrl += '&modelId=' + lastVehicle.vehicleInformation.modelId;
    }

    if (lastVehicle.vehicleInformation.bodyStyle) {
        continueShoppingUrl += '&body=' + lastVehicle.vehicleInformation.bodyStyle;
    }

    if (lastVehicle.vehicleInformation.bodyStyleId) {
        continueShoppingUrl += '&bodyId=' + lastVehicle.vehicleInformation.bodyStyleId;
    }

    if (lastVehicle.vehicleInformation.wheelBase) {
        continueShoppingUrl += '&wheel=' + lastVehicle.vehicleInformation.wheelBase;
    }

    if (lastVehicle.vehicleInformation.wheelBaseId) {
        continueShoppingUrl += '&wheelId=' + lastVehicle.vehicleInformation.wheelBaseId;
    }

    if (lastVehicle.vehicleInformation.trim) {
        continueShoppingUrl += '&trim=' + lastVehicle.vehicleInformation.trim;
    }

    if (lastVehicle.vehicleInformation.trimId) {
        continueShoppingUrl += '&trimId=' + lastVehicle.vehicleInformation.trimId;
    }

    if (lastVehicle.vehicleInformation.driveType) {
        continueShoppingUrl += '&drive=' + lastVehicle.vehicleInformation.driveType;
    }

    if (lastVehicle.vehicleInformation.driveTypeId) {
        continueShoppingUrl += '&driveId=' + lastVehicle.vehicleInformation.driveTypeId;
    }

    if (lastVehicle.vehicleInformation.engineBase) {
        continueShoppingUrl += '&engine=' + lastVehicle.vehicleInformation.engineBase;
    }

    if (lastVehicle.vehicleInformation.engineBaseId) {
        continueShoppingUrl += '&engineId=' + lastVehicle.vehicleInformation.engineBaseId;
    }

    if (lastVehicle.vehicleInformation.bodyNumDoors) {
        // Need bodyNumDoorsId too because it is passed into add to cart call. If we don't, we may encounter issue
        // of products in cart being under different duplicate vehicles
        continueShoppingUrl += '&bodyNumDoors=' + lastVehicle.vehicleInformation.bodyNumDoors +
            `&bodyNumDoorsId=${lastVehicle.vehicleInformation.bodyNumDoors}`
    }
    return continueShoppingUrl;
}
