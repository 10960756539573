import React from 'react';

const Toggle = (props) => {
    return (
        <span>
            <label
                // Quantum styling?
                className="ac-toggle ac-scale"
            >
                <input
                    type="checkbox"
                    defaultChecked={props.checked}
                    onClick={props.onClick}
                    data-dtm={props.dataDtm}
                />
                <span className="ac-slider round stat-text-link"></span>
            </label>
        </span>
    )
}

export default Toggle;