
import React, { Component } from 'react';
import { Translation } from 'react-i18next';

export class DocumentationTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let documentationObject = this.props.documentationContent; 
        
        return(
            <div className="ac-tabContent"><h2 className="q-headline2">{this.props.documentationTitle}</h2>
                <h5 className="q-text">
                    {documentationObject.map((item, index) => {
                        return (<div key={index}><a className="ac-accessible-link-underline" target="_blank" href={item.URL}>{item.desc}</a></div>)                      
                    })
                    }
                </h5>
            </div>
        );
    }
}

export default DocumentationTab
