import React from "react";
import Button from "../../../../shared/Button/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { getDealersByCoords, getZipCodeFromCoordinates } from "../../Data/ApiService";
import { cleanResponseArr } from "../HelperFunctions.js";


/**
 * <GetLocationSection/>
 * User clicks button. Triggers browser prompt to use location
 * if user confirms, function grabs coordinates and saves a state
 * if declines or some other location issue, error is shown.
 *
 * @param {*} props
 *  dispatch (required) redux dispatch function
 *  state (required) current state.
 * @return {Object} React Component with a button to fetch user location
 */
export default function GetLocationSection(props) {
    const { t } = useTranslation();

    /**
     * Get user gets user's longitude and latitude and sets the state
     * @param {*} position GeolocationPosition
     */

    const getPosition = async (position) => {
        const param = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            partNumber: props.state.partNumber
        };
        //grabbed from save and close
        props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: true });
        props.dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
        
        try {
            const dealersRes = await getDealersByCoords(param);

            if (dealersRes.data.dealers) {
                props.dispatch({ type: "UPDATE_SEARCH_TYPE", searchType: "Auto" });
                props.dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: cleanResponseArr(dealersRes.data.dealers)});
                props.dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: false });
                props.dispatch({ type: "SOURCE_RESULTS", sourceResults: "Auto" });
                props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
                // resolveAndSaveZipcode(param);
                const zipRes = await getZipCodeFromCoordinates(param.lat, param.long);
                if (zipRes.locationData) {
                    const {long_name, short_name} = zipRes.locationData;
                    props.dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: short_name || long_name });
                }
            } else {
                const errorType = resp.status == 204
                        ? t("ERROR_DEALER_LOCATOR_NO_DEALERS_COORDS")
                        : t("LABEL_DEALER_ERROR_LOCATOR_LOCATION");
                props.dispatch({ type: "TOGGLE_SPINNER", showSpinner: false });
                props.dispatch({ type: "SET_LOCATION_ERROR_MSG", locationErrorMsg: errorType });
                props.dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: true });
            }
            props.dispatch({ type: "TOGGLE_CLOSE", preventClose: false }); 
        } catch (e) {
            console.warn("encountered error with location data:\n", e.toString());
        }
    };

    // triggers flag to show location error messaging when geolocation is available,
    // but user has location turned off.
    const checkLocationPermission = (error) => {
        props.dispatch({ type: "SET_LOCATION_ERROR_MSG", locationErrorMsg: t("LABEL_DEALER_ERROR_LOCATOR_LOCATION")});
        props.dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: true });
    };

    /**
     * Get permission to use location and/or determines
     * if browser supports location. Shows error if not supported or not detected
     */
    const handleFindUserLocation = () => {
        props.dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: false });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getPosition, checkLocationPermission);
        } else {
            /** if geolocation is not supported by the browser */
            props.dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: [] });
            props.dispatch({ type: "SET_LOCATION_ERROR_MSG", locationErrorMsg: t("LABEL_DEALER_ERROR_LOCATOR_LOCATION")});
            props.dispatch({ type: "TOGGLE_LOCATION_ERROR", showLocationError: true });
        }
    };
    /* Render 'Use Location' Button */
    return (
        <div id="dealerization-use-location-btn">
            <Button
                isSecondary={false}
                localeKey={t("LABEL_DEALER_LOCATOR_USE_MY_LOCATION")}
                onClick={() => handleFindUserLocation()}
                disabled={props.state.showSpinner}
                type={"submit"}
                fontAwesomeRender={faLocationArrow}
                className="gb-primary-button"
                dataDtm = "modal:dealer search"
            />
            {props.state.showLocationError && (
                <div className="prt-dealerization-error-msg">{props.state.locationErrorMsg}</div>
            )}
        </div>
    );
}

GetLocationSection.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
