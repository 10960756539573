import queryString from "query-string";
import AppSettings from "../../core/AppSettings";
import AuthenticationReducer from "../Authentication/AuthenticationReducer";

export const digitalDataImplementation = (props) => {
    let digitalData = window.digitalData;

    const getRenderedExperience = () => {
        const PHONE_PORT_MAX = 360;
        const PHONE_MIN = 361;
        const PHONE_MAX = 768;
        const TABLET_MIN = 769;
        const TABLET_MAX = 992;
        const NOTEBOOK_MIN = 993;
        const NOTEBOOK_MAX = 1200;
        const DESKTOP_MIN = 1201;
        const DESKTOP_MAX = 1500;
        const FULL_SCREEN_MIN = 1501;
        const FULL_SCREEN_MAX = 1920;

        var renderedExperience = "";
        var width = window.innerWidth;

        if (width <= PHONE_MAX) {
            renderedExperience = "small";
        } else if (width <= TABLET_MAX) {
            renderedExperience = "medium";
        } else {
            renderedExperience = "large";
        }

        return renderedExperience;
    };

    const getOrientation = () => {
        const orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
        return orientation;
    };

    const getViewPort = () => {
        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return w + "x" + h;
    };

    const getSiteSectionLevel2 = (isSEO) => {
        const pathname = window.location.pathname;
        const splitPath = pathname.split("/");

        const vin = queryString.parse(window.location.search).vin;
        let siteSectionLevel2 = "";

        if (splitPath[1] == "") {
            siteSectionLevel2 = "home";
        } else if (splitPath[1] === "search") {
            // Do nothing on VIN search
            siteSectionLevel2 = "search results";
        } else if (splitPath[1] === "product") {
            // Do nothing on VIN search
            siteSectionLevel2 = "product details";
        } else if (isSEO && splitPath[2] == "orderhistory") {
            return "Order History";
        } else if (splitPath[1]) {
            siteSectionLevel2 = splitPath[1];
        }

        return siteSectionLevel2;
    };

    const getSiteSectionLevel5 = (one, two, three) => {
        let output = one;
        if (two) {
            output += ":" + two;
        }
        if (three) {
            output += ":" + three;
        }

        return output;
    };

    // User Registration - Start
    const getUserRegistrationStart = (authenticationData) => {
        let userRegistrationStarted = false;
        if (authenticationData) {
            const registerSearch = window.location.href.indexOf("register");

            if (registerSearch != -1) {
                userRegistrationStarted = true;
            }
        }
        return userRegistrationStarted;
    };

    // User Registration - Complete
    const getUserRegistrationComplete = (authenticationData) => {
        let userRegistrationComplete = "";
        if (authenticationData) {
            if (!AppSettings.isT3) {
                userRegistrationComplete = authenticationData.registrationStatus === "R" ? true : false;
            }
        }
        return userRegistrationComplete;
    };

    //pulls the product info from OrderInfoReducer, CategoryReducer, ProductDetailsReducer, OrderConfirmationReducer or alternately the MiniCartReducer if available
    //there are various pieces of information that needs to be available on various pages. which pieces are set on which page is specified in the tagging doc.
    const getCartAnalyticsData = (cart, minicart, orderConfirmData, categoryData, productData) => {
        let product = [];
        let vehicles = [];
        if (cart.parsedResponse) {
            vehicles = cart.parsedResponse.vehicles;
        } else if (orderConfirmData.parsedResponse) {
            vehicles = orderConfirmData.parsedResponse.vehicles;
        }
        if (vehicles.length > 0) {
            vehicles.forEach(function (veh) {
                if (veh.orderItems) {
                    veh.orderItems.forEach(function (item) {
                        if (item.items && item.items.length) {
                            //logic handling for when we have an additional line item for install
                            const deliveryMethod =
                                item.items[1] && item.items[1].shipModeDescription
                                    ? item.items[1].shipModeDescription
                                    : item.items[0].shipModeDescription;
                            let orderItem = {
                                category: "",
                                msrp: item.items[0].unitPrice,
                                description: item.items[0].partName,
                                sku: item.items[0].partNumber,
                                deliveryMethod: deliveryMethod,
                                quantity: item.items[0].quantity
                            };
                            product.push(orderItem);
                        }
                    });
                }
            });
        } else if (productData.name) {
            let orderItem = {
                category:
                    productData.breadcrumb && productData.breadcrumb.subcategory
                        ? productData.breadcrumb.subcategory
                        : "",
                msrp: productData.price,
                description: productData.name,
                sku: productData.partNumber
            };
            product.push(orderItem);
        } else if (categoryData.selectedCategory) {
            let orderItem = {
                category: categoryData.selectedCategory.label ? categoryData.selectedCategory.label : ""
            };
            product.push(orderItem);
        }
        //attempt to pull data from minicart if all else fails
        if (minicart.data && minicart.data.vehicles && !vehicles.length) {
            let vehicles = minicart.data.vehicles;
            vehicles.forEach(function (veh) {
                let orderItems = veh.orderItems;
                if (orderItems) {
                    orderItems.forEach(function (item) {
                        let orderItem = {
                            category: "",
                            msrp: item.UnitPrice,
                            description: item.partName,
                            sku: item.partNumber,
                            deliveryMethod: "",
                            quantity: item.quantity
                        };
                        product.push(orderItem);
                    });
                }
            });
        }
        return product;
    };
    //pulls the zipcode from OrderInfoReducer
    const getZipCode = (cart, orderConfirm, authenticationData, dealerization) => {
        let zipCode = "";
        let cartData = {};
        if (cart.parsedResponse) {
            cartData = cart.parsedResponse;
        } else if (orderConfirm.parsedResponse) {
            cartData = orderConfirm.parsedResponse;
        }
        if (dealerization.userInputZip) {
            zipCode = dealerization.userInputZip;
        } else if (authenticationData.userData.zipCode) {
            zipCode = authenticationData.userData.zipCode;
        }

        return zipCode;
    };

    const getLeadComplete = () => {
        let leadComplete = false;

        if (location.pathname == "/orderconfirmation") {
            leadComplete = true;
        }

        return leadComplete;
    };

    //Returns the transaction data pulled from the OrderInfoReducer or OrderConfirmReducer
    const getTransactionAnalyticsData = (cart, orderConfirm) => {
        let transaction = {};
        let orderInfo = {};
        //OrderInfoReducer
        if (cart.parsedResponse && cart.parsedResponse.orderInfo) {
            orderInfo = cart.parsedResponse.orderInfo;
        }
        //OrderConfirmReducer
        else if (orderConfirm.parsedResponse && orderConfirm.parsedResponse.orderInfo) {
            orderInfo = orderConfirm.parsedResponse.orderInfo;
        }
        if (orderInfo.subtotal) {
            transaction = {
                basePrice: orderInfo.subtotal,
                voucherCode: getCouponCodes(orderInfo.adjustment),
                voucherDiscount: orderInfo.totalAdjustment,
                currency: orderInfo.currency,
                salesTax: orderInfo.totalTax,
                shipping: orderInfo.fedEx,
                installation: orderInfo.installation,
                transactionTotal: orderInfo.total,
                transactionID: orderInfo.orderId
            };
        } else {
            transaction = {
                currency: "USD"
            };
        }
        return transaction;
    };

    //iterates through the coupons and returns them in a semicolon seperated format
    const getCouponCodes = (adjustments) => {
        let voucherCode = "";
        if (adjustments.length > 0) {
            adjustments.forEach(function (adj) {
                let code = adj.code;
                voucherCode = voucherCode + code + ";";
            });
        }
        return voucherCode;
    };

    const getCheckoutStep = (checkoutData) => {
        let step = "";
        if (checkoutData.currentStep && AppSettings.pageName == "checkout") {
            step = "step" + checkoutData.currentStep;
        } else if (AppSettings.pageName != "checkout") {
            // the page name is not checkout and URL is structure as /profile/pageName we need to break up url
            const pathname = window.location.pathname;
            const splitPath = pathname.split("/");

            if (splitPath[2] == "orderhistory") {
                step = "order-history";
            }
        }
        return step;
    };

    const getVehicleInfo = (vehicleData) => {
        let vehicle = {};
        if (vehicleData.year) {
            vehicle.modelName = vehicleData.model ? vehicleData.model : "";
            vehicle.modelYear = vehicleData.year ? vehicleData.year : "";
            vehicle.vin = vehicleData.vin ? vehicleData.vin : "";
            vehicle.bodyStyle = vehicleData.bodyStyle
                ? vehicleData.bodyStyle
                : vehicleData.vehicleConfig.body
                ? vehicleData.vehicleConfig.body
                : "";
            vehicle.trimLevel = vehicleData.trim
                ? vehicleData.trim
                : vehicleData.vehicleConfig.trim
                ? vehicleData.vehicleConfig.trim
                : "";

            // Adding to the data layer for MRM
            vehicle.make = vehicleData.make ? vehicleData.make : "";
            vehicle.modelId = vehicleData.vehicleConfig.modelId ? vehicleData.vehicleConfig.modelId : "";
            vehicle.bodyId = vehicleData.vehicleConfig.bodyId ? vehicleData.vehicleConfig.bodyId : "";
            vehicle.wheel = vehicleData.vehicleConfig.wheel
                ? vehicleData.vehicleConfig.wheel
                : vehicleData.wheelBase
                ? vehicleData.wheelBase
                : "";
            vehicle.wheelId = vehicleData.vehicleConfig.wheelId ? vehicleData.vehicleConfig.wheelId : "";
            vehicle.drive = vehicleData.vehicleConfig.drive ? vehicleData.vehicleConfig.drive : "";
            vehicle.driveId = vehicleData.vehicleConfig.driveId ? vehicleData.vehicleConfig.driveId : "";
            vehicle.engine = vehicleData.vehicleConfig.engine
                ? vehicleData.vehicleConfig.engine
                : vehicleData.engine
                ? vehicleData.engine
                : "";
            vehicle.engineId = vehicleData.vehicleConfig.engineId ? vehicleData.vehicleConfig.engineId : "";
            vehicle.bodyNumDoors = vehicleData.bodyNumDoors
                ? vehicleData.bodyNumDoors
                : vehicleData.vehicleConfig.bodyNumDoors
                ? vehicleData.vehicleConfig.bodyNumDoors
                : "";

            if (vehicleData.vin) {
                vehicle.bodyId = vehicleData.vcdb.bodyStyleConfigId ? vehicleData.vcdb.bodyStyleConfigId : "";
                vehicle.bodyNumDoors = vehicleData.bodyNumDoors ? vehicleData.bodyNumDoors : "";
                vehicle.drive = vehicleData.driveType ? vehicleData.driveType : "";
                vehicle.driveId = vehicleData.vcdb.driveTypeId ? vehicleData.vcdb.driveTypeId : "";
                vehicle.engineId = vehicleData.vcdb.engineId ? vehicleData.vcdb.engineId : "";
                vehicle.modelId = vehicleData.vcdb.modelId ? vehicleData.vcdb.modelId : "";
                vehicle.wheelId = vehicleData.vcdb.wheelBaseId ? vehicleData.vcdb.wheelBaseId : "";
            } else {
                // Override these values once this data is available for YMM
                // BodyNumDoors is actually from the response rather than URL, so ignore it.
                if (vehicleData.vehicleInfo) {
                    vehicle.bodyStyle = vehicleData.vehicleInfo.bodyStyle ? vehicleData.vehicleInfo.bodyStyle : "";
                    vehicle.bodyId = vehicleData.vehicleInfo.vcdb.bodyStyleConfigId
                        ? vehicleData.vehicleInfo.vcdb.bodyStyleConfigId
                        : "";
                    vehicle.drive = vehicleData.vehicleInfo.driveType ? vehicleData.vehicleInfo.driveType : "";
                    vehicle.driveId = vehicleData.vehicleInfo.vcdb.driveTypeId
                        ? vehicleData.vehicleInfo.vcdb.driveTypeId
                        : "";
                    vehicle.engine = vehicleData.vehicleInfo.engine ? vehicleData.vehicleInfo.engine : "";
                    vehicle.engineId = vehicleData.vehicleInfo.vcdb.engineId
                        ? vehicleData.vehicleInfo.vcdb.engineId
                        : "";
                    vehicle.make = vehicleData.vehicleInfo.make ? vehicleData.vehicleInfo.make : "";
                    vehicle.modelId = vehicleData.vehicleInfo.vcdb.modelId ? vehicleData.vehicleInfo.vcdb.modelId : "";
                    vehicle.modelName = vehicleData.vehicleInfo.model ? vehicleData.vehicleInfo.model : "";
                    vehicle.modelYear = vehicleData.vehicleInfo.year ? vehicleData.vehicleInfo.year : "";
                    vehicle.trimLevel = vehicleData.vehicleInfo.trim ? vehicleData.vehicleInfo.trim : "";
                    vehicle.wheel = vehicleData.vehicleInfo.wheelBase ? vehicleData.vehicleInfo.wheelBase : "";
                    vehicle.wheelId = vehicleData.vehicleInfo.vcdb.wheelBaseId
                        ? vehicleData.vehicleInfo.vcdb.wheelBaseId
                        : "";
                }
            }
        }
        return vehicle;
    };

    const getCartData = (data) => {
        let callObj = {};
        let ymmArr = [];
        let productArr = [];
        let tempArr = [];

        // Loop through the vehicles in the cart to save the data
        data.data.vehicles &&
            data.data.vehicles.map((vehicle) => {
                // Get ymm for each vehicle
                ymmArr.push(vehicle.ymm);

                tempArr = [];
                // Now loop through the products for each vehicle
                vehicle &&
                    vehicle.orderItems &&
                    vehicle.orderItems.map((item) => {
                        // Add to array the product(s) for current vehicle
                        tempArr.push(item.partNumber);
                    });
                // Append to the original array the array of products of the current vehicles
                productArr.push(tempArr);
            });

        callObj.ymmData = ymmArr;
        callObj.productData = productArr;

        return callObj;
    };

    const getOrderData = (data) => {
        let callObj = {};

        let ymmArr = [];
        let productArr = [];
        let tempArr = [];

        // Loop through the vehicles in the order to save the data
        data.parsedResponse.vehicles &&
            data.parsedResponse.vehicles.map((vehicle) => {
                // Get ymm for each vehicle
                ymmArr.push(vehicle.vehiclesInfo.ymm);

                tempArr = [];
                // Now loop through the products for each vehicle
                vehicle &&
                    vehicle.orderItems &&
                    vehicle.orderItems.map((item) => {
                        // Add to array the product(s) for current vehicle
                        tempArr.push(item.items[0].partNumber);
                    });
                productArr.push(tempArr);
            });

        callObj.ymmData = ymmArr;
        callObj.productData = productArr;
        return callObj;
    };

    //returns the formatted pagename
    const getAnalyticsPageName = (pageInfo) => {
        let pageName = "";
        let brand = "";
        switch (pageInfo.brand) {
            case "Chevrolet": {
                brand = "ch";
                break;
            }
            case "Buick": {
                brand = "bu";
                break;
            }
            case "GMC": {
                brand = "gmc";
                break;
            }
            case "Cadillac": {
                brand = "ca";
                break;
            }
            default: {
                brand = "t3";
                break;
            }
        }

        if (pageInfo) {
            let site = "t1";
            if (AppSettings.isT3) {
                site = "t3";
            }
            if (pageInfo.siteSectionsLevel5 === "accessories:product") {
                pageName =
                    brand +
                    ":" +
                    pageInfo.region +
                    ":" +
                    pageInfo.country +
                    ":" +
                    pageInfo.languageSelected +
                    ":" +
                    site +
                    ":" +
                    pageInfo.siteSectionsLevel5 +
                    ":CatalogEntryPage";
            } else {
                pageName =
                    brand +
                    ":" +
                    pageInfo.region +
                    ":" +
                    pageInfo.country +
                    ":" +
                    pageInfo.languageSelected +
                    ":" +
                    site +
                    ":" +
                    pageInfo.siteSectionsLevel5;
            }
        }
        return pageName;
    };

    const getAnalyticsPageType = (pageInfo) => {
        let pageType = "";
        if (pageInfo) {
            if (!AppSettings.isT3) {
                if (pageInfo.siteSectionsLevel2 === "register") {
                    pageType = "account registration";
                }
                if (pageInfo.siteSectionsLevel2 === "checkoutmethod") {
                    pageType = "checkout block";
                }
                if (pageInfo.siteSectionsLevel3 == "order-history") {
                    pageType = "order history page";
                }
            }
        }
        return pageType;
    };

    // User Profile State (authentication) Logged in , Not logged in
    const getUserProfileStateAuthentication = (authenticationData) => {
        let userProfileState = "Not Logged in";
        if (authenticationData) {
            if (!AppSettings.isT3) {
                if (authenticationData.registrationStatus === "G") {
                    userProfileState = "Not Logged in";
                }
                if (authenticationData.registrationStatus === "R") {
                    userProfileState = "Logged in";
                }
            }
        }
        return userProfileState;
    };
    // User Login - Start
    const getUserLoginStart = (authenticationData) => {
        let userLoginStarted = "";
        if (authenticationData) {
            if (!AppSettings.isT3) {
                userLoginStarted = authenticationData.loginIsFetching ? true : false;
            }
        }
        return userLoginStarted;
    };
    // User Login - Complete
    const getUserLoginComplete = (authenticationData) => {
        let userLoginComplete = "";
        if (authenticationData) {
            if (!AppSettings.isT3) {
                userLoginComplete = authenticationData.registrationStatus === "R" ? true : false;
            }
        }
        return userLoginComplete;
    };

    //Search Text
    const getSearchTerm = () => {
        let searchTerm = queryString.parse(window.location.search).searchTerm
            ? queryString.parse(window.location.search).searchTerm
            : "";
        return searchTerm;
    };

    const getSearchResultsNumber = (searchData) => {
        let numberResults = searchData.recordSetTotalMatches ? searchData.recordSetTotalMatches : "";
        return numberResults;
    };

    // Config Data
    const getConfigData = () => {
        let obj = {
            storeId: AppSettings.storeId
        };

        return obj;
    };

    const getUniversalId = (authenticationData) => {
        return authenticationData.userData.logonId;
    };

    const getLoyaltyProgramStatus = (authenticationData) => {
        return authenticationData.userData.contextAttribute;
    };

    // Page Info
    digitalData.pageInfo.siteSectionsLevel1 = "accessories";
    digitalData.pageInfo.siteSectionsLevel2 = getSiteSectionLevel2();
    digitalData.pageInfo.siteSectionsLevel3 = getCheckoutStep(props.checkoutData);
    digitalData.pageInfo.siteSectionsLevel4 = "";
    digitalData.pageInfo.siteSectionsLevel5 = getSiteSectionLevel5(
        digitalData.pageInfo.siteSectionsLevel1,
        digitalData.pageInfo.siteSectionsLevel2,
        digitalData.pageInfo.siteSectionsLevel3
    );
    digitalData.pageInfo.url = window.location.href;
    digitalData.pageInfo.seoStrategyPageName = "SEO Strategy - " + getSiteSectionLevel2("isSEO");

    digitalData.pageInfo.languageSelected = "en"; // Hard Coded Value
    digitalData.pageInfo.siteName = "accessories";
    digitalData.pageInfo.brand = AppSettings.currentSite.label;
    digitalData.pageInfo.country = AppSettings.country;
    digitalData.pageInfo.region = "na"; // Hard Coded Value

    digitalData.pageInfo.renderedExperience = getRenderedExperience();
    digitalData.pageInfo.viewport = getViewPort();
    digitalData.pageInfo.orientation = getOrientation();
    digitalData.pageInfo.dealerBac = AppSettings.bac;
    digitalData.pageInfo.pageName = getAnalyticsPageName(digitalData.pageInfo); // different than v7
    digitalData.pageInfo.pageType = getAnalyticsPageType(digitalData.pageInfo);

    digitalData.pageInfo.universalId = getUniversalId(props.authenticationData);

    // Vehicle Info
    digitalData.vehicleInfo = getVehicleInfo(props.vehicleData);

    // Config Data
    digitalData.configData = getConfigData();

    // Cart Info
    if (props.miniCartData && props.miniCartData.data) {
        digitalData.cartData = getCartData(props.miniCartData);
    }

    // Order Info
    if (props.orderConfirmData && props.orderConfirmData.parsedResponse) {
        digitalData.orderData = getOrderData(props.orderConfirmData);
    }

    /*digitalData.vehicleInfo.vehicleType
    digitalData.vehicleInfo.modelName
    digitalData.vehicleInfo.modelYear
    digitalData.vehicleInfo.bodyStyle
    digitalData.vehicleInfo.trimLevel
    digitalData.vehicleInfo.vin*/

    // Visitor Profile
    digitalData.visitorProfile.zipCode = getZipCode(props.orderData, props.orderConfirmData, props.authenticationData, props.dealerizationData);
    digitalData.visitorProfile.loyaltyProgramStatus = getLoyaltyProgramStatus(props.authenticationData);
    // Form Info
    digitalData.formInfo.leadComplete = getLeadComplete();

    digitalData.product = getCartAnalyticsData(
        props.orderData,
        props.miniCartData,
        props.orderConfirmData,
        props.categoryData,
        props.productData
    );

    // product
    /* digitalData.product.category
    digitalData.product.quantity
    digitalData.product.msrp
    digitalData.product.description
    digitalData.product.sku
    digitalData.product.deliveryMethod */

    // transaction
    digitalData.transaction = getTransactionAnalyticsData(props.orderData, props.orderConfirmData);
    /*digitalData.transaction.basePrice
    digitalData.transaction.voucherCode
    digitalData.transaction.voucherDiscount
    digitalData.transaction.currency
    digitalData.transaction.salesTax
    digitalData.transaction.shipping
    digitalData.transaction.installation
    digitalData.transaction.transactionTotal
    digitalData.transaction.transactionID*/

    // authentication
    digitalData.visitorProfile.userProfileStateAuthentication = getUserProfileStateAuthentication(
        props.authenticationData
    );
    digitalData.visitorProfile.userLoginStart = getUserLoginStart(props.authenticationData);
    digitalData.visitorProfile.userLoginComplete = getUserLoginComplete(props.authenticationData);
    digitalData.visitorProfile.userRegistrationStart = getUserRegistrationStart(props.authenticationData);
    digitalData.visitorProfile.userRegistrationComplete = getUserRegistrationComplete(props.authenticationData);

    // search
    digitalData.internalCampaignInfo.internalSearchTerms = getSearchTerm();
    digitalData.internalCampaignInfo.internalSearchNumberOfResults = getSearchResultsNumber(props.searchData);
};
