import AppSettings from "../../core/AppSettings";
import { deleteFitmentCookie, setFitmentCookie } from "../VehicleSelect/CookieService/FitmentData";

const MODEL_CONST = "model";
const YEAR_CONST = "year";
const BODY_CONST = "body";
const WHEEL_CONST = "wheel";
const TRIM_CONST = "trim";
const DRIVE_CONST = "drive";
const ENGINE_CONST = "engine";
const ID_CONST = "Id";
const BND_CONST = "bodyNumDoors";
// Reverse an array if the facet value includes the passed facet string passed
export function reverseArray(fitmentType, facet = "", arr = []) {
    // If passed arr value is not an array, then return
    if (typeof arr != "object") {
        return;
    }

    // If the array is not empty and  has a "value" key in the first index that includes the passed facet
    if (arr?.length && !fitmentType?.includes(facet)) {
        // return it as is...
        return arr;
    }

    return arr.reverse();
}

// function to delete fitment from URL in sequential order. 
export function sequentialFitmentDelete(fitmentType = "", params, removeAll = false) {
    if (removeAll){
        fitmentType = YEAR_CONST;
    }
    switch (fitmentType) {
        case YEAR_CONST:
            delete params[YEAR_CONST];
        case MODEL_CONST:
            delete params[MODEL_CONST];
            delete params[MODEL_CONST + ID_CONST];
        case BODY_CONST:
            delete params[BODY_CONST];
            delete params[BODY_CONST + ID_CONST];
            delete params[BND_CONST];
            delete params[BND_CONST + ID_CONST];
        case WHEEL_CONST:
            delete params[WHEEL_CONST];
            delete params[WHEEL_CONST + ID_CONST];
        case TRIM_CONST:
            delete params[TRIM_CONST];
            delete params[TRIM_CONST + ID_CONST];
        case DRIVE_CONST:
            delete params[DRIVE_CONST];
            delete params[DRIVE_CONST + ID_CONST];
        case ENGINE_CONST:
            delete params[ENGINE_CONST];
            delete params[ENGINE_CONST + ID_CONST];
        default:
            break;
    }
    return params;
}

// Switch to get makeId from brand string in AppSettings
export function getMakeId(fitmentParams){
    switch (fitmentParams.brand.toLowerCase()) {
        case "buick":
            fitmentParams.makeId = "45";
            fitmentParams.make = "Buick";
            break;
        case "cadillac":
            fitmentParams.makeId = "46";
            fitmentParams.make = "Cadillac";
            break;
        case "chevrolet":
            fitmentParams.makeId = "47";
            fitmentParams.make = "Chevrolet";
            break;
        case "gmc":
            fitmentParams.makeId = "48";
            fitmentParams.make = "GMC";
            break;
        }
    return fitmentParams;
}


export const clearCookie = async (newCookieParams) =>{
    let response = await deleteFitmentCookie();
    if (response.status){
        await setFitmentCookie(newCookieParams);
    }
}