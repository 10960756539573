import React, { useState } from 'react';
import { setIsRoscaChecked } from '../../CheckoutRedux/CheckoutActionCreator';
import i18next from "i18next";
import { Trans } from "react-i18next";
import { Toggle } from '../../../../shared/Hooks/Hooks';
import store from '../../../../core/Redux/Store';
import { useTranslation } from "react-i18next";
import AppSettings from "../../../../core/AppSettings";

function RoscaDisclosure(props) {

    const { t } = useTranslation();
    const { roscaDisclosureMsg, cartResponse } = props;
    const [isChecked, setIsChecked] = useState("");

    const dynamicName = cartResponse.parsedResponse.dealer.dealerName;
    let brandName = '';
    if (!AppSettings.isT3 && AppSettings.currentSite.label) {
        brandName = AppSettings.currentSite.label;
    }
    

    const classNameRosca = isChecked || props.isRoscaChecked ? "ac-rosca-checkbox stat-checkbox " : "ac-rosca-checkbox stat-checkbox ac-checkout-checkbox";
  
    function handleCheckboxClick() {
        Toggle(isChecked, setIsChecked);
        store.dispatch(setIsRoscaChecked(isChecked));
    }
    return (
        <>
            <div className="gmit-gf-request-type ac-roscadisclosure text-left">
                <label className="ac-rosca-checkbox-wrapper">
                    <input 
                        className={classNameRosca} 
                        data-dtm="checkout step4" 
                        onChange={handleCheckboxClick} 
                        checked={isChecked} 
                        type="checkbox" 
                    />
                    <span className="gb-body3">
                        {i18next.t("LABEL_CHECKOUT_ROSCA_LABEL_1", { dynamicName })}
                        <Trans i18nKey="LABEL_CHECKOUT_POLICY_STATEMENT">
                            <a
                                className="stat-text-link"
                                data-dtm="checkout step4"
                                href={process.env.REACT_APP_DAM_SOURCE + process.env.REACT_APP_TERMS_OF_SALE}
                                target="_blank"
                            >
                                TERMS OF SALE
                            </a>
                            <a
                                className="stat-text-link"
                                data-dtm="checkout step4"
                                href={process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS}
                                target="_blank"
                            >
                                PRIVACY STATEMENT
                            </a>
                        </Trans>
                        <br />
                        <br />
                        {i18next.t("LABEL_CHECKOUT_ROSCA_TEXT")}
                        {roscaDisclosureMsg && (
                            <div className="error">
                                <label className="gmit-gf-error-label disclosure-error-font">{t(roscaDisclosureMsg)}</label>
                            </div>
                        )}
                    </span>
                </label>
            </div>
        </>
    );
}

export default RoscaDisclosure;