import * as type from './SimilarItemsActionType';

/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const loadSimilarItemsDataAsync = (partTerminologyId, partNumber) => {
    const action = {
        type: type.LOAD_SIMILAR_ITEMS_DATA_ASYNC,
        partTerminologyId: partTerminologyId,
        partNumber : partNumber 
    }
    return action;
}

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const setSimilarItemsData = (data) => {
    const action = {
        type: type.SET_SIMILAR_ITEMS_DATA,
        data: data
    }
    return action;
}

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const loadSimilarItemsDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_SIMILAR_ITEMS_DATA_ASYNC_FAIL,
        error
    }
    return action;
}

export const setSimilarItemsDataFail = (error) => {
    const action = {
        type: type.SET_SIMILAR_ITEMS_DATA_FAIL,
        error
    }
    return action;
}