import React, { Component } from 'react';
import { Translation } from "react-i18next";
import Modal from '../../../../shared/Modal/Modal';

class CouponsModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showModal, toggleModal, offersLabel, offers, apply } = this.props;
    
    return (
      <Translation>
        {t =>
          <div className="small-12 q-grid-row-new-mobile columns">
            <Modal open={showModal} onClose={toggleModal} onOverlayClick={toggleModal} center>
              <div className="ac-coupon-modal">
                {/* Modal Header */}
                <h5 className="ac-coupon-h5 small-11">
                  <b>{t(offersLabel ? 'LABEL_COUPON_AVAILABLE_DEALER' : 'LABEL_COUPON_AVAILABLE_MANUFACTURE')}</b>
                </h5>
                <hr />

                {/* Modal Subheader */}
                <h5 className="ac-coupon-h5 ac-small-marginbottom">
                  <b>{t("LABEL_COUPON_SELECT_OFFER")}</b>
                </h5>

                {/* Coupon Offer Boxes */}
                {offers.map((offer, index) =>
                  <div className="ac-offer-box stat-button-link" data-dtm="checkout step2:promotion" key={index} onClick={() => apply({couponCode: offer.couponCode})}>
                    <h5 className="ac-coupon-code-name text-center">
                      <b>{offer.codeAndName}</b>
                    </h5>
                    {/* Check if coupon offer has only 1 condition for styling purposes */}
                    <ul className={'q-text q-body2 ac-coupon-conditions ' + (offer.conditions.length == 1 ? 'ac-coupon-single-condition' : '')}>
                      {offer.conditions.map((line, key) =>
                        <li key={key}>{line}</li>
                      )}
                    </ul>
                  </div>
                )}

                {/* Modal Cancel Button */}
                <div className="ac-coupon-button small-12 medium-12 large-12 xlarge-12">
                  <button className="ac-coupon-cancel-button q-button q-secondary-button stat-button-link" data-dtm="checkout step2:promotion" type="submit" onClick={toggleModal}>{t("LABEL_COUPON_CANCEL")}</button>
                </div>
              </div>
            </Modal>
          </div>
        }
      </Translation>
    )
  }
}

export default CouponsModal;