import React from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { urlBuilder } from './PartsShopCardUtils';

/*
SHARED PARTS SHOP CARD COMPONENT

Props:
    image: string
    string containing the image url to render in the card.

    label: string
    string to populate the card title

    categoryId: string
    string to populate the categoryId. This will be used if they click the CTA and get redirected to Parts.

Usage:
    1) Import the component

    2) utilize as needed
        <PartsShopCard 
            image={<string url>} 
            label={<string label>}
            categoryId={<string categoryId>} 
        />
*/
function PartsShopCard(props) {
    const { t } = useTranslation();
    const url = urlBuilder([props.categoryId]);

    return (
        <div>
            <div id ="ac-parts-card-wrapper" className='small-12 large-centered ac-nopadding-x q-flex-container'>
                <div id="ac-parts-card" className='small-12 medium-12 large-6 ac-desktop xlarge-4 columns'>
                    <div className='q-responsive-image q-responsive-image-container parts-card-img-wrapper'>
                        <picture>
                            <a href={url} target="_blank">
                                <img id="ac-parts-card-image" className="q-image stat-image-link" src={props.image} />
                            </a>
                        </picture>
                    </div>
                    <div id="ac-parts-card-label" className='q-headline2'>{props.label}</div>
                    <div className='small-12 xlarge-8'>
                        <a id="ac-parts-card-href" href={url} target="_blank">
                            <button id="parts-shop-card-btn" className="ac-add-to-cart-btn q-button q-primary-button gm-primary-btn stat-button-link" data-dtm="">{t("LABEL_PARTS_SHOP_CARD_SHOP") + props.label}</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

PartsShopCard.propTypes = {
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired
  };
  

export default PartsShopCard;