import axios from 'axios';
import AppSettings from '../../../core/AppSettings'

export const getGpmsWalletData = () => {
    const url = `/wcs/resources/store/${AppSettings.storeId}/wallet/profile`;

    return axios.get(url, { headers: { "Cache-Control" : "no-cache, no-store, must-revalidate", 'Pragma': 'no-cache', 'Expires': '0' } })
        .then((response) => { return response })
        .catch((error) => { console.log("error:", error) });
} 
