import * as type from './MiniCartActionType';

let initialState = {
  loaded: false,
  count: 0,
  modalOpen: false
};
const MiniCartReducer = (state = initialState, action) => {
    switch (action.type) {

    case type.MINI_CART_DATA_LOADED:
      return Object.assign({}, state, { data: action.payload.data, loaded: true, count: action.payload.count, orderId : action.payload.orderId });

    case type.LOAD_MINI_CART_DATA_ASYNC_FAIL:
      return Object.assign({}, state, {
        error: action.error
      });

    case type.SET_MINI_CART_ORDER_ID:
        return Object.assign({}, state, {
            orderId: action.orderId
        });

    case type.SET_MINI_CART_OPEN:
      return Object.assign({}, state, {
          modalOpen: action.modalOpen
      });
      
    default:
      return state;
  }
}

export default MiniCartReducer;