/**
 * This file has components that will dropped into the body of the dealerization widget modal.
 * This has been separated to improve the readability of Dealer Locator.
 */
import React from "react";
import { useTranslation } from "react-i18next";
import ListOfSellersSection from "../ListOfSellers/ListOfSellersSection";
import Spinner from "../../../../shared/Spinner/Spinner";

/**
 * The box of radio buttons rendered after the user inputs some location data.
 * @param {*} state state of the dealerization widget
 * @param {*} dispatch reducer dispatch function
 * @return {div}
 */
export const selectASrcSection = (state, dispatch, showMap) => {
    const { t } = useTranslation();
    let locationOrZipVerbiage;
    const locationOrZipPresent = state.currentZip !== "" || state.currentCity !== "" || state.currentSeller !== "" || state.sourceResults == "Auto";
    // Check the submitted search type and adjust the results wording based on that
    if (state.sourceResults === "Zip") {
        locationOrZipVerbiage = state.currentZip;
    } else if (state.sourceResults === "City") {
        locationOrZipVerbiage = state.currentCity;
    } else if (state.sourceResults === "Seller") {
        //wrap search terms for seller name in quotes
        locationOrZipVerbiage = '"' + state.currentSeller + '"';
     }  else if (state.sourceResults === "Auto") {
         locationOrZipVerbiage = "Your Location";
     }
    
    const listOfSellers =
        state.listOfAllSources && state.listOfAllSources.length ? (
            <div className="prt-dealer-list-wrapper">
                {!state.showSpinner &&
                    locationOrZipPresent &&
                    t("LABEL_DEALER_LOCATOR_SELECT_SRC") &&
                    t("LABEL_DEALER_LOCATOR_SELECT_A_SRC", {
                        qty: state.listOfAllSources.length,
                        userInput: locationOrZipVerbiage
                    })}
                <ListOfSellersSection dispatch={dispatch} state={state} mapFlag={showMap} />
            </div>
        ) : (
            !showMap && <></>
        );

    const noListSpinnerView = state.showSpinner && (
        <div className="dealer-list-spinner-container">
            <Spinner />
        </div>
    );
    return state?.listOfAllSources?.length ? listOfSellers : noListSpinnerView;
};

/**
 * @return {div} the --- or ---- between the use my location button and the zip search bar
 */
export const orSeparator = () => {
    const { t } = useTranslation();
    return <div className="or-separator">{t("LABEL_SEPARATOR_OR")}</div>;
};

/**
 * @return {div} disclaimer at the bottom of the dealerization widget
 */
export const disclaimer = (src) => {
    const { t } = useTranslation();
    return <>{t("LABEL_DEALER_LOCATOR_DISCLAIMER", { source: src })}</>;
};

export const dealerRadioBtn = (handleSearchType, state) => {
    const { t } = useTranslation();
    return (
        <div className="dealer-radio-btns stat-radio" data-dtm="modal:dealer search">
            <label className="radio-dealer-labels">
                <input
                    className="radio-dealer-btn"
                    type="radio"
                    id="zipCode"
                    name="radio-btn-search-dealer-type"
                    onChange={() => handleSearchType("Zip")}
                    checked={state.searchType === "Zip"}
                />
                {t("LABEL_DEALER_LOCATOR_ZIPCODE")}
            </label>
            <label className="radio-dealer-labels">
                <input
                    className="radio-dealer-btn"
                    type="radio"
                    id="city"
                    name="radio-btn-search-dealer-type"
                    onChange={() => handleSearchType("City")}
                    checked={state.searchType === "City"}
                />
                {t("LABEL_DEALER_LOCATOR_CITY")}
            </label>
            <label className="radio-dealer-labels">
                <input
                    className="radio-dealer-btn"
                    type="radio"
                    id="sellerName"
                    name="radio-btn-search-dealer-type"
                    onChange={() => handleSearchType("Seller")}
                    checked={state.searchType === "Seller"}
                />
                {t("LABEL_DEALER_LOCATOR_SELLER_NAME")}
            </label>
        </div>
    );
};
