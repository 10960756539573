import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import FieldErrorMessage from "../../../shared/FieldErrorMessage/FieldErrorMessage";
import SharedRadioButton from "../../../shared/RadioButton/SharedRadioButton";
import SharedSpinner from "../../../shared/Spinner/Spinner";
import { getDeliveryOptions } from "../DeliveryOptions/DeliveryOptionsHelpers";

/**
 * This section is used to generate some dummy options to display while loading.
 * If using this sort of placeholder becomes popular elsewhere, it should be moved
 * into SharedRadioButton and controlled with its props.
 */
const UNICODE_BLOCK = "\u2588";
const getRandomIntegerUpToNumber = (n) => Math.ceil(Math.random() * n);
const generateLoadingStateOption = (index) => ({
    id: `loading-${index}`,
    checked: false,
    disabled: true,
    label: UNICODE_BLOCK.repeat(30 + getRandomIntegerUpToNumber(6)),
    secondaryLabel: UNICODE_BLOCK.repeat(8 + getRandomIntegerUpToNumber(4)),
});

/**
 * Shows a loading state for the delivery options component.
 * 
 * Includes a few disabled, greyed-out sample radio buttons
 * so that 1) the space this section will take up on the page
 * is more defined and 2) the user is clearly notified that
 * a radio-selection menu will appear shortly.
 * 
 * @param {{count: number}} props the number of disabled options to render while data is loading 
 * @returns {React.ReactElement}
 */
const OptionsLoadingState = ({count}) => {
    const loadingStateRadioOptions = [];

    for (let i=0; i<count; i++) {
        loadingStateRadioOptions.push(generateLoadingStateOption(i));
    }
    
    return (
        <div className="loading-container">
            <SharedSpinner />
            <SharedRadioButton 
                radioButtonProps={loadingStateRadioOptions}
                hrBetweenLabels={true}
                objectLabel={true}
                inputClass="prt-radio-delivery-input stat-radio "
            />
        </div>
    );
};

/**
 * Delivery Options selector
 * 
 * Displays delivery options as a radio button list
 * 
 * @typedef DeliveryOptionsProps
 * @type {Object}
 * 
 * @property {function} setShipModeId a function to call when the selection is updated
 * @property {Object} deliveryOptionsData the response of a /deliveryOptions call
 * @property {boolean} deliveryOptionsLoading a boolean indicating if a /deliveryOptions request is ongoing
 * 
 * @param {DeliveryOptionsProps} props 
 * @returns {React.ReactElement} JSX element
 */
function DeliveryOptions(props) {
    const { t } = useTranslation();

    let deliveryMethodData = props.deliveryOptionsData;

    let radioDataHolder = getDeliveryOptions(deliveryMethodData, handleOptionClick);
    const [radioBtnData, setRadioBtnData] = useState(radioDataHolder);

    useEffect(() => {
        setRadioBtnData(getDeliveryOptions(deliveryMethodData, handleOptionClick));
        // First radio button is selected by default. Must have a default value in case the radio buttons aren't selected.
        if (radioDataHolder?.deliveryOptions.length) {
            props.setShipModeId(radioDataHolder.deliveryOptions[0].id);
        }
    }, [deliveryMethodData]);

    // This function handles setting the radioBtnData hook so that the radio buttons re-render with the correct data.
    function handleOptionClick(id) {
        let newData = { ...radioDataHolder };
        if (newData?.deliveryOptions.length) {
            newData.deliveryOptions.map((option) => {
                if (id == option.id) {
                    option.checked = true;
                    // Set this so that the ProductInfoContainer can set the hook and pass it to the cart call params.
                    props.setShipModeId(option.id, option.carrierCode);
                } else {
                    option.checked = false;
                }
            });
            setRadioBtnData(newData);
        }
    }

    // Create the errors if any exist.
    const suppressedWarnings = () => {
        if (radioDataHolder?.suppressedErrors?.length) {
            return radioDataHolder.suppressedErrors.map((warning, key) => {
                // Some of the errors do not have a requirement to show a suppression message.
                // Stories included: 1948176, 1948178, 1948185
                if (
                    warning.errorMessage == "RESTRICTED_DEALER_ADDRESS" ||
                    warning.errorMessage == "NO_INVENTORY_AVAILABLE_PICKUP_AT_DEALER" ||
                    warning.errorMessage == "NO_INVENTORY_AVAILABLE_SHIP_TO_HOME"
                ) {
                    return null;
                } else {
                    return (
                        <div key={key} id="prt-suppressed-warning">
                            <FieldErrorMessage message={t("LABEL_SUPPRESSION_" + warning.errorMessage)} />
                        </div>
                    );
                }
            });
        } else {
            return null;
        }
    };

    return (
        <article id="deliveryOptionsSelector">
            {radioDataHolder?.suppressedErrors?.length ? (
                <div className="prt-suppressed-warning-container">{suppressedWarnings()}</div>
            ) : null}
            <h4 className="prt-pd-description-part prt-delivery-options q-descriptive1 medium-margin">
                {t("LABEL_PRODUCT_DETAILS_SELECT_DELIVERY_OPTION")}
            </h4>
            {!props.deliveryOptionsLoading ? (
                radioBtnData?.deliveryOptions?.length ? (
                    <SharedRadioButton
                        radioButtonProps={radioBtnData.deliveryOptions}
                        objectLabel={true}
                        hrBetweenLabels={true}
                        inputClass="prt-radio-delivery-input stat-radio "
                        analyticsTag="delivery option"
                    />
                ) : null
            ) : (
                <OptionsLoadingState count={3} />
            )}
        </article>
    );
};

DeliveryOptions.propTypes = {
    setShipModeId: PropTypes.func,
    deliveryOptionsData: PropTypes.object,
    deliveryOptionsLoading: PropTypes.bool
};

export default DeliveryOptions;
