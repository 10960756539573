import React from "react";
import i18n from "../../core/i18n/i18n";
import { isValidString } from "../Validator/Validator";

// This shared file exists to internationalize numbers across the app
// number formatting resource:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat


//function takes a numerical value to a formatted string with grouping - ie. 1000 to 1,000 for en-US locale
export function formatNumber(value) {
  const language = i18n.language;
  return Number(value).toLocaleString(language, {useGrouping:true});
}

// currency value formatting: takes in the currency type (ex: USD), and value (ex: 499.99)
// Within the data parser for price, pass the below values from the JSON response to the formattedCurrency const
// record.price.currency
// record.price.value
export const formattedCurrency = (currency, value) => {
  // grabbing language from i18n for setting into NumberFormat
  const language = i18n.language;
  // testing for validity of language being a string
  const isValidLanguage= isValidString(currency);
  if (isValidLanguage.result === false) {
    console.log('formattedCurrency, language invalid: ' + isValidLanguage.err);
  }
  // formatting the currency value output; using language, currency, and value
  const formattedValue = new Intl.NumberFormat(language, { style: 'currency', currency: (currency)}).format(value);
  return formattedValue;
};

/**
 * takes a phone number string "XXXXXXXXXX" and formats it
 * into "XXX-XXX-XXXX" or if it's an 11-digit phone number,
 * it will format it into +X XXX-XXX-XXX.
 * country code only is applicable to USA.
 *
 * @param {string} phoneNumberString dealer's phone number
 */
 export const formattedPhoneNumber = (phone) => {
  const REGULAR_PHONE_NUM = phone.length === 10;
  const cleaned = ("" + phone).replace(/\D/g, "");

  const match = REGULAR_PHONE_NUM
      ? cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      : cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return REGULAR_PHONE_NUM
          ? match[1] + "-" + match[2] + "-" + match[3]
          : [intlCode, " ", match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};
