import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translation } from "react-i18next";
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import * as validate from '../../../../../../shared/Form/FormValidate/FormValidate';
import store from '../../../../../../core/Redux/Store';
import { resetCouponState } from '../../../../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionCreator';
import Spinner from '../../../../../../shared/Spinner/Spinner';

const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
  <div className="gb-input">
    <div className={(touched && error ? 'ac-coupon-field-error' : 'ac-coupon-field') + ' small-7 medium-8 columns q-form'}>
      <input className="gmit-gf-input-text stat-input-field q-input margin-small" {...input} placeholder={label} type={type} data-dtm="order summary:coupon codes" {...otherProps} />
      {touched && ((error && <label className="error ac-error-label gmit-gf-error-label"><FontAwesomeIcon icon="exclamation-circle" />{error}</label>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)

const CouponsForm = props => {
  const { handleSubmit, pristine, reset, submitting } = props;

  // don't show error message after user makes changes to input field
  const clearCouponError = () => {
    if (props.couponMsg) {
      const clearError = resetCouponState();
      store.dispatch(clearError);
    }
    return null
  }

  // CUSTOM FORM ERRORS FOR COUPONS:
  // Check for errors from service call,
  // if user entered a value or not
  const couponError = value =>
    ((!value && props.couponMsg !== '')
      || (value && props.couponMsg !== ''))
      ? props.couponMsg : undefined;

  return (
    <Translation>
      {t => (
        <form className="ac-coupon-code-form" onSubmit={handleSubmit} onChange={clearCouponError}>
          {/* Coupon Input Field */}
          <div className="ac-coupon-form">
            <Field
              name="couponCode"
              type="text"
              component={renderField}
              label="Enter Code"
              data-dtm="checkout step2:coupon codes"
              validate={[validate.minLength2, validate.alphaNumeric, couponError]}
              minLength={2}
            />
          </div>

          {/* Coupon Form Submit Button */}
          <div className="ac-coupon-button small-5 medium-4 grid-column-alignment-right columns">
            <button className="ac-coupon-apply-button q-button q-primary-button gm-primary-btn stat-button-link"
              data-dtm="checkout step2:coupon codes"
              type="submit"
              disabled={submitting || props.showLoadingBtn}>
              {t("LABEL_COUPON_APPLY")}
              {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true" />}
            </button>
          </div>
        </form>
      )}
    </Translation>
  )
}

export default reduxForm({
  form: 'CouponsForm', // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(CouponsForm)
