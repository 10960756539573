import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import EditPersonalInfo from './../EditPersonalInfo/EditPersonalInfo';

const PersonalInfoDetails = props => {
    const johnDoeNumbers =  [
                {
                    type: "mobile",
                    number: "123-456-7890"
                },
                {
                    type: "home",
                    number: "123-456-7890"
                },
                {
                    type: "work",
                    number: "123-456-7890"
                }
            ];

    return(
        <Translation>
            {
                (t)=>(
                    <div className="ac-personal-info-details small-12 grid-column-alignment-left columns ac-nopadding-x">
                        <div className={(props.profilesettings.showEditPhone ? "profile-edit-phone-display" : "")}>
                            <div className="small-6 medium-6 large-3 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                                <div className="ac-profile-name-section small-12 grid-column-alignment-left columns ac-nopadding-x">
                                    <p className="ac-profile-name q-body2">John Doe</p>
                                </div>
                                <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">
                                    <a className="q-body2 ac-profile-edit" onClick={props.togglePhoneEdit}>{t("LABEL_CHECKOUT_EDIT_INFO")}</a>
                                </div>
                            </div>
                            {johnDoeNumbers && johnDoeNumbers.map((numbers, index) =>
                                <div className="small-6 medium-6 large-3 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                                    <div className="ac-profile-number">
                                        <p className="ac-profile-number-type q-body2">{numbers.type}</p>
                                        <p className="ac-profile-phone-number q-body2">{numbers.number}</p>
                                    </div>
                                    <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">
                                        <a className="q-body2 ac-profile-edit" onClick={props.togglePhoneEdit}>{t("LABEL_CHECKOUT_EDIT_INFO")}</a>
                                        <span className="q-body2 ac-profile-vertical-line"></span>
                                        <a className="q-body2 ac-profile-remove">{t("LABEL_CHECKOUT_REMOVE")}</a>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={(props.profilesettings.showEditPhone ? "profile-edit-phone-display-active" : "profile-edit-phone-display")}>
                            <EditPersonalInfo
                                resetProfileToggle={props.resetProfileToggle}/>
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

export default PersonalInfoDetails;