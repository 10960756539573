import React, { useState } from "react";
import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button/Button";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { removePaymentMethod } from "../GMWalletService";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import SuccessMessage from "../../SuccessMessage/SuccessMessage";
import store from "../../../core/Redux/Store";
import {resetPaymentInformation} from "../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";

//This component is a modal that welcomes the user to the new GMWallet
//It will ask the user to add new Payments or select their existing payments if applicable
const RemoveConfirmationModal = (props) => {
    const { t } = useTranslation();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showSpinnerBtn, setShowSpinnerBtn] = useState(false);
    //modal styling that is sent to the <Modal component
    const modalStyling= {
        modal: 'wallet-modal-width'
    };
    const removalMethod = async () => {
        setShowSpinnerBtn(true);
        try {
            const removalPayObj = { userId: props.state.params.userId, paymentId: props.removalData.id };
            const removalResponse = await removePaymentMethod(removalPayObj);
            if (removalResponse.response === "[detail-successfully-disabled]") {
                store.dispatch(resetPaymentInformation());
                props.hideRemovalModal();
                setShowErrorMessage(false);
                props.dispatch({ type: "NO_MESSAGES" });
                props.dispatch({
                    type: "REMOVE_PAYMENT_METHOD",
                    cardName: props.removalData.name,
                    lastFour: props.removalData.lastFour,
                    success: true
                });
                // setting the View add new pay to true when Guest User removes payment method in Checkout so that they can add a different payment
                if(props.state.isCheckout && !props.state.isAuthUser){
                    props.dispatch({
                        type: "VIEW_ADD_NEW_PAY", boolean: true,
                    });
                    props.dispatch({ type: "NO_MESSAGES" });
                }
            } else {
                setShowErrorMessage(true);
            }
        } catch (e) {
            setShowErrorMessage(true);
        } finally {
            setShowSpinnerBtn(false);
        }
    };

    return (
        <div className="wallet-modal">
            {showSuccessMessage && <SuccessMessage icon showSuccess={showSuccessMessage} message={t("LABEL_GMWALLET_PAYMENT_SAVED")} />}
            <Modal
                open
                classNames={modalStyling}
                onClose={() => props.hideRemovalModal()}
            >
                <div className="ac-modal-body ">
                    <div className="q-headline1 ac-modal-header remove-confirm-header">
                        {t(props.state.isCheckout ? "LABEL_GMWALLET_REMOVE_CONFIRMATION_CHECKOUT" : "LABEL_GMWALLET_REMOVE_CONFIRMATION", {
                        cardName: props.removalData.name,
                        lastFour: props.removalData.lastFour
                        })}
                    </div>
                    {showErrorMessage && <ErrorMessage icon message={t("LABEL_GMWALLET_REMOVE_ERROR")} />}
                </div>
                <div className="gmWallet-btn-container">
                    <div className="btn-width" id="cancel-btn">
                        <Button
                            id="wallet-remove-modal-cancel-btn"
                            className="stat-button-link"
                            dataDtm="modal:remove payment"
                            localeKey={t("LABEL_COMMON_CANCEL")}
                            onClick={() => props.hideRemovalModal()}
                        />
                    </div>
                    <div className="btn-width" id="remove-confirm-btn">
                        <Button
                            id="wallet-remove-modal-remove-btn"
                            className="stat-button-link"
                            dataDtm="modal:remove payment"
                            localeKey={t("LABEL_GMWALLET_REMOVE_CONFIRM_BTN")}
                            onClick={() => removalMethod()}
                            isLoading={showSpinnerBtn}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
RemoveConfirmationModal.propTypes = {
    hideRemovalModal: PropTypes.func,
    removalData: PropTypes.object,
    userId: PropTypes.string
};
export default RemoveConfirmationModal;
