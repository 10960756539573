/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_SIMILAR_ITEMS_DATA_ASYNC = 'LOAD_SIMILAR_ITEMS_DATA_ASYNC';

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const SET_SIMILAR_ITEMS_DATA = 'SET_SIMILAR_ITEMS_DATA';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_SIMILAR_ITEMS_DATA_ASYNC_FAIL = 'LOAD_SIMILAR_ITEMS_DATA_ASYNC_FAIL';
export const SET_SIMILAR_ITEMS_DATA_FAIL = 'SET_SIMILAR_ITEMS_DATA_FAIL';