/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_YEARS_ASYNC = "VEHICLE_SELECT_LOAD_YEARS_ASYNC";
export const LOAD_VIN_DATA_ASYNC = "LOAD_VIN_DATA_ASYNC";

/**************************************************************/
// Actions to set data (handle in FitmentCheckReducer switch statement)
/**************************************************************/
export const SET_YEARS = "VEHICLE_SELECT_SET_YEARS";
export const LOAD_MODELS = "LOAD_MODELS";
export const LOAD_MAKES = "LOAD_MAKES";
export const SET_VIN = "SET_VIN";
export const RESET_VIN = "RESET_VIN";
export const RESET_VEHICLE_SELECT_STATE = "RESET_VEHICLE_SELECT_STATE";
export const SET_T3_DEFAULT_MAKE = "SET_T3_DEFAULT_MAKE";
export const SET_MODEL_TO_MODEL_ID = "VEHICLE_SELECT_SET_MODEL_TO_MODEL_ID";
export const SET_CLP_REDIRECT = "SET_CLP_REDIRECT";

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const SELECT_YEAR = "SELECT_YEAR";
export const SELECT_MAKE = "SELECT_MAKE";
export const SELECT_MODEL = "SELECT_MODEL";
export const TOGGLE_VIN_INSTRUCTIONS = "TOGGLE_VIN_INSTRUCTIONS";

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_YEARS_ASYNC_FAIL = "LOAD_YEARS_ASYNC";
export const LOAD_VIN_DATA_ASYNC_FAIL = "LOAD_VIN_DATA_ASYNC_FAIL";
export const LOAD_MODELS_FAIL = "LOAD_MODELS_FAIL";
export const LOAD_MAKES_FAIL = "LOAD_MAKES_FAIL";
export const SET_VIN_FAIL = "SET_VIN_FAIL";

/**************************************************************/
// Actions to handle modal visibility
/**************************************************************/
export const TOGGLE_MODAL = "TOGGLE_MODAL";
