import * as type from './FitmentCheckActionType';

const initialState = {
    fitmentStep: '',
    showModal: false,
    vehicleCombinations: [],
    fitmentDetails: [],
    fitmentStatus: '',
    fitmentSelections: { bodyFitmentSelection: [], wheelFitmentSelection: [], trimFitmentSelection: [], driveFitmentSelection: [], engineFitmentSelection: [] },
    vehicleParams: { bodyNumDoorsParam: '', bodyParam: '', wheelParam: '', trimParam: '', driveParam: '', engineParam: '' },
    showError: false,
    returnToSearch: false,
    fitmentVehicleInfo: { bodyStyle: "", bodyStyleId: "", wheelBase: "", wheelBaseId: "", trim: "", trimId: "", driveType: "", driveTypeId: "" }
}

const FitmentCheckReducer = (state = initialState, action) => {

    switch (action.type) {
        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.LOAD_VEHICLE_COMBINATIONS_ASYNC:
            return { ...state, isFetchingVehicleCombinations: true };

        case type.LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC:
            return { ...state, isFetchingFitmentCheck: true, fitmentStep: action.fitmentStep, showLoadingBtn: true };

        case type.SET_VEHICLE_FITMENT:
            const {data} = action;
            return {
                ...state,
                vehicleParams: {
                    bodyNumDoorsParam: data.bodyNumDoors || '',
                    bodyParam: data.bodyStyle || '',
                    driveParam: data.driveType || '',
                    engineParam: data.engineBase || '',
                    trimParam: data.trim || '',
                    wheelParam: data.wheelBase || '',
                },
            };

        case type.SET_VEHICLE_COMBINATIONS:
            return {
                ...state,
                isFetchingVehicleCombinations: false,
                vehicleCombinations: action.combinations,
                wheelDropDownLabel: action.combinations[0].DROP_DOWN_NAME,
                fitmentSelections: {
                    bodyFitmentSelection: action.parsedCombinations.bodyFitmentSelection,
                    wheelFitmentSelection: action.parsedCombinations.wheelFitmentSelection,
                    trimFitmentSelection: action.parsedCombinations.trimFitmentSelection,
                    driveFitmentSelection: action.parsedCombinations.driveFitmentSelection,
                    engineFitmentSelection: action.parsedCombinations.engineFitmentSelection
                },
            };

        case type.SET_FITMENT_CHECK:
            //these values are only available if checkSingleFitOneSelection is needed
            //see FitmentCheckWorker.js for more details
            let fitmentFlags = '';
            let vehicleParams = state.vehicleParams;
            if (action.selectionValues) {
                fitmentFlags = action.selectionValues.fitmentFlags.oneFitOneSelectionFlag;
                vehicleParams = action.selectionValues.vehicleParams;
            }

            return {
                ...state,
                fitmentStatus: action.fitmentStatus,
                fitmentDetails: action.fitmentDetails,
                oneFitOneSelectionFlag: fitmentFlags,
                vehicleParams: vehicleParams,
                isFetchingFitmentCheck: false,
                showLoadingBtn: false
            };

        /**************************************************************/
        // Actions triggered by the user
        /**************************************************************/
        //close modal and reset the state
        case type.CLOSE_FITMENT_MODAL:
            return {
                ...state,
                isFetchingFitmentCheck: false,
                showModal: false,
                vehicleParams: { bodyNumDoorsParam: '', bodyParam: '', wheelParam: '', trimParam: '', driveParam: '', engineParam: '' },
                fitmentDetails: [],
                fitmentStatus: '',
                showError: false
            };

        // Showing fitment modal only if fitment details are needed from user
        case type.SHOW_FITMENT_MODAL:
            return {
                ...state,
                showModal: action.showModal
            }

        case type.RETURN_TO_SEARCH:
            const returnToSearch = action.returnToSearch;
            return {
                ...state,
                returnToSearch: returnToSearch
            }

        //TODO: test if all selections need updating in each select action and find way to reduce lines of code.
        case type.SELECT_BODY:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                
                fitmentVehicleInfo : {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle, 
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase:  action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId:  action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId, 
                    driveType: action.fitmentVehicleInfo.driveType, 
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,  
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_WHEEL:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle, 
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase:  action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId:  action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId, 
                    driveType: action.fitmentVehicleInfo.driveType, 
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId, 
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_TRIM:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle, 
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase:  action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId:  action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId, 
                    driveType: action.fitmentVehicleInfo.driveType, 
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId, 
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_DRIVE:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle, 
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase:  action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId:  action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId, 
                    driveType: action.fitmentVehicleInfo.driveType, 
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId, 
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_ENGINE:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle, 
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase:  action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId:  action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId, 
                    driveType: action.fitmentVehicleInfo.driveType, 
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId, 
                },
                vehicleParams: action.vehicleParams
            };

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_VEHICLE_COMBINATIONS_ASYNC_FAIL:
            return { ...state, isFetchingVehicleCombinations: false, error: action, showError: true };

        case type.SET_VEHICLE_COMBINATIONS_FAIL:
            return { ...state, isFetchingVehicleCombinations: false, error: action, showError: true };

        case type.LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC_FAIL:
            return { ...state, isFetchingFitmentCheck: false, error: action, showError: true, showLoadingBtn: false };

        case type.SET_FITMENT_CHECK_FAIL:
            return { ...state, isFetchingFitmentCheck: false, error: action, showError: true, showLoadingBtn: false };

        default: return state;
    }

}

export default FitmentCheckReducer