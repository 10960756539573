
import React, { Component } from 'react';
import { Translation } from 'react-i18next';

export class FAQTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let faqObject = [];
        let faqData = this.props.faqData;
        if (faqData !== undefined) {
            faqData.map((item) => {
                faqObject.push(item);
            })
        };

        let faqBody = faqObject.map((item, index) => {
            return <Translation key={index}>{(t) => (
                <h5 className="q-text">
                    <b><span key={index}>{t("LABEL_PRODUCT_DETAILS_FAQ_QUESTION")}: {item.question}</span><br/></b>
                    <span key={index + 1} className="ac-faqAnswer">{t("LABEL_PRODUCT_DETAILS_FAQ_ANSWER")}: {item.answer}</span>
                </h5>)}</Translation>
        });
        return(
            <Translation>{(t) => (
                <div className="ac-tabContent">
                    <div>
                        <h2 className="q-headline2">{t(this.props.faqTitle)}</h2>
                    </div>{faqBody}
                </div>
            )}</Translation>
        );
    }
}

export default FAQTab
