import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import AddressDetails from './AddressDetails/AddressDetails';

class Addresses extends Component {
    render(){
        return(
            <Translation>
                {
                    (t)=>(
                        <div className="ac-address-section small-12 grid-column-alignment-left columns">
                            <h3 className="q-headline3">{t("LABEL_PROFILE_ADDRESSES")}</h3>
                            <div>
                                <AddressDetails
                                    profilesettings={this.props.profilesettings}
                                    personalInfo={this.props.personalInfo}
                                    toggleAddressEdit={this.props.toggleAddressEdit}
                                    toggleAddressAdd={this.props.toggleAddressAdd}
                                    resetProfileToggle={this.props.resetProfileToggle}/>
                            </div>

                        </div>
                    )
                }

            </Translation>
        )
    }
}

export default Addresses;