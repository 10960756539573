
/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_ERROR_PAGE_DATA_ASYNC = 'LOAD_ERROR_PAGE_DATA_ASYNC';

/**************************************************************/
// Actions to set data (handle in HomeCarouselReducer switch statement)
/**************************************************************/
export const SET_ERROR_PAGE_DATA = 'SET_ERROR_PAGE_DATA';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_ERROR_PAGE_DATA_ASYNC_FAIL = "LOAD_ERROR_PAGE_DATA_ASYNC_FAIL";
export const SET_ERROR_PAGE_DATA_FAIL = 'SET_ERROR_PAGE_DATA_FAIL';