/*** COMPONENT NAME : DEALER LIST (LIST OF THE DEALERS ON THE LEFT SIDE OF THE DEALER LOCATOR) ***/

/** IMPORT STATEMENT **/
import React from 'react';
import { connect } from 'react-redux';
import { handleSelectDealer, handleScroller } from '../../DealerLocatorRedux/DealerLocatorActionCreators';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import AppSettings from '../../../../core/AppSettings';

const DealerList = (props) => {

    /*** VARIABLES ***/
    const data = props.item;
    const name = data.name;
    const distance = data.distance;
    const address1 = data.address1;
    const address2 = data.address2;
    const city = data.city;
    const state = data.state;
    const postalCode = data.postalCode;
    const phone = data.phone;
    const marker = data.marker;
    const distanceMetric = data.distanceMetric;
    const lastItem = props.lastIndex;
    const isRewards = data.rewardsProgram;

    const brand = AppSettings.currentSite.label;

    return <div onClick={props.handleClickEvent} className="ac-dealerListInfoWrapper stat-text-link" id={data.bac} data-dtm="checkout step2:locate your dealer:select dealer">
        <div className="small-1 columns">
            <img src={marker} alt={""} />
        </div>
        <div className="small-8 columns">
            <div className="ac-dealerName">
                {name}
            </div>
            <div className="ac-address1">
                <span>{address1}</span>
            </div>
            <div className="ac-address2">
                <span>{address2}</span>
            </div>
            <div className="ac-cityStateZip">
                <span className="ac-city">{city},</span>
                <span className="ac-state">{state}</span>
                <span className="ac-postalCode">{postalCode}</span>
            </div>
            <div className="ac-phone">
                <span>{phone}</span>
            </div>
            {/* indicate dealer participation in rewards program */}
            {(isRewards == "true"|| isRewards===true) && <div>{i18next.t("LABEL_CHECKOUT_DEALER_LOCATOR_REWARDS_INDICATOR", { brand })}</div>}
        </div>
        <div className="small-3 columns">
            <span className="ac-dealerDistance">{distance} {distanceMetric}</span>
        </div>

        {
            /*** SHOW HORIZONTAL ROW UNLESS ITS LAST ITEM ***/
            <hr />
        }
    </div>

};

/*** MAP-DISPATCH-TO-PROPS
 * handleScroller : MOVE THE SCROLLER UP AND DOWN TO ALIGN THE ITEM VERTICALLY ON CENTER.
 * selectDealer :   SELECT THE DEALER, CHANGE THE MARKET TO SELECTED DEALER
 *
 * **/
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClickEvent: () => {
            handleScroller(ownProps.item.bac);
            dispatch(handleSelectDealer(ownProps.item));
        }
    }
};

DealerList.propTypes = {
    item: PropTypes.object,
    count: PropTypes.number
}

export default connect(null, mapDispatchToProps)(DealerList);

