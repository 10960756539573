import React from 'react';
import { Translation } from 'react-i18next';

import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import Button from '../../../shared/Button/Button';
import { pageTitle } from "../../../shared/Hooks/Hooks";

function PasswordResetExpired() {

    pageTitle("Reset Link Expired");

    function handleTryAgain() {
        window.location.href = '/forgotUsernamePassword';
    }

    return (
        <Translation> 
            {(t) => (
                <CenterGrid headerLabel={t("LABEL_PASSWORD_RESET_TOKEN_EXPIRED")}>
                    <div className="q-text q-body2 medium-margin">{t("LABEL_PASSWORD_RESET_TOKEN_EXPIRED_INSTRUCTIONS")}</div>
                    <Button
                        localeKey="LABEL_COMMON_TRY_AGAIN"
                        onClick={() => handleTryAgain()} />
                </CenterGrid>
        )}</Translation>
    )
}

export default PasswordResetExpired;
