/** COMPONENT NAME : MSRPCOMPONENT (SHOW PRICE IN  **/

/** IMPORT STATEMENT **/
import React from 'react';
import AppSetting from '../../../core/AppSettings';
import {Translation} from 'react-i18next';
import PropTypes from 'prop-types';
import { formattedCurrency } from "../../Internationalization/FormattedNumbers";

const MsrpComponent = (props)=>{

    let currencyType = 'USD';
    if (props.currencyType) {
        currencyType = props.currencyType;
    }
    const price = formattedCurrency(currencyType, props.price);
    const isT3 = AppSetting.isT3;

    /** Adding class based on the page user in ***/
    const chooseClass = ()=> props.component==="miniCartWrapper" ? 'ac-mini-msrpComponent-delivery' : props.component==='delivery' ? 'ac-msrpComponent-delivery' : 'ac-msrpComponent';

    return(<Translation>
            {
                (t)=>(
                    <div className={chooseClass()}>

                        { /** DISPLAY MSRP FOR T1 STORE**/}
                        {!isT3 && <span className="ac-t1msrp">{t("LABEL_COMMON_MSRP")}: {price}*</span>}
                        { /** DISPLAY MSRP FOR T1 STORE (NO MSRP LABEL TO SHOW)**/}
                        {isT3 && <span>{t("LABEL_PART_PRICE")} {price}</span>}

                    </div>
                )
            }
        </Translation>
    )
};

/** VALIDATING PROP TYPES **/

MsrpComponent.proptypes = {
    price:PropTypes.string.isRequired
};

export default MsrpComponent