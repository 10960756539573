import * as summaryRailType from '../../../shared/SummaryRail/SummaryRailRedux/SummaryRailActionType';
import * as type from './CheckoutActionType';

const CHECKOUT_LABELS = [
    'LABEL_CHECKOUT_STEP_ONE',
    'LABEL_CHECKOUT_STEP_TWO',
    'LABEL_CHECKOUT_STEP_THREE',
    'LABEL_CHECKOUT_STEP_FOUR'
];

//CRITICAL ALERTS/ERROR MESSAGES THROUGH CHECKOUT
const CHECKOUT_ERRORS = {
    adrressValidationError: 'LABEL_ADDRESS_VALIDATION_INCORRECT_WARNING',
    addressNotSupported: 'LABEL_CHECKOUT_CANNOT_SHIP',
    getCouponsFail: 'LABEL_CHECKOUT_CANNOT_DISPLAY_COUPON_CODES',
    applyCouponsFail: 'LABEL_CHECKOUT_CANNOT_APPLY_COUPON_CODES',
    removeCouponsFail: 'LABEL_CHECKOUT_CANNOT_REMOVE_COUPON_CODES',
    voucherInvalidError: 'LABEL_CHECKOUT_VOUCHER_ERROR',
    paymentInvalidError: "LABEL_CHECKOUT_MASTERCARD_PAYMENT_ERROR",
    genericError: 'LABEL_CHECKOUT_BAC_ERROR'
}

let initialState = {
    orderId: 0,
    currentStep: 1,
    currentLabel: 'LABEL_CHECKOUT_STEP_ONE',
    couponDisplayData: [],
    sendToHome: false,
    preventStepOneSkip: false,
    shippingContactForm: {
        stateList: [],
        stateNameToCode: {},
        stateCodeToName: {},
        initialValues: {
            firstName: '',
            lastName: '',
            companyName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            phone: '',
            email: ''
        }
    },
    // delivery
    deliveryMethodShowSpinner: new Set([]),
    couponMsg: '',
    promoIdMsg: '',
    deliveryMissingParams: [],

    // payment
    paymentPage: {
        showSpinner: true,
        isBillingIsSameAsShipping: false,
        billingInformation: {},
        paymentInformation: {},
        showTechnicalError: false,
        showPaymentUserError: false,
        showMissingPaymentError: false,
        payMethodId: '',
        triggeredPayMethod: false,
        payMethodError: false,
        cvv_Info: ''
    },
    isLoading: true,
    addressModalOpen: false,
    originalAddressForm: [],
    validatedResponse: [],
    validity: true,
    fedExAvailability: true,
    stateZipMismatch: false,

    //review page:
    showOrderProcessingModal: false,
    submitOrderInfo: {
        submitOrderId: '',
        submitOrderStatus: ''
    },
    sendToOrderConfirmation: false,
    showOrderErrorMsg: false,
    showShippingErrorModal: false,
    toggleShippingErrorModal: false,

    //CRITICAL ALERTS/ERROR MESSAGES THROUGHOUT CHECKOUT
    hideCheckoutAlert: true,
    currentCheckoutError: '',
    switchOrgFlag: true,
    zeroCheckoutAmount: false
};



const CheckoutReducer = (state = initialState, action) => {
    switch (action.type) {

        case type.CHECKOUT_NEXT_STEP:
            const nextStep = state.currentStep + 1;

            // Makes analtyics direct call for checkout step.
            if (nextStep != 1 && typeof _satellite != 'undefined') {
                _satellite.track(`checkout-step${nextStep}`);
            }

            return Object.assign({}, state, {
                currentStep: nextStep,
                currentLabel: CHECKOUT_LABELS[nextStep - 1],
                hideCheckoutAlert: true
            });

        case type.CHECKOUT_PREV_STEP:
            const prevStep = state.currentStep - 1;

            // Makes analtyics direct call for checkout step.
            if (typeof _satellite != 'undefined') {
                if (prevStep == 1) {
                    _satellite.track(`checkout`);
                } else {
                    _satellite.track(`checkout-step${prevStep}`);
                }
            }

            return Object.assign({}, state, {
                currentStep: prevStep,
                currentLabel: CHECKOUT_LABELS[prevStep - 1]
            });

        case type.CHECKOUT_SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });

        case type.CHECKOUT_DELIVERY_METHOD_SHOW_SPINNER:
            let deliveryMethodShowSpinner = state.deliveryMethodShowSpinner;
            if (action.showSpinner === true) {
                deliveryMethodShowSpinner.add(action.orderItemId);
            } else if (deliveryMethodShowSpinner.has(action.orderItemId)) {
                deliveryMethodShowSpinner.delete(action.orderItemId);
            }
            return Object.assign({}, state, {
                deliveryMethodShowSpinner: deliveryMethodShowSpinner,
                deliveryMissingParams: []
            });

        case type.CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC:
            return Object.assign({}, state, {
                hideCheckoutAlert: true
            });

        case type.CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                hideCheckoutAlert: false,
                currentCheckoutError: action?.error ? action.error : CHECKOUT_ERRORS.genericError
            });

        case type.CHECKOUT_LOAD_COUPON_DATA_ASYNC:
            return Object.assign({}, state, {
                couponDisplayData: action.couponDisplayData
            });

        case type.HANDLE_COUPON_RESPONSE_MSG:
            return Object.assign({}, state, {
                couponMsg: action.couponMsg
            });

        case summaryRailType.RESET_COUPON_RESPONSE_MSG:
            return Object.assign({}, state, {
                couponMsg: ''
            });

        case type.HANDLE_PROMOTION_ID_RESPONSE_MSG:
            return Object.assign({}, state, {
                promoIdMsg: action.promoIdMsg
            });

        case summaryRailType.RESET_PROMOTION_ID_RESPONSE_MSG:
            return Object.assign({}, state, {
                promoIdMsg: ''
            });

        case type.CHECKOUT_COUPON_SUBMIT_FAIL:
            let getCouponsError = CHECKOUT_ERRORS.getCouponsFail
            if (action.error) {
                getCouponsError = action.error;
            }
            return Object.assign({}, state, {
                promoIdMsg: getCouponsError
            });

        case type.CHECKOUT_APPLY_COUPON_FAIL:
            let applyCouponsError = CHECKOUT_ERRORS.applyCouponsFail
            if (action.error) {
                applyCouponsError = action.error.message;
            }
            return Object.assign({}, state, {
                promoIdMsg: applyCouponsError
            });

        case type.CHECKOUT_REMOVE_COUPON_FAIL:
            let removeCouponsError = CHECKOUT_ERRORS.removeCouponsFail
            if (action.error) {
                removeCouponsError = action.error.message;
            }
            return Object.assign({}, state, {
                promoIdMsg: removeCouponsError
            });

        case type.CHECKOUT_PAYMENT_BILLING_AS_SHIPPING:
            return (state.paymentPage.isBillingIsSameAsShipping ? Object.assign({}, state, {
                paymentPage: {
                    isBillingIsSameAsShipping: false,
                    showSpinner: state.paymentPage.showSpinner,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info,
                    billingInformation: {
                        firstName: "",
                        lastName: "",
                        address1: "",
                        address2: "",
                        companyName: "",
                        city: "",
                        state: "",
                        zipCode: ""

                    }
                }
            }) : Object.assign({}, state, {
                paymentPage: {
                    isBillingIsSameAsShipping: true,
                    showSpinner: state.paymentPage.showSpinner,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info,
                    billingInformation: {
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        companyName: action.payload.companyName,
                        address1: action.payload.address1,
                        address2: action.payload.address2,
                        city: action.payload.city,
                        state: action.payload.state,
                        zipCode: action.payload.zipCode
                    }
                }
            }));
            case type.CHECKOUT_PAYMENT_BILLING_NOT_AS_SHIPPING:
            return Object.assign({}, state, {
                paymentPage: {
                    isBillingIsSameAsShipping: false,
                    showSpinner: state.paymentPage.showSpinner,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info,
                    billingInformation: {
                        firstName: action.payload.firstName,
                        lastName: action.payload.lastName,
                        companyName: action.payload.companyName,
                        address1: action.payload.address1,
                        address2: action.payload.address2,
                        city: action.payload.city,
                        state: action.payload.state,
                        zipCode: action.payload.zipCode
                    }
                }
            });
        case type.CHECKOUT_PAYMENT_SPINNER:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });

        case type.RESET_PAYMENT_INFORMATION: {
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner || false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: {},
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                },
                isRoscaChecked: true,
                roscaDisclosureMsg: null
            })
        }
        case type.UPDATE_CREDIT_CARD_SUCCESS:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner || false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: action.payload.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info

                }
            });
        case type.UPDATE_BILLING_INFORMATION:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: action.payload.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });
        case type.SHOW_PAYMENT_TECHNICAL_ERROR:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner || false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: {},
                    showTechnicalError: true,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });
        case type.SHOW_MISSING_PAYMENT_ERROR:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner || false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: {},
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showMissingPaymentError: true,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });
        case type.RESET_ALL_ERRORS_BEFORE_SUBMIT:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner || false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: false,
                    showPaymentUserError: false,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.triggeredPayMethod,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info

                }
            });
        case type.CHECK_PAYMENT_METHOD:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: action.payload,
                    triggeredPayMethod: true,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });
        case type.CHECK_PAYMENT_METHOD_ERROR:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    cvv_Info: state.paymentPage.cvv_Info,
                    payMethodId: '',
                    triggeredPayMethod: true,
                    payMethodError: true,

                }
            });
        case type.SHOW_PAYMENT_USER_ERROR:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: true,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.showTechnicalError,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });
        case type.CHECKOUT_SHIPPING_ERROR:
            return Object.assign({}, state, {
                showShippingErrorModal: action.open
            })
        case type.CHECKOUT_TOGGLE_SHIPPING_ERROR:
            return Object.assign({}, state, {
                toggleShippingErrorModal: action.open
            })
        case type.CHECKOUT_SHIPPING_CONTACT_SET_INITIAL_VALUES:
            const updatedShippingContactForm = Object.assign({}, state.shippingContactForm, {
                initialValues: action.initialValues
            });
            return Object.assign({}, state, {
                shippingContactForm: updatedShippingContactForm,
                isLoading: false
            });

        case type.CHECKOUT_SEND_TO_HOME_PG:
            return Object.assign({}, state, {
                isLoading: false,
                sendToHome: true
            });

        case type.CHECKOUT_SET_COUNTRY_STATE_LIST:
            const stateList = action.stateList;
            const stateNameToCode = action.stateNameToCode;
            const stateCodeToName = action.stateCodeToName;
            return Object.assign({}, state, {
                shippingContactForm: {
                    stateList: stateList,
                    stateNameToCode: stateNameToCode,
                    stateCodeToName: stateCodeToName
                }
            });

        case type.SHOW_ADDRESS_MODAL:
            const originalAddressForm = action.originalAddressForm;
            const validatedResponse = action.validatedResponse;
            const validity = action.validity;
            const fedExAvailability = action.fedExAvailability;
            const stateZipMismatch = action.stateZipMismatch;
            const POBox = action.POBox;
            const addressValid = action.addressValid;

            return Object.assign({}, state, {
                addressModalOpen: true,
                originalAddressForm: originalAddressForm,
                validatedResponse: validatedResponse,
                hideCheckoutAlert: true,
                validity: validity,
                fedExAvailability: fedExAvailability,
                stateZipMismatch: stateZipMismatch,
                POBox: POBox, 
                addressValid: addressValid
            });
        case type.CLOSE_ADDRESS_MODAL:
            return Object.assign({}, state, {
                addressModalOpen: false,
            });
        case type.SHOW_ADDRESS_ALERT:
            return Object.assign({}, state, {
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.adrressValidationError,
            });
        case type.SHOW_ADDRESS_NOT_SUPPORTED_ALERT:
            return Object.assign({}, state, {
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.addressNotSupported,
            });

        case type.SHOW_GENERIC_ERROR_ALERT:
            return Object.assign({}, state, {
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.genericError,
            });
        case type.HIDE_ADDRESS_ALERT:
            return Object.assign({}, state, {
                hideCheckoutAlert: true
            });

        case type.CHECKOUT_EDIT_FORM:
            const step = action.payload;

            return Object.assign({}, state, {
                currentStep: step,
                currentLabel: CHECKOUT_LABELS[step - 1]
            });

        case type.CHECKOUT_ROSCA_CHECKBOX:
            return Object.assign({}, state, {
                isRoscaChecked: action.isRoscaChecked,
                roscaDisclosureMsg: action.roscaDisclosureMsg
            });

        case type.SUBMITTING_ORDER:
            return Object.assign({}, state, {
                showOrderProcessingModal: true,
            });
        case type.NEVER_CLOSE_MODAL:
            return Object.assign({}, state, {
                showOrderProcessingModal: true,
            });
        case type.SET_PAYMENT_INFO:
            return Object.assign({}, state, {
                paymentErrorMsg: action.paymentErrorMsg,
            });
        case type.SET_ORDER_INFO:
            return Object.assign({}, state, {
                submitOrderInfo: {
                    submitOrderId: action.orderId,
                    submitOrderStatus: action.orderStatus,
                },
                showOrderProcessingModal: false
            });
        case type.SEND_TO_ORDER_CONFIRMATION:
            const sendToOrderConfirmation = action.sendToOrderConfirmation;
            return Object.assign({}, state, {
                sendToOrderConfirmation: sendToOrderConfirmation
            });
        case type.SUBMITTING_ORDER_FAIL:
            return Object.assign({}, state, {
                showOrderProcessingModal: false,
                showOrderErrorMsg: true,
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.genericError,
            });
        case type.SUBMITTING_PAYMENT_FAIL:
            return Object.assign({}, state, {
                showOrderProcessingModal: false,
                showOrderErrorMsg: true,
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.paymentInvalidError
            });
        case type.SUBMITTING_ORDER_VOUCHER_FAIL:
            return Object.assign({}, state, {
                showOrderProcessingModal: false,
                showOrderErrorMsg: true,
                hideCheckoutAlert: false,
                currentCheckoutError: CHECKOUT_ERRORS.voucherInvalidError,
            });
        case type.SUBMITTING_ORDER_SOFT_FAIL:
            return Object.assign({}, state, {
                showOrderProcessingModal: false,
                showOrderErrorMsg: false,
                hideCheckoutAlert: true
            });
        case type.CHECKOUT_SET_DELIVERY_ERROR_TRUE:
            return Object.assign({}, state, {
                deliveryError: true
            });
        case type.CHECKOUT_SET_DELIVERY_ERROR_FALSE:
            return Object.assign({}, state, {
                deliveryError: false
            });
        case type.CHECKOUT_DELIVERY_PAGE_FAIL:
            return Object.assign({}, state, {
                hideCheckoutAlert: false,
                currentCheckoutError: action.error ? action.error : CHECKOUT_ERRORS.genericError, // display generic error unless specified
                deliveryMissingParams: action.params
            });
        case type.CHECKOUT_ON_DELIVERY_FORM_PREVIOUS:
            return Object.assign({}, state, {
                hideCheckoutAlert: true
            });
        case type.CHECKOUT_DELIVERY_PAGE_FAIL_TAX:
            return Object.assign({}, state, {
                deliveryErrorTax: true
            });
        case type.CHECKOUT_DELIVERY_PAGE_TAX_FALSE:
            return Object.assign({}, state, {
                deliveryErrorTax: false
            });
        case type.RESET_ORDER_PROCESSING_MODAL:
            return Object.assign({}, state, {
                showOrderProcessingModal: false
            });
        case type.CHECKOUT_DELIVERY_PAGE_SWITCH_ORG_FLAG:
            return Object.assign({}, state, {
                switchOrgFlag: false
            });
        case type.HANDLE_DEFAULT_DELIVERY_METHOD_FAIL:
            return Object.assign({}, state, {
                hideCheckoutAlert: false,
                currentCheckoutError: action.error,
            });
        case type.CHECKOUT_ZERO_DOLLAR_AMOUNT:
            return Object.assign({}, state, {
                zeroCheckoutAmount: action.payload
            });

        case type.TURN_OFF_SPINNER_FOR_ZERO_CHECKOUT:
            return Object.assign({}, state, {
                isLoading: false,
                paymentPage: {
                    showSpinner: false,
                    isBillingIsSameAsShipping: state.paymentPage.isBillingIsSameAsShipping,
                    billingInformation: state.paymentPage.billingInformation,
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.showTechnicalError,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.cvv_Info
                }
            });
        case type.RESET_BILLING_INFO:
            return Object.assign({}, state, {
                paymentPage: {
                    showSpinner: state.paymentPage.showSpinner,
                    isBillingIsSameAsShipping: false,
                    billingInformation: {},
                    paymentInformation: state.paymentPage.paymentInformation,
                    showTechnicalError: state.paymentPage.showTechnicalError,
                    showPaymentUserError: state.paymentPage.showPaymentUserError.error,
                    payMethodId: state.paymentPage.payMethodId,
                    triggeredPayMethod: state.paymentPage.showTechnicalError,
                    payMethodError: state.paymentPage.payMethodError,
                    cvv_Info: state.paymentPage.cvv_Info
                }
            });

        case type.CHECKOUT_PREVENT_STEP_ONE_SKIP:
            return Object.assign({}, state, {
                preventStepOneSkip: true,
            })

        default:
            return state;
    }
};

export default CheckoutReducer;
