import React from "react";

/**
 * Resets vault to its default configuration and reloads the page.
 */
export const resetAllValues = () => void (window.VaultController.resetVaultInstance(), location.reload());

/**
 * Applies the requested changes to the vault configuration and reloads the page.
 * @param {Object<string, string>} sets the key-value map of vault overrides to apply
 * @param {Object<string, true>} resets the 'set' of keys to restore to default values
 */
export const saveAndApplyAll = (sets, resets) => {
    console.log("INSIDE SAVE AND APPLY ALL FUNCTION");
    if (!Object.keys({ ...sets, ...resets }).length) return null;

    if (Object.keys(resets).length) {
        window.VaultController.resetValue(Object.keys(resets));
    }

    if (Object.keys(sets).length) {
        window.VaultController.apply(sets);
    }

    window.VaultController.store();
    location.reload();
};

/**
 * Renders a floating back button in the upper-left corner of the page
 * @param {{target: string, disabled: boolean, onBack: Function}} props properties for the button
 * @returns {React.ReactElement}
 */
export const BackButton = (props) => {
    return (
        <button
            className="vc-button vc-back-button"
            variant="secondary"
            onClick={() => props.onBack?.(props.target)}
            disabled={props.disabled}
        >
            Back
        </button>
    );
};
