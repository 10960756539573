import axios from "axios";
import store from "../../../core/Redux/Store";
import { updateVehicleInfoByData } from "../../Session/VehicleInfo/VehicleInfoActionCreator";

/**
 * This method sets the vehicle fitment cookie by calling the "/node/setFitmentCookie" endpoint
 * @param {Object} fitment Vehicle fitment object
 */

export const setFitmentCookie = async (fitment) => {
    let setCookieUrl = `/node/setFitmentCookie?vin=${[fitment.vin]}&year=${[fitment.year]}&make=${[
        fitment.make
    ]}&makeId=${[fitment.makeId]}&model=${[fitment.model]}&modelId=${[fitment.modelId]}&body=${[
        fitment.body
    ]}&bodyId=${[fitment.bodyId]}&wheel=${[fitment.wheel]}&wheelId=${[fitment.wheelId]}&trim=${[
        fitment.trim
    ]}&trimId=${[fitment.trimId]}&drive=${[fitment.drive]}&driveId=${[fitment.driveId]}&engine=${[
        fitment.engine
    ]}&engineId=${[fitment.engineId]}&bodyNumDoorsId=${[fitment.bodyNumDoorsId]}`; 
    try {
        await axios.get(setCookieUrl);
        const data = await validateFitmentCookie();
        const fitmentCookie = data.data.cookie;
        if(fitmentCookie){
            store.dispatch(updateVehicleInfoByData(fitmentCookie))
        }
        // on the SERP page it is possible to have only year, or only year and make. 
        // this condition will cause an invalid cookie, so we are using the bellow else if
        // to dispatch updateVehicleInfoData with the fitment instead of an unidentified cookie. 
        else if (fitment.searchTerm) {
            store.dispatch(updateVehicleInfoByData(fitment));
        }
    } catch (e) {
        console.warn("encountered error with setting fitment cookie:\n", e.toString());
    }
};
/**
 * This method validates the current fitment cookie by calling the "/node/validateFitmentCookie" endpoint
 * and will also delete the cookie if it fails our validation check
 */
export const validateFitmentCookie = async () => {
    const validateCookieUrl = `/node/validateFitmentCookie`;
    try {
        return await axios.get(validateCookieUrl).then((response) => response);
    } catch (e) {
        console.warn("encountered error with fitment cookie validation:\n", e.toString());
    }
};
/**
 * This deletes the current fitment cookie by calling the "/node/deleteFitmentCookie" endpoint
 *
 */
export const deleteFitmentCookie = () => {
    const deleteCookieUrl = `/node/deleteFitmentCookie`;
    try {
        return axios.get(deleteCookieUrl).then((response) => response);
    } catch (e) {
        console.warn("encountered error with fitment cookie deletion:\n", e.toString());
    }
};
