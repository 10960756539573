import React, { useState } from 'react';
import AppSettings from '../../core/AppSettings';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function SearchBar(props) {

    const backgroundImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/search.svg";

    const [active, setActive] = useState(false);

    return (
        <div className="ac-search-bar">
            <form
                autoComplete="off"
                onSubmit={e => props.onSubmit(e)}>
                <div className="ac-search-bar-field stat-search-input" data-dtm="search">
                    <input
                        type="text"
                        value={props.value}
                        placeholder={props.placeholder}
                        style={{ backgroundImage: `url(${backgroundImg})` }}
                        onChange={e => {
                            setActive(true);
                            props.onChange(e);
                        }}
                        onFocus={e => props.onFocus(e)}
                        onClick={e => props.onClick(e)}
                        onBlur={() => setActive(false)}
                        onKeyDown={e => props.onKeyDown(e)}
                    />
                </div>
                {active && props.dropdownContent &&
                    // onMouseDown function prevents onBlur, which was causing the dropdown to be closed before the Link event fired
                    <div id="ac-search-dropdown" className="ac-search-dropdown" onMouseDown={e => e.preventDefault()} onClick={() => setActive(false)}>
                        {props.dropdownContent}
                    </div>
                }
                {
                    props.errorMessage &&
                    <FieldErrorMessage message={props.errorMessage} />
                }
            </form>
        </div>
    );
}

export default SearchBar;