import * as type from './HomeCategoriesActionType';

/**************************************************************/
// Actions to load data into the HomeCarouselReducer state
/**************************************************************/
export const loadHomeCategoriesDataAsync = () => {
    const action = {
        type: type.LOAD_HOME_CATEGORIES_DATA_ASYNC
    }
    return action;
}

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const setHomeCategoriesData = (assets, title) => {
    const action = {
        type: type.SET_HOME_CATEGORIES_DATA,
        categoriesAssets: assets,
        categoriesTitle: title
    }
    return action;
}

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const selectedCategoryTitle = (title) => {
    const action = {
        type: type.SELECTED_CATEGORY_TITLE,
        selectedCategoryTitle: title,
    }
    return action;
}

export const resetHomeCategory = () => {
    const action = {
        type: type.RESET_HOME_CATEGORY
    }
    return action;
}


/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadHomeCategoriesDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_HOME_CATEGORIES_DATA_ASYNC_FAIL,
        error
    }
    return action;
}

export const setHomeCategoriesDataFail = (error) => {
    const action = {
        type: type.SET_HOME_CATEGORIES_DATA_FAIL,
        error
    }
    return action;
}