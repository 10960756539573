import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductCard from "../../../shared/ProductCard/ProductCard";
import "./ProductDetailsSimilarItems.scss";
import AppSettings from "../../../core/AppSettings";
import { formattedCurrency } from "../../../shared/Internationalization/FormattedNumbers";
import i18n from "../../../core/i18n/i18n";
import * as utils from "../../../shared/Utils/Utils";
import {setSimilarItemsData} from "./ProductDetailsSimilarItemsRedux/SimilarItemsActionCreator";
import store from "../../../core/Redux/Store";

// https://www.npmjs.com/package/slick-carousel - Documentation for the slick library we are using
const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
                arrows: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                arrows: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ]
};

const ProductDetailsSimilarItems = () => {
    const [cards, setCards] = useState([]);
    const [areCardsMapped, setAreCardsMapped] = useState(false);
    const session = useSelector((state) => state.Session);
    const productDetailsData = useSelector((state) => state.SimilarItemsReducer);

    useEffect(() => {
        return () => {
            store.dispatch(setSimilarItemsData([]));
        }
    }, [])

    useEffect(() => {
        // Initialize slick-carousel
        const isMobile = window.innerWidth < AppSettings.MEDIUM_BREAKPOINT;

        if (!isMobile && areCardsMapped) {
            setTimeout(() => {
                $(".jSlider").not(".slick-initialized").slick();
            }, 1000);
            // Destroy slick
            return () => {
                $(".jSlider").slick("unslick");
            };
        }
    }, [AppSettings.isMobile, areCardsMapped]);

    useEffect(() => {
        if (productDetailsData.similarItems?.length) {
            const cardArray = productDetailsData.similarItems.map((item) => {
                const imgNotFound = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/blank_car.png";

                let image = "";
                if (item.UserData[0] != undefined && item.UserData[0].images != undefined) {
                    image = item.UserData[0].images.mainImage;
                }
                let currency = i18n.t("CURRENCY");
                if (item.price[0].currency) {
                    currency = item.price[0].currency;
                }
                let price = formattedCurrency(currency, item.price[0].value);
                let productPriceDisclosure = "";
                let installedPrice = "";
                let productPriceLabel = i18n.t("LABEL_COMMON_MSRP");
                let productPriceAsterisk = "*";
                if (AppSettings.isT3) {
                    price = formattedCurrency(currency, item.price[1].value);

                    if (item.installPrice) {
                        installedPrice = formattedCurrency(currency, item.installPrice);
                    } else {
                        installedPrice = i18n.t("LABEL_SEARCH_RESULTS_CONTACT_DEALER");
                    }
                    productPriceLabel = i18n.t("LABEL_SEARCH_RESULTS_PART_ONLY");
                    productPriceDisclosure = i18n.t("LABEL_SEARCH_RESULTS_INSTALLED_PRICE");
                    productPriceAsterisk = "";
                }
                let { name, seoUrl, uniqueID } = item;

                //Checks if there is already a bac param if there is don't want duplicate so just replace with empty string
                const bacKey = "&bac=";
                let t3Check = utils.getSessionParams();
                let bacDupl = t3Check.match(new RegExp(bacKey + "\\d{6}"));

                if (bacDupl) {
                    t3Check = t3Check.replace(bacDupl[0], "");
                }

                let productLink = seoUrl + t3Check;

                return (
                    <ProductCard
                        key={uniqueID}
                        productLink={productLink}
                        productImg={image}
                        imgNotFound={imgNotFound}
                        productImgSize="/640X640"
                        productName={name}
                        modalLink=""
                        productPriceLabel={productPriceLabel}
                        productPrice={price}
                        productPriceAsterisk={productPriceAsterisk}
                        productPriceDisclosure={`${productPriceDisclosure} ${installedPrice}`}
                        buttonLabel={i18n.t("LABEL_SEARCH_VIEW_DETAILS")}
                        aTag={true}
                    />
                );
            });
            setCards(cardArray);
            setAreCardsMapped(true);
        }
    }, [productDetailsData.similarItems, session]);

    if (!cards || !cards.length) return null;

    return (
        <div>
            <div className="small-12 ac-similarItemsTitle">
                <h2 className="q-headline2">Similar Items</h2>
            </div>
            <div className="q-gallery-full-scroller">
                <div className="q-multimedia-scroller q-mod q-mod-multimedia-scroller">
                    <div className="jSlider" data-slick={JSON.stringify(sliderSettings)}>
                        {cards.map((card, index) => (
                            <div key={index} className="q-mod q-mod-slide q-slider-item">
                                {card}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductDetailsSimilarItems;
