import React, {useEffect, useState} from 'react'
import App from "./App";
import {isValidValue} from "../../shared/Validator/Validator";
import AppSettings from "../../core/AppSettings";
import {handleAzureSSOCheck} from "../../shared/Authentication/AuthenticationWorker";
import store from "../../core/Redux/Store";
import {getRegistrationStatus} from "../../shared/Authentication/AuthenticationActionCreator";
import PageSpinner from "../../shared/Spinner/PageSpinner";



const Main = ()=>{
    const [showLoading,setShowLoading] = useState(false);

    useEffect(()=>{
        let doneSSOCheck = isValidValue(sessionStorage.getItem("SSOCHECK"));
        const SSOCheckNotRequired = AppSettings.isT3 || sessionStorage.getItem('isRUser')==='true';
        let isSSOChecked = false;

        if(location.href.indexOf('isSSO=true') > -1) {
            isSSOChecked = true;
        }

        if(location.href.indexOf('authCheck=true') > -1){
            doneSSOCheck = true;
        }

        if(!doneSSOCheck && !SSOCheckNotRequired && AppSettings.pageName !=='Authenticate' && isSSOChecked){
            setShowLoading(true);
            handleAzureSSOCheck();
        }

        else if(AppSettings.pageName==='Authenticate'){
            setShowLoading(false);
            sessionStorage.removeItem('SSOCHECK');
        }
        else {
            setShowLoading(false);
            sessionStorage.removeItem('SSOCHECK');
            store.dispatch(getRegistrationStatus());
            }
    },[]);

    return (showLoading ? <PageSpinner/>:<App/>)
};

export default Main
