import queryString from "query-string";
import AppConfig from "./AppConfig.json";

const { sitesStoreMap, liveChatButtonAttributes } = AppConfig;

// make stores indexable by <number> AND <string> for compatibility:
for (const store in sitesStoreMap) sitesStoreMap[Number(store)] = sitesStoreMap[store];

// need to determine T3 label, which is readable at runtime:
sitesStoreMap[11205].label = getMake();

const storeId =
    document.getElementsByName("x-store") && document.getElementsByName("x-store")[0]
        ? document.getElementsByName("x-store")[0].content
        : "";

const routePaths = {
    home: "/",
    search: "/search",
    psr: "/product/search",
    product: "/product",
    cart: "/cart",
    checkout: "/checkout"
};

const browserLocalHost = window.location.href.indexOf("localhost") > -1;
const nodeEnvironment = process.env.NODE_ENV;

function isT3() {
    return sitesStoreMap[storeId].key === "dealer";
}
function getBasename() {
    let basename =
        document.getElementsByName("x-basename") && document.getElementsByName("x-basename")[0]
            ? document.getElementsByName("x-basename")[0].content
            : "";
    //handlebars vars are not resolved when not running node
    basename = basename === "{{basename}}" ? "" : basename;
    return basename;
}
function getCurrentSite() {
    return sitesStoreMap[storeId];
}

// Links for T1 and T3
function getHomeUrl() {
    if (isT3()) {
        return getDealerName() + "?bac=" + getBac();
    } else {
        return "/";
    }
}

function getBac() {
    let parseBac = queryString.parse(location.search).bac;
    let bac = "";
    if (parseBac) {
        bac = parseBac.replace(/[^\d]/g, "");
    }
    if (bac.length === 6) {
        return bac;
    } else {
        return "";
    }
}

function getDealerName() {
    if (isT3()) {
        let path = window.location.pathname;
        let urlPathArr = path ? path.split("?") : [];
        if (urlPathArr[0].split("/").length > 2) {
            let dealerPath = urlPathArr[0].split("/");
            return "/" + dealerPath[1];
        }
        return urlPathArr[0];
    } else {
        return "";
    }
}

export function getPageName() {
    let pageName = "";
    let path = window.location.pathname;
    let pathCat = path.split("/");
    switch (pathCat[1]) {
        case "":
            pageName = "HomePage";
            break;
        case "search":
            pageName = "Search";
            break;
        case "categories":
            pageName = "Category";
            break;
        case "product":
            pageName = "Product";
            break;
        case "cart":
            pageName = "Cart";
            break;
        case "checkoutmethod":
            pageName = "CheckoutMethod";
            break;
        case "checkout":
            pageName = "Checkout";
            break;
        case "orderconfirmation":
            pageName = "OrderReceipt";
            break;
        case "forgotUsernamePassword":
            pageName = "ForgotUsernamePassword";
            break;
        case "register":
            pageName = "Register";
            break;
        case "authenticate":
            pageName = "Authenticate";
            break;
        default:
            pageName = "HomePage";
    }
    return pageName;
}

function getOrderId() {
    return queryString.parse(location.search).orderId;
}

/*added a getMake function for the t3 dealer site.
 * helps to grab the chosen make for dealer rest calls that depend on "AppSettings.currentSite.label"*/
function getMake() {
    let path = document.URL.toString();
    const regexMake = /make=[a-z]*/;
    const matchMake = path.match(regexMake);
    let make = "";
    if (matchMake) {
        const makeSplit = matchMake.toString().split("=");
        make = makeSplit[1];
    } else {
        make = "";
    }
    return make;
}

function getDamSource() {
    let damSource = process.env.REACT_APP_DAM_SOURCE;
    if (damSource != undefined) {
        return damSource;
    } else {
        return "";
    }
}

function getAzureBaseLoginURL() {
    let azureBaseLoginURL = process.env.REACT_APP_AZURE_LOGIN_SOURCE.url;
    if (azureBaseLoginURL != undefined) {
        return azureBaseLoginURL;
    } else {
        return "";
    }
}

function getAzureSSOURL() {
    let azureSSOURL = process.env.REACT_APP_AZURE_LOGIN_SOURCE.ssoUrl;
    if (azureSSOURL != undefined) {
        return azureSSOURL;
    } else {
        return "";
    }
}
function getAzureBaseLogoutURL() {
    let azureBaseLogoutURL = process.env.REACT_APP_AZURE_LOGOUT_SOURCE;
    if (azureBaseLogoutURL != undefined) {
        return azureBaseLogoutURL;
    } else {
        return "";
    }
}

function getAzureLoginUserFlow() {
    let azureLoginUserFlow = process.env.REACT_APP_AZURE_LOGIN_SOURCE.userFlow;
    if (azureLoginUserFlow != undefined) {
        return azureLoginUserFlow;
    } else {
        return "";
    }
}

const XSMALL_BREAKPOINT = 576;
const SMALL_BREAKPOINT = 768;
const MEDIUM_BREAKPOINT = 991;
const LARGE_BREAKPOINT = 1200;
const EXTRA_LARGE_BREAKPOINT = 1400;

/**
 * A function to filter the list of sites to only the ones that
 * service a single GM make.
 *
 * @returns {object[]} the list of active single-make sites
 */
function getActiveSites() {
    const arr = Array.from(Object.entries(sitesStoreMap));
    const fullMap = arr.map((item) => ({ storeId: item[0], ...item[1] }));
    // test if makeCode is integer to tell if on single-make site
    const isMakeActive = (m) => !Number.isNaN(Number.parseInt(m.makeCode));

    return fullMap.filter((item) => isMakeActive(item));
}

// get and freeze list of single manufacturer sites
const activeMakes = Object.freeze(getActiveSites());
activeMakes.forEach((o) => Object.freeze(o));

const ENV_BRAND = process.env["REACT_APP_BRAND"];

const AppSettings = {
    storeId: storeId,
    brand: ENV_BRAND,
    country: "USA",
    currency: "USD",
    locale: "en_US",
    sitesStoreMap: sitesStoreMap,
    routePaths: routePaths,
    isT3: isT3(),
    damSource: getDamSource(),
    basename: getBasename(),
    currentSite: getCurrentSite(),
    azureBaseLoginURL: getAzureBaseLoginURL(),
    azureSSOURL: getAzureSSOURL(),
    azureBaseLogoutURL: getAzureBaseLogoutURL(),
    azureLoginUserFlow: getAzureLoginUserFlow(),
    langId: "-1",
    catalogId: "10052",
    oldUIUrl: "/webapp/wcs/stores/servlet",
    baseWCSURL: `/wcs/resources/store/${storeId}`,
    bac: getBac(),
    dealerName: getDealerName(),
    homeUrl: getHomeUrl(),
    orderId: getOrderId(),
    pageName: getPageName(),
    prevPageName: "",
    liveChatButtonAttributes: liveChatButtonAttributes,
    enableReduxLogger: false,
    isLocalHost: nodeEnvironment === "development" || browserLocalHost,
    thisMake: activeMakes.find((o) => o.key === ENV_BRAND) || Object.freeze({ key: ENV_BRAND }),
    urlParameters: () => queryString.parse(location.search),
    XSMALL_BREAKPOINT: XSMALL_BREAKPOINT,
    SMALL_BREAKPOINT: SMALL_BREAKPOINT,
    MEDIUM_BREAKPOINT: MEDIUM_BREAKPOINT,
    LARGE_BREAKPOINT: LARGE_BREAKPOINT,
    EXTRA_LARGE_BREAKPOINT: EXTRA_LARGE_BREAKPOINT
};

export default AppSettings;
