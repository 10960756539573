import React from 'react';
import {Translation, Trans, withTranslation, useTranslation} from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import Button from '../../../../shared/Button/Button';
import FieldErrorMessage from '../../../../shared/FieldErrorMessage/FieldErrorMessage';
import { renderCheckboxField } from '../../../../shared/Form/FormFields/FormFields';
import {formatNumber} from "../../../../shared/Internationalization/FormattedNumbers";
import Footnote from "../../../../shared/Footnote/Footnote";
import AppSettings from "../../../../core/AppSettings";
import vaultConstants from "../../../../../config/vault_constants";

const OrderConfirmationNonregistered = (props) => {
    let {rewardsPoints, brand, label, handleSubmit, handleEnrollInRewardsCheckboxClick, isEnrollRewardsChecked,
        privacyLink, rewardsTerms, isLoading} = props;

    const createMarkup = (text) => {
        return {
            __html: text
        };
    };
    const FF_2274900_CA_RESIDENTS_FOOTNOTE = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2274900_CA_RESIDENTS_FOOTNOTE) === 'true';
    const caDisclosure = process.env.REACT_APP_CA_FINANCIAL_INCENTIVE_DISCLOSURE;
    return (
        <Translation>
            {
                (t) => (
                    <>
                        <b className="ac-confirm-loyalty">
                            <div id="join-rewards-div" dangerouslySetInnerHTML={createMarkup(t("LABEL_CONFIRMATION_POTENTIAL_LOYALTY_USERS", {brand, label, rewardsPoints}))}/>
                        </b>
                        <br/>
                        <div id="sign-in-for-points">
                            {t("LABEL_CONFIRMATION_POTENTIAL_LOYALTY_USER3")}
                        </div>
                        <br/>
                        <form className="ac-account-details-form">
                            <div className="small-12">
                                <div className="ac-enroll-checkbox stat-checkbox" id="order-confirmation-enroll-checkbox">
                                    <Field
                                        name="ConfirmLoyaltyCheckbox"
                                        onChange={handleEnrollInRewardsCheckboxClick}
                                        checked={isEnrollRewardsChecked}
                                        component={renderCheckboxField}
                                        href={[rewardsTerms, privacyLink]}
                                        localeKey="LABEL_CONFIRMATION_ENROLLMENT"
                                        value={brand}
                                        dataDtmCheckBox={"order confirmation"} // DO I NEED UNIQUE LABELS HERE,
                                        dataDtmCheckBoxLink={["additional info","additional info"]} // AND HERE?
                                    />
                                </div>
                                {FF_2274900_CA_RESIDENTS_FOOTNOTE &&
                                    <div id="ac-ca-residents-footnote">
                                        <Footnote
                                            localeKey="LABEL_ACCOUNT_CA_RESIDENTS_FOOTNOTE"
                                            href={[caDisclosure]}
                                        />
                                    </div>
                                }

                            </div>
                            <div className="small-12" id="order-confirmation-enroll-button">
                                    <Button
                                        localeKey={t("LABEL_CONFIRMATION_ENROLL")}
                                        onClick={handleSubmit}
                                        disabled={!isEnrollRewardsChecked || isLoading}
                                        isLoading={isLoading}
                                        dataDtm={"order confirmation"}
                                    />
                            </div>
                        </form>
                        {props.enrollError &&
                            <FieldErrorMessage
                                message={t(props.enrollError)}
                            />
                        }
                    </>
                )
            }

        </ Translation>
    )
}

export default withTranslation()(reduxForm({
    form: 'OrderConfirmationLoyaltyNonRegisteredForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(OrderConfirmationNonregistered));
