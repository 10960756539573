import { SELECT_MAKE, SELECT_MODEL, SELECT_YEAR } from '../../VehicleSelect/VehicleSelectRedux/VehicleSelectActionType';
import * as type from './VehicleConfigActionType';
const initialState = {
    isBtnDisabled: true,
    isFetchingVehicleCombinations: false,
    vehicleCombinations: [],
    fitmentDetails: [],
    fitmentStatus: '',
    fitmentSelections: { bodyFitmentSelection: [], wheelFitmentSelection: [], trimFitmentSelection: [], driveFitmentSelection: [], engineFitmentSelection: [] },
    vehicleParams: { bodyNumDoorsParam: '', bodyParam: '', wheelParam: '', trimParam: '', driveParam: '', engineParam: '' },
    showError: false,
    fitmentVehicleInfo: {
        bodyStyle: "",
        bodyStyleId: "",
        wheelBase: "",
        wheelBaseId: "",
        trim: "",
        trimId: "",
        driveType: "",
        driveTypeId: "",
        engine: "",
        engineId: ""
    }
}

const VehicleConfigReducer = (state = initialState, action) => {

    switch (action.type) {
        /**************************************************************/
        // Loading data and updating the state
        /**************************************************************/
        case type.LOAD_VEHICLE_COMBOS_ASYNC:
            return { ...state, isFetchingVehicleCombinations: true };

        case type.SET_VEHICLE_FITMENT:
            const { data } = action;
            return {
                ...state,
                vehicleParams: {
                    bodyNumDoorsParam: data.bodyNumDoors || '',
                    bodyParam: data.bodyStyle || '',
                    driveParam: data.driveType || '',
                    engineParam: data.engineBase || '',
                    trimParam: data.trim || '',
                    wheelParam: data.wheelBase || '',
                },
            };

        case type.SET_VEHICLE_CONFIG_COMBINATIONS:
            return {
                ...state,
                isFetchingVehicleCombinations: false,
                vehicleCombinations: action.combinations,
                wheelDropDownLabel: action.combinations[0].DROP_DOWN_NAME,
                fitmentSelections: {
                    bodyFitmentSelection: action.parsedCombinations.bodyFitmentSelection,
                    wheelFitmentSelection: action.parsedCombinations.wheelFitmentSelection,
                    trimFitmentSelection: action.parsedCombinations.trimFitmentSelection,
                    driveFitmentSelection: action.parsedCombinations.driveFitmentSelection,
                    engineFitmentSelection: action.parsedCombinations.engineFitmentSelection
                },
            };

        // TODO: reset on modal closeout
        case SELECT_YEAR:
        case SELECT_MAKE:
        case SELECT_MODEL:
        case type.RESET_VEHICLE_CONFIG_STATE:
            return {
                ...state,
                isBtnDisabled: true,
                vehicleCombinations: [],
                fitmentDetails: [],
                fitmentStatus: '',
                fitmentSelections: { bodyFitmentSelection: [], wheelFitmentSelection: [], trimFitmentSelection: [], driveFitmentSelection: [], engineFitmentSelection: [] },
                vehicleParams: { bodyNumDoorsParam: '', bodyParam: '', wheelParam: '', trimParam: '', driveParam: '', engineParam: '' },
                showError: false,
                fitmentVehicleInfo: {
                    bodyStyle: "",
                    bodyStyleId: "",
                    wheelBase: "",
                    wheelBaseId: "",
                    trim: "",
                    trimId: "",
                    driveType: "",
                    driveTypeId: "",
                    engine: "",
                    engineId: ""
                }
            };


        /**************************************************************/
        // Actions triggered by the user
        /**************************************************************/
        case type.SELECT_BODY:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },

                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle,
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase: action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId: action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId,
                    driveType: action.fitmentVehicleInfo.driveType,
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,
                    engine: action.fitmentVehicleInfo.engine,
                    engineId: action.fitmentVehicleInfo.engineId
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_WHEEL:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle,
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase: action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId: action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId,
                    driveType: action.fitmentVehicleInfo.driveType,
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,
                    engine: action.fitmentVehicleInfo.engine,
                    engineId: action.fitmentVehicleInfo.engineId
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_TRIM:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle,
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase: action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId: action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId,
                    driveType: action.fitmentVehicleInfo.driveType,
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,
                    engine: action.fitmentVehicleInfo.engine,
                    engineId: action.fitmentVehicleInfo.engineId
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_DRIVE:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle,
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase: action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId: action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId,
                    driveType: action.fitmentVehicleInfo.driveType,
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,
                    engine: action.fitmentVehicleInfo.engine,
                    engineId: action.fitmentVehicleInfo.engineId
                },
                vehicleParams: action.vehicleParams
            };

        case type.SELECT_ENGINE:
            return {
                ...state,
                fitmentSelections: {
                    bodyFitmentSelection: action.bodyFitmentSelection,
                    wheelFitmentSelection: action.wheelFitmentSelection,
                    trimFitmentSelection: action.trimFitmentSelection,
                    driveFitmentSelection: action.driveFitmentSelection,
                    engineFitmentSelection: action.engineFitmentSelection
                },
                fitmentVehicleInfo: {
                    bodyStyle: action.fitmentVehicleInfo.bodyStyle,
                    bodyStyleId: action.fitmentVehicleInfo.bodyStyleId,
                    wheelBase: action.fitmentVehicleInfo.wheelBase,
                    wheelBaseId: action.fitmentVehicleInfo.wheelBaseId,
                    trim: action.fitmentVehicleInfo.trim,
                    trimId: action.fitmentVehicleInfo.trimId,
                    driveType: action.fitmentVehicleInfo.driveType,
                    driveTypeId: action.fitmentVehicleInfo.driveTypeId,
                    engine: action.fitmentVehicleInfo.engine,
                    engineId: action.fitmentVehicleInfo.engineId
                },
                vehicleParams: action.vehicleParams
            };

        case type.DISABLE_BTN:
            return { ...state, isBtnDisabled: false };

        /**************************************************************/
        // Handling data errors
        /**************************************************************/
        case type.LOAD_VEHICLE_COMBOS_ASYNC_FAIL:
            return { ...state, isFetchingVehicleCombinations: false, error: action, showError: true };

        case type.SET_VEHICLE_CONFIG_COMBINATIONS_FAIL:
            return { ...state, isFetchingVehicleCombinations: false, error: action, showError: true };

        default: return state;
    }

}

export default VehicleConfigReducer