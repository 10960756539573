import React, {useEffect} from "react";
import IdleTimer from "react-idle-timer";
import {LOGIN_TIMEOUT} from "../../../../config/vault_constants";
import AppSettings from "../../../core/AppSettings";
import {connect} from "react-redux";
import store from "../../../core/Redux/Store";
import {requestUserLogout, setAuthenticationCookieIsSessionExpired} from "../AuthenticationActionCreator";

/**
 * Container for IdleTimer to log user's session out when idle for specified amount of time. User gets logged out, then sent
 * to the login page, then to wherever they were before, after logging back in.
 * @returns {JSX.Element}
 * @constructor
 */
const SessionTimeOut = (props) => {
    const DEBUG_FLAG = false;
    const INACTIVITY_TIMEOUT_LOCALHOST = 6900000;
    const inactivityTimeoutDuration = AppSettings.isLocalHost ? INACTIVITY_TIMEOUT_LOCALHOST : LOGIN_TIMEOUT;
    const isSessionExpired = props.authenticationData.isSessionExpired;
    const registrationStatus = props.authenticationData.registrationStatus;
    const logoutError = props.authenticationData.logoutError;
    const userFlow = AppSettings.azureLoginUserFlow;
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    let throttleTimeoutId;
    const inputEvents = ["mousedown", "keypress", "scroll", "touchstart", "onclick", "mousemove"];

    /** Attempt to log the user out when their session times out **/
    useEffect(() => {
        if (isSessionExpired && registrationStatus === "R") {
            logOutUser();
        }
    }, [isSessionExpired]);

    /** Debugging print out  **/
    useEffect(() => {
        if (DEBUG_FLAG && registrationStatus === "R") {
            console.log("Session expiration in: ", inactivityTimeoutDuration);
        }
    }, [registrationStatus]);

    /** If session has expired and there is a logout error, start custom action tracker to attempt to logout on next
     * action. This is to handle if computer was asleep, which causes network errors when logging out **/
    useEffect(() => {
        if (isSessionExpired && registrationStatus === "R" && logoutError) {
            enableInputListeners();
        }
    }, [isSessionExpired, logoutError]);

    /** Construct the url for login and dispatch action to request logout & redirect to login **/
    const logOutUser = () => {
        store.dispatch(requestUserLogout(userFlow, clientId, " ", '/'));
    };

    /**
     * A barebones function throttler - prevents excessive function execution.
     * @param {function} func - a function taking no parameters to throttle execution of
     * @param {number} [delay=3000] - a minimum number of milliseconds between dispatches of func
     */
    const throttleExecution = (func, delay = 3000) => {
        if (throttleTimeoutId) return;

        func();

        throttleTimeoutId = setTimeout(() => {
            throttleTimeoutId = null;
        }, delay);
    };

    /*** Handle when the user has been inactive for the specified time period ***/
    const handleOnIdle = () => {
        if (DEBUG_FLAG) {
            console.log(
                `%c user has been idle for ${inactivityTimeoutDuration}, logging out`,
                "background-color:dateslategrey; color: white"
            );
        }

        store.dispatch(setAuthenticationCookieIsSessionExpired(true));
    };

    /** When user becomes active again, check if there was a logout error. If so, keep trying to logout after short
     * delays. **/
    const handleOnAction = () => {
        if (isSessionExpired && registrationStatus === "R" && logoutError) {
            if (DEBUG_FLAG) {
                console.log("Detected logout error on action, attempting to logout user");
            }
            logOutUser();
        }
    };

    /** ****** adding event listener to DOM ********/
    const enableInputListeners = () => {
        inputEvents.forEach((event) => {
            document.addEventListener(event, () => throttleExecution(handleOnAction, 10000), true);
        });
    };

    return (
        <>
            {registrationStatus === "R" && !isSessionExpired && (
                <IdleTimer timeout={inactivityTimeoutDuration} onIdle={handleOnIdle} debounce={500}/>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        authenticationData: state.Authentication
    }
}


export default connect(mapStateToProps)(SessionTimeOut);
