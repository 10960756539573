import React, { useState, useMemo } from "react";
import baseVault from "../vault_constants";
import { TextCell, InputCell, ActionsCell } from "./OverridesTableCells";

/*

TODO: 
- optimize edit times by memorizing key locations in `pairs`
- add warning if exiting with unsaved changes

*/

// uses the default vault instance as a context
const DEFAULT_VAULT_INSTANCE = window.VaultController?.previewDefaultInstance();

export const C = {
    key: "vault-ui-key-col",
    value: "vault-ui-value-col",
    actions: "vault-ui-actions-col",
    header: "vault-ui-header-row"
};

/**
 * Gets the current vault object as a list of entries, with any staged changes applied.
 * @param {Object<string, string>} edits the current edits staged
 * @param {Object<string, boolean>} resets the current keys to be reset
 * @returns {[string, string][]} the new state of vault as a list of sorted entries
 */
const getVaultEntries = (edits, resets) => {
    const compare = (a, b) => String(a[0]).localeCompare(b[0]);
    const vault = { ...baseVault };

    if (Object.keys(edits || {})) {
        for (const editKey in edits) {
            if (!(editKey in resets)) {
                vault[editKey] = edits[editKey];
            }
        }
    }

    return Object.entries(vault).sort(compare);
};

/**
 * Gets a function to copy the current vault override to the
 * clipboard in .profile syntax.
 *
 * @param {string} key the key to work with
 * @param {string} value the new value to work with
 * @returns {Function} the function to copy the .profile syntax
 */
const getOnCopy = (key, value) => {
    const strToCopy = `${key}=${String(value).trim()}`;
    return async () => {
        window?.navigator?.clipboard
            ?.writeText?.(strToCopy)
            .then(() => console.log("Successfully copied value to clipboard"))
            .catch((e) => {
                console.error("Failed to access the system's clipboard.");
                console.error(e);
            });
    };
};

/**
 * The Overrides table on the main VaultUI screen
 *
 * @typedef OverridesTableProps
 * @type {object}
 * @property {Object<string, string>} editsRef the edits currently staged
 * @property {Object<string, string>} resetsRef the resets currently staged
 *
 * @param {OverridesTableProps} props
 * @returns {React.ReactElement}
 */
const OverridesTable = (props) => {
    const edits = props.editsRef;
    const resets = props.resetsRef;
    const vaultOnMount = useMemo(() => getVaultEntries(edits, resets), []);
    const [pairs, setPairs] = useState(vaultOnMount);
    if (!pairs.length) return null;

    /**
     * A function to pass to the clear action button
     * @param {string} key the key to clear
     */
    const clearEdit = (key) => {
        if (key in edits) delete edits[key];
        resets[key] = true;
        const duplicate = [...pairs];
        const target = duplicate.findIndex((entry) => entry[0] === key);
        duplicate[target][1] = DEFAULT_VAULT_INSTANCE[key];
        setPairs(duplicate);
    };

    /**
     * A function to handle user input in InputCell fields
     * @param {string} key the key to work with
     * @param {string} value the new value to apply
     */
    const onEdit = (key, value) => {
        if (value === DEFAULT_VAULT_INSTANCE[key]) {
            clearEdit(key);
        } else {
            if (key in resets) delete resets[key];
            edits[key] = value;
            const duplicate = [...pairs];
            const target = duplicate.findIndex((entry) => entry[0] === key);
            duplicate[target][1] = value;
            setPairs(duplicate);
        }
    };

    return (
        <table id="VaultUITable" striped bordered hover>
            <thead className="vc-table-header-row">
                <tr className={C.header}>
                    <th className="vault-ui-header-key-col">Key</th>
                    <th className="vault-ui-header-value-col">Value</th>
                    <th className="vault-ui-header-actions-col">Actions</th>
                </tr>
            </thead>
            <tbody className="vc-table-overrides-table-body">
                {pairs.map((entry) => (
                    <tr className="vc-overridetable-row" key={entry[0]} id={entry[0]}>
                        <TextCell className={C.key} text={entry[0]} />
                        <InputCell
                            className={C.value}
                            text={entry[1]}
                            isTouched={entry[0] in edits}
                            onEdit={(val) => onEdit(entry[0], val)}
                        />
                        <ActionsCell
                            className={C.actions}
                            vaultKey={entry[0]}
                            onCopy={getOnCopy(...entry)}
                            onClear={() => clearEdit(entry[0])}
                        />
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default OverridesTable;
