import React from "react";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import MediaQuery from "react-responsive";
import queryString from "query-string";
import Modal from "../../../../../shared/Modal";

// react-accessible-accordion: Accessible Accordion component
import { Accordion, AccordionItem, AccordionItemTitle } from "react-accessible-accordion";
import CategoryItem from "./CategoryItem";
import { CATEGORY_SELECTED, CATEGORY_NAV_URL_UPDATE } from "../CategoryNavRedux/CategoryNavActionTypes";
import { LIST_DATA_ASYNC } from "../../../SearchService/SearchDataServiceActionTypes";
import { RESET_PAGE_NUMBER } from "../../../SearchService/SearchDataServiceActionTypes";
import AppSettings from "../../../../../core/AppSettings";

/**************************************************************/
//          CategoryItems Component
/**************************************************************/
class CategoryItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selections: {},
            showModal: false,
            fitmentErrorActive: false
        };
        this.closeAccordion = this.closeAccordion.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidUpdate() {
        //determine which tagging classes should be active in the category links, dependant on if there's a fitment issue
        const fitmentErrorElement = document.getElementsByClassName("ac-errorParent");
        if (!this.state.fitmentErrorActive && fitmentErrorElement.length) {
            this.setState({ fitmentErrorActive: true });
        }
    }

    /**
     * responsible for creating the categories that serve as the accordion for the subcategories
     *  */
    createCategories() {
        const categories = this.props.categories;
        const currentCategory = this.props.currentCategory;
        if (categories !== undefined && categories.categories !== undefined) {
            //One entry is special in that you can select it to view all categories
            const allCategories = categories;
            const categoryMap = allCategories.categories;
            let queryParams = queryString.parse(document.location.search);
            //maps the categories to an AccordionItem, then create the sub-category list items from that entry.
            /*this also sets the currently selected cat or subcat to currentCategory*/
            const renderedCategoryItems = categoryMap.map((category, i) => {
                let isOpen = false;
                const subcategories = category.subCategories;
                if (category) {
                    if (
                        currentCategory.uniqueId === category.uniqueId ||
                        queryParams.categoryId === category.uniqueId
                    ) {
                        isOpen = true;
                    } else {
                        for (let i in subcategories) {
                            if (
                                currentCategory.uniqueId === subcategories[i].uniqueId ||
                                queryParams.categoryId === subcategories[i].uniqueId
                            ) {
                                isOpen = true;
                                break;
                            }
                        }
                    }
                }
                return (
                    <CategoryItem
                        key={i}
                        closeAccordion={this.closeAccordion}
                        category={category}
                        fitmentErrorActive={this.state.fitmentErrorActive}
                        currentCategory={this.props.currentCategory}
                        isOpen={isOpen}
                        selectCategory={this.props.selectCategory}
                        id={category.uniqueId}
                        searchParams={this.props.searchParams}
                        selected={this.state.selections}
                        handleURL={this.props.handleURL}
                        allCategories={allCategories}
                    ></CategoryItem>
                );
            });
            let parentIsOpen = false;
            if (currentCategory.uniqueId === categories.uniqueId || queryParams.categoryId === categories.uniqueId) {
                parentIsOpen = true;
            }
            //the final component that includes the parent "all categories", other parent categories and their subcategories. (that's a lot of categories!)
            const combinedCategories = (
                <div>
                    {/*_this.props.accordion && newItem.expanded*/}
                    <AccordionItem
                        expanded={parentIsOpen}
                        onClick={() => {
                            this.closeAccordion();
                            this.props.selectCategory(allCategories, this.props.searchParams);
                        }}
                        className="q-expander"
                    >
                        <div onClick={() => this.props.handleURL(allCategories)}>
                            <AccordionItemTitle className="ac-accordion-title ac-parent-expander q-headline q-expander-button">
                                <h5>
                                    <span className="ac-category-link">
                                        {allCategories.label} ({allCategories.count})
                                    </span>
                                </h5>
                            </AccordionItemTitle>
                        </div>
                    </AccordionItem>
                    {renderedCategoryItems}
                </div>
            );

            return combinedCategories;
        } else {
            return <div />;
        }
    }

    closeAccordion() {
        this.setState({ showModal: false });
    }

    toggleModal() {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    }

    render() {
        const categories = this.createCategories();

        //mobile nav includes a button and is hidden unless selected
        const mobileNav = (
            <Translation>
                {(t) => (
                    <React.Fragment>
                        <div className="ac-mobile-catnav-btn">
                            <button
                                className="q-button q-secondary-button stat-button-link"
                                type="button"
                                data-dtm={this.state.fitmentErrorActive ? "all fitted products" : "all products"}
                                onClick={this.toggleModal}
                            >
                                {t("LABEL_SEARCH_SHOP_CATEGORY")}
                            </button>
                        </div>

                        <Modal open={this.state.showModal} onClose={this.closeAccordion} center>
                            <div>
                                <h2 className="q-headline2">{t("LABEL_SEARCH_SHOP_CATEGORY")}</h2>
                                <Accordion className="ac-accordion">{categories}</Accordion>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}
            </Translation>
        );

        //desktop nav
        const desktopNav = <Accordion className="ac-accordion">{categories}</Accordion>;

        return (
            <Translation>
                {(t) => (
                    <div>
                        <MediaQuery query="(max-width: 59.99em)">{mobileNav}</MediaQuery>
                        <MediaQuery query="(min-width: 60em)">{desktopNav}</MediaQuery>
                    </div>
                )}
            </Translation>
        );
    }
}

/**************************************************************/
//          Map Dispatch to Props
/**************************************************************/
function mapDispatchToProps(dispatch) {
    return {
        selectCategory: (category, searchParams) => {
            dispatch({
                type: CATEGORY_SELECTED,
                payload: { selectedCategory: category }
            });
            dispatch({
                type: RESET_PAGE_NUMBER
            });
            dispatch({
                type: LIST_DATA_ASYNC,
                payload: {
                    year: searchParams.year,
                    make: searchParams.make,
                    model: searchParams.model,
                    categoryId: category.uniqueId
                }
            });
        },
        /* takes the currently selected category and passes it to the URL updater
         * used in this file, CategoryItem.js and SubCategoryItem.js */
        handleURL: (category) => {
            dispatch({
                type: CATEGORY_NAV_URL_UPDATE,
                category: category
            });
        }
    };
}

export default connect(null, mapDispatchToProps)(CategoryItems);
