import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { C } from "./OverridesTable";
import { TextCell, ActionsCell } from "./OverridesTableCells";

/**
 * The (mostly) read-only Overrides table on the confirmation screen
 *
 * @typedef OverridesTableProps
 * @type {object}
 * @property {[Object<string, string>, Object<string, string>]} diff the edits and resets currently staged
 * @property {Function} onDelete the callback function to remove an override from the staged changeset
 *
 * @param {OverridesTableProps} props
 * @returns {React.ReactElement}
 */
const OverridesConfirmTable = (props) => {
    const { diff } = props;
    const pairs = Object.entries(diff[0]).concat(Object.entries(diff[1]));
    const [overrides, setOverrides] = useState(pairs);

    const handleDelete = (keyName) => {
        if (keyName in diff[0]) delete diff[0][keyName];
        if (keyName in diff[1]) delete diff[1][keyName];

        setOverrides((set) => set.filter((row) => row[0] !== keyName));
        props?.onDelete?.(keyName);
    };

    if (overrides.length > 0) {
        return (
            <table id="VaultUITable" bordered>
                <thead className="vc-confirm-table-header-container">
                    <tr className={C.header}>
                        <th className="vault-ui-header-key-col">Key</th>
                        <th className="vault-ui-header-value-col">Value</th>
                        <th className="vault-ui-header-actions-col">Actions</th>
                    </tr>
                </thead>
                <tbody className="vc-table-container">
                    {overrides.map((entry) => (
                        <tr key={entry[0]} id={entry[0]}>
                            <th id="vc-confirm-table-header-key">{entry[0]}</th>
                            <th id="vc-confrim-table-header-value">
                                {typeof entry[1] === "boolean" ? "*** will be reset ***" : entry[1]}
                            </th>
                            <ActionsCell
                                id="vc-confirm-table-header-actions"
                                vaultKey={entry[0]}
                                onDelete={() => handleDelete(entry[0])}
                            />
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    } else {
        return <p className="warning">No additional overrides have been selected.</p>;
    }
};

export default OverridesConfirmTable;
