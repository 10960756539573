import store from "../../../core/Redux/Store";
import * as type from './CheckoutActionType';

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const nextStep = () => {
    const action = {
        type: type.CHECKOUT_NEXT_STEP
    }
    return action;
}

export const prevStep = () => {
    const action = {
        type: type.CHECKOUT_PREV_STEP
    }
    return action;
}

export const setLoading = (isLoading) => {
    const action = {
        type: type.CHECKOUT_SET_LOADING,
        isLoading: isLoading
    }
    return action;
}

/**************************************************************/
// Actions for checkout step 1 (contact form)
/**************************************************************/
export const loadCheckoutDataAsync = () => {
    const action = {
        type: type.CHECKOUT_LOAD_DATA_ASYNC
    }
    return action;
}

export const loadInitialContactForm = () => {
    const action = {
        type: type.CHECKOUT_LOAD_INITIAL_CONTACT_FORM
    }
    return action;
}

export const setCheckoutShippingContactInitialValues = (initialValues) => {
    const action = {
        type: type.CHECKOUT_SHIPPING_CONTACT_SET_INITIAL_VALUES,
        initialValues: initialValues
    }
    return action;
}

export const loadCountryStateListAsync = () => {
    const action = {
        type: type.CHECKOUT_LOAD_COUNTRY_STATE_LIST_ASYNC
    }
    return action;
}

export const sendToHomePg = (error) => {
    const action = {
        type: type.CHECKOUT_SEND_TO_HOME_PG,
        error: error
    }
    return action;
}

/**
 * The address validation has about 6 different flows
 * these params assist in populating the different flows through the components
 * @param form  The data the user input into Checkout Step 1
 * @param res   The address validation data FedEx returns
 * @param validity  Whether the form data input was validated by FedEx or not
 * @param fedExAvailability Whether the FedEx service is available or not
 * @param stateZipMismatch  Whether the form and res State/Zip mismatch or not (True means mismatch, and False means match)
 * @param POBox Whether the address entered is recognized by FedEx to be a POBox or not (True means POBox and False means not a POBox)
 * @returns {{validatedResponse: *, stateZipMismatch: *, originalAddressForm: *, validity: *, type: string, fedExAvailability: *}}
 */
export const showModal = (form, res, validity, fedExAvailability, stateZipMismatch, POBox, addressValid) => {
    const action = {
        type: type.SHOW_ADDRESS_MODAL,
        originalAddressForm: form,
        validatedResponse: res,
        validity: validity,
        fedExAvailability: fedExAvailability,
        stateZipMismatch: stateZipMismatch,
        POBox: POBox, 
        addressValid: addressValid
    };
    return action;
};

export const setCountryStateList = (stateList, stateNameToCode, stateCodeToName) => {
    const action = {
        type: type.CHECKOUT_SET_COUNTRY_STATE_LIST,
        stateList: stateList,
        stateNameToCode: stateNameToCode,
        stateCodeToName: stateCodeToName
    }
    return action;
}

export const submitContactFormAsync = (form, skipAddressValidation) => {
    const action = {
        type: type.CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC,
        form: form,
        skipAddressValidation: skipAddressValidation
    };
    return action;
};

export const submitContactFormAsyncFail = (error) => {
    const action = {
        type: type.CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC_FAIL,
        error: error
    };
    return action;
};

export const submitModal = (form, selectedAddress, skipAddressValidation) => {
    const action = {
        type: type.CHECKOUT_SUBMIT_MODAL,
        form: form,
        selectedAddress: selectedAddress,
        skipAddressValidation: skipAddressValidation,
    };
    return action;
};
/**************************************************************/
// Actions for checkout step 2 (delivery form)
/**************************************************************/
export const deliveryMethodChangeAsync = (orderItemId, shipModeId, quantity, addressId, carrierCode, orderId, productId) => {
    const action = {
        type: type.CHECKOUT_DELIVERY_METHOD_CHANGE_ASYNC,
        orderItemId: orderItemId,
        shipModeId: shipModeId,
        quantity: quantity,
        addressId: addressId,
        carrierCode: carrierCode,
        orderId: orderId,
        productId: productId
    }
    return action;
}

export const showShippingModal = (show) => {
    const action = {
        type: type.CHECKOUT_SHIPPING_ERROR,
        open: show
    }
    return action;
}

export const toggleShippingModal = (toggle) => {
    const action = {
        type: type.CHECKOUT_TOGGLE_SHIPPING_ERROR,
        open: toggle
    }
    return action;
}

export const deliveryMethodShowSpinner = (showSpinner, orderItemId) => {
    const action = {
        type: type.CHECKOUT_DELIVERY_METHOD_SHOW_SPINNER,
        showSpinner: showSpinner,
        orderItemId: orderItemId
    }
    return action;
}

export const couponCodePostAsync = (couponCode) => {
    const action = {
        type: type.CHECKOUT_COUPON_CODE_POST_ASYNC,
        payload: couponCode
    }
    return action;
}

export const couponCodeRemovePostAsync = (couponCode) => {
    const action = {
        type: type.CHECKOUT_COUPON_CODE_REMOVE_POST_ASYNC,
        payload: couponCode
    }
    return action;
}

export const handleCouponResponseMsg = (couponMsg) => {
    const action = {
        type: type.HANDLE_COUPON_RESPONSE_MSG,
        couponMsg: couponMsg
    }
    return action
}

export const couponPromotionIdPostAsync = (promoId) => {
    const action = {
        type: type.CHECKOUT_COUPON_PROMOTION_ID_POST_ASYNC,
        promoId: promoId
    }
    return action;
}

export const handlePromoIdResponseMsg = (promoIdMsg) => {
    const action = {
        type: type.HANDLE_PROMOTION_ID_RESPONSE_MSG,
        promoIdMsg: promoIdMsg
    }
    return action
}

export const setCouponData = (response) => {
    const action = {
        type: type.CHECKOUT_LOAD_COUPON_DATA_ASYNC,
        couponDisplayData: response
    }
    return action;
}

export const getCouponData = (orderId) => {
    const action = {
        type: type.GET_COUPON_DATA,
        orderId: orderId
    };
    return action;
}

export const onDeliveryFormPrevious = () => {
    const action = {
        type: type.CHECKOUT_ON_DELIVERY_FORM_PREVIOUS
    }
    return action;
}
export const submitDeliveryFormAsync = () => {
    const action = {
        type: type.CHECKOUT_SUBMIT_DELIVERY_FORM_ASYNC
    };
    return action;
};

export const setDeliveryErrorTrue = () => {
    const action = {
        type: type.CHECKOUT_SET_DELIVERY_ERROR_TRUE
    };
    return action;
}

export const setDeliveryErrorFalse = () => {
    const action = {
        type: type.CHECKOUT_SET_DELIVERY_ERROR_FALSE
    };
    return action;
}

export const deliveryMethodChangeFail = (error) => {
    const action = {
        type: type.CHECKOUT_DELIVERY_METHOD_CHANGE_FAIL,
        error: error
    };
    return action;
}

export const couponFail = (error) => {
    const action = {
        type: type.CHECKOUT_COUPON_SUBMIT_FAIL,
        error: error
    };
    return action;
}

export const deliveryPageFail = (params, error) => {
    return {
        type: type.CHECKOUT_DELIVERY_PAGE_FAIL,
        params: params,
        error: error
    }
}

export const applyCouponFail = (error) => {
    const action = {
        type: type.CHECKOUT_APPLY_COUPON_FAIL,
        error: error
    };
    return action;
}

export const removeCouponFail = (error) => {
    const action = {
        type: type.CHECKOUT_REMOVE_COUPON_FAIL,
        error: error
    };
    return action;
}

export const deliveryPageFailTax = () => {
    return {
        type: type.CHECKOUT_DELIVERY_PAGE_FAIL_TAX,
    }
}

export const deliveryPageTaxFalse = () => {
    const action = {
        type: type.CHECKOUT_DELIVERY_PAGE_TAX_FALSE
    };
    return action;
}

export const deliveryPageSwitchOrgFlag = () => {
    return {
        type: type.CHECKOUT_DELIVERY_PAGE_SWITCH_ORG_FLAG
    }
}

/**************************************************************/
// Actions for checkout step 3 (Payment Page)
/**************************************************************/
export const loadBillingAsShipping = (orderData) => {
    const { stateCodeToName } = store.getState().CheckoutReducer.shippingContactForm;
    if (orderData) {
        const billingDataFromOrderInfo = {
            firstName: orderData.customerInfo.firstName,
            lastName: orderData.customerInfo.lastName,
            companyName: orderData.customerInfo.officeAddress || "",
            address1: orderData.shipping.address1,
            address2: orderData.shipping.address2 || "",
            city: orderData.shipping.city,
            state: stateCodeToName[orderData.shipping.state],
            zipCode: orderData.shipping.zipcode
        };
        return {
            type: type.CHECKOUT_PAYMENT_BILLING_AS_SHIPPING,
            payload: billingDataFromOrderInfo
        };
    } else {
        const clearData = {
            firstName: "",
            lastName: "",
            companyName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: ""
        };
        return {
            type: type.CHECKOUT_PAYMENT_BILLING_NOT_AS_SHIPPING,
            payload: clearData
        };
    }
};

export const loadSpinnerOnPaymentPage = () => {
    return {
        type: type.CHECKOUT_PAYMENT_SPINNER
    }
};

export const updateCreditCardSuccessInformation = (creditCardResponse) => {
    return {
        type: type.UPDATE_CREDIT_CARD_SUCCESS,
        payload: {
            paymentInformation: creditCardResponse,
        }
    }
};

export const updateBillingInfo = (billingInfo) => {
    return {
        type: type.UPDATE_BILLING_INFORMATION,
        payload: {
            billingInformation: billingInfo
        }
    }
};

export const resetPaymentInformation = () => {
    return {
        type: type.RESET_PAYMENT_INFORMATION
    }
};

export const enableTechnicalFlag = () => {
    return {
        type: type.SHOW_PAYMENT_TECHNICAL_ERROR
    }
};

export const enablePaymentUserFlag = () => {
    return {
        type: type.SHOW_PAYMENT_USER_ERROR
    }
};

export const enableMissingPaymentFlag = () => {
    return {
        type: type.SHOW_MISSING_PAYMENT_ERROR
    }
};

export const resetAllErrors = () => {
    return {
        type: type.RESET_ALL_ERRORS_BEFORE_SUBMIT
    }
};

export const checkPaymentMethodAsync = () => {
    return {
        type: type.CHECK_PAYMENT_METHOD_ASYNC

    }
};

export const checkPaymentMethodError = (error) => {
    return {
        type: type.CHECK_PAYMENT_METHOD_ERROR,
        payload: error
    }
};

export const checkPaymentMethodCreator = (payType) => {
    return {
        type: type.CHECK_PAYMENT_METHOD,
        payload: payType
    }
};



/**************************************************************/
// Actions for checkout step 4 (Review Page)
/**************************************************************/

export const shouldPreventStepOneSkip = () => {
    const action = {
        type: type.CHECKOUT_PREVENT_STEP_ONE_SKIP
    };
    return action;
}

export const preventStepOneSkipStatus = () => {
    return store.getState().CheckoutReducer.preventStepOneSkip;
}

export const editForm = (step) => {
    if (step === 1 && store.getState().CheckoutReducer.paymentPage.isBillingIsSameAsShipping) {
        store.dispatch(resetBillingInfo())
    }
    return {
        type: type.CHECKOUT_EDIT_FORM,
        payload: step
    }
}

export const setIsRoscaChecked = (isRoscaChecked, message) => {
    return {
        type: type.CHECKOUT_ROSCA_CHECKBOX,
        isRoscaChecked: isRoscaChecked,
        roscaDisclosureMsg: message,
    }
}

export const submittingOrder = () => {
    return {
        type: type.SUBMITTING_ORDER,
    }
}

export const setPaymentInfo = (paymentErrorMsg) => {
    return {
        type: type.SET_PAYMENT_INFO,
        paymentErrorMsg: paymentErrorMsg
    }
}

export const setOrderInfo = (orderId, orderStatus) => {
    return {
        type: type.SET_ORDER_INFO,
        orderId: orderId,
        orderStatus: orderStatus
    }
}

export const sendToOrderConfirmation = (sendToOrderConfirmation) => {
    const action = {
        type: type.SEND_TO_ORDER_CONFIRMATION,
        sendToOrderConfirmation: sendToOrderConfirmation
    }
    return action;
}


export const submittingOrderFail = (error) => {
    const action = {
        type: type.SUBMITTING_ORDER_FAIL,
        error
    }
    return action;
}

export const submittingPaymentFail = (error) => {
    const action = {
        type: type.SUBMITTING_PAYMENT_FAIL,
        error
    }
    return action;
}

export const submittingOrderVoucherFail = () => {
    const action = {
        type: type.SUBMITTING_ORDER_VOUCHER_FAIL
    }
    return action;
}

export const submittingOrderSoftFail = () => {
    const action = {
        type: type.SUBMITTING_ORDER_SOFT_FAIL
    }
    return action;
}

export const neverCloseModal = () => {
    return {
        type: type.NEVER_CLOSE_MODAL
    }
}

export const resetOrderProcessingModal = () => {
    return {
        type: type.RESET_ORDER_PROCESSING_MODAL
    }
}

export const zeroCheckoutAmount = (value) => {
    return {
        type: type.CHECKOUT_ZERO_DOLLAR_AMOUNT,
        payload: value
    }
};

export const turnOffSpinnerForZeroCheckout = (value) => {
    return {
        type: type.TURN_OFF_SPINNER_FOR_ZERO_CHECKOUT
    }
};

export const defaultDeliveryMethodFail = (error) => {
    const action = {
        type: type.HANDLE_DEFAULT_DELIVERY_METHOD_FAIL,
        error
    }
    return action;
}

/*** Resets the billing info to empty if user wants to change shipping address from Step 4***/
export const resetBillingInfo = () => {
    const action = {
        type: type.RESET_BILLING_INFO,
    }
    return action;
}


