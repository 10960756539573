import axios from 'axios';
import AppSettings from '../../core/AppSettings';

const couponBannerEspot = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Coupon_Banner_Flyout?noCache=true";

export const getCouponTiles = () => {
    return axios.get(couponBannerEspot).then((response) => { return response; }, (error) => { return error; });
};

export const applyCoupon = (promotionCode) => {
    let postApplyCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/cart/@self/assigned_promotion_code';
    let payload = { promoCode: promotionCode};

    return axios.post(postApplyCouponURL, payload).then((response) => { return response; }, (error) => { return error; });
};

export const removeAppliedCoupon = (promotionCode) => {
    let postRemoveCouponURL = '/wcs/resources/store/' + AppSettings.storeId + '/cart/@self/assigned_promotion_code/' + promotionCode;

    return axios.delete(postRemoveCouponURL).then((response) => { return response; }, (error) => { return error; });
};

export const getPromotionsTiedToUser = () => {
    let getPromotionsTiedToUserURL = '/wcs/resources/store/' + AppSettings.storeId + '/assigned_coupon_code/list';

    return axios.get(getPromotionsTiedToUserURL).then((response) => { return response; }, (error) => { return error; });
};

