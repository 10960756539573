import React, { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import store from '../../../core/Redux/Store';
import { connect } from "react-redux";
import PersonalInformation from './PersonalInformation/PersonalInformation';
import Addresses from './Addresses/Addresses';
import Password from './Password/Password';
import { togglePhoneEdit, toggleAddressEdit, toggleAddressAdd, resetProfileToggle } from "../UserProfileRedux/UserProfileCreator";
import { retrievePersonalInfoDataAsync } from '../UserProfileRedux/UserProfileCreator';

const ProfileSettings = props => {

  const [personalInfo, setPersonalInfo] = useState({});

  useEffect(() => {
    store.dispatch(retrievePersonalInfoDataAsync());
  }, []);

  useEffect(() => {
    if (props.personalInfoData != undefined) {
      setPersonalInfo(props.personalInfoData);
    }
  }, [props.personalInfoData]);

  return (
    <Translation>
      {
        (t) => (
          <div className="ac-profile-settings small-12 grid-column-alignment-left columns ac-nopadding-x">
            <h1 className="q-headline1">{t("LABEL_PROFILE_SETTINGS")}</h1>
            <PersonalInformation
              personalInfo={personalInfo}
              profilesettings={props.profileSettingsState}
              togglePhoneEdit={props.togglePhoneEdit}
              resetProfileToggle={props.resetProfileToggle} />
            <Addresses
              personalInfo={personalInfo}
              profilesettings={props.profileSettingsState}
              toggleAddressEdit={props.toggleAddressEdit}
              toggleAddressAdd={props.toggleAddressAdd}
              resetProfileToggle={props.resetProfileToggle} />
            <Password />
          </div>

        )
      }
    </Translation>
  )
}

const mapStateToProps = state => {
  return {
    profileSettingsState: state.UserProfileReducer.profilesettings,
    personalInfoData: state.UserProfileReducer.personalInfoData
  };
};

export default connect(mapStateToProps, { togglePhoneEdit, toggleAddressEdit, toggleAddressAdd, resetProfileToggle })(ProfileSettings);