import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import './HomeContentBottom.scss';

class HomeContentBottom extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="ac-home-content-bottom row q-gridbuilder gridspacing-large">
            <hr className="q-teaser-separator-light" />
            <div className="small-12 medium-12 large-12 xlarge-12  grid-column-alignment-left  q-grid-row-new-mobile columns">
              <div className="q-margin-base q-headline">
                <div className="none-margin">
                  <div>
                    <div className="q-text q-body2">{t("LABEL_HOME_CONTENT_BOTTOM")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>)
        }
      </Translation>
    );
  }
}

export default HomeContentBottom;