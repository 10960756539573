import axios from 'axios';
import AppSettings from '../../../../core/AppSettings';

export const getVehicleCombinationsData = (year, make, model) => {
    const url = '/wcs/resources/store/' + AppSettings.storeId + '/yearmakemodel/vehicleCombinations/' + AppSettings.country + '/' + year + '/' + make + '/' + model
    // console.log('getAllVehicleCombinationsData url: ',url)
    return axios.get(url).then(response => response.data).catch(error => console.log('X_X GET VEHICLE COMBINATIONS DATA ERROR X_X', error));
};

// (V7 link) https://accessories.gmc.com/wcs/resources/store/11203/fitmentcheck/18560/USA/2017/GMC/Canyon?bodyStyle=&wheelBase=&trim=&driveType=&engineBase=
// (v9 link) https://accessories-dev1-live-local-chevrolet.int.gm.com/wcs/resources/store/11201/fitmentcheck/160020/USA/2018/chevrolet/colorado?bodyStyle=&wheelBase=&trim=&driveType=&engineBase=
export const getFitmentCheckData = (productID, year, make, model, bodyParameter, wheelParameter, trimParameter, driveParameter, engineParameter) => {
    //Don't want to pass in 'undefined' for optional parameters
    let bodyParam = bodyParameter ? bodyParameter : '';
    let wheelParam = wheelParameter ? wheelParameter : '';
    let trimParam = trimParameter ? trimParameter : '';
    let driveParam = driveParameter ? driveParameter : '';
    let engineParam = engineParameter ? engineParameter : '';

    const url = '/wcs/resources/store/' + AppSettings.storeId + '/fitmentcheck/' +
        productID + '/' +
        AppSettings.country + '/' +
        year + '/' +
        make.toUpperCase() + '/' +
        model + '?' +
        'bodyStyle=' + bodyParam +
        '&wheelBase=' + wheelParam +
        '&trim=' + trimParam +
        '&driveType=' + driveParam +
        '&engineBase=' + engineParam;
    
    return axios.get(url).then(response => response.data).catch(error => console.log('X_X GET FITMENT CHECK DATA ERROR X_X', error));
};
export const fitmentCheckYMM = (catEntryID, year, make, model) => {
    let url = `/wcs/resources/store/${AppSettings.storeId}/fitmentcheck/${catEntryID}/USA/${year}/${make}/${model}`;
    return axios.get(url).then(
        (response) => {
            return response.data;
        },
        (error) => {
            throw error;
        }
    );
};