import axios from 'axios';
import { call } from 'redux-saga/effects';
import AppSettings from '../../../core/AppSettings';
import { postAuthenticated } from '../../../shared/Authenticator/Authenticator';
import queryString from "query-string";

export const getProductDetailsData = (productIdentifier, makeFromVin) => {
    const paramBrand = queryString.parse(location.search).make;
    const brand = AppSettings.currentSite.label || paramBrand || makeFromVin;

    let url = '/wcs/resources/store/' + AppSettings.storeId + '/productSearch/' + productIdentifier + '?brand=' + `${brand}`;
    if (AppSettings.isT3) {
        const bac = AppSettings.bac;
        url += '&bac=' + bac;
    }

    return axios.get(url).then(response => response);
}

export function* postAddToCart(orderId, uniqueId, zipcode, shipModeId, quantity, year, make, makeId, model, modelId, vin, bodyStyle, bodyStyleId, wheelBase, wheelBaseId, trim, trimId, driveType, driveTypeId, engineBase, engineBaseId, bodyNumDoorsId) {
    if (orderId === undefined || orderId === null) {
        orderId = "**";
    }
    
    let body = {
        "orderId": orderId,
        "x_vinNumber": vin ? vin : "",
        "x_year": year.toString(),
        "x_make": make,
        "x_makeId": makeId,
        "x_model": model,
        "x_modelId": modelId,
        "x_trim": trim ? trim : "",
        "x_trimId": trimId ? trimId : "", //trimId in product search URL
        "x_driveType": driveType ? driveType : "",
        "x_bodyStyle": bodyStyle ? bodyStyle : "",
        "x_driveTypeId": driveTypeId.toString() ? driveTypeId : "", //driveId in product search URL
        "x_bodyStyleId": bodyStyleId.toString() ? bodyStyleId : "", //bodyId in product search URL
        "x_bedLength": wheelBase ? wheelBase : "",
        "x_bedLengthId": wheelBaseId ? wheelBaseId : "", //wheelId in product search URL
        "x_engineBase": engineBase ? engineBase : "",
        "x_engineBaseId": engineBaseId ? engineBaseId : "", //engineId in product search URL
        "x_bodyNumDoors" : bodyNumDoorsId.toString() ? bodyNumDoorsId.toString() : "", 
        "orderItem": [{
            "productId": uniqueId, // corresponds to uniqueId
            "quantity": quantity  
        }]
    }
    //Only want bac and domain in T3, do not want zipcode or shipmodeId in T3
    if (AppSettings.isT3) {
        body.x_bac = AppSettings.bac;
        body.x_domain = AppSettings.dealerName;
    } else { 
        body.orderItem[0].xitem_shipModeId = shipModeId;
        body.x_zipcode = zipcode;
    }

    const url = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart';
    let data = yield call(postAuthenticated, url, body, {});
    return data;
}