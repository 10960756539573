import React from 'react';
import { Translation } from "react-i18next";

const PayInStore = (props)=>{

    setTimeout(()=>{
        const el = document.getElementsByClassName('ac-checkout-wizard-required');
        el[0].classList.add('ac-hideRequired');
    });

    return (<Translation>
            { (t)=>(
                <div className="ac-payInStore">
                    <form onSubmit={props.nextButton}>
                    <div className="row">
                        <div className="small-12 medium-12 large-12 xlarge-12 grid-column-alignment-left columns">
                            <input type="radio" checked={true} readOnly={true}/>
                            <span className="ac-payInStoreText">{t("LABEL_RECEIPT_PAY_IN_STORE")}</span>
                         </div>
                    </div>
                    </form>
                </div>

            )}
        </Translation>
        )
    };

export default PayInStore;
