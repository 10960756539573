import {takeEvery, takeLatest} from 'redux-saga/effects'


// Vehicle select imports
import * as vehicleSelectWorker from '../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectWorker';
import * as vehicleSelectTypes from '../../shared/VehicleSelect/VehicleSelectRedux/VehicleSelectActionType';
import * as homeCarouselType from '../../modules/Home/HomeCarousel/HomeCarouselRedux/HomeCarouselActionType';
import * as homeCarouselWorker from '../../modules/Home/HomeCarousel/HomeCarouselRedux/HomeCarouselWorker';
import * as homeCategoriesType from '../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionType';
import * as homeCategoriesWorker from '../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesWorker';
import * as searchResultsListWorker
  from '../../modules/Search/SearchResultsList/SearchResultsListRedux/SearchResultsListWorker';
import * as searchServiceActionType from '../../modules/Search/SearchService/SearchDataServiceActionTypes';
import * as headerTypes from '../../modules/App/Header/HeaderRedux/HeaderActionType';
import * as headerWorker from '../../modules/App/Header/HeaderRedux/HeaderWorker';
import * as footerTypes from '../../modules/App/Footer/FooterRedux/FooterActionType';
import * as footerWorker from '../../modules/App/Footer/FooterRedux/FooterWorker';

// Search
import * as searchDataServiceWorker from '../../modules/Search/SearchService/SearchDataServiceWorker';

//category nav imports
import * as categoryNavTypes from '../../modules/Search/SearchNav/CategoryNav/CategoryNavRedux/CategoryNavActionTypes';
import * as categoryNavWorker from '../../modules/Search/SearchNav/CategoryNav/CategoryNavRedux/CategoryNavWorker';

// product details imports
import * as productDetailsTypes from '../../modules/ProductDetails/ProductDetailsRedux/ProductDetailsActionType';
import * as productDetailsWorker from '../../modules/ProductDetails/ProductDetailsRedux/ProductDetailsWorker';

// similar items imports
import * as similarItemsTypes
  from '../../modules/ProductDetails/ProductDetailsSimilarItems/ProductDetailsSimilarItemsRedux/SimilarItemsActionType';
import * as similarItemsWorker
  from '../../modules/ProductDetails/ProductDetailsSimilarItems/ProductDetailsSimilarItemsRedux/SimilarItemsWorker';

//Wheel package imports
import * as wheelPackageType from '../../shared/WheelPackage/WheelPackageRedux/WheelPackageActionType';
import * as wheelPackageWorker from '../../shared/WheelPackage/WheelPackageRedux/WheelPackageWorker';

//Fitment check imports
import * as fitmentCheckType from '../../modules/ProductDetails/FitmentCheck/FitmentCheckRedux/FitmentCheckActionType';
import * as fitmentCheckWorker from '../../modules/ProductDetails/FitmentCheck/FitmentCheckRedux/FitmentCheckWorker';

//Vehicle configuration imports
import * as vehicleConfigType from '../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigActionType';
import * as vehicleConfigWorker from '../../shared/VehicleConfig/VehicleConfigRedux/VehicleConfigWorker';

//Mini Cart imports
import * as miniCartType from '../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartActionType';
import * as miniCartWorker from '../../modules/App/Header/MiniCart/MiniCartRedux/MiniCartWorker';

//Cart imports
import * as orderInfoType from '../../shared/OrderInfo/OrderInfoRedux/OrderInfoActionTypes';
import * as orderInfoWorker from '../../shared/OrderInfo/OrderInfoRedux/OrderInfoWorker';

//Order Confirm Imports
import * as orderConfirmType from '../../modules/OrderConfirmation/OrderConfirmRedux/OrderConfirmActionTypes';
import * as orderConfirmWorker from '../../modules/OrderConfirmation/OrderConfirmRedux/OrderConfirmWorker';


//Dealer Locator Imports
import {
  CONTINUE_BUTTON,
  LOAD_DEALER_DATA_ASYNC,
  UPDATE_ZIP_CODE
} from '../../shared/DealerLocator/DealerLocatorRedux/DealerLocatorActionTypes'
import {getDealerData, handleContinueButton} from '../../shared/DealerLocator/DealerLocatorRedux/DealerLocatorWorker';

//Checkout imports
import * as checkoutTypes from '../../modules/Checkout/CheckoutRedux/CheckoutActionType';
import * as checkoutWorker from '../../modules/Checkout/CheckoutRedux/CheckoutWorker';

//Session Imports
import * as vehicleInfoTypes from '../../shared/Session/VehicleInfo/VehicleInfoActionType';

//Error Page Imports
import * as errorPageTypes from '../../shared/ErrorPage/ErrorPageRedux/ErrorPageActionType'
import * as errorPageWorker from '../../shared/ErrorPage/ErrorPageRedux/ErrorPageWorker';


// Vov Imports
import * as vovActionType from '../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVActionTypes';
import * as vovWorker from '../../shared/ViewOnVehicle/ViewOnVehicleRedux/VoVWorker';

// Authentication imports
import * as authenticationTypes from '../../shared/Authentication/AuthenticationActionTypes';
import * as authenticationWorker from '../../shared/Authentication/AuthenticationWorker';

// Rewards imports
import * as rewardsTypes from '../../shared/Rewards/RewardsRedux/RewardsActionTypes';
import * as rewardsWorker from '../../shared/Rewards/RewardsRedux/RewardsWorker';

// Featured Categories imports
import * as featuredCategoriesTypes from "../../shared/FeaturedCategories/FeaturedCategoriesRedux/FeaturedCategoriesActionType";
import * as featuredCategoriesWorker from "../../shared/FeaturedCategories/FeaturedCategoriesRedux/FeaturedCategoriesWorker";

//User Profile Imports
import * as userProfileType from '../../modules/UserProfile/UserProfileRedux/UserProfileActionType';
import * as userProfileWorker from '../../modules/UserProfile/UserProfileRedux//UserProfileWorker';

// Wallet Imports
import * as walletType from '../../shared/Wallet/WalletRedux/WalletActionType';
import * as walletWorker from '../../shared/Wallet/WalletRedux/WalletWorker';

export default function* RootSaga() {
  //watcher goes here
  // Home Carousel
  yield takeEvery(homeCarouselType.LOAD_HOME_CAROUSEL_DATA_ASYNC, homeCarouselWorker.callForHomeCarousel);
  // Home Categories
  yield takeEvery(homeCategoriesType.LOAD_HOME_CATEGORIES_DATA_ASYNC, homeCategoriesWorker.callForHomeCategories);
  // Vehicle select watchers
  yield takeEvery(vehicleSelectTypes.LOAD_YEARS_ASYNC, vehicleSelectWorker.handleLoadYears);
  yield takeEvery(vehicleSelectTypes.SELECT_YEAR, vehicleSelectWorker.handleSelectYear);
  yield takeEvery(vehicleSelectTypes.SELECT_MAKE, vehicleSelectWorker.handleSelectMake);
  yield takeEvery(vehicleSelectTypes.LOAD_VIN_DATA_ASYNC, vehicleSelectWorker.handleVehicleSelectSearch);

  // Search Result List
  yield takeEvery(vehicleInfoTypes.SET_VIN_ASYNC, searchDataServiceWorker.handleLoadData);
  yield takeEvery(searchServiceActionType.LIST_DATA_ASYNC, searchResultsListWorker.searchResultsListData);
  yield takeEvery(searchServiceActionType.LOAD_MORE, searchResultsListWorker.searchResultsListData);
  yield takeEvery(categoryNavTypes.LOAD_CATEGORY_NAV_DATA_ASYNC, categoryNavWorker.getSearchData);
  yield takeEvery(categoryNavTypes.CATEGORY_NAV_URL_UPDATE, categoryNavWorker.handleURL);
  yield takeEvery(searchServiceActionType.NLS_LOAD_SPINNER, searchResultsListWorker.handleNlsFlow);

  // Similar Items
  yield takeEvery(similarItemsTypes.LOAD_SIMILAR_ITEMS_DATA_ASYNC, similarItemsWorker.handleLoadSimilarItemsData);
  // product details
  yield takeEvery(productDetailsTypes.LOAD_PRODUCT_DETAILS_ASYNC, productDetailsWorker.handleLoadData)
  yield takeEvery(productDetailsTypes.ADD_TO_CART_ASYNC, productDetailsWorker.handleAddToCart)


  // checkout
  yield takeEvery(checkoutTypes.CHECKOUT_NEXT_STEP, checkoutWorker.handleCheckoutStepChange);
  yield takeEvery(checkoutTypes.CHECKOUT_PREV_STEP, checkoutWorker.handleCheckoutStepChange);
  yield takeEvery(checkoutTypes.CHECKOUT_EDIT_FORM, checkoutWorker.handleCheckoutStepChange);
  yield takeEvery(checkoutTypes.CHECKOUT_LOAD_INITIAL_CONTACT_FORM, checkoutWorker.handleLoadInitialContactForm);
  yield takeEvery(checkoutTypes.CHECKOUT_SUBMIT_CONTACT_FORM_ASYNC, checkoutWorker.handleContactFormSubmit)
  yield takeEvery(checkoutTypes.CHECKOUT_COUPON_CODE_POST_ASYNC, checkoutWorker.handleCouponCodeSubmit);
  yield takeEvery(checkoutTypes.CHECKOUT_COUPON_CODE_REMOVE_POST_ASYNC, checkoutWorker.handleCouponCodeRemove);
  yield takeEvery(checkoutTypes.CHECKOUT_COUPON_PROMOTION_ID_POST_ASYNC, checkoutWorker.handleCouponPromotionIdSubmit);
  yield takeEvery(checkoutTypes.CHECKOUT_LOAD_COUNTRY_STATE_LIST_ASYNC, checkoutWorker.handleLoadCountryStateList);
  yield takeEvery(checkoutTypes.CHECKOUT_SUBMIT_MODAL, checkoutWorker.handleAddressModalSubmit);
  yield takeEvery(checkoutTypes.CHECKOUT_ON_DELIVERY_FORM_PREVIOUS, checkoutWorker.handleOnDeliveryFormPrevious);
  yield takeEvery(checkoutTypes.CHECK_PAYMENT_METHOD_ASYNC, checkoutWorker.paymentMethodWorker);
  yield takeEvery(checkoutTypes.SUBMITTING_ORDER, checkoutWorker.handlePostSubmitOrder);
  yield takeEvery(checkoutTypes.CHECKOUT_SUBMIT_DELIVERY_FORM_ASYNC, checkoutWorker.handleDeliveryFormSubmit);
  yield takeEvery(checkoutTypes.GET_COUPON_DATA, checkoutWorker.handleGetCouponData);

  // header
  yield takeEvery(headerTypes.LOAD_HEADER_DATA_ASYNC, headerWorker.handleLoadHeaderData);
  // footer
  yield takeEvery(footerTypes.LOAD_FOOTER_DATA_ASYNC, footerWorker.handleLoadFooterData);

  //Wheel Package
  yield takeLatest(wheelPackageType.OPEN_MODAL_AND_ASYNC_CALL, wheelPackageWorker.getWheelPackageData);

  //Fitment Check
  yield takeEvery(fitmentCheckType.LOAD_VEHICLE_COMBINATIONS_ASYNC, fitmentCheckWorker.callForVehicleCombinations)
  yield takeEvery(fitmentCheckType.LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC, fitmentCheckWorker.callForFitmentCheck)

  //Vehicle Configuration
  yield takeEvery(vehicleConfigType.LOAD_VEHICLE_COMBOS_ASYNC, vehicleConfigWorker.callForVehicleCombinations)

  //Mini Cart Package
  yield takeEvery(miniCartType.LOAD_MINI_CART_DATA_ASYNC, miniCartWorker.getMiniCartData);

  //Cart Package
  yield takeEvery(orderInfoType.ORDER_INFO_ORDER_IN_CART_ASYNC, orderInfoWorker.getOrderInfo);
  yield takeEvery(orderInfoType.ORDER_INFO_SHIPPING_RATES, orderInfoWorker.getShippingRateData);
  yield takeEvery(orderInfoType.ORDER_INFO_QTY_INCREMENT, orderInfoWorker.orderItemChangeQuantity);
  yield takeEvery(orderInfoType.ORDER_INFO_QTY_DECREMENT, orderInfoWorker.orderItemChangeQuantity);
  yield takeEvery(orderInfoType.ORDER_INFO_QTY_INPUT_CHANGE, orderInfoWorker.orderItemChangeQuantity);
  yield takeEvery(orderInfoType.ORDER_INFO_LINE_ITEM_REMOVE, orderInfoWorker.orderItemRemove);
  yield takeEvery(orderInfoType.ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC, orderInfoWorker.getDataInfo);

  //Order Confirmation
  yield takeEvery(orderConfirmType.ORDER_CONFIRM_GET_ORDER_DATA_ASYNC, orderConfirmWorker.getOrderInformation)
  yield takeEvery(orderConfirmType.ORDER_CONFIRM_GET_PAYMENT_INFO_ASYNC, orderConfirmWorker.getPaymentInfo);

  //Dealer Locator
  yield takeLatest(LOAD_DEALER_DATA_ASYNC, getDealerData);
  yield takeLatest(UPDATE_ZIP_CODE, getDealerData);
  yield takeLatest(CONTINUE_BUTTON, handleContinueButton);

  //Error Page
  yield takeEvery(errorPageTypes.LOAD_ERROR_PAGE_DATA_ASYNC, errorPageWorker.callForErrorPageData);

  //View on Vehicle
  yield takeEvery(vovActionType.VOV_DATA_ASYNC, vovWorker.vovWorkerInitialData);
  yield takeEvery(vovActionType.VOV_CHANGE_COLOR, vovWorker.vovWorkerInitialData);
  yield takeEvery(vovActionType.RETRIEVE_PARTS_RPO_FROM_CGI_ASYNC, vovWorker.getAllPartsRPOFromCgiWorker);

  // Authentication
  yield takeEvery(authenticationTypes.AUTHENTICATION_USER_LOGIN_REQUEST, authenticationWorker.handleRequestUserLogin);
  yield takeEvery(authenticationTypes.AUTHENTICATION_USER_LOGOUT_REQUEST, authenticationWorker.handleRequestUserLogout);
  yield takeEvery(authenticationTypes.AUTHENTICATION_REGISTRATION_STATUS_REQUEST, authenticationWorker.handleGetRegistrationStatus);
  yield takeEvery(authenticationTypes.AUTHENTICATION_CHECK_SSO, authenticationWorker.handleAzureSSOCheck);
  yield takeEvery(authenticationTypes.AZURE_SINGLE_SIGN_ON_CHECK, authenticationWorker.AzureSingleSignOnCheck);

  // featured categories
  yield takeEvery(
  featuredCategoriesTypes.LOAD_FEATURED_CATEGORIES_DATA_ASYNC,
  featuredCategoriesWorker.callForFeaturedCategories
  );

  //Rewards
  yield takeEvery(rewardsTypes.REWARDS_GET_HEADER_REWARDS_POINTS, rewardsWorker.getRewardsHeaderDetail)
  yield takeEvery(rewardsTypes.REWARDS_GET_CART_POTENTIAL_POINTS, rewardsWorker.handleGetCartPotentialPoints);
  yield takeEvery(rewardsTypes.REWARDS_UPDATE_ITEM_POTENTIAL_POINTS, rewardsWorker.handleUpdateItemPotentialRewardsPoints);
  yield takeEvery(rewardsTypes.REWARDS_GET_MEMBER_REDEMPTION_INFO, rewardsWorker.getRedemptionInformationConfig);
  yield takeEvery(rewardsTypes.REWARDS_GET_POTENTIAL_POINTS_INFO, rewardsWorker.getPotentialPointsConfig);
  yield takeEvery(rewardsTypes.UPDATE_REWARDS_POINTS, rewardsWorker.callForUpdateRewardsPoints);
  yield takeEvery(rewardsTypes.LOYALTY_GET_PD_POTENTIAL_POINTS, rewardsWorker.handleGetPdPotentialPoints);

  //User Profile
  yield takeEvery(userProfileType.LOAD_ORDER_HISTORY_DATA_ASYNC, userProfileWorker.getOrderHistoryData);
  yield takeEvery(userProfileType.LOAD_ORDER_DATA_ASYNC, userProfileWorker.getOrderDetailsData);
  yield takeEvery(userProfileType.LOAD_PAYMENT_INFO_DATA_ASYNC, userProfileWorker.getPaymentInfoData);
  yield takeEvery(userProfileType.LOAD_PERSONAL_INFO_DATA_ASYNC, userProfileWorker.getPersonalInfoData);

  // Wallet
  yield takeEvery(walletType.GET_WALLET_DATA, walletWorker.handleGetWalletData);
}
