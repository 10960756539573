import React, { useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import store from '../../core/Redux/Store';
import { pageTitle } from "../../shared/Hooks/Hooks";
import { requestUserLogin, setAuthenticationStateValues } from './AuthenticationActionCreator';


function Authentication(props) {
    pageTitle("Authenticate");

    useEffect(() => {
        // get auth values from login
        const parsedAuthURLValues = parseAuthenticationQueryValues(window.location.search);
        // request login
        store.dispatch(setAuthenticationStateValues(parsedAuthURLValues.idToken, parsedAuthURLValues.stateJSON));
        store.dispatch(requestUserLogin(parsedAuthURLValues.idToken, parsedAuthURLValues.stateJSON));
    }, [])

    // If the reducer has an unverifiedEmail, the page needs to redirect to the Verification page
    if (props.authenticationData.unverifiedEmail && props.authenticationData.unverifiedEmail !== null) {
        return <Redirect to={{ pathname: '/register/accountVerification', state: { unverified: props.authenticationData.unverifiedEmail } }} />
    }

    //responsible for parsing the expected URL values returned from Azure on this page. 
    function parseAuthenticationQueryValues(queryString) {
        const values = {
            state: '',
            code: ''
        };
        const hashArray = queryString.replace('?', '').split('&');
        hashArray.forEach(function (valueString) {
            const valuePair = valueString.split('=');
            if (valuePair.length === 2) {
                const key = valuePair[0];
                const value = valuePair[1];
                values[key] = value;
            }
        })

        //parse encoded JSON state in URL
        let stateJSON = {};
        if (values['state']) {
            const decodedStateValue = decodeURIComponent(values['state']);
            try {
                stateJSON = JSON.parse(decodedStateValue);
            } catch (e) {
                //if we fail to pass a proper JSON state in the URL, there's no way to go forward
                stateJSON = { error: 'Unable to parse JSON passed in URL' };
            }
        }
        else {
            //if we fail to pass a proper JSON state in the URL, there's no way to go forward
            stateJSON = { error: 'Invalid or missing state passed in URL' };
        }

        const action = {
            stateJSON: stateJSON,
            idToken: values['code']
        }
        return action;
    }

    // get just the route path from redirect url (remove base)
    function parseLocation(href) {
        var pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
        var matches = href ? href.match(pathNameRegex) : null;
        // if no route path, send to home page
        // registrationDetails check is needed for the very specific scenario when a user is redirected from the account registration flow.
        return (href && href === '/register/registrationDetails' ? href :
                matches && matches[1] ? matches[1] : '/');
    }


    return (
        <>

            {/* This page doesn't display anything and only redirects the user 
                See AuthenticationModal for error message handling */}
            {props.authenticationData.registrationStatusFetched && <Redirect to={parseLocation(props.authenticationData.state.redirectPath)} />}
        </>
    )
};


const mapStateToProps = state => {
    return {
        authenticationData: state.Authentication
    }
}

export default connect(mapStateToProps)(Authentication);