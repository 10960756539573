import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import SharedAccordion from "../Accordion/SharedAccordion";
import accHistory from "../../../client/modules/App/History";
import propTypes from "prop-types";
import AppSettings from "../../core/AppSettings";
import { clearCookie, getMakeId, reverseArray, sequentialFitmentDelete } from "./filtersUtils";
import { jsonToUrlParams } from "../Utils/Utils";

/**
 *  This is the FacetSingleSelect component. Fill the below props to dictate the features of the accordion:
 Usage instructions. ctrl+f and search the relevant Step X) for the example:
 NOTE: FacetSingleSelect is used to display facet filters on left side of the results page, these facets are returned by 
 backend as an array of arrays (facetView is the array of arrays), therefore it is likely that you will need to map through the 
 facetView to display Fitment RadioButton. Example of this below:
    props.facetView &&
        props.facetView.map((facet, index) => {
            if (index > 1) {
                const title = facet.name.charAt(0).toUpperCase() + facet.name.slice(1);
                const facetEntryArray = facet.entry;
                return (
                    <FacetSingleSelect
                        key={index}
                        showModel={IS_AC_DELCO}
                        setIsMakeSelected={setIsMakeSelected}
                        isMakeSelected={isMakeSelected}
                        accordionTitle={title}
                        facetView={props.facetView}
                        facetEntry={facetEntryArray}
                        isYearSelected={this.state.isYearSelected}
                        setIsYearSelected={this.handleIsYearSelected}
                        isSearchDataLoading={this.props.searchData.searchResultsListIsLoading}
                    ></FacetSingleSelect>
                );
            }
        })}
 Step 0) Import the FacetSingleSelect component using the relevant path,
 Step 1) Declare the FacetSingleSelect component and pass the props.
          A list of the props, the type of the prop, any defaults, and their descriptions are explained below:
 Available Props:
  showModel = boolean;
              Required only for the model facet (used for ACDelco brand site to hide or show the model accordion)
  SetIsMakeSelected = function;
              Required only for ACDelco brand site, setter function for isMakeSelected
  IsMakeSelected = bool;
              Required only for ACDelco brand site, this holds boolean value based on whether or not make is active. 
  SetReloadUrl = function;
              REQUIRED 
              This function handles reloading the url once a facet is selected
  accordionTitle = string;
              REQUIRED this value is displayed at the top of the accordion.
  facetView = array;
              REQUIRED this array is the array of facets (also an array) passed in from the backend.
              we are passing this in to use in a useEffect to update and show only the appropriate facet filters.
  facetEntry = array;
              REQUIRED this array is the individual facet array, containing the facets you wish to populate the accordion with.
              ie. an array of colors. 
 // Step 0) Import the FacetSingleSelect component from the shared dir (use the correct path from your component)
 import FacetSingleSelect from "../../../shared/FitmentFilters/FacetSingleSelect";
 // Step 1) Declare the FacetSingleSelect component with props
            <FacetSingleSelect
                key={index}
                showModel={IS_AC_DELCO}
                setIsMakeSelected={setIsMakeSelected}
                isMakeSelected={isMakeSelected}
                setReloadUrl={props.setReloadUrl}
                accordionTitle={title}
                facetView={props.facetView}
                facetEntry={facetEntryArray}
                isYearSelected={this.state.isYearSelected}
                setIsYearSelected={this.handleIsYearSelected}
                isSearchDataLoading={this.props.searchData.searchResultsListIsLoading}
            ></FacetSingleSelect>
 export default App
 * */

const FacetSingleSelect = (props) => {
    // DEFAULT_FACET_AMOUNT holds the number of facets to be displayed before user clicks view more CTA.
    const DEFAULT_FACET_AMOUNT = 7;
    const NONE_CONST = "None";
    const MODEL_CONST = "model";
    const YEAR_CONST = "year";
    const BODY_CONST = "body";
    const WHEEL_CONST = "wheel";
    const DRIVE_CONST = "drive";
    const ENGINE_CONST = "engine";
    const ID_CONST = "Id";
    const BND_CONST = "bodyNumDoors";

    const [facets, setFacets] = useState([]);
    const [viewMoreAmount, setViewMoreAmount] = useState();
    const [showMore, setShowMore] = useState("false");
    const [inputValue, setInputValue] = useState("");
    const [filterList, setFilterList] = useState([]);
    const [selectedRadioButton, setSelectedRadioButton] = useState(NONE_CONST);
    const displayFilter = props.lastActiveFilter + 1 >= props.displaySequence; 
    const filterFocus = "filteringInputField" + props.accordionTitle;
    const searchBarBackgroundImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/search.svg";
    const {t} = useTranslation();
    const urlParameters = queryString.parse(location.search);
    const scrollToDiv = "ac-facet-filter" + props.accordionTitle;
    const fitmentType = fitmentParam(props.fitmentType);
    const fitmentIdUrlParam = fitmentType + ID_CONST
    const [showSearchError, setShowSearchError] = useState(false);
    let uriFacet = "";
    let isActive = false;
    const isRadioSelected = (value) => {
        return selectedRadioButton === value ? true : false;
    };

    const handleRadioClick = (event) => {
        setSelectedRadioButton(event.target.value);
    };

    function getFacetsFromFacetData(data) {
        let facetArr = [];
        // remove duplicate facets if they exist. Checking for "body" because body type data returns special case duplicates with extra needed data in extendedData.
        if(fitmentType !== "body"){
            data = data.filter(
                (obj, index, self) => index === self.findIndex((element) => obj["value"] == element["value"])
            );
        } else {
            // make an array that contains only facets that contain bodyNumDoors in extended data. 
            let bodyNumDoorList = [];
             data.map((facet) => {
                if (facet.extendedData.bodyNumDoors) {
                    bodyNumDoorList.push(facet);
                }
            }
            );
            // filter the data array to remove duplicates (this will likely filter out some of the facets with bodyNumDoors, which we want to keep).
            data = data.filter(
            (obj, index, self) => index === self.findIndex((element) => obj["value"] == element["value"])
            );
            // finally, find and replace any facets in the data array that may be missing bodyNumDoors with their duplicate counterparts that *do* have it. 
            data.forEach((obj, index) => {
                let bodyNumDoorsIndex = bodyNumDoorList.findIndex((element) => obj.value == element.value)
                if (bodyNumDoorsIndex !== -1) {
                    data[index] = bodyNumDoorList[bodyNumDoorsIndex];
                }
            });
        }
        
        if (data) {
            let formattedArr = [...data];
            // Reverse the year filters to display in decending order.
            reverseArray(fitmentType, "year", formattedArr);
            formattedArr.map((item, index) => {
                uriFacet = decodeURIComponent(item.value.replace(/\+/g, "%20"));

                if (urlParameters[fitmentIdUrlParam] && fitmentIdUrlParam !== MODEL_CONST + ID_CONST) {
                    isActive = uriFacet == urlParameters[fitmentIdUrlParam];
                    props.setLastActiveFilter(props.displaySequence);
                }else if (urlParameters[fitmentType]) {
                    isActive = uriFacet == urlParameters[fitmentType];
                    props.setLastActiveFilter(props.displaySequence);

                } else {
                    isActive = false;      
                }
                // isActive = compareUrlFacets(uriFacet, urlParameters?.facet ? urlParameters.facet.split(',') : []);
                // if a facet is active, add it to the front of the facet array, if not place it at the end.
                // oldIndex is used to return the facet to its proper place in the list once inactive.
                if (isActive) {
                    setSelectedRadioButton(item.label.split("_").pop());
                    facetArr.unshift({
                        name: item.label.split("_").pop(),
                        id: uriFacet,
                        idUrlParam: fitmentIdUrlParam,
                        active: isActive,
                        oldIndex: index,
                        type: fitmentType,
                        extendedData: item?.extendedData
                    });
                } else {
                    facetArr.push({
                        name: item.label.split("_").pop(),
                        id: uriFacet,
                        idUrlParam: fitmentIdUrlParam,
                        active: isActive,
                        oldIndex: index,
                        type: fitmentType,
                        extendedData: item?.extendedData
                    });
                }
            });
            // check to see if there are are no active filters when the data reloads. if none of the filters are active,
            // set the default "None" value to active.
            let noneActive = facetArr.some((item) => item.active === true);
            !noneActive && setSelectedRadioButton(NONE_CONST);

            facetArr.unshift({
                name: NONE_CONST,
                id: "defaultValue" + props.accordionTitle,
                idUrlParam: fitmentIdUrlParam,
                active: selectedRadioButton === NONE_CONST ? true : false,
                type: fitmentType
            });
            // check to see if a facet of type year has been selected.
            if (facetArr[0].type === YEAR_CONST) {
                facetArr[0].active === true ? props.setIsYearSelected(false) : props.setIsYearSelected(true);
            }
            // set the amount shown in view (??) more CTA
            setViewMoreAmount(facetArr.length - DEFAULT_FACET_AMOUNT);
        }
        return facetArr;
    }



    const buildFacetParameters = async (facet) => {
        // Iterate through facet list from URL
        let q = AppSettings.urlParameters();


        // Selected none, so delete the query parameter.
        if (facet.id.includes("defaultValue")) {
            // reset the display sequence of the filters. if you select none for model, stop displaying any filter under model. 
            props.setLastActiveFilter(props.displaySequence - 1);

            // switch statement makes sure that facets are set to none and removed from URL if a facet above it in the sequence is set to none. 
            q = sequentialFitmentDelete(facet.type, q);
        } else {
            q = sequentialFitmentDelete(facet.type, q);
            // Otherwise add the selected facet as a parameter
            if (facet.type === "year" || facet.type === "model"){
                q[facet.type] = facet.id;
            }
            else {
                q[facet.type] = facet.name;
                q[facet.idUrlParam] = facet.id;
                if(facet.extendedData.bodyNumDoors) {
                    q[BND_CONST] = facet.extendedData.bodyNumDoors;
                    q[BND_CONST+ID_CONST] = facet.extendedData.bodyNumDoors;
                }
            }
        }

        let cookieParams = q;
        let customURL = location.pathname + jsonToUrlParams(q);
        cookieParams.brand = AppSettings.brand;
        cookieParams = getMakeId(cookieParams);
        // Delete Previous Cookie before setting new one to avoid invalid fitment combinations. 
        await clearCookie(cookieParams);

        accHistory.push(customURL);
    }


    function fitmentParam(type) {
                switch(type){
            case "bodytype":
                return BODY_CONST;
            case "wheelbase":
                return WHEEL_CONST;
            case "drivetype":
                return DRIVE_CONST;
            case "enginebase":
                return ENGINE_CONST;
            default:
                return type;
        }
    }

    // When initializing, start out the Radio Buttons checked depending on the facet parameters, if any.
    useEffect(() => {
        //Set latest facet data from backend
        setFacets(getFacetsFromFacetData(props.facetEntry));

        //Reload facet filter with latest from backend data
        handleFacetViewData();

        //If the page reloads and a filters search bar is still populated, clear it.
        if (inputValue !== "") {
            setInputValue("");
        }

    }, [props.facetView, showMore, selectedRadioButton]);

    useEffect(() => {
        const filterSearchBar = document.getElementById(filterFocus);
        if (filterSearchBar) {
            filterSearchBar.focus();
        }
    }, [inputValue]);

    // Sets which Radio Buttons are checked/unchecked and records this info in an array
    const facetAccordionClick = (isActive, index) => {
        props.setLastActiveFilter(props.displaySequence);

        if (!isActive) {
            // clear the search field if it is populated whena  selection is made
            const filterSearchBar = document.getElementById(filterFocus);
            if (filterSearchBar) {
                if (inputValue !== "") {
                    setInputValue("");
                }
            }
    
            // Save old facets
            let newArr = [...facets];
    
            // TODO: set up multi and single select using passed in flag from backend.
            // current logic is for single select only
            // check to see if multi select is true
            // if (!multiSelect) {
            // }
    
            // Toggle the active key, then set the facets hook to the array. Only difference is the new updated active key
            newArr[index].active = !isActive;
            if (index !== 0) {
                newArr[0].active = false;
            }


            // for single select, if there is an active facet, set active to false and move it back to original position
            if (newArr[1].active && index !== 1) {
                newArr[1].active = false;
                newArr.splice(newArr[1].oldIndex + 1, 0, newArr[1]);
                newArr.splice(1, 1);
            }
            newArr.splice(1, 0, newArr.splice(index, 1)[0]);


            setFacets(newArr);

            // if a user is selecting a facet with the filter expanded, collapse the filter and scroll to top.
            !showMore && handleShowMoreClick();
    
            // Handle reloading the page
            buildFacetParameters(facets[index]);
        }
    };

    const handleShowMoreClick = () => {
        setShowMore(!showMore);
        // when user selectes to view less, scroll back to top of filter
        if (!showMore) {
            let location1 = document.getElementsByClassName(scrollToDiv);
            location1 = location1[0].getBoundingClientRect().top;
            window.scrollTo({ left: 0, top: location1 + window.pageYOffset - 100, behavior: "smooth" });
        }
    };

    // Uses backend data to dynamically populate Radio Buttons based on the results on page load.
    const handleFacetViewData = () => {
        const listOfRadioButtons = inputValue !== "" ? filterList : facets;
        return listOfRadioButtons.map((facetRadioButton, key) => {
            if (showMore && key < DEFAULT_FACET_AMOUNT) {
                return (
                    <div key={key} id="facet-checkbox-item">
                        <div className="facet-container">
                            <input
                                id={facetRadioButton.id}
                                type="radio"
                                checked={isRadioSelected(facetRadioButton.name)}
                                onChange={handleRadioClick}
                                name={props.accordionTitle}
                                value={facetRadioButton.name}
                                disabled={props.isSearchDataLoading}
                                data-dtm={"filter control:" + props.accordionTitle}
                                className="stat-radio"
                                onClick={() =>
                                    facetAccordionClick(
                                        facetRadioButton.active,
                                        inputValue !== ""
                                            ? facets.map((facet) => facet.name).indexOf(facetRadioButton.name)
                                            : key
                                    )
                                }
                                style={{ height: "1.5rem", width: "1.5rem", marginBottom: "0" }}
                            />
                            <label className="facet-label" htmlFor={facetRadioButton.id}>{t(facetRadioButton.name)}</label>
                        </div>
                    </div>
                );
            } else if (!showMore) {
                return (
                    <div key={key} id="facet-checkbox-item">
                        <div className="facet-container">
                            <input
                                id={facetRadioButton.id}
                                type="radio"
                                checked={isRadioSelected(facetRadioButton.name)}
                                onChange={handleRadioClick}
                                name={props.accordionTitle}
                                value={facetRadioButton.name}
                                disabled={props.isSearchDataLoading}
                                data-dtm={"filter control:" + props.accordionTitle}
                                className="stat-radio"
                                onClick={() =>
                                    facetAccordionClick(
                                        facetRadioButton.active,
                                        inputValue !== ""
                                            ? facets.map((facet) => facet.name).indexOf(facetRadioButton.name)
                                            : key
                                    )
                                }
                                style={{ height: "1.5rem", width: "1.5rem", marginBottom: "0" }}
                            />
                            <label className="facet-label" htmlFor={facetRadioButton.id}>{t(facetRadioButton.name)}</label>
                        </div>
                    </div>
                );
            }
        });
    };

    const filterHandler = (event) => {
        // original list is a copy of the facets array which we use to filter through as user uses search bar.
        const originalList = [...facets];
        const filteredList = originalList.filter((item) =>
            item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
        if (filteredList.length <= 0 ) {
            setShowSearchError(true);
        } else {
            setShowSearchError(false);
        }
        setInputValue(event.target.value);
        if (event.target.value == "") {
            setViewMoreAmount(facets.length - DEFAULT_FACET_AMOUNT);
            setFilterList([]);
            return;
        }
        setFilterList(filteredList);
        setViewMoreAmount(filteredList.length - DEFAULT_FACET_AMOUNT);
    };

    //content of facet accordion - radio and a modal link
    const handleBodyContentList = () => {
        return (
        <React.Fragment>
            <div id="facet-accordion-content" className="ac-facets">
                {facets?.length >= 20 && (
                    <div>
                    <input
                        className={showSearchError ? "filter-input-error stat-search-input" : "filter-input stat-search-input"}
                        id={filterFocus} 
                        onChange={filterHandler}
                        value={inputValue}
                        type="text"
                        style={{
                            backgroundImage: `url(${searchBarBackgroundImg})`
                        }}
                        data-dtm={"filter control:" + props.accordionTitle + ":search"}
                    />
                    {showSearchError && <p className="filter-search-error">{t("LABEL_SEARCH_BOX_FILTER_ERROR")}</p>}
                    
                    </div>
                )}
                {/*Radio Buttons*/}
                {handleFacetViewData()} 
                {viewMoreAmount > 0 ? (
                    showMore ? (
                        <a id="facet-view-more-cta" className= "stat-text-link" onClick={() => handleShowMoreClick()} data-dtm={"filter control:"+ props.accordionTitle}>
                            {t("LABEL_FILTER_SHOW_MORE", { viewMoreAmount })}
                        </a>
                    ) : (
                        <a id="facet-view-less-cta"  className= "stat-text-link"  onClick={() => handleShowMoreClick()} data-dtm={"filter control:"+ props.accordionTitle}>
                            {t("LABEL_FILTER_SHOW_LESS")}
                        </a>
                    )
                ) : (
                    <></>
                )}
            </div>
        </React.Fragment>
        );
    };

    const bodyContentList = handleBodyContentList();

    // Frame of facet accordion
    const facetAccordion = [{ position: "0", titleName: props.accordionTitle, bodyContent: bodyContentList }];

    if (props.facetEntry) {
        // TEMPORARY fix to hide engine filter and tags while Khalid finishes backend logic for engine facet
        if (displayFilter && fitmentType !== ENGINE_CONST) {
            return (
                <div id="facet-shared-accordion" className={scrollToDiv}>
                    <SharedAccordion
                        accordions={facetAccordion}
                        defaultActiveKey="0"
                        objTitle={true}
                        objBody={true}
                        fontAwesomeSize="lg"
                        iconPlusMinus
                        leftOfTitle={false}
                        dataDtm="filter control"
                    />
                </div>
            );
        } 
    }
    return null;
};

FacetSingleSelect.propTypes = {
    showModel: propTypes.bool,
    setIsMakeSelected: propTypes.func,
    isMakeSelected: propTypes.bool,
    accordionTitle: propTypes.string.isRequired,
    facetView: propTypes.array.isRequired,
    facetEntry: propTypes.array.isRequired 
};

export default FacetSingleSelect;
