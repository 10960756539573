import React, { useState } from "react";
import PropTypes from "prop-types";
import SharedToastMessage from "./SharedToastMessage";
import { faCheck, faExclamationCircle, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

/**
  SHARED TOAST COMPONENT - Initial handler for toast/messaging shareable component.
  Props:
    toast_header (Required): string
      Words to display on the header of the toast message, passed in as a translation key.

    toast_body (Required): string
      Description that follows the header information, displays in the body of the toast message.
      Passed in as a translation key.

    toast_type (Required): [error, info, success, warning]
      The type of toast to display, from 4 different choices

    handleDismissClick (Optional): func
      Function that can be passed down from parent to help manage state

  Usage:
    1) Import the shared toast component:
        import SharedToast from "../../shared/Toasts/SharedToast";
    2) determine how you want the toast component to display.
        example CSS for top right positioning. changing absolute to relative moves toasts with scroll:
        style={{
          position: 'absolute',
          top: 0,
          right: 0
          }}
    3) create your shared toast component and pass in the props indicated in the section above.
        example:
        <SharedToast
          toast_header="LABEL_ERROR_TOAST_HEADER_GENERIC"
          toast_body="LABEL_ERROR_TOAST_MESSAGE_GENERIC"
          toast_type="warning"
        />
 */
export default function SharedToast(props) {
    const { t } = useTranslation();
    const [show, setShow] = useState(true);

    const handleDismissClick = () => {
        setShow(!show);
    };

    const toastSelector = (header, body, toast_type) => {
        const inputProps = {
            header_title: header,
            body_message: body,
            handleDismissClick: props.handleDismissClick || handleDismissClick
        };

        switch (toast_type) {
            case "error":
                return (
                    <SharedToastMessage
                        {...inputProps}
                        toast_header_css="error-toast-header"
                        toast_icon_color="error-toast-icon-color"
                        toast_icon_css={faExclamationTriangle}
                        toast_body_css="error-toast-body"
                    />
                );
            case "info":
                return (
                    <SharedToastMessage
                        {...inputProps}
                        toast_header_css="info-toast-header"
                        toast_icon_color="info-toast-icon-color"
                        toast_icon_css={faInfoCircle}
                        toast_body_css="info-toast-body"
                    />
                );
            case "success":
                return (
                    <SharedToastMessage
                        {...inputProps}
                        toast_header_css="success-toast-header"
                        toast_icon_color="success-toast-icon-color"
                        toast_icon_css={faCheck}
                        toast_body_css="success-toast-body"
                    />
                );
            case "warning":
                return (
                    <SharedToastMessage
                        {...inputProps}
                        toast_header_css="warning-toast-header"
                        toast_icon_color="warning-toast-icon-color"
                        toast_icon_css={faExclamationCircle}
                        toast_body_css="warning-toast-body"
                        isStockOrderPage={props.isStockOrderPage}
                    />
                );
        }
    };

    return <>{show && toastSelector(t(props.toast_header), t(props.toast_body), props.toast_type)}</>;
}
SharedToast.propTypes = {
    toast_header: PropTypes.string.isRequired,
    toast_body: PropTypes.string.isRequired,
    toast_type: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
    handleDismissClick: PropTypes.func,
    isStockOrderPage: PropTypes.bool
};
