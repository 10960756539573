import React, { useState } from "react";
import { Accordion } from "react-accessible-accordion";
import propTypes from "prop-types";
import ClpCategoryItem from "./ClpCategoryItem.js";
import AppSettings from "../../../core/AppSettings.js";
import vaultConstants from "../../../../config/vault_constants.js";
import Modal from "../../../shared/Modal/Modal.js";
import SharedButton from "../../../shared/Button/SharedButton.js";
import { useTranslation } from "react-i18next";

/**
 *  This is the CategorySelector Accordion component. Fill the below props to dictate the features of the accordion:

 Available Props:
 categories= array of objects;
 REQUIRED You must define the array for the accordion to grab necessary props from;
 subCategories= array of objects;
Not
 The accordion array requires these object keys:
 [{
      position: 'string #',
      mainCategory: 'accordionTitle',
      count: 'string',
      subCategories:
         {[
             count: "string #",
             label: "accordionSubTitle",
             uniqueID: "unique string"
         ]}
          OR <Component/>
  }];

 categories = {
    count: "42",
    mainCategory: "Cleaning Products",
    position: "2",
    subCategories: [
        {
        count: "7",
        label: "Vehicle Care Kits",
        uniqueID: "3074457345616858671"
        },
        {
        count: "22",
        label: "Vehicle Exterior Care",
        uniqueID: "3074457345616858672"
        }
    ]
 }



 * */

export default function CategorySelector(props) {
    const clpEnhancementsFlag = AppSettings.isLocalHost
        ? true
        : String(vaultConstants.FF_2207674_CLP_ENHANCEMENTS) === "true";
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    //creates accordion items for accordion
    const createCategories = () => {
        const categories = props.categoryData;
        if (categories !== undefined) {
            const accordionItems = categories.map((category, i) => {
                return (
                    <ClpCategoryItem
                        id="clp-category-item"
                        key={category.position}
                        category={category}
                        currentCategory={props.currentCategory}
                        handleClickEvent={props.handleClickEvent}
                        handleSubMainClickEvent={props.handleSubMainClickEvent}
                        handleSubClickEvent={props.handleSubClickEvent}
                        closeAllCategories={props.closeAllCategories}
                        isMobile={props.isMobile}
                        isModalOpen={isModalOpen}
                        closeModalFunc={closeModal}
                        displayCategory={props.displayCategory}
                    />
                );
            });

            return accordionItems;
        }
    };

    // Modal Handlers
    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal() {
        setIsModalOpen(true);
    }

    return props.isMobile && clpEnhancementsFlag ? (
        <div id="clp-modal-mobile-view" data-testid="clp-modal-mobile-view-active">
            <Modal
                open={isModalOpen}
                modalId="ac-clp-accordion-mobile-view"
                dataDtm="category landing page"
                dataClass="stat-button-close"
                closeOnOverlayClick={true}
                onClose={closeModal}
                closeIconSize={25}
                data-testid="ac-clp-accordion-modal-active"
            >
                <h2 className="q-headline2">{t("LABEL_SEARCH_SHOP_CATEGORY")}</h2>
                <Accordion id="clp-accordion" className="ac-accordion">
                    {createCategories()}
                </Accordion>
            </Modal>
            <div className="ac-mobile-catnav-btn">
                <SharedButton
                    className="gb-primary-button q-button ac-clp-category-btn"
                    analyticsTag="category landing page"
                    buttonText={t("LABEL_SEARCH_SHOP_CATEGORY")}
                    onClick={openModal}
                    id="ac-clp-accordion-modal-btn"
                />
            </div>
        </div>
    ) : (
        <div id="clp-left-nav-desktop-view">
            <Accordion id="clp-accordion" className="ac-accordion">
                {createCategories()}
            </Accordion>
        </div>
    );
}

CategorySelector.propTypes = {
    categoryData: propTypes.array.isRequired,
    closeAllCategories: propTypes.bool.isRequired,
    currentCategory: propTypes.any,
    handleClickEvent: propTypes.func.isRequired,
    handleSubMainClickEvent: propTypes.func.isRequired,
    handleSubClickEvent: propTypes.func.isRequired
};
