export const handlePaymentFieldError = ()=> {
    const nameIDError = document.getElementById('gmpayui-name-error');
    const cardIDError = document.getElementById('gmpayui-card-number-error');
    const expireMonthIDError = document.getElementById('gmpayui-expiry-month-error');
    const expireYearIDError = document.getElementById('gmpayui-expiry-year-error');
    const securityCodeIDError = document.getElementById('gmpayui-security-code-error');


    let showNameError = false;
    let showCardError = false;
    let showExpireMonthError = false;
    let showExpireYearError = false;
    let showSecurityCodeError = false;

    const errorClass = 'ac-paymentFieldError';
    const eventType = 'blur';
    const displayStyle = 'block';


    if (nameIDError.style.display === displayStyle) {
        showNameError = true;
    }
    if (cardIDError.style.display === displayStyle) {
        showCardError = true;

    }
    if (expireMonthIDError.style.display === displayStyle) {
        showExpireMonthError = true;
    }
    if (expireYearIDError.style.display === displayStyle) {
        showExpireYearError = true;
    }
    if (securityCodeIDError.style.display === displayStyle) {
        showSecurityCodeError = true;
    }

    if (showNameError) {
        const element = document.getElementById('gmpayui-name');
        element.classList.add(errorClass);


        element.addEventListener(eventType, () => {
            element.classList.remove(errorClass);
        });

    }

    if (showCardError) {
        const element = document.getElementById('gmpayui-card-number');
        element.classList.add(errorClass);

       window.onclick = ()=>{
            const el = document.getElementById('gmpayui-card-number-error');
            if(el && el.style && el.style.display ==='none'){
                element.classList.remove(errorClass);
            }
        };

    }


    if (showExpireMonthError) {
        const element = document.getElementById('gmpayui-expiry-month');
        element.classList.add(errorClass);


        element.addEventListener('click', () => {
            element.classList.remove(errorClass);
        });

    }

    if (showExpireYearError) {
        const element = document.getElementById('gmpayui-expiry-year');
        element.classList.add(errorClass);


        element.addEventListener('click', () => {
            element.classList.remove(errorClass);
        });

    }

    if(showSecurityCodeError){
        const element = document.getElementById('gmpayui-security-code');
        element.classList.add(errorClass);

        window.onclick = ()=>{
            const el = document.getElementById('gmpayui-security-code-error');
            if(el && el.style && el.style.display ==='none'){
                element.classList.remove(errorClass);
            }
        };

    }
    //Move scroller at the top of the screen
    const scrollElement = document.getElementById('gmpayui-name');
    scrollElement.scrollIntoView({behavior: "smooth"});
};