import React from "react";
import PropTypes from "prop-types";
import DealerLocatorHeader from "../DealerLocatorHeader";
import SaveAndCloseButton from "../Buttons/SaveAndCloseButton";
import { disclaimer, orSeparator, selectASrcSection, dealerRadioBtn } from "./ModalBodyComponents";
import CartRefillWarning from "../Warning/CartRefillWarning";
import { switchSearch } from "../HelperFunctions";

/**
 * @param {*} props
 * dispatch // reducer state updater
 * state // reducer state
 * cartFlag // boolean // triggers cart warning if true
 *
 * @return {div} <RegularBody/> modal body component that will render for the dealerization widget when it's not for product details.
 */

export default function RegularBody(props) {
    const CART_FLAG = props.cartFlag;
    if (CART_FLAG) {
        return <CartRefillWarning handleClose={props.handleClose} />;
    } else {
        return (
            <div className="prt-dealerization-modal-body">
                <DealerLocatorHeader
                    mapView={false}
                    dispatch={props.dispatch}
                    state={props.state}
                    setDealerList={props.setDealerList}
                />
                {orSeparator()}
                {dealerRadioBtn(props.handleSearchType, props.state)}
                {switchSearch(props)}
                {selectASrcSection(props.state, props.dispatch, false)}
                {disclaimer(props.state.sellerSource)}
                <SaveAndCloseButton cartFlag={CART_FLAG} dispatch={props.dispatch} state={props.state} />
            </div>
        );
    }
}

/*** PROP TYPES VALIDATION ***/
RegularBody.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired,
    cartFlag: PropTypes.bool.isRequired
};
