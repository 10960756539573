import propTypes from 'prop-types';
import React from 'react';
import FormHeader from './FormHeader/FormHeader';

//  Center page grid that takes up 1/3 space on desktop (100% on mobile)
//  Optional form header requires locale key for content
//  Customize max-width (maxWidth) style value and column screen sizes (columnClassName)

/* 

EXAMPLE:

<CenterGrid headerLabel="LABEL_CENTER_FORM_HEADER">
    [YOUR PAGE CONTENT HERE]
</CenterGrid>

*/

function CenterGrid(props) {

    return (
        <div className="row q-gridbuilder gridfullwidth columns ac-large-margintop" style={{ padding: "0 1rem" }}>
            <div className={"ac-center-form-grid-wrapper " + (props.columnClassName ? props.columnClassName : "small-11 large-8 xlarge-4 large-margin")} style={{ margin: "0 auto", maxWidth: props.maxWidth ? props.maxWidth : "600px" }}>
                {/* Optional header label */}
                {props.headerLabel && <FormHeader headerLabel={props.headerLabel} />}
                {/* Your content */}
                {props.children}
            </div>
        </div>
    )
}

CenterGrid.propTypes = {
    headerLabel: propTypes.string
}

export default CenterGrid;