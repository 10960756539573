import axios from 'axios';
import AppSettings from '../../../../core/AppSettings';


const footerLinksESpotUrl = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Footer_Links?pageName=";
const footerTextESpotUrl = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Footer_Text?noCache=true"  + "&pageName=";
const footerSloganESpotUrl = "/wcs/resources/store/" + AppSettings.storeId + "/espot/Footer_Slogan?pageName=";

export const getLinks = (pageName) => {
    return axios.get(footerLinksESpotUrl + pageName).then((response) => { return response; }, (error) => { return error; });
}

export const getSlogan = (pageName) => {
    return axios.get(footerSloganESpotUrl + pageName).then((response) => { return response; }, (error) => { return error; });
}

export const getText = (pageName) => {
    return axios.get(footerTextESpotUrl + pageName).then((response) => { return response; }, (error) => { return error; });
}
