import * as type from './ProductDetailsTabActionTypes'

const nonActiveClass = 'q-expander';
const activeClass = 'q-expander active q-button-active';

const initialState = {
    warrantyOpen: false,
    maintenanceOpen: false,
    documentationOpen: false,
    faqOpen: false,
    specificationOpen: false,
    troubleshootingOpen: false,
    wheelPackageOpen: false,
    descriptionOpen: true,
    warrantyClass: nonActiveClass,
    documentationClass: nonActiveClass,
    maintenanceClass: nonActiveClass,
    faqClass: nonActiveClass,
    specificationClass: nonActiveClass,
    troubleshootingClass: nonActiveClass,
    descriptionClass: activeClass,
    wheelPackageClass: nonActiveClass
};




const ProductDetailsTabReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.CLICKED_ON_WARRANTY:
            return (state.warrantyOpen ? { ...state, warrantyOpen: false, warrantyClass: nonActiveClass } : { ...state, warrantyOpen: true, warrantyClass: activeClass });

        case type.CLICKED_ON_DOCUMENTATION:
            return (state.documentationOpen ? { ...state, documentationOpen: false, documentationClass: nonActiveClass } : { ...state, documentationOpen: true, documentationClass: activeClass });

        case type.CLICKED_ON_MAINTENANCE:
            return (state.maintenanceOpen ? { ...state, maintenanceOpen: false, maintenanceClass: nonActiveClass } : { ...state, maintenanceOpen: true, maintenanceClass: activeClass });

        case type.CLICKED_ON_DESCRIPTION:
            return (state.descriptionOpen ? { ...state, descriptionOpen: false, descriptionClass: nonActiveClass } : { ...state, descriptionOpen: true, descriptionClass: activeClass });

        case type.CLICKED_ON_TROUBLESHOOTING:
            return (state.troubleshootingOpen ? { ...state, troubleshootingOpen: false, troubleshootingClass: nonActiveClass } : { ...state, troubleshootingOpen: true, troubleshootingClass: activeClass });

        case type.CLICKED_ON_FAQ:
            return (state.faqOpen ? { ...state, faqOpen: false, faqClass: nonActiveClass } : { ...state, faqOpen: true, faqClass: activeClass });

        case type.CLICKED_ON_SPECIFICATION:
            return (state.specificationOpen ? { ...state, specificationOpen: false, specificationClass: nonActiveClass } : { ...state, specificationOpen: true, specificationClass: activeClass });

        case type.CLICKED_ON_WHEEL_PACKAGE:
            return (state.wheelPackageOpen ? { ...state, wheelPackageOpen: false, wheelPackageClass: nonActiveClass } : { ...state, wheelPackageOpen: true, wheelPackageClass: activeClass });
        default: return state
    }
}

export default ProductDetailsTabReducer