import * as type from './WheelPackageActionType';

const initialState = {
    "showModal":false,
    "showError":false
 }

const WheelPackageReducer = (state = initialState,action)=>{
    switch (action.type){
        case type.OPEN_MODAL_AND_ASYNC_CALL:
                   return {...state,showModal:true,isFetching:true,wheelPackageData: []};

        case type.WHEEL_PACKAGE_ASYNC_SUCCESS:
            return {...state,isFetching:false,wheelPackageData:action.payload};

        case type.WHEEL_PACKAGE_ASYNC_FAILED:
            return {...state,isFetching:false,error:action.payload,showError:true};

        case type.CLOSE_MODAL:
             return {...state,showModal:false,isFetching:false};

        default:return state;
    }

}

export default WheelPackageReducer