export const ORDER_INFO_QTY_INCREMENT = "ORDER_INFO_QTY_INCREMENT";
export const ORDER_INFO_QTY_DECREMENT = "ORDER_INFO_QTY_DECREMENT";
export const ORDER_INFO_QTY_INPUT_CHANGE = "ORDER_INFO_QTY_INPUT_CHANGE";
export const ORDER_INFO_QTY_CHANGE_FAIL = "ORDER_INFO_QTY_CHANGE_FAIL";
export const ORDER_INFO_LINE_ITEM_REMOVE = "ORDER_INFO_LINE_ITEM_REMOVE";
export const ORDER_INFO_LINE_ITEM_REMOVE_FAIL = "ORDER_INFO_LINE_ITEM_REMOVE_FAIL";
export const ORDER_INFO_ORDER_IN_CART_ASYNC = "ORDER_INFO_ORDER_IN_CART_ASYNC";
export const ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL = "ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL";
export const ORDER_INFO_ORDER_IN_CART = "ORDER_INFO_ORDER_IN_CART";
export const ORDER_INFO_SHIPPING_RATES = "ORDER_INFO_SHIPPING_RATES";
export const ORDER_INFO_SHIPPING_RATES_FAIL = "ORDER_INFO_SHIPPING_RATES_FAIL";
export const ORDER_INFO_SET_SHIPPING_RATES = "ORDER_INFO_SET_SHIPPING_RATES";
export const ORDER_INFO_SET_SHIPPING_RATES_FAIL = "ORDER_INFO_SET_SHIPPING_RATES_FAIL";
export const ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC = "ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC";
export const ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC_FAILED = "ORDER_INFO_SWITCH_ORG_FOR_T3_ASYNC_FAILED";
export const ORDER_INFO_CART_QUANTITY_SHOW_TEXT_INPUT = "ORDER_INFO_CART_QUANTITY_SHOW_TEXT_INPUT";
export const ORDER_INFO_CART_QUANTITY_SHOW_SPINNER = "ORDER_INFO_CART_QUANTITY_SHOW_SPINNER";
export const ORDER_INFO_CART_REMOVE_SHOW_SPINNER = "ORDER_INFO_CART_REMOVE_SHOW_SPINNER";
export const ORDER_INFO_MINI_CART_REMOVE_SHOW_SPINNER = "ORDER_INFO_MINI_CART_REMOVE_SHOW_SPINNER";
export const ORDER_INFO_RESET = "ORDER_INFO_RESET";
