
import React, { Component } from 'react';

export class WarrantyTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="ac-tabContent">
                <h2 className="q-headline2">{this.props.warrantyTitle}</h2>
                <h5 className="q-text">{this.props.warrantyContent}</h5>
            </div>
        );
    }
}

export default WarrantyTab
