import axios from "axios";
import React, { useState } from 'react';
import store from '../../../core/Redux/Store';
import { setAccountCreationEmail } from '../AccountCreationRedux/AccountCreationActionCreator';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AppSettings from "../../../core/AppSettings";
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import Footnote from "../../../shared/Footnote/Footnote";
import { pageTitle } from "../../../shared/Hooks/Hooks";
import { submitButtonFocus, getBrandUrl } from '../../../shared/Utils/Utils';
import RegisterEmailForm from "./RegisterEmailForm";
import { sendEmailLink } from "../shared/AccountRegistrationAPI";
import vaultConstants from "../../../../config/vault_constants";

function RegisterEmail(props) {
    pageTitle("Create an Account");
    const [email, setEmail] = useState(props.location.state ? props.location.state.email : "");
    const [showSpinner, setShowSpinner] = useState(false);
    const [redirect, setRedirect] = useState("");
    const ff_2224243_caps_v3_migration = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2224243_CAPS_V3_MIGRATION_TARGETABLE) === "true";
    const orderId = props.location.state ? props.location.state.orderId : "";

    // They will also see an error which can be triggered with this variable
    const [errorMessage, setErrorMessage] = useState(props.location.state && props.location.state.noEmailError ? "LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_ERROR_EMAIL_NOT_ENTERED" : null);

    // Terms and conditions
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    const getCheckIfUserExists = (email) => {
        const UserExistsURL = "/wcs/resources/store/" + AppSettings.storeId + "/GMPerson/user/checkExists/" + email + "?responseFormat=json";
        return axios.get(UserExistsURL).then(response => response.data).catch(error => console.log("error", error));
    };

    function handleNextButton(email) {
        setErrorMessage(null); // Clear error message
        setShowSpinner(true);

        // When submitting through the use of pressing enter, if loading bar is wanted, then this focuses on the button so that the loading bar can be seen.
        submitButtonFocus();

        //first checks to see if account already exists
        getCheckIfUserExists(email).then(result => {
            // Checks if email already exists in system
            if (result.exists === "true") {
                //sets the redirect of page once Next button is clicked
                store.dispatch(setAccountCreationEmail(email));
                setRedirect("userExists");
            }
            else if (result.exists === "false") {
                //if user does not exist, then continue through the account creation process
                if (orderId) {
                    // Registering flow from the OCP page -- Loyalty 3.8 release
                    //TODO: remove feature flag after v3 migration
                    if (!ff_2224243_caps_v3_migration) {
                        setRedirect("createPassword");
                    } else {
                        let payload = {
                            username: email,
                            orderId: orderId
                        };
                        sendEmailLink(payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    setRedirect("accountConfirmation");
                                } else {
                                    //TODO: come up with better error message if send email link fails
                                    handleErrorMessage(error, t("LABEL_ERROR_BOUNDARY_GENERIC_ERROR"));
                                }
                            })
                            .catch((error) => {
                                //if response does not return then display error message
                                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                            });
                    }
                } else {
                    //TODO: remove feature flag after v3 migration
                    if (!ff_2224243_caps_v3_migration) {
                        setRedirect("createPassword");
                    } else {
                        let payload = {
                            username: email
                        };
                        sendEmailLink(payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    setRedirect("accountConfirmation");
                                } else {
                                    //TODO: come up with better error message if send email link fails
                                    handleErrorMessage(error, t("LABEL_ERROR_BOUNDARY_GENERIC_ERROR"));
                                }
                            })
                            .catch((error) => {
                                //if response does not return then display error message
                                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                            });
                    }
                }
            }
            else {
                //if response is successful but some reason doesnt have data, then display error message
                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            }
        }).catch((error) => {
            //if response does not return then display error message
            handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
        });
    }

    function handleErrorMessage(error, errorMessage) {
        // Hide spinner
        setShowSpinner(false);
        // Remove focus from button
        if (document.activeElement != document.body) {
            document.activeElement.blur();
        }
        // Display error message
        setErrorMessage(errorMessage);
    }

    if (props.authData.registrationStatusFetched) {
        //If user is in a registered session, reroute user to homepage
        if (props.authData.registrationStatus != 'G') {
            window.location.href = '/';
            return null;
        } else if (redirect === "accountConfirmation") {
            return (
                <Redirect
                    to={{
                        pathname: "/register/accountConfirmation",
                        state: {
                            email: email,
                            checkExpiration: false,
                            orderId: orderId ? orderId : "",
                            ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration
                        }
                    }}
                />
            );
        } else if (redirect === "createPassword") {
            return (
                <Redirect
                    to={{
                        pathname: "/register/createPassword",
                        state: { email: email, orderId: orderId ? orderId : "", ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration }
                    }}
                />
            );
        } else if (redirect === "userExists") {
            //If email user tries to use already exists, reroute the user to the "user exists" page.
            return <Redirect to={{ pathname: "/register/userAlreadyExists", state: { email: email,ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration } }} />;
        } else {
            //If none of the above criteria is met, return "Create Account" Page
            return (
                <CenterGrid headerLabel="LABEL_ACCOUNT_REGISTRATION_CREATE_ACCOUNT_HEADER">
                    <Translation>
                        {(t) => (
                            <>
                                <div className="margin-medium">
                                    <RegisterEmailForm
                                        initialValues={{email: email}}
                                        onEmailChange={e => setEmail(e.target.value)}
                                        onSubmit={() => handleNextButton(email)}
                                        isLoading={showSpinner}
                                        errorMessage={errorMessage} />
                                </div>
                                <Footnote
                                    localeKey="LABEL_ACCOUNT_REGISTRATION_TERMS_CONDITIONS_FOOTNOTE"
                                    href={[ownerCenterLink, privacyLink]}
                                    className="stat-text-link"
                                    dataDtmLink={["enter email", "enter email"]}
                                />
                            </>
                        )}
                    </Translation>
                </CenterGrid>
            );
        }
    } else {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        authData: state.Authentication
    }
}

export default connect(mapStateToProps)(RegisterEmail);
