/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_TEMPLATE_DATA_ASYNC = 'LOAD_TEMPLATE_DATA_ASYNC';
export const SET_VIN_ASYNC = 'SET_VIN_ASYNC';
export const UPDATE_VEHICLE_INFO_BY_URL = 'UPDATE_VEHICLE_INFO_BY_URL';
export const SET_VIN_SEARCH = 'SET_VIN_SEARCH';
export const UPDATE_YEAR_MAKE_MODEL = 'UPDATE_YEAR_MAKE_MODEL';
export const SET_YEAR_MAKE_MODEL = 'SET_YEAR_MAKE_MODEL';
export const UPDATE_VEHICLE_INFO = 'UPDATE_VEHICLE_INFO';
export const RESET_VEHICLE_INFO = 'RESET_VEHICLE_INFO';

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';
export const UPDATE_VEHICLE_INFO_BY_DATA = 'UPDATE_VEHICLE_INFO_BY_DATA';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_TEMPLATE_DATA_ASYNC_FAIL = 'LOAD_TEMPLATE_DATA_ASYNC_FAIL';
export const SET_VIN_ASYNC_FAIL = 'SET_VIN_ASYNC_FAIL';
export const SET_TEMPLATE_DATA_FAIL = 'SET_TEMPLATE_DATA_FAIL';
export const SET_VIN_SEARCH_FAIL = 'SET_VIN_SEARCH_FAIL';