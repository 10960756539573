import * as type from './HomeCarouselActionType';

/**************************************************************/
// Actions to load data into the HomeCarouselReducer state
/**************************************************************/
export const loadHomeCarouselDataAsync = () => {
    const action = {
        type: type.LOAD_HOME_CAROUSEL_DATA_ASYNC
    }
    return action;
}

/**************************************************************/
// Actions to set data (handle in Reducer switch statement) 
/**************************************************************/
export const setHomeCarouselData = (carouselAssets) => {
    const action = {
        type: type.SET_HOME_CAROUSEL_DATA,
        carouselAssets: carouselAssets,
    }
    return action;
}

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadHomeCarouselDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_HOME_CAROUSEL_DATA_ASYNC_FAIL,
        error
    }
    return action;
}

export const setHomeCarouselDataFail = (error) => {
    const action = {
        type: type.SET_HOME_CAROUSEL_DATA_FAIL,
        error
    }
    return action;
}

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const playVideo = (videoId) => ({
    type: type.PLAY_VIDEO,
    payload: { videoId: videoId }
});

export const stopVideo = (videoId) => ({
    type: type.STOP_VIDEO,
    payload: { videoId: videoId }
});