import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Store from "../core/Redux/Store";
import { BrowserRouter } from "react-router-dom";
import "../core/i18n/i18n";
import AppSettings from "../core/AppSettings";
import ErrorBoundary from "../shared/ErrorBoundary/ErrorBoundary";
import Main from "../modules/App/Main";

const JEST_ACTIVE = process.env.JEST_WORKER_ID !== undefined;

async function bootstrap() {
    ReactDOM.render(
        <Provider store={Store}>
            <BrowserRouter basename={AppSettings.basename}>
                <ErrorBoundary>
                    <Main />
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>,
        document.getElementById("root")
    );
}

bootstrap();
