/**************************************************************/
// Actions to load data asynchronously (add watchers to RootSaga.js)
/**************************************************************/
export const LOAD_VEHICLE_COMBINATIONS_ASYNC = "LOAD_VEHICLE_COMBINATIONS_ASYNC";
export const LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC = "LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC";

/**************************************************************/
// Actions to set data (handle in FitmentCheckReducer switch statement) 
/**************************************************************/
export const SET_VEHICLE_COMBINATIONS = 'SET_VEHICLE_COMBINATIONS';
export const SET_FITMENT_CHECK = "SET_FITMENT_CHECK";
export const SET_VEHICLE_FITMENT = "SET_VEHICLE_FITMENT";

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
export const CLOSE_FITMENT_MODAL = "CLOSE_FITMENT_MODAL";
export const SHOW_FITMENT_MODAL = "SHOW_FITMENT_MODAL";
export const RETURN_TO_SEARCH = "RETURN_TO_SEARCH";
export const SELECT_BODY = 'SELECT_BODY';
export const SELECT_WHEEL = 'SELECT_WHEEL';
export const SELECT_TRIM = 'SELECT_TRIM';
export const SELECT_DRIVE = 'SELECT_DRIVE';
export const SELECT_ENGINE = 'SELECT_ENGINE';

/**************************************************************/
// Actions to handle errors when loading or setting data
/**************************************************************/
export const LOAD_VEHICLE_COMBINATIONS_ASYNC_FAIL = "LOAD_VEHICLE_COMBINATIONS_ASYNC_FAIL";
export const LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC_FAIL = "LOAD_FITMENT_CHECK_AND_OPEN_MODAL_ASYNC_FAIL"
export const SET_VEHICLE_COMBINATIONS_FAIL = 'SET_VEHICLE_COMBINATIONS_FAIL';
export const SET_FITMENT_CHECK_FAIL = "SET_FITMENT_CHECK_FAIL";