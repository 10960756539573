import React, { Component } from "react";
import { connect } from "react-redux";
import VehicleSelectModal from "../VehicleSelect/VehicleSelectModal/VehicleSelectModal";
import { TOGGLE_MODAL, SET_CLP_REDIRECT } from "../VehicleSelect/VehicleSelectRedux/VehicleSelectActionType";
import { setCategory } from "../../shared/SearchLink/SearchLinkRedux/SearchLinkActionCreator";
import { selectedCategoryTitle } from "../../modules/Home/HomeCategories/HomeCategoriesRedux/HomeCategoriesActionCreator";
import { Translation } from "react-i18next";
import AppSettings from "../../core/AppSettings";
import vaultConstants from "../../../config/vault_constants";

/**
 * Shared Vehicle Header component for to show what vehicle currently shopping. Currently being used on PSR, PDP, CLP
 * @param props
 * bodyAndWheel: String. Used to show what bodyAndWheel youre working with
 * bodyStyle: String. Used to show what bodyStyle youre working with
 * driveType: String. Used to show what driveType youre working with
 * engineBase: String. Used to show what engineBase youre working with
 * make: String. Used to show what make youre working with
 * model: String. Used to show what model youre working with
 * wheelBase: String. Used to show what wheelBase youre working with
 * year: String. Used to show what year youre working with
 * trim: String. Used to show what trim youre working with
 * searchData: Object. If searchData comes with 0 objects in search results then renders search results not present
 * showVehicleInfo: Boolean. True if want to show header on the page. False if not
 * vehicleConfig: Object. Shows whole fitment info in object
 * catId: String. Currently being used if we need to redirect on CLP then we will need category information
 * catName: String. Currently being used if we need to redirect on CLP then we will need category information
 * @return {JSX.Element}
 * @constructor
 */

class VehicleHeader extends Component {
    constructor(props) {
        super(props);

        if (props.SWOFFlag) {
            this.state = { vehicleInfo: {} };
        }
    }

    //data call will often return "-" as empty, need to account for that
    isValidProperty(property) {
        if (!property || property === "-") {
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.SWOFFlag) {
            if (this.props.searchData !== prevProps.searchData) {
                if (this.props.searchData?.vehicleInfo) {
                    this.setState({
                        vehicleInfo: this.props.searchData.vehicleInfo
                    });
                }
            }
        }
    }

    render() {
        // Show if search results are present
        if (this.props.SWOFFlag) {
            // Show if search results are present
            let vehicleInfo = this.state.vehicleInfo;
            const hasVehicleInfo = vehicleInfo.length;
            if (hasVehicleInfo) {
                vehicleInfo = vehicleInfo[0];
            }

            const year = this.props.year || vehicleInfo?.year || "";
            const make = this.props.make || vehicleInfo?.make || "";
            const model = this.props.model || vehicleInfo?.model || "";
            const body = this.props.vehicleConfig.body || vehicleInfo?.body || "";
            const bodyStyle = this.props.bodyStyle || vehicleInfo?.bodyStyle || "";
            const bodyAndWheel = this.props.bodyAndWheel || vehicleInfo?.bodyAndWheel || "";
            const trim = this.props.trim || vehicleInfo?.trim || "";
            const driveType = this.props.driveType || vehicleInfo?.driveType || "";
            const wheelBase = this.props.wheelBase || vehicleInfo?.wheelBase || "";
            const engineBase = this.props.engineBase || vehicleInfo?.engine || "";

            var vehicleHeaderDatadtm =
                "currently shopping:" + year + " " + make.toLowerCase() + " " + model.toLowerCase();

            if (this.props.showVehicleInfo) {
                return (
                    <Translation>
                        {(t) => (
                            <div className="ac-vehicle-header">
                                <div className="ac-static-vehicle-heading">{t("LABEL_SEARCH_CURRENTLY_SHOPPING")}:</div>
                                <div>
                                    <b>
                                        <div className="q-display3 ac-current-vehicle">
                                            {year} {make.toUpperCase()} {model.toUpperCase()}
                                        </div>
                                    </b>
                                    {/* VIN 2017 or newer */}
                                    {bodyStyle && bodyAndWheel && (
                                        <div className="q-text">
                                            {this.isValidProperty(bodyAndWheel) ? bodyAndWheel : ""}{" "}
                                            {this.isValidProperty(trim) ? ": " + trim : ""}{" "}
                                            {this.isValidProperty(driveType) ? ": " + driveType : ""}
                                        </div>
                                    )}
                                    {/* VIN pre 2017 */}
                                    {bodyStyle && !bodyAndWheel && (
                                        <div className="q-text">
                                            {this.isValidProperty(bodyStyle) ? bodyStyle : ""}{" "}
                                            {this.isValidProperty(wheelBase) ? ": " + wheelBase : ""}{" "}
                                            {this.isValidProperty(trim) ? ": " + trim : ""}{" "}
                                            {this.isValidProperty(driveType) ? ": " + driveType : ""}{" "}
                                            {this.isValidProperty(engineBase) ? ": " + engineBase : ""}
                                        </div>
                                    )}
                                    {/* This should show for just vehicles searched by YMM */}
                                    {!bodyStyle && body && (
                                        <div className="q-text">
                                            {this.isValidProperty(body) ? body : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.wheel)
                                                ? ": " + this.props.vehicleConfig.wheel
                                                : ""}{" "}
                                            {this.isValidProperty(trim) ? ": " + trim : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.drive)
                                                ? ": " + this.props.vehicleConfig.drive
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.engine)
                                                ? ": " + this.props.vehicleConfig.engine
                                                : ""}
                                        </div>
                                    )}
                                </div>
                                {this.props.catName !== undefined ? (
                                    <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                        <a
                                            className="stat-text-link ac-accessible-link"
                                            data-dtm={vehicleHeaderDatadtm}
                                            onClick={() =>
                                                this.props.handleVehicleChangeClp(this.props.catName, this.props.catId)
                                            }
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                        <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                    </div>
                                ) : (
                                    <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                        <a
                                            className="stat-text-link ac-accessible-link"
                                            data-dtm={vehicleHeaderDatadtm}
                                            onClick={() => this.props.handleVehicleChange()}
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                        <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                    </div>
                                )}
                            </div>
                        )}
                    </Translation>
                );
            }
            // Show if search results are not present
            else {
                return (
                    <Translation>
                        {(t) => (
                            <div className="ac-vehicle-header stat-text-link" data-dtm={vehicleHeaderDatadtm}>
                                <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                    <h5>
                                        <a
                                            className="ac-accessible-link"
                                            onClick={() => this.props.handleVehicleChange()}
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                    </h5>
                                    <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                </div>
                            </div>
                        )}
                    </Translation>
                );
            }
        } else {
            // Show if search results are present
            var vehicleHeaderDatadtm =
                "currently shopping:" +
                (this.props.year ? this.props.year : "") +
                " " +
                (this.props.make ? this.props.make.toLowerCase() : "") +
                " " +
                (this.props.model ? this.props.model.toLowerCase() : "");

            if (this.props.showVehicleInfo) {
                return (
                    <Translation>
                        {(t) => (
                            <div className="ac-vehicle-header">
                                <div className="ac-static-vehicle-heading">{t("LABEL_SEARCH_CURRENTLY_SHOPPING")}:</div>
                                <div>
                                    <b>
                                        <div className="q-display3 ac-current-vehicle">
                                            {this.props.year} {this.props.make ? this.props.make.toUpperCase() : ""}{" "}
                                            {this.props.model ? this.props.model.toUpperCase() : ""}
                                        </div>
                                    </b>
                                    {/* VIN 2017 or newer */}
                                    {this.props.bodyStyle && this.props.bodyAndWheel && (
                                        <div className="q-text">
                                            {this.isValidProperty(this.props.bodyAndWheel)
                                                ? this.props.bodyAndWheel
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.trim) ? ": " + this.props.trim : ""}{" "}
                                            {this.isValidProperty(this.props.driveType)
                                                ? ": " + this.props.driveType
                                                : ""}
                                        </div>
                                    )}
                                    {/* VIN pre 2017 */}
                                    {this.props.bodyStyle && !this.props.bodyAndWheel && (
                                        <div className="q-text">
                                            {this.isValidProperty(this.props.bodyStyle) ? this.props.bodyStyle : ""}{" "}
                                            {this.isValidProperty(this.props.wheelBase)
                                                ? ": " + this.props.wheelBase
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.trim) ? ": " + this.props.trim : ""}{" "}
                                            {this.isValidProperty(this.props.driveType)
                                                ? ": " + this.props.driveType
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.engineBase)
                                                ? ": " + this.props.engineBase
                                                : ""}
                                        </div>
                                    )}
                                    {/* This should show for just vehicles searched by YMM */}
                                    {!this.props?.bodyStyle && this.props?.vehicleConfig?.body && (
                                        <div className="q-text">
                                            {this.isValidProperty(this.props.vehicleConfig.body)
                                                ? this.props.vehicleConfig.body
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.wheel)
                                                ? ": " + this.props.vehicleConfig.wheel
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.trim)
                                                ? ": " + this.props.vehicleConfig.trim
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.drive)
                                                ? ": " + this.props.vehicleConfig.drive
                                                : ""}{" "}
                                            {this.isValidProperty(this.props.vehicleConfig.engine)
                                                ? ": " + this.props.vehicleConfig.engine
                                                : ""}
                                        </div>
                                    )}
                                </div>
                                {this.props.catName !== undefined ? (
                                    <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                        <a
                                            className="stat-text-link ac-accessible-link"
                                            data-dtm={vehicleHeaderDatadtm}
                                            onClick={() =>
                                                this.props.handleVehicleChangeClp(this.props.catName, this.props.catId)
                                            }
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                        <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                    </div>
                                ) : (
                                    <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                        <a
                                            className="stat-text-link ac-accessible-link"
                                            data-dtm={vehicleHeaderDatadtm}
                                            onClick={() => this.props.handleVehicleChange()}
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                        <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                    </div>
                                )}
                            </div>
                        )}
                    </Translation>
                );
            }
            // Show if search results are not present
            else {
                return (
                    <Translation>
                        {(t) => (
                            <div className="ac-vehicle-header stat-text-link" data-dtm={vehicleHeaderDatadtm}>
                                <div className="ac-ymm-modal-container stat-text-link" data-dtm="subheader">
                                    <h5>
                                        <a
                                            className="ac-accessible-link"
                                            onClick={() => this.props.handleVehicleChange()}
                                        >
                                            {" "}
                                            {t("LABEL_COMMON_CHANGE_VEHICLE")}
                                        </a>
                                    </h5>
                                    <VehicleSelectModal headerLabel={"LABEL_COMMON_CHANGE_VEHICLE"} />
                                </div>
                            </div>
                        )}
                    </Translation>
                );
            }
        }
    }
}

/**************************************************************/
//          Map State to Props
/**************************************************************/
function mapStateToProps(state) {
    // State comes from store
    // Return value goes to props

    return {
        searchData: state.SearchServiceReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleVehicleChangeClp: (catName, catId) => {
            dispatch(setCategory(catName, catId));
            dispatch(selectedCategoryTitle(catName));
            dispatch({ type: TOGGLE_MODAL });
            dispatch({ type: SET_CLP_REDIRECT, category: catName });
        },
        handleVehicleChange: () => {
            dispatch({ type: TOGGLE_MODAL });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleHeader);
