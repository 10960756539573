import * as type from "./VehicleConfigActionType";
import {
    addDriveFitmentSelection,
    addEngineFitmentSelection,
    addTrimFitmentSelection,
    addWheelFitmentSelection
} from "./VehicleConfigUtils";
import store from "../../../core/Redux/Store";

/**************************************************************/
// Actions to load data into the FitmentReducer state
/**************************************************************/
export const loadVehicleCombosAsync = () => {
    const action = {
        type: type.LOAD_VEHICLE_COMBOS_ASYNC
    };
    return action;
};

/**************************************************************/
// Actions to set data (handle in Reducer switch statement)
/**************************************************************/
export const setVehicleCombinations = (combinations, parsedCombinations) => {
    const action = {
        type: type.SET_VEHICLE_CONFIG_COMBINATIONS,
        combinations: combinations,
        parsedCombinations: parsedCombinations
    };
    return action;
};

export const setVehicleFitment = (data) => {
    return {
        type: type.SET_VEHICLE_FITMENT,
        data
    };
};

export const resetVehicleConfig = () => {
    const action = {
        type: type.RESET_VEHICLE_CONFIG_STATE
    };
    return action;
};

export const disableBtn = () => {
    const action = {
        type: type.DISABLE_BTN
    };
    return action;
};

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadVehicleCombosAsyncFail = (error) => {
    const action = {
        type: type.LOAD_VEHICLE_COMBOS_ASYNC_FAIL,
        error
    };
    return action;
};

export const setVehicleCombinationsFail = (error) => {
    const action = {
        type: type.SET_VEHICLE_CONFIG_COMBINATIONS_FAIL,
        error
    };
    return action;
};

/**************************************************************/
// Actions to handle events triggered by a user
/**************************************************************/
//These select actions filter the json further based on user selection.
//Params are being passed in from the FitmentCheckForm.js mapDispatchToProps function
export const selectBody = (
    selectedBodyValues,
    fitmentVehicleInfo,
    vehicleParams,
    vehicleCombinations,
    dropdownLabel,
    bodyFitmentSelection
) => {
    fitmentVehicleInfo = store.getState().VehicleConfigReducer.fitmentVehicleInfo;
    vehicleParams = store.getState().VehicleConfigReducer.vehicleParams;
    vehicleCombinations = store.getState().VehicleConfigReducer.vehicleCombinations;
    bodyFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.bodyFitmentSelection;
    // console.warn('selectBody:fitmentVehicleInfo', fitmentVehicleInfo);
    // console.warn('selectBody:vehicleParams', vehicleParams);
    // console.warn('selectBody:vehicleCombinations', vehicleCombinations);
    // console.warn('selectBody:bodyFitmentSelection', bodyFitmentSelection);

    // Checking if initial vehicle body info is being passed in.
    let bodyId;
    let bodyNumDoors;
    let bodyStyle;

    // Dropdown option contains BODY_TYPE_ID, while pre-population info does not
    if (selectedBodyValues.BODY_TYPE_ID) {
        bodyId = selectedBodyValues.BODY_TYPE_ID;
        bodyNumDoors = selectedBodyValues.BODY_NUM_DOORS_ID;
        bodyStyle = selectedBodyValues.BODY_TYPE_NAME;
    } else {
        bodyId = selectedBodyValues.bodyId;
        bodyNumDoors = selectedBodyValues.bodyNumDoors || selectedBodyValues.bodyNumDoorsId;
        bodyStyle = selectedBodyValues.body;
    }

    let tempFitmentCombination = [];
    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (vehicleCombinations[index].BODY_TYPE_ID == bodyId) {
            tempFitmentCombination.push(vehicleCombinations[index]);
        }
    }

    let wheelFitmentSelection = [];
    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];

        addWheelFitmentSelection(record, wheelFitmentSelection);
        addTrimFitmentSelection(record, trimFitmentSelection);
        addDriveFitmentSelection(record, driveFitmentSelection);
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let wheelParam = "";
    let trimParam = "";
    let driveParam = "";
    let engineParam = "";

    if (wheelFitmentSelection.length == 1) {
        wheelParam = wheelFitmentSelection[0].WHEEL_BASE_ID;
    }
    if (trimFitmentSelection.length == 1) {
        trimParam = trimFitmentSelection[0].SUBMODEL_ID;
    }
    if (driveFitmentSelection.length == 1) {
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_BODY,

        vehicleParams: {
            bodyNumDoorsParam: bodyNumDoors || "",
            bodyParam: bodyId || "",
            wheelParam: wheelParam || "",
            trimParam: trimParam || "",
            driveParam: driveParam || "",
            engineParam: engineParam || ""
        },
        fitmentVehicleInfo: {
            bodyStyle: bodyStyle || "",
            bodyStyleId: bodyId || "",
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: fitmentVehicleInfo.driveType,
            driveTypeId: fitmentVehicleInfo.driveTypeId,
            engine: fitmentVehicleInfo.engine,
            engineId: fitmentVehicleInfo.engineId
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection
    };

    return vehicleInfo;
};

export const selectWheel = (
    selectedWheelValues,
    fitmentVehicleInfo,
    vehicleParams,
    vehicleCombinations,
    bodyFitmentSelection,
    wheelFitmentSelection
) => {
    fitmentVehicleInfo = store.getState().VehicleConfigReducer.fitmentVehicleInfo;
    vehicleParams = store.getState().VehicleConfigReducer.vehicleParams;
    vehicleCombinations = store.getState().VehicleConfigReducer.vehicleCombinations;
    bodyFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.bodyFitmentSelection;
    wheelFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.wheelFitmentSelection;
    // console.warn('selectWheel:fitmentVehicleInfo', fitmentVehicleInfo);
    // console.warn('selectWheel:vehicleParams', vehicleParams);
    // console.warn('selectWheel:vehicleCombinations', vehicleCombinations);
    // console.warn('selectWheel:bodyFitmentSelection', bodyFitmentSelection);
    // console.warn('selectWheel:wheelFitmentSelection', wheelFitmentSelection);

    let wheelBase;
    let wheelId;
    // Dropdown option contains WHEEL_BASE_OVERRIDE, while pre-population info does not
    if (selectedWheelValues.WHEEL_BASE) {
        wheelBase = selectedWheelValues.WHEEL_BASE_OVERRIDE;
        if (wheelBase === null || wheelBase === "") {
            wheelBase = selectedWheelValues.WHEEL_BASE;
        }
    } else {
        wheelBase = selectedWheelValues.wheel;
    }
    if (selectedWheelValues.WHEEL_BASE_ID) {
        wheelId = selectedWheelValues.WHEEL_BASE_ID;
    } else {
        wheelId = selectedWheelValues.wheelId;
    }

    let { bodyParam } = vehicleParams;
    let tempFitmentCombination = [];
    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (isEmpty(bodyParam)) {
            if (vehicleCombinations[index].WHEEL_BASE_ID == wheelId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else {
            if (
                vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].WHEEL_BASE_ID == wheelId
            ) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        }
    }

    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];

        addTrimFitmentSelection(record, trimFitmentSelection);
        addDriveFitmentSelection(record, driveFitmentSelection);
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let trimParam = "";
    let driveParam = "";
    let engineParam = "";
    if (trimFitmentSelection.length == 1) {
        // && showTrimDropdown == true
        trimParam = trimFitmentSelection[0].SUBMODEL_ID;
    }

    if (driveFitmentSelection.length == 1) {
        //&& driveDropdown == true
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }

    if (engineFitmentSelection.length == 1) {
        //&& showEngineDropdown == true
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_WHEEL,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || "",
            bodyParam: bodyParam || "",
            wheelParam: wheelId || "",
            trimParam: trimParam || "",
            driveParam: driveParam || "",
            engineParam: engineParam || ""
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle,
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBaseId: wheelId || "",
            wheelBase: wheelBase || "",
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: fitmentVehicleInfo.driveType,
            driveTypeId: fitmentVehicleInfo.driveTypeId,
            engine: fitmentVehicleInfo.engine,
            engineId: fitmentVehicleInfo.engineId,
            modelId: fitmentVehicleInfo.modelId
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection
    };

    return vehicleInfo;
};

export const selectTrim = (
    selectedTrimValues,
    fitmentVehicleInfo,
    vehicleParams,
    vehicleCombinations,
    bodyFitmentSelection,
    wheelFitmentSelection,
    trimFitmentSelection
) => {
    fitmentVehicleInfo = store.getState().VehicleConfigReducer.fitmentVehicleInfo;
    vehicleParams = store.getState().VehicleConfigReducer.vehicleParams;
    vehicleCombinations = store.getState().VehicleConfigReducer.vehicleCombinations;
    bodyFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.bodyFitmentSelection;
    wheelFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.wheelFitmentSelection;
    trimFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.trimFitmentSelection;
    // console.warn('selectTrim:fitmentVehicleInfo', fitmentVehicleInfo);
    // console.warn('selectTrim:vehicleParams', vehicleParams);
    // console.warn('selectTrim:vehicleCombinations', vehicleCombinations);
    // console.warn('selectTrim:bodyFitmentSelection', bodyFitmentSelection);
    // console.warn('selectTrim:wheelFitmentSelection', wheelFitmentSelection);
    // console.warn('selectTrim:trimFitmentSelection', trimFitmentSelection);

    // Dropdown option contains SUBMODEL_ID, while pre-population info does not
    let trimId;
    let trimName;
    if (selectedTrimValues.SUBMODEL_ID) {
        trimId = selectedTrimValues.SUBMODEL_ID;
        trimName = selectedTrimValues.SUBMODEL_NAME;
    } else {
        trimId = selectedTrimValues.trimId;
        trimName = selectedTrimValues.trim;
    }

    let tempFitmentCombination = [];
    let { bodyParam, wheelParam } = vehicleParams;

    for (let index = 0; index < vehicleCombinations.length; index++) {
        if (isEmpty(bodyParam) && isEmpty(wheelParam)) {
            if (vehicleCombinations[index].SUBMODEL_ID == trimId) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else if (isNotEmpty(bodyParam) && isEmpty(wheelParam)) {
            if (
                vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId
            ) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else if (isEmpty(bodyParam) && isNotEmpty(wheelParam)) {
            //vehicleInfo.wheelParam <- this should just be from state?
            if (
                vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId
            ) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        } else {
            if (
                vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                vehicleCombinations[index].SUBMODEL_ID == trimId
            ) {
                tempFitmentCombination.push(vehicleCombinations[index]);
            }
        }
    }

    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    let records = tempFitmentCombination;
    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        let record = records[indexRecord];
        addDriveFitmentSelection(record, driveFitmentSelection);
        addEngineFitmentSelection(record, engineFitmentSelection);
    }

    let driveParam = "";
    let engineParam = "";
    if (driveFitmentSelection.length == 1) {
        driveParam = driveFitmentSelection[0].DRIVE_TYPE_ID;
    }
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_TRIM,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || "",
            bodyParam: vehicleParams.bodyParam || "",
            wheelParam: vehicleParams.wheelParam || "",
            trimParam: trimId || "",
            driveParam: driveParam || "",
            engineParam: engineParam || ""
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle,
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: trimName || "",
            trimId: trimId || "",
            driveType: fitmentVehicleInfo.driveType,
            driveTypeId: fitmentVehicleInfo.driveTypeId,
            engine: fitmentVehicleInfo.engine,
            engineId: fitmentVehicleInfo.engineId,
            modelId: fitmentVehicleInfo.modelId
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection
    };

    return vehicleInfo;
};

export const selectDrive = (
    selectedDriveValues,
    fitmentVehicleInfo,
    vehicleParams,
    vehicleCombinations,
    bodyFitmentSelection,
    wheelFitmentSelection,
    trimFitmentSelection,
    driveFitmentSelection
) => {
    fitmentVehicleInfo = store.getState().VehicleConfigReducer.fitmentVehicleInfo;
    vehicleParams = store.getState().VehicleConfigReducer.vehicleParams;
    vehicleCombinations = store.getState().VehicleConfigReducer.vehicleCombinations;
    bodyFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.bodyFitmentSelection;
    wheelFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.wheelFitmentSelection;
    trimFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.trimFitmentSelection;
    driveFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.driveFitmentSelection;
    // console.warn('selectDrive:fitmentVehicleInfo', fitmentVehicleInfo);
    // console.warn('selectDrive:vehicleParams', vehicleParams);
    // console.warn('selectDrive:vehicleCombinations', vehicleCombinations);
    // console.warn('selectDrive:bodyFitmentSelection', bodyFitmentSelection);
    // console.warn('selectDrive:wheelFitmentSelection', wheelFitmentSelection);
    // console.warn('selectDrive:trimFitmentSelection', trimFitmentSelection);
    // console.warn('selectDrive:driveFitmentSelection', driveFitmentSelection);

    // Dropdown option contains DRIVE_TYPE_NAME, while pre-population info does not
    let driveType;
    let driveId;
    if (selectedDriveValues.DRIVE_TYPE_NAME) {
        driveType = selectedDriveValues.DRIVE_TYPE_NAME;
        driveId = selectedDriveValues.DRIVE_TYPE_ID;
    } else {
        driveType = selectedDriveValues.drive;
        driveId = selectedDriveValues.driveId;
    }

    let tempFitmentCombination = [];
    let { bodyParam, wheelParam, trimParam } = vehicleParams;
    for (let index = 0; index < vehicleCombinations.length; index++) {
        //skip the combo if it's not even this driveId
        if (vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
            if (isEmpty(bodyParam) && isEmpty(wheelParam) && isEmpty(trimParam)) {
                if (vehicleCombinations[index].DRIVE_TYPE_ID == driveId) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isNotEmpty(bodyParam) && isEmpty(wheelParam) && isEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID === driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isEmpty(bodyParam) && isNotEmpty(wheelParam) && isEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].WHEEL_BASE_ID === wheelParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isEmpty(bodyParam) && isEmpty(wheelParam) && isNotEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isNotEmpty(bodyParam) && isNotEmpty(wheelParam) && isEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isEmpty(bodyParam) && isNotEmpty(wheelParam) && isNotEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else if (isNotEmpty(bodyParam) && isEmpty(wheelParam) && isNotEmpty(trimParam)) {
                if (
                    vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            } else {
                if (
                    vehicleCombinations[index].BODY_TYPE_ID == bodyParam &&
                    vehicleCombinations[index].WHEEL_BASE_ID == wheelParam &&
                    vehicleCombinations[index].SUBMODEL_ID == trimParam &&
                    vehicleCombinations[index].DRIVE_TYPE_ID == driveId
                ) {
                    tempFitmentCombination.push(vehicleCombinations[index]);
                }
            }
        }
    }

    let engineFitmentSelection = [];
    let records = tempFitmentCombination;

    for (let indexRecord = 0; indexRecord < records.length; indexRecord++) {
        addEngineFitmentSelection(records[indexRecord], engineFitmentSelection);
    }

    let engineParam = "";
    if (engineFitmentSelection.length == 1) {
        engineParam = engineFitmentSelection[0].ENGINE_BASE_ID;
    }

    let vehicleInfo = {
        type: type.SELECT_DRIVE,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || "",
            bodyParam: vehicleParams.bodyParam || "",
            wheelParam: vehicleParams.wheelParam || "",
            trimParam: vehicleParams.trimParam || "",
            driveParam: driveId || "",
            engineParam: engineParam || ""
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle,
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: driveType || "",
            driveTypeId: driveId || "",
            engine: fitmentVehicleInfo.engine,
            engineId: fitmentVehicleInfo.engineId,
            modelId: fitmentVehicleInfo.modelId
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection
    };

    return vehicleInfo;
};

export const selectEngine = (
    selectedEngineValues,
    fitmentVehicleInfo,
    vehicleParams,
    bodyFitmentSelection,
    wheelFitmentSelection,
    trimFitmentSelection,
    driveFitmentSelection,
    engineFitmentSelection
) => {
    fitmentVehicleInfo = store.getState().VehicleConfigReducer.fitmentVehicleInfo;
    vehicleParams = store.getState().VehicleConfigReducer.vehicleParams;
    bodyFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.bodyFitmentSelection;
    wheelFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.wheelFitmentSelection;
    trimFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.trimFitmentSelection;
    driveFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.driveFitmentSelection;
    engineFitmentSelection = store.getState().VehicleConfigReducer.fitmentSelections.engineFitmentSelection;
    // console.warn('selectDrive:fitmentVehicleInfo', fitmentVehicleInfo);
    // console.warn('selectDrive:vehicleParams', vehicleParams);
    // console.warn('selectDrive:bodyFitmentSelection', bodyFitmentSelection);
    // console.warn('selectDrive:wheelFitmentSelection', wheelFitmentSelection);
    // console.warn('selectDrive:trimFitmentSelection', trimFitmentSelection);
    // console.warn('selectDrive:driveFitmentSelection', driveFitmentSelection);
    // console.warn('selectDrive:engineFitmentSelection', engineFitmentSelection);

    // Dropdown option contains ENGINE_LABEL, while pre-population info does not
    let engine;
    let engineId;
    if (selectedEngineValues.ENGINE_LABEL) {
        engine = selectedEngineValues.ENGINE_LABEL;
        engineId = selectedEngineValues.ENGINE_BASE_ID;
    } else {
        engine = selectedEngineValues.engine;
        engineId = selectedEngineValues.engineId;
    }

    let vehicleInfo = {
        type: type.SELECT_ENGINE,

        vehicleParams: {
            bodyNumDoorsParam: vehicleParams.bodyNumDoorsParam || "",
            bodyParam: vehicleParams.bodyParam || "",
            wheelParam: vehicleParams.wheelParam || "",
            trimParam: vehicleParams.trimParam || "",
            driveParam: vehicleParams.driveParam || "",
            engineParam: engineId || ""
        },
        fitmentVehicleInfo: {
            bodyStyle: fitmentVehicleInfo.bodyStyle,
            bodyStyleId: fitmentVehicleInfo.bodyStyleId,
            wheelBase: fitmentVehicleInfo.wheelBase,
            wheelBaseId: fitmentVehicleInfo.wheelBaseId,
            trim: fitmentVehicleInfo.trim,
            trimId: fitmentVehicleInfo.trimId,
            driveType: fitmentVehicleInfo.driveType,
            driveTypeId: fitmentVehicleInfo.driveTypeId,
            engine: engine || "",
            engineId: engineId || "",
            modelId: fitmentVehicleInfo.modelId
        },
        bodyFitmentSelection: bodyFitmentSelection,
        wheelFitmentSelection: wheelFitmentSelection,
        trimFitmentSelection: trimFitmentSelection,
        driveFitmentSelection: driveFitmentSelection,
        engineFitmentSelection: engineFitmentSelection
    };

    return vehicleInfo;
};

function isEmpty(value) {
    return value == "" || value == undefined;
}

function isNotEmpty(value) {
    return value != "" && value != undefined;
}
