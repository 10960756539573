
import React, { Component } from 'react';
import { Translation } from 'react-i18next';

export class MaintenanceTab extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let maintenanceObject = []; 
        let maintenanceData = this.props.maintenanceData;
        
        if (maintenanceData !== undefined) {
            maintenanceData.map((item) => {
                maintenanceObject.push(item);
            })
        };
        return(
            <Translation>
            {(t) => (
            <div className="ac-tabContent"><h2 className="q-headline2">{t(this.props.maintenanceTitle)}</h2>
                <h5 className="q-text">
                    {maintenanceObject.map((item, index) => {
                        return <li key={index}>{item}</li>
                    })
                    }
                </h5>
            </div>
            )}
            </Translation>
        );
    }
}

export default MaintenanceTab
