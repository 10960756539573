import axios from "axios";
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { change, clearFields } from 'redux-form';
import AppSettings from '../../../../../../core/AppSettings';
import store from '../../../../../../core/Redux/Store';
import { disableRewardsInput } from '../../../../../../shared/Rewards/RewardsRedux/RewardsActionCreator';
import { loadOrderInfoDataAsync } from '../../../../../../shared/OrderInfo/OrderInfoRedux/OrderInfoCreator';
import { parseCartResponse } from '../../../../../../shared/OrderInfo/OrderInfoRedux/OrderInfoWorker';
import { deliveryMethodShowSpinner, deliveryPageFailTax, getCouponData } from '../../../../CheckoutRedux/CheckoutActionCreator';
import { taxError } from '../../../../CheckoutRedux/CheckoutWorker';
import {checkPartialAuthenticationError} from "../../../../../../shared/Utils/Utils";

// Error messages for redux-form
export const DELIVERY_METHOD_FIELD_ERROR_DATA = 'update order item failed response data errors';
export const DELIVERY_METHOD_FIELD_ERROR_NO_DATA = 'update order item failed no response data';
export const DELIVERY_METHOD_FIELD_ERROR_REQUEST_STATUS_CODE = 'update order item failed: request failed with bad status code';
export const DELIVERY_METHOD_FIELD_ERROR_REQUEST_FAILED = 'update order item failed request failed with error';
export const DELIVERY_METHOD_FIELD_ERROR_ORDER_SUMMARY = 'update order item failed unable to update order summary';
export const DELIVERY_METHOD_FIELD_ERROR_DELETE_ORDER_ITEM = 'delete order item failed';
export const DELIVERY_METHOD_FIELD_ERROR_DELETE_ORDER_ITEM_REQUEST_STATUS_CODE = 'delete order item failed: request failed with bad status code';
export const DELIVERY_METHOD_FIELD_ERROR_INSTALL_ORDER_ITEM_NOT_FOUND = 'install order item not found';

// Install values (needed for special handling of install order item)
const INSTALL_SHIP_MODE_ID = '13202'; // May be inconsistent across environments.
const INSTALL_ORDER_ITEM_TYPE = 'Installed';
const INSTALL_CARRIER_CODE = 'INSTALL'

// Service URLs
const updateOrderItemURL = '/wcs/resources/store/' + AppSettings.storeId + '/GMCart/@self/update_order_item?responseFormat=json'; //&testErrorFlow=true'
const gmDeleteOrderItemURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/delete_order_item?responseFormat=json`;

/**
 * validateDeliveryMethodFormField() returns a Promise that will resolve if 
 * field-level delivery method validation is passed or otherwise will reject 
 * with an object of validation  errors. 
 * 
 * There are three steps required to pass field-level validation. 
 * 
 * First, a check must be performed to determine if the new field value is 
 * replacing an install value. If this is true, then the install value must be 
 * deleted by calling deleteOrderItem() before validating the new value.
 * 
 * Next, updateOrderItem() must be successfully called in order to update the 
 * order with the user's delivery method selection. 
 * 
 * Finally, following a successful call to updateOrderItem(), 
 * getUpdatedOrderInfo() must also be successfully called so that the new 
 * values are reflected in the price summary.
 */
export const validateDeliveryMethodFormField = (values, dispatch, props, blurredField) => {
    // Parses the current field value and delivery form props in order to 
    // prepare the parameters necessary to call updateOrderItem().
    let deliveryOptionValues = JSON.parse(values[blurredField]);
    const shipModeId = deliveryOptionValues.shipModeId;
    const orderItemId = deliveryOptionValues.orderItemId;
    const quantity = deliveryOptionValues.quantity;
    const carrierCode = deliveryOptionValues.carrierCode;
    const productId = deliveryOptionValues.productId;
    const orderId = props.data.parsedResponse.orderId;
    const bac = AppSettings.bac;

    dispatch(deliveryMethodShowSpinner(true, orderItemId));
    dispatch(disableRewardsInput(true));
    return new Promise((resolve, reject) => {
        if (isReplacingInstall(blurredField, props.values)) {
            const itemsByVehicle = props.data.parsedResponse.vehicles;
            const installOrderItemId = getInstallOrderItemId(orderItemId, itemsByVehicle);
            if (installOrderItemId !== undefined) {
                deleteOrderItem(installOrderItemId, orderId, bac)
                    .then(response => {
                        if (response.status === 200) {
                            validate(dispatch, shipModeId, orderItemId, quantity,
                                carrierCode, orderId, bac, productId)
                                .then(() => {
                                    resolve();
                                    return;
                                })
                                .catch((error) => {
                                    reject(error);
                                    return;
                                });
                        } else {
                            handleDeleteInstallOrderItemFail(dispatch, blurredField, props.values, orderItemId);
                            reject(DELIVERY_METHOD_FIELD_ERROR_DELETE_ORDER_ITEM_REQUEST_STATUS_CODE);
                            return;
                        }
                    })
                    .catch(() => {
                        handleDeleteInstallOrderItemFail(dispatch, blurredField, props.values, orderItemId);
                        reject(DELIVERY_METHOD_FIELD_ERROR_DELETE_ORDER_ITEM);
                        return;
                    });
            } else {
                handleDeleteInstallOrderItemFail(dispatch, blurredField, props.values, orderItemId);
                reject(DELIVERY_METHOD_FIELD_ERROR_INSTALL_ORDER_ITEM_NOT_FOUND);
                return;
            }
        } else {
            // Validates new form value.
            validate(dispatch, shipModeId, orderItemId, quantity,
                carrierCode, orderId, bac, productId)
                .then(() => {
                    resolve();
                    return;
                })
                .catch((error) => {
                    reject(error);
                    return;
                });
        }
    });
}

/**
 * validate() returns a Promise that will resolve if field-level delivery 
 * method validation is passed or otherwise will reject with an object of 
 * validation errors.
 */
const validate = (dispatch, shipModeId, orderItemId, quantity, carrierCode, orderId, bac, productId) => {
    return new Promise((resolve, reject) => {
        updateOrderItem(shipModeId, orderItemId, quantity, carrierCode, orderId, bac, productId)
            .then(response => {
                if (response.status === 200) {
                    if (response.data) {
                        if (response.data.errors) {
                            handleUpdateOrderItemDataErrors(dispatch, response, orderItemId);
                            reject(DELIVERY_METHOD_FIELD_ERROR_DATA);
                            return;
                        }

                        handleUpdateOrderItemSuccess(dispatch, orderId, orderItemId)
                            .then(() => {
                                resolve();
                                return;
                            })
                            .catch(() => {
                                handleUpdateOrderItemFail(dispatch, orderItemId);
                                reject(DELIVERY_METHOD_FIELD_ERROR_ORDER_SUMMARY);
                                return;
                            });
                    } else {
                        handleUpdateOrderItemFail(dispatch, orderItemId);
                        reject(DELIVERY_METHOD_FIELD_ERROR_NO_DATA);
                        return;
                    }
                } else {
                    handleUpdateOrderItemFail(dispatch, orderItemId);
                    reject(DELIVERY_METHOD_FIELD_ERROR_REQUEST_STATUS_CODE);
                    return;
                }
            })
            .catch(() => {
                handleUpdateOrderItemFail(dispatch, orderItemId);
                reject(DELIVERY_METHOD_FIELD_ERROR_REQUEST_FAILED);
                return
            });
    });
}

/**
 * updateOrderItem() sends a POST request that updates the order item with the 
 * user's delivery method selection and then returns the response.
 */
const updateOrderItem = (shipModeId, orderItemId, quantity, carrierCode, orderId, bac, productId) => {

    let data = {
        x_action: carrierCode,
        x_type: carrierCode,
        x_calculateOrder: "1",
        orderId: orderId,
        orderItem: [{
            orderItemId: orderItemId,
            xitem_shipModeId: shipModeId,
            quantity: quantity.toString()
        }]
    };

    if (AppSettings.isT3) {
        data.x_bac = bac;
    }

    if (carrierCode === "INSTALL") {
        data.orderItem[0].productId = productId;
    }

    return axios.post(updateOrderItemURL, data);
}

/**
 * handleUpdateOrderItemSuccess() returns a Promise that will resolve if 
 * getUpdatedOrderInfo() is successfully called or otherwise will reject with 
 * an object of validation errors. getUpdatedOrderInfo() must be successfully 
 * called so that the user's new delivery method selection is reflected in the 
 * price summary.
 */
const handleUpdateOrderItemSuccess = (dispatch, orderId, orderItemId) => {
    dispatch(getCouponData(orderId));
    return new Promise((resolve) => {
        getUpdatedOrderInfo(dispatch, orderItemId)
            .then(() => {
                dispatch(deliveryMethodShowSpinner(false, orderItemId));
                dispatch(disableRewardsInput(false));
                resolve();
                return;
            })
            .catch(error => {
                reject(`update order info failed with error ${error}`);
                return;
            });
    })
}

/**
 * handleUpdateOrderItemDataErrors() 
 */
const handleUpdateOrderItemDataErrors = (dispatch, response, orderItemId) => {
    const field = `deliveryMethod-${orderItemId}`;

    // If the keepTouched parameter is true, the values of currently touched 
    // fields will be retained.
    const keepTouched = false;

    // If the persistentSubmitErrors parameter is true, the values of currently 
    // submit errors fields will be retained.
    const persistentSubmitErrors = true;

    if (taxError(response)) { // generic error
        dispatch(deliveryPageFailTax());
    }

    dispatch(clearFields('DeliveryForm', keepTouched, persistentSubmitErrors, field));
    dispatch(deliveryMethodShowSpinner(false, orderItemId));
    dispatch(disableRewardsInput(false));
}

/**
 * handleUpdateOrderItemFail() performs any cleanup necessary after a call to 
 * updateOrderItem() fails.
 */
const handleUpdateOrderItemFail = (dispatch, orderItemId) => {
    const field = `deliveryMethod-${orderItemId}`;

    // If the keepTouched parameter is true, the values of currently touched 
    // fields will be retained.
    const keepTouched = false;

    // If the persistentSubmitErrors parameter is true, the values of currently 
    // submit errors fields will be retained.
    const persistentSubmitErrors = true;

    dispatch(clearFields('DeliveryForm', keepTouched, persistentSubmitErrors, field));
    dispatch(deliveryMethodShowSpinner(false, orderItemId));
    dispatch(disableRewardsInput(false));
}

/**
 * handleDeleteInstallOrderItemFail() performs any cleanup necessary after an 
 * attempt to delete an install order item fails.
 */
const handleDeleteInstallOrderItemFail = (dispatch, blurredField, previousValues, orderItemId) => {
    let prevDeliveryOptionValues = INSTALL_SHIP_MODE_ID;
    if (blurredField in previousValues) {
        prevDeliveryOptionValues = previousValues[blurredField];
    }

    // If the keepTouched parameter is true, the values of currently touched 
    // fields will be retained.
    const keepTouched = false;

    // If the persistentSubmitErrors parameter is true, the values of currently 
    // submit errors fields will be retained.
    const persistentSubmitErrors = true;

    dispatch(change('DeliveryForm', blurredField, prevDeliveryOptionValues, keepTouched, persistentSubmitErrors));
    dispatch(deliveryMethodShowSpinner(false, orderItemId));
    dispatch(disableRewardsInput(false));
}

/**
 * getUpdatedOrderInfo() returns a Promise that will resolve if 
 * getCartResponse() is successfully called or otherwise will reject with an 
 * object of validation errors. getCartResponse() must be successfully called 
 * so that the user's new delivery method selection is reflected in the price 
 * summary.
 */
const getUpdatedOrderInfo = (dispatch, orderItemId) => {
    const field = `deliveryMethod-${orderItemId}`;
    const isUserSignedIn = store.getState().Authentication.registrationStatus != 'G';

    return new Promise((resolve, reject) => {
        getCartResponse()
            .then(response => {
                if (response.status === 200) {
                    const parsedResponse = parseCartResponse(response.data);
                    dispatch(loadOrderInfoDataAsync(response.data, parsedResponse))
                    resolve();
                    return;
                }
                throw { [field]: `getUpdatedOrderInfo request failed with bad status code ${response.status}` }
            })
            .catch(error => {
                const authentication401Message = 'Error: Request failed with status code 401';
                if (error === authentication401Message && isUserSignedIn && (AppSettings.pageName === 'Checkout' || AppSettings.pageName === 'Cart')) {
                  checkPartialAuthenticationError();
                 }
                throw { [field]: `getUpdatedOrderInfo request failed with error ${error}` }
            });
    })
}

/**
 * getCartResponse() sends a GET request to fetch the current cart/order info 
 * from the API in order to ensure that the price summary reflects the 
 * latest applied delivery method field selections.
 */
function getCartResponse() {
    const currentTime = new Date().getTime();
    const cartUrl = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/getCartData?cache=${currentTime}`;
    return axios.get(cartUrl);
}

/**
 * updateOrderItem() sends a POST request that updates the order item with the 
 * user's delivery method selection and then returns the response.
 */
const deleteOrderItem = (orderItemId, orderId, bac) => {
    let data = {
        orderItemId: orderItemId,
        calculateOrder: "1"
    };

    if (AppSettings.isT3) {
        data.x_bac = bac;
    };

    return axios.post(gmDeleteOrderItemURL, data);
}

const isReplacingInstall = (blurredField, previousValues) => {
    if (blurredField in previousValues) {
        let prevDeliveryOptionValues = JSON.parse(previousValues[blurredField]);
        const prevCarrierCode = prevDeliveryOptionValues.carrierCode;
        if (prevCarrierCode === INSTALL_CARRIER_CODE) {
            return true;
        }
    }
    return false;
}

/**
 * getInstallOrderItemId() searches an array of order items for the install 
 * order item corresponding to the given part order item. This is necessary 
 * because, unlike other shipping methods, the install delivery method is 
 * represented by its own unique order item.
 */
const getInstallOrderItemId = (partOrderItemId, itemsByVehicle) => {
    for (let vehicleItems of itemsByVehicle) {
        for (let groupedItems of vehicleItems.orderItems) {
            for (let item of groupedItems.items) {
                if (item.orderItemType === INSTALL_ORDER_ITEM_TYPE) {
                    if (partOrderItemId === item.PartOrderItemId) {
                        return item.orderItemId;
                    }
                }
            }
        }
    }
    return undefined;
}