import Modal from "../../../shared/Modal";
import Button from "../../../shared/Button/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SuccessMessage from "../../SuccessMessage/SuccessMessage";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {updateWallet} from "../GMWalletService";
import {getPaymentTypeImage} from "../GMWalletHelper";

//This component returns the payment methods to the WalletConsentModal
//and allows the user to select which payments they want to add to their new GMWallet
const FoundPaymentModal = (props) => {
    const { t } = useTranslation();
    const [showSpinnerBtn, setShowSpinnerBtn] = useState(false);
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [paymentsSelected, setPaymentsSelected] = useState([]);

    //modal styling that is sent to the <Modal component
    const modalStyling= {
        modal: 'wallet-modal-width'
    };

     //takes the id of the selected payment method and updates the hook
    const selectPayMethod = (id) => {
        if (paymentsSelected.includes(id)) {
            setPaymentsSelected(paymentsSelected.filter((val) => val !== id))
        } else {
            setPaymentsSelected(paymentsSelected => [...paymentsSelected,id])
        }
    };

    //Adds the cards found from the user to then add the selected ones to the new Global Wallet
    const postAddToWallet = async () => {
        setShowSpinnerBtn(true);
        props.dispatch({ type: "NO_MESSAGES" });
        try{
            const res = await updateWallet(paymentsSelected,props.state.params.userId);
            if(res){
                setShowSuccessMessage(true);
                props.dispatch({ type: "OPEN_FOUND_PAY_MODAL", boolean: false });
                props.dispatch({ type: "ADD_FOUND_CARD_TO_WALLET_SUCCESS", boolean: true });
            }else{
                setShowErrorMessage(true);
            }
        }catch (e) {
            setShowErrorMessage(true);
        }finally {
            setShowSpinnerBtn(false)
        }
    };

    // dictionary of wallet type code to display text
    const walletTypetoText = {
        ESTORE_WALLET: "GM Wallet",
        ONSTAR_WALLET: "OnStar",
        ACCS_WALLET: "GM Accessories"
    };

return (
    <div className="wallet-modal">
        {showSuccessMessage && <SuccessMessage icon showSuccess={showSuccessMessage} message={t("LABEL_GMWALLET_PAYMENT_SAVED")} />}
            <Modal
                classNames={modalStyling}
                onClose={() => props.dispatch({ type: "OPEN_FOUND_PAY_MODAL", boolean: false })}
                open
            >
            <div className="q-headline1 welcome-header ac-modal-header">
                {t("LABEL_GMWALLET_FOUND_PAYMENT_HEADER")}
            </div>
                <div className="ac-modal-body">
                    {showErrorMessage && <ErrorMessage icon message={t("LABEL_GMWALLET_ADD_FOUND_PAYMENTS_ERROR")} />}
                    <p>{t("LABEL_GMWALLET_FOUND_PAYMENT_SELECT")}</p>
                        <br></br>
                            {JSON.stringify(props.state.payMethodsResponse) !== "{}" && props.state.payMethodsResponse?.storedPaymentMethods.map((paymentMethod, key) =>
                            paymentMethod.walletType !== "ESTORE_WALLET" &&
                            (<div key={key} className="checkbox">
                                <label className="found-payment-label">
                                <input type="checkbox"
                                    onChange={() => selectPayMethod(paymentMethod.id)}/>
                                    <span>
                                        <img  className="select-payment-checkbox-img" src={getPaymentTypeImage(paymentMethod.brand)}/>
                                        {t("LABEL_GMWALLET_FOUND_PAYMENT_TEXT", {
                                            last4: paymentMethod.lastFour,
                                            walletType: walletTypetoText[paymentMethod.walletType]
                                        })}
                                    </span>
                                </label>
                            </div>))}
                            <br></br>
                            <div className="found-payment-section"/>
                                <br></br>
                            <p className="privacy-statement">
                                    {t("LABEL_GMWALLET_CONFIRM_PAYMENT_AGREEMENT")}
                                <a className="privacy-statement" id="privacy-statement" href={privacyLink} target="_blank">
                                    {t("LABEL_GMWALLET_CONFIRM_PAYMENT_PRIVACY_LINK")}
                                </a>{"."}
                            </p>
                    <br></br>
                </div>
                    <div className="gmWallet-btn-container">
                        <div className="btn-width" id="cancel-btn">
                            <Button
                                id="found-payment-modal-cancel-btn"
                                className="stat-button-link"
                                dataDtm="modal:new wallet"
                                localeKey={t("LABEL_COMMON_CANCEL")}
                                onClick={() => props.dispatch({ type: "OPEN_FOUND_PAY_MODAL", boolean: false })}
                                />
                        </div>
                        <div className="btn-width" id="addToWallet-btn">
                            <Button
                                id="found-payment-modal-add-to-wallet-btn"
                                disabled={paymentsSelected.length === 0}
                                dataDtm="modal:new wallet"
                                className="stat-button-link"
                                localeKey={t("LABEL_GMWALLET_ADD_TO_WALLET")}
                                onClick={() => postAddToWallet()}
                                isLoading={showSpinnerBtn}
                            />
                        </div>
                    </div>
            </Modal>
        </div>
)};

export default FoundPaymentModal;
