import { faExclamation,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import {Translation} from "react-i18next";



const ErrorMessage = (props) => {
    const showError = props.showError;
    let errorType =  props.type || 'error';
    let parentClass = 'ac-errorParent ac-errorMessage';
    let iconClass = 'ac-icon ac-error-icon';
    let messageClass = 'ac-message ac-errorMessage';
    let fontIcon = faTimesCircle;
    const errorIcon = props.icon;

    if(errorType === 'warning'){
        parentClass = 'ac-errorParent ac-warningMessage';
        iconClass = ' ac-icon ac-warningIcon';
        messageClass = 'ac-message ac-warningMessage';
        fontIcon = faExclamation
    }
    if (showError === false) {
        return (null);
    } else if (props.htmlMessage) { //message contains html elements
        return (
            <Translation>
                {(t) => (
                    <div className={parentClass}>
                        {errorIcon && (
                            <span className={iconClass}>
                                <FontAwesomeIcon icon={fontIcon} />
                            </span>
                        )}
                        <div className={messageClass} dangerouslySetInnerHTML={{
                        __html: t(props.message, {
                            cart: "/cart",
                        })
                    }}/>
                    </div>
                )}
            </Translation>
        );
    } else {
        // If props.message contains a ':' then it splits the message into an Array of strings.
        // If it does not, then it returns one Array element consisting of the entire props.message
        const errorMessage = props.message.split(':');

        return (<Translation>
                {
                    (t)=>(
                        <div className={parentClass}>
                            {
                                errorIcon && <span className={iconClass}><FontAwesomeIcon icon={fontIcon} /></span>

                            }
                            {errorMessage && <span className={messageClass}>{errorMessage.length === 2 ? t(errorMessage[0]) + ': ' + t(errorMessage[1]) : t(errorMessage[0])}</span>}
                        </div>
                    )
                }
            </Translation>
        )
    }
}

export default ErrorMessage
