import queryString from "query-string";
import AppSettings from "../../../../../core/AppSettings";
import {
    getCategoryResultsByYMM,
    getCategoryResultsByVIN,
    searchCallForShopWithoutFitment
} from "../../../SearchService/SearchDataService";
import { LOAD_CATEGORY_NAV_DATA, LOAD_CATEGORY_NAV_DATA_FAILED } from "./CategoryNavActionTypes";
import { call, put, select } from "redux-saga/effects";
import accHistory from '../../../../App/History';
import * as util from '../../../../../shared/Utils/Utils';
import vaultConstants from '../../../../../../config/vault_constants';
import { checkFitmentWithUrlParams } from "../../../SearchUtils";


// VEHICLE INFO HERE
export const getYear = (state) => state.Session.year;
export const getMake = (state) => state.Session.make;
export const getModel = (state) => state.Session.model;
export const getVehicleConfig = (state) => state.Session.vehicleConfig;
export const getVin = (state) => state.Session.vin;
export const getSearchCallLock = (state) => state.SearchServiceReducer.searchCallLock;
export const getShopWithoutFitment = (state) => state.SearchServiceReducer.isShopWithoutFitmentActive;
export const getSearchServiceReducer = (state)=> state.SearchServiceReducer;


// get breadcrumb all products clicked state
export const getAllProductsBreadcrumbFlag = state => state.BreadcrumbReducer.isAllProductsBreadcrumbClicked;
const SEARCH_TERM = "searchTerm";

export function* getSearchData(action) {
    const isSearching = yield select(getSearchCallLock);
    
    //If fitment changes on PDP and a user navigates back to search, Search should unlock because url parameters will change
    let differentParams = false;
    const serviceData = yield select(getSearchServiceReducer);
    if(!checkFitmentWithUrlParams(serviceData.currentUrlParams)){
        differentParams=true;
    }

    if (differentParams || !isSearching) {
        try {
            let searchParams = action.payload;
            const vehicleConfig = yield select(getVehicleConfig);
            let vehicleVin = yield select(getVin);

            // Cookies don't work when running local, so this is to ease work in local. For safety measure in async cases,
            // if vehicleVin does not exist, check the payload and url.
            if (!vehicleVin || AppSettings.isLocalHost) {
                vehicleVin = action.payload.vin || AppSettings.urlParameters().vin || "";
            }
            const isShopWithoutFitmentActive = yield select(getShopWithoutFitment);
            let response = "";
            // VIN SEARCH
            if (vehicleVin) {
                response = yield call(getCategoryResultsByVIN, vehicleVin);

                const currUrlParams = AppSettings.urlParameters();
                //Prevents race conditions when using back button with SWOF by comparing curr url param with param that was passed into search call. 
                if(vehicleVin !== currUrlParams["vin"]){
                    response = undefined;
                }
            }
            // SHOP WITHOUT FITMENT
            else {
                // One on page load, and one with fitment data...
                const urlParams = AppSettings.urlParameters();
                let orderBy = sessionStorage.getItem("SEARCH_RESULTS_FILTERS") ? sessionStorage.getItem("SEARCH_RESULTS_FILTERS") : "";
                let params = AppSettings.urlParameters();
                delete params.categoryId;
                params = util.constructMappingUrl(params);

                // flag to see if user is coming from SERP using the All Parts breadcrumb
                const AllProductsBreadcrumbFlag = yield select(getAllProductsBreadcrumbFlag);
                // if user clicks the "all parts" breadcrumb, remove searchTerm from url to update catnav and producsearchresults.
                if(AllProductsBreadcrumbFlag) {
                   delete params[SEARCH_TERM];
                }

                // When a facet is selected and we need to rebuild the accordion we need to include the facets to rebuild it with that facet data.
                response = yield call(searchCallForShopWithoutFitment, util.jsonToUrlParams(params), orderBy);
                if(!checkFitmentWithUrlParams(urlParams)){
                    response = undefined;
                }           
            }
            let categories = response;

            //parse the data first, pass both
            if (response !== undefined && response.facetView !== undefined) {
                categories = parseCategories(response.facetView, searchParams, vehicleConfig);
                yield put({
                    type: LOAD_CATEGORY_NAV_DATA,
                    payload: categories
                });
            } else {
                yield put({
                    type: LOAD_CATEGORY_NAV_DATA_FAILED,
                    errorMsg:
                        "getCategoryResultsByYMM response returned undefined with params: " +
                        searchParams.year +
                        searchParams.make +
                        searchParams.model,
                    response: response
                });
            }
        } catch (error) {
            yield put({
                type: LOAD_CATEGORY_NAV_DATA_FAILED,
                error
            });
        }
    }
}

export function parseCategories(data, searchParams) {
    //Accessories code
    let facetViewEntry = [];
    // iterate through the facet view until we find the one pertaining to categories
    let FACET_VIEW_VALUE = "parentCatgroup_id_search";
    let FACET_VIEW_NAME = "ParentCatalogGroup";
    for (let index in data) {
        if (data[index].value === FACET_VIEW_VALUE && data[index].name === FACET_VIEW_NAME) {
            facetViewEntry = data[index].entry;
        }
    }
    let facet = [];

    for (let index in facetViewEntry) {
        // parent category
        if (!facetViewEntry[index].extendedData.hasOwnProperty("parentIds")) {
            let urlSearchParams = util.getSessionParams(
                null,
                `${"&categoryId=" + facetViewEntry[index].extendedData.uniqueId}`
            );
            let parentFacet = {
                uniqueId: facetViewEntry[index].extendedData.uniqueId,
                /*should the parent category bother with a parentCategory array?? I don't think so, so I commented it out*/
                /*"parentCategory": [],*/
                label: "All Products",
                count: facetViewEntry[index].count,
                categories: [],
                urlSearchParams: urlSearchParams
            };
            facet.push(parentFacet);
        }
    }

    for (let index1 in facetViewEntry) {
        // category
        if (facetViewEntry[index1].extendedData.hasOwnProperty("parentIds")) {
            if (facetViewEntry[index1].extendedData.parentIds.indexOf("_") < 0) {
                for (let index2 in facet) {
                    if (facetViewEntry[index1].extendedData.parentIds === facet[index2].uniqueId) {
                        let urlSearchParams = util.getSessionParams(
                            null,
                            `${"&categoryId=" + facetViewEntry[index1].extendedData.uniqueId}`
                        );
                        let childFacet = {
                            uniqueId: facetViewEntry[index1].extendedData.uniqueId,
                            parentCategory: facet[index2],
                            label: facetViewEntry[index1].label,
                            count: facetViewEntry[index1].count,
                            /*renamed category's categories to subCategories*/
                            subCategories: [],
                            urlSearchParams: urlSearchParams
                        };
                        let insertFlag = false;
                        if (facet[index2].categories.length === 0) {
                            facet[index2].categories.push(childFacet);
                            //get active category from url, only if it a) doesn't already exist and b)is actually active
                        } else {
                            for (let sortIndex in facet[index2].categories) {
                                if (childFacet.label < facet[index2].categories[sortIndex].label) {
                                    facet[index2].categories.splice(sortIndex, 0, childFacet);
                                    insertFlag = true;
                                    break;
                                }
                            }
                            if (!insertFlag) {
                                facet[index2].categories.push(childFacet);
                            }
                        }
                    }
                }
            }
        }
    }

    for (let index1 in facetViewEntry) {
        // subcategory
        if (facetViewEntry[index1].extendedData.hasOwnProperty("parentIds")) {
            if (facetViewEntry[index1].extendedData.parentIds.indexOf("_") > 0) {
                let ids = facetViewEntry[index1].extendedData.parentIds.split("_");
                for (let index2 in facet) {
                    if (ids[0] === facet[index2].uniqueId || ids[1] === facet[index2].uniqueId) {
                        for (let index3 in facet[index2].categories) {
                            if (
                                ids[1] === facet[index2].categories[index3].uniqueId ||
                                ids[0] === facet[index2].categories[index3].uniqueId
                            ) {
                                let urlSearchParams = util.getSessionParams(
                                    null,
                                    `${"&categoryId=" + facetViewEntry[index1].extendedData.uniqueId}`
                                );
                                let grandchildFacet = {
                                    uniqueId: facetViewEntry[index1].extendedData.uniqueId,
                                    /*renamed parentCategory of the subcategory to categories to differentiate the level*/
                                    categories: facet[index2].categories[index3],
                                    label: facetViewEntry[index1].label,
                                    count: facetViewEntry[index1].count,
                                    /*there are no categories under subcategories (lowest level) so I removed that data array*/
                                    urlSearchParams: urlSearchParams
                                };
                                let insertFlag = false;
                                if (facet[index2].categories[index3].subCategories.length === 0) {
                                    facet[index2].categories[index3].subCategories.push(grandchildFacet);
                                } else {
                                    for (let sortIndex in facet[index2].categories[index3].subCategories) {
                                        if (
                                            grandchildFacet.label <
                                            facet[index2].categories[index3].subCategories[sortIndex].label
                                        ) {
                                            facet[index2].categories[index3].subCategories.splice(
                                                sortIndex,
                                                0,
                                                grandchildFacet
                                            );
                                            insertFlag = true;
                                            break;
                                        }
                                    }
                                    if (!insertFlag) {
                                        facet[index2].categories[index3].subCategories.push(grandchildFacet);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return parseSelectedCategory(facet, searchParams);
}

/**
 * @facet the category array
 * @searchParams search values (ymm or vin)
 * parses and matches unique ID's to find which is the currently selected cat
 * */
/*pulled the parser out from every step above to reduce repeated code, and add to readability*/
export function parseSelectedCategory(facet, searchParams) {
    let selectedCategory = {};
    let parentFacet = facet[0];
    let childFacet = parentFacet.categories;
    if (searchParams.categoryId === parentFacet.uniqueId) {
        selectedCategory = parentFacet;
    }
    for (let i in childFacet) {
        if (searchParams.categoryId === childFacet[i].uniqueId) {
            selectedCategory = childFacet[i];
        }
    }
    for (let i in childFacet) {
        let grandchildFacet = childFacet[i].subCategories;
        for (let i in grandchildFacet) {
            if (searchParams.categoryId === grandchildFacet[i].uniqueId) {
                selectedCategory = grandchildFacet[i];
            }
        }
    }
    return createCategoriesArray(facet, selectedCategory, searchParams);
}
/**
 * @facet main category array
 * @selectedCategory currently selected category array
 * @searchParams the YMM or VIN info*/
export function createCategoriesArray(facet, selectedCategory, searchParams) {
    //return object containing all categories and the selectedCategory
    let categories = {
        parsedCategories: facet,
        selectedCategory: selectedCategory,
        searchParams: {
            year: searchParams.year,
            make: searchParams.make,
            model: searchParams.model,
            vin: searchParams.vin
        }
    };
    return categories;
}
/**
 *  @category pulls in the current level category (the active parent, category, or sub-category)
 *  checks for if catId in case it's a fitment check page, and it'll navigate to a properly formatted SR page
 *  replaceState will update the URL, but not add it to history
 *  back-button will return to previous page, not previous search filter update
 *  refresh and manual enter of updated URL will return currently selected results*/
export function* handleURL(category) {
    category = category.category;

    let query = queryString.parseUrl(document.location.search);
    let url = document.location.pathname;

    if (query.categoryId !== category.uniqueId) {
        // get params from Session reducer
        let queryParams = AppSettings.urlParameters();
        delete queryParams.categoryId;
        queryParams = util.jsonToUrlParams(queryParams);
        let categoryParam = "&categoryId";

        // If no fitment is selected and category will be the only parameter
        if (Object.keys(queryParams).length === 0) {
            categoryParam = "?categoryId";

            // If there are no other params, and categoryId is the only one, we still want to keep the ?
        } else if (Object.keys(queryParams).length === 1 && queryParams.categoryId) {
            categoryParam = "?categoryId";
        }
        // get category id
        category.uniqueId ? (queryParams += `${categoryParam}=${category.uniqueId}`) : "";

        accHistory.push(`${url}${queryParams}`);
    }
}
