import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translation } from 'react-i18next';
import HeaderLoyalty from '../../HeaderLoyalty/HeaderLoyalty';
import HeaderRewards from '../../HeaderRewards/HeaderRewards';
import store from '../../../../../core/Redux/Store';

const RegisteredUserWidget = (props) => {
  const {
    authenticationData,
    isRewardsActive,
    isDropdownActive,
    rewardsPoints,
    width,
  } = props;

  useEffect(() => {
    if (isDropdownActive) {
     store.dispatch({ type: "SET_MINI_CART_OPEN", modalOpen: false });
    }
  }, [isDropdownActive]);

  if (
    (isRewardsActive && rewardsPoints) ||
    (!rewardsPoints && isRewardsActive)
  ) {
    return (
      <Translation>
        {(t) => (
          <div className='small-12 ac-header-user-vert-align'>
            <div className='ac-header-user-section'>
              <a>
                <span
                  className='ac-header-user-vert-align'
                  style={{ paddingRight: '8px' }}
                >
                  <FontAwesomeIcon icon='user-circle' size='2x' />
                </span>
                <span
                  className='ac-header-user-link ac-header-user-vert-align stat-button-link'
                  data-dtm='global nav'
                  id='ac-rewards-user-header'
                  style={{ paddingLeft: '5px' }}
                >
                  {/* Use an icon if on mobile, or small screen device instead of label */}
                  {/* May update to use media screen small medium definitions instead of width variable */}
                  <div>
                    {authenticationData.userData.firstName && (
                      <span>
                        {authenticationData.userData.firstName.substring(0, 24)}
                      </span>
                    )}
                  </div>
                  <HeaderRewards
                    className='ac-header-rewards'
                    rewardsPoints={rewardsPoints ? rewardsPoints : '0'}
                    width={width}
                  />
                </span>
                <span className='ac-header-user-vert-align'>
                  <FontAwesomeIcon
                    className='ac-header-user-link-caret ac-header-caret-spacing-signed-in'
                    icon={getRegisteredUserIcon(isDropdownActive)}
                  />
                </span>
              </a>
            </div>
          </div>
        )}
      </Translation>
    );
  } else {
    return (
      <Translation>
        {(t) => (
          <div className='ac-header-user-vert-align'>
            <div className='ac-header-user-section ac-header-user-vert-align'>
              <a>
                <span>
                  <FontAwesomeIcon icon='user-circle' size='2x' />
                </span>
                <span
                  className='ac-header-user-link ac-header-user-vert-align'
                  id='ac-nonrewards-user-header'
                  style={{ paddingLeft: '5px' }}
                >
                  {/* Use an icon if on mobile, or small screen device instead of label */}
                  {/* May update to use media screen small medium definitions instead of width variable */}
                  {width >= 570 && authenticationData.userData.firstName && (
                    <span>
                      {authenticationData.userData.firstName.substring(0, 24)}
                    </span>
                  )}
                </span>
                <FontAwesomeIcon
                  className='ac-header-user-link-caret ac-header-caret-spacing-signed-in'
                  icon={getRegisteredUserIcon(isDropdownActive)}
                />
              </a>
            </div>
          </div>
        )}
      </Translation>
    );
  }
};
const getRegisteredUserIcon = (isDropdownActive) => {
  return isDropdownActive ? 'caret-up' : 'caret-down';
};

export default RegisteredUserWidget;
