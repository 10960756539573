/**************************************************************/
// Order History
/**************************************************************/
export const LOAD_ORDER_HISTORY_DATA_ASYNC = 'LOAD_ORDER_HISTORY_DATA_ASYNC';
export const ORDER_HISTORY_DATA_LOADED = 'ORDER_HISTORY_DATA_LOADED';
export const LOAD_ORDER_HISTORY_DATA_ASYNC_FAIL = 'LOAD_ORDER_HISTORY_DATA_ASYNC_FAIL';
export const LOAD_ORDER_DATA_ASYNC = 'LOAD_ORDER_DATA_ASYNC';
export const ORDER_DATA_LOADED = 'ORDER_DATA_LOADED';
export const LOAD_ORDER_DATA_ASYNC_FAIL = 'LOAD_ORDER_DATA_ASYNC_FAIL';
export const LOAD_PAYMENT_INFO_DATA_ASYNC = 'LOAD_PAYMENT_INFO_DATA_ASYNC';
export const PAYMENT_INFO_DATA_LOADED = 'PAYMENT_INFO_DATA_LOADED';
export const LOAD_PAYMENT_INFO_DATA_ASYNC_FAIL = 'LOALOAD_PAYMENT_INFO_DATA_ASYNC_FAILD_ORDER_DATA_ASYNC_FAIL';



/**************************************************************/
// Profile & Settings
/**************************************************************/
export const TOGGLE_PROFILE_EDIT_PHONE = 'TOGGLE_PROFILE_EDIT_PHONE';
export const TOGGLE_PROFILE_EDIT_ADDRESS = 'TOGGLE_PROFILE_EDIT_ADDRESS';
export const TOGGLE_PROFILE_EDIT_PERSONAL_INFO = 'TOGGLE_PROFILE_EDIT_PERSONAL_INFO';

export const TOGGLE_PROFILE_ADD_PHONE = 'TOGGLE_PROFILE_ADD_PHONE';
export const TOGGLE_PROFILE_ADD_ADDRESS = 'TOGGLE_PROFILE_ADD_ADDRESS';

export const RESET_PROFILE_TOGGLE = 'RESET_PROFILE_TOGGLE';

export const LOAD_PERSONAL_INFO_DATA_ASYNC = 'LOAD_PERSONAL_INFO_DATA_ASYNC';
export const PERSONAL_INFO_DATA_LOADED = 'PERSONAL_INFO_DATA_LOADED';
export const LOAD_PERSONAL_INFO_DATA_ASYNC_FAIL = 'LOAD_PERSONAL_INFO_DATA_ASYNC_FAIL';