import React, { Component } from 'react';
import store from '../../../core/Redux/Store';
import FitmentCheck from './FitmentCheck';
import { loadVehicleCombinationsAsync } from './FitmentCheckRedux/FitmentCheckActionCreator';
import queryString from 'query-string';

class FitmentCheckContainer extends Component {

    componentDidMount() {
        /**************************************************************/
        // Load vehicle combination data in current state
        // if YMM Search
        /**************************************************************/

        let parsed = queryString.parse(location.search);

        if (!parsed.vin) {
            const action = loadVehicleCombinationsAsync()
            store.dispatch(action);
        }
        
    }

    render() {
        return (
            <FitmentCheck />
        );
    }
}

export default FitmentCheckContainer;