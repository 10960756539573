import axios from 'axios';
import { call } from 'redux-saga/effects';
import AppSettings from '../../../core/AppSettings'
import { postAuthenticated } from "../../Authenticator/Authenticator";

/** REST CALL URLS **/
const gmUpdateOrderItemURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/update_order_item?responseFormat=json`;
const gmDeleteOrderItemURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/delete_order_item?responseFormat=json`;
const switchOrgUrl = `/wcs/resources/store/${AppSettings.storeId}/switchOrganization/switchTo`;
const getShippingRatesUrl = `/wcs/resources/store/${AppSettings.storeId}/GMCart/{orderId}/getShippingRates`;


/** REST CALL TO GET CART **/
export const getCartResponse = () => {
    let currTime = new Date().getTime();
    const gmCartURL = `/wcs/resources/store/${AppSettings.storeId}/GMCart/@self/getCartData?cache=${currTime}`;
    return axios.get(gmCartURL, { headers: { "Cache-Control" : "no-cache" } })
        .then((response) => { return response.data; },
            (error) => { console.log("****Error occur while trying to fetch cart data => ", error); throw error; });
};

/** REST CALL TO UPDATE THE QTY **/
export function* getOrderItemChangeQuantityResponse(payload, addFromCartQueryParam) {

    let gmUpdateOrderItemCall = gmUpdateOrderItemURL;
    if(addFromCartQueryParam){
        payload.x_calculateOrder = "1";
        payload.x_calculationUsage = "-1,-2,-5,-6,-7"
    }

    try {
        return (yield call(postAuthenticated, gmUpdateOrderItemCall, payload));

    }
    catch (error) {
        console.log("***Error occurred while trying to change the Qty => ", error);
        throw error
    }
}

/** REST CALL TO REMOVE LINE ITEM FROM CART **/
export function* getOrderItemDeleteResponse(payload, addFromCartQueryParam) {
    let gmDeleteOrderItemCall = gmDeleteOrderItemURL;
    if(addFromCartQueryParam){
        payload.x_calculateOrder = "1";
        payload.x_calculationUsage = "-1,-2,-5,-6,-7"
    }

    try {
        return(yield call(postAuthenticated, gmDeleteOrderItemCall, payload));
    }
    catch (error) {
        console.log("*** Error occurred while trying to remove the Qty => ", error);
        throw error
    }
}

/** REST CALL TO SWITCH ORG IN T3 **/
export const callSwitchOrg = (payload)=>{
    return axios.put(switchOrgUrl,payload).then((response)=> {return response.data})
        .catch( (error) =>{
            console.log("*** Error occur while trying to fetch switch org =>",error);
            throw error
        });
}

/** RESET CALL TO GET THE SHIPPING RATES **/
export const getShippingRates = (orderId) => {
    const url = getShippingRatesUrl.replace('{orderId}', orderId);
    return axios.get(url).then(response => response.data).catch((error) => {console.log("*** Error occur while trying to fetch shipping rates => ", error); throw error});

};