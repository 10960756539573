import React, { useEffect, useState } from "react";
import { withTranslation, Translation, Trans } from "react-i18next";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import FieldErrorMessage from "../../FieldErrorMessage/FieldErrorMessage";
import { renderCheckboxField } from "../../Form/FormFields/FormFields";
import AppSettings from "../../../core/AppSettings";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import "./EnrollmentModal.scss";
import { getBaseMarketingSpotActivityData } from "../../Validator/Validator";
import { getEnrollModalEspot } from "./EnrollmentModalService";
import { useReplaceAllNamedDisclaimers } from "../../../modules/App/Footer/FooterDisclaimerLabel";
import Footnote from "../../Footnote/Footnote";
import vaultConstants from "../../../../config/vault_constants";

/*
    ENROLLMENT MODAL
    props:
    - open: Hook boolean passed by parent container. True will allows the modal to appear, false will make it disappear.
    - onClose: contains "open"s hook setter. This allows the modal to be closed.
    - center: Modal component optional prop. Center the modal to the screen. If not, modal will appear near the top.
*/

const EnrollmentModal = (props) => {
    const { open, onClose = () => true, center = true, label } = props;
    const [enrollMessage, setEnrollMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [espotMade, setEspotMade] = useState(false);
    const FF_2274900_CA_RESIDENTS_FOOTNOTE = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2274900_CA_RESIDENTS_FOOTNOTE) === 'true';
    const caDisclosure = process.env.REACT_APP_CA_FINANCIAL_INCENTIVE_DISCLOSURE;

    //set correct superscript label for disclaimers
    const replaceDisclaimers = useReplaceAllNamedDisclaimers();

    useEffect(() => {
        if (espotMade) {
            let formattedText = replaceDisclaimers(enrollMessage);
            setEnrollMessage(formattedText);
        }
    }, [espotMade, replaceDisclaimers]);

    useEffect(() => {
        getEnrollMessage();
    }, []);

    //get Espot data for cart potential points message
    const getEnrollMessage = async () => {
        try {
            const response = await getEnrollModalEspot();
            const baseMarketingSpotActivityData = getBaseMarketingSpotActivityData(response.data, 0);
            if (baseMarketingSpotActivityData[0].marketingContentDescription[0]) {
                setEnrollMessage(baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText);
                setEspotMade(true);
            }
        } catch (e) {
            console.warn("Unable to retrieve content from espot -->", e);
        }
    };
    // Terms and conditions
    const brand = AppSettings.currentSite.label;
    const rewardsTerms = process.env.REACT_APP_REWARDS_BASE_URL + brand + process.env.REACT_APP_REWARDS_FILENAME;
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    const handleSubmitEnrollment = () => {
        setIsLoading(true);
        setError(null);

        let body = {
            enrolled_in_rewards: true
        };
        axios
            .post("/wcs/resources/store/" + AppSettings.storeId + "/GMPerson/user/enrollRewards/", body)
            .then((result) => {
                // If the user was successfully enrolled, then show success message and reload page. Otherwise show error.
                if (result.data.gmrewards_success) {
                    localStorage.setItem("showEnrolledConfirmationModal", true);
                    location = location;
                } else {
                    setError("LABEL_LOYALTY_ENROLLMENT_ERROR_TRY_AGAIN");
                }

                setIsLoading(false);
            })
            .catch(() => {
                setError("LABEL_LOYALTY_ENROLLMENT_ERROR_TRY_AGAIN");
                setIsLoading(false);
            });
    };

    const onEnrollRewardsChange = () => {
        setIsDisabled(!isDisabled);
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setIsDisabled(true), onClose(false);
            }}
            center={center}
            classNames={{
                modal: "enrollment-modal"
            }}
            dataClass="stat-button-close"
            dataDtm="enrollment state 1-modal"
        >
            <Translation>
                {(t) => (
                    <div className="enrollmentModal">
                        <div className="q-headline2">
                            <Trans i18nKey={"REWARDS_ENROLL_MODAL_STATE_1_HEADER"} values={{ brand, label }}>
                                {{ brand }} <sup>{{ label }}</sup>
                            </Trans>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: enrollMessage }} />
                        <hr className="q-separator-horizontal" />
                        <form onSubmit={() => handleSubmitEnrollment()}>
                            <div id="enrollment-modal-checkbox">
                                <Field
                                    name="RewardsCheckbox"
                                    className="stat-checkbox"
                                    onChange={onEnrollRewardsChange}
                                    component={renderCheckboxField}
                                    href={[rewardsTerms, privacyLink]}
                                    localeKey={"REWARDS_ENROLL_MODAL_STATE_1_CHECKBOX_MESSAGE"}
                                    dataDtmCheckBox={"enroll modal"}
                                    dataDtmCheckBoxLink={["enroll modal", "enroll modal"]}
                                />
                            </div>
                            {FF_2274900_CA_RESIDENTS_FOOTNOTE &&
                                <div id="ac-ca-residents-footnote">
                                    <Footnote
                                        localeKey="LABEL_ACCOUNT_CA_RESIDENTS_FOOTNOTE"
                                        href={[caDisclosure]}
                                    />
                                </div>
                            }
                            <div id="enrollment-modal-button" className="enrollment-btn-container">
                                <div className="later-btn">
                                    <Button
                                        className="stat-button-link"
                                        localeKey={t("REWARDS_ENROLL_MODAL_STATE_1_RETURN_BUTTON")}
                                        onClick={() => {
                                            setIsDisabled(true), onClose(false);
                                        }}
                                        dataDtm={"enroll modal"}
                                        isSecondary={true}
                                    />
                                </div>
                                <div className="enrollment-btn">
                                    <Button
                                        className="stat-button-link"
                                        localeKey={t("LABEL_COMMON_ENROLL_IN_REWARDS")}
                                        onClick={handleSubmitEnrollment}
                                        disabled={isDisabled || isLoading}
                                        isLoading={isLoading}
                                        dataDtm={"enroll modal"}
                                    />
                                </div>
                            </div>
                        </form>
                        {error && <FieldErrorMessage message={<Trans i18nKey={error} />} showError={true} />}
                    </div>
                )}
            </Translation>
        </Modal>
    );
};

export default withTranslation()(
    reduxForm({
        form: "EnrollmentModal", // a unique identifier for this form
        destroyOnUnmount: false, // <------ preserve form data
        enableReinitialize: true,
        forceUnregisterOnUnmount: true // <------ unregister fields on unmount
    })(EnrollmentModal)
);
