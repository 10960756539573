import React, { useState } from "react";
import { authorizeEditPayment } from "../GMWalletService";
import Button from "../../../shared/Button/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import store from "../../../core/Redux/Store";
import {resetPaymentInformation} from "../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";

export default function EditPaymentForm(props) {
    const { t } = useTranslation();
    const { storedPaymentMethodId, holderName, expiryDate } = props;
    const [name, setName] = useState(holderName);
    const [expDate, setExpDate] = useState(expiryDate);
    const regexTestExpDateFormat = /^(0[1-9]|1[0-2])\/\d{4}$/; // does the final test for validating the MM/YYYY format exactly and ensures proper months are entered
    const regexOnlyNumAndBackslash = /^[0-9&/]*$/; //numbers and the "/" char allowed
    const regexOnlyNum = /^[0-9]*$/; // only numbers
    const regexAlphaNumeric = /^[a-z0-9& ]*$/i; // alphanumeric and spaces allowed

    let params = props.state.params;

    const editPayObj = {
        currency: params.currency,
        value: "0",
        orderNo: params.orderNo,
        storedPaymentMethodId: storedPaymentMethodId,
        userId: params.userId,
        holderName: holderName,
        shopperInteraction: "ContAuth",
        storePaymentMethod: "true"
    };

    const handleChange = (e) => {
        if (e.target.id === "editName") {
            if (regexAlphaNumeric.test(e.target.value)) setName(e.target.value);
        }
        if (e.target.id === "editExpiryDate") {
            if (regexOnlyNumAndBackslash.test(e.target.value)) setExpDate(e.target.value);
        }
    };

    const editMethod = async () => {
        props.dispatch({ type: "NO_MESSAGES" });
        try {
            store.dispatch(resetPaymentInformation());
            if (name !== "") {
                editPayObj.holderName = name;
                if (expDate) {
                    let expiryDateArray = expDate.split("/");
                    editPayObj.expiryMonth = expiryDateArray[0];
                    editPayObj.expiryYear = expiryDateArray[1];
                }
                props.dispatch({ type: "SHOW_EDIT_SPINNER", boolean: true });
                let editPaymentResponse = await authorizeEditPayment(editPayObj);
                props.dispatch({ type: "EDIT_PAYMENT", success: editPaymentResponse.status === "Authorised" });
                props.hideEditForm();
            }
        } catch (error) {
            props.dispatch({ type: "EDIT_PAYMENT", success: false });
        }
    };

    return (
        <form id="editForm">
            {props.state.showEditErrorMessage && <ErrorMessage icon message={t("LABEL_GMWALLET_UNEXPECTED_ERROR")} />}
            <Row className="">
                <label className="edit-label">{t("LABEL_GMWALLET_EDIT_NAME")}</label>
                    <input
                        id="editName"
                        className="form-control"
                        placeholder={t("LABEL_GMWALLET_EDIT_NAME")}
                        value={name}
                        type="text"
                        maxLength={50}
                        onChange={handleChange}
                    />
            </Row>
            <Row className="edit-label-padding-top">
                <Col id="editFormCol">
                    <label>{t("LABEL_GMWALLET_EXPIRY_DATE")}</label>
                    <input
                        id="editExpiryDate"
                        className="form-control"
                        placeholder={"MM/YYYY"}
                        value={expDate}
                        type="text"
                        maxLength={7}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            <Row className="btn-padding-bottom">
                <Button 
                    className="btn-primary-edit" 
                    onClick={() => editMethod()}
                    isLoading={props.state.showEditSpinnerBtn}
                    localeKey={t("LABEL_COMMON_UPDATE")}
                    disabled={name.length === 0 || !regexTestExpDateFormat.test(expDate)}
                />
            </Row>
        </form>
    );
}
