import * as type from './AuthenticationActionTypes';
import AppSettings from "../../core/AppSettings";

const isRegisterUser = AppSettings.isT3 || sessionStorage.getItem('isRUser')==='true';

const initialState = {
    loginIsFetching: null,
    isAuthenticated: false,
    errorMessage: '',
    showError: false,
    state: '',
    idToken: '',
    userData: {},
    registrationStatusFetched: false,
    registrationStatus: 'G',
    checkRegistrationStatus: '',
    unverifiedEmail: null,
    enrolledAttributeValue: 0,
    enrolledAttributeValueFetched: false,
    isSessionExpired: false,
    showAuthModal: false,
    checkSSO: true,
    logoutRedirectPath: null,
    appReadyState:false,
    addWrapper:!isRegisterUser,
    logoutError:false
}

const AuthenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.AUTHENTICATION_LOGIN_REQUEST:
            // Makes analtyics direct call when user first engages the login process
            if (typeof _satellite != 'undefined') {
                _satellite.track('login-start');
            }
            return Object.assign({}, state, {
                loginIsFetching: true
            });

        case type.AUTHENTICATION_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loginIsFetching: false,
                isFetching: false,
                isAuthenticated: true,
                errorMessage: '',
                logoutError: false
            });

        case type.AUTHENTICATION_LOGIN_FAILURE:
            return Object.assign({}, state, {
                loginIsFetching: false,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message,
                showError: true,
                logoutError: true
            });

        case type.AUTHENTICATION_USER_LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message,
                showError: true,
            });

        case type.AUTHENTICATION_NOTIFICATION_MODAL:
            return Object.assign({}, state, {
                showAuthModal: action.showAuthModal
            });

        case type.AUTHENTICATION_SET_PARSED_VALUES:
            return Object.assign({}, state, {
                state: action.state,
                idToken: action.idToken
            });

        case type.AUTHENTICATION_REGISTRATION_STATUS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });

        case type.AUTHENTICATION_USER_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                showError: false,
            });

        case type.AUTHENTICATION_CHECK_SSO:
            return Object.assign({}, state, {
                checkSSO: action.checkSSO,
            });

        case type.SET_REGISTRATION_STATUS:
            return Object.assign({}, state, {
                loginIsFetching: false,
                isFetching: false,
                registrationStatus: action.registrationStatus,
                registrationStatusFetched: action.registrationStatusFetched,
                checkRegistrationStatus: action.registrationStatus
            });

        case type.AUTHENTICATION_SET_USER_INFO:
            return Object.assign({}, state, {
                userData: action.userData
            });

        case type.AUTHENTICATION_SET_ENROLLED_ATTRIBUTE_VALUE:
            return Object.assign({}, state, {
                enrolledAttributeValue: action.enrolledAttributeValue,
                enrolledAttributeValueFetched: true
            });
        case type.AUTHENTICATION_USER_LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                logoutRedirectPath: action.redirectPath
            });
        case type.AUTHENTICATION_USER_LOGIN_UNVERIFIED_FAILURE:
            return Object.assign({}, state, {
                loginIsFetching: false,
                isFetching: false,
                isAuthenticated: false,
                unverifiedEmail: action.unverifiedEmail,
                errorMessage: action.message,
                showError: true
            });

        case type.AUTHENTICATION_SET_COOKIE_EXPIRY_STATUS:
            return Object.assign({}, state, {
                isSessionExpired: action.isSessionExpired,
                isFetching: false
            });
        case type.RENDER_APP:
            return Object.assign({}, state, {
                appReadyState: action.payload
            });
        case type.ADD_WRAPPER_FOR_PAGE_REDIRECT:
            return Object.assign({}, state, {
                addWrapper: action.addWrapper
            });
        default: return state;

    }
};

export default AuthenticationReducer;
