import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as validate from '../../../../../shared/Form/FormValidate/FormValidate';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../core/i18n/i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? 'gmit-gf-text-field error' : 'gmit-gf-text-field'}>
            {required == true && <label className="gmit-gf-label-text required">{label}</label>}
            {required != true && <label className="gmit-gf-label-text">{label}</label>}
            <input {...input} placeholder={label} type={type} className="stat-input-field" data-dtm="checkout step1"
                   data-dtm-2="exclude" {...otherProps} />
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />
                {error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)

const PasswordForm = props => {
    const { handleSubmit, pristine, reset, submitting, stateList, initialValues } = props;

    return(
        <Translation>
            {
                (t)=>(
                    <div className="gmit-gf-request-type">
                        <div className="row">
                            <div className="small-12 grid-column-alignment-left columns">
                                <Field
                                    name="currentpassword"
                                    type="text"
                                    component={renderField}
                                    label={i18n.t("LABEL_PROFILE_CURRENT_PASSWORD")}
                                    required={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    className="stat-input-field"
                                    maxLength={64}
                                    minLength={2}
                                />
                            </div>
                            <div className="small-12 grid-column-alignment-left columns">
                                <Field
                                    name="newpassword"
                                    type="text"
                                    component={renderField}
                                    label={i18n.t("LABEL_PROFILE_NEW_PASSWORD")}
                                    required={true}
                                    validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                    maxLength={64}
                                    minLength={2}
                                />
                            </div>
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

export default withTranslation()(reduxForm({
    form: 'PasswordForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(PasswordForm))