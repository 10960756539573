import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import React, { useState } from "react";
import store from "../../.././core/Redux/Store";
import Spinner from "../../Spinner/Spinner";
import {
    couponCodePostAsync,
    deliveryPageTaxFalse,
    handleCouponResponseMsg
} from "../../../modules/Checkout/CheckoutRedux/CheckoutActionCreator";
import SharedButton from "../../Button/SharedButton";
import FieldWarningMessage from "../../FieldWarningMessage/FieldWarningMessage";
import {showApplyCouponBtnSpinner} from "../../SummaryRail/SummaryRailRedux/SummaryRailActionCreator";
/**
 * This is the container that displays the input field and apply button that lets users manually enter a coupon
 *
 * @param props
 * couponMsg={checkoutData.couponMsg ? checkoutData.couponMsg : ""}
 * showLoadingBtn={summaryRailsState.showLoadingBtn}
 *
 * @returns {JSX.Element}
 */
const CouponsForm = (props) => {
    //TODO:need to clean up some of the code that may or may not be needed
    const [couponCodeValue, setCouponCodeValue] = useState("");
    const regex = /^[a-z0-9]+$/i;

    // don't show error message after user makes changes to input field
    const clearCouponError = () => {
        if (props.couponMsg) {
            store.dispatch(handleCouponResponseMsg(""));
        }
        return null;
    };

    const handleChange = (e) => {
        clearCouponError();
        //Using regex to test the inputs and make sure they are AlphaNumeric
        // regex.test equals false when e.target.value is empty, so need an OR statement for when user deletes the last string in input
        if (regex.test(e.target.value) || e.target.value.length === 0) setCouponCodeValue(e.target.value);
    };

    const handleSubmit = () => {
        //TODO: figure out if these comments need to be integrated into cart page. Coming from checkout
        store.dispatch(showApplyCouponBtnSpinner(true));
        store.dispatch(couponCodePostAsync({couponCode:couponCodeValue}));
        store.dispatch(deliveryPageTaxFalse());
    };

    return (
        <Translation>
            {(t) => (
                <div className="ac-coupon-code-form">
                    {/* Coupon Input Field */}
                    <div className="ac-coupon-form">
                        <div className="gmit-gf-request-type ac-field-wrapper">
                            <div className="ac-coupon-field">
                                {/*TODO: figure out value for DTM value*/}
                                <input
                                    className="gmit-gf-input-text stat-input-field q-input margin-small"
                                    value={couponCodeValue}
                                    onChange={handleChange}
                                    placeholder={t("LABEL_COUPON_ENTER_CODE")}
                                    type="text"
                                    //data-dtm="order summary" TODO: does this data dtm need to change? Old one on check was: checkout step2:coupon codes
                                    name="couponCode"
                                />
                                {props.couponMsg && <FieldWarningMessage message={props.couponMsg} />}
                            </div>
                        </div>
                    </div>

                    {/* Coupon Form Submit Button */}
                    <div className="ac-coupon-button small-5 medium-4 grid-column-alignment-right columns">
                        <SharedButton
                            type="submit"
                            disabled={props.showLoadingBtn || couponCodeValue.length < 1}
                            className="ac-coupon-apply-button q-button q-primary-button gm-primary-btn stat-button-link gb-primary-button"
                            analyticsTag="checkout step2:coupon codes"
                            buttonText={t("LABEL_COUPON_APPLY")}
                            onClick={handleSubmit}
                        />
                        {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true" />}
                    </div>
                </div>
            )}
        </Translation>
    );
};

export default CouponsForm;
