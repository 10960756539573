import i18n from "i18next";
import { formattedCurrency } from "../../../shared/Internationalization/FormattedNumbers";

// Return the radio button data for the delivery options.
export function getDeliveryOptions(data, handleOptionClick) {
  let options = { deliveryOptions: [], suppressedErrors: [] };

  // In case there are any suppression errors, add them here. Otherwise add them to delivery options array.
  if (data?.supressedDeliveryOptions?.length > 0) {
      data.supressedDeliveryOptions.map((error) => {
          options.suppressedErrors.push(error);
      });
  }

  if (data?.deliveryOptions?.length > 0) {
      sortDeliveryOptions(data.deliveryOptions).map((option, index) => {
          // Delivery options array so the radio buttons can be populated.
          options.deliveryOptions.push({
              id: option.shippingModeId || "",
              carrierCode: option.carrierCode || "",
              handleClick: () => handleOptionClick(option.shippingModeId || ""),
              checked: index == 0,
              disabled: false,
              label: option.desc || option.carrierCode || "",
              handleOnChange: () => {},
              secondaryLabel: getSecondaryLabel(option),
          });
      });
  }

  return options;
}

const DELIVERY_OPTION_CODES = {
    PICKUP: "PICKUP",
    INSTALL: "INSTALL",
    SELECT: "SELECT"
};

/**
 * Handles the cost label for delivery options, mainly to solve the $0 contact
 * for install price situation.
 * 
 * @param {object} option the option to parse
 * @returns {string} the shipping rate to render
 */
const getSecondaryLabel = option => {
    if (option.shippingRate > 0) {
        return formattedCurrency("USD", option.shippingRate);
    } else {
        if (option.carrierCode.toUpperCase().includes(DELIVERY_OPTION_CODES.INSTALL)) {
            // fixme we should work with backend to get a more robust solution to this.
            return "";
        } else {
            return i18n.t("LABEL_COMMON_FREE");
        }
    }
}

/**
 * Sorts delivery options in this order:
 * 
 * (1) ---- Pickup at dealer
 * (2) ---- Dealer install
 * (3..N) - Ship to home (price ascending)
 * 
 * @param {Object[]} options the delivery options to sort
 */
function sortDeliveryOptions(options) {
    const {PICKUP, INSTALL, SELECT} = DELIVERY_OPTION_CODES;
    const categories = {
        pickup: null,
        install: null,
        ship: []
    };

    // categorize the delivery options
    for (const option of options) {
        if (typeof option.carrierCode !== "string") continue;
        const carrierCode = option.carrierCode.toUpperCase();
        
        if (carrierCode.includes(PICKUP)) {
            categories.pickup = option;
        } else if (carrierCode.includes(INSTALL)) {
            categories.install = option;
        } else if (carrierCode === SELECT) {
            // suppress the "select a shipping method" option
            continue;
        } else {
            categories.ship.push(option);
        }
    }

    // ascending in-place sort by shipping rates for ship to home
    categories.ship.sort(({shippingRate: a}, {shippingRate: b}) => a - b);

    const {pickup, install, ship} = categories;
    return [pickup, install, ...ship].filter(opt => opt !== null); // pickup/install would be null if unavailable
}