import { callForVINInfo } from '../../../shared/Session/VehicleInfo/VehicleInfoWorker';
import { setCatalogData } from '../SearchService/SearchDataService';

// Called by watcher in RootSaga.js.
export function* handleLoadData() {
    //Call vin search to get vehicle info 
    yield callForVINInfo();
}

export function setCatalogDataServiceValue(catalogData) {
    setCatalogData(catalogData);
}
