import { call, put } from 'redux-saga/effects';
import { loadOrderHistoryDataAsync, loadOrderHistoryDataAsyncFail, loadOrderDataAsync, loadOrderDataAsyncFail, loadPersonalInfoDataAsync, loadPersonalInfoDataAsyncFail, loadPaymentInfoDataAsync, loadPaymentInfoDataAsyncFail } from './UserProfileCreator';
import { getOrderHistoryResponse, getOrderDataResponse, getPaymentInfoResponse, getPersonalInfo } from './UserProfileService';
import i18n from '../../../core/i18n/i18n';

export function * getOrderHistoryData() {
  try {
    
    const res = yield call(getOrderHistoryResponse);

    //TODO this may also need to parse the order based on the final response
    if(res && res.Order){
      const data = res;

      yield put(loadOrderHistoryDataAsync(data));
    }

  }
  catch (error) {
    yield put(loadOrderHistoryDataAsyncFail(error));
  }
}

export function * getPaymentInfoData() {
  
  try {
    
    const res = yield call(getPaymentInfoResponse);
    console.log(res);
    if(res){
      yield put(loadPaymentInfoDataAsync(res));
    }

  }
  catch (error) {
    yield put(loadPaymentInfoDataAsyncFail(error));
  }
}

export function * getOrderDetailsData() {
  
  try {
    
    const res = yield call(getOrderDataResponse);

    //TODO this may also need to parse the order based on the final response
    if(res && res.order){
      const data = parseOrderDetailsData(res);

      yield put(loadOrderDataAsync(data));
    }

  }
  catch (error) {
    yield put(loadOrderDataAsyncFail(error));
  }
}

export function * getPersonalInfoData() {
  try {

    //TODO remove C360 mock response
    const res = {
      profile_id: "cec88a2a-4200-4a3f-8862-bb469ae441fa",
      source_name: "",
      language_preference: "",
      locale: "en-US",
      created_by: "OC",
      create_date: "2019-07-18T12:36:54Z",
      external_ids: {
        onstar_number: "",
        gcin: " da0441bf-9e2c-4146-8501-a3aeb7ac509f",
        login_id: ""
      },
      iams: [
        {
          iam_name: "",
          guid: " 7fcc7e85-6966-46de-8fcd-11178e17009e"
        }
      ],
      person: {
        first_name: "Samuel",
        last_name: "Malone",
        middle_name: "",
        nick_name: "Mayday",
        suffix: "",
        salutation: "MR"
      },
      emails: [
        {
          email_id: 165,
          email_type: "WORK",
          email_address: "sam.malone@cheers.com"
        },
        {
          email_id: 126,
          email_type: "HOME",
          email_address: "sam.malone@google.com"
        }
      ],
      addresses: [
        {
          address_id: 525,
          address_type: "WORK",
          address_line1: "84 Beacon St",
          address_line2: "",
          address_line3: "",
          city: "Boston",
          district_or_county: "",
          state_or_province: "MA",
          postal_code: "02108",
          country_code: "US"
        },
        {
          address_id: 874,
          address_type: "HOME",
          address_line1: "140 Northern Ave",
          address_line2: "Unit 7M",
          address_line3: "",
          city: "Boston",
          district_or_county: "",
          state_or_province: "MA",
          postal_code: "02210",
          country_code: "US"
        }
      ],
      phones: [
        {
          phone_id: 324,
          phone_type: "WORK",
          country_code: "1",
          phone_number: "6172279605",
          phone_extension: ""
        },
        {
          phone_id: 489,
          phone_type: "HOME",
          country_code: "1",
          phone_number: "6175551212",
          phone_extension: ""
        }
      ]
    }
  
    //const res = yield call(getPersonalInfoData);
      if(res){
          console.log(res);
          yield put(loadPersonalInfoDataAsync(res));
      }

  }
  catch (error) {
      yield put(loadPersonalInfoDataAsyncFail(error));
  }
}

export function parseOrderDetailsData(response) {
  const responseOrder = response.order;
  let parsedResponse = responseOrder;
  if(responseOrder != undefined){
    parsedResponse =  {
      createdBy : responseOrder.createdBy,
      customerInfo : responseOrder.customerInfo,
      dealer : responseOrder.dealer,
      orderInfo : parseOrderInfo(responseOrder.orderInfo),
      shipAddressId : responseOrder.shipAddressId,
      shipping : responseOrder.shipping,
      submittedBy : responseOrder.submittedBy,
      vehicles : responseOrder.vehicles
    }
  }
  return parsedResponse
}

/* Parses the data in the orderInfo section of the order response, mostly handles formatting the Dates/Currency values*/
export function parseOrderInfo(orderInfo) {

  let parsedOrderInfo = {
    addressId: orderInfo.addressId,
    adiTotalCost: orderInfo.adiTotalCost,
    formattedAdiTotalCost : formatCurrencyString(orderInfo.adiTotalCost, orderInfo.currency),
    adjustment: orderInfo.adjustment,
    commissionPlanName: orderInfo.commissionPlanName,
    createDate: orderInfo.createDate,
    formattedCreateDate : formatDateString(orderInfo.createDate),
    currency: orderInfo.currency,
    currentOrderStatus: orderInfo.currentOrderStatus,
    deliveryType: orderInfo.deliveryType,
    domain: orderInfo.domain,
    fedEx: orderInfo.fedEx,
    formattedFedExAmmount : formatCurrencyString(orderInfo.fedEx, orderInfo.currency),
    installation: orderInfo.installation,
    formattedInstallation : formatCurrencyString(orderInfo.installation, orderInfo.currency),
    lastUpdateDate: orderInfo.lastUpdateDate,
    formattedLastUpdateDate:  formatDateString(orderInfo.lastUpdateDate),
    orderId: orderInfo.orderId,
    orderSource: orderInfo.orderSource,
    orderStatus: orderInfo.orderStatus,
    placedDate: orderInfo.placedDate,
    formattedPlacedDate: formatDateString(orderInfo.placedDate),
    poNumber: orderInfo.poNumber,
    recordSetTotal: orderInfo.recordSetTotal,
    shipModeId:  orderInfo.shipModeId,
    storeId: orderInfo.storeId,
    subtotal: orderInfo.subtotal,
    formattedSubtotal : formatCurrencyString(orderInfo.subtotal, orderInfo.currency),
    total: orderInfo.total,
    formattedTotal : formatCurrencyString(orderInfo.total, orderInfo.currency),
    totalAdjustment: orderInfo.totalAdjustment,
    formattedTotalAdjustment : formatCurrencyString(orderInfo.totalAdjustment, orderInfo.currency),
    totalSalesTax: orderInfo.totalSalesTax,
    totalShippingTax: orderInfo.totalShippingTax,
    formattedTotalSalesTax : formatCurrencyString(orderInfo.totalShippingTax, orderInfo.currency),
    totalTax: orderInfo.totalTax,
    formattedTotalTax : formatCurrencyString(orderInfo.totalTax, orderInfo.currency),
  }
  return parsedOrderInfo;
}

/* Formats the date based on language selected in i18n, currently returns standard mm/dd/yy*/
export function formatCurrencyString(value, currency) {
  const language = i18n.language;
  let parsedTotalString = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency
  }).format(Number(value));

  return parsedTotalString;
}

/* Formats the prices on language selected in i18n, currently returns standard mm/dd/yy*/
export function formatDateString(dateString) {
  let date = new Date(dateString);
               
  const language = i18n.language;
  const placedDate = new Intl.DateTimeFormat(language).format(date);
  
  return placedDate;
}
