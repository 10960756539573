import React from 'react';
import propTypes from 'prop-types';
import { Translation } from 'react-i18next';

/**************************************************************/
//                  BUTTON 
/**************************************************************/
// NOTES:
// Displays a header in between lines, veritcally, horizontally, 
// or both depending on screen sizes (THIS IS THE DEFAULT BEHAVIOR), with vertical line on larger 
// devices and horizontal line on smaller devices

// HORIZONTAL LINE HEADER:
// ------- CUSTOM TEXT ------- 

// VERTICAL LINE HEADER:
//       |
//       |
//       |
//  CUSTOM TEXT
//       |
//       |
//       |


/*

Example: 

1.) Regular HeaderBetweenLines (Secondary):

    <HeaderBetweenLines
        localeKey="LABEL_HEADER_CUSTOM_TEXT" />

*/

HeaderBetweenLines.propTypes = {
    localeKey: propTypes.string.isRequired, // translation text, required
    horizontal: propTypes.bool, // For horizontal line header
    vertical: propTypes.bool, // For vertical line header (if horizontal and vertical are both true, it'll change according to screen size)
    gray: propTypes.bool // changes background color of text
}

function HeaderBetweenLines(props) {

    return (
        <Translation>
            {(t) => (
                <div className={
                    (props.vertical && !props.horizontal) ? 'ac-line-header-wrapper-vertical' :
                        (props.horizontal && !props.vertical) ? 'ac-line-header-wrapper-horizontal' :
                            (props.vertical && props.horizontal) ? 'ac-line-header-wrapper' : 
                            'ac-line-header-wrapper'}>
                    <div className="ac-line"></div>
                    <div className="ac-line-header-text-wrapper">
                        {/* HEADING TEXT */}
                        <h2 className='q-headline2 ac-line-header-text'><span className={props.gray ? 'ac-line-gray' : 'ac-line-white'}>{t(props.localeKey)}</span></h2>
                    </div>
                </div>
            )}</Translation>
    )
}

export default HeaderBetweenLines;