import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from '../../../core/Redux/Store';
import { loadFooterDataAsync } from './FooterRedux/FooterActionCreator';
import AppSettings from "../../../core/AppSettings";
import {loadLiveChatScript, sendLiveChatUserInfo, setLiveChatButtonAttributes} from '../../../shared/Utils/Utils';

class Footer extends Component {
  constructor(props) {
    super(props);
    store.dispatch(loadFooterDataAsync(AppSettings.pageName));
  }

  componentDidMount() {

    if (this.props.footerData && this.props.footerData.loading == false) {
      this.appendAdChoicesScript();
    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.footerData && this.props.footerData.loading == false) {
      this.appendAdChoicesScript();
    }

    if (this.props.location !== prevProps.location) {
      store.dispatch(loadFooterDataAsync(AppSettings.pageName));
    }

    //checks if user is not on T3 site to loadLiveChatScript
    if (!AppSettings.isT3) {
      //LIVE CHAT BUTTON
      /** Only enable chat on pages that are not included in the vault value disable_chat. Chat uses a script that passes full urls including 
      information like tokens which is considered a security risk. In the case that vault doesnt have any values, default to enable**/
      const enableChat = process.env.REACT_APP_DISABLE_CHAT ? process.env.REACT_APP_DISABLE_CHAT.split(",").find(element => element !== location.pathname.split('/')[1]) : true;
      if (enableChat) {
        //Authenticated Live Chat
        if (this.props.authenticationData.checkRegistrationStatus === 'R') {
          let environmentName = 'server_environment_name_host_value';
          loadLiveChatScript(environmentName);
          if (this.props.authenticationData.userData) {
            //sends user data to the live chat bot
            sendLiveChatUserInfo(this.props.authenticationData.userData);
          }
          setLiveChatButtonAttributes(true);
        }
        //Un-authenticated Live Chat
        else if (this.props.authenticationData.checkRegistrationStatus === 'G') {
          let environmentName = 'server_environment_name_host_value';
          loadLiveChatScript(environmentName);
          setLiveChatButtonAttributes(false);
        }
      }
    }
  }

  appendAdChoicesScript() {
    const isT3 = AppSettings.isT3;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    /*for setting the AdChoices link, there are diff values for Brands vs t3 Dealer. First is for Dealer, else is for Brands.*/
    if (isT3) {
      script.innerHTML = "(function() {var ev = document.createElement('script'); " +
          "ev.type = 'text/javascript'; ev.async = true; ev.setAttribute('data-ev-tag-pid', 827); " +
          "ev.setAttribute('data-ev-tag-ocid', 740);" +
          "ev.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'c.betrad.com/pub/tag.js';" +
          "var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ev, s);})();";
    } else {
      script.innerHTML = "(function() {var ev = document.createElement('script'); " +
          "ev.type = 'text/javascript'; ev.async = true; ev.setAttribute('data-ev-tag-pid', 45); " +
          "ev.setAttribute('data-ev-tag-ocid', 797);" +
          "ev.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'c.betrad.com/pub/tag.js';" +
          "var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ev, s);})();";
    }
    // TODO: why is this breaking t3
    if(document.readyState === "complete"){
      let acfooter = document.getElementById('acfooter')

      if (acfooter) {
        acfooter.appendChild(script);
      }
    }
  }

  render() {
    if (this.props.footerData && this.props.footerData.loading == false) {
      return (
          <footer className="ac-footer gm-ow-footer" id="acfooter">
            <div className="row">
              <div className="ac-footer-content small-12 medium-8 xlarge-9  grid-column-alignment-left  columns" dangerouslySetInnerHTML={{ __html: this.props.footerData.links }}>
              </div>
              {/* {this.appendAdChoicesScript()} */}

              <div className="small-12 medium-4 xlarge-3  grid-column-alignment-left  q-grid-row-new-mobile columns ac-footer-slogan">
                <img src={this.props.footerData.slogan}></img>
              </div>
            </div>
            <div className="row">
              <p className="ac-footer-disclaimer small-12 columns q-text q-body2 q-invert" dangerouslySetInnerHTML={{ __html: this.props.footerData.text }}></p>
            </div>
          </footer >
      );
    } else {
      return (
          <footer className="ac-footer" id="acfooter">
          </footer>
      )
    }
  }

}

const mapStateToProps = state => {
  return {
    footerData: state.FooterReducer,
    authenticationData: state.Authentication
  }
}

export default connect(mapStateToProps)(Footer);
