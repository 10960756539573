import { call, put, select } from "redux-saga/effects";
import { setSimilarItemsData, setSimilarItemsDataFail } from "./SimilarItemsActionCreator";
import { getSimilarItemsData } from "./SimilarItemsService";
import AppSettings from "../../../../core/AppSettings";

const getYear = (state) => state.Session.vehicleConfig.year;
const getMake = (state) => state.Session.vehicleConfig.make;
const getModel = (state) => state.Session.vehicleConfig.model;
const getBodyStyle = (state) => state.Session.vehicleConfig.bodyId;
const getWheelBase = (state) => state.Session.vehicleConfig.wheelId;
const getTrim = (state) => state.Session.vehicleConfig.trimId;
const getDriveType = (state) => state.Session.vehicleConfig.driveId;
const getEngineBase = (state) => state.Session.vehicleConfig.engineId;
const getBodyNumDoors = (state) => state.Session.vehicleConfig.bodyNumDoorsId;
const getVin = (state) => state.Session.vin;

// called by watcher in RootSaga.js
export function* handleLoadSimilarItemsData(action) {
    yield callForSimilarItemsData(action.partTerminologyId, action.partNumber);
}

function* callForSimilarItemsData(partTerminologyId, partNumber) {
    try {
        const year = yield select(getYear);
        const make = yield select(getMake);
        const model = yield select(getModel);
        const bodyNumDoors = yield select(getBodyNumDoors);
        const bodyStyle = yield select(getBodyStyle);
        const wheelBase = yield select(getWheelBase);
        const trim = yield select(getTrim);
        const driveType = yield select(getDriveType);
        const engineBase = yield select(getEngineBase);
        const vin = yield select(getVin);

        const placeholderParam = !!vin
            ? `${partTerminologyId}/${vin}?langId=-1&responseFormat=json&searchType=100&partNumbers=${partNumber}&catalogId=${AppSettings.catalogId}`
            : `${partTerminologyId}/${year}/${make}/${model}?bodyType=${bodyStyle}&bodyNumDoors=${bodyNumDoors}&wheelBase=${wheelBase}&trim=${trim}&driveType=${driveType}&engineBase=${engineBase}&langId=-1&responseFormat=json&searchType=100&partNumbers=${partNumber}`;
        const { catalogEntryView } = yield call(getSimilarItemsData, placeholderParam);
        yield put(setSimilarItemsData(catalogEntryView));
    } catch (error) {
        yield put(setSimilarItemsDataFail(error));
    }
}
