/**
 * Validates an expected string variable for type and emptiness.
 * Returns the result along with an error message if applicable.
 */
export const isValidString = (str) => {
    let isValidString = {
        result: true,
        err: ''
    };

    if (typeof str != 'string') {
        isValidString = {
            result: false,
            err: 'unexpected type ' + typeof str
        };
        return isValidString;
    }

    if (str === '') {
        isValidString = {
            result: false,
            err: 'empty string'
        };
        return isValidString;
    }

    return isValidString;
};

/**
 * Validates an expected object variable for type and other errors.
 * Returns the result along with an error message if applicable.
 */
export const isValidObject = (obj) => {
    let isValidObject = {
        result: true,
        err: ''
    };

    if (typeof obj != 'object') {
        isValidObject = {
            result: false,
            err: 'unexpected type ' + typeof str
        };
        return isValidObject;
    }

    return isValidObject;
};

/**Check if parameter values are valid**/
export const isValidValue = (value) => {
    return (value !== '' && value !== 'null' && value !== undefined && value !== null);
}

const US_ZIPCODE_VALIDATION_REGEX = /^([0-9]{5})(-[0-9]{4}){0,1}$/;
/**
 * validate a zip code matches the USPS standard format, one of two forms:
 * 
 * NNNNN
 *   or 
 * NNNNN-NNNN
 * 
 * @param {any} zip a type-safe (could be five digit Number) zipcode to validate
 * @returns {boolean} true if the zipcode matches USPS formatting requirements
 */
export const validateUSAZip = (zip='') => US_ZIPCODE_VALIDATION_REGEX.test(String(zip).trim());
// could compose international validators to crate a generic validateZip in the future...

/**
 * Returns BaseMarketingSpotActivityData, an array which usually contains  
 * the data to be displayed from an eSpot. Because the enclosing 
 * MarketingSpotData may contain multiple BaseMarketingSpotActivityData arrays, 
 * index specifies which BaseMarketingSpotActivityData to return. In most 
 * cases, this value will simply be 0. If any part of the data structure is 
 * invalid, returns null.
 * 
 * For more information about the eSpot object: 
 *     https://www.ibm.com/support/knowledgecenter/en/SSZLC2_7.0.0/com.ibm.commerce.starterstores.doc/code/rsm_espot_fep8.htm 
 */
export const getBaseMarketingSpotActivityData = (data, index) => {
    const marketingSpotData = getMarketingSpotData(data);
    if (marketingSpotData) {
        const baseMarketingSpotActivityData =
            getBaseMarketingSpotActivityDataFromMarketingSpotData(marketingSpotData[index]);
        if (baseMarketingSpotActivityData) {
            return baseMarketingSpotActivityData;
        }
    }
    return null;
}

/**
 * Returns MarketingSpotData from eSpot response data if the data is valid, or 
 * otherwise returns null.
 */
export const getMarketingSpotData = (data) => {
    if (containsValidMarketingSpotData(data)) {
        return data.MarketingSpotData;
    }
    return null;
}

/**
 * Returns true if the eSpot response data contains a valid MarketingSpotData 
 * object, or otherwise returns false.
 */
export const containsValidMarketingSpotData = (data) => {
    const isValidMarketingSpotData = data
        && data.hasOwnProperty('MarketingSpotData')
        && Array.isArray(data.MarketingSpotData)
        && data.MarketingSpotData.length;
    return isValidMarketingSpotData;
}

/**
 * Returns BaseMarketingSpotActivityData from a MarketingSpotData object if the 
 * data is valid, or otherwise returns false.
 */
export const getBaseMarketingSpotActivityDataFromMarketingSpotData = (data) => {
    if (containsValidBaseMarketingSpotActivityData(data)) {
        return data.baseMarketingSpotActivityData;
    }
    return null;
}

/**
 * Returns true if the MarketingSpotData  data contains a valid 
 * BaseMarketingSpotActivityData object, or otherwise returns false.
 */
export const containsValidBaseMarketingSpotActivityData = (data) => {
    const isValidMarketingSpotActivityData = data
        && data.hasOwnProperty('baseMarketingSpotActivityData')
        && Array.isArray(data.baseMarketingSpotActivityData)
        && data.baseMarketingSpotActivityData.length;
    return isValidMarketingSpotActivityData;
}

