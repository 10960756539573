import {authorize} from "./GMWalletService";
import AppSettings from "../../../config/jest/mocks/appSettingsMock";
import vaultConstants from "../../../config/vault_constants";

const PAYMENT_TYPE_IMAGE_URL =  AppSettings.isLocalHost ? "https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/" : vaultConstants.GM_WALLET_ADYEN_PAYMENT_TYPE_URL_TARGETABLE;

export const handleSubmission = async (data, params, component, url) => {
    try {
        const res = await authorize(data, params);
        handleServerResponse(res, component);
    } catch (error) {
        console.error(error);
    }
};

const handleServerResponse = (res, component) => {
    if (res.action) {
        component.handleAction(res.action);
    } else {
        let stringRes = JSON.stringify(res);
        switch (res.data.resultCode) {
            case "Authorised":
                alert("Authorised: \n\n" + stringRes);
                // window.location.href = "/result/success";
                break;
            case "Pending":
                alert("Pending: \n\n" + stringRes);
            case "Received":
                alert("Received: \n\n" + stringRes);
                // window.location.href = "/result/pending";
                break;
            case "Refused":
                alert("Refused: \n\n" + stringRes);
                //window.location.href = "/result/failed";
                break;
            default:
                alert("Error: \n\n" + stringRes);
                // window.location.href = "/result/error";
                break;
        }
    }
};
/**
 * @paymentMethods payment methods full response
 * @configValues the values passed in by the configurable Adyen Parameters object before creating the dropin
 * @storedCardChosen passes in to detemine whether a stored card was chosen or payment Method was chosen
 * This formats the paymentMethodsConfiguration object that is included in the Adyend configuration. This is to specify certain
 * parameters to make it customizable for each type of payment method.
 * */
export const formatPaymentMethodsConfigParams = (paymentMethods, storedPayments, configValues, storedCardChosen) => {
    let paymentMethodsObj = { paymentMethods: paymentMethods, storedPaymentMethods: storedPayments };
    let paymentMethodsConfigurationFormatted = {};
    let standardParams = {
        showPayButton: true,
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: configValues.isBillingAddressRequired,
        enableStoreDetails: configValues.isEnableStoreDetails,
        showBrandsUnderCardNumber: false,
        data: {
            holderName: configValues.nameOnCard
        }
        //  name: "Credit or debit card",
    };
    let cardParams = {
        // brandsConfiguration: {
        //     visa: {
        //         icon: 'https://i.guim.co.uk/img/media/1010e695e472e5254b5d73388137df37bd05025f/55_265_6415_3851/master/6415.jpg?width=620&quality=85&auto=format&fit=max&s=b6500a8f449497dfdee113b0c30edaf1'}
        //         },
        positionHolderNameOnTop: true,
        ...standardParams,
        //  name: "Credit or debit card",
        amount: {
            value: configValues.totalAmount,
            currency: 0
        }
    };

    try {
        //no need to iterate through stored payment methods as they use the same configuration as the credit card payment method.
        if (paymentMethods?.length > 0) {
            //if stored card is not chosen, then iterate through paymentMethods and configure each payment Method.
            //Else, set the cardParams object to the 'card' value in the paymentMethodsConfiguration
            if (storedCardChosen === -1) {
                paymentMethods.forEach((val) => {
                    //Since there will always be a credit card payment method.
                    // This configuration is also used by 'storedPaymentMethods' when selected since they are mounted as type 'card'
                    if (val.name === "Credit Card") {
                        //has issues retrieving the credit card images when adding in brands parameter
                        // cardParams.brand = val.brands;
                        paymentMethodsConfigurationFormatted.card = cardParams;
                    } else {
                        paymentMethodsConfigurationFormatted[val.type] = standardParams;
                    }
                });
            } else {
                paymentMethodsConfigurationFormatted.card = cardParams;
            }
        }

        return { paymentMethodsObj, paymentMethodsConfigurationFormatted };
    } catch (error) {
        console.error(error);
    }
};
/**
 * @sessionResponse session full response
 * This formats the sessionResponse to the correct format that is needed for the AdyenCheckout configuration
 * */
export const formatSessionObj = (sessionResponse) => {
    let sessionObj = {};
    if (sessionResponse?.id) {
        sessionObj = {
            session: {
                id: sessionResponse.id,
                sessionData: sessionResponse.sessionData
            }
        };
    }
    return sessionObj;
};

export const encrypt = (value) => {
    let encrypted = "test_" + value;
    return encrypted;
};
/**
 * @paymentType Examples: visa, paywithgoogle, card
 * Returns the image of payment type in svg form
 * */
export const getPaymentTypeImage = (paymentType) => {
    return  PAYMENT_TYPE_IMAGE_URL + paymentType + ".svg";
};

//assigns DTM and class name to components based on their IDs
export function addDtmAttributesById(id, dataDtm, className) {
    const element = document.getElementById(id);
    if (element) {
        if (element.className.indexOf(className) < 0) {
            element.className += element.className ? " " + className : className;
        }
        element.setAttribute("data-dtm", dataDtm);
    }
}

/**
 *
 * @param attributeToGetElemBy - 'id' , 'className' , 'name' , 'iframe'
 * @param attributeValue - the specific unique string to look for within the attribute to help pinpoint that group of elements or that specific element
 * @param dataDtm - the value given by the Analytics team to assign the data-dtm attribute to
 * @param appendToClassNameVal - className given by Analytics team to append or add to the specific html element(s)
 * @param indexNeeded - if multiple elements are found and Analytics team want to number them according to index of top to bottom or left to right
 */
export function addDTMTag(attributeToGetElemBy, attributeValue, dataDtm, appendToClassNameVal, indexNeeded = false) {
    let selectorBeginning = "";
    let selectorEnding = "";

    //Building out the selector string to pass into the isElementLoaded function
    switch (attributeToGetElemBy) {
        case "id":
            selectorBeginning = "#";
            break;
        case "className":
            selectorBeginning = ".";
            break;
        case "name":
            selectorBeginning = "input[name='";
            selectorEnding = "']";
            break;
        case "iframe":
            selectorBeginning = "iframe[title='";
            selectorEnding = "']";
            break;
        default:
            console.log("Could not apply DTM Tag values: ", dataDtm, appendToClassNameVal);
            return;
    }

    // passing in the selector to wait until the element is loaded
    isElementLoaded(selectorBeginning + attributeValue + selectorEnding).then(() => {
        let element = {};
        // Once element is loaded, we will identify which method to use (attributeToGetElemBy) to get the specific attributeValue
        switch (attributeToGetElemBy) {
            case "id":
                addDtmAttributesById(attributeValue, dataDtm, appendToClassNameVal);
                return;
            case "className":
                element = document.getElementsByClassName(attributeValue);
                break;
            case "name":
                element = document.getElementsByName(attributeValue);
                break;
            case "iframe":
                element = document.getElementsByClassName("js-iframe");
                break;
            default:
                console.log("Could not apply DTM Tag values: ", dataDtm, appendToClassNameVal);
        }

        Object.keys(element).forEach((index) => {
            if (element) {
                if (element[index].className && element[index].className.indexOf(appendToClassNameVal) < 0) {
                    element[index].className = appendToClassNameVal
                        ? element[index].className + " " + appendToClassNameVal
                        : element[index].className;
                }
                indexNeeded
                    ? element[index].setAttribute("data-dtm", dataDtm + (parseInt(index) + 1))
                    : element[index].setAttribute("data-dtm", dataDtm);
            }
        });
    });
}

//function to add tags to the Add Payment Form
export function addAnalyticsToAddPaymentForm(isAuthUser) {
    //Set timeout function to help wait for the add payment form to load from Adyen
    setTimeout(() => {
        //Different data-dtm tags get passed depending if user is authenticated or guest
        if (isAuthUser) {
            //Cancel Add New payment link
            addDTMTag("id", "wallet-cancel-add-payment-link", "add payment", "stat-text-link");
            //Terms and conditions checkbox for adding payment method in wallet
            addDTMTag("className", "adyen-checkout__checkbox__input", "add payment", "stat-checkbox");
            //Confirm button
            addDTMTag("className", "adyen-checkout__button", "add payment", "stat-button-link");
            //US Consumer Privacy Statement
            addDTMTag("className", "privacy-statement-link", "add payment", "stat-text-link");
        } else {
            //Confirm button
            addDTMTag("className", "adyen-checkout__button", "checkout:payment information", "stat-button-link");
        }
    }, 1000);
}

//Pass in a valid selector to wait for an element to load on the DOM. Will search for element for 10 seconds.
const isElementLoaded = async (selector) => {
    let breakVal = 0;
    while (document.querySelector(selector) === null && breakVal === 0) {
        // Timeout of 10s to stop this function from querying the DOM if it cannot find the selector passed in
        setTimeout(() => {
            breakVal = 1;
            return null;
        }, 10000);
        await new Promise((resolve) => requestAnimationFrame(resolve));
    }
};

// verify's and returns if the valueToCheck (paymentMethod.walletType) is found within the walletTypesArr
export function isExternalWalletType(valueToCheck, walletTypesArr = ["N/A"]) {
    //if the valueToCheck is found or if the valueToCheck was undefined (due to the walletType value not being set yet from the backend when just adding the card to the wallet)
    // then it returns true, if neither then returns false
    return !!walletTypesArr.includes(valueToCheck);
}
