import { call, put, select } from 'redux-saga/effects';
import { setVINSearch, setVINSearchFail } from './VehicleInfoActionCreator';
import { getVehicleInfoByVin } from "./VehicleInfoService";
import queryString from 'query-string';

export const getVin = state => state.Session.vin;
export const getSearchCallLock = state => state.SearchServiceReducer.searchCallLock;

export function* callForVINInfo() {

  // check for vin from session reducer or url param
  let parsed = queryString.parse(location.search);  

  // check for vin from session reducer or url param  
  let vinFromSession = yield select(getVin);
  let vinFromUrl = parsed.vin;

  let vin = '';

  if (vinFromUrl) {
    vin = vinFromUrl;
  }
  if (!vinFromUrl && vinFromSession) {
    vin = vinFromSession;
  }
  if(vinFromSession && vinFromSession !== vinFromUrl){
    vin = vinFromSession;
  }
  // only call this with a vin search and not a ymm search when the page loads
  const isSearching = yield select(getSearchCallLock);
  if (vin && !isSearching) {
    try {
      const VINDataResponse = yield call(getVehicleInfoByVin, vin);
      // Check if valid VIN
      if (VINDataResponse.vehicleInfo) {
        const vehicleInfo = parseVINResponse(VINDataResponse);
        yield put(setVINSearch(vehicleInfo));
      }

      // TODO: If VIN is invalid, handle error
      if (VINDataResponse.errors) {
        const invalidVIN = parseVINResponse(VINDataResponse);
        yield put(setVINSearch(invalidVIN));
      }
    }

    catch (error) {
      yield put(setVINSearchFail(error));
    }
  }   
}

const parseVINResponse = (response) => {
  let results = {}

  // if vin is valid
  if (response.vehicleInfo) {
    results = response.vehicleInfo;
  }

  // if vin is invalid
  if (response.errors) {
    results = response.errors[0];
  }

  return results
}