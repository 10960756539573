import React, { useState, useEffect } from 'react';
import { Translation, Trans } from 'react-i18next';
import {pageTitle, Toggle} from "../../../shared/Hooks/Hooks";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import store from '../../../core/Redux/Store';
import Button from "../../../shared/Button/Button";
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import ErrorMessage from "../../../shared/ErrorMessage/ErrorMessage";
import Modal from "../../../shared/Modal";
import Spinner from "../../../shared/Spinner/Spinner";
import { getBrandUrl, scrubFormField } from '../../../shared/Utils/Utils';
import { getCountryStateList } from "../../Checkout/CheckoutRedux/CheckoutService";
import { getOrderIdData, postUpdateProfile, registerUser } from '../shared/AccountRegistrationAPI';
import AccountDetailsForm from "./AccountDetailsForm";
import AppSettings from "../../../core/AppSettings";
import { constructAzureLoginURL } from "../../../shared/Authentication/AuthenticationWorker";
import vaultConstants from '../../../../config/vault_constants';

const PHONE_TYPES = ["Mobile", "Home", "Work", "Other"];
const myRewardsLink = process.env.REACT_APP_MYREWARDS_LINK;

function AccountDetails(props) {
    pageTitle("Account Details");

    const [showSpinnerBtn, setShowSpinnerBtn] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const [redirectOCP, setRedirectOCP] = useState(false);
    const [showEnrollmentFailedModal, setShowEnrollmentFailedModal] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(true);
    const [stateList, setStateList] = useState([]);
    const [phoneType, setPhoneType] = useState(PHONE_TYPES[0]);
    const [isEnrollRewardsChecked, seIsEnrollRewardsChecked] = useState(false);
    const [covisintID, setCovisintID] = useState('');

    const [userData, setUserData] = useState(props.location.state ? props.location.state.userData : '');
    const [userState, setUserState] = useState("");
    let orderId = localStorage.getItem('OrderIdRegistration');
    const getCovisintID = props.authenticationData.userData.logonId;
    const getAuthError = props.authenticationData.errorMessage;
    const ff_2224243_caps_v3_migration = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2224243_CAPS_V3_MIGRATION_TARGETABLE) === "true";
    // These values are used in case user navigates to page without signing in
    const registrationStatus = props.authenticationData.registrationStatus;
    const registrationFetched = props.authenticationData.registrationStatusFetched;
    let registrationUserData = props.authenticationData.userData;

    // They will also see an error which can be triggered with this variable
    const [errorMessage, setErrorMessage] = useState(null);

    // Terms and conditions
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    const brand = AppSettings.currentSite.label

    useEffect(() => {
        if (getCovisintID) {
            setCovisintID(getCovisintID);
            setErrorMessage(null);
        }
        if (getAuthError) {
            setErrorMessage("LABEL_ERROR_BOUNDARY_REFRESH_PAGE");
        }
        //V1 implementation. User is still a guest in V3.
        if (!ff_2224243_caps_v3_migration && registrationStatus == "G" && registrationFetched) {
            setErrorMessage("LABEL_ERROR_PLEASE_SIGN_IN");
        }
    }, [getCovisintID, getAuthError, registrationStatus, registrationFetched]);

    const getBodyToUpdateProfile = (data) => {
        let body = {};
        if (!ff_2224243_caps_v3_migration) {
            body = {
                first_name: data.firstName ? scrubFormField(data.firstName) : 
                    userData && userData.firstName ? scrubFormField(userData.firstName) : '',
                last_name: data.lastName ? scrubFormField(data.lastName) : 
                    userData && userData.lastName ? scrubFormField(userData.lastName) : '',
                address_line_1: data.address1 ? scrubFormField(data.address1) : 
                    userData && userData.address1 ? scrubFormField(userData.address1) : '',
                address_line_2: data.address2 ? scrubFormField(data.address2) : 
                    userData && userData.address2 ? scrubFormField(userData.address2) : '',
                city: data.city ? scrubFormField(data.city) : 
                    userData && userData.city ? scrubFormField(userData.city) : '',
                state_or_province: data.state ? scrubFormField(data.state) : 
                    userData && userData.state ? scrubFormField(userData.state) : '',
                postal_code: data.postalCode ? scrubFormField(data.postalCode) : 
                    userData && userData.postalCode ? scrubFormField(userData.postalCode) : '',
                enrolled_in_rewards: isEnrollRewardsChecked
            };
        }
        if (ff_2224243_caps_v3_migration) {
            body = {
                firstName: data.firstName ? scrubFormField(data.firstName) : 
                    userData && userData.firstName ? scrubFormField(userData.firstName) : '',
                lastName: data.lastName ? scrubFormField(data.lastName) : 
                    userData && userData.lastName ? scrubFormField(userData.lastName) : '',
                address1: data.address1 ? scrubFormField(data.address1) : 
                    userData && userData.address1 ? scrubFormField(userData.address1) : '',
                address2: data.address2 ? scrubFormField(data.address2) : 
                    userData && userData.address2 ? scrubFormField(userData.address2) : '',
                city: data.city ? scrubFormField(data.city) : 
                    userData && userData.city ? scrubFormField(userData.city) : '',
                state: data.state ? scrubFormField(data.state) : 
                    userData && userData.state ? scrubFormField(userData.state) : '',
                zipCode: data.postalCode ? scrubFormField(data.postalCode) : 
                    userData && userData.postalCode ? scrubFormField(userData.postalCode) : '',
                enrolled_in_rewards: isEnrollRewardsChecked,
                username: props.location.state.email,
                password: props.location.state.password,
                token: props.location.state.token,
                locale: AppSettings.locale,
                brand: brand,
                orderId: orderId ? orderId : ""
            };
        }

        // if phone is empty, then phone and phone_type cannot be included in body
        if (data.phone) {
            // Removes the UI look of (xxx) xxx-xxxx, and sets phone value to xxxxxxxxxx to safely pass to /updateProfile api
            const phoneNum = data.phone.replace(/([\s-()])/g, '');
            if (!ff_2224243_caps_v3_migration) {
                body = Object.assign({ 'phone_number': "+1" + phoneNum }, body);
                // Make sure the back-end receives "Cell" instead of "Mobile". For UI purposes Mobile was preferred over Cell.
                body = Object.assign({ 'phone_type': phoneType == "Mobile" ? "Cell" : phoneType }, body);
            } else {
                body = Object.assign({ phone: "+1" + phoneNum }, body);
                // Make sure the back-end receives "Cell" instead of "Mobile". For UI purposes Mobile was preferred over Cell.
                body = Object.assign({ phoneType: phoneType == "Mobile" ? "MOBILE" : phoneType }, body);
            }
        }

        return body;
    };
    function redirectToLogin(routeLocation) {
        //Redirect User to login page
        let redirectPath = "/"; //Homepage is default page to send user to after sign on
        if (routeLocation == 'orderConfirmation') {
            redirectPath = `/OrderConfirmation?orderId=${orderId}`
        }
        const applicationState = { redirectPath: redirectPath };
        const applicationStateString = encodeURIComponent(JSON.stringify(applicationState));

        window.location.href = constructAzureLoginURL(
            AppSettings.azureLoginUserFlow, process.env.REACT_APP_AZURE_CLIENT_ID, applicationStateString, '/authenticate', AppSettings.currentSite.key, props.location.state.email
        );
    }

    function handleUpdateProfileButton() {
        setErrorMessage(null); // Clear error message
        setShowSpinnerBtn(true);

        let callBody = getBodyToUpdateProfile(store.getState().form.AccountDetailsForm.values);

        if (ff_2224243_caps_v3_migration) {
            if (!callBody.enrolled_in_rewards) {
                //TODO: If user has not opted to enroll in rewards, re verify that they do not want to before proceeding to create account
            }
            registerUser(callBody).then((response) => {
                if (response?.data?.status === "success") {
                    //TODO: Need to investigate what to do about rewards enrollment success and failure....shouuld we present to user and wait 3 seconds to redirect?
                    // If we came from OCP, then we want to redirect back to OCP instead of home page
                    if (userData && userData?.orderId) {
                        redirectToLogin("orderConfirmation");
                        localStorage.setItem("newUserThreeLogic", true);
                    } else {
                        redirectToLogin("home");
                    }
                } else {
                    handleErrorMessage("", "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                }
            }).catch((error) => {
                //if response does not return then display error message
                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            });
        } else {
            postUpdateProfile(covisintID, callBody).then(result => {
                if (result.status === "SUCCESS") {
                    //check for rewards enrollment success if user checks box.
                    if (result.gmrewards_success === false && isEnrollRewardsChecked) {
                        setShowEnrollmentFailedModal(true);
                    }
                    //show modal for successful enrollment into rewards
                    if (result.gmrewards_success && isEnrollRewardsChecked){
                        localStorage.setItem("showEnrolledConfirmationModal", true);
                    }

                    // If we came from OCP, then we want to redirect back to OCP instead of home page
                    if (userData && userData.orderId) {
                        setRedirectOCP(true);
                        localStorage.setItem('newUserThreeLogic', true);
                    }
                    setRedirectHome(true);
                } else {
                    //if response is successful but some reason doesnt have data, then display error message
                    handleErrorMessage("", "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                }
            }).catch((error) => {
                //if response does not return then display error message
                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            });
        }
        setShowSpinnerBtn(false);
    }

    useEffect(() => {
        if (!ff_2224243_caps_v3_migration) {
            // registrationUserData has to be added because the @self call for the OCP flow gets called first once, and then a second time, so it was causing issues with the first @self
            // After the second @self the userData gets populated, alloweing for this call to be made to prepopulate the user data
            if (orderId && Object.entries(registrationUserData).length > 0) {
                getOrderIdData(orderId, "").then(result => {
                    if (result && result.profile_exists && result.matched) {
                        let newData = {
                            "firstName": result.customer_info.first_name,
                            "lastName": result.customer_info.last_name,
                            "phone": result.customer_info.phone_number,
                            "address1": result.customer_info.address_line_1,
                            "address2": result.customer_info.address_line_2,
                            "city": result.customer_info.city,
                            "state": result.customer_info.state_or_province,
                            "postalCode": result.customer_info.postal_code,
                            "password": props.password ? props.password : ""
                        }
                        setUserData(newData);
                    }
                    // Remove this from local storageafter all is set and done.
                    localStorage.removeItem('OrderIdRegistration');
                }).catch(err => {
                    handleErrorMessage(err, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                })
            }
        } else if (orderId){
            getOrderIdData(orderId, props.location.state.email).then(result => {
                if (result) {
                    let newData = {
                        "firstName": result.customer_info.first_name,
                        "lastName": result.customer_info.last_name,
                        "phone": result.customer_info.phone_number,
                        "address1": result.customer_info.address_line_1,
                        "address2": result.customer_info.address_line_2,
                        "city": result.customer_info.city,
                        "state": result.customer_info.state_or_province,
                        "postalCode": result.customer_info.postal_code,
                        "password": props.password ? props.password : ""
                    }
                    setUserState(result.customer_info.state_or_province);
                    setUserData(newData);
                }
            }).catch(err => {
                handleErrorMessage(err, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            })
        }

        // Don't want to make this call again if the stateList has already been populated.
        if (stateList.length < 1) {
            getCountryStateList().then(result => {
                const updatedStateLists = [];
                if (result) {
                    const countryCodeStates = result.countryCodeStates;
                    for (const countryCodeObject of countryCodeStates) {
                        const code = countryCodeObject["code"];
                        updatedStateLists.push(code);
                    }
                    setStateList(updatedStateLists);
                }
                else {
                    //if response is successful but some reason doesnt have data, then display error message
                    handleErrorMessage("", "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
                }
            }).catch((error) => {
                //if response does not return then display error message
                handleErrorMessage(error, "LABEL_ERROR_BOUNDARY_GENERIC_ERROR");
            });
        }
    }, [props.authenticationData.userData]);//this empty array mimics componentDidMount()

    function handleErrorMessage(error, errorMessage) {
        // Hide spinner
        setShowSpinnerBtn(false);
        // Remove focus from button
        if (document.activeElement != document.body) {
            document.activeElement.blur();
        }
        // Display error message
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
    }

    function updateUserData(value, updateType) {
        let newData = Object.assign({}, userData);
        // Update form state
        switch (updateType) {
            case "firstName":
                newData.firstName = value;
                break;
            case "lastName":
                newData.lastName = value;
                break;
            case "address1":
                newData.address1 = value;
                break;
            case "address2":
                newData.address2 = value;
                break;
            case "city":
                newData.city = value;
                break;
            case "state":
                newData.state = value;
                break;
            case "postalCode":
                newData.postalCode = value;
                break;
            case "state":
                newData.state = value;
                break;
            case "phone":
                newData.phone = value;
                break;
        }
        setUserData(newData);
    }

    function handleContinueShoppingModal() {
        // Hide modal
        Toggle(modalVisibility, setModalVisibility);
        window.location.href = '/';
    }

    function handleEnrollInRewardsCheckboxClick() {
        Toggle(isEnrollRewardsChecked, seIsEnrollRewardsChecked);
    }

    if (ff_2224243_caps_v3_migration) {
        // If updateProfile button is successful, then user is redirected to
        // an Enrollment modal or Home page
        if (redirectHome) {
            if (showEnrollmentFailedModal) {
                return (
                    <Modal
                        open={modalVisibility}
                        onClose={() => handleContinueShoppingModal()}
                        closeOnOverlayClick={true}
                        center>
                        <Translation>
                            {(t) => (
                                <div>
                                    <div className="q-headline2">
                                        {t('LABEL_LOYALTY_ENROLLMENT_ERROR')}
                                    </div>
                                    <p>
                                        <Trans i18nKey="LABEL_LOYALTY_ENROLLMENT_ERROR_INSTRUCTIONS">
                                            {/* Not hardcoded text - this is done for interpoliation */}
                                        Please visit My {{ brand: brand }} Rewards
                                        <a className="ac-accessible-link-underline" href={myRewardsLink} target="_blank">{{ myRewardsLink }}</a>
                                        </Trans>
                                    </p>
                                    <div className='ac-small-margintop'>
                                        <Button
                                            localeKey="LABEL_COMMON_CONTINUE_TO_ACCESSORIES"
                                            onClick={() => handleContinueShoppingModal()} />
                                    </div>
                                </div>
                            )}
                        </Translation>
                    </Modal>
                )
            }
            else {
                if (redirectOCP) {
                    // Came from OCP, so redirect back to OCP
                    window.location.href = '/orderconfirmation?orderId=' + userData.orderId;
                } else {
                    window.location.href = '/';
                }
            }
        }
    }
    
    return (
        (!ff_2224243_caps_v3_migration && !errorMessage && !covisintID && (
            <CenterGrid headerLabel="LABEL_CHECKOUT_SUBMIT_ORDER_LOADING">
                <Spinner className="q-loader" />
            </CenterGrid>
        )) ||
        (errorMessage && !covisintID && !ff_2224243_caps_v3_migration &&(
            <CenterGrid headerLabel="">
                <ErrorMessage icon={true} message={errorMessage} />
            </CenterGrid>
        )) ||
        (covisintID && !ff_2224243_caps_v3_migration && (
                <CenterGrid headerLabel="LABEL_ACCOUNT_DETAILS_HEADER">
                    <div className="margin-medium">
                        {errorMessage && <ErrorMessage icon={true} message={errorMessage} />}
                        <AccountDetailsForm
                            initialValues={{
                                firstName: userData.firstName,
                                lastName: userData.lastName,
                                phone: userData.phone,
                                address1: userData.address1,
                                address2: userData.address2,
                                city: userData.city,
                                state: userData.state,
                                postalCode: userData.postalCode
                            }}
                            phoneTypes={PHONE_TYPES}
                            stateList={stateList}
                            ownerCenterLink={ownerCenterLink}
                            privacyLink={privacyLink}
                            onPhoneTypeChange={(e) => setPhoneType(e.target.value)}
                            onEnrollRewardsChange={handleEnrollInRewardsCheckboxClick}
                            isEnrollRewardsChecked={isEnrollRewardsChecked}
                            onSubmit={() => handleUpdateProfileButton()}
                            isLoading={showSpinnerBtn}
                            errorMessage={errorMessage}
                            brand={brand}
                            prefillEnrollment={userData.prefillCheckbox ? userData.prefillCheckbox : false}
                        />
                    </div>
                </CenterGrid>
        )) ||
        (ff_2224243_caps_v3_migration && (
            <CenterGrid headerLabel="LABEL_ACCOUNT_DETAILS_HEADER">
                <div className="margin-medium">
                    {errorMessage && <ErrorMessage icon={true} message={errorMessage} />}
                    <AccountDetailsForm
                        initialValues={{
                            firstName: userData?.firstName ? userData.firstName : "",
                            lastName: userData?.lastName ? userData.lastName : "",
                            phone: userData?.phone ? userData.phone : "",
                            address1: userData?.address1 ? userData.address1 : "",
                            address2: userData?.address2 ? userData.address2 : "",
                            city: userData?.city ? userData.city : "",
                            postalCode: userData?.postalCode ? userData.postalCode : "",
                            state: userState
                        }}
                        phoneTypes={PHONE_TYPES}
                        stateList={stateList}
                        ownerCenterLink={ownerCenterLink}
                        privacyLink={privacyLink}
                        onPhoneTypeChange={(e) => setPhoneType(e.target.value)}
                        onStateChange={(e) => setUserState(e.target.value)}
                        onFirstNameChange={(e) => updateUserData(e.target.value, "firstName")}
                        onLastNameChange={(e) => updateUserData(e.target.value, "lastName")}
                        onPhoneChange={(e) => updateUserData(e.target.value, "phone")}
                        onAddress1Change={(e) => updateUserData(e.target.value, "address1")}
                        onAddress2Change={(e) => updateUserData(e.target.value, "address2")}
                        onCityChange={(e) => updateUserData(e.target.value, "city")}
                        onPostalChange={(e) => updateUserData(e.target.value, "postalCode")}
                        onEnrollRewardsChange={handleEnrollInRewardsCheckboxClick}
                        isEnrollRewardsChecked={isEnrollRewardsChecked}
                        onSubmit={() => handleUpdateProfileButton()}
                        isLoading={showSpinnerBtn}
                        errorMessage={errorMessage}
                        brand={brand}
                        prefillEnrollment={userData?.prefillCheckbox ? userData.prefillCheckbox : false}
                        ff_2224243_caps_v3_migration= {ff_2224243_caps_v3_migration}
                    />
                </div>
            </CenterGrid>
        ))
    );
}
const mapStateToProps = state => {
    return {
        authenticationData: state.Authentication
    }
}

export default connect(mapStateToProps)(AccountDetails);
