import React, { useState } from 'react';

import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import Button from '../../../shared/Button/Button';

import FormInstructions from '../../../shared/Form/FormInstructions';
import { Translation, Trans } from 'react-i18next';
import { pageTitle } from "../../../shared/Hooks/Hooks";
import { Redirect } from "react-router";
import Footnote from "../../../shared/Footnote/Footnote";
import { handleUserLogin, getBrandUrl } from '../../../shared/Utils/Utils';

function UserAlreadyExists(props) {
    pageTitle("User Already Exists");

    let email = props.location.state ? props.location.state.email : "";
    // The user will be redirected to this page if they try to go directly to /register/accountConfirmation.
    // They will also see an error which can be triggered with the noEmailError variable.
    if (email == "" || email == null) {
        return <Redirect to={{ pathname: '/register', state: { noEmailError: true } }} />
    }
    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

    // Terms and conditions
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    return (
        <Translation>
            {
                (t) => (

                    <CenterGrid headerLabel={t("LABEL_ACCOUNT_REGISTRATION_USER_ALREADY_EXISTS")}>
                        <div className="ac-grid-email-confirmation">

                            <FormInstructions instructions={[
                                { text: "LABEL_ACCOUNT_REGISTRATION_USER_ALREADY_EXISTS_MSG" },
                                { text: 'LABEL_ACCOUNT_REGISTRATION_EMAIL', name: email },
                            ]} />

                            <Button type="submit"
                                className="stat-button-link"
                                data-dtm="continue to sign in"
                                disabled={false}
                                localeKey={"LABEL_ACCOUNT_REGISTRATION_CONTINUE_TO_LOGIN"}
                                onClick={() => handleUserLogin('/', email)}
                                isLoading={false}
                            />
                            <div>
                                <Trans i18nKey="LABEL_ACCOUNT_REGISTRATION_CREATE_ACCOUNT_WITH_DIFF_USER_REDIRECT">
                                    {/* Not hardcoded text - this is done for interpoliation */}
                                    CREATE ACCOUNT WITH DIFFERENT EMAIL
                                    <a className="q-text q-body2 stat-text-link" data-dtm="create another account"  onClick={() => createAccountRedirect()}>ADDRESS</a>
                                </Trans>
                            </div>
                            <br />
                            <br />
                            <Footnote
                                localeKey="LABEL_ACCOUNT_REGISTRATION_TERMS_CONDITIONS_FOOTNOTE"
                                href={[ownerCenterLink, privacyLink]}
                            />
                            <div className="text-center">
                                <Trans i18nKey="LABEL_PASSWORD_RESET_PHONECALL">
                                    {/* Not hardcoded text - this is done for interpolation */}
                                    Contact us at:
                                    <a href={"tel:" + phoneNumber}>{{ phoneNumber }}</a>
                                </Trans>
                            </div>
                        </div>
                    </CenterGrid>
                )
            }
        </Translation>
    )
};

const createAccountRedirect = () => {
    window.location.href = '/register';
}

export default UserAlreadyExists;