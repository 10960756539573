
import React, { Component } from 'react';
import CategoryItems from './CategoryNavComponents/CategoryItems';
import { connect } from 'react-redux';

class CategoryNav extends Component {
    constructor(props) {
        super(props);
    }
/**
 * rendering the Category Nav top level with CategoryItems reference
 * if the props are lacking, Loading... will be presented until the props are loaded*/
    render() {
        const categoryData = this.props.categoryNavData;
        const parsedCategoryData = categoryData.parsedCategories;
        let selectedCategory = categoryData.selectedCategory;
        if (parsedCategoryData) {
            //if the selectedCategory is still undefined, default to the parent
            if (selectedCategory == undefined) {
                selectedCategory = parsedCategoryData[0];
            }
            return (<CategoryItems
              currentCategory={selectedCategory}
              categories={parsedCategoryData[0]}
              searchParams={this.props.searchData.searchParams}
              vehicleConfig={this.props.vehicleConfig} />);
        }
        else {
            return (
                null
              );
        }
    }
}


/**************************************************************/
//          Map State to Props
/**************************************************************/
function mapStateToProps(state) {
    // State comes from store
    // Return value goes to props
    return {
        categoryNavData : state.CategoryNavReducer,
        searchData : state.SearchServiceReducer
    }
}

export default connect(mapStateToProps)(CategoryNav)
