import Cookies from "js-cookie";

import AppSettings from "../../core/AppSettings";
import vaultConstants from "../../../config/vault_constants";
import store from "../../core/Redux/Store";
import * as api from "./Data/ApiService";
import { 
  cleanResponseArr, 
  grabLocationParamFromCookie, 
  parseZipCode, 
  storeSearchZip 
} from "./Components/HelperFunctions";

const GM_CONTRACT_SUCCESS_TEXT = 
  "<!-- GM B2C Dealer Contract setup test  page --><!-- setup the cookies with contract to get dealer price -->";

const restApiContractsFeatureFlag = AppSettings.isLocalHost
? true
: String(vaultConstants.FF_2243221_REST_API_CONTRACTS)  === "true";

const LOGLEVEL = 
  AppSettings.isLocalHost || 
  vaultConstants.SERVER_ENVIRONMENT_NAME_HOST.includes('dev') ? 3 : 0;
const debug = {
  log: (level, ...props) => level <= LOGLEVEL && console.log(...props),
  warn: (level, ...props) => level <= LOGLEVEL && console.warn(...props),
  error: (level, ...props) => level <= LOGLEVEL && console.error(...props),
};

/**
 * Auto-dealerize a user by initiating this workflow.
 * Will attempt to use browser location, falls back to Akamai
 * zipcode if that is not entered.
 * 
 * The routine will have no effect if the user is already dealerized.
 * 
 * The routine will fail if location is rejected AND Akamai is
 * unavailable.
 * 
 * @returns {boolean} true if the user is dealerized at the end of this operation.
 */
export const autoDealerizeByBestAvailableLocation = async () => {
  const {DealerizationReducer: dr} = store.getState();
  const {dispatch} = store;
  debug.log(2, 'will run auto-dealerize:\n', dr);
  
  try {
    const isDealerized = await safeCheckDealerization(dr);
    if (typeof isDealerized == "boolean") return isDealerized;
  
    const willDealerize = willUserDealerizeByBAC(window.location.href);
    if (willDealerize === true) return false; // false b/c we will not dealerize by location.
  
    const geolocateResult = await autoDealerizeByGeolocation(dr, dispatch);
    if (typeof geolocateResult == "boolean") return geolocateResult;
  
    const zipcodeResult = await autoDealerizeByLocationCookie(dr, dispatch);
    if (typeof zipcodeResult == "boolean") return zipcodeResult;
  
  } catch (e) {
    debug.error(0, "unexpected exception below occured in auto-dealerization.\n", e.toString());
  }

  debug.log(1, "Could not auto-dealerize user.");
  return false;
};

// checks for dealerization in redux or cookies
// returns true/undefined to indicate dealerization state
// returns false if an error occurs
/**
 * Checks for dealerization in redux and cookies
 * 
 * @param {any} state The redux state of DealerizationReducer
 * @returns {Promise<boolean=>} 
 * - true if the user is already dealerized
 * - undefined if the user is definitely not dealerized
 * - false if dealerization state is unknown
 */
const safeCheckDealerization = async (state) => {
  if (state.selectedSource) {
    debug.log(1, `user is already dealerized to: ${state.selectedSource}`);
    return true;
  } else {
    const cookieResponse = await api.readDealerCookie();
    debug.log(2, 'got cookie:\n', cookieResponse);
    if (cookieResponse.status === 200) {
      debug.log(
        1, 'user is already dealerized with cookie:\n', 
        JSON.parse(cookieResponse.data.cookie)
      );
      return true;
    } else if (cookieResponse.status !== 204) {
      // TODO: do we want to attempt dealerization if we get an inconclusive cookie response? The user might be dealerized.
      // (situation *shouldn't* occur, this is on same server as app with no ext. deps)
      console.warn("Unexpected response from readDealerCookie. User will have to select a seller.");
      return false;
    }
  }
};

/**
 * Checks if the user has a pending direct dealerization, which 
 * would be indicated by a bac in the passed url.
 * 
 * @param {string} url the url to check
 * @returns {boolean} true if the user will be automatically direct-dealerized
 */
const willUserDealerizeByBAC = (url) => {
  const hasDirectBac = /\?.*bac=([^&]+)/;
  if (hasDirectBac.test(url)) {
    debug.log(1, `User should directly dealerize to ${hasDirectBac.exec(url).pop()}`);
    return true;
  }
  return false;
};

/**
 * 
 * @param {any} state Redux state of DealerizationReducer
 * @param {function} dispatch the Redux dispatch function
 * @returns {Promise<boolean=>} 
 * - true if the user is now dealerized
 * - undefined if the user was not dealerized
 */
const autoDealerizeByGeolocation = async (state, dispatch) => {
  try {
    const res = [];
    const position = await new Promise((resolve, reject) => {
      // geolocation callback will resolve or reject our promise.
      if (typeof navigator.geolocation?.getCurrentPosition === "function") {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject("geolocation not supported.");
      }
    });

    const param = {
      lat: position.coords.latitude,
      long: position.coords.longitude,
    };
    const userZipCall = api.getZipCodeFromCoordinates(param.lat, param.long);
    dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
    res.push(await api.getClosestDealerByCoords(param));
    debug.log(2, "response from geo call:\n", res[0]);

    const nearbyDealers = cleanResponseArr(res[0].data.dealers);
    if (!Array.isArray(nearbyDealers) || nearbyDealers.length === 0) {
      throw new Error("no nearby dealers in coordinate response.");
    }
    const nearestDealer = nearbyDealers?.[0];
    await autoDealerizeWithDealer(nearestDealer, dispatch);
    
    try {
      res.push(await userZipCall);
    } catch (e) {
      debug.log(1, "userZipCall failed, will store matched dealer's zipcode for user.");
      res[1] = null;
    }
    const userZip = res[1]?.locationData?.short_name || res[1]?.locationData?.long_name || nearestDealer.zip;
    await handleReduxState({ state, dispatch, userZip, nearbyDealers });

    debug.log(
      1, 
      `%cUser has been dealerized to ${nearestDealer.bac} from ${userZip}`, 
      "color:white; background-color: #005dab; padding: 0.2rem;"
    );
    return true;
  } catch (e) {
    debug.log(1, "geolocation was not successful:\n", e?.toString?.() || e);
  }
};

/**
 * Auto-dealerizes the user with an Akamai/location cookie, if one exists
 * @param {any} state Redux state of DealerizationReducer
 * @param {function} dispatch the Redux dispatch function 
 * @returns {Promise<boolean=>}
 * - true if the user is now dealerized
 * - undefined if the user was not dealerized
 */
const autoDealerizeByLocationCookie = async (state, dispatch) => {
  const LOCATION_COOKIE_NAME = "GMWP_location";
  const hasLocationCookie = document.cookie.includes(LOCATION_COOKIE_NAME);

  if (!hasLocationCookie) {
    debug.log(1, "no location cookie detected.");    
  } else {
    const locationCookie = Cookies.get(LOCATION_COOKIE_NAME);

    if (!locationCookie.includes("zip=")) {
      debug.warn(0, "expected zip property not found in location cookie.");
    }
    
    const userZip = parseZipCode(grabLocationParamFromCookie(locationCookie).zip);
    storeSearchZip(userZip);

    try {
      dispatch({ type: "TOGGLE_CONTRACT", contractStatus: false });
      const res = await api.getClosestDealerByZipcode({location: userZip});
      debug.log(2, "response from zip call:\n", res);

      const nearbyDealers = cleanResponseArr(res.data.dealers);
      if (!Array.isArray(nearbyDealers) || nearbyDealers.length === 0) {
        throw new Error("no nearby dealers in coordinate response.");
      }
      const nearestDealer = nearbyDealers?.[0];
      await autoDealerizeWithDealer(nearestDealer, dispatch);
      await handleReduxState({ state, dispatch, userZip, nearbyDealers });
      
      debug.log(
        1, 
        `%cUser has been dealerized to ${nearestDealer.bac} from ${userZip}`, 
        "color:white; background-color: #005dab; padding: 0.2rem;"
      );
      return true;
    
    } catch (e) {
      debug.log(1, "set by detected zipcode was not successful:\n", e?.toString?.() || e);
    }
  }
};

/**
 * Handles the dealerization/contract step of dealerizing to a known dealer.
 * 
 * @typedef DealerEntry
 * @type {object}
 * @property {string} bac the dealer's bac
 * @property {string} vendorMake a make that this dealer supports
 * @property {string} zip the dealer's zipcode
 * @property {string} dealerName the full name of this dealer (ex. "HENNA CHEVROLET L.P.")
 * @property {string} id the unique id for this dealer
 *  
 * @param {DealerEntry} dealer a single dealer entry from a dealerLocate GET call
 * @param {function} dispatch the Redux dispatch function
 * @returns {Promise<boolean=>} true if the user was dealerized successfully
 */
const autoDealerizeWithDealer = async (dealer, dispatch) => {
  const updateRes = await api.updateSelectedDealer(dealer.bac);

  // an error response can return status 200 with HTML body
  if (updateRes.status === 200 && (restApiContractsFeatureFlag ? updateRes.data.viewTaskName === "GMContractSetupView" : updateRes.data === GM_CONTRACT_SUCCESS_TEXT)) {
    dispatch({ type: "TOGGLE_CONTRACT", contractStatus: true });
  } else {
    debug.error(0, "update dealer/contract call failed.");
  }

  return true;
}

/**
 * Handles some redux-related management following successful auto-dealerization
 * 
 * @typedef HandleReduxArgs
 * @type {object}
 * @property {any} state the DealerizationState from Redux
 * @property {function} dispatch the Redux dispatch function
 * @property {string} userZip the determined zipcode for this user's location
 * @property {DealerEntry[]} nearbyDealers the array(1) of dealers to show as search results
 * 
 * @param {HandleReduxArgs} params object with the required parameters
 * @returns {boolean} true once the operation completes
 */
const handleReduxState = (params) => {
  const { state, dispatch, userZip, nearbyDealers } = params;
  const nearestDealer = nearbyDealers[0];

  dispatch({
    type: "UPDATE_SELECTED_SOURCE",
    selectedDealerAllData: nearestDealer,
    selectedSource: nearestDealer.id,
  });
  dispatch({ type: "UPDATE_LIST_OF_ALL_SOURCES", listOfAllSources: nearbyDealers });
  dispatch({ type: "UPDATE_CURRENT_SELLER", currentSeller: nearestDealer });
  dispatch({ type: "UPDATE_CURRENT_SRC", currentSourceName: nearestDealer.dealerName });   
  // we use coordinates for auto-locate, but persist zip from coords or cookie below to use if user opens modal.
  dispatch({ type: "SOURCE_RESULTS", sourceResults: "Zip" }); 
  dispatch({ type: "UPDATE_SEARCH_TYPE", searchType: "Zip" });
  dispatch({ type: "UPDATE_CURRENT_ZIP", currentZip: userZip });
  api.setDealerCookie(JSON.stringify([nearestDealer.bac]), JSON.stringify([nearestDealer.vendorMake]), userZip);
  if (userZip) {
    storeSearchZip(userZip);
  }

  return true;
};