import i18next from 'i18next';

export const WalletTranslations = () => {
    let translations = {
        myWallet: {
            header: i18next.t('LABEL_WALLET_HOME_HEADER'),
            noPaymentMethods: i18next.t('LABEL_WALLET_HOME_NO_PAYMENT_METHODS'),
            editButton: i18next.t('LABEL_WALLET_HOME_EDIT_BUTTON'),
            removeButton: i18next.t('LABEL_WALLET_HOME_REMOVE_BUTTON'),
            addButton: i18next.t('LABEL_WALLET_HOME_ADD_BUTTON'),
            defaultPayMethod: i18next.t('LABEL_WALLET_HOME_DEFAULT_PAY_METHOD'),
            cardInfoTypeAndName: i18next.t('LABEL_WALLET_HOME_CARD_INFO_TYPE_AND_NUMBER'),
            cardInfoExpiry: i18next.t('LABEL_WALLET_HOME_CARD_INFO_EXPIRY'),
            cardInfoExpired: i18next.t('LABEL_WALLET_HOME_CARD_INFO_EXPIRED'),
            cardInfoName: i18next.t('LABEL_WALLET_HOME_CARD_INFO_NAME'),
        },
        addPayMethod: {
            header: i18next.t('LABEL_WALLET_ADD_HEADER'),
            subheader: i18next.t('LABEL_WALLET_ADD_SUBHEADER'),
            disclosures: [
                {
                    disclosure: i18next.t('LABEL_WALLET_ADD_DISCLOSURE_MAKE_DEFAULT'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_DISCLOSURE_MAKE_DEFAULT_REQUIRED_ERROR')
                },
                {
                    disclosure: i18next.t('LABEL_WALLET_ADD_DISCLOSURE_TERMS_AND_CONDITIONS')
                }
            ],
            creditCardForm: {
                nameOnCard: {
                    label: i18next.t('LABEL_WALLET_ADD_NAME_ON_CARD_LABEL'),
                    placeholder: i18next.t('LABEL_GMPAYMENT_NAME_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_NAME_ON_CARD_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_ADD_NAME_ON_CARD_INVALID_ERROR')
                },
                creditCard: {
                    label: i18next.t('LABEL_WALLET_ADD_CARD_NUMBER_LABEL'),
                    placeholder: i18next.t('LABEL_CHECKOUT_ENTER_CARD_NUMBER'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_CARD_NUMBER_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_ADD_CARD_NUMBER_INVALID_ERROR')
                },
                expiryMonth: {
                    label: i18next.t('LABEL_WALLET_ADD_EXPIRY_MONTH_LABEL'),
                    placeholder: i18next.t('LABEL_WALLET_ADD_EXPIRY_MONTH_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_EXPIRY_MONTH_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_ADD_EXPIRY_MONTH_INVALID_ERROR')
                },
                expiryYear: {
                    label: i18next.t('LABEL_WALLET_ADD_EXPIRY_YEAR_LABEL'),
                    placeholder: i18next.t('LABEL_WALLET_ADD_EXPIRY_YEAR_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_EXPIRY_YEAR_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_ADD_EXPIRY_YEAR_INVALID_ERROR')
                },
                securityCode: {
                    label: i18next.t('LABEL_WALLET_ADD_CVV_LABEL'),
                    placeholder: i18next.t('LABEL_GMPAYMENT_SECCODE_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_ADD_CVV_INVALID_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_ADD_CVV_INVALID_ERROR'),
                    toolTip: {
                        header: i18next.t('LABEL_GMPAYMENT_CVV_TOOLTIP_HEADER'),
                        instructions1: i18next.t('LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS'),
                        instructions2: i18next.t('LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS2'),
                        instructions3: i18next.t('LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS3'),
                        instructions4: i18next.t('LABEL_GMPAYMENT_CVV_TOOLTIP_INSTRCUTIONS4')
                    }
                }
            },
            buttons: [
                {
                    label: i18next.t('LABEL_WALLET_ADD_CANCEL_BUTTON')
                },
                {
                    label: i18next.t('LABEL_WALLET_ADD_SAVE_BUTTON')
                }
            ],
            errors: {
                sessionTimeoutError: i18next.t('LABEL_WALLET_ADD_ERROR_SESSION_TIMEOUT'),
                genericMastercardTechnicalError: i18next.t('LABEL_WALLET_ADD_GENERIC_MASTERCARD_TECHNICAL_ERROR'),
                invalidCardInfo: i18next.t('LABEL_WALLET_ADD_INVALID_CARD_ERROR'),
                payMethodNotSupported: i18next.t('LABEL_WALLET_ADD_PAY_METHOD_NOT_SUPPORTED_ERROR'),
                payMethodAlreadyExists: i18next.t('LABEL_WALLET_ADD_CARD_ALREADY_EXISTS_ERROR'),
                addPaymentFormIncomplete: i18next.t('LABEL_WALLET_ADD_CARD_FORM_INCOMPLETE_ERROR')
            },
            success: {
                successMessage: i18next.t('LABEL_WALLET_ADD_PAY_METHOD_SUCCESS')
            }

        },
        editPayMethod: {
            header: i18next.t('LABEL_WALLET_EDIT_HEADER'),
            subheader: i18next.t('LABEL_WALLET_EDIT_SUBHEADER'),
            instructions: [
                {
                    text: i18next.t('LABEL_WALLET_EDIT_INSTRUCTIONS_TEXT')
                }
            ],
            creditCardForm: {
                nameOnCard: {
                    label: i18next.t('LABEL_WALLET_EDIT_NAME_ON_CARD_LABEL'),
                    placeholder: "",
                    requiredError: i18next.t('LABEL_WALLET_EDIT_NAME_ON_CARD_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_EDIT_NAME_ON_CARD_INVALID_ERROR')
                },
                cardNumber: {
                    label: i18next.t('LABEL_WALLET_EDIT_CARD_NUMBER_LABEL'),
                    placeholder: i18next.t('LABEL_WALLET_EDIT_CARD_NUMBER_PLACEHOLDER'),
                },
                expiryMonth: {
                    label: i18next.t('LABEL_WALLET_EDIT_EXPIRY_MONTH_LABEL'),
                    placeholder: i18next.t('LABEL_WALLET_EDIT_EXPIRY_MONTH_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_EDIT_EXPIRY_MONTH_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_EDIT_EXPIRY_MONTH_INVALID_ERROR')
                },
                expiryYear: {
                    label: i18next.t('LABEL_WALLET_EDIT_EXPIRY_YEAR_LABEL'),
                    placeholder: i18next.t('LABEL_WALLET_EDIT_EXPIRY_YEAR_PLACEHOLDER'),
                    requiredError: i18next.t('LABEL_WALLET_EDIT_EXPIRY_YEAR_REQUIRED_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_EDIT_EXPIRY_YEAR_INVALID_ERROR')
                },
                securityCode: {
                    label: i18next.t('LABEL_WALLET_EDIT_CVV_LABEL'),
                    placeholder: "",
                    requiredError: i18next.t('LABEL_WALLET_EDIT_CVV_INVALID_ERROR'),
                    invalidError: i18next.t('LABEL_WALLET_EDIT_CVV_INVALID_ERROR')
                }
            },
            disclosures: [
                {
                    disclosure: i18next.t('LABEL_WALLET_EDIT_DISCLOSURE_MAKE_DEFAULT'),
                    requiredError: i18next.t('LABEL_WALLET_EDIT_DISCLOSURE_MAKE_DEFAULT_REQUIRED_ERROR')
                },
                {
                    disclosure: i18next.t('LABEL_WALLET_EDIT_DISCLOSURE_TERMS_AND_CONDITIONS'),
                    requiredError: i18next.t('LABEL_WALLET_EDIT_DISCLOSURE_TERMS_AND_CONDITIONS_REQUIRED_ERROR')
                }
            ],
            buttons: [
                {
                    label: i18next.t('LABEL_WALLET_EDIT_CANCEL_BUTTON')
                },
                {
                    label: i18next.t('LABEL_WALLET_EDIT_SAVE_BUTTON')
                }
            ],
            errors: {
                noChangesFound: i18next.t('LABEL_WALLET_EDIT_NO_CHANGES_FOUND_ERROR'),
                genericError: i18next.t('LABEL_WALLET_EDIT_GENERIC_TECHNICAL_ERROR')
            }
        },
        deletePayMethod: {
            deletePayMethodHeader: i18next.t('LABEL_WALLET_DELETE_HEADER'),
            deletePayMethodInstructions: [
                {
                    text: i18next.t('LABEL_WALLET_DELETE_INTRSUCTIONS_TEXT')
                }
            ],
            deletePayMethodButtons: [
                {
                    label: i18next.t('LABEL_WALLET_DELETE_CANCEL_BUTTON')
                },
                {
                    label: i18next.t('LABEL_WALLET_DELETE_REMOVE_BUTTON')
                }
            ],
            deleteDefaultPayMethodHeader: i18next.t('LABEL_WALLET_DELETE_DEFAULT_HEADER'),
            deleteDefaultPayMethodSubheader: i18next.t('LABEL_WALLET_DELETE_DEFAULT_SUBHEADER'),
            deleteDefaultPayMethodInstructions: [
                {
                    text: i18next.t('LABEL_WALLET_DELETE_DEFAULT_INTRSUCTIONS_TEXT')
                },
                {
                    text: i18next.t('LABEL_WALLET_DELETE_DEFAULT_INTRSUCTIONS_TEXT2')
                }
            ],
            disclosures: [
                {
                    disclosure: i18next.t('LABEL_WALLET_DELETE_DEFAULT_DISCLOSURE_SELECT_NEW_DEFAULT'),
                    requiredError: i18next.t('LABEL_WALLET_DELETE_DEFAULT_DISCLOSURE_SELECT_NEW_DEFAULT_REQUIRED_ERROR')
                }
            ],
            deleteDefaultPayMethodButtons: [
                {
                    label: i18next.t('LABEL_WALLET_DELETE_DEFAULT_CANCEL_BUTTON')
                },
                {
                    label: i18next.t('LABEL_WALLET_DELETE_DEFAULT_SAVE_BUTTON')
                }
            ],
            errors: {
                deleteGenericError: i18next.t('LABEL_WALLET_DELETE_PAY_METHOD_GENERIC_TECHNICAL_ERROR')
            }
        }
    }

    return translations
}
