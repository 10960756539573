import React from 'react';
import {isValidValue} from "../Validator/Validator";
import i18next from 'i18next';

/**
 * SHARED PAGE SPINNER COMPONENT:
 * Essentially the same code base from <Spinner/> but this spinner sits top of the page and hides page while loading...
 *
 *
 * Props (not really necessary, but if you need it....):
 *  message: string
 *    OPTIONAL ("div")
 *    if you need to use any massage, you can pass as message as props.
 *
 * Usage:
 *  1) Import the PageSpinner component from ./shared/Spinner/PageSpinner
 *
 *  2) Add the SharedPageSpinner component and pass in the items as a prop, e.g.:
 *      <PageSpinner
 *       message="This is Page Spinner"
 *      />
 *     or, you could just do this:     <PageSpinner/>
 */

const PageSpinner = (props)=>{
    const showText = isValidValue(props.message);
    const message = showText ? i18next.t(props.message):'';

    return <div className='ac-page-spinner'>
        <div className='ac-loader-wrapper'>
             <div className='q-loader'> </div>
            {
                showText && <div className='ac-loader-text'>{message}</div>
            }
        </div>
    </div>
};

export default PageSpinner;
