const vaultConstants = {
    UI_TARGETABLE: "ui_targetable_value",
    DAM_TARGETABLE: "dam_targetable_value",
    PAYMENT_TARGETABLE: "payment_targetable_value",
    PAYMENT_DEBUGFLAG_TARGETABLE: "payment_debugflag_targetable_value",
    WALLET_TARGETABLE: "wallet_targetable_value",
    WALLET_APP_STATUS_SERVICE_TARGETABLE: "wallet_app_status_service_targetable_value",
    WALLET_CLIENT_ID_TARGETABLE: "wallet_client_id_targetable_value",
    WALLET_USER_ID_TARGETABLE: "wallet_user_id_targetable_value",
    SEARCH_TARGETABLE: "search_targetable_value",
    DTM_TARGETABLE: "dtm_targetable_value",
    LIVECHAT_TARGETABLE: "livechat_targetable_value",
    VIEW_ON_VEHICLE_BASEURL_TARGETABLE: "viewOnVehicle_baseUrl_targetable_value",
    VIEW_ON_VEHICLE_USER_NAME_TARGETABLE: "viewOnVehicle_userName_targetable_value",
    VIEW_ON_VEHICLE_PASSWORD_TARGETABLE: "viewOnVehicle_password_targetable_value",
    VPM_TARGETABLE: "vpm_targetable_value",
    AZURE_LOGIN_TARGETABLE: "azure_login_targetable_value",
    AZURE_SSO_TARGETABLE: "azure_sso_targetable_value",
    AZURE_LOGIN_USER_FLOW: "azure_login_user_flow_value",
    AZURE_LOGIN_CHANNEL_TYPE: "azure_login_channel_type_value",
    AZURE_LOGOUT_TARGETABLE: "azure_logout_targetable_value",
    AZURE_CLIENT_ID_TARGETABLE: "azure_client_id_targetable_value",
    LOGIN_TIMEOUT: "login_timeout_value",
    LOYALTY_QUERY_MEMBER_CONTEXT: "loyalty_query_member_context_value",
    LOYALTY_GET_VOUCHERS_CONTEXT: "loyalty_get_vouchers_context_value",
    LOYALTY_DISPLAY_ALLOWANCE_CONTEXT: "loyalty_display_allowance_context_value",
    LOYALTY_TIMEOUT: "loyalty_timeout_targetable_value",
    LOYALTY_PART_NUMBER: "loyalty_part_number_targetable_value",
    LOYALTY_CATEGORY: "loyalty_category_targetable_value",
    LOYALTY_VOUCHER_ADJ_CODE: "loyalty_voucher_adjustment_code_targetable_value",
    LOYALTY_POINTS_ADJ_CODE: "loyalty_points_adjustment_code_targetable_value",
    LOYALTY_ENROLLMENT_POINTS: "loyalty_enrollment_points_targetable_value",
    SERVER_ENVIRONMENT_NAME_HOST: "server_environment_name_host_value",
    ROSCA_TERMS_AND_CONDITIONS_TARGETABLE: "rosca_terms_and_conditions_targetable_value",
    CA_FINANCIAL_INCENTIVE_DISCLOSURE_TARGETABLE: "ca_financial_incentive_disclosure_targetable_value",
    OWNER_CENTER_TERMS_CONDITIONS_TARGETABLE: "owner_center_terms_conditions_targetable_value",
    BUICK_TERMS_OF_SALE: "buick_terms_of_sale_targetable_value",
    CADILLAC_TERMS_OF_SALE: "cadillac_terms_of_sale_targetable_value",
    CHEVROLET_TERMS_OF_SALE: "chevrolet_terms_of_sale_targetable_value",
    GMC_TERMS_OF_SALE: "gmc_terms_of_sale_targetable_value",
    AUTH_HELP_PHONE: "auth_help_phone_number_value",
    AUTH_OC_PWD_MIN_LENGTH: "auth_oc_pwd_min_length_value",
    AUTH_OC_PWD_MAX_LENGTH: "auth_oc_pwd_max_length_value",
    AUTH_OC_PWD_SPECIAL_CHAR: "auth_oc_pwd_special_char_value",
    AUTH_OC_PWD_ONE_NUMBER: "auth_oc_pwd_one_number_value",
    AUTH_OC_PWD_UPPER_CASE_LETTER: "auth_oc_pwd_upper_case_letter_value",
    AUTH_OC_PWD_LOWER_CASE_LETTER: "auth_oc_pwd_lower_case_letter_value",
    MYREWARDS_LINK_TARGETABLE: "myrewards_link_targetable_value",
    DISCLAIMER_LOYALTY_POINTS_TARGETABLE: "disclaimer_loyalty_points_targetable_value",
    DISCLAIMER_CHECKOUT_COUPON_TARGETABLE: "disclaimer_checkout_coupon_targetable_value",
    DISCLAIMER_CARD_APPROVAL_TARGETABLE: "disclaimer_card_approval_targetable_value",
    DISCLAIMER_CARD_INTERNAL_POINTS_TARGETABLE: "disclaimer_card_internal_points_targetable_value",
    DISCLAIMER_CARD_EXTERNAL_POINTS_TARGETABLE: "disclaimer_card_external_points_targetable_value",
    DISCLAIMER_CARD_APR_TARGETABLE: "disclaimer_card_apr_targetable_value",
    DISCLAIMER_TIER_MEMBER_TARGETABLE: "disclaimer_tier_member_targetable_value",
    DISCLAIMER_EARN_TARGETABLE: "disclaimer_earn_targetable_value",
    DISCLAIMER_REDEEM_TARGETABLE: "disclaimer_redeem_targetable_value",
    FEDEX_TRACKING_BASE_URL_TARGETABLE: "fedex_tracking_base_url_targetable_value",
    REWARDS_BASE_URL_TARGETABLE: "rewards_base_url_targetable_value",
    REWARDS_FILENAME_TARGETABLE: "rewards_filename_targetable_value",
    NLS_AUTOSUGGEST_SECTION_LIMIT_TARGETABLE: "nls_autosuggest_section_limit_targetable_value",
    NLS_AUTOSUGGEST_MOBILE_LIMIT_TARGETABLE: "nls_autosuggest_mobile_limit_targetable_value",
    NLS_MIN_SEARCH_LENGTH_TARGETABLE: "nls_min_search_length_targetable_value",
    COUPON_BANNER_AUTO_OPEN_TIMEOUT: "coupon_banner_auto_open_timeout_value",
    B2C_UI_NODE_JS_LOGGER_LEVEL: "b2c_ui_node_js_logger_level_targetable_value",
    BUICK_FAQ_TARGETABLE: "buick_faq_targetable_value",
    CADILLAC_FAQ_TARGETABLE: "cadillac_faq_targetable_value",
    CHEVROLET_FAQ_TARGETABLE: "chevrolet_faq_targetable_value",
    GMC_FAQ_TARGETABLE: "gmc_faq_targetable_value",
    BUICK_ACCESSORIES_TAB_LOGO: "buick_accessories_tab_logo_targetable_value",
    CADILLAC_ACCESSORIES_TAB_LOGO: "cadillac_accessories_tab_logo_targetable_value",
    CHEVROLET_ACCESSORIES_TAB_LOGO: "chevrolet_accessories_tab_logo_targetable_value",
    GMC_ACCESSORIES_TAB_LOGO: "gmc_accessories_tab_logo_targetable_value",
    REDIRECT_POLICY_LINKS_JSON: "redirect_policy_links_json_value",
    ENABLE_FEATURE_FLAG_LOGS: "enable_feature_flag_logs_value",
    FF_2092326_PDP_DELIVERY_OPTIONS: "ff_2092326_pdp_delivery_options_value",
    ZIP_FROM_COORDINATES_TARGETABLE: "zip_from_coordinates_targetable_value",
    GM_WALLET_ADYEN_ENVIRONMENT_TARGETABLE: "gm_wallet_adyen_environment_targetable_value",
    GM_WALLET_ADYEN_PAYMENT_TYPE_URL_TARGETABLE: "gm_wallet_adyen_payment_type_url_targetable_value",
    GM_WALLET_WIDGET_SCRIPT_URL: "gm_wallet_widget_script_url_value",
    FF_1745770_USE_AZURE_WALLET_WIDGET: "ff_1745770_use_azure_wallet_widget_value",
    FF_2085403_DISPLAY_CATEGORIES: "ff_2085403_display_categories_value",
    FF_2097306_CLP_ROUTE: "ff_2097306_clp_route_value",
    FF_2098470_NLS_SEARCH_TERM_ASSOCIATION: "ff_2098470_nls_search_term_association_value",
    FF_2106248_REMOVE_COUPON_CHECKOUT: "ff_2106248_remove_coupon_checkout_value",
    FF_2106197_REMOVE_REWARD_CHECKOUT: "ff_2106197_remove_reward_checkout_value",
    FF_2097839_RIGHT_ORDER_SUMMARY: "ff_2097839_right_order_summary_value",
    IMG_COMING_SOON: "img_coming_soon_targetable_value",
    FF_2094634_DYNAMIC_CHECKOUT: "ff_2094634_dynamic_checkout_value",
    MINICART_MODAL_TIMEOUT_TARGETABLE: "minicart_modal_timeout_targetable_value",
    FF_2134531_SUPERSESSION_SERVICE: "ff_2134531_supersession_service_value",
    FF_2197227_INLINE_FITMENT_PDP_ACCESSORIES: "ff_2197227_inline_fitment_pdp_accessories_value",
    FF_2237162_HOME_PAGE_SEARCH_BAR: "ff_2237162_home_page_search_bar_value",
    FF_2207674_CLP_ENHANCEMENTS: "ff_2207674_clp_enhancements_value",
    FF_2170003_ORDER_CONFIRM_CONTACT: "ff_2170003_order_confirm_contact_targetable_value",
    FF_2237155_HOME_PAGE_CURRENT_VEHICLE: "ff_2237155_home_page_current_vehicle_value",
    GENERIC_CURRENT_VEHICLE_ICON: "generic_current_vehicle_icon_value",
    FF_2237155_NEW_FITMENT_CONTAINER_ACC: "ff_2237155_new_fitment_container_acc_value",
    FF_2224243_CAPS_V3_MIGRATION_TARGETABLE: "ff_2224243_caps_v3_migration_targetable_value",
    FF_2237161_NEW_FITMENT_CLP_ACC: "ff_2237161_new_fitment_clp_acc_value",
    FF_2243221_REST_API_CONTRACTS: "ff_2243221_rest_api_contracts_value",
    FF_2170003_ORDER_REVIEW_CONTACT: "ff_2170003_order_review_contact_value",
    FF_2246212_FITMENT_COOKIE: "ff_2246212_fitment_cookie_value",
    FITMENT_COOKIE_TIMER: "fitment_cookie_timer_value",
    UI_VAULT_CONTROLLER_SOURCE: "ui_vault_controller_source_value",
    FF_2169904_SWOF_SEARCH_PAGE: "ff_2169904_swof_search_page_value",
    FF_2274900_CA_RESIDENTS_FOOTNOTE: "ff_2274900_ca_residents_footnote_value",
    ENVIRONMENT_TRACK: "environment_track_value",
    DISABLE_CHAT: "disable_chat_value",
    FF_2258116_HIDE_SHOW_COMPANY: "ff_2258116_hide_show_company_value",
    FF_473706_FITMENT_RESET_IMPROVEMENTS: "ff_473706_fitment_reset_improvements_value",
    IS_AUTH_SITE: "is_auth_site_value",
    DEALER_CSS_TIMEOUT: "dealer_css_timeout_value",
};

/**
 * Feature flag parsing is enabled for flags with this syntax:
 *
 *   FF_<TFS feature#>_<short_feature_name>
 *
 *   these flags will be overwritten in-place to a value of type boolean.
 *   conversion status of these flags can be logged at startup.
 *
 * ex.
 *   FF_2103911_order_history
 *   FF_2085402_category_landing_page
 */
const featureFlagPattern = /^FF_[0-9]+_(.*)/;
const logFeatureFlagStatus = vaultConstants.ENABLE_FEATURE_FLAG_LOGS === "true";

const handleFeatureFlagStatus = (name, status) => console.log(`feature flag for ${name} was initialized as ${status}`);

const handleWarnNoFlagValue = (name) => console.warn(`no vault replacement was detected for feature flag: ${name}`);

const comprehendVaultEntry = (key, value) => {
    const featureFlagMeta = key.match(featureFlagPattern);

    if (key.endsWith("_JSON")) {
        let singleString = value.replace(/'/g, '"');
        value = singleString;
    } else if (Array.isArray(featureFlagMeta)) {
        const shortName = featureFlagMeta.pop();
        if (logFeatureFlagStatus && value.endsWith("_value")) {
            handleWarnNoFlagValue(shortName);
        }
        value = value === "true";
        // log the name of the feature as captured by regex if enabled
        logFeatureFlagStatus && handleFeatureFlagStatus(shortName, value);
    }
};

const comprehendAllVaultEntries = () => {
    for (const key in vaultConstants) comprehendVaultEntry(key, vaultConstants[key]);
};

comprehendAllVaultEntries();

// VAULT_CONTROLLER_DOM_ID = "applicationVaultOverrideUI";
globalThis?.VaultController?.initialize(vaultConstants);

module.exports = vaultConstants;
