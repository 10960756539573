import store from "../../../core/Redux/Store";
import queryString from "query-string";
import { call, put, select } from "redux-saga/effects";
import { isValidValue } from "../../../shared/Validator/Validator";
import {
    getImageDataService,
    getColorConfigDataService,
    byoBodyStyleService,
    getVehicleInfoForYearMakeModel
} from "./VoVService";
import {
    getVovDataWithParts,
    getVovDataWithoutParts,
    colorConfigData,
    retrievePartsRPOFromCGI,
    updateSearchResultWithVovData,
    updateProductDetailsWithVovData,
    vovDataInitialization,
    loadImageDataServiceFail,
    loadVOVImageDataWithoutPartsFail,
    loadVOVImageDataWithPartsFail,
    loadVOVFail,
    emptyCatelogEntryViewOnSearchResultPage,
    updateFullSearchResponseWithVoVData
} from "./VoVActionCreator";
import { updateVehicleInfo } from "../../Session/VehicleInfo/VehicleInfoActionCreator";

const ECOM_COLOR_RPO_ATTRIBUTE_NAME = "Color RPO";
const ECOM_PART_RPO_ATTRIBUTE_NAME = "RPO";
const ECOM_VOV_FLAG_ATTRIBUTE_NAME = "VOV";

const getSessionVehicleInfo = (state) => state.Session;
const getSearchServiceVehicleInfo = (state) => state.SearchServiceReducer;
const getPartRPOsForVehicle = (state) => state.VoVReducer.cgiPartsRPOList;
const getColorCodesForVehicle = (state) => state.VoVReducer.cgiColorCodeList;
const searchServiceReducer = (state) => state.SearchServiceReducer;

/***
 *
 * FOR YMM : this function will make a call to VPM to get series code and model year
 * This is excluded for vin since vehicle information is already included inside the vin response
 * on search result and product details page
 *
 *
 */
export function* callForVehicleInfo() {
    let vehicleInfo = {
        seriesCode: "",
        modelYear: ""
    };

    const sessionInfo = yield select(getSessionVehicleInfo);
    const year = sessionInfo.year;
    const makeId = sessionInfo.makeCode;
    const modelId = sessionInfo.vehicleConfig.modelId;
    const bodyId = sessionInfo.vehicleConfig.bodyId;
    const wheelId = sessionInfo.vehicleConfig.wheelId;
    const trimId = sessionInfo.vehicleConfig.trimId;
    const driveId = sessionInfo.vehicleConfig.driveId;
    const engineId = sessionInfo.vehicleConfig.engineId;
    console.warn("sessionInfo modelId", modelId);

    // check if sessionInfo parameter values are valid
    const isValidParam =
        isValidValue(modelId) &&
        isValidValue(bodyId) &&
        isValidValue(wheelId) &&
        isValidValue(trimId) &&
        isValidValue(driveId) &&
        isValidValue(engineId);
    // todo vov find out what to do for path without these vars
    let res;
    try {
        // only make YMM vehicleInfo call if sessionInfo params are valid
        if (isValidParam) {
            res = yield call(
                getVehicleInfoForYearMakeModel,
                year,
                makeId,
                modelId,
                bodyId,
                wheelId,
                trimId,
                driveId,
                engineId
            );
            if (res.responseCode && res.responseCode.indexOf("200") > -1 && res.vehicleInfo) {
                if (res.errorMessage) {
                    vehicleInfo.seriesCode = "";
                    vehicleInfo.modelYear = "";
                } else {
                    if (res.vehicleInfo && res.vehicleInfo.marketing) {
                        vehicleInfo = res.vehicleInfo;
                    }
                }
                // Need vehicleInfo to get this data too.
                store.dispatch(updateVehicleInfo(res.vehicleInfo));
            }
        }
    } catch (error) {
        yield put(loadVOVFail(error));
    }
    return vehicleInfo;
}

/***
 * This function is getting called in search result service,
 * this is add the vov data on search result response and dispatch an action ***/
export function* callCGIToGetDataForVovIcon() {
    //retrieves session
    let vehicleInfo = yield select(getSessionVehicleInfo);
    if ((!vehicleInfo || !vehicleInfo.make) && getSearchServiceVehicleInfo) {
        let _vehicleInfo = yield select(getSearchServiceVehicleInfo);
        if (_vehicleInfo?.vehicleInfo) {
            vehicleInfo = _vehicleInfo[0];
        }
    }

    let partRPOsForVehicle = yield select(getPartRPOsForVehicle);
    let colorCodesForVehicle = yield select(getColorCodesForVehicle);

    if (!partRPOsForVehicle || !colorCodesForVehicle) {
        let seriesCode = "";
        let modelYear = "";

        // VIN
        if (vehicleInfo?.vin && vehicleInfo.vin !== "" && vehicleInfo.vin !== null) {
            // check for marketing data to get seriesCode/modelYear
            if (vehicleInfo.marketing) {
                const seriesMmc = vehicleInfo.marketing.seriesMmc;
                const seriesPeg = vehicleInfo.marketing.seriesPeg;

                if (isValidValue(seriesMmc) && isValidValue(seriesPeg)) {
                    seriesCode = `${seriesMmc}_${seriesPeg}`;
                }

                modelYear = vehicleInfo.year ? vehicleInfo.year : "";
            }
            /**** Retrieving series code (MMD + packageRPO) if marketing data is not available ****/
            if (!isValidValue(seriesCode)) {
                const mmdCode = isValidValue(vehicleInfo.mmd) ? vehicleInfo.mmd : "";
                const packageRPO = isValidValue(vehicleInfo.packageRPO) ? vehicleInfo.packageRPO : "";

                if (isValidValue(mmdCode) && isValidValue(packageRPO)) {
                    seriesCode = `${mmdCode}_${packageRPO}`;
                }
            }
        }
        // YMM
        else {
            vehicleInfo = yield callForVehicleInfo();
            if (vehicleInfo.marketing) {
                const pegCode = vehicleInfo.marketing.peg;
                const mmcCode = vehicleInfo.marketing.mmc;

                if (isValidValue(mmcCode) && isValidValue(pegCode)) {
                    seriesCode = `${mmcCode}_${pegCode}`;
                }
                modelYear = vehicleInfo.year;
            }
            /**** Retrieving series code (MMD + packageRPO) if marketing data is not available ****/
            if (!isValidValue(seriesCode)) {
                const mmdCode = isValidValue(vehicleInfo.mmd) ? vehicleInfo.mmd : "";
                const packageRPO = isValidValue(vehicleInfo.packageRPO) ? vehicleInfo.packageRPO : "";

                if (isValidValue(mmdCode) && isValidValue(packageRPO)) {
                    seriesCode = `${mmdCode}_${packageRPO}`;
                }
            }
        }

        if (isValidValue(seriesCode) && isValidValue(modelYear)) {
            yield getAllPartsRPOFromCgiWorker(seriesCode, modelYear);
            partRPOsForVehicle = yield select(getPartRPOsForVehicle);
            colorCodesForVehicle = yield select(getColorCodesForVehicle);
        }
    }
    if (partRPOsForVehicle.length && colorCodesForVehicle.length) {
        /** This is only need to run on search result page ***/
        if (location.href.indexOf("/search") > -1) {
            yield setupVoVIconOnSearchResultPage(partRPOsForVehicle, colorCodesForVehicle);
        }
    }

    // Error out and don't display VOV at all if everything above fails
    else {
        yield put(loadVOVFail("callCGIToGetDataForVovIcon worker failed"));
    }
}

/***
 *   This function compare the cgi parts data with search result parts rpo
 *   and color code and set the flag if color code and parts rpo match
 ***/
function* setupVoVIconOnSearchResultPage(cgiPartsRPO, cgiColorCode) {
    const searchData = yield select(searchServiceReducer);
    const sessionData = yield select(getSessionVehicleInfo);
    let catalogEntryView = searchData.searchResponse.catalogEntryView || {};
    const emptyCatelogEntryView = isEmptyObj(catalogEntryView);

    if (catalogEntryView && !emptyCatelogEntryView) {
        let searchResultPartsRPO = ""; // get from search results response
        let searchResultColorCode = "";

        if (sessionData.options) {
            searchResultColorCode = sessionData.options.primaryColor; // given from vin
        } else {
            searchResultColorCode = cgiColorCode[0];
        }

        for (let i = 0; i < catalogEntryView.length; i++) {
            let enableProductVOV = false; // override flag from search results response
            // Get part RPO and vov override flag
            if (catalogEntryView[i].VOV) {
                catalogEntryView[i].VOV.map((item) => {
                    if (item.name == ECOM_VOV_FLAG_ATTRIBUTE_NAME && item.value == "Y") {
                        enableProductVOV = true;
                    }
                    if (item.name == ECOM_PART_RPO_ATTRIBUTE_NAME) {
                        searchResultPartsRPO = item.value;
                    }
                });
            }
            // TODO vov sometimes we may get color code like "1GP, 3PG,4PG" instead of "3PG". we need to split and compare against cgiColorCode
            if (enableProductVOV) {
                let hasPartsRPO = false;
                for (let j = 0; j < searchResultPartsRPO.length; j++) {
                    if (cgiPartsRPO.includes(searchResultPartsRPO[j])) {
                        hasPartsRPO = true;
                        break;
                    }
                }
                //catalogEntryView[i].vovIcon = cgiPartsRPO.includes(searchResultPartsRPO);
                catalogEntryView[i].vovIcon = hasPartsRPO;
            }
        }
        yield put(updateSearchResultWithVovData(searchData, searchResultPartsRPO, searchResultColorCode));
    } else if (emptyCatelogEntryView) {
        /** whenever we show the message "Unfortunately there were no accessories in the category you selected that fit your vehicle,
         * please see available accessories below for your vehicle." on search result page, we display the result without catagoryId ***/

        //Route to FullSearch
        handleFullSearchRoute();
    } else {
        yield put(loadVOVFail("setupVoVIconOnSearchResultPage failed"));
    }
}

/*** Check if Object is empty **/
const isEmptyObj = (obj) => Object.keys(obj).length === 0;
/** Check if its valid object ***/
const isValidResponse = (response) => {
    return response !== null && response !== undefined && typeof object;
};

/** If Catalog Entry view is empty, we show VOV on full search **/
export const handleFullSearchRoute = () => {
    const cgiPartsRPO = store.getState().VoVReducer.cgiPartsRPOList;
    const cgiColorCode = store.getState().VoVReducer.cgiColorCodeList;
    const fullSearchResponse = store.getState().SearchServiceReducer.fullSearchResponse;
    const sessionData = store.getState().Session;
    const catalogEntryView = fullSearchResponse.catalogEntryView || {};
    const validCatalogEntryView = isValidResponse(catalogEntryView);
    const emptyCatalogEntryView = isEmptyObj(catalogEntryView);

    if (emptyCatalogEntryView) {
        //If full search catalog emtpy comes back empty, set the flag to true so next Category call will trigger this function again.
        store.dispatch(emptyCatelogEntryViewOnSearchResultPage());
    } else if (!emptyCatalogEntryView && validCatalogEntryView) {
        let enableProductVOV = false; // override flag from search results response
        let searchResultPartsRPO = ""; // get from search results response
        let searchResultColorCode = "";

        if (sessionData.options) {
            searchResultColorCode = sessionData.options.primaryColor; // given from vin
        } else {
            searchResultColorCode = cgiColorCode[0];
        }

        for (let i = 0; i < catalogEntryView.length; i++) {
            // Get part RPO and vov override flag
            if (catalogEntryView[i].VOV) {
                catalogEntryView[i].VOV.map((item) => {
                    if (item.name == ECOM_VOV_FLAG_ATTRIBUTE_NAME && item.value == "Y") {
                        enableProductVOV = true;
                    }
                    if (item.name == ECOM_PART_RPO_ATTRIBUTE_NAME) {
                        searchResultPartsRPO = item.value;
                    }
                });
            }
            // TODO vov sometimes we may get color code like "1GP, 3PG,4PG" instead of "3PG". we need to split and compare against cgiColorCode
            if (enableProductVOV) {
                let hasPartsRPO = false;
                for (let j = 0; j < searchResultPartsRPO.length; j++) {
                    if (cgiPartsRPO.includes(searchResultPartsRPO[j])) {
                        hasPartsRPO = true;
                        break;
                    }
                }
                //catalogEntryView[i].vovIcon = cgiPartsRPO.includes(searchResultPartsRPO);
                catalogEntryView[i].vovIcon = hasPartsRPO;
            }
        }
        store.dispatch(
            updateFullSearchResponseWithVoVData(fullSearchResponse, searchResultPartsRPO, searchResultColorCode)
        );
    } else {
        store.dispatch(loadVOVFail("setupVoVIconOnSearchResultPage failed"));
    }
};

/** This function is responsible for
 1. Compare the cgi parts data with the PDP part rpo AND checks the productDetailsVOVFlag to determine if the product is available for VOV
 2. Determining the initial color with data from either
 a. the primaryColor value from a VIN search
 b. the default Color RPO value returned from commerce
 **/
export function* setupVoVIconOnProductDetailsPage(cgiPartsRPO) {
    const productDetailsData = store.getState().ProductDetailsReducer;
    const sessionData = yield select(getSessionVehicleInfo);
    let showVoVIcon = false;
    let productDetailsColorCode = "";
    let productDetailsPartsRPO = "";

    if (productDetailsData.VoV && productDetailsData.VoV.length) {
        let productDetailsVOVFlag = "N";
        const partVoVData = productDetailsData.VoV;

        if (sessionData.options && sessionData.options.primaryColor) {
            productDetailsColorCode = sessionData.options.primaryColor; // given from vin
        }
        partVoVData.forEach(function (vovAttribute) {
            //VoV flag
            if (vovAttribute.name === ECOM_VOV_FLAG_ATTRIBUTE_NAME) {
                productDetailsVOVFlag = vovAttribute.value;
            }
            //color RPO from part (may already be retrieved from VIN, don't bother if so)
            if (vovAttribute.name === ECOM_COLOR_RPO_ATTRIBUTE_NAME && !productDetailsColorCode) {
                productDetailsColorCode = vovAttribute.value;
            }
            //part RPO
            if (vovAttribute.name === ECOM_PART_RPO_ATTRIBUTE_NAME) {
                productDetailsPartsRPO = vovAttribute.value;
            }
        });

        if (productDetailsVOVFlag === "Y" && productDetailsPartsRPO !== "") {
            let hasPartsRPO = false;
            for (let j = 0; j < productDetailsPartsRPO.length; j++) {
                if (cgiPartsRPO.includes(productDetailsPartsRPO[j])) {
                    hasPartsRPO = true;
                    break;
                }
            }
            showVoVIcon = hasPartsRPO;
        }
    }

    yield put(updateProductDetailsWithVovData(showVoVIcon, productDetailsPartsRPO, productDetailsColorCode));
}

/***
 * THIS WORKER WOULD CHECK THE REDUCER IF THERE IS DEFAULT OR SELECTED CCL VALUES (VEHICLE COLOR),
 * CALL CGI SERVICE TO RETRIEVE ALL IMAGE DATA AND SET THEM UP TO THE REDUCER AS IMAGEDATAWITHPARTS
 *
 * SERIES CODE : THE PARTS ROP,
 * CCLVALUES : COLOR CODE OF THE VEHICLE
 * SELECTEDCCLVALUES: COLOR CODE FOR SELECTED PART NUMBER
 *
 * ***/

export function* vovWorkerInitialData() {
    const reducerData = store.getState().VoVReducer;
    const selectedColorCode = reducerData.selectedColorCode;

    let url = `${reducerData.baseUrl}?countrycode=${reducerData.countryCode}&modelyear=${reducerData.modelYear}&seriescode=${reducerData.seriesCode}&options=${reducerData.partsRPO}&size=gmds10`;
    if (selectedColorCode) {
        url = `${reducerData.baseUrl}?countrycode=${reducerData.countryCode}&modelyear=${reducerData.modelYear}&seriescode=${reducerData.seriesCode}&options=${reducerData.partsRPO}+${reducerData.selectedColorCode}&size=gmds10`;
    }

    try {
        const res = yield call(getImageDataService, url);
        if (res.message === "success") {
            const parseData = res.data.ExteriorCollection.sizes[0].angles;
            yield put(getVovDataWithParts(parseData));
            yield imageWithoutPartsWorker();
        }
    } catch (error) {
        yield put(loadVOVImageDataWithPartsFail(error));
    }
}

/***
 * THIS WORKER WOULD CHECK THE REDUCER IF THERE IS DEFAULT OR SELECTED CCL VALUES (VEHICLE COLOR),
 * CALL CGI SERVICE WITHOUT PASSING ANY SERIES CODE TO RETRIEVE ALL IMAGE DATA (BASE IMAGE) AND SET THEM UP TO THE REDUCER AS IMAGEDATAWITHOUTPARTS
 *
 * SERIES CODE : THE PARTS ROP,
 * CCLVALUES : COLOR CODE OF THE VEHICLE
 *
 * ***/
export function* imageWithoutPartsWorker() {
    const reducerData = store.getState().VoVReducer;
    const selectedColorCode = reducerData.selectedColorCode;
    let url = `${reducerData.baseUrl}?countrycode=${reducerData.countryCode}&modelyear=${reducerData.modelYear}&seriescode=${reducerData.seriesCode}&size=gmds10`;

    if (selectedColorCode) {
        url = `${reducerData.baseUrl}?countrycode=${reducerData.countryCode}&modelyear=${reducerData.modelYear}&seriescode=${reducerData.seriesCode}&options=${reducerData.selectedColorCode}&size=gmds10`;
    }

    try {
        const res = yield call(getImageDataService, url);
        if (res.message === "success") {
            const parseData = res.data.ExteriorCollection.sizes[0].angles;
            yield put(getVovDataWithoutParts(parseData));
        }
    } catch (error) {
        yield put(loadVOVImageDataWithoutPartsFail(error));
    }
}

/*** THIS WORKER WOULD CALL THE CGI SERVICE TO RETRIEVE ALL PARTS RPO AND SAVE THEM ON REDUCER ***/
export function* getAllPartsRPOFromCgiWorker(seriesCode, modelYear) {
    /** Getting vehicle Information data from Search Service Reducer (Vehicle Info) ***/
    const countryCode = store.getState().VoVReducer.countryCode;

    const payload = {
        data: {
            seriesCode: seriesCode,
            modelYear: modelYear
        }
    };

    /*** Update Series code and model year to vov reducer ***/
    yield put(vovDataInitialization(payload));

    const url = `/standardurl/urlservice?countrycode=${countryCode}&modelyear=${modelYear}&seriescode=${seriesCode}`;

    try {
        const response = yield call(getImageDataService, url);
        const partsRPO = response.data.ExteriorCollection.options.split(",");
        const colorRPO = response.data.ExteriorCollection.cclvalues.split(",");

        /*** Parsing cgi parts rpo to have only first three character ***/
        const cgiPartsRPOObject = [];
        partsRPO.map((item) => {
            const parsedItem = item.substring(0, 3);
            cgiPartsRPOObject.push(parsedItem);
        });

        /*** Saving parsed cgiPartsRPO,and colorCode to reducer ***/
        yield put(retrievePartsRPOFromCGI(cgiPartsRPOObject, colorRPO));
    } catch (error) {
        yield put(loadImageDataServiceFail(error));
    }
}

/***
 * NOTE: THIS FEATURE IS NOT IN CURRENT SCOPE
 * THIS WORKER  THAT CALLS BYO SERVICE TO GET THE COLOR DATA AND SET THEM UP ON REDUCER SO
 * IT CAN BE USED ON COLOR CONFIGURATION COMPONENT
 *
 */
export function* colorConfigurationWorker() {
    try {
        /*** call to  byo service get the bodyStyle and bodyStyle code ***/
        const byoStyleTrimData = yield call(byoBodyStyleService);
        const colorConfigArgsData = parseBodyStyleData(byoStyleTrimData);
        /*** calling another byo service to get the color data,parsing the data and dispatching to set them up on reducer **/
        const response = yield call(getColorConfigDataService, colorConfigArgsData);
        const parseData = parseColorConfigData(response);
        const finalColorConfigData = colorDataMapping(parseData);
        yield put(colorConfigData(finalColorConfigData));
    } catch (error) {
        yield put(loadColorConfigurationFail(error));
    }
}

/**
 * Function to parse Color configuration data
 * @param data
 * @returns {colorId,colorName,colorImageUrl}
 */

const parseColorConfigData = (data) => {
    const colorObj = data.config.OPTIONS.COLOR.exterior;
    let customColorObj = [];
    colorObj.map((item) => {
        const items = item.items;
        items.map((subitem) => {
            const data = {
                id: subitem.id,
                name: subitem.description,
                url: subitem.smallImageUrl
            };
            customColorObj.push(data);
        });
    });
    return customColorObj;
};

/**
 * THIS FUNCTION WOULD COMPARE CGI CCL VALUES WITH BYO DATA VALUES, AND RETURNS THE NEW OBJECT
 * @param data
 * @returns {Array}
 */
const colorDataMapping = (data) => {
    const cclValue = store.getState().VoVReducer.cclvalues;
    const cclObj = cclValue.split(",");
    let colorItemObj = [];
    for (let i = 0; i < cclObj.length; i++) {
        for (let j = 0; j < data.length; j++) {
            if (cclObj[i] === data[j].id) {
                colorItemObj.push(data[j]);
                break;
            }
        }
    }
    return colorItemObj;
};

/***
 * THIS FUNCTION WOULD VALIDATE AND RETURNS THE BODY STYLE DATA
 * @param bodyStyleObj
 * @returns {string}
 */
const parseBodyStyleData = (bodyStyleObj) => {
    const ymmData = queryString.parse(location.search);
    const year = ymmData.year;
    const make = ymmData.make.toLowerCase();
    const model = ymmData.model.toLowerCase();

    let bodyStyleData = "";
    for (let i = 0; i < bodyStyleObj.length; i++) {
        const byoModel = bodyStyleObj[i].bodyStyle.model;
        const byoYear = bodyStyleObj[i].bodyStyle.year.toString();
        if (byoYear === year && byoModel === model) {
            bodyStyleData = bodyStyleObj[i].bodyStyle;
            bodyStyleData.make = make;
            break;
        }
    }
    return bodyStyleData;
};
