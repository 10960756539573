import queryString from 'query-string';
import axios from 'axios';

import AppSettings from "../../core/AppSettings";

export function axiosSetup() {
// Add a request interceptor
  axios.interceptors.request.use(function(config) {
    // Add BAC to all requests for T3

    config.url = handleCache(config);
    //config.validateStatus = false;
    if (AppSettings.isT3) {
      let parsed = queryString.parseUrl(config.url);
      let {query, url} = parsed;
      if (!query.bac) {
        query.bac = AppSettings.bac;
        config.url = `${url}?${queryString.stringify(query)}`;

      }
    }

    return config;
  }, function(error) {
    // Do something with request error
    return Promise.reject(error);
  });
};

/** * Adding noCache with date to make url unique so that browser won't serve the data from cache ***/
const handleCache = (config) => {
  const customDate = new Date().getTime();
  const noCaching = 'noCache=true';
  const hasCaching = (config.url.indexOf(noCaching) > 0);
  return hasCaching ? config.url.replace(noCaching, 'noCache=' + customDate) : config.url;
};
