import * as type from "./CartActionTypes";
import { parseCartWarnings } from "./CartHandler";

// ====Shopping Cart Initial State ===
const cartInitialState = {
    showSpinner: true,
    response: "",
    parsedResponse: "",
    fedexError: false,
    error: false,
    errorMessage: "",
    showAlert: false,
    alertMessage: "",
    changeWarnings: {},
    dealerParticipatesInRewards: false,
    isLoading: true,
    checkCouponBanner: false
};

const cartOrderInfoAsyncFail = "LABEL_CART_GET_CART_RESPONSE_ERROR";
const cartRemoveItemFailedErrorMessage = "LABEL_CART_REMOVE_LINE_ITEM_ERROR";
const cartUpdateQtyFailedErrorMessage = "LABEL_CART_UPDATE_QTY_ERROR";
const cartUpdateQtyFailAvailableInventory = "LABEL_CART_UPDATE_QTY_INVENTORY_ERROR";
const cartOrderShippingRatesError = "LABEL_FED_EX_SHIPPING_RATES_ERROR";
const cartOrderDeliveryMethodError = "LABEL_CART_UPDATE_DELIVERY_ERROR";

const CartReducer = (state = cartInitialState, action) => {
    switch (action.type) {
        case type.CART_PAGE_INIT:
            const cartWarnings = parseCartWarnings(action.response?.order);

            return Object.assign({}, state, {
                error: false,
                errorMessage: "",
                showAlert: !!cartWarnings.itemsChanged,
                changeWarnings: cartWarnings,
                alertMessage: "",
                response: action.response
            });

        case type.CART_RESET_REDUCER:
            return Object.assign({}, state, cartInitialState);

        case type.CART_PAGE_FAILED_API_CALL:
            return Object.assign({}, state, {
                error: false,
                errorMessage: ""
            });

        case type.CART_PAGE_SPINNER_OFF:
            return Object.assign({}, state, {
                showSpinner: false
            });

        case type.CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY:
            return Object.assign({}, state, {
                showAlert: true,
                alertMessage: cartUpdateQtyFailAvailableInventory,
                changeWarnings: {}
            });

        case type.CART_HIDE_ALERT: {
            return Object.assign({}, state, {
                showAlert: false,
                alertMessage: "",
                changeWarnings: {}
            });
        }
        case type.CART_DELIVERY_CHANGE_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartOrderDeliveryMethodError
            });

        case type.CART_GET_SHIPPING_RATES_ERROR:
            return Object.assign({}, state, {
                fedexError: true,
                errorMessage: cartOrderShippingRatesError
            });

        case type.CART_QTY_CHANGE_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartUpdateQtyFailedErrorMessage
            });

        case type.CART_PAGE_ITEM_REMOVE_FAIL:
            return Object.assign({}, state, {
                error: true,
                errorMessage: cartRemoveItemFailedErrorMessage,
                isLoading: false
            });

        // For checkout
        case type.ORDER_INFO_ORDER_IN_CART:
            return Object.assign({}, state, {
                response: action.payload.response,
                parsedResponse: action.payload.parsedResponse,
                isLoading: false,
                error: false,
                errorMessage: ""
            });

        case type.ORDER_INFO_ORDER_IN_CART_ASYNC_FAIL:
            return Object.assign({}, state, {
                error: false,
                errorMessage: "",
                isLoading: false
            });

        case type.UPDATE_COUPON_BANNER:
            return Object.assign({}, state, {
                checkCouponBanner: action.checkCouponBanner
            });

        default:
            return state;
    }
};

const cartStyles = {
    chevrolet: {
        pageHeader: "q-headline2  gb-display4",
        itemHeader: "q-headline3 gb-body3",
        partName: "q-headline2 gb-headline2",
        partNumbers: "q-descriptive2 gb-body3",
        fitmentStatus: "q-body1",
        dealerLabel: "q-headline3 gb-descriptive1",
        dealer: "q-body3 grey-dark gb-body3",
        deliveryLabel: "q-headline3 gb-descriptive1",
        deliveryMethod: "q-body3 gb-body3"
    },
    buick: {
        pageHeader: "q-headline2 gb-descriptive1 heavy",
        itemHeader: "q-headline3 gb-body3",
        partName: "q-descriptive1 gb-headline2",
        partNumbers: "q-body2 gb-body3",
        fitmentStatus: "",
        dealerLabel: "q-label1 gb-descriptive1",
        dealer: "q-body1 gb-body3",
        deliveryLabel: "q-label1 gb-descriptive1",
        deliveryMethod: "q-body1 gb-body3"
    },
    cadillac: {
        pageHeader: "q-headline1 gb-descriptive1 heavy",
        itemHeader: "q-headline1 gb-body3",
        partName: "q-headline1 gb-headline2",
        partNumbers: "q-body1 gb-body3",
        fitmentStatus: "q-body1",
        dealerLabel: "q-headline1 gb-headline3",
        dealer: "q-body1 gb-body3",
        deliveryLabel: "q-headline1 gb-headline3",
        deliveryMethod: "q-body1 gb-body3"
    },
    gmc: {
        pageHeader: "q-display1 gb-descriptive1 heavy",
        itemHeader: "q-display3 gb-body3",
        partName: "q-bold2 gb-headline2",
        partNumbers: "q-descriptive2 gb-body3",
        fitmentStatus: "q-body2",
        dealerLabel: "q-body1 gb-descriptive1",
        dealer: "q-body2 gb-body3",
        deliveryLabel: "q-body1 gb-descriptive1",
        deliveryMethod: "q-body2 gb-body3"
    },
    gmparts: {
        pageHeader: "q-display3 gb-descriptive1 heavy",
        itemHeader: "q-headline3 gb-body3",
        partName: "q-headline3 gb-headline2",
        partNumbers: "q-descriptive2 gb-body3",
        fitmentStatus: "q-bold1",
        dealerLabel: "q-headline3 gb-descriptive1",
        dealer: "q-body1 gb-body3",
        deliveryLabel: "q-headline3 gb-descriptive1",
        deliveryMethod: "q-body1 gb-body3"
    }
};

export { cartInitialState, CartReducer, cartStyles };
