import axios from 'axios';
import { brand } from '../../../shared/Utils/Utils';
import AppSettings from '../../../core/AppSettings';

// CTA value is retrieved from backend and has been removed from body
export const sendEmail = (email) => {
    // Set up the request
    let body = {
        username: email,
        brand: brand(),      
    };

    // Send the request
    return axios.post('/wcs/resources/store/' + AppSettings.storeId + '/GMPerson/resetpassword/request?responseFormat=json', body);
}

export const updatePasswordToken = (payload) => {
    return axios.post(`/wcs/resources/store/${AppSettings.storeId}/GMPerson/user/verifytoken`, payload).then(result => {
        return result
    });
}