import React from "react";
import Button from "../../../../shared/Button/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { InputGroup, FormControl } from "react-bootstrap";
import { resetErrors } from "../HelperFunctions";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import MediaQuery from "react-responsive";

/**
 * Zip code search function takes user input (max length 5) and updates currentZip.
 * When the value of currentZip changes, a GET call is made in DealerLocator.js
 *
 * @param {*} props
 * dispatch (required) dealerizationState reducer
 * state (required) dealerizationState reducer updater
 * @return {SellerSearch} React Component with search bar for zipcode
 */

export default function SellerSearch(props) {
    const { t } = useTranslation();
    const userInputSeller = useSelector((state) => state.DealerizationReducer.userInputSeller);
    const ENTER_CHARCODE = 13;
    const SELLER_MAX_LENGTH = 50;
    const MAX_WIDTH = props.state.showMap ? "979" : "639";
    const MIN_WIDTH = props.state.showMap ? "980" : "640";

    /**
     * checking if the user hits enter while doing the zipcode search.
     * @param {*} event user keypress
     */
    const handleKeyPress = (event) => {
        if (event.charCode === ENTER_CHARCODE) {
            handleSellerSearch();
        }
    };

    /**
     * updates seller in reducer so parent can make a backend call
     * to update the list of sources.
     */
    const handleSellerSearch = () => {


        if (userInputSeller.length <= 2) {
            props.dispatch({ type: "TOGGLE_NAME_ERROR", showNameError: true });
            props.dispatch({ type: "SET_NAME_ERROR_MSG", nameErrorMsg: t("ERROR_DEALER_LOCATOR_INVALID_NAME") });
        } else {
            props.dispatch({ type: "UPDATE_CURRENT_SELLER", currentSeller: props.state.userInputSeller });
            props.dispatch({ type: "SOURCE_RESULTS", sourceResults: "Seller" });
            const mapArg = {
                coordinates: { lat: 37.0902, lng: -95.7129 },
                googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-adamopelag",
                zoom: 4
            };
            props.dispatch({ type: "UPDATE_MAP_DATA", dealerMapData: mapArg });
            (props.state.showLocationError || props.state.showNameError) && resetErrors(props.dispatch);
            props.setDealerList((prevDealerList) => !prevDealerList);
        }
    };

    /**
     * updates the user input for the seller
     * @param {*} e event to track what user input in the form control
     */
    const handleSellerInput = (e) => {
        if (e.target.value.length <= SELLER_MAX_LENGTH) {
            props.dispatch({ type: "UPDATE_USER_INPUT_SELLER", userInputSeller: e.target.value });
        }
    };

    /* UI render of the seller search bar. */
    return (
        <div className="search-container">
            {/** DESKTOP VIEW*/}
            <MediaQuery query={`(min-width: ${MIN_WIDTH}px)`}>
                <InputGroup>
                    <FormControl
                        type={"text"}
                        placeholder={t("LABEL_COMMON_SELLER_NAME")}
                        onChange={handleSellerInput}
                        name={"dealerLocatorSeller"}
                        id={"dealerization-search-bar"}
                        value={props.state.userInputSeller}
                        onKeyPress={handleKeyPress}
                        className="stat-search-input"
                        data-dtm="modal:dealer search"

                    />
                    <Button
                        localeKey={t("LABEL_COMMON_SEARCH")}
                        onClick={() => handleSellerSearch()}
                        id={"dealerization-search-btn"}
                        className="gb-primary-button stat-search-submit"
                        dataDtm="modal:dealer search"
                        disabled={props.state.userInputSeller.length < 3 || props.state.userInputSeller.length > 50}
                    />
                </InputGroup>
            </MediaQuery>

            {/** MOBILE VIEW */}
            <MediaQuery query={`(max-width: ${MAX_WIDTH}px)`}>
                <InputGroup>
                    <FormControl
                        type={"text"}
                        placeholder={t("LABEL_COMMON_SEARCH") + " " + t("LABEL_COMMON_SELLER_NAME")}
                        onChange={handleSellerInput}
                        name={"dealerLocatorSeller"}
                        id={"dealerization-search-bar"}
                        value={props.state.userInputSeller}
                        onKeyPress={handleKeyPress}
                        className="stat-search-input"
                        data-dtm="modal:dealer search"
                    />
                    <Button
                        localeKey={""}
                        fontAwesomeRender={faSearch}
                        onClick={() => handleSellerSearch()}
                        type={"submit"}
                        id={"dealerization-search-btn"}
                        className="gb-primary-button stat-search-submit"
                        dataDtm="modal:dealer search"
                        disabled={props.state.userInputSeller.length < 3 || props.state.userInputSeller.length > 50}
                    />
                </InputGroup>
            </MediaQuery>
            {props.state.showNameError && <div className="prt-dealerization-error-msg">{props.state.nameErrorMsg}</div>}
        </div>
    );
}

// PropType Checking
SellerSearch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.any.isRequired
};
