import React, { useState } from "react";
import PropTypes from "prop-types";
import Toast from "react-bootstrap/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SharedToastMessage(props) {
    const [state, setState] = useState(true);

    const handleClick = () => {
        setState((state) => false);
        const timeout = props; // pass props through to timeout
        // wait 350ms to allow animation to finish before unrendering toast in parent class
        setTimeout(function () {
            timeout.handleDismissClick();
        }, 350);
    };

    const splitErrorMessages = () => {
        let messageToDisplay;
        if (props.isStockOrderPage && props.body_message) {
            const messages = props.body_message.split("; ");
            messageToDisplay = (
                <ul>
                    {messages.map((message, i) => (
                        <li key={i}>{message}</li>
                    ))}
                </ul>
            );
        }
        return messageToDisplay;
    };

    return (
        <Toast
            show={state}
            onClose={() => {
                handleClick();
            }}
        >
            <Toast.Header className={props.toast_header_css}>
                <FontAwesomeIcon className={props.toast_icon_color} icon={props.toast_icon_css} />
                <strong className="toast-header-text">{props.header_title}</strong>
            </Toast.Header>
            {props.isStockOrderPage ? (
                <Toast.Body className={props.toast_body_css}>{splitErrorMessages()}</Toast.Body>
            ) : (
                <Toast.Body className={props.toast_body_css}>{props.body_message}</Toast.Body>
            )}
        </Toast>
    );
}

SharedToastMessage.propTypes = {
    header_title: PropTypes.string,
    body_message: PropTypes.string,
    toast_header_css: PropTypes.string,
    toast_icon_color: PropTypes.string,
    toast_icon_css: PropTypes.object,
    toast_body_css: PropTypes.string,
    isStockOrderPage: PropTypes.bool
};
