import * as actionType from './DealerLocatorActionTypes';

const initialState = {
    showDealerLocator:false,
    showSpinner :false,
    showLoadingBtn: false,
    showError:false,
    zipCode:'',
    state:'',
    country:'',
    dealerMapData:{
        zoom:4,
        coordinates:{lat:37.0902,lng:-95.7129},
        googleMapURL:"https://maps.googleapis.com/maps/api/js?client=gme-adamopelag"
    }
};

const noDealerFoundError = "LABEL_CHECKOUT_DEALER_LOCATOR_NO_DEALER_FOUND_ERROR";
const failedToLoadDealerList = "LABEL_CHECKOUT_DEALER_LOCATOR_ERROR_RETRIEVE_DEALER_LIST";
const failedToUpdateDealer = "LABEL_CHECKOUT_DEALER_LOCATOR_UPDATE_DEALER_ERROR";

const DealerLocatorReducer = (state=initialState,action)=>{
    switch(action.type){
        case actionType.LOAD_DEALER_DATA_ASYNC:
            return ({...state,  showDealerLocator: true,
                                zipCode:action.payload.zipCode,
                                state:action.payload.state,
                                country:action.payload.country,
                                showSpinner:true});

        case actionType.CONTINUE_BUTTON:
            return ({...state, showLoadingBtn: true});
            
        case actionType.CANCEL_BUTTON:
            return ({...state,showDealerLocator: false, 
                              showLoadingBtn: false});

        case actionType.LOAD_DEALER_DATA:
            return ({...state,dealers:action.payload.dealers,
                              showSpinner:false,
                              showError:false,
                              errorMessage: '',
                              selectedBac:"",
                              dealerMapData:{
                              coordinates: action.payload.coordinates,
                              zoom: 8,
                              googleMapURL: state.dealerMapData.googleMapURL,
                              }});

        case actionType.LOAD_DEALER_DATA_ASYNC_FAILED:
            return ({...state,dealerDataAvailable:false,
                              showError:true,
                              error:action.error,
                              errorMessage:failedToLoadDealerList,
                              showSpinner:false});

        case actionType.NO_DEALER_FOUND_ERROR:
            return ({...state,noDealerFoundError: true,
                              showError:true,
                              errorMessage:noDealerFoundError,
                              dealers:[],
                              showSpinner:false});

        case actionType.CHANGE_ZIP_CODE:
            return ({...state,zipCode: action.payload});

       case actionType.DISPATCH_ERROR_MESSAGE:
            return({...state,showError: true,
                             errorMessage:action.error});

        case actionType.VALID_ZIP_CODE:
            return({...state,showError:false,
                             errorMessage: ''});

        case actionType.SELECT_DEALER:
            return({...state,
                    selectedBac:action.payload.selectedBac,
                    showError:false,
                    dealerMapData:{
                    coordinates:action.payload.selectedCoordinates,
                        zoom:17,
                        googleMapURL:state.dealerMapData.googleMapURL
                    }});
        case actionType.UPDATE_ZIP_CODE:
            return ({...state,showSpinner:true,
                              dealers:[]});
        case actionType.FAILED_UPDATE_DEALER:
            return ({...state,showSpinner:false,
                              showError:true,
                              errorMessage:failedToUpdateDealer,
                              showLoadingBtn: false});

         default:
            return state
    }
};

export default DealerLocatorReducer