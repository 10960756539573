import React from "react";
import vaultConstants from "../../../config/vault_constants";
import AppSettings from "../../core/AppSettings";
import PropTypes from "prop-types";
import { getGenericVehicle } from "../Utils/Utils";

/**
 * Shared component to display vehicle jellybean images
 * @returns {JSX.Element}
 * @constructor
 */
const JellybeanImage = (props) => {
    // TODO: WIP to figure out where the mapping will occur to determine correct image. Generic image for now
    const genericVehicleIconURL = getGenericVehicle();
    const brandNoImgFound = `../../../assets/${AppSettings.brand}/img/${AppSettings.brand}_no_car_icon_found.svg`;
    const {imageWidth, imageHeight} = props;

    return (
        <div className="jellybean-vehicle-image">
            <img
                src={genericVehicleIconURL}
                width={imageWidth}
                height={imageHeight}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = brandNoImgFound;
                }}
                alt="vehicle silhouette image"
            />
        </div>
    )
}

JellybeanImage.defaultProps = {
    imageWidth: "70vw",
    imageHeight: "50vh"
}

JellybeanImage.propTypes = {
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string
}

export default JellybeanImage;
