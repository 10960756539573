import React from 'react';
import AppSettings from '../../../../core/AppSettings';
import {Translation, Trans, useTranslation} from 'react-i18next';
import SummaryRailRedeemTextbox from './SummaryRailRedeemTextbox';
import { formatNumber } from '../../../../../client/shared/Internationalization/FormattedNumbers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import FieldWarningMessage from '../../../FieldWarningMessage/FieldWarningMessage';
import { isValidValue } from '../../../Validator/Validator';
import {useFooterDisclaimerLabel} from "../../../../modules/App/Footer/FooterDisclaimerLabel";

function SummaryRailRewardsPoints(props) {
    const {t} = useTranslation()
    const brand = AppSettings.currentSite.label;
    const tier = props.tierName;
    const totalPointPoints = isValidValue(props.totalPointPoints) && !props.totalPointPoints.includes(",") ? formatNumber(props.totalPointPoints):props.totalPointPoints;
    const totalPointDollars = props.totalPointDollars;
    const nonVehicleRedeemDollars=props.nonVehicleRedeemDollars;

    const rewardsDisclaimer = process.env.REACT_APP_DISCLAIMER_LOYALTY;
    const rewardsLabel = useFooterDisclaimerLabel(rewardsDisclaimer);
    const redeemDisclaimer = process.env.REACT_APP_DISCLAIMER_REDEEM_NAME;
    const redeemLabel = useFooterDisclaimerLabel(redeemDisclaimer);

    const createMarkup = (text) => {
        return {
            __html: text
        };
    };


    if (isValidValue(nonVehicleRedeemDollars) && nonVehicleRedeemDollars === "0") {
        return (
            <FieldWarningMessage
                message={<span dangerouslySetInnerHTML={createMarkup(t("LABEL_REWARDS_CHECKOUT_YEARLY_LIMIT_ERROR", { brand, label:rewardsLabel, limitLabel:redeemLabel }))}/>}
                showError={true}
            />
        );
    } else if (totalPointPoints === "0") {
        return (
            <FieldWarningMessage message={<Trans i18nKey="LABEL_REWARDS_CHECKOUT_NOT_ENOUGH_POINTS2"/>} showError={true}/>
        );
    } else if (totalPointPoints && totalPointDollars){
        return (
            <Translation>
            {
                (t) => (
                    <div className="ac-small-marginbottom">
                        <div>
                            <div className="rewards-label" id="rewards-points-value-label">
                                <Trans i18nKey="LABEL_REWARDS_CHECKOUT_TIER_POINTS_INFORMATION">{{ tier }} {{ totalPointDollars : parseFloat(totalPointDollars).toFixed(2) }} {{totalPointPoints}} {{brand}}</Trans>
                                <i>
                                    <div className="ac-redeem-rewards-icon">
                                        <span className="ac-redeem-rewards-text"><Trans i18nKey="LABEL_REWARDS_CHECKOUT_HINT"> {{brand}} </Trans></span>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </div>
                                </i>
                            </div>
                        </div>
                        <SummaryRailRedeemTextbox
                            currencySymbol={t("LABEL_COMMON_DOLLAR_SIGN")}
                            pointAllowancesError={props.pointAllowancesError}
                            appliedDollars={props.appliedDollars}
                            appliedPoints={props.appliedPoints}
                            handleApplyRewardsPoints={props.handleApplyRewardsPoints}
                            totalPointDollars={totalPointDollars}
                            totalPointPoints={totalPointPoints}
                            subtotal={props.subtotal}
                        />
                    </div>
                    )
                }
            </Translation>
        )
    }
    else{
      return null;
    }
}

export default SummaryRailRewardsPoints;
