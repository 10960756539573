import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FieldErrorMessage from "../FieldErrorMessage/FieldErrorMessage";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppSettings from "../../core/AppSettings";
import SharedButton from "../Button/SharedButton";

/**
 * SHARED QUANTITY PICKER COMPONENT
 * Allows users to input quantity via button clicks or text input
 *
 * Props:
 *   value: string or number
 *     REQUIRED
 *     The quantity value shown in the picker
 *
 *   onChange: function
 *     REQUIRED
 *     A callback function handler for the updated quantity
 *
 *   minimum: string or number
 *     OPTIONAL - defaults to 1
 *     The minimum value allowed in the picker
 *
 *   maximum: string or number
 *     OPTIONAL - defaults to 99999
 *     The maximum value allowed in the picker
 *
 *   defaultValue: string or number
 *     OPTIONAL - defaults to minimum
 *     The default value to show in the picker if something invalid is entered
 *
 * Usage:
 *   Store quantity in the consuming component and update the quantity using the onChange handler function
 *
 *   Example:
 *     const [quantity, setQuantity] = useState(1);
 *     <QuantityPicker value={quantity} onChange={setQuantity} />
 */
function QuantityPicker(props) {
    const { t } = useTranslation();
    const minimum = props.minimum || 1;
    const maximum = props.maximum || 99999;
    const defaultValue = props.defaultValue || minimum;
    const dirty = props.value !== defaultValue;
    const [errorMessage, setErrorMessage] = useState();
    const brand = AppSettings.thisMake.key;
    const pageName = AppSettings.pageName;
    const quantityLabelClass = brand === "gmc" || brand === "chevrolet" ? "q-descriptive2" : "q-descriptive1";
    const quantitySubmitButtonClass =
        dirty && props.value !== ""
            ? "q-button-small q-button q-primary-button stat-button-link prt-update prt-hide-on-print"
            : "prt-update-hidden";
    const isLargeQuantity = String(props.value).length > 2;
    const isMiniCart = props.isMiniCart;

    function handleIncrement() {
        setErrorMessage("");
        const cleanValue = cleanInput(props.value);
        if (props.value < maximum) {
            props.onChange(props.value + 1);
        } else {
            setErrorMessage(cleanValue.error);
        }
    }

    function handleDecrement() {
        setErrorMessage("");
        if (props.value > minimum) {
            props.onChange(props.value - 1);
        }
    }

    function handleOnBlur(e) {
        setErrorMessage("");
        const cleanValue = cleanInput(e.target.value);
        if (cleanValue.error) {
            if (cleanValue.error === "Minimum exceeded") {
                setErrorMessage(cleanValue.error);
            } else if (cleanValue.error === "Maximum exceeded") {
                setErrorMessage(cleanValue.error);
            }
        }
        props.onChange(cleanValue.value);
    }

    function handleOnKeyDown(e) {
        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                handleDecrement();
                break;
            case "ArrowUp":
                e.preventDefault();
                handleIncrement();
                break;
            case "Enter":
                e.preventDefault();
                handleOnBlur(e);
            default:
                break;
        }
    }

    function cleanInput(value) {
        let cleanValue;
        let error; // Error messages are not meant for UI display - convert to translated label elsewhere
        if (!value) {
            cleanValue = defaultValue;
            error = "Empty";
        } else if (isNaN(value)) {
            cleanValue = defaultValue;
            error = "NaN";
        } else if (value < minimum) {
            cleanValue = minimum;
            error = "Minimum exceeded";
        } else if (value > maximum) {
            cleanValue = maximum;
            error = "Maximum exceeded";
        } else if (value % 1 !== 0) {
            cleanValue = Math.floor(value);
            error = "Non-integer";
        } else {
            cleanValue = value;
        }
        return error ? { value: Number(cleanValue), error: error } : { value: Number(cleanValue) };
    }

    const minusIcon = (
        <div className="prt-quantity-button">
            <FontAwesomeIcon icon={faMinus} />
        </div>
    );

    const plusIcon = (
        <div className="prt-quantity-button">
            <FontAwesomeIcon icon={faPlus} />
        </div>
    );

    return (
        <article className="prt-quantity-picker-wrapper">
            <div className={"prt-quantity-picker-label gb-descriptive1 " + quantityLabelClass}>
                {t("LABEL_COMMON_QUANTITY")}
            </div>
            <div className="prt-quantity-picker">
                <div className="prt-quantity-input">
                    <SharedButton
                        id="qty-picker-decrement"
                        type="button"
                        className="gb-secondary-button"
                        variant="secondary"
                        onClick={() => handleDecrement()}
                        icon={minusIcon}
                        disabled={props.value <= minimum}
                        buttonText={""}
                        analyticsTag={"shopping cart: " + props.analyticsTag}
                        className="prt-quantity-decrement-button stat-button-link"
                    />
                    <input
                        id="qty-picker-input"
                        type="text"
                        className={(isLargeQuantity ? "prt-large-quantity" : "") + " q-body1"}
                        data-dtm={"shopping cart: " + props.analyticsTag}
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        onBlur={(e) => handleOnBlur(e)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                    />
                    <SharedButton
                        id="qty-picker-increment"
                        type="button"
                        className="gb-secondary-button"
                        variant="secondary"
                        onClick={() => handleIncrement()}
                        icon={plusIcon}
                        buttonText=""
                        analyticsTag={"shopping cart: " + props.analyticsTag}
                        className="stat-button-link"
                    />
                </div>
            </div>
            {(pageName === "cart" || isMiniCart) && !errorMessage && (
                <SharedButton
                    type="submit"
                    variant="primary"
                    onClick={props.onSubmit}
                    disabled={errorMessage || !dirty}
                    buttonText={t("LABEL_COMMON_UPDATE")}
                    data-dtm={"shopping cart: " + props.analyticsTag}
                    analyticsTag=""
                    className={
                        quantitySubmitButtonClass +
                        " prt-cart-button stat-button-link gb-primary-button" +
                        (isLargeQuantity ? " prt-update-large" : "")
                    }
                />
            )}
            <span className="prt-quantity-error-message">
                <FieldErrorMessage message={errorMessage} showError={Boolean(errorMessage)} />
            </span>
        </article>
    );
}

QuantityPicker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    minimum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maximum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default QuantityPicker;
