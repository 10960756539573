import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppSettings from '../../core/AppSettings';

class CheckoutProgressBar extends Component {
    

    constructor(props) {
        super(props);
        
      }
      
    progressTile(property){
        
        if (parseInt(property.currentStep) < parseInt(property.stepNumber)){
            return (
                <div>
                    <div className='ac-bar-icon ac-bar-inactive'>                        
                        <span className='ac-fa-icon'><FontAwesomeIcon size={property.circleSize} icon='circle' /></span>
                            <span className='ac-text-overlay'>{property.stepNumber}</span> 
                        
                    </div>
                    <span className='ac-bar-label ac-bar-label-inactive'> {property.stepLabel}</span>
                </div>
            );
        } else if ( parseInt(property.currentStep) > parseInt(property.stepNumber)) {
            return (
                <div>
                    <div className='ac-bar-icon'>                        
                        <span className='ac-fa-icon '><FontAwesomeIcon size={property.circleSize} icon='circle' /></span> 
                            <span className='ac-icon-overlay'><FontAwesomeIcon icon='check' /></span>
                                           
                    </div>
                    <span className='ac-bar-label ac-bar-label-completed'> {property.stepLabel}</span>
                </div>
            );
        }  else {
            return (
                <div>
                    <div className='ac-bar-icon'>                        
                        <span className='ac-fa-icon'><FontAwesomeIcon size={property.circleSize} icon='circle' /></span> 
                            <span className='ac-text-overlay'>{property.stepNumber}</span>    
                                           
                    </div>
                    <span className='ac-bar-label ac-bar-label-active'> {property.stepLabel}</span>
                </div>
            );
        }
        
    }  

    createCheckoutProgressBar(props) {

        const faCircleSize = "2x";
     
        return (
            <Translation>
            {(t) => (
              
                <div className="row q-gridbuilder grid-column-alignment-left ac-checkout-progress-bar-wrapper">
                    
                    <div className="small-3 medium-3 large-3 xlarge-3 grid-column-alignment-center columns">
                        {this.progressTile( {currentStep: this.props.step,
                                             circleSize: faCircleSize,
                                             stepNumber: "1",
                                             stepLabel: AppSettings.isT3 ?  t('LABEL_CHECKOUT_PROGRESS_BAR_CONTACT_INFO') : t('LABEL_CHECKOUT_PROGRESS_BAR_SHIP_CONTACT')
                                            })}
                    </div>
                    <div className="small-3 medium-3 large-3 xlarge-3 grid-column-alignment-center columns">
                        {this.progressTile( {currentStep: this.props.step,
                                             circleSize: faCircleSize,
                                             stepNumber: "2",
                                             stepLabel: t('LABEL_CHECKOUT_PROGRESS_BAR_DELIVERY_OPTIONS')
                                            })}     
                    </div>

                    <div className="small-3 medium-3 large-3 xlarge-3 grid-column-alignment-center columns">
                        {this.progressTile( {currentStep: this.props.step,
                                             circleSize: faCircleSize,
                                             stepNumber: "3",
                                             stepLabel: t('LABEL_CHECKOUT_PROGRESS_BAR_PAYMENT_BILLING')
                                            })}
                    </div>

                    <div className="small-3 medium-3 large-3 xlarge-3 grid-column-alignment-center columns">
                        {this.progressTile( {currentStep: this.props.step,
                                             circleSize: faCircleSize,
                                             stepNumber: "4",
                                             stepLabel: t('LABEL_CHECKOUT_PROGRESS_BAR_REVIEW_PLACE')
                                            })}
                    </div>
                </div>
            )}
            </Translation>
        );
    }
    
    render() {
        
        return (
           
            <div>
                {this.createCheckoutProgressBar(this.props.step)}
            </div>
            
        )
    }

}

export default CheckoutProgressBar;