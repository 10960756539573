import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Slider from "react-slick";
import AppSettings from "../../../core/AppSettings";
import { generateAltText } from "./HomeCarouselHelper";
import { playVideo, stopVideo } from "./HomeCarouselRedux/HomeCarouselActionCreator";
import i18next from "i18next";
import { jsonToUrlParams } from "../../../shared/Utils/Utils";
/**************************************************************/
//     SLICK SLIDER CUSTOM SETTINGS
/**************************************************************/
//GENERAL SETTINGS
const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: "q-content-bar", // slick dots wrapper class
    appendDots: (dots) => (
        <div>
            <div className="slick-indicator-container js-append-dots">
                <ul className="slick-dots stat-dots" data-dtm="search option">
                    {dots}
                </ul>
            </div>
            <div className="q-semi-opaque-bar"></div>
        </div>
    )
};

//CUSTOM CAROUSEL ICONS
const VideoExitBtnImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/ui_close-icon_v01.svg";
const VideoPlayBtnImg = "/assets/" + AppSettings.sitesStoreMap[AppSettings.storeId].key + "/img/video_play_btn.svg";

//CUSTOM CAROUSEL ARROWS
export function PrevArrow(props) {
    const { onClick } = props;
    const dtm = props.dataDtm || "search option";
    return (
        <button
            aria-label={i18next.t("LABEL_CAROUSEL_PREVIOUS")}
            className="slick-prev slick-arrow gallery-image-arrow ac-home-carousel-arrow stat-arrow-prev"
            title={i18next.t("LABEL_CAROUSEL_PREVIOUS")}
            onClick={onClick}
            data-dtm={dtm}
        >
            {i18next.t("LABEL_CAROUSEL_PREVIOUS")}
        </button>
    );
}

export function NextArrow(props) {
    const { onClick } = props;
    const dtm = props.dataDtm || "search option";
    return (
        <button
            aria-label={i18next.t("LABEL_CAROUSEL_NEXT")}
            className="slick-next slick-arrow gallery-image-arrow ac-home-carousel-arrow stat-arrow-next"
            title={i18next.t("LABEL_CAROUSEL_NEXT")}
            onClick={onClick}
            data-dtm={dtm}
        >
            {i18next.t("LABEL_CAROUSEL_NEXT")}
        </button>
    );
}

class HomeCarousel extends Component {
    /**************************************************************/
    //     CREATE SLICK CAROUSEL
    /**************************************************************/
    //  This function sets up slick and populates it with images, marketing text
    //  and video placeholder images from the data recieved from the espot.
    //  This data is parsed in HomeCarouselWorker.js
    createSlides() {
        // GET CAROUSEL DATA
        const carouselAssets = this.props.data.carouselAssets;
        //GET FITMENT DATA
        const urlParameters = AppSettings.urlParameters();
        const vehicleInfoData = this.props.vehicleInfo;
        const vehicleFitment = {
            year: vehicleInfoData.year || "",
            make: vehicleInfoData.make || "",
            model: vehicleInfoData.model || "",
            modelId: vehicleInfoData.vehicleConfig.modelId || "",
            body: vehicleInfoData.vehicleConfig.body || "",
            bodyId: vehicleInfoData.vehicleConfig.bodyId || "",
            trim: vehicleInfoData.vehicleConfig.trim || "",
            trimId: vehicleInfoData.vehicleConfig.trimId || "",
            drive: vehicleInfoData.vehicleConfig.drive || "",
            driveId: vehicleInfoData.vehicleConfig.driveId || "",
            engine: vehicleInfoData.vehicleConfig.engine || "",
            engineId: vehicleInfoData.vehicleConfig.engineId || "",
            wheel: vehicleInfoData.vehicleConfig.wheel || "",
            wheelId: vehicleInfoData.vehicleConfig.wheelId || "",
            bodyNumDoors: vehicleInfoData.vehicleConfig.bodyNumDoorsId || "",
            vin: vehicleInfoData.vin || ""
        };
        Object.entries(vehicleFitment).map(([key, value]) => {
            urlParameters[key] = value;
        });
        const jsonToUrlModification = jsonToUrlParams(urlParameters).replace("?", "&");

        if (carouselAssets.length) {
            // Dangerously Set innerHTML: https://react-cn.github.io/react/tips/dangerously-set-inner-html.html
            function createMarkup(text) {
                return {
                    __html: text
                };
            }

            // BRIGHTCOVE VIDEO JS
            const script = document.createElement("script");
            script.src = "https://players.brightcove.net/5358100852001/ClrGDcamQr_default/index.min.js";
            script.async = true;
            document.body.appendChild(script);

            return (
                <div className="q-gallery-full-scroller">
                    <div className="ac-no-background q-multimedia-scroller q-mod q-mod-multimedia-scroller">
                        <Slider {...settings} className="q-slider q-with-caption js-has-caption q-slider-hover-arrow">
                            {carouselAssets.map((asset, i) => (
                                <div
                                    className="q-responsive-image q-responsive-image-container"
                                    key={i}
                                    alt={asset.assetName.toString()}
                                >
                                    {/* IMAGE SLIDES */}
                                    {asset.imagePath && (
                                        <a
                                            className={!asset.contentUrl ? "inactiveLink" : ""}
                                            href={asset.contentUrl + jsonToUrlModification}
                                            onClick={!asset.contentUrl ? (e) => e.preventDefault() : null}
                                        >
                                            <div className="q-content-overlay">
                                                {/* Markup html displayed over carousel image */}
                                                <div className="q-layer q-top-xs q-top-s q-top-m q-top-l q-top-xl">
                                                    <div className="q-layer-width  q-twenty-five-percent-width-xsmall q-twenty-five-percent-width-small q-twenty-five-percent-width-medium q-twenty-five-percent-width-large q-twenty-five-percent-width-xlarge">
                                                        <div className="row q-gridbuilder grid-bg-color-one q-indent-grid-content">
                                                            <div
                                                                dangerouslySetInnerHTML={createMarkup(
                                                                    asset.marketingText
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <picture>
                                                    {/* swap image src based on screen size */}
                                                    <source media="(min-width: 1025px)" srcSet={asset.imagePath} />
                                                    <source media="(min-width: 641px)" srcSet={asset.imagePathMd} />
                                                    <img
                                                        // applies to all smaller viewports (max-width: 640px)
                                                        src={asset.imagePathSm}
                                                        alt={generateAltText(asset.marketingText)}
                                                    />
                                                </picture>
                                            </div>
                                        </a>
                                    )}
                                    {/* VIDEO SLIDES */}
                                    {asset.videoPlaceholderImgPath && (
                                        <div className="q-content-overlay">
                                            <div className="ac-video-slide-wrapper">
                                                {/* Video play button - show video on click */}
                                                <span
                                                    onClick={() => this.props.startVideo(asset.videoId)}
                                                    className="ac-video-play-btn"
                                                >
                                                    <img src={VideoPlayBtnImg} alt="play" />
                                                </span>
                                                <picture>
                                                    {/* swap image src based on screen size */}
                                                    <source
                                                        media="(min-width: 1500px)"
                                                        srcSet={asset.videoPlaceholderImgPath}
                                                    />
                                                    <source
                                                        media="(min-width: 1200px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 960px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 600px)"
                                                        srcSet={asset.videoPlaceholderImgPathMd}
                                                    />
                                                    <source
                                                        media="(min-width: 0px)"
                                                        srcSet={asset.videoPlaceholderImgPathSm}
                                                    />
                                                    <img
                                                        src={asset.videoPlaceholderImgPath}
                                                        alt="image Not Found"
                                                        className="q-rectangle-image-responsive q-image q-posterframe"
                                                    />
                                                </picture>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            );
        }
    }
    /**************************************************************/
    //     CREATE VIDEO PLAYER
    /**************************************************************/
    //  This function contains the video player for the carousel.
    //  It's detached because Slick Slider duplicates its slides
    //  (when infinite is set to true) and was adding two of each video
    createVideo() {
        // GET CAROUSEL DATA
        const carouselAssets = this.props.data.carouselAssets;

        if (carouselAssets.length) {
            return (
                <div className="video-wrapper">
                    {carouselAssets.map((asset, i) => (
                        <div key={i}>
                            {/* Brightcove video display when this.props.data.videoIsPlaying is set to true */}
                            {asset.videoIsPlaying && (
                                <div className="ac-carousel-video-wrapper">
                                    <video
                                        id={`bcPlayerID-` + asset.videoId}
                                        data-video-id={asset.videoId}
                                        data-account="5358100852001"
                                        data-player="ClrGDcamQr"
                                        data-embed="default"
                                        data-application-id
                                        className="video-js gm-player"
                                        controls={true}
                                        autoPlay={true}
                                    ></video>
                                    {/* video close button - stop video on click */}
                                    <span
                                        onClick={() => this.props.stopVideo(asset.videoId)}
                                        className="ac-video-stop-btn"
                                    >
                                        <img src={VideoExitBtnImg} alt="X" />
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }
    }

    render() {
        return (
            <div className="home-carousel-wrapper">
                {/* video player */}
                {this.createVideo()}
                {/* slick carousel */}
                {this.createSlides()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.HomeCarouselReducer,
        vehicleInfo: state.Session
    };
}

function mapDispatchToProps(dispatch) {
    return {
        startVideo: (videoId) => {
            dispatch(playVideo(videoId));
        },

        stopVideo: (videoId) => {
            dispatch(stopVideo(videoId));
        }
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomeCarousel));
