/**************************************************************/
// Actions to load data into the Footer state
/**************************************************************/
export const LOAD_FOOTER_DATA_ASYNC = 'LOAD_FOOTER_DATA_ASYNC';
export const SET_LINKS = 'SET_LINKS';
export const SET_SLOGAN = 'SET_SLOGAN';
export const SET_TEXT = 'SET_TEXT';
export const SET_LOADING = 'SET_LOADING';
export const SET_SEQUENCE = 'SET_SEQUENCE';

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const LOAD_FOOTER_DATA_ASYNC_FAIL = 'LOAD_FOOTER_DATA_ASYNC_FAIL';
export const SET_LINKS_FAIL = 'SET_LINKS_FAIL';
export const SET_SLOGAN_FAIL = 'SET_SLOGAN_FAIL';
export const SET_TEXT_FAIL = 'SET_TEXT_FAIL';
export const SET_SEQUENCE_FAIL = 'SET_SEQUENCE_FAIL';