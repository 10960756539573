import React from 'react';
import { Translation } from 'react-i18next';
import SideNavigatorLink from './SideNavigatorLink/SideNavigatorLink'

/**
 * SideNavigator takes in an array of sites as a prop value and displays them on the page.
 * 
 * props: navLocations, activePath
 * 
 * ex. navLocations: [
            {
                displayName: "Account Overview", <---site display value
                value : "/profile/overview"    <---site URL    
            }
        ],
        activePath = window.location.pathname
 */

function SideNavigator(props) {
    const sites = props.navigation.navLocations;
    const renderMobile = { props };
    return (
        <Translation>
            {
                (t) => (
                    <div className="ac-side-nav-links-wrapper">
                        {sites.map(function(site, i) {
                            return (
                            <SideNavigatorLink
                                key={i}
                                renderMobile={renderMobile}
                                displayName={site.displayName}
                                isActive={site.isActive}
                                url={site.value}
                            />
                            )
                        })
                        }
                    </div>
                )
            }
        </Translation>
    )
}

export default SideNavigator