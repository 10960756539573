import { TOGGLE_ACCORDION, SUMMARY_RAIL_SPINNER, APPLY_COUPON_BTN_SPINNER } from "./SummaryRailActionType";

const initialState = {
  accordion: true,
  showSpinner: false,
  showLoadingBtn: false,
};

const RailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ACCORDION:
        return {
            ...state,
           accordion: !state.accordion
        }
    case SUMMARY_RAIL_SPINNER:
        return {
          ...state, 
          showSpinner: action.showSpinner
        }
    case APPLY_COUPON_BTN_SPINNER:
        return {
          ...state, 
          showLoadingBtn: action.showLoadingBtn
        }
    default:
      return state;
  }
  
            
};

export default RailsReducer;
