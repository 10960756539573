import React from 'react';
import i18next from 'i18next';
import AppSettings from '../../../core/AppSettings';

const MSRP = (props) =>
    <h6 className='q-text q-body2'>
        <span className='q-bold2'> {i18next.t('LABEL_COMMON_MSRP')}: {props.price}</span>
    </h6>;


const PartPrice = (props) =>
    <h6 className='q-text q-body2'>
        <span className='q-bold2'> {i18next.t('LABEL_SEARCH_RESULTS_PART_PRICE')}: {props.price}</span>
    </h6>;


const InstallPrice = (props) =>
    <h6 className='q-text q-body2'>
        <span className='q-bold2'>{i18next.t('LABEL_SEARCH_RESULTS_INSTALLED_PRICE')}: {props.price}</span>
    </h6>;

const ViewOnVehiclePrice = (props) => {
    if (AppSettings.isT3) {
        return (
            <div>
                <PartPrice price={props.productPrice} />
                <InstallPrice price={props.installPrice ? props.installPrice : i18next.t('LABEL_SEARCH_RESULTS_CONTACT_DEALER')} />
            </div>
        );
    } else {
        return (
            <div>
                <MSRP price={props.productPrice} />
            </div>
        );
    }
}

export default ViewOnVehiclePrice;