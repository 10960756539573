import React, { useEffect, useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import {
    addAnalyticsToAddPaymentForm,
    formatPaymentMethodsConfigParams,
    formatSessionObj,
    getPaymentTypeImage
} from "../GMWalletHelper";
import {getSession} from "../GMWalletService";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import {appendAnalyticsTagAsURLParam} from "../../Utils/Utils";

// TODO: will remove commented out console.logs after first successful release

const AddNewPayment = (props) => {
    const { t } = useTranslation();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS + appendAnalyticsTagAsURLParam("eVar25", "wallet-add-payment");;
    const adyenConfigClientKey = process.env.REACT_APP_GM_WALLET_ADYEN_CLIENT_KEY_TARGETABLE;
    const adyenConfigEnvironment = process.env.REACT_APP_GM_WALLET_ADYEN_ENVIRONMENT_TARGETABLE;

    let shopperReference = props.isCheckout ? props.params.userId : props.state.params?.userId;
    let orderNo = props.isCheckout ? props.params.orderNo : props.state.params?.orderNo;
    let totalAmount = props.state.params.totalAmount;
    let currency = props.state.params.currency;

    let enableStoreDetails = props.isAuthUser && !props.viewAddOneTimePayment ? "true" : "false";//this shows the checkbox on the UI to have the option to save payment method
    let billingAddressRequired = "false";

    //storing the total payment methods and session response
    const [totalPaymentMethods, setTotalPaymentMethods] = useState({});
    const [sessionResponse, setSessionResponse] = useState({});
    // storing which payment component chosen, either 'card' or 'ach' for direct debit for example
    const [componentChosen, setComponentChosen] = useState("card");
    //stores the initial dropin component created so that we are able to call methods associated with it such as unmount() which unmounts the dropin.
    const [dropinComponent, setDropinComponent] = useState({});
    //setting the name on card to prefill the labels depending on which payment method was chosen
    const [nameOnCard, setNameOnCard] = useState("");
    //sets the selected payment type to help show user which payment is active on the UI
    const [userSelectedPaymentMethod, setUserSelectedPaymentMethod] = useState("scheme");

    const [listOfBrands, setListOfBrands] = useState([]);

    //config values converted to boolean values
    const isEnableStoreDetails = enableStoreDetails === "true";
    const isBillingAddressRequired = billingAddressRequired === "true";
    //config values to pass into the paymentMethod object helper function to pass to Adyens Widget configuration
    const configValues = {
        isEnableStoreDetails,
        isBillingAddressRequired,
        totalAmount,
        currency
    };
    let sessionObj = {};
    let session;

    //values for API request payloads
    let params = {
        totalAmount: props.state.params.totalAmount,
        currency: props.state.params.currency,
        userId: shopperReference,
        orderNo: orderNo.toString(),
        storePaymentMethod: props.isAuthUser && !props.viewAddOneTimePayment ? "false" : "true" // set this to False for Auth flow because the user must agree to T&Cs before saving card to wallet// and does not for guest since its tied to the order number
    };

    useEffect(() => {
        // console.log("showing Adyen payment poc ",props.options);
        adyenCheck().then((values) => {
            // console.log("values promise return", values);
            // dropinComponent.setStatus('success', { message: 'Payment successful!' });
            values.paymentMethodsObj && setTotalPaymentMethods(values.paymentMethodsObj);
            values.dropinComponent && setDropinComponent(values.dropinComponent);
            values.session && setSessionResponse(values.session);
            addAnalyticsToAddPaymentForm(props.isAuthUser);
        });
    }, [componentChosen, props.state?.paymentResult]);

    const adyenCheck = async () => {
        //passing in the nameOnCard value to paymentMethods configuration
        nameOnCard !== "" && (configValues.nameOnCard = nameOnCard);
        //if statement to only make the api calls once while user selects different payment methods
        if (JSON.stringify(sessionResponse) === "{}") {
            session = await getSession(params);
            //formats the session object
            sessionObj = formatSessionObj(session);
        } else {
            //passes in the 'sessionResponse' hook value since it has already been stored in the hook
            sessionObj = formatSessionObj(sessionResponse);
        }

        const configuration = {
            clientKey: adyenConfigClientKey,
            ...sessionObj,
            locale: "en_US",
            environment: adyenConfigEnvironment ? adyenConfigEnvironment : "live",
            translations: {
                //https://docs.adyen.com/online-payments/web-components/localization-components
                "en-US": {
                    payButton: t("LABEL_GMWALLET_CONFIRM_PAY_BTN"),
                    confirmPreauthorization: t("LABEL_GMWALLET_CONFIRM_PAY_BTN"),
                    storeDetails:
                        t("LABEL_GMWALLET_CONFIRM_PAYMENT_AGREEMENT") +
                        t("LABEL_GMWALLET_CONFIRM_PAYMENT_PRIVACY_LINK") +
                        "."
                }
            },
            onPaymentCompleted: (result, component) => {
                // console.log("onPaymentCompleted result", result);
                // console.log("onPaymentCompleted component", component);
                // console.info(result, component);
                component.unmount();
                //clear any old messages from previous adding card attempt
                props.dispatch({ type: "NO_MESSAGES" });
                if (props.isAuthUser) {
                    if (result.resultCode === "Authorised") {
                        //when a payment method is successfully added, and make sure the user selects the T&Cs before changing the page
                        //which is capture by the component.state.storePaymentMethod value returned after hitting the Confirm button
                        // they must select it or we will not store the payment for them
                        if (!component.state.storePaymentMethod && !props.viewAddOneTimePayment) {
                            //this will trigger an error message on the Add New payment page to inform user that checkbox selection is required
                            //only occurs in wallet flow
                            props.dispatch({ type: "SHOW_ADD_TO_WALLET_CHECKBOX_REQUIRED_ERROR" });
                        } else {
                            //dispatches to reducer only in wallet flow
                            if (component.state.storePaymentMethod) {
                                props.dispatch({ type: "VIEW_ADD_NEW_PAY", boolean: false });
                            }
                            //dispatches to reducer only in one time payment flow
                            if (props.viewAddOneTimePayment) {

                                props.dispatch({ type: "VIEW_ADD_ONE_TIME_PAYMENT", boolean: false });
                            }
                            //dispatches to reducer that happen in both wallet and OTP flow
                            props.dispatch({ type: "SHOW_SPINNER", boolean: true });
                            props.dispatch({
                                type: "SET_IS_ONE_TIME_PAYMENT_FLOW",
                                boolean: props.viewAddOneTimePayment
                            });
                            props.dispatch({ type: "NEW_PAYMENT_ADDED", response: true });
                        }
                    } else {
                        //this will trigger an error message on the Add New payment page
                        //catch all for all refused result codes
                        props.dispatch({ type: "NEW_PAYMENT_ADDED", response: false });
                    }
                } else {
                    //displaying an error or success message depending on the resultCode
                    props.dispatch({ type: "NEW_PAYMENT_ADDED", response: result.resultCode === "Authorised" });
                    //For Guest checkout flow, when a payment method is successfully added it will leave the add new payment view
                    if (result.resultCode === "Authorised")
                        props.dispatch({ type: "VIEW_ADD_NEW_PAY", boolean: false });
                }
                props.dispatch({ type: "SET_PAYMENT_RESULT", result: result });
            },
            showPayButton: true,
            onError: (error, component) => {
                console.error(error.name, error.message, component);
                // console.log("onError error result", error);
                // console.log("onError component result", component);
            }
        };

        const checkout = await AdyenCheckout(configuration);
        const retrievedPaymentMethodsObj = checkout.paymentMethodsResponse.paymentMethods;
        // console.log("retrievedPaymentMethodsObj ", retrievedPaymentMethodsObj);

        retrievedPaymentMethodsObj.forEach((payMethods) => {
            payMethods && payMethods.brands && setListOfBrands(payMethods.brands);
        });
        //helper function to set the paymentMethods configuration object for each type of payment method. 'card': or 'ach': for example
        let { paymentMethodsObj, paymentMethodsConfigurationFormatted } = await formatPaymentMethodsConfigParams(
            retrievedPaymentMethodsObj,
            {},
            configValues,
            -1
        );

        //set the paymentMethodsConfiguration updated object within the Adyen checkout object
        checkout.options.paymentMethodsConfiguration = { ...paymentMethodsConfigurationFormatted };

        // console.log("checkout ", checkout);
        //instatiate the dropinComponent
        const dropinComponent = checkout.create(componentChosen);
        // mount the dropinComponent
        dropinComponent.mount("#dropin-container");

        return { paymentMethodsObj, dropinComponent, session };
    };

    const clickOnCard = (method, key) => {
        let chosenType = method.type === "scheme" ? "card" : method.type;
        if (chosenType !== componentChosen) {
            dropinComponent.unmount();
            //checks the type of each payment method. Type of 'scheme' returns a standard payment form so it is changed to type of 'card' to display proper payment form.
            setComponentChosen(chosenType);
            //sets the name on card value so that it prefills the label with the stored cards name.
            setNameOnCard(method?.holderName);
            setUserSelectedPaymentMethod(method.type)
        }
    };

    const renderPaymentMethods = (paymentMethod,key) => (
        <div className={"payment-type-btn-container"} key={key}>
            <button
                id={"wallet-" + (paymentMethod.type === "scheme" ? "card" : paymentMethod.type) + "-button"}
                className={userSelectedPaymentMethod === paymentMethod.type ? "adyen-payment-types-selected" : "adyen-payment-types"}
                key={key}
                onClick={() => {
                    clickOnCard(paymentMethod, -1);
                }}
            >
                <img
                    src={
                        paymentMethod.type === "scheme"
                            ? getPaymentTypeImage("card")
                            : getPaymentTypeImage(paymentMethod.type)
                    }
                />
            </button>
            <label className={"payment-type-label"}>{paymentMethod.name}</label>
        </div>
);

    return (
        <>
            <Card className="card-bootstrap">
                <div id="add-new-pay-header-section">
                    <span className="add-new-pay-header">{props.isCheckout ? t("LABEL_GMWALLET_ADD_NEW_HEADER_CHECKOUT") : t("LABEL_GMWALLET_HEADER")}</span>
                    {props.isAuthUser && <span id="wallet-cancel-add-payment-link" className="cancel-add-payment-link" onClick={() => {
                        props.dispatch({ type: "VIEW_ADD_NEW_PAY", boolean: false });
                        props.dispatch({ type: "VIEW_ADD_ONE_TIME_PAYMENT", boolean: false });
                        props.dispatch({ type: "SET_IS_ONE_TIME_PAYMENT_FLOW", boolean: false });
                        props.dispatch({ type: "NO_MESSAGES" });
                    }}>
                        {t("LABEL_COMMON_CANCEL")}
                    </span>}
                </div>
                <div id="add-new-pay-body add-new-pay-border">
                    <span className="add-new-pay-dropin">
                        {/*iterates through the payment methods. Such as 'credit card' , 'ach' ..etc*/}
                        {JSON.stringify(totalPaymentMethods) !== "{}" &&
                            totalPaymentMethods.paymentMethods.map((paymentMethod, key) => (
                                //only displays credit card payment method only when user wants to add payment to wallet(not one time payment)
                                !props.viewAddOneTimePayment && paymentMethod.type === 'scheme' &&
                                renderPaymentMethods(paymentMethod,key)
                                ||
                                //shows all payment methods to be used for one time use payments or for guest users
                                (props.viewAddOneTimePayment || !props.isAuthUser) &&
                                renderPaymentMethods(paymentMethod,key)
                            ))}
                    </span>
                    <div id="add-new-pay-border" />

                    <div className="add-pay-component-container">
                        {componentChosen === "card" && (
                            <div className="add-new-pay-header">
                                {t("LABEL_GMWALLET_ADD_CREDIT_OR_DEBIT")}
                                <br />
                                {JSON.stringify(listOfBrands) !== "[]" &&
                                    listOfBrands.map((brand, key) => (
                                        <a
                                            id={brand + "-link-" + key}
                                            key={key}
                                            //href={paymentImage(brand)}
                                            //target="_blank"
                                        >
                                            <img
                                                id={brand + "-image-" + key}
                                                className="cardImage"
                                                src={getPaymentTypeImage(brand)}
                                                alt="Card Type"
                                            />
                                        </a>
                                    ))}
                            </div>
                        )}
                        <div id="dropin-container" />
                    </div>
                    {props.isAuthUser && <a className="privacy-statement-link" href={privacyLink} target="_blank">
                        {t("LABEL_GMWALLET_CONFIRM_PAYMENT_PRIVACY_LINK")}
                    </a>}
                </div>
            </Card>
        </>
    );
};

export default AddNewPayment;
