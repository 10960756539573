import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import store from "../../core/Redux/Store";
import VehicleSelectForm from "./VehicleSelectForm";
import VehicleSelectHeader from "./VehicleSelectHeader";
import {
    selectMake,
    selectModel,
    selectYear,
    toggleVinInstructions,
    loadVINDataAsync
} from "./VehicleSelectRedux/VehicleSelectActionCreator";
import VinInstructions from "./VehicleSelectVinInstructions";
import {
    loadVehicleCombosAsync,
    resetVehicleConfig
} from "../VehicleConfig/VehicleConfigRedux/VehicleConfigActionCreator";

/***************************************************************************/
/*            Vehicle Select === YMM/VIN search options
/***************************************************************************/
const VehicleSelect = (props) => {
    // Returns vehicle select form content. The content rendering is separated into
    // different components. State data for those components is loaded here and
    // then passed to the individual component's props. Because any change in
    // state causes VehicleSelect to re-render, the data in each component is
    if (props.vehicleInfoData) {
        // checks if user has entered a vin.
        // used to disable vehicle select
        // search button (see VehicleSelectForm)

        let enteredVin = "";
        if (props.formData && props.formData.values) {
            enteredVin = props.formData.values.vin;
        }

        // always current.
        return (
            <>
                <VehicleSelectHeader
                    headerLabel={props.headerLabel}
                    headerLabelDynamicValue={props.headerLabelDynamicValue}
                />
                <VehicleSelectForm
                    fullVehicleInfo={props.vehicleInfoData}
                    // YMM
                    handleYearChange={props.handleYearChange}
                    handleMakeChange={props.handleMakeChange}
                    handleModelChange={props.handelModelChange}
                    handleSetDefault={props.handleSetDefault}
                    enableSearchBtn={props.vehicleConfig} // TODO: clean up this nonsense.. see DISABLE_BTN in vehicle config
                    // VIN
                    enteredVin={enteredVin}
                    toggleVinInstructions={props.toggleVinInstructions}
                    // Form Search Button
                    handleSearchAccessories={props.handleSearchAccessories}
                />
                <div className="ac-vehicle-select-hide-on-small">
                    <VinInstructions showVinInstructions={props.vehicleInfoData.showVinInstructions} />
                </div>
            </>
        );
    }
};

const mapStateToProps = (state) => {
    // All data for the vehicle select is stored in the Redux store
    // state and accessed through the VehicleSelectReducer. mapStateToProps maps
    // the latest state data to props that are connected to VehicleSelect.
    // VehicleSelect then uses props.vehicleInfoData to render data in the component.
    return {
        vehicleInfoData: state.VehicleSelectReducer,
        vehicleConfig: state.VehicleConfigReducer.isBtnDisabled,
        formData: state.form.VehicleSelectForm
    };
};

const mapDispatchToProps = (dispatch) => {
    // Every time a user selects an item from a dropdown or toggles the VIN
    // instructions, an action is dispatched to update the state and load any
    // necessary data. The functions that dispatch those actions are defined
    // here and mapped to props that are connected to VehicleSelect.
    // When an event is triggered, VehicleSelect then uses props to call the
    // corresponding dispatch function.
    return {
        handleYearChange: (e) => {
            const year = e.target.value;
            dispatch(selectYear(year));
            //TODO: only need to reset if year changes....
            // dispatch(resetVehicleConfig())
        },
        handleMakeChange: (e) => {
            const make = e.target.value;
            dispatch(selectMake(make));
            //TODO: only need to reset if year changes....
            // dispatch(resetVehicleConfig())
        },
        handelModelChange: (e) => {
            const model = e.target.value;
            dispatch(selectModel(model));
            dispatch(loadVehicleCombosAsync());
        },
        handleSearchAccessories: (searchType) => {
            store.dispatch(loadVINDataAsync(searchType));
        },
        toggleVinInstructions: () => {
            dispatch(toggleVinInstructions());
        },
        handleSetDefault: (defaultMake) => {
            dispatch(selectMake(defaultMake));
        }
    };
};
/* prop types used to populate the dropdown and assign the selected YMM's
 *  years, yearsToModels, and yearsToMakes are used to populate the dropdowns
 *  dependent on the years the options may exist
 *  yearsToMakes is specifically only used in t3 sites, since the make is already set on brand sites
 * */
VehicleSelect.propTypes = {
    vehicleInfoData: PropTypes.shape({
        years: PropTypes.array.isRequired,
        yearsToModels: PropTypes.object.isRequired,
        selectedYear: PropTypes.string.isRequired,
        selectedMake: PropTypes.string,
        selectedModel: PropTypes.string.isRequired,
        showVinInstructions: PropTypes.bool.isRequired,
        yearsToMakes: PropTypes.object,
        codeToMake: PropTypes.object,
        defaultMake: PropTypes.string
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSelect);
