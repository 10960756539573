import queryString from 'query-string';
import { call, put, select } from 'redux-saga/effects';
import AppSettings from '../../core/AppSettings';
import { getRewardsHeaderPoints } from "../Rewards/RewardsRedux/RewardsActionCreator";
import { checkPartialAuthenticationError, isIEBrowser, isValidResponse } from "../Utils/Utils";
import {
    addWrapperForRedirect,
    getRegistrationStatus,
    setAuthenticationCookieIsSessionExpired,
    setEnrolledAttributeValue,
    setRegistrationStatus,
    setSSOCheck,
    setUserInformation,
    showAuthModal,
    unverifiedLoginError,
    userLoginError,
    userLoginSuccess,
    userLogoutError,
    userLogoutSuccess
} from './AuthenticationActionCreator';
import { getUserRegistrationStatus, postLogin, postUserLogout } from './AuthenticationService';


const REGISTERED_USER_STATUS_STRING = 'R';
const REGISTERED_USER_RESPONSE_STRING = 'RegisteredPerson';
const channelType = process.env.REACT_APP_AZURE_LOGIN_CHANNEL_TYPE;
const GUEST_USER_ID = '-1002';
export const GUEST_USER_STATUS_STRING = 'G';
export const ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED = 'ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED';
export const ERR_INVALID_COOKIE = '_ERR_INVALID_COOKIE';


// constants for SSO
export const HOME_PAGE = "HomePage";
export const SEARCH_RESULT_PAGE = 'Search';
export const PRODUCT_DETAILS_PAGE = 'Product';
export const CART_PAGE = 'Cart';
export const ROAD_BLOCK_PAGE = 'CheckoutMethod';
export const AUTHENTICATE_PAGE = 'Authenticate';


const getSSOCheck = state => state.Authentication.checkSSO;
const appReadyState = state => state.Authentication.appReadyState;


/*
**
Constructs the Azure login URL by taking in the following parameters and replacing their corresponding fields in the URL
Azure user flow -- will vary depending on azure action taken, ex. B2C_1_ACC_PREPROD_SIGNUP_SIGNIN
clientId -- retrieved from vault and dependant on environment
redirectPath -- this will almost always be <sitedomain>/authenticate, Azure requires a list of whitelisted URLs to redirect back to. Will vary based on current site
state -- this is a JSON objects that stores information that we collected about the user's current state in the application. Currently holds the redirectURL
that the user will be redirected to after successfully authenticated
channel -- will control how the OC common login page is formatted/styled ex. buick, chevrolet, chevrolet-acc.
prepopulatedEmail -- When the Azure page loads up, use this to pre-fill the email field with the given email
**
*/
export const constructAzureLoginURL = (userFlow, clientId, state, redirectPath, channel, prepopulatedEmail) => {

    let baseURL = AppSettings.azureBaseLoginURL;
    var redirectURI = window.location.protocol + "//" + window.location.host + redirectPath;

    // remove auth expired message from local storage
    if (localStorage.getItem('authExpiredMsg')) {
        localStorage.removeItem('authExpiredMsg');
    }

    if (baseURL != undefined && baseURL != "") {
        if (userFlow && baseURL.indexOf("{user_flow}") > -1) {
            baseURL = baseURL.replace("{user_flow}", userFlow);
        }
        if (clientId && baseURL.indexOf("{client_id}") > -1) {
            baseURL = baseURL.replace("{client_id}", clientId);
        }
        if (clientId && baseURL.indexOf("{client_id2}") > -1) {
            baseURL = baseURL.replace("{client_id2}", clientId + '%20offline_access%20');
        }
        if (state && baseURL.indexOf("{state}") > -1) {
            baseURL = baseURL.replace("{state}", state);
        }
        if (redirectURI && baseURL.indexOf("{redirect_uri}") > -1) {
            baseURL = baseURL.replace("{redirect_uri}", redirectURI);
        }
        //append channels with channel type 'acc' to be accessories site specific ex. buickacc
        if (redirectURI && baseURL.indexOf("{channel}") > -1) {
            baseURL = baseURL.replace("{channel}", channel + channelType);
        }
        // If no login hint is provided, then remove the placeholder, otherwise replace it with the given email
        if (prepopulatedEmail && baseURL.indexOf("{login_hint}") > -1) {
            baseURL = baseURL.replace("{login_hint}", prepopulatedEmail);
        } else {
            baseURL = baseURL.replace("&login_hint={login_hint}", '');
        }

        return baseURL;
    }
    else {
        return "";
    }
};

// Handles SSO Auto
export const handleAzureSSOCheck = () => {
     // userFlow will vary depending on azure action taken, ex. B2C_1_ACC_PREPROD_SIGNUP_SIGNIN
    let baseURL = AppSettings.azureSSOURL;
    const userFlow = AppSettings.azureLoginUserFlow;
    // retrieved from vault and dependant on environment
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
    // redirectPath -- this will almost always be <sitedomain>/authenticate.
    // Azure requires a list of whitelisted URLs to redirect back to. Will vary based on current site
    const currentURL = window.location;
    const redirectURI = encodeURI(currentURL.protocol + "//" + currentURL.host + '/authenticate');
    //current site (chevacc, buickacc, etc)
    const channel = AppSettings.currentSite.key;
    //This is to remove isSSO param from redirect URL
    const updateRedirectURLForSSO = window.location.href.replace("isSSO=true", "");
    //this is a JSON objects that stores information that we collected
    //about the user's current state in the application. Currently holds the redirectURL
    const applicationState = { 'redirectPath': updateRedirectURLForSSO };
    const state = encodeURIComponent(JSON.stringify(applicationState));
    // adds SSO param to redirect user to home page if not signed in anywhere
    // Currently only the first part of URL will get passed back.
    const authCheck = window.location.href;

    // Construct URL with above values
    if (baseURL != undefined && baseURL != "") {
        if (userFlow && baseURL.indexOf("{user_flow}") > -1) {
            baseURL = baseURL.replace("{user_flow}", userFlow);
        }
        if (clientId && baseURL.indexOf("{client_id}") > -1) {
            baseURL = baseURL.replace("{client_id}", clientId);
        }
        if (clientId && baseURL.indexOf("{client_id2}") > -1) {
            baseURL = baseURL.replace("{client_id2}", clientId + '%20offline_access%20');
        }
        if (redirectURI && baseURL.indexOf("{redirect_uri}") > -1) {
            baseURL = baseURL.replace("{redirect_uri}", redirectURI);
        }
        if (channel && baseURL.indexOf("{channel}") > -1) {
            baseURL = baseURL.replace("{channel}", channel + channelType);
        }
        if (state && baseURL.indexOf("{state}") > -1) {
            baseURL = baseURL.replace("{state}", state);
        }
        if (authCheck && baseURL.indexOf("{authCheck}") > -1) {

            if (isIEBrowser()) {
                // authCheck = redirect url from azure login page
                let authURL = window.location.href;
                const addQueryParam = authURL.indexOf('?') > 0 ? '&' : '?';
                authURL = authURL + `${addQueryParam}authCheck=true`;
                baseURL = baseURL.replace("{authCheck}", encodeURIComponent(authURL));
            } else {
                baseURL = baseURL.replace("{authCheck}", encodeURIComponent(window.location.href));
            }
        }

        // Check if user is logged in
        sessionStorage.setItem("SSOCHECK", 'true');
        window.location.href = baseURL;
    }
    else {
        return null;
    }

};

/*
**
Constructs the Azure logout URL by taking in the following parameters and replacing their corresponding fields in the URL
Azure user flow -- will vary depending on azure action taken, ex. B2C_1_ACC_PREPROD_SIGNUP_SIGNIN
clientId -- retrieved from vault and dependant on environment
redirectPath -- this paramater controls where the user is redirected to on logout. The logout URL is NOT restricted by the azure whitelist at all.
**
*/
export const constructAzureLogoutURL = (userFlow, clientId, state, redirectPath) => {
    let baseURL = AppSettings.azureBaseLogoutURL;
    if (baseURL != "") {
        if (userFlow && baseURL.indexOf("{user_flow}") > -1) {
            baseURL = baseURL.replace("{user_flow}", userFlow);
        }
        if (clientId && baseURL.indexOf("{client_id}") > -1) {
            baseURL = baseURL.replace("{client_id}", clientId);
        }
        if (state && baseURL.indexOf("{state}") > -1) {
            baseURL = baseURL.replace("{state}", state);
        }
        if (redirectPath && baseURL.indexOf("{redirect_uri}") > -1) {
            baseURL = baseURL.replace("{redirect_uri}", window.location.protocol + "//" + window.location.host + redirectPath);
        }
        return encodeURI(baseURL);
    }
    else {
        return "";
    }
};

/*
**
Handles a request for a user login
This makes a DELETE call out to commerce to end the current guest session
Upon successful completion of this call, it will make a subsequent call to begin a user session
**
*/

export function* handleRequestUserLogin(action) {

    try {
        if (action.idToken) {
            const response = yield call(postLogin, action.idToken);
            if ((response.status == 200 || response.status == 201) && response.data) {
                // Makes analtyics direct call on successful login.
                if (typeof _satellite != 'undefined') {
                    _satellite.track("login-complete");
                }
                yield put(userLoginSuccess());
                yield put(getRegistrationStatus());
            }
            else if (response.data.errors) {
                for (let i = 0; i < response.data.errors.length; i++) {
                    // Get the e-mail of the unverified account from the error message, so that it can be passed on to the UI
                    if (response.data.errors[i].errorKey === 'USER_REGISTRATION_NOT_VERIFIED') {
                        const userUnverifiedEmail = response.data.errors[i].errorParameters[0];
                        yield put(showAuthModal(true));
                        yield put(unverifiedLoginError("Account has not been verified.", userUnverifiedEmail));
                    }
                    else {
                        yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
                        yield put(showAuthModal(true));
                    }
                }
            }
            else {
                yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
                yield put(showAuthModal(true));
            }
        }
        else {
            yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
            yield put(userLoginError());
        }
    }

    catch (error) {
        // If the error is due to an account being unverified, then call the unverified Login Error
        if (error.response.data.errors) {
            for (var i = 0; i < error.response.data.errors.length; i++) {
                // Get the e-mail of the unverified account from the error message, so that it can be passed on to the UI
                if (error.response.data.errors[i].errorKey === 'USER_REGISTRATION_NOT_VERIFIED') {
                    const userUnverifiedEmail = error.response.data.errors[i].errorParameters[0];
                    yield put(showAuthModal(true));
                    yield put(unverifiedLoginError("Account has not been verified.", userUnverifiedEmail));

                }
                else {
                    yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
                    yield put(showAuthModal(true));
                }
            }
        }
        else {
            yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
            yield put(showAuthModal(true));
        }
    }

};

/*
**
Handles a request for a user logout
This makes a DELETE call out to commerce to end the current user session
Upon successful completion of this call, it will redirect the user to the Azure logout URL. From there it will be redirected back to the specified URL.
Error Handling: if logoutResponse fails and user is in checkout, send to roadblock page
**
*/
export function* handleRequestUserLogout(action) {
    let parseOrderId = queryString.parse(location.search).orderId;
    let orderId = parseOrderId ? parseOrderId : '';

    try {
        const logoutResponse = yield call(postUserLogout);

        if (logoutResponse && logoutResponse.status == 200) {
            yield put(userLogoutSuccess());
            // set up flag to prevent SSO on logout
            sessionStorage.setItem("isLogout", true);
            sessionStorage.removeItem("isRUser");
            // log user out
            window.location.href = constructAzureLogoutURL(action.userFlow, action.clientId, action.state, action.redirectPath);
        } else {
            yield put(userLogoutError("Unspecified error occured during logout. Please see server logs for more information."));
            if (AppSettings.pageName==='Checkout') {
                yield checkPartialAuthenticationError();
                }
        }
    }
    catch (error) {
        if (AppSettings.pageName==='Checkout') {
            yield checkPartialAuthenticationError();
         }
        yield put(userLogoutError("Unspecified error occured during logout. Please see server logs for more information."));
    }

};



/*
**
Handles retrieving the current registration status of the user
This makes a GET call out to commerce to retrieve information about the current user's session.
If the user is registered the user has a registration status of R in redux and their information from commerce (address, name, etc) is stored.
If the user is a generic/guest user, the user has a registration status of G in redux and their information is not stored
**
*/

export function* handleGetRegistrationStatus() {
    let parseOrderId = queryString.parse(location.search).orderId;
    let orderId = parseOrderId ? parseOrderId : '';
    let response = '';
    try {
         response = yield call(getUserRegistrationStatus);
        if (response.data) {

            const isValidRegistrationStatus = isValidResponse(response.data.registrationStatus);
            const userID = response.data.userId;
             if(userID === !GUEST_USER_ID && !isValidRegistrationStatus){
                 console.log("Unexpected self data return = >",response);
                 response = yield call(getUserRegistrationStatus);
             }
            //Logic applies to the /person/@self method of determining registration status
            if (response.status === 200 && response.data.registrationStatus) {
                const registrationStatus = response.data.registrationStatus;
                if (registrationStatus === REGISTERED_USER_RESPONSE_STRING) {
                    sessionStorage.setItem('isRUser','true');
                    yield put(setUserInformation(response.data));
                    yield put(setRegistrationStatus(REGISTERED_USER_STATUS_STRING));

                    // Get the enrolled attribute value
                    let enrolledAttributeValue = 0;
                    let memberNumber = null;
                    for (var i = 0; i < response.data.contextAttribute.length; i++) {
                        // Loop through attributes for GMREWARDS value (1 = rewards member (true), 0 = not a member (false))
                        if (response.data.contextAttribute[i].attributeName === 'GMREWARDS') {
                            enrolledAttributeValue = response.data.contextAttribute[i].attributeValue[0].value[0];
                        } else if (response.data.contextAttribute[i].attributeName === 'GMREWARDS_ID') {
                            memberNumber = response.data.contextAttribute[i].attributeValue[0].value[0];
                        }
                    }
                    yield put(setEnrolledAttributeValue(enrolledAttributeValue, memberNumber));
                    // call to get rewards Account Information data
                    yield put(getRewardsHeaderPoints());
                }
                else {
                    yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
                }
            }
            else if (response.data.errors) {
                const errors = response.data.errors[0];

                // On session timeout, log user out
                if (errors.errorKey === ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED || errors.errorKey === ERR_INVALID_COOKIE) {
                    if (AppSettings.pageName==='Checkout') {
                        yield checkPartialAuthenticationError();
                    }
                    // Update state, session expired = true
                    yield put(setAuthenticationCookieIsSessionExpired(true));
                }
                yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
            }
            else {
                yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
                // Update state, session expired = false
                yield put(setAuthenticationCookieIsSessionExpired(false));
            }
        }
        else {
            yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
            // Update state, session expired = false
            yield put(setAuthenticationCookieIsSessionExpired(false));
        }
    }
    catch (error) {
        let errorData = error.response && error.response.data ? error.response.data : '';
        let errorMsg = ''
        // If call fails (unauthenticated)
        if (errorData) {
            if (errorData.errors) {
                errorMsg = errorData.errors[0].errorKey;
                if (errorMsg === ERR_PARTIAL_AUTHENTICATION_NOT_ALLOWED || errorMsg === ERR_INVALID_COOKIE) {
                    // if user was on checkout page, log user out and send to roadblock page
                    if (AppSettings.pageName==='Checkout') {
                        // The auth cookies are deleted when the user closes the browser
                        // In production, the cookies are maintained
                        // Page redirect shouldn't happen when user continues checkout as guest,
                        // but since the self call fails without the deleted cookies in preprod,
                        // we check for auth cookies before redirecting
                        yield checkPartialAuthenticationError();
                       }

                    // Update state, session expired = true
                    yield put(setAuthenticationCookieIsSessionExpired(true));

                }
            }
        }

        yield put(setRegistrationStatus(GUEST_USER_STATUS_STRING));
    }
  yield put (addWrapperForRedirect(false));

};

export function* AzureSingleSignOnCheck() {
                    const checkSSO = yield select(getSSOCheck);
                    // Check SSO if user's first visit to page
                    if (!sessionStorage.getItem("isFirstVisit") && !sessionStorage.getItem("isLogout") && !sessionStorage.getItem("pageReloaded")) {
                        sessionStorage.setItem("isFirstVisit", true);
                        sessionStorage.setItem("pageReloaded", true);
                        handleAzureSSOCheck();
                    }
                    // if user's page is refreshed
                    else if (checkSSO && !sessionStorage.getItem("pageReloaded") && !sessionStorage.getItem("isLogout")) {
                        yield put(setSSOCheck(false));
                        sessionStorage.setItem("pageReloaded", true);
                        handleAzureSSOCheck();
                    }
                    else {
                        sessionStorage.removeItem("pageReloaded");
                        sessionStorage.removeItem("isLogout");
                    }
        }




