import PropTypes from "prop-types";
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../../shared/Modal';
import store from "../../../core/Redux/Store";
import { addToCartAsync } from '../ProductDetailsRedux/ProductDetailsActionCreator';
import FitmentCheckForm from './FitmentCheckForm';
import { loadFitmentCheckAsync, closeModal } from './FitmentCheckRedux/FitmentCheckActionCreator';
import AppSettings from "../../../core/AppSettings";
import vaultConstants from "../../../../config/vault_constants";

// Returns fitment check content. The content rendering is separated into 
// two files: fitmentCheck.js and fitmentCheckForm.js. fitmentCheck.js contains 
// logic to handle fitment match/no match (add item to cart/redirect user) and 
// renders the modal container for the fitment check form (which contains the fitment dropdowns). 
// State data for the fitmentCheckForm is loaded here and then passed to the component as its props.
const FitmentCheck = (props) => {
  const inlineFitmentFlag = AppSettings.isLocalHost ? false : String(vaultConstants.FF_2197227_INLINE_FITMENT_PDP_ACCESSORIES) === "true"; 
  if (props.dropdownData) {

    let bodyFitmentSelection = [];
    let wheelFitmentSelection = [];
    let trimFitmentSelection = [];
    let driveFitmentSelection = [];
    let engineFitmentSelection = [];

    if (props.dropdownData.fitmentSelections) {
      bodyFitmentSelection = props.dropdownData.fitmentSelections.bodyFitmentSelection;
      wheelFitmentSelection = props.dropdownData.fitmentSelections.wheelFitmentSelection;
      trimFitmentSelection = props.dropdownData.fitmentSelections.trimFitmentSelection;
      driveFitmentSelection = props.dropdownData.fitmentSelections.driveFitmentSelection;
      engineFitmentSelection = props.dropdownData.fitmentSelections.engineFitmentSelection;
    }

    // if the vehicle passes the fitment check, 
    // go ahead and add item to cart
    if (props.dropdownData.fitmentStatus == 'yes') {
      store.dispatch(closeModal());
      store.dispatch(addToCartAsync());
    }
    // if the vehicle doesn't pass the fitment check, 
    // redirect user to the search results page with user selected params passed to the url
    if (props.dropdownData.fitmentStatus == 'no') {
      return (
        null
      )
    }
    else {
      //if fitment check returns one fit with one selection
      //don't display the fitment modal, add item to cart
      if (props.dropdownData.oneFitOneSelectionFlag) {
        store.dispatch(addToCartAsync());
        return null;
      }
      else {

        const isFetchingVehicleCombinations = props.dropdownData.isFetchingVehicleCombinations;
        const isFetchingFitmentCheck = props.dropdownData.isFetchingFitmentCheck;
        const fitmentPassed = props.dropdownData.fitmentStatus == 'yes';
        // check if fitment call is made from add to cart or fitment modal
        const fitmentStep = props.dropdownData.fitmentStep;

        //TODO: Will have to add logic to implement the fitment check with inline fitment once that piece is delivered
        //for now could just turn off the modal by hard coding a false value into the open prop
        const FitmentForm = (
          <div className="small-12 q-grid-row-new-mobile columns">
            <Modal
              open={!inlineFitmentFlag && props.showModal}
              onClose={props.closeFitmentModal}
              onOverlayClick={props.closeFitmentModal}
              classNames={{
                modal: 'ac-fitment-modal'
              }}
              center>
              <FitmentCheckForm
                isFetchingVehicleCombinations={isFetchingVehicleCombinations}
                isFetchingFitmentCheck={isFetchingFitmentCheck}
                fitmentStep={fitmentStep}
                wheelDropDownLabel={props.dropdownData.wheelDropDownLabel}
                oneFitOneSelectionFlag={props.oneFitOneSelectionFlag}
                vehicleCombinations={props.dropdownData.vehicleCombinations}
                vehicleParams={props.dropdownData.vehicleParams}
                fitmentVehicleInfo={props.dropdownData.fitmentVehicleInfo}
                fitmentDetails={props.dropdownData.fitmentDetails}
                fitmentStatus={props.dropdownData.fitmentStatus}

                bodyFitmentSelection={bodyFitmentSelection}
                wheelFitmentSelection={wheelFitmentSelection}
                trimFitmentSelection={trimFitmentSelection}
                driveFitmentSelection={driveFitmentSelection}
                engineFitmentSelection={engineFitmentSelection}
                showError={props.showError}/>
            </Modal>
          </div>
        );

        return (
          <main>
            {
              /** SHOW Fitment Form **/
              !fitmentPassed && FitmentForm
            }
          </main>
        );
      }

    }

  }
}

const mapStateToProps = state => {
  // All data for the fitment check dropdowns is stored in the Redux store 
  // state and accessed through the FitmentCheckReducer. mapStateToProps maps 
  // the latest state data to props that are connected to FitmentCheck. 
  // FitmentCheck then uses props.dropdownData to render data in the component.
  // It gets ymm info from Session reducer
  return {
    dropdownData: state.FitmentCheckReducer,
    showModal: state.FitmentCheckReducer.showModal,
    oneFitOneSelectionFlag: state.FitmentCheckReducer.oneFitOneSelectionFlag,
    showError:state.FitmentCheckReducer.showError || false
  }
}

// actions for showing and closing
// the fitment check modal
const mapDispatchToProps = (dispatch) => {
  return {
    //check fitment and show modal
    fitmentCheckAndShowModal: () => {
      dispatch(loadFitmentCheckAsync());
    },
    //close modal
    closeFitmentModal: () => {
      dispatch(closeModal());
    }
  }
}

FitmentCheck.propTypes = {
  dropdownData: PropTypes.shape({
    wheelDropDownLabel: PropTypes.string,
    oneFitOneSelectionFlag: PropTypes.boolean,
    vehicleCombinations: PropTypes.array,
    fitmentDetails: PropTypes.array,
    fitmentStatus: PropTypes.string
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(FitmentCheck)