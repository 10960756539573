import React  from "react";
import StoredCardInfo from "../StoredCardInfo/StoredCardInfo";

const ViewWallet = (props) => {
    return (
        <div>
            {JSON.stringify(props.state.payMethodsResponse) !== "{}" &&
            props.state.payMethodsResponse &&
            props.state.payMethodsResponse.storedPaymentMethods &&
            props.state.payMethodsResponse.storedPaymentMethods.map((storedMethod, key) => (
                <div className="stored-card-info" key={key}>
                    <StoredCardInfo
                            showESTOREcard={storedMethod.walletType === "ESTORE_WALLET"}
                            storedCardData={storedMethod}
                            state={props.state}
                            dispatch={props.dispatch}
                    />
                </div>
            ))} 
        </div>
    );
};

export default ViewWallet;
