import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import AppSettings from '../../core/AppSettings';

const VehicleSelectVinInstructions = props => {

  const [imgError, setImgError] = useState(false);

  function getToggleClass() {
    let classes = 'ac-vehicle-select-instructions row q-gridbuilder gridfullwidth';
    return props.showVinInstructions ? classes + ' ac-vehicle-select-instructions-show' : classes + ' ac-vehicle-select-instructions-hide';
  }

  return (
    <Translation>
      {(t) => (
        <div className={getToggleClass()}>
          <div className="ac-vehicle-select-instructions-text ">
            <h2 className="q-headline1 medium-margin">{t('LABEL_COMMON_LOCATE_VIN')}</h2>

            {AppSettings.damSource && !imgError &&
              <>
                <div className="q-text q-body1 small-12 medium-6 xlarge-6 grid-column-alignment-left q-grid-row-new-mobile columns">
                  <p>{t('LABEL_COMMON_LOCATE_VIN_EXPLANATION')}</p>
                </div>
                <div className="small-12 medium-6 xlarge-6 grid-column-alignment-left q-grid-row-new-mobile columns">
                  <img className="ac-vehicle-select-instructions-img"
                    src={AppSettings.damSource + "products/images/b2c-locate_vin_illustration_no_text.svg"}
                    onError={(e) => { e.target.onerror = null; setImgError(true) }} />
                </div>
              </>
            }
            {/* center text and do not display image when there is error */}
            {(!AppSettings.damSource || imgError) &&
              <div className="q-text q-body1 ac-large-sidepadding">
                <p>{t('LABEL_COMMON_LOCATE_VIN_EXPLANATION_ERROR')}</p>
              </div>}
          </div>
        </div>
      )}
    </Translation>
  );
}

export default VehicleSelectVinInstructions;
