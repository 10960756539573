import { call, put } from "redux-saga/effects";
import AppSettings from "../../../../../core/AppSettings";
import { loadMiniCartDataAsync, loadMiniCartDataAsyncFail } from "./MiniCartCreator";
import { getMiniCartResponse } from "./MiniCartService";
import { CART_QTY_CHANGE_FAIL, CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY } from "../../../../Cart/Redux/CartActionTypes";
import { fetchCartData } from "../../../../Cart/Redux/CartHandler";
import { LOAD_MINI_CART_DATA_ASYNC } from "./MiniCartActionType";
import store from "../../../../../core/Redux/Store";
import { getOrderItemChangeQuantityResponse } from "../../../../Cart/Redux/CartService";

export function* getMiniCartData() {
    try {
        const response = yield call(getMiniCartResponse);
        const res = response.data;
        if (res && res.order) {
            const data = {
                orderId: res.order.orderId,
                count: res.order.recordSetTotal,
                data: res.order,
                status: response.status
            };

            yield put(loadMiniCartDataAsync(data));
            return data;
        } else if (res && Object.keys(res).length === 0) {
            // Minicart response will now be empty if no items in cart, handling here to reset count to 0
            const data = {
                orderId: null,
                count: 0,
                data: {},
                status: response.status
            };

            yield put(loadMiniCartDataAsync(data));
            return data;
        }
    } catch (error) {
        yield put(loadMiniCartDataAsyncFail(error));
    }
}

export const miniCartChangeQuantity = async (
    dispatch,
    orderId,
    orderItemId,
    laborOrderItemId,
    qty,
    authData,
    setShowItemSpinner
) => {
    try {
        let addFromCartQueryParam = true;
        let payload;
        if (laborOrderItemId) {
            payload = {
                x_action: "change_quantity",
                orderId: orderId,
                orderItem: [
                    { orderItemId: orderItemId, quantity: qty.toString() },
                    { orderItemId: laborOrderItemId, quantity: qty.toString() }
                ]
            };
        } else {
            payload = {
                x_action: "change_quantity",
                orderId: orderId,
                orderItem: [{ orderItemId: orderItemId, quantity: qty.toString() }]
            };
        }
        /** CHANGE PAYLOAD FOR T3 ORDER */
        if (AppSettings.isT3) {
            addFromCartQueryParam = false;
            payload = {
                x_action: "change_quantity",
                orderId: orderId,
                x_bac: AppSettings.bac,
                orderItem: [{ orderItemId: orderItemId, quantity: qty.toString() }]
            };
        }

        setShowItemSpinner(true);
        const response = await getOrderItemChangeQuantityResponse(payload, addFromCartQueryParam, authData);

        if (!response || !response.data || response.status !== 200 || response?.data?.errors?.length) {
            // Show alert message that not enough inventory is available instead of error message
            if (response?.data?.errors?.[0].errorMessage.indexOf("Inventory is Unavailable") > -1) {
                dispatch({ type: CART_QTY_CHANGE_FAIL_AVAILABLE_INVENTORY });
            } else {
                dispatch({ type: CART_QTY_CHANGE_FAIL });
            }
        } else {
            await fetchCartData(dispatch);
            store.dispatch({ type: LOAD_MINI_CART_DATA_ASYNC });
        }
    } catch (error) {
        dispatch({ type: CART_QTY_CHANGE_FAIL });
    } finally {
        setShowItemSpinner(false);
    }
};
