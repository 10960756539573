import React from 'react'
import SharedButton from '../../../../shared/Button/SharedButton';
import Modal from '../../../../shared/Modal';
import { useTranslation } from 'react-i18next';
import accHistory from '../../../App/History';
import { useDispatch, useSelector } from 'react-redux';
import { showShippingModal } from '../../CheckoutRedux/CheckoutActionCreator';

/**Simple Error Modal that opens when a delivery option selected is not available
 *  Opens in step 2 of checkout, when attempting to go from step2 to step3. 
 * @returns Shared modal component with delivery error messaging and a go to cart CTA. 
 */
function ShippingErrorModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const modalOpen = useSelector((state) => state.CheckoutReducer.showShippingErrorModal);

    const closeShippingModal = () => dispatch(showShippingModal(false));
    const shippingErrorContent = (
        <div>
            <span><h4>{t("LABEL_CHECKOUT_SHIPPING_ERROR_TITLE")}</h4></span>
            <hr className="q-separator-horizontal"/>
            <div style={{marginBottom: "1rem"}}>{t("LABEL_CHECKOUT_SHIPPING_ERROR")}</div>
            <div>
                <SharedButton
                    buttonText={t("LABEL_COMMON_GO_TO_CART")}
                    onClick={()=>{accHistory.push("/cart")}}
                />
            </div>
        </div>
    )

    return (
        <Modal open={modalOpen} closeOnOverlayClick={false} closeOnEsc={false} simpleModal={true} onClose={closeShippingModal} center>
            {shippingErrorContent}
        </Modal>
    )
}



export default ShippingErrorModal;