
import React, { Component } from 'react';
import AppSettings from '../../core/AppSettings';
import { titleSetUp } from "../Utils/Utils";
import { Translation } from 'react-i18next';
import VehicleSelectContainer from '../VehicleSelect/VehicleSelectContainer';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        /** Setting up Page Title **/
        titleSetUp('404');
        console.warn(this.props.location)
    }

    render() {

        let brandStyle = AppSettings.currentSite.label;

        //default dealer brands to chevrolet styles/images
        if (AppSettings.isT3) {
            brandStyle = 'chevrolet';
        }

        const imgFolderPath = AppSettings.damSource + 'marketing/accessories/' + brandStyle.toLowerCase() + '/na/usa/en_us/images/';
        const imgFileName = brandStyle.toLowerCase() + '-error-pg.png';
        const imgFileNameMd = brandStyle.toLowerCase() + '-error-pg-md.png';
        const imgFileNameSm = brandStyle.toLowerCase() + '-error-pg-sm.png';

        return (
            <Translation>
                {(t) => (
                    <div className={"ac-404-pg-wrapper ac-404-pg-wrapper-" + brandStyle.toLowerCase()}>
                        <div className="row q-gridbuilder collapse gridspacing-xlarge grid-bg-color-one gridfullwidth">
                            <div className="small-12 medium-12 xlarge-12 grid-column-alignment-left columns">
                                <div className="q-responsive-image q-mod q-mod-responsive-image q-margin-base none-margin q-rectangle-image-responsive q-animate q-animate-fade-in">
                                    <div className="q-content-overlay">
                                        <div className="q-layer ac-404-header-wrapper">
                                            <div className="row q-gridbuilder grid-bg-color-one gridfullwidth">
                                                <div className="small-12 medium-12 xlarge-12 grid-column-alignment-left columns">
                                                    <div className="q-margin-base  q-separator">
                                                        <div className="medium-margin hide-for-small hide-for-medium">
                                                            <hr className="invisible" />
                                                        </div>
                                                    </div>
                                                    <div className="q-margin-base q-separator">
                                                        <div className="small-margin hide-for-large">
                                                            <hr className="invisible" />
                                                        </div>
                                                    </div>
                                                    <div className="q-margin-base q-headline">
                                                        <div className="small-margin">
                                                            <h1 className="q-display1 q-invert ac-404-headline ac-fade-in">{t('LABEL_INVALID_ROUTE_HEADLINE')}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="q-space q-overlay">
                                        <div className="none-margin">
                                            <div className="q-responsive-image q-responsive-image-container stat-image-link q-content-overlay-gradient-generic-type-on-top-warm ac-image-wrapper">
                                                    <picture>
                                                        <source media="(min-width: 1500px)" className="q-rectangle-image-responsive" srcSet={imgFolderPath + imgFileName} />
                                                        {/* <source media="(min-width: 1200px)" className="q-rectangle-image-responsive" srcSet={'/parts/images/' + brandStyle.toLowerCase() + '/' + brandStyle.toLowerCase() + imgPathName} />		
                                                        <source media="(min-width: 960px)" className="q-rectangle-image-responsive" srcSet={'/parts/images/' + brandStyle.toLowerCase() + '/' + brandStyle.toLowerCase() + imgPathNameMd} /> */}
                                                        <source media="(min-width: 600px)" className="q-rectangle-image-responsive" srcSet={imgFolderPath + imgFileNameMd} />
                                                        {/* <source media="(min-width: 420px)" className="q-rectangle-image-responsive" srcSet={'/parts/images/' + brandStyle.toLowerCase() + '/' + brandStyle.toLowerCase() + imgPathNameSm} /> */}
                                                        <source media="(min-width: 0px)" className="q-rectangle-image-responsive" srcSet={imgFolderPath + imgFileNameSm} />
                                                        <img alt="Page Not Found" className="q-rectangle-image-responsive q-image ac-fade-in ac-image" src={imgFolderPath + imgFileName}/>
                                                    </picture>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row q-gridbuilder gridspacing-xlarge grid-bg-color-one gridfullwidth">
                            <div className="small-12 medium-12 xlarge-12 grid-column-alignment-left columns ac-404-tips">
                                <div className="small-12 medium-12 xlarge-12 grid-column-alignment-left columns">
                                    <VehicleSelectContainer headerLabel={'LABEL_ERROR_MSG_SEARCH_BY_VEHICLE'} />
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </Translation>
        )
    }
}

export default PageNotFound;