import React,{Component} from 'react';
import {Translation} from 'react-i18next';

const VehicleSection = props => {

    const vehicles =  [
        {
            year: "2018",
            model: "Chevrolet",
            make: "Silverado 1500",
            description: "LT, 3.6L V6 DI DOHC VVT Engine, 2WD",
            dealer: {
                dealername: "Mike Savoie Chevrolet",
                dealeraddress: "1900 Maple Road Troy, MI 48084"
            }
        },
        {
            year: "2019",
            model: "Chevrolet",
            make: "Silverado 1500",
            description: "LT, 3.6L V6 DI DOHC VVT Engine, 2WD",
            dealer: {
                dealername: "Mike Savoie Chevrolet",
                dealeraddress: "1900 Maple Road Troy, MI 48084"
            }
        }
    ];

    return(
        <Translation>
            {
                (t)=>(
                    <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">
                        {vehicles && vehicles.map((vehicle, index) =>
                            <div className="ac-my-vehicle small-12 grid-column-alignment-left columns ac-nopadding-x" key={index}>
                                <div className="small-12 large-3 xlarge-3 grid-column-alignment-left columns ac-nopadding-x">
                                    <img className="ac-history-image-thumb" src="https://www.chevrolet.com/content/dam/chevrolet/na/us/english/index/vehicles/2019/trucks/silverado-t1/colorizer/01-images/2019-silverado1500-piccrsh-2lt-g7c-colorizer.jpg"></img>
                                </div>
                                <div className="small-12 large-9 xlarge-9 grid-column-alignment-left columns">
                                    <div className="ac-vehicles-divider">
                                        <p className="ac-vehicles-mmy q-descriptive1">{vehicle.year} {vehicle.make} {vehicle.model}</p>
                                        <p className="ac-vehicles-description q-descriptive2">{vehicle.description}</p>
                                    </div>
                                    <div className="ac-vehicles-divider">
                                        <p className="ac-vehicles-dealer q-descriptive3">{t("LABEL_CONFIRMATION_DEALER_PICKUP")}</p>
                                        <p className="ac-vehicles-dealer-name q-body2">{vehicle.dealer.dealername}</p>
                                        <p className="ac-vehicles-dealer-address q-body2">{vehicle.dealer.dealeraddress}</p>
                                    </div>
                                    <div>
                                        <a className="q-body2 ac-vehicles-link">{t("LABEL_VEHICLES_MANAGE_OC")}</a>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="ac-my-vehicle small-12 grid-column-alignment-left columns ac-nopadding-x">
                            <div className="ac-vehicles-divider">
                                <p className="ac-vehicles-mmy q-descriptive1">{t("LABEL_MY_VEHICLE")}</p>
                            </div>
                            <div>
                                <a className="q-body2 ac-vehicles-link">{t("LABEL_VEHICLES_ADD_MANAGE_OC")}</a>
                            </div>
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

export default VehicleSection;