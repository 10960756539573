/********** COMPONENT NAME:WHEEL PACKAGE,
            DEPENDENCY:WHEEL PACKAGE TABLE
 *****************/

/********** IMPORT STATEMENT FOR WHEEL PACKAGE *****************/
import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import {Translation} from 'react-i18next';
import WheelPackageTable from './WheelPackageTable/WheelPackageTable';
import propTypes from "prop-types";
import {asyncCall,closeModal} from './WheelPackageRedux/WheelPackageActionCreator';
import Spinner from '../Spinner/Spinner';
import ErrorMessage from "../ErrorMessage/ErrorMessage";



/********** BEGINNING OF WHEEL PACKAGE *****************/
const WheelPackage = (prop)=>{
    const customCss = prop.cssName ||'';
    return (
        <Translation>
            { (t) => (
                <div className="ac-wheelPackageWrapper ">
                   <span className="q-link ac-wheelPackageLink" onClick={prop.showModel}>
                       {/********** CLICKABLE LINK TO OPEN THE MODAL *****************/}
                    <a className="ac-accessible-link-underline" href="javascript:void(0);">{t("LABEL_WHEEL_PACKAGES")}</a></span>
                   <div>
                        <Modal open={prop.showModal} onClose={prop.closeModal} onOverlayClick={prop.closeModal}  classNames={{
                            overlay:`${customCss}`,
                            modal:'ac-fixedModalShadow'
                        }} center>
                            {/********** SPINNER WILL SHOW WHILE TRYING TO LOAD THE DATA *****************/}
                            {prop.showSpinner && <div className="">
                                <h4 className="">{t("LABEL_WHEEL_PACKAGES")}</h4>
                              <Spinner/>
                            </div>}
                         {
                             /********** RENDER WHEEL PACKAGE TABLE WHEN THERE IS PROPER DATA *****************/
                                prop.wheelPackDataReady && <div>
                                    <WheelPackageTable data={prop.wheelData}/>
                                </div>
                            }
                            {
                                /********** DISPLAY AN ERROR MESSAGE IF REST CALL FAILS *****************/
                                prop.showErrorMessage && <div className="ac-wheelPackage-modal-errorMessage">
                                 <ErrorMessage icon={true} message={t("LABEL_SERVER_ERROR_MSG")}/>
                                </div>
                            }
                        </Modal>
                    </div>
                </div>
            )}
        </Translation>
    )
}

/********** MAPPING STATE TO PROPS *****************/
const mapStateToProps = state =>{
    let reducer = state.WheelPackageReducer;
     return {
        showSpinner:reducer.isFetching || false,
        showErrorMessage:reducer.showError,
        showModal:reducer.showModal,
        wheelData:reducer.wheelPackageData,
        wheelPackDataReady:!!(reducer.wheelPackageData !== undefined && reducer.wheelPackageData.length)
    };
};


/********** ACTION DISPATCH *****************/
const mapDispatchToProps = (dispatch,ownProps) =>{
   return {
        showModel : ()=>{
            dispatch(asyncCall(ownProps));
        },
        closeModal : ()=>{
            dispatch(closeModal())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WheelPackage)

/********** END OF THE WHEEL PACKAGE *****************/