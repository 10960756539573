import React, { useEffect, useState } from "react";
import AppSettings from "../../../core/AppSettings";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SharedYMMSearchExtend from "./SharedYMMSearchExtend";
import { getFitmentCheckData, fitmentCheckYMM } from "../FitmentCheck/FitmentCheckRedux/FitmentCheckService";
import InlineFitmentResults from "./InlineFitmentResults";
import { handlePSRRedirectYMM, createVehicleInfoObjectYMM } from "./HelperFunctions";
import PropTypes from "prop-types";
import JellybeanImage from "../../../shared/JellybeanImage/JellybeanImage";

/**
 * This component will render the proper dropdowns for each step after Year, Make, and Model. Note that for certain selections(body, drive)
 * additional dropdowns will show
 * @returns dropdowns for each step
 */

export default function InlineFitmentSteps(props) {
    const ymmData = useSelector((state) => state.VehicleSelectReducer);
    const ymmExtendData = useSelector((state) => state.VehicleConfigReducer.fitmentVehicleInfo);
    const bodyNumDoorsParam = useSelector((state) => state.VehicleConfigReducer.vehicleParams.bodyNumDoorsParam);
    const modelToModelID = ymmData.modelToModelID;
    const [currentModelToModelID, setCurrentModelToModelId] = useState({});
    const modelId = ymmData.selectedModel ? modelToModelID?.[ymmData.selectedModel] : "";
    const [steps, setSteps] = useState(0);
    const [neededFitment, showNeededFitment] = useState();
    const catID = useSelector((state) => state.ProductDetailsReducer.uniqueID);
    const { t } = useTranslation();
    const ymmHeader = `${ymmData.selectedYear} ${AppSettings.isGenericPartsSite ? ymmData.selectedMake : ""} ${
        ymmData.selectedModel
    }`;
    const [vehicleBreadcrumb, setVehicleBreadcrumb] = useState([ymmHeader]);
    const [showFitView, setShowFitView] = useState(false);
    const currentVehicleInfo = props.currentVehicleInfo;
    const showFitHandler = () => {
        setShowFitView(true);
    };
    const [fitmentCheckArray, setFitmentCheckArray] = useState([]);

    useEffect(() => {
        if (true) {
            fitmentCheckYMM(catID, ymmData.selectedYear, ymmData.selectedMake, ymmData.selectedModel).then((res) => {
                props.setCurrentVehicleInfo(
                    createVehicleInfoObjectYMM(ymmData, ymmExtendData, modelId, bodyNumDoorsParam)
                );
                if (res?.fitment?.fitmentStatus === "no") {
                    setShowFitView(true);
                    setFits(false);
                } else if (res?.fitment?.fitmentStatus === "yes") {
                    setShowFitView(true);
                    setFits(true);
                } else {
                    if (res?.fitment?.fitmentDetails?.includes("engineBase")) {
                        let index = res.fitment.fitmentDetails.indexOf("engineBase");

                        //changing field name from 'engineBase' to 'engine' to map properly to ymmExtendData object
                        res.fitment.fitmentDetails[index] = "engine";
                    }
                    setFitmentCheckArray(res?.fitment?.fitmentDetails);
                    if (
                        res?.fitment?.fitmentDetails?.includes("bodyStyle") &&
                        res?.fitment?.fitmentDetails?.includes("wheelBase") &&
                        res?.fitment?.fitmentDetails?.includes("driveType") &&
                        res?.fitment?.fitmentDetails?.includes("engine")
                    ) {
                        setSteps(res?.fitment?.fitmentDetails?.length - 2);
                        showNeededFitment(res?.fitment?.fitmentDetails);
                    } else if (
                        res?.fitment?.fitmentDetails?.includes("bodyStyle") &&
                        res?.fitment?.fitmentDetails?.includes("wheelBase")
                    ) {
                        setSteps(res?.fitment?.fitmentDetails?.length - 1);
                        showNeededFitment(res?.fitment?.fitmentDetails);
                    } else if (
                        res?.fitment?.fitmentDetails?.includes("driveType") &&
                        res?.fitment?.fitmentDetails?.includes("engine")
                    ) {
                        setSteps(res?.fitment?.fitmentDetails?.length - 1);
                        showNeededFitment(res?.fitment?.fitmentDetails);
                    } else {
                        setSteps(res?.fitment?.fitmentDetails?.length);
                        showNeededFitment(res?.fitment?.fitmentDetails);
                    }
                }
            });
        }
    }, [ymmData, steps]);
    useEffect(() => {
        let requiredNumberOfFitments = fitmentCheckArray.length;
        let selectedfitments = 0;

        if (fitmentCheckArray.length) {
            for (let index in fitmentCheckArray) {
                if (ymmExtendData[fitmentCheckArray[index]] != "") {
                    selectedfitments += 1;
                }
            }
            if (selectedfitments == requiredNumberOfFitments) {
                setShowFitView(true);
            }
        }
    }, [
        ymmExtendData.bodyStyleId,
        ymmExtendData.driveTypeId,
        ymmExtendData.trimId,
        ymmExtendData.wheelBaseId,
        ymmExtendData.engineId,
        fitmentCheckArray
    ]);
    const [fits, setFits] = useState(undefined);

    useEffect(() => {
        // Check for valid modelToModelID object due to timing issue when prepopulating
        // TODO: Test if can remove the currentModelToModelID comparison once duplicate calls removed
        if (
            showFitView &&
            Object.keys(modelToModelID).length &&
            JSON.stringify(modelToModelID) !== JSON.stringify(currentModelToModelID)
        ) {
            getFitmentCheckData(
                catID,
                ymmData.selectedYear,
                ymmData.selectedMake,
                ymmData.selectedModel,
                ymmExtendData.bodyStyleId,
                ymmExtendData.wheelBaseId,
                ymmExtendData.trimId,
                ymmExtendData.driveTypeId,
                ymmExtendData.engineId
            ).then((res) => {
                setCurrentModelToModelId(modelToModelID);
                props.setCurrentVehicleInfo(
                    createVehicleInfoObjectYMM(ymmData, ymmExtendData, modelId, bodyNumDoorsParam)
                );
                if (res?.fitment?.fitmentStatus === "yes") {
                    setFits(true);
                } else {
                    setFits(false);
                }
            });
        }
    }, [showFitView, modelToModelID]);

    const handleVehicleBreadcrumb = (value, replace) => {
        // Prevent weird situation in which breadcrumb could be added twice
        if (!vehicleBreadcrumb.includes(value)) {
            if (replace) {
                setVehicleBreadcrumb((prev) => [...prev.slice(0, -1), value]);
            } else {
                setVehicleBreadcrumb((prev) => [...prev, value]);
            }
        }
    };

    if (showFitView && (fits === true || fits === false)) {
        return (
            <InlineFitmentResults
                vehicleBreadcrumb={vehicleBreadcrumb}
                fits={fits}
                onReset={props.onReset}
                handlePSRRedirect={() => handlePSRRedirectYMM(ymmData, ymmExtendData, bodyNumDoorsParam, modelId)}
                vehicleInfo={currentVehicleInfo}
                fitmentError={props.fitmentError}
            />
        );
    }

    return (
        <div id="inline-steps">
            <div className="inline-steps-breadcrumbs">
                <JellybeanImage />
                <b className="gb-body3 inline-vehicle-fitment">{vehicleBreadcrumb.join(", ")}</b>
            </div>
            <div className="ymm-wrapper">
                <SharedYMMSearchExtend
                    hasReset={props.hasReset}
                    inlineFitmentYMM
                    steps={steps}
                    neededFitment={neededFitment}
                    autoComplete
                    handleVehicleBreadcrumb={handleVehicleBreadcrumb}
                    showFitHandler={showFitHandler}
                />
            </div>
            <a
                className="reset-vehicle-fit stat-text-link"
                data-dtm={"vehicle fit"}
                id="inline-reset-vehicle-fit"
                onClick={() => props.onReset()}
            >
                {t("LABEL_RESET_VEHICLE_INLINE_FIT")}
            </a>
        </div>
    );
}

InlineFitmentSteps.propTypes = {
    onReset: PropTypes.func.isRequired,
    setCurrentVehicleInfo: PropTypes.func.isRequired
};
