
import React, { useState, useEffect } from 'react';
import { Translation, Trans } from 'react-i18next';
import vaultConstants from '../../../../config/vault_constants';
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import Button from '../../../shared/Button/Button';
import SuccessMessage from '../../../shared/SuccessMessage/SuccessMessage';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import FormInstructions from '../../../shared/Form/FormInstructions';
import Footnote from '../../../shared/Footnote/Footnote';
import queryString from 'query-string';
import Spinner from '../../../shared/Spinner/Spinner';
import { pageTitle } from "../../../shared/Hooks/Hooks";
import { Redirect } from 'react-router-dom';
import { generateVerificationCode, verifyCode, exchangeCode, sendEmailLink } from '../shared/AccountRegistrationAPI';
import { handleUserLogin, getBrandUrl } from '../../../shared/Utils/Utils';
import AppSettings from '../../../core/AppSettings';

function AccountConfirmation(props) {
    pageTitle("Account Confirmation");
    // PAGE STATUS
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState("");
    const [orderId, setOrderId] = useState("");
    // This will be false if this page is being directed to by the create password page. This will keep the page from doing any token verifying.
    const [checkExpiration, setCheckExpiration] = useState(props.location.state ? props.location.state.checkExpiration : true)
    const ff_2224243_caps_v3_migration = AppSettings.isLocalHost ? true : String(vaultConstants.FF_2224243_CAPS_V3_MIGRATION_TARGETABLE) === "true";

    // Get token and email from URL and store it
    let parsed = queryString.parse(window.location.search);
    const [token, setToken] = useState(parsed.token);
    const email = props.location.state ? props.location.state.email : "";
    const [verificationEmail, setVerificationEmail] = useState(parsed.username);

    // This will be used to trigger a redirect to /verificationExpired if the API returns the error.
    const [isVerificationExpired, setIsVerificationExpired] = useState(false);

    // The user will be redirected to this page if they try to go directly to /register/accountConfirmation. 
    // They will also see an error which can be triggered with the noEmailError variable.
    if ((email == "" || email == null) && (!token) && (!verificationEmail)) {
        return <Redirect to={{ pathname: '/register', state: { noEmailError: true } }} />
    }

    // Terms and conditions, and privacy links
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

    // 1.) VERIFY TOKEN, CHECK IF IT'S EXPIRED (ON PAGE LOAD)
    useEffect(() => {
        //If user is coming from order confirmation, order ID will be passed in through props
        setOrderId(props.location.state?.orderId ? props.location.state.orderId : parsed.orderId ? parsed.orderId : "");
        if (ff_2224243_caps_v3_migration) {
            //Parse URL for customer email. EX. of domain: https://localhost:8093/register/accountConfirmation/testnewlink@yopmail.com/1266543789/?token=iKefh6D0LiYQs2mkN913ENUICDwJTmj9&evar36=OC_US_eml_gm_email_verification
            let path = window.location.pathname.split("/");

            for (let i = 0; i < path.length; i++) {
                if (path[i].includes("@")) {
                    setVerificationEmail(path[i]);
                    let orderIdIndex = i + 1; //The order id will always come after the email
                    setOrderId(path[orderIdIndex]);
                }
            }
        }
        //TODO: Remove if logic and FF after V3 migration successful
        if (!ff_2224243_caps_v3_migration) {
            if (checkExpiration) {
                verifyCode(verificationEmail, token).then(result => {
                    if (result.data.status === "success") {
                        // Makes analtyics direct call when user finishes the registration process
                        if (typeof _satellite != 'undefined') {
                            _satellite.track('registration-complete');
                        }
                        // Registering flow from the OCP page -- Loyalty 3.8 release
                        // For the OCP registration flow the orderId needs to be used in the account details page (which is redirected to after Azure login)
                        // Since we don't have control over what value we can get passed from Azure, we have to save it to local storage.
                        if (orderId) {
                            localStorage.setItem('OrderIdRegistration', orderId);
                        }
                        if(ff_2224243_caps_v3_migration) {
                            return <Redirect to={{ pathname: '/register/createPassword', state: { email: email, orderId: orderId, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration } }} />
                        } else {
                            handleUserLogin('/register/registrationDetails', verificationEmail); // This arguement will make the user go to the account details page for first time login users
                        }
                        
                    } else {
                        setIsVerificationExpired(true);
                    }
                }).catch((error) => {
                    setIsVerificationExpired(true);
                });
            }
        } else if (checkExpiration && token && (email || verificationEmail)) {
            let payload = {
                username: verificationEmail ? verificationEmail : email,
                code: token
            };
            exchangeCode(payload)
                .then((result) => {
                    if (result.data.status === "success" && result.data.token) {
                        setToken(result.data.token);
                        //TODO: Need to change the analytics tag since the user is not yet registered in v3 flow
                        // Makes analtyics direct call when user finishes the registration process
                        if (typeof _satellite != "undefined") {
                            _satellite.track("registration-complete");
                        }
                        // Registering flow from the OCP page -- Loyalty 3.8 release
                        // For the OCP registration flow the orderId needs to be used in the account details page (which is redirected to after Azure login)
                        // Since we don't have control over what value we can get passed from Azure, we have to save it to local storage.
                        if (orderId) {
                            localStorage.setItem("OrderIdRegistration", orderId);
                        }
                        setCheckExpiration(false);
                        setRedirect("createPassword");
                    } else {
                        setIsVerificationExpired(true);
                    }
                })
                .catch((error) => {
                    setIsVerificationExpired(true);
                });
            }
        }, [token, verificationEmail]);

    const handleEmailRequest = (_email, _orderId) => {
        // Reset in case the 'Resend verification email' button is pressed again while the message is active
        setError(false);
        setSuccess(false);

        // Activate spinner
        setLoading(true);

        if (!ff_2224243_caps_v3_migration) {
            // This API will send an email to the registered, but not verified, email.
            generateVerificationCode(_email, _orderId).then(result => {
                if (result.data.status === "success") {
                    setSuccess(true);
                    setLoading(false);
                } else {
                    handleErrorMessage(request.messageKey, request.messageDescription);
                }
            }).catch((err) => {
                handleErrorMessage(err, "LABEL_PASSWORD_RESET_EMAIL_ERROR");
            });
        } else {
            let payload = {
                username: _email,
                orderId: orderId ? orderId : ""
            };
            sendEmailLink(payload)
                .then((response) => {
                    if (response.data.status === "success") {
                        setLoading(false);
                        setSuccess(true);
                    } else {
                        handleErrorMessage(null, t("LABEL_PASSWORD_RESET_EMAIL_ERROR"));
                    }
                })
                .catch((error) => {
                    //if response does not return then display error message
                    handleErrorMessage(error, t("LABEL_PASSWORD_RESET_EMAIL_ERROR"));
                });
            setLoading(false);
        }
    }

    function handleErrorMessage(error, errorMessage) {
        // Display error message
        setError(true);

        // Hide spinner
        setLoading(false);
        console.log(error, errorMessage);
    }

    // When the token has expired, then take them to the account verification page.
    if (isVerificationExpired) {
        if (!ff_2224243_caps_v3_migration) {
            if (orderId) {
                // Scenario for the OCP flow includes an order id
                return (
                    <Redirect
                        to={{
                            pathname: "/register/accountVerification",
                            state: { email: verificationEmail, orderId: orderId, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration }
                        }}
                    />
                );
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: "/register/accountVerification",
                            state: { email: verificationEmail, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration }
                        }}
                    />
                );
            }
        } else {
            if (orderId) {
                // Scenario for the OCP flow includes an order id
                return <Redirect to={{
                    pathname: '/register/accountVerification',
                    state: { email: verificationEmail, orderId: orderId, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration }
                }} />
            } else {
                return <Redirect to={{
                    pathname: '/register/accountVerification',
                    state: { email: verificationEmail, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration }
                }} />
            }
        }
    }

    if (checkExpiration) {
        return (
            <CenterGrid>
                <div className="ac-email-confirmation-spinner-overlay">
                    <Spinner className="q-loader" />
                </div>
            </CenterGrid>
        )
    } else if (redirect === "createPassword") {
        return (
            <Redirect
                to={{
                    pathname: "/register/createPassword",
                    state: {
                        token: token,
                        email: verificationEmail,
                        orderId: orderId ? orderId : null,
                        ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration
                    }
                }}
            />
        );
    } else {
        return (
            <Translation>
                {
                    (t) => (

                        <CenterGrid headerLabel={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_HEADER")}>
                            <div className="ac-grid-email-confirmation">
                                {error && <ErrorMessage icon={true} message={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_ERROR_UNABLE_TO_SEND")} />}
                                {success && <SuccessMessage icon={true} showSuccess={success} message={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUCCESSFULLY_SENT")} />}

                                <FormInstructions instructions={[
                                    { text: "LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUBHEADER1" }
                                ]} />

                                <div className="q-text">
                                    <Trans i18nKey="LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_SUBHEADER2">
                                        An email has been sent to <b>{{ name: email }}</b> with a link
                                    </Trans>
                                </div>

                                <br />
                                <Button type="submit"
                                    className="stat-button-link"
                                    disabled={false}
                                    localeKey={"LABEL_ACCOUNT_REGISTRATION_RESEND_EMAIL_BUTTON_LABEL"}
                                    onClick={() => handleEmailRequest(email, orderId)}
                                    isLoading={loading}
                                    dataDtm={"email verification"}
                                />
                            </div>

                            <Footnote
                                localeKey="LABEL_ACCOUNT_REGISTRATION_TERMS_CONDITIONS_FOOTNOTE"
                                href={[ownerCenterLink, privacyLink]}
                                dataDtmLink={["email verification", "email verification"]}
                            />
                            <br />
                            <div className="text-center">
                                <Trans i18nKey="LABEL_PASSWORD_RESET_PHONECALL">
                                    {/* Not hardcoded text - this is done for interpolation */}
                                    Contact us at:
                                        <a href={"tel:" + phoneNumber}>{{ phoneNumber }}</a>
                                </Trans>
                            </div>
                        </CenterGrid>
                    )
                }
            </Translation>
        )
    }
};

export default AccountConfirmation;
