import React, { useState }from 'react';
import { connect } from 'react-redux';
import { Translation, Trans } from 'react-i18next';
import { generateVerificationCode, sendEmailLink } from '../shared/AccountRegistrationAPI';
import { pageTitle } from "../../../shared/Hooks/Hooks";
import { Redirect } from 'react-router-dom';
import CenterGrid from '../../../shared/CenterGrid/CenterGrid';
import ErrorMessage from '../../../shared/ErrorMessage/ErrorMessage';
import Button from '../../../shared/Button/Button';
import Footnote from '../../../shared/Footnote/Footnote';
import { getBrandUrl } from '../../../shared/Utils/Utils';

function AccountVerification(props) {
    pageTitle("Account Verification");

    // For error handling
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const ff_2224243_caps_v3_migration = props.location.state.ff_2224243_caps_v3_migration;

    // Set this as the default title. Only change it if the user logged in without verifying their account
    let title = "LABEL_ACCOUNT_VERIFICATION_EXPIRED";

    // Retrieve the unverifiedEmail, if any, from the reducer. (Coming from Authentication.js)
    let unverified = props.authData.unverifiedEmail;
    let email = props.location.state ? props.location.state.email : "";

    // Registering flow from the OCP page -- Loyalty 3.8 release
    const orderId = props.location.state ? props.location.state.orderId : "";

    // Terms and conditions, and privacy links
    const ownerCenterLink = getBrandUrl();
    const privacyLink = process.env.REACT_APP_ROSCA_TERMS_AND_CONDITIONS;

    // Get the phone number that users can use to call for help, if needed.
    const phoneNumber = process.env.REACT_APP_AUTH_HELP_PHONE;

    // If neither of the email, or unverified email where filled in this means user tried to access /register/accountConfirmation directly. Redirect them.
    if((email == "" || email == null) && (unverified == "" || unverified == null)) {
        return <Redirect to={{ pathname: '/register', state: { noEmailError: true } }} />
    } else if (unverified) {
        // This will only occur if the user tried to log in without verifying their account.
        // Set the title to a proper title, and the email to the unverified email to be rendered.
        title = "LABEL_ACCOUNT_VERIFICATION_MISSING";
        email = unverified;
    }

    const handleEmailRequest = (_email, _orderId) => {
        // Reset in case the 'Resend verification email' button is pressed again while the message is active
        setError(false);

        // Activate spinner
        setLoading(true);
        if (!ff_2224243_caps_v3_migration) {
            // This API will send an email to the registered, but not verified, email.
            generateVerificationCode(_email, _orderId).then(result => {
                if (result.data.status === "success") {
                    setLoading(false);
                    setSuccess(true);

                } else {
                    handleErrorMessage("Error", "LABEL_PASSWORD_RESET_EMAIL_ERROR"); 
                }
            }).catch((error) => {
                handleErrorMessage(error, "LABEL_PASSWORD_RESET_EMAIL_ERROR");
            });
        } else {
            let payload = {
                username: _email,
                orderId: orderId ? orderId : ""
            };
            sendEmailLink(payload)
                .then((response) => {
                    if (response.data.status === "success") {
                        setLoading(false);
                        setSuccess(true);
                    } else {
                        handleErrorMessage("Error", "LABEL_PASSWORD_RESET_EMAIL_ERROR");
                    }
                })
                .catch((error) => {
                    //if response does not return then display error message
                    handleErrorMessage(error, "LABEL_PASSWORD_RESET_EMAIL_ERROR");
                });
        }
    }

    function handleErrorMessage(error, errorMessage) {
        // Display error message
        setError(true);

        // Hide spinner
        setLoading(false); 
    }

    // When the email sending is successful, we will redirect to confirmation page.
    if(success) {
        if (orderId) {
            // This is for the OCP user#1 flow
            return <Redirect to={{ pathname: '/register/accountConfirmation', 
            state: { email: email, orderId: orderId, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration } }} /> 
        } else {
            return <Redirect to={{ pathname: '/register/accountConfirmation', 
                                   state: { email: email, ff_2224243_caps_v3_migration: ff_2224243_caps_v3_migration } }} /> 
        }
    }

    return (
        <Translation> 
            {(t) => (
                <CenterGrid headerLabel={t(title)}>
                    {error && <ErrorMessage icon={true} message={t("LABEL_ACCOUNT_REGISTRATION_CHECK_EMAIL_ERROR_UNABLE_TO_SEND")} /> }
                    <div className = "ac-small-marginbottom" >
                        <Trans i18nKey="LABEL_ACCOUNT_VERIFICATION_EXPIRED_INSTRUCTIONS">
                            YOU HAVE <b>{{email}}</b> AVAILABLE!
                        </Trans>
                    </div>
                    <Button
                        type="submit"
                        localeKey="LABEL_ACCOUNT_VERIFICATION_RESEND_BUTTON"
                        onClick={() => handleEmailRequest(email, orderId)} 
                        isLoading={loading} />
                    <Footnote
                        localeKey="LABEL_ACCOUNT_REGISTRATION_TERMS_CONDITIONS_FOOTNOTE"
                        href={[ownerCenterLink, privacyLink]}                             
                    />
                    <br />
                    <div className="text-center">
                        <Trans i18nKey="LABEL_PASSWORD_RESET_PHONECALL">
                            {/* Not hardcoded text - this is done for interpolation */}
                            Contact us at:
                                <a href={"tel:" + phoneNumber}>{{ phoneNumber }}</a>
                        </Trans>
                    </div>
                </CenterGrid>
        )}</Translation>
    )
}

const mapStateToProps = state => {
    return {
        authData: state.Authentication
    };
};
export default connect(mapStateToProps)(AccountVerification);