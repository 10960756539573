import * as type from './MiniCartActionType';

export const loadMiniCartDataAsync = (response) => {
    const action = {
        type: type.MINI_CART_DATA_LOADED,
        payload : response
    }
    return action;
}

export const loadMiniCartDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_MINI_CART_DATA_ASYNC_FAIL,
        error: error
    }
    return action;
}

export const setMiniCartOrderId = (orderId) => {
    const action = {
        type: type.SET_MINI_CART_ORDER_ID,
        orderId: orderId
    }
    return action;
}
