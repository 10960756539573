/** COMPONENT NAME : DEALER LOCATOR BUTTONS(cancel and continue button on dealer locator) **/

/** IMPORT STATEMENT **/
import React from 'react';
import {connect} from 'react-redux';
import {closeChangeDealerLocatorModal,handleContinueButton} from "../../DealerLocatorRedux/DealerLocatorActionCreators";
import {Translation} from 'react-i18next';
import PropTypes from 'prop-types';
import Spinner from '../../../Spinner/Spinner';


const DealerLocatorButtons = (props)=>{
    let disabledButton = true;

    if(props.selectedDealer !==''){
        disabledButton = false;
    }
    return<Translation>{(t) => (<div className="ac-dealerLocatorButtonWrapper small-12">

        <div className="ac-cancelButton small-12 medium-6 large-6 xlarge-6">
            <button onClick={props.handleCancelEvent} className=" q-button q-button-small q-secondary-button stat-button-link" data-dtm="checkout step2:locate your dealer:select dealer">{t("LABEL_COMMON_CANCEL")}</button>
        </div>
        <div className="ac-continueButton small-12 medium-6 large-6 xlarge-6 ">
            <button disabled={disabledButton || props.showLoadingBtn} onClick={props.handleSubmitEvent} className=" q-button q-button-small q-primary-button stat-button-link" data-dtm="checkout step2:locate your dealer:select dealer">
            {t("LABEL_COMMON_CONTINUE")}
            {props.showLoadingBtn && <Spinner isLoadingBtnEnabled="true"/>}
            </button>
        </div>

    </div>)}</Translation>;
        };



/** MAP-DISPATCH-TO-PROPS
 * handleCancelEvent: ACTION WILL TRIGGER TO CLOSE THE MODAL.
 * handleSubmitEvent : DEALER BAC WILL BE SELECTED, MODAL WILL BE CLOSED.
 *
 * TO PROPS **/
const mapDispatchToProps = (dispatch,ownProps)=>{

    return {
        handleCancelEvent:()=>{
            dispatch(closeChangeDealerLocatorModal());
        },
        handleSubmitEvent:()=>{
            const selectedDealer = ownProps.selectedDealer;
            if(selectedDealer !== undefined && selectedDealer !== ''){
                dispatch(handleContinueButton(ownProps));
            }
         }
    }
};

/*** PROP TYPES VALIDATION ***/
DealerLocatorButtons.propTypes = {
    selectedDealer:PropTypes.string
};

export default connect(null,mapDispatchToProps)(DealerLocatorButtons)