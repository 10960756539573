import * as type from './HeaderActionType';

/**************************************************************/
// Actions to load data into the Header state
/**************************************************************/
export const loadHeaderDataAsync = () => {
    const action = {
        type: type.LOAD_HEADER_DATA_ASYNC,
    }
    return action;
}

export const setLogo = (logo) => {
    const action = {
        type: type.SET_LOGO,
        logo: logo
    }
    return action;
}

export const setDealerHeader = (dealerAddress, dealerPhone, dealerName,orgId) => {
    const action = {
        type: type.SET_DEALER_HEADER,
        address: dealerAddress,
        phone: dealerPhone,
        name: dealerName,
        orgId:orgId
    }
    return action;
}

export const setFlyoutText = (flyoutText) => {
    const action = {
        type: type.SET_FLYOUT_TEXT,
        flyoutText: flyoutText
    }
    return action;
}

export const setLoading = (loading) => {
    const action = {
        type: type.SET_LOADING,
        loading: loading
    }
    return action;
}

/**************************************************************/
// Actions to handle errors when loading data
/**************************************************************/
export const loadHeaderDataAsyncFail = (error) => {
    const action = {
        type: type.LOAD_HEADER_DATA_ASYNC_FAIL,
        error
    }
    return action;
}

export const setLogoFail = (error) => {
    const action = {
        type: type.SET_LOGO_FAIL,
        error
    }
    return action;
}

export const setDealerHeaderFail = (error) => {
    const action = {
        type: type.SET_DEALER_HEADER,
        error
    }
    return action;
}
export const setFlyoutTextFail = (error) => {
    const action = {
        type: type.SET_FLYOUT_TEXT_FAIL,
        error
    }
    return action;
}
