import React, { Component } from "react";

function OptionList(props) {
    const options = props.options;
    if (options) {
        const optionItems = options.map((option) => <option key={option}>{option}</option>);
        return optionItems;
    } else {
        return "";
    }
}

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.defaultValue };
    }

    render() {
        return (
            <>
                <div className={"q-form-dropdown-label stat-dropdown-label "}>
                    {this.props.defaultValue.replace("Choose ", "")}
                </div>
                <select
                    disabled={this.props.disabled}
                    onChange={this.props.onClick}
                    value={this.props.value}
                    className={
                        this.props.className
                            ? "q-form-dropdown stat-dropdown " + this.props.className
                            : "q-form-dropdown stat-dropdown "
                    }
                    id={this.props.defaultValue.replace(/ /g, "-") + "-dropdown"}
                    data-dtm={this.props.dataDtm ? this.props.dataDtm : "search option:year-model search"}
                >
                    <option hidden value="">
                        {this.props.defaultValue}
                    </option>
                    <OptionList options={this.props.options} />
                </select>
            </>
        );
    }
}

export default Select;
