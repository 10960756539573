import { call, put, select } from 'redux-saga/effects';
import AppSettings from '../../../core/AppSettings';
import store from '../../../core/Redux/Store';
import { loadOrderInfoAsync } from '../../OrderInfo/OrderInfoRedux/OrderInfoCreator';
import { showSummaryRailSpinner } from '../../SummaryRail/SummaryRailRedux/SummaryRailActionCreator';
import { isValidValue } from '../../Validator/Validator';
import {
    clearRewardsHeaderPoints, getCartPotentialRewardsPoints,
    setCartPotentialRewardsPoints,
    setCartPotentialRewardsPointsFailed, setMemberRedemptionInfo,
    setMemberRedemptionInfoFailed,
    setPotentialPointsInfoFailed, setRedeemRewardsError, setRewardsEnrollmentStatusFetched,
    setRewardsHeaderPoints,
    setRewardsHeaderPointsFailed, setRewardsPotentialPoints,
} from './RewardsActionCreator';
import {
    getHeaderRewardsMemberDetail,
    getMemberRedemptionInformation,
    getPotentialRewardsPoints,
    updateRewardsPoints,
    simulateProductPointsGuest
} from './RewardsService';

//used to get redux state information to pass in
//functions below will be used with Redux select
export const getEnrolledAttributeValue = state => state.Authentication.enrolledAttributeValue;
export const getUserData = state => state.Authentication.userData;
export const getMemberStatus = state => state.RewardsReducer.isRewardsActive;

/**Rewards Users:
 User 1 = Guest User
 User 2 = Registered with an account && NOT ENROLLED in Rewards
 User 3 = Registered with an account && ENROLLED in Rewards
 User 4 = Registered with an account && ENROLLED in Rewards && has GM Card
 */

//---------------------------------------------------------------------------------------------------------------
// Calls get Redemption Information available of members and checks for valid response
export const getRedemptionInformationConfig = async (action) => {  
    try {
        const response = await getMemberRedemptionInformation(action.transactionPrice)
        if (isValidValue(response)) {
            if (response.data.RespMessage === "Successful") {
                if( response.data['LOY Member'] &&  
                response.data['LOY Member']['Point Balance'] &&
                response.data['LOY Member']['Point Balance']['Redeemable - Points']){
                    let redemptionDollars = response.data['LOY Member']['Point Balance']['Redeemable - Dollars'];
                    let redemptionPoints = response.data['LOY Member']['Point Balance']['Redeemable - Points'];
                    store.dispatch(setMemberRedemptionInfo(redemptionDollars, redemptionPoints));
                }
                else{
                  store.dispatch(setMemberRedemptionInfoFailed("NO POINT BALANCE"));
                }
            } else {
                const failureMessage = response.RespMessage || response.data.ERROR;
                store.dispatch(setMemberRedemptionInfoFailed(failureMessage));
            }
        } else {
            store.dispatch(setMemberRedemptionInfoFailed("INVALID AMOUNT"));
        }
    } catch (error) {
        store.dispatch(setMemberRedemptionInfoFailed("INVALID AMOUNT"));
    }
}

//-----------------------------------------------------------------------------------------------------------------
// Calls get Earn API information checks for valid response
export const getPotentialPointsConfig = async (action) => {
    try {
        let success = false;
        let failureMessage = '';
        const MAX_ATTEMPTS = 3;

        for (let i = 0; i < MAX_ATTEMPTS && !success; ++i) {
            const response = await getPotentialRewardsPoints(action.amount);

            if (isValidValue(response)) {
                if (response.RespMessage === "Successful") {
                    store.dispatch(setRewardsPotentialPoints(response['Points Awarded']));
                    success = true;
                } else {
                    failureMessage = response.RespMessage || response.data.ERROR;
                }
            } else {
                failureMessage = "POTENTIAL POINTS FAILED";
            }
        }

        if (!success) {
            store.dispatch(setPotentialPointsInfoFailed(failureMessage));
        }
    } catch (error) {
        store.dispatch(setPotentialPointsInfoFailed("POTENTIAL POINTS FAILED"));
    }
}

//---------------------------------------------------------------------------------------------------------
export function* getRewardsHeaderDetail() {
    yield put(clearRewardsHeaderPoints());
    const isLight = AppSettings.pageName === "HomePage";
    const enrolledAttributeValue = yield select(getEnrolledAttributeValue);
    //Check to see if user is enrolled in rewards, if not do not run accountInformation call
    if (enrolledAttributeValue === '1') {
        try {
            const userData = yield select(getUserData);
            const userAttributes = userData.contextAttribute;
            //Make REST call to retrieve member information
            const response = yield call(getHeaderRewardsMemberDetail, isLight);
            if (isValidValue(response.data) && response.data.RespCode === '200') {
                //destructure response.data to get MemberInfo data that is needed
                const MemberInfo = response.data['LOY Member'];
                let isEnrolledInRewards = '';
                let attributeValueGMRewards = '';
                let currentTierName = '';
                let rewardsStatus = MemberInfo.Status;
                let totalPoints = MemberInfo["Total Points"];
                let applyForGMCard = isLight ? '' : MemberInfo["Apply Now Flag"];
                let redeemableBalances = isLight ? '' : MemberInfo["Redeemable Balances"];
                let nonVehicleRedeemDollars = '';
                let nonVehicleRedeemPoints = '';
                let nonVehicleRedeemActualDollars = '';
                let nonVehicleRedeemActualPoints = '';
                let canRedeem = MemberInfo["Can Redeem"];
                if(MemberInfo['Current Tier'] !== undefined){
                    currentTierName = MemberInfo['Current Tier']['Display Name'];
                }
                //Redeemable Balances can either come back as an array or an object
                //depending on the tier status the 'Pool Code' will return as 'NONVEH - S', 'NONVEH - G', 'NONVEH - P'
                //S = 'Silver' G = 'Gold' p = 'Platinum', we only need to check for 'NONVEH' in the string
                //Will check if there are redeemableBalances and if so loop through to find "NONVEH"
                if (redeemableBalances && Array.isArray(redeemableBalances)) {
                    for (const val of redeemableBalances) {
                        if (val["Pool Code"].split(' ')[0] === "NONVEH") {
                            nonVehicleRedeemDollars = val["Cap Remaining Amount"]
                            nonVehicleRedeemPoints = val["Cap Remaining Points"]
                            nonVehicleRedeemActualDollars = val["Redeemable Amount Dollars"]
                            nonVehicleRedeemActualPoints = val["Redeemable Amount Points"]
                        }
                    }
                } else if (redeemableBalances) {
                    if (redeemableBalances.val["Pool Code"].split(' ')[0] === "NONVEH") {
                        nonVehicleRedeemDollars = redeemableBalances.val["Cap Remaining Amount"]
                        nonVehicleRedeemPoints = redeemableBalances.val["Cap Remaining Points"]
                        nonVehicleRedeemActualDollars = val["Redeemable Amount Dollars"]
                        nonVehicleRedeemActualPoints = val["Redeemable Amount Points"]
                    }
                }
                // This for loop is meant to retrieve if the user is a rewards member as
                // well as set attributeValueGMRewards to GMRewards
                for (const val of userAttributes) {
                    // loop through attributes and find GMREWARDS in order to pull if they are enrolled
                    //in GM rewards 1(true) shows they are a member and 0(false) show they are NOT a member
                    if (val.attributeName === 'GMREWARDS') {
                        isEnrolledInRewards = val.attributeValue[0].value[0];
                        attributeValueGMRewards = val.attributeName;
                    }
                }
                if (rewardsStatus === 'Active' && isEnrolledInRewards === "1" && attributeValueGMRewards === 'GMREWARDS') {
                    yield put(setRewardsHeaderPoints(
                        rewardsStatus, totalPoints, isEnrolledInRewards, attributeValueGMRewards, currentTierName,
                        applyForGMCard, nonVehicleRedeemDollars, nonVehicleRedeemPoints, canRedeem, nonVehicleRedeemActualDollars, nonVehicleRedeemActualPoints));
                }
                yield put(setRewardsEnrollmentStatusFetched())
            } else {
                throw response.data.RespMessage;
            }
        } catch (error) {
            yield put(setRewardsHeaderPointsFailed('LABEL_REWARDS_SERVICE_ERROR'));
            console.warn('Unable to get account information for rewards data: ', error);
        }
    }
}


//Max attempts will be for cart potential points
let MAX_ATTEMPTS = 3;

//*****************Cart Potential Rewards Points*****************************\\
export function* handleGetCartPotentialPoints(action) {
    //check if rewards member is active
    const isRewardsMember = yield select(getMemberStatus)
    try {
        let success = false
        for (let a = 0; a < MAX_ATTEMPTS && !success; a++) {
            let pointsArray = [];

            let failure = false;

            for (let i = 0; i < action.cartData.length && !failure; i++) {
                for (let j = 0; j < action.cartData[i].orderItems.length && !failure; j++) {
                    const {orderItemId, unitPrice, quantity} = action.cartData[i].orderItems[j].items[0];
                    const amount = unitPrice * quantity;

                    //if rewards member is active get potential points for members
                    //we are making checks in other files to suppress showing anything in rewards for non active members
                    //else get points for guests
                    const response = isRewardsMember ?
                        yield call(getPotentialRewardsPoints, amount) :
                        yield call(simulateProductPointsGuest, amount);
                    if (response) {
                        if (response.data.RespMessage === "Successful" && isValidValue(response.data)) {
                            let itemPotentialPoints = isRewardsMember ?
                                parseInt(response.data.PointsAwarded) :
                                parseInt(response.data.Points);
                            let arrayItem = {
                                id: orderItemId,
                                unitPrice: unitPrice,
                                quantity: quantity,
                                points: itemPotentialPoints
                            }
                            pointsArray.push(arrayItem);
                        } else {
                            failure = true;
                        }
                    } else {
                        failure = true;
                    }
                }
            }

            if (!failure) {
                success = true;

                yield put(setCartPotentialRewardsPoints(pointsArray));
            }
        }

        if (!success) {
            yield put(setCartPotentialRewardsPointsFailed("Unable to retrieve cart potential points"));
        }
    } catch (error) {
        yield put(setCartPotentialRewardsPointsFailed(error));
    }
}

//*******************Update Cart Potential Points***************************\\
export function* handleUpdateItemPotentialRewardsPoints(action) {
    const isRewardsMember = yield select(getMemberStatus)
    try {
        if (store.getState().RewardsReducer.cartPotentialRewardsPointsError) {
            const cartData = store.getState().OrderInfoReducer.response.order.vehicles;
            yield put(getCartPotentialRewardsPoints(cartData));
        } else {
            const unitPrice = store.getState().RewardsReducer.cartPotentialRewardsPoints.find(item => item.id === action.itemId).unitPrice;
            const amount = unitPrice * action.quantity;
            let success = false;

            for (let a = 0; a < MAX_ATTEMPTS && !success; a++) {
                const response = isRewardsMember ?
                    yield call( getPotentialRewardsPoints, amount) :
                    yield call(simulateProductPointsGuest, amount);

                if (response) {
                    if (response.data.RespMessage === "Successful") {
                        const potentialPoints = isRewardsMember ?
                            parseInt(response.data.PointsAwarded) :
                            parseInt(response.data.Points);

                        const pointsArray = store.getState().RewardsReducer.cartPotentialRewardsPoints.map(item => {
                            if (item.id === action.itemId) {
                                return {
                                    ...item,
                                    points: potentialPoints
                                }
                            } else {
                                return item;
                            }
                        });
                        success = true;
                        yield put(setCartPotentialRewardsPoints(pointsArray));
                    }
                }
            }

            if (!success) {
                yield put(setCartPotentialRewardsPointsFailed("Unable to retrieve cart potential points"));
            }
        }
    } catch (error) {
        yield put(setCartPotentialRewardsPointsFailed(error));
    }
}

//******************* Redeem rewards at checkout step 2 ***************************\\
export function* callForUpdateRewardsPoints(action) {
    yield put(showSummaryRailSpinner(true));
    try {
        // If the user needs to re-select points, then current points must be removed first.
        if (action.rewardsPointsToRemove.length && action.rewardsPointsToApply.length) {
            //Remove Points
            yield put(setRedeemRewardsError(""));
            const removeResponse = yield call(updateRewardsPoints, action.orderId, action.rewardsPointsToRemove, "REMOVE");
            if (removeResponse && removeResponse.data && removeResponse.data.respKey == "SUCCESS") {
                //If remove successful apply new value
                const applyResponse = yield call(updateRewardsPoints, action.orderId, action.rewardsPointsToApply, "APPLY");
                if (applyResponse && (applyResponse.data && applyResponse.data.respCode != 200)) {
                    yield put(setRedeemRewardsError("LABEL_REWARDS_POINTS_ERROR"));
                }
            } else {
                yield put(setRedeemRewardsError("LABEL_REWARDS_POINTS_ERROR"));
            }
        } else if (action.rewardsPointsToRemove.length) {
            //remove value
            yield put(setRedeemRewardsError(""));
            const removeResponse = yield call(updateRewardsPoints, action.orderId, action.rewardsPointsToRemove, "REMOVE");
            if (removeResponse && removeResponse.data && removeResponse.data.respKey != "SUCCESS") {
                yield put(setRedeemRewardsError("LABEL_REWARDS_POINTS_ERROR"));
            }
        } else if (action.rewardsPointsToApply.length) {
            //apply value
            yield put(setRedeemRewardsError(""));
            const applyResponse = yield call(updateRewardsPoints, action.orderId, action.rewardsPointsToApply, "APPLY");
            if (applyResponse && applyResponse.data && applyResponse.data.respCode != 200) {
                yield put(setRedeemRewardsError("LABEL_REWARDS_POINTS_ERROR"));
            }
        }
    } catch (error) {
        yield put(setRedeemRewardsError("LABEL_REWARDS_POINTS_ERROR"));
    } finally {
        yield put(loadOrderInfoAsync());
        yield put(showSummaryRailSpinner(false));
    }
}

export function* handleGetPdPotentialPoints(action) {
  try {
      let success = false;
      let failureMessage = "";

      for (let i = 0; i < MAX_ATTEMPTS && !success; i++) {
          // Check for memberNumber (User 3) to determine which API to call
          const response = 
          action.memberNumber ?
              yield call(getPotentialRewardsPoints, action.price):
              yield call(simulateProductPointsGuest, action.price);
          if (response) {
              if (response.data.RespMessage === "Successful") {
                  const potentialPoints = action.memberNumber ?
                      response.data["Estimated Total Points"] :
                      response.data.Points;

                  success = true;
                  yield put(setRewardsPotentialPoints(potentialPoints));
              } else {
                  failureMessage = response.data.RespMessage || response.data.ERROR || response.data.message;
              }
          } else {
              failureMessage = "500";
          }
      }

      if (!success) {
          yield put (setPotentialPointsInfoFailed(failureMessage));
      }
  } catch (error) {
      yield put (setPotentialPointsInfoFailed(error));
  }
}
