import React, { useState, useEffect } from 'react';
import { Translation } from 'react-i18next';
import EditAddressForm from './../EditAddresses/EditAddresses';

const AddressDetails = props => {

    const [addresses, setAddresses] = useState([]);
    const [person, setPerson] = useState({});

    const addressTypes = {
        work : "WORK",
        home : "HOME"
    }

    useEffect(() => {
        if (props.personalInfo != undefined && props.personalInfo.addresses != undefined) {
            setAddresses(props.personalInfo.addresses);
        }
        if (props.personalInfo != undefined && props.personalInfo.person != undefined) {
            setPerson(props.personalInfo.person);
        }
    }, [props.personalInfo]);

    return (
        <Translation>
            {
                (t) => (
                    <div className="ac-personal-address-details small-12 grid-column-alignment-left columns ac-nopadding-x">
                        <div className={(props.profilesettings.showEditAddress || props.profilesettings.showAddAddress ? "profile-address-hide" : "") + " small-12 grid-column-alignment-left columns ac-nopadding-x"}>
                            {addresses && addresses.map((address, index) =>
                                <div className="ac-address-column small-6 grid-column-alignment-left columns ac-nopadding-x">
                                    <div className="ac-profile-address">
                                        {address.address_type == addressTypes.home && <h5>Default Address</h5>}
                                        {address.address_type == addressTypes.work && <h5>Work Address</h5>}
                                        <p>{person.first_name} {person.last_name}</p>
                                        {address.address_line1 && <p className="q-body2">{address.address_line1}</p>}
                                        {address.address_line2 && <p className="q-body2">{address.address_line2}</p>}
                                        {address.address_line3 && <p className="q-body2">{address.address_line3}</p>}
                                        <p className="q-body2">{address.city}, {address.state_or_province} {address.postal_code}</p>
                                    </div>
                                    <div className="small-12 grid-column-alignment-left columns ac-nopadding-x">
                                        <a className="q-body2 ac-profile-edit" onClick={() => props.toggleAddressEdit({address: address, person: person})}>{t("LABEL_CHECKOUT_EDIT_INFO")}</a>
                                        <span className="q-body2 ac-profile-vertical-line"></span>
                                        <a className="q-body2 ac-profile-remove">{t("LABEL_CHECKOUT_REMOVE")}</a>
                                    </div>
                                </div>
                            )}
                            <div className="ac-profile-add-address small-12 grid-column-alignment-left columns ac-nopadding-x">
                                <a className="q-body2 ac-add-address" onClick={props.toggleAddressAdd}>Add New Address</a>
                            </div>
                        </div>
                        {(props.profilesettings.showEditAddress || props.profilesettings.showAddAddress) &&
                            <div className="overlay small-12 grid-column-alignment-left columns ac-nopadding-x"></div>}

                        <div className={((props.profilesettings.showEditAddress || props.profilesettings.showAddAddress) ? "edit-address-display-active" : "edit-address-display") + " small-6 grid-column-alignment-left columns ac-nopadding-left"}>
                            <EditAddressForm
                                profilesettings={props.profilesettings}
                                toggleAddressEdit={props.toggleAddressEdit}
                                toggleAddressAdd={props.toggleAddressAdd}
                                resetProfileToggle={props.resetProfileToggle} />
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

export default AddressDetails;