import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Simple modal footer for the main screen of the Vault UI modal.
 * Offers apply and reset all functionality.
 *
 * @param {{onSelection: function}} props
 * @returns {React.ReactFragment}
 */
const VaultModalFooter = (props) => {
    const { onSelection = () => {} } = props;
    const { t } = useTranslation();

    return (
        <>
            <button className="vc-button" variant="danger" onClick={() => onSelection("resetConfirm")}>
                Reset All
            </button>
            <button className="vc-button" variant="info" onClick={() => onSelection("applyConfirm")}>
                Save and Apply All
            </button>
        </>
    );
};

export default VaultModalFooter;
