import React,{Component} from 'react';
import {Translation} from 'react-i18next';
import {connect} from "react-redux";
import { Field, reduxForm } from 'redux-form';
import * as validate from '../../../../../shared/Form/FormValidate/FormValidate';
import { withTranslation } from 'react-i18next';
import { toggleAddressEdit as initializeAddressFields } from '../../../UserProfileRedux/UserProfileCreator';

import i18n from '../../../../../core/i18n/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const renderField = ({ input, label, type, required, meta: { touched, error, warning }, ...otherProps }) => (
    <div>
        <div className={touched && error ? 'gmit-gf-text-field error' : 'gmit-gf-text-field'}>
            {required == true && <label className="gmit-gf-label-text required">{label}</label>}
            {required != true && <label className="gmit-gf-label-text">{label}</label>}
            <input {...input} placeholder={label} type={type} className="stat-input-field" data-dtm="checkout step1"
                   data-dtm-2="exclude" {...otherProps} />
            {touched && ((error && <label className="error ac-error-label"><FontAwesomeIcon icon="exclamation-circle" />
                {error}</label>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)

let EditAddressesForm = props => {
    const { handleSubmit, pristine, reset, submitting, stateList, initialValues, profilesettings, resetProfileToggle } = props;

    return(
        <Translation>
            {
                (t)=>(
                    <div className="ac-phone-edit-form">
                        <div className="small-8 grid-column-alignment-left columns ac-nopadding-x">
                            {profilesettings.showEditAddress &&
                                <h5>{t("LABEL_ADDRESS_VALIDATION_EDIT_BUTTON")}</h5>}
                            {profilesettings.showAddAddress &&
                                <h5>{t("LABEL_PROFILE_ADD_ADDRESS")}</h5>}
                        </div>
                        <div className="small-4 grid-column-alignment-right columns ac-nopadding-x">
                            <span>
                                <a className="q-body2 ac-profile-cancel" onClick={resetProfileToggle}>{t("LABEL_COMMON_CANCEL")}</a>
                            </span>
                        </div>

                        <div className="gmit-gf-request-type">
                            <div className="row">
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="firstname"
                                        type="text"                                        
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_FIRST_NAME")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        className="stat-input-field"
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="lastname"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_LAST_NAME")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="companyName"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_COMPANY_NAME")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        className="stat-input-field"
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="address1"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_CHECKOUT_ADDRESS_LINE1")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="address2"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_CHECKOUT_ADDRESS_LINE2")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="city"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_CITY")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="state"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_STATE")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="zipcode"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_ZIP")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                                <div className="small-12 grid-column-alignment-left columns">
                                    <Field
                                        name="phone"
                                        type="text"
                                        component={renderField}
                                        label={i18n.t("LABEL_COMMON_PHONE")}
                                        required={true}
                                        validate={[validate.required, validate.minLength2, validate.maxLength64]}
                                        maxLength={64}
                                        minLength={2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ac-phone-disclaimer">
                            <input type="checkbox"/>
                            <span className="q-body2">{t("LABEL_PROFILE_DEFAULT_ADDRESS")}</span>
                        </div>
                        <div className="ac-update-profile small-12 medium-12 grid-column-alignment-left columns ac-nopadding-x">
                            {profilesettings.showEditAddress &&
                                <button className="q-button q-primary-button gm-primary-btn" onClick={resetProfileToggle}>{t("LABEL_PROFILE_UPDATE_ADDRESS")}</button>}
                            {profilesettings.showAddAddress &&
                                <button className="q-button q-primary-button gm-primary-btn" onClick={resetProfileToggle}>{t("LABEL_PROFILE_PASSWORD")}</button>}
                        </div>
                    </div>
                )
            }

        </Translation>
    )
}

EditAddressesForm = reduxForm({
    form: 'EditAddressesForm', // a unique identifier for this form
    destroyOnUnmount: true, // <------ preserve form data
    enableReinitialize: true,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  })(EditAddressesForm)

  EditAddressesForm = connect(  state => ({
    initialValues: state.UserProfileReducer.profilesettings.editAddressInitialValues 
  }),
  { toggleAddressEdit: initializeAddressFields }) 
  (EditAddressesForm)

export default EditAddressesForm